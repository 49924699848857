import { CurrentUser, Optional } from 'backend-api/models';
import { actionsGroup } from 'core/store';
import { SettingsFilters } from 'settings/types';

interface UpdateUserPayload {
  phone: Optional<string>;
  dateTimeFormat: number;
}

const { action, asyncAction } = actionsGroup('settings');

export const updateFilters = action<SettingsFilters>('UPDATE_FILTERS');

export const setCurrentPhone = action<string>('SET_CURRENT_PHONE');

export const updateCurrentUser = asyncAction<UpdateUserPayload, CurrentUser, Error>('UPDATE_CURRENT_USER');
