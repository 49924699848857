import { createGlobalStyle } from 'app/styled';
import { authContainer as authContainerZIndex } from 'app/styles/z-indexes';

export const SLogin = createGlobalStyle`
	body {
		.auth0-lock.auth0-lock {
			font-family: 'Montserrat';
			z-index: ${authContainerZIndex};
		}

		.auth0-lock.auth0-lock .auth0-lock-header-logo {
			height: 30px;
		}

		.auth0-lock.auth0-lock .auth0-lock-name {
			font-size: 13px;
		}

		.auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link {

		}

		.auth0-lock.auth0-lock .auth0-lock-header-bg {
			background: ${({ theme }) => theme.colors.header.background};
		}

		// this button in styled inline therefore !important
		.auth0-lock-submit {
			background-color: ${({ theme }) => theme.colors.loader.background} !important;
		}

		// there is no way to configure error message color via JS so we have to override CSS
		.auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error {
		  background-color: ${({ theme }) => theme.colors.loader.background}
		 }

		.auth0-lock-header-bg-blur {
			background-image: none !important;
		}
	}
`;
