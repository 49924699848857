import styled, { css } from 'styled-components';

export const BEM_CLASS = 'label-trigger';

interface SLabelTriggerProps {
  isDisabled: boolean;
}

export const SLabelTrigger = styled.div<SLabelTriggerProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: default;
      pointer-events: none;
    `}

  .${BEM_CLASS} {

    &__image {
      width: 22px;
      height: 22px;
      border-radius: 5px;
      overflow: hidden;
    }
  }
`;
