import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { BgStyle } from 'common/styles/utils';
import { SInput } from 'common/components/form/input/s-input';
interface SMarkdownProps {
  readonly isPlaceholder: boolean;
  readonly bgStyle: BgStyle;
}

export const SMarkdown = styled.div<SMarkdownProps>`
  color: ${({ isPlaceholder }) => (isPlaceholder ? palette.darkBlueTwo : 'black')} !important;
  .markdown {
    &__button {
      background: black;
      color: red;
      font-size: 18px;
      border: 0;
      padding-top: 5px;
      vertical-align: bottom;
      height: 34px;
      width: 36px;
      fill: #ccc;
      &:hover {
        background: black;
        outline: 0; /* reset for :focus */
        fill: ${palette.pink};
        color: ${palette.pink};
      }
    }
    &__button-active {
      background: black;
      color: red;
      font-size: 18px;
      border: 0;
      padding-top: 5px;
      vertical-align: bottom;
      height: 34px;
      width: 36px;
      fill: ${palette.pink};
      color: ${palette.pink};
    }
    &__toolbar {
      left: 50%;
      -webkit-transform: translate(-50%) scale(0);
      transform: translate(-50%) scale(0);
      position: absolute;
      border: 1px solid #ddd;
      background: white;
      color: white;
      border-radius: 120px;
      box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
      z-index: 2;
      overflow: hidden;
      display: flex;
      flex-direction: row;
    }
    &__button-wrapper {
      display: inline-block;
    }
    &__button-active {
      background: black;
      color: red;
      font-size: 18px;
      border: 0;
      padding-top: 5px;
      vertical-align: bottom;
      height: 34px;
      width: 36px;
    }
    &__separator {
      display: inline-block;
      border-right: 1px solid #ddd;
      height: 24px;
      margin: 0 0.5em;
    }
    &__link-input {
      ${SInput};
    }
    &__link-input-invalid {
      ${SInput};
      border: 1px solid red;
      &:focus {
        border: 1px solid red;
      }
      &:focus-within {
        border: 1px solid red;
      }
    }
    &__link {
      cursor: pointer;
      color: ${palette.brightBlue};
      font-family: Montserrat;
      font-size: 14px;
      font-weight: bold;
      line-height: 16px;
      &:active {
        opacity: 0.5;
      }
    }
  }
`;
