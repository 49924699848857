import { ServerErrorResponse } from 'backend-api';
import { InvalidDateRangeErrorResponse, INVALID_DATE_RANGE_ERROR_CODE } from 'common/constants';
import { OptionalPeriod } from 'common/types';
import { getDiffInCalendarDays, isSameDate } from 'common-v2/utils';

export const getDaysDiffInclusive = (firstDate: Date, secondDate: Date) =>
  Math.abs(getDiffInCalendarDays(secondDate, firstDate)) + 1;

const isDateChanged = (date?: Date, dateToCompare?: Date) => {
  if (!date && !dateToCompare) return false;

  if (!date || !dateToCompare) return true;

  return !isSameDate(date, dateToCompare);
};

export const isPeriodChanged = (
  [prevStartDate, prevEndDate]: OptionalPeriod,
  [nextStartDate, nextEndDate]: OptionalPeriod
): boolean => {
  const isStartDateChanged = isDateChanged(prevStartDate, nextStartDate);

  const isEndDateChanged = isDateChanged(prevEndDate, nextEndDate);

  return isStartDateChanged || isEndDateChanged;
};

export const isInvalidDateRangeErrorResponse = (
  response: ServerErrorResponse
): response is InvalidDateRangeErrorResponse => {
  return response.code === INVALID_DATE_RANGE_ERROR_CODE && !!response.extra?.hasOwnProperty('max_period');
};
