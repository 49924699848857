import styled from 'styled-components';
import { palette, shadows } from 'frontend-shared-theme';
import { colorPalette } from 'app/theme/colors';

interface Props {
  accentColor?: string;
}

export const STooltip = styled.div<Props>`
  min-width: 262px;

  .tooltip {
    &__container {
      display: flex;
      flex-direction: column;
      box-shadow: ${shadows.tooltip};
      background-color: ${palette.white};
      padding: 25px;
    }

    &__title {
      color: ${palette.darkBlueTwo};
      font-size: 12px;
      align-self: left;
      margin-bottom: 4px;
    }

    &__values-container {
      border-spacing: 0px;
    }

    &__row {
      display: flex;
      margin-top: 10px;
      align-items: center;
    }

    &__indicator,
    &__accent-indicator {
      width: 10px;
      height: 10px;
      background: ${colorPalette.athensGray};
      border-radius: 10px;
      margin-right: 10px;
    }

    &__accent-indicator {
      background: ${({ accentColor }) => accentColor};
    }

    &__row-element {
      display: flex;
    }

    &__row-title,
    &__total-text,
    &__row-value-percentage,
    &__row-value {
      color: ${palette.darkBlue};
      font-size: 14px;
    }

    &__row-title {
      font-weight: 400;
      flex: 1;
    }

    &__row-value {
      margin-left: 25px;
      font-weight: 600;
    }

    &__row-value-percentage {
      margin-left: 5px;
      font-weight: 400;
    }

    &__separator {
      height: 1px;
      background-color: ${palette.lavanderGray};
    }

    &__total-container {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
    }
  }
`;
