import styled from 'styled-components';

export const SDropdownText = styled.div`
  &.pagination {
    &__items-per-page {
      &--active {
        font-weight: 700;
      }
    }
  }
`;
