import { manhattanThemeColors } from 'app/theme/colors';
import styled from 'styled-components';

export const BEM_CLASS = 'date-input';

export const SDateInput = styled.div`
  width: 100%;
  height: 100%;

  .${BEM_CLASS} {
    &__input {
      width: inherit;
      height: inherit;
      padding: 0 10px;
      border: none;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      color: ${manhattanThemeColors.primaryDark};
      text-transform: uppercase;
    }
  }
`;
