import React from 'react';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';
import { CampaignNoPermission } from 'common/components/campaign-no-permission';
import { Head } from 'app/components/head';
import { HeadStaticTitles } from 'app/components/head/constants';

export const CampaignNoPermissionContainer = React.memo(() => (
  <>
    <Head title={HeadStaticTitles.ACCESS_RESTRICTED} />
    <OutdatedGlobalLayout>
      <CampaignNoPermission />
    </OutdatedGlobalLayout>
  </>
));
