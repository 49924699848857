import styled from 'styled-components';

export const BEM_CLASS = 'label-item';

export const SLabelItem = styled.div`
  padding-bottom: 8px;

  .${BEM_CLASS} {
    &__name {
      text-transform: uppercase;
    }
  }
`;
