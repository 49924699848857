import React, { useCallback, useMemo } from 'react';
import { palette } from 'frontend-shared-theme';
import { SPaper } from 'common/s-components/s-paper';
import { SRow } from 'common/s-components/layout/s-row';
import { SCol } from 'common/s-components/layout/s-col';
import { Cell } from 'common/components/form/cell';
import { Header } from './components';
import { bem } from 'utils/bem';
import { BEM_CLASS, SMomentDetails } from './s-moment-details';
import { LinksViewComponent } from 'common/components/form/links/components';
import { getNamesString } from 'common/transducers';
import { Tooltip } from 'common/components/tooltip';
import { MomentData } from 'artist-moment/types';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { useFormattedDateTime } from 'common/hooks/use-formatted-date-time';

export const classes = bem(BEM_CLASS);

const MAX_TERRITORIES_LENGTH = 48;

export const MomentDetails = React.memo(({ project, moment }: MomentData) => {
  const territoriesString = useMemo(() => getNamesString(moment.territories), [moment.territories]);
  const shortTerritoriesString = useMemo(() => {
    return territoriesString && territoriesString.length > MAX_TERRITORIES_LENGTH
      ? territoriesString?.substring(0, MAX_TERRITORIES_LENGTH).concat('...')
      : territoriesString;
  }, [territoriesString]);
  const showTerritoriesTooltop = !!territoriesString && territoriesString.length > MAX_TERRITORIES_LENGTH;
  const dateString = useFormattedDateTime({ date: moment.date });
  const editDateString = useFormattedDateTime({ date: moment.editDate, isFullDate: true });
  const momentType = moment.type?.name || `Custom ${moment.category?.name || ''}`;

  const lastModifiedLabel = useMemo(() => {
    const userEmail = moment.editUser?.email || moment.createUser?.email;

    return userEmail ? (
      <div className={classes('last-modified-label')}>
        Modified by {userEmail} at {editDateString?.date}
      </div>
    ) : null;
  }, [moment, editDateString?.date]);

  const onLinkClick = useCallback(() => {
    trackEvent(AnalyticsEvents.ARTIST_MOMENT_LINK_CLICKED, {
      moment_category: moment.category?.name,
      moment_type: moment.type?.name,
      moment_source: moment.createUser?.name,
    });
  }, [moment]);

  return (
    <SMomentDetails>
      <div>
        <Header project={project} moment={moment} />
        <SPaper pt="25px" pb="10px" mb="10px" color={palette.white}>
          <div className={classes('moment-main-info')}>
            <Cell label="Artist" dataSelector="artist">
              <span className={classes('text')}>{moment.artist.name}</span>
            </Cell>
            <Cell label="Date" dataSelector="date">
              <span className={classes('text')}>{dateString?.date}</span>
            </Cell>
            <Cell label="Type" dataSelector="type">
              <span className={classes('text')}>{momentType}</span>
            </Cell>
            <Cell label="Countries" dataSelector="countries">
              <Tooltip content={territoriesString} enabled={showTerritoriesTooltop}>
                <span className={classes('countries-text')}>{shortTerritoriesString}</span>
              </Tooltip>
            </Cell>
            {moment.link && (
              <Cell label="Link" className={classes('link')} dataSelector="link">
                {moment.link && <LinksViewComponent links={[moment.link]} onLinkClick={onLinkClick} />}
              </Cell>
            )}
          </div>
        </SPaper>
        {moment.notes && (
          <SPaper pt="25px" pb="15px" mb="10px" color={palette.white}>
            <SRow>
              <SCol width={1}>
                <Cell label="Notes" wide dataSelector="notes">
                  <span className={classes('wide-text')}>{moment.notes}</span>
                </Cell>
              </SCol>
            </SRow>
          </SPaper>
        )}
      </div>
      {lastModifiedLabel}
    </SMomentDetails>
  );
});
