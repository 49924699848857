import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'recent-search';

export const SRecentSearch = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  overflow-x: hidden;
  overflow-y: auto;

  .${BEM_CLASS} {
    &__title {
      margin: 0 0 15px;
      font-size: 14px;
      line-height: 20px;
      color: ${colorPalette.black};
    }

    &__item {
      flex-shrink: 0;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__center {
      margin: auto;
    }
  }
`;
