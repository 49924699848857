import React from 'react';
import RestrictedAccessGray from 'assets/restricted-access-gray.svg';
import { bem } from 'utils/bem';
import { SPermissions, BEM_CLASS } from './s-permissions';
import { Label } from 'backend-api/models';
import { getJoinedNames } from 'common/transducers';

const classes = bem(BEM_CLASS);

interface PermissionsProps {
  labels: Label[];
}

export const Permissions = React.memo(({ labels }: PermissionsProps) => (
  <SPermissions>
    <img className={classes('image')} src={RestrictedAccessGray} alt="No permissions" />
    <b className={classes('main-text')} data-selector="no-permissions-title">
      You don’t have permission to access any projects for this artist
    </b>
    <span className={classes('text')} data-selector="no-permissions-text">
      Please reach out to these label’s marketing teams for permission:
    </span>
    <b className={classes('text')} data-selector="no-permissions-labels">
      {getJoinedNames(labels)}
    </b>
  </SPermissions>
));
