import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { COLUMN_COUNT, GRID_CELL_SIZE } from './constants';

export const BEM_CLASS = 'live-artist';

interface Props {
  itemsLength: number;
  expansionRow?: number;
  isAnimate: boolean;
}

const mediumArtistCardStyle = css<Props>`
  grid-column: span 2;
  aspect-ratio: 19/9;
`;

const largeArtistCardStyle = css<Props>`
  grid-row: span ${({ isAnimate }) => (isAnimate ? 1 : 2)};
  grid-column: span 2;
  aspect-ratio: ${({ isAnimate }) => (isAnimate ? 19 / 9 : 1)};
`;

const extraLargeArtistCardStyle = css<Props>`
  align-self: stretch;
  overflow: hidden;
  grid-row: span ${({ isAnimate }) => (isAnimate ? 1 : 2)};
  grid-column: span 4;
  aspect-ratio: ${({ isAnimate }) => (isAnimate ? 19 / 9 : 39 / 19)};
`;

export const GRID_CONFIG_BY_ITEMS_LENGTH = {
  1: css<Props>`
    > :first-child {
      ${extraLargeArtistCardStyle};
    }
  `,
  2: css<Props>`
    > :first-child {
      ${extraLargeArtistCardStyle};
    }
    > * {
      ${largeArtistCardStyle};
    }
  `,
  3: css<Props>`
    > * {
      ${largeArtistCardStyle};
    }
  `,
  4: css<Props>`
    > :first-child,
    > :nth-child(2) {
      ${largeArtistCardStyle};
    }
    > * {
      ${mediumArtistCardStyle};
    }
  `,
  5: css<Props>`
    > :first-child {
      ${largeArtistCardStyle};
    }
    > * {
      ${mediumArtistCardStyle};
    }
  `,
  6: css<Props>`
    > :first-child {
      ${largeArtistCardStyle};
    }
    > :nth-child(2),
    > :nth-child(3),
    > :nth-child(4) {
      ${mediumArtistCardStyle};
    }
  `,
  7: css<Props>`
    > :first-child,
    > :nth-child(2),
    > :nth-child(3),
    > :nth-child(4),
    > :nth-child(5) {
      ${mediumArtistCardStyle};
    }
  `,
  8: css<Props>`
    > :first-child,
    > :nth-child(2),
    > :nth-child(3),
    > :nth-child(4) {
      ${mediumArtistCardStyle};
    }
  `,
  9: css<Props>`
    > :first-child,
    > :nth-child(2),
    > :nth-child(3) {
      ${mediumArtistCardStyle};
    }
  `,
  10: css<Props>`
    > :first-child,
    > :nth-child(2) {
      ${mediumArtistCardStyle};
    }
  `,
  11: css<Props>`
    > :first-child {
      ${mediumArtistCardStyle};
    }
  `,
};

export const SLiveArtist = styled(motion.div)<Props>`
  display: flex;
  flex-direction: column;

  .${BEM_CLASS} {
    &__title-container {
      margin-bottom: 15px;
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(${COLUMN_COUNT}, minmax(${GRID_CELL_SIZE}px, 1fr));
      grid-template-rows: repeat(auto-fill, min-content);
      gap: 20px;
      padding-top: 10px;
      width: 100%;

      ${({ itemsLength }) => GRID_CONFIG_BY_ITEMS_LENGTH[itemsLength]};
    }

    &__expanded-section {
      grid-column: 1/-1;
      aspect-ratio: unset;
      grid-row: ${({ expansionRow, isAnimate }) => (isAnimate ? expansionRow : 'unset')};
    }
  }
`;
