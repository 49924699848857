import CreatableSelect from 'react-select';
import styled from 'styled-components';
import { SDownCss, SUpCss } from 'common/components/icon/s-icons';
import { select } from 'app/styles/z-indexes';
import { BgStyle, bgStyle } from 'common/styles/utils';
import { palette } from 'frontend-shared-theme';
import { theme } from 'app/theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const block = 'react-select';

const INITIAL_CONTROL_PADDING_LEFT = 20;
const INITIAL_CONTROL_PADDING_RIGHT = 10;

interface Props {
  isInputDisabled?: boolean;
  paddings?: {
    vertical: number;
    horizontal: number;
  };
  bgStyle?: BgStyle;
}

export const SCreatableSelect = styled(CreatableSelect)<Props>`
  line-height: 1;
  cursor: pointer;

  .${block} {
    &__control {
      padding-top: ${({ paddings }) => paddings?.vertical}px;
      padding-bottom: ${({ paddings }) => paddings?.vertical}px;
      padding-left: ${({ paddings }) =>
        paddings ? paddings.horizontal + INITIAL_CONTROL_PADDING_LEFT : INITIAL_CONTROL_PADDING_LEFT}px;
      border-radius: 20px;
      background: ${bgStyle};
      opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
      padding-right: ${({ paddings }) =>
        paddings ? paddings.horizontal + INITIAL_CONTROL_PADDING_RIGHT : INITIAL_CONTROL_PADDING_RIGHT}px;
      border-color: transparent;
      min-height: 35px;
      cursor: text;

      &:hover:not(.${block}__control--is-focused) {
        border-color: transparent;
      }
      &:focus {
        background-color: red;
      }
    }

    &__value-container {
      padding-left: 0;
      padding-right: 23px;
      margin-left: -2px;
    }

    &__control--is-focused {
      border-color: ${({ theme, isDisabled, isInputDisabled }) =>
        isDisabled || isInputDisabled ? 'transparent' : theme.colors.select.focus.borderColor};
      box-shadow: none;
    }
    &__indicator-separator {
      display: none;
    }
    &__indicators {
      .${block}__dropdown-indicator {
        padding: 0;
        position: absolute;
        right: 17px;
        align-self: flex-start;
        top: 11px;
      }
    }

    &__single-value {
      margin-left: 0;
      margin-right: 0;
      overflow: visible;
      top: calc(50% - 1px);
      font-weight: 400;
      color: ${palette.darkBlue};
      line-height: 21px;
    }

    &__menu {
      padding: 11px 0;
      border-radius: 20px;
      line-height: 23px;
      margin-top: 3px;
      box-shadow: ${theme.shadows.default};
      z-index: ${select};
      min-height: 60px;
      overflow: hidden;
    }

    &__input {
      input {
        display: ${({ isInputDisabled }) => (isInputDisabled ? 'none' : 'unset')};
      }
    }

    &__option {
      padding: 5px 19px;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.select.option.color};
      background-color: transparent;
      font-weight: 400;
      line-height: 21px;
      cursor: pointer;
      ${elipsizeText};

      &:active,
      &:hover {
        color: ${({ theme }) => theme.colors.select.option.active.color};
        background-color: ${({ theme }) => theme.colors.select.option.active.background};

        &.${block}__option--is-selected {
          color: ${({ theme }) => theme.colors.select.option.active.color};
          background-color: ${({ theme }) => theme.colors.select.option.active.background};
        }
      }

      &.${block}__option--is-selected {
        color: ${({ theme }) => theme.colors.select.option.selected.color};
        /*background-color: ${({ theme }) => theme.colors.select.option.selected.background};*/
        font-weight: 700;
      }

      &.${block}__option--is-focused {
        color: ${({ theme }) => theme.colors.select.option.active.color};
        background-color: ${({ theme }) => theme.colors.select.option.active.background};
      }
    }

    &__dropdown-indicator {
      ${SDownCss};
      padding-right: ${25 - 9}px;

      svg {
        display: none;
      }
    }

    &__control--menu-is-open {
      .${block}__dropdown-indicator {
        ${SUpCss};
      }
    }
  }
`;
