import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { gradientBorder } from 'common/styles/utils';
import { CardProps } from './types';

export const BEM_CLASS = 'card';

export const SCard = styled.div<CardProps>`
  display: flex;
  background-color: ${transparentize(0.5, colorPalette.white)};
  border: 1px solid; 
  border-radius: 10px;
  box-shadow: 0px 5px 20px ${transparentize(0.95, colorPalette.woodsmoke)};
  overflow: hidden;

  ${({ size, isLoading, isError, hasAction }) =>
    css`
      height: ${size}px;
      border-color: ${isError ? 'transparent' : transparentize(0.9, colorPalette.woodsmoke)};
      box-shadow: 0px 5px 20px ${isLoading || isError ? 'transparent' : transparentize(0.95, colorPalette.woodsmoke)};

      ${hasAction &&
        css`
          &:hover {
            background-color: ${transparentize(0.1, colorPalette.white)};
            box-shadow: 0px 5px 20px ${transparentize(0.8, colorPalette.woodsmoke)};
            border: 0;
            padding: 1px;

            ${gradientBorder(
              10,
              `
                radial-gradient(100% 100% at 0% 0%, ${transparentize(
                  0.5,
                  manhattanThemeColors.primaryFillNew
                )} 0%, ${transparentize(0.95, manhattanThemeColors.primaryFillNew)} 100%), 
                radial-gradient(100% 100% at 100% 100%, ${transparentize(
                  0.5,
                  manhattanThemeColors.primaryFillNew
                )} 0%, ${transparentize(0.95, colorPalette.woodsmoke)} 100%, ${transparentize(
                0.95,
                manhattanThemeColors.primaryFillNew
              )} 100%)
              `
            )}
          }
        `}
    `}

  .${BEM_CLASS} {
    &__error {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      padding: 20px;
      color: ${colorPalette.fiordGrey};
    }
  }
`;
