import React, { useCallback, useMemo, useState } from 'react';
import { BEM_CLASS, SAdSetsTable } from '../../../s-components/ad-sets/s-ad-sets-table';
import { AdSetsSortField } from 'backend-api/types';
import { usePagination, useProjectCurrencyCode } from 'hooks';
import { Table } from 'common/components/table';
import { Pagination } from 'common/components/pagination';
import { ExpandedAdSet } from './expanded-ad-set';
import { useSelector } from 'react-redux';
import { adSetsSelector, isLoadingSelector } from '../../../selectors';
import { bem } from 'utils/bem';
import { AdSet, Id } from 'backend-api/models';
import { getAdSetsTableConfig } from './table-config';
import { DEFAULT_SORT } from '../../../constants';
import { useAdSetsFilters } from 'campaign/hooks';
import { Sort } from 'utils/sort';
import { TableTheme } from 'app/theme/table';
import { dateFormatSelector } from 'common/selectors';
import { UUID } from 'io-ts-types/lib/UUID';

interface Props {
  projectId?: Id;
  campaignUuid: UUID;
}

const classes = bem(BEM_CLASS);

export const AdSetsTable = ({ projectId, campaignUuid }: Props) => {
  const [expandedIds, setExpandedIds] = useState<Id[]>([]);

  const isLoading = useSelector(isLoadingSelector);
  const adSets = useSelector(adSetsSelector);
  const dateFormat = useSelector(dateFormatSelector);

  const currencyCode = useProjectCurrencyCode();

  const [filters, filtersDispatcher] = useAdSetsFilters(campaignUuid, projectId);

  const sort = useMemo(() => filters.sort || DEFAULT_SORT, [filters.sort]);

  const onSortChange = useCallback(
    (sort: Sort<AdSetsSortField>) => {
      filtersDispatcher.setSort(sort);
    },
    [filtersDispatcher]
  );

  const pagination = usePagination(filters, adSets?.total);

  const onPaginationChange = useCallback(
    (pagination: Pagination) => {
      filtersDispatcher.setPagination(pagination);
    },
    [filtersDispatcher]
  );

  const onExpand = useCallback(
    (item: AdSet, isExpanded: boolean) => {
      if (isExpanded) {
        setExpandedIds([...expandedIds, item.id]);
      } else {
        setExpandedIds(expandedIds.filter(id => id !== item.id));
      }
    },
    [expandedIds]
  );

  const bodyContent = useMemo(() => adSets?.items || [], [adSets?.items]);
  const headerContent = useMemo(() => getAdSetsTableConfig(dateFormat, currencyCode), [currencyCode, dateFormat]);

  return (
    <SAdSetsTable>
      <Table
        className={classes('table')}
        dataSelector="campaign-ad-set-table"
        headerContent={headerContent}
        bodyContent={bodyContent}
        onSortChange={onSortChange}
        sort={sort}
        onPaginationChange={onPaginationChange}
        pagination={pagination}
        isExpandable
        renderExpandedRow={content => <ExpandedAdSet item={content} />}
        isLoading={isLoading}
        isSearching={!!filters.search}
        theme={TableTheme.GREY}
        onExpandItem={onExpand}
        expandedIds={expandedIds}
      />
    </SAdSetsTable>
  );
};
