import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { ArtistSearch, Playlist } from 'backend-api/models';
import { DEFAULT_MIN_SEARCH_LENGTH } from 'common/constants';
import { ArtistModalType, ArtistModalTypeInfo, PRIMARY_ARTISTS_LIMIT } from 'project/constants';
import { deselectArtist, deselectPlaylist, selectArtist, selectPlaylist } from 'project/actions';
import { isArtistLocked, typedArtistFromArtistSearch } from 'project/transducers';
import {
  artistsSelector,
  isArtistModalLoadingSelector,
  playlistsSelector,
  selectedArtistsSelector,
  selectedPlaylistsSelector,
} from 'project/selectors';
import { ArtistLoader } from 'common/components/loaders/artists-loader';
import { NoResults } from 'common/components/no-results';
import { ArtistPlaylistItem } from 'common/components/artist-playlist-item';
import { SArtistsPlaylistsSection } from './s-artists-playlists-section';
import { H4 } from 'common/components/typography';

interface Props {
  searchQuery: string;
  type: ArtistModalTypeInfo;
  className?: string;
}

const classes = bem('artists-playlists-section');

export const ArtistsPlaylistsSection = React.memo(({ searchQuery, type, className }: Props) => {
  const dispatch = useDispatch();

  const artists = useSelector(artistsSelector);
  const playlists = useSelector(playlistsSelector);
  const selectedArtists = useSelector(selectedArtistsSelector);
  const selectedPlaylists = useSelector(selectedPlaylistsSelector);
  const isLoading = useSelector(isArtistModalLoadingSelector);

  const [isNoResults, setNoSearchResults] = useState(true);

  useEffect(() => {
    setNoSearchResults(
      searchQuery.length < DEFAULT_MIN_SEARCH_LENGTH || (artists.length === 0 && playlists.length === 0)
    );
  }, [searchQuery, artists, playlists]);

  const canSelect = useMemo(() => selectedArtists.length < type.limit && selectedPlaylists.length < type.limit, [
    type,
    selectedArtists,
    selectedPlaylists,
  ]);

  const renderArtistItem = useCallback(
    (artist: ArtistSearch) => {
      const selectedArtist = selectedArtists.find(selected => selected.id === artist.id);
      const isSelectedArtistLocked = selectedArtist && isArtistLocked(selectedArtist);
      const primaryArtists = selectedArtists.filter(artist => artist.type === 'Primary');
      const artistType = primaryArtists.length === PRIMARY_ARTISTS_LIMIT ? 'Featured' : 'Primary';

      const onSelect = (isSelected: boolean) => {
        if (isSelected) {
          dispatch(selectArtist(typedArtistFromArtistSearch(artist, artistType)));
        } else {
          dispatch(deselectArtist(artist.id));
        }
      };

      return (
        <ArtistPlaylistItem
          target={artist}
          onSelect={onSelect}
          isSelected={!!selectedArtist}
          canSelect={canSelect}
          isLocked={isSelectedArtistLocked}
        />
      );
    },
    [dispatch, selectedArtists, canSelect]
  );
  const renderPlaylistItem = useCallback(
    (playlist: Playlist) => {
      const isSelected = selectedPlaylists.some(selected => selected.id === playlist.id);
      const onSelect = (isSelected: boolean) => {
        if (isSelected) {
          dispatch(selectPlaylist(playlist));
        } else {
          dispatch(deselectPlaylist(playlist));
        }
      };
      return <ArtistPlaylistItem target={playlist} onSelect={onSelect} isSelected={isSelected} canSelect={canSelect} />;
    },
    [dispatch, selectedPlaylists, canSelect]
  );

  const itemsList = useMemo(() => {
    switch (type.type) {
      case ArtistModalType.Artist:
        return artists.map(artist => renderArtistItem(artist));
      case ArtistModalType.Playlist:
        return playlists.map(playlist => renderPlaylistItem(playlist));
    }
  }, [type, artists, playlists, renderPlaylistItem, renderArtistItem]);

  const resultLength = type.type === ArtistModalType.Artist ? artists.length : playlists.length;

  const body = useMemo(() => {
    if (isLoading) {
      return <ArtistLoader className={classes('empty-view')} />;
    }

    if (isNoResults) {
      return <NoResults withFilters className={classes('empty-view')} />;
    }

    return (
      <>
        <H4 className={classes('section-title')}>Search Result ({resultLength})</H4>
        <div className={classes('items-scroller')}>
          <div className={classes('items-grid')}>{itemsList}</div>
        </div>
      </>
    );
  }, [isLoading, isNoResults, resultLength, itemsList]);

  return <SArtistsPlaylistsSection className={className}>{body}</SArtistsPlaylistsSection>;
});
