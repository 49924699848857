import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { typography } from 'app/theme/typography';

export const BEM_CLASS = 'multi-value';

export const SMultiValueContainer = styled.div`
  height: 22px;
  border: 1px solid ${colorPalette.lavenderGray};
  border-radius: 5px;
  background-color: ${colorPalette.white};
  padding: 0 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-right: 5px;
  ${typography.body};
`;
