import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'confirm-review-footer';

export const SConfirmReviewFooter = styled.div`
  display: grid;
  align-items: center;
  padding: 10px 40px;
  grid-template-columns: repeat(3, 1fr);
  background-color: ${manhattanThemeColors.background};
  border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};

  .${BEM_CLASS} {
    &__info {
      grid-column: 1 / 2;
    }

    &__button-container {
      grid-column: 2 / 3;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    &__warning-container {
      display: flex;
      align-items: center;
      gap: 7px;
      color: ${manhattanThemeColors.darkRed};
    }

    &__empty-review-text {
      color: inherit;
    }
  }
`;
