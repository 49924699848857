import styled from 'styled-components';
import { motion } from 'framer-motion';
import { palette } from 'frontend-shared-theme';
import { theme } from 'app/theme';

interface Props {
  disabled?: boolean;
}

export const SRangeSlider = styled(motion.div)<Props>`
  padding-bottom: 22px;
  margin-top: 15px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  .range-slider {
    &__slide-container {
      width: 100%;
      background: blue;
      position: relative;
      display: flex;
      flex-direction: row;
    }
    &__track {
      margin: auto 0;
      height: 3px;
      width: 100%;
      border-radius: 1.5px;
      z-index: 1;
    }
    &__handle {
      height: 15px;
      width: 15px;
      border-radius: 7px;
      position: relative;
      background-color: white;
      z-index: 2;
      box-shadow: ${theme.shadows.default};
    }
    &__points-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 9px;
      background: red;
      position: relative;
    }
    &__point {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__indicator {
      height: 5px;
      width: 1px;
      background: #cfd2e6;
    }
    &__point-value {
      text-align: center;
    }

    &__tooltip {
      position: absolute;
      top: -42px;
      left: -27px;
      width: 70px;
      height: 34px;
      color: #fff;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 15px 7px;
      border-radius: 4px;
      background-color: ${palette.darkBlue};

      ::after {
        content: '';
        position: absolute;
        top: 34px;
        left: 28px;
        z-index: 1;
        border: solid 7px transparent;
        border-top-color: black;
      }
    }
  }
`;
