import React from 'react';
import { bem } from 'utils/bem';
import { ReusableModal } from 'common/components/reusable-modal';
import { Button } from 'common/components/button';
import { BEM_CLASS, SMediaPlanWarningModal } from './s-media-plan-not-exist-modal';
import { H3 } from 'common/components/typography';

interface Props {
  message: string;
  isOpen?: boolean;
  onClose(): void;
}

const classes = bem(BEM_CLASS);

export const MediaPlanWarningModal = React.memo(({ isOpen = false, onClose, message }: Props) => {
  return (
    <ReusableModal
      width="393px"
      isOpen={isOpen}
      hasFooter={false}
      hasHeader={false}
      onClose={onClose}
      dataSelector="media-plan-not-exist"
    >
      <SMediaPlanWarningModal>
        <H3 className={classes('title')} data-selector="access-restricted-title">
          Updated Media Plan
        </H3>
        <span className={classes('text')} data-selector="access-restricted-text">
          {message}
        </span>
        <div className={classes('footer')}>
          <Button variant="primary-cta" onClick={onClose} className={classes('button')}>
            Ok
          </Button>
        </div>
      </SMediaPlanWarningModal>
    </ReusableModal>
  );
});
