import { motion } from 'framer-motion';
import { appearingAnimation } from '../../constants';
import React from 'react';
import { STableEmptyView } from '../../s-components/table/s-table-empty-view';
import { NoResults } from '../no-results';
import { bem } from 'utils/bem';

interface Props {
  isSearching?: boolean;
  colSpan: number;
  maxWidth?: number;
  hasError?: boolean;
  noResultComponent?: React.ReactElement;
}

const classes = bem('table-empty-view');

export const TableEmptyView = React.memo(({ isSearching, colSpan, maxWidth, hasError, noResultComponent }: Props) => {
  return (
    <STableEmptyView {...appearingAnimation} maxWidth={maxWidth}>
      <motion.tr>
        <td colSpan={colSpan}>
          {noResultComponent || (
            <NoResults
              withFilters={isSearching}
              withoutFiltersImage={''}
              withoutFiltersMessage={hasError ? 'Data failed to load. Try refreshing this page.' : undefined}
              className={classes('container')}
            />
          )}
        </td>
      </motion.tr>
    </STableEmptyView>
  );
});
