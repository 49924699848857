import { Phase, IndexablePhase, Id, PhaseRequest, Optional, ProjectDetails } from 'backend-api/models';
import { MAX_DATE_MILLIS } from 'common/constants';
import { Period } from 'common/types';
import { PhaseInput } from 'common/types/phases';
import {
  getStartOfDay,
  getEndOfDay,
  addDaysToDate,
  getMinDate,
  substractDaysFromDate,
  isDateAfter,
  isSameDay,
  isValidDate,
  getDiffInMiliseconds,
} from 'common-v2/utils';

export const getIndexablePhases = (phases: Phase[]): IndexablePhase[] => {
  return phases.sort((a, b) => a.order - b.order).map((phase, index) => ({ ...phase, index: index + 1 }));
};

export const getPhaseAvailableDates = (
  phases: Phase[],
  campaignsAvailableDates: Period[],
  currentPhaseId?: Id,
  projectEndDate?: Date,
  inputPhaseStartDate?: Date,
  inputPhaseEndDate?: Date
): Period => {
  let startDate = new Date(0);
  let endDate = projectEndDate || new Date(MAX_DATE_MILLIS);

  if (phases.length === 0) {
    return [startDate, endDate];
  }

  let currentIndex = phases.findIndex(phase => phase.id === currentPhaseId);
  if (currentIndex === -1) {
    currentIndex = phases.length;
  }

  const previousPhase = phases[currentIndex - 1];
  const nextPhase = phases[currentIndex + 1];

  const afterPreviousPhaseStartDate = addDaysToDate(getStartOfDay(previousPhase?.start_date), 1);

  if (isValidDate(afterPreviousPhaseStartDate)) {
    startDate = afterPreviousPhaseStartDate;
  }

  const beforeNextPhaseStartDate = substractDaysFromDate(getEndOfDay(nextPhase?.start_date), 1);

  if (isValidDate(beforeNextPhaseStartDate)) {
    endDate = beforeNextPhaseStartDate;
  }

  const campaignsStartDates = campaignsAvailableDates.map(period => period[0]);
  const earliestCampaignStartDate =
    campaignsStartDates.length > 0 ? getEndOfDay(getMinDate(campaignsStartDates)) : undefined;

  if (!previousPhase && earliestCampaignStartDate) {
    endDate = getMinDate([endDate, earliestCampaignStartDate]);
  }

  if (projectEndDate && isDateAfter(endDate, projectEndDate)) {
    endDate = projectEndDate;
  }

  if (inputPhaseStartDate) {
    startDate = inputPhaseStartDate;
  }

  if (inputPhaseEndDate) {
    endDate = inputPhaseEndDate;
  }

  return [startDate, endDate];
};

export const preparePhaseRequest = (values: PhaseInput): PhaseRequest => ({
  name: values.name || '',
  start_date: values.start_date || new Date(),
  tracks: values.product_families?.flatMap(productFamily => productFamily.tracks).map(track => track.id) || [],
  playlists: values.playlists || [],
  objective_text: values.objective_text,
});

export const isAvailableDatesToCreatePhase = (
  phases: Optional<Phase[]>,
  project: Optional<ProjectDetails>
): boolean => {
  if (!phases || !project || phases.length === 0) {
    return false;
  }

  const lastPhase = phases.sort((current, next) => getDiffInMiliseconds(current.end_date, next.end_date))[
    phases.length - 1
  ];

  return (
    ((isSameDay(project.endDate, lastPhase.end_date) || isDateAfter(project.endDate, lastPhase.end_date)) &&
      !isSameDay(lastPhase.start_date, lastPhase.end_date)) ||
    false
  );
};
