import React, { useCallback, useEffect } from 'react';
import { SReportingContainer } from './s-login-container';
import { Login } from 'login-v2/components';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { BaseState, navigateTo } from 'utils/navigation';
import { paths } from 'app/routing/paths';
import { showWarningToast } from 'common/components/toast';
import { clearAuthToken, setAuthToken, setAuthTokenLoading } from 'login/actions';
import { tokenLoadingSelector, tokenSelector } from 'login/selectors';
import { tokenService } from 'backend-api';
import { TokenResponse } from 'backend-api/core/token-service';
import { useLocation } from 'react-router-dom';

export const LoginContainer = React.memo(() => {
  const dispatch = useDispatch();

  const isLoading = useSelector(tokenLoadingSelector);
  const token = useSelector(tokenSelector);

  const location = useLocation<BaseState>();
  const path = location.state?.from?.pathname ?? paths.projects();

  const handleSignIn = useCallback(() => {
    tokenService.login(path, () => {
      dispatch(clearAuthToken());
      showWarningToast('Cannot sign in. Please contact support.');
    });
  }, [dispatch, path]);

  useEffect(() => {
    if (Boolean(token)) {
      navigateTo(path);
    } else {
      dispatch(setAuthTokenLoading());

      tokenService
        .getToken()
        .then(({ data }: AxiosResponse<TokenResponse>) => {
          dispatch(setAuthToken({ data, currentTimestamp: Date.now() }));
          return Promise.resolve();
        })
        .catch(error => {
          dispatch(clearAuthToken());
          return Promise.reject(error);
        });
    }
  }, [token, dispatch, path]);

  return (
    <SReportingContainer>
      <Login isLoading={isLoading} onClick={handleSignIn} />
    </SReportingContainer>
  );
});
