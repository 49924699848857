import React, { useCallback } from 'react';
import { SFlex } from 'common/s-components/layout/s-flex';
import { SHeader, BEM_CLASS } from './s-header';
import { Button } from 'common/components/button';
import { SBox } from 'common/s-components/layout/s-box';
import { useSelector } from 'react-redux';
import { activeMomentSelector, processingSelector } from 'artist-moment/selectors';
import { Id } from 'backend-api/models';
import { paths } from 'app/routing/paths';
import { navigateTo } from 'utils/navigation';
import { bem } from 'utils/bem';
import { H4 } from 'common/components/typography';

interface Props {
  momentId?: Id;
  projectId: Id;
  isValid?: boolean;
  saveEditing: () => void;
}

const classes = bem(BEM_CLASS);

export const Header = React.memo(({ saveEditing, isValid, momentId, projectId }: Props) => {
  const moment = useSelector(activeMomentSelector);
  const isBeingProcessed = useSelector(processingSelector);

  const cancelEditing = useCallback(() => {
    if (momentId) {
      navigateTo(paths.momentDetails(projectId, momentId));
    } else {
      navigateTo(paths.projectReporting(projectId));
    }
  }, [momentId, projectId]);

  const isSaveDisabled = isBeingProcessed || !isValid;
  return (
    <SHeader>
      <SFlex justifyContent="space-between" alignContent="center" alignItems="center" flexWrap="nowrap">
        <H4 className={classes('title-text')}>{moment?.name || 'Create New Moment'}</H4>
        <SFlex>
          <SBox mr="20px" ml="20px">
            <Button dataSelector="discard-moment-changes-button" variant="secondary-white" onClick={cancelEditing}>
              Cancel
            </Button>
          </SBox>
          <Button
            dataSelector="save-moment-changes-button"
            variant={isSaveDisabled ? 'secondary-white' : 'primary-cta'}
            disabled={isSaveDisabled}
            onClick={saveEditing}
          >
            Save
          </Button>
        </SFlex>
      </SFlex>
    </SHeader>
  );
});
