import React, { useCallback } from 'react';
import { FiltersSelect } from 'common/components/form/filters-select';
import { FilterSelectProps, isMultiProps } from 'common/components/form/filters-select/types';
import { MenuFooter } from './components';
import { callbackWithoutPropagation } from 'utils/event';
import { MenuProps } from 'react-select';

interface InnerProps {
  isClearAllShown?: boolean;
}

type Props<T> = FilterSelectProps<T> & InnerProps;

export const ActionButtonSelect = React.memo(<T,>({ isClearAllShown = true, ...props }: Props<T>) => {
  const onApply = useCallback((onBlur, value, onApplyHandler) => {
    onBlur?.(value);
    onApplyHandler?.();
  }, []);

  const onClearAll = useCallback(onChange => {
    onChange([]);
  }, []);

  const renderMenuFooter: (props: MenuProps<T>, onApplyHandler?: () => void) => React.ReactElement = useCallback(
    (props: MenuProps<T>, onApplyHandler?: () => void) => (
      <MenuFooter
        onApply={() => onApply(props.selectProps.onBlur, props.selectProps.value, onApplyHandler)}
        onClearAll={callbackWithoutPropagation(() => onClearAll(props.selectProps.onChange))}
        isClearAllShown={isClearAllShown}
      />
    ),
    [onApply, onClearAll, isClearAllShown]
  );

  return isMultiProps(props) ? (
    <FiltersSelect {...props} renderMenuFooter={renderMenuFooter} />
  ) : (
    <FiltersSelect {...props} renderMenuFooter={renderMenuFooter} />
  );
});
