import React, { PropsWithChildren } from 'react';
import { bem } from 'utils/bem';
import { SGlobalLayout, BEM_CLASS } from './s-global-layout';
import { AppHeaderContainer } from 'app/containers';
import { AppFooter } from 'app/components/app-footer';
import { Toasts } from 'common/components/toasts';
import { Intercom } from 'app/components/intercom';
import { ErrorView, ErrorViewStates } from 'common-v2/components';
import { ErrorConfig } from './types';

const classes = bem(BEM_CLASS);

interface Props {
  errorConfig: ErrorConfig;
  className?: string;
}

export const GlobalLayout = ({ errorConfig, className = '', children }: PropsWithChildren<Props>) => (
  <SGlobalLayout className={className}>
    <AppHeaderContainer />

    <div className={classes('wrapper')}>
      {errorConfig.type === ErrorViewStates.none ? (
        children
      ) : (
        <ErrorView config={errorConfig.type} action={errorConfig.onRefreshAfterError} />
      )}
      <Intercom />
      <Toasts />
    </div>

    <AppFooter />
  </SGlobalLayout>
);
