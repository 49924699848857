import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'age-editor';

export const SAgeEditor = styled.div`
  background-color: ${colorPalette.white};

  &&& {
    .${BEM_CLASS} {
      &__age-input {
        width: 100%;
        border-radius: 0;
        text-align: end;
      }

      &__mask-age-input {
        text-align: end;
      }
    }
  }
`;
