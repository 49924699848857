import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'manage-campaigns-modal';

const CONTAINER_HEIGHT = 465;
const FOOTER_HEIGHT = 115;
const SIDEBAR_WIDTH = 317;
const DIVIDER_WIDTH = 1;

export const SManageCampaignsModal = styled.div`
  width: 880px;

  .${BEM_CLASS} {
    &__header {
      display: grid;
      grid-template-columns: 25px auto 25px;
      width: 100%;
      align-items: center;
      padding: 20px;

      &::before {
        content: '';
      }
    }

    &__title {
      text-align: center;
    }

    &__container {
      display: flex;
      flex-direction: column;
      height: ${CONTAINER_HEIGHT}px;
    }

    &__body {
      display: flex;
      height: calc(100% - ${FOOTER_HEIGHT}px);
      padding: 0 30px;
    }

    &__content {
      max-width: calc(100% - ${SIDEBAR_WIDTH + DIVIDER_WIDTH}px);
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 0 20px;
    }

    &__input-root {
      padding-bottom: 15px;
    }

    &__input {
      width: 100%;
    }

    &__divider {
      flex-shrink: 0;
      width: ${DIVIDER_WIDTH}px;
      background-color: ${transparentize(0.9, manhattanThemeColors.primaryDark)};
    }

    &__sidebar {
      flex-shrink: 0;
      width: ${SIDEBAR_WIDTH}px;
      padding: 0 20px;
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${FOOTER_HEIGHT}px;
      padding: 0 40px;
    }

    &__button {
      margin: 10px;
    }
  }
`;
