import React from 'react';
import { bem } from 'utils/bem';
import userPng from 'assets/user.png';
import { Image } from 'common-v2/components';
import { CardProps } from '../../types';
import { DEFAULT_IS_LOADING, DEFAULT_SIZE } from '../../constants';
import { ImageLoader } from './components';
import { SCardImage, BEM_CLASS } from './s-card-image';

interface CardImageProps extends CardProps {
  className?: string;
  dataSelector?: string;
  url?: string;
  placeholder?: string;
}

const classes = bem(BEM_CLASS);

export const CardImage = React.memo(
  ({
    className,
    dataSelector = 'card-image',
    url,
    placeholder = userPng,
    size = DEFAULT_SIZE,
    isLoading = DEFAULT_IS_LOADING,
  }: CardImageProps) => (
    <SCardImage className={className} width={size} data-selector={dataSelector}>
      {isLoading ? (
        <ImageLoader className={classes('loader')} />
      ) : (
        <Image className={classes('image')} imageUrl={url} alt="card-image" placeholderUrl={placeholder} />
      )}
    </SCardImage>
  )
);
