import React from 'react';
import { SStatusBadge } from './s-status-badge';
import { SingleValueView } from '../single-value-view';
import { CampaignStatuses } from 'backend-api/models';

interface StatusBadgeProps {
  className?: string;
  status: CampaignStatuses;
}

export const StatusBadge = React.memo(({ className, status }: StatusBadgeProps) => (
  <SStatusBadge className={className} status={status}>
    <SingleValueView value={status} />
  </SStatusBadge>
));
