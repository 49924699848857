import React, { useCallback } from 'react';
import { paths } from 'app/routing/paths';
import { navigateTo } from 'utils/navigation';
import { Id } from 'backend-api/models';
import { ZeroStateCard } from 'common/components/zero-state-card';
import NoOwnerSvg from 'assets/no-owner.svg';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';

interface Props {
  projectId: Id;
  className?: string;
}

export const NoOwnerCard = React.memo(({ projectId, className }: Props) => {
  const onClick = useCallback(() => {
    trackEvent(AnalyticsEvents.PROJECT_CLAIM_STARTED, { project_id: projectId });
    navigateTo(paths.editProject(projectId));
  }, [projectId]);

  return (
    <ZeroStateCard
      title="Claim This Project"
      subtitle="Become Project Editor and start planning."
      buttonChildren="Claim"
      onButtonClick={onClick}
      imageSrc={NoOwnerSvg}
      className={className}
    />
  );
});
