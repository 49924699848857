import React from 'react';
import { useSelector } from 'react-redux';
import { SplitClient, SplitTreatments } from '@splitsoftware/splitio-react';
import { Routing } from 'app/routing';
import { AppLoader } from 'app/components/app-loader';
import { labelsSelector, userInfoSelector } from 'common/selectors';
import { getLabelsIds } from 'common-v2/transducers';
import { FEATURE_FLAGS } from 'common-v2/constants';
import { FeatureFlagsContextProvider } from 'common-v2/context';

export const AppContainer = React.memo(() => {
  const { data: user } = useSelector(userInfoSelector);
  const { data: labels } = useSelector(labelsSelector);

  return (
    <SplitClient splitKey={user?.email} trafficType="user" attributes={{ labelIds: getLabelsIds(labels ?? []) }}>
      <SplitTreatments names={Object.values(FEATURE_FLAGS)}>
        {({ isReady, treatments }) =>
          isReady ? (
            <FeatureFlagsContextProvider treatments={treatments}>
              <Routing />
            </FeatureFlagsContextProvider>
          ) : (
            <AppLoader />
          )
        }
      </SplitTreatments>
    </SplitClient>
  );
});
