import { SLoaderItem } from './s-loader-item';
import React from 'react';
import { bem } from 'utils/bem';

const classes = bem('loader-item');

interface Props {
  className?: string;
}

export const LoaderItem = ({ className }: Props) => {
  return (
    <SLoaderItem className={className}>
      <div className={classes('clip-container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id="loader-item">
            <path
              d={`M0 0 60 0 60 60 0 60 0 0z
                  M80 10 200 10 200 24 80 24 80 10z
                  M80 30 300 30 300 44 80 44 80 30z`}
            />
          </clipPath>
        </defs>
      </svg>
    </SLoaderItem>
  );
};
