import React from 'react';
import { components, MultiValueGenericProps } from 'react-select';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { getBaseCustomSelectProps } from '../../transducers';
import { BaseOption as BaseOptionType } from '../../types';
import { SMultiValueLabel, BEM_CLASS } from './s-multi-value-label';

type Props<T extends BaseOptionType> = MultiValueGenericProps<T, true>;

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType>(props: Props<T>) => {
  const { classNames, dataSelector } = getBaseCustomSelectProps(props.selectProps);

  return (
    <SMultiValueLabel className={classNames.multiValueLabel} data-selector={`${dataSelector}-multi-value-label`}>
      <components.MultiValueLabel {...props}>
        {typeof props.children === 'string' ? (
          <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('text')} hasPadding={false}>
            {props.children}
          </Typography>
        ) : (
          props.children
        )}
      </components.MultiValueLabel>
    </SMultiValueLabel>
  );
};

export const MultiValueLabel = React.memo(Root) as typeof Root;
