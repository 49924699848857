import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const BEM_CLASS = 'permissions';

export const SPermissions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  color: ${palette.black};
  font-size: 14px;
  line-height: 25px;

  .${BEM_CLASS} {
    &__image {
      margin-bottom: 20px;
    }

    &__main-text {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__text {
      & b {
        font-weight: 600;
      }
    }
  }
`;
