import { CampaignSources } from 'backend-api/models';
import { IconName } from 'common/components/icon/types';

export const getSourceIconName = (source: CampaignSources): IconName => {
  switch (source) {
    case CampaignSources.FACEBOOK:
      return 'facebook';
    case CampaignSources.GOOGLE:
      return 'google';
    default:
      return 'unknown';
  }
};
