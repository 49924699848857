import { FreeTextParam } from '../../types';

export * from './guards';

export const AD_SETS_SORT_FIELDS = ['id', 'name', 'spend', 'remainingDays', 'startDate', 'endDate'] as const;

export type AdSetsSortField = typeof AD_SETS_SORT_FIELDS[number];
export interface AdSetsFilterParams extends FreeTextParam {
  readonly offset?: number;
  readonly limit?: number;
  readonly sort?: string;
}
