import styled from 'styled-components';
import { MODAL_SPACING } from '../../constants';

export const SModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${MODAL_SPACING}px;
  flex-shrink: 0;

  .modal-footer {
    &__button {
      &:not(:last-child) {
        margin-right: ${MODAL_SPACING}px;
      }
    }
  }
`;
