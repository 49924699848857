import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReusableModal } from 'common/components/reusable-modal';
import { ModalContent } from '../components';
import { closeModal } from '../actions';
import { permissionErrorModalOpenedSelector } from '../selectors';

export const PermissionErrorModalContainer = React.memo(() => {
  const dispatch = useDispatch();

  const isModalOpened = useSelector(permissionErrorModalOpenedSelector);

  const handleClose = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const handleAfterClose = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <ReusableModal
      width="390px"
      isOpen={isModalOpened}
      hasFooter={false}
      title="Access Restricted"
      onClose={handleClose}
      onAfterClose={handleAfterClose}
      dataSelector="access-restricted"
    >
      <ModalContent onConfirm={handleClose} />
    </ReusableModal>
  );
});
