import styled from 'styled-components';

export const BEM_CLASS = 'media-plans-dropdown';

interface SMediaPlansDropdownProps {
  disabled?: boolean;
}

export const SMediaPlansDropdown = styled.div<SMediaPlansDropdownProps>`
  display: flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  .${BEM_CLASS} {
    &__trigger {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-left: 10px;
    }

    &__edit-trigger {
      margin-left: 10px;
    }

    &__icon {
      display: flex;
    }
  }
`;
