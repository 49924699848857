import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArtistSearch } from 'backend-api/models';
import { bem } from 'utils/bem';
import { paths } from 'app/routing/paths';
import { ResultTable } from '../result-table';
import { SArtistsResult, BEM_CLASS } from './s-artists-result';
import { TargetsImages } from 'common/components/targets-images';
import { TooltipedText } from 'common/components/tooltiped-text';
import { hideGlobalSearch } from 'common/actions';

const classes = bem(BEM_CLASS);

interface ArtistsResultProps {
  artists: ArtistSearch[];
}

export const ArtistsResult = React.memo(({ artists }: ArtistsResultProps) => {
  const dispatch = useDispatch();

  const onLinkClick = useCallback(() => {
    dispatch(hideGlobalSearch());
  }, [dispatch]);

  const tableConfig = useMemo(
    () => [
      {
        title: '',
        fraction: 'max-content',
        render: (artist: ArtistSearch) => (
          <span className={classes('cell')}>
            <TargetsImages targets={artist} className={classes('avatar')} gap />
          </span>
        ),
      },
      {
        title: 'Name',
        fraction: 'auto',
        render: (artist: ArtistSearch) => (
          <Link
            to={`${paths.artist(artist.id)}?fromSearch=1`}
            className={classes('cell', 'name')}
            onClick={onLinkClick}
            data-selector="search-result-artist-item"
          >
            <TooltipedText text={artist.name}>
              <span>{artist.name}</span>
            </TooltipedText>
          </Link>
        ),
      },
      {
        title: 'Projects',
        fraction: 'max-content',
        render: (artist: ArtistSearch) => <span className={classes('cell', 'count')}>{artist.projectsCount}</span>,
      },
    ],
    [onLinkClick]
  );

  return (
    <SArtistsResult>
      <ResultTable config={tableConfig} data={artists} />
    </SArtistsResult>
  );
});
