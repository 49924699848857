import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { bem } from 'utils/bem';
import { Id, MediaPlanListItem, Optional } from 'backend-api/models';
import {
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  Input,
  INPUT_VARIANT,
  THEME,
  Tooltip,
  Typography,
  TYPOGRAPHY_TYPE,
} from 'gdb-web-shared-components';
import { LoadingState } from 'common/types';
import { callbackWithoutPropagation } from 'utils/event';
import { getFooterTooltip, validateNewMediaPlan } from './transducers';
import { Loader } from './components';
import { MAX_MEDIA_PLANS_COUNT, MEDIA_PLAN_NAME_LIMIT } from './constants';
import { Mode } from './types';
import { BEM_CLASS, SPopup } from './s-popup';

interface Props {
  mediaPlans: MediaPlanListItem[];
  mediaPlansLoading: LoadingState;
  mediaPlanId?: Id;
  onItemClick?(mediaPlanId: Id): void;
  onDelete(mediaPlanId: Id): void;
  onCreateNew(name: string): void;
  onEdit(mediaPlanId: Id, name: string): void;
  onRefresh(): void;
  loading?: boolean;
  visible?: boolean;
  editable?: boolean;
}

export const classes = bem(BEM_CLASS);

export const Popup = React.memo(
  ({
    mediaPlans,
    mediaPlansLoading,
    mediaPlanId,
    onDelete,
    onCreateNew,
    onEdit,
    onItemClick,
    onRefresh,
    loading,
    editable,
    visible,
  }: Props) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const [editingItemId, setEditingId] = useState<Optional<Id>>();
    const [mode, setMode] = useState<Mode>(Mode.VIEW);
    const [inputValue, setInputValue] = useState<Optional<string>>();
    const [error, setError] = useState<Optional<string>>();

    useEffect(() => {
      if (!visible) {
        setEditingId(undefined);
        setInputValue('');
        setError(undefined);
        setMode(Mode.VIEW);
      }
    }, [visible]);

    useEffect(() => {
      if (!inputValue) {
        setError(undefined);
      }
    }, [inputValue]);

    useEffect(() => {
      if (!editingItemId) return;
      const editingMediaPlan = mediaPlans.find(item => item.id === editingItemId);
      if (!editingMediaPlan) return;
      setInputValue(editingMediaPlan.name);
    }, [editingItemId, mediaPlans]);

    useEffect(() => {
      if (error && mode === Mode.EDIT) {
        inputRef?.current?.scrollIntoView({ block: 'nearest', inline: 'nearest', behavior: 'smooth' });
      }
    }, [error, mode]);

    const onEditClick = useCallback((id?: Id) => {
      setInputValue('');
      setEditingId(id);
      setMode(Mode.EDIT);
    }, []);

    const onCancel = useCallback(() => {
      setInputValue('');
      setEditingId(undefined);
      setMode(Mode.VIEW);
    }, []);

    const onEditConfirm = useCallback(() => {
      const trimmedValue = inputValue?.trim();
      const mediaPlanError = validateNewMediaPlan(trimmedValue, editingItemId, mediaPlans);
      if (mediaPlanError || !trimmedValue) {
        setError(mediaPlanError);
        return;
      }

      if (!editingItemId) {
        onCreateNew(trimmedValue);
      } else {
        onEdit(editingItemId, trimmedValue);
      }

      setEditingId(undefined);
      setMode(Mode.VIEW);
    }, [editingItemId, inputValue, mediaPlans, onCreateNew, onEdit]);

    const onAddClick = useCallback(() => {
      setInputValue('');
      setEditingId(undefined);
      setMode(Mode.CREATE);
    }, []);

    const onDeleteClick = useCallback(
      (id?: Id) => {
        if (!id) return;
        onDelete(id);
        onCancel();
      },
      [onCancel, onDelete]
    );

    const renderControls = useCallback(
      (itemId?: Id, newItem?: boolean) => {
        const isInputEmpty = inputValue?.trim().length === 0;

        const buttons = (
          <>
            <Button
              icon="check"
              type={BUTTON_TYPE.tertiary}
              size={BUTTON_SIZE.smallRound}
              theme={THEME.light}
              disabled={isInputEmpty}
              data-selector="save-button"
              onClick={callbackWithoutPropagation(onEditConfirm)}
            />

            <Button
              icon="cross"
              type={BUTTON_TYPE.tertiary}
              size={BUTTON_SIZE.smallRound}
              theme={THEME.light}
              data-selector="cancel-button"
              onClick={callbackWithoutPropagation(onCancel)}
            />
          </>
        );

        switch (mode) {
          case Mode.EDIT:
            return itemId === editingItemId ? buttons : null;
          case Mode.CREATE:
            return newItem ? buttons : null;
          default:
            return (
              <>
                <Button
                  icon="edit"
                  type={BUTTON_TYPE.tertiary}
                  size={BUTTON_SIZE.smallRound}
                  theme={THEME.light}
                  data-selector="edit-button"
                  onClick={callbackWithoutPropagation(() => onEditClick(itemId))}
                />

                <Button
                  icon="delete"
                  type={BUTTON_TYPE.tertiary}
                  size={BUTTON_SIZE.smallRound}
                  theme={THEME.light}
                  disabled={mediaPlans.length === 1}
                  data-selector="delete-button"
                  onClick={callbackWithoutPropagation(() => onDeleteClick(itemId))}
                />
              </>
            );
        }
      },
      [editingItemId, inputValue, mediaPlans.length, mode, onCancel, onDeleteClick, onEditClick, onEditConfirm]
    );

    const input = useMemo(
      () => (
        <div className={classes('input-container')} data-selector="input-field">
          <Input
            ref={inputRef}
            theme={THEME.light}
            variant={INPUT_VARIANT.compact}
            onChange={event => setInputValue(event.target.value)}
            value={inputValue}
            inputClassName={classes('input')}
            maxLength={MEDIA_PLAN_NAME_LIMIT}
            autoFocus
            required
            error={error}
            placeholder="Type Media Plan Name"
          />
        </div>
      ),
      [error, inputValue]
    );

    const isAddDisabled = mediaPlans.length === MAX_MEDIA_PLANS_COUNT;
    const editing = mode === Mode.EDIT;

    if (mediaPlansLoading === LoadingState.Failed) {
      return (
        <SPopup data-selector="media-plans-dropdown">
          <div className={classes('error')}>
            <Typography className={classes('error-text')} type={TYPOGRAPHY_TYPE.body4}>
              An error occurred while loading Media Plan
              <br />
              data, please try again.
            </Typography>

            <button className={classes('error-button')} onClick={onRefresh}>
              <Typography type={TYPOGRAPHY_TYPE.body2}>Refresh</Typography>
            </button>
          </div>
        </SPopup>
      );
    }

    return (
      <SPopup data-selector="media-plans-dropdown">
        {loading ? (
          <Loader className={classes('loader')} />
        ) : (
          <>
            <div className={classes('list')}>
              {mediaPlans.map(item => {
                const isSelected = mediaPlanId === item.id;

                return (
                  <button
                    key={item.id}
                    className={classes('item', { editing, selected: isSelected && editingItemId !== item.id })}
                    data-selector="media-plan-list-item"
                    onClick={() => {
                      if (!editing) {
                        onItemClick?.(item.id);
                      }
                    }}
                  >
                    <Typography
                      className={classes('name')}
                      type={isSelected ? TYPOGRAPHY_TYPE.body1 : TYPOGRAPHY_TYPE.body2}
                      hasPadding={false}
                    >
                      {editing && item.id === editingItemId ? input : item.name}
                    </Typography>

                    {editable && <div className={classes('controls-container')}>{renderControls(item.id)}</div>}
                  </button>
                );
              })}
            </div>

            {mode === Mode.CREATE && (
              <div key="new-item" className={classes('new-item')}>
                {input}

                <div className={classes('controls-container')}>{renderControls(undefined, true)}</div>
              </div>
            )}

            {mode !== Mode.CREATE && editable && (
              <div className={classes('footer')}>
                <Tooltip isDisabled={!isAddDisabled} tooltip={getFooterTooltip(mediaPlans, editable)}>
                  <Button
                    type={BUTTON_TYPE.tertiary}
                    size={BUTTON_SIZE.small}
                    caption="Add new"
                    theme={THEME.light}
                    onClick={onAddClick}
                    data-selector="add-new-media-plan"
                    disabled={isAddDisabled}
                  />
                </Tooltip>
              </div>
            )}
          </>
        )}
      </SPopup>
    );
  }
);
