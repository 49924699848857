import { LocalRole, RestrictionCode } from 'common-v2/types';

export const ROLES: Record<string, LocalRole> = {
  ADMIN: { name: 'Admin', id: 0, icon: 'edit', weight: 5 },
  EDITOR: {
    name: 'Editor',
    id: 1,
    icon: 'edit',
    weight: 4,
    description:
      'Editors have permission to edit their project’s phases and edit all campaigns in the categories they are assigned.',
  },
  LABELMATE: { name: 'Labelmate', id: 2, icon: 'shared', weight: 3 },
  VIEWER: {
    name: 'Viewer',
    id: 3,
    icon: 'view',
    weight: 2,
    description:
      'Viewers have permission to see campaigns, reports, and metrics for this project. All labelmates are given viewer permission to all projects in their label space by default.',
  },
  APPROVER: { name: 'Approver', id: 4, weight: 1, icon: 'edit' },
};

export const ACCESS_PERMISSIONS = {
  EDIT_PROJECT: [ROLES.ADMIN, ROLES.EDITOR],
  SHARE_PROJECT: [ROLES.ADMIN, ROLES.EDITOR],
  DELETE_PROJECT: [ROLES.ADMIN, ROLES.EDITOR],
  VIEW: [ROLES.ADMIN, ROLES.EDITOR, ROLES.VIEWER, ROLES.LABELMATE],
  CREATE_CAMPAIGN: [ROLES.ADMIN, ROLES.EDITOR],
  REMOVE_CAMPAIGN: [ROLES.ADMIN, ROLES.EDITOR],
  EDIT_PHASE: [ROLES.ADMIN, ROLES.EDITOR],
  CHANGE_CAMPAIGN_STATUS: [ROLES.ADMIN, ROLES.EDITOR],
  EDIT_MEDIA_PLAN: [ROLES.ADMIN, ROLES.EDITOR],
  EDIT_REPORTING: [ROLES.ADMIN, ROLES.EDITOR],
};

export const RESTRICTION_CODES = [
  RestrictionCode.ProjectEditRestricted,
  RestrictionCode.CampaignCreationRestricted,
  RestrictionCode.AssignCampaignRestricted,
  RestrictionCode.TeamEditRestricted,
];

export const PROJECT_ACCESS_RESTRICTED_CODES = [
  RestrictionCode.ProjectAccessRestricted,
  RestrictionCode.UnclaimedProjectAccessRestricted,
];
