import { ListProps } from 'common/components/multiple-lists-drag-n-drop';
import { SectionTitle } from './components';
import React from 'react';
import { DraggableSectionConfig, ModelWithId } from './types';

export const getDragNDropConfigFromProps = <T extends ModelWithId>(
  sectionsConfigs: DraggableSectionConfig<T>[],
  disabled?: boolean
): ListProps<T>[] =>
  sectionsConfigs.map(
    ({
      id,
      title,
      renderItem,
      selectedItems,
      placeholder,
      dropOutsideEnabled,
      draggable,
      maxCount,
      withCounter,
      containerItemClassName,
      warningText,
      isShuffleEnabled: isShuffleEnabled = true,
    }) => ({
      id,
      title: title && (
        <SectionTitle
          title={title}
          currentCount={selectedItems.length}
          maxCount={maxCount}
          withCounter={withCounter}
          warningText={warningText}
        />
      ),
      sourceArray: selectedItems,
      placeholder,
      isDropOutsideDisabled: !dropOutsideEnabled,
      renderItem,
      isDragDisabled: !draggable || disabled,
      containerItemClassName,
      isShuffleEnabled,
    })
  );
