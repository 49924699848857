import React from 'react';
import { bem } from 'utils/bem';
import { SFlex } from 'common/s-components/layout/s-flex';
import { EditButton } from '../edit-button';
import { SHeader, BEM_CLASS } from 'artist-moment/components/form/components/header/s-header';
import { MomentData } from 'artist-moment/types';

const classes = bem(BEM_CLASS);

export const Header = React.memo(({ project, moment }: MomentData) => (
  <SHeader>
    <SFlex justifyContent="space-between" alignContent="center" alignItems="center" flexWrap="nowrap">
      <div className={classes('title-text')} data-selector="artist-moment-title">
        {moment.name}
      </div>
      <EditButton project={project} moment={moment} />
    </SFlex>
  </SHeader>
));
