import { Dimension, Position } from './types';
import { BORDER_WIDTH } from './constants';

const getTextAreaTopPosition = (rootRect: DOMRect, elementDimension: Dimension, window: Window): number => {
  const borderOffset = BORDER_WIDTH * 2;
  const windowScrollY = window.scrollY;

  return rootRect.top + borderOffset + elementDimension.height > window.innerHeight
    ? rootRect.top + borderOffset + rootRect.height - elementDimension.height + windowScrollY
    : rootRect.top - borderOffset + windowScrollY;
};

const getTextAreaLeftPosition = (rootRect: DOMRect, elementDimension: Dimension, window: Window): number => {
  const borderOffset = BORDER_WIDTH * 2;
  const windowScrollX = window.scrollX;
  const windowInnerWidth = window.innerWidth;

  let left = rootRect.left - borderOffset + windowScrollX;

  const isOverflownX = rootRect.left + borderOffset + elementDimension.width > windowInnerWidth;

  if (isOverflownX) {
    left =
      rootRect.left + borderOffset + rootRect.width + windowScrollX > windowInnerWidth
        ? windowInnerWidth - elementDimension.width
        : rootRect.left + borderOffset + rootRect.width - elementDimension.width + windowScrollX;
  }

  return left;
};

export const getTextareaPosition = (rootRect: DOMRect, elementDimension: Dimension, window: Window): Position => {
  const top = getTextAreaTopPosition(rootRect, elementDimension, window);

  const left = getTextAreaLeftPosition(rootRect, elementDimension, window);

  return { top, left };
};
