import React from 'react';
import { BEM_CLASS, SEmptyState } from './s-empty-state';
import NoResultsGray from 'assets/no-results-gray.svg';
import { SmallText } from 'common/components/typography';
import { bem } from 'utils/bem';

const classes = bem(BEM_CLASS);

export const EmptyState = React.memo(() => {
  return (
    <SEmptyState>
      <img alt="empty-state" src={NoResultsGray} className={classes('image')} />
      <SmallText>
        No results to show.
        <br /> Try another search.
      </SmallText>
    </SEmptyState>
  );
});
