import styled from 'styled-components';
import { TABLE_NOTIFICATION_HEIGHT, TABLE_HEADER_HEIGHT } from 'common/components/table/constants';
import { TableTheme } from 'app/theme/table';
import { colorPalette, themeColors } from 'app/theme/colors';

interface Props {
  tableTheme: TableTheme;
  withoutRoundCorners?: boolean;
  isNotificationVisible: boolean;
}

export const SHeader = styled.thead<Props>`
  ${({ isNotificationVisible }) =>
    isNotificationVisible &&
    `
      &::after {
        content: '-';
        display: block;
        height: ${TABLE_NOTIFICATION_HEIGHT}px;
        text-indent: -99999px;
      }
    `};

  .header {
    &__column {
      height: ${TABLE_HEADER_HEIGHT}px;
      position: sticky;
      top: 0;
      z-index: 11;
      box-shadow: inset 0 -1px 0 ${colorPalette.lavenderGray};

      background: ${({ tableTheme, theme }) => theme.table[tableTheme].headerColumnBackground};

      &--sorted {
        background: ${colorPalette.snuff};
      }

      &--checkbox {
        background: ${({ tableTheme, theme }) => theme.table[tableTheme].headerColumnBackground};
        width: 45px;
        padding: 9px 0 5px 16px !important;

        &:hover {
          cursor: default;
        }
      }

      &--expand {
        width: 50px;
        background: ${({ tableTheme, theme }) => theme.table[tableTheme].headerColumnBackground};
      }

      &:hover {
        background: ${colorPalette.snuff};
        cursor: pointer;
      }
    }

    &__row {
      transition: none;
      background: ${colorPalette.athensGray};

      & > :not(:first-child) {
        padding: 0 10px;
      }

      & > :first-child {
        padding: 0 10px 0 20px;
        border-radius: ${({ withoutRoundCorners }) => (withoutRoundCorners ? 'unset' : '10px 0 0 0')};
        overflow: hidden;
      }

      & > :last-child {
        padding: 0 20px 0 10px;
        border-radius: ${({ withoutRoundCorners }) => (withoutRoundCorners ? 'unset' : '0 10px 0 0')};
        overflow: hidden;
      }
    }

    &__sub-title {
      height: 15px;
      color: ${themeColors.basicTextColor};
      font-size: 10px;
      letter-spacing: 0;
      line-height: 15px;
      text-align: right;
    }

    &__cell-container {
      display: flex;
      flex-direction: row;
    }

    &__title-container {
      display: flex;
      flex-direction: column;
    }

    &__icon-container {
      margin-bottom: 7px;
      margin-left: 9px;
      width: 6px;
      height: 6px;
      display: flex;
    }

    &__title {
      color: ${colorPalette.kimberley};
      font-size: 12px;
      line-height: 15px;
      font-weight: bold;
      position: relative;
      cursor: pointer;
      top: 2px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &--sorted {
        color: ${colorPalette.vulcan};
      }
    }
  }
`;
