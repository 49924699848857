import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { transparentize } from 'polished';
import { formFooter } from 'app/styles/z-indexes';
import { PAGE_WIDTH, SCROLLBAR_WIDTH } from 'common/constants';

const BEM_CLASS = 'form-footer';
const CONTAINER_OFFSET = `calc((100vw - ${SCROLLBAR_WIDTH}px - 100%) / 2)`;
const NEGATIVE_CONTAINER_OFFSET = `calc((-100vw + 100% - ${SCROLLBAR_WIDTH}px) / 2)`;
const NEGATIVE_SMALL_CONTAINER_OFFSET = `calc((100% - ${PAGE_WIDTH}px) / 2)`;

interface SFormFooterProps {
  isSticky: boolean;
}

export const SFormFooter = styled.div<SFormFooterProps>`
  position: sticky;
  bottom: -1px;
  padding: 13px 0 14px;
  width: 100vw;
  margin-left: ${NEGATIVE_CONTAINER_OFFSET};
  padding-left: ${CONTAINER_OFFSET};
  padding-right: ${CONTAINER_OFFSET};
  z-index: ${formFooter};

  transition: background-color 0.2s, box-shadow 0.2s;
  @media (max-width: ${PAGE_WIDTH}px) {
    width: ${PAGE_WIDTH}px;
    margin-left: ${NEGATIVE_SMALL_CONTAINER_OFFSET};
    padding: 13px 20px 14px;
  }

  ${({ isSticky }) =>
    isSticky &&
    `
    background-color: ${palette.athensGrayTwo};
    box-shadow: 0 0 10px ${transparentize(0.5, palette.darkBlueFive)};
    `}

  .${BEM_CLASS} {
    &__title {
      color: black;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
`;
