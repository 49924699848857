import { Id, LabelUser } from 'backend-api/models';

interface EmailParams {
  projectId?: Id;
  projectName?: string;
  email: string;
  currentUser?: LabelUser;
}

export const sendEmail = ({ projectId, projectName, email, currentUser }: EmailParams) => {
  const subject = '[Decibel] Project Access Requested';
  const accessLink = projectId && `${window.origin}/projects/${projectId}`;
  let body = `${currentUser?.name ?? 'User'} requested access to the project ${projectName ?? ''}.`;

  if (accessLink) {
    body += ` Click the link to grant access ${accessLink}`;
  }

  window.open(`mailto:${email}?subject=${subject}&body=${body}`);
};
