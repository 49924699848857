import React, { useCallback, useEffect } from 'react';
import { UserSettings } from 'settings/components/user-settings';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPhone, updateCurrentUser } from 'settings/actions';
import { currentPhoneSelector } from 'settings/selectors';
import { dateTimeFormatSelector } from 'common/selectors';
import { setDateFormat } from 'common/actions';
import { convertFromDateFormatToId } from 'common-v2/transducers';
import { DATE_FORMAT_BY_DATE_TIME_FORMAT } from 'settings/constants';
import { useAppContext } from 'common-v2/hooks';

export const UserSettingsContainer = React.memo(() => {
  const dispatch = useDispatch();
  const { user: userInfo, labels } = useAppContext();

  const currentPhone = useSelector(currentPhoneSelector);
  const dateFormat = useSelector(dateTimeFormatSelector);

  const onPhoneBlur = useCallback(
    (phone: string) => {
      const dateTimeFormatId = convertFromDateFormatToId(dateFormat);
      if (!dateTimeFormatId) return;

      dispatch(updateCurrentUser.request({ phone, dateTimeFormat: dateTimeFormatId }));
    },
    [dispatch, dateFormat]
  );

  const onDateFormatChange = useCallback(
    (dateTimeFormat: string) => {
      const dateFormat = DATE_FORMAT_BY_DATE_TIME_FORMAT[dateTimeFormat];
      dispatch(setDateFormat({ dateTimeFormat, dateFormat }));

      const dateTimeFormatId = convertFromDateFormatToId(dateTimeFormat);
      if (!dateTimeFormatId) return;

      dispatch(updateCurrentUser.request({ phone: currentPhone, dateTimeFormat: dateTimeFormatId }));
    },
    [dispatch, currentPhone]
  );

  useEffect(() => {
    if (!userInfo) return;
    dispatch(setCurrentPhone(userInfo.phone));
  }, [userInfo, dispatch]);

  return (
    <UserSettings
      userInfo={userInfo}
      labels={labels}
      onPhoneBlur={onPhoneBlur}
      phone={currentPhone}
      dateFormat={dateFormat}
      onDateFormatChange={onDateFormatChange}
    />
  );
});
