import styled from 'styled-components';

export const BEM_CLASS = 'user-settings';

export const SUserSettings = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr;
  grid-template-areas:
    'profile-info preferences'
    'teams teams';
  column-gap: 20px;
  margin: 35px 0 0 0;
  padding: 0 93px;

  .${BEM_CLASS} {
    &__profile-info {
      grid-area: profile-info;
    }
    &__prefernces {
      grid-area: preferences;
    }
  }
`;
