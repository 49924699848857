import React from 'react';
import { bem } from 'utils/bem';
import { SPieChartLoader } from './s-loader-pie-chart';

const rectangleClasses = bem('loader-pie-chart');
const CLIP_PATH_ID = 'loader-pie-chart';

interface Props {
  className?: string;
}

export const PieChartLoader = React.memo(({ className }: Props) => {
  return (
    <SPieChartLoader className={className} clipPathId={CLIP_PATH_ID}>
      <div className={rectangleClasses('clip-container')} />
      <svg width="130" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <path d="M 65 0 A 65 65 0 1 0 65 130 A 65 65 0 1 0 65 0 Z M 65 15 A 50 50 0 1 1 65 115 A 50 50 0 1 1 65 15 Z" />
          </clipPath>
        </defs>
      </svg>
    </SPieChartLoader>
  );
});
