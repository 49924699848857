import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { theme } from 'app/theme';

const CARD_HEIGHT = 70;
const CARD_DEFAULT_WIDTH = 310;
const BORDER_WIDTH = 1;

interface Props {
  shouldCardStretch?: boolean;
}

export const SArtistPlaylistCard = styled.div<Props>`
  .artist-playlist-card {
    &__container {
      height: ${CARD_HEIGHT}px;
      width: ${({ shouldCardStretch }) => (shouldCardStretch ? 'auto' : `${CARD_DEFAULT_WIDTH}px`)};
      border-radius: 6px;
      background-color: ${palette.athensGrayTwo};
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: box-shadow 0.3s ease-in-out, background 0.3s ease-in-out;

      &:hover {
        .artist-playlist-card__apollo-icon {
          opacity: 1;
        }
      }

      & > * {
        cursor: default;
      }

      &--selected {
        height: ${CARD_HEIGHT + BORDER_WIDTH * 2}px;
        padding: ${BORDER_WIDTH}px 0;
        box-sizing: border-box;
        box-shadow: ${theme.shadows.default};
        border: 1px solid ${palette.brightBlue};
        background-color: white;
      }

      &--link {
        &:hover {
          background-color: ${palette.white};
          box-shadow: ${theme.shadows.default};
        }
      }
    }

    &__image {
      width: ${CARD_HEIGHT}px;
      min-width: ${CARD_HEIGHT}px;
      height: ${CARD_HEIGHT}px;
      border-radius: 5px 0px 0px 5px;
      cursor: pointer;
    }

    &__text-container {
      display: flex;
      flex-direction: column;
      margin: 0 20px;
      min-width: 0;
    }

    &__top-text-container {
      display: flex;
      align-items: center;
    }

    &__title {
      color: black;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      ${elipsizeText}
    }

    &__badge-container {
      margin-left: 10px;
    }

    &__bottom-text-container {
      display: flex;
      align-items: center;
      margin-top: 1px;
    }

    &__subtitle {
      color: ${palette.darkBlueTwo};
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      text-transform: capitalize;

      &--owner {
        ${elipsizeText}
      }
    }

    &__circle {
      height: 4px;
      width: 4px;
      min-width: 4px;
      border-radius: 2px;
      background-color: ${palette.lavanderGray};
      margin: 0 8px;
    }

    &__actions-icon {
      margin-right: 9px;
    }

    &__lock-icon {
      display: flex;
      margin-right: 15px;
    }

    &__apollo-icon {
      width: 20px;
      min-width: 20px;
      margin-right: 9px;
      cursor: pointer;
      opacity: 0;
      transition: opacity 300ms ease-out;
    }
  }
`;
