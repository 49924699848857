import React, { SyntheticEvent } from 'react';
import { camelCase, startCase } from 'lodash';
import * as SIcons from './s-icons';
import { IconName, IconColor, IconSize } from './types';

interface Props {
  name: IconName;
  color?: IconColor;
  size?: IconSize;
  style?: React.CSSProperties;
  onClick?(event: SyntheticEvent): void;
  dataSelector?: string;
  className?: string;
}

export const Icon = React.memo(
  ({ name, color, size = 'glyph', style, className, onClick, dataSelector = name }: Props) => {
    // eslint-disable-next-line import/namespace
    const SIcon = SIcons[`S${startCase(camelCase(name)).replace(/ /g, '')}`];

    if (!SIcon) return null;

    return (
      <SIcon
        size={size}
        color={color || null}
        onClick={onClick}
        style={style}
        data-selector={`${dataSelector}-icon`}
        className={className}
      />
    );
  }
);

export const ICON_DISPLAY_NAME = 'Icon';

Icon.displayName = ICON_DISPLAY_NAME;
