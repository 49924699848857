import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';
import { SDownCss, SUpCss } from 'common/components/icon/s-icons';
import { select } from 'app/styles/z-indexes';
import { BgStyle, bgStyle, inputBorderColor } from 'common/styles/utils';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { theme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';
import {
  DIVIDER_HEIGHT,
  DIVIDER_VERTICAL_MARGIN,
  MAX_MENU_HEIGHT,
  OPTION_HEIGHT,
  OPTION_HORIZONTAL_PADDING,
  OPTION_VERTICAL_PADDING,
} from './constants';
import { SelectComponentsProps, Alignment } from './types';

export const BEM_CLASS = 'react-select';

const INITIAL_CONTROL_PADDING_LEFT = 20;
const INITIAL_CONTROL_PADDING_RIGHT = 10;

export interface Props {
  horizontalAlignment?: Alignment;
  isRequired?: boolean;
  isInvalid?: boolean;
  menuWidthFromChildren?: boolean;
  paddings?: {
    vertical: number;
    horizontal: number;
  };
  disabled?: boolean;
  bgStyle?: BgStyle;
}

export const SelectCss = css<Props & SelectComponentsProps>`
  line-height: 1;
  cursor: pointer;

  .${BEM_CLASS} {
    &__tree {
      overflow: auto;
      max-height: ${MAX_MENU_HEIGHT}px;
      padding-bottom: 11px;
    }
    &__control {
      padding-top: ${({ paddings }) => paddings?.vertical}px;
      padding-bottom: ${({ paddings }) => paddings?.vertical}px;
      padding-left: ${({ paddings }) =>
        paddings ? paddings.horizontal + INITIAL_CONTROL_PADDING_LEFT : INITIAL_CONTROL_PADDING_LEFT}px;
      border-radius: 20px;
      background: ${bgStyle};
      padding-right: ${({ paddings }) =>
        paddings ? paddings.horizontal + INITIAL_CONTROL_PADDING_RIGHT : INITIAL_CONTROL_PADDING_RIGHT}px;
      border-color: ${inputBorderColor()};
      min-height: 35px;
      cursor: pointer;
      align-content: center;
    }

    &__value-container {
      display: flex;
      flex-wrap: nowrap;
      padding-left: 0;
      margin-left: -2px;
    }

    &__control--is-focused {
      box-shadow: none;
    }
    &__indicator-separator {
      display: none;
    }
    &__indicators {
      .${BEM_CLASS}__dropdown-indicator {
        padding: 0;
        position: absolute;
        right: 17px;
        align-self: flex-start;
        top: 11px;
      }
    }

    &__single-value {
      ${elipsizeText};
      padding-right: 10px;
      margin-left: 0;
      margin-right: 0;
      top: calc(50% - 1px);
      font-weight: 400;
      color: ${({ disabled, theme }) =>
        disabled ? theme.colors.input.text.disabled : theme.colors.input.text.regular};
      line-height: 21px;
    }

    &__placeholder {
      color: ${colorPalette.eastBay};
    }

    &__multi-value {
      background: ${colorPalette.eastBay};
      height: 20px;
      max-width: 130px;
      display: flex;
      align-items: center;
      &__label {
        color: white;
        font-size: 12px;
        font-weight: bold;
        line-height: 25px;
      }
      &__remove {
        color: white;
        &:hover {
          background: ${colorPalette.eastBay};
          opacity: 0.5;
        }
        visibility: ${({ disabled }) => (disabled ? 'hidden' : 'visible')};
        width: ${({ disabled }) => (disabled ? '10px' : 'unset')};
      }
    }

    &__menu {
      width: ${({ menuWidthFromChildren }) => (menuWidthFromChildren ? 'auto' : '')};
      padding: 11px 0;
      border-radius: 20px;
      line-height: 23px;
      margin-top: 3px;
      box-shadow: ${theme.shadows.default};
      z-index: ${select};
      min-height: 60px;
      max-height: ${MAX_MENU_HEIGHT}px;
      left: ${({ horizontalAlignment = 'left' }) => (horizontalAlignment === 'left' ? 0 : undefined)};
      right: ${({ horizontalAlignment = 'left' }) => (horizontalAlignment === 'right' ? 0 : undefined)};
      min-width: ${({ menuWidthFromChildren }) => (menuWidthFromChildren ? 'unset' : '300px')};
      overflow: hidden;
    }

    &__option {
      width: 100%;
      padding: ${OPTION_VERTICAL_PADDING}px ${OPTION_HORIZONTAL_PADDING}px;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.select.option.color};
      background-color: transparent;
      font-weight: 400;
      line-height: ${OPTION_HEIGHT}px;
      cursor: pointer;
      ${elipsizeText};

      &:active,
      &:hover {
        color: ${({ theme }) => theme.colors.select.option.active.color};
        background-color: ${({ theme }) => theme.colors.select.option.active.background};

        &.${BEM_CLASS}__option--is-selected {
          color: ${({ theme }) => theme.colors.select.option.active.color};
          background-color: ${({ theme }) => theme.colors.select.option.active.background};
        }
        &.${BEM_CLASS}__option--is-disabled {
          color: ${({ theme }) => theme.colors.select.option.disabled.color};
          background-color: ${({ theme }) => theme.colors.select.option.disabled.background};
        }
      }

      &.${BEM_CLASS}__option--is-selected {
        font-weight: 700;
      }

      &.${BEM_CLASS}__option--is-focused {
        color: ${({ theme }) => theme.colors.select.option.active.color};
        background-color: ${({ theme }) => theme.colors.select.option.active.background};
      }
      &.${BEM_CLASS}__option--is-disabled {
        color: ${({ theme }) => theme.colors.select.option.disabled.color};
        background-color: ${({ theme }) => theme.colors.select.option.disabled.background};
      }
    }

    &__dropdown-indicator {
      ${SDownCss};
      padding-right: ${25 - 9}px;

      svg {
        display: none;
      }
    }

    &__control--menu-is-open {
      .${BEM_CLASS}__dropdown-indicator {
        ${SUpCss};
      }
    }

    &__divider {
      height: ${DIVIDER_HEIGHT}px;
      margin: ${DIVIDER_VERTICAL_MARGIN}px ${OPTION_HORIZONTAL_PADDING}px;
      background-color: ${colorPalette.lavenderGray};
    }
  }
`;

export const SSelect = styled(ReactSelect)<Props>`
  ${SelectCss}
`;

export const FullWidthControlSelect = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .${BEM_CLASS}__control {
    width: 100%;
    height: 100%;
    background: transparent;
  }
  .${BEM_CLASS}__indicator {
    top: 40%;
  }
  .${BEM_CLASS}__control-container {
    width: 100%;
    height: 100%;
    background: transparent;
  }
`;

export const SSelectStyledDiv = styled.div<Props & SelectComponentsProps>`
  ${SelectCss};
`;
