import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import loadedBarsSvg from 'assets/states/loading/loader-bars.raw.svg';
import { transparentize } from 'polished';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { ToastOptions, ToastType } from './utils';
import { colorPalette } from 'app/theme/colors';
import { theme } from 'app/theme';

const DEFAULT_MARGIN = 20;

export const bemClass = 'toast';

type SToastProps = Omit<ToastOptions, 'message' | 'onUndo'>;

const getToastBackground = (type: ToastType) => {
  switch (type) {
    case ToastType.Error:
      return palette.brightRed;
    case ToastType.Warning:
      return palette.vividOrange;
    case ToastType.Info:
      return palette.veryDarkBlueTwo;
    default:
      return palette.cyanGreen;
  }
};

export const SToast = styled.div<SToastProps>`
  position: relative;
  margin: ${DEFAULT_MARGIN}px;
  background-color: ${({ type }) => getToastBackground(type)};
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - ${DEFAULT_MARGIN * 2}px);
  height: 50px;
  padding: 0 60px 0 20px;
  border-radius: 10px;
  box-shadow: ${theme.shadows.notification};

  .${bemClass} {
    &__icon {
      margin-right: 18px;
      flex-shrink: 0;
    }

    &__info-icon {
      width: 18px;
      height: 18px;
      background: url(${loadedBarsSvg});
      background-size: 100%;
      margin-right: 20px;
      flex-shrink: 0;
    }

    &__message {
      color: ${colorPalette.white};
      ${elipsizeText};
    }

    &__undo {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      text-decoration: underline;
      text-decoration-style: dotted;
      margin-right: auto;
      margin-left: 20px;
    }

    &__close {
      position: absolute;
      z-index: 1;
      right: 12.5px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;

      &:hover {
        background-color: ${transparentize(0.75, palette.white)};
      }
    }
  }
`;
