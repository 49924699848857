import styled from 'styled-components';
import { PADDING } from '../../constants';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'value-container';

interface Props {
  isMulti?: boolean;
}

export const SValueContainer = styled.div<Props>`
  flex: 1;
  padding: 9px 10px 9px ${PADDING}px;
  overflow: hidden;

  .${BEM_CLASS} {
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &__values-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      max-height: 92px;
      overflow: auto;
      flex-wrap: ${({ isMulti }) => (isMulti ? 'wrap' : 'nowrap')};
      row-gap: 5px;
      align-content: flex-start;
      scroll-padding-bottom: 10px;
      margin-bottom: auto;
    }

    &__footer {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }

    &__clear-all {
      color: black;
      text-transform: uppercase;
      font-weight: 800;

      &:hover {
        color: ${colorPalette.ceriseRed};
      }
    }

    &__counter {
      text-transform: uppercase;
      margin-left: auto;
    }
  }
`;
