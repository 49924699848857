import { actionsGroup } from 'core/store';
import { ArtistMoment, Id, ProjectDetails } from 'backend-api/models';

const { action, asyncAction } = actionsGroup('artist-moment');

export interface ArtistMomentInput {
  name?: string;
  date?: Date;
  artistId?: string;
  territoriesIds?: Id[];
  links?: string[];
  notes?: string;
  typeId?: Id;
  categoryId?: Id;
}

export interface CreateMomentPayload extends ArtistMomentInput {
  projectId: Id;
}

export interface MomentInfo {
  momentId: Id;
  projectId: Id;
}

export interface MomentPayload extends ArtistMomentInput, MomentInfo {}

export interface ShowModalPayload {
  id: string;
  isOpened: boolean;
}

export const getMoment = asyncAction<MomentPayload, ArtistMoment, Error>('GET_MOMENT');

export const createMoment = asyncAction<CreateMomentPayload, ArtistMoment, Error>('CREATE_MOMENT');

export const deleteMoment = asyncAction<MomentPayload, unknown, Error>('DELETE_MOMENT');

export const editMoment = asyncAction<MomentPayload, unknown, Error>('EDIT_MOMENT');

export const getMomentProject = asyncAction<Id, ProjectDetails, Error>('GET_MOMENT_PROJECT');

export const resetMoment = action<void>('RESET_MOMENT');

export const showConfirmModal = action<ShowModalPayload>('SHOW_DELETE_MOMENT_CONFIRM_MODAL');
