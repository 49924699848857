import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { components } from 'react-select';
import { transparentize } from 'polished';

export const BEM_CLASS = 'settings-control';

export const SSettingsControlTrigger = styled(components.Control)`
  border: none;
  background-color: transparent;

  .${BEM_CLASS} {
    &__trigger {
      padding: 0 9px;

      &:not(&--isDisabled) {
        border: 1px solid ${transparentize(0.6, manhattanThemeColors.primaryFillNew)};
      }
    }
  }
` as typeof components.Control;
