import styled from 'styled-components';
import { ACTION_COLUMN_WIDTH, PAGE_PADDING } from 'media-plan-v2/constants';

export const BEM_CLASS = 'phase-row';

export const SPhaseRow = styled.div`
  display: flex;
  align-items: center;
  width: calc(100vw - ${PAGE_PADDING * 2}px - 2px); // 2px is table borders
  height: 100%;

  .${BEM_CLASS} {
    &__action {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${ACTION_COLUMN_WIDTH}px;
      width: 39px;
      height: 37px;
      border-right: 1px solid var(--ag-border-color);
    }

    &__value-container {
      flex-grow: 1;
      align-self: stretch;
      padding-right: var(--cell-horizontal-padding);
      padding-left: var(--cell-horizontal-padding);
    }

    &__input {
      width: 100%;
      font-weight: bold;
      padding: 0;
      font-size: 14px;
      border: none;
      background: transparent;
    }
  }
`;
