import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Widget } from 'common/constants';
import { bem } from 'utils/bem';
import { callbackWithoutPropagation } from 'utils/event';
import { SSearchField, BEM_CLASS } from './s-search-field';
import { Icon } from 'common/components/icon';
import { Button, BUTTON_TYPE, BUTTON_SIZE, THEME } from 'gdb-web-shared-components';
import { MAX_GLOBAL_SEARCH_QUERY_LENGTH } from './constants';

interface SearchFieldProps {
  searchValue: string;
  onSearchValueChange(value: string): void;
  onFocus(): void;
  focused?: boolean;
  className?: string;
  inputClassName?: string;
}

const classes = bem(BEM_CLASS);

export const SearchField = React.memo(
  ({ searchValue, onSearchValueChange, onFocus, focused, className, inputClassName }: SearchFieldProps) => {
    const [inputValue, setInputValue] = useState(searchValue);

    const changeHandler = useCallback(
      ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = target;
        setInputValue(value);
        onSearchValueChange(value);
      },
      [onSearchValueChange]
    );

    const clearInput = useCallback(() => {
      setInputValue('');
      onSearchValueChange('');
    }, [onSearchValueChange]);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      if (focused === undefined) {
        return;
      }

      focused ? inputRef.current?.focus() : inputRef.current?.blur();
    });

    useEffect(() => {
      return () => {
        clearInput();
      };
    }, [clearInput]);

    return (
      <SSearchField className={className}>
        <div
          className={classes('wrapper')}
          onClick={callbackWithoutPropagation()}
          data-selector="app-header-search-field"
        >
          <Icon size="general" color="black" name="search" className={classes('icon')} />
          <input
            ref={inputRef}
            value={inputValue}
            type={Widget.Text}
            placeholder="Search by project, artist or campaign..."
            className={classes('input', undefined, inputClassName)}
            onChange={changeHandler}
            onFocus={onFocus}
            maxLength={MAX_GLOBAL_SEARCH_QUERY_LENGTH}
          />
          {inputValue && (
            <Button
              type={BUTTON_TYPE.tertiary}
              size={BUTTON_SIZE.smallRound}
              theme={THEME.light}
              icon="cross"
              onClick={clearInput}
              className={classes('button')}
              data-selector="search-field-clear-button"
            />
          )}
        </div>
      </SSearchField>
    );
  }
);
