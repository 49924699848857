import styled from 'styled-components';
import { animatedFlare } from 'common/s-components/s-animated-flare';

interface Props {
  clipPathId: string;
}

export const SLoaderTab = styled.div<Props>`
  width: 100px;
  height: 15px;
  .loader-tab {
    &__clip-container {
      width: 100px;
      height: 15px;
      clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      -webkit-clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      ${animatedFlare}
    }
  }
`;
