import React from 'react';
import { MenuListProps } from 'react-select';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { bem } from 'utils/bem';
import { BaseOption as BaseOptionType } from '../../types';
import { BEM_CLASS, SMenuList } from './s-menu-list';

type Props<T extends BaseOptionType, IsMulti extends boolean> = MenuListProps<T, IsMulti>;

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType, IsMulti extends boolean>({
  children,
  innerRef,
  innerProps,
  selectProps,
}: Props<T, IsMulti>) => {
  const { classNames, dataSelector } = getBaseCustomSelectProps(selectProps);

  return (
    <SMenuList
      {...innerProps}
      ref={innerRef}
      className={classNames.menuList}
      data-selector={`${dataSelector}-menu-list`}
    >
      <div className={classes('options')}>{children}</div>
    </SMenuList>
  );
};

export const MenuList = React.memo(Root) as typeof Root;
