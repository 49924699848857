import {
  assignCampaignToProject,
  clearProjectError,
  createPlacement,
  editPhase,
  getAllLabels,
  getArtistMomentCategories,
  getArtistMomentTypes,
  getCampaignProviders,
  getCampaignStatuses,
  getCampaignTaxonomy,
  getCountryCodes,
  getCurrencies,
  getGenders,
  getGlobalSearch,
  getGlobalSearchPage,
  getGoals,
  getLabels,
  getLabelUsers,
  getPerformanceArtistsBreakdowns,
  getPerformanceCampaignProviders,
  getPerformanceCampaignsBreakdowns,
  getPerformanceObjectives,
  getPerformancePlatforms,
  getPerformanceProjectsBreakdowns,
  getProductFamilies,
  getProject,
  getProjectReleaseTypes,
  getProjectStatuses,
  getRecentSearch,
  getRecommendedProjectsForAssignModal,
  getTerritories,
  getUserInfo,
  searchLinkfireLinks,
  searchPlaylists,
  searchProjectsForAssignModal,
} from './actions';
import { asyncEpic, ofType } from 'core/epics';
import { Api } from 'backend-api';
import { showErrorToast, showSuccessToast } from 'common/components/toast';
import {
  AnalyticsEvents,
  increaseUserCounter,
  trackEvent,
  trackUserInfo,
  trackUserLabels,
  UserCounters,
} from 'utils/analytic';
import { LINKFIRE_LINKS_SEARCH_RESULT_LIMIT } from './constants';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { preparePhaseRequest } from 'common/transducers';
import { GetTaxonomyParams } from './types';

export const getLabelsEpic = asyncEpic(getLabels, () => Api.getLabels().pipe(tap(labels => trackUserLabels(labels))));

export const getAllLabelsEpic = asyncEpic(getAllLabels, () => Api.getAllLabels());

export const getCampaignTaxonomyEpic = asyncEpic(getCampaignTaxonomy, action => {
  const payload: GetTaxonomyParams = { labelIds: action.payload };
  return Api.getTaxonomy(payload);
});

export const getGoalsEpic = asyncEpic(getGoals, () => Api.getGoals());

export const getGendersEpic = asyncEpic(getGenders, () => Api.getGenders());

export const getCampaignProvidersEpic = asyncEpic(getCampaignProviders, action =>
  Api.getCampaignProviders(action.payload)
);

export const getPerformanceCampaignProvidersEpic = asyncEpic(getPerformanceCampaignProviders, () =>
  Api.getPerformanceCampaignProviders(true)
);

export const getTerritoriesEpic = asyncEpic(getTerritories, () => Api.getTerritories());

export const getProjectStatusEpic = asyncEpic(getProjectStatuses, () => Api.getProjectStatus());

export const getCurrenciesEpic = asyncEpic(getCurrencies, () => Api.getCurrencies());

export const getProjectEpic = asyncEpic(
  getProject,
  action => {
    const { projectId, params } = action.payload;
    return Api.getProject(projectId, params);
  },
  (error, { hasErrorToast }) => {
    if (hasErrorToast) {
      showErrorToast("We're sorry, an error occurred during retrieving Project data. Try again.");
    }
    return of(getProject.failure(error));
  },
  undefined,
  clearProjectError
);

export const getLabelUsersEpic = asyncEpic(getLabelUsers, action => Api.getLabelUsers(action.payload));

export const getUserInfoEpic = asyncEpic(getUserInfo, () =>
  Api.getUserInfo().pipe(tap(info => trackUserInfo(info.currentUser)))
);

export const getCountryCodesEpic = asyncEpic(getCountryCodes, () => Api.getCountryCodes());

export const getPerformanceObjectivesEpic = asyncEpic(getPerformanceObjectives, () => Api.getPerformanceObjectives());

export const getPerformancePlatformsEpic = asyncEpic(getPerformancePlatforms, () => Api.getPerformancePlatforms());

export const getPerformanceArtistsBreakdownsEpic = asyncEpic(getPerformanceArtistsBreakdowns, () =>
  Api.getArtistsPerformanceBreakdownOptions()
);

export const getPerformanceProjectsBreakdownsEpic = asyncEpic(getPerformanceProjectsBreakdowns, () =>
  Api.getProjectsPerformanceBreakdownOptions()
);

export const getPerformanceCampaignsBreakdownsEpic = asyncEpic(getPerformanceCampaignsBreakdowns, () =>
  Api.getCampaignsPerformanceBreakdownOptions()
);

export const getCampaignStatusesEpic = asyncEpic(getCampaignStatuses, () => Api.getCampaignStatuses());

export const searchProjectsForAssignEpic = asyncEpic(searchProjectsForAssignModal, action =>
  Api.getAssignableProjects(action.payload)
);

export const getRecommendedProjectsForAssignEpic = asyncEpic(getRecommendedProjectsForAssignModal, action =>
  Api.getAssignableProjects({ campaignUuid: action.payload })
);

export const assignCampaignToProjectEpic = asyncEpic(assignCampaignToProject, action => {
  return Api.assignCampaignsToProject([action.payload.campaignUuid], action.payload.projectId).pipe(
    tap(() => {
      showSuccessToast('Campaign successfully assigned');
      if (action.payload.onCampaignAssigned) action.payload.onCampaignAssigned(action.payload.projectId);
    }),
    tap(() => increaseUserCounter(UserCounters.CAMPAIGNS_ASSIGNED, 1))
  );
});

export const getArtistMomentTypesEpic = asyncEpic(getArtistMomentTypes, Api.getMomentTypes);
export const getArtistMomentCategoriesEpic = asyncEpic(getArtistMomentCategories, Api.getMomentCategories);

export const getGlobalSearchDataEpic = asyncEpic(
  getGlobalSearch,
  ({ payload }) => Api.getGlobalSearch(payload),
  undefined,
  { showError: false }
);
export const getGlobalSearchPageEpic = asyncEpic(getGlobalSearchPage, ({ payload }) => Api.getGlobalSearch(payload));
export const getRecentSearchDataEpic = asyncEpic(
  getRecentSearch,
  ({ payload: { limit } }) => Api.getRecentGlobalSearch({ limit }),
  undefined,
  {
    showError: false,
  }
);

export const searchLinkfireLinksEpic = asyncEpic(searchLinkfireLinks, action => {
  return Api.getLinkfireLinks({ query: action.payload, limit: LINKFIRE_LINKS_SEARCH_RESULT_LIMIT });
});

export const getProductFamiliesEpic = asyncEpic(getProductFamilies, action => {
  return Api.getProductFamilies(action.payload);
});

export const searchPlaylistsEpic = asyncEpic(searchPlaylists, action => Api.searchPlaylists(action.payload));

export const editPhaseEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(editPhase.request),
    switchMap(action => {
      const data = preparePhaseRequest(action.payload);
      const { projectId, phaseId, onPhaseEditSuccess } = action.payload;
      return Api.editPhase(projectId, phaseId, data).pipe(
        tap(() => onPhaseEditSuccess && onPhaseEditSuccess()),
        tap(response =>
          trackEvent(AnalyticsEvents.PHASE_EDITED, {
            project_id: projectId,
            phase_number: response.order + 1,
            phase_name: response.name,
            phase_prodfam_count: response.product_families.length,
            phase_playlist_count: response.playlists.length,
            phase_track_count: response.product_families.flatMap(family => family.tracks).length,
          })
        ),
        mergeMap(() => [editPhase.success(), getProject.request({ projectId })]),
        catchError(err => {
          showErrorToast(err);
          return of(editPhase.failure(err));
        })
      );
    })
  );
};

export const сreatePlacementEpic = asyncEpic(createPlacement, action => {
  const { projectId, placement } = action.payload;
  return Api.createPlacements(projectId, placement);
});

export const getProjectReleaseTypesEpic = asyncEpic(getProjectReleaseTypes, () => Api.getProjectReleaseTypes());
