export const PLATFORM_SLDS = {
  amazon: 'amazon',
  apple: 'apple',
  deezer: 'deezer',
  facebook: 'facebook',
  google: 'google',
  instagram: 'instagram',
  linkfire: 'lnk',
  pandora: 'pandora',
  shazam: 'shazam',
  smarturl: 'smarturl',
  snapchat: 'snapchat',
  soundcloud: 'soundcloud',
  spotify: 'spotify',
  tidal: 'tidal',
  tiktok: 'tiktok',
  twitter: 'twitter',
  youtube: 'youtube',
} as const;
