import styled from 'styled-components';

export const SProjectsLoader = styled.div`
  min-height: 255px;
  display: flex;
  flex-direction: column;
  margin: 4px 20px 0;

  .release-loader {
    &__items-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
    }

    &__item {
      flex: 1;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
`;
