import { Tooltip } from 'common/components/tooltip';
import React from 'react';
import { SEllipsisItem } from './s-ellipsis-item';

interface Props {
  count: number;
  tooltipText?: string;
  className?: string;
  dataSelector?: string;
}

export const EllipsisItem = React.memo(({ count, tooltipText, className, dataSelector }: Props) => (
  <Tooltip content={tooltipText} enabled={!!tooltipText}>
    <SEllipsisItem className={className} data-selector={dataSelector}>
      +{count}...
    </SEllipsisItem>
  </Tooltip>
));
