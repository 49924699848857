import 'simplebar/dist/simplebar.min.css?raw';
import 'react-day-picker/lib/style.css?raw';
import 'frontend-shared-icons/dist/style.css?raw';

import 'gdb-web-shared-components/dist/esm/vendors.css?raw';
import 'gdb-web-shared-components/dist/esm/index.css?raw';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/integration/react';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/browser';
import { configureStore } from './store/store';
import { SGlobalStyles } from './s-components/s-global-styles';
import { history } from 'utils/navigation';
import { AppContainer } from './containers/app-container';
import { theme } from './theme';
import intercom from './sdks/intercom';
import { initAnalytics } from 'utils/analytic';
import { SplitFactory, SplitSdk } from '@splitsoftware/splitio-react';
import { BorderSvg, SnackbarProvider } from 'gdb-web-shared-components';
import { LicenseManager } from 'ag-grid-enterprise';
import { Head } from './components/head';

if (process.env.LOGROCKET_KEY) {
  LogRocket.init(process.env.LOGROCKET_KEY);
}

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.ENVIRONMENT || 'Unknown',
    ignoreErrors: [
      // Ignore Safari browser known error, no actual impact
      'ResizeObserver loop limit exceeded',
    ],
  });
}

if (process.env.AG_GRID_KEY) {
  LicenseManager.setLicenseKey(process.env.AG_GRID_KEY);
}

initAnalytics();

const splitSDKConfig =
  process.env.SPLIT_KEY &&
  SplitSdk({
    core: {
      authorizationKey: process.env.SPLIT_KEY,
      //Customer identifier, change if it will be needed to differentiate unauthorized users
      key: 'unauthorized-user',
    },
  });

export const { store, persistor } = configureStore();

const AppRoot = () => {
  /*
   * Custom injection point gives the highest priority for styled-components
   * */
  // https://github.com/styled-components/styled-components/pull/1102
  // https://github.com/styled-components/styled-components/pull/1324
  // in production insertRule will be used therefore style tag's content will be invisible
  // https://stackoverflow.com/questions/36638076/why-are-styles-invisible-and-style-tags-not-re-appendable-after-using-insertrul
  const cssRoot = document.getElementById('css-root') || undefined;

  return (
    <StyleSheetManager target={cssRoot}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Head>
            <script>{intercom}</script>
          </Head>

          <SGlobalStyles />
          <BorderSvg />

          <PersistGate loading={null} persistor={persistor}>
            <SplitFactory factory={splitSDKConfig}>
              <SnackbarProvider>
                <Router history={history}>
                  <AppContainer />
                </Router>
              </SnackbarProvider>
            </SplitFactory>
          </PersistGate>
        </ThemeProvider>
      </Provider>
    </StyleSheetManager>
  );
};

const root = document.getElementById('root');

if (root) {
  ReactDOM.render(AppRoot(), root);
  Modal.setAppElement(root);
}
