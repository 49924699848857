import React from 'react';
import { SNotes } from './s-notes';
import { bem } from 'utils/bem';
import { Icon } from '../../icon';
import MarkdownEditor from '../../markdown-editor';
import { BgStyle } from 'common/styles/utils';
import { PrimaryLabel } from 'common/components/typography';

const classes = bem('notes');

interface Props {
  bgStyle?: BgStyle;
  onChange(value: string): void;
  value: string;
  maxLength: number;
  expandClick(): void;
  expanded: boolean;
  placeholder: string;
  className?: string;
  disabled: boolean;
}

export const Notes = ({
  bgStyle,
  onChange,
  value,
  maxLength,
  expandClick,
  expanded,
  placeholder,
  className,
  disabled,
}: Props) => {
  const onContentChange = (text, html) => {
    onChange(html);
  };
  return (
    <SNotes bgStyle={bgStyle} expanded={expanded} data-selector="notes-field" className={className} disabled={disabled}>
      {expanded && <PrimaryLabel className={classes('label')}>Notes</PrimaryLabel>}
      <div onClick={expandClick} className={classes('icon')}>
        <Icon name="expand-field" color="pink" size="general" />
      </div>
      <MarkdownEditor
        content={value}
        maxLength={maxLength}
        onChange={onContentChange}
        className={classes('input')}
        placeholder={placeholder}
      />
    </SNotes>
  );
};
