import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const STabsLoader = styled.div`
  &&& {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 18px;
    border-bottom: 1px solid ${transparentize(0.9, manhattanThemeColors.dividerColor)};
  }
`;
