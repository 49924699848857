import { includesOnlyApproverRole } from 'common/transducers';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { activeProjectSelector } from 'common/selectors';

export const useProjectApproverAccessRestricted = (): void => {
  const project = useSelector(activeProjectSelector);
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();

  useEffect(() => {
    const isAccessRestricted = !!project && includesOnlyApproverRole(project.userRoles);
    const isRightProject = parseInt(match.params.id, 10) === project?.id;

    if (isAccessRestricted && isRightProject) history.replace(`/projects/${project?.id}/media-plan`);
  }, [project, history, match]);
};
