import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const BEM_CLASS = 'notification';

export const SNotification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.darkBlueTwo};
  padding: 12px 20px;
  margin: 18px 20px 0;
  border-radius: 5px;

  .${BEM_CLASS} {
    &__icon {
      margin-right: 20px;
    }

    &__text {
      color: ${palette.white};
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
    }
  }
`;
