import styled from 'styled-components';
import { fullScreen as fullScreenZIndex, stickyFooter as stickyFooterZIndex } from 'app/styles/z-indexes';
import { manhattanBackground } from 'common-v2/styles';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'media-plan-container';

export const SMediaPlanContainer = styled.div`
  flex: 1;

  .${BEM_CLASS} {
    &__spreadsheet {
      &--isFullScreen {
        ${manhattanBackground};

        position: fixed;
        z-index: ${fullScreenZIndex};
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
        overflow: auto;

        .${BEM_CLASS}__container {
          padding: 15px 30px 30px;
        }
      }
    }

    &__background {
      justify-content: flex-start;
    }

    &__metrics-container {
      padding: 0 30px;
      margin: 30px 0 10px;
    }
  }
`;

export const SMediaPlanContainerFooter = styled.div`
  position: sticky;
  z-index: ${stickyFooterZIndex};
  bottom: 0;
  left: 0;
  right: 0;

  .${BEM_CLASS} {
    &__footer {
      position: relative;
      z-index: 2;
    }

    &__overlay {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-image: linear-gradient(
        360deg,
        ${transparentize(0.3, manhattanThemeColors.primaryDark)} 0%,
        ${transparentize(0.3, manhattanThemeColors.primaryFillNew)} 100%
      );
    }
  }
`;
