import { animatedFlare } from 'common/s-components/s-animated-flare';
import styled from 'styled-components';

export const BEM_CLASS = 'territories';
export const CLIP_PATH_ID = 'territories';

export const STerritoriesLoader = styled.div`
  width: 90px;
  height: 30px;

  .${BEM_CLASS} {
    &__clip-container {
      width: 90px;
      height: 30px;
      clip-path: url(#${CLIP_PATH_ID});
      -webkit-clip-path: url(#${CLIP_PATH_ID});
      ${animatedFlare}
    }
  }
`;
