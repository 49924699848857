import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { AppContentLoader } from 'app/containers/app-content-loader';
import RouteInternal from './route-internal';

class ProtectedRouteComponent extends Component {
  render() {
    const {
      permissions,
      isLoading,
      permission,
      component,
      hasAccess,
      fallbackUrl,
      error,
      getPermissions,
      isAuthorized,
      unauthorized,
      checkSession,
      startPollingSession,
      stopPollingSession,
      ...rest
    } = this.props;

    return (
      <Route {...rest}>
        {routerParams => (
          <AppContentLoader>
            <RouteInternal
              permissions={permissions}
              isLoading={isLoading}
              permission={permission}
              component={component}
              hasAccess={hasAccess}
              isAuthorized={isAuthorized}
              unauthorized={unauthorized}
              fallbackUrl={fallbackUrl}
              error={error}
              getPermissions={getPermissions}
              checkSession={checkSession}
              startPollingSession={startPollingSession}
              stopPollingSession={stopPollingSession}
              {...routerParams}
            />
          </AppContentLoader>
        )}
      </Route>
    );
  }
}

export default ProtectedRouteComponent;
