import styled from 'styled-components';
import { MAX_MENU_HEIGHT } from 'common/components/form/select';

export const BEM_CLASS = 'empty-state';

export const SEmptyState = styled.div`
  display: flex;
  flex-direction: column;
  min-height: ${MAX_MENU_HEIGHT}px;
  align-items: center;
  justify-content: center;
  .${BEM_CLASS} {
    &__image {
      margin-bottom: 16px;
    }
  }
`;
