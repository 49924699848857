import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette } from 'app/theme/colors';
import { MAX_WIDTH, MIN_WIDTH } from '../../constants';

export const SMenuWrapper = styled.div`
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;
  border-radius: 10px;
  box-shadow: 0 5px 10px ${transparentize(0.5, colorPalette.coolGray)};
  overflow: hidden;
`;
