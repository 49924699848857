import React from 'react';
import { SModalSideBar } from './s-modal-sidebar';

interface Props {
  children: React.ReactChild;
  width?: string;
  className?: string;
}

export const ModalSideBar = React.memo(({ children, width, className }: Props) => {
  return (
    <SModalSideBar width={width} className={className}>
      {children}
    </SModalSideBar>
  );
});
