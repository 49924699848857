import { ValueFormatterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { CopyPastedCell, FocusDirection, UndoData, UndoHook } from './types';
import {
  MediaPlanColumnId,
  SpreadsheetRowFields,
  SpreadsheetRowData,
  SpreadsheetValidationModel,
  MediaPlanAccessMode,
  SpreadsheetContext,
} from 'media-plan-v2/containers/spreadsheet/types';
import { camelCase } from 'lodash';
import { UUID } from 'io-ts-types/lib/UUID';
import {
  processPlacements,
  getInitialCellData,
  getIsCellEditableCallback,
  isMediaPlanColumnId,
} from 'media-plan-v2/containers/spreadsheet/transducers';
import { CampaignStatuses, Optional } from 'backend-api/models';
import {
  Column,
  ColumnApi,
  ExcelStyle,
  GridApi,
  InitialGroupOrderComparatorParams,
  PostSortRowsParams,
  ProcessCellForExportParams,
  RowNode,
  SortChangedEvent,
} from 'ag-grid-community';
import {
  COLORS_IDENTIFIER,
  COLOR_IDENTIFIER,
  EXCEL_EXPORT_COLUMNS,
  FIRST_VALUE_COLUMN_EXPORT_INDEX,
  LINK_COLUMNS,
  NOT_COPYABLE_MEDIA_PLAN_COLUMNS_COUNT,
  COLUMNS_TO_SANITIZE,
} from './constants';
import { CURRENCY_CODE_TO_SIGN, IS_MAC_OS } from 'common/constants';
import { MediaPlanMode } from 'media-plan-v2/types';
import first from 'lodash/first';
import { isFirefox } from 'common-v2/utils';
import { isEnumValue } from 'common-v2/types';
import { TOAST_TYPE, ToastOptions } from 'gdb-web-shared-components';

export const createDraftCampaign = (
  data: Pick<SpreadsheetRowFields, 'uuid' | 'phaseId' | 'phaseName' | 'phaseOrder'>,
  orders: Pick<SpreadsheetRowFields, 'orderInPhase' | 'orderOnSort'>
): SpreadsheetRowData => ({
  uuid: getInitialCellData(data.uuid),
  phaseId: getInitialCellData(data.phaseId),
  phaseName: getInitialCellData(data.phaseName),
  phaseOrder: getInitialCellData(data.phaseOrder),
  orderInPhase: getInitialCellData(orders.orderInPhase),
  orderOnSort: getInitialCellData(orders.orderOnSort),
  status: getInitialCellData(CampaignStatuses.DRAFT),
  id: getInitialCellData(undefined),
  name: getInitialCellData(undefined),
  platforms: getInitialCellData(undefined),
  objective: getInitialCellData(undefined),
  kpi: getInitialCellData(undefined),
  notes: getInitialCellData(undefined),
  genders: getInitialCellData(undefined),
  adCreativeLinks: getInitialCellData(undefined),
  destinationLinks: getInitialCellData(undefined),
  startDate: getInitialCellData(undefined),
  endDate: getInitialCellData(undefined),
  countries: getInitialCellData(undefined),
  audienceDetails: getInitialCellData(undefined),
  creativeDescription: getInitialCellData(undefined),
  adCopy: getInitialCellData(undefined),
  age: getInitialCellData(undefined),
  eCpm: getInitialCellData(undefined),
  budget: getInitialCellData(undefined),
  placements: getInitialCellData(undefined),
  action: getInitialCellData(undefined),
  actualSpent: getInitialCellData(undefined),
  callToAction: getInitialCellData(undefined),
  estImpressions: getInitialCellData(undefined),
  headline: getInitialCellData(undefined),
  namingConvention: getInitialCellData(undefined),
  namingConventionManual: getInitialCellData(undefined),
  editableInApproval: getInitialCellData(undefined),
});

export const isEditMode = (accessMode: MediaPlanAccessMode): boolean => accessMode === MediaPlanAccessMode.EDIT;

export const isActionsShown = (mediaPlanMode: MediaPlanMode): boolean => mediaPlanMode === MediaPlanMode.DEFAULT;

export const isReadOnlyMode = (accessMode: MediaPlanAccessMode): boolean =>
  accessMode === MediaPlanAccessMode.READ_ONLY;

export const getExcelStyles = (currencyCode: string): ExcelStyle[] => [
  {
    id: 'currencyFormat',
    numberFormat: {
      format: `${CURRENCY_CODE_TO_SIGN[currencyCode]} #,##0.00`,
    },
  },
  {
    id: 'hyperlinks',
    dataType: 'Formula',
    alignment: {
      horizontal: 'Center',
      vertical: 'Top',
      wrapText: true,
    },
  },
];

export const getColumnIndexByColId = (columns: Column[], colId: string): number | undefined =>
  columns.findIndex(col => col.getColId() === colId);

export const handleRemovingValue = (
  event: KeyboardEvent,
  api: GridApi<SpreadsheetRowData>,
  updateUndoStack: (undo: UndoData) => void,
  openToast: (options: ToastOptions) => void
) => {
  event.preventDefault();
  event.stopPropagation();

  api.getCellRanges()?.forEach(range => {
    if (!range.startRow || !range.endRow) return;
    const colIds: MediaPlanColumnId[] = [];

    range.columns.forEach(col => {
      const colId = col.getColId();
      if (isMediaPlanColumnId(colId)) {
        colIds.push(colId);
      }
    });

    const startRowIndex = Math.min(range.startRow?.rowIndex, range.endRow?.rowIndex);
    const endRowIndex = Math.max(range.startRow?.rowIndex, range.endRow?.rowIndex);

    clearCells(startRowIndex, endRowIndex, colIds, api, updateUndoStack, openToast);
  });
};

const clearCells = (
  start: number,
  end: number,
  columns: MediaPlanColumnId[],
  gridApi: GridApi<SpreadsheetRowData>,
  updateUndoStack: (undo: UndoData) => void,
  openToast: (options: ToastOptions) => void
) => {
  const itemsToUpdate: any[] = []; // BUG: need any here because gridApi.getModel().getRow(i) returns value of type RowNode<any>
  const nodesToRefreshPlacements: RowNode[] = [];

  for (let i = start; i <= end; i++) {
    const row = gridApi.getModel().getRow(i);

    if (row?.footer || row?.group) continue;

    const data = { ...row?.data };

    columns.forEach(column => {
      if (column === MediaPlanColumnId.STATUS) return;

      const isPlacementsEmpty = !data.placements.value || data.placements.value.length === 0;

      if (
        column === MediaPlanColumnId.PLATFORMS &&
        row &&
        !isPlacementsEmpty &&
        !columns.includes(MediaPlanColumnId.PLACEMENTS)
      ) {
        openToast({
          id: 'media-plan-placements-warning',
          type: TOAST_TYPE.WARNING,
          message: 'Pasted placements automatically updated to match selected Platforms.',
          preventDuplicate: true,
        });

        nodesToRefreshPlacements.push(row);

        updateUndoStack({ field: MediaPlanColumnId.PLACEMENTS, data: { ...data } });
        data[MediaPlanColumnId.PLACEMENTS] = {
          ...data[MediaPlanColumnId.PLACEMENTS],
          value: null,
        };
      }

      updateUndoStack({ field: column, data: { ...data } });
      data[column] = {
        ...data[column],
        value: null,
      };
    });

    itemsToUpdate.push(data);
  }

  gridApi.applyTransactionAsync({ update: itemsToUpdate }, () => {
    if (nodesToRefreshPlacements.length > 0) {
      gridApi.refreshCells({
        columns: [MediaPlanColumnId.PLACEMENTS],
        rowNodes: nodesToRefreshPlacements,
        force: true,
      });
    }
  });
};

export const processDataFromClipboard = (data: CopyPastedCell[][], columnApi: ColumnApi): CopyPastedCell[][] => {
  if (data.length === 0) return [];

  const isFullRowCopy =
    data[0].length === columnApi.getAllDisplayedColumns()?.length - NOT_COPYABLE_MEDIA_PLAN_COLUMNS_COUNT;
  const namingConventionIndex =
    columnApi.getAllDisplayedColumns().findIndex(col => col.getColId() === MediaPlanColumnId.NAMING_CONVENTION) -
    NOT_COPYABLE_MEDIA_PLAN_COLUMNS_COUNT;

  return isFullRowCopy
    ? data.map(item => [...item.slice(0, namingConventionIndex), ...item.slice(namingConventionIndex + 1, item.length)])
    : data;
};

export const prepareCellDataForClipboard = (
  { column, value, api, node }: ProcessCellForExportParams<SpreadsheetRowData>,
  spreadsheetValidationModel: SpreadsheetValidationModel,
  addCopiedData: (data: [UUID, CopyPastedCell]) => void
) => {
  if (!value) return;

  const { color, value: cellValue } = value;

  const campaignUuid = node?.data?.uuid.value;
  const columnValidationModel = spreadsheetValidationModel[column.getColId()];

  if (campaignUuid) {
    addCopiedData([
      campaignUuid,
      { color, value: columnValidationModel.formatToString({ value: cellValue, api, node }) },
    ]);
  }

  return columnValidationModel.formatToString({ value: cellValue, api, node });
};

const onPlacementsPaste = (
  pastedValue: CopyPastedCell,
  updatedCell: SpreadsheetRowData[keyof SpreadsheetRowData],
  openToast: (options: ToastOptions) => void
) => {
  const pastedPlacementsLength = pastedValue.value.split(',').length;
  const arePlacementsAutomaticallyUpdated =
    Array.isArray(updatedCell.value) && pastedPlacementsLength !== updatedCell.value.length;

  if (arePlacementsAutomaticallyUpdated) {
    openToast({
      id: 'media-plan-placements-warning',
      type: TOAST_TYPE.WARNING,
      message: 'Pasted placements automatically updated to match selected Platforms.',
      preventDuplicate: true,
    });
  }
};

export const parseCellValueFromClipboard = (
  { color, value }: CopyPastedCell,
  columnId: MediaPlanColumnId,
  api: GridApi<SpreadsheetRowData>,
  columnApi: ColumnApi,
  spreadsheetValidationModel: SpreadsheetValidationModel,
  context: SpreadsheetContext,
  data: SpreadsheetRowData,
  openToast: (options: ToastOptions) => void
): Optional<SpreadsheetRowData[keyof SpreadsheetRowData]> => {
  const isCellEditable = getIsCellEditableCallback(columnId)(context, data);
  const isFullRowCopy =
    first(api.getCellRanges())?.columns.length ===
    columnApi.getAllDisplayedColumns()?.length - NOT_COPYABLE_MEDIA_PLAN_COLUMNS_COUNT;

  if (!isCellEditable) {
    openToast({
      id: 'media-plan-read-only',
      type: TOAST_TYPE.ERROR,
      message: "We're sorry, you cannot update read-only data.",
      preventDuplicate: true,
    });

    return;
  }

  if (isFullRowCopy && columnId === MediaPlanColumnId.NAMING_CONVENTION) {
    return;
  }

  const columnValidationModel = spreadsheetValidationModel[columnId];
  const normalizedValue = columnValidationModel.normalizeValue({ value, data });

  if (normalizedValue !== undefined) {
    return {
      color,
      value: normalizedValue,
    };
  }

  openToast({
    id: 'media-plan-invalid-data',
    type: TOAST_TYPE.ERROR,
    message: columnValidationModel.errorText ?? "We're sorry, data of an invalid data type was pasted into the table.",
    preventDuplicate: true,
  });

  return;
};

export const pasteDataIntoSingleCell = (
  api: GridApi<SpreadsheetRowData>,
  columnApi: ColumnApi,
  spreadsheetValidationModel: SpreadsheetValidationModel,
  undo: UndoHook,
  context: SpreadsheetContext,
  openToast: (options: ToastOptions) => void
) => (clipboard: CopyPastedCell[][]) => {
  const focusedCell = api.getFocusedCell();
  const allColumns = columnApi.getColumns();

  if (!focusedCell || !allColumns) return;

  let clipboardArrayIndex = 0;

  const clipboardArray = processDataFromClipboard(clipboard, columnApi);

  const transactionUpdate: SpreadsheetRowData[] = [];

  api.forEachNode(node => {
    let currentColumnIndex = getColumnIndexByColId(allColumns, focusedCell.column.getColId());

    if (node.rowIndex === null || currentColumnIndex === undefined || !node.data) return;
    if (node.rowIndex < focusedCell.rowIndex || node.rowIndex > focusedCell.rowIndex + clipboardArray.length - 1)
      return;

    const updatedRow = { ...node.data };

    clipboardArray[clipboardArrayIndex].forEach(value => {
      if (currentColumnIndex === undefined || !node.data) return;

      const currentColumnId = allColumns[currentColumnIndex]?.getColId() || undefined;

      if (currentColumnId === undefined || !isMediaPlanColumnId(currentColumnId)) return;

      const updatedCell = parseCellValueFromClipboard(
        value,
        currentColumnId,
        api,
        columnApi,
        spreadsheetValidationModel,
        context,
        updatedRow,
        openToast
      );

      if (updatedCell) {
        undo.updateUndoStack({ field: currentColumnId, data: node.data });
        updatedRow[currentColumnId] = updatedCell;

        if (currentColumnId === MediaPlanColumnId.PLACEMENTS) {
          onPlacementsPaste(value, updatedCell, openToast);
        }

        if (currentColumnId === MediaPlanColumnId.PLATFORMS) {
          updatedRow[MediaPlanColumnId.PLACEMENTS] = updatePlacementsOnPlatformsChange(
            context,
            updatedRow,
            clipboardArray[clipboardArrayIndex].length === 1,
            openToast
          );
        }
      }

      currentColumnIndex++;
    });

    transactionUpdate.push(updatedRow);

    clipboardArrayIndex++;
  });

  api.applyTransactionAsync({
    update: transactionUpdate,
  });
};

export const initialGroupOrderComparator = ({
  nodeA,
  nodeB,
}: InitialGroupOrderComparatorParams<SpreadsheetRowData>): number => {
  const orderA = nodeA.allLeafChildren[0].data?.phaseOrder.value ?? 0;
  const orderB = nodeB.allLeafChildren[0].data?.phaseOrder.value ?? 0;

  return orderA - orderB;
};

export const isMultipleRowsSelected = (api: GridApi): boolean => {
  const cellRanges = api.getCellRanges();

  if (!cellRanges) return false;

  return cellRanges.some(range => {
    if (range.startRow && range.endRow) {
      return range.startRow.rowIndex !== range.endRow.rowIndex;
    }

    return false;
  });
};

export const exportMediaPlanToExcel = (api: GridApi<SpreadsheetRowData>, projectName: string) => {
  const sanitizeCell = (cell: string): string => {
    if (!cell || cell.trim().length === 0) {
      return cell;
    }

    const sanitizedString = `${cell.replace(/"/g, '""')}`;

    return `="${sanitizedString}"`;
  };

  api.exportDataAsExcel({
    fileName: `${projectName.replace(/\.|\s/g, '_')}_media_plan`,
    columnKeys: EXCEL_EXPORT_COLUMNS,
    autoConvertFormulas: true,
    suppressTextAsCDATA: false,
    processCellCallback: params => {
      const colDef = params.column.getColDef(); // BUG: type is ColDef<any> because of ag-grid

      if (!colDef?.field || !isMediaPlanColumnId(colDef.field)) {
        return;
      }

      const colId = params.column.getColId();
      const columnIndex = getColumnIndexByColId(params.columnApi.getAllDisplayedColumns(), colId);

      if (params.node?.group && columnIndex === FIRST_VALUE_COLUMN_EXPORT_INDEX) {
        return sanitizeCell(params.node.allLeafChildren?.[0].data.phaseName.value);
      }

      let value = params.value?.value;

      if (colDef?.field && COLUMNS_TO_SANITIZE.includes(colDef?.field) && typeof value === 'string') {
        value = sanitizeCell(value);
      }

      if (colDef?.field && LINK_COLUMNS.includes(colDef?.field) && !params.node?.group) {
        return sanitizeCell(value.toString());
      }

      if (colDef.valueFormatter && params.node && typeof colDef.valueFormatter !== 'string') {
        const valueFormatterParams: ValueFormatterParams<SpreadsheetRowData> = {
          ...params,
          data: params.node.data,
          node: params.node,
          colDef: params.column.getColDef(),
        };
        if (colDef.field === MediaPlanColumnId.NAMING_CONVENTION) {
          return sanitizeCell(colDef.valueFormatter(valueFormatterParams));
        }
        return colDef.valueFormatter(valueFormatterParams);
      }
      return value;
    },
  });
};

export const isLastPhase = (api: GridApi<SpreadsheetRowData>) => {
  const phases: Set<number> = new Set([]);

  api.forEachNode(node => {
    if (node.group || node.footer || !node.data) return;

    phases.add(node.data.phaseId.value);
  });

  return phases.size < 2;
};

export const isTableSorted = (columnApi: ColumnApi): boolean => {
  const columns = columnApi.getColumnState();
  return columns.some(({ sort }) => sort != null);
};

const isGroupNodes = (nodes: RowNode<SpreadsheetRowData>[]): boolean => nodes.every(({ group }) => group);

export const postSortRows = ({ columnApi, nodes, api }: PostSortRowsParams<SpreadsheetRowData>) => {
  const isGroupRows = isGroupNodes(nodes);
  const isOrderOnSortSet = nodes
    .filter(node => !node.group && !node.footer && node.data)
    .every(({ data }) => data?.orderOnSort.value !== undefined);

  const isSorted = isTableSorted(columnApi);

  if (!isOrderOnSortSet && isSorted) {
    const data = nodes
      .filter(node => !node.group && !node.footer)
      .flatMap(({ data }, index) =>
        data
          ? [
              {
                ...data,
                orderOnSort: { ...data.orderOnSort, value: index },
              },
            ]
          : []
      );

    api.applyTransaction({ update: data });
    return;
  }

  if (isGroupRows) {
    nodes.sort((nodeA, nodeB) => {
      const phaseOrderA = nodeA.allLeafChildren[0]?.data?.phaseOrder.value ?? 0;
      const phaseOrderB = nodeB.allLeafChildren[0]?.data?.phaseOrder.value ?? 0;

      return phaseOrderA - phaseOrderB;
    });
  } else {
    nodes.sort((nodeA, nodeB) => {
      const orderInPhaseA = nodeA.data?.orderInPhase.value ?? 0;
      const orderOnSortA = nodeA.data?.orderOnSort.value ?? 0;

      const orderInPhaseB = nodeB.data?.orderInPhase.value ?? 0;
      const orderOnSortB = nodeB.data?.orderOnSort.value ?? 0;

      return isSorted ? orderOnSortA - orderOnSortB : orderInPhaseA - orderInPhaseB;
    });
  }
};

export const onSortChanged = ({ api }: SortChangedEvent<SpreadsheetRowData>) => {
  const updateTransaction: SpreadsheetRowData[] = [];

  api.forEachNode(({ data }) => {
    if (data) {
      updateTransaction.push({ ...data, orderOnSort: { ...data.orderOnSort, value: undefined } });
    }
  });

  api.applyTransaction({
    update: updateTransaction,
  });
};

export const moveCellFocus = (api: GridApi<SpreadsheetRowData>, direction: FocusDirection) => {
  const cell = api.getFocusedCell();
  if (cell) {
    api.clearRangeSelection();
    api.setFocusedCell(cell.rowIndex + direction, cell.column);
  }
};

export const hasCampaignsStatuses = (api: GridApi<SpreadsheetRowData>, statuses: CampaignStatuses[]): boolean => {
  let hasStatuses = false;
  const isCampaignStatus = isEnumValue(CampaignStatuses);
  api.forEachNode(({ data }) => {
    const status = data?.status?.value;
    if (isCampaignStatus(status) && statuses.includes(status)) {
      hasStatuses = true;
    }
  });
  return hasStatuses;
};

export const isMediaPlanEditing = (api: GridApi<SpreadsheetRowData>) => {
  const editingCells = api.getEditingCells();
  return editingCells.length > 0;
};

export const isUndoKey = (event: KeyboardEvent): boolean => {
  const commandKey = IS_MAC_OS ? event.metaKey : event.ctrlKey;
  const undoShortCut = commandKey && event.code === 'KeyZ';

  return undoShortCut;
};

export const isRedoKey = (event: KeyboardEvent): boolean => {
  const commandKey = IS_MAC_OS ? event.metaKey : event.ctrlKey;

  const redoShortCut = commandKey && event.shiftKey && event.code === 'KeyZ';
  return redoShortCut;
};

export const sendToClipboard = (data: Map<UUID, CopyPastedCell[]>, resetCopiedData: () => void) => () => {
  const textPlainArray: string[][] = [];
  const textHTMLArray: string[][] = [];

  for (const [, value] of data) {
    textPlainArray.push(value.map(({ value }) => value ?? ''));
    textHTMLArray.push(value.map(({ color }) => color ?? ''));
  }

  const textPlain = textPlainArray.map(row => row.join('\t')).join('\r\n');
  const textHTML = `<table xmlns="http://www.w3.org/1999/xhtml" cellspacing="0" cellpadding="0" ${COLORS_IDENTIFIER}><tbody>${textPlainArray
    .map(
      (row, rowIndex) =>
        `<tr>${row
          .map((column, columnIndex) => {
            const color = textHTMLArray[rowIndex]?.[columnIndex] ?? '';
            return `<td ${COLOR_IDENTIFIER}="${color}">${column || '<br style="visibility: hidden;">'}</td>`;
          })
          .join('')}</tr>`
    )
    .join('')}</tbody></table>`;

  if (isFirefox(navigator)) {
    const handleCopy = (event: ClipboardEvent) => {
      event.preventDefault();

      event.clipboardData?.setData('text/plain', textPlain);
      event.clipboardData?.setData('text/html', textHTML);

      document.removeEventListener('copy', handleCopy, true);

      resetCopiedData();
    };

    document.addEventListener('copy', handleCopy, true);
    document.execCommand('copy');
  } else {
    navigator.clipboard
      .write([
        new ClipboardItem({
          'text/html': new Blob([textHTML], { type: 'text/html' }),
          'text/plain': new Blob([textPlain], { type: 'text/plain' }),
        }),
      ])
      .then(() => {
        resetCopiedData();
      });
  }
};

export const getValuesFromClipboard = (data?: string): string[][] | undefined => {
  if (!data) return;

  return data.split(/\r\n|\r|\n/).map(colorsRowString => colorsRowString.split('\t'));
};

const parseColorsTable = (table: HTMLTableElement): string[][] => {
  const colorDataSelector = camelCase(COLOR_IDENTIFIER.replace('data-', ''));

  return Array.from(table.rows).reduce<string[][]>((acc, row) => {
    const columns = Array.from(row.children);
    const columnsColors: string[] = [];

    columns.forEach(column => {
      if (column instanceof HTMLElement) {
        columnsColors.push(column.dataset[colorDataSelector] ?? '');
      }
    });

    acc.push(columnsColors);

    return acc;
  }, []);
};

export const getColorsFromClipboard = (data?: string): string[][] | undefined => {
  const colorsContainerElement = document.createElement('div');

  if (!data) return;

  colorsContainerElement.innerHTML = data;
  const colorsElement = colorsContainerElement.querySelector<HTMLTableElement>(`table[${COLORS_IDENTIFIER}]`);

  if (!colorsElement) return;

  return parseColorsTable(colorsElement);
};

export const mergeClipboardData = (values: string[][] = [], colors: string[][] = []): CopyPastedCell[][] => {
  const isValuesPrimary = values.length >= colors.length;
  const [primaryData, secondaryData] = isValuesPrimary ? [values, colors] : [colors, values];

  return primaryData.map((rowValues, rowIndex) =>
    rowValues.map((primaryValue, index) => {
      const secondaryValue = secondaryData[rowIndex]?.[index] ?? '';

      return isValuesPrimary
        ? { color: secondaryValue, value: primaryValue }
        : { color: primaryValue, value: secondaryValue };
    })
  );
};

export const getIndexesRange = (start: number, end: number): number[] => {
  const rangeIndexes = [start];

  Array.from({ length: end - start }, (_, index) => {
    rangeIndexes.push(start + index + 1);
  });

  return rangeIndexes;
};

export const updatePlacementsOnPlatformsChange = (
  context: SpreadsheetContext,
  data: SpreadsheetRowData,
  hasWarning: boolean,
  openToast: (options: ToastOptions) => void
): SpreadsheetRowData['placements'] => {
  const currentPlacementsValue = data.placements.value;
  const processedPlacements = processPlacements(
    data.platforms,
    context.taxonomy,
    currentPlacementsValue?.map(({ name }) => name).join(', ')
  );

  if (
    hasWarning &&
    currentPlacementsValue &&
    currentPlacementsValue.length > 0 &&
    currentPlacementsValue.length !== processedPlacements?.length
  ) {
    openToast({
      id: 'media-plan-placements-warning',
      type: TOAST_TYPE.WARNING,
      message: 'Pasted placements automatically updated to match selected Platforms.',
      preventDuplicate: true,
    });
  }

  return {
    ...data.placements,
    value: processedPlacements ?? null,
  };
};
