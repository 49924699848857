import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { Sort } from 'utils/sort';
import { Checkbox } from '../checkbox';
import { Icon } from '../icon';
import { STableRow } from '../../s-components/table/s-table-row';
import { BodyContent, TableContent, TableElementId, TableRowState } from './constants';
import { TableTheme } from 'app/theme/table';

const classes = bem('table-row');

interface Props<BodyType extends BodyContent, SortField extends string> {
  headerContent: TableContent<BodyType, SortField>[];
  onCheckboxChange?(isChecked: boolean, id: TableElementId): void;
  onExpanded?(isExpanded: boolean, item: BodyContent): void;
  sort: Sort<SortField>;
  isCheckable?: boolean;
  isExpandable?: boolean;
  renderExpandedRow?: (content: BodyType) => React.ReactNode;
  shouldDisableExpandForRow?: (rowData: BodyType) => boolean;
  hasStickyColumns?: boolean;
  theme: TableTheme;
  isExpanded?: boolean;
  isChecked?: boolean;
  rowData: BodyType;
  index: number;
}

export const TableRow = <BodyType extends BodyContent, SortField extends string>({
  rowData,
  headerContent,
  onCheckboxChange,
  sort,
  isCheckable,
  isExpandable,
  theme,
  onExpanded,
  hasStickyColumns,
  isExpanded,
  isChecked,
  shouldDisableExpandForRow,
  index,
}: Props<BodyType, SortField>) => {
  const isEven = index % 2 === 0;

  const handleCheckItem = useCallback(
    (isChecked: boolean, id: TableElementId) => {
      if (onCheckboxChange) {
        onCheckboxChange(isChecked, id);
      }
    },
    [onCheckboxChange]
  );

  const handleExpandItem = useCallback(() => {
    if (onExpanded) {
      onExpanded(!isExpanded, rowData);
    }
  }, [onExpanded, rowData, isExpanded]);

  const renderColumn = useCallback(
    (rowData: BodyType, state: TableRowState, index: number) => {
      return headerContent.map(col => {
        const { dataIndex, render } = col;
        if (!dataIndex || !render) return null;

        return (
          <td key={dataIndex} className={classes('column', { sorted: sort.field === col.sortField })}>
            {render(rowData, state, index)}
          </td>
        );
      });
    },
    [headerContent, sort]
  );
  return (
    <STableRow tableTheme={theme} key={rowData.id} isEven={isEven}>
      {isCheckable && !hasStickyColumns && (
        <td className={classes('column', 'checkbox')}>
          <Checkbox
            name=""
            label=""
            onClick={isChecked => handleCheckItem(isChecked, rowData.id)}
            isChecked={isChecked}
          />
        </td>
      )}
      {isExpandable && !hasStickyColumns && (
        <td
          rowSpan={2}
          className={classes('column', {
            expand: true,
            disabled: !!shouldDisableExpandForRow && shouldDisableExpandForRow(rowData),
          })}
          onClick={handleExpandItem}
          data-selector="expand-table-row-button"
        >
          <Icon name="next" color="black" className={classes('icon-expand', isExpanded ? 'expanded' : 'collapsed')} />
        </td>
      )}
      {renderColumn(rowData, { isRowExpanded: isExpanded, isRowChecked: isChecked }, index)}
    </STableRow>
  );
};
