interface GetEmailClientLinkConfig {
  address: string;
  subject?: string;
}

export const getEmailClientLink = ({ address, subject }: GetEmailClientLinkConfig): string => {
  let link = `mailto:${address}?`;

  if (subject) {
    link += `subject=${subject}`;
  }

  return link;
};
