import { BaseOption } from '../form/select';
import { Id, LinkfireSearch, Optional } from 'backend-api/models';

export type LinkfireSearchOption = BaseOption & Omit<LinkfireSearch, 'id'> & { modelId: Optional<Id> };

export enum LinkfireSearchError {
  DuplicatedLink,
  InvalidLength,
  NotAvailable,
  None,
}
