import { Id } from 'backend-api/models';
import { MediaPlanMode } from 'media-plan/types';
import { useMediaPlanAccessMode } from 'media-plan/hooks';
import { isEditMode } from 'media-plan/components/spreadsheet/transducers';

export const useShowRequestApprovalButton = (projectId: Id, mediaPlanMode: MediaPlanMode) => {
  const accessMode = useMediaPlanAccessMode(projectId, mediaPlanMode);
  const isAccessModeEdit = isEditMode(accessMode);
  const isRegularMode = mediaPlanMode === MediaPlanMode.DEFAULT;

  return isRegularMode && isAccessModeEdit;
};
