import styled from 'styled-components';
import { elipsizeText } from '../s-elipsize-text';
import { colorPalette } from 'app/theme/colors';

export const SLinkLongTableTitle = styled.div`
  display: flex;
  min-width: 0;

  .link-long-table-title {
    &__text {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: ${colorPalette.azureRadiance};
      ${elipsizeText}

      ::after {
        content: '';
        background: ${colorPalette.azureRadiance};
        height: 0.5px;
        display: block;
        width: 100%;
        transform: scaleX(0);
      }
      :hover {
        ::after {
          transform: scaleX(1);
        }
      }
    }
  }
`;
