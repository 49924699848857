import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'artist-team-empty-state';

export const SEmptyState = styled.div`
  .${BEM_CLASS} {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &__button {
      display: flex;
      align-items: center;
      column-gap: 9px;
      cursor: pointer;
    }

    &__subtitle {
      font-weight: 600;
      color: ${colorPalette.azureRadiance};
    }
  }
`;
