import { useField } from 'formik';
import { Input, THEME } from 'gdb-web-shared-components';
import { uniqueId } from 'lodash';
import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SInputField } from './s-input-field';

interface InputFieldProps {
  name: string;
  label?: string;
  validate?(value?: string): string | undefined;
  disabled?: boolean;
  width?: number;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const InputField = React.memo(
  ({ name, validate, width, disabled = false, label, className }: InputFieldProps) => {
    const [field, meta, helpers] = useField({
      name: name,
      validate: validate,
    });

    return (
      <SInputField>
        <Input
          name={name}
          onChange={field.onChange}
          id={uniqueId()}
          value={field.value}
          error={meta.error}
          theme={THEME.light}
          onError={helpers.setError}
          disabled={disabled}
          label={label}
          width={width}
          autoComplete="off"
          className={className}
          inputClassName={classes('input')}
        />
      </SInputField>
    );
  }
);
