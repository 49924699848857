import React, { useCallback } from 'react';
import { SLinkContainer } from './s-link-container';
import { MultiValueGenericProps } from 'react-select';
import { MultiValueContainer } from '../../../../components';
import { LinkOption } from '../../../../types';

type Props<T extends LinkOption> = MultiValueGenericProps<T, true>;

const Root = <T extends LinkOption>(props: Props<T>) => {
  const onMouseDown = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation(), []);

  return (
    <SLinkContainer onMouseDown={onMouseDown}>
      <MultiValueContainer {...props} />
    </SLinkContainer>
  );
};

export const LinkContainer = React.memo(Root) as typeof Root;
