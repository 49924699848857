import styled from 'styled-components';
import { motion } from 'framer-motion';

interface STableEmptyViewProps {
  readonly maxWidth?: number;
}

export const STableEmptyView = styled(motion.tbody)<STableEmptyViewProps>`
  .table-empty-view {
    &__container {
      max-width: ${({ maxWidth }) => `${maxWidth}px` || '100vw'};
    }
  }
`;
