import { ResponseModel } from 'backend-api/models';
import * as t from 'io-ts';
import { withFallback } from 'io-ts-types/withFallback';
import { either } from 'fp-ts/Either';
import { SettingsFilters, SettingsQuery, SettingsTabs } from 'settings/types';
import { ParsedQs } from 'qs';
import { decodeQueryString } from 'utils/filters';

export const SettingsTabFromQueryString = new t.Type(
  'SettingsTabString',
  (u): u is SettingsTabs => typeof u === 'string' && Object.values(SettingsTabs).some(tab => tab === u),
  (input, context) =>
    either.chain(t.string.validate(input, context), s => {
      const tab = typeof s === 'string' && Object.values(SettingsTabs).find(tab => tab === s);
      return tab ? t.success(tab) : t.failure(input, context);
    }),
  encode => encode
);

export const SettingsFiltersSchema = ResponseModel(
  {
    activeTab: withFallback(SettingsTabFromQueryString, SettingsTabs.UserSettings),
  },
  'SettingsFiltersSchema'
);

export const getSettingsFiltersFromSearchQuery = (query: ParsedQs): SettingsFilters => {
  return decodeQueryString(query, SettingsFiltersSchema);
};

export const getSettingsSearchQueryFromFilters = (filters: SettingsFilters): SettingsQuery => {
  return {
    activeTab: filters.activeTab,
  };
};
