import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';
import { transparentize } from 'polished';
import { STICKY_Z_INDEX } from './constants';

export const BEM_CLASS = 'request-approval-footer';

export const SRequestApprovalFooter = styled.div`
  display: grid;
  padding: 20px 30px;
  grid-template-columns: repeat(3, 1fr);
  background-color: ${colorPalette.white};
  box-shadow: 0 0 10px ${transparentize(0.5, colorPalette.coolGray)};
  border-top: 1px solid ${colorPalette.white};

  .${BEM_CLASS} {
    &__info {
      grid-column: 1 / 2;
      display: flex;
      align-items: center;
    }

    &__buttons {
      grid-column: 0 / 4;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    &__warning-container {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__empty-checkboxes-text {
      font-size: 12px;
      color: ${colorPalette.cinnabar};
    }

    &__request-approval-modal {
      position: fixed;
      z-index: ${STICKY_Z_INDEX};
      left: 50%;
      bottom: 24px;
      transform: translateX(-50%);
    }
  }
`;
