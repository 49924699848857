import React from 'react';
import { Icon } from './icon';
import { SAnchorLink } from '../s-components/s-anchor-link';
import { bem } from 'utils/bem';
import { IconName } from './icon/types';

interface Props {
  onClick?: () => void;
  title: string;
  className?: string;
  dataSelector?: string;
  iconName?: IconName;
}

const classes = bem('anchor-link');

export const AnchorLink = ({ onClick, title, className, dataSelector, iconName = 'arrow-down' }: Props) => {
  return (
    <SAnchorLink onClick={onClick} data-selector={`${dataSelector}-anchor-link`} className={className}>
      <div className={classes('anchor-title')}>{title}</div>
      <Icon name={iconName} color="cerulean" size="menu" />
    </SAnchorLink>
  );
};
