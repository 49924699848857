import { Id, LabelPermissionsArtist, LabelPermissionsTeamMembers } from 'backend-api/models';

export enum LabelPermissionsTab {
  MEMBERS = 'Team Members',
  ARTISTS = 'Artists',
}

export interface EditArtistsModalPayload {
  userId: Id;
  data: LabelPermissionsArtist[];
  title: string;
}

export interface EditMembersModalPayload {
  artistId: string;
  data: LabelPermissionsTeamMembers[];
  title: string;
}

export interface EditArtistsRequestPayload {
  labelId: Id;
  userId: Id;
  addedArtistsIds: string[];
  deletedArtistsIds: string[];
}

export interface EditMembersRequestPayload {
  labelId: Id;
  artistId: string;
  addedMembersIds: Id[];
  deletedMembersIds: Id[];
}
