import styled from 'styled-components';
import Tippy from '@tippyjs/react';

export const SPopover = styled(Tippy)`
  background: transparent;
  display: flex;
  text-align: left;

  .tippy-content > div {
    word-break: break-word;
  }

  &[data-placement] {
    .tippy-arrow {
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }
`;
