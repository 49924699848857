import styled from 'styled-components';

export const BEM_CLASS = 'details-header';

export const SDetailsHeader = styled.div`
  display: flex;
  height: 85px;
  align-items: center;
  justify-content: space-between;

  .${BEM_CLASS} {
    &__buttons-container {
      display: flex;
    }

    &__button {
      margin-right: 20px;
    }
  }
`;
