import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const SReportingTableLoader = styled.div`
  height: 402px;
  background: ${transparentize(0.5, manhattanThemeColors.background)};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
