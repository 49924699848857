import { BEM_CLASS as SELECT_BEM_CLASS, SSelect } from '../form/select';
import styled from 'styled-components';
import { colorPalette, themeColors } from '../../../app/theme/colors';
import { inputBorderColor } from 'common/styles/utils';

interface Props {
  isInputDisabled?: boolean;
  hasError?: boolean;
}

export const BEM_CLASS = 'autocomplete-input';

export const SAutocompleteInput = styled(SSelect)<Props>`
  .${BEM_CLASS} {
    &__icon {
      margin-right: 10px;
    }
  }
  .${SELECT_BEM_CLASS} {
    &__menu {
      width: auto;
    }
    &__control {
      cursor: text;
    }
    &__control--is-focused {
      border-color: ${({ isInputDisabled, hasError }) =>
        hasError ? colorPalette.alizarinCrimson : isInputDisabled ? 'transparent' : inputBorderColor(true)};
      background-color: ${colorPalette.white};
      box-shadow: none;
    }
    &__input {
      input {
        background: red;
        min-width: 50px;
      }
    }
    &__multi-value {
      height: 20px;
      display: flex;
      align-items: center;
      border-radius: 2px;
      background-color: ${colorPalette.white};
      box-shadow: 0 0 2px 0 ${themeColors.shadowColor};
      &__remove {
        cursor: pointer;
        color: ${colorPalette.lavenderGray};
        &:hover {
          background: transparent;
        }
      }
      &__label {
        cursor: pointer;
        color: ${colorPalette.azureRadiance};
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
`;
