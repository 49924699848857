import React from 'react';
import { BEM_CLASS, SLegendItem } from './s-legend-item';
import { bem } from 'utils/bem';
import { Body } from 'common/components/typography';

interface Props {
  color: string;
  title: string;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const LegendItem = React.memo(({ color, title, className }: Props) => {
  return (
    <SLegendItem color={color} className={className}>
      <div className={classes('indicator')} />
      <Body className={classes('title')}>{title}</Body>
    </SLegendItem>
  );
});
