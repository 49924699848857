import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { colorPalette } from 'app/theme/colors';

export const TABS_HEIGHT = 55;

export const STabs = styled.div`
  .tabs {
    &__header {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    &__top-container {
      display: flex;
    }
    &__titles-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 2;
      justify-content: flex-end;
      & > :not(:last-child) {
        margin-right: 40px;
      }
    }
    &__tab-title {
      position: relative;
      height: ${TABS_HEIGHT}px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      color: black;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 15px;
      text-transform: uppercase;
      &--active {
        color: ${palette.pink};
      }

      &--disabled {
        opacity: 0.5;
        cursor: default;
      }
    }
    &__tab-highlight {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      bottom: 0;
      &--active {
        background: ${palette.pink};
      }
    }
    &__period-container {
      display: flex;
      align-items: center;
      position: relative;
    }
    &__period-label {
      font-size: 10px;
      letter-spacing: 1px;
      color: ${palette.darkBlueTwo};
      align-self: center;
      text-transform: uppercase;
    }

    &__period-picker {
      margin-left: 10px;
      width: 220px;
    }
    &__tabs-divider {
      height: 1px;
      width: 100%;
      background: ${colorPalette.periwinkleGray};
      position: absolute;
      bottom: 0;
    }
    &__widget-title {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      align-self: center;
    }
  }
`;
