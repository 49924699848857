import styled, { css } from 'styled-components';
import { theme } from 'app/theme';
import { manhattanThemeColors } from 'app/theme/colors';

interface Props {
  position: number;
  isDisabled: boolean;
}

export const BEM_CLASS = 'release-details-popup';

export const SReleaseDetailsPopup = styled(({ className, children }) => children(className))<Props>`
  .${BEM_CLASS} {
    &__root {
      position: absolute;
      z-index: 1;
      background-color: ${manhattanThemeColors.background};
      border-radius: 10px;
      box-shadow: ${theme.shadows.default};
      padding: 20px 20px 30px;
      left: -15px;

      ${({ position }) => css`
        top: ${position}px;
      `}
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 14px;
    }

    &__title {
      margin-right: auto;
    }

    &__close {
      display: flex;
      margin-left: 20px;
    }

    &__content,
    &__select {
      width: 300px;
    }

    &__loading {
      display: flex;
      justify-content: center;
    }

    &__error {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px 0 15px;
    }

    &__error-text {
      text-align: center;
      color: ${manhattanThemeColors.grayedText};
    }

    &__error-refresh {
      padding-top: 10px;
      border-bottom: 1px dotted ${manhattanThemeColors.primaryDark};
    }

    &__content-item {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__content-title {
      color: ${({ isDisabled }) =>
        isDisabled ? manhattanThemeColors.secondGrayedText : manhattanThemeColors.primaryDark};
      padding: 0;
    }

    &__content-title {
      white-space: nowrap;
    }

    &__release-name-input {
      width: 300px;
    }
  }
`;
