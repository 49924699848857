import React, { useCallback, useMemo, useState } from 'react';
import { DragUpdate, DropResult } from 'react-beautiful-dnd';
import { TeamUser, UserRoleType } from 'backend-api/models';
import { DraggableSectionConfig, SelectedSection } from 'common/components/selected-section';
import { SelectedUserItem } from './components';
import { EDITORS_LIMIT, VIEWERS_LIMIT } from 'modals/sharing-modal/constants';
import { searchUserToTeamUser } from 'modals/sharing-modal/transducers';
import { ShareModalListId } from './constants';
import { ShareModalContext } from 'modals/sharing-modal/types';
import { listIdToRole, prepareUsersForDisplay } from './transducers';
import { ROLES } from 'common-v2/constants';

interface Props {
  selectedUsers: TeamUser[];
  onClearAll(): void;
  onRemoveUser(user: TeamUser): void;
  onUpdateUser(user: TeamUser): void;
  context: ShareModalContext;
  className?: string;
  isLoading: boolean;
}

export const SelectedUsersSidebar = React.memo(
  ({ className, selectedUsers, onClearAll, onRemoveUser, onUpdateUser, context, isLoading }: Props) => {
    const editors = useMemo(() => prepareUsersForDisplay(selectedUsers, UserRoleType.Editor), [selectedUsers]);
    const viewers = useMemo(() => prepareUsersForDisplay(selectedUsers, UserRoleType.Viewer), [selectedUsers]);

    const [isShuffleEnabled, setIsShuffleEnabled] = useState(true);

    const renderTeamUserItem = useCallback(
      (user: TeamUser) => (
        <SelectedUserItem
          isEditor={user.roles.some(role => role.id === ROLES.EDITOR.id)}
          user={user}
          onRemove={() => onRemoveUser(user)}
        />
      ),
      [onRemoveUser]
    );

    const onDragEnd = useCallback(
      (result: DropResult) => {
        setIsShuffleEnabled(true);
        if (!result.destination || result.destination.droppableId === result.source.droppableId) return;

        const sourceArray = (function() {
          switch (result.source.droppableId) {
            case ShareModalListId.EDITORS:
              return editors;
            default:
              return viewers;
          }
        })();

        const fromIndex = result.source.index;
        const draggedUser = sourceArray[fromIndex];
        const destinationListId: ShareModalListId = result.destination.droppableId as ShareModalListId;

        if (draggedUser) {
          const newRole = listIdToRole(destinationListId);
          onUpdateUser(searchUserToTeamUser(draggedUser, newRole));
        }
      },
      [onUpdateUser, editors, viewers]
    );

    const onDragUpdate = useCallback((result: DragUpdate) => {
      if (!result.destination) return;
      setIsShuffleEnabled(result.destination.droppableId !== result.source.droppableId);
    }, []);

    const artistTeamSectionsConfig: DraggableSectionConfig<TeamUser>[] = useMemo(
      () => [
        {
          id: ShareModalListId.EDITORS,
          title: 'Editors',
          placeholder: 'No selected editors',
          selectedItems: editors,
          renderItem: renderTeamUserItem,
          draggable: false,
          dropOutsideEnabled: editors.length !== EDITORS_LIMIT,
          maxCount: EDITORS_LIMIT,
          withCounter: true,
          isShuffleEnabled,
        },
      ],
      [renderTeamUserItem, editors, isShuffleEnabled]
    );

    const collaboratorsSectionConfig: DraggableSectionConfig<TeamUser>[] = useMemo(
      () => [
        {
          id: ShareModalListId.EDITORS,
          title: 'Editors',
          placeholder: 'No selected editors',
          selectedItems: editors,
          renderItem: renderTeamUserItem,
          draggable: true,
          dropOutsideEnabled: editors.length !== EDITORS_LIMIT,
          maxCount: EDITORS_LIMIT,
          withCounter: true,
        },
        {
          id: ShareModalListId.VIEWERS,
          title: 'Viewers',
          placeholder: 'No selected viewers.',
          selectedItems: viewers,
          renderItem: renderTeamUserItem,
          draggable: true,
          dropOutsideEnabled: viewers.length !== VIEWERS_LIMIT,
          maxCount: VIEWERS_LIMIT,
          withCounter: true,
        },
      ],
      [editors, renderTeamUserItem, viewers]
    );

    return (
      <SelectedSection
        dragEnabled
        onDragUpdate={onDragUpdate}
        onDragEnd={onDragEnd}
        title={`Selected (${selectedUsers.length})`}
        placeholder="No selected users"
        onResetClick={onClearAll}
        sectionsConfig={
          context === ShareModalContext.ARTIST_TEAM ? artistTeamSectionsConfig : collaboratorsSectionConfig
        }
        className={className}
        isLoading={isLoading}
        disabled={selectedUsers.length < 1 || isLoading}
      />
    );
  }
);
