import { paths } from 'app/routing/paths';
import React from 'react';
import { Link } from 'react-router-dom';
import { bem } from 'utils/bem';
import { SArtistLink, BEM_CLASS } from './s-artist-link';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { LabelPermissionsArtist } from 'backend-api/models';

interface ArtistLinkProps {
  artist: LabelPermissionsArtist;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ArtistLink = React.memo(({ artist, className }: ArtistLinkProps) => {
  return (
    <SArtistLink className={className} data-selector="artist-link-item">
      <Link
        rel="noopener noreferrer"
        target="_blank"
        to={paths.artist(artist.id)}
        data-selector={`artist-link-item-${artist.id}`}
      >
        <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('text')} key={artist.id}>
          {artist.name}
        </Typography>
      </Link>
    </SArtistLink>
  );
});
