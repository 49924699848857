import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'campaign-item';

export const SCampaignItem = styled.div`
  display: flex;

  .${BEM_CLASS} {
    &__checkbox-container {
      flex-shrink: 0;
      margin-right: 10px;
    }

    &__text-container {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    &__title-container {
      display: flex;
      align-items: center;
      max-width: 100%;
    }

    &__title {
      ${elipsizeText};
      line-height: 25px;
    }

    &__sub-title {
      color: ${manhattanThemeColors.grayedText};
    }

    &__chip {
      margin-left: 10px;
    }
  }
`;
