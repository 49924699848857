import React, { useCallback } from 'react';
import ReactModal from 'react-modal';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { ReusableModalProps } from './types';
import { ModalHeader, ModalFooter, ModalSideBar } from './components';

const classes = bem('reusable-modal');

export const ReusableModal = ({
  children,
  isOpen,
  title,
  searchValue,
  searchPlaceholder,
  onSearchChange,
  sideBarConfig,
  footerConfig,
  cancelText,
  submitText,
  submitTooltipContent,
  submitTooltipEnabled,
  isSubmitDisabled,
  hasCloseIcon = true,
  hasSearch = false,
  hasFooter = false,
  hasFooterDivider = true,
  hasHeader = true,
  onClose,
  onAfterClose,
  onSubmit,
  shouldCloseOnUserInteraction = true,
  dataSelector,
  className,
  headerClassName,
}: ReusableModalProps) => {
  const onAfterOpenModal = useCallback(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const onAfterCloseModal = useCallback(() => {
    document.body.style.overflow = '';
    onAfterClose && onAfterClose();
  }, [onAfterClose]);

  return (
    <ReactModal
      isOpen={isOpen}
      closeTimeoutMS={300}
      portalClassName={className}
      className={classes('react-modal-content')}
      overlayClassName={classes('react-modal-overlay')}
      onAfterOpen={onAfterOpenModal}
      onAfterClose={onAfterCloseModal}
      onRequestClose={onClose}
      shouldCloseOnEsc={shouldCloseOnUserInteraction}
      shouldCloseOnOverlayClick={shouldCloseOnUserInteraction}
    >
      <div className={classes('container')} data-selector={dataSelector}>
        <div className={classes('content-with-sidebar')}>
          <div className={classes('content')}>
            {hasHeader && (
              <ModalHeader
                title={title}
                hasSideBar={!!sideBarConfig}
                hasSearch={hasSearch}
                searchValue={searchValue}
                searchPlaceholder={searchPlaceholder}
                onSearchChange={onSearchChange}
                dataSelector={dataSelector}
                className={headerClassName}
              />
            )}
            {children}
          </div>
          {sideBarConfig && (
            <ModalSideBar
              width={sideBarConfig.width}
              className={classes('sidebar', undefined, sideBarConfig.className)}
            >
              {sideBarConfig.component}
            </ModalSideBar>
          )}
        </div>
        {footerConfig && footerConfig.component}
        {hasFooter && (
          <>
            {hasFooterDivider && <div className={classes('divider')} />}
            <ModalFooter
              cancelText={cancelText}
              submitText={submitText}
              submitTooltipContent={submitTooltipContent}
              submitTooltipEnabled={submitTooltipEnabled}
              isSubmitDisabled={isSubmitDisabled}
              onCancel={onClose}
              onSubmit={onSubmit}
              dataSelector="reusable-modal"
            />
          </>
        )}
        {hasCloseIcon && (
          <Icon
            onClick={onClose}
            name="close"
            size="general"
            dataSelector="artists-modal"
            className={classes('close-icon')}
          />
        )}
      </div>
    </ReactModal>
  );
};
