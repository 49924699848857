import styled from 'styled-components';

import {
  BUDGET_WIDGET_FIRST_ROW_PERCENT_WIDTH,
  BUDGET_WIDGET_SECOND_ROW_LEFT_OFFSET,
  BUDGET_WIDGET_SECOND_ROW_PERCENT_WIDTH,
} from 'project/constants';

export const BEM_CLASS = 'budget-details-grid-header';

export const SBudgetDetailsGridHeader = styled.div`
  width: 100%;
  margin-bottom: 12px;
  display: flex;

  .${BEM_CLASS} {
    &__budget-and-allocated-container {
      width: ${BUDGET_WIDGET_FIRST_ROW_PERCENT_WIDTH}%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__utilization-and-total-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: ${BUDGET_WIDGET_SECOND_ROW_LEFT_OFFSET}px;
      width: calc(${BUDGET_WIDGET_SECOND_ROW_PERCENT_WIDTH}% - ${BUDGET_WIDGET_SECOND_ROW_LEFT_OFFSET}px);
    }

    &__text {
      text-transform: uppercase;
    }
  }
`;
