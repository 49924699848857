import styled from 'styled-components';
import { motion } from 'framer-motion';
import { TableTheme } from 'app/theme/table';
import { colorPalette } from 'app/theme/colors';

interface Props {
  isEven?: boolean;
  tableTheme: TableTheme;
}

export const SExpandedRow = styled(motion.tr)<Props>`
  height: 1px;
  .expanded-row {
    &__empty-cell {
      height: 0;
      padding: 0;
    }
    &__empty-checkbox {
      padding: 0;
      background-color: ${colorPalette.white};
    }
    &__expanded-container {
      display: flex;
      flex-direction: column;
      background: ${({ theme, isEven, tableTheme }) =>
        isEven ? theme.table[tableTheme].rowBackgroundColor.even : theme.table[tableTheme].rowBackgroundColor.odd};
      &--is-overflowed {
        overflow: hidden;
      }
    }
    &__row-container {
      display: flex;
      width: 100%;
      & > :not(:first-child) {
        & > * {
          padding: 0 10px;
        }
      }

      & > :first-child {
        & > * {
          padding: 0 10px 0 20px;
        }
      }
      & > :last-child {
        & > * {
          padding: 0 20px 0 10px;
        }
      }
    }
    &__expanded-column {
      width: 100%;
      align-items: center;
      flex: 1;
      overflow: hidden;
      & > * {
        height: 60px;
        min-height: 60px;
        display: flex;
        align-items: center;
      }
      &--sorted {
        background: ${({ tableTheme, theme }) => theme.table[tableTheme].rowSelectedBackground};
      }
    }
  }
`;
