import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'option';

export const SOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-out, color 0.2s ease-out;

  &:hover {
    background-color: ${transparentize(0.9, colorPalette.azureRadiance)};
    color: ${colorPalette.azureRadiance};

    .${BEM_CLASS}__icon {
      opacity: 1;
      visibility: visible;
    }
  }

  .${BEM_CLASS} {
    &__text {
      color: inherit;
      ${elipsizeText};
    }

    &__icon {
      color: ${colorPalette.azureRadiance};
      margin-left: 20px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
    }
  }
`;
