import styled from 'styled-components';

export const BEM_CLASS = 'filters';

export const SFilters = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__filter {
      margin-right: 20px;

      &--growed {
        flex-grow: 1;
      }

      &--date-picker {
        width: 240px;
      }
    }

    &__search {
      max-width: 320px;
    }
  }
`;
