import React, { ReactElement } from 'react';
import { SMenuDropdown } from './s-menu-dropdown';
import { Icon } from '../icon';
import { bem } from 'utils/bem';
import { DropdownOptionProps } from './components';
import { Direction } from '../dropdown';

interface Props {
  className?: string;
  disabled?: boolean;
  trigger?: React.ReactElement;
  children: ReactElement<DropdownOptionProps>[] | ReactElement<DropdownOptionProps>;
  initialDirection?: Direction;
  dataSelector?: string;
}

const classes = bem('menu-dropdown');

export const MenuDropdown = ({
  disabled = false,
  className,
  children,
  initialDirection,
  dataSelector,
  trigger = (
    <Icon
      className={classes('icon', { disabled })}
      size="menu"
      name="ellipsis-menu"
      color="black"
      dataSelector="campaign-menu-dropdown"
    />
  ),
}: Props) => {
  return (
    <SMenuDropdown
      dropdownClassName={classes('menu-dropdown-list')}
      buttonClassName={classes('icon-button', { disabled })}
      variant="secondary-gray"
      trigger={trigger}
      className={className}
      initialDirection={initialDirection}
      dataSelector={dataSelector}
    >
      {children}
    </SMenuDropdown>
  );
};
