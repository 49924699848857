import { colorPalette } from 'app/theme/colors';
import { ServerErrorResponse } from 'backend-api';
import { ProjectStatus, StatusId } from 'backend-api/models';
import { IconName } from './components/icon/types';
import { MetricId } from 'common/types';
import { BaseOption } from './components/select';

// TODO move it to projects?
export const DEFAULT_DATE_FORMAT = 'MM/dd/yy';
export const SHORT_DATE_FORMAT = 'MM/dd/yy';
export const REQUEST_DATE_FORMAT = 'MM-dd-yyyy';
export const ANALYTICS_AXIS_DATE_FORMAT = 'd MMM';
export const ANALYTICS_TOOLTIP_SHORTEN_DATE_FORMAT = 'M/d/yyyy';
export const ANALYTICS_TOOLTIP_DATE_FORMAT = 'eeee, M/d/yyyy';
export const DEFAULT_DATE_FORMAT_A = 'MM/dd/yyyy hh:mm a';
export const HISTORY_DATE_FORMAT = 'MM/dd/yy';
export const HISTORY_DATE_FORMAT_A = "MM/dd/yy 'at' HH:mm";
export const SHORT_DATE_REQUEST_FORMAT = 'yyyy-MM-dd';
export const EXPORT_FILENAME_DATE_FORMAT = 'yyyy-MM-dd_HH-mm-ss';
// https://stackoverflow.com/a/31242199/6190198
export const SHORTEN_NUMERAL_FORMAT = '0.[0]a';
export const SHORTEN_CURRENCY_FORMAT = '$0.[0]a';
export const HERO_CURRENCY_FORMAT = '$0.[00]a';
export const PERCENT_FORMAT = '0.0%';
export const SHORTEN_PERCENT_FORMAT = '0%';
export const NUMERAL_FORMAT = '0,0';
export const CURRENCY_FORMAT = '$0,0.[00]';
export const PERFORMANCE_CURRENCY_FORMAT = '$0,0.00';
export const PERFORMANCE_RATE_FORMAT = '0,0.0';
export const DECIMAL_NUMERAL_FORMAT = '0,0.00';
export const PHONE_FORMAT = '+999999999999999';
export const DATE_RANGE_INPUT_FORMAT = '99/99/99–99/99/99';
export const DATE_SINGLE_INPUT_FORMAT = '99/99/99';

export const USD_CURRENCY_CODE = 'USD';
export const DEFAULT_CURRENCY_CODE = USD_CURRENCY_CODE;
export const DEFAULT_CURRENCY_SIGN = 'US$';

export const CURRENCY_CODE_TO_SIGN = {
  AED: 'د.إ',
  ARS: '$',
  AUD: '$',
  BAM: 'KM',
  BBD: '$',
  BHD: 'BD',
  BMD: '$',
  BOB: 'Bs',
  BRL: '$',
  BWP: 'P',
  CAD: '$',
  CHF: 'Fr',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUP: '$',
  CYP: '£',
  CZK: 'Kč',
  DKK: 'kr.',
  DOP: '$',
  EEK: 'kr',
  EGP: '£',
  EUR: '€',
  GBP: '£',
  GNF: 'Fr',
  GTQ: 'Q',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  ISK: 'kr',
  JMD: '$',
  JOD: 'د.ا',
  JPY: '¥',
  KES: '/-',
  KRW: '₩',
  KWD: 'د.ك',
  KZT: '₸',
  LBP: 'LL',
  LKR: '₨',
  LSL: 'L',
  LTL: 'Lt',
  LVL: 'Ls',
  MAD: 'DH',
  MKD: 'den',
  MTL: '₤',
  MUR: '₨',
  MVR: 'Rf',
  MXN: '$',
  MYR: 'RM',
  NGN: '₦',
  NIO: '$',
  NOK: 'kr',
  NPR: 'Rs',
  NZD: '$',
  OMR: 'RO',
  PAB: 'B/.',
  PEN: 'S/',
  PHP: '₱',
  PKR: 'Rs',
  PLN: 'zł',
  PYG: '₲',
  QAR: 'QR',
  ROL: 'L',
  RUB: '₽',
  RWF: 'RF',
  SAR: 'SR',
  SEK: 'kr',
  SGD: '$',
  SIT: '€',
  SKK: 'Sk',
  SVC: '₡',
  SZL: 'L',
  THB: '฿',
  TND: 'DT',
  TRL: '₤',
  TWD: '$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYU: '$',
  VEB: 'Bs.',
  VND: '₫',
  XAF: 'FCFA',
  XOF: 'CFA',
  YER: '﷼',
  ZAR: 'R',
  ZMK: 'K',
  BGN: 'Лв.',
  RON: 'L',
  CSD: 'din',
  TRY: '₺',
  VEF: 'Bs.',
  RSD: 'din',
  VES: 'Bs.',
  AFN: '؋',
  GHS: '₵',
  MZN: 'MT',
  ZMW: 'K',
};

export const CURRENCY_FORMATS = {
  default: '0,0.[00]',
  fixedDecimal: '0,0.00',
  short: '0.[0]a',
  hero: '0.[00]a',
};

export const DEFAULT_PROJECT_HEADER_HEIGHT = 130;
export const TOP_HEADER_HEIGHT = 50;
export const MANHATTAN_HEADER_HEIGHT = 60;
export const MAIN_FOOTER_HEIGHT = 50;
export const MAIN_FOOTER_PADDING_TOP = 10;

//campaign board
export const CAMPAIGN_BOARD_MIN_COL_COUNT = 2;
export const MAX_PHASES_COUNT = 5;
export const CAMPAIGN_BOARD_ROW_HEIGHT = 60;

//phases
export type ReleaseType = 'track' | 'album';
export interface ReleaseOption {
  id: number;
  name: ReleaseType;
}

export const RELEASE_OPTIONS: ReleaseOption[] = [
  { id: 0, name: 'track' },
  { id: 1, name: 'album' },
];

export const appearingAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { type: 'tween', duration: 0.4, when: 'beforeChildren' },
};

// patterns
// TODO - install some normal library for validations
export const PATTERNS = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  phone: /^\+[0-9]{9,15}$/,
  link: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
};

// validation messages
export const VALIDATION_MESSAGES = {
  required: 'This field is required',
  maxLength: 'Entered text is too long',
  email: 'Invalid email address!',
  phone: 'Phone number is not correct!',
  number: 'Must be a number.',
  currency: 'Maximum value should be less than 100,000,000',
};

export enum Status {
  Scheduled = 'Upcoming',
  InProgress = 'In Progress',
  Completed = 'Completed',
}

export const STATUS_BY_ID: Record<StatusId, Status> = {
  1: Status.Scheduled,
  2: Status.InProgress,
  3: Status.Completed,
};

export const ID_BY_STATUS: Record<Status, StatusId> = {
  [Status.Scheduled]: 1,
  [Status.InProgress]: 2,
  [Status.Completed]: 3,
};

export const STATUS_ICONS_BY_ID: Record<StatusId, IconName> = {
  1: 'scheduled',
  2: 'in-progress',
  3: 'check',
};

export const ACCESS_RESTRICTED_CODES = ['project_access_restricted', 'unclaimed_project_access_restricted'];

export const CAMPAIGN_ACCESS_RESTRICTED_CODES = ['unassigned_campaign_access_restricted', 'ad_set_view_restricted'];

//Campaign creation widget types
export enum Widget {
  Text = 'text',
  Select = 'select',
  MultiSelect = 'multi-select',
  GroupSelect = 'group-select',
  Notes = 'notes',
  Links = 'links',
  Date = 'date',
  RadioGroup = 'radio-group',
  Toggle = 'toggle',
  Currency = 'currency',
  PhoneNumber = 'phone-number',
  FlatInput = 'flat-input',
  RangePicker = 'range-picker',
  Autocomplete = 'autocomplete',
  LinkfireSearch = 'linkfire-search',
  BadgeSelector = 'badge-selector',
  LimitedTextField = 'limited-text-field',
  CreatableSelect = 'creatable-select',
  ActionableSelect = 'actionable-select',
}

export type CampaignColor = 'cerulean' | 'amethyst' | 'mountainMeadow' | 'uncategorizedGray';

export const DEBOUNCE_TIME_SHORT = 100;
export const DEBOUNCE_TIME = 300;
export const DEBOUNCE_TIME_LONG = 1000;

// TODO: refactor
export const CAMPAIGN_TYPE_ID_DISPLACEMENT = 10000;
export const CAMPAIGN_SUBTYPE_ID_DISPLACEMENT = 5000;

export const MAX_CURRENCY_VALUE = 100000000;

export const DEFAULT_PAGINATION_LIMIT = 20;
export const ARTIST_SEARCH_LIMIT = 30;

export const ITEMS_PER_PAGE = [
  { text: '10 / Page', value: 10, id: 1 },
  { text: '20 / Page', value: 20, id: 2 },
  { text: '30 / Page', value: 30, id: 3 },
  { text: '40 / Page', value: 40, id: 4 },
];

export const collaborationFilterOptions = [
  {
    id: 0,
    code: 'myProjects',
    name: 'My Projects',
  },
  {
    id: 1,
    code: 'sharedByMe',
    name: 'Shared by Me',
  },
  {
    id: 2,
    code: 'sharedToMe',
    name: 'Shared with Me',
  },
];

export const DEFAULT_MIN_SEARCH_LENGTH = 2;

export const SEARCH_DEBOUNCE_TIME = 800;
export const BUTTON_THROTTLE_TIME = 2000;

export const PAGINATION_PAGES_LIMIT = 5;

export const PAGINATION_OFFSET = 2;

export const AMPLITUDE_EVENT_NAMES = {
  newSession: 'new session',
  projectDetailPageViewed: 'project details page viewed',
  projectDashboardPageViewed: 'projects list page viewed',
  projectCalendarPageViewed: 'project calendar page viewed',
  campaignDetailPageViewed: 'campaign details page viewed',
  projectDetailsEdited: 'project details edited',
  campaignDetailsEdited: 'campaign details edited',
  createCampaignCompleted: 'create campaign completed',
  createCampaignTimeSpent: 'create campaign time spent',
  campaignsExported: 'campaigns exported',
  projectsExported: 'projects exported',
  projectShared: 'project shared',
  signOut: 'sign out',
  filterPanelOpened: 'filter panel opened',
  filterApplied: 'filter applied',
  createCampaignStarted: 'create campaign started',
  createCampaignChangesDiscarded: 'create campaign changes discarded',
};

export const MAX_DATE_MILLIS = 1e15;

export interface SimpleOption {
  id: number;
  name: string;
  description?: string;
  isDivided?: boolean;
}

export interface TreeOption {
  id: number;
  label: string;
  name: string;
  parentId: number | null;
  isDisabled?: boolean;
}

export interface SimpleSectionOption {
  label: string;
  options: SimpleOption[];
}

export const CAMPAIGN_DEFAULT_AUDIENCE_LOVER_AGE = 0;
export const CAMPAIGN_DEFAULT_AUDIENCE_HIGHER_AGE = 65;

export const MAX_AGE = 99;
export const MIN_AGE = 13;
export const AGE_STEPS = [20, 30, 40, 50, 60, 70, 80, 90];
export const DEFAULT_TERRITORY = 231; //United States
export const DEFAULT_PROVIDER = 1; //Sony Music
export const DEFAULT_GENDERS = [1, 2, 3]; //Male, Female, Non-binary

export const PLURAL_EXCEPTIONS = ['day'];

export const DAYS_IN_YEAR = 366;

export const DEFAULT_OBJECTIVE_ID = 11;

export const INVALID_DATE_RANGE_ERROR_CODE = 'invalid_date_range';

export interface InvalidDateRangeErrorResponse extends ServerErrorResponse {
  code: typeof INVALID_DATE_RANGE_ERROR_CODE;
  extra: {
    max_period: string;
  };
}

export enum ReleasesTypes {
  PRODUCT_FAMILIES,
  PLAYLISTS,
  EMPTY,
}

export const GLOBAL_TERRITORY_ID = 0;

export const UPCOMING_STATUS = {
  id: 1,
  name: 'Upcoming',
};

export const IN_PROGRESS_STATUS = {
  id: 2,
  name: 'In Progress',
};

export const COMPLETED_STATUS = {
  id: 3,
  name: 'Completed',
};

export const UNASSIGNED_STATUS = {
  id: 4,
  name: 'Unclaimed',
};

export const PROJECT_STATUSES: ProjectStatus[] = [
  IN_PROGRESS_STATUS,
  UPCOMING_STATUS,
  COMPLETED_STATUS,
  UNASSIGNED_STATUS,
];

export const CAMPAIGN_CATEGORY_GROUPS = {
  digital: 1,
  traditional: 2,
  radio: 3,
  other: 4,
};

export const PURCHASE_ORDERS_STATUSES: BaseOption[] = [
  {
    id: 0,
    value: 'Failed',
    info: 'This PO failed validation in the approval flow.',
  },
  {
    id: 1,
    value: 'Unapproved',
    info: 'This PO has been raised.',
  },
  {
    id: 2,
    value: 'Rejected',
    info: 'This PO has been reviewed and rejected.',
  },
  {
    id: 3,
    value: 'Resubmitted',
    info: 'This PO has been re-raised after an initial review.',
  },
  {
    id: 4,
    value: 'Removed',
    info: 'This PO has been officially removed from PRS.',
  },
  {
    id: 5,
    value: 'Returned',
    info: 'This PO has been reviewed and returned to the creator.',
  },
  {
    id: 6,
    value: 'Approved',
    info: 'This PO is approved but the Goods Receipt has not been applied.',
  },
  {
    id: 7,
    value: 'Processed',
    info: 'This Blanket PO is underway.',
  },
  {
    id: 8,
    value: 'Payable',
    info: 'This PO is fully approved and Goods Receipted.',
  },
  {
    id: 9,
    value: 'Partially Paid',
    info: 'This PO has been partially paid.',
  },
  {
    id: 10,
    value: 'Paid',
    info: 'This PO has been paid (allowing up to 10% or $100 to be unpaid).',
  },
  {
    id: 11,
    value: 'Pending',
  },
  {
    id: 12,
    value: 'Unknown',
  },
  {
    id: 14,
    value: 'Invoiced',
    info: 'All items on the PO are linked to an invoice.',
  },
  {
    id: 17,
    value: 'Closed',
    info: 'PO has been fully invoiced and all invoices have been posted all paid.',
  },
  {
    id: 18,
    value: 'Sent',
    info: 'PO is open - has open un-invoiced items on it.',
  },
];

export const PO_NAME_SEPARATOR = ' | ';

export const HTTPS_LINK_PREFIX = 'https://';

export const CAMPAIGN_LINKFIRE_LINKS_LIMIT = 5;
export const CAMPAIGN_CREATIVE_LINKS_LIMIT = 5;
export const LINKFIRE_LINKS_SEARCH_RESULT_LIMIT = 5;

export const FAKE_ALL_METRICS_OBJECTIVE_ID = -1;
export const FAKE_ALL_METRICS_OBJECTIVE_NAME = 'All Metrics';

export const RELEASES_POPOVER_WIDTH = 326;

export const ICON_COLOR_TO_COLOR = {
  violet: colorPalette.mediumPurple,
  pink: colorPalette.wildStrawberry,
  black: colorPalette.black,
  white: colorPalette.white,
  mountainMeadow: colorPalette.mountainMeadow,
  green: colorPalette.chateauGreen,
  blue: colorPalette.azureRadiance,
  darkBlue: colorPalette.mariner,
  red: colorPalette.pomegranate,
  yellow: colorPalette.buddhaGold,
  christine: colorPalette.christine,
  cerulean: colorPalette.cerulean,
  amethyst: colorPalette.amethyst,
  uncategorizedGray: colorPalette.gray,
  eastBay: colorPalette.eastBay,
  inherit: 'inherit',
  wildStrawberry: colorPalette.wildStrawberry,
  snuff: colorPalette.snuff,
  default: colorPalette.lavenderGray,
  cinnabar: colorPalette.cinnabar,
  jade: colorPalette.jade,
  fiordGrey: colorPalette.fiordGrey,
};

export const ALL_PHASES_OPTION: SimpleOption = { id: -1, name: 'All Phases' };

export const STREAMS_REPORTING_METRICS: MetricId[] = [
  'streams',
  'leanForwardStreams',
  'leanBackStreams',
  'spotifyStreams',
  'spotifyLeanForwardStreams',
  'spotifyLeanBackStreams',
  'appleStreams',
  'appleLeanForwardStreams',
  'appleLeanBackStreams',
  'amazonStreams',
  'amazonLeanBackStreams',
  'amazonLeanForwardStreams',
  'revenueStreams',
  'revenueSpotifyStreams',
  'revenueAppleStreams',
  'revenueAmazonStreams',
];

export const DSP_REPORTING_METRICS: MetricId[] = [
  'videoViews',
  'facebookVideoViews',
  'facebookThruplays',
  'googleVideoViews',
  'impressions',
  'facebookImpressions',
  'googleImpressions',
  'clicks',
  'facebookClicks',
  'googleClicks',
  'reach',
  'conversions',
  'engagements',
  'facebookReactions',
  'facebookComments',
  'googleEngagements',
  'cpm',
  'facebookCpm',
  'googleCpm',
  'spend',
  'facebookSpend',
  'googleSpend',
  'linkfireClicks',
  'linkfirePageViews',
  'linkfireEvents',
];

export const FOLLOWERS_REPORTING_METRICS: MetricId[] = [
  'followers',
  'instagramFollowers',
  'spotifyFollowers',
  'twitterFollowers',
  'facebookLikes',
  'appleFollowers',
  'youtubeChannelSubscribers',
];

export const ESTIMATED_REVENUE_METRICS: MetricId[] = [
  'revenueStreams',
  'revenueAppleStreams',
  'revenueAmazonStreams',
  'revenueSpotifyStreams',
];

export const AVERAGE_METRICS: MetricId[] = ['cpm', 'facebookCpm', 'googleCpm'];

export const CURRENCY_METRICS: MetricId[] = [
  'spend',
  'facebookSpend',
  'googleSpend',
  'cpm',
  'facebookCpm',
  'googleCpm',
  'revenueStreams',
  'revenueSpotifyStreams',
  'revenueAmazonStreams',
  'revenueAppleStreams',
];

export const NON_CUMULATIVE_METRICS: MetricId[] = [
  'followers',
  'instagramFollowers',
  'spotifyFollowers',
  'twitterFollowers',
  'facebookLikes',
  'appleFollowers',
  'youtubeChannelSubscribers',
];

export const MIN_CHART_BAR_THICKNESS = 5;

export const GLOBAL_COUNTRY_OPTION: BaseOption = { id: GLOBAL_TERRITORY_ID, value: 'Global' };

export const BREAKDOWNS_UNAVAILABLE_WITH_COUNTRIES = [1, 2, 3, 5];

export const INITIAL_DATE_FORMAT = 'MM/dd/yy';
export const INITIAL_DATE_TIME_FORMAT = 'MM/dd/yy, hh:mm a';

export const INVALID_LINK_LABEL = 'Invalid link';

export const IS_OLD_AUTH = process.env.USE_OLD_AUTH === 'true';

export const DISABLED_DATA_HEALTH_METRICS = [
  'followers',
  'instagramFollowers',
  'spotifyFollowers',
  'twitterFollowers',
  'facebookLikes',
  'appleFollowers',
  'youtubeChannelSubscribers',
];

export const MS_PER_DAY = 24 * 60 * 60 * 1000;

export const PAGE_WIDTH = 1240;

export const SCROLLBAR_WIDTH = window.innerWidth - document.body.clientWidth;

export const IS_MAC_OS = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);

export const GLOBAL_SEARCH_WIDTH = 1180;
