import * as t from 'io-ts';
import { IdNameField, ResponseModel } from '../../common';
import { ObjectiveSchema } from '../common';

export const GoalSchema = ResponseModel(
  {
    ...IdNameField,
    objectives: t.array(ObjectiveSchema),
  },
  'Goal'
);

export type Goal = t.TypeOf<typeof GoalSchema>;

export const GoalIdSchema = ResponseModel(IdNameField, 'GoalId');

export type GoalId = t.TypeOf<typeof GoalIdSchema>;
