import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Badge, BADGE_TYPE, Icon, ICON_SIZE, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import placeholderPng from 'assets/cd-note-65.png';
import { Artist, CampaignSearch, Playlist } from 'backend-api/models';
import { bem } from 'utils/bem';
import { paths } from 'app/routing/paths';
import { CardContent, CardImage } from 'common-v2/components';
import { getJoinedNames, getPrimaryTargets } from 'common-v2/transducers';
import { CARD_SIZE } from '../../constants';
import { formatDatePeriodForCampaignCard, getCampaignIcon } from './transducers';
import { SCampaignCard, BEM_CLASS } from './s-campaign-card';

interface CampaignCardProps extends CampaignSearch {
  className?: string;
  dateFormat: string;
  withBadges?: boolean;
}

const classes = bem(BEM_CLASS);

export const CampaignCard = React.memo(
  ({
    className,
    dateFormat,
    withBadges = false,
    uuid,
    name,
    project,
    startDate,
    endDate,
    platforms,
  }: CampaignCardProps) => {
    const campaignIconName = useMemo(() => getCampaignIcon(platforms), [platforms]);
    const targetsNames = useMemo(() => {
      if (!project) return '';

      const primaryTargets = getPrimaryTargets(project.targets.items);
      return getJoinedNames<Artist | Playlist>(primaryTargets);
    }, [project]);
    const date = useMemo(() => formatDatePeriodForCampaignCard(dateFormat, startDate, endDate), [
      dateFormat,
      endDate,
      startDate,
    ]);
    const link = project?.id ? paths.campaign(project.id, uuid) : paths.unassignedCampaign(uuid);

    return (
      <Link to={`${link}?fromSearch=1`}>
        <SCampaignCard className={className} dataSelector="campaign-card" size={CARD_SIZE} hasAction>
          {campaignIconName ? (
            <div className={classes('icon')} data-selector="campaign-card-icon">
              <Icon name={campaignIconName} size={ICON_SIZE.medium} />
            </div>
          ) : (
            <CardImage placeholder={placeholderPng} />
          )}

          <CardContent className={classes('content')}>
            <Typography className={classes('name')} type={TYPOGRAPHY_TYPE.body2} data-selector="campaign-card-name">
              {name}
            </Typography>

            <Typography className={classes('info')} type={TYPOGRAPHY_TYPE.body4}>
              {withBadges && (
                <Badge
                  content="Campaign"
                  type={BADGE_TYPE.tertiary}
                  className={classes('badge')}
                  data-selector="campaign-card-campaign-badge"
                />
              )}
              <span className={classes('date')} data-selector="campaign-card-date">
                {date}&nbsp;{targetsNames.length > 0 && '•'}&nbsp;
              </span>
              <span className={classes('targets')} data-selector="campaign-card-targets">
                {targetsNames}
              </span>
            </Typography>
          </CardContent>
        </SCampaignCard>
      </Link>
    );
  }
);
