import styled, { css } from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { colorPalette } from 'app/theme/colors';

interface Props {
  isDisabled: boolean;
}

export const SSingleValue = styled.div<Props>`
  ${elipsizeText};
  line-height: initial;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          color: ${colorPalette.gray};
        `
      : css`
          color: ${colorPalette.vulcan};
        `}
`;
