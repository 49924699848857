import React from 'react';
import { Skeleton } from '../skeleton';

export const ContentLoader = React.memo(() => (
  <Skeleton width={275} height={65}>
    <rect x="0" y="0" rx="0" ry="0" width="15" height="15" />
    <rect x="0" y="25" rx="0" ry="0" width="15" height="15" />
    <rect x="0" y="50" rx="0" ry="0" width="15" height="15" />
    <rect x="25" y="0" rx="0" ry="0" width="150" height="15" />
    <rect x="25" y="25" rx="0" ry="0" width="250" height="15" />
    <rect x="25" y="50" rx="0" ry="0" width="150" height="15" />
  </Skeleton>
));
