import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SArtistPlaylistWidgetLoader = styled(motion.div)`
  border-radius: 10px;
  background-color: white;
  padding-top: 30px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 400px;
`;
