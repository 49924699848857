import React from 'react';
import { bem } from 'utils/bem';
import { SRecentSearchLoader, BEM_CLASS, CLIP_PATH_ID } from './s-recent-search-loader';

const classes = bem(BEM_CLASS);

const ITEM_OFFSET_Y = 35;
const ITEM_HEIGHT = 40;
const ITEM_GAP = 20;

const generateItem = (offsetY: number = ITEM_OFFSET_Y) =>
  `M0 ${offsetY} ${ITEM_HEIGHT} ${offsetY} ${ITEM_HEIGHT} ${40 + offsetY} 0 ${40 + offsetY} 0 ${offsetY}z
   M60 ${offsetY} 170 ${offsetY} 170 ${15 + offsetY} 60 ${15 + offsetY} 60 ${offsetY}z
   M60 ${25 + offsetY} 210 ${25 + offsetY} 210 ${40 + offsetY} 60 ${40 + offsetY} 60 ${25 + offsetY}z`;

export const RecentSearchLoader = React.memo(() => (
  <SRecentSearchLoader>
    <div className={classes('container')} />
    <svg className={classes('svg')}>
      <defs>
        <clipPath id={CLIP_PATH_ID}>
          <path
            d={`M0 0 50 0 50 15 0 15 0 0z
                ${generateItem()}
                ${generateItem(ITEM_OFFSET_Y + ITEM_HEIGHT + ITEM_GAP)}
                ${generateItem(ITEM_OFFSET_Y + ITEM_HEIGHT * 2 + ITEM_GAP * 2)}`}
          />
        </clipPath>
      </defs>
    </svg>
  </SRecentSearchLoader>
));
