import React from 'react';
import { Button, BUTTON_TYPE, BUTTON_SIZE, THEME, Tooltip } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { BEM_CLASS, SRequestApproval } from './s-request-approval-control';

const classes = bem(BEM_CLASS);

interface RequestApprovalControlProps {
  disabled?: boolean;
  isTooltipShown?: boolean;
  handleRequestApprovalClick(): void;
}

export const RequestApprovalControl = React.memo(
  ({ disabled = false, isTooltipShown, handleRequestApprovalClick }: RequestApprovalControlProps) => (
    <SRequestApproval>
      <Tooltip
        tooltip="You cannot request approval until Media Campaign is no longer in draft phase."
        isDisabled={!isTooltipShown}
        isInteractive={false}
      >
        <Button
          disabled={disabled}
          type={BUTTON_TYPE.tertiary}
          size={BUTTON_SIZE.small}
          theme={THEME.light}
          className={classes('button', { isDisabled: disabled })}
          caption="Request Approval"
          onClick={handleRequestApprovalClick}
          data-selector="media-plan-request-approval-button"
        />
      </Tooltip>
    </SRequestApproval>
  )
);
