import styled from 'styled-components';
import { BEM_CLASS as REACT_SELECT_BEM_CLASS, Props, SelectCss } from 'common/components/form/select';
import CreatableSelect from 'react-select/creatable';
import { MENU_LIST_HEIGHT } from 'common/components/form/creatable-select/constants';

export const BEM_CLASS = 'creatable-select';

export const SCreatableSelect = styled(CreatableSelect)<Props>`
  ${SelectCss};
  .${REACT_SELECT_BEM_CLASS}__menu {
    max-height: 350px;
  }
  .${REACT_SELECT_BEM_CLASS}__menu-list {
    max-height: ${MENU_LIST_HEIGHT}px;
    min-height: ${MENU_LIST_HEIGHT}px;
  }
`;
