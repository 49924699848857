import React from 'react';
import { ApiError } from 'backend-api';
import { Artist, Id, Playlist, TargetType, SimpleProjectUser, IdNameField, ProjectDetails } from 'backend-api/models';
import { FromSearchParam } from 'backend-api/types';
import { LinkfireSearchOption } from 'common/components/linkfire-search/types';
import { OptionalPeriod } from 'common/types';

export enum ProjectTab {
  Summary,
  MediaPlan,
  Reporting,
  Finance,
}

export interface ProjectTabView {
  value: ProjectTab;
  label: string;
}

export interface ProjectDetailsPayload {
  projectId: Id;
  params?: FromSearchParam;
}

export interface ProjectFormValues {
  artists: Artist[];
  playlists: Playlist[];
  name: string;
  dates?: OptionalPeriod;
  budget?: number;
  allocation?: number;
  type: number;
  notes?: string;
  labelId?: number;
  linkfireLinks?: LinkfireSearchOption[];
  grassId?: string;
}

export interface EditProjectPayload {
  project: ProjectDetails;
  data: ProjectFormValues;
  changedFields?: string[];
  onFinished?(): void;
  onSuccess(successMessage: React.ReactNode): void;
  onError(error: ApiError): void;
}

export interface TargetOption {
  id: number;
  name: TargetType;
}

export interface EditArtistModalInfo {
  selectedArtists: Artist[];
}

export interface PrimaryAndFeaturedArtists {
  primaryArtists: Artist[];
  featuredArtists: Artist[];
}

export interface PreviousUserState {
  userId: Id;
  role: IdNameField;
}

export interface UpdateProjectUserPayload {
  projectId: Id;
  userId: Id;
  role: IdNameField;
  previousRole: IdNameField;
  onFailure(): void;
  onEditRestricted(error: ApiError): void;
  onProjectRestricted(): void;
}

export interface RemoveProjectUserPayload {
  projectId: Id;
  userId: Id;
  roleId: Id;
  currenUserRole?: SimpleProjectUser['role']['name'];
  onFailure(): void;
  onProjectUnclaim(): void;
  onEditRestricted(error: ApiError): void;
  onProjectRestricted(): void;
}

export interface SearchArtistsPayload {
  name: string;
  onFailure(): void;
}

export interface AddProjectUsersPayload {
  projectId: Id;
  usersIds: Id[];
  roleId: SimpleProjectUser['role']['id'];
  currenUserRole?: SimpleProjectUser['role']['name'];
  onSuccess(): void;
  onFailure(): void;
  onEditRestricted(error: ApiError): void;
  onProjectRestricted(): void;
}
