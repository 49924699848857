export enum ViewTeamModalMode {
  ARTIST_TEAM,
  PROJECT_COLLABORATORS,
}

export enum LiveArtistCardStates {
  DEFAULT,
  SELECTED,
  INACTIVE,
}
