import { useCallback } from 'react';
import { PROJECT_STATUSES } from 'common/constants';
import { useProjectsFilters } from './use-projects-filters';

export const useActiveTab = (): [string | undefined, (activeTab: string) => void] => {
  const [filters, filtersDispatcher] = useProjectsFilters();

  const changeActiveTab = useCallback(
    (activeTab: string) => {
      const status = PROJECT_STATUSES.find(status => status.name === activeTab);
      if (!status) return;

      filtersDispatcher.setFilters({ status });
    },
    [filtersDispatcher]
  );

  return [filters.status?.name, changeActiveTab];
};
