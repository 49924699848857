import React, { useCallback } from 'react';
import { SettingsControl } from './components';
import { Select, SelectType } from 'common/components/select';
import { EXPORT_OPTION_ID, SETTINGS_OPTIONS } from './constants';

interface Props {
  onExportClick?(): void;
  className?: string;
}

export const SettingsDropdown = React.memo(({ className, onExportClick }: Props) => {
  const selectComponents = useCallback(
    () => ({
      Control: SettingsControl,
    }),
    []
  );

  const handleOptionSelect = useCallback(
    (valueId: string) => {
      if (valueId === EXPORT_OPTION_ID) {
        onExportClick?.();
      }
    },
    [onExportClick]
  );

  return (
    <div className={className}>
      <Select
        type={SelectType.Base}
        selectComponents={selectComponents}
        valueId={0}
        valueHandler={handleOptionSelect}
        options={SETTINGS_OPTIONS}
      />
    </div>
  );
});
