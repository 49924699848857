import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { tokenService } from 'backend-api';
import { clearPersistedState } from 'utils/storage';
import { auth0IsAuthorizedSelector } from 'auth/selectors';
import { ACTION } from 'auth/reducer';
import { tokenSelector } from 'login/selectors';
import { isAccessTokenExpired } from 'login/transducers';
import { showWarningToast } from 'common/components/toast';
import { IS_OLD_AUTH } from 'common/constants';
import { userInfoSelector } from 'common/selectors';

export const useAuth = () => {
  const dispatch = useDispatch();

  const { data: currentUser } = useSelector(userInfoSelector);
  const loginToken = useSelector(tokenSelector);
  const isAuthAuthorized = useSelector(auth0IsAuthorizedSelector);

  const isLoginAuthorized = useMemo(() => !!loginToken && !isAccessTokenExpired(loginToken.expires_timestamp), [
    loginToken,
  ]);

  const isAuthorized = IS_OLD_AUTH ? isAuthAuthorized : isLoginAuthorized;

  const authLogout = useCallback(() => {
    dispatch(ACTION.logout());
  }, [dispatch]);

  const loginLogout = useCallback(() => {
    tokenService.logout(() => {
      showWarningToast('Cannot sign out. Please contact support.');
    });
  }, []);

  const logout = useCallback(() => {
    clearPersistedState(storage);

    if (IS_OLD_AUTH) {
      authLogout();
    } else {
      loginLogout();
    }
  }, [authLogout, loginLogout]);

  return {
    isAuthorized,
    authProfile: currentUser,
    logout,
  };
};
