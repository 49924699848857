import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SUnclaimedProjectView } from './s-unclaimed-project-view';

const classes = bem(BEM_CLASS);

interface UnclaimedProjectViewProps {
  title?: React.ReactNode;
  description: React.ReactNode;
  actionButton?: React.ReactNode;
  imageSrc: string;
}

export const UnclaimedProjectView = React.memo(
  ({ title, description, actionButton, imageSrc }: UnclaimedProjectViewProps) => (
    <SUnclaimedProjectView>
      {title && <div className={classes('title')}>{title}</div>}
      <div className={classes('description')}>{description}</div>
      {actionButton && <div className={classes('action')}>{actionButton}</div>}
      <img src={imageSrc} className={classes('image')} data-selector="unclaimed-project-view-image" />
    </SUnclaimedProjectView>
  )
);
