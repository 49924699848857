import { Artist } from 'backend-api/models';
import { Button, BUTTON_SIZE, BUTTON_TYPE, THEME, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { convertToDataSelector } from 'utils/string';
import { ArtistItem } from './artist-item';
import { COLUMN_ITEMS_COUNT } from './constants';
import { SArtistsGrid, BEM_CLASS } from './s-artists-grid';

interface ArtistsGridProps {
  title: string;
  artists: Artist[];
  onEdit?(): void;
  emptyText?: string;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ArtistsGrid = React.memo(({ title, artists, onEdit, emptyText, className }: ArtistsGridProps) => {
  const columnsCount = Math.ceil(artists.length / COLUMN_ITEMS_COUNT);
  const titleSelector = convertToDataSelector(title);

  return (
    <SArtistsGrid
      columnsCount={columnsCount}
      className={className}
      data-selector={`project-form-${titleSelector}-grid`}
    >
      <div className={classes('header')}>
        <Typography
          type={TYPOGRAPHY_TYPE.heading2}
          data-selector={`project-form-${titleSelector}-title`}
        >{`${title} (${artists.length})`}</Typography>
        {onEdit && (
          <Button
            type={BUTTON_TYPE.tertiary}
            size={BUTTON_SIZE.small}
            theme={THEME.light}
            onClick={onEdit}
            data-selector={`project-form-${titleSelector}-edit-button`}
          >
            <Typography type={TYPOGRAPHY_TYPE.subtitle3}>EDIT</Typography>
          </Button>
        )}
      </div>
      {artists.length > 0 && (
        <div className={classes('grid')}>
          {artists.map(artist => (
            <ArtistItem artist={artist} key={artist.id} className={classes('item')} />
          ))}
        </div>
      )}
      {artists.length === 0 && emptyText && (
        <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('empty')}>
          {emptyText}
        </Typography>
      )}
    </SArtistsGrid>
  );
});
