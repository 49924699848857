import { Optional, PurchaseOrderDetails, PurchaseOrdersListResponse, PurchaseOrdersProvider } from 'backend-api/models';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { createTypedReducer, onAction } from 'core/store';
import { LoadingState, LoadableData } from 'common/types';
import { getPurchaseOrder, getPurchaseOrderChildren, getPurchaseOrdersProviders, updateFilters } from './actions';
import { PurchaseOrdersFilters } from 'common/components/tables/purchase-orders';
import { ApiError } from 'backend-api';

export interface State {
  purchaseOrder: LoadableData<Optional<PurchaseOrderDetails>>;
  filters: PurchaseOrdersFilters;
  purchaseOrdersResponse?: PurchaseOrdersListResponse;
  providers: PurchaseOrdersProvider[];
  isPOChildrenLoading: boolean;
  isPOChildrenLoadingFailed: boolean;
  error?: ApiError<ProjectDetailsErrorResponse>;
}

export const initialState: State = {
  purchaseOrder: {
    loading: LoadingState.Idle,
    data: undefined,
  },
  filters: {},
  providers: [],
  isPOChildrenLoading: false,
  isPOChildrenLoadingFailed: false,
};

export const reducer = createTypedReducer<State>(
  initialState,
  onAction(updateFilters, (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      ...action.payload,
    },
  })),
  onAction(getPurchaseOrder.request, state => ({
    ...state,
    purchaseOrder: {
      loading: LoadingState.Started,
      data: undefined,
    },
  })),
  onAction(getPurchaseOrder.success, (state, { payload }) => ({
    ...state,
    purchaseOrder: {
      ...state.purchaseOrder,
      loading: LoadingState.Finished,
      data: payload,
    },
  })),
  onAction(getPurchaseOrder.failure, (state, action) => ({
    ...state,
    purchaseOrder: {
      ...state.purchaseOrder,
      loading: LoadingState.Failed,
    },
    error: action.payload,
  })),
  onAction(getPurchaseOrdersProviders.success, (state, action) => ({
    ...state,
    providers: action.payload,
  })),
  onAction(getPurchaseOrderChildren.request, state => ({
    ...state,
    isPOChildrenLoading: true,
    isPOChildrenLoadingFailed: false,
  })),
  onAction(getPurchaseOrderChildren.success, (state, action) => ({
    ...state,
    purchaseOrdersResponse: action.payload,
    isPOChildrenLoading: false,
    isPOChildrenLoadingFailed: false,
  })),
  onAction(getPurchaseOrderChildren.failure, (state, action) => ({
    ...state,
    isPOChildrenLoading: false,
    isPOChildrenLoadingFailed: true,
    error: action.payload,
  }))
);
