import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { LabelPermissionsArtistWithMembers } from 'backend-api/models';
import { artistsSelector } from 'label-permissions/selectors';
import { artistPredicate } from '../transducers';

export const useArtists = (
  search: string
): {
  artists: LabelPermissionsArtistWithMembers[];
  isSearchActive: boolean;
} => {
  const { data: artists } = useSelector(artistsSelector);

  const [filteredArtists, setFilteredArtists] = useState(artists);
  const [isSearchActive, setIsSearchActive] = useState(search.length > 0);

  useEffect(() => {
    if (!Boolean(search)) {
      setIsSearchActive(search.length > 0);
      setFilteredArtists(artists);
    }
  }, [search, artists]);

  useDebounce(
    () => {
      if (Boolean(search) && artists) {
        setIsSearchActive(search.length > 0);
        setFilteredArtists(artists.filter(artistPredicate(search)));
      }
    },
    300,
    [search, artists]
  );

  return {
    artists: filteredArtists,
    isSearchActive,
  };
};
