import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { bgStyle, BgStyle } from 'common/styles/utils';

interface Props {
  readonly bgStyle?: BgStyle;
}

export const SGoto = styled.div<Props>`
  &.pagination__goto {
    display: inline-block;
    margin-left: 22px;
  }
  .pagination__goto-text {
    color: ${palette.darkBlueTwo};
    text-transform: uppercase;
    font-size: 10px;
    margin-right: 11px;
    letter-spacing: 1px;
  }
  .pagination__goto-input {
    background: ${bgStyle};
    width: 64px;
    text-align: center;
    position: relative;
    display: inline-block;
    height: 35px;
    padding: 5px 19px;
    color: ${palette.darkBlue};
    font-size: 14px;
    line-height: 1.5;
    border: 1px solid transparent;
    border-radius: 20px;
    transition: all 0.3s;
    &:focus {
      border-color: ${palette.brightBlue};
    }
  }
`;
