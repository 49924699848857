import { apiClient, ResponseArray } from '../index';
import {
  ArtistProjectsResponseSchema,
  ArtistTeamSchema,
  UpdateArtistTeam,
  UpdateArtistTeamSchema,
  ArtistSearchSchema,
} from '../models';
import { ProjectFilterParams, FromSearchParam } from '../types';

export const getArtist = (id: string, params?: FromSearchParam) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/artists/${id}`,
    responseSchema: ArtistSearchSchema,
    queryParams: params,
  });

export const getArtistProjects = (id: string, params: ProjectFilterParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/artists/${id}/projects`,
    responseSchema: ArtistProjectsResponseSchema,
    queryParams: params,
  });

export const getArtistUnassignedProjects = (id: string, params: ProjectFilterParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/artists/${id}/projects`,
    responseSchema: ArtistProjectsResponseSchema,
    queryParams: params,
  });

export const exportArtistProjectsCsv = (id: string, params: ProjectFilterParams) =>
  apiClient.executeBinaryRequest({
    method: 'GET',
    endpoint: `/artists/${id}/projects/export`,
    queryParams: params,
  });

export const getArtistTeams = (artistId: string) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/artists/${artistId}/artist-teams`,
    responseSchema: ResponseArray(ArtistTeamSchema),
  });

export const createArtistTeam = (artistId: string, payload: UpdateArtistTeam) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/artists/${artistId}/artist-teams`,
    responseSchema: ArtistTeamSchema,
    requestSchema: UpdateArtistTeamSchema,
    payload,
  });

export const editArtistTeam = (artistId: string, payload: UpdateArtistTeam) =>
  apiClient.executeRequest({
    method: 'PUT',
    endpoint: `/artists/${artistId}/artist-teams`,
    responseSchema: ArtistTeamSchema,
    requestSchema: UpdateArtistTeamSchema,
    payload,
  });
