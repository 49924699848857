import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors, colorPalette } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'campaign-item';

export const SCampaignItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 12px 8px 12px 15px;
  border-radius: 10px;
  border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
  background: ${transparentize(0.5, colorPalette.white)};

  .${BEM_CLASS} {
    &__name {
      ${elipsizeText}
    }

    &__delete {
      display: none;
      flex-shrink: 0;
      margin-left: 5px;
      background: ${transparentize(0.95, manhattanThemeColors.hoveredBorderColor)};

      &:hover {
        background: ${transparentize(0.9, manhattanThemeColors.hoveredBorderColor)};
      }
    }
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${transparentize(0.6, manhattanThemeColors.hoveredBorderColor)};

    .${BEM_CLASS} {
      &__delete {
        display: block;
      }
    }
  }
`;
