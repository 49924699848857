import { CurrentUserInfo, Label } from 'backend-api/models';
import { Avatar } from 'common-v2/components';
import { Badge, BADGE_TYPE, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { SUserInfo, BEM_CLASS } from './s-user-info';

interface UserInfoProps {
  user: CurrentUserInfo;
  labels: Label[];
  className?: string;
}

const classes = bem(BEM_CLASS);

export const UserInfo = React.memo(({ user, labels, className }: UserInfoProps) => {
  return (
    <SUserInfo className={className}>
      <Avatar name={user.name} size={100} avatarColor="black" className={classes('avatar')} />
      <div className={classes('info-container')}>
        <Typography className={classes('name')} type={TYPOGRAPHY_TYPE.heading2}>
          {user.name}
        </Typography>
        <Typography className={classes('email')} type={TYPOGRAPHY_TYPE.body2}>
          {user.email}
        </Typography>
        <div className={classes('separator')} />
        <Typography className={classes('name')} type={TYPOGRAPHY_TYPE.heading2}>
          {`Labels (${labels.length})`}
        </Typography>
        <div className={classes('labels')}>
          {labels.map(label => (
            <Badge key={label.id} content={label.name} type={BADGE_TYPE.quaternary} className={classes('label')} />
          ))}
        </div>
      </div>
    </SUserInfo>
  );
});
