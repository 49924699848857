import { combineEpics, StateObservable } from 'redux-observable';
import { Observable, ReplaySubject } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import * as commonEpics from 'common/epics';
import * as Sentry from '@sentry/browser';

const epics = [...Object.values(commonEpics)];

const dynamicallyAdded = new Set<any>();
const epic$ = new ReplaySubject();

epic$.next(combineEpics(...epics));

export const rootEpic = (action$: Observable<any>, state$: StateObservable<any>) =>
  epic$.pipe(
    mergeMap((epic: any) => epic(action$, state$)),
    catchError((err, source) => {
      Sentry.captureException(err);
      return source;
    })
  );

export function addEpics(epics: Record<string, any>) {
  for (const epic of Object.values(epics)) {
    if (!dynamicallyAdded.has(epic)) {
      epic$.next(epic);
      dynamicallyAdded.add(epic);
    }
  }
}
