import React, { useCallback } from 'react';
import { MenuDropdown, MenuDropdownOption } from 'common/components/menu-dropdown';
import { navigateTo } from 'utils/navigation';
import { paths } from 'app/routing/paths';
import { Id, PurchaseOrderCampaign } from 'backend-api/models';

interface Props {
  projectId: Id;
  linkedCampaign?: PurchaseOrderCampaign;
  className?: string;
}

export const TableMenuDropdown = ({ projectId, linkedCampaign, className }: Props) => {
  const onViewCampaign = useCallback(() => {
    linkedCampaign && navigateTo(paths.campaign(projectId, linkedCampaign.uuid));
  }, [linkedCampaign, projectId]);

  return (
    <MenuDropdown className={className} disabled={linkedCampaign === undefined || linkedCampaign.isDeleted}>
      <MenuDropdownOption key="view-campaign" title="View Campaign" iconName="view-outline" onClick={onViewCampaign} />
    </MenuDropdown>
  );
};
