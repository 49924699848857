import React from 'react';
import { Skeleton } from 'common/components/skeleton';

interface Props {
  className?: string;
}

export const ApprovalControlsLoader = React.memo(({ className }: Props) => (
  <Skeleton className={className} width={102} height={24}>
    <rect x="0" y="0" rx="0" ry="0" width="102" height="24" />
  </Skeleton>
));
