import { ErrorViewStates, ErrorViewConfig } from './types';
import NotFoundImage from 'assets/error_404.png';
import ServerErrorImage from 'assets/error_500.png';
import GlobalErrorImage from 'assets/error_global.png';

export const defaultErrorViewConfig: Partial<Record<ErrorViewStates, ErrorViewConfig>> = {
  [ErrorViewStates.notFound]: {
    topImageSrc: NotFoundImage,
    description: "This page doesn't exist.",
    actionTitle: 'Go to Home Page',
  },
  [ErrorViewStates.serverError]: {
    topImageSrc: ServerErrorImage,
    description: 'Failed to load due to internal error.',
    actionTitle: 'Refresh',
  },
  [ErrorViewStates.globalError]: {
    topImageSrc: GlobalErrorImage,
    description: "Pause the music! Something's out of tune.",
    actionTitle: 'Refresh',
  },
};
