import React, { Ref, useMemo } from 'react';
import { Spreadsheet, SpreadsheetRef } from 'media-plan/components';
import { Id } from 'backend-api/models';
import { useDispatch, useSelector } from 'react-redux';
import { setSpreadsheetValidationModel } from 'media-plan/actions';
import {
  useCampaignStatuses,
  useGenders,
  useGoals,
  usePerformanceObjectives,
  useProjectCurrencyCode,
  useTaxonomy,
  useTerritories,
} from 'hooks';
import {
  getColumnsValidationConfig,
  getEmptyColumnConfig,
  getInitialConfig,
  prepareSpreadsheetData,
} from 'media-plan/components/spreadsheet/transducers';
import {
  approvalRequestsLoadingStateSelector,
  approvalRequestsPhasesSelector,
  isAllCampaignsShownInApprovalSelector,
  mediaPlanModeSelector,
  mediaPlanSelector,
  reviewedCampaignsSelector,
} from 'media-plan/selectors';
import { useMediaPlanAccessMode } from 'media-plan/hooks';
import { dateFormatSelector } from 'common/selectors';
import { LoadingState } from 'common/types';
import { isLoadingStateAllowUILoading, joinMediaPlanWithApprovalRowData } from 'media-plan/transducers';

interface Props {
  projectId: Id;
  mediaPlanId?: Id;
  className?: string;
}

export const SpreadsheetContainer = React.memo(
  React.forwardRef(({ projectId, mediaPlanId, className }: Props, ref: Ref<SpreadsheetRef>) => {
    const dispatch = useDispatch();

    const performanceObjectives = usePerformanceObjectives();
    const currencyCode = useProjectCurrencyCode() || 'USD'; // HOTFIX: BE bug
    const goals = useGoals();
    const taxonomy = useTaxonomy();
    const genders = useGenders();
    const countries = useTerritories();
    const mediaPlanMode = useSelector(mediaPlanModeSelector);
    const accessMode = useMediaPlanAccessMode(projectId, mediaPlanMode);
    const dateFormat = useSelector(dateFormatSelector);
    const statuses = useCampaignStatuses();

    const { data: mediaPlan, loading: loadingState } = useSelector(mediaPlanSelector);
    const approvalRequestsPhases = useSelector(approvalRequestsPhasesSelector);
    const isAllCampaignsShownInApproval = useSelector(isAllCampaignsShownInApprovalSelector);
    const approvalRequestsLoading = useSelector(approvalRequestsLoadingStateSelector);
    const reviewedCampaigns = useSelector(reviewedCampaignsSelector);

    const isSpreadsheetLoading =
      isLoadingStateAllowUILoading(loadingState) || isLoadingStateAllowUILoading(approvalRequestsLoading);

    const data = useMemo(() => {
      const mediaPlanRowData = prepareSpreadsheetData({ phases: mediaPlan.phases, dateFormat });

      const approvalRowData = prepareSpreadsheetData({
        phases: approvalRequestsPhases,
        isEditableInApproval: true,
        dateFormat,
      });
      return joinMediaPlanWithApprovalRowData(mediaPlanRowData, approvalRowData);
    }, [mediaPlan.phases, approvalRequestsPhases, dateFormat]);

    const columnsDefinitions = useMemo(() => {
      if (!performanceObjectives || !currencyCode || !goals || !taxonomy || !genders || !countries || !statuses)
        return undefined;

      if (loadingState !== LoadingState.Finished) {
        return getEmptyColumnConfig();
      }

      dispatch(
        setSpreadsheetValidationModel(
          getColumnsValidationConfig({
            performanceObjectives,
            currencyCode,
            goals,
            taxonomy,
            genders,
            countries,
            dateFormat,
            statuses,
            projectId,
          })
        )
      );

      return getInitialConfig({
        performanceObjectives,
        currencyCode,
        goals,
        taxonomy,
        genders,
        countries,
        dateFormat,
        statuses,
        projectId,
      });
    }, [
      performanceObjectives,
      currencyCode,
      goals,
      taxonomy,
      genders,
      countries,
      statuses,
      loadingState,
      dispatch,
      dateFormat,
      projectId,
    ]);

    return (
      <div className={className}>
        <Spreadsheet
          ref={ref}
          projectId={projectId}
          mediaPlanId={mediaPlanId || -1}
          data={data}
          reviewedCampaigns={reviewedCampaigns}
          columnsDefinitions={columnsDefinitions}
          accessMode={accessMode}
          mediaPlanMode={mediaPlanMode}
          taxonomy={taxonomy}
          isLoading={isSpreadsheetLoading}
          isFilterEnabled={!isAllCampaignsShownInApproval}
          haseError={loadingState === LoadingState.Failed || approvalRequestsLoading === LoadingState.Failed}
          currencyCode={currencyCode}
        />
      </div>
    );
  })
);
