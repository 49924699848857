import { useSelector } from 'react-redux';
import { dateTimeFormatSelector } from 'common/selectors';
import { useCallback } from 'react';
import { getFormattedDate } from 'common-v2/utils';

export const useFormatDateTime = (): ((date?: Date) => void) => {
  const currentFormat = useSelector(dateTimeFormatSelector);

  return useCallback((date?: Date) => date && getFormattedDate(currentFormat)(date), [currentFormat]);
};
