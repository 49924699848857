import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

interface Props {
  flat?: boolean;
  invalid?: boolean;
}

export const SLinkViewContainer = styled.a<Props>`
  height: 35px;
  padding: 0 13px 0 ${({ flat }) => (flat ? 0 : 13)}px;
  margin: 5px 0;
  border-radius: 5px;
  background-color: ${({ flat }) => (flat ? 'transparent' : colorPalette.ghostWhite)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: fit-content;
  pointer-events: ${({ invalid }) => (invalid ? 'none' : 'default')};

  .link-view-container {
    &__link-text {
      color: ${colorPalette.azureRadiance};
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      user-select: none;
    }

    &__icon {
      margin-right: 8px;
    }

    &__link-badge {
      margin-left: 10px;
    }
  }
`;
