import { GridApi } from 'ag-grid-community';
import React, { useCallback, useRef } from 'react';
import { Params, Result, useEditorKeyboardInteractions, ValueEvent } from './use-editor-keyboard-interactions';
import Select from 'react-select/base';
import { BaseOption } from 'common/components/select';
import { Nullable, Optional } from 'backend-api/models';
import { SpreadsheetRowData } from '../types';

type IdType = string | number;

export interface SelectParams<IsMulti extends boolean> extends Omit<Params, 'onValueEvent'> {
  api: GridApi<SpreadsheetRowData>;
  value: any;
  isMulti: IsMulti;
  setValue(valueIds: Optional<Nullable<IsMulti extends true ? IdType[] : IdType>>): void;
}

export interface SelectResult<IsMulti extends boolean> extends Result {
  selectRef: React.RefObject<Select<BaseOption, IsMulti>>;
  onKeyDown(event: React.KeyboardEvent<HTMLElement>): void;
}
export const useSelectEditorKeyboardInteractions = <IsMulti extends boolean>({
  api,
  value,
  setValue,
  isMulti,
}: SelectParams<IsMulti>): SelectResult<IsMulti> => {
  const selectRef = useRef<Select<BaseOption, IsMulti>>(null);
  const onValueEvent = useCallback(
    (event: ValueEvent) => {
      switch (event) {
        case ValueEvent.RESET:
          if (!isMulti && !selectRef.current?.inputRef?.value) {
            setValue(null);
            selectRef.current?.focus();
          }
          return !isMulti;
        case ValueEvent.SELECT_CURRENT:
          const focusedValue = selectRef.current?.state.focusedOption;
          if (!focusedValue) return true;
          const currentValue = isMulti
            ? selectRef.current?.state.selectValue || []
            : selectRef.current?.state.selectValue;

          const newValueOption = isMulti ? currentValue?.concat(focusedValue) : focusedValue;

          selectRef.current.onInputChange('', {
            action: 'input-change',
            prevInputValue: selectRef.current.inputRef?.value || '',
          });
          selectRef.current.onChange(newValueOption as IsMulti extends true ? BaseOption[] : BaseOption, {
            action: 'select-option',
            option: focusedValue,
          });

          return !focusedValue || !isMulti;
      }
    },
    [setValue, isMulti]
  );
  const { onKeyDown } = useEditorKeyboardInteractions({ api, value, onValueEvent, shouldCloseOnEnter: !isMulti });

  return { onKeyDown, selectRef };
};
