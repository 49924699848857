import {
  ColDef,
  EditableCallback,
  IAggFuncParams,
  ValueFormatterFunc,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import {
  CopyPastedCell,
  FocusDirection,
  InitialConfigParams,
  MediaPlanAccessMode,
  MediaPlanColumnId,
  NameEditorInstance,
  PendingData,
  PendingDataStore,
  SpreadsheetCellData,
  SpreadsheetContext,
  SpreadsheetRowData,
  SpreadsheetRowFields,
  SpreadsheetValidationModel,
  UndoData,
  UndoHook,
} from './types';
import { camelCase, cloneDeep, isEqual, sum, toNumber } from 'lodash';
import { UUID } from 'io-ts-types/lib/UUID';
import {
  ActionButton,
  AdCopyRenderer,
  AgeEditor,
  AgeRenderer,
  BudgetInputEditor,
  CampaignNameRenderer,
  CountriesEditor,
  CountriesRenderer,
  CreativeDescriptionRenderer,
  CurrencyRenderer,
  DetailsRenderer,
  EndDateEditor,
  EndDateRenderer,
  GendersEditor,
  GendersRenderer,
  HeadlineRenderer,
  KpiEditor,
  KpiRenderer,
  LinksReadonlyEditor,
  NamingConventionEditor,
  NotesRenderer,
  ObjectiveEditor,
  ObjectiveRenderer,
  PlacementsEditor,
  PlacementsRenderer,
  PlatformsEditor,
  PlatformsRenderer,
  SimpleNumberRenderer,
  StartDateEditor,
  StartDateRenderer,
  StatusEditor,
  StatusRenderer,
  TextEditor,
  TotalRenderer,
} from './components';
import { estimatedImpressionsGetter } from 'media-plan/transducers';
import { getFlattenedPlatforms, getPlacementsOptions, getSelectOptions } from 'common/transducers';
import {
  AudienceAge,
  CampaignPlacement,
  CampaignPlatform,
  CampaignStatus,
  CampaignStatuses,
  Id,
  IdNameField,
  MediaPlanCampaignRequest,
  MediaPlanCampaignStylesRequest,
  MediaPlanPhase,
  ModifyBulkCampaignsRequest,
  Nullable,
  Optional,
  Taxonomy,
} from 'backend-api/models';
import {
  Column,
  ColumnApi,
  ExcelStyle,
  GridApi,
  InitialGroupOrderComparatorParams,
  PostSortRowsParams,
  ProcessCellForExportParams,
  RowNode,
  SortChangedEvent,
} from 'ag-grid-community';
import {
  COLORS_IDENTIFIER,
  COLOR_IDENTIFIER,
  EXCEL_EXPORT_COLUMNS,
  FIRST_VALUE_COLUMN_EXPORT_INDEX,
  LINK_COLUMNS,
  NOT_COPYABLE_MEDIA_PLAN_COLUMNS_COUNT,
} from './constants';
import { CURRENCY_CODE_TO_SIGN, CURRENCY_FORMATS, IS_MAC_OS, MAX_AGE, MIN_AGE } from 'common/constants';
import { CallToActionRenderer, LinksRenderer, NamingConventionRenderer } from './components/renderers';
import { LinksEditor } from './components/editors/links-editor';
import { DecibelLink } from 'utils/decibel-link';
import { ACTION_COLUMN_WIDTH, CHECKBOX_COLUMN_WIDTH } from 'media-plan/constants';
import { showToast, ToastType } from 'common/components/toast';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { prepareCountries } from 'media-plan/components/spreadsheet/components/editors/countries-editor/transducers';
import { BaseOption } from 'common/components/select';
import { MediaPlanMode } from 'media-plan/types';
import first from 'lodash/first';
import {
  isDateAfter,
  getFormattedDate,
  getDiffInMiliseconds,
  parseDateByFormat,
  isValidDate,
  isFirefox,
} from 'common-v2/utils';
import {isMediaPlanColumnId} from "../../../media-plan-v2/containers/spreadsheet/transducers";
import {COLUMNS_TO_SANITIZE} from "../../../media-plan-v2/components/spreadsheet/constants";

export const canEditPlacements = (value: CampaignPlatform[] | null | undefined, taxonomy?: Taxonomy): boolean => {
  const platforms = value ? value.map(item => item.id) : undefined;
  const availablePlacements = getPlacementsOptions(taxonomy?.placements, platforms, taxonomy?.platforms);

  return availablePlacements.length > 0;
};

export const createDraftCampaign = (
  data: Pick<SpreadsheetRowFields, 'uuid' | 'phaseId' | 'phaseName' | 'phaseOrder'>,
  orders: Pick<SpreadsheetRowFields, 'orderInPhase' | 'orderOnSort'>
): SpreadsheetRowData => ({
  uuid: getInitialCellData(data.uuid),
  phaseId: getInitialCellData(data.phaseId),
  phaseName: getInitialCellData(data.phaseName),
  phaseOrder: getInitialCellData(data.phaseOrder),
  orderInPhase: getInitialCellData(orders.orderInPhase),
  orderOnSort: getInitialCellData(orders.orderOnSort),
  status: getInitialCellData(CampaignStatuses.DRAFT),
  id: getInitialCellData(undefined),
  name: getInitialCellData(undefined),
  platforms: getInitialCellData(undefined),
  objective: getInitialCellData(undefined),
  kpi: getInitialCellData(undefined),
  notes: getInitialCellData(undefined),
  genders: getInitialCellData(undefined),
  adCreativeLinks: getInitialCellData(undefined),
  destinationLinks: getInitialCellData(undefined),
  startDate: getInitialCellData(undefined),
  endDate: getInitialCellData(undefined),
  countries: getInitialCellData(undefined),
  audienceDetails: getInitialCellData(undefined),
  creativeDescription: getInitialCellData(undefined),
  adCopy: getInitialCellData(undefined),
  age: getInitialCellData(undefined),
  eCpm: getInitialCellData(undefined),
  budget: getInitialCellData(undefined),
  placements: getInitialCellData(undefined),
  action: getInitialCellData(undefined),
  actualSpent: getInitialCellData(undefined),
  callToAction: getInitialCellData(undefined),
  estImpressions: getInitialCellData(undefined),
  headline: getInitialCellData(undefined),
  namingConvention: getInitialCellData(undefined),
  namingConventionManual: getInitialCellData(undefined),
  editableInApproval: getInitialCellData(undefined),
});

export const clearCampaign = (data: SpreadsheetRowData): SpreadsheetRowData => {
  const { id, uuid, phaseId, phaseName, phaseOrder, orderInPhase, ...fields } = data;
  const fieldsCopy = { ...fields };

  for (const field in fieldsCopy) {
    fieldsCopy[field] = getInitialCellData(null);
  }

  return {
    ...fieldsCopy,
    id,
    uuid,
    phaseId,
    phaseName,
    phaseOrder,
    orderInPhase,
    status: getInitialCellData(CampaignStatuses.DRAFT),
  };
};

export const isEditMode = (accessMode: MediaPlanAccessMode): boolean => accessMode === MediaPlanAccessMode.EDIT;

export const isCheckboxShown = (mediaPlanMode: MediaPlanMode): boolean =>
  mediaPlanMode === MediaPlanMode.REQUEST_APPROVAL;

export const isReadOnlyMode = (accessMode: MediaPlanAccessMode): boolean =>
  accessMode === MediaPlanAccessMode.READ_ONLY;

export const getEmptyColumnConfig = (): ColDef<SpreadsheetRowData>[] => {
  return [
    {
      field: MediaPlanColumnId.ACTIONS,
      editable: false,
      width: ACTION_COLUMN_WIDTH,
      minWidth: ACTION_COLUMN_WIDTH,
      sortable: false,
      resizable: false,
      headerName: '',
      headerClass: ['not-resizable-header-cell', 'action-header-cell'],
      cellClass: 'action-cell',
      suppressFillHandle: true,
      suppressNavigable: true,
      suppressPaste: true,
      suppressHeaderKeyboardEvent: () => true,
      suppressKeyboardEvent: () => true,
      suppressSizeToFit: true,
      suppressAutoSize: true,
    },
    {
      field: MediaPlanColumnId.PHASE_ID,
      rowGroup: true,
      hide: true,
      editable: true,
      pinned: false,
    },
    {
      field: MediaPlanColumnId.STATUS,
      headerName: 'Status',
      sortable: false,
      valueFormatter: params => params.value?.value,
      cellRendererSelector: params =>
        params.node.footer ? { component: TotalRenderer } : { component: StatusRenderer },
    },
    {
      field: MediaPlanColumnId.NAME,
      headerName: 'Campaign Description',
      initialWidth: 150,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.PLATFORMS,
      initialWidth: 168,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.PLACEMENTS,
      initialWidth: 169,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.OBJECTIVE,
      initialWidth: 160,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.KPI,
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.START_DATE,
      headerName: 'Start Date',
      initialWidth: 100,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.END_DATE,
      headerName: 'End Date',
      initialWidth: 100,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.BUDGET,
      initialWidth: 87,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.AGE,
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.AUDIENCE_GENDERS,
      headerName: 'Gender',
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.DETAILS,
      headerName: 'Details',
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.ECPM,
      headerName: 'eCPM',
      initialWidth: 87,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.EST_IMPRESSIONS,
      initialWidth: 142,
      editable: false,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.COUNTRIES,
      headerName: 'Territory',
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.AD_COPY,
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.AD_CREATIVE,
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.NOTES,
      initialWidth: 141,
      sortable: false,
    },
  ];
};

export const processPlacementsOnPlatformSelect = (
  platformIds: Id[],
  api: GridApi<SpreadsheetRowData>,
  node: RowNode<SpreadsheetRowData>,
  taxonomy?: Taxonomy
) => {
  const availablePlacements =
    getPlacementsOptions(taxonomy?.placements, platformIds || [], taxonomy?.platforms)?.[0]?.options || [];
  const selectedPlacements = api.getValue(MediaPlanColumnId.PLACEMENTS, node) as SpreadsheetCellData<
    BaseOption[] | null | undefined
  >;
  const validPlacements = selectedPlacements?.value?.filter(placement =>
    availablePlacements.some(available => available.id === placement.id)
  );

  if (validPlacements && selectedPlacements?.value && validPlacements.length < selectedPlacements.value.length) {
    showToast({
      id: 'media-plan-paste',
      type: ToastType.Warning,
      message: 'Pasted placements automatically updated to match selected Platforms.',
      replaceable: true,
    });
  }

  if (availablePlacements.length === 0) {
    node?.setDataValue(MediaPlanColumnId.PLACEMENTS, { ...selectedPlacements, value: null });
    api?.refreshCells({ columns: [MediaPlanColumnId.PLACEMENTS], rowNodes: [node], force: true });
  } else {
    node?.setDataValue(MediaPlanColumnId.PLACEMENTS, { ...selectedPlacements, value: validPlacements });
    api?.refreshCells({ columns: [MediaPlanColumnId.PLACEMENTS], rowNodes: [node], force: true });
  }
};

const normalizeSingleSelectValue = <T extends { value?: string } | { name: string }>(
  value: Nullable<string>,
  allOptions: T[]
): Optional<Nullable<T>> => {
  if (!value) return null;

  const validOption = allOptions.find(
    option =>
      ('value' in option && option?.value?.toLowerCase() === value.toLowerCase()) ||
      ('name' in option && option.name?.toLowerCase() === value.toLowerCase())
  );

  if (validOption === null) return undefined;

  return validOption;
};

const normalizeMultiSelectValue = <T extends { value?: string } | { name?: string }>(
  value: Nullable<string>,
  allOptions: T[]
): Optional<Nullable<T[]>> => {
  if (!value) return null;
  const selectedValues = value.split(', ').map(item => item.toLowerCase());
  const validOptions = allOptions?.filter(option => {
    if ('value' in option) {
      return selectedValues.includes(option.value?.toLowerCase() || '');
    } else if ('name' in option) {
      return selectedValues.includes(option.name?.toLowerCase() || '');
    }
    return false;
  });

  if (validOptions.length === 0) return undefined;

  return validOptions;
};

const normalizeNumber = (value: string): Optional<Nullable<number>> => {
  if (!value) return null;
  const number = toNumber(value);
  if (isNaN(number)) return undefined;
  return number;
};

const normalizeAge = (value: string): Optional<Nullable<AudienceAge>> => {
  if (!value) return null;
  const values = value.split('-');
  const lowerAge = toNumber(values[0]);
  const higherAge = toNumber(values[1]);

  if (isNaN(higherAge) && isNaN(lowerAge)) return undefined;

  return { lowerAge: isNaN(lowerAge) ? undefined : lowerAge, higherAge: isNaN(higherAge) ? undefined : higherAge };
};

const normalizeLinks = (value: string): Optional<Nullable<string[]>> => {
  if (!value) return null;
  const links = value.split(', ');

  if (links.length === 0) return undefined;

  return value.split(', ');
};

const normalizeDate = (value: string, dateFormat?: string): Optional<Nullable<string>> => {
  if (!value) return null;

  return isValidDate(parseDateByFormat(value, dateFormat)) ? value : undefined;
};

const normalizeStatusValue = (value: string, data: SpreadsheetRowData, statuses: CampaignStatus[]) => {
  const currentStatusId = data.status.value;

  if (currentStatusId === value) return currentStatusId;

  const availableStatuses = statuses.find(status => status.status === currentStatusId)?.availableTransitions;
  const newStatusOption = statuses.find(status => status.status === value);

  if (!newStatusOption) {
    return undefined;
  }

  const canChangeStatus = availableStatuses?.includes(newStatusOption.status);

  if (!canChangeStatus) {
    return undefined;
  }

  return newStatusOption.status;
};

export const getColumnsValidationConfig = ({
  dateFormat,
  performanceObjectives,
  goals,
  taxonomy,
  genders,
  countries,
  statuses,
}: InitialConfigParams): SpreadsheetValidationModel => ({
  [MediaPlanColumnId.CHECKBOX]: {
    normalizeValue: () => null,
    formatToString: () => null,
  },
  [MediaPlanColumnId.ACTIONS]: {
    normalizeValue: () => null,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.PHASE_ID]: {
    normalizeValue: () => null,
    formatToString: () => null,
  },
  [MediaPlanColumnId.STATUS]: {
    normalizeValue: ({ value, data }) => normalizeStatusValue(value, data, statuses),
    formatToString: ({ value }) => value,
    errorText: 'Invalid Status type, data can not be copied',
  },
  [MediaPlanColumnId.NAME]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.PLATFORMS]: {
    normalizeValue: ({ value }) => normalizeMultiSelectValue(value, getFlattenedPlatforms(taxonomy?.platforms || [])),
    formatToString: ({ value }) => value?.map(item => item.name).join(', '),
  },
  [MediaPlanColumnId.PLACEMENTS]: {
    normalizeValue: ({ value, data }) => processPlacements(data.platforms, taxonomy, value),
    formatToString: ({ value }) => value?.map(item => item.name).join(', '),
    errorText: "Error Occurred! We're sorry, Placements inconsistent with Platforms were pasted into the table.",
  },
  [MediaPlanColumnId.OBJECTIVE]: {
    normalizeValue: ({ value }) => normalizeSingleSelectValue(value, goals?.flatMap(goal => goal.objectives) || []),
    formatToString: ({ value }) => value?.name,
  },
  [MediaPlanColumnId.KPI]: {
    normalizeValue: ({ value }) =>
      normalizeSingleSelectValue(
        value,
        getSelectOptions(performanceObjectives).flatMap(item => item.options)
      ),
    formatToString: ({ value }) => value?.value,
  },
  [MediaPlanColumnId.START_DATE]: {
    normalizeValue: ({ value }) => normalizeDate(value, dateFormat),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.END_DATE]: {
    normalizeValue: ({ value }) => normalizeDate(value, dateFormat),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.BUDGET]: {
    normalizeValue: ({ value }) => normalizeNumber(value),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.ACTUAL_SPENT]: {
    normalizeValue: ({ value }) => normalizeNumber(value),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.AGE]: {
    normalizeValue: ({ value }) => normalizeAge(value),
    formatToString: ({ value }) => value && `${value.lowerAge}-${value.higherAge}`,
  },
  [MediaPlanColumnId.AUDIENCE_GENDERS]: {
    normalizeValue: ({ value }) => normalizeMultiSelectValue(value, genders),
    formatToString: ({ value }) => value?.map(item => item.name).join(', '),
  },
  [MediaPlanColumnId.DETAILS]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.ECPM]: {
    normalizeValue: ({ value }) => normalizeNumber(value),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.EST_IMPRESSIONS]: {
    normalizeValue: ({ value }) => normalizeNumber(value),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.COUNTRIES]: {
    normalizeValue: ({ value }) => normalizeMultiSelectValue(value, prepareCountries(countries)),
    formatToString: ({ value }) => value?.map(item => item.name).join(', '),
  },
  [MediaPlanColumnId.AD_COPY]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.AD_CREATIVE]: {
    normalizeValue: ({ value }) => normalizeLinks(value),
    formatToString: ({ value }) => value?.join(', '),
  },
  [MediaPlanColumnId.NOTES]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.CREATIVE_DESCRIPTION]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.DESTINATION]: {
    normalizeValue: ({ value }) => normalizeLinks(value),
    formatToString: ({ value }) => value?.join(', '),
  },
  [MediaPlanColumnId.HEADLINE]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.CALL_TO_ACTION]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.NAMING_CONVENTION]: {
    normalizeValue: () => undefined,
    formatToString: ({ value, node }) => (node?.data?.namingConvention.value ?? '') + (value ?? ''),
    errorText: 'Error Occurred! We’re sorry, you cannot update read-only data.',
  },
});

export const getIsCellEditableCallback = (
  id: string
): ((context?: SpreadsheetContext, data?: SpreadsheetRowData) => boolean) => {
  switch (id) {
    case MediaPlanColumnId.STATUS:
    case MediaPlanColumnId.NAME:
    case MediaPlanColumnId.PLATFORMS:
    case MediaPlanColumnId.OBJECTIVE:
    case MediaPlanColumnId.KPI:
    case MediaPlanColumnId.START_DATE:
    case MediaPlanColumnId.END_DATE:
    case MediaPlanColumnId.BUDGET:
    case MediaPlanColumnId.ACTUAL_SPENT:
    case MediaPlanColumnId.AGE:
    case MediaPlanColumnId.AUDIENCE_GENDERS:
    case MediaPlanColumnId.DETAILS:
    case MediaPlanColumnId.ECPM:
    case MediaPlanColumnId.CREATIVE_DESCRIPTION:
    case MediaPlanColumnId.COUNTRIES:
    case MediaPlanColumnId.AD_COPY:
    case MediaPlanColumnId.AD_CREATIVE:
    case MediaPlanColumnId.DESTINATION:
    case MediaPlanColumnId.HEADLINE:
    case MediaPlanColumnId.CALL_TO_ACTION:
    case MediaPlanColumnId.NOTES:
    case MediaPlanColumnId.NAMING_CONVENTION:
      return isBaseCellEditable;
    case MediaPlanColumnId.PLACEMENTS:
      return isPlacementsCellEditable;
    default:
      return () => false;
  }
};

export const isBaseCellEditable = (context?: SpreadsheetContext, data?: SpreadsheetRowData) =>
  context?.accessMode === MediaPlanAccessMode.EDIT &&
  (context?.mediaPlanMode !== MediaPlanMode.APPROVAL || data?.editableInApproval.value || false);

export const isPlacementsCellEditable = (context?: SpreadsheetContext, data?: SpreadsheetRowData) =>
  isBaseCellEditable(context, data) && canEditPlacements(data?.platforms.value, context?.taxonomy);

export const isCellEditable: EditableCallback<SpreadsheetRowData> = params =>
  getIsCellEditableCallback(params.column.getColId())(params.context, params.data);

const calculateRowIndex = ({ node, data, context, api }: ValueGetterParams<SpreadsheetRowData>) => {
  const isGroupRow = node?.group || node?.footer;
  const isFilteredApproval = context.mediaPlanMode === MediaPlanMode.APPROVAL && api.isAnyFilterPresent();

  if (node?.footer && isFilteredApproval) {
    const rowGroup = node?.sibling;
    const prevFooterValue = api.getValue(MediaPlanColumnId.ACTIONS, rowGroup);

    return rowGroup?.allLeafChildren.length + (prevFooterValue ?? 0) + 1;
  }

  if (node?.group && isFilteredApproval) {
    let phaseIndex;
    let footerCount = 0;

    api.forEachNode((iteratedNode, index) => {
      if (iteratedNode.id === node.id) {
        phaseIndex = index;
        return;
      }

      if (iteratedNode.group && phaseIndex === undefined) {
        footerCount++;
      }
    });

    return phaseIndex + footerCount + 1;
  }

  if (isGroupRow) return (node?.rowIndex || 0) + 1;

  const groupIndex = node?.parent ? api.getValue(MediaPlanColumnId.ACTIONS, node.parent) : 0;

  return (data?.orderInPhase?.value || 0) + (groupIndex ?? 0) + 1;
};

const aggregateNumbers = ({
  values,
}: IAggFuncParams<SpreadsheetRowData, SpreadsheetCellData<any>>): SpreadsheetCellData<number | null> & {
  toString: () => string;
} => {
  const numberValues = values.flatMap(({ value }) => {
    const numberValue = parseFloat(value);
    return isNaN(numberValue) ? [] : [numberValue];
  });

  const value = numberValues.length > 0 ? sum(numberValues) : null;

  return {
    ...getInitialCellData(value),
    toString: () => (value ? value.toString() : ''),
  };
};

export const getInitialConfig = ({
  dateFormat,
  performanceObjectives,
  currencyCode,
  goals,
  taxonomy,
  genders,
  countries,
  statuses,
  projectId,
}: InitialConfigParams): ColDef<SpreadsheetRowData>[] => {
  return [
    {
      field: MediaPlanColumnId.CHECKBOX,
      editable: isCellEditable,
      width: CHECKBOX_COLUMN_WIDTH,
      minWidth: CHECKBOX_COLUMN_WIDTH,
      sortable: false,
      resizable: false,
      headerName: '',
      headerClass: ['not-resizable-header-cell', 'action-header-cell'],
      cellClass: 'action-cell',
      suppressFillHandle: true,
      suppressNavigable: true,
      suppressPaste: true,
      suppressSizeToFit: true,
      suppressAutoSize: true,
      cellRendererSelector: suppressFooterRendererSelector,
      checkboxSelection: params => !params.node.footer,
      showDisabledCheckboxes: true,
      headerCheckboxSelection: true,
      hide: true,
      pinned: true,
      equals: () => true,
    },
    {
      field: MediaPlanColumnId.ACTIONS,
      editable: isCellEditable,
      width: ACTION_COLUMN_WIDTH,
      minWidth: ACTION_COLUMN_WIDTH,
      sortable: false,
      resizable: false,
      headerName: '',
      headerClass: ['not-resizable-header-cell', 'action-header-cell'],
      cellClass: 'action-cell',
      cellRendererParams: { dateFormat },
      cellRenderer: ActionButton,
      suppressFillHandle: true,
      suppressNavigable: true,
      suppressPaste: true,
      suppressSizeToFit: true,
      suppressAutoSize: true,
      pinned: true,
      valueGetter: calculateRowIndex,
    },
    {
      field: MediaPlanColumnId.PHASE_ID,
      editable: isCellEditable,
      rowGroup: true,
      hide: true,
      pinned: false,
      checkboxSelection: true,
      keyCreator: ({ value }) => value.value,
    },
    {
      field: MediaPlanColumnId.STATUS,
      editable: isCellEditable,
      headerName: 'Status',
      cellRenderer: StatusRenderer,
      cellRendererParams: { statuses },
      cellEditor: StatusEditor,
      aggFunc: () => 'Total',
      suppressPaste: ({ context }) => context.mediaPlanMode === MediaPlanMode.APPROVAL,
      cellRendererSelector: params =>
        params.node.footer ? { component: TotalRenderer } : { component: StatusRenderer },
    },
    {
      field: MediaPlanColumnId.NAME,
      editable: isCellEditable,
      headerName: 'Campaign Description',
      initialWidth: 161,
      cellRenderer: CampaignNameRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.PLATFORMS,
      editable: isCellEditable,
      initialWidth: 220,
      cellRenderer: PlatformsRenderer,
      cellEditor: PlatformsEditor,
      valueFormatter: formatSelectCell,
      cellEditorParams: { taxonomy },
      sortable: true,
      comparator: multiSelectComparator,
      cellRendererSelector: suppressFooterRendererSelector,
      equals: ({ color: colorA, value: valueA } = {}, { color: colorB, value: valueB } = {}) => {
        const isPlatformsEqual = isEqual(valueA, valueB);

        if (!isPlatformsEqual && valueB && valueB.length !== 0) {
          trackEvent(AnalyticsEvents.PLATFORM_SELECTED, {
            projectId,
            platforms: valueB.map(platform => platform.name),
          });
        }

        return isPlatformsEqual && colorA === colorB;
      },
    },
    {
      field: MediaPlanColumnId.PLACEMENTS,
      editable: isCellEditable,
      initialWidth: 169,
      cellRenderer: PlacementsRenderer,
      cellEditor: PlacementsEditor,
      cellEditorParams: { taxonomy },
      cellClassRules: {
        ['cell-disabled']: params => !params.node.footer && !canEditPlacements(params.data?.platforms.value, taxonomy),
      },
      valueFormatter: formatSelectCell,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.OBJECTIVE,
      editable: isCellEditable,
      initialWidth: 160,
      cellRenderer: ObjectiveRenderer,
      cellEditor: ObjectiveEditor,
      cellEditorParams: { goals },
      valueFormatter: formatSelectCell,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.KPI,
      editable: isCellEditable,
      initialWidth: 141,
      headerName: 'KPI',
      cellRenderer: KpiRenderer,
      cellRendererParams: { performanceObjectives },
      cellEditor: KpiEditor,
      valueFormatter: params => params.value?.value?.value,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.START_DATE,
      editable: isCellEditable,
      headerName: 'Start Date',
      initialWidth: 100,
      cellRenderer: StartDateRenderer,
      cellRendererParams: { dateFormat },
      cellEditor: StartDateEditor,
      valueFormatter: props => props.value?.value,
      cellClassRules: {
        ['cell-error']: params =>
          getIsScheduleInvalid(params.data?.startDate.value, params.data?.endDate.value, dateFormat),
      },
      sortable: true,
      comparator: dateComparator,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.END_DATE,
      editable: isCellEditable,
      headerName: 'End Date',
      initialWidth: 100,
      cellRenderer: EndDateRenderer,
      cellRendererParams: { dateFormat },
      cellEditor: EndDateEditor,
      valueFormatter: props => props.value?.value,
      cellClassRules: {
        ['cell-error']: params =>
          getIsScheduleInvalid(params.data?.startDate.value, params.data?.endDate.value, dateFormat),
      },
      sortable: true,
      comparator: dateComparator,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.BUDGET,
      editable: isCellEditable,
      headerName: `Budget (${currencyCode})`,
      initialWidth: 87,
      sortable: true,
      headerClass: 'ag-header-cell-label-align-right',
      cellRenderer: CurrencyRenderer,
      cellRendererParams: { currencyCode },
      cellEditor: BudgetInputEditor,
      cellEditorParams: { dataSelector: 'phase-budget-campaign-spreadsheet', currencyCode },
      comparator: numberComparator,
      aggFunc: aggregateNumbers,
      valueFormatter: ({ value }) => (value?.value == null ? ' ' : value.value),
      cellRendererSelector: params => {
        return params.node.footer
          ? {
              component: CurrencyRenderer,
              params: {
                currencyCode,
                isDisabled: true,
              },
            }
          : {
              component: params.colDef?.cellRenderer,
            };
      },
      cellClass: ['currencyFormat'],
      cellClassRules: {
        ['cell-footer']: params => params.node.footer,
      },
    },
    {
      field: MediaPlanColumnId.ACTUAL_SPENT,
      editable: isCellEditable,
      headerName: `Actual Spent (${currencyCode})`,
      initialWidth: 87,
      sortable: false,
      headerClass: 'ag-header-cell-label-align-right',
      cellRenderer: CurrencyRenderer,
      cellRendererParams: { currencyCode },
      cellEditor: BudgetInputEditor,
      cellEditorParams: { dataSelector: 'phase-actual-spent-campaign-spreadsheet', currencyCode },
      comparator: numberComparator,
      aggFunc: aggregateNumbers,
      valueFormatter: ({ value }) => (value?.value == null ? ' ' : value.value),
      cellRendererSelector: params => {
        return params.node.footer
          ? {
              component: CurrencyRenderer,
              params: {
                currencyCode,
                isDisabled: true,
              },
            }
          : {
              component: params.colDef?.cellRenderer,
            };
      },
      cellClass: ['currencyFormat'],
      cellClassRules: {
        ['cell-footer']: params => params.node.footer,
      },
    },
    {
      field: MediaPlanColumnId.AGE,
      editable: isCellEditable,
      initialWidth: 141,
      cellRenderer: AgeRenderer,
      cellEditor: AgeEditor,
      valueFormatter: ({ value }) => value?.value && `${value.value.lowerAge || 'YY'}-${value.value.higherAge || 'YY'}`,
      cellClassRules: {
        ['cell-error']: ({ value }) => getIsTargetAgeInvalid(value?.value),
      },
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.AUDIENCE_GENDERS,
      editable: isCellEditable,
      headerName: 'Gender',
      initialWidth: 141,
      cellRenderer: GendersRenderer,
      cellEditor: GendersEditor,
      valueFormatter: formatSelectCell,
      cellEditorParams: { genders },
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.DETAILS,
      editable: isCellEditable,
      headerName: 'Audience Details',
      initialWidth: 141,
      cellRenderer: DetailsRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.ECPM,
      editable: isCellEditable,
      headerName: `eCPM (${currencyCode})`,
      headerClass: 'ag-header-cell-label-align-right',
      initialWidth: 87,
      cellRenderer: CurrencyRenderer,
      cellRendererParams: {
        currencyCode,
        currencyFormat: CURRENCY_FORMATS.fixedDecimal,
      },
      valueFormatter: ({ value }) => (value?.value == null ? ' ' : value.value),
      cellEditor: BudgetInputEditor,
      cellEditorParams: {
        dataSelector: 'ecpm-budget-campaign-spreadsheet',
        currencyCode,
      },
      sortable: true,
      aggFunc: () => getInitialCellData(null),
      comparator: numberComparator,
      cellRendererSelector: suppressFooterRendererSelector,
      cellClass: ['currencyFormat'],
      cellClassRules: {
        ['cell-footer']: params => params.node.footer,
      },
    },
    {
      field: MediaPlanColumnId.EST_IMPRESSIONS,
      editable: isCellEditable,
      headerName: 'Est. Impressions',
      initialWidth: 142,
      valueGetter: estimatedImpressionsGetter,
      cellClass: 'cell-align-right',
      headerClass: 'ag-header-cell-label-align-right',
      sortable: true,
      aggFunc: aggregateNumbers,
      cellRenderer: SimpleNumberRenderer,
      comparator: numberComparator,
      cellClassRules: {
        ['cell-footer']: params => params.node.footer,
      },
    },
    {
      field: MediaPlanColumnId.CREATIVE_DESCRIPTION,
      editable: isCellEditable,
      initialWidth: 151,
      cellRenderer: CreativeDescriptionRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.COUNTRIES,
      editable: isCellEditable,
      headerName: 'Territory',
      initialWidth: 141,
      cellRenderer: CountriesRenderer,
      cellEditor: CountriesEditor,
      valueFormatter: formatSelectCell,
      cellEditorParams: { countries },
      cellRendererSelector: suppressFooterRendererSelector,
      cellClassRules: {
        ['cell-footer']: params => params.node.footer,
      },
    },
    {
      field: MediaPlanColumnId.AD_COPY,
      editable: isCellEditable,
      initialWidth: 141,
      cellRenderer: AdCopyRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.AD_CREATIVE,
      editable: isCellEditable,
      headerName: 'Ad Creative',
      initialWidth: 141,
      cellEditor: LinksEditor,
      cellRenderer: LinksRenderer,
      valueFormatter: ({ value }) => (Array.isArray(value?.value) ? value?.value?.join(', ') : value?.value),
      cellRendererSelector: suppressFooterRendererSelector,
      cellEditorSelector: params =>
        params.context.accessMode !== MediaPlanAccessMode.EDIT
          ? {
              component: LinksReadonlyEditor,
              popup: true,
            }
          : {
              component: params.colDef.cellEditor,
            },
      cellClass: ['hyperlinks'],
      cellClassRules: {
        ['cell-error']: ({ value }) => getIsLinksInvalid(value?.value),
      },
    },
    {
      field: MediaPlanColumnId.DESTINATION,
      editable: isCellEditable,
      headerName: 'Destination Link',
      initialWidth: 141,
      cellEditor: LinksEditor,
      cellRenderer: LinksRenderer,
      valueFormatter: ({ value }) => (Array.isArray(value?.value) ? value?.value?.join(', ') : value?.value),
      cellRendererSelector: suppressFooterRendererSelector,
      cellEditorSelector: params =>
        params.context.accessMode !== MediaPlanAccessMode.EDIT
          ? {
              component: LinksReadonlyEditor,
              popup: true,
            }
          : {
              component: params.colDef.cellEditor,
            },
      cellClass: ['hyperlinks'],
      cellClassRules: {
        ['cell-error']: ({ value }) => getIsLinksInvalid(value?.value),
      },
    },
    {
      field: MediaPlanColumnId.HEADLINE,
      editable: isCellEditable,
      initialWidth: 141,
      cellRenderer: HeadlineRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.CALL_TO_ACTION,
      editable: isCellEditable,
      initialWidth: 141,
      cellRenderer: CallToActionRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.NOTES,
      editable: isCellEditable,
      initialWidth: 141,
      cellRenderer: NotesRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.NAMING_CONVENTION,
      editable: isCellEditable,
      headerName: 'Naming Convention',
      initialWidth: 150,
      cellRenderer: NamingConventionRenderer,
      cellEditor: NamingConventionEditor,
      valueFormatter: ({ node, value }) => (node?.data?.namingConvention.value ?? '') + (value?.value ?? ''),
      cellRendererSelector: suppressFooterRendererSelector,
    },
  ];
};

export const getExcelStyles = (currencyCode: string): ExcelStyle[] => [
  {
    id: 'currencyFormat',
    numberFormat: {
      format: `${CURRENCY_CODE_TO_SIGN[currencyCode]} #,##0.00`,
    },
  },
  {
    id: 'hyperlinks',
    dataType: 'Formula',
    alignment: {
      horizontal: 'Center',
      vertical: 'Top',
      wrapText: true,
    },
  },
];

export const getColumnIndexByColId = (columns: Column[], colId: string): number | undefined =>
  columns.findIndex(col => col.getColId() === colId);

export const handleRemovingValue = (
  event: KeyboardEvent,
  api: GridApi<SpreadsheetRowData>,
  updateUndoStack: (undo: UndoData) => void
) => {
  event.preventDefault();
  event.stopPropagation();

  api.getCellRanges()?.forEach(range => {
    if (!range.startRow || !range.endRow) return;
    const colIds = range.columns.map(col => col.getColId()) as MediaPlanColumnId[];

    const startRowIndex = Math.min(range.startRow?.rowIndex, range.endRow?.rowIndex);
    const endRowIndex = Math.max(range.startRow?.rowIndex, range.endRow?.rowIndex);

    clearCells(startRowIndex, endRowIndex, colIds, api, updateUndoStack);
  });
};

export const clearCells = (
  start: number,
  end: number,
  columns: MediaPlanColumnId[],
  gridApi: GridApi<SpreadsheetRowData>,
  updateUndoStack: (undo: UndoData) => void
) => {
  const itemsToUpdate: any[] = [];
  const nodesToRefreshPlacements: RowNode[] = [];

  for (let i = start; i <= end; i++) {
    const row = gridApi.getModel().getRow(i);

    if (row?.footer || row?.group) continue;

    const data = { ...row?.data };

    columns.forEach(column => {
      if (column === MediaPlanColumnId.STATUS) return;

      const isPlacementsEmpty = !data.placements.value || data.placements.value.length === 0;

      if (
        column === MediaPlanColumnId.PLATFORMS &&
        row &&
        !isPlacementsEmpty &&
        !columns.includes(MediaPlanColumnId.PLACEMENTS)
      ) {
        showToast({
          id: 'media-plan-paste',
          type: ToastType.Warning,
          message: 'Pasted placements automatically updated to match selected Platforms.',
          replaceable: true,
        });

        nodesToRefreshPlacements.push(row);

        updateUndoStack({ field: MediaPlanColumnId.PLACEMENTS, data: { ...data } });
        data[MediaPlanColumnId.PLACEMENTS] = {
          ...data[MediaPlanColumnId.PLACEMENTS],
          value: null,
        };
      }

      updateUndoStack({ field: column, data: { ...data } });
      data[column] = {
        ...data[column],
        value: null,
      };
    });

    itemsToUpdate.push(data);
  }

  gridApi.applyTransactionAsync({ update: itemsToUpdate }, () => {
    if (nodesToRefreshPlacements.length > 0) {
      gridApi.refreshCells({
        columns: [MediaPlanColumnId.PLACEMENTS],
        rowNodes: nodesToRefreshPlacements,
        force: true,
      });
    }
  });
};

export const processDataFromClipboard = (data: CopyPastedCell[][], columnApi: ColumnApi): CopyPastedCell[][] => {
  if (data.length === 0) return [];

  const isFullRowCopy =
    data[0].length === columnApi.getAllDisplayedColumns()?.length - NOT_COPYABLE_MEDIA_PLAN_COLUMNS_COUNT;
  const namingConventionIndex =
    columnApi.getAllDisplayedColumns().findIndex(col => col.getColId() === MediaPlanColumnId.NAMING_CONVENTION) -
    NOT_COPYABLE_MEDIA_PLAN_COLUMNS_COUNT;

  return isFullRowCopy
    ? data.map(item => [...item.slice(0, namingConventionIndex), ...item.slice(namingConventionIndex + 1, item.length)])
    : data;
};

export const prepareCellDataForClipboard = (
  { column, value, api, node }: ProcessCellForExportParams<SpreadsheetRowData>,
  spreadsheetValidationModel: SpreadsheetValidationModel,
  addCopiedData: (data: [UUID, CopyPastedCell]) => void
) => {
  if (!value) return;

  const { color, value: cellValue } = value;

  const campaignUuid = node?.data?.uuid.value;
  const columnValidationModel = spreadsheetValidationModel[column.getColId()];

  if (campaignUuid) {
    addCopiedData([
      campaignUuid,
      { color, value: columnValidationModel.formatToString({ value: cellValue, api, node }) },
    ]);
  }

  return columnValidationModel.formatToString({ value: cellValue, api, node });
};

export const showInvalidDataCellError = (
  errorText = "Error Occurred! We're sorry, data of an invalid data type was pasted into the table."
): void => {
  showToast({
    id: 'media-plan-paste',
    type: ToastType.Error,
    message: errorText,
    replaceable: true,
  });
};

export const parseCellValueFromClipboard = (
  { color, value }: CopyPastedCell,
  columnId: MediaPlanColumnId,
  api: GridApi<SpreadsheetRowData>,
  columnApi: ColumnApi,
  spreadsheetValidationModel: SpreadsheetValidationModel,
  context: SpreadsheetContext,
  data: SpreadsheetRowData
): Optional<SpreadsheetRowData[keyof SpreadsheetRowData]> => {
  const isCellEditable = getIsCellEditableCallback(columnId)(context, data);
  const isFullRowCopy =
    first(api.getCellRanges())?.columns.length ===
    columnApi.getAllDisplayedColumns()?.length - NOT_COPYABLE_MEDIA_PLAN_COLUMNS_COUNT;

  if (!isCellEditable) {
    showInvalidDataCellError("Error Occurred! We're sorry, you cannot update read-only data.");
    return;
  }

  if (isFullRowCopy && columnId === MediaPlanColumnId.NAMING_CONVENTION) {
    return;
  }

  const columnValidationModel = spreadsheetValidationModel[columnId];
  const normalizedValue = columnValidationModel.normalizeValue({ value, data });

  if (normalizedValue !== undefined) {
    return {
      color,
      value: normalizedValue,
    };
  }

  showInvalidDataCellError(columnValidationModel.errorText);
  return;
};

export const pasteDataIntoSingleCell = (
  api: GridApi<SpreadsheetRowData>,
  columnApi: ColumnApi,
  spreadsheetValidationModel: SpreadsheetValidationModel,
  undo: UndoHook,
  context: SpreadsheetContext
) => (clipboard: CopyPastedCell[][]) => {
  const focusedCell = api.getFocusedCell();
  const allColumns = columnApi.getColumns();

  if (!focusedCell || !allColumns) return;

  let clipboardArrayIndex = 0;

  const clipboardArray = processDataFromClipboard(clipboard, columnApi);

  const transactionUpdate: SpreadsheetRowData[] = [];

  api.forEachNode(node => {
    let currentColumnIndex = getColumnIndexByColId(allColumns, focusedCell.column.getColId());

    if (node.rowIndex === null || currentColumnIndex === undefined || !node.data) return;
    if (node.rowIndex < focusedCell.rowIndex || node.rowIndex > focusedCell.rowIndex + clipboardArray.length - 1)
      return;

    const updatedRow = { ...node.data };

    clipboardArray[clipboardArrayIndex].forEach(value => {
      if (currentColumnIndex === undefined || !node.data) return;

      const currentColumnId = allColumns[currentColumnIndex]?.getColId() as MediaPlanColumnId | undefined;

      if (currentColumnId === undefined) return;

      const updatedCell = parseCellValueFromClipboard(
        value,
        currentColumnId,
        api,
        columnApi,
        spreadsheetValidationModel,
        context,
        updatedRow
      );

      if (updatedCell) {
        undo.updateUndoStack({ field: currentColumnId, data: node.data });
        updatedRow[currentColumnId] = updatedCell;

        if (currentColumnId === MediaPlanColumnId.PLATFORMS) {
          updatedRow[MediaPlanColumnId.PLACEMENTS] = updatePlacementsOnPlatformsChange(
            context,
            updatedRow,
            clipboardArray[clipboardArrayIndex].length === 1
          );
        }
      }

      currentColumnIndex++;
    });

    transactionUpdate.push(updatedRow);

    clipboardArrayIndex++;
  });

  api.applyTransactionAsync({
    update: transactionUpdate,
  });
};

export const getIsScheduleInvalid = (startDate?: string | null, endDate?: string | null, dateFormat?: string) => {
  if (!startDate || !endDate) return false;
  return isDateAfter(parseDateByFormat(startDate, dateFormat), parseDateByFormat(endDate, dateFormat));
};

export const getIsLinksInvalid = (value?: string[]) => {
  if (!value) return false;
  try {
    value.forEach(item => new DecibelLink(item));
    return false;
  } catch (e) {
    return true;
  }
};

export const numberComparator = (
  { value: valueA }: SpreadsheetCellData<number | null | undefined>,
  { value: valueB }: SpreadsheetCellData<number | null | undefined>,
  rowNodeA: RowNode<SpreadsheetRowData>,
  rowNodeB: RowNode<SpreadsheetRowData>,
  isInverted: boolean
) => {
  if (valueA === valueB || (valueA == null && valueB == null)) {
    const orderInPhaseA = rowNodeA.data?.orderInPhase.value ?? 0;
    const orderInPhaseB = rowNodeB.data?.orderInPhase.value ?? 0;

    return isInverted ? orderInPhaseB - orderInPhaseA : orderInPhaseA - orderInPhaseB;
  }

  if (rowNodeA.data?.orderOnSort.value != null && rowNodeB.data?.orderOnSort.value != null) {
    const orderOnSortA = rowNodeA.data.orderOnSort.value;
    const orderOnSortB = rowNodeB.data.orderOnSort.value;

    return isInverted ? orderOnSortB - orderOnSortA : orderOnSortA - orderOnSortB;
  }

  const orderInPhaseA = rowNodeA.data?.orderInPhase.value ?? 0;
  const orderInPhaseB = rowNodeB.data?.orderInPhase.value ?? 0;

  if (valueA == null && valueB == null)
    return isInverted ? orderInPhaseB - orderInPhaseA : orderInPhaseA - orderInPhaseB;

  if (valueA == null) return isInverted ? -1 : 1;
  if (valueB == null) return isInverted ? 1 : -1;

  return valueA - valueB;
};

export const multiSelectComparator = (
  valueA: SpreadsheetCellData<IdNameField[] | null | undefined>,
  valueB: SpreadsheetCellData<IdNameField[] | null | undefined>,
  rowNodeA: RowNode<SpreadsheetRowData>,
  rowNodeB: RowNode<SpreadsheetRowData>,
  isInverted: boolean
) => {
  if (rowNodeA.data?.orderOnSort.value !== undefined && rowNodeB.data?.orderOnSort.value !== undefined) {
    const orderOnSortA = rowNodeA.data.orderOnSort.value;
    const orderOnSortB = rowNodeB.data.orderOnSort.value;

    return isInverted ? orderOnSortB - orderOnSortA : orderOnSortA - orderOnSortB;
  }

  const stringA = valueA?.value?.map(item => item.name).join(' ') || '';
  const stringB = valueB?.value?.map(item => item.name).join(' ') || '';

  const orderInPhaseA = rowNodeA.data?.orderInPhase.value ?? 0;
  const orderInPhaseB = rowNodeB.data?.orderInPhase.value ?? 0;

  if (stringA?.length === 0 && stringB?.length === 0)
    return isInverted ? orderInPhaseB - orderInPhaseA : orderInPhaseA - orderInPhaseB;

  if (stringA?.length === 0) return isInverted ? -1 : 1;
  if (stringB?.length === 0) return isInverted ? 1 : -1;

  return stringA?.localeCompare(stringB);
};

export const dateComparator = (
  valueA: SpreadsheetCellData<string | null | undefined>,
  valueB: SpreadsheetCellData<string | null | undefined>,
  rowNodeA: RowNode<SpreadsheetRowData>,
  rowNodeB: RowNode<SpreadsheetRowData>,
  isInverted: boolean
) => {
  if (rowNodeA.data?.orderOnSort.value !== undefined && rowNodeB.data?.orderOnSort.value !== undefined) {
    const orderOnSortA = rowNodeA.data.orderOnSort.value;
    const orderOnSortB = rowNodeB.data.orderOnSort.value;

    return isInverted ? orderOnSortB - orderOnSortA : orderOnSortA - orderOnSortB;
  }

  const orderInPhaseA = rowNodeA.data?.orderInPhase.value ?? 0;
  const orderInPhaseB = rowNodeB.data?.orderInPhase.value ?? 0;

  if (!valueA?.value && !valueB?.value)
    return isInverted ? orderInPhaseB - orderInPhaseA : orderInPhaseA - orderInPhaseB;

  if (!valueA?.value) return isInverted ? -1 : 1;
  if (!valueB?.value) return isInverted ? 1 : -1;

  return getDiffInMiliseconds(parseDateByFormat(valueA.value, 'MM/dd/yy'), parseDateByFormat(valueB.value, 'MM/dd/yy'));
};

const suppressFooterRendererSelector = (params: ICellRendererParams<SpreadsheetRowData>) =>
  params.node.footer ? { component: null } : { component: params.colDef?.cellRenderer };

export const initialGroupOrderComparator = ({
  nodeA,
  nodeB,
}: InitialGroupOrderComparatorParams<SpreadsheetRowData>): number => {
  const orderA = nodeA.allLeafChildren[0].data?.phaseOrder.value ?? 0;
  const orderB = nodeB.allLeafChildren[0].data?.phaseOrder.value ?? 0;

  return orderA - orderB;
};

interface PrepareSpreadsheetDataParams {
  phases: MediaPlanPhase[];
  isEditableInApproval?: boolean;
  dateFormat?: string;
}

export const prepareSpreadsheetData = ({
  phases,
  isEditableInApproval,
  dateFormat,
}: PrepareSpreadsheetDataParams): SpreadsheetRowData[] => {
  const rows: SpreadsheetRowData[] = [];

  phases.forEach(phase => {
    const campaigns: SpreadsheetRowData[] = phase.campaigns.map(({ columns, style }) => {
      const fields: SpreadsheetRowData = {
        phaseId: getInitialCellData(phase.id),
        phaseName: getInitialCellData(phase.name),
        phaseOrder: getInitialCellData(phase.order),
        id: getInitialCellData(columns.id),
        uuid: getInitialCellData(columns.uuid),
        name: { value: columns.name, color: style?.name?.background ?? null },
        status: {
          value: columns.workflowStatus ? columns.workflowStatus.status : undefined,
          color: style?.workflowStatus?.background ?? null,
        },
        platforms: { value: columns.platforms, color: style?.platforms?.background ?? null },
        kpi: {
          value: columns.kpiMetricsField
            ? {
                id: columns.kpiMetricsField.id,
                value: columns.kpiMetricsField.name,
              }
            : undefined,
          color: style?.kpiMetricsFieldId?.background ?? null,
        },
        objective: { value: columns.objective, color: style?.objective?.background ?? null },
        adCopy: { value: columns.adCreativeNotes, color: style?.adCreativeNotes?.background ?? null },
        notes: { value: columns.notes, color: style?.notes?.background ?? null },
        genders: { value: columns.genders, color: style?.genders?.background ?? null },
        countries: { value: columns.territories, color: style?.territories?.background ?? null },
        startDate: {
          value: getFormattedDate(dateFormat)(columns.startDate),
          color: style?.startDate?.background ?? null,
        },
        endDate: { value: getFormattedDate(dateFormat)(columns.endDate), color: style?.endDate?.background ?? null },
        age: { value: columns.audienceAge, color: style?.audienceLowerAge?.background ?? null },
        adCreativeLinks: { value: columns.adCreativeLinks, color: style?.adCreativeLinks?.background ?? null },
        audienceDetails: { value: columns.audienceNotes, color: style?.audienceNotes?.background ?? null },
        eCpm: { value: columns.ecpm, color: style?.ecpm?.background ?? null },
        budget: { value: columns.plannedBudget, color: style?.plannedBudget?.background ?? null },
        placements: { value: columns.placements, color: style?.placements?.background ?? null },
        orderInPhase: getInitialCellData(columns.orderInPhase),
        orderOnSort: getInitialCellData(undefined),
        destinationLinks: { value: columns.destinationLinks, color: style?.destinationLinks?.background ?? null },
        creativeDescription: {
          value: columns.creativeDescription,
          color: style?.creativeDescription?.background ?? null,
        },
        headline: { value: columns.headline, color: style?.headline?.background ?? null },
        callToAction: { value: columns.callToAction, color: style?.callToAction?.background ?? null },
        actualSpent: { value: columns.actualSpent, color: style?.actualSpent?.background ?? null },
        namingConvention: getInitialCellData(columns.namingConvention),
        namingConventionManual: {
          value: columns.namingConventionManual,
          color: style?.namingConventionManual?.background ?? null,
        },
        editableInApproval: getInitialCellData(isEditableInApproval),
        action: getInitialCellData(undefined),
        estImpressions: { value: undefined, color: style?.estImpressions?.background ?? null },
      };

      return fields;
    });

    rows.push(...campaigns);
  });

  return rows;
};

export const getIsTargetAgeInvalid = (targetAge?: AudienceAge | null): boolean => {
  if (!targetAge) return false;
  if (targetAge.lowerAge == null && targetAge.higherAge == null) return false;
  if (!targetAge.lowerAge || !targetAge.higherAge) return true;
  if (targetAge.higherAge < targetAge.lowerAge) return true;
  if (targetAge.lowerAge < MIN_AGE || targetAge.higherAge > MAX_AGE) return true;
  if (targetAge.lowerAge === targetAge.higherAge) return true;

  return false;
};

export const isMultipleColumnsSelected = (api: GridApi): boolean => {
  const cellRanges = api.getCellRanges();

  if (!cellRanges) return false;

  return cellRanges.some(range => range.columns.length > 1);
};

export const isMultipleRowsSelected = (api: GridApi): boolean => {
  const cellRanges = api.getCellRanges();

  if (!cellRanges) return false;

  return cellRanges.some(range => {
    if (range.startRow && range.endRow) {
      return range.startRow.rowIndex !== range.endRow.rowIndex;
    }

    return false;
  });
};

export const formatSelectCell: ValueFormatterFunc<SpreadsheetRowData> = ({ value }) =>
  Array.isArray(value?.value) ? value?.value.map(valueItem => valueItem.name).join(', ') : value?.value?.name;

export const exportMediaPlanToExcel = (api: GridApi<SpreadsheetRowData>, projectName: string) => {
  const sanitizeCell = (cell: string): string => {
    if (!cell || cell.trim().length === 0) {
      return cell;
    }

    const sanitizedString = `${cell.replace(/"/g, '""')}`;

    return `="${sanitizedString}"`;
  };

  api.exportDataAsExcel({
    fileName: `${projectName.replace(/\.|\s/g, '_')}_media_plan`,
    columnKeys: EXCEL_EXPORT_COLUMNS,
    autoConvertFormulas: true,
    suppressTextAsCDATA: false,
    processCellCallback: params => {
      const colDef = params.column.getColDef(); // BUG: type is ColDef<any> because of ag-grid

      if (!colDef?.field || !isMediaPlanColumnId(colDef.field)) {
        return;
      }

      const colId = params.column.getColId();
      const columnIndex = getColumnIndexByColId(params.columnApi.getAllDisplayedColumns(), colId);

      if (params.node?.group && columnIndex === FIRST_VALUE_COLUMN_EXPORT_INDEX) {
        return sanitizeCell(params.node.allLeafChildren?.[0].data.phaseName.value);
      }

      let value = params.value?.value;

      if (colDef?.field && COLUMNS_TO_SANITIZE.includes(colDef?.field) && typeof value === 'string') {
        value = sanitizeCell(value);
      }

      if (colDef?.field && LINK_COLUMNS.includes(colDef?.field) && !params.node?.group) {
        return sanitizeCell(value.toString());
      }

      if (colDef.valueFormatter && params.node && typeof colDef.valueFormatter !== 'string') {
        const valueFormatterParams: ValueFormatterParams<SpreadsheetRowData> = {
          ...params,
          data: params.node.data,
          node: params.node,
          colDef: params.column.getColDef(),
        };
        if (colDef.field === MediaPlanColumnId.NAMING_CONVENTION) {
          return sanitizeCell(colDef.valueFormatter(valueFormatterParams));
        }
        return colDef.valueFormatter(valueFormatterParams);
      }
      return value;
    },
  });
};

export const isLastPhase = (api: GridApi<SpreadsheetRowData>) => {
  const phases: Set<number> = new Set([]);

  api.forEachNode(node => {
    if (node.group || node.footer || !node.data) return;

    phases.add(node.data.phaseId.value);
  });

  return phases.size < 2;
};

export const isTableSorted = (columnApi: ColumnApi): boolean => {
  const columns = columnApi.getColumnState();
  return columns.some(({ sort }) => sort != null);
};

export const isGroupNodes = (nodes: RowNode<SpreadsheetRowData>[]): boolean => nodes.every(({ group }) => group);

export const postSortRows = ({ columnApi, nodes, api }: PostSortRowsParams<SpreadsheetRowData>) => {
  const isGroupRows = isGroupNodes(nodes);
  const isOrderOnSortSet = nodes
    .filter(node => !node.group && !node.footer && node.data)
    .every(({ data }) => data?.orderOnSort.value !== undefined);

  const isSorted = isTableSorted(columnApi);

  if (!isOrderOnSortSet && isSorted) {
    const data = nodes
      .filter(node => !node.group && !node.footer)
      .flatMap(({ data }, index) =>
        data
          ? [
              {
                ...data,
                orderOnSort: { ...data.orderOnSort, value: index },
              },
            ]
          : []
      );

    api.applyTransaction({ update: data });
    return;
  }

  if (isGroupRows) {
    nodes.sort((nodeA, nodeB) => {
      const phaseOrderA = nodeA.allLeafChildren[0]?.data?.phaseOrder.value ?? 0;
      const phaseOrderB = nodeB.allLeafChildren[0]?.data?.phaseOrder.value ?? 0;

      return phaseOrderA - phaseOrderB;
    });
  } else {
    nodes.sort((nodeA, nodeB) => {
      const orderInPhaseA = nodeA.data?.orderInPhase.value ?? 0;
      const orderOnSortA = nodeA.data?.orderOnSort.value ?? 0;

      const orderInPhaseB = nodeB.data?.orderInPhase.value ?? 0;
      const orderOnSortB = nodeB.data?.orderOnSort.value ?? 0;

      return isSorted ? orderOnSortA - orderOnSortB : orderInPhaseA - orderInPhaseB;
    });
  }
};

export const onSortChanged = ({ api }: SortChangedEvent<SpreadsheetRowData>) => {
  const updateTransaction: SpreadsheetRowData[] = [];

  api.forEachNode(({ data }) => {
    if (data) {
      updateTransaction.push({ ...data, orderOnSort: { ...data.orderOnSort, value: undefined } });
    }
  });

  api.applyTransaction({
    update: updateTransaction,
  });
};

type CellValueDataForRequestInput = {
  [K in keyof SpreadsheetRowData]: { column: K; value: SpreadsheetRowFields[K] };
}[keyof SpreadsheetRowData];

export const prepareCellValueDataForRequest = (
  input: CellValueDataForRequestInput,
  params?: Partial<InitialConfigParams>
): Partial<MediaPlanCampaignRequest> => {
  if (!input) return {};

  const { column, value } = input;

  switch (column) {
    case MediaPlanColumnId.PLATFORMS:
      return { platforms: value?.map(platform => platform.id) || null };
    case MediaPlanColumnId.AD_COPY:
      return { adCreativeNotes: value || null };
    case MediaPlanColumnId.DETAILS:
      return { audienceNotes: value || null };
    case MediaPlanColumnId.AD_CREATIVE:
      return { adCreativeLinks: value || null };
    case MediaPlanColumnId.DESTINATION:
      return { destinationLinks: value || null };
    case MediaPlanColumnId.BUDGET:
      return { plannedBudget: value || null };
    case MediaPlanColumnId.ECPM:
      return { ecpm: value || null };
    case MediaPlanColumnId.AGE:
      return { audienceHigherAge: value?.higherAge || null, audienceLowerAge: value?.lowerAge || null };
    case MediaPlanColumnId.KPI:
      return { kpiMetricsFieldId: value !== undefined && value !== null ? value.id : null };
    case MediaPlanColumnId.OBJECTIVE:
      return { objective: value !== undefined && value !== null ? value.id : null };
    case MediaPlanColumnId.COUNTRIES:
      return { territories: value?.map(country => country.id) || null };
    case MediaPlanColumnId.STATUS:
      return { workflowStatus: value !== undefined && value !== null ? value : null };
    case MediaPlanColumnId.PLACEMENTS:
      return { placements: value?.map(placement => placement.id) || null };
    case MediaPlanColumnId.END_DATE:
      if (!value) return { endDate: null };

      return { endDate: getFormattedDate('yyyy-MM-dd')(parseDateByFormat(value, params?.dateFormat)) };
    case MediaPlanColumnId.START_DATE:
      if (!value) return { startDate: null };

      return { startDate: getFormattedDate('yyyy-MM-dd')(parseDateByFormat(value, params?.dateFormat)) };

    case MediaPlanColumnId.AUDIENCE_GENDERS:
      return { genders: value?.map(gender => gender.id) || null };
    case MediaPlanColumnId.NOTES:
      return { notes: value || null };
    case MediaPlanColumnId.NAME:
      return { name: value || null };
    case MediaPlanColumnId.CREATIVE_DESCRIPTION:
      return { creativeDescription: value || null };
    case MediaPlanColumnId.HEADLINE:
      return { headline: value || null };
    case MediaPlanColumnId.CALL_TO_ACTION:
      return { callToAction: value || null };
    case MediaPlanColumnId.ACTUAL_SPENT:
      return { actualSpent: value || null };
    case MediaPlanColumnId.NAMING_CONVENTION:
      return { namingConventionManual: value || null };
    default:
      return {};
  }
};

type CellColorForRequestInput = {
  [K in keyof SpreadsheetRowData]: { column: K; color: string | null };
}[keyof SpreadsheetRowData];

export const prepareCellColorForRequest = (
  input: CellColorForRequestInput
): Partial<MediaPlanCampaignStylesRequest> => {
  if (!input) return {};

  const { column, color } = input;
  const background = color ? { background: color } : null;

  switch (column) {
    case MediaPlanColumnId.PLATFORMS:
      return { platforms: background };
    case MediaPlanColumnId.AD_COPY:
      return { adCreativeNotes: background };
    case MediaPlanColumnId.DETAILS:
      return { audienceNotes: background };
    case MediaPlanColumnId.AD_CREATIVE:
      return { adCreativeLinks: background };
    case MediaPlanColumnId.DESTINATION:
      return { destinationLinks: background };
    case MediaPlanColumnId.BUDGET:
      return { plannedBudget: background };
    case MediaPlanColumnId.ECPM:
      return { ecpm: background };
    case MediaPlanColumnId.AGE:
      return {
        audienceHigherAge: background,
        audienceLowerAge: background,
      };
    case MediaPlanColumnId.KPI:
      return { kpiMetricsFieldId: background };
    case MediaPlanColumnId.OBJECTIVE:
      return { objective: background };
    case MediaPlanColumnId.COUNTRIES:
      return { territories: background };
    case MediaPlanColumnId.STATUS:
      return { workflowStatus: background };
    case MediaPlanColumnId.PLACEMENTS:
      return { placements: background };
    case MediaPlanColumnId.END_DATE:
      return { endDate: background };
    case MediaPlanColumnId.START_DATE:
      return { startDate: background };
    case MediaPlanColumnId.AUDIENCE_GENDERS:
      return { genders: background };
    case MediaPlanColumnId.NOTES:
      return { notes: background };
    case MediaPlanColumnId.NAME:
      return { name: background };
    case MediaPlanColumnId.CREATIVE_DESCRIPTION:
      return { creativeDescription: background };
    case MediaPlanColumnId.HEADLINE:
      return { headline: background };
    case MediaPlanColumnId.CALL_TO_ACTION:
      return { callToAction: background };
    case MediaPlanColumnId.ACTUAL_SPENT:
      return { actualSpent: background };
    case MediaPlanColumnId.NAMING_CONVENTION:
      return { namingConventionManual: background };
    case MediaPlanColumnId.EST_IMPRESSIONS:
      return { estImpressions: background };
    default:
      return {};
  }
};

export const isKeySymbol = (key: string) => {
  const symbolMatch = key.match(/(\w|\s)/g);

  return symbolMatch !== null && symbolMatch.length === 1;
};

export const aggregatePendingDataStore = (
  pendingData: PendingData,
  store: keyof PendingData,
  data?: SpreadsheetRowData
): Nullable<PendingDataStore> => {
  const pendingDataStore = pendingData[store];

  if (!data) return pendingDataStore;

  const id = data.uuid.value;

  if (id === undefined) return pendingDataStore;

  if (pendingDataStore === null) {
    return { [id]: data };
  } else {
    return {
      ...pendingDataStore,
      [id]: Object.assign(pendingDataStore[id] ?? {}, data),
    };
  }
};

export const sanitizePendingDataStore = (pendingData: PendingData): PendingData => {
  const pendingDataCopy = cloneDeep(pendingData);

  if (pendingDataCopy.delete !== null) {
    for (const id in pendingDataCopy.delete) {
      delete pendingDataCopy.create?.[id];
      delete pendingDataCopy.update?.[id];
    }
  }

  if (pendingDataCopy.create !== null) {
    for (const id in pendingDataCopy.create) {
      pendingDataCopy.create[id] = Object.assign(pendingDataCopy.create[id], pendingDataCopy.update?.[id]);
      delete pendingDataCopy.update?.[id];
    }
  }

  if (pendingDataCopy.update !== null) {
    for (const id in pendingDataCopy.update) {
      pendingDataCopy.update[id] = Object.assign(pendingDataCopy.update[id], {
        orderInPhase: { ...pendingDataCopy.update[id].orderInPhase, value: undefined },
      });
    }
  }

  return pendingDataCopy;
};

export const mapPendingData = (
  pendingData: PendingData,
  mediaPlanMode?: MediaPlanMode,
  params?: Partial<InitialConfigParams>
): ModifyBulkCampaignsRequest => {
  const payload: ModifyBulkCampaignsRequest = { create: [], update: [], delete: [] };

  if (pendingData.create !== null) {
    Object.values(pendingData.create).forEach(data => {
      const { style, columns } = Object.entries(data).reduce<{
        style: MediaPlanCampaignStylesRequest;
        columns: MediaPlanCampaignRequest;
      }>(
        (acc, [column, { color, value }]) => {
          const colorsPayload = {
            ...acc.style,
            ...prepareCellColorForRequest({ column, color } as CellColorForRequestInput),
          };

          if (column === MediaPlanColumnId.STATUS && mediaPlanMode === MediaPlanMode.APPROVAL)
            return {
              ...acc,
              style: colorsPayload,
            };

          return {
            style: colorsPayload,
            columns: {
              ...acc.columns,
              ...prepareCellValueDataForRequest({ column, value } as CellValueDataForRequestInput, params),
            },
          };
        },
        { style: {}, columns: {} } as {
          style: MediaPlanCampaignStylesRequest;
          columns: MediaPlanCampaignRequest;
        }
      );

      payload.create.push({
        orderInPhase: data.orderInPhase.value,
        phaseId: data.phaseId.value,
        columns,
        campaignUuid: data.uuid.value,
        style,
      });
    });
  }

  if (pendingData.update !== null) {
    Object.values(pendingData.update).forEach(data => {
      if (!data.id) return;

      const { style, columns } = Object.entries(data).reduce(
        (acc, [column, { color, value }]) => {
          const colorsPayload = {
            ...acc.style,
            ...prepareCellColorForRequest({ column, color } as CellColorForRequestInput),
          };

          if (column === MediaPlanColumnId.STATUS && mediaPlanMode === MediaPlanMode.APPROVAL)
            return {
              ...acc,
              style: colorsPayload,
            };

          return {
            style: colorsPayload,
            columns: {
              ...acc.columns,
              ...prepareCellValueDataForRequest({ column, value } as CellValueDataForRequestInput, params),
            },
          };
        },
        { style: {}, columns: {} } as {
          style: MediaPlanCampaignStylesRequest;
          columns: MediaPlanCampaignRequest;
        }
      );

      payload.update.push({
        orderInPhase: data.orderInPhase.value,
        campaignUuid: data.uuid.value,
        columns,
        style,
      });
    });
  }

  if (pendingData.delete !== null) {
    Object.values(pendingData.delete).forEach(data => {
      if (!data.id) return;

      payload.delete.push(data.uuid.value);
    });
  }

  return payload;
};

export const getNamingConventionEditorInstance = (api: GridApi<SpreadsheetRowData>): Optional<NameEditorInstance> =>
  api.getCellEditorInstances({
    columns: [MediaPlanColumnId.NAMING_CONVENTION],
  })[0];

export const enrichPendingData = (api: GridApi<SpreadsheetRowData>, data: PendingData): PendingData => {
  const pendingDataCopy = cloneDeep(data);

  if (pendingDataCopy.update !== null) {
    for (const id in pendingDataCopy.update) {
      const updateData = pendingDataCopy.update[id];

      if (updateData.id.value === undefined) {
        const node = api.getRowNode(id);

        if (!node || !node.data) continue;

        if (updateData.id) {
          updateData.id.value = node.data.id.value;
        }
      }
    }
  }

  return pendingDataCopy;
};

export const processPlacements = (
  platforms: SpreadsheetRowData['platforms'],
  taxonomy?: Taxonomy,
  value?: Nullable<string>
): Optional<CampaignPlacement[]> => {
  if (!value) return;

  const selectedPlatforms = platforms.value?.map(item => item.id);
  const selectedPlacements = value?.split(', ') || [];
  const availablePlacements =
    getPlacementsOptions(taxonomy?.placements, selectedPlatforms, taxonomy?.platforms)?.[0]?.options || [];

  const validPlacements = availablePlacements
    ?.filter(placement => selectedPlacements.includes(placement.value))
    .map(placement => ({ id: placement.id, name: placement.value } as CampaignPlacement));

  return validPlacements.length === 0 ? undefined : validPlacements;
};

export const getTextFieldPlaceholder = (columnId?: string): Optional<string> => {
  switch (columnId) {
    case MediaPlanColumnId.AD_COPY:
      return 'Write or paste ad copy here...';
    case MediaPlanColumnId.DETAILS:
      return 'Write or paste details here...';
    case MediaPlanColumnId.NOTES:
      return 'Write or paste note here...';
    case MediaPlanColumnId.CREATIVE_DESCRIPTION:
      return 'Write or paste creative description here...';
    case MediaPlanColumnId.HEADLINE:
      return 'Write or paste headline here...';
    case MediaPlanColumnId.CALL_TO_ACTION:
      return 'Write or paste call to action here...';
    case MediaPlanColumnId.NAME:
      return 'Write or paste campaign description here...';

    default:
      return '';
  }
};

export const moveCellFocus = (api: GridApi<SpreadsheetRowData>, direction: FocusDirection) => {
  const cell = api.getFocusedCell();
  if (cell) {
    api.clearRangeSelection();
    api.setFocusedCell(cell.rowIndex + direction, cell.column);
  }
};

export const hasCampaignsStatuses = (api: GridApi<SpreadsheetRowData>, statuses: CampaignStatuses[]): boolean => {
  let hasStatuses = false;
  api.forEachNode(({ data }) => {
    if (statuses.includes(data?.status?.value as CampaignStatuses)) {
      hasStatuses = true;
    }
  });
  return hasStatuses;
};

export const isMediaPlanEditing = (api: GridApi<SpreadsheetRowData>) => {
  const editingCells = api.getEditingCells();
  return editingCells.length > 0;
};

export const isUndoKey = (event: KeyboardEvent): boolean => {
  const commandKey = IS_MAC_OS ? event.metaKey : event.ctrlKey;
  const undoShortCut = commandKey && event.code === 'KeyZ';

  return undoShortCut;
};

export const isRedoKey = (event: KeyboardEvent): boolean => {
  const commandKey = IS_MAC_OS ? event.metaKey : event.ctrlKey;

  const redoShortCut = commandKey && event.shiftKey && event.code === 'KeyZ';
  return redoShortCut;
};

export const getInitialCellData = <T extends SpreadsheetRowFields[keyof SpreadsheetRowFields]>(
  value: T
): SpreadsheetCellData<T> => ({
  value,
  color: null,
});

export const updateCellsColor = (api: GridApi<SpreadsheetRowData>, undo: UndoHook, color: string | null) => {
  const range = api.getCellRanges()?.[0];
  const focusedCell = api.getFocusedCell();

  if (!range || range.startRow?.rowIndex === undefined || range.endRow?.rowIndex === undefined) return;

  const startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
  const endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

  const updateTransaction: SpreadsheetRowData[] = [];
  const rowIndexes = getIndexesRange(startRowIndex, endRowIndex);

  rowIndexes.forEach(rowIndex => {
    const node = api.getDisplayedRowAtIndex(rowIndex);

    if (!node || !node.data) return;

    let updatedRow = node.data;

    range.columns.forEach(column => {
      if (!node || !node.data) return;

      const colId = column.getColId();
      const cellData = api.getValue(colId, node);

      if (cellData.color !== color) {
        undo.updateUndoStack({ field: colId as MediaPlanColumnId, data: node.data });
        updatedRow = { ...updatedRow, [colId]: { ...cellData, color } };
      }
    });

    updateTransaction.push(updatedRow);
  });

  api.applyTransactionAsync(
    {
      update: updateTransaction,
    },
    () => {
      const focusRowIndex = focusedCell?.rowIndex ?? startRowIndex;
      const focusColumnIndex = (focusedCell?.column ?? range.startColumn).getColId();

      api.clearFocusedCell();
      api.setFocusedCell(focusRowIndex, focusColumnIndex);
    }
  );
};

export const sendToClipboard = (data: Map<UUID, CopyPastedCell[]>, resetCopiedData: () => void) => () => {
  const textPlainArray: string[][] = [];
  const textHTMLArray: string[][] = [];

  for (const [, value] of data) {
    textPlainArray.push(value.map(({ value }) => value ?? ''));
    textHTMLArray.push(value.map(({ color }) => color ?? ''));
  }

  const textPlain = textPlainArray.map(row => row.join('\t')).join('\r\n');
  const textHTML = `<table xmlns="http://www.w3.org/1999/xhtml" cellspacing="0" cellpadding="0" ${COLORS_IDENTIFIER}><tbody>${textPlainArray
    .map(
      (row, rowIndex) =>
        `<tr>${row
          .map((column, columnIndex) => {
            const color = textHTMLArray[rowIndex]?.[columnIndex] ?? '';
            return `<td ${COLOR_IDENTIFIER}="${color}">${column || '<br style="visibility: hidden;">'}</td>`;
          })
          .join('')}</tr>`
    )
    .join('')}</tbody></table>`;

  if (isFirefox(navigator)) {
    const handleCopy = (event: ClipboardEvent) => {
      event.preventDefault();

      event.clipboardData?.setData('text/plain', textPlain);
      event.clipboardData?.setData('text/html', textHTML);

      document.removeEventListener('copy', handleCopy, true);

      resetCopiedData();
    };

    document.addEventListener('copy', handleCopy, true);
    document.execCommand('copy');
  } else {
    navigator.clipboard
      .write([
        new ClipboardItem({
          'text/html': new Blob([textHTML], { type: 'text/html' }),
          'text/plain': new Blob([textPlain], { type: 'text/plain' }),
        }),
      ])
      .then(() => {
        resetCopiedData();
      });
  }
};

export const getValuesFromClipboard = (data?: string): string[][] | undefined => {
  if (!data) return;

  return data.split(/\r\n|\r|\n/).map(colorsRowString => colorsRowString.split('\t'));
};

const parseColorsTable = (table: HTMLTableElement): string[][] => {
  const colorDataSelector = camelCase(COLOR_IDENTIFIER.replace('data-', ''));

  return Array.from(table.rows).reduce((acc, row) => {
    const columns = Array.from(row.children).map(
      column => (column as HTMLTableColElement).dataset[colorDataSelector] ?? ''
    );

    acc.push(columns);

    return acc;
  }, [] as string[][]);
};

export const getColorsFromClipboard = (data?: string): string[][] | undefined => {
  const colorsContainerElement = document.createElement('div');

  if (!data) return;

  colorsContainerElement.innerHTML = data;
  const colorsElement = colorsContainerElement.querySelector<HTMLTableElement>(`table[${COLORS_IDENTIFIER}]`);

  if (!colorsElement) return;

  return parseColorsTable(colorsElement);
};

export const mergeClipboardData = (values: string[][] = [], colors: string[][] = []): CopyPastedCell[][] => {
  const isValuesPrimary = values.length >= colors.length;
  const [primaryData, secondaryData] = isValuesPrimary ? [values, colors] : [colors, values];

  return primaryData.map((rowValues, rowIndex) =>
    rowValues.map((primaryValue, index) => {
      const secondaryValue = secondaryData[rowIndex]?.[index] ?? '';

      return isValuesPrimary
        ? { color: secondaryValue, value: primaryValue }
        : { color: primaryValue, value: secondaryValue };
    })
  );
};

export const normalizeIndexForCopyPaste = (data: unknown[], index: number): number =>
  data.length && index >= data.length ? index - Math.floor(index / data.length) * data.length : index;

export const getIndexesRange = (start: number, end: number): number[] => {
  const rangeIndexes = [start];

  Array.from({ length: end - start }, (_, index) => {
    rangeIndexes.push(start + index + 1);
  });

  return rangeIndexes;
};

export const updatePlacementsOnPlatformsChange = (
  context: SpreadsheetContext,
  data: SpreadsheetRowData,
  hasWarning: boolean
): SpreadsheetRowData['placements'] => {
  const currentPlacementsValue = data.placements.value;
  const processedPlacements = processPlacements(
    data.platforms,
    context.taxonomy,
    currentPlacementsValue?.map(({ name }) => name).join(', ')
  );

  if (
    hasWarning &&
    currentPlacementsValue &&
    currentPlacementsValue.length > 0 &&
    currentPlacementsValue.length !== processedPlacements?.length
  ) {
    showToast({
      id: 'media-plan-paste',
      type: ToastType.Warning,
      message: 'Pasted placements automatically updated to match selected Platforms.',
      replaceable: true,
    });
  }

  return {
    ...data.placements,
    value: processedPlacements ?? null,
  };
};
