import styled, { keyframes } from 'styled-components';
import { appHeader } from 'app/styles/z-indexes';
import { GLOBAL_SEARCH_WIDTH, MANHATTAN_HEADER_HEIGHT } from 'common/constants';
import { transparentize } from 'polished';
import { palette } from 'frontend-shared-theme';
import { motion } from 'framer-motion';
import { theme } from 'app/theme';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'global-search-modal';

const contentAnimation = keyframes`
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
`;

export const SGlobalSearchModal = styled(motion.div)`
  display: flex;
  position: fixed;
  z-index: ${appHeader};
  justify-content: center;
  width: 100vw;
  padding: 5px;
  background-color: ${transparentize(0.6, palette.darkBlue)};
  top: ${MANHATTAN_HEADER_HEIGHT}px;
  height: calc(100vh - ${MANHATTAN_HEADER_HEIGHT}px);
  backdrop-filter: blur(10px);

  .${BEM_CLASS} {
    &__content {
      flex-grow: 1;
      max-width: ${GLOBAL_SEARCH_WIDTH}px;
      max-height: 700px;
      height: 100%;
      background-color: ${transparentize(0.1, palette.white)};
      border-radius: 10px;
      box-shadow: ${theme.shadows.default};
      transform-origin: 50% 0;
      will-change: transform, opacity;
      animation: ${contentAnimation} 0.3s ease-out both;
      backdrop-filter: blur(10px);
    }

    &__loader {
      padding: 20px;
    }

    &__empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      padding: 20px;
    }

    &__empty-text {
      margin-bottom: 30px;
      color: ${manhattanThemeColors.grayedText};
      text-align: center;
    }

    &__empty-image {
      width: 229px;
    }
  }
`;
