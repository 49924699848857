import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeArtistModal } from 'project/actions';
import { ARTIST_MODAL_TYPES, ArtistModalType } from 'project/constants';
import {
  isArtistModalChangedSelector,
  isArtistModalOpenedSelector,
  selectedArtistsSelector,
  selectedPlaylistsSelector,
} from 'project/selectors';
import { Artist, Id, Playlist } from 'backend-api/models';
import { ModalSideBarConfig, ReusableModal } from 'common/components/reusable-modal';
import { DEFAULT_MIN_SEARCH_LENGTH } from 'common/constants';
import { ArtistsPlaylistsSection, ArtistsPlaylistsSelectedSection } from './components';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';

interface Props {
  projectId: Id;
  type: ArtistModalType;
  onModalSave: (artists: Artist[], playlists: Playlist[]) => void;
}

export const ArtistModal = React.memo(({ projectId, type = ArtistModalType.Artist, onModalSave }: Props) => {
  const dispatch = useDispatch();

  const infoType = ARTIST_MODAL_TYPES[type];

  const isOpened = useSelector(isArtistModalOpenedSelector);
  const isModalChanged = useSelector(isArtistModalChangedSelector);
  const selectedArtists = useSelector(selectedArtistsSelector);
  const selectedPlaylists = useSelector(selectedPlaylistsSelector);

  const primaryArtists = useMemo(() => selectedArtists.filter(artist => artist.type === 'Primary'), [selectedArtists]);

  const hasPrimaryArtists = type === ArtistModalType.Playlist || primaryArtists.length > 0;

  const isSaveDisabled = !isModalChanged || !hasPrimaryArtists;

  const [searchQuery, setSearchQuery] = useState<string>('');

  const onModalClose = useCallback(() => {
    setSearchQuery('');
    dispatch(closeArtistModal());
  }, [dispatch]);

  const onModalSubmit = useCallback(() => {
    setSearchQuery('');
    onModalSave(selectedArtists, selectedPlaylists);
    trackEvent(AnalyticsEvents.PROJECT_ARTIST_EDITED, { project_id: projectId });
    dispatch(closeArtistModal());
  }, [dispatch, onModalSave, selectedArtists, selectedPlaylists, projectId]);

  useEffect(() => {
    if (searchQuery.length >= DEFAULT_MIN_SEARCH_LENGTH) {
      dispatch(infoType.searchAction(searchQuery));
    }
  }, [dispatch, searchQuery, infoType]);

  const sideBarConfig: ModalSideBarConfig = useMemo(
    () => ({
      component: <ArtistsPlaylistsSelectedSection type={infoType} />,
    }),
    [infoType]
  );

  return (
    <ReusableModal
      width="1172px"
      height="600px"
      title={infoType.title}
      isOpen={isOpened}
      isSubmitDisabled={isSaveDisabled}
      searchValue={searchQuery}
      searchPlaceholder={infoType.searchPlaceholder}
      onSearchChange={setSearchQuery}
      submitTooltipContent="Select a primary artist"
      submitTooltipEnabled={!hasPrimaryArtists}
      onClose={onModalClose}
      onSubmit={onModalSubmit}
      sideBarConfig={sideBarConfig}
      dataSelector="artist-modal"
      hasSearch
      hasFooter
    >
      <ArtistsPlaylistsSection searchQuery={searchQuery} type={infoType} />
    </ReusableModal>
  );
});
