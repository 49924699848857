export const isElementInTree = (elementWithChildren: Element, element: Element) => {
  if (elementWithChildren === element) {
    return true;
  }

  const collectionHTML = elementWithChildren.children;

  for (let i = 0, { length } = collectionHTML; i < length; i += 1) {
    const el = collectionHTML[i];

    if (el === element) {
      return true;
    }

    if (el.children && el.children.length > 0 && isElementInTree(el, element)) {
      return true;
    }
  }

  return false;
};

export const getNearestScrollableParentElement = (node: HTMLElement | null): HTMLElement | null => {
  if (!node) return null;

  const parent = node.parentElement;

  if (!parent) return null;

  const { overflow } = window.getComputedStyle(parent);

  if (
    overflow.split(' ').some(o => o === 'auto' || o === 'scroll') &&
    (parent.scrollHeight > parent.offsetHeight || parent.scrollWidth > parent.offsetWidth)
  ) {
    return parent;
  } else {
    return getNearestScrollableParentElement(parent);
  }
};

export const scrollParentToChild = (parent: HTMLElement, child: HTMLElement) => {
  const parentRect = parent.getBoundingClientRect();
  const parentViewableArea = {
    height: parent.clientHeight,
    width: parent.clientWidth,
  };

  const childRect = child.getBoundingClientRect();
  const isViewable = childRect.top >= parentRect.top && childRect.bottom <= parentRect.top + parentViewableArea.height;

  if (!isViewable) {
    const scrollTop = childRect.top - parentRect.top;
    const scrollBot = childRect.bottom - parentRect.bottom;
    if (Math.abs(scrollTop) < Math.abs(scrollBot)) {
      parent.scrollBy({ top: scrollTop, behavior: 'smooth' });
    } else {
      parent.scrollBy({ top: scrollBot, behavior: 'smooth' });
    }
  }
};
