import { RefObject, useLayoutEffect, useState } from 'react';

export const useIsTextOverflown = (editorRef: RefObject<HTMLElement>, countLineHeightDiff = true) => {
  const [isTextOverflown, setIsTextOverflown] = useState(false);

  useLayoutEffect(() => {
    if (editorRef?.current) {
      const lineHeightDiff = countLineHeightDiff ? 1 : 0;
      const isNotesOverflown = editorRef.current.offsetWidth + lineHeightDiff < editorRef.current.scrollWidth;
      setIsTextOverflown(isNotesOverflown);
    }
  }, [editorRef, countLineHeightDiff]);

  return isTextOverflown;
};
