import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { Button } from 'common/components/button';
import { Icon } from 'common/components/icon';
import { SProjectCollaboratorsButton, BEM_CLASS } from './s-project-collaborators-button';

export enum ProjectCollaboratorsButtonMode {
  SHARE,
  MANAGE,
  VIEW,
}

interface ProjectCollaboratorsButtonProps {
  mode: ProjectCollaboratorsButtonMode;
  collaboratorsCount: number;
  onClick(): void;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ProjectCollaboratorsButton = React.memo(
  ({ mode, collaboratorsCount, onClick, className }: ProjectCollaboratorsButtonProps) => {
    const title = useMemo(() => {
      switch (mode) {
        case ProjectCollaboratorsButtonMode.SHARE:
          return 'SHARE PROJECT';
        case ProjectCollaboratorsButtonMode.VIEW:
          return 'VIEW SHARING';
        case ProjectCollaboratorsButtonMode.MANAGE:
          return 'MANAGE SHARING';
      }
    }, [mode]);

    const content = useMemo(() => {
      if (mode === ProjectCollaboratorsButtonMode.SHARE) {
        return (
          <>
            <Icon name="shared" className={classes('icon')} />
            {title}
          </>
        );
      }

      return (
        <>
          <div className={classes('container')}>
            <Icon name="shared" />
            {collaboratorsCount > 0 && collaboratorsCount}
          </div>
          {title}
        </>
      );
    }, [mode, title, collaboratorsCount]);

    return (
      <SProjectCollaboratorsButton className={className}>
        <Button
          disabled={false}
          dataSelector="project-collaborators-button"
          onClick={onClick}
          className={classes('button')}
        >
          {content}
        </Button>
      </SProjectCollaboratorsButton>
    );
  }
);
