export enum MetricsReportingType {
  STREAMS = 1,
  FOLLOWERS = 2,
  DSP_METRICS = 3,
}

export interface MetricsReportingParams {
  startDate: string;
  endDate: string;
  reportingType?: MetricsReportingType;
  territoryId: number;
}
