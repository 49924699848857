import { elipsizeText } from 'common/s-components/s-elipsize-text';
import styled from 'styled-components';

export const SMultiSelectTree = styled.div`
  overflow: auto;
  max-height: inherit;
  .multi-select {
    &__tree {
      padding: 0 21px 11px;
    }

    &__elipsized-text {
      ${elipsizeText};
    }
  }
`;
