import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { BEM_CLASS, SPlatformsEditor } from './s-platforms-editor';
import { ICellEditorParams } from 'ag-grid-community';
import { getFlattenedPlatforms, getPlatformsOptions } from 'common/transducers';
import { BaseOption, ClassNamesProps } from 'common/components/select';
import { MultiSelect } from 'common/components/select/multi-select';
import { MultiSelectType } from 'common/components/select/types';
import { bem } from 'utils/bem';
import { useSelectEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan/components/spreadsheet/hooks';
import { Id } from 'backend-api/models';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { isSubstring } from 'utils/string';
import { MAX_COUNT_CLEARABLE } from 'common/components/select/constants';
import { processPlacementsOnPlatformSelect } from 'media-plan/components/spreadsheet/transducers';
import { ExpandableField } from '../../expandable-field';
import { SpreadsheetRowData } from 'media-plan/components/spreadsheet/types';

const classes = bem(BEM_CLASS);

export const PlatformsEditor = React.memo(
  React.forwardRef((props: ICellEditorParams<SpreadsheetRowData, SpreadsheetRowData['platforms']>, ref) => {
    const [value, setValue] = useState(props.value.value);
    const { onBlur } = useStopEditingBlur(props.api);
    const taxonomy = useMemo(() => props.colDef?.cellEditorParams.taxonomy, [props.colDef?.cellEditorParams.taxonomy]);

    const handleChange = useCallback(
      (ids: Id[]) => {
        const platforms =
          getFlattenedPlatforms(taxonomy?.platforms || []).filter(platform => ids.includes(platform.id)) || [];

        setValue(platforms);
      },
      [taxonomy?.platforms]
    );

    const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions({
      api: props.api,
      value,
      setValue: handleChange,
      isMulti: true,
    });

    const selectedPlatformsIds = useMemo(() => value?.map(platform => platform.id) || [], [value]);

    const platformsOptions = useMemo(() => getPlatformsOptions(taxonomy?.platforms, selectedPlatformsIds), [
      selectedPlatformsIds,
      taxonomy?.platforms,
    ]);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return {
            ...props.value,
            value,
          };
        },
        isCancelAfterEnd() {
          processPlacementsOnPlatformSelect(selectedPlatformsIds || [], props.api, props.node, taxonomy);
          return false;
        },
      };
    });

    const selectClassNames = useCallback<(className: string) => ClassNamesProps>(
      className => ({
        control: classes('control'),
        valueContainer: { root: classes('value-container') },
        menu: classes('menu'),
        menuList: classes('menu-list'),
        indicatorsContainer: classes('indicators-container'),
        menuPortal: className,
      }),
      []
    );

    const filterOption = useCallback(
      (option: FilterOptionOption<BaseOption>, inputValue: string) =>
        isSubstring(option.data.value, inputValue) && !option.data.isDisabled,
      []
    );

    useEffect(() => {
      selectRef.current?.inputRef?.focus({ preventScroll: true });
    }, [selectRef]);

    return (
      <ExpandableField hasError={false}>
        <SPlatformsEditor width={props.column.getActualWidth()} height={props.node.rowHeight || 0}>
          {className => {
            const clearable = value ? value.length >= MAX_COUNT_CLEARABLE : false;

            return (
              <div className={className}>
                <MultiSelect
                  selectRef={selectRef}
                  type={MultiSelectType.AutoComplete}
                  dataSelector="platforms-editor"
                  valueIds={value?.map(value => value.id) || []}
                  valueHandler={handleChange}
                  options={platformsOptions}
                  isSearchable
                  classNames={selectClassNames(className)}
                  onKeyDown={onKeyDown}
                  filterOption={filterOption}
                  placeholder=""
                  onBlur={onBlur}
                  isClearable={clearable}
                  isOpened
                />
              </div>
            );
          }}
        </SPlatformsEditor>
      </ExpandableField>
    );
  })
);
