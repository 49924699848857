export const USD_CURRENCY_CODE = 'USD';
export const DEFAULT_CURRENCY_CODE = USD_CURRENCY_CODE;
export const DEFAULT_CURRENCY_SIGN = 'US$';

export const CURRENCY_FORMATS = {
  default: '0,0.[00]',
  fixedDecimal: '0,0.00',
  short: '0.[0]a',
  hero: '0.[00]a',
};

export const CURRENCY_CODE_TO_SIGN = {
  AED: 'د.إ',
  ARS: '$',
  AUD: '$',
  BAM: 'KM',
  BBD: '$',
  BHD: 'BD',
  BMD: '$',
  BOB: 'Bs',
  BRL: '$',
  BWP: 'P',
  CAD: '$',
  CHF: 'Fr',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  CUP: '$',
  CYP: '£',
  CZK: 'Kč',
  DKK: 'kr.',
  DOP: '$',
  EEK: 'kr',
  EGP: '£',
  EUR: '€',
  GBP: '£',
  GNF: 'Fr',
  GTQ: 'Q',
  HKD: '$',
  HNL: 'L',
  HRK: 'kn',
  HUF: 'Ft',
  IDR: 'Rp',
  ILS: '₪',
  INR: '₹',
  ISK: 'kr',
  JMD: '$',
  JOD: 'د.ا',
  JPY: '¥',
  KES: '/-',
  KRW: '₩',
  KWD: 'د.ك',
  KZT: '₸',
  LBP: 'LL',
  LKR: '₨',
  LSL: 'L',
  LTL: 'Lt',
  LVL: 'Ls',
  MAD: 'DH',
  MKD: 'den',
  MTL: '₤',
  MUR: '₨',
  MVR: 'Rf',
  MXN: '$',
  MYR: 'RM',
  NGN: '₦',
  NIO: '$',
  NOK: 'kr',
  NPR: 'Rs',
  NZD: '$',
  OMR: 'RO',
  PAB: 'B/.',
  PEN: 'S/',
  PHP: '₱',
  PKR: 'Rs',
  PLN: 'zł',
  PYG: '₲',
  QAR: 'QR',
  ROL: 'L',
  RUB: '₽',
  RWF: 'RF',
  SAR: 'SR',
  SEK: 'kr',
  SGD: '$',
  SIT: '€',
  SKK: 'Sk',
  SVC: '₡',
  SZL: 'L',
  THB: '฿',
  TND: 'DT',
  TRL: '₤',
  TWD: '$',
  TZS: 'TSh',
  UAH: '₴',
  UGX: 'USh',
  USD: '$',
  UYU: '$',
  VEB: 'Bs.',
  VND: '₫',
  XAF: 'FCFA',
  XOF: 'CFA',
  YER: '﷼',
  ZAR: 'R',
  ZMK: 'K',
  BGN: 'Лв.',
  RON: 'L',
  CSD: 'din',
  TRY: '₺',
  VEF: 'Bs.',
  RSD: 'din',
  VES: 'Bs.',
  AFN: '؋',
  GHS: '₵',
  MZN: 'MT',
  ZMW: 'K',
};
