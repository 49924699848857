import React, { useCallback, useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Icon } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { CurrentUserInfo, Id, LabelPermissionsArtistWithMembers, Optional } from 'backend-api/models';
import { SEditMembersRenderer, BEM_CLASS } from './s-edit-members-renderer';

const classes = bem(BEM_CLASS);

export const EditMembersRenderer = ({ data, colDef }: ICellRendererParams<LabelPermissionsArtistWithMembers, Id>) => {
  const members = useMemo(() => data?.teamMembers || [], [data]);

  const openModal = useCallback(() => {
    colDef?.cellRendererParams?.openEditMembersModal({
      artistId: data?.artist.id,
      data: members,
      title: `Assign Team Members to ${data?.artist.name} (${members.length})`,
    });
  }, [colDef?.cellRendererParams, data?.artist, members]);

  const user: Optional<CurrentUserInfo> = colDef?.cellRendererParams?.user;

  const hasEditPermission = useMemo(() => {
    const isAdmin = Boolean(user?.isAdmin);
    const isMemberOfArtistTeam = members.some(member => member.id === user?.id);

    return isAdmin || isMemberOfArtistTeam;
  }, [user, members]);

  if (!user || !hasEditPermission) {
    return null;
  }

  return (
    <SEditMembersRenderer onClick={openModal} data-selector="edit-members-button">
      <Icon className={classes('icon')} name={members.length > 0 ? 'edit' : 'plus'} />
    </SEditMembersRenderer>
  );
};
