import { useCallback } from 'react';
import { PROJECT_STATUSES } from 'common/constants';
import { useProjectsFilters } from './use-projects-filters';
import { Optional } from 'backend-api/models';

export const useActiveTab = (artistId: string): [Optional<string>, (activeTab: string) => void] => {
  const [filters, filtersDispatcher] = useProjectsFilters(artistId);

  const changeActiveTab = useCallback(
    (activeTab: string) => {
      const status = PROJECT_STATUSES.find(status => status.name === activeTab);
      if (!status || filters.status?.name === activeTab) return;

      filtersDispatcher.setFilters({ status });
    },
    [filters.status?.name, filtersDispatcher]
  );

  return [filters.status?.name, changeActiveTab];
};
