import React, { useCallback, useMemo } from 'react';
import { BaseOption, Select } from 'common/components/form/select';
import { Id } from 'backend-api/models';
import { BEM_CLASS, SBadgeSelector, SelectStyles } from './s-badge-selector';
import { bem } from 'utils/bem';
import { components as selectComponents, PlaceholderProps, SingleValueProps } from 'react-select';
import { ControlProps } from 'react-select/dist/declarations/src/components/Control';

interface Props {
  options: BaseOption[];
  value: Id;
  onChange(id: Id): void;
  className?: string;
  shouldUsePortal?: boolean;
  isDisable?: boolean;
  closeMenuOnScroll?: boolean;
  color?: string;
  dataSelector?: string;
  menuIsOpen?: boolean;
}

const classes = bem(BEM_CLASS);

export const BadgeSelector = React.memo(
  ({
    options,
    value,
    onChange,
    shouldUsePortal,
    closeMenuOnScroll,
    isDisable,
    className,
    color,
    dataSelector,
    menuIsOpen = false,
  }: Props) => {
    const control = useCallback(
      ({ children, ...rest }: ControlProps<BaseOption>) => {
        const innerProps = { ...rest.innerProps, 'data-selector': `${dataSelector}-control` };

        return (
          <selectComponents.Control {...rest} className={classes('control')} innerProps={innerProps}>
            {children}
          </selectComponents.Control>
        );
      },
      [dataSelector]
    );

    const renderValueText = useCallback(
      ({ children, ...rest }: SingleValueProps<BaseOption> | PlaceholderProps<BaseOption>) => {
        return (
          <div {...rest} className={classes('value')} data-selector={`${dataSelector}-value`}>
            {children}
          </div>
        );
      },
      [dataSelector]
    );

    const components = useMemo(
      () => ({ Control: control, SingleValue: renderValueText, Placeholder: renderValueText }),
      [control, renderValueText]
    );

    const handleCloseMenuOnScroll = useCallback(e => !e.target.classList?.contains('react-select__menu-list'), []);

    return (
      <SBadgeSelector color={color} className={className}>
        <Select
          value={value}
          onChange={onChange}
          options={options}
          components={components}
          styles={SelectStyles}
          disabled={isDisable}
          menuPortalTarget={shouldUsePortal && document.getElementById('root')}
          menuPosition={shouldUsePortal && 'fixed'}
          menuPlacement={shouldUsePortal && 'bottom'}
          closeMenuOnScroll={closeMenuOnScroll && handleCloseMenuOnScroll}
          dataSelector={dataSelector}
          menuIsOpen={menuIsOpen}
        />
      </SBadgeSelector>
    );
  }
);
