import { useEffect } from 'react';
import { paths } from 'app/routing/paths';
import { LoadingState } from 'common/types';
import { replaceTo } from 'utils/navigation';
import { setMediaPlanNotFoundModalOpened } from 'media-plan/actions';
import { useDispatch, useSelector } from 'react-redux';
import { mediaPlanErrorSelector, mediaPlansSelector } from 'media-plan/selectors';
import { Id, Optional } from 'backend-api/models';

export const useRedirectToFirstMediaPlan = (mediaPlanId: Optional<Id>, projectId: Id) => {
  const dispatch = useDispatch();

  const mediaPlanError = useSelector(mediaPlanErrorSelector);
  const mediaPlans = useSelector(mediaPlansSelector);

  useEffect(() => {
    if ((!!mediaPlanId && !isNaN(mediaPlanId)) || mediaPlans.data.length === 0) return;

    replaceTo(paths.mediaPlan(projectId, mediaPlans.data[0].id));
  }, [mediaPlanId, mediaPlans.data, mediaPlans.loading, projectId]);

  useEffect(() => {
    if (mediaPlans.loading === LoadingState.Finished && mediaPlanError?.statusCode === 404) {
      replaceTo(paths.mediaPlan(projectId, mediaPlans.data[0].id));
      dispatch(setMediaPlanNotFoundModalOpened(true));
    }
  }, [dispatch, mediaPlanError?.statusCode, mediaPlanId, mediaPlans.data, mediaPlans.loading, projectId]);
};
