import { GridApi } from 'ag-grid-community';
import { useCallback, useEffect, useState } from 'react';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';

interface Result {
  onBlur(): void;
}

export const useStopEditingBlur = (api: GridApi<SpreadsheetRowData>): Result => {
  const [isBlur, setIsBlur] = useState(false);
  const onBlur = useCallback(() => {
    setIsBlur(true);
  }, []);

  useEffect(() => {
    if (isBlur) {
      api.stopEditing();
    }
  }, [api, isBlur]);

  return { onBlur };
};
