import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'multi-value-container';

export const SMultiValueView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .${BEM_CLASS} {
    &__placeholder {
      color: ${colorPalette.wildBlueYonder};
    }

    &__labels-container {
      margin-top: 2px;
      overflow: hidden;
      flex-wrap: wrap;
      display: flex;
      flex: 1;
      height: 22px;
      row-gap: 50px;
    }

    &__content {
      margin-right: 5px;
      border: 1px solid rgba(184, 188, 217, 1);
      border-radius: 5px;
      background-color: rgba(255, 255, 255, 1);
      padding: 0 5px;
    }

    &__other-badge {
      border-radius: 5px;
      background-color: ${colorPalette.athensGray};
      padding: 2px 5px;
      margin-left: 10px;
    }
  }
`;
