import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'recent';

export const SRecent = styled.div`
  .${BEM_CLASS} {
    &__title {
      margin: 0 7.5px;
    }

    &__list {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
    }

    &__separator {
      margin: 7.5px;
      background-color: ${transparentize(0.9, manhattanThemeColors.textHeaderColor)};
      height: 1px;
      width: 100%;
    }
  }
`;
