import React, { useRef } from 'react';
import { BEM_CLASS, SSingleSelectItem } from './s-single-select-item';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { Tooltip } from 'common/components/tooltip';
import { callbackWithoutPropagation } from 'utils/event';

const classes = bem(BEM_CLASS);

interface Props {
  label: string;
  tooltip?: string;
  disabledTooltip?: string;
  isExpandable?: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;
  isTree?: boolean;
  depth?: number;
  dataSelector?: string;

  onClick?(): void;

  onExpand?(): void;
}

export const SingleSelectItem = React.memo(
  ({
    label,
    onClick,
    tooltip,
    disabledTooltip,
    onExpand,
    isExpandable = false,
    depth = 0,
    isExpanded = false,
    isDisabled,
    isTree = false,
    dataSelector,
  }: Props) => {
    const iconRef = useRef<HTMLDivElement>(null);
    const tooltipEnabled = !!tooltip || (isDisabled && !!disabledTooltip);

    return (
      <Tooltip
        content={isDisabled ? disabledTooltip : tooltip}
        placement="right-end"
        offset={[0, 16]}
        maxWidth={200}
        enabled={tooltipEnabled}
      >
        <SSingleSelectItem onClick={isDisabled ? undefined : onClick} depth={depth}>
          {isTree && (
            <Icon
              className={classes('expand-trigger', { hidden: !isExpandable })}
              onClick={callbackWithoutPropagation(onExpand)}
              name={isExpanded ? 'up' : 'down'}
              data-selector={dataSelector}
            />
          )}
          <span className={classes('label', { offset: !!tooltip })} data-selector="single-item-value">
            {label}
          </span>
          {(tooltip || (disabledTooltip && isDisabled)) && (
            <div className={classes('icon-container')} ref={iconRef}>
              <Icon name="info" size="general" />
            </div>
          )}
        </SSingleSelectItem>
      </Tooltip>
    );
  }
);
