import { Id, TeamUser, UserRoleType } from 'backend-api/models';
import { ROLES } from 'common-v2/constants';
import { filterUsersByRole } from 'common/transducers';
import { EDITORS_LIMIT, VIEWERS_LIMIT } from 'modals/sharing-modal/constants';
import { searchUsersToTeamUsers } from 'modals/sharing-modal/transducers';
import { SearchUser } from 'modals/sharing-modal/types';

export const getAllUserSelectedAsEditors = (allUsers: SearchUser[], selectedIds: Id[], selectedUsers: TeamUser[]) => {
  const notSelectedUsers = allUsers.filter(user => !selectedIds.includes(user.id));
  const maxEditorsCount = EDITORS_LIMIT - filterUsersByRole(selectedUsers, UserRoleType.Editor).length;

  const usersToAddAsEditors =
    notSelectedUsers.length <= maxEditorsCount ? notSelectedUsers : notSelectedUsers.slice(0, maxEditorsCount);

  return searchUsersToTeamUsers(usersToAddAsEditors, ROLES.EDITOR);
};

export const getAllUserSelectedAsEditorsAndViewers = (
  allUsers: SearchUser[],
  selectedIds: Id[],
  selectedUsers: TeamUser[]
) => {
  const addedEditors = getAllUserSelectedAsEditors(allUsers, selectedIds, selectedUsers);
  const selectedEditorsIds = addedEditors.map(user => user.id);

  const allSelectedIds = [...selectedIds, ...selectedEditorsIds];

  const notSelectedUsers = allUsers.filter(user => !allSelectedIds.includes(user.id));
  const maxViewersCount = VIEWERS_LIMIT - filterUsersByRole(selectedUsers, UserRoleType.Viewer).length;

  const usersToAddAsViewers =
    notSelectedUsers.length <= maxViewersCount ? notSelectedUsers : notSelectedUsers.slice(0, maxViewersCount);

  const addedViewers = searchUsersToTeamUsers(usersToAddAsViewers, ROLES.VIEWER);

  return [...addedEditors, ...addedViewers];
};
