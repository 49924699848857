import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SSuggestions } from './s-suggestions';

interface SuggestionsProps {
  suggestedNodes: React.ReactNode[];
  width: number;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const Suggestions = React.memo(({ suggestedNodes, width, className }: SuggestionsProps) => (
  <SSuggestions width={width} className={className}>
    {suggestedNodes.length > 0 ? (
      <div className={classes('list')}>{suggestedNodes}</div>
    ) : (
      <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('empty')}>
        No results found.
        <br />
        Please try editing your query.
      </Typography>
    )}
  </SSuggestions>
));
