import styled from 'styled-components';

export const BEM_CLASS = 'unclaimed-confidential-project';

export const SUnclaimedConfidentialProject = styled.div`
  .${BEM_CLASS} {
    &__button {
      margin-top: 40px;
    }
  }
`;
