import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { BaseRenderer } from '../../base-renderer';
import { SingleValueView } from '../../single-value-view';
import { SpreadsheetTooltip } from '../../spreadsheet-tooltip';
import { bem } from 'utils/bem';
import { BEM_CLASS, SAgeRenderer } from './s-age-renderer';
import { useAudienceAgeValidation } from 'media-plan/components/spreadsheet/hooks';
import { audienceAgeToString } from '../../editors/age-editor/transducers';
import { AUDIENCE_AGE_VALIDATION_TEXT } from 'media-plan/components/spreadsheet/constants';
import { ValidationTooltipContent } from '../../validation-tooltip-content';
import { SpreadsheetRowData } from 'media-plan/components/spreadsheet/types';

const classes = bem(BEM_CLASS);

export const AgeRenderer = ({
  value: { color, value },
  context,
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['age']>) => {
  const [isValueInvalid] = useAudienceAgeValidation(value);

  const validationMessage = useMemo(() => <ValidationTooltipContent text={AUDIENCE_AGE_VALIDATION_TEXT} />, []);

  return (
    <SpreadsheetTooltip content={validationMessage} disabled={!isValueInvalid}>
      <SAgeRenderer>
        <BaseRenderer
          className={classes('base-renderer')}
          color={color}
          placeholder="13-99"
          accessMode={context.accessMode}
        >
          {value && <SingleValueView value={audienceAgeToString(value)} />}
        </BaseRenderer>
      </SAgeRenderer>
    </SpreadsheetTooltip>
  );
};
