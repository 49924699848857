import React, { useCallback, useMemo } from 'react';
import { bem } from 'utils/bem';
import { isSubstring } from 'utils/string';
import { Id } from 'backend-api/models';
import { Icon } from 'common/components/icon';
import { MenuPlacement, MultiSelectType, SelectComponents, SelectMenuProps } from 'common/components/select/types';
import { MultiSelect } from 'common/components/select/multi-select';
import { SApproversSelect, BEM_CLASS } from './s-approvers-select';
import { CustomSelectProps, SelectOption } from './types';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { MultiValuePlaceholder } from 'common/components/select/components';
import { ApproversControl, ApproversValueContainer } from './components';

interface ApproversSelectProps {
  className?: string;
  selectedOptions: Id[];
  options: SelectOption[];
  dropdownIdentifier: string;
  hasError?: boolean;
  onOptionSelect(options: Id[]): void;
}

const classes = bem(BEM_CLASS);

export const ApproversSelect = React.memo(
  ({
    className,
    selectedOptions,
    options,
    dropdownIdentifier,
    hasError = false,
    onOptionSelect,
  }: ApproversSelectProps) => {
    const customSelectProps = useMemo<CustomSelectProps>(
      () => ({
        hasError,
      }),
      [hasError]
    );

    const selectComponents = useCallback<(props: SelectMenuProps) => SelectComponents<SelectOption, true>>(
      () => ({
        Control: ApproversControl,
        ValueContainer: ApproversValueContainer,
        Option: ({ innerProps, innerRef, isFocused, data }) => (
          <div {...innerProps} ref={innerRef} className={classes('option', { isFocused })}>
            <span className={classes('option-name')}>{data.value}</span>
            <span className={classes('option-email')}>{data.email}</span>
          </div>
        ),
        Placeholder: props => (props.isFocused ? null : <MultiValuePlaceholder {...props} />),
        NoOptionsMessage: ({ innerProps }) => (
          <div {...innerProps} className={classes('no-options-message')}>
            No user found. You can only add user from
            <br />
            the Sony team. Please try again.
          </div>
        ),
      }),
      []
    );

    const selectClassNames = useCallback(
      (className: string) => ({
        menuList: classes('menu-list'),
        menuPortal: `${className} ${dropdownIdentifier}`,
      }),
      [dropdownIdentifier]
    );

    const filterOption = useCallback((option: FilterOptionOption<SelectOption>, inputValue: string) => {
      const isNameMatched = isSubstring(option.data.value, inputValue);
      const isEmailMatched = isSubstring(option.data.email, inputValue);

      return isNameMatched || isEmailMatched;
    }, []);

    return (
      <SApproversSelect>
        {styledClassName => (
          <div className={`${className} ${styledClassName}`}>
            <MultiSelect<SelectOption, CustomSelectProps>
              type={MultiSelectType.AutoComplete}
              valueIds={selectedOptions}
              valueHandler={onOptionSelect}
              options={options}
              classNames={selectClassNames(styledClassName)}
              dataSelector="approvers"
              placeholder="Type email or name..."
              selectComponents={selectComponents}
              customReactSelectProps={customSelectProps}
              filterOption={filterOption}
              placement={MenuPlacement.BottomLeft}
              minSearchLength={2}
              maxItemsLength={1}
              captureMenuScroll
              closeMenuOnScroll
            />

            {hasError && (
              <div className={classes('error')}>
                <Icon className={classes('error-icon')} color="inherit" name="warning" size="general" />
                Please provide email or name of employee.
              </div>
            )}
          </div>
        )}
      </SApproversSelect>
    );
  }
);
