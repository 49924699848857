import { either } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import {
  AudienceAgeSchema,
  CampaignPlacementSchema,
  CampaignPlatformSchema,
  CampaignProviderSchema,
  CampaignStatus,
  CampaignStatuses,
  CampaignStatusesSchema,
  CampaignStatusSchema,
  GenderSchema,
  ObjectiveSchema,
} from '../campaign';
import {
  CampaignTypeAndCategorySchema,
  FormattedDateFromISOString,
  IdNameField,
  IdType,
  Nullable,
  Optional,
  OptionalNullable,
  ResponseModel,
  TerritorySchema,
} from '../common';

export const MediaPlanCampaignStyleSchema = ResponseModel({ background: t.string }, 'MediaPlanCampaignStyleRequest');

export type MediaPlanCampaignStyle = t.TypeOf<typeof MediaPlanCampaignStyleSchema>;

export const MediaPlanKPISchema = ResponseModel({ ...IdNameField }, 'MediaPlanKPI');

export type MediaPlanKPI = t.TypeOf<typeof MediaPlanKPISchema>;

export const MediaPlanCampaignSchema = ResponseModel(
  {
    id: IdType,
    uuid: UUID,
    name: Optional(t.string),
    projectId: Optional(IdType),
    budgetSpend: Optional(t.number),
    plannedBudget: Optional(t.number),
    ecpm: Optional(t.number),
    startDate: Optional(FormattedDateFromISOString),
    endDate: Optional(FormattedDateFromISOString),
    territories: Optional(t.array(TerritorySchema)),
    objective: Optional(ObjectiveSchema),
    type: Optional(CampaignTypeAndCategorySchema),
    platforms: t.array(CampaignPlatformSchema),
    notes: Optional(t.string),
    audienceAge: Optional(AudienceAgeSchema),
    genders: Optional(t.array(GenderSchema)),
    provider: Optional(CampaignProviderSchema),
    adCreativeLinks: Optional(t.array(t.string)),
    adCreativeNotes: Optional(t.string),
    audienceNotes: Optional(t.string),
    workflowStatus: Optional(CampaignStatusSchema),
    kpiMetricsField: Optional(MediaPlanKPISchema),
    placements: Optional(t.array(CampaignPlacementSchema)),
    currency: Optional(t.string),
    orderInPhase: t.number,
    destinationLinks: Optional(t.array(t.string)),
    creativeDescription: Optional(t.string),
    headline: Optional(t.string),
    callToAction: Optional(t.string),
    actualSpent: Optional(t.number),
    namingConvention: Optional(t.string),
    namingConventionManual: Optional(t.string),
  },
  'MediaPlanCampaign'
);

export type MediaPlanCampaign = t.TypeOf<typeof MediaPlanCampaignSchema>;

export const MediaPlanCampaignStylesSchema = ResponseModel(
  {
    name: Optional(MediaPlanCampaignStyleSchema),
    territories: Optional(MediaPlanCampaignStyleSchema),
    startDate: Optional(MediaPlanCampaignStyleSchema),
    endDate: Optional(MediaPlanCampaignStyleSchema),
    platforms: Optional(MediaPlanCampaignStyleSchema),
    campaignType: Optional(MediaPlanCampaignStyleSchema),
    notes: Optional(MediaPlanCampaignStyleSchema),
    audienceNotes: Optional(MediaPlanCampaignStyleSchema),
    adCreativeNotes: Optional(MediaPlanCampaignStyleSchema),
    kpiMetricsFieldId: Optional(MediaPlanCampaignStyleSchema),
    provider: Optional(MediaPlanCampaignStyleSchema),
    objective: Optional(MediaPlanCampaignStyleSchema),
    budgetSpend: Optional(MediaPlanCampaignStyleSchema),
    ecpm: Optional(MediaPlanCampaignStyleSchema),
    plannedBudget: Optional(MediaPlanCampaignStyleSchema),
    workflowStatus: Optional(MediaPlanCampaignStyleSchema),
    genders: Optional(MediaPlanCampaignStyleSchema),
    audienceLowerAge: Optional(MediaPlanCampaignStyleSchema),
    audienceHigherAge: Optional(MediaPlanCampaignStyleSchema),
    placements: Optional(MediaPlanCampaignStyleSchema),
    adCreativeLinks: Optional(MediaPlanCampaignStyleSchema),
    destinationLinks: Optional(MediaPlanCampaignStyleSchema),
    creativeDescription: Optional(MediaPlanCampaignStyleSchema),
    headline: Optional(MediaPlanCampaignStyleSchema),
    callToAction: Optional(MediaPlanCampaignStyleSchema),
    actualSpent: Optional(MediaPlanCampaignStyleSchema),
    namingConventionManual: Optional(MediaPlanCampaignStyleSchema),
    estImpressions: Optional(MediaPlanCampaignStyleSchema),
  },
  'MediaPlanCampaignStyles'
);

export type MediaPlanCampaignStyles = t.TypeOf<typeof MediaPlanCampaignStylesSchema>;

export const MediaPlanCampaignRowSchema = ResponseModel(
  {
    columns: MediaPlanCampaignSchema,
    style: Nullable(MediaPlanCampaignStylesSchema),
  },
  'MediaPlanCampaignRow'
);

export type MediaPlanCampaignRow = t.TypeOf<typeof MediaPlanCampaignRowSchema>;

export const MediaPlanPhaseSchema = ResponseModel(
  { ...IdNameField, order: t.number, campaigns: t.array(MediaPlanCampaignRowSchema) },
  'MediaPlanPhase'
);

export type MediaPlanPhase = t.TypeOf<typeof MediaPlanPhaseSchema>;

export const MediaPlanListItemSchema = ResponseModel({ ...IdNameField, order: t.number }, 'MediaPlanListItem');

export type MediaPlanListItem = t.TypeOf<typeof MediaPlanListItemSchema>;

export const MediaPlanSchema = ResponseModel({ phases: t.array(MediaPlanPhaseSchema) }, 'MediaPlan');

export type MediaPlan = t.TypeOf<typeof MediaPlanSchema>;

export const CreatePhaseRequestSchema = ResponseModel(
  { order: Optional(t.number), name: Optional(t.string), campaignUuid: Optional(t.string) },
  'CreatePhaseRequest'
);

export type CreatePhaseRequest = t.TypeOf<typeof CreatePhaseRequestSchema>;

export const EditPhaseRequestSchema = ResponseModel({ name: Optional(t.string) }, 'EditPhaseRequest');

export type EditPhaseRequest = t.TypeOf<typeof EditPhaseRequestSchema>;

export const MediaPlanCampaignRequestSchema = ResponseModel(
  {
    name: OptionalNullable(t.string),
    territories: OptionalNullable(t.array(IdType)),
    startDate: OptionalNullable(t.string),
    endDate: OptionalNullable(t.string),
    platforms: OptionalNullable(t.array(IdType)),
    campaignType: OptionalNullable(IdType),
    notes: OptionalNullable(t.string),
    audienceNotes: OptionalNullable(t.string),
    adCreativeNotes: OptionalNullable(t.string),
    kpiMetricsFieldId: OptionalNullable(IdType),
    provider: OptionalNullable(IdType),
    objective: OptionalNullable(IdType),
    budgetSpend: OptionalNullable(t.number),
    ecpm: OptionalNullable(t.number),
    plannedBudget: OptionalNullable(t.number),
    workflowStatus: OptionalNullable(CampaignStatusesSchema),
    genders: OptionalNullable(t.array(IdType)),
    audienceLowerAge: OptionalNullable(t.number),
    audienceHigherAge: OptionalNullable(t.number),
    placements: OptionalNullable(t.array(IdType)),
    adCreativeLinks: OptionalNullable(t.array(t.string)),
    destinationLinks: OptionalNullable(t.array(t.string)),
    creativeDescription: OptionalNullable(t.string),
    headline: OptionalNullable(t.string),
    callToAction: OptionalNullable(t.string),
    actualSpent: OptionalNullable(t.number),
    namingConventionManual: OptionalNullable(t.string),
  },
  'MediaPlanCampaignRequest'
);

export type MediaPlanCampaignRequest = t.TypeOf<typeof MediaPlanCampaignRequestSchema>;

export const MediaPlanCampaignStylesRequestSchema = ResponseModel(
  {
    name: OptionalNullable(MediaPlanCampaignStyleSchema),
    territories: OptionalNullable(MediaPlanCampaignStyleSchema),
    startDate: OptionalNullable(MediaPlanCampaignStyleSchema),
    endDate: OptionalNullable(MediaPlanCampaignStyleSchema),
    platforms: OptionalNullable(MediaPlanCampaignStyleSchema),
    campaignType: OptionalNullable(MediaPlanCampaignStyleSchema),
    notes: OptionalNullable(MediaPlanCampaignStyleSchema),
    audienceNotes: OptionalNullable(MediaPlanCampaignStyleSchema),
    adCreativeNotes: OptionalNullable(MediaPlanCampaignStyleSchema),
    kpiMetricsFieldId: OptionalNullable(MediaPlanCampaignStyleSchema),
    provider: OptionalNullable(MediaPlanCampaignStyleSchema),
    objective: OptionalNullable(MediaPlanCampaignStyleSchema),
    budgetSpend: OptionalNullable(MediaPlanCampaignStyleSchema),
    ecpm: OptionalNullable(MediaPlanCampaignStyleSchema),
    plannedBudget: OptionalNullable(MediaPlanCampaignStyleSchema),
    workflowStatus: OptionalNullable(MediaPlanCampaignStyleSchema),
    genders: OptionalNullable(MediaPlanCampaignStyleSchema),
    audienceLowerAge: OptionalNullable(MediaPlanCampaignStyleSchema),
    audienceHigherAge: OptionalNullable(MediaPlanCampaignStyleSchema),
    placements: OptionalNullable(MediaPlanCampaignStyleSchema),
    adCreativeLinks: OptionalNullable(MediaPlanCampaignStyleSchema),
    destinationLinks: OptionalNullable(MediaPlanCampaignStyleSchema),
    creativeDescription: OptionalNullable(MediaPlanCampaignStyleSchema),
    headline: OptionalNullable(MediaPlanCampaignStyleSchema),
    callToAction: OptionalNullable(MediaPlanCampaignStyleSchema),
    actualSpent: OptionalNullable(MediaPlanCampaignStyleSchema),
    namingConventionManual: OptionalNullable(MediaPlanCampaignStyleSchema),
    estImpressions: OptionalNullable(MediaPlanCampaignStyleSchema),
  },
  'MediaPlanCampaignStylesRequest'
);

export type MediaPlanCampaignStylesRequest = t.TypeOf<typeof MediaPlanCampaignStylesRequestSchema>;

export const CreateCampaignRequestSchema = ResponseModel(
  {
    columns: MediaPlanCampaignRequestSchema,
    phaseId: IdType,
    orderInPhase: Optional(t.number),
    campaignUuid: Optional(UUID),
    style: Nullable(MediaPlanCampaignStylesRequestSchema),
  },
  'CreateCampaignRequest'
);

export type CreateCampaignRequest = t.TypeOf<typeof CreateCampaignRequestSchema>;

export const UpdateCampaignRequestSchema = ResponseModel(
  {
    columns: MediaPlanCampaignRequestSchema,
    campaignUuid: UUID,
    orderInPhase: Optional(t.number),
    style: Nullable(MediaPlanCampaignStylesRequestSchema),
  },
  'UpdateCampaignRequest'
);

export type UpdateCampaignRequest = t.TypeOf<typeof UpdateCampaignRequestSchema>;

export const ModifyBulkCampaignsRequestSchema = ResponseModel(
  {
    create: t.array(CreateCampaignRequestSchema),
    update: t.array(UpdateCampaignRequestSchema),
    delete: t.array(UUID),
  },
  'ModifyBulkCampaignsRequest'
);

export type ModifyBulkCampaignsRequest = t.TypeOf<typeof ModifyBulkCampaignsRequestSchema>;

export const NamingConventionSchema = ResponseModel(
  {
    id: IdType,
    namingConvention: Optional(t.string),
    uuid: UUID,
  },
  'CampaignName'
);

export type CampaignName = t.TypeOf<typeof NamingConventionSchema>;

export const ModifyBulkCampaignsResponseSchema = ResponseModel(
  {
    created: t.array(NamingConventionSchema),
    updated: t.array(NamingConventionSchema),
  },
  'ModifyBulkCampaignsResponse'
);

export type ModifyBulkCampaignsResponse = t.TypeOf<typeof ModifyBulkCampaignsResponseSchema>;

export const ProjectReviewerSchema = ResponseModel(
  {
    ...IdNameField,
    email: t.string,
    hasProjectAccess: t.boolean,
  },
  'ProjectReviewer'
);

export type ProjectReviewer = t.TypeOf<typeof ProjectReviewerSchema>;

export const RequestApprovalRequestSchema = ResponseModel(
  {
    reviewers: t.array(IdType),
    message: Optional(t.string),
    campaigns: t.array(UUID),
  },
  'RequestApprovalRequest'
);

export type RequestApprovalRequest = t.TypeOf<typeof RequestApprovalRequestSchema>;

export const RequesterSchema = ResponseModel({
  ...IdNameField,
  email: t.string,
  isAdmin: t.boolean,
  phone: t.string,
  externalId: t.string,
});
export type Requester = t.TypeOf<typeof RequesterSchema>;

export const MediaPlanApproversStatus = new t.Type(
  'MediaPlanApproversStatus',
  (u): u is CampaignStatus => CampaignStatusSchema.is(u),
  (u, c) =>
    either.chain(CampaignStatusSchema.validate(u, c), s => {
      const mappedStatus = s.status === CampaignStatuses.PENDING ? CampaignStatuses.REVIEW : s;
      const result: CampaignStatus = { ...s, status: mappedStatus as CampaignStatuses };
      return t.success(result);
    }),
  m => m.toString()
);

export type MediaPlanApproversStatusType = t.TypeOf<typeof MediaPlanApproversStatus>;

export const ApprovalRequestSchema = ResponseModel(
  {
    requester: RequesterSchema,
    phases: t.array(
      ResponseModel({
        ...MediaPlanPhaseSchema.props,
        campaigns: t.array(MediaPlanCampaignRowSchema),
      })
    ),
  },
  'RequestApprovalRequest'
);

export type ApprovalRequest = t.TypeOf<typeof ApprovalRequestSchema>;

export const SubmitApprovalCampaignSchema = ResponseModel(
  {
    campaignUuid: t.string,
    workflowStatus: CampaignStatusesSchema,
  },
  'ConfirmApprovalCampaign'
);

export type SubmitApprovalCampaign = t.TypeOf<typeof SubmitApprovalCampaignSchema>;

export const SubmitApprovalRequestSchema = ResponseModel(
  {
    campaigns: t.array(SubmitApprovalCampaignSchema),
  },
  'ConfirmApprovalRequest'
);

export type SubmitApprovalRequest = t.TypeOf<typeof SubmitApprovalRequestSchema>;

export const ProjectMetricsSchema = ResponseModel(
  {
    plannedBudget: t.number,
    platformsCount: t.number,
    campaignsCount: t.number,
    estimatedImpressions: t.number,
    territories: t.array(TerritorySchema),
  },
  'ProjectMetrics'
);

export type ProjectMetrics = t.TypeOf<typeof ProjectMetricsSchema>;

export const CreateMediaPlanRequestSchema = ResponseModel(
  {
    name: t.string,
  },
  'CreateMediaPlanRequest'
);

export type CreateMediaPlanRequest = t.TypeOf<typeof CreateMediaPlanRequestSchema>;
