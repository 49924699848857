import { UserDropdown } from 'app/components/app-header/components/user-dropdown';
import { basePaths } from 'app/routing/paths';
import { useAppContext } from 'common-v2/hooks';
import { useAuth } from 'hooks';
import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { navigateTo } from 'utils/navigation';
import { SUserDropdownContainer, BEM_CLASS } from './s-user-dropdown-container';

interface UserDropdownContainerProps {
  className?: string;
}

const classes = bem(BEM_CLASS);

export const UserDropdownContainer = React.memo(({ className }: UserDropdownContainerProps) => {
  const { logout } = useAuth();

  const { user: userInfo } = useAppContext();

  const navigateToLabelsPermissions = useCallback(() => {
    navigateTo(basePaths.labelPermissions());
  }, []);

  const navigateToSettings = useCallback(() => {
    navigateTo(basePaths.settings());
  }, []);

  return (
    <SUserDropdownContainer className={className}>
      <UserDropdown
        userInfo={userInfo}
        className={classes('user-dropdown')}
        onLogoutClick={logout}
        onSettingsClick={navigateToSettings}
        onLabelsPermissionsClick={navigateToLabelsPermissions}
      />
    </SUserDropdownContainer>
  );
});
