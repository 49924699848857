import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'common/selectors';
import { useCallback } from 'react';
import { getFormattedDate } from 'common-v2/utils';

export const useFormattedDate = (): ((date?: Date) => string | undefined) => {
  const currentFormat = useSelector(dateFormatSelector);

  return useCallback((date?: Date) => date && getFormattedDate(currentFormat)(date), [currentFormat]);
};
