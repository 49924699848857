import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

interface Props {
  depth: number;
}

const ITEM_DEFAULT_MARGIN = 22;

export const SMultiSelectItem = styled.div<Props>`
  display: flex;
  align-items: center;
  margin-left: ${({ depth }) => `${depth * ITEM_DEFAULT_MARGIN}px`};
  margin-bottom: 9px;
  overflow: hidden;
  .multi-select-item {
    &__expand-trigger {
      margin-right: 11px;
      &--hidden {
        visibility: hidden;
      }
    }
    &__checkbox {
      height: 20px;
    }
    &__label {
      color: ${palette.darkBlue};
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
`;
