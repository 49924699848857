import styled from 'styled-components';
import { animatedFlare } from 'common/s-components/s-animated-flare';

interface SAutocompleteLoaderProps {
  readonly maxWidth?: number;
  clipPathId: string;
}

export const SAutocompleteLoader = styled.div<SAutocompleteLoaderProps>`
  height: 95px;
  .autocomplete-loader {
    &__clip-container {
      width: 275px;
      height: 95px;
      clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      -webkit-clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      ${animatedFlare}
    }
  }
`;
