import React from 'react';
import { bem } from 'utils/bem';
import { SBadge, BEM_CLASS } from './s-badge';
import { TextValue } from '../text-value';

interface Props {
  value: React.ReactNode;
  textClassName?: string;
  className?: string;
  dataSelector?: string;
}

const classes = bem(BEM_CLASS);

export const Badge = React.memo(
  React.forwardRef<HTMLDivElement, Props>(({ value, textClassName, className, dataSelector }, ref) => (
    <SBadge data-selector={dataSelector} className={className}>
      <TextValue
        ref={ref}
        value={value}
        className={classes('content')}
        textClassName={classes('text', undefined, textClassName)}
      />
    </SBadge>
  ))
);
