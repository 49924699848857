import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'text-value-item';

export const STextValueItem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;

  .${BEM_CLASS} {
    &__text {
      ${elipsizeText}
    }
  }
`;
