import styled from 'styled-components';

interface SShareModalProps {
  height: number;
}

export const BEM_CLASS = 'share-modal';

export const SShareModal = styled.div<SShareModalProps>`
  display: flex;
  flex-direction: column;
  width: 630px;
  height: ${({ height }) => `${height}px`};
  padding-bottom: 15px;

  .${BEM_CLASS} {
    &__header {
      flex-shrink: 0;
    }

    &__content {
      flex-grow: 1;
      padding: 15px 40px 15px 50px;
      overflow: hidden;
    }
  }
`;
