import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

interface Props {
  disabled?: boolean;
}

export const SSelectedSection = styled.div<Props>`
  padding: 0 14px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .selected-section {
    &__scrolling-container {
      height: 100%;
      width: 100%;
      overflow: auto;
      padding: 10px 14px;
    }
    &__placeholder-container {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
    &__placeholder {
      color: ${palette.darkBlue};
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
    }
    &__static-sections {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }
  }
`;
