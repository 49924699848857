import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '../components/form';
import { activeMomentSelector, errorSelector, projectSelector } from '../selectors';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { ProjectHeader } from 'common/containers/project-header';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { WaveBackground } from 'common/components/wave-background';
import { Footer } from 'app/components/footer';
import { getMoment, getMomentProject, resetMoment } from 'artist-moment/actions';
import { isAddMomentPath, isEditMomentPath, isMomentDetailsPath } from 'app/routing/guards';
import { MomentDetails } from 'artist-moment/components/moment-details';
import { useProjectAccessRestricted } from 'common/hooks/use-project-access-restricted';
import { paths } from 'app/routing/paths';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';
import { getProjectName } from 'common-v2/transducers';
import { Head } from 'app/components/head';

interface MatchParams {
  id: string;
  momentId: string;
}

export const MomentContainer = React.memo((props: RouteComponentProps<MatchParams>) => {
  const { match, history } = props;
  const momentId = parseInt(match.params.momentId, 10);
  const projectId = parseInt(match.params.id, 10);

  const dispatch = useDispatch();

  const project = useSelector(projectSelector);
  const moment = useSelector(activeMomentSelector);
  const error = useSelector(errorSelector);

  const location = useLocation();

  useEffect(() => {
    projectId && dispatch(getMomentProject.request(projectId));
    momentId && projectId && dispatch(getMoment.request({ projectId, momentId }));

    return () => {
      dispatch(resetMoment());
    };
  }, [dispatch, momentId, projectId]);

  useEffect(() => {
    if (error?.statusCode === 404) {
      history.replace(paths.outdatedNotFound());
    }
  }, [error, history]);

  const renderBody = useMemo(() => {
    const isCreateMomentPage = isAddMomentPath(location.pathname);
    const isEditMomentPage = isEditMomentPath(location.pathname);
    const isMomentDetailsPage = isMomentDetailsPath(location.pathname);

    if (isMomentDetailsPage) {
      return project && moment && <MomentDetails project={project} moment={moment} />;
    } else if (isCreateMomentPage || isEditMomentPage) {
      return <Form momentId={momentId} projectId={projectId} />;
    }
    return;
  }, [location.pathname, project, moment, momentId, projectId]);

  useProjectAccessRestricted(errorSelector);

  return (
    <>
      <Head title={getProjectName(project)} />
      <OutdatedGlobalLayout>
        <ProjectHeader projectId={projectId} isNoActiveTab />
        <WaveBackground withTabs className="app__wrapper">
          <ResponsiveContainer>{renderBody}</ResponsiveContainer>
          <Footer />
        </WaveBackground>
      </OutdatedGlobalLayout>
    </>
  );
});
