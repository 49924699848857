import styled from 'styled-components';
import { fullScreen as fullScreenZIndex } from 'app/styles/z-indexes';

export const BEM_CLASS = 'media-plan-container';

export const SMediaPlanContainer = styled.div`
  flex: 1;

  .${BEM_CLASS} {
    &__spreadsheet {
      padding-top: 15px;

      &--isFullScreen {
        position: fixed;
        z-index: ${fullScreenZIndex};
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-image: linear-gradient(180deg, rgba(240, 240, 245, 1) 8%, rgba(222, 222, 234, 1) 100%);
        display: flex;
        flex-direction: column;
        overflow: auto;
      }
    }

    &__table {
      padding: 15px 30px 30px;
    }

    &__background {
      justify-content: flex-start;
    }

    &__footer {
      position: sticky;
      bottom: 0;
      z-index: 1;
    }

    &__metrics-container {
      padding: 0 30px;
      margin: 30px 0 10px;
    }
  }
`;
