import React from 'react';
import { GridApi, ICellEditor, ICellEditorParams, IContextMenuFactory, RowNode, Column } from 'ag-grid-community';
import { Id, Nullable, Optional } from 'backend-api/models';
import InputMask from 'react-input-mask';
import { UUID } from 'io-ts-types/lib/UUID';
import { IClipboardService } from 'ag-grid-community/dist/lib/interfaces/iClipboardService';
import { IMenu } from 'ag-grid-community/dist/lib/interfaces/iMenu';
import {
  MediaPlanColumnId,
  SpreadsheetRowFields,
  SpreadsheetRowData,
  MediaPlanAccessMode,
} from 'media-plan-v2/containers/spreadsheet/types';

export type SpreadsheetRowFieldsValues = SpreadsheetRowFields[keyof SpreadsheetRowFields];
export interface PhaseRowProps extends ICellEditorParams {
  projectId: Id;
  mediaPlanId: Id;
  accessMode: MediaPlanAccessMode;
}

export interface GroupRowRendererParams {
  suppressCount?: boolean;
  suppressDoubleClickExpand?: boolean;
  suppressEnterExpand?: boolean;
  innerRenderer?: React.FC<PhaseRowProps>;
}

export interface CellRendererProps {
  data: SpreadsheetRowData;
  eGridCell: Node;
}

export interface ClipboardTransferObject {
  columnId: MediaPlanColumnId;
  value: Record<string, unknown>;
  isRowSelected?: boolean;
}

export type PendingDataStore = Record<string, SpreadsheetRowData>;

export interface PendingData {
  create: Nullable<PendingDataStore>;
  update: Nullable<PendingDataStore>;
  delete: Nullable<PendingDataStore>;
}

export type NameEditorInstance = ICellEditor & {
  getId?: () => number;
  updateValue?: (value: Optional<string>) => void;
  getIsTouched?: () => boolean;
};

export type InputMaskExtended = InputMask & { focus: () => void; blur: () => void };

export enum FocusDirection {
  NONE = 0,
  FORWARD = 1,
  BACKWARD = -1,
}

export interface ExtendedContextMenuFactory extends IContextMenuFactory {
  activeMenu?: IMenu;
  showMenu?(
    node: RowNode,
    column: Column,
    value: SpreadsheetRowData,
    event: React.MouseEvent,
    gridCell: HTMLElement
  ): void;
}

export interface ExtendedGridApi extends Omit<GridApi<SpreadsheetRowData>, 'clipboardService' | 'contextMenuFactory'> {
  clipboardService: IClipboardService;
  contextMenuFactory: ExtendedContextMenuFactory;
}

export interface UndoData {
  field: MediaPlanColumnId;
  data: SpreadsheetRowData;
}

export interface UndoHook {
  updateUndoStack(undo: UndoData): void;
  sanitizeUndoRedoStacks(uuid: UUID): void;
  applyUndo(api: GridApi<SpreadsheetRowData>): void;
  applyRedo(api: GridApi<SpreadsheetRowData>): void;
  isUndoStackEmpty: boolean;
  isRedoStackEmpty: boolean;
  clearUndoStack(): void;
  clearRedoStack(): void;
}

export interface CopyPastedCell {
  color: string;
  value: string;
}
