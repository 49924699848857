import React from 'react';
import { SColoredCircle } from '../s-components/s-colored-circle';

interface Props {
  color: string;
  opacity?: number;
  className?: string;
}

export const ColoredCircle = React.memo(({ color, opacity = 1, className }: Props) => (
  <SColoredCircle color={color} opacity={opacity} className={className} />
));
