import { Id, Nullable, Optional, ProjectDetails, UpcomingProject, UserRole } from 'backend-api/models';
import { RESTRICTION_CODES, ROLES, PROJECT_ACCESS_RESTRICTED_CODES } from 'common-v2/constants';
import { LocalRole } from 'common-v2/types';
import { intersection } from 'lodash';

export const makePermissionsChecker = (project?: ProjectDetails | UpcomingProject) => {
  return (roles: LocalRole[]) => {
    if (!project || project.userRoles.length === 0) return false;

    const rolesIds = roles.map(role => role.id);
    const userRolesIds = project.userRoles.map(role => role.id);
    const hasPermissions = intersection(rolesIds, userRolesIds).length > 0;

    return hasPermissions;
  };
};

export const hasRole = (roles: UserRole[] = [], role: LocalRole): boolean => roles.some(({ id }) => role.id === id);

export const hasOnlyApproverRole = (roles: UserRole[]): boolean => roles.length === 1 && hasRole(roles, ROLES.APPROVER);

export const getRoleNameById = (roleId: Id): Optional<string> =>
  Object.values(ROLES).find(role => role.id === roleId)?.name;

export const isRequestRestricted = err =>
  err?.statusCode === 403 && RESTRICTION_CODES.some(code => code === err?.data?.code);

export const isProjectAccessRestricted = err =>
  err?.statusCode === 403 && PROJECT_ACCESS_RESTRICTED_CODES.some(code => code === err?.data?.code);

export const isRestrictedError = err => isRequestRestricted(err) || isProjectAccessRestricted(err);

export const getHigestRole = (roles: UserRole[]): Nullable<UserRole> => {
  let highestWeight = 0;

  const highestRole = roles.reduce<Nullable<UserRole>>((acc, cur) => {
    const weight = Object.values(ROLES).find(role => role.id === cur.id)?.weight ?? -1;
    if (acc === null || weight > highestWeight) {
      highestWeight = weight;
      return cur;
    }
    return acc;
  }, null);

  return highestRole;
};
