import { Label } from 'backend-api/models';
import { LabelOption } from './types';

export const mapLabelsToOptions = (labels?: Label[]): LabelOption[] => {
  if (!labels) return [];

  return labels.map(userLabel => ({
    id: userLabel.id,
    label: userLabel.name,
    image: `${userLabel.imageUrl}?resolution=small`,
  }));
};
