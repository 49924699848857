import React, { useCallback, useEffect, useRef } from 'react';
import { bem } from 'utils/bem';
import { Button, Typography, TYPOGRAPHY_TYPE, BUTTON_SIZE, BUTTON_TYPE, THEME } from 'gdb-web-shared-components';
import { CONFIRMATION_MODAL_CONTENT_ID } from './constants';
import { BEM_CLASS, SConfirmationModal } from './s-confirmation-modal';

const classes = bem(BEM_CLASS);

interface ConfirmationModalProps {
  className?: string;
  text: string;
  isVisible: boolean;
  action(allow: boolean): void;
}

export const ConfirmationModal = React.memo(({ className, text, isVisible, action }: ConfirmationModalProps) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const confirmAction = useCallback(() => {
    action(true);
  }, [action]);

  const cancelAction = useCallback(() => {
    action(false);
  }, [action]);

  useEffect(() => {
    const cancelButtonElement = cancelButtonRef && cancelButtonRef.current;

    if (cancelButtonElement && isVisible) {
      cancelButtonElement.focus();
    }
  }, [isVisible]);

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      const isCancelFocused = document.activeElement?.isEqualNode(cancelButtonRef.current);

      if (isCancelFocused) {
        confirmButtonRef.current?.focus();
      } else {
        cancelButtonRef.current?.focus();
      }
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);

  return (
    <SConfirmationModal
      className={className}
      data-selector="confirmation-modal"
      isVisible={isVisible}
      onKeyDown={onKeyDown}
      data-id={CONFIRMATION_MODAL_CONTENT_ID}
    >
      <div className={classes('overlay')} onClick={cancelAction} data-id={CONFIRMATION_MODAL_CONTENT_ID} />

      <div className={classes('wrapper')} data-id={CONFIRMATION_MODAL_CONTENT_ID}>
        <div className={classes('container')} data-id={CONFIRMATION_MODAL_CONTENT_ID}>
          <Typography
            type={TYPOGRAPHY_TYPE.body4}
            className={classes('text')}
            data-id={CONFIRMATION_MODAL_CONTENT_ID}
            data-selector="confirmation-modal-text"
          >
            {text} Are you sure you want to continue?
          </Typography>

          <Button
            className={classes('close')}
            theme={THEME.light}
            icon="cross"
            size={BUTTON_SIZE.smallRound}
            type={BUTTON_TYPE.tertiary}
            onClick={cancelAction}
            data-id={CONFIRMATION_MODAL_CONTENT_ID}
          />
        </div>

        <div className={classes('actions')} data-id={CONFIRMATION_MODAL_CONTENT_ID}>
          <Button
            ref={cancelButtonRef}
            caption="Cancel"
            theme={THEME.light}
            type={BUTTON_TYPE.secondary}
            className={classes('action')}
            onClick={cancelAction}
            data-id={CONFIRMATION_MODAL_CONTENT_ID}
            data-selector="confirmation-modal-cancel-button"
          />

          <Button
            ref={confirmButtonRef}
            caption="Delete"
            theme={THEME.light}
            className={classes('action')}
            onClick={confirmAction}
            data-id={CONFIRMATION_MODAL_CONTENT_ID}
            data-selector="confirmation-modal-delete-button"
          />
        </div>
      </div>
    </SConfirmationModal>
  );
});
