import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { colorPalette } from 'app/theme/colors';

interface SProjectHeaderProps {
  readonly isNoActiveTab?: boolean;
}

export const SProjectHeader = styled.div<SProjectHeaderProps>`
  width: 100%;
  background-color: ${palette.white};
  display: inline-block;

  .project-header {
    &__content {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      padding: 0 30px;
    }

    &__top-content {
      display: flex;
    }

    &__bottom-content {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__info {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      gap: 5px;
    }

    &__info-section {
      display: flex;
      align-items: center;
    }

    &__images {
      flex-shrink: 0;
      width: 89px;
      height: 89px;
      margin-right: 20px;
      border-radius: 5px;
    }

    &__schedule-value {
      flex-shrink: 0;
      color: ${palette.darkBlue};
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 1px;
      line-height: 20px;
      margin-left: auto;
    }

    &__schedule-icon {
      flex-shrink: 0;
      display: flex;
      align-self: center;
      margin-left: 10px;
    }

    &__campaign-name-convention {
      margin-right: 60px;
    }

    &__project-ids-container {
      display: flex;
      margin-left: auto;
      align-items: center;
    }

    &__project-id-badge {
      margin-left: 10px;
    }

    &__project-ids-label {
      color: ${colorPalette.black};
      line-height: 20px;
    }

    &__project-ids-button {
      display: flex;
      padding: 10px;
      margin: 0 -4px 0 5px;
      transition: transform 0.2s ease-out;

      &--rotated {
        transform: rotate(180deg);
      }
    }

    &__country-icon {
      width: 22px;
      height: 22px;
      margin: 5px;
      border-radius: 50%;
    }

    &__territories-container {
      display: flex;
      align-items: center;
    }

    &__more-territories-container {
      height: 24px;
      border-radius: 12px;
      background-color: ${palette.darkBlueTwo};
      padding: 0 10px;
      margin-left: 5px;
      display: flex;
      align-items: center;
    }

    &__more-territories-text {
      color: white;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 15px;
    }

    &__title {
      overflow: hidden;
      margin-right: 80px;
    }

    &__routes {
      display: flex;
      justify-content: space-between;

      & > *:not(:last-child) {
        margin-right: 40px;
      }
    }

    &__menu-item {
      position: relative;
      display: flex;
      align-items: center;
      height: 60px;
      font-weight: 700;
      color: ${palette.black};
      text-align: center;
      text-transform: uppercase;

      &.active {
        color: ${({ isNoActiveTab }) => (isNoActiveTab ? '' : palette.pink)};
      }

      &--disabled {
        opacity: 0.6;
        pointer-events: none;
      }
    }

    &__line-indicator {
      position: absolute;
      bottom: 0;
      margin: 0;
      width: 100%;
      height: 2px;
      background-color: ${palette.pink};
    }

    &__label-badge {
      padding: 3px 10px 2px;
      margin-left: 10px;
      background-color: ${colorPalette.eastBay};
      .badge {
        &__text {
          color: ${palette.white};
        }
      }
    }
  }
`;
