import { Checkbox } from 'common/components/checkbox';
import { BaseOption } from 'common/components/select';
import { Body } from 'common/components/typography';
import React, { useCallback, useState } from 'react';
import { MenuListProps } from 'react-select';
import { bem } from 'utils/bem';
import { BEM_CLASS, SApprovalStatusMenu } from './s-approval-status-menu';

interface Props extends MenuListProps<BaseOption, false> {
  onApplyToAll(checked: boolean): void;
}

const classes = bem(BEM_CLASS);

export const ApprovalStatusMenu = React.memo(({ onApplyToAll, innerProps, innerRef, children }: Props) => {
  const [checked, setChekced] = useState(false);

  const handleCheckChanges = useCallback(
    (isChecked: boolean) => {
      setChekced(isChecked);
      onApplyToAll(isChecked);
    },
    [onApplyToAll]
  );
  return (
    <SApprovalStatusMenu {...innerProps} ref={innerRef} data-selector="approval-status-menu-list">
      <div className={classes('options')}>{children}</div>
      <div className={classes('divider')} />
      <div className={classes('apply-to-all')} data-selector="apply-to-all-phase-option">
        <Checkbox
          isChecked={checked}
          checkboxClassName={classes('checkbox')}
          onClick={handleCheckChanges}
          dataSelector="apply-to-all-phase-checkbox"
        />
        <Body>Apply to All Phase</Body>
      </div>
    </SApprovalStatusMenu>
  );
});
