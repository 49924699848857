export const getVisibleItemCount = (element: Element) => {
  let visibleItemCount = 0;
  const children = element.children;
  const firstChild = children[0];

  if (firstChild instanceof HTMLElement) {
    const startingOffset = firstChild.offsetLeft;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      if (!(child instanceof HTMLElement) || (i !== 0 && child.offsetLeft === startingOffset)) {
        break;
      }
      visibleItemCount++;
    }
  }

  return visibleItemCount;
};
