import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

const MAX_NOTES_LINE = 3;
const NOTES_LINE_HEIGHT = 16;

interface SBodyProps {
  readonly expanded: boolean;
}

export const SBody = styled.div<SBodyProps>`
  .campaign-details {
    &__campaign-main-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 80px;
      width: 100%;
    }
    &__campaign-links {
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > :not(:first-child) {
        margin-top: 5px;
      }
    }
    &__cell-centered-label {
      align-items: center;
    }
    &__cell-top-label {
      align-self: flex-start;
    }
    &__taxonomy-level-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__dot {
      height: 5px;
      width: 5px;
      border-radius: 5px;
      margin: 0 8px;
      background-color: #b8bcd9;
    }
    &__text,
    &__long-text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    &__long-text {
      white-space: normal;
      word-wrap: break-word;
    }
    &__campaignTypesContainer {
      display: flex;
      flex-wrap: wrap;
    }
    &__dots {
      margin-top: 20px;
    }
    &__editor {
      width: 90%;
      overflow: hidden;
      display: inline-block;
      line-height: ${NOTES_LINE_HEIGHT}px;
      max-height: ${({ expanded }) => (expanded ? null : NOTES_LINE_HEIGHT * MAX_NOTES_LINE)}px;
    }
    &__editor_container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
    }
    &__show-notes {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      text-align: end;
      cursor: pointer;
      margin-top: 20px;
      color: ${palette.brightBlue};
      &:active {
        opacity: 0.5;
      }
    }
    &__incomplete-campaign-banner {
      margin-bottom: 10px;
    }
    &__timeline {
      margin-top: 8px;
    }
  }
`;
