import { useCallback, useEffect, useState } from 'react';
import { ArtistRosterItem, Optional } from 'backend-api/models';
import { getItemLayoutInfo } from 'projects/components/transducers';
import { TRIANGLE_WIDTH } from 'projects/components/live-artist/constants';

interface ExpansionConfig {
  arrowPosition: number;
  expansionRow: number;
  onItemClick(id: string): void;
  onCollapseFinish(): void;
}

interface HookConfig {
  gridElement: HTMLDivElement | null;
  expandedArtistId?: string;
  items: ArtistRosterItem[];
  onItemExpand?(id?: string): void;
  gridGap: number;
  columnCount: number;
  isTriangleCenter?: boolean;
  rowHeight: number;
}

export const useArtistRosterExpansion = ({
  gridElement,
  expandedArtistId,
  items,
  onItemExpand,
  isTriangleCenter,
  gridGap = 0,
  columnCount = 0,
  rowHeight,
}: HookConfig): ExpansionConfig => {
  const [arrowPosition, setArrowPosition] = useState(0);
  const [pendingArtistId, setPendingArtistId] = useState<Optional<string>>(undefined);
  const [expansionRow, setExpansionRow] = useState<number>(0);

  const getIndex = useCallback((artistId?: string) => items.findIndex(item => item.artist.id === artistId), [items]);

  const getArrowPosition = useCallback(
    (index: number) => {
      if (items.length === 0) return 0;
      const itemLayoutInfo = getItemLayoutInfo(gridElement, index, gridGap);

      if (!itemLayoutInfo) return 0;

      const triangleOffset = isTriangleCenter ? itemLayoutInfo.itemWidth / 2 - TRIANGLE_WIDTH / 2 : 0;

      return itemLayoutInfo.x + triangleOffset;
    },
    [gridElement, gridGap, isTriangleCenter, items.length]
  );

  const getItemRow = useCallback(
    (id: string) => {
      const index = getIndex(id);
      const item = gridElement?.children.item(index) as HTMLElement;
      const itemRect = item?.getBoundingClientRect();

      if (!itemRect || !item || !gridElement) return -1;

      const itemRowSpan = Math.floor(itemRect.height / rowHeight);
      const rowStart = Math.floor((item.offsetTop - gridElement.offsetTop) / rowHeight) + 1;

      return rowStart + itemRowSpan;
    },
    [getIndex, gridElement, rowHeight]
  );

  useEffect(() => {
    if (items.length === 0 || expandedArtistId === undefined) return;
    const index = getIndex(expandedArtistId);
    const row = getItemRow(expandedArtistId);
    setArrowPosition(getArrowPosition(index));
    setExpansionRow(row);
  }, [expandedArtistId, getArrowPosition, getIndex, items, columnCount, getItemRow]);

  useEffect(() => {
    const onResized = () => {
      if (!expandedArtistId) return;
      setArrowPosition(getArrowPosition(getIndex(expandedArtistId)));
    };
    window.addEventListener('resize', onResized);

    return () => {
      window.removeEventListener('resize', onResized);
    };
  }, [getArrowPosition, expandedArtistId, getIndex]);

  const onItemClick = useCallback(
    (id: string) => {
      const index = getIndex(id);
      const pendingRow = getItemRow(id);
      setArrowPosition(getArrowPosition(index));
      if (pendingRow !== expansionRow && expandedArtistId !== id && expandedArtistId !== undefined) {
        setPendingArtistId(id);
        onItemExpand?.(undefined);
        return;
      }

      onItemExpand?.(expandedArtistId === id ? undefined : id);
      setExpansionRow(pendingRow);
    },
    [getIndex, getItemRow, getArrowPosition, expansionRow, expandedArtistId, onItemExpand]
  );

  const onCollapseFinish = useCallback(() => {
    if (!pendingArtistId) return;

    const pendingRow = getItemRow(pendingArtistId);
    onItemExpand?.(pendingArtistId);
    setPendingArtistId(undefined);
    setExpansionRow(pendingRow);
  }, [pendingArtistId, getItemRow, onItemExpand]);

  return {
    arrowPosition,
    expansionRow,
    onItemClick,
    onCollapseFinish,
  };
};
