export const ADD_CAMPAIGN = 'add_campaign';
export const REMOVE_CAMPAIGN = 'remove_campaign';
export const UPDATE_CAMPAIGN_TIMELINE = 'update_campaign_timeline';
export const UPDATE_CAMPAIGN_BUDGET = 'update_campaign_budget';
export const UPDATE_CAMPAIGN_TYPES = 'update_campaign_types';
export const CHANGE_TERRITORIES = 'change_territories';
export const ADD_PHASE = 'add_phase';
export const REMOVE_PHASE = 'remove_phase';
export const CHANGE_OWNER = 'change_project_owner';
export const CHANGE_PROJECT_BUDGET = 'change_project_budget';
export const UPDATE_ARTIST = 'update_artist';
export const PROJECT_TEAM_CHANGE = 'change_project_team';
export const CAMPAIGN_PHASE_CHANGE = 'change_campaign_phases';
export const PLATFORM_UPDATED = 'change_campaign_platform';
export const ADD_PROJECT = 'add_project';
export const UPDATE_PROJECT_NOTES = 'update_project_notes';
export const UPDATE_PROJECT_TARGET_TYPE = 'update_project_target_type';
export const ADD_PRIMARY_ARTISTS = 'add_primary_artists';
export const REMOVE_PRIMARY_ARTISTS = 'remove_primary_artists';
export const ADD_FEATURED_ARTISTS = 'add_featured_artists';
export const REMOVE_FEATURED_ARTISTS = 'remove_featured_artists';
export const ADD_PLAYLISTS = 'add_playlists';
export const REMOVE_PLAYLISTS = 'remove_playlists';
export const CLAIM_GRAS_PROJECT_OWNERSHIP = 'claim_gras_project_ownership';
export const APPROVE_GRAS_CAMPAIGN = 'approve_gras_campaign';
export const REJECT_GRAS_CAMPAIGN = 'reject_gras_campaign';

export const AUTO_APPROVED_CAMPAIGN_SOURCES = ['PRS'];
