import styled from 'styled-components';
import { theme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';

export const SNotesUpdates = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  .notes-updates {
    &__avatar-container {
      display: flex;
      flex-direction: row;
      position: relative;
      margin-right: 12px;
      & :not(:first-child) {
        transform: translate(-8px, -1px);
      }
    }
    &__avatar {
      display: flex;
      height: 40px;
      width: 40px;
      border-radius: 20px;
      color: white;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.24px;
      line-height: 14px;
      text-align: center;
      align-items: center;
      justify-content: center;
      grid-area: avatar;
      background: ${colorPalette.darkBlue};
      &--last {
        box-sizing: content-box;
        border-radius: 22px;
        border: 2px solid white;
      }
    }
    &__last-updated-container {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
    }
    &__last-updated-text {
      color: ${theme.textLabelColor};
    }
  }
`;
