import { Optional } from 'backend-api/models';
import { trim } from 'lodash';

export const projectNameValidation = (value?: string): Optional<string> => {
  if (!value || trim(value).length === 0) {
    return 'Field cannot be empty.';
  }

  if (value.length > 5000) {
    return 'Project name is too long';
  }

  return;
};
