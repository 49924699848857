import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { ActionRenderer } from '../../../common-renderers';

export const BEM_CLASS = 'status-renderer';

export const SStatusRenderer = styled(ActionRenderer)`
  .${BEM_CLASS} {
    &__text {
      color: ${manhattanThemeColors.grayedText};
    }
  }
`;
