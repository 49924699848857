import { Skeleton } from 'common-v2/components';
import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SDetailsLoading } from './s-details-loading';

interface LoadingProps {
  className?: string;
}

const classes = bem(BEM_CLASS);

export const DetailsLoading = React.memo(({ className }: LoadingProps) => (
  <SDetailsLoading className={className}>
    <div className={classes('container')}>
      <Skeleton className={classes('title-loader')} width={130} height={130}>
        <rect x="0" y="0" rx="2" ry="2" width="130" height="130" />
      </Skeleton>

      <div className={classes('rows')}>
        <Skeleton width={150} height={25}>
          <rect x="0" y="0" rx="2" ry="2" width="150" height="25" />
        </Skeleton>
        <Skeleton className={classes('second-row')} width={100} height={20}>
          <rect x="0" y="0" rx="2" ry="2" width="100" height="20" />
        </Skeleton>
        <Skeleton className={classes('third-row')} width={50} height={15}>
          <rect x="0" y="0" rx="6" ry="6" width="50" height="15" />
        </Skeleton>
      </div>

      <div className={classes('controls')}>
        <Skeleton width={40} height={40}>
          <rect x="0" y="0" rx="20" ry="20" width="40" height="40" />
        </Skeleton>
        <Skeleton width={40} height={40}>
          <rect x="0" y="0" rx="20" ry="20" width="40" height="40" />
        </Skeleton>
      </div>
    </div>
  </SDetailsLoading>
));
