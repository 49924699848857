import { Icon, ICON_SIZE, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { SArtistAvatarAndName, BEM_CLASS } from './s-artist-avatar-and-name';
import { Image, TooltipedText } from 'common-v2/components';

interface ArtistAvatarAndNameProps {
  name: string;
  imageUrl?: string;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ArtistAvatarAndName = React.memo(({ name, imageUrl, className }: ArtistAvatarAndNameProps) => (
  <SArtistAvatarAndName className={className}>
    <Image
      imageUrl={imageUrl}
      className={classes('image')}
      placeholderComponent={<Icon name="artist-m" size={ICON_SIZE.medium} />}
    />
    <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('text')}>
      <TooltipedText text={name}>
        <span>{name}</span>
      </TooltipedText>
    </Typography>
  </SArtistAvatarAndName>
));
