import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'media-plan-header';

export const SMediaPlanHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding-bottom: 20px;

  .${BEM_CLASS} {
    &__header {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 30px;
    }

    &__title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      height: 35px;
    }

    &__title-loader {
      margin: 15px 0 11px;
    }

    &__approval-button {
      margin-right: 15px;

      &:not(&--isDisabled) {
        border: 1px solid ${transparentize(0.6, manhattanThemeColors.primaryFillNew)};
      }
    }

    &__expand {
      width: 22px;
      height: 22px;
      border: unset;
      color: black;
      min-height: unset;
      border: 1px solid ${transparentize(0.5, colorPalette.mischka)};
      background: white;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        background-color: ${transparentize(0.9, colorPalette.azureRadiance)};
      }
    }

    &__requesters {
      margin-left: auto;
    }

    &__mode-controls {
      margin-bottom: 10px;
    }
  }
`;
