import { colorPalette, themeColors } from 'app/theme/colors';

export const buttonsTheme = {
  primaryCta: {
    color: colorPalette.white,
    background: themeColors.primaryColor,
    hover: {
      background: themeColors.primaryDarkColor,
    },
    disabled: {
      background: colorPalette.ghostWhite,
      color: colorPalette.gray,
    },
  },
  secondaryCta: {
    color: colorPalette.white,
    background: themeColors.secondaryColor,
    hover: {
      background: themeColors.secondaryDarkColor,
    },
    disabled: {
      background: colorPalette.ghostWhite,
      color: colorPalette.gray,
    },
  },
  secondaryGray: {
    color: colorPalette.black,
    background: colorPalette.athensGray,
    hover: {
      background: colorPalette.mischka,
    },
    disabled: {
      color: colorPalette.gray,
      background: colorPalette.ghostWhite,
    },
  },
  dark: {
    color: colorPalette.white,
    background: colorPalette.eastBay,
    hover: {
      background: colorPalette.pickledBluewood,
    },
    disabled: {
      color: colorPalette.gray,
      background: colorPalette.ghostWhite,
    },
  },
  secondaryWhite: {
    color: colorPalette.black,
    iconColor: colorPalette.lavenderGray, // In this case Icon color is not equal to label color
    background: colorPalette.white,
    hover: {
      background: colorPalette.mischka,
    },
    disabled: {
      color: colorPalette.gray,
      background: colorPalette.whitenedTooth,
    },
  },
  black: {
    color: colorPalette.white,
    iconColor: colorPalette.white,
    background: colorPalette.black,
    hover: {
      background: colorPalette.tundora,
    },
    disabled: {
      color: colorPalette.gray,
      background: colorPalette.ghostWhite,
    },
  },
  transparent: {
    disabled: {
      color: colorPalette.gray,
    },
  },
};
