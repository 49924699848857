import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { showErrorToast, showSuccessToast, showToast, ToastType } from 'common/components/toast';
import { prepareAutogenerateNameParams, prepareRequest } from './transducers';
import { goBack, navigateTo, replaceTo } from 'utils/navigation';
import { paths } from 'app/routing/paths';
import { EXPORT_FILENAME_DATE_FORMAT, CAMPAIGN_ACCESS_RESTRICTED_CODES } from 'common/constants';
import FileSaver from 'file-saver';
import { asyncEpic, ofType } from 'core/epics';
import { Api } from 'backend-api';
import {
  createCampaign,
  deleteCampaign,
  editCampaign,
  exportAdSets,
  generateCampaignName,
  getAdSets,
  getCampaign,
  getCampaignProject,
  getPhases,
  getUnassignedCampaign,
  unassignCampaign,
  undoDeleteCampaign,
} from './actions';
import { getProject } from 'common/actions';
import { AnalyticsEvents, increaseUserCounter, trackEvent, UserCounters } from 'utils/analytic';
import { getFormattedDate } from 'common-v2/utils';

export const getCampaignEpic = asyncEpic(getCampaign, action => {
  const { campaignUuid, projectId, params } = action.payload;
  return Api.getCampaignDetails(projectId, campaignUuid, params);
});

export const getUnassignedCampaignEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(getUnassignedCampaign.request),
    switchMap(({ payload }) => {
      return Api.getUnassignedCampaignDetails(payload.campaignUuid, payload.params).pipe(
        mergeMap(result => [getUnassignedCampaign.success(result)]),
        catchError(error => {
          if (error.statusCode === 403 && CAMPAIGN_ACCESS_RESTRICTED_CODES.some(code => error.data?.code === code)) {
            replaceTo(`/unassigned-campaigns/${payload.campaignUuid}/access-restricted`);
          }
          return of(getUnassignedCampaign.failure(error));
        })
      );
    })
  );
};

export const getProjectEpic = asyncEpic(getCampaignProject, action => {
  const { campaign, projectId } = action.payload;
  const id = projectId || campaign?.projectId || -1;
  return Api.getProject(id);
});

export const createCampaignEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(createCampaign.request),
    switchMap(action => {
      const { projectId, onSuccess } = action.payload;
      const data = prepareRequest(action.payload);
      return Api.createCampaign(projectId, data).pipe(
        tap(campaign => goBack(() => navigateTo(paths.campaign(projectId, campaign.uuid)))),
        tap(() => showSuccessToast('New campaign was created successfully.')),
        tap(() => increaseUserCounter(UserCounters.CAMPAIGNS_CREATED, 1)),
        tap(response =>
          trackEvent(AnalyticsEvents.CAMPAIGN_CREATED, {
            project_id: response.projectId,
            campaign_category: response.type?.category.name || 'Uncatecorized',
            campaign_type: response.type?.name,
            campaign_source: response.source,
          })
        ),
        tap(() => onSuccess?.()),
        mergeMap(() => [createCampaign.success(), getProject.request({ projectId })]),
        catchError(error => {
          showErrorToast(error);
          return of(createCampaign.failure(error));
        })
      );
    })
  );
};

export const deleteCampaignEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(deleteCampaign.request),
    switchMap(({ payload: { projectId, campaignUuid, onUndo, onDeleteCampaignAction } }) => {
      return Api.deleteCampaign(projectId, campaignUuid).pipe(
        tap(() => onDeleteCampaignAction && onDeleteCampaignAction()),
        tap(() =>
          showToast({
            id: `campaign-${campaignUuid}-removed-toast`,
            type: ToastType.Success,
            message: 'Campaign was successfully removed.',
            onUndo,
          })
        ),
        mergeMap(() => [deleteCampaign.success(), getProject.request({ projectId })]),
        catchError(error => {
          showErrorToast(error);
          return of(deleteCampaign.failure(error));
        })
      );
    })
  );
};

export const editCampaignEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(editCampaign.request),
    switchMap(action => {
      const { projectId, campaignUuid } = action.payload;
      const data = prepareRequest(action.payload);
      return Api.editCampaign(projectId, campaignUuid, data).pipe(
        tap(campaign => goBack(() => navigateTo(paths.campaign(projectId, campaign.uuid)))),
        tap(() => showSuccessToast('Campaign was successfully updated.')),
        tap(() => increaseUserCounter(UserCounters.CAMPAIGNS_EDITED, 1)),
        tap(response =>
          trackEvent(AnalyticsEvents.CAMPAIGN_EDITED, {
            project_id: projectId,
            campaign_category: response.type?.category.name || 'Uncatecorized',
            campaign_type: response.type?.name,
            campaign_editor: response.createUser?.email,
          })
        ),
        mergeMap(() => [editCampaign.success(), getProject.request({ projectId })]),
        catchError(error => {
          showErrorToast(error);
          return of(editCampaign.failure(error));
        })
      );
    })
  );
};

export const undoDeleteCampaignEpic = (action$: Observable<any>) =>
  action$.pipe(
    ofType(undoDeleteCampaign.request),
    switchMap(({ payload: { projectId, campaignUuid } }) => {
      return Api.undoDeleteCampaign(projectId, campaignUuid).pipe(
        mergeMap(() => [undoDeleteCampaign.success(), getProject.request({ projectId })]),
        catchError(error => {
          showErrorToast(error);
          return of(undoDeleteCampaign.failure(error));
        })
      );
    })
  );

export const getPhasesEpic = asyncEpic(getPhases, action => Api.getPhases(action.payload));

export const getAdSetsEpic = asyncEpic(
  getAdSets,
  action => {
    const { campaignUuid, filters } = action.payload;
    return Api.getAdSets(campaignUuid, filters);
  },
  undefined,
  { showError: false }
);

export const exportAdSetsEpic = asyncEpic(exportAdSets, action => {
  const formatDate = getFormattedDate(EXPORT_FILENAME_DATE_FORMAT);
  const now = formatDate(new Date());

  return Api.exportAdSets(action.payload).pipe(
    tap(data => FileSaver.saveAs(new Blob([data]), `${now}_All_Projects.csv`))
  );
});

export const unassignCampaignEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(unassignCampaign.request),
    switchMap(({ payload: { projectId, campaignUuid, onUnassignCampaignAction } }) => {
      return Api.unassignCampaign(projectId, campaignUuid).pipe(
        tap(() => onUnassignCampaignAction && onUnassignCampaignAction()),
        tap(() => showSuccessToast('Campaign was successfully removed.')),
        mergeMap(() => [unassignCampaign.success(), getProject.request({ projectId })]),
        catchError(error => {
          showErrorToast(error);
          return of(deleteCampaign.failure(error));
        })
      );
    })
  );
};

export const generateCampaignNameEpic = asyncEpic(generateCampaignName, action => {
  const data = prepareAutogenerateNameParams(action.payload);
  return Api.generateCampaignName(action.payload.projectId || 0, data).pipe(map(response => response.name));
});

//†epic
