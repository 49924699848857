import React from 'react';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { SNotification, BEM_CLASS } from './s-notification';

const classes = bem(BEM_CLASS);

export const Notification = React.memo(() => (
  <SNotification data-selector="share-confidential-project-warning">
    <Icon className={classes('icon')} name="warning" color="white" size="general" />
    <span className={classes('text')}>
      Sharing this <strong>confidential</strong> project with new collaborators will allow them to see and interact with
      it.
    </span>
  </SNotification>
));
