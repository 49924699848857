import React from 'react';
import { bem } from 'utils/bem';
import { ICellRendererParams } from 'ag-grid-community';
import { BEM_CLASS, SUserNameRenderer } from './s-user-name-renderer';
import { LabelUser } from 'backend-api/models';
import { Avatar, TooltipedText } from 'common-v2/components';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

const classes = bem(BEM_CLASS);

export const UserNameRenderer = ({ value }: ICellRendererParams<any, LabelUser>) => (
  <SUserNameRenderer>
    <Avatar
      name={value.name}
      size={30}
      avatarColor={transparentize(0.85, manhattanThemeColors.primaryFillNew)}
      className={classes('avatar')}
    />

    <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('tooltip-wrapper')}>
      <TooltipedText text={value.name}>
        <span>{value.name}</span>
      </TooltipedText>
    </Typography>
  </SUserNameRenderer>
);
