import { LoadingState } from 'common/types';
import { createSelector } from 'reselect';
import { MediaPlanV2State } from './reducer';

export const mediaPlanStateSelector = state => state.mediaPlanV2 as MediaPlanV2State;

export const mediaPlansSelector = createSelector(mediaPlanStateSelector, state => state.mediaPlans);

export const mediaPlanSelector = createSelector(mediaPlanStateSelector, state => state.mediaPlan);

export const mediaPlanErrorSelector = createSelector(mediaPlanStateSelector, state => state.mediaPlanError);

export const phasesCounterSelector = createSelector(mediaPlanStateSelector, state => state.phases.length);

export const confirmModalSelector = createSelector(mediaPlanStateSelector, state => state.confirmModal);
export const isConfirmModalShownSelector = createSelector(
  mediaPlanStateSelector,
  state => state.confirmModal.isVisible
);

export const focusedPhaseIdSelector = createSelector(mediaPlanStateSelector, state => state.focusedPhaseId);

export const spreadsheetValidationModelSelector = createSelector(
  mediaPlanStateSelector,
  state => state.spreadsheetValidationModel
);

export const isSyncingSelector = createSelector(
  mediaPlanStateSelector,
  state =>
    state.isCreatePhaseSyncing === LoadingState.Started ||
    state.isDeletePhaseSyncing === LoadingState.Started ||
    state.isModifyCampaignSyncing === LoadingState.Started
);

export const projectReleaseSelector = createSelector(mediaPlanStateSelector, state => state.projectRelease);
export const autogeneratedNamesSelector = createSelector(mediaPlanStateSelector, state => state.autogeneratedNames);

export const mediaPlanModeSelector = createSelector(mediaPlanStateSelector, state => state.mediaPlanMode);

export const selectedCampaignsIdsSelector = createSelector(mediaPlanStateSelector, state => state.selectedCampaignsIds);
export const reviewedCampaignsSelector = createSelector(mediaPlanStateSelector, state => state.campaignsForApproval);

export const projectReviewersSelector = createSelector(mediaPlanStateSelector, state => state.projectReviewers);

export const isRequestApprovalLoadingSelector = createSelector(
  mediaPlanStateSelector,
  state => state.isRequestApprovalLoading
);

export const approvalRequestsSelector = createSelector(mediaPlanStateSelector, state => state.approvalRequests);
export const approvalRequestsPhasesSelector = createSelector(
  approvalRequestsSelector,
  requests => requests?.data.flatMap(item => item.phases) || []
);
export const approvalRequestsCampaignsSelector = createSelector(approvalRequestsPhasesSelector, phases =>
  phases.flatMap(item => item.campaigns)
);
export const approvalRequestsUsersSelector = createSelector(
  mediaPlanStateSelector,
  state => state.approvalRequests?.data.map(item => item.requester) || []
);

export const disableRequestApprovalButtonSelector = createSelector(
  mediaPlanStateSelector,
  state => state.disableRequestApprovalButton
);

export const approvalRequestsLoadingStateSelector = createSelector(
  mediaPlanStateSelector,
  state => state.approvalRequests?.loading
);

export const projectMetricsSelector = createSelector(mediaPlanStateSelector, state => state.projectMetrics);

export const isMediaPlanNotFoundModalOpenedSelector = createSelector(
  mediaPlanStateSelector,
  state => state.isMediaPlanNotFoundModalOpened
);

export const confirmDeleteMediaPlanModalSelector = createSelector(
  mediaPlanStateSelector,
  state => state.confirmDeleteMediaPlanModal
);

export const isMediaPlansProcessingSelector = createSelector(
  mediaPlanStateSelector,
  state => state.isMediaPlansProcessing
);

export const isMediaPlanDropdownVisibleSelector = createSelector(
  mediaPlanStateSelector,
  state => state.isMediaPlanDropdownVisible
);

export const isMediaPlanUpdatedModalOpenedSelector = createSelector(
  mediaPlanStateSelector,
  state => state.isMediaPlanUpdatedModalOpened
);

export const artistTeamSelector = createSelector(mediaPlanStateSelector, state => state.artistTeam);

export const errorSelector = createSelector(mediaPlanStateSelector, state => state.error);
