import { TeamUser } from 'backend-api/models';
import { actionsGroup } from 'core/store';

const { action } = actionsGroup('sharing-modal');

export const addInitialTeamMembers = action<TeamUser[]>('ADD_INITIAL_TEAM_USERS');
export const addInitialOwner = action<TeamUser>('ADD_INITIAL_OWNER');
export const deleteTeamUser = action<TeamUser>('DELETE_TEAM_USER');
export const addTeamUsers = action<TeamUser>('ADD_TEAM_USERS');
export const updateTeamUser = action<TeamUser>('UPDATE_TEAM_USER');
export const clearAll = action<TeamUser[]>('CLEAR_ALL');

export const resetModal = action<void>('RESET_MODAL');
