import React, { useMemo } from 'react';
import { useTaxonomy } from 'hooks';
import { RecentSearchItem } from 'backend-api/models';
import { bem } from 'utils/bem';
import { NoResults } from 'common/components/no-results';
import { LoadingState } from 'common/types';
import NoSearchesGraySvg from 'assets/no-searches-gray.svg';
import FailedToLoadGraySvg from 'assets/failed-to-load-gray.svg';
import { trackEvent, AnalyticsEvents } from 'utils/analytic';
import { RecentSearchLoader } from './components';
import { mapRecentSearchEntity } from './transducers';
import { SRecentSearch, BEM_CLASS } from './s-recent-search';
import { SearchItem } from '../';

const classes = bem(BEM_CLASS);

interface RecentSearchProps {
  loading: LoadingState;
  data: RecentSearchItem[];
}

export const RecentSearch = React.memo(({ loading, data }: RecentSearchProps) => {
  const taxonomy = useTaxonomy();

  const renderContent = useMemo(() => {
    if (loading === LoadingState.Idle || loading === LoadingState.Started) {
      return <RecentSearchLoader />;
    }

    if (loading === LoadingState.Failed) {
      return (
        <NoResults
          className={classes('center')}
          withoutFiltersMessage="Failed to load recent searches."
          withoutFiltersImage={FailedToLoadGraySvg}
        />
      );
    }

    if (data.length === 0) {
      return (
        <NoResults
          className={classes('center')}
          withoutFiltersMessage="Start typing to see search results."
          withoutFiltersImage={NoSearchesGraySvg}
        />
      );
    }

    return (
      <>
        <h3 className={classes('title')} data-selector="recent-searches-title">
          Recent Searches
        </h3>
        {data.map((item, index, arr) => {
          const { id, type, entity } = item;
          const { link, targets, icon, component } = mapRecentSearchEntity(entity, taxonomy?.categories);

          const trackRecentClick = () => trackEvent(AnalyticsEvents.RECENT_SEARCHES_CLICKED, { item_type: type });

          return (
            <SearchItem
              key={id}
              className={classes('item')}
              targets={targets}
              link={link}
              icon={icon}
              hasBorder={index !== arr.length - 1}
              onClick={trackRecentClick}
            >
              {component}
            </SearchItem>
          );
        })}
      </>
    );
  }, [loading, data, taxonomy?.categories]);

  return <SRecentSearch>{renderContent}</SRecentSearch>;
});
