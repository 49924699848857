import React, { useState, useCallback } from 'react';
import { Typography, TYPOGRAPHY_TYPE, Icon, ICON_SIZE } from 'gdb-web-shared-components';
import { UserRole } from 'backend-api/models';
import { Popper } from 'common/components/popper';
import { LocalRole } from 'common-v2/types';
import { bem } from 'utils/bem';
import { BEM_CLASS, SUserRoleEditor } from './s-user-role-editor';
import { UserRoleDropdown } from './components';

interface UserRoleEditorProps {
  availableRoles: LocalRole[];
  currentRole: UserRole;
  flipBoundary: React.ReactNode;
  onSelectRole(role: LocalRole): void;
  onRemoveUser(role: UserRole): void;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const UserRoleEditor = React.memo(
  ({ availableRoles, currentRole, flipBoundary, onSelectRole, onRemoveUser, className }: UserRoleEditorProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = useCallback(() => setIsOpen(!isOpen), [isOpen]);
    const hideMenu = useCallback(() => setIsOpen(false), []);

    const handleRoleSelection = useCallback(
      (role: LocalRole) => {
        setIsOpen(false);
        onSelectRole(role);
      },
      [onSelectRole]
    );

    const handleRemoveUser = useCallback(
      (role: UserRole) => {
        setIsOpen(false);
        onRemoveUser(role);
      },
      [onRemoveUser]
    );

    return (
      <SUserRoleEditor className={className}>
        <Popper
          content={
            <UserRoleDropdown
              availableRoles={availableRoles}
              currentRole={currentRole}
              onSelectRole={handleRoleSelection}
              onRemoveUser={handleRemoveUser}
            />
          }
          flipBoundary={flipBoundary}
          visible={isOpen}
          onClickOutside={hideMenu}
          placement="bottom-end"
        >
          <div className={classes('content')} onClick={toggleMenu} data-selector="share-modal-member-item-role">
            <Typography type={TYPOGRAPHY_TYPE.body4}>{currentRole.name}</Typography>
            <Icon name={isOpen ? 'up-single-m' : 'down-single-m'} size={ICON_SIZE.small} />
          </div>
        </Popper>
      </SUserRoleEditor>
    );
  }
);
