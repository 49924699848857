import { ArtistSearch } from 'backend-api/models';
import { ArtistAvatarAndName } from 'common-v2/components';
import { Button, BUTTON_SIZE, BUTTON_TYPE, THEME } from 'gdb-web-shared-components';
import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { SArtistItem, BEM_CLASS } from './s-artist-item';

interface ArtistItemProps {
  artist: ArtistSearch;
  onDelete?(id: string): void;
  isDisabled?: boolean;
  onArtistClick?(artist: ArtistSearch): void;

  className?: string;
}

const classes = bem(BEM_CLASS);

export const ArtistItem = React.memo(({ artist, onDelete, isDisabled, onArtistClick, className }: ArtistItemProps) => {
  const onDeleteClick = useCallback(() => {
    onDelete?.(artist.id);
  }, [onDelete, artist.id]);

  const onClick = useCallback(() => {
    onArtistClick?.(artist);
  }, [onArtistClick, artist]);

  return (
    <SArtistItem className={className} onClick={onClick} data-selector="edit-modal-artist-item">
      <ArtistAvatarAndName className={classes('name-and-avatar')} name={artist.name} imageUrl={artist.image?.url} />
      {onDelete && !isDisabled && (
        <Button
          size={BUTTON_SIZE.smallRound}
          icon="delete"
          type={BUTTON_TYPE.tertiary}
          theme={THEME.light}
          className={classes('delete')}
          onClick={onDeleteClick}
          data-selector="artist-item-delete-button"
        />
      )}
    </SArtistItem>
  );
});
