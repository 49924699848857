import { useAppContext } from 'common-v2/hooks';
import { getCampaignTaxonomy } from 'common/actions';
import { campaignTaxonomySelector } from 'common/selectors';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useTaxonomy = () => {
  const dispatch = useDispatch();
  const { labels: userLabels } = useAppContext();

  const taxonomy = useSelector(campaignTaxonomySelector);

  useEffect(() => {
    if (userLabels && userLabels.length > 0 && !taxonomy) {
      const userLabelsIds = userLabels.map(label => label.id);
      dispatch(getCampaignTaxonomy.request(userLabelsIds));
    }
  }, [dispatch, userLabels, taxonomy]);

  return taxonomy;
};
