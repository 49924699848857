import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'request-approval-modal';

export const SRequestApprovalModal = styled.div`
  width: 480px;

  .${BEM_CLASS} {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 50px;
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 20px 0 30px;
    }

    &__button {
      width: 180px;

      &--cancel {
        &::before {
          border-color: ${transparentize(0.6, manhattanThemeColors.primaryFillNew)};
        }
      }
    }

    &__header {
      flex-shrink: 0;
      display: grid;
      grid-template-columns: 25px auto 25px;
      width: 100%;
      align-items: center;
      padding: 23px 20px 20px;

      &::before {
        content: '';
      }
    }

    &__alert {
      width: 100%;
      margin-bottom: 20px;
    }

    &__title {
      text-align: center;
    }

    &__search-field {
      width: 100%;

      &:not(&--hasError) {
        margin-bottom: 20px;
      }
    }

    &__text {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }

    &__textarea-label {
      margin-bottom: 10px;
    }

    &__textarea-wrapper {
      width: 100%;
    }

    &__textarea {
      height: 95px;
    }

    &__search-field-list {
      max-height: 185px;
    }

    &__search-field-no-options {
      height: 185px;
    }
  }
`;
