import React, { useCallback } from 'react';
import { ValueContainerProps } from 'react-select';
import { BaseOption as BaseOptionType } from '../../types';
import { getMultiCustomSelectProps } from 'common/components/select/transducers';
import { BEM_CLASS, SValueContainer } from './s-value-container';
import { bem } from 'utils/bem';
import { PrimaryLabel } from 'common/components/typography';
import { callbackWithoutPropagation } from 'utils/event';

type Props<T, IsMulti extends boolean> = ValueContainerProps<T, IsMulti>;

export const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType, IsMulti extends boolean>({
  children,
  selectProps,
  getValue,
  clearValue,
  isMulti,
  innerProps,
}: Props<T, IsMulti>) => {
  const { classNames, dataSelector, valueContainerLabel } = getMultiCustomSelectProps(selectProps);

  const handleClearAll = useCallback(() => {
    clearValue();
  }, [clearValue]);

  const valueCount = getValue().length;
  const shouldShowFooter = (isMulti && valueCount > 0) || valueContainerLabel;

  const onMouseDown = useCallback((event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <SValueContainer
      className={classNames.valueContainer?.root}
      data-selector={`${dataSelector}-value-container`}
      isMulti={isMulti}
    >
      <div className={classes('container')}>
        <div {...innerProps} className={classes('values-container', [], classNames.valueContainer?.container)}>
          {children}
        </div>

        {shouldShowFooter && (
          <div className={classes('footer')}>
            {selectProps.isClearable && (
              <PrimaryLabel
                as="button"
                className={classes('clear-all')}
                onMouseDown={onMouseDown}
                onClick={callbackWithoutPropagation(handleClearAll)}
              >
                Clear All
              </PrimaryLabel>
            )}
            <PrimaryLabel className={classes('counter', [], classNames.valueContainer?.counter)}>
              {valueContainerLabel || valueCount}
            </PrimaryLabel>
          </div>
        )}
      </div>
    </SValueContainer>
  );
};

export const ValueContainer = React.memo(Root) as typeof Root;
