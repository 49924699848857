import React, { useCallback, useRef } from 'react';
import { SpreadsheetTooltip } from '../spreadsheet-tooltip';
import { TextValue } from '../text-value';
import { Badge } from '../badge';

interface Props {
  value: React.ReactNode;
  isBadge?: boolean;
  className?: string;
  textClassName?: string;
  tooltipDisabled?: boolean;
}

export const SingleValueView = ({ value, className, textClassName, isBadge = false, tooltipDisabled }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleOnShow = useCallback(() => {
    if (!ref.current) return false;

    if (ref.current.scrollWidth <= ref.current.clientWidth) {
      return false;
    }

    return;
  }, []);

  const ContentComponent = isBadge ? Badge : TextValue;

  return (
    <SpreadsheetTooltip content={value} onShow={handleOnShow} disabled={tooltipDisabled}>
      <ContentComponent
        ref={ref}
        value={value}
        className={className}
        textClassName={textClassName}
        dataSelector="single-value-view-value"
      />
    </SpreadsheetTooltip>
  );
};
