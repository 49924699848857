import React, { useCallback, useMemo } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { DraggableSectionConfig, SelectedSection } from 'common/components/selected-section';
import { moveItem } from 'utils/data';
import { CustomColumnsModalProps } from '../../custom-columns-modal';
import { SelectedMetricItem } from './components';
import { IdNameField } from '../../types';

type SelectedCustomColumnsSectionProps<T extends IdNameField> = Pick<
  CustomColumnsModalProps<T>,
  'defaultColumnsIds' | 'onUpdate' | 'onRemove'
> & {
  selectedColumns: T[];
};

export const SelectedCustomColumnsSection = React.memo(
  <T extends IdNameField>({
    selectedColumns,
    defaultColumnsIds,
    onUpdate,
    onRemove,
  }: SelectedCustomColumnsSectionProps<T>) => {
    const onResetClick = useCallback(() => {
      onUpdate(defaultColumnsIds);
    }, [onUpdate, defaultColumnsIds]);

    const renderMetricItem = useCallback(
      (metric: T) => <SelectedMetricItem name={metric.name} onRemove={() => onRemove(metric.id)} />,
      [onRemove]
    );

    const onDragEnd = useCallback(
      (result: DropResult) => {
        if (!result.destination) return;

        const fromIndex = result.source.index;
        const toIndex = result.destination.index;
        const newMetrics = moveItem(selectedColumns, fromIndex, toIndex);
        const newMetricsIds = newMetrics.map(metric => metric.id);

        onUpdate(newMetricsIds);
      },
      [selectedColumns, onUpdate]
    );

    const sectionsConfig: DraggableSectionConfig<T>[] = useMemo(
      () => [
        {
          id: 'primary',
          selectedItems: selectedColumns,
          renderItem: renderMetricItem,
          draggable: true,
        },
      ],
      [selectedColumns, renderMetricItem]
    );

    return (
      <SelectedSection
        dragEnabled
        onDragEnd={onDragEnd}
        title={`Selected Columns (${selectedColumns.length})`}
        placeholder="No selected columns"
        resetTitle="Reset"
        onResetClick={onResetClick}
        sectionsConfig={sectionsConfig}
      />
    );
  }
);
