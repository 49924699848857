import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { colorPalette } from 'app/theme/colors';
import { Loader } from '../loader';
import { SInfiniteScroll, BEM_CLASS } from './s-infinite-scroll';

interface InfiniteScrollProps {
  className?: string;
  children: React.ReactElement | React.ReactElement[];
  onScrollEnd(): void;
  isLoading?: boolean;
  isActive: boolean;
}

const classes = bem(BEM_CLASS);

export const InfiniteScroll = React.memo(
  React.forwardRef(
    (
      { className, children, onScrollEnd, isLoading, isActive }: InfiniteScrollProps,
      ref: React.Ref<HTMLDivElement>
    ) => {
      const onScroll = useCallback(
        ({ nativeEvent }: React.UIEvent<HTMLDivElement>) => {
          const { target } = nativeEvent;

          if (!isActive || isLoading || !(target instanceof HTMLDivElement)) return;

          const { offsetHeight, scrollHeight, scrollTop } = target;

          if (scrollTop > 0 && scrollTop === scrollHeight - offsetHeight) {
            onScrollEnd();
          }
        },
        [isActive, isLoading, onScrollEnd]
      );

      return (
        <SInfiniteScroll ref={ref} className={className} onScroll={onScroll}>
          {children}
          {isLoading && (
            <div className={classes('loader')}>
              <Loader size={22} color={colorPalette.eastBay} isActive />
            </div>
          )}
        </SInfiniteScroll>
      );
    }
  )
);
