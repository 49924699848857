import React, { useCallback, useState, useLayoutEffect } from 'react';
import { bem } from 'utils/bem';
import { SImage, BEM_CLASS } from './s-image';

interface ImageProps {
  imageUrl?: string;
  placeholderUrl?: string;
  placeholderComponent?: JSX.Element;
  alt?: string;
  className?: string;
  dataSelector?: string;
}

const classes = bem(BEM_CLASS);

export const Image = React.memo(
  ({
    className,
    dataSelector = 'image',
    imageUrl,
    placeholderUrl,
    placeholderComponent,
    alt = 'image',
  }: ImageProps) => {
    const [isVisible, setIsVisible] = useState(false);
    const [url, setUrl] = useState(placeholderUrl);

    const onError = useCallback(() => {
      if (!placeholderUrl) {
        setIsVisible(false);
      } else {
        setUrl(placeholderUrl);
      }
    }, [placeholderUrl]);

    const onLoad = useCallback(() => {
      setIsVisible(true);
    }, []);

    useLayoutEffect(() => {
      if (imageUrl) {
        setUrl(imageUrl);
      }
    }, [imageUrl]);

    return (
      <SImage className={className} isVisible={isVisible} data-selector={dataSelector}>
        <img className={classes('image')} src={url} alt={alt} onLoad={onLoad} onError={onError} />
        {Boolean(!isVisible && !!placeholderComponent) && placeholderComponent}
      </SImage>
    );
  }
);
