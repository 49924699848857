import { PrimaryLabel } from 'common/components/typography';
import React from 'react';
import { bem } from 'utils/bem';
import { SLabelItem, BEM_CLASS } from './s-label-item';

interface LabelItemProps {
  name: string;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const LabelItem = React.memo(({ name, className }: LabelItemProps) => (
  <SLabelItem className={className}>
    <PrimaryLabel className={classes('name')}>{name}</PrimaryLabel>
  </SLabelItem>
));
