import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'user-info';

export const SUserInfo = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  padding: 30px;
  background-color: ${transparentize(0.5, colorPalette.white)};
  border: 1px solid;
  border-radius: 10px;
  box-shadow: 0px 5px 20px ${transparentize(0.95, colorPalette.woodsmoke)};
  border-color: ${transparentize(0.9, colorPalette.woodsmoke)};

  .${BEM_CLASS} {
    &__avatar {
      flex-shrink: 0;
      font-size: 24px;
      color: ${colorPalette.white};
      background: linear-gradient(
        360deg,
        ${transparentize(0.3, manhattanThemeColors.primaryDark)} 0%,
        ${transparentize(0.3, manhattanThemeColors.hoveredBorderColor)} 100%
      );
    }

    &__info-container {
      display: flex;
      flex-direction: column;
      width: inherit;
      margin-left: 20px;
    }

    &__name {
      margin-top: 22px;
    }

    &__email {
      margin-top: 5px;
    }

    &__separator {
      margin-top: 19px;
      width: 100%;
      height: 1px;
      background-color: ${transparentize(0.9, colorPalette.black)};
    }

    &__labels-title {
      margin-top: 20px;
    }

    &__labels {
      margin-top: 20px;
      gap: 10px;
      display: flex;
      flex-wrap: wrap;
    }

    &__label {
      background-color: ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
      border-color: ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
      color: ${manhattanThemeColors.grayedText};
    }
  }
`;
