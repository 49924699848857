import styled from 'styled-components';

export const BEM_CLASS = 'menu-footer';

export const SMenuFooter = styled.div`
  height: 60px;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: stretch;
  padding: 0 20px;
  .${BEM_CLASS} {
    &__reset-button,
    &__apply-button {
      flex: 1;
    }
    &__reset-button {
      margin-right: 20px;
    }
  }
`;
