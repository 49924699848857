import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { SBox } from './layout/s-box';
import { SpaceProps } from 'styled-system';

export const SPaper = styled(SBox)<SpaceProps>`
  border-radius: 10px;
  background-color: ${({ color }) => color || palette.athensGrayTwo};
  padding-left: 30px;
  padding-right: 30px;
`;
