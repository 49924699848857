import { AdSetsSortField } from 'backend-api/types';
import { Sort } from 'utils/sort';

export interface AdSetsFilters {
  search?: string;
  offset?: number;
  limit?: number;
  sort?: Sort<AdSetsSortField>;
}

export interface ConfirmModalState {
  id?: string;
  isOpened: boolean;
}

export enum GratisType {
  Paid,
  Gratis,
}
