import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BEM_CLASS, SPopup } from './s-popup';
import { bem } from 'utils/bem';
import { Id, MediaPlanListItem, Optional } from 'backend-api/models';
import { Body, PrimaryLabel, SecondaryLabel } from 'common/components/typography';
import { Icon, ICON_SIZE } from 'gdb-web-shared-components';
import { Input } from 'common/components/form';
import { getFooterTooltip, validateNewMediaPlan } from './transducers';
import { Loader } from './components';
import { Tooltip } from 'common/components/tooltip';
import { MAX_MEDIA_PLANS_COUNT, MEDIA_PLAN_NAME_LIMIT } from './constants';
import { Mode } from './types';
import { callbackWithoutPropagation } from 'utils/event';

interface Props {
  mediaPlans: MediaPlanListItem[];
  mediaPlanId?: Id;
  onItemClick?(mediaPlanId: Id): void;
  onDelete(mediaPlanId: Id): void;
  onCreateNew(name: string): void;
  onEdit(mediaPlanId: Id, name: string): void;
  loading?: boolean;
  visible?: boolean;
  editable?: boolean;
  className?: string;
}

export const classes = bem(BEM_CLASS);

export const Popup = React.memo(
  ({
    mediaPlans,
    mediaPlanId,
    onDelete,
    onCreateNew,
    onEdit,
    onItemClick,
    loading,
    editable,
    visible,
    className,
  }: Props) => {
    const [editingItemId, setEditingId] = useState<Optional<Id>>();
    const [mode, setMode] = useState<Mode>(Mode.VIEW);
    const [inputValue, setInputValue] = useState<Optional<string>>();
    const [error, setError] = useState<Optional<string>>();
    const errorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (error && mode === Mode.EDIT) {
        errorRef.current?.scrollIntoView({
          block: 'nearest',
          inline: 'nearest',
          behavior: 'smooth',
        });
      }
    }, [mode, error]);

    useEffect(() => {
      if (!visible) {
        setEditingId(undefined);
        setInputValue('');
        setError(undefined);
        setMode(Mode.VIEW);
      }
    }, [visible]);

    useEffect(() => {
      if (!inputValue) {
        setError(undefined);
      }
    }, [inputValue]);

    useEffect(() => {
      if (!editingItemId) return;
      const editingMediaPlan = mediaPlans.find(item => item.id === editingItemId);
      if (!editingMediaPlan) return;
      setInputValue(editingMediaPlan.name);
    }, [editingItemId, mediaPlans]);

    const onEditClick = useCallback((id?: Id) => {
      setInputValue('');
      setEditingId(id);
      setMode(Mode.EDIT);
    }, []);

    const onCancel = useCallback(() => {
      setInputValue('');
      setEditingId(undefined);
      setMode(Mode.VIEW);
    }, []);

    const onEditConfirm = useCallback(() => {
      const trimmedValue = inputValue?.trim();
      const mediaPlanError = validateNewMediaPlan(trimmedValue, editingItemId, mediaPlans);
      if (mediaPlanError || !trimmedValue) {
        setError(mediaPlanError);
        return;
      }

      if (!editingItemId) {
        onCreateNew(trimmedValue);
      } else {
        onEdit(editingItemId, trimmedValue);
      }

      setEditingId(undefined);
      setMode(Mode.VIEW);
    }, [editingItemId, inputValue, mediaPlans, onCreateNew, onEdit]);

    const onAddClick = useCallback(() => {
      setInputValue('');
      setEditingId(undefined);
      setMode(Mode.CREATE);
    }, []);

    const onDeleteClick = useCallback(
      (id?: Id) => {
        if (!id) return;
        onDelete(id);
        onCancel();
      },
      [onCancel, onDelete]
    );

    const renderControls = useCallback(
      (itemId?: Id, newItem?: boolean) => {
        const isInputEmpty = inputValue?.trim().length === 0;
        switch (mode) {
          case Mode.EDIT:
            return itemId === editingItemId ? (
              <>
                <button
                  className={classes('filled-button', { disabled: isInputEmpty })}
                  data-selector="save-button"
                  onClick={callbackWithoutPropagation(onEditConfirm)}
                >
                  <Icon name="check" className={classes('action-icon')} />
                </button>
                <button
                  className={classes('filled-button')}
                  data-selector="cancel-button"
                  onClick={callbackWithoutPropagation(onCancel)}
                >
                  <Icon name="cross" className={classes('action-icon')} />
                </button>
              </>
            ) : null;
          case Mode.CREATE:
            return newItem ? (
              <>
                <button
                  className={classes('filled-button', { disabled: isInputEmpty })}
                  data-selector="save-button"
                  onClick={callbackWithoutPropagation(onEditConfirm)}
                >
                  <Icon name="check" className={classes('action-icon')} />
                </button>
                <button
                  className={classes('filled-button')}
                  data-selector="cancel-button"
                  onClick={callbackWithoutPropagation(onCancel)}
                >
                  <Icon name="cross" className={classes('action-icon')} />
                </button>
              </>
            ) : null;
          default:
            return (
              <>
                <button
                  className={classes('outlined-button')}
                  data-selector="edit-button"
                  onClick={callbackWithoutPropagation(() => onEditClick(itemId))}
                >
                  <Icon name="edit" className={classes('action-icon')} />
                </button>
                <button
                  className={classes('outlined-button', { disabled: mediaPlans.length === 1 })}
                  data-selector="delete-button"
                  onClick={callbackWithoutPropagation(() => onDeleteClick(itemId))}
                >
                  <Icon name="delete" className={classes('action-icon')} />
                </button>
              </>
            );
        }
      },
      [editingItemId, inputValue, mediaPlans.length, mode, onCancel, onDeleteClick, onEditClick, onEditConfirm]
    );

    const input = useMemo(
      () => (
        <div className={classes('input-container')} data-selector="input-field">
          <Input
            onChange={setInputValue}
            value={inputValue}
            className={classes('input')}
            maxLength={MEDIA_PLAN_NAME_LIMIT}
            autoFocus
            bgStyle="white"
            isRequired
            isInvalid={!!error}
          />
          {error && (
            <div className={classes('error-container')} ref={errorRef}>
              <Icon name="warning" className={classes('error-icon')} size={ICON_SIZE.medium} />
              <SecondaryLabel className={classes('error-text')}>{error}</SecondaryLabel>
            </div>
          )}
        </div>
      ),
      [error, inputValue]
    );

    const isAddDisabled = mediaPlans.length === MAX_MEDIA_PLANS_COUNT;
    const editing = mode === Mode.EDIT;

    const [listWidth, setListWidth] = useState<number | undefined>(undefined);
    const listRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const width = listRef.current?.getBoundingClientRect().width;
      if (width !== 0 && listWidth === undefined) {
        setListWidth(width);
      }

      if (!visible) {
        setListWidth(undefined);
      }
    }, [listWidth, visible, loading]);

    return (
      <SPopup className={className} width={listWidth} data-selector="media-plans-dropdown">
        {loading ? (
          <Loader className={classes('loader')} />
        ) : (
          <>
            <div className={classes('list')} ref={listRef}>
              {mediaPlans.map(item => (
                <button
                  key={item.id}
                  className={classes('item', { editing })}
                  data-selector="media-plan-list-item"
                  onClick={() => {
                    if (!editing) {
                      onItemClick?.(item.id);
                    }
                  }}
                >
                  <Body className={classes('name', { selected: mediaPlanId === item.id && !editing })}>
                    {editing && item.id === editingItemId ? input : item.name}
                  </Body>
                  {editable && (
                    <div className={classes('controls-container', { editing, error: !!error })}>
                      {renderControls(item.id)}
                    </div>
                  )}
                </button>
              ))}
            </div>
            {mode === Mode.CREATE && (
              <div key="new-item" className={classes('new-item')}>
                {input}
                <div className={classes('controls-container', { error: !!error })}>
                  {renderControls(undefined, true)}
                </div>
              </div>
            )}
            {mode !== Mode.CREATE && editable && (
              <Tooltip disabled={!isAddDisabled} content={getFooterTooltip(mediaPlans, editable)}>
                <div>
                  <span
                    className={classes('footer', { disabled: isAddDisabled })}
                    onClick={onAddClick}
                    data-selector="add-new-media-plan"
                  >
                    <Icon name="plus" className={classes('icon')} />
                    <PrimaryLabel className={classes('add-new')}>ADD NEW MEDIA PLAN</PrimaryLabel>
                  </span>
                </div>
              </Tooltip>
            )}
          </>
        )}
      </SPopup>
    );
  }
);
