import { Icon, ICON_SIZE, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { SArtistsListHeader, BEM_CLASS } from './s-artists-list-header';

interface ArtistsListHeaderProps {
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ArtistsListHeader = React.memo(({ className }: ArtistsListHeaderProps) => {
  return (
    <SArtistsListHeader className={className} data-selector="edit-modal-artists-list-header">
      <span className={classes('icon-container')}>
        <Icon className={classes('icon')} name="label-permissions" size={ICON_SIZE.medium} />
      </span>
      <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('title')}>
        All Artists
      </Typography>
    </SArtistsListHeader>
  );
});
