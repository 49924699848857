import { colorPalette } from 'app/theme/colors';
import { typography } from 'app/theme/typography';
import styled from 'styled-components';

export const BEM_CLASS = 'ad-sets-table';

export const SAdSetsTable = styled.div`
  &&& {
    .${BEM_CLASS} {
      &__loader {
        margin-top: 25px;
        height: 320px;
        border-radius: 10px;
        background-color: ${colorPalette.whisper};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
      }

      &__small-cell {
        width: 10%;
        .header__sub-title {
          ${typography.primaryLabel};
          margin-right: 15px;
          height: 15px;
          text-align: right;
        }

        &--right {
          .header__cell-container {
            justify-content: flex-end;
          }
        }

        &--down {
          .header__cell-container {
            margin-top: 15px;
          }
        }
      }

      &__long-cell {
        &--down {
          .header__cell-container {
            margin-top: 15px;
          }
        }
      }
    }
  }
`;
