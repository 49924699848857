import { css } from 'styled-components';

export const appearingAnimation = () => css`
  animation-name: animateIn;
  animation-duration: 350ms;
  animation-delay: 0ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  @keyframes animateIn {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    100% {
      opacity: 1;
    }
  }
`;
