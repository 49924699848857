import { v4 as uuidv4 } from 'uuid';
import { getIdNameMock, getTerritoryMock } from '../common/__mocks__';
import { getAudienceAgeMock, getCampaignProviderMock, getGenderMock, getObjectiveMock } from './common/__mocks__';
import { getReferenceUserMock } from '../user/__mocks__';
import {
  Campaign,
  CampaignCategoryAndTypes,
  CampaignDetails,
  CampaignEditableFields,
  CampaignMetadata,
  CampaignPlatform,
  CampaignPlatformObjectType,
  CampaignPlatformsGroup,
  CampaignPlatformValue,
  CampaignSources,
  CampaignStatuses,
  Taxonomy,
} from './index';
import { getLinkfireSearch } from '../linkfire/__mocks__';
import { CampaignPlacement, CampaignPlatformWithPlacements, CampaignTypeAndCategory } from '..';
import { CampaignRequest } from './request';
import { parseDateByFormat, convertDateToUTC } from 'common-v2/utils';

export * from './common/__mocks__';
export * from './goal/__mocks__';

const getDefaultsCampaignTypeAndCategory = (): CampaignTypeAndCategory => ({
  ...getIdNameMock(),
  category: getIdNameMock(),
});

export const getCampaignTypeAndCategoryMock = (p?: Partial<CampaignTypeAndCategory>): CampaignTypeAndCategory => ({
  ...getDefaultsCampaignTypeAndCategory(),
  ...p,
});

const getDefaultsCampaignPlatform = (): CampaignPlatform => getIdNameMock();

export const getCampaignPlatformMock = (p?: Partial<CampaignPlatform>): CampaignPlatform => ({
  ...getDefaultsCampaignPlatform(),
  ...p,
});

const getDefaultsCampaignPlacement = (): CampaignPlacement => ({ ...getIdNameMock(), labelId: undefined });

export const getCampaignPlacementMock = (p?: Partial<CampaignPlacement>): CampaignPlacement => ({
  ...getDefaultsCampaignPlacement(),
  ...p,
});

const getDefaultsCampaignPlatformWithPlacements = (): CampaignPlatformWithPlacements => ({
  ...getIdNameMock(),
  defaultPlacements: [],
  children: [],
});

export const getCampaignPlatformWithPlacementsMock = (
  p?: Partial<CampaignPlatformWithPlacements>
): CampaignPlatformWithPlacements => ({
  ...getDefaultsCampaignPlatformWithPlacements(),
  ...p,
});

const defaultCampaignPlatformsGroup: CampaignPlatformsGroup = {
  ...getIdNameMock(),
  items: [],
  type: CampaignPlatformObjectType.GROUP,
};

export const getCampaignPlatformsGroupMock = (p?: Partial<CampaignPlatformsGroup>): CampaignPlatformsGroup => ({
  ...defaultCampaignPlatformsGroup,
  ...p,
});

const getDefaultsCampaignPlatformValue = (): CampaignPlatformValue => ({ ...getIdNameMock(), value: 100 });

export const getCampaignPlatformValueMock = (p?: Partial<CampaignPlatformValue>): CampaignPlatformValue => ({
  ...getDefaultsCampaignPlatformValue(),
  ...p,
});

const getDefaultsCampaignCategoryAndTypes = (): CampaignCategoryAndTypes => ({
  ...getIdNameMock(),
  campaignTypes: [getIdNameMock({ id: 0, name: 'type0' }), getIdNameMock({ id: 1, name: 'type1' })],
});

export const getCampaignCategoryAndTypesMock = (p?: Partial<CampaignCategoryAndTypes>): CampaignCategoryAndTypes => ({
  ...getDefaultsCampaignCategoryAndTypes(),
  ...p,
});

const getDefaultsTaxonomy = (): Taxonomy => ({
  categories: [
    getCampaignCategoryAndTypesMock({
      id: 0,
      name: 'category0',
      campaignTypes: [getIdNameMock({ id: 0, name: 'type00' }), getIdNameMock({ id: 1, name: 'type01' })],
    }),
    getCampaignCategoryAndTypesMock({
      id: 1,
      name: 'category1',
      campaignTypes: [getIdNameMock({ id: 2, name: 'type10' }), getIdNameMock({ id: 3, name: 'type11' })],
    }),
    getCampaignCategoryAndTypesMock({
      id: 2,
      name: 'category2',
      campaignTypes: [getIdNameMock({ id: 4, name: 'type20' }), getIdNameMock({ id: 5, name: 'type21' })],
    }),
    getCampaignCategoryAndTypesMock({
      id: 3,
      name: 'category3',
      campaignTypes: [getIdNameMock({ id: 6, name: 'type30' }), getIdNameMock({ id: 7, name: 'type31' })],
    }),
  ],
  platforms: [
    getCampaignPlatformsGroupMock({ id: 0, name: 'platform0', items: [] }),
    getCampaignPlatformsGroupMock({ id: 1, name: 'platform1', items: [] }),
    getCampaignPlatformsGroupMock({ id: 2, name: 'platform2', items: [] }),
    getCampaignPlatformsGroupMock({ id: 3, name: 'platform3', items: [] }),
  ],
  placements: [
    getCampaignPlacementMock({ id: 0, name: 'placement0', labelId: undefined }),
    getCampaignPlacementMock({ id: 1, name: 'placement1', labelId: '1' }),
    getCampaignPlacementMock({ id: 2, name: 'placement2', labelId: '2' }),
  ],
});

export const getTaxonomyMock = (p?: Partial<Taxonomy>): Taxonomy => ({
  ...getDefaultsTaxonomy(),
  ...p,
});

const getDefaultsCampaignEditableFields = (): CampaignEditableFields => ({
  platforms: true,
  name: true,
  provider: true,
  objective: true,
  budget: true,
  spend: true,
  startDate: true,
  endDate: true,
  genders: true,
  audienceAge: true,
  countries: true,
  destinations: true,
  notes: true,
});

export const getCampaignEditableFieldsMock = (p?: CampaignEditableFields): CampaignEditableFields => ({
  ...getDefaultsCampaignEditableFields(),
  ...p,
});

const getDefaultsCampaignMetadata = (): CampaignMetadata => ({
  fields: getCampaignEditableFieldsMock(),
});

export const getCampaignMetadataMock = (p?: Partial<CampaignMetadata>): CampaignMetadata => ({
  ...getDefaultsCampaignMetadata(),
  ...p,
});

const getDefaultsCampaignDetails = (): CampaignDetails => ({
  id: 51,
  uuid: uuidv4(),
  name: 'digital adv',
  budgetSpend: 15000,
  plannedBudget: 15000,
  projectId: 11,
  provider: getCampaignProviderMock(),
  genders: [getGenderMock()],
  audienceAge: getAudienceAgeMock(),
  createUser: getReferenceUserMock(),
  editDate: convertDateToUTC(parseDateByFormat('2019-10-15', 'yyyy-MM-dd')),
  editUser: getReferenceUserMock(),
  endDate: parseDateByFormat('2019-10-08', 'yyyy-MM-dd'),
  readOnly: false,
  startDate: parseDateByFormat('2019-10-07', 'yyyy-MM-dd'),
  territories: [getTerritoryMock()],
  objective: getObjectiveMock(),
  notes: undefined,
  phases: [333],
  platforms: [getCampaignPlatformMock()],
  type: getCampaignTypeAndCategoryMock(),
  gratis: false,
  destinationLinks: ['https://qa.decibel.stream/projects'],
  isPending: false,
  metadata: getCampaignMetadataMock(),
  externalId: undefined,
  externalName: undefined,
  source: CampaignSources.FACEBOOK,
  linkfireLinks: [getLinkfireSearch()],
  purchaseOrder: undefined,
  audienceNotes: 'details',
  adCreativeLinks: undefined,
  kpiMetricsFieldId: undefined,
  adCreativeNotes: 'copy',
  workflowStatus: { status: CampaignStatuses.DRAFT, availableTransitions: [] },
  ecpm: 0,
  placements: [],
  currency: 'EUR',
});

export const getCampaignDetailsMock = (p?: Partial<CampaignDetails>): CampaignDetails => ({
  ...getDefaultsCampaignDetails(),
  ...p,
});

const getDefaultsCampaign = (): Campaign => ({
  id: 51,
  uuid: uuidv4(),
  name: 'digital adv',
  externalId: 'id',
  platforms: [getCampaignPlatformMock()],
  type: getCampaignTypeAndCategoryMock(),
  gratis: false,
  adSetsCount: 1,
  endDate: parseDateByFormat('2019-10-08', 'yyyy-MM-dd'),
  startDate: parseDateByFormat('2019-10-07', 'yyyy-MM-dd'),
  budgetSpend: 1000,
  plannedBudget: 2000,
  linkfireLinks: [getLinkfireSearch()],
  isPending: false,
  source: CampaignSources.FACEBOOK,
});

export const getCampaignMock = (p?: Partial<Campaign>): Campaign => ({
  ...getDefaultsCampaign(),
  ...p,
});

const defaultsCampaignRequest: CampaignRequest = {
  name: 'campaign',
  territories: [],
  budgetSpend: 1000,
  ecpm: 10000,
  plannedBudget: 12345,
  campaignType: undefined,
  platforms: [],
  gratis: false,
  startDate: parseDateByFormat('2019-10-07', 'yyyy-MM-dd'),
  endDate: parseDateByFormat('2019-10-08', 'yyyy-MM-dd'),
  objective: undefined,
  notes: 'notes',
  genders: undefined,
  provider: undefined,
  audienceLowerAge: 18,
  audienceHigherAge: 60,
  destinationLinks: undefined,
  linkfireLinks: [],
  workflowStatus: undefined,
  audienceNotes: 'notes',
  adCreativeLinks: undefined,
  adCreativeNotes: undefined,
  kpiMetricsFieldId: undefined,
  placements: [],
};

export const getCampaignRequestMock = (p?: Partial<CampaignRequest>): CampaignRequest => ({
  ...defaultsCampaignRequest,
  ...p,
});
