import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { CARD_SIZE } from './constants';

export const BEM_CLASS = 'new-projects-container';

export const SNewProjectsContainer = styled.div`
  max-width: 1727px;
  width: 100%;
  margin: 0 auto;

  .${BEM_CLASS} {
    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &__title-icon {
      margin-right: 12px;
      color: ${transparentize(0.5, colorPalette.woodsmoke)};
    }

    &__grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 20px;
    }

    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      border-radius: 10px;
      background-color: ${transparentize(0.5, colorPalette.white)};
      height: ${CARD_SIZE}px;
      color: ${manhattanThemeColors.grayedText};
    }
  }
`;
