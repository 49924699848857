import { createSelector } from 'reselect';
import { CampaignState } from './reducer';

export const campaignSelector = state => state.campaign as CampaignState;

export const activeCampaignSelector = createSelector(campaignSelector, state => state.activeCampaign);

export const projectSelector = createSelector(campaignSelector, state => state.project);

export const assignedProjectSelector = createSelector(campaignSelector, state => state.project);

export const errorSelector = createSelector(campaignSelector, state => state.error);

export const isLoadingSelector = createSelector(campaignSelector, state => state.isLoading);

export const isConfirmSelector = createSelector(campaignSelector, state => state.confirmModal?.isOpened);

export const processingSelector = createSelector(campaignSelector, state => state.isProcessing);

export const phasesSelector = createSelector(campaignSelector, state => state.phases);

export const filtersSelector = createSelector(campaignSelector, state => state.filters);

export const adSetsSelector = createSelector(campaignSelector, state => state.adSets);

export const autogeneratedNameSelector = createSelector(campaignSelector, state => state.autogeneratedName);

//†selector
