import styled from 'styled-components';

export const BEM_CLASS = 'members-table';

export const SMembersTable = styled.div`
  .${BEM_CLASS} {
    &__artists-cell {
      height: unset;
    }
  }
`;
