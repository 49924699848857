import {
  ProjectStatus,
  Label,
  ArtistSearch,
  ArtistTeam,
  LabelUser,
  UpdateArtistTeam,
  ProjectDetails,
  Project,
} from 'backend-api/models';
import { FromSearchParam, ProjectFilterParams, ProjectSortField } from 'backend-api/types';
import { Sort } from 'utils/sort';

export enum ProjectsTab {
  InProgress = 'In Progress',
  Upcoming = 'Upcoming',
  Completed = 'Completed',
  Unassigned = 'Unclaimed',
}

export type TableBodyItem = Project | ProjectDetails;
export type TableBodyContent = TableBodyItem[];

export interface ProjectsFiltersByStatus {
  offset?: number;
  limit?: number;
  sort?: Sort<ProjectSortField>;
}

export interface ProjectsFilters {
  earliestStartDate?: Date;
  latestEndDate?: Date;
  status?: ProjectStatus;
  search?: string;
  filtersByStatus: Record<string, ProjectsFiltersByStatus>;
}

export interface GetArtistPayload {
  id: string;
  params?: FromSearchParam;
}

export interface GetArtistProjectsPayload {
  id: string;
  params: ProjectFilterParams;
}

export interface PermissionsState {
  isNotificationVisible: boolean;
  isNotificationActive: boolean;
  unavailableLabels: Label[];
}

export interface SharingModal {
  labelUsers: LabelUser[];
  error?: string;
  isOpened: boolean;
}

export interface ExportArtistProjectsPayload extends GetArtistProjectsPayload {
  name: string;
  status?: string;
}

export interface ArtistInfo {
  info: ArtistSearch;
  teams: ArtistTeam[];
}

export interface CreateArtistTeamPayload extends UpdateArtistTeam {
  artistId: string;
  onEditSuccess(): void;
}
