import React from 'react';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { UnclaimedProjectView } from 'project-v2/components';
import ShareAndConnectSrc from 'assets/share-and-connect.png';

export const UnclaimedProjectWithLockedArtistContactAdmin = React.memo(() => (
  <UnclaimedProjectView
    title={
      <Typography type={TYPOGRAPHY_TYPE.body1} data-selector="unclaimed-project-title">
        Claim This Project
      </Typography>
    }
    description={
      <Typography type={TYPOGRAPHY_TYPE.body2} data-selector="unclaimed-project-description">
        To begin working on this project, please contact the project admin
        <br />
        to create an artist team first.
      </Typography>
    }
    imageSrc={ShareAndConnectSrc}
  />
));
