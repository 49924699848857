import { Direction } from './dropdown';
import { DROPDOWN_VERTICAL_GAP, DEFAULT_SCREEN_OFFSET } from './constants';

export const getDropdownDirection = (rect: DOMRect, dropdownWidth: number, dropdownHeight: number, window: Window) => {
  const isDirectionTop =
    rect.y + rect.height + dropdownHeight + DROPDOWN_VERTICAL_GAP * 2 + DEFAULT_SCREEN_OFFSET > window.innerHeight;
  const isDirectionLeft = rect.x - rect.width > dropdownWidth + DEFAULT_SCREEN_OFFSET;

  if (isDirectionTop && isDirectionLeft) {
    return Direction.TopLeft;
  } else if (isDirectionTop && !isDirectionLeft) {
    return Direction.TopRight;
  } else if (!isDirectionTop && isDirectionLeft) {
    return Direction.BottomLeft;
  }

  return Direction.BottomRight;
};

export const getDropdownPosition = (
  rect: DOMRect,
  dropdownWidth: number,
  dropdownHeight: number,
  window: Window,
  initialDirection?: Direction
) => {
  const direction = initialDirection || getDropdownDirection(rect, dropdownWidth, dropdownHeight, window);

  const isDirectionTop = direction === Direction.TopLeft || direction === Direction.TopRight;
  const isDirectionLeft = direction === Direction.TopLeft || direction === Direction.BottomLeft;

  return {
    top: isDirectionTop
      ? rect.y + window.scrollY - dropdownHeight - DROPDOWN_VERTICAL_GAP
      : rect.y + rect.height + window.scrollY + DROPDOWN_VERTICAL_GAP,
    left: isDirectionLeft ? rect.x + rect.width - dropdownWidth : rect.x,
  };
};
