import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';
import { MODAL_BORDER_RADIUS } from '../../constants';

export const SContent = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 2px;
  background-color: ${transparentize(0.1, manhattanThemeColors.background)};
  border-radius: ${MODAL_BORDER_RADIUS}px;
  box-shadow: 0 5px 20px 0 ${transparentize(0.8, manhattanThemeColors.primaryDark)};
  border: 2px solid ${transparentize(0.6, manhattanThemeColors.primaryFill)};
`;
