import styled from 'styled-components';
import { theme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';
import { popup } from 'app/styles/z-indexes';

interface Props {
  isVisible: boolean;
}

export const BEM_CLASS = 'confirmation-modal';

export const SConfirmationModal = styled.div<Props>`
  position: fixed;
  z-index: ${popup};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  overflow-x: hidden;
  overflow-y: auto;

  .${BEM_CLASS} {
    &__overlay {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      background-color: ${colorPalette.vulcan};
      opacity: 0.4;
    }

    &__wrapper {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      width: 400px;
      margin: auto;
      background-color: ${colorPalette.white};
      border-radius: 10px;
      box-shadow: ${theme.shadows.default};
      overflow: hidden;
    }

    &__container {
      display: flex;
      align-items: flex-start;
      margin-bottom: 18px;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px 25px 0;
    }

    &__text {
      margin-bottom: 12px;
    }

    &__close {
      flex-shrink: 0;
      display: flex;
      padding: 10px 12px;

      &:focus {
        background-color: ${colorPalette.ghostWhite};
      }
    }

    &__actions {
      display: flex;
      justify-content: center;
      margin-top: auto;
      padding: 0 20px 30px;
    }

    &__action {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
`;
