import { manhattanBackground } from 'common-v2/styles';
import styled from 'styled-components';

export const BEM_CLASS = 'settings-container';

export const SSettingsContainer = styled.div`
  .${BEM_CLASS} {
    &__background {
      ${manhattanBackground};
    }

    &__container {
      display: flex;
      flex-direction: column;
      padding: 0 40px;
      width: 100%;
      gap: 20px;
    }

    &__title {
      margin-top: 20px;
    }
  }
`;
