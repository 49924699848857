import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { BEM_CLASS, SBudgetInputEditor } from './s-budget-input-editor';
import { InputNumber } from 'common/components/form/input-number';
import { bem } from 'utils/bem';
import { useEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan-v2/components/spreadsheet/hooks';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';

const classes = bem(BEM_CLASS);

interface Props extends ICellEditorParams<SpreadsheetRowData, SpreadsheetRowData['budget']> {
  fixedDecimalScale?: boolean;
}

export const BudgetInputEditor = React.memo(
  React.forwardRef((props: Props, ref) => {
    const { colDef, api, charPress, fixedDecimalScale } = props;
    const initialValue = charPress ? '' : props.value.value;
    const [value, setValue] = useState(initialValue);
    const inputRef = useRef<HTMLInputElement>(null);
    const { onKeyDown } = useEditorKeyboardInteractions({ api, value });
    const { onBlur } = useStopEditingBlur(api);

    const currencyCode = useMemo<string>(() => colDef?.cellEditorParams.currencyCode, [
      colDef?.cellEditorParams.currencyCode,
    ]);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return {
            ...props.value,
            value,
          };
        },
      };
    });

    const handleChange = useCallback(value => setValue(value === undefined ? null : value), []);

    return (
      <SBudgetInputEditor>
        <InputNumber
          ref={inputRef}
          onChange={handleChange}
          value={value}
          className={classes('input')}
          shouldUseCurrencyFormat
          allowEmptyFormatting
          decimalScale={2}
          allowNegative={false}
          autoFocus
          fixedDecimalScale={value !== null && value !== undefined && fixedDecimalScale}
          currencyCode={currencyCode}
          dataSelector={colDef?.cellEditorParams.dataSelector}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
      </SBudgetInputEditor>
    );
  })
);
