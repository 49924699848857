import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import { CampaignPlatformValueSchema } from '../campaign';
import { ResponseModel, IdNameField, FormattedDateFromISOString, IdType, NonEmptyString, Optional } from '../common';
import { PlaylistSchema } from '../playlist';
import { ProductFamilySchema } from '../product-family';

export const PhaseCampaignSchema = ResponseModel(
  {
    ...IdNameField,
    uuid: UUID,
    startDate: FormattedDateFromISOString,
    endDate: FormattedDateFromISOString,
    isEditable: t.boolean,
    isExternal: t.boolean,
  },
  'Phase'
);

export type PhaseCampaign = t.TypeOf<typeof PhaseCampaignSchema>;

export const PhaseSchema = ResponseModel(
  {
    ...IdNameField,
    order: t.number,
    project_id: t.number,
    start_date: FormattedDateFromISOString,
    end_date: FormattedDateFromISOString,
    campaigns: t.array(PhaseCampaignSchema),
    playlists: t.array(PlaylistSchema),
    product_families: t.array(ProductFamilySchema),
    estimated_impression: Optional(t.number),
    budget: Optional(t.number),
    objective_text: Optional(t.string),
    campaign_platforms: t.array(CampaignPlatformValueSchema),
  },
  'Phase'
);

export type Phase = t.TypeOf<typeof PhaseSchema>;

export type IndexablePhase = Phase & { index: number };

export const PhaseRequestSchema = ResponseModel(
  {
    name: NonEmptyString,
    start_date: FormattedDateFromISOString,
    playlists: t.array(PlaylistSchema),
    tracks: t.array(IdType),
    objective_text: Optional(t.string),
  },
  'PhaseRequest'
);

export type PhaseRequest = t.TypeOf<typeof PhaseRequestSchema>;
