import { SLoaderCategory } from './s-loader-category';
import React from 'react';
import { bem } from 'utils/bem';

const classes = bem('loader-category');

export const LoaderCategory = () => {
  return (
    <SLoaderCategory>
      <div className={classes('clip-container')} />
      <svg width="0" height="0">
        <defs>
          <clipPath id="loader-category">
            <rect x="0" y="0" rx="0" ry="0" width={150} height={15} />
          </clipPath>
        </defs>
      </svg>
    </SLoaderCategory>
  );
};
