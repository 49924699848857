import React from 'react';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { BaseOption as BaseOptionType } from '../../types';
import { SMultiValueContainer } from './s-multi-value-container';
import { MultiValueGenericProps } from 'react-select';

type Props<T extends BaseOptionType> = MultiValueGenericProps<T, true>;

const Root = <T extends BaseOptionType>({ selectProps, ...props }: Props<T>) => {
  const { classNames, dataSelector } = getBaseCustomSelectProps(selectProps);

  return (
    <SMultiValueContainer
      {...props}
      ref={null}
      className={classNames.multiValueContainer}
      data-selector={`${dataSelector}-multi-value-container`}
    />
  );
};

export const MultiValueContainer = React.memo(Root) as typeof Root;
