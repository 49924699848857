import styled from 'styled-components';
import { WaveBackground } from 'common/components/wave-background';

export const BEM_CLASS = 'artist-roster';

export const SArtistRoster = styled(WaveBackground)`
  padding: 30px 0;

  .${BEM_CLASS} {
    &__control {
      margin-bottom: 20px;
    }

    &__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
    }
  }
`;
