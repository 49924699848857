import React, { useEffect, useMemo, useState } from 'react';
import { ValidationTooltipContent } from '../components';
import { getIsScheduleInvalid } from 'media-plan-v2/containers/spreadsheet/transducers';

interface UseScheduleValidationParams {
  startDate?: string | null;
  endDate?: string | null;
  tooltipText: string;
  dateFormat?: string;
}

export const useScheduleValidation = ({
  startDate,
  endDate,
  tooltipText,
  dateFormat,
}: UseScheduleValidationParams): [boolean, React.ReactChild | React.ReactChild[]] => {
  const [isValueInvalid, setIsValueInvalid] = useState(getIsScheduleInvalid(startDate, endDate, dateFormat));

  useEffect(() => {
    setIsValueInvalid(getIsScheduleInvalid(startDate, endDate, dateFormat));
  }, [endDate, startDate, dateFormat]);

  const tooltipContent = useMemo(() => <ValidationTooltipContent text={tooltipText} />, [tooltipText]);

  return [isValueInvalid, tooltipContent];
};
