import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const SSectionTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  .section-title {
    &__counter,
    &__title {
      text-transform: uppercase;
      margin: 10px 0;
    }
    &__title {
      display: flex;
      aling-items: flex-start;
    }
    &__counter {
      color: ${colorPalette.logan};
    }
    &__expand {
      width: 15px;
      height: 15px;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(0.25turn);
      transition: transform 0.3s ease-out;

      &--expanded {
        transform: rotate(-0.25turn);
      }
    }
    &__warning-icon {
      flex-shrink: 0;
      display: flex;
      align-self: center;
      margin: -3px 0 0 10px;
    }
  }
`;
