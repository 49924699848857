import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const SClearAllButton = styled.button`
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 15px;
  text-align: center;
  color: black;
  text-transform: uppercase;

  :hover {
    color: ${colorPalette.ceriseRed};
  }
`;
