import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { noop } from 'lodash';
import { closeConfirmModal, openConfirmModal } from 'media-plan/actions';
import { WithConfirmPayload } from 'media-plan/types';

export const useConfirm = () => {
  const dispatch = useDispatch();

  const [confirmAction, setConfirmAction] = useState<(allow: boolean) => void>(() => noop);

  const withConfirm = useCallback(
    ({ text, action }: WithConfirmPayload) => {
      dispatch(openConfirmModal(text));

      setConfirmAction(() => (allow: boolean) => {
        if (allow) {
          action();
        }

        dispatch(closeConfirmModal());
      });
    },
    [dispatch]
  );

  return { withConfirm, confirmAction };
};
