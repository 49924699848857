import { isObject } from 'lodash';
import { CampaignDetails } from '.';

export * from './common/guards';

export const isCampaign = (v): v is CampaignDetails =>
  isObject(v as CampaignDetails) &&
  v.endDate !== undefined &&
  v.startDate !== undefined &&
  v.readOnly !== undefined &&
  v.isPending !== undefined &&
  v.metadata !== undefined &&
  v.platforms !== undefined;
