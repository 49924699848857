import FileSaver from 'file-saver';
import { forkJoin, merge, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { asyncEpic, ofType } from 'core/epics';
import { Api } from 'backend-api';
import {
  EXPORT_FILENAME_DATE_FORMAT,
  IN_PROGRESS_STATUS,
  SHORT_DATE_REQUEST_FORMAT,
  UPCOMING_STATUS,
} from 'common/constants';
import {
  exportAllProjectsCsv,
  getAllProjects,
  getArtistRoster,
  getLiveArtistTeams,
  getUpcomingArtistTeams,
  getExpansionLiveProjects,
  getExpansionUpcomingProjects,
  getProjectsSnapshots,
  getRecentInfo,
  getUserProjectsCount,
  getCurrentLabel,
  getAllUnassignedProjects,
} from './actions';
import { filtersSelector } from './selectors';
import { getProjectFiltersParamsFromFilters } from './transducers';
import { MetricsReportingParams, MetricsReportingType } from 'backend-api/types';
import { ProjectSnapshotParams } from './types';
import { getFormattedDate, substractDaysFromDate, getEndOfDay } from 'common-v2/utils';

export const getRecentInfoEpic = asyncEpic(getRecentInfo, () => {
  return forkJoin([Api.getRecentProjects(), Api.getUpcomingProjects(), Api.getTeamActivity()]).pipe(
    map(result => ({
      recentProjects: result[0],
      upcomingProjects: result[1],
      teamActivity: result[2],
    }))
  );
});

export const getAllProjectsEpic = asyncEpic(getAllProjects, action => Api.getProjects(action.payload));

export const exportAllProjectsCsvEpic = asyncEpic(exportAllProjectsCsv, (action, state) => {
  const filters = filtersSelector(state);
  const params = getProjectFiltersParamsFromFilters(filters);
  const formatDate = getFormattedDate(EXPORT_FILENAME_DATE_FORMAT);
  const now = formatDate(new Date());

  return Api.exportAllProjectsCsv(params).pipe(
    tap(data => FileSaver.saveAs(new Blob([data]), `${now}_All_Projects.csv`))
  );
});

export const getUserProjectsCountEpic = asyncEpic(getUserProjectsCount, () =>
  Api.getUserInfo().pipe(map(user => user.projectsCount))
);

export const getArtistRosterEpic = asyncEpic(getArtistRoster, action => Api.getArtistRoster(action.payload));

export const getDefaultLabelEpic = asyncEpic(getCurrentLabel, () => Api.getDefaultLabel());

export const getLiveArtistTeamsEpic = asyncEpic(getLiveArtistTeams, action => Api.getArtistTeams(action.payload));

export const getUpcomingArtistTeamsEpic = asyncEpic(getUpcomingArtistTeams, action =>
  Api.getArtistTeams(action.payload)
);

export const getExpansionLiveProjectsEpic = asyncEpic(getExpansionLiveProjects, ({ payload }) =>
  Api.getArtistRosterProjects(payload.id, { ...payload.params, statuses: [IN_PROGRESS_STATUS.id, UPCOMING_STATUS.id] })
);
export const getAllUnassignedProjectsEpic = asyncEpic(getAllUnassignedProjects, action =>
  Api.getUnassignedProjects(action.payload)
);

export const getExpansionUpcomingProjectsEpic = asyncEpic(getExpansionUpcomingProjects, ({ payload }) =>
  Api.getArtistRosterProjects(payload.id, { ...payload.params, statuses: [UPCOMING_STATUS.id] })
);

export const getProjectsSnapshotsEpic = (action$: Observable<any>) => {
  return action$.pipe(
    ofType(getProjectsSnapshots.request),
    switchMap(action => {
      const getProjectMetricsReporting = (params: ProjectSnapshotParams) => {
        // for project snapshot, we need to load metrics for a doubled period of days to calculate metric values delta relative to a previous period
        const periodDays = params.period * 2;

        const formatDate = getFormattedDate(SHORT_DATE_REQUEST_FORMAT);
        const startDate = getEndOfDay(substractDaysFromDate(new Date(), periodDays));
        const endDate = substractDaysFromDate(new Date(), 1);

        const reportingParams: MetricsReportingParams = {
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
          territoryId: 0,
        };

        return Api.getProjectMetricsReporting(params.projectId, reportingParams).pipe(
          mergeMap(data => [
            getProjectsSnapshots.success({
              projectId: params.projectId,
              metrics: data.items,
            }),
          ]),
          catchError(error => {
            return of(
              getProjectsSnapshots.failure({
                ...error,
                type: MetricsReportingType.STREAMS,
              })
            );
          })
        );
      };

      const requests = action.payload.map(params => getProjectMetricsReporting(params));

      return merge(...requests);
    })
  );
};
