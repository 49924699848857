import React, { useMemo } from 'react';
import { ArtistSearch, Playlist, PlaylistSchema } from 'backend-api/models';
import { bem } from 'utils/bem';
import { Checkbox } from 'common/components/checkbox';
import { Icon } from 'common/components/icon';
import { Tooltip } from 'common/components/tooltip';
import { TargetsImages } from 'common/components/targets-images';
import { getTargetsLimitMessage, iconByProvider } from 'project/transducers';
import { SArtistPlaylistItem } from './s-artist-playlist-item';

interface Props {
  target: ArtistSearch | Playlist;
  onSelect: (value: boolean) => void;
  isSelected?: boolean;
  canSelect?: boolean;
  customLimitMessage?: string;
  isLocked?: boolean;
}

const classes = bem('artist-playlist-item');

export const ArtistPlaylistItem = React.memo(
  ({ target, onSelect, isSelected, canSelect, customLimitMessage, isLocked = false }: Props) => {
    const icon = useMemo(() => (PlaylistSchema.is(target) ? iconByProvider(target.provider) : undefined), [target]);
    const owner = useMemo(() => (PlaylistSchema.is(target) ? target.ownerName : undefined), [target]);
    const limitMessage = useMemo(() => customLimitMessage || getTargetsLimitMessage(PlaylistSchema.is(target)), [
      customLimitMessage,
      target,
    ]);

    const isCheckBoxDisabled = !canSelect && !isSelected;
    const checkBoxComponent = useMemo(
      () => (
        <div className={classes('checkbox')} data-selector="target-item-checkbox">
          <Checkbox isChecked={isSelected} onClick={onSelect} isDisabled={isCheckBoxDisabled} />
        </div>
      ),
      [isSelected, onSelect, isCheckBoxDisabled]
    );

    return (
      <SArtistPlaylistItem data-selector="project-target-item">
        <TargetsImages targets={target} className={classes('images-container')} />
        <div className={classes('info-container')}>
          <div data-selector="project-target-item-name" className={classes('name')}>
            {target.name}
          </div>
          {owner && <div className={classes('owner-name')}>{owner}</div>}
        </div>
        {icon && <Icon name={icon} className={classes('icon')} size="general" />}
        {isLocked ? (
          <Tooltip content="The Primary Artist listed in PRS cannot be removed from this project.">
            <span className={classes('lock-icon')}>
              <Icon name="blocked" size="general" />
            </span>
          </Tooltip>
        ) : (
          <Tooltip enabled={isCheckBoxDisabled} content={limitMessage}>
            {checkBoxComponent}
          </Tooltip>
        )}
      </SArtistPlaylistItem>
    );
  }
);
