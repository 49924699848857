import React, { FC, PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { HeadStaticTitles } from './constants';

interface HeadProps {
  title?: HeadStaticTitles | string;
}

export const Head: FC<PropsWithChildren<HeadProps>> = React.memo(({ children, title = 'Decibel' }) => (
  <Helmet>
    <title>{title}</title>
    {children}
  </Helmet>
));
