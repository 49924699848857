import styled from 'styled-components';

export const BEM_CLASS = 'menu-footer';

export const SMenuFooter = styled.div`
  width: 100%;
  padding-top: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .${BEM_CLASS} {
    &__button {
      flex: 1;
      margin: 0 10px;
    }
  }
`;
