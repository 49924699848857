import styled from 'styled-components';
import { typography } from 'app/theme/typography';
import { StylesConfig } from 'react-select';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'badge-selector';

export const SelectStyles: StylesConfig = {
  container: provided => ({
    ...provided,
    maxWidth: '100%',
  }),
  menu: provided => ({
    ...provided,
    minWidth: '120px !important',
    width: 'auto',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    display: state.selectProps.isDisabled ? 'none' : 'block',
    top: '5px !important',
    right: '7px !important',
    color: 'white',
    '&:hover': {
      color: 'white',
    },
  }),
};

interface Props {
  color?: string;
}

export const SBadgeSelector = styled.div<Props>`
  .${BEM_CLASS} {
    &__control {
      border-radius: 3px;
      background-color: ${({ color }) => (color ? color : colorPalette.eastBay)};
      padding-left: 5px;
      max-height: 23px;
      min-height: 23px;
      padding-right: 0;
    }
    &__value {
      ${elipsizeText};
      ${typography.primaryLabel};
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0;
    }
  }
`;
