import React from 'react';
import { bem } from 'utils/bem';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { WaveBackground } from 'common/components/wave-background';
import { SPage, BEM_CLASS } from './s-page';
import { Footer } from 'app/components/footer';

const classes = bem(BEM_CLASS);

interface PageProps {
  filters?: React.ReactElement;
  content: React.ReactElement;
  isCentered?: boolean;
  isFullHeight?: boolean;
}

export const Page = React.memo(({ filters, content, isCentered = false, isFullHeight = false }: PageProps) => (
  <SPage isFullHeight={isFullHeight}>
    <WaveBackground className="app__wrapper">
      <ResponsiveContainer className={classes('container', { centered: isCentered })}>
        <div className={classes('wrapper')}>
          {filters && <div className={classes('filters')}>{filters}</div>}
          {content}
        </div>
      </ResponsiveContainer>
      <Footer />
    </WaveBackground>
  </SPage>
));
