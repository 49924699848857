import styled, { css } from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { PADDING } from '../../constants';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

interface Props {
  hasSeparator: boolean;
  isFirst: boolean;
  isLast: boolean;
}

export const BEM_CLASS = 'base-group';

export const SBaseGroup = styled.div<Props>`
  position: relative;
  padding-top: ${({ isFirst }) => !isFirst && '8px'};
  padding-bottom: ${({ isLast }) => !isLast && '8px'};

  ${({ hasSeparator }) =>
    hasSeparator &&
    css`
      margin-bottom: 1px;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: ${PADDING}px;
        display: block;
        width: calc(100% - 40px);
        height: 1px;
        background-color: ${colorPalette.lavenderGray};
      }
    `}


  .${BEM_CLASS} {
    &__label {
      display: block;
      padding: 0 ${PADDING}px;
      margin-bottom: 8px;
      color: ${colorPalette.eastBay};
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 1px;
      text-transform: uppercase;
      ${elipsizeText};
    }
  }
`;
