import { ApiError } from 'backend-api';
import { AdSetsResponse, CampaignDetails, Phase, ProjectDetails } from 'backend-api/models';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { createTypedReducer, onAction } from 'core/store';
import {
  createCampaign,
  deleteCampaign,
  editCampaign,
  generateCampaignName,
  getAdSets,
  getCampaign,
  getCampaignProject,
  getPhases,
  getUnassignedCampaign,
  resetCampaign,
  setProcessing,
  showConfirmModal,
  unassignCampaign,
  updateFilters,
} from './actions';
import { AdSetsFilters, ConfirmModalState } from './types';

export interface CampaignState {
  activeCampaign?: CampaignDetails;
  confirmModal: ConfirmModalState;
  phases: Phase[];
  project?: ProjectDetails;
  isLoading?: boolean;
  isProcessing: boolean;
  error?: ApiError<ProjectDetailsErrorResponse>;
  adSets?: AdSetsResponse;
  filters: AdSetsFilters;
  autogeneratedName?: string;
}

export const initialState: CampaignState = {
  activeCampaign: undefined,
  confirmModal: {
    id: undefined,
    isOpened: false,
  },
  phases: [],
  filters: {},
  isProcessing: false,
};

const processCampaign = (state: CampaignState) => ({
  ...state,
  isProcessing: true,
});

const processCampaignSuccess = state => ({
  ...state,
  isProcessing: true,
});

const processCampaignError = state => ({
  ...state,
  isProcessing: false,
});

export const reducer = createTypedReducer<CampaignState>(
  initialState,
  onAction(getCampaign.request, state => ({
    ...state,
    isLoading: true,
  })),
  onAction(getCampaign.success, (state, action) => ({
    ...state,
    activeCampaign: action.payload,
    isLoading: false,
  })),
  onAction(getUnassignedCampaign.success, (state, action) => ({
    ...state,
    activeCampaign: action.payload,
    isLoading: false,
  })),
  onAction(getUnassignedCampaign.failure, (state, action) => ({
    ...state,
    error: action.payload,
  })),
  onAction(showConfirmModal, (state, action) => ({
    ...state,
    confirmModal: action.payload,
  })),
  onAction(resetCampaign, state => ({
    ...state,
    activeCampaign: undefined,
    project: undefined,
    error: undefined,
    adSets: undefined,
    filters: {},
  })),
  onAction(editCampaign.request, state => processCampaign(state)),
  onAction(editCampaign.success, state => processCampaignSuccess(state)),
  onAction(editCampaign.failure, state => processCampaignError(state)),
  onAction(deleteCampaign.request, state => processCampaign(state)),
  onAction(deleteCampaign.success, state => processCampaignSuccess(state)),
  onAction(deleteCampaign.failure, state => processCampaignError(state)),
  onAction(unassignCampaign.request, state => processCampaign(state)),
  onAction(unassignCampaign.success, state => processCampaignSuccess(state)),
  onAction(unassignCampaign.failure, state => processCampaignError(state)),
  onAction(createCampaign.request, state => processCampaign(state)),
  onAction(createCampaign.success, state => processCampaignSuccess(state)),
  onAction(createCampaign.failure, state => processCampaignError(state)),
  onAction(getCampaignProject.success, (state, action) => ({
    ...state,
    project: action.payload,
  })),
  onAction(getCampaignProject.failure, (state, action) => ({
    ...state,
    error: action.payload,
  })),
  onAction(getPhases.request, state => ({
    ...state,
    isLoading: true,
  })),
  onAction(getPhases.success, (state, action) => ({
    ...state,
    isLoading: false,
    phases: action.payload,
  })),
  onAction(getPhases.failure, state => ({
    ...state,
    isLoading: false,
  })),
  onAction(updateFilters, (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      ...action.payload,
    },
  })),
  onAction(getAdSets.request, state => ({
    ...state,
    isLoading: state.adSets?.items.length === 0,
  })),
  onAction(getAdSets.success, (state, action) => ({
    ...state,
    isLoading: false,
    adSets: action.payload,
  })),
  onAction(setProcessing, (state, { payload }) => ({
    ...state,
    isProcessing: payload,
  })),
  onAction(generateCampaignName.success, (state, action) => ({
    ...state,
    autogeneratedName: action.payload,
  }))
);
//†reducer
