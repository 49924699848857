import { useDispatch, useSelector } from 'react-redux';
import { getCampaignProviders, getPerformanceCampaignProviders } from 'common/actions';
import { campaignProvidersSelector, performanceCampaignProvidersSelector } from 'common/selectors';
import { useEffect } from 'react';
import { UUID } from 'io-ts-types/lib/UUID';

export const useCampaignProviders = (uuid?: UUID) => {
  const dispatch = useDispatch();
  const campaignProviders = useSelector(campaignProvidersSelector);
  const performanceCampaignProviders = useSelector(performanceCampaignProvidersSelector);

  useEffect(() => {
    if (uuid) {
      dispatch(getCampaignProviders.request(uuid));
    } else {
      dispatch(getPerformanceCampaignProviders.request());
    }
  }, [dispatch, uuid]);

  return uuid ? campaignProviders : performanceCampaignProviders;
};
