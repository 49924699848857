import React, { SyntheticEvent } from 'react';
import { Icon } from './icon';
import { IconColor, IconSize } from './icon/types';
import { getCampaignCategoryColor, getCampaignCategory, getCampaignCategoryIconName } from '../transducers';
import { CampaignCategory, CampaignCategoryAndTypes } from 'backend-api/models';

interface Props {
  campaignTypeId?: number;
  campaignCategory?: CampaignCategory;
  campaignCategories?: CampaignCategoryAndTypes[];
  disabled?: boolean;
  size?: IconSize;
  style?: React.CSSProperties;
  onClick?(event: SyntheticEvent): void;
  dataSelector?: string;
  className?: string;
  iconColor?: IconColor;
}

export const CampaignIcon = React.memo(
  ({
    disabled,
    campaignCategory,
    campaignCategories,
    campaignTypeId,
    size = 'general',
    iconColor,
    ...props
  }: Props) => {
    const category = campaignCategory || getCampaignCategory(campaignTypeId, campaignCategories);

    const name = getCampaignCategoryIconName(category);
    const color = iconColor ?? getCampaignCategoryColor(category?.id);

    if (!name) return null;

    return <Icon color={disabled ? undefined : color} size={size} name={name} {...props} />;
  }
);
