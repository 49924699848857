export interface PurchaseOrderFilterParams {
  readonly provider?: number;
  readonly offset?: number;
  readonly limit?: number;
  readonly sort?: string;
  readonly search?: string;
  readonly status?: number;
}

export const PURCHASE_ORDER_SORT_FIELDS = ['id', 'name', 'status', 'total', 'paid', 'createdAt', 'modifiedAt'] as const;

export type PurchaseOrderSortField = typeof PURCHASE_ORDER_SORT_FIELDS[number];

export const isPurchaseOrderSortField = (value: unknown): value is PurchaseOrderSortField => {
  return PURCHASE_ORDER_SORT_FIELDS.includes(value as any);
};
