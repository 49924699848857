import { Popper } from 'common/components/popper';
import { Input, THEME, Tooltip } from 'gdb-web-shared-components';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { bem } from 'utils/bem';
import { Suggestions } from '../suggestions';
import { SSearchInput, BEM_CLASS, SSearchInputTooltip, TOOLTIP_BEM_CLASS } from './s-search-input';

interface SearchInputProps {
  value?: string;
  isDisabled?: boolean;
  suggestedNodes: React.ReactNode[];
  onSearchChange: React.ChangeEventHandler<HTMLInputElement>;
  clearSearch(): void;
  className?: string;
}

const classes = bem(BEM_CLASS);
const tooltipClasses = bem(TOOLTIP_BEM_CLASS);

export const SearchInput = React.memo(
  ({ value = '', isDisabled = false, suggestedNodes, onSearchChange, clearSearch, className }: SearchInputProps) => {
    const inputRef = useRef<HTMLDivElement>(null);
    const [inputWidth, setInputWidth] = useState(0);
    const isSearchEmpty = value.length === 0;
    useLayoutEffect(() => setInputWidth(inputRef?.current?.clientWidth || 0), [isSearchEmpty]);

    return (
      <SSearchInput className={className} data-selector="edit-artists-modal-search-input">
        <Popper
          content={<Suggestions width={inputWidth} suggestedNodes={suggestedNodes} />}
          placement="bottom-start"
          visible={value.length > 0}
          offset={[0, 5]}
          maxWidth="none"
          onClickOutside={clearSearch}
        >
          <div className={classes('input-container')} ref={inputRef}>
            <SSearchInputTooltip>
              {tooltipClassName => (
                <Tooltip
                  tooltip="You reached artists limit. Remove a selection to add a new one."
                  isDisabled={!isDisabled}
                  className={classes('input-tooltip')}
                  popperClassName={tooltipClassName}
                  contentClassName={tooltipClasses('content')}
                >
                  <Input
                    inputClassName={classes('input')}
                    disabled={isDisabled}
                    theme={THEME.light}
                    value={value}
                    placeholder="Search Artist to add to the List"
                    onChange={onSearchChange}
                    isClearable={value.length > 0}
                    onClearClick={clearSearch}
                    icon="search"
                  />
                </Tooltip>
              )}
            </SSearchInputTooltip>
          </div>
        </Popper>
      </SSearchInput>
    );
  }
);
