import React, { useMemo } from 'react';
import { SPurchaseOrdersHeader, BEM_CLASS } from './s-purchase-orders-header';
import { H4 } from 'common/components/typography';
import { Input } from 'common/components/form/input';
import { Button } from 'common/components/button';
import { Icon } from 'common/components/icon';
import { ALL_PROVIDERS_OPTION, ALL_STATUSES_SELECT_OPTION } from '../../constants';
import { bem } from 'utils/bem';
import { PURCHASE_ORDERS_STATUSES } from 'common/constants';
import { BgStyle } from 'common/styles/utils';
import { ALL_OPTION_ID } from 'common/components/form/select/constants';
import { BaseGroup, MenuPlacement, Select, SelectType } from 'common/components/select';
import { PurchaseOrdersProvider } from 'backend-api/models';

interface Props {
  search?: string;
  onSearchChange(search: string): void;
  onExportClick?(): void;
  providers: PurchaseOrdersProvider[];
  onProviderChange(id: number): void;
  selectedProvider: number;
  selectedStatus: number;
  onStatusChange(status: number): void;
  isBlanketPOTableHeader?: boolean;
  purchaseOrdersCount?: number;
  isTableEmpty: boolean;
}

const classes = bem(BEM_CLASS);

export const PurchaseOrdersHeader = React.memo(
  ({
    search,
    onSearchChange,
    onExportClick,
    providers,
    onProviderChange,
    selectedProvider,
    selectedStatus,
    onStatusChange,
    isBlanketPOTableHeader = false,
    purchaseOrdersCount,
    isTableEmpty,
  }: Props) => {
    const bgStyle: BgStyle = isBlanketPOTableHeader ? 'grey' : 'white';
    const title =
      isBlanketPOTableHeader && purchaseOrdersCount !== undefined
        ? `Purchase Orders (${purchaseOrdersCount})`
        : 'Purchase Orders';

    const selectorClassNames = useMemo(
      () => ({
        control: classes('selector-control', { isGray: isBlanketPOTableHeader }),
      }),
      [isBlanketPOTableHeader]
    );

    const statusSelectorOptions = useMemo<BaseGroup[]>(
      () => [
        { options: [ALL_STATUSES_SELECT_OPTION], isFirst: true },
        { options: PURCHASE_ORDERS_STATUSES, isLast: true },
      ],
      []
    );

    const providerSelectorOptions = useMemo<BaseGroup[]>(() => {
      const mappedProviders = providers.map(({ id, name }) => ({ id, value: name }));

      return [
        { options: [ALL_PROVIDERS_OPTION], isFirst: true },
        { options: mappedProviders, isLast: true },
      ];
    }, [providers]);

    return (
      <SPurchaseOrdersHeader>
        <H4 className={classes('title')}>{title}</H4>
        <Input
          bgStyle={bgStyle}
          iconLeft="search"
          clearable
          className={classes('search')}
          disabled={false}
          placeholder="Search by PO name and #"
          dataSelector="search-purchase-orders-input"
          initialValue={search}
          onChange={onSearchChange}
        />
        {!isBlanketPOTableHeader && (
          <Select
            type={SelectType.Base}
            valueId={selectedStatus}
            valueHandler={onStatusChange}
            options={statusSelectorOptions}
            classNames={selectorClassNames}
            isDisabled={isTableEmpty && selectedStatus === ALL_OPTION_ID}
            placement={MenuPlacement.BottomRight}
            dataSelector="select-purchase-orders-status-dropdown"
          />
        )}
        <Select
          type={SelectType.Searchable}
          valueId={selectedProvider}
          valueHandler={onProviderChange}
          options={providerSelectorOptions}
          classNames={selectorClassNames}
          isDisabled={providers.length === 0}
          searchPlaceholder="Filter providers"
          placement={MenuPlacement.BottomRight}
          dataSelector="select-purchase-orders-provider-dropdown"
        />
        <Button variant="secondary-cta" dataSelector="purchase-orders-export-button" onClick={onExportClick}>
          <Icon name="export" />
          Export
        </Button>
      </SPurchaseOrdersHeader>
    );
  }
);
