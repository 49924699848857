import React from 'react';
import { ICON_SIZE, Icon, TYPOGRAPHY_TYPE, Typography } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { BEM_CLASS, SValidationTooltipContent } from './s-validation-tooltip-content';

const classes = bem(BEM_CLASS);

interface ValidationTooltipContentProps {
  text: string;
  className?: string;
}

export const ValidationTooltipContent = ({ text, className }: ValidationTooltipContentProps) => (
  <SValidationTooltipContent className={className}>
    <Icon className={classes('warning-icon')} size={ICON_SIZE.medium} name="warning-l" />

    <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('error-text')}>
      {text}
    </Typography>
  </SValidationTooltipContent>
);
