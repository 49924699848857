import React from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { MediaPlanMode } from 'media-plan/types';
import { ApprovalStatusEditor, CommonStatusEditor } from './components';
import { SpreadsheetRowData } from 'media-plan/components/spreadsheet/types';

export const StatusEditor = React.memo(
  React.forwardRef((props: ICellEditorParams<SpreadsheetRowData, SpreadsheetRowData['status']>, ref) => {
    return props.context.mediaPlanMode === MediaPlanMode.APPROVAL && props.data?.editableInApproval?.value ? (
      <ApprovalStatusEditor {...props} key="approval-status-editor" ref={ref} />
    ) : (
      <CommonStatusEditor {...props} key="common-status-editor" ref={ref} />
    );
  })
);
