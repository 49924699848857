import React from 'react';
import LoaderSvg from 'assets/states/loading/loader-bars.raw.svg';
import { SLoader } from './loaders/s-loader';

interface Props {
  isActive: boolean;
  size?: number;
  color?: string;
  topOffset?: number;
}

export const Loader = React.memo(({ isActive, size, color, topOffset = 0 }: Props) =>
  isActive ? (
    <SLoader size={size} color={color} topOffset={topOffset}>
      <LoaderSvg />
    </SLoader>
  ) : null
);
