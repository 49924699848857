import styled from 'styled-components';

export const BEM_CLASS = 'layout';

export const SGlobalLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;

  .${BEM_CLASS} {
    &__wrapper {
      flex: 1;
      display: flex;
    }
  }
`;
