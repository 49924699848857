import { ColumnApi, GridApi, RowNode } from 'ag-grid-community';
import { throttle } from 'lodash';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ROW_CREATE_THROTTLE } from '../constants';
import { createDraftCampaign, isTableSorted } from '../transducers';
import { MediaPlanColumnId, SpreadsheetRowData } from '../types';

export const useCreateCampaign = () => {
  const createCampaign = useMemo(
    () =>
      throttle(
        (
          api: GridApi<SpreadsheetRowData>,
          columnApi: ColumnApi,
          node?: RowNode<SpreadsheetRowData> | null,
          isAbove = false
        ) => {
          if (!node) return;

          const isSorted = isTableSorted(columnApi);
          const campaignUuid = uuidv4();

          const addTransaction: SpreadsheetRowData[] = [];
          const updateTransaction: SpreadsheetRowData[] = [];

          if (node.group) {
            const groupIndex = isAbove ? node.childIndex - 1 : node.childIndex;
            const groupNodes =
              node.parent?.childrenAfterGroup?.[groupIndex][isSorted ? 'childrenAfterSort' : 'allLeafChildren'] ?? [];
            const groupNode = groupNodes[0];

            if (groupNode && groupNode.data) {
              const order = isAbove ? groupNodes.length : 0;

              addTransaction.push(
                createDraftCampaign(
                  {
                    uuid: campaignUuid,
                    phaseId: groupNode.data.phaseId.value,
                    phaseName: groupNode.data.phaseName.value,
                    phaseOrder: groupNode.data.phaseOrder.value,
                  },
                  {
                    orderInPhase: order,
                    orderOnSort: isSorted ? order : undefined,
                  }
                )
              );

              groupNodes.forEach(({ data }) => {
                if (data) {
                  const nextOrderInPhase =
                    data.orderInPhase.value !== undefined ? data.orderInPhase.value + 1 : data.orderInPhase.value;
                  const orderInPhase = isAbove ? data.orderInPhase.value : nextOrderInPhase;
                  const nextOrderOnSort =
                    data.orderOnSort.value !== undefined ? data.orderOnSort.value + 1 : data.orderOnSort.value;
                  const orderOnSort = isAbove ? data.orderOnSort.value : nextOrderOnSort;

                  updateTransaction.push({
                    ...data,
                    orderInPhase: {
                      ...data.orderInPhase,
                      value: orderInPhase,
                    },
                    orderOnSort: {
                      ...data.orderOnSort,
                      value: isSorted ? orderOnSort : undefined,
                    },
                  });
                }
              });
            }
          } else {
            const groupNodes = node.parent?.[isSorted ? 'childrenAfterSort' : 'allLeafChildren'] ?? [];
            const groupNodeIndex = groupNodes.findIndex(
              ({ data }) => data?.uuid.value && data.uuid.value === node.data?.uuid.value
            );
            const { data } = groupNodes[groupNodeIndex];

            if (data) {
              const nextOrder =
                data.orderInPhase.value !== undefined ? data.orderInPhase.value + 1 : data.orderInPhase.value;
              const order = isAbove ? data.orderInPhase.value : nextOrder;
              const sortOrder = isAbove ? groupNodeIndex : groupNodeIndex + 1;
              const orderAfterSort = isSorted ? groupNodes.length : order;

              addTransaction.push(
                createDraftCampaign(
                  {
                    uuid: campaignUuid,
                    phaseId: data.phaseId.value,
                    phaseName: data.phaseName.value,
                    phaseOrder: data.phaseOrder.value,
                  },
                  {
                    orderInPhase: orderAfterSort,
                    orderOnSort: sortOrder,
                  }
                )
              );

              groupNodes.forEach(({ data }, index) => {
                if (data) {
                  const orderInPhase =
                    data.orderInPhase.value !== undefined &&
                    orderAfterSort !== undefined &&
                    data.orderInPhase.value >= orderAfterSort
                      ? data.orderInPhase.value + 1
                      : data.orderInPhase.value;

                  updateTransaction.push({
                    ...data,
                    orderInPhase: {
                      ...data.orderInPhase,
                      value: orderInPhase,
                    },
                    orderOnSort: {
                      ...data.orderOnSort,
                      value: index >= sortOrder ? index + 1 : index,
                    },
                  });
                }
              });
            }
          }

          api.applyTransaction({
            update: updateTransaction,
          });

          api.applyTransactionAsync(
            {
              addIndex: node.rowIndex && (isAbove ? node.rowIndex : node.rowIndex + 1),
              add: addTransaction,
            },
            () => {
              api.clearRangeSelection();
              api.refreshCells({ columns: [MediaPlanColumnId.ACTIONS], force: true });
            }
          );
        },
        ROW_CREATE_THROTTLE
      ),
    []
  );

  return createCampaign;
};
