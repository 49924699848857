import { actionsGroup } from 'core/store';
import { ShortProject } from 'backend-api/models';
import { NewProjectsParams, RecentlyViewedProjectsParams } from 'backend-api/types';

const { asyncAction } = actionsGroup('projects');

export const getRecentlyViewedProjects = asyncAction<RecentlyViewedProjectsParams, ShortProject[], Error>(
  'GET_RECENTLY_VIEWED_PROJECTS'
);

export const getNewProjects = asyncAction<NewProjectsParams, ShortProject[], Error>('GET_NEW_PROJECTS');
