import { Optional } from 'backend-api/models';
import { OptionalPeriod } from 'common/types';

export const projectDatesValidation = (value?: OptionalPeriod): Optional<string> => {
  if (!value || value[0] === undefined || value[1] === undefined) {
    return 'Please provide the Date Period.';
  }

  return;
};
