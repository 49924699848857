import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
export const SMultipleListsDragNDrop = styled.div`
  .lists-drag-n-drop {
    &__items-list {
      width: 100%;
      min-height: 55px;
      display: flex;
      flex-direction: column;
      position: relative;
    }
    &__list-item-container {
      margin-bottom: 10px;
    }
    &__droppable-placeholder {
      box-sizing: border-box;
      max-height: 40px;
      width: 260px;
      padding: 0;
      margin: 0;
      overflow: hidden;
      border: 1px dashed ${palette.darkBlueTwo};
      border-radius: 5px;
      transition: top 200ms ease-in;
      z-index: 1;
    }

    &__placeholder-container {
      height: 45px;
      width: 100%;
      border-radius: 5px;
      background-color: ${palette.athensGrayFour};
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      opacity: 1;
      overflow: hidden;
      transition: opacity 200ms ease-in;
      &--hidden {
        opacity: 0;
      }
    }
    &__placeholder-text {
      color: ${palette.darkBlueTwo};
      font-size: 10px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 5px 0;
      text-align: center;
    }
  }
`;
