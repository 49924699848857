import React from 'react';
import { Badge, SearchFieldOptionProps, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';
import { SearchOption as SearchFieldOption } from '../../types';
import { SSearchOption, BEM_CLASS } from './s-search-option';
import { Avatar } from 'common-v2/components';

interface SearchOptionProps extends SearchFieldOptionProps<SearchFieldOption> {
  className?: string;
}

const classes = bem(BEM_CLASS);

export const SearchOption = React.memo(({ className, option, isSelected, ...liProps }: SearchOptionProps) => (
  <SSearchOption {...liProps} className={className} isSelected={isSelected} isDisabled={Boolean(option.isDisabled)}>
    <div className={classes('container')}>
      <Avatar
        className={classes('avatar')}
        name={option.title}
        size={25}
        avatarColor={transparentize(0.85, manhattanThemeColors.primaryFillNew)}
      />

      <div className={classes('content')}>
        <Typography className={classes('name')} type={TYPOGRAPHY_TYPE.body2}>
          {option.title}
        </Typography>

        <Typography className={classes('email')} type={TYPOGRAPHY_TYPE.body4}>
          {option.description}
        </Typography>
      </div>

      {option.badge && <Badge className={classes('badge')} content={option.badge} />}
    </div>
  </SSearchOption>
));
