import React from 'react';
import { NoResults } from 'common/components/no-results';
import NoProjectsGraySvg from 'assets/no-projects-gray.svg';
import NoResultsGray from 'assets/no-results-gray.svg';

interface Props {
  isSearching: boolean;
  className?: string;
}

export const ProjectsEmptyView = React.memo(({ isSearching, className }: Props) => {
  return (
    <NoResults
      withFiltersMessage={isSearching ? 'Try changing search parameters.' : 'Start typing to see search results.'}
      withFiltersImage={isSearching ? NoResultsGray : NoProjectsGraySvg}
      message={isSearching ? 'No results to show.' : 'No Recommended Projects to show.'}
      className={className}
      withFilters
    />
  );
});
