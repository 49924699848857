import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { BEM_CLASS, SObjectiveEditor } from './s-objective-edtior';
import { ClassNamesProps, Select, SelectType } from 'common/components/select';
import { bem } from 'utils/bem';
import { goalsToSelectOptions } from 'media-plan/transducers';
import { useSelectEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan/components/spreadsheet/hooks';

const classes = bem(BEM_CLASS);

export const ObjectiveEditor = React.memo(
  React.forwardRef(({ value: tableValue, api, colDef, rowIndex }: ICellEditorParams, ref) => {
    const [value, setValue] = useState(tableValue.value);
    const { onBlur } = useStopEditingBlur(api);

    const goals = useMemo(() => colDef?.cellEditorParams.goals, [colDef?.cellEditorParams.goals]);

    const allObjectives = useMemo(() => goals?.flatMap(goal => goal.objectives), [goals]);
    const options = useMemo(() => goalsToSelectOptions(goals), [goals]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          ...tableValue,
          value,
        };
      },
    }));

    const handleChange = useCallback(
      (id: number) => {
        setValue(allObjectives?.find(option => option.id === id));
        api.setFocusedCell(rowIndex, colDef.field || '');
      },
      [allObjectives, api, colDef.field, rowIndex]
    );

    const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions({
      api,
      value,
      setValue: handleChange,
      isMulti: false,
    });

    const selectClassNames = useCallback<(className: string) => ClassNamesProps>(
      () => ({
        selectContainer: classes('select-container'),
        control: classes('control'),
        valueContainer: { root: classes('value-container-root'), container: classes('value-container') },
        menu: classes('menu'),
        indicatorsContainer: classes('indicators-container'),
      }),
      []
    );

    return (
      <SObjectiveEditor objectiveId={value?.id}>
        {className => (
          <div className={className} id="container">
            <Select
              selectRef={selectRef}
              type={SelectType.Searchable}
              dataSelector="objective-editor"
              valueId={value?.id}
              valueHandler={handleChange}
              options={options}
              className={classes('cont')}
              classNames={selectClassNames(className)}
              onBlur={onBlur}
              isSearchable
              placeholder=""
              isOpened
              autoFocus
              onKeyDown={onKeyDown}
            />
          </div>
        )}
      </SObjectiveEditor>
    );
  })
);
