import React, { useCallback } from 'react';
import { Popper } from 'common/components/popper';
import { Icon } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { BEM_CLASS, SMediaPlansDropdown } from './s-media-plans-dropdown';
import { Popup } from 'media-plan/components/media-plans-dropdown/components';
import { MediaPlanName } from '../media-plan-name';
import { Id, MediaPlanListItem } from 'backend-api/models';
import { CONFIRMATION_MODAL_CONTENT_ID } from '../confirmation-modal/constants';

interface Props {
  mediaPlans: MediaPlanListItem[];
  mediaPlanId?: Id;
  onItemClick(mediaPlanId: Id): void;
  onDelete(mediaPlanId: Id): void;
  onCreateNew(name: string): void;
  onEdit(mediaPlanId: Id, name: string): void;
  onOpen(): void;
  onClose(): void;
  loading?: boolean;
  editable?: boolean;
  isOpen: boolean;
  className?: string;
  mediaPlanName?: string;
}

const classes = bem(BEM_CLASS);

export const MediaPlansDropdown = React.memo(
  ({
    mediaPlans,
    mediaPlanId,
    onCreateNew,
    onItemClick,
    onDelete,
    onEdit,
    onOpen,
    onClose,
    loading,
    editable,
    className,
    isOpen,
    mediaPlanName,
  }: Props) => {
    const onSelectItem = useCallback(
      (mediaPlanId: Id) => {
        onItemClick(mediaPlanId);
        onClose();
      },
      [onClose, onItemClick]
    );

    const onDropdownTriggerClick = useCallback(() => {
      isOpen ? onClose() : onOpen();
    }, [isOpen, onClose, onOpen]);

    const onClickOutside = useCallback(
      (instance, event: Event) => {
        const isClickedOnConfirmationModal =
          event.target instanceof HTMLElement && event.target.dataset.id === CONFIRMATION_MODAL_CONTENT_ID;

        if (isClickedOnConfirmationModal) {
          return;
        }
        onClose();
      },
      [onClose]
    );

    return (
      <Popper
        content={
          <Popup
            mediaPlans={mediaPlans}
            mediaPlanId={mediaPlanId}
            loading={loading}
            editable={editable}
            visible={isOpen}
            onItemClick={onSelectItem}
            onCreateNew={onCreateNew}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        }
        onClickOutside={onClickOutside}
        visible={isOpen}
        placement="bottom-start"
        maxWidth="none"
      >
        <SMediaPlansDropdown onClick={onDropdownTriggerClick}>
          <MediaPlanName mediaPlanName={mediaPlanName} />
          {mediaPlans.length > 1 || !editable ? (
            <button
              className={classes('trigger', { menuOpen: isOpen }, className)}
              data-selector="media-plan-dropdown-trigger"
            >
              <Icon name={isOpen ? 'up-single-m' : 'down-single-m'} className={classes('icon')} />
            </button>
          ) : (
            <button
              className={classes('trigger-outlined', { menuOpen: isOpen }, className)}
              data-selector="media-plan-dropdown-edit-trigger"
            >
              <Icon name="edit" className={classes('icon', { menuOpen: isOpen })} />
            </button>
          )}
        </SMediaPlansDropdown>
      </Popper>
    );
  }
);
