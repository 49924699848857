import styled from 'styled-components';
import { MANHATTAN_HEADER_HEIGHT, TOP_HEADER_HEIGHT } from 'common/constants';

export const BEM_CLASS = 'global-search-modal-container';

export const SGlobalSearchModalContainer = styled.div`
  .${BEM_CLASS} {
    &__global-search-modal {
      top: ${MANHATTAN_HEADER_HEIGHT}px;
      height: calc(100vh - ${MANHATTAN_HEADER_HEIGHT}px);
    }

    &__search-modal {
      top: ${TOP_HEADER_HEIGHT}px;
      height: calc(100vh - ${TOP_HEADER_HEIGHT}px);
    }
  }
`;
