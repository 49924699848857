import { TeamUser } from 'backend-api/models';
import { ROLES } from 'common-v2/constants';
import { LocalRole } from 'common-v2/types';
import { hasUserRole } from 'common/transducers';
import { isEqual, orderBy } from 'lodash';
import { SearchUser } from './types';

export const hasSameRoleAndCategories = (initialMembers: TeamUser[], teamUser: TeamUser) =>
  initialMembers.some(member => member.id === teamUser.id && isEqual(orderBy(member.roles), orderBy(teamUser.roles)));

export const prepareTeammatesForDisplay = (
  team: TeamUser[],
  pendingAdded: TeamUser[],
  pendingUpdated: TeamUser[],
  pendingDeleted: TeamUser[]
) =>
  team
    .filter(user => {
      const updated = pendingUpdated.find(updated => updated.id === user.id);
      const predicate = updated || pendingDeleted.includes(user);
      const isEditorOrViewer = hasUserRole(user, ROLES.EDITOR) || hasUserRole(user, ROLES.VIEWER);
      return !predicate && isEditorOrViewer;
    })
    .concat(pendingAdded)
    .concat(pendingUpdated);

export const searchUserToTeamUser = (searchUser: SearchUser, role: LocalRole): TeamUser => ({
  ...searchUser,
  roles: [{ ...role, categoryIds: [1, 2, 3] }],
  // TODO: use actual phone when in will be added to SearchUser
  phone: '',
});

export const searchUsersToTeamUsers = (searchUsers: SearchUser[], role: LocalRole) =>
  searchUsers.map(user => searchUserToTeamUser(user, role));
