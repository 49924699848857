import { createSelector } from 'reselect';
import { ModalState } from './reducer';

export const bulkCampaignsAssignmentModalSelector = state => state.bulkCampaignsAssignmentModal as ModalState;

export const campaignsSelector = createSelector(bulkCampaignsAssignmentModalSelector, state => state.campaigns);
export const campaignsTotalCountSelector = createSelector(
  bulkCampaignsAssignmentModalSelector,
  state => state.campaignsTotalCount
);
export const campaignsFiltersSelector = createSelector(
  bulkCampaignsAssignmentModalSelector,
  state => state.campaignsFilters
);

export const isBulkCampaignAssignmentModalOpened = createSelector(
  bulkCampaignsAssignmentModalSelector,
  state => state.isOpened
);

export const selectedCampaignsSelector = createSelector(
  bulkCampaignsAssignmentModalSelector,
  state => state.selectedCampaigns
);
