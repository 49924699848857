import { css } from 'styled-components';
import { theme as appTheme } from 'app/theme';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { TOP_HEADER_HEIGHT } from 'common/constants';
import { CHECKBOX_COLUMN_WIDTH, PAGE_PADDING } from 'media-plan-v2/constants';
import { ACTION_BUTTON_BEM_CLASS, SBaseRenderer } from './components';
import Checked from 'assets/manhattan-checkbox-checked.svg';
import Unchecked from 'assets/manhattan-checkbox-unchecked.svg';
import Indeterminate from 'assets/manhattan-checkbox-intermediate.svg';
import { BORDER_WIDTH } from 'media-plan-v2/components/spreadsheet/components/expandable-field/constants';
import { transparentize } from 'polished';

interface Props {
  shouldShowGroupCheckbox?: boolean;
}

export const theme = css<Props>`
  .cell-error {
    --ag-input-focus-border-color: ${manhattanThemeColors.darkRed};
  }

  &&& {
    .ag-overlay-wrapper {
      margin-top: 38px;
      background: white;
    }
    .ag-overlay-wrapper,
    .ag-react-container {
      height: 100%;
      width: 100%;
    }

    .ag-header-cell-label-align-right {
      .ag-header-cell-label {
        justify-content: flex-end;
      }
    }

    .ag-theme-alpine {
      --ag-foreground-color: ${colorPalette.vulcan};
      --ag-border-color: ${colorPalette.iron};
      --ag-font-family: Montserrat;
      --ag-font-size: 14px;

      --ag-header-background-color: ${transparentize(0.5, manhattanThemeColors.background)};
      --ag-header-foreground-color: ${manhattanThemeColors.grayedText};

      --ag-range-selection-border-color: ${transparentize(0.6, manhattanThemeColors.hoveredBorderColor)};
      --ag-range-selection-background-color: ${transparentize(0.95, manhattanThemeColors.primaryFillNew)};
      --ag-range-selection-highlight-color: ${transparentize(0.6, manhattanThemeColors.primaryFillNew)};

      --ag-selected-row-background-color: transparent;
      --ag-row-border-color: ${colorPalette.iron};
      --ag-checkbox-background-color: ${colorPalette.white};
      --ag-input-focus-border-color: ${transparentize(0.6, manhattanThemeColors.hoveredBorderColor)};
      --ag-row-hover-color: ${transparentize(0.95, manhattanThemeColors.primaryFillNew)};

      height: calc(100vh - ${TOP_HEADER_HEIGHT + PAGE_PADDING * 2}px);

      .ag-header-cell {
        padding-left: var(--cell-horizontal-padding);
        padding-right: var(--cell-horizontal-padding);
        font-size: 10px;
        line-height: 15px;
        font-weight: 700;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      .ag-header-cell-resize {
        &::after {
          top: 0;
          height: 100%;
          background-color: ${colorPalette.iron};
        }
      }

      .ag-full-width-row {
        .ag-cell-wrapper.ag-row-group {
          padding-right: 0;
          padding-left: 0;
        }
      }

      .ag-group-expanded {
        display: none;
        .ag-row-selected {
          background-color: ${colorPalette.lavender};
          ::before {
            background: transparent;
          }
        }
      }

      .ag-row-group-expanded {
        background-color: ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};

        &.ag-row-focus {
          background-color: ${transparentize(0.85, manhattanThemeColors.primaryFillNew)};

          & > .ag-row-group {
            border: 1px solid var(--ag-range-selection-border-color);
          }
        }
      }

      .ag-row.ag-row-selected {
        &.ag-row-hover {
          &::before {
            background-image: none;
          }
        }

        :not(.ag-row-group-expanded) {
          background: transparent;
        }
      }

      .ag-row-hover,
      .ag-row-selected {
        &:not(&.ag-row-footer) {
          .${ACTION_BUTTON_BEM_CLASS}__value {
            visibility: hidden;
          }
        }

        .${ACTION_BUTTON_BEM_CLASS}__action-button {
          visibility: visible;
        }

        .cell-disabled {
          background-color: transparent;
        }
      }

      .ag-cell {
        display: flex;
        padding-left: 0;
        padding-right: 1px;
        line-height: unset;
        border-right: 1px solid ${colorPalette.iron};

        &:hover:not(.ag-cell-highlight, .ag-cell-highlight-animation) {
          ${SBaseRenderer} {
            z-index: 0;

            &::after {
              visibility: visible;
            }
          }
        }
      }

      .ag-cell-disabled {
        background-color: ${colorPalette.alabaster};
      }

      .ag-cell-focus {
        border: 1px solid ${transparentize(0.6, manhattanThemeColors.hoveredBorderColor)};

        &.ag-cell:not(.ag-cell-highlight, .ag-cell-highlight-animation) {
          ${SBaseRenderer} {
            z-index: 0;

            &::after {
              visibility: hidden;
            }
          }
        }
      }

      .ag-cell-inline-editing {
        border-radius: 0;
        height: 100%;
      }

      .ag-fill-handle {
        bottom: 0;
        z-index: 1;
      }

      .ag-cell-range-selected.ag-cell-range-right {
        border-right: 1px solid ${transparentize(0.6, manhattanThemeColors.hoveredBorderColor)};
      }

      .ag-cell-range-selected.ag-cell-range-single-cell {
        border-right: 1px solid ${transparentize(0.6, manhattanThemeColors.hoveredBorderColor)};
      }

      .ag-cell-range-selected.cell-disabled {
        background-color: var(--ag-range-selection-background-color);
      }

      .ag-selection-fill-left {
        border-right: 1px dashed ${transparentize(0.6, manhattanThemeColors.hoveredBorderColor)};
      }

      .not-resizable-header-cell {
        border-right: 1px solid ${colorPalette.iron};
      }

      .action-header-cell {
        padding: 0;
      }

      .action-cell {
        padding: 0;
      }

      .cell-error:hover {
        border: 1px solid ${manhattanThemeColors.darkRed};
      }

      .cell-error::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0;
        right: 0;
        border-bottom: 10px solid ${manhattanThemeColors.darkRed};
        border-left: 10px solid transparent;
      }

      .ag-checkbox-input {
        cursor: pointer;
      }

      .ag-checkbox-input-wrapper.ag-checked::after {
        content: '';
        outline: none;
        width: 100%;
        height: 100%;
        background: transparent url(${Checked}) center/contain no-repeat;
      }
      .ag-checkbox-input-wrapper.ag-indeterminate::after {
        content: '';
        outline: none;
        width: 100%;
        height: 100%;
        background: transparent url(${Indeterminate}) center/contain no-repeat;
      }
      .ag-checkbox-input-wrapper {
        height: 20px;
        width: 20px;
        background: transparent;
        :active,
        :focus-within {
          box-shadow: unset;
        }
        ::after {
          content: '';
          outline: none;
          width: 100%;
          height: 100%;
          background: transparent url(${Unchecked}) center/contain no-repeat;
        }
      }
      .ag-selection-checkbox {
        display: flex;
        width: ${CHECKBOX_COLUMN_WIDTH - BORDER_WIDTH * 2}px;
        margin-right: 0;
        justify-content: center;
      }
      .ag-selection-checkbox.ag-invisible {
        display: none;
      }
      .ag-group-checkbox {
        display: ${({ shouldShowGroupCheckbox }) => (shouldShowGroupCheckbox ? 'flex' : 'none')};
        border-right: 1px solid var(--ag-border-color);

        .ag-selection-checkbox.ag-hidden {
          display: ${({ shouldShowGroupCheckbox }) => (shouldShowGroupCheckbox ? 'flex' : 'none')} !important;
          opacity: 0.5;
        }
      }

      .ag-header-cell {
        .ag-checkbox {
          margin: 0 25%;
        }
      }

      .ag-row-footer {
        .ag-checkbox {
          display: none;
        }
      }

      // NOTE: Context menu styling starts
      .ag-popup {
        height: auto;
      }

      .ag-menu {
        width: 310px;
        border: 0;
        border-radius: 10px;
        background-color: ${colorPalette.white};
        box-shadow: ${appTheme.shadows.default};

        // TODO: Find better solution to match sub-menus
        // NOTE: This will affect all sub-menus
        &[aria-label='SubMenu'] {
          width: auto;

          .ag-menu-list {
            display: flex;
            padding: 10px 15px;
          }

          .color-menu-option {
            --ag-row-hover-color: transparent;

            .ag-menu-option-shortcut {
              display: none;
            }

            .ag-menu-option-text {
              padding: 5px;
            }
          }
        }
      }

      .ag-menu-list {
        padding: 15px 0;
      }

      .ag-menu-option-disabled {
        opacity: 1;
      }

      .ag-menu-option-text {
        padding: 8.5px 20px 6.5px;
        color: ${manhattanThemeColors.primaryDark};
        font-size: 14px;
        line-height: 25px;
        font-weight: 500;
        cursor: pointer;
      }

      .ag-menu-option-shortcut {
        text-align: right;
        padding: 4px 20px 6px 5px;
        font-size: 14px;
        line-height: 25px;
        font-weight: 500;
        color: ${manhattanThemeColors.secondGrayedText};
        cursor: pointer;
      }

      .menu-options-group {
        &:not(.menu-options-group-no-offset) {
          .ag-menu-option-text {
            padding-top: 15px;
          }
        }

        .ag-menu-option-text {
          padding: 0 20px;
          color: ${manhattanThemeColors.grayedText};
          cursor: default;
        }

        .ag-menu-option-shortcut {
          cursor: default;
        }
      }

      .ag-menu-option-icon,
      .ag-menu-option-popup-pointer {
        display: none;
      }
      // NOTE: Context menu styling ends
    }

    .cell-footer {
      font-weight: 600;
    }
  }
`;
