import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bem } from 'utils/bem';
import { ActionButton } from '../action-button';
import { BEM_CLASS, SPhaseRow } from './s-phase-row';
import { THEME, Typography, TYPOGRAPHY_TYPE, useManageToasts } from 'gdb-web-shared-components';
import { FocusDirection, PhaseRowProps } from '../../types';
import { MediaPlanColumnId } from 'media-plan-v2/containers/spreadsheet/types';
import { isEditMode, moveCellFocus } from '../../transducers';
import { useDispatch, useSelector } from 'react-redux';
import { editPhase, resetPhaseInput } from 'media-plan-v2/actions';
import { useMediaPlanAccessMode } from 'media-plan-v2/hooks';
import { callbackWithoutPropagation } from 'utils/event';
import { focusedPhaseIdSelector, isConfirmModalShownSelector, mediaPlanModeSelector } from 'media-plan-v2/selectors';
import { MediaPlanMode } from 'media-plan-v2/types';
import { useColumnsVisibility } from '../../hooks';

const classes = bem(BEM_CLASS);

const PHASE_INPUT_ID = 'phase-input';

export const PhaseRow = React.memo(
  (props: PhaseRowProps) => {
    const { projectId, mediaPlanId, node, api, context } = props;
    const dispatch = useDispatch();
    const { openToast } = useManageToasts(THEME.light);
    const { isActionsColumnVisible } = useColumnsVisibility();

    const mediaPlanMode = useSelector(mediaPlanModeSelector);
    const accessMode = useMediaPlanAccessMode(projectId, mediaPlanMode);
    const isConfirmShown = useSelector(isConfirmModalShownSelector);
    const focusedPhaseId = useSelector(focusedPhaseIdSelector);
    const editable = isEditMode(accessMode);
    const inputRef = useRef<HTMLInputElement>(null);

    const value = node.allLeafChildren[0]?.data.phaseName.value || '';
    const phaseId = node.allLeafChildren[0]?.data.phaseId.value;

    const [inputValue, setInputValue] = useState(value);
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [shouldRefocus, setShouldRefocus] = useState(false);

    const updateLeafChildrenPhaseName = useCallback(
      (name: string) => {
        api.applyTransaction({
          update: node.allLeafChildren.map(child => ({
            ...child.data,
            phaseName: {
              ...child.data.phaseName,
              value: name,
            },
          })),
        });
      },
      [api, node.allLeafChildren]
    );

    const onError = useCallback(() => {
      setInputValue(value);
    }, [value]);
    const onSuccess = useCallback(phase => updateLeafChildrenPhaseName(phase.name), [updateLeafChildrenPhaseName]);

    const inputHandler = useCallback(({ target }) => setInputValue(target.value), []);

    const openEditor = useCallback(() => setIsEditorOpen(true), []);
    const closeEditor = useCallback(() => {
      setIsEditorOpen(false);
    }, []);

    const refocusCell = useCallback(() => {
      if (node.rowIndex === null) return;
      api.clearRangeSelection();
      api.clearFocusedCell();
      api.setFocusedCell(node.rowIndex, MediaPlanColumnId.STATUS);
    }, [api, node.rowIndex]);

    useEffect(() => {
      if (isEditorOpen || !shouldRefocus) return;
      refocusCell();
      setShouldRefocus(false);
    }, [isEditorOpen, refocusCell, shouldRefocus]);

    useEffect(() => {
      if (focusedPhaseId === undefined) {
        inputRef.current?.blur();
        return;
      }
      if (focusedPhaseId === phaseId) {
        refocusCell();
        openEditor();
      }
    }, [focusedPhaseId, openEditor, phaseId, refocusCell]);

    const blurHandler = useCallback(() => {
      dispatch(
        editPhase.request({
          projectId,
          mediaPlanId,
          phaseId,
          payload: { name: inputValue },
          onError,
          onSuccess,
          openToast,
        })
      );
      dispatch(resetPhaseInput());
      closeEditor();
    }, [dispatch, projectId, mediaPlanId, phaseId, inputValue, onError, onSuccess, openToast, closeEditor]);

    const isInputVisible = phaseId && editable && isEditorOpen && mediaPlanMode !== MediaPlanMode.APPROVAL;

    const handleEnterPress = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (node.rowIndex == null) return;

        if (event.shiftKey) {
          if (node.rowIndex === 0) {
            setShouldRefocus(true);
            return;
          }
          moveCellFocus(api, FocusDirection.BACKWARD);
          return;
        }

        moveCellFocus(api, FocusDirection.FORWARD);
      },
      [api, node.rowIndex]
    );

    const onKeyInputDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
          event.stopPropagation();
          inputRef.current?.blur();
          handleEnterPress(event);
        }
        if (event.key === 'Tab' && event.shiftKey) {
          event.preventDefault();
        }
      },
      [handleEnterPress]
    );

    const onKeyContainerDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (node.rowIndex === null) return;
        if (event.key === 'ArrowUp') {
          if (node.rowIndex === 0) return;
          api.setFocusedCell(node.rowIndex + FocusDirection.BACKWARD, MediaPlanColumnId.STATUS);
          return;
        }
        if (event.key === 'ArrowDown') {
          api.setFocusedCell(node.rowIndex + FocusDirection.FORWARD, MediaPlanColumnId.STATUS);
          return;
        }

        if (event.key === 'Tab' && event.shiftKey) {
          inputRef.current?.blur();
          api.tabToPreviousCell();
          return;
        }
        if (event.key === 'Tab') {
          inputRef.current?.blur();
          api.tabToNextCell();
          return;
        }

        return;
      },
      [api, node.rowIndex]
    );

    useEffect(() => {
      const onKeyDown = event => {
        if (isConfirmShown || event.defaultPrevented) return;
        const focusedRowIndex = api.getFocusedCell()?.rowIndex;
        if (focusedRowIndex === node.rowIndex) {
          switch (event.key) {
            case 'Enter':
              if (isEditorOpen) {
                inputRef.current?.blur();
              } else {
                openEditor();
              }
              break;
          }
        }
      };
      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }, [api, blurHandler, isConfirmShown, isEditorOpen, node, openEditor]);

    return (
      <SPhaseRow onKeyDown={onKeyContainerDown} onMouseDown={refocusCell} data-selector="phase-row">
        {isActionsColumnVisible && (
          <div className={classes('action')} data-selector="phase-row-number">
            <ActionButton
              {...props}
              value={api.getValue(MediaPlanColumnId.ACTIONS, node)}
              context={context}
              key="action-button"
            />
          </div>
        )}

        <div className={classes('value-container')} onDoubleClick={openEditor} data-selector="phase-row-value">
          {isInputVisible ? (
            <input
              id={PHASE_INPUT_ID}
              ref={inputRef}
              autoFocus
              className={classes('input')}
              value={inputValue}
              onInput={inputHandler}
              onKeyDown={onKeyInputDown}
              onMouseDownCapture={callbackWithoutPropagation()}
              onBlur={blurHandler}
              autoComplete="off"
            />
          ) : (
            <Typography type={TYPOGRAPHY_TYPE.body1}>{inputValue}</Typography>
          )}
        </div>
      </SPhaseRow>
    );
  },
  (prevProps, nextProps) => {
    const previousPhaseId = prevProps.data?.phaseId.value;
    const nextPhaseId = nextProps.data?.phaseId.value;

    const previousIndex = prevProps.node?.rowIndex;
    const nextIndex = nextProps.api.getValue(MediaPlanColumnId.ACTIONS, nextProps.node);

    return previousPhaseId === nextPhaseId && previousIndex === nextIndex;
  }
);
