import { PAGE_WIDTH } from 'common/constants';
import styled, { css } from 'styled-components';

export const BEM_CLASS = 'responsive-container';

interface SResponsiveContainerProps {
  readonly background: string;
  readonly filtersOpened?: boolean;
  readonly filtersMargin?: number[];
}

export const responsiveContainerWrapperCss = css<Omit<SResponsiveContainerProps, 'background'>>`
  padding: 0 20px;
  min-width: ${PAGE_WIDTH}px;
  width: 100%;
  margin: 0 auto;
  height: 100%;

  /*
    0 - 1366: 1200 + paddings
    1366 - 1560: 1200 + paddings
    1560+: 1380 + paddings
  */

  @media (min-width: 1366px) {
    max-width: calc(100% - 146px);
  }

  @media (min-width: 1546px) {
    max-width: 1420px;
  }

  @media (min-width: 920px) and (max-width: 1560px) {
    min-width: ${({ filtersOpened }) => (filtersOpened ? 'calc(100% - 320px - 40px)' : '1200px')};
    max-width: ${({ filtersOpened }) => (filtersOpened ? 'calc(100% - 40px)' : `${PAGE_WIDTH}px`)};

    margin: ${({ filtersOpened }) => filtersOpened && '0 20px'};
    padding: ${({ filtersOpened }) => filtersOpened && '0'};
  }
`;

export const SResponsiveContainer = styled.div<SResponsiveContainerProps>`
  background-color: ${({ background }) => background};

  .${BEM_CLASS} {
    &__wrapper {
      ${responsiveContainerWrapperCss}
    }
  }
`;
