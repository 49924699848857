import React from 'react';
import { SortOrder } from 'utils/sort';
import { Icon } from './icon';
import { IconName } from './icon/types';

interface Props {
  sortOrder?: SortOrder;
  className?: string;
}

export const SortingIcon = React.memo(({ sortOrder, className }: Props) => {
  let iconName: IconName = 'sort';

  if (sortOrder === SortOrder.Descending) {
    iconName = 'down';
  }

  if (sortOrder === SortOrder.Ascending) {
    iconName = 'up';
  }

  return <Icon className={className} name={iconName} color={sortOrder ? 'black' : 'default'} />;
});
