import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { Sort, SortOrder } from 'utils/sort';
import { Checkbox } from '../checkbox';
import { SortingIcon } from '../sorting-icon';
import { SHeader } from '../../s-components/table/s-header';
import { BodyContent, TableContent } from './constants';
import { Tooltip } from '../tooltip';
import { TableTheme } from 'app/theme/table';

interface Props<BodyType extends BodyContent, SortField extends string> {
  sort: Sort<SortField>;
  selectAllIsHalfChecked: boolean;
  selectAllIsChecked: boolean;
  headerContent: TableContent<BodyType, SortField>[];

  selectAll(isChecked: boolean): void;

  onSortChange(sorter: Sort<SortField>): void;

  theme: TableTheme;
  isCheckable?: boolean;
  isExpandable?: boolean;
  hasStickyColumns?: boolean;
  withoutRoundCorners?: boolean;
  isNotificationVisible: boolean;
}

const classes = bem('header');

export const TableHeader = <BodyType extends BodyContent, SortField extends string>(
  props: Props<BodyType, SortField>
) => {
  const {
    selectAllIsHalfChecked,
    selectAllIsChecked,
    headerContent,
    sort,
    onSortChange,
    selectAll,
    isCheckable,
    isExpandable,
    hasStickyColumns,
    theme,
    withoutRoundCorners,
    isNotificationVisible,
  } = props;

  const handleSelectAll = useCallback(
    (val: boolean) => {
      selectAll(val);
    },
    [selectAll]
  );

  const handleSort = useCallback(
    (field?: SortField) => {
      if (field) {
        const isSameField = sort.field === field;
        const invertedOrder = sort.order === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending;

        const newOrder = isSameField ? invertedOrder : SortOrder.Ascending;

        onSortChange(new Sort(newOrder, field));
      }
    },
    [onSortChange, sort]
  );

  return (
    <SHeader tableTheme={theme} withoutRoundCorners={withoutRoundCorners} isNotificationVisible={isNotificationVisible}>
      <tr className={classes('row')}>
        {isCheckable && !hasStickyColumns && (
          <td className={classes('column', 'checkbox')}>
            <Checkbox
              name=""
              label=""
              halfChecked={selectAllIsHalfChecked}
              onClick={handleSelectAll}
              isChecked={selectAllIsChecked}
            />
          </td>
        )}
        {isExpandable && !hasStickyColumns && <td className={classes('column', 'expand')} />}
        {headerContent.map(item => {
          const sorted = sort.field === item.sortField;
          return (
            <td
              onClick={item.useSort ? () => handleSort(item.sortField) : undefined}
              data-selector={item.dataSelector}
              className={classes('column', { sorted }, item.additionalClassName)}
              key={item.dataIndex}
            >
              <Tooltip
                content={item.description}
                enabled={!!item.description}
                placement="bottom-start"
                offset={[-70, 0]}
              >
                <div className={classes('cell-container')}>
                  <div className={classes('title-container')}>
                    {item.subTitle !== undefined && <div className={classes('sub-title')}>{item.subTitle}</div>}
                    <div className={classes('title', { sorted })}>
                      {item.title}
                      {item.useSort && (
                        <div className={classes('icon-container')}>
                          <SortingIcon
                            className={classes('sorting-icon')}
                            sortOrder={sorted ? sort.order : undefined}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Tooltip>
            </td>
          );
        })}
      </tr>
    </SHeader>
  );
};
