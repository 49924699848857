import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import {
  BUDGET_WIDGET_FIRST_ROW_PERCENT_WIDTH,
  BUDGET_WIDGET_SECOND_ROW_LEFT_OFFSET,
  BUDGET_WIDGET_SECOND_ROW_PERCENT_WIDTH,
} from 'project/constants';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'budget-details-grid-item';

interface Props {
  percentage: number;
}

export const SBudgetDetailsGridItem = styled.div<Props>`
  width: 100%;
  display: flex;

  .${BEM_CLASS} {
    &__budget-and-allocated-container {
      width: ${BUDGET_WIDGET_FIRST_ROW_PERCENT_WIDTH}%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__allocated-container {
      display: flex;
      align-items: center;
      margin-left: 30px;
    }

    &__title {
      ${elipsizeText};
    }

    &__clickable-title {
      ${elipsizeText};

      cursor: pointer;
      &:hover {
        font-weight: 600;
      }
    }

    &__currency {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      font-weight: 600;
    }

    &__percentage {
      margin-left: 5px;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
    }

    &__utilization-and-total-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-left: ${BUDGET_WIDGET_SECOND_ROW_LEFT_OFFSET}px;
      width: calc(${BUDGET_WIDGET_SECOND_ROW_PERCENT_WIDTH}% - ${BUDGET_WIDGET_SECOND_ROW_LEFT_OFFSET}px);
    }

    &__progress-bar {
      display: flex;
      width: 305px;
      background-color: ${colorPalette.athensGray};
      height: 5px;
    }

    &__filled-progress-bar {
      width: ${props => props.percentage}%;
      background-color: ${colorPalette.cottonCandy};
    }
  }
`;
