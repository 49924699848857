import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

interface Props {
  backgroundColor: string | null;
  isDisabled: boolean;
}

export const BEM_CLASS = 'base-renderer';

export const SBaseRenderer = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 var(--cell-horizontal-padding);
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: inherit;
    height: inherit;
    
  }

  &::before {
    z-index: -1;
    background-color: ${({ backgroundColor, isDisabled }) =>
      backgroundColor ?? (isDisabled ? colorPalette.alabaster : colorPalette.white)};
  }

  &::after {
    z-index: 1;
    visibility: hidden;
    background-color: ${transparentize(0.97, manhattanThemeColors.primaryFillNew)};
    pointer-events: none;
  }

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        .${BEM_CLASS} {
          &__placeholder {
            visibility: visible;
          }
        }
      }
    `}


  .${BEM_CLASS} {
    &__value {
      ${elipsizeText};
      display: flex;
      width: 100%;

      &--isText {
        color: ${manhattanThemeColors.primaryDark};
        font-size: 14px;
        font-weight: 500;
      }
    }

    &__placeholder {
      flex-grow: 1;
      visibility: hidden;

      &--isText {
        color: ${manhattanThemeColors.grayedText};
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
`;
