import React, { useCallback } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { bem } from 'utils/bem';
import { BEM_CLASS, SActionButton } from './s-action-button';
import { isEditMode } from '../../transducers';
import { SmallText } from 'common/components/typography';
import { MediaPlanAccessMode } from '../../types';

const classes = bem(BEM_CLASS);

interface Props extends ICellEditorParams {
  accessMode: MediaPlanAccessMode;
}

export const ActionButton = React.memo(({ api, node, column, value, eGridCell, context }: Props) => {
  const editable = isEditMode(context.accessMode);
  const isFooter = node.footer;

  const openContextMenu = useCallback<React.MouseEventHandler>(
    event => {
      if (isFooter || !editable) return;
      (api as any).contextMenuFactory.showMenu(node, column, value, event, eGridCell);
    },
    [api, column, eGridCell, node, value, isFooter, editable]
  );

  const onMouseDown = useCallback(() => {
    if (isFooter || !editable) {
      api.clearFocusedCell();
      api.clearRangeSelection();
      api.setFocusedCell(0, 'action');
      return;
    }
  }, [api, editable, isFooter]);

  return (
    <SActionButton onClick={openContextMenu} isPhase={node.group && !node.footer} onMouseDown={onMouseDown}>
      {editable && !isFooter && (
        <div className={classes('action-button')} data-selector="action-button">
          <div className={classes('dot')} />
          <div className={classes('dot')} />
          <div className={classes('dot')} />
        </div>
      )}

      <SmallText>{value}</SmallText>
    </SActionButton>
  );
});
