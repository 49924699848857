import React, { useCallback } from 'react';
import { Tabs, THEME } from 'gdb-web-shared-components';
import { getUrlForProjectTab } from 'common/components/project-header/transducers';
import { useGetTabs } from 'common/components/project-header/hooks';
import { ProjectTab } from 'common-v2/types';
import { navigateTo } from 'utils/navigation';
import { ProjectDetails } from 'backend-api/models';
import { TabsLoader } from './components';

interface Props {
  project?: ProjectDetails;
  activeTab: ProjectTab;
  isLoading?: boolean;
  className?: string;
}

export const ProjectTabs = React.memo(({ project, activeTab, isLoading, className }: Props) => {
  const tabs = useGetTabs(project);

  const onTabChange = useCallback(
    (tab: ProjectTab) => {
      const path = getUrlForProjectTab(tab, project);

      navigateTo(path);
    },
    [project]
  );

  return isLoading ? (
    <TabsLoader className={className} />
  ) : (
    <Tabs<ProjectTab>
      tabsData={tabs}
      value={activeTab}
      theme={THEME.light}
      onChange={onTabChange}
      className={className}
    />
  );
});
