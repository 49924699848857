import React, { useCallback, useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Icon } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { Id, LabelPermissionsMember } from 'backend-api/models';
import { SEditArtistsRenderer, BEM_CLASS } from './s-edit-artists-renderer';

const classes = bem(BEM_CLASS);

export const EditArtistsRenderer = ({ data, colDef }: ICellRendererParams<LabelPermissionsMember, Id>) => {
  const artists = useMemo(() => data?.artists || [], [data]);

  const openModal = useCallback(() => {
    const name = `${data?.user.name || 'Team Member'}'s`;

    colDef?.cellRendererParams?.openEditArtistsModal({
      userId: data?.user.id,
      data: artists,
      title: `${name} Artists (${artists.length})`,
    });
  }, [colDef?.cellRendererParams, data, artists]);

  return (
    <SEditArtistsRenderer onClick={openModal} data-selector="edit-artists-button">
      <Icon className={classes('icon')} name={artists.length > 0 ? 'edit' : 'plus'} />
    </SEditArtistsRenderer>
  );
};
