import React, { useEffect, useRef } from 'react';
import { OptionProps } from 'react-select';
import { bem } from 'utils/bem';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { Tooltip } from 'common/components/tooltip';
import { Icon } from 'common/components/icon';
import { BaseOption as BaseOptionType } from '../../types';
import { BEM_CLASS, SBaseOption } from './s-base-option';
import { getNearestScrollableParentElement, scrollParentToChild } from 'utils/dom';
import { convertToDataSelector } from 'utils/string';

export type Props<T extends BaseOptionType, IsMulti extends boolean> = OptionProps<T, IsMulti>;

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType, IsMulti extends boolean>({
  isSelected,
  isDisabled,
  isFocused,
  innerProps,
  data,
  selectProps,
}: Props<T, IsMulti>) => {
  const optionRef = useRef<HTMLDivElement>(null);

  const { classNames, dataSelector } = getBaseCustomSelectProps(selectProps);

  useEffect(() => {
    if (isFocused) {
      const scrollElement = getNearestScrollableParentElement(optionRef?.current);
      if (!scrollElement || !optionRef.current) return;
      scrollParentToChild(scrollElement, optionRef.current);
    }
  }, [isFocused]);

  return (
    <SBaseOption
      {...innerProps}
      ref={optionRef}
      isFocused={isFocused}
      isDisabled={isDisabled}
      className={classNames.option?.root}
      data-selector={`${dataSelector ?? convertToDataSelector(data.value)}-option`}
    >
      <div className={classes('text', { isSelected, isDisabled }, classNames.option?.text)} title={data.value}>
        {data.value}
      </div>
      {data.info ? (
        <Tooltip content={data.info} placement="bottom-end" interactive={false}>
          <div className={classes('info', undefined, classNames.option?.info)}>
            <Icon name="info" size="general" />
          </div>
        </Tooltip>
      ) : null}
    </SBaseOption>
  );
};

export const BaseOption = React.memo(Root) as typeof Root;
