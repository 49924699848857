import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

function parseSearch(search) {
  return qs.parse(search, {
    ignoreQueryPrefix: true,
    // allowDots: true,
    // https://github.com/ljharb/qs/issues/91#issuecomment-348481496
    decoder(value) {
      if (/^(\d+|\d*\.\d+)$/.test(value)) {
        return parseFloat(value);
      }

      const keywords = {
        true: true,
        false: false,
        null: null,
        // eslint-disable-next-line object-shorthand
        undefined: undefined,
      };

      if (value in keywords) {
        return keywords[value];
      }

      return value;
    },
  });
}

export const useQuery = () => {
  const { search, hash } = useLocation();
  const query = useMemo(() => parseSearch(search), [search]);
  const queryHash = useMemo(() => parseSearch(hash?.slice(1)), [hash]);
  return { query, queryHash };
};

// TODO refactor at first
