import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { OutputSelector } from 'reselect';
import { PayloadActionCreator } from 'typesafe-actions';
import { ParsedQs } from 'qs';
import { getSearchParamsFromLocation, replaceTo } from 'utils/navigation';
import { Sort } from 'utils/sort';
import { Pagination } from 'common/components/pagination';
import { useInitialValue } from './use-initial-value';

interface FiltersHookConfig<FiltersType extends {}> {
  action: PayloadActionCreator<string, FiltersType>;
  selector: OutputSelector<any, FiltersType, (state: any) => FiltersType>;
  path: string;
  queryToFilters: (query: ParsedQs) => FiltersType;
  filtersToQuery: (filters: FiltersType) => any;
  resetOnUnmount?: boolean;
}

interface FiltersHookDispatcher<FiltersType extends {}, SortField extends string> {
  setFilters(filters: Partial<FiltersType>): void;
  setSearch(value?: string, resetOffset?: boolean): void;
  setSort(sort: Sort<SortField>): void;
  setPagination(pagination: Pagination): void;
}

export const useFilters = <FiltersType extends {}, SortField extends string>({
  action,
  selector,
  path,
  queryToFilters,
  filtersToQuery,
  resetOnUnmount = false,
}: FiltersHookConfig<FiltersType>): [FiltersType, FiltersHookDispatcher<FiltersType, SortField>] => {
  const dispatch = useDispatch();

  const location = useLocation();
  const search = useMemo(() => getSearchParamsFromLocation(location), [location]);

  const initialFilters = useInitialValue(queryToFilters(search));

  useEffect(() => {
    const filters = queryToFilters(search);
    dispatch(action(filters));

    return () => {
      if (resetOnUnmount && initialFilters) {
        dispatch(action(initialFilters));
      }
    };
  }, [dispatch, action, queryToFilters, search, initialFilters, resetOnUnmount]);

  const filters = useSelector(selector);

  const setFilters = useCallback(
    (newFilters: Partial<FiltersType>) => {
      const updatedFilters = {
        ...filters,
        ...newFilters,
      };

      const query = filtersToQuery(updatedFilters);
      replaceTo(path, { query });
    },
    [filters, path, filtersToQuery]
  );

  const setSearch = useCallback(
    (value?: string, resetOffset?: boolean) => {
      const query = filtersToQuery(filters);
      const additionalQuery = resetOffset ? { offset: 0 } : {};

      replaceTo(path, {
        query: {
          ...query,
          ...additionalQuery,
          freeText: value || undefined,
          offset: undefined,
        },
      });
    },
    [filtersToQuery, filters, path]
  );

  const setSort = useCallback(
    <SortField extends string>(sort: Sort<SortField>) => {
      const query = filtersToQuery(filters);
      replaceTo(path, { query: { ...query, sort: sort.getString() } });
    },
    [filtersToQuery, filters, path]
  );

  const setPagination = useCallback(
    (pagination: Pagination) => {
      const query = filtersToQuery(filters);
      const offset = (pagination.current - 1) * pagination.pageSize;
      const limit = pagination.pageSize;

      return replaceTo(path, { query: { ...query, limit, offset } });
    },
    [filtersToQuery, filters, path]
  );

  const dispatcher: FiltersHookDispatcher<FiltersType, SortField> = useMemo(
    () => ({
      setFilters,
      setSearch,
      setSort,
      setPagination,
    }),
    [setFilters, setSearch, setSort, setPagination]
  );

  return [filters, dispatcher];
};
