import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { appearingAnimation } from 'common/constants';
import { ACCESS_PERMISSIONS } from 'common-v2/constants';
import { Id, MarketingMix } from 'backend-api/models';
import { SMarketingMixWidget } from './s-marketing-mix-widget';
import { activeProjectSelector, projectMarketingMixSelector } from 'project/selectors';
import { Tooltip } from 'common/components/tooltip';
import { PieChartLoader } from 'common/components/loaders/pie-chart-loader';
import { usePermissionsChecker } from 'common-v2/hooks';
import { MarketingMixAnalytics, MarketingMixCategoriesDropdown } from './components';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { ALL_CATEGORIES_OPTION } from './components/marketing-mix-categories-dropdown/constants';
import { LoadingState } from 'common/types';

interface Props {
  projectId: Id;
  className?: string;
  currencyCode?: string;
}

const classes = bem('marketing-mix-widget');

export const MarketingMixWidget = React.memo(({ projectId, className, currencyCode }: Props) => {
  const [selectedMarketingMix, setSelectedMarketingMix] = useState<MarketingMix>();

  const project = useSelector(activeProjectSelector);
  const { loading, data: marketingMix } = useSelector(projectMarketingMixSelector);
  const isLoading = loading === LoadingState.Started;

  const permissionsChecker = usePermissionsChecker(project);

  const canCreateCampaign = useMemo(() => permissionsChecker(ACCESS_PERMISSIONS.CREATE_CAMPAIGN), [permissionsChecker]);

  const onCategoriesChanged = useCallback(
    (marketingMix: MarketingMix) => {
      trackEvent(AnalyticsEvents.MARKETING_MIX_FILTER_APPLIED, {
        project_id: projectId,
        category_name: marketingMix?.name || ALL_CATEGORIES_OPTION.value,
      });
      setSelectedMarketingMix(marketingMix);
    },
    [projectId]
  );

  return (
    <SMarketingMixWidget
      {...appearingAnimation}
      data-selector="marketing-mix-categories-widget"
      showButton={canCreateCampaign}
      className={className}
    >
      {isLoading ? (
        <PieChartLoader className={classes('loader')} />
      ) : (
        <>
          <div className={classes('title-with-dropdown')}>
            <Tooltip content="Shows the distribution of spend across campaign categories and types for this project.">
              <span className={classes('title')}>Marketing Mix</span>
            </Tooltip>
            <MarketingMixCategoriesDropdown
              marketingMix={marketingMix}
              onChange={onCategoriesChanged}
              className={classes('dropdown')}
            />
          </div>
          <MarketingMixAnalytics
            projectId={projectId}
            marketingMix={marketingMix}
            selectedMarketingMix={selectedMarketingMix}
            currencyCode={currencyCode}
          />
        </>
      )}
    </SMarketingMixWidget>
  );
});
