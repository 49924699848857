import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';

export const BEM_CLASS = 'toggle-switch';

export const SToggleSwitch = styled.label`
  height: 24px;
  display: flex;
  user-select: none;
  overflow: hidden;
  position: relative;

  .${BEM_CLASS} {
    &__switch-container {
      position: relative;
      width: 40px;
      height: 100%;
      display: inline-block;
      user-select: none;
      text-align: left;
      overflow: hidden;
      border-radius: 18px;
      background: white;
      z-index: 1;
      cursor: pointer;
    }
    &__checkbox {
      display: none;
      :checked + .${BEM_CLASS}__switch-container {
        .${BEM_CLASS}__inner {
          transform: translateX(0);
        }
        .${BEM_CLASS}__switch {
          transform: translate(16px);
        }
      }

      :disabled + .${BEM_CLASS}__switch-container {
        opacity: 0.6;
      }
    }

    &__inner {
      display: block;
      width: 200%;
      height: 100%;
      transform: translateX(-50%);
      transition: transform 0.3s ease-in 0s;
      &::before,
      &::after {
        display: block;
        float: left;
        width: 50%;
        height: 100%;
      }
      &::before {
        content: '';
        padding-left: 10px;
        background-color: ${colorPalette.wildStrawberry};
      }
      &::after {
        content: '';
        padding-right: 10px;
        background-color: ${colorPalette.lavenderGray};
      }
    }

    &__switch {
      display: block;
      width: 20px;
      margin: 2px;
      background: ${colorPalette.white};
      position: absolute;
      top: 0;
      bottom: 0;
      border: 0 solid ${colorPalette.coolGray};
      border-radius: 20px;
      transform: translate(0);
      transition: transform 0.3s ease-in 0s;
    }
    &__toggle-label {
      align-self: center;
      margin-left: 13px;
      font-size: 12px;
    }
  }
`;
