import { elipsizeText } from 'common/s-components/s-elipsize-text';
import styled from 'styled-components';

export const BEM_CLASS = 'tooltiped-text';

export const STooltipedText = styled.span`
  display: block;
  width: fit-content;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;

  .${BEM_CLASS} {
    &__child {
      display: block;
      max-width: 100%;

      &--ellipsis {
        ${elipsizeText};
      }
    }
  }
`;
