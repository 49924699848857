import React from 'react';
import { Body } from './components';
import { Form as BaseForm } from 'common/containers/form';
import { Footer } from 'app/components/footer';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { ProjectInput } from 'project/actions';
import { Id } from 'backend-api/models';
import { WaveBackground } from 'common/components/wave-background';
import { processingSelector } from 'project/selectors';
import { useSelector } from 'react-redux';

interface Props {
  initialValues?: ProjectInput;

  handleSubmit(values: ProjectInput): void;

  projectId: Id;
}

export const Form = (props: Props) => {
  const { initialValues, handleSubmit, projectId } = props;

  const isProcessing = useSelector(processingSelector);

  return (
    <div>
      <WaveBackground className="app__wrapper">
        <ResponsiveContainer>
          {initialValues && (
            <BaseForm initialValues={initialValues} onSubmit={handleSubmit} useNavigationPrompt={!isProcessing}>
              <Body projectId={projectId} />
            </BaseForm>
          )}
        </ResponsiveContainer>
        <Footer />
      </WaveBackground>
    </div>
  );
};
