import React from 'react';
import { Skeleton } from 'common-v2/components/skeleton';

export const Loader = React.memo(() => (
  <Skeleton width="100%" height={140}>
    <rect x="0" y="0" rx="10px" ry="10px" width="100%" height="40" />
    <rect x="0" y="50" rx="10px" ry="10px" width="100%" height="40" />
    <rect x="0" y="100" rx="10px" ry="10px" width="100%" height="40" />
  </Skeleton>
));
