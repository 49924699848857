import styled from 'styled-components';
import { MenuDropdown } from 'common/components/menu-dropdown';
import { appHeader } from 'app/styles/z-indexes';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const SUserDropdown = styled(MenuDropdown)`
  .dropdown__list {
    z-index: ${appHeader};
    width: 265px;
    border-radius: 10px;
  }

  .menu-dropdown__icon-button {
    min-width: 25px;
  }

  .dropdown__option {
    font-weight: 500;
    &:hover {
      color: ${manhattanThemeColors.textHeaderColor};
      background-color: ${transparentize(0.9, manhattanThemeColors.hoveredBorderColor)};
    }
  }
`;
