import React from 'react';
import { CardLoader } from './card-loader';

interface ArtistCardLoaderProps {
  className?: string;
}

export const ArtistCardLoader = React.memo(({ className }: ArtistCardLoaderProps) => (
  <CardLoader className={className}>
    <rect x="85" y="22.5" rx="2px" ry="2px" width="200" height="20" />
  </CardLoader>
));
