import React, { useMemo } from 'react';
import { Select, THEME } from 'gdb-web-shared-components';
import { SLabelSelector } from './s-label-selector';
import { mapLabelsToOptions } from './transducers';
import { Id, Label } from 'backend-api/models';
import { LabelOption as Option } from './types';
import { LabelTrigger, Loader, LabelOption } from './components';

interface LabelSelectorProps {
  labels?: Label[];
  selectedLabelId?: Id;
  onChange(labelId: Id): void;
  onClick?(): void;
  isEnabled: boolean;
  className?: string;
  dataSelector?: string;
}

export const LabelSelector = React.memo(
  ({ labels, selectedLabelId, onChange, onClick, isEnabled, className, dataSelector }: LabelSelectorProps) => {
    const options = useMemo(() => mapLabelsToOptions(labels), [labels]);

    const components = { Trigger: LabelTrigger, Option: LabelOption };

    const isLoading = !labels;

    return (
      <SLabelSelector className={className} data-selector={dataSelector ?? 'label-selector'} onClick={onClick}>
        {isLoading ? (
          <Loader />
        ) : (
          <Select<Option>
            isDisabled={!isEnabled}
            options={options}
            value={selectedLabelId ?? null}
            onChange={onChange}
            theme={THEME.light}
            isSearchable
            components={components}
          />
        )}
      </SLabelSelector>
    );
  }
);
