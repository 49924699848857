import React from 'react';
import { bem } from 'utils/bem';
import { Badge } from 'common/components/badge';
import { H3 } from 'common/components/typography';
import { SSharingModalTitle, BEM_CLASS } from './s-sharing-modal-title';

interface SharingModalTitleProps {
  artist?: string;
  label?: string;
}

const classes = bem(BEM_CLASS);

export const SharingModalTitle = React.memo(({ artist, label }: SharingModalTitleProps) => (
  <SSharingModalTitle>
    <H3 className={classes('title')} data-selector="sharing-modal-window-title">{`${artist || 'Artist'}'s Team`}</H3>
    {label && <Badge className={classes('badge')} text={label} dataSelector="artists-team-modal-label" />}
  </SSharingModalTitle>
));
