import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { paths } from 'app/routing/paths';
import { MediaPlanMode } from 'media-plan/types';
import { mediaPlanModeSelector } from 'media-plan/selectors';
import { BEM_CLASS, SMediaPlanModeControls } from './s-media-plan-mode-controls';
import { resetCampaignsForApproval } from 'media-plan/actions';
import { ToggleSwitch } from 'common/components/toggle-switch';
import { navigateTo } from 'utils/navigation';

interface MediaPlanModeControlsProps {
  className?: string;
  projectId: number;
  mediaPlanId?: number;
}

const classes = bem(BEM_CLASS);

export const MediaPlanModeControls = React.memo(({ className, projectId, mediaPlanId }: MediaPlanModeControlsProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const mediaPlanMode = useSelector(mediaPlanModeSelector);

  const handleChange = useCallback(() => {
    const query = mediaPlanMode === MediaPlanMode.DEFAULT ? { mode: MediaPlanMode.APPROVAL } : {};
    navigateTo(paths.mediaPlan(projectId, mediaPlanId), { query });
  }, [mediaPlanMode, projectId, mediaPlanId]);

  useEffect(() => {
    dispatch(resetCampaignsForApproval());
  }, [history, dispatch]);

  return (
    <SMediaPlanModeControls className={className}>
      <ToggleSwitch
        className={classes('toggle')}
        dataSelector="media-plan-approval-mode"
        onCheckChange={handleChange}
        label="APPROVAL VIEW MODE"
        checked={mediaPlanMode === MediaPlanMode.APPROVAL}
        disabled={false}
      />
    </SMediaPlanModeControls>
  );
});
