import { loader } from 'app/styles/z-indexes';
import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const SLoadingOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: ${transparentize(0.4, colorPalette.black)};
  z-index: ${loader};
  display: flex;
  justify-content: center;
  align-items: center;
`;
