import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { EXPANDED_CONTAINER_HEIGHT, EXPANDED_CONTAINER_WIDTH } from './constants';

export const BEM_CLASS = 'expandable-links';

interface Props {
  isMaxValue?: boolean;
}

export const SExpandableLinks = styled(({ className, children }) => children(className))<Props>`
  display: flex;
  flex-direction: column;
  width: ${EXPANDED_CONTAINER_WIDTH}px;
  height: ${EXPANDED_CONTAINER_HEIGHT}px;

  &&& {
    .${BEM_CLASS} {
      &__select-container {
        height: 100%;
      }

      &__control {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: unset;
      }

      &__value-container {
        height: 100%;
        padding: 0;
        padding: 10px;
      }

      &__value-counter {
        color: ${({ isMaxValue }) => (isMaxValue ? 'red' : 'black')};
      }

      &__error-notification {
        height: 42px;
        display: flex;
        align-items: center;
        padding: 10px;
      }

      &__divider {
        width: 100%;
        height: 2px;
        background: ${colorPalette.athensGray};
      }

      &__select {
        flex: 1;
        overflow: hidden;
      }
    }
  }
`;
