import React, { ReactNode } from 'react';
import { STab } from '../../s-components/tabs/s-tab';

export interface TabProps<T extends string> {
  title: T;
  badge?: number;
  disabled?: boolean;
  tooltipContent?: React.ReactChild | React.ReactChild[];
  children: ReactNode;
  dataSelector?: string;
  className?: string;
}

export const Tab = <T extends string>({ children, className }: TabProps<T>) => {
  return <STab className={className}>{children}</STab>;
};
