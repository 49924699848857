import { MarketingMix } from 'backend-api/models';
import { BaseGroup, BaseOption } from 'common/components/select';
import { DIGITAL_CATEGORY_IDS } from '../../constants';
import { ALL_CATEGORIES_OPTION } from './constants';

export const getOptions = (marketingMix: MarketingMix[]): BaseGroup<BaseOption>[] => {
  const options: BaseGroup<BaseOption>[] = [{ isFirst: true, options: [ALL_CATEGORIES_OPTION] }];

  const digitalMarketingMix = marketingMix
    .filter(({ id }) => DIGITAL_CATEGORY_IDS.includes(id))
    .map(({ id, name }) => ({
      id,
      value: name,
    }));

  if (digitalMarketingMix.length > 0) {
    options.push({
      label: 'Digital',
      options: digitalMarketingMix,
    });
  }

  const otherMarketingMix = marketingMix
    .filter(({ id, subtypes }) => !DIGITAL_CATEGORY_IDS.includes(id) && subtypes.length > 0)
    .map(({ id, name }) => ({
      id,
      value: name,
    }));

  if (otherMarketingMix.length > 0) {
    options.push({
      label: 'Others',
      options: otherMarketingMix,
    });
  }

  const lastGroup = options[options.length - 1];

  if (lastGroup) {
    lastGroup.isLast = true;
  }

  return options;
};
