import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebounce } from 'react-use';
import { LabelPermissionsMember } from 'backend-api/models';
import { teamMembersSelector } from 'label-permissions/selectors';
import { teamMemberPredicate } from '../transducers';

export const useTeamMembers = (
  search: string
): {
  teamMembers: LabelPermissionsMember[];
  isSearchActive: boolean;
} => {
  const { data: teamMembers } = useSelector(teamMembersSelector);

  const [filteredTeamMembers, setFilteredTeamMembers] = useState(teamMembers);
  const [isSearchActive, setIsSearchActive] = useState(search.length > 0);

  useEffect(() => {
    if (!Boolean(search)) {
      setIsSearchActive(search.length > 0);
      setFilteredTeamMembers(teamMembers);
    }
  }, [search, teamMembers]);

  useDebounce(
    () => {
      if (Boolean(search)) {
        setIsSearchActive(search.length > 0);
        setFilteredTeamMembers(teamMembers.filter(teamMemberPredicate(search)));
      }
    },
    300,
    [search, teamMembers]
  );

  return {
    teamMembers: filteredTeamMembers,
    isSearchActive,
  };
};
