import React from 'react';
import { bem } from 'utils/bem';
import { Intercom } from 'app/components/intercom';
import { TopHeaderOutdated } from 'app/components/top-header-outdated';
import { Toasts } from 'common/components/toasts';
import { SOutdatedGlobalLayout, BEM_CLASS } from './s-outdated-global-layout';

const classes = bem(BEM_CLASS);

export const OutdatedGlobalLayout: React.FC = ({ children }) => (
  <SOutdatedGlobalLayout>
    <TopHeaderOutdated />
    <div className={classes('top-holder')} />
    <div className={classes('wrapper')}>
      {children}
      <Toasts />
      <Intercom />
    </div>
  </SOutdatedGlobalLayout>
);
