import { isArtistTeamEditor } from 'common/transducers';
import { makePermissionsChecker } from 'common-v2/transducers';
import { MediaPlanAccessMode } from 'media-plan-v2/containers/spreadsheet/types';
import { artistTeamSelector } from 'media-plan-v2/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getProjectArtistTeam } from 'media-plan-v2/actions';
import { Id } from 'backend-api/models';
import { MediaPlanMode } from 'media-plan-v2/types';
import { ACCESS_PERMISSIONS } from 'common-v2/constants';
import { useAppContext } from 'common-v2/hooks';
import { projectDetailsSelector } from 'project-v2/selectors';
import { LoadingState } from 'common/types';

export const useMediaPlanAccessMode = (projectId: Id, mediaPlanMode: MediaPlanMode): MediaPlanAccessMode => {
  const dispatch = useDispatch();
  const { user } = useAppContext();

  const { loading, data: project } = useSelector(projectDetailsSelector);
  const artistTeam = useSelector(artistTeamSelector);
  const isNotConfidential = !project?.isConfidential;
  const isTeamEditor = user && isArtistTeamEditor(user, artistTeam.data?.users || []);
  const isAdminOrEditor = makePermissionsChecker(project)(ACCESS_PERMISSIONS.EDIT_MEDIA_PLAN);
  const isRequestApproval = mediaPlanMode === MediaPlanMode.REQUEST_APPROVAL;
  const isApproval = mediaPlanMode === MediaPlanMode.APPROVAL;

  useEffect(() => {
    if (artistTeam.loading === LoadingState.Idle) dispatch(getProjectArtistTeam.request(projectId));
  }, [artistTeam, dispatch, loading, project, projectId]);

  if ((isAdminOrEditor && !isRequestApproval) || isApproval) return MediaPlanAccessMode.EDIT;
  if (isTeamEditor && isNotConfidential && !isRequestApproval) return MediaPlanAccessMode.EDIT;

  return MediaPlanAccessMode.READ_ONLY;
};
