import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

interface SUserAvatarProps {
  size: number;
  isDark: boolean;
}

export const SUserAvatar = styled.div<SUserAvatarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background: ${({ isDark }) => (isDark ? colorPalette.eastBay : colorPalette.lavenderGray)};
  color: ${colorPalette.white};
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.24px;
  line-height: 14px;
  text-transform: uppercase;
  box-shadow: 0 0 0 2px ${colorPalette.white};
`;
