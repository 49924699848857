import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'budget-main-info';

interface Props {
  progressBarWidth: number;
}

export const SBudgetMainInfo = styled.div<Props>`
  margin-top: 19px;
  display: flex;
  width: 100%;
  align-items: center;
  .${BEM_CLASS} {
    &__allocated-and-total-container {
      display: flex;
      align-items: center;
    }
    &__progress-and-total-container {
      display: flex;
      align-items: center;
      margin-left: 22px;
      width: 100%;
    }

    &__allocated {
      margin-left: 26px;
    }

    &__utilization {
      margin-left: 20px;
    }

    &__progress-bar {
      display: flex;
      width: 100%;
      background-color: ${colorPalette.athensGray};
      height: 5px;
      margin-bottom: -15px;
    }

    &__filled-progress-bar {
      width: ${props => props.progressBarWidth}%;
      background-color: ${colorPalette.wildStrawberry};
    }
  }
`;
