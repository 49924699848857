import styled from 'styled-components';
import { motion } from 'framer-motion';
import { EMPTY_TABLE_HEIGHT } from '../../components/table';
import { TABLE_NOTIFICATION_HEIGHT, TABLE_HEADER_HEIGHT } from 'common/components/table/constants';
import { FOOTER_ROW_HEIGHT } from './s-footer';
import { TableTheme } from 'app/theme/table';

interface STableProps {
  readonly minHeight: number;
  readonly isScrollable?: boolean;
  readonly hasSticky?: boolean;
  readonly hasContent?: boolean;
  readonly tableTheme: TableTheme;
  readonly withoutRoundCorners?: boolean;
  readonly paginationHeight: number;
}

const FOOTER_DIVIDER_VERTICAL_MARGIN = 10;
const TABLE_BORDER_SPACING = 2;
const RESIZE_HANDLER_OFFSET = FOOTER_ROW_HEIGHT + TABLE_BORDER_SPACING;

export const STable = styled(motion.div)<STableProps>`
  position: relative;

  .table-container {
    &__notification {
      position: absolute;
      z-index: 14;
      top: ${TABLE_HEADER_HEIGHT}px;
      left: 0;
      width: 100%;
      height: ${TABLE_NOTIFICATION_HEIGHT}px;
      background-color: white;
    }
    &__scrolling-tables-container {
      display: flex;
      align-items: flex-start;
    }
    &__container {
      height: ${({ hasContent, isScrollable }) =>
        !hasContent && !isScrollable ? `${EMPTY_TABLE_HEIGHT}px` : isScrollable ? '0px' : 'max-content'};
      min-height: ${({ minHeight, hasContent, isScrollable }) => {
        if (isScrollable) return 'unset';
        return !hasContent ? `${EMPTY_TABLE_HEIGHT}px` : `${minHeight}px`;
      }};
      background: ${({ tableTheme, theme }) => theme.table[tableTheme].tableBackgroundColor};
      border-radius: ${({ withoutRoundCorners }) => (withoutRoundCorners ? `unset` : '10px')};
      overflow: hidden;
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      overflow-y: ${({ isScrollable }) => (isScrollable ? 'auto' : '')};
      overflow-x: ${({ isScrollable }) => (isScrollable ? 'auto' : '')};
    }
    &__sticked-table-wrapper {
      position: sticky;
      left: 0;
      height: 100%;
      z-index: 12;
    }
    &__sticked-table {
      width: 100%;
      height: 100%;
      table-layout: fixed;
      text-align: left;
      border-top-left-radius: 10px;
      display: table;
      resize: horizontal;
      border-collapse: collapse;
    }
    &__resize-handle {
      background-color: rgba(184, 188, 217, 0.3);
      box-shadow: 1px 0 5px 1px rgba(222, 222, 228, 0.8);
      cursor: col-resize;
      position: absolute;
      width: 1px;
      min-width: 1px;
      height: ${({ paginationHeight }) =>
        `calc(100% - ${paginationHeight ? paginationHeight + RESIZE_HANDLER_OFFSET : RESIZE_HANDLER_OFFSET}px)`};
      z-index: 13;

      &:active {
        background-color: rgba(1, 156, 254, 1);
      }
    }
    &__footer-divider {
      height: 40px;
      width: 1px;
      background-color: rgba(228, 229, 240, 1);
      position: absolute;
      bottom: ${({ paginationHeight }) =>
        `${paginationHeight ? paginationHeight + FOOTER_DIVIDER_VERTICAL_MARGIN : FOOTER_DIVIDER_VERTICAL_MARGIN}px`};
      z-index: 13;
    }
    &__table {
      height: ${({ hasContent }) => (!hasContent ? `100%` : 'unset')};
      width: 100%;
      table-layout: fixed;
      text-align: left;
      border-radius: ${({ hasSticky }) => (hasSticky ? 0 : 10)}px 10px 0 0;
      border-collapse: collapse;
      &:first-child {
        padding: 0 20px;
      }

      &--scrollable {
        overflow-x: auto;
        position: relative;
        overflow-y: visible;
        height: 100%;
        &:first-child {
          padding: 0;
        }
      }
    }
    &__pagination-divider {
      height: 1px;
      background: rgb(223, 224, 237);
      width: 100%;
    }
  }
`;
