import { BaseOption } from '../select';
import { TreeItemType, TreeNode } from '../../tree-list';

export const mapOptionsToNode = (options: BaseOption[]): TreeNode[] =>
  options.map(option => ({
    id: option.id || 0,
    name: option.name || option.label || '',
    description: option.description,
    disabledDescription: option.disabledDescription,
    children: mapOptionsToNode(option.options || []),
    isDisabled: option.isDisabled,
    type: option.label === undefined ? TreeItemType.CHECKBOX : TreeItemType.LABEL,
  }));
