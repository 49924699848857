import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'login';

export const SLogin = styled.div`
  height: 100vh;

  .${BEM_CLASS} {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: inherit;
      min-height: 0;
      text-align: center;
    }

    &__logo {
      width: 176px;
      margin-bottom: 30px;
      flex-shrink: 0;
    }

    &__button {
      position: relative;
      width: 180px;
      height: 50px;
      flex-shrink: 0;
      border-radius: 25px;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 1px;
      background-color: ${colorPalette.wildStrawberry};
      color: ${colorPalette.white};

      &--loading {
        pointer-events: none;
      }

      &:active {
        transform: scale(0.95);
      }
    }
  }
`;
