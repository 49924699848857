import { apiClient, ResponseArray } from '../index';
import { ArtistSearchSchema, GlobalSearchSchema, PlaylistSchema, RecentSearchItemSchema } from '../models';
import { GlobalSearchParams, RecentSearchParams } from '../types';
import { PLAYLIST_SEARCH_LIMIT, ARTIST_SEARCH_LIMIT } from '../constants';

export const getGlobalSearch = (params: GlobalSearchParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/global-search',
    responseSchema: GlobalSearchSchema,
    queryParams: params,
  });

export const getRecentGlobalSearch = (params: RecentSearchParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/global-search/recent',
    responseSchema: ResponseArray(RecentSearchItemSchema),
    queryParams: params,
  });

export const searchArtist = (query: string) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/artists',
    queryParams: { query, limit: ARTIST_SEARCH_LIMIT },
    responseSchema: ResponseArray(ArtistSearchSchema),
  });

export const searchPlaylists = (query: string) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/playlists',
    queryParams: { query, limit: PLAYLIST_SEARCH_LIMIT },
    responseSchema: ResponseArray(PlaylistSchema),
  });
