export const ALL_OPTION_ID = -999;

export const MAX_MENU_HEIGHT = 300;

export const OPTION_HEIGHT = 21;
export const OPTION_HORIZONTAL_PADDING = 20;
export const OPTION_VERTICAL_PADDING = 5;

export const DIVIDER_HEIGHT = 1;
export const DIVIDER_VERTICAL_MARGIN = 10;
