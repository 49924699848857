import {
  LabelPermissionsArtistWithMembers,
  Id,
  LabelPermissionsCounters,
  LabelPermissionsMember,
  ShortArtist,
  LabelUser,
} from 'backend-api/models';
import { actionsGroup } from 'core/store';
import {
  EditArtistsModalPayload,
  EditArtistsRequestPayload,
  EditMembersModalPayload,
  EditMembersRequestPayload,
} from './types';

const { action, asyncAction } = actionsGroup('label-permissions');

export const getLabelCounters = asyncAction<Id, LabelPermissionsCounters, Error>('GET_LABEL_COUNTERS');

export const getLabelTeamMembers = asyncAction<Id, LabelPermissionsMember[], Error>('GET_LABEL_TEAM_MEMBERS');

export const openEditArtistsModal = action<EditArtistsModalPayload>('OPEN_EDIT_ARTISTS_MODAL');
export const closeEditArtistsModal = action<void>('CLOSE_EDIT_ARTISTS_MODAL');

export const getLabelArtists = asyncAction<Id, ShortArtist[], Error>('GET_LABEL_ARTISTS');

export const setArtistsSearch = action<string>('SET_ARTISTS_SEARCH');

export const addRecentArtist = action<ShortArtist>('ADD_RECENT_ARTIST');
export const removeRecentArtist = action<string>('REMOVE_RECENT_ARTIST');
export const removeArtist = action<string>('REMOVE_ARTIST');
export const clearAllRecentArtists = action<void>('CLEAR_ALL_RECENT_ARTISTS');
export const addAllArtists = action<ShortArtist[]>('ADD_ALL_ARTISTS');

export const editUserArtists = asyncAction<EditArtistsRequestPayload, unknown, Error>('EDIT_ARTISTS');
export const getLabelArtistsList = asyncAction<Id, LabelPermissionsArtistWithMembers[], Error>(
  'GET_LABEL_ARTISTS_LIST'
);

export const openEditMembersModal = action<EditMembersModalPayload>('OPEN_EDIT_MEMBERS_MODAL');
export const closeEditMembersModal = action<void>('CLOSE_EDIT_MEMBERS_MODAL');

export const getLabelUsers = asyncAction<Id, LabelUser[], Error>('GET_LABEL_USERS');

export const setMembersSearch = action<string>('SET_MEMBERS_SEARCH');

export const addRecentMember = action<LabelUser>('ADD_RECENT_MEMBER');
export const removeRecentMember = action<Id>('REMOVE_RECENT_MEMBER');
export const removeMember = action<Id>('REMOVE_MEMBER');
export const clearAllRecentMembers = action<void>('CLEAR_ALL_RECENT_MEMBERS');
export const addAllMembers = action<LabelUser[]>('ADD_ALL_MEMBERS');

export const editArtistMembers = asyncAction<EditMembersRequestPayload, unknown, Error>('EDIT_MEMBERS');
