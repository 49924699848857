import { BgStyle } from 'common/styles/utils';
import { OptionalPeriod, Period } from 'common/types';

export enum DatePickerMode {
  SINGLE,
  RANGE,
}

interface BaseComponentsProps<T> {
  value?: T;
  onChange?(value: T): void;
  mode: DatePickerMode;
  alignment?: PopupAlignment;
  annotationMessage?: string;
  disabled?: boolean;
}

export interface RangeComponentsProps extends BaseComponentsProps<OptionalPeriod> {
  mode: DatePickerMode.RANGE;
}

export interface SingleComponentsProps extends BaseComponentsProps<Date> {
  mode: DatePickerMode.SINGLE;
}

export interface InnerDatePickerProps {
  bgStyle?: BgStyle;
  availableDates?: Period[] | Period;
  dataSelector?: string;
  className?: string;
  inputContainerClassName?: string;
  inputClassName?: string;
  withInputIcon?: boolean;
}

export interface RangeDatePickerProps extends RangeComponentsProps {
  renderSidebar?: (hidePopup: () => void) => JSX.Element;
  maxPeriodDays?: number;
}

export interface DatePickerPopupProps extends RangeComponentsProps {
  sidebar?: JSX.Element;
  maxPeriodDays?: number;
}

export type SingleDatePickerProps = SingleComponentsProps;

export type DatePickerProps = (RangeDatePickerProps | SingleDatePickerProps) & InnerDatePickerProps;

export interface PopupPosition {
  top: number;
  left: number;
}

export enum PopupAlignment {
  Right,
  Left,
  Auto,
}

export enum HeaderType {
  RIGHT,
  LEFT,
}
