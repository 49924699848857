import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'label-permissions';

export const SLabelPermissions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .${BEM_CLASS} {
    &__title {
      display: flex;
      align-items: center;
      gap: 7px;
      padding: 20px 40px 0;
    }

    &__icon {
      color: ${transparentize(0.7, manhattanThemeColors.primaryDark)};
    }

    &__container {
      padding: 20px 40px;
    }

    &__search-field {
      width: 410px;
      padding-bottom: 15px;
    }

    &__input {
      width: 100%;
    }
  }
`;
