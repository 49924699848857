import React, { useMemo, useCallback } from 'react';
import { bem } from 'utils/bem';
import { ArtistTeam, Label, CurrentUserInfo } from 'backend-api/models';
import { Button } from 'common/components/button';
import { Icon } from 'common/components/icon';
import { Tooltip } from 'common/components/tooltip';
import { SmallText } from 'common/components/typography';
import { Team } from 'common/components/team';
import { SSingleTeamEntry, BEM_CLASS } from './s-single-team-entry';
import { isArtistTeamEditor } from 'common/transducers';

interface SingleTeamEntryProps {
  label: Label;
  user: CurrentUserInfo;
  team?: ArtistTeam;
  onEditTeam(team: ArtistTeam): void;
  onCreateTeam(label: Label): void;
  onViewTeam(team: ArtistTeam): void;
}

const classes = bem(BEM_CLASS);

export const SingleTeamEntry = React.memo(
  ({ label, user, team, onViewTeam, onCreateTeam, onEditTeam }: SingleTeamEntryProps) => {
    const onViewTeamClick = useCallback(() => team && onViewTeam(team), [onViewTeam, team]);
    const onCreateTeamClick = useCallback(() => onCreateTeam(label), [onCreateTeam, label]);
    const onEditTeamClick = useCallback(() => team && onEditTeam(team), [onEditTeam, team]);

    const button = useMemo(() => {
      if (team) {
        const isTeamEditor = isArtistTeamEditor(user, team.users);

        return user.isAdmin || isTeamEditor ? (
          <Button onClick={onEditTeamClick} dataSelector="edit-artist-team-button">
            <Icon name="edit" />
            Edit team
          </Button>
        ) : (
          <Button onClick={onViewTeamClick} dataSelector="view-artist-team-button">
            View team
          </Button>
        );
      } else {
        return user.isAdmin ? (
          <Tooltip content={`Create a team for ${label.name}`}>
            <Button variant="primary-cta" onClick={onCreateTeamClick} dataSelector="create-artist-team-button">
              <Icon name="plus" />
              Create team
            </Button>
          </Tooltip>
        ) : null;
      }
    }, [label.name, onCreateTeamClick, onEditTeamClick, onViewTeamClick, team, user]);

    return (
      <SSingleTeamEntry>
        {team && (
          <>
            <SmallText className={classes('label')} data-selector="single-team-label">
              {label.name}
            </SmallText>
            <Team className={classes('members')} users={team.users} dataSelector="single-team" />
          </>
        )}
        {button}
      </SSingleTeamEntry>
    );
  }
);
