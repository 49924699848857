import React, { useCallback } from 'react';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { Optional, ProjectSharing, SimpleProjectUser } from 'backend-api/models';
import FailedToLoadErrorImage from 'assets/error_failed_to_load.png';
import { Loadable, Loading } from 'common-v2/types';
import { ErrorView } from 'common-v2/components';
import { MemberItem } from '../member-item';
import { MembersList } from '../members-list';
import { MembersBlock } from '../members-block';
import { Loader } from '../loader';
import { SViewMode } from './s-view-mode';

interface EditModeProps {
  sharingInfo: Loadable<Optional<ProjectSharing>>;
  isConfidential: boolean;
  hasLockedArtist: boolean;
  refreshSharingInfo(): void;
}

export const ViewMode = React.memo(
  ({ sharingInfo, isConfidential, hasLockedArtist, refreshSharingInfo }: EditModeProps) => {
    const renderMember = useCallback(
      (member: SimpleProjectUser) => (
        <MemberItem
          member={member}
          roleNode={
            <Typography type={TYPOGRAPHY_TYPE.body4} data-selector="share-modal-member-item-role">
              {member.role.name}
            </Typography>
          }
        />
      ),
      []
    );

    const renderBlocks = useCallback(
      ({ projectTeam, approvers, artistTeamAndAdmins }: ProjectSharing) => [
        projectTeam.length > 0 ? (
          <MembersBlock
            title="Project Team"
            members={projectTeam}
            renderMember={renderMember}
            dataSelector="project-team"
          />
        ) : null,

        approvers.length > 0 ? (
          <MembersBlock
            title="Project Approvers"
            members={approvers}
            renderMember={renderMember}
            dataSelector="approvers-team"
          />
        ) : null,

        artistTeamAndAdmins.length > 0 ? (
          <MembersBlock
            title={isConfidential || !hasLockedArtist ? 'Label Admins' : 'Artist Team & Label Admins'}
            members={artistTeamAndAdmins}
            renderMember={renderMember}
            dataSelector="artist-team-and-admins"
          />
        ) : null,
      ],
      [hasLockedArtist, isConfidential, renderMember]
    );

    const renderContent = () => {
      switch (sharingInfo.loading) {
        case Loading.Idle:
        case Loading.Started:
          return <Loader />;
        case Loading.Finished:
          return <MembersList data={sharingInfo.data} renderBlocks={renderBlocks} />;
        case Loading.Failed:
          return (
            <ErrorView
              config={{
                bottomImageSrc: FailedToLoadErrorImage,
                description: (
                  <span>
                    An error occurred while loading Project Team
                    <br />
                    data. Please try again
                  </span>
                ),
                actionTitle: 'Refresh',
              }}
              action={refreshSharingInfo}
            />
          );
      }
    };

    return <SViewMode>{renderContent()}</SViewMode>;
  }
);
