import styled from 'styled-components';
import { typography } from 'app/theme/typography';
import { manhattanThemeColors } from 'app/theme/colors';

export const SEmptyTableView = styled.div`
  ${typography.smallText};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${manhattanThemeColors.grayedText};
`;
