import { ProjectPath } from 'app/routing/types';
import { ProjectTab, TargetOption, ProjectTabView } from './types';

export const PROJECT_TABS: ProjectTabView[] = [{ value: ProjectTab.MediaPlan, label: 'Media Plans' }];

export const VIEWER_ONLY_PROJECT_TABS: ProjectTabView[] = [{ value: ProjectTab.MediaPlan, label: 'Media Plans' }];

export const PROJECT_PATH_TO_TAB_MAP: Record<ProjectPath, ProjectTab> = {
  [ProjectPath.mediaPlans]: ProjectTab.MediaPlan,
  [ProjectPath.mediaPlan]: ProjectTab.MediaPlan,
  [ProjectPath.reporting]: ProjectTab.Reporting,
  [ProjectPath.finance]: ProjectTab.Finance,
};

export const TARGET_OPTIONS: TargetOption[] = [
  {
    id: 1,
    name: 'Artist',
  },
  {
    id: 2,
    name: 'Playlist',
  },
];
