import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { confirmationModalOverlay } from 'app/styles/z-indexes';
import { bgStyle } from 'common/styles/utils';
import { ReusableModal } from './reusable-modal';
import { ReusableModalProps } from './types';
import {
  CLOSE_ICON_TOP_OFFSET,
  CLOSE_ICON_RIGHT_OFFSET,
  DEFAULT_MODAL_WIDTH,
  MODAL_SIDEBAR_TOP_PADDING,
  MODAL_SPACING,
  MODAL_MIN_WIDTH,
} from './constants';

export const REUSABLE_MODAL_BEM = 'reusable-modal';

export const SReusableModal = styled(ReusableModal)<ReusableModalProps>`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .${REUSABLE_MODAL_BEM} {
    &__react-modal-overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: ${confirmationModalOverlay};
      height: 100%;
      overflow: auto;
      background-color: rgba(0, 0, 0, 0.65);
      display: flex;
      padding: ${MODAL_SPACING}px;
    }
    &__react-modal-content {
      width: ${({ width = `${DEFAULT_MODAL_WIDTH}px` }) => width};
      min-width: ${MODAL_MIN_WIDTH}px;
      margin: auto;
      border-radius: 12px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
      background: ${bgStyle};
      overflow: hidden;
    }
    &__container {
      display: flex;
      flex-direction: column;
      position: relative;
      height: ${({ height = 'auto' }) => height};
    }
    &__content-with-sidebar {
      flex: 1;
      display: flex;
      overflow: hidden;
    }
    &__content {
      max-width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    &__sidebar {
      padding-top: ${MODAL_SIDEBAR_TOP_PADDING}px;
    }
    &__divider {
      height: 1px;
      background: ${palette.athensGrayTwo};
    }
    &__close-icon {
      position: absolute;
      cursor: pointer;
      top: ${CLOSE_ICON_TOP_OFFSET}px;
      right: ${CLOSE_ICON_RIGHT_OFFSET}px;
    }
  }
`;
