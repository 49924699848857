import { actions, constants } from 'ducks-helpers';
import { handleActions } from 'redux-actions';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';

export const TYPE = constants('auth', [
  '~LOGIN',
  'SET_TOKEN',
  'UNAUTHORIZED',
  '~LOGOUT',
  'SET_AUTH0_ERROR',
  '~CHECK_SESSION',
  'START_POLLING_SESSION',
  'STOP_POLLING_SESSION',
  '~LOGIN_AMPLITUDE_EVENT',
  'LOGOUT_SUCCESS_AMPLITUDE',
  //†type
]);
export const ACTION = actions(TYPE);

export const initialState = {
  auth0: null,
  checkingTime: null,
  //†init
};

const persistConfig = {
  key: 'auth',
  storage,
  // stateReconciler: autoMergeLevel2,
  // debug: true,
  whitelist: ['auth0', 'checkingTime'],
  blacklist: ['auth0Error'],
};

export const reducer = persistReducer(
  persistConfig,
  handleActions(
    {
      [TYPE.LOGIN]: login,
      [TYPE.LOGIN_SUCCESS]: loginSuccess,
      [TYPE.LOGIN_ERROR]: loginError,
      [TYPE.SET_TOKEN]: setToken,
      [TYPE.UNAUTHORIZED]: unauthorized,
      [TYPE.LOGOUT]: logout,
      [TYPE.LOGOUT_SUCCESS]: logoutSuccess,
      [TYPE.LOGOUT_ERROR]: logoutError,
      [TYPE.SET_AUTH0_ERROR]: setAuth0Error,
      [TYPE.CHECK_SESSION]: checkSession,
      [TYPE.CHECK_SESSION_SUCCESS]: checkSessionSuccess,
      [TYPE.CHECK_SESSION_ERROR]: checkSessionError,
      [TYPE.START_POLLING_SESSION]: startPollingSession,
      [TYPE.STOP_POLLING_SESSION]: stopPollingSession,
      [TYPE.LOGIN_AMPLITUDE_EVENT]: loginAmplitudeEvent,
      [TYPE.LOGIN_AMPLITUDE_EVENT_SUCCESS]: loginAmplitudeEventSuccess,
      [TYPE.LOGIN_AMPLITUDE_EVENT_ERROR]: loginAmplitudeEventError,
      [TYPE.LOGOUT_SUCCESS_AMPLITUDE]: logoutSuccessAmplitude,
      //†handler
    },
    initialState,
  ),
);

function login(state /*, { payload }*/) {
  return state;
}

function loginSuccess(state /*, { payload }*/) {
  return state;
}

function loginError(state /*, { payload }*/) {
  return state;
}

function setToken(state, { payload }) {
  return {
    ...state,
    checkingTime: Date.now(),
    auth0: {
      ...payload,
      expiresAt: payload.expiresIn * 1000 + new Date().getTime(),
    },
  };
}

function unauthorized(/* state, { payload } */) {
  return {
    ...initialState,
  };
}

function logout(/* state, { payload } */) {
  return {
    ...initialState,
  };
}

function logoutSuccess(state /*, { payload }*/) {
  return state;
}

function logoutError(state /*, { payload }*/) {
  return state;
}

function setAuth0Error(state, { payload }) {
  return {
    ...state,
    auth0Error: payload,
  };
}

function checkSession(state /*, { payload }*/) {
  return state;
}

function checkSessionSuccess(state /*, { payload }*/) {
  return {
    ...state,
    checkingTime: Date.now(),
  };
}

function checkSessionError(state /*, { payload }*/) {
  return state;
}

function startPollingSession(state /*, { payload }*/) {
  return state;
}

function stopPollingSession(state /*, { payload }*/) {
  return state;
}

function loginAmplitudeEvent(state /*, { payload }*/) {
  return state;
}

function loginAmplitudeEventSuccess(state /*, { payload }*/) {
  return state;
}

function loginAmplitudeEventError(state /*, { payload }*/) {
  return state;
}

function logoutSuccessAmplitude(state /*, { payload }*/) {
  return state;
}

//†reducer
