import React from 'react';
import { bem } from 'utils/bem';
import appLoaderGif from 'assets/app-loader.gif';
import { ErrorView, ErrorViewStates } from 'common-v2/components';
import { SAppLoader, BEM_CLASS } from './s-app-loader';

interface AppLoaderProps {
  className?: string;
  hasError?: boolean;
}

const classes = bem(BEM_CLASS);

export const AppLoader = React.memo(({ className, hasError = false }: AppLoaderProps) => (
  <SAppLoader className={className}>
    {hasError ? (
      <ErrorView config={ErrorViewStates.globalError} />
    ) : (
      <img className={classes('loader')} src={appLoaderGif} />
    )}
  </SAppLoader>
));
