import { Body, H2, H4 } from 'common/components/typography';
import React, { useCallback } from 'react';
import { BEM_CLASS, SProfileInfo } from './s-profile-info';
import { bem } from 'utils/bem';
import { CurrentUserInfo, Label } from 'backend-api/models';
import { LabelsList } from './components';
import { FlatInput } from 'common/components/form/flat-input';

interface Props {
  className?: string;
  userInfo: CurrentUserInfo;
  labels: Label[];
  onPhoneBlur(phone: string): void;
  phone: string;
}

const classes = bem(BEM_CLASS);

export const ProfileInfo = React.memo(({ className, userInfo, labels, onPhoneBlur, phone }: Props) => {
  const formattedPhone = phone.startsWith('+') || !phone ? phone : `+${phone}`;
  const valueChangeValidator = useCallback((value: string) => {
    return !!Number(value) || !value.replace('+', '');
  }, []);

  const handleBlur = useCallback(
    (value: string) => {
      return onPhoneBlur(value.replace('+', ''));
    },
    [onPhoneBlur]
  );

  return (
    <SProfileInfo className={className} data-selector="profile-info-container">
      <H4 className={classes('title')} data-selector="profile-info-title">
        Profile Information
      </H4>
      <div className={classes('card')} data-selector="profile-info-card-container">
        <div className={classes('avatar')} data-selector="profile-avatar-container" />
        <H2 className={classes('name')} data-selector="profile-name">
          {userInfo.name}
        </H2>
        <div className={classes('contact-info-container')} data-selector="profile-contact-info-container">
          <Body data-selector="profile-email">{userInfo.email}</Body>
          <div className={classes('dot')} />
          <FlatInput
            iconRight="edit"
            onBlur={handleBlur}
            value={formattedPhone}
            maxLength={15}
            placeholder="Enter phone number"
            valueChangeValidator={valueChangeValidator}
            className={classes('phone-input')}
            dataSelector="user-phone"
          />
        </div>
        <LabelsList labels={labels} isAdmin={userInfo.isAdmin} />
      </div>
    </SProfileInfo>
  );
});
