import { Id, MediaPlanListItem, Optional } from 'backend-api/models';
import { MAX_MEDIA_PLANS_COUNT } from './constants';

export const validateNewMediaPlan = (
  name: Optional<string>,
  selectedMediaPlanId: Optional<Id>,
  allMediaPlans: MediaPlanListItem[]
): Optional<string> => {
  if (!name) {
    return 'Name cannot be empty';
  }

  if (allMediaPlans.some(item => item.name === name && item.id !== selectedMediaPlanId)) {
    return 'This name is already taken';
  }

  return undefined;
};

export const getFooterTooltip = (allMediaPlans: MediaPlanListItem[], editable?: boolean): Optional<string> => {
  if (!editable) {
    return "You don't have permissions to add Media Plans";
  }

  if (allMediaPlans.length === MAX_MEDIA_PLANS_COUNT) {
    return 'Maximum 15 plans per Project';
  }

  return undefined;
};
