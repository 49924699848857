import styled from 'styled-components';

export const SShowAllTargetsModal = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;

  .show-all-targets-modal {
    &__targets-container {
      display: grid;
      grid-template-columns: repeat(3, minmax(250px, 1fr));
      grid-gap: 10px;
    }
    &__artist-type-section {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }
    &__artist-type-title {
      color: #4d5280;
      font-size: 10px;
      letter-spacing: 1px;
      grid-column: 1/-1;
      line-height: 15px;
      text-transform: uppercase;
      margin: 4px 0 16px;
    }
  }
`;
