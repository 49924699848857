import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BulkCampaignsAssignmentModal } from '../components/modal';
import { ArtistRosterProject, Campaign, ProjectDetails } from 'backend-api/models';
import {
  assignCampaignsToProject,
  clearAllSelectedCampaigns,
  closeModal,
  resetModal,
  updateCampaignsFilters,
} from '../actions';
import { campaignsFiltersSelector, isBulkCampaignAssignmentModalOpened, selectedCampaignsSelector } from '../selectors';
import { showSuccessToast } from 'common/components/toast';
import { pluralizeString } from 'utils/string';

interface Props {
  project: ProjectDetails | ArtistRosterProject;
  onCampaignsAssign?(): void;
  onCampaignsAssigned(campaigns: Campaign[]): void;
}

export const BulkCampaignsAssignmentModalContainer = React.memo(
  ({ project, onCampaignsAssign, onCampaignsAssigned }: Props) => {
    const dispatch = useDispatch();

    const isOpened = useSelector(isBulkCampaignAssignmentModalOpened);
    const selectedCampaigns = useSelector(selectedCampaignsSelector);
    const filters = useSelector(campaignsFiltersSelector);

    const onCloseModal = useCallback(() => {
      dispatch(closeModal());
      dispatch(resetModal());
    }, [dispatch]);

    const onResetSelectedCampaigns = useCallback(() => dispatch(clearAllSelectedCampaigns()), [dispatch]);

    const onCampaignsAssignedSuccessfully = useCallback(() => {
      const campaignsCount = selectedCampaigns.length;
      showSuccessToast(
        `${campaignsCount} ${pluralizeString('campaign', campaignsCount)} successfully assigned to this project.`
      );
      onCampaignsAssigned(selectedCampaigns);
      dispatch(resetModal());
    }, [dispatch, onCampaignsAssigned, selectedCampaigns]);

    const onAssignCampaigns = useCallback(() => {
      onCampaignsAssign && onCampaignsAssign();

      const campaignUuids = selectedCampaigns.map(({ uuid }) => uuid);
      const projectId = project.id;
      dispatch(
        assignCampaignsToProject.request({
          projectId,
          campaignUuids,
          onCampaignsAssigned: onCampaignsAssignedSuccessfully,
        })
      );
      dispatch(closeModal());
    }, [dispatch, onCampaignsAssign, onCampaignsAssignedSuccessfully, project.id, selectedCampaigns]);

    const updateSearchFilter = useCallback(
      (query: string) => {
        dispatch(updateCampaignsFilters({ search: query }));
      },
      [dispatch]
    );

    const onModalClosed = useCallback(() => {
      dispatch(resetModal());
    }, [dispatch]);

    return (
      <BulkCampaignsAssignmentModal
        project={project}
        isOpened={isOpened}
        onCloseModal={onCloseModal}
        onSearchChange={updateSearchFilter}
        selectedCampaigns={selectedCampaigns}
        resetAllSelectedCampaigns={onResetSelectedCampaigns}
        onAssignCampaigns={onAssignCampaigns}
        searchTerm={filters.search}
        onModalClosed={onModalClosed}
      />
    );
  }
);
