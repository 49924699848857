import { apiClient, EmptyPayload, EmptySchema, ResponseArray } from '../index';
import {
  AllProjectsResponseSchema,
  AllUnassignedProjectsResponseSchema,
  ArtistTeamSchema,
  BudgetGroupSchema,
  CampaignDetailsSchema,
  CampaignsResponseSchema,
  ChangeTeamRequest,
  ChangeTeamRequestSchema,
  HistoryResponseSchema,
  Id,
  MarketingMixSchema,
  MetricsReportingResponseSchema,
  NotesHistoryResponseSchema,
  PerformanceDataSchema,
  PhaseSchema,
  ProductFamilySchema,
  ProjectDataSourcesResponseSchema,
  ProjectDetailsSchema,
  ProjectIdsResponseSchema,
  ProjectPlatformsResponseSchema,
  ProjectReleaseTypeSchema,
  ProjectRequest,
  ProjectRequestSchema,
  ProjectStatusSchema,
  ProjectTypeSchema,
  ProjectUserSchema,
  ShortProjectSchema,
  RecentProjectSchema,
  TaxonomySchema,
  TeamActivitySchema,
  TeamCountersResponseSchema,
  TeamUserSchema,
  UpcomingProjectSchema,
  ProjectSharingSchema,
  UpdateProjectUserRequest,
  UpdateProjectUserRequestSchema,
  AddProjectUsersRequest,
  AddProjectUsersRequestSchema,
} from '../models';
import {
  CampaignDetailsParams,
  CampaignFilterParams,
  FromSearchParam,
  MediaPlanExportParams,
  MetricsReportingParams,
  NewProjectsParams,
  PerformanceDataParams,
  ProjectFilterParams,
  ProjectHistoryParams,
  RecentlyViewedProjectsParams,
} from '../types';
import { parseDateFromISOString, addDaysToDate, getFormattedDate } from 'common-v2/utils';

export const getProjects = (params: ProjectFilterParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/projects',
    responseSchema: AllProjectsResponseSchema,
    queryParams: params,
  });

export const getUnassignedProjects = (params: ProjectFilterParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/projects',
    responseSchema: AllUnassignedProjectsResponseSchema,
    queryParams: params,
  });

export const exportAllProjectsCsv = (params: ProjectFilterParams) =>
  apiClient.executeBinaryRequest({
    method: 'GET',
    endpoint: '/projects/export',
    queryParams: params,
  });

export const getAllProjectsId = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/projects/id',
    responseSchema: ProjectIdsResponseSchema,
  });

export const getProject = (projectId: number, params?: FromSearchParam) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}`,
    queryParams: params,
    responseSchema: ProjectDetailsSchema,
  });

export const editProject = (id: number, payload: ProjectRequest) =>
  apiClient.executeRequest({
    method: 'PUT',
    endpoint: `/projects/${id}`,
    requestSchema: ProjectRequestSchema,
    responseSchema: ProjectDetailsSchema,
    payload,
  });

export const getProjectSharingInfo = (projectId: number) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/sharing`,
    responseSchema: ProjectSharingSchema,
  });

export const updateProjectUser = (projectId: number, payload: UpdateProjectUserRequest) =>
  apiClient.executeRequest({
    method: 'PATCH',
    endpoint: `/projects/${projectId}/sharing`,
    requestSchema: UpdateProjectUserRequestSchema,
    responseSchema: EmptySchema,
    payload,
  });

export const removeProjectUser = (projectId: number, payload: UpdateProjectUserRequest) =>
  apiClient.executeRequest({
    method: 'DELETE',
    endpoint: `/projects/${projectId}/sharing`,
    requestSchema: UpdateProjectUserRequestSchema,
    responseSchema: EmptySchema,
    payload,
  });

export const addProjectUsers = (projectId: number, payload: AddProjectUsersRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/sharing`,
    requestSchema: AddProjectUsersRequestSchema,
    responseSchema: EmptySchema,
    payload,
  });

export const deleteProject = (id: number) =>
  apiClient.executeRequest({
    method: 'DELETE',
    endpoint: `/projects/${id}`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
  });

export const undoDeleteProject = (id: number) =>
  apiClient.executeRequest({
    method: 'PUT',
    endpoint: `/projects/${id}/undo/delete`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
  });

export const getProjectUsers = (projectId: number) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/users`,
    responseSchema: ResponseArray(ProjectUserSchema),
  });

export const getTeam = (projectId: number) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/team`,
    responseSchema: ResponseArray(TeamUserSchema),
  });

export const changeProjectTeam = (projectId: number, payload: ChangeTeamRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/team`,
    responseSchema: ResponseArray(TeamUserSchema),
    requestSchema: ChangeTeamRequestSchema,
    payload,
  });

export const getProjectStatus = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/project-statuses',
    responseSchema: ResponseArray(ProjectStatusSchema),
  });

export const getProjectHistory = (projectId: Id = -1, isUnassigned = false, params?: ProjectHistoryParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/${isUnassigned ? 'unassigned-' : ''}projects/${projectId}/history`,
    responseSchema: HistoryResponseSchema,
    queryParams: params,
  });

export const getProjectMarketingMix = (projectId: Id = -1) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/marketing-mix`,
    responseSchema: ResponseArray(MarketingMixSchema),
  });

export const getTeamCounters = (projectId: Id = -1) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/team-counters`,
    responseSchema: TeamCountersResponseSchema,
  });

export const getNotesHistory = (projectId: Id = -1) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/notes-history`,
    responseSchema: NotesHistoryResponseSchema,
  });

export const getProjectTypes = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/projects/project-types',
    responseSchema: ResponseArray(ProjectTypeSchema),
  });

export const getRecentProjects = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/projects/recent',
    responseSchema: ResponseArray(RecentProjectSchema),
  });

export const getUpcomingProjects = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/projects/upcoming',
    responseSchema: ResponseArray(UpcomingProjectSchema),
  });

export const getTeamActivity = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/projects/recent-activity',
    responseSchema: ResponseArray(TeamActivitySchema),
  });

export const claimUnassignedProject = (id: Id, payload: ProjectRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${id}/claim`,
    responseSchema: ProjectDetailsSchema,
    requestSchema: ProjectRequestSchema,
    payload,
  });

export const getProductFamilies = (id: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${id}/product-families`,
    responseSchema: ResponseArray(ProductFamilySchema),
  });

export const getCampaignsDetails = (projectId: Id, params?: CampaignDetailsParams, isUnassigned = false) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/${isUnassigned ? 'unassigned-' : ''}projects/${projectId}/campaigns`,
    queryParams: params,
    responseSchema: ResponseArray(CampaignDetailsSchema),
  });

export const getCampaigns = (projectId: Id, params?: CampaignFilterParams, isUnassigned = false) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/${isUnassigned ? 'unassigned-' : ''}projects/${projectId}/campaigns-list`,
    queryParams: params,
    responseSchema: CampaignsResponseSchema,
  });

// TODO: Ask for export response type
export const exportCsv = (projectId: Id, isUnassigned = false) =>
  apiClient.executeBinaryRequest({
    method: 'GET',
    endpoint: `/${isUnassigned ? 'unassigned-' : ''}projects/${projectId}/export`,
  });

export const getTaxonomy = (params: { labelIds: Id[] }) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/campaign-types`,
    responseSchema: TaxonomySchema,
    queryParams: params,
  });

export const getPhases = (projectId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/phases`,
    responseSchema: ResponseArray(PhaseSchema),
  });

export const getProjectPerformance = (projectId: Id, params: PerformanceDataParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/metrics`,
    responseSchema: PerformanceDataSchema,
    queryParams: params,
  });

export const getProjectMetricsReporting = (projectId: Id, params: MetricsReportingParams) => {
  const formatDate = getFormattedDate('yyyy-MM-dd');
  const parsedEndDate = parseDateFromISOString(params.endDate);

  const updatedParams: MetricsReportingParams = {
    ...params,
    endDate: formatDate(addDaysToDate(parsedEndDate, 1)),
  };

  return apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/reporting-v2/metrics`,
    responseSchema: MetricsReportingResponseSchema,
    queryParams: updatedParams,
  });
};

export const getBudgetGroupsForProject = (projectId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/budgets`,
    responseSchema: ResponseArray(BudgetGroupSchema),
  });

export const getProjectArtistTeam = (projectId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/artist-team`,
    responseSchema: ArtistTeamSchema,
  });

export const exportMediaPlanCsv = (id: number, params: MediaPlanExportParams) =>
  apiClient.executeBinaryRequest({
    method: 'GET',
    endpoint: `/projects/${id}/media_plan/export`,
    queryParams: params,
  });

export const getProjectPlatforms = (id: number) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${id}/platforms`,
    responseSchema: ProjectPlatformsResponseSchema,
  });

export const getProjectDataSources = (id: number) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${id}/data-sources`,
    responseSchema: ProjectDataSourcesResponseSchema,
  });

export const getProjectReleaseTypes = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/release-types',
    responseSchema: ResponseArray(ProjectReleaseTypeSchema),
  });

export const getRecentlyViewedProjects = (queryParams: RecentlyViewedProjectsParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/projects/recently-viewed',
    responseSchema: ResponseArray(ShortProjectSchema),
    queryParams,
  });

export const getNewProjects = (queryParams: NewProjectsParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/projects/newly-added',
    responseSchema: ResponseArray(ShortProjectSchema),
    queryParams,
  });
