import styled from 'styled-components';
import { motion } from 'framer-motion';

interface Props {
  noImage: boolean;
}

export const SZeroStateCard = styled(motion.div)<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, rgba(28, 31, 51, 0) 0%, rgba(28, 31, 51, 1) 100%),
    linear-gradient(180deg, #4d5280 0%, #24283e 100%);
  border-radius: 10px;
  min-height: 70px;
  padding: 0 30px;

  .zero-state-card {
    &__info {
      width: 100%;
      height: ${({ noImage }) => (noImage ? 'unset' : '155px')};
      display: grid;
      grid-template-columns: 2fr 1fr;
      justify-items: start;
    }
    &__text-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
    }
    &__title {
      color: white;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 25px;
      padding-top: 25px;
    }
    &__subtitle {
      margin-top: 9px;
      color: white;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__image {
      grid-column: 2;
      grid-row: 1 / span 2;
      align-self: center;
      justify-self: end;
    }
    &__button {
      justify-self: ${({ noImage }) => (noImage ? 'end' : 'start')};
    }
  }
`;
