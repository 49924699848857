import styled from 'styled-components';

export const BEM_CLASS = 'footer';

export const SFooter = styled.footer`
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .${BEM_CLASS} {
    &__button {
      min-width: 180px;
    }

    &__clear-all-button {
      margin-right: auto;
    }
  }
`;
