import styled from 'styled-components';

export const SAllProjectsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 45px;

  .all-projects-section {
    &__title {
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
    }

    &__tabs {
      margin-top: 10px;
    }

    &__tab-header {
      margin-top: 22px;
    }

    &__all-projects {
      margin-top: 22px;
    }
  }
`;
