import React from 'react';
import { bem } from 'utils/bem';
import { SLoaderHistoryWidget } from './s-loader-history-widget';

const rectangleClasses = bem('loader-history-widget');
const CLIP_PATH_ID = 'loader-history-widget';

interface Props {
  className?: string;
}

export const HistoryWidgetLoader = React.memo(({ className }: Props) => {
  return (
    <SLoaderHistoryWidget className={className} clipPathId={CLIP_PATH_ID}>
      <div className={rectangleClasses('clip-container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <path
              d={`M0 0 110 0 110 15 0 15 0 0z
                  M0 25 110 25 110 40 0 40 0 25z
                  M0 50 110 50 110 65 0 65 0 50z`}
            />
          </clipPath>
        </defs>
      </svg>
    </SLoaderHistoryWidget>
  );
});
