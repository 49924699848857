import { CampaignStatuses } from 'backend-api/models';
import { SelectBaseGroup, SelectBaseOption } from 'common-v2/components';
import React from 'react';
import { OptionProps } from 'react-select';
import { bem } from 'utils/bem';
import { getDataSelector } from 'utils/string';
import { isEnumValue } from 'common-v2/types';
import { BEM_CLASS, SApprovalSelectOption } from './s-approval-select-option';
import { TYPOGRAPHY_TYPE, Typography } from 'gdb-web-shared-components';

const classes = bem(BEM_CLASS);

export const ApprovalSelectOption = React.memo(
  ({
    innerProps,
    data,
    isFocused,
    isDisabled,
  }: OptionProps<SelectBaseOption, false, SelectBaseGroup<SelectBaseOption>>) => {
    return (
      <SApprovalSelectOption
        {...innerProps}
        ref={null}
        isFocused={isFocused}
        isDisabled={isDisabled}
        status={isEnumValue(CampaignStatuses)(data?.value) ? data.value : undefined}
        data-selector={getDataSelector('approval-option', data?.label)}
      >
        <div className={classes('option-indicator')} />

        <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('value')}>
          {data?.label}
        </Typography>
      </SApprovalSelectOption>
    );
  }
);
