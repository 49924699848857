import { PHONE_FORMAT } from 'common/constants';

export const formatPhone = (phone: string) => {
  let j = 0;
  const mask = PHONE_FORMAT;
  if (phone.length === 0) {
    return '';
  }
  return mask
    .split('')
    .map(sym => {
      if (j >= phone.length) {
        return '';
      }

      return sym === '9' ? phone[j++] : sym;
    })
    .join('');
};
