import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'edit-artists-modal';

export const SEditArtistsModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 737px;
  height: 513px;

  .${BEM_CLASS} {
    &__header {
      flex-shrink: 0;
      display: grid;
      grid-template-columns: 25px auto 25px;
      width: 100%;
      align-items: center;
      padding: 23px 20px 20px;

      &::before {
        content: '';
      }
    }

    &__title {
      text-align: center;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 0 42.5px;
      overflow: hidden;
    }

    &__list {
      margin-top: 15.5px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &__loader {
      margin: 0 7.5px;
    }

    &__artists-list {
      display: flex;
      flex-direction: column;
    }

    &__recent-artists {
      margin-top: 21px;
    }

    &__footer {
      padding: 30px 50px 40px;
    }

    &__empty {
      width: 100%;
      margin: auto;
      text-align: center;
      display: flex;
      justify-content: center;
    }

    &__empty-text {
      color: ${manhattanThemeColors.grayedText};
    }

    &__error {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      color: ${transparentize(0.3, manhattanThemeColors.textHeaderColor)};
    }
  }
`;
