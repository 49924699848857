import React from 'react';

export const getInfoMessage = (
  isArtistsFailed: boolean,
  hasEmptyData: boolean,
  isArtistsEmpty: boolean,
  isProjectsEmpty: boolean,
  isCampaignsEmpty: boolean
): JSX.Element => {
  const noResults = 'No results to show for ';
  const failedToLoad = 'Data failed to load for ';

  const elements: React.ReactNode[] = [];

  if (isProjectsEmpty) {
    elements.push(<b>Projects</b>);
  }

  if (!isArtistsFailed && isArtistsEmpty) {
    elements.push(<b>Artists</b>);
  }

  if (isCampaignsEmpty) {
    elements.push(<b>Campaigns</b>);
  }

  return (
    <span>
      {hasEmptyData && noResults}
      {elements.map((element, index) => (
        <>
          {element}
          {index === elements.length - 1 ? (isArtistsFailed ? ' ' : '. ') : ', '}
        </>
      ))}
      {isArtistsFailed && (
        <>
          {hasEmptyData ? `and ${failedToLoad.toLowerCase()}` : failedToLoad} <b>Artists</b>.{' '}
        </>
      )}
      Try another search.
    </span>
  );
};
