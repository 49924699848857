import { actionsGroup, asyncAction } from 'core/store';
import { PurchaseOrdersFilters } from 'common/components/tables/purchase-orders/types';
import { Id, PurchaseOrderDetails, PurchaseOrdersListResponse, PurchaseOrdersProvider } from 'backend-api/models';
import { PurchaseOrderFilterParams } from 'backend-api/types';

const { action } = actionsGroup('prs');

export const updateFilters = action<PurchaseOrdersFilters>('UPDATE_FILTERS');

export interface FetchBreakdownDataRequestPayload {
  projectId: Id;
  entityId: Id;
}

export interface FetchBreakdownDataResponsePayload {
  data: PurchaseOrdersListResponse;
  entityId: Id;
}

export interface ToggleBreakdownPayload {
  item: PurchaseOrderDetails;
  isExpanded: boolean;
}
export interface FetchBreakdownError {
  error: Error;
  entityId: Id;
}

export interface AllPurchaseOrdersRequest {
  projectId: Id;
  params: PurchaseOrderFilterParams;
}

export const getAllPurchaseOrders = asyncAction<AllPurchaseOrdersRequest, PurchaseOrdersListResponse, Error>(
  'GET_ALL_PURCHASE_ORDERS'
);

export const getPurchaseOrdersProviders = asyncAction<Id, PurchaseOrdersProvider[], Error>(
  'GET_PURCHASE_ORDERS_PROVIDERS'
);

export const fetchBreakdownData = asyncAction<
  FetchBreakdownDataRequestPayload,
  FetchBreakdownDataResponsePayload,
  FetchBreakdownError
>('FETCH_BREAKDOWN_DATA');

export const toggleBreakdown = action<ToggleBreakdownPayload>('TOGGLE_BREAKDOWN');
export const resetBreakdowns = action<void>('RESET_BREAKDOWNS');
