import styled from 'styled-components';
import { elipsizeText } from '../s-elipsize-text';

export const SLongTableTitle = styled.div`
  overflow: hidden;
  .long-table-title {
    &__long-text {
      ${elipsizeText};
      display: block;
      width: 100%;
      line-height: 20px;
      outline: none;
      vertical-align: middle;
    }
  }
`;
