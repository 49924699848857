import React from 'react';
import { Link } from 'react-router-dom';
import { SNoMatch } from '../s-components/s-no-match';
import { bem } from 'utils/bem';
import NotFoundSvg from 'assets/404.svg';
import { Head, HeadStaticTitles } from './head';

const classes = bem('no-match');

export const NoMatch = () => (
  <>
    <Head title={HeadStaticTitles.NOT_FOUND} />
    <SNoMatch>
      <div className={classes('inner')}>
        <img alt="" src={NotFoundSvg} />
        <div className={classes('inner-text')}>
          <div>The page you’re looking for is not available.</div>
          <span className={classes('inner-link')}>
            <Link to="/">Return to Projects</Link>
          </span>
        </div>
      </div>
    </SNoMatch>
  </>
);
