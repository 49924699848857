export class LinkParsingError extends Error {
  message: string;
  constructor(error: string) {
    super('Failed to parse link');
    this.name = 'LinkParsingError';
    this.message = error;
  }
}

export enum LinkPlatform {
  Amazon,
  Apple,
  Deezer,
  Facebook,
  Google,
  Instagram,
  Linkfire,
  Pandora,
  Shazam,
  SmartURL,
  Snapchat,
  SoundCloud,
  Spotify,
  Tidal,
  TikTok,
  Twitter,
  YouTube,
  Unknown,
}
