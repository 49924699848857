import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { formatNumeral } from 'utils/format';
import { BaseRenderer } from '../../base-renderer';
import { SSimpleNumberRenderer } from './s-simple-number-renderer';
import { SpreadsheetRowData } from 'media-plan/components/spreadsheet/types';

export const SimpleNumberRenderer = ({
  context,
  value: { color, value },
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['estImpressions']>) => (
  <BaseRenderer color={color} accessMode={context.accessMode}>
    <SSimpleNumberRenderer>{value && formatNumeral(value)}</SSimpleNumberRenderer>
  </BaseRenderer>
);
