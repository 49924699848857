import { AppHeader } from 'app/components/app-header';
import { getAllLabelsOptions, getIsLabelSelectorActive } from 'app/components/app-header/transducers';
import { Label } from 'backend-api/models';
import { useAppContext, useFeatureFlagsContext } from 'common-v2/hooks';
import { isTreatmentEnabled } from 'common-v2/transducers';
import { setGlobalLabel, showGlobalSearch, hideGlobalSearch } from 'common/actions';
import { globalLabelSelector, globalSearchSelector } from 'common/selectors';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { GlobalSearchFieldContainer } from '../global-search-field-container';
import { GlobalSearchModalContainer } from '../global-search-modal-container';
import { UserDropdownContainer } from '../user-dropdown-container';
import { SAppHeaderContainer } from './s-app-header-container';

interface AppHeaderContainerProps {
  className?: string;
}

export const AppHeaderContainer = React.memo(({ className }: AppHeaderContainerProps) => {
  const dispatch = useDispatch();

  const { treatments } = useFeatureFlagsContext();
  const { labels } = useAppContext();

  const globalLabel = useSelector(globalLabelSelector);
  const location = useLocation();

  const isLabelSelectorActive = useMemo(() => getIsLabelSelectorActive(location.pathname), [location]);
  const labelsWithAllOption = useMemo(() => getAllLabelsOptions(location.pathname, labels), [
    location.pathname,
    labels,
  ]);

  const onLabelChange = useCallback(
    (label: Label) => {
      dispatch(setGlobalLabel(label));
    },
    [dispatch]
  );

  useEffect(() => {
    if (labelsWithAllOption.length === 0) return;

    if (globalLabel) {
      const isGlobalLabelValid = labelsWithAllOption.find(({ id }) => id === globalLabel.id);

      if (isGlobalLabelValid) return;
    }

    dispatch(setGlobalLabel(labelsWithAllOption[0]));
  }, [dispatch, globalLabel, labelsWithAllOption]);

  const { isActive } = useSelector(globalSearchSelector);

  const onShowGlobalSearch = useCallback(() => {
    dispatch(showGlobalSearch());
  }, [dispatch]);

  const onHideGlobalSearch = useCallback(() => {
    dispatch(hideGlobalSearch());
  }, [dispatch]);

  const isNewUIEnabled = isTreatmentEnabled(treatments, 'GLOBAL_SEARCH_V2');

  return (
    <SAppHeaderContainer className={className}>
      <AppHeader
        labels={labelsWithAllOption}
        globalLabel={globalLabel}
        isLabelSelectorActive={isLabelSelectorActive}
        onLabelChange={onLabelChange}
        showGlobalSearch={onShowGlobalSearch}
        hideGlobalSearch={onHideGlobalSearch}
        isSearchActive={isActive}
        isNewUIEnabled={isNewUIEnabled}
        userDropdown={<UserDropdownContainer />}
        searchField={<GlobalSearchFieldContainer />}
      />

      <GlobalSearchModalContainer isNewModalActive={isNewUIEnabled} />
    </SAppHeaderContainer>
  );
});
