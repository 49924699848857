import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { typography } from 'app/theme/typography';

export const BEM_CLASS = 'error-overlay';

export const SErrorOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  .${BEM_CLASS} {
    &__icon {
      width: 180px;
      height: 95px;
    }
    &__text {
      margin-top: 25px;
      margin-bottom: 5px;
      text-align: center;
    }

    &__refresh {
      ${typography.body};
      color: ${colorPalette.azureRadiance};
      font-weight: 600;
    }
  }
`;
