import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'search-input';

export const SSearchInput = styled.div`
  overflow: hidden;

  .${BEM_CLASS} {
    &__input {
      color: ${manhattanThemeColors.primaryDark};
      font-size: 14px;
      font-weight: 500;
      padding: 0;
      margin: 2px 0;
    }
  }
`;
