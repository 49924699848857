import { v4 as uuidv4 } from 'uuid';
import { getProductFamilyMock } from '../product-family/__mocks__';
import { getPlaylistMock } from '../playlist/__mocks__';
import { getCampaignPlatformValueMock } from '../campaign/__mocks__';
import { IndexablePhase, Phase, PhaseCampaign, PhaseRequest } from './index';
import { parseDateByFormat } from 'common-v2/utils';

const getPhaseDefaults = (): Phase => ({
  id: 0,
  name: 'Phase 1',
  start_date: parseDateByFormat('1970-01-01', 'yyyy-MM-dd'),
  end_date: parseDateByFormat('1970-01-01', 'yyyy-MM-dd'),
  project_id: 0,
  order: 0,
  campaigns: [],
  product_families: [getProductFamilyMock()],
  playlists: [getPlaylistMock()],
  budget: 0,
  objective_text: '',
  estimated_impression: 0,
  campaign_platforms: [getCampaignPlatformValueMock()],
});

export const getPhaseMock = (p?: Partial<Phase>): Phase => ({
  ...getPhaseDefaults(),
  ...p,
});

const getIndexablePhaseDefaults = (): IndexablePhase => ({
  id: 0,
  name: 'Phase 1',
  start_date: parseDateByFormat('1970-01-01', 'yyyy-MM-dd'),
  end_date: parseDateByFormat('1970-01-01', 'yyyy-MM-dd'),
  project_id: 0,
  order: 0,
  campaigns: [],
  product_families: [getProductFamilyMock()],
  playlists: [getPlaylistMock()],
  budget: 0,
  objective_text: '',
  estimated_impression: 0,
  campaign_platforms: [getCampaignPlatformValueMock()],
  index: 1,
});

export const getIndexablePhaseMock = (p?: Partial<IndexablePhase>): IndexablePhase => ({
  ...getIndexablePhaseDefaults(),
  ...p,
});

const getPhaseCampaignDefaults = (): PhaseCampaign => ({
  id: 0,
  uuid: uuidv4(),
  name: 'Campaign',
  startDate: parseDateByFormat('1970-01-01', 'yyyy-MM-dd'),
  endDate: parseDateByFormat('1970-01-01', 'yyyy-MM-dd'),
  isEditable: true,
  isExternal: false,
});

export const getPhaseCampaignMock = (p?: Partial<PhaseCampaign>): PhaseCampaign => ({
  ...getPhaseCampaignDefaults(),
  ...p,
});

const getPhaseRequestDefaults = (): PhaseRequest => ({
  name: 'Campaign',
  start_date: parseDateByFormat('1970-01-01', 'yyyy-MM-dd'),
  playlists: [getPlaylistMock()],
  tracks: [1],
  objective_text: '',
});

export const getPhaseRequestMock = (p?: Partial<PhaseRequest>): PhaseRequest => ({
  ...getPhaseRequestDefaults(),
  ...p,
});
