import { ApiError } from 'backend-api';
import { SettingsFilters, SettingsTabs } from 'settings/types';
import { createTypedReducer, onAction } from 'core/store';
import { setCurrentPhone, updateCurrentUser, updateFilters } from 'settings/actions';

export interface SettingsState {
  filters: SettingsFilters;
  error?: ApiError;
  currentPhone: string;
  previousPhone: string;
}

export const initialState: SettingsState = {
  filters: { activeTab: SettingsTabs.UserSettings },
  currentPhone: '',
  previousPhone: '',
};

export const reducer = createTypedReducer<SettingsState>(
  initialState,
  onAction(updateFilters, (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      ...action.payload,
    },
  })),
  onAction(setCurrentPhone, (state, action) => ({
    ...state,
    currentPhone: action.payload,
    previousPhone: action.payload,
  })),
  onAction(updateCurrentUser.request, (state, action) => ({
    ...state,
    currentPhone: action.payload.phone || '',
  })),
  onAction(updateCurrentUser.failure, state => ({
    ...state,
    currentPhone: state.previousPhone,
  }))
);
