import { Optional } from 'backend-api/models';
import { OptionalPeriod } from 'common/types';
import { useFormattedDateTime } from './use-formatted-date-time';

export const useDatePeriodFormatted = ([startDate, endDate]: OptionalPeriod): Optional<string> => {
  const formattedStartDate = useFormattedDateTime({ date: startDate || new Date() });
  const formattedEndDate = useFormattedDateTime({ date: endDate || new Date() });

  if (!startDate && !endDate) return '';
  if (!endDate && formattedStartDate?.date) return formattedStartDate.date;
  if (!startDate && formattedEndDate?.date) return formattedEndDate.date;

  return `${formattedStartDate.date}–${formattedEndDate.date}`;
};
