import styled from 'styled-components';

export const SAllProjects = styled.div`
  height: 100%;
  min-height: 320px;
  .all-projects {
    &__table {
      height: 100px;
    }
    &__unassigned-date-cell {
      width: 120px;
    }
    &__artists-cell {
      padding-left: 85px !important;
    }
    &__status-cell {
      width: 125px;
    }
    &__schedule-cell {
      width: 180px;
    }
    &__allocation-cell,
    &__budget-cell,
    &__role-cell {
      width: 160px;
    }
    &__artist-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__artist-images {
      height: 40px;
      width: 40px;
      margin-right: 25px;
      min-width: 40px;
    }
    &__artist-name {
      flex: 1;
    }
    &__roles-container {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__roles-icon {
      height: 20px;
      width: 20px;
      margin-right: 11px;
    }
  }
`;
