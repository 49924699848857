import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import logoSvg from 'assets/decibel-logo-big-manhattan.svg';
import { Button, BUTTON_SIZE, THEME, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { BEM_CLASS, SLogin } from './s-login';
import { getEmailClientLink } from 'common-v2/utils';
import { DECIBEL_SUPPORT_EMAIL } from 'common-v2/constants';

const classes = bem(BEM_CLASS);

interface Props {
  isLoading: boolean;
  onClick(): void;
}

export const Login = React.memo(({ isLoading, onClick }: Props) => {
  const contactLink = useMemo(() => {
    const subject = '[Decibel] Trouble Logging In';

    return getEmailClientLink({ address: DECIBEL_SUPPORT_EMAIL, subject });
  }, []);

  return (
    <SLogin>
      <img className={classes('logo')} alt="Decibel" src={logoSvg} />
      <Button
        className={classes('action-button')}
        caption={isLoading ? undefined : 'Sign in'}
        icon={isLoading ? 'ellipsis' : undefined}
        onClick={onClick}
        theme={THEME.light}
        size={BUTTON_SIZE.large}
        data-selector="login-button"
      />
      <div className={classes('contact-section')}>
        <Typography type={TYPOGRAPHY_TYPE.body2}>
          Having trouble signing in?
          <a href={contactLink} className={classes('contact-link')}>
            Contact us
          </a>
        </Typography>
      </div>
    </SLogin>
  );
});
