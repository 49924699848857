import styled from 'styled-components';
import { Typography } from 'gdb-web-shared-components';
import Tippy from '@tippyjs/react';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const SSpreadsheetTooltip = styled(Tippy)`
  padding: 7px 10px;
  background-color: ${manhattanThemeColors.background};
  border-radius: 5px;
  box-shadow: 0 5px 20px 0 ${transparentize(0.95, manhattanThemeColors.primaryDark)};
  text-align: left;
  word-wrap: break-word;

  .tippy-backdrop {
    background-color: ${manhattanThemeColors.background};
  }

  .tippy-content {
    background-color: ${manhattanThemeColors.background};
  }

  &[data-placement] {
    .tippy-arrow {
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }
`;

export const EllipsisBody = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 10;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
`;
