import styled from 'styled-components';

const defaultSpacing = 20;

export const SProjectDetails = styled.div`
  display: flex;
  flex-direction: column;

  .project-details {
    &__container {
      flex: 1;
      display: flex;
      flex-direction: row;
      margin-bottom: 50px;

      & > *:not(:first-child) {
        margin-left: ${defaultSpacing}px;
      }
    }
    &__main-column {
      flex: 39;
      display: flex;
      flex-direction: column;

      & > *:not(:first-child) {
        margin-top: ${defaultSpacing}px;
      }
    }
    &__budget-container {
      display: flex;

      & > * {
        flex: 1;
      }

      & > *:not(:first-child) {
        margin-left: ${defaultSpacing}px;
      }
    }
    &__secondary-column {
      flex: 19;
      display: flex;
      overflow: hidden;
      flex-direction: column;
    }

    &__artist-team-widget {
      margin-bottom: 20px;
    }

    &__project-owner-widget {
      margin-bottom: 20px;
    }
  }
`;
