import React, { useMemo } from 'react';
import { getInitials } from 'utils/string';
import { Tooltip } from '../tooltip';
import { DEFAULT_AVATAR_SIZE } from './constants';
import { SAvatar } from './s-avatar';
import { Property } from 'csstype';
import sonyMusicPng from 'assets/sony-music.png';

interface AvatarContent {
  url?: string;
  alt?: string;
}

interface TooltipContent {
  visible: boolean;
  colorsInversion?: boolean;
  title: string | JSX.Element;
}

interface UserAvatarProps {
  className?: string;
  name: string;
  size?: number;
  avatarColor: Property.Color;
  avatarContent?: AvatarContent;
  tooltipContent?: TooltipContent;
  dataSelector?: string;
}

export const Avatar = React.memo(
  ({
    className,
    name,
    size = DEFAULT_AVATAR_SIZE,
    avatarColor,
    tooltipContent,
    avatarContent,
    dataSelector,
  }: UserAvatarProps) => {
    const content = useMemo(
      () =>
        avatarContent ? <img src={avatarContent.url || sonyMusicPng} alt={avatarContent?.alt} /> : getInitials(name),
      [name, avatarContent]
    );

    return (
      <Tooltip
        disabled={!tooltipContent?.visible}
        content={tooltipContent?.title}
        invertedColors={tooltipContent?.colorsInversion}
      >
        <SAvatar className={className} size={size} avatarColor={avatarColor} data-selector={dataSelector}>
          {content}
        </SAvatar>
      </Tooltip>
    );
  }
);
