import React from 'react';
import { EllipsisBody, SSpreadsheetTooltip } from './s-spreadsheet-tooltip';
import { TippyProps } from '@tippyjs/react';
import { TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';

interface Props extends TippyProps {
  contentClassName?: string;
}

export const SpreadsheetTooltip = React.memo(
  ({ children, content, placement = 'bottom', contentClassName, ...props }: Props) => (
    <SSpreadsheetTooltip
      {...props}
      followCursor="horizontal"
      content={
        <EllipsisBody className={contentClassName} type={TYPOGRAPHY_TYPE.body2}>
          {content}
        </EllipsisBody>
      }
      placement={placement}
      duration={0}
    >
      {children}
    </SSpreadsheetTooltip>
  )
);
