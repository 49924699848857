import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'date-preferences';

export const SDatePreferences = styled.div`
  .${BEM_CLASS} {
    &__container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 50%;
      margin-top: 20px;
      padding: 15px 30px;
      background-color: ${transparentize(0.5, colorPalette.white)};
      border: 1px solid;
      border-radius: 10px;
      box-shadow: 0px 5px 20px ${transparentize(0.95, colorPalette.woodsmoke)};
      border-color: ${transparentize(0.9, colorPalette.woodsmoke)};
    }

    &__header {
      display: flex;
      align-items: center;
    }

    &__icon {
      margin-right: 12px;
      color: ${transparentize(0.5, colorPalette.woodsmoke)};
    }

    &__title {
      display: flex;
      align-items: center;
    }

    &__radio-group {
      margin: 21px 0 29px 0;
      gap: 5px;
    }

    &__radio-item {
      padding: 0 10px;
    }
  }
`;
