import { CampaignDetails } from 'backend-api/models';
import { ManageExternalCampaignsPayload } from '../../types';
import { UUID } from 'io-ts-types/lib/UUID';

const toUuidArray = (arr: CampaignDetails[]) => arr.map(val => val.uuid);

const getDiff = (target: UUID[], other: UUID[]) => target.filter(uuid => !other.includes(uuid));

export function getManageCampaignPayload(
  projectId: number,
  initialCampaigns: CampaignDetails[],
  currentCampaigns: CampaignDetails[],
  closeManagingCampaignsModal: () => void
): ManageExternalCampaignsPayload {
  const initialUuids = toUuidArray(initialCampaigns);
  const currentUuids = toUuidArray(currentCampaigns);

  return {
    projectId,
    data: {
      assignCampaignsUuids: getDiff(currentUuids, initialUuids),
      unassignCampaignsUuids: getDiff(initialUuids, currentUuids),
    },
    closeManagingCampaignsModal,
  };
}
