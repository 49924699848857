import styled from 'styled-components';

export const STreeList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .tree-list {
    &__item-container {
      display: flex;
      align-items: center;
      flex-shrink: 0;
    }
    &__sub-items {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      overflow: hidden;
      &--top {
        overflow: visible;
      }
    }
  }
`;
