import { ValueGetterParams } from 'ag-grid-community/dist/lib/entities/colDef';
import { CampaignStatuses, Goal, Optional, SubmitApprovalCampaign } from 'backend-api/models';
import { BaseOption } from 'common/components/select';
import { LoadingState } from 'common/types';
import { GroupBase } from 'react-select';
import { SpreadsheetCellData, SpreadsheetRowData } from './components/spreadsheet/types';

export const estimatedImpressionsGetter = ({
  data,
}: ValueGetterParams<SpreadsheetRowData>): SpreadsheetCellData<number | null> => {
  const budget = data?.budget.value;
  const ecpm = data?.eCpm.value;
  const estImpressionsColor = data?.estImpressions?.color ?? null;

  if (budget == null || !ecpm)
    return {
      color: estImpressionsColor,
      value: null,
    };

  return { color: estImpressionsColor, value: (budget / ecpm) * 1000 };
};

export const goalsToSelectOptions = (goals?: Goal[]): Optional<GroupBase<BaseOption>[]> =>
  goals?.map(goal => ({
    label: goal.name,
    options: goal.objectives.map(objective => ({
      id: objective.id,
      value: objective.name,
    })),
  }));

export const joinMediaPlanWithApprovalRowData = (
  mediaPlanData: SpreadsheetRowData[],
  approvalData: SpreadsheetRowData[]
): SpreadsheetRowData[] =>
  mediaPlanData.map(mediaPlanData => {
    return approvalData.find(approvalRow => approvalRow.uuid.value === mediaPlanData.uuid.value) || mediaPlanData;
  });

export const isLoadingStateAllowUILoading = (loadingState?: LoadingState) =>
  loadingState === LoadingState.Idle || loadingState === LoadingState.Started;

export const splitAppovedDissaprovedCampaigns = (
  reviewedCampaigs: SubmitApprovalCampaign[]
): { approved: SubmitApprovalCampaign[]; disapproved: SubmitApprovalCampaign[] } => {
  const approved: SubmitApprovalCampaign[] = [];
  const disapproved: SubmitApprovalCampaign[] = [];

  reviewedCampaigs.forEach(campaign => {
    if (campaign.workflowStatus === CampaignStatuses.APPROVED) {
      approved.push(campaign);
    } else {
      disapproved.push(campaign);
    }
  });
  return { approved, disapproved };
};
