import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { BEM_CLASS, SPlacementsEditor } from './s-placements-editor';
import { ICellEditorParams } from 'ag-grid-community';
import { Id } from 'backend-api/models';
import { getPlacementsOptions } from 'common/transducers';
import { ClassNamesProps } from 'common/components/select';
import { bem } from 'utils/bem';
import { MultiSelect } from 'common/components/select/multi-select';
import { MultiSelectType } from 'common/components/select/types';
import { MediaPlanColumnId } from 'media-plan/components/spreadsheet/types';
import { useSelectEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan/components/spreadsheet/hooks';
import { MAX_COUNT_CLEARABLE } from 'common/components/select/constants';
import { ExpandableField } from '../../expandable-field';

const classes = bem(BEM_CLASS);

export const PlacementsEditor = React.memo(
  React.forwardRef((props: ICellEditorParams, ref) => {
    const [value, setValue] = useState(props.value.value);
    const { onBlur } = useStopEditingBlur(props.api);

    const taxonomy = useMemo(() => props.colDef?.cellEditorParams.taxonomy, [props.colDef?.cellEditorParams.taxonomy]);

    const platforms = useMemo(
      () => props.api.getValue(MediaPlanColumnId.PLATFORMS, props.node)?.value?.map(item => item.id),
      [props.api, props.node]
    );
    const options = useMemo(() => getPlacementsOptions(taxonomy?.placements, platforms, taxonomy?.platforms), [
      platforms,
      taxonomy?.placements,
      taxonomy?.platforms,
    ]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          ...props.value,
          value,
        };
      },
    }));

    const handleChange = useCallback(
      (ids: Id[]) => {
        const placements = taxonomy?.placements?.filter(placement => ids.includes(placement.id)) || [];
        setValue(placements);
      },
      [taxonomy?.placements]
    );

    const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions<true>({
      api: props.api,
      value,
      setValue: handleChange,
      isMulti: true,
    });

    const selectClassNames = useCallback<(className: string) => ClassNamesProps>(
      className => ({
        control: classes('control'),
        valueContainer: { root: classes('value-container') },
        menu: classes('menu'),
        menuList: classes('menu-list'),
        indicatorsContainer: classes('indicators-container'),
        menuPortal: className,
      }),
      []
    );

    useEffect(() => {
      selectRef.current?.inputRef?.focus({ preventScroll: true });
    }, [selectRef]);

    return (
      <ExpandableField hasError={false}>
        <SPlacementsEditor width={props.column.getActualWidth()} height={props.node.rowHeight || 0}>
          {className => (
            <div className={className}>
              <MultiSelect
                selectRef={selectRef}
                type={MultiSelectType.AutoComplete}
                valueIds={value?.map(value => value.id) || []}
                valueHandler={handleChange}
                options={options}
                onBlur={onBlur}
                isSearchable
                placeholder=""
                classNames={selectClassNames(className)}
                onKeyDown={onKeyDown}
                isClearable={value?.length >= MAX_COUNT_CLEARABLE}
                isOpened
              />
            </div>
          )}
        </SPlacementsEditor>
      </ExpandableField>
    );
  })
);
