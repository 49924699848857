import {
  parse,
  parseISO,
  isAfter,
  isBefore,
  formatISO,
  isValid,
  addDays,
  addMonths,
  addYears,
  subDays,
  subMonths,
  subYears,
  min,
  max,
  endOfDay,
  startOfDay,
  isSameDay as isSameDayFns,
  isSameSecond,
  differenceInDays,
  differenceInMilliseconds,
  differenceInCalendarDays,
} from 'date-fns';
import { format, toDate, zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { DATE_FORMAT_SHORT } from 'settings/constants';
import { DEFAULT_DATE_FORMAT } from 'common-v2/constants/format';

export const getCurrentTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const parseDate = (str?: string, tz?: string) =>
  str ? toDate(str, { timeZone: tz ?? getCurrentTimezone() }) : new Date('Invalid date');

export const parseDateByFormat = (str?: string, dateFormat?: string) => {
  if (!str || !dateFormat) return new Date('Invalid date');

  return parse(str, dateFormat, new Date());
};

export const parseDateFromISOString = (str?: string) => (str ? parseISO(str) : new Date('Invalid date'));

export const getFormattedDate = (dateFormat = DEFAULT_DATE_FORMAT, tz?: string) => (date?: Date): string => {
  const options = { timeZone: tz ?? getCurrentTimezone() };

  return date ? format(date, dateFormat, options) : '';
};

export const getFormattedShortDate = (pattern?: string) => (date?: Date) => {
  if (!pattern || !date) return {};

  const options = { timeZone: getCurrentTimezone() };

  return {
    date: format(date, DATE_FORMAT_SHORT[pattern]?.date || '', options),
    time: format(date, DATE_FORMAT_SHORT[pattern]?.time || '', options),
  };
};

export const convertDateToISOString = (date?: Date) => (date ? formatISO(date) : '');

export const convertDateToUTC = (date?: Date) =>
  date ? zonedTimeToUtc(date, getCurrentTimezone()) : new Date('Invalid date');

export const convertDateToZonedTime = (date: Date, tz?: string) => utcToZonedTime(date, tz ?? getCurrentTimezone());

export const isDateAfter = (date?: Date, dateToCompare?: Date) => {
  if (!date || !dateToCompare) return false;

  return isAfter(date, dateToCompare);
};

export const isDateAfterDay = (date?: Date, dateToCompare?: Date) =>
  isDateAfter(date, dateToCompare) && Math.abs(getDiffInDays(date, dateToCompare)) > 0;

export const isDateBefore = (date?: Date, dateToCompare?: Date) => {
  if (!date || !dateToCompare) return false;

  return isBefore(date, dateToCompare);
};

export const isDateBeforeDay = (date?: Date, dateToCompare?: Date) =>
  isDateBefore(date, dateToCompare) && Math.abs(getDiffInDays(date, dateToCompare)) > 0;

export const isDateBetween = (date?: Date, dateFrom?: Date, dateTo?: Date) =>
  isDateAfter(date, dateFrom) && isDateBefore(date, dateTo);

export const isDateBetweenInclusive = (date?: Date, dateFrom?: Date, dateTo?: Date) => {
  if (!date || !dateFrom || !dateTo) return false;

  return isSameDate(date, dateFrom) || isDateBetween(date, dateFrom, dateTo) || isSameDate(date, dateTo);
};

export const isDateBetweenDays = (date?: Date, dateFrom?: Date, dateTo?: Date) =>
  isDateAfterDay(date, dateFrom) && isDateBeforeDay(date, dateTo);

export const isDateBetweenDaysInclusive = (date?: Date, dateFrom?: Date, dateTo?: Date) => {
  if (!date || !dateFrom || !dateTo) return false;

  return isSameDay(date, dateFrom) || isDateBetweenDays(date, dateFrom, dateTo) || isSameDay(date, dateTo);
};

export const isValidDate = (date?: Date) => {
  if (!date) return false;

  return isValid(date);
};

export const addDaysToDate = (date?: Date, days?: number) => {
  if (!date) return new Date('Invalid date');

  return addDays(date, days ?? 0);
};

export const addMonthsToDate = (date?: Date, months?: number) => {
  if (!date) return new Date('Invalid date');

  return addMonths(date, months ?? 0);
};

export const addYearsToDate = (date?: Date, years?: number) => {
  if (!date) return new Date('Invalid date');

  return addYears(date, years ?? 0);
};

export const substractDaysFromDate = (date?: Date, days?: number) => {
  if (!date) return new Date('Invalid date');

  return subDays(date, days ?? 0);
};

export const substractMonthsFromDate = (date?: Date, months?: number) => {
  if (!date) return new Date('Invalid date');

  return subMonths(date, months ?? 0);
};

export const substractYearsFromDate = (date?: Date, years?: number) => {
  if (!date) return new Date('Invalid date');

  return subYears(date, years ?? 0);
};

export const getStartOfDay = (date?: Date) => {
  if (!date) return undefined;

  return startOfDay(date);
};

export const getEndOfDay = (date?: Date) => {
  if (!date) return undefined;

  return endOfDay(date);
};

export const getMinDate = (dates: Date[]) => min(dates);

export const getMaxDate = (dates: Date[]) => max(dates);

export const isSameDay = (date?: Date, dateToCompare?: Date) => {
  if (!date || !dateToCompare) return false;

  return isSameDayFns(date, dateToCompare);
};

export const getDiffInDays = (dateFrom?: Date, dateTo?: Date) => {
  if (!dateFrom || !dateTo) return NaN;

  return differenceInDays(dateFrom, dateTo);
};

export const getDiffInMiliseconds = (dateFrom?: Date, dateTo?: Date) => {
  if (!dateFrom || !dateTo) return NaN;

  return differenceInMilliseconds(dateFrom, dateTo);
};

export const getDiffInCalendarDays = (dateFrom?: Date, dateTo?: Date) => {
  if (!dateFrom || !dateTo) return NaN;

  return differenceInCalendarDays(dateFrom, dateTo);
};

export const isSameDate = (date?: Date, dateToCompare?: Date) => {
  if (!date || !dateToCompare) return false;

  return isSameSecond(date, dateToCompare);
};
