import { TOP_HEADER_HEIGHT, MAIN_FOOTER_HEIGHT, MAIN_FOOTER_PADDING_TOP } from 'common/constants';
import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'no-permission';

export const SNoPermission = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - ${TOP_HEADER_HEIGHT}px - ${MAIN_FOOTER_HEIGHT}px - ${MAIN_FOOTER_PADDING_TOP}px);

  .${BEM_CLASS} {
    &__image {
      margin-bottom: 20px;
    }

    &__restriction {
      height: 25px;
      color: ${colorPalette.black};
      font-size: 18px;
      font-weight: bold;
      line-height: 25px;
    }

    &__information {
      margin: 6px 0 28px;
      height: 25px;
      color: ${colorPalette.vulcan};
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
    }

    &__team-member-select {
      width: 350px;
    }

    &__email-button {
      margin-top: 20px;
    }

    &__button-plug {
      height: 55px;
    }
  }
`;
