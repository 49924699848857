import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { PurchaseOrderDetails } from 'backend-api/models';
import { LoadingState } from 'common/types';
import { H4, Body } from 'common/components/typography';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { Badge } from 'common/components/badge';
import { AnchorLink } from 'common/components/anchor-link';
import { SPaper } from 'common/s-components/s-paper';
import { AllProjectsLoader } from 'common/components/loaders/all-projects-loader';
import { TabLoader } from 'common/components/loaders/tab-loader';
import { Info } from './components';
import { SDetails, BEM_CLASS } from './s-details';

interface DetailsProps {
  projectId: number;
  data?: PurchaseOrderDetails;
  isLoading: LoadingState;
  onViewPOsClick: () => void;
}

const classes = bem(BEM_CLASS);

export const Details = React.memo(({ projectId, data, isLoading, onViewPOsClick }: DetailsProps) => {
  const body = useMemo(() => {
    if (isLoading === LoadingState.Started || isLoading === LoadingState.Failed || !data) {
      let emptyComponent: React.ReactChild = <></>;

      if (isLoading === LoadingState.Started) {
        emptyComponent = <AllProjectsLoader />;
      } else if (isLoading === LoadingState.Failed) {
        emptyComponent = <Body>Data failed to load. Try refreshing this page.</Body>;
      } else if (!data) {
        emptyComponent = <Body>No Data Available.</Body>;
      }

      return (
        <SPaper className={classes('empty')} pt="25px" pb="25px" color="white">
          {emptyComponent}
        </SPaper>
      );
    }

    return <Info projectId={projectId} purchaseOrder={data} />;
  }, [data, isLoading, projectId]);

  return (
    <SDetails withTabs heightFromChildren>
      <ResponsiveContainer>
        <div className={classes('head')} data-selector="purchase-order-header">
          {isLoading === LoadingState.Started ? (
            <TabLoader />
          ) : (
            data && (
              <>
                <H4 className={classes('title')} data-selector="purchase-order-title">
                  {data.name}
                </H4>
                <Badge className={classes('badge')} dataSelector="purchase-order-status" text={data.status} />
                {data.isBlanket && (
                  <AnchorLink
                    onClick={onViewPOsClick}
                    className={classes('anchor')}
                    title="View POs"
                    dataSelector="purchase-order"
                  />
                )}
              </>
            )
          )}
        </div>
        {body}
      </ResponsiveContainer>
    </SDetails>
  );
});
