import styled from 'styled-components';

export const BEM_CLASS = 'assigned-campaigns';

export const SAssignedCampaignsList = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .${BEM_CLASS} {
    &__header {
      flex-shrink: 0;
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
    }

    &__wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`;
