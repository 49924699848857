import React, { useMemo } from 'react';
import { Multiselect, MultiSelectComponentProps } from '../multiselect/multiselect';
import { BaseOption } from '../select';

interface GroupItem extends BaseOption {
  group_number: number;
}

interface Props<T extends GroupItem> extends MultiSelectComponentProps<T> {
  options: T[];
}

export const GroupSelect = React.memo(<T extends GroupItem>({ options, value, ...props }: Props<T>) => {
  const groupId = useMemo(() => options.find(option => option.id === value?.[0])?.group_number, [options, value]);

  const optionsDisabledByGroup = useMemo(
    () =>
      groupId ? options.map(item => (item.group_number === groupId ? item : { ...item, isDisabled: true })) : options,
    [options, groupId]
  );

  return <Multiselect {...props} options={optionsDisabledByGroup} value={value} />;
});
