import React, { useCallback, useMemo } from 'react';
import { bem } from 'utils/bem';
import { Id } from 'backend-api/models';
import { BEM_CLASS, SUsersList } from './s-users-list';
import { SearchUser } from 'modals/sharing-modal/types';
import { TreeItemProps, TreeList } from 'common/components/tree-list';
import { NoResults } from 'common/components/no-results';
import { TreeItem } from 'common/components/tree-list/components/tree-item';
import { Badge, BadgeType } from 'common/components/badge';
import { getUsersTreeNodes } from './transducers';
import { AllProjectsLoader } from 'common/components/loaders/all-projects-loader';
import { isNumber } from 'lodash';

const classes = bem(BEM_CLASS);

interface Props {
  isLoading: boolean;
  searchUsers: SearchUser[];
  selectedUserIds: Id[];
  onChangeSelectedUsers(userIds: Id[]): void;
  canSelectMoreUsers: boolean;
  approversIds: Id[];
  search?: string;
}

export const UsersList = React.memo(
  ({
    isLoading,
    searchUsers,
    selectedUserIds,
    onChangeSelectedUsers,
    search,
    canSelectMoreUsers,
    approversIds,
  }: Props) => {
    const userNodes = useMemo(() => getUsersTreeNodes(searchUsers, selectedUserIds, canSelectMoreUsers), [
      canSelectMoreUsers,
      searchUsers,
      selectedUserIds,
    ]);

    const getEmptyState = useCallback(
      () => (
        <NoResults
          message="No results to show."
          withFilters
          withFiltersMessage="Try changing search parameters."
          className={classes('empty-view')}
        />
      ),
      []
    );

    const renderTreeItem = useCallback(
      ({ depth, isChecked, isHalfChecked, onClick, name, nodeId, isDisabled }: TreeItemProps) => {
        const showApproverBadge = isNumber(nodeId) ? approversIds.includes(nodeId) : false;

        return (
          <div className={classes('user-item')}>
            <TreeItem
              isChecked={isChecked}
              isHalfChecked={isHalfChecked || false}
              name={name}
              depth={depth}
              onClick={onClick}
              isEmpty={false}
              isDisabled={isDisabled}
              className={classes('tree-item')}
            />
            <div>
              {showApproverBadge && (
                <Badge type={BadgeType.Rounded} className={classes('approver-badge')} text="Approver" />
              )}
            </div>
          </div>
        );
      },
      [approversIds]
    );

    return (
      <SUsersList>
        {isLoading ? (
          <div className={classes('loader')}>
            <AllProjectsLoader />
          </div>
        ) : (
          <TreeList
            nodes={userNodes}
            value={selectedUserIds}
            onChange={onChangeSelectedUsers}
            className={classes('users-tree')}
            searchQuery={search}
            renderTreeItem={renderTreeItem}
            renderEmptyPlaceholder={getEmptyState}
          />
        )}
      </SUsersList>
    );
  }
);
