import React from 'react';
import { bem } from 'utils/bem';
import { SValue, BEM_CLASS, Alignment } from './s-value';
import { H2, PrimaryLabel } from 'common/components/typography';

const classes = bem(BEM_CLASS);

interface Props {
  title: string;
  value: string;
  className?: string;
  alignment?: Alignment;
  dataSelector?: string;
}

export const Value = React.memo(({ title, value, className, alignment = Alignment.LEFT, dataSelector }: Props) => {
  return (
    <SValue className={className} alignment={alignment}>
      <div className={classes('container')}>
        <PrimaryLabel className={classes('title')} data-selector={`${dataSelector}-title`}>
          {title}
        </PrimaryLabel>
        <H2 className={classes('value')} data-selector={`${dataSelector}-value`}>
          {value}
        </H2>
      </div>
    </SValue>
  );
});
