import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { getUnderline } from 'app/styles/underline';

export const SPagination = styled('div')`
  .pagination {
    &__list {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      align-items: center;
      padding: 19px 0 19px;
      margin: 0 0 6px 0;
    }
    &__item {
      display: inline-block;
      line-height: 28px;
      vertical-align: middle;
      &--left {
        flex-grow: 1;
      }
      &--middle {
        margin-right: 37px;
        justify-content: space-between;
        display: flex;
      }
    }
    &__dots {
      position: relative;
      top: 0;
      margin: 0 17px;
      font-size: 10px;
    }
    &__page {
      position: relative;
      top: 2px;
      color: ${palette.brightBlue};
      cursor: pointer;
      font-size: 12px;
      margin: 0 17px;
      ${getUnderline()}
      &--active {
        color: ${palette.black};
        font-weight: bolder;
        cursor: default;
      }
    }
    &__text {
      font-size: 12px;
      letter-spacing: 0;
    }
    &__dropdown {
      display: inline-flex;
      margin-right: 14px;
    }
    &__dropdown-title {
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 0;
      text-transform: capitalize;
    }
  }

  & .dropdown {
    &__button {
      background-color: ${palette.athensGrayTwo};
      min-width: 78px;
      position: relative;
      padding: 8px 28px 8px 20px;

      i {
        margin-left: 15px;
        &::before {
          left: 8px;
          top: 1px;
        }
      }
    }
  }
`;
