import React from 'react';
import { SArtistsList } from './s-list';

interface ListProps {
  children: React.ReactNode;
  className?: string;
}

export const List = React.memo(({ children, className }: ListProps) => {
  return <SArtistsList className={className}>{children}</SArtistsList>;
});
