import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'tree-list';

export const STreeList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 340px;
  overflow-x: hidden;
  overflow-y: auto;

  .${BEM_CLASS} {
    &__options {
      display: flex;
      flex-direction: column;
      padding: 15px 0;
      background-color: ${colorPalette.white};
    }
  }
`;
