import React, { useMemo } from 'react';
import { Label } from 'backend-api/models';
import { bem } from 'utils/bem';
import { H3 } from 'common/components/typography';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { SHeader, BEM_CLASS } from './s-header';
import { Badge } from 'common/components/badge';
import { Select, SelectType } from 'common/components/select';

interface HeaderProps {
  labels?: Label[];
  label?: number;
  onLabelChange(label: number): void;
}

const classes = bem(BEM_CLASS);

export const Header = React.memo(({ labels = [], label, onLabelChange }: HeaderProps) => {
  const selectOptions = useMemo(() => labels.map(({ id, name }) => ({ id, value: name })), [labels]);

  const labelSelector = useMemo(() => {
    if (!label || labels.length === 0) return null;

    if (labels.length === 1) {
      return <Badge text={labels[0].name} dataSelector="home-page-label-badge" />;
    }

    return (
      <Select
        type={SelectType.Badge}
        valueId={label}
        valueHandler={onLabelChange}
        options={selectOptions}
        dataSelector="home-page-label-selector"
      />
    );
  }, [label, labels, onLabelChange, selectOptions]);

  return (
    <ResponsiveContainer>
      <SHeader>
        <H3 className={classes('title')}>My Label</H3>
        {labelSelector}
      </SHeader>
    </ResponsiveContainer>
  );
});
