import styled from 'styled-components';

export const BEM_CLASS = 'team';

export const STeam = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;

  .${BEM_CLASS} {
    &__member {
      margin-left: -1px;
    }
  }
`;
