import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'header';

export const SHeader = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__title {
      margin-right: 13px;
    }

    &__icon {
      display: block;
    }

    &__selector {
      margin-left: auto;

      .react-select__menu {
        width: auto;
        min-width: 100%;
      }
    }

    &__button {
      margin-left: 20px;
    }

    &__platform-select-control {
      background-color: ${colorPalette.ghostWhite};
    }
  }
`;
