import { BaseOption, OptionIdType } from './types';
import { flatMapDeep, uniq } from 'lodash';
import { Optional } from 'backend-api/models';
import { ALL_OPTION_ID } from './constants';

export const flattenOptions = <T extends BaseOption>(options: T[]): T[] => {
  const flattenNested = item => item?.options?.concat(item).concat(flatMapDeep(item?.options, flattenNested)) || item;
  return uniq(options?.flatMap(flattenNested));
};

export const filterOptionsByValue = <T extends BaseOption>(options: T[], values?: Set<Optional<OptionIdType>>): T[] => {
  if (!values) return [];

  return options.filter(item => values.has(item.id));
};

export const getCombinedOptions = (allOption, options) => {
  if (!allOption) return options;

  if (typeof allOption === 'string') {
    return [
      {
        id: ALL_OPTION_ID,
        name: allOption,
        isDivided: true,
      },
      ...options,
    ];
  }

  if (Array.isArray(allOption)) {
    return allOption
      .map((item, index) => (index === allOption.length - 1 ? { ...item, isDivided: true } : item))
      .concat(options);
  }

  return [{ ...allOption, isDivided: true }, ...options];
};
