import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { TableTheme } from 'app/theme/table';
import { Table as CampaignsTable, TableElementId, TableInfiniteScroll } from 'common/components/table';
import { Campaign } from 'backend-api/models';
import { SortField } from '../../types';
import { getCampaignsTableConfig } from './table-config';
import { BEM_CLASS, STable } from './s-table';
import { Sort } from 'utils/sort';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'common/selectors';
import { UUID } from 'io-ts-types/lib/UUID';

interface TableProps {
  className?: string;
  campaigns: Campaign[];
  checkedIds: UUID[];
  isLoading: boolean;
  infiniteScroll: TableInfiniteScroll;
  sort: Sort<SortField>;
  currencyCode: string;
  onSortChange(sort: Sort<SortField>): void;
  onCheckItem(isChecked: boolean, id: TableElementId): void;
  onSelectedAll(isChecked: boolean): void;
}

const classes = bem(BEM_CLASS);

export const Table = React.memo(
  ({
    className,
    campaigns,
    isLoading,
    infiniteScroll,
    sort,
    onSortChange,
    onCheckItem,
    checkedIds,
    onSelectedAll,
    currencyCode,
  }: TableProps) => {
    const dateFormat = useSelector(dateFormatSelector);
    const headerContent = useMemo(() => getCampaignsTableConfig(dateFormat, currencyCode), [dateFormat, currencyCode]);

    return (
      <STable className={className}>
        <CampaignsTable
          className={classes('root')}
          tableContainerClassName={classes('container')}
          dataSelector="bulk-assignment-campaigns-table"
          headerContent={headerContent}
          bodyContent={campaigns}
          onSortChange={onSortChange}
          onCheckItem={onCheckItem}
          onSelectAll={onSelectedAll}
          checkedIds={checkedIds}
          sort={sort}
          isLoading={isLoading}
          isSearching={false}
          theme={TableTheme.WHITE}
          withoutRoundCorners
          isCheckable
          isScrollable
          infiniteScroll={infiniteScroll}
        />
      </STable>
    );
  }
);
