import { createTypedReducer, onAction } from 'core/store';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import {
  ArtistRosterModel,
  ArtistRosterProject,
  ArtistTeam,
  ProjectDetails,
  ProjectsMetadata,
  RecentProject,
  TeamActivity,
  UpcomingProject,
} from 'backend-api/models';
import {
  getAllProjects,
  getAllUnassignedProjects,
  getArtistRoster,
  getCurrentLabel,
  getExpansionLiveProjects,
  getExpansionUpcomingProjects,
  getLiveArtistTeams,
  getProjectsSnapshots,
  getRecentInfo,
  getUpcomingArtistTeams,
  getUserProjectsCount,
  resetExpansions,
  setCurrentLabel,
  setLiveExpandedId,
  setUpcomingExpandedId,
  updateFilters,
} from './actions';
import { AllProjectsFilters, ProjectSnapshotsByProjectId } from './types';
import { getFiltersByStatusWithDefaultOffset } from './transducers';
import { LoadableData, LoadingState } from 'common/types';

export interface ExpansionState {
  liveArtistTeams: LoadableData<ArtistTeam[]>;
  upcomingArtistTeams: LoadableData<ArtistTeam[]>;
  liveProjects: LoadableData<ArtistRosterProject[]>;
  upcomingProjects: LoadableData<ArtistRosterProject[]>;
  expandedUpcomingArtistId?: string;
  expandedLiveArtistId?: string;
}

export interface ProjectsState {
  isLoading: boolean;
  shouldShowNextLoadingAllProject: boolean;
  isLoadingAllProjects: boolean;
  recentProjects: RecentProject[];
  upcomingProjects: UpcomingProject[];
  teamActivity: TeamActivity[];
  allAssignedProjects: ProjectDetails[];
  allUnassignedProjects: ProjectDetails[];
  allProjectsMeta?: ProjectsMetadata;
  filters: AllProjectsFilters;
  userProjectsCount?: number;
  currentLabel?: number;
  artistRoster: LoadableData<ArtistRosterModel>;
  expansionState: ExpansionState;
  projectsSnapshots: ProjectSnapshotsByProjectId;
}

export const initialState: ProjectsState = {
  isLoading: true,
  isLoadingAllProjects: true,
  shouldShowNextLoadingAllProject: true,
  recentProjects: [],
  upcomingProjects: [],
  teamActivity: [],
  allAssignedProjects: [],
  allUnassignedProjects: [],
  expansionState: {
    liveArtistTeams: {
      loading: LoadingState.Idle,
      data: [],
    },
    upcomingArtistTeams: {
      loading: LoadingState.Idle,
      data: [],
    },
    liveProjects: {
      loading: LoadingState.Idle,
      data: [],
    },
    upcomingProjects: {
      loading: LoadingState.Idle,
      data: [],
    },
    expandedLiveArtistId: undefined,
    expandedUpcomingArtistId: undefined,
  },
  filters: {
    filtersByStatus: {},
  },
  artistRoster: { loading: LoadingState.Idle, data: { live: [], planned: [], inactive: [] } },
  projectsSnapshots: {},
};

const persistConfig = {
  key: 'projects',
  storage,
  whitelist: ['currentLabel'],
};

export const projectsReducer = createTypedReducer<ProjectsState>(
  initialState,
  onAction(getRecentInfo.request, state => ({
    ...state,
    isLoading: true,
  })),
  onAction(getRecentInfo.failure, state => ({
    ...state,
    isLoading: false,
  })),
  onAction(getRecentInfo.success, (state, action) => ({
    ...state,
    ...action.payload,
    isLoading: false,
  })),
  onAction(getAllProjects.request, state => ({
    ...state,
    isLoadingAllProjects: true,
  })),
  onAction(getAllProjects.failure, state => ({
    ...state,
    isLoadingAllProjects: false,
  })),
  onAction(getAllProjects.success, (state, action) => ({
    ...state,
    allAssignedProjects: action.payload.items,
    allProjectsMeta: action.payload.metadata,
    isLoadingAllProjects: false,
  })),
  onAction(getAllUnassignedProjects.request, state => ({
    ...state,
    isLoadingAllProjects: true,
  })),
  onAction(getAllUnassignedProjects.failure, state => ({
    ...state,
    isLoadingAllProjects: false,
  })),
  onAction(getAllUnassignedProjects.success, (state, action) => ({
    ...state,
    allUnassignedProjects: action.payload.items,
    allProjectsMeta: action.payload.metadata,
    isLoadingAllProjects: false,
  })),
  onAction(updateFilters, (state, { payload }) => {
    let filtersByStatus = {
      ...state.filters.filtersByStatus,
      ...payload.filtersByStatus,
    };

    if (state.filters.labelId !== payload.labelId) {
      filtersByStatus = getFiltersByStatusWithDefaultOffset(filtersByStatus);
    }

    return {
      ...state,
      filters: {
        ...state.filters,
        ...payload,
        filtersByStatus,
      },
    };
  }),
  onAction(getProjectsSnapshots.request, (state, { payload }) => {
    const projectsSnapshots = { ...state.projectsSnapshots };

    payload.forEach(params => {
      projectsSnapshots[params.projectId] = {
        period: params.period,
        maxPeriod: params.maxPeriod,
        metrics: { loading: LoadingState.Started, data: [] },
      };
    });

    return { ...state, projectsSnapshots };
  }),
  onAction(getProjectsSnapshots.success, (state, { payload }) => ({
    ...state,
    projectsSnapshots: {
      ...state.projectsSnapshots,
      [payload.projectId]: {
        ...state.projectsSnapshots[payload.projectId],
        metrics: { loading: LoadingState.Finished, data: payload.metrics },
      },
    },
  })),
  onAction(getProjectsSnapshots.failure, (state, { payload }) => ({
    ...state,
    projectsSnapshots: {
      ...state.projectsSnapshots,
      [payload.projectId]: {
        ...state.projectsSnapshots[payload.projectId],
        metrics: { loading: LoadingState.Failed, data: [] },
      },
    },
  })),
  onAction(getUserProjectsCount.failure, state => ({
    ...state,
    userProjectsCount: undefined,
  })),
  onAction(getUserProjectsCount.success, (state, action) => ({
    ...state,
    userProjectsCount: action.payload,
  })),
  onAction(getCurrentLabel.failure, state => ({
    ...state,
    currentLabel: undefined,
  })),
  onAction(getCurrentLabel.success, (state, { payload }) => ({
    ...state,
    currentLabel: payload.id,
  })),
  onAction(setCurrentLabel, (state, { payload }) => ({
    ...state,
    currentLabel: payload,
  })),
  onAction(getArtistRoster.request, state => ({
    ...state,
    artistRoster: { loading: LoadingState.Started, data: initialState.artistRoster.data },
  })),
  onAction(getArtistRoster.failure, state => ({
    ...state,
    artistRoster: { loading: LoadingState.Failed, data: initialState.artistRoster.data },
  })),
  onAction(getArtistRoster.success, (state, action) => {
    const upcomingArtistBecameLive = action.payload.live.find(
      artist => artist.artist.id === state.expansionState.expandedUpcomingArtistId
    );
    return {
      ...state,
      artistRoster: { loading: LoadingState.Finished, data: action.payload },
      expansionState: {
        ...state.expansionState,
        expandedLiveArtistId: upcomingArtistBecameLive
          ? upcomingArtistBecameLive.artist.id
          : state.expansionState.expandedLiveArtistId,
        expandedUpcomingArtistId: upcomingArtistBecameLive ? undefined : state.expansionState.expandedUpcomingArtistId,
      },
    };
  }),
  onAction(getLiveArtistTeams.request, state => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      liveArtistTeams: { loading: LoadingState.Started, data: [] },
    },
  })),
  onAction(getLiveArtistTeams.success, (state, action) => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      liveArtistTeams: { loading: LoadingState.Finished, data: action.payload },
    },
  })),
  onAction(getLiveArtistTeams.failure, state => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      liveArtistTeams: { loading: LoadingState.Failed, data: [] },
    },
  })),
  onAction(getUpcomingArtistTeams.request, state => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      upcomingArtistTeams: { loading: LoadingState.Started, data: [] },
    },
  })),
  onAction(getUpcomingArtistTeams.success, (state, action) => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      upcomingArtistTeams: { loading: LoadingState.Finished, data: action.payload },
    },
  })),
  onAction(getUpcomingArtistTeams.failure, state => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      upcomingArtistTeams: { loading: LoadingState.Failed, data: [] },
    },
  })),
  onAction(getExpansionLiveProjects.request, state => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      liveProjects: { loading: LoadingState.Started, data: [] },
    },
  })),
  onAction(getExpansionLiveProjects.success, (state, action) => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      liveProjects: { loading: LoadingState.Finished, data: action.payload },
    },
  })),
  onAction(getExpansionLiveProjects.failure, state => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      liveProjects: { loading: LoadingState.Failed, data: [] },
    },
  })),
  onAction(getExpansionUpcomingProjects.request, state => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      upcomingProjects: { loading: LoadingState.Started, data: [] },
    },
  })),
  onAction(getExpansionUpcomingProjects.success, (state, action) => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      upcomingProjects: { loading: LoadingState.Finished, data: action.payload },
    },
  })),
  onAction(getExpansionUpcomingProjects.failure, state => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      upcomingProjects: { loading: LoadingState.Failed, data: [] },
    },
  })),
  onAction(setLiveExpandedId, (state, action) => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      expandedLiveArtistId: action.payload,
    },
  })),
  onAction(setUpcomingExpandedId, (state, action) => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      expandedUpcomingArtistId: action.payload,
    },
  })),
  onAction(resetExpansions, state => ({
    ...state,
    expansionState: {
      ...state.expansionState,
      expandedUpcomingArtistId: undefined,
      expandedLiveArtistId: undefined,
    },
  }))
);

export const reducer = persistReducer(persistConfig, projectsReducer);
