import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TargetType } from 'backend-api/models';
import { TARGET_OPTIONS } from '../../project/constants';
import { filtersSelector } from '../selectors';
import { useSetFiltersForCurrentStatus } from './use-set-filters-for-current-status';

export const useTargetValue = (): [TargetType, (targetValue: number) => void] => {
  const filters = useSelector(filtersSelector);
  const filtersByStatus = filters.filtersByStatus[filters?.status?.id || 0] || {};
  const targetType = filtersByStatus.targetType || 'Artist';

  const setFiltersForCurrentStatus = useSetFiltersForCurrentStatus();

  const changeTargetValue = useCallback(
    (value: number) => {
      const targetType = TARGET_OPTIONS.find(option => option.id === value)?.name;

      setFiltersForCurrentStatus({ targetType, offset: 0 });
    },
    [setFiltersForCurrentStatus]
  );

  return [targetType, changeTargetValue];
};
