import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { theme } from 'app/theme';

export const SViewProject = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  .contacts {
    display: flex;
    align-items: center;
    margin-top: 26px;
    color: ${theme.textLabelColor};
  }
  .campaign-details {
    &__delete-button-icon {
      margin-left: 5px;
    }

    &__container {
      flex: 1;
      padding-bottom: 44px;
    }

    &__text {
      max-width: 350px;
      line-height: 15px;
      display: inline-block;
      ${elipsizeText}
    }
    & .tick-icon {
      margin-right: 5px;
    }

    & .project {
      &__phone {
        margin-right: 13px;
      }
      &__dot {
        margin-right: 13px;
      }
    }
  }
`;
