import React, { useMemo } from 'react';
import { ErrorViewStates } from 'common-v2/components';
import { GlobalLayout, ErrorConfig } from 'app/components/global-layout';
import { bem } from 'utils/bem';
import { SErrorViewContainer, BEM_CLASS } from './s-error-view-container';

const classes = bem(BEM_CLASS);

interface ErrorViewProps {
  type: ErrorViewStates;
  action?(): void;
}

export const ErrorViewContainer = React.memo(({ type, action }: ErrorViewProps) => {
  const errorConfig: ErrorConfig = useMemo(
    () => ({
      type,
      onRefreshAfterError: action,
    }),
    [type, action]
  );

  return (
    <SErrorViewContainer>
      <GlobalLayout errorConfig={errorConfig} className={classes('manhattan-background')} />
    </SErrorViewContainer>
  );
});
