import React from 'react';
import { Icon } from 'common/components/icon';
import { SFlagSprite } from './s-flag-sprite';

interface FlagSpriteProps {
  icon?: string;
  size?: number;
  className?: string;
}

export const FlagSprite = React.memo(({ icon, size = 48, className }: FlagSpriteProps) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    const image = require(`assets/flags/${icon}.sprite.svg`).default;
    return (
      <svg viewBox={image.viewBox} className={className} width={size} height={size}>
        <use xlinkHref={`#${image.id}`} />
      </svg>
    );
  } catch {
    return (
      <SFlagSprite className={className} size={size}>
        <Icon name="placeholder" size="large" />
      </SFlagSprite>
    );
  }
});
