import styled from 'styled-components';
import {
  background,
  border,
  color,
  compose,
  Config,
  flexbox,
  FlexboxProps,
  grid,
  layout,
  position,
  shadow,
  space,
  SpaceProps,
  system,
  typography,
} from 'styled-system';

/*
 * WARNING!
 * With great power comes great responsibility
 * Try not overuse this technology and follow css guidlines from readme.md
 * Inside app use only for light positioning
 */

/*
 * 1. Space
 * https://styled-system.com/api/#space
 * margin, m
 * marginTop, mt
 * marginRight, mr
 * marginBottom, mb
 * marginLeft, ml
 * marginX, mx	= margin-left + margin-right
 * marginY, my	= margin-top + margin-bottom
 * padding, p
 * paddingTop, pt
 * paddingRight, pr
 * paddingBottom, pb
 * paddingLeft, pl
 * paddingX, px	= padding-left + padding-right
 * paddingY, py = padding-top + padding-bottom
 * */

/*
 * 2. Color
 * https://styled-system.com/api/#color
 * color, c
 * backgroundColor, bg
 * */

/*
 * 3. Typography
 * https://styled-system.com/api/#typography
 * fontFamily, fontSize, fontWeight, lineHeight, letterSpacing, textAlign, fontStyle
 * */

/* 4. Layout
 * https://styled-system.com/api/#layout
 * width, height, display, minWidth, minHeight, maxWidth, maxHeight, size, verticalAlign
 *
 * Numbers from 0-1 are converted to percentage widths.
 * Numbers greater than 1 are converted to pixel values.
 * String values are passed as raw CSS values.
 * And arrays are converted to responsive width styles https://styled-system.com/responsive-styles/
 * <SBox
 *   width={[
 *     1,    // 100% below the smallest breakpoint
 *     1/2,  // 50% from the next breakpoint and up
 *     1/4   // 25% from the next breakpoint and up
 *   ]}
 * />
 * <SBox fontSize={[ 1, 2, 3 ]} />
 * <SBox m={[ 1, 2, 3 ]} />
 * <SBox p={[ 1, 2, 3 ]} />
 * If theme.sizes is defined, the width prop will attempt to pick up values from the theme
 * */

/*
 * 5. Flexbox
 * https://styled-system.com/api/#flexbox
 * alignItems, alignContent, justifyItems, justifyContent, flexWrap, flexDirection, flex, flexGrow,
 * flexShrink, flexBasis, justifySelf, alignSelf, and order
 * */

/*
 * 6. Grid
 * https://styled-system.com/api/#grid-layout
 * gridGap, gridColumnGap, gridRowGap, gridColumn, gridRow, gridAutoFlow, gridAutoColumns,
 * gridAutoRows, gridTemplateColumns, gridTemplateRows, gridTemplateAreas, and gridArea
 * */

/*
 * 7. Background
 * https://styled-system.com/api/#background
 * backgroundImage, backgroundSize, backgroundPosition, backgroundRepeat
 * */

/*
 * 8. Border
 * https://styled-system.com/api/#border
 * border, borderTop, borderRight, borderBottom, borderLeft, borderWidth,
 * borderStyle, borderColor, borderRadius
 * */

/*
 * 9. Position
 * https://styled-system.com/api/#position
 * position, zIndex, top, right, bottom, left
 * */

/*
 * 10. Shadow
 * https://styled-system.com/api/#shadow
 * textShadow, boxShadow
 * */

/*
 * 11. Alias
 * https://styled-system.com/custom-props#aliases
 * */
const config: Config = {
  display: {
    property: 'display',
    scale: 'displays',
  },
  d: {},
};

config.d = config.display;

export const SBox = styled.div<FlexboxProps & SpaceProps>`
  ${compose(
    space,
    color,
    typography,
    layout,
    flexbox,
    grid,
    background,
    border,
    position,
    shadow,
    system(config) // alias for display
  )}
`;
