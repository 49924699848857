import numeral from 'numeral';
import { Optional } from 'backend-api/models';
import { DEFAULT_CURRENCY_CODE, CURRENCY_FORMATS, DEFAULT_CURRENCY_SIGN, CURRENCY_CODE_TO_SIGN } from '../constants';

export const getCurrencyFormatted = (
  value: Optional<string | number>,
  currencyCode = DEFAULT_CURRENCY_CODE,
  displayCurrencyCode = false,
  format = CURRENCY_FORMATS.default
): string => {
  const currencySign = CURRENCY_CODE_TO_SIGN[currencyCode] || DEFAULT_CURRENCY_SIGN;

  const formattedNumber = numeral(value)
    .format(format)
    .toUpperCase();

  return displayCurrencyCode ? currencySign + formattedNumber + ' ' + currencyCode : currencySign + formattedNumber;
};

export const getShortCurrencyFormatted = (
  value: Optional<string | number>,
  currencyCode = DEFAULT_CURRENCY_CODE,
  displayCurrencyCode = false
): string => getCurrencyFormatted(value, currencyCode, displayCurrencyCode, CURRENCY_FORMATS.short);
