import React from 'react';
import { bem } from 'utils/bem';
import { STooltip } from './s-tooltip';
import { colorPalette } from 'app/theme/colors';
import { useProjectCurrencyFormat } from 'hooks';
import { isNumber } from 'lodash';
import { formatBudgetInfoPercentage } from 'project/components/project-details/components/budget-widget/transducers';
import { formatRemainingBudgetInfoPercentage } from './transducers';

interface Props {
  title?: string;
  allocation?: number;
  budget?: number;
  accentColor?: string;
}

const classes = bem('tooltip');

export const Tooltip = React.memo(({ title, allocation, budget, accentColor = colorPalette.cottonCandy }: Props) => {
  const formattedAllocation = useProjectCurrencyFormat(allocation);

  return (
    <STooltip accentColor={accentColor}>
      <div className={classes('container')}>
        {title && <span className={classes('title')}>{title}</span>}
        <div className={classes('values-container')}>
          <div className={classes('row')}>
            <div className={classes('accent-indicator')} />
            <span className={classes('row-title')}>Allocated</span>
            <div className={classes('row-element')}>
              <span className={classes('row-value')}>{isNumber(allocation) ? formattedAllocation : 'N/A'}</span>
              <span className={classes('row-value-percentage')}>{formatBudgetInfoPercentage(allocation, budget)}</span>
            </div>
          </div>
          <div className={classes('row')}>
            <div className={classes('indicator')} />
            <span className={classes('row-title')}>Remaining</span>
            <div className={classes('row-element')}>
              <span className={classes('row-value')}>{formatBudgetInfoPercentage(allocation, budget)}</span>
              <span className={classes('row-value-percentage')}>
                {formatRemainingBudgetInfoPercentage(allocation, budget)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </STooltip>
  );
});
