import { actionsGroup } from 'core/store';
import { Id, ProductFamily, Playlist } from 'backend-api/models';
import {
  AttachReleasesTab,
  EditPhasePayload,
  ProductFamiliesSortFields,
  ShowAttachReleasesModalPayload,
} from 'common/types/phases';
import { Sort } from 'utils/sort';

const { action, asyncAction } = actionsGroup('common');

export const openAttachReleasesModal = action<ShowAttachReleasesModalPayload>('OPEN_ATTACH_RELEASES_MODAL');
export const closeAttachReleasesModal = action<void>('CLOSE_ATTACH_RELEASES_MODAL');
export const setAttachReleasesModalActiveTab = action<AttachReleasesTab>('SET_ATTACH_RELEASES_MODAL_ACTIVE_TAB');
export const resetAttachReleasesModal = action<void>('RESET_ATTACH_RELEASES_MODAL');
export const getProductFamilies = asyncAction<Id, ProductFamily[], Error>('GET_PRODUCT_FAMILIES');
export const setExpandedProductFamilies = action<ProductFamily[]>('SET_EXPANDED_PRODUCT_FAMILIES');
export const toggleProductFamilyExpand = action<ProductFamily>('TOGGLE_PRODUCT_FAMILY_EXPAND');
export const setProductFamiliesSearch = action<string>('SET_PRODUCT_FAMILIES_SEARCH');
export const setPlaylistsSearch = action<string>('SET_PLAYLISTS_SEARCH');
export const setPendingProductFamilies = action<ProductFamily[]>('SET_PENDING_PRODUCT_FAMILIES');
export const searchPlaylists = asyncAction<string, Playlist[], Error>('SEARCH_PLAYLISTS');
export const setPlaylists = action<Playlist[]>('SET_PLAYLISTS');
export const setSelectedPlaylists = action<Playlist[]>('SET_SELECTED_PLAYLISTS');
export const sortProductFamilies = action<Sort<ProductFamiliesSortFields>>('SORT_PRODUCT_FAMILIES');
export const setSelectedProductFamilies = action<ProductFamily[]>('SET_SELECTED_PRODUCT_FAMILIES');
export const setSelectedProductFamilyTrack = action<ProductFamily[]>('SET_SELECTED_PRODUCT_FAMILY_TRACK');
export const editPhase = asyncAction<EditPhasePayload, void, Error>('EDIT_PHASE');
