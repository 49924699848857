import styled from 'styled-components';

export const BEM_CLASS = 'search-input';

export const SSearchInput = styled.div`
  .${BEM_CLASS} {
    &__input-container {
      margin: 0 7.5px 0;
    }

    &__input-tooltip {
      width: 100%;
    }

    &__input {
      width: 100%;
    }
  }
`;

export const TOOLTIP_BEM_CLASS = 'search-input-tooltip';

export const SSearchInputTooltip = styled(({ className, children }) => children(className))`
  .${TOOLTIP_BEM_CLASS} {
    &__content {
      max-width: 637px;
    }
  }
`;
