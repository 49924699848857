import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'project-form';

export const SProjectForm = styled.div`
  width: 100%;
  padding-left: 130px;
  border-top: 1px solid ${transparentize(0.9, manhattanThemeColors.placeholderTextColor)};

  .${BEM_CLASS} {
    &__container {
      display: flex;
      flex-direction: column;
      padding: 30px;
    }

    &__form {
      margin-top: 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &__field {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__name-input {
      width: 538px;
    }

    &__grass-id-input {
      width: 259px;
    }

    &__grass-id-container {
      display: flex;
    }

    &__copy-wrapper {
      margin-top: auto;
      margin-left: 20px;
    }

    &__copy {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: ${transparentize(0.95, manhattanThemeColors.primaryFillNew)};
      border-radius: 50%;
      font-size: 17px;
    }

    &__icon {
      font-size: 18px;
    }

    &__separator {
      margin-top: 5px;
      height: 1px;
      background-color: ${transparentize(0.9, manhattanThemeColors.placeholderTextColor)};
    }
  }
`;
