import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { Card } from 'common-v2/components';
import { CARD_SIZE } from '../../constants';

export const BEM_CLASS = 'campaign-card';

export const SCampaignCard = styled(Card)`
  .${BEM_CLASS} {
    &__icon {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${CARD_SIZE}px;
      background-color: ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
      color: ${colorPalette.woodsmoke};
    }

    &__content {
      justify-content: center;
      padding: 5px 20px;
    }

    &__name {
      ${elipsizeText};
      max-width: 100%;
    }

    &__badge {
      margin-right: 5px;
    }

    &__info {
      display: flex;
      align-items: baseline;
      max-width: 100%;
      color: ${manhattanThemeColors.grayedText};
    }

    &__targets {
      ${elipsizeText};
      flex-grow: 1;
    }

    &__date {
      flex-shrink: 0;
    }
  }
`;
