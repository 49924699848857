import { css, FlattenSimpleInterpolation } from 'styled-components';

export type BgStyle = 'white' | 'grey';

interface BackgroundStyleProps {
  theme: any;
  bgStyle?: BgStyle;
  disabled?: boolean;
}

export const bgStyle = ({ theme, bgStyle = 'white', disabled }: BackgroundStyleProps) => {
  const bgStyleProps = {
    grey: {
      regular: theme.colors.input.background.grey,
      disabled: theme.colors.input.background.disabled.grey,
    },
    white: {
      regular: theme.colors.input.background.light,
      disabled: theme.colors.input.background.disabled.light,
    },
  };

  return bgStyleProps[bgStyle][disabled ? 'disabled' : 'regular'];
};

interface BorderStyleProps {
  theme: any;
  isRequired?: boolean;
  isInvalid?: boolean;
}

export const inputBorderColor = (isFocused?: boolean) => ({ theme, isRequired, isInvalid }: BorderStyleProps) => {
  if (isFocused) {
    return theme.colors.input.border.focus;
  }

  if (isInvalid) {
    return theme.colors.input.border.error;
  }

  if (isRequired) {
    return theme.colors.input.border.required;
  }

  return 'transparent';
};

export const gradientBorder = (radius: number, backgroundImage: string, zIndex = 1): FlattenSimpleInterpolation => {
  return css`
    position: relative;

    &::after {
      content: '';
      position: absolute;
      z-index: ${zIndex};
      inset: 0;
      padding: 1px;
      border-radius: ${radius}px;
      pointer-events: none;

      -webkit-mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);
      mask: linear-gradient(white 0 0) content-box, linear-gradient(white 0 0);

      -webkit-mask-composite: xor;
      mask-composite: exclude;

      background-image: ${backgroundImage};
    }
  `;
};
