import React from 'react';
import PropTypes from 'prop-types';
import { SModalContainer } from '../../s-components/modal/s-container';
import { bem } from 'utils/bem';

const { string, number, oneOfType } = PropTypes;

export const ModalContainer = ({ children, width, top, transform, minWidth, bgStyle, dataSelector }) => {
  const classes = bem('container');
  return (
    <SModalContainer
      data-selector={dataSelector}
      bgStyle={bgStyle}
      minWidth={minWidth}
      width={width}
      top={top}
      transform={transform}
    >
      <div className={classes()}>{children}</div>
    </SModalContainer>
  );
};

ModalContainer.propTypes = {
  children: PropTypes.any,
  width: oneOfType([number, string]),
};

ModalContainer.defaultProps = {
  width: 400,
  children: null,
};
