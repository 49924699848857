import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'media-plan-header';

export const SMediaPlanHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 0 30px;

  .${BEM_CLASS} {
    &__header {
      display: flex;
      width: 100%;
      align-items: center;
    }

    &__requester-label {
      font-size: 12px;
      margin-right: 6px;
      letter-spacing: 1.2px;
    }
    &__requester-team {
      margin-right: 25px;
    }

    &__mode-controls {
      margin-right: auto;
    }

    &__controls {
      display: flex;
      align-items: center;
    }

    &__mode-container {
      margin-bottom: 20px;
    }

    &__title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      height: 35px;
    }

    &__title-loader {
      margin: 15px 0 11px;
    }

    &__settings-dropdown {
      margin-right: 10px;
    }

    &__expand {
      width: 22px;
      height: 22px;
      border: unset;
      color: black;
      min-height: unset;
      border: 1px solid ${transparentize(0.5, colorPalette.mischka)};
      background: white;
      border-radius: 20px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      :hover {
        background-color: ${transparentize(0.9, colorPalette.azureRadiance)};
      }
    }

    &__divider {
      height: 15px;
      width: 1px;
      margin: 0 20px;
      background: ${colorPalette.iron};
    }

    &__info {
      margin-right: auto;
    }
  }
`;
