import { Api } from 'backend-api';
import { asyncEpic, ofType } from 'core/epics';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap, mergeMap, concatMap } from 'rxjs/operators';
import {
  editArtistMembers,
  editUserArtists,
  getLabelArtists,
  getLabelArtistsList,
  getLabelCounters,
  getLabelTeamMembers,
  getLabelUsers,
} from './actions';

export const getLabelCountersEpic = asyncEpic(
  getLabelCounters,
  ({ payload }) => Api.getLabelPermissionsCounters(payload),
  undefined,
  { showError: false }
);

export const getLabelTeamMembersEpic = asyncEpic(
  getLabelTeamMembers,
  ({ payload }) => Api.getLabelPermissionsTeamMembers(payload),
  undefined,
  { showError: false }
);

export const getLabelArtistsEpic = asyncEpic(
  getLabelArtists,
  ({ payload }) => Api.getLabelArtists(payload),
  undefined,
  { showError: false }
);

export const editUserArtistsEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(editUserArtists.request),
    switchMap(({ payload: { labelId, userId, addedArtistsIds, deletedArtistsIds } }) => {
      const requests: Observable<unknown>[] = [];
      addedArtistsIds.length > 0 && requests.push(Api.addUserToLabelArtists(labelId, userId, addedArtistsIds));
      deletedArtistsIds.length > 0 && requests.push(Api.removeUserFromLabelArtists(labelId, userId, deletedArtistsIds));

      return forkJoin(requests).pipe(
        mergeMap(result => [editUserArtists.success(result)]),
        catchError(error => {
          return of(editUserArtists.failure(error));
        })
      );
    })
  );
};

export const getLabelPermissionsArtistsEpic = asyncEpic(
  getLabelArtistsList,
  ({ payload }) => Api.getLabelPermissionsArtists(payload),
  undefined,
  { showError: false }
);

export const getLabelUsersEpic = asyncEpic(getLabelUsers, ({ payload }) => Api.getLabelUsers(payload), undefined, {
  showError: false,
});

export const editArtistMembersEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(editArtistMembers.request),
    switchMap(({ payload: { labelId, artistId, addedMembersIds, deletedMembersIds } }) => {
      const addMembers = (): Observable<unknown> =>
        addedMembersIds.length > 0 ? Api.addMembersToArtist(labelId, artistId, addedMembersIds) : of(null);
      const removeMembers = (): Observable<unknown> =>
        deletedMembersIds.length > 0 ? Api.removeMembersFromArtist(labelId, artistId, deletedMembersIds) : of(null);

      // HOTFIX: BE bug (DEC-11303)
      return addMembers().pipe(
        concatMap(() => removeMembers()),
        mergeMap(result => [editArtistMembers.success(result)]),
        catchError(error => {
          return of(editArtistMembers.failure(error));
        })
      );
    })
  );
};
