import React from 'react';
import {
  Artist,
  CampaignCategory,
  CampaignSearch,
  Optional,
  Playlist,
  ProjectSearch,
  TargetItems,
} from 'backend-api/models';
import { isPlaylists } from 'backend-api/guards';
import { getPrimaryTargets } from 'common/transducers';
import { CampaignIcon } from 'common/components/campaign-icon';
import { Badge } from 'common/components/badge';
import { paths } from 'app/routing/paths';
import { CampaignPlatformIcon } from 'common/components/campaign-platform-icon';
import { isIconName } from 'common/components/icon/guards';
import { UUID } from 'io-ts-types/lib/UUID';
import { parseDateFromISOString, getFormattedDate, isDateAfter } from 'common-v2/utils';

export const aggregateTargetsNames = (items: Pick<Artist | Playlist, 'name'>[]) =>
  items.map(({ name }) => name).join(', ');

export const getProjectInfo = (targets: TargetItems): string => {
  const primaryTargets = getPrimaryTargets(targets);

  if (primaryTargets.length === 0) return '';

  const isPlaylist = isPlaylists(primaryTargets);

  if (primaryTargets.length === 1) return primaryTargets[0].name || (isPlaylist ? 'Playlist' : 'Artist');

  return isPlaylist ? 'Various Playlists' : 'Various Artists';
};

export const getCampaignLink = (isUnassigned: boolean, uuid: UUID, project?: ProjectSearch): string => {
  if (isUnassigned) {
    return paths.unassignedCampaignDetails(uuid);
  }

  return project ? paths.campaign(project.id, uuid) : '';
};

export const getCampaignIcon = (campaign: CampaignSearch, campaignCategories?: CampaignCategory[]): JSX.Element => {
  if (campaign.platforms?.length === 1 && isIconName(campaign.platforms[0])) {
    return <CampaignPlatformIcon platforms={campaign.platforms} iconSize="large" />;
  }

  const campaignCategory = campaignCategories?.find(category => category.id === campaign.categoryId);
  return <CampaignIcon campaignCategory={campaignCategory} size="large" />;
};

export const getCampaignBadge = (isUnassigned: boolean, isPending: boolean): Optional<JSX.Element> => {
  if (isUnassigned) {
    return <Badge text="Unassigned" />;
  }

  if (isPending) {
    return <Badge text="Pending" />;
  }

  return undefined;
};

export const getScheduleDate = (startDate: Optional<string>, endDate: Optional<string>, dateFormat: string): string => {
  if (!startDate) return '';

  const formatDate = getFormattedDate(dateFormat);
  const campaignStartDate = parseDateFromISOString(startDate);

  if (endDate) {
    const campaignEndDate = parseDateFromISOString(endDate);

    return `${formatDate(campaignStartDate)}-${formatDate(campaignEndDate)}`;
  }

  const currentDate = new Date();
  const isStartDateInFuture = isDateAfter(campaignStartDate, currentDate);

  return isStartDateInFuture ? `Starts ${formatDate(campaignStartDate)}` : `Started ${formatDate(campaignStartDate)}`;
};

export const getCampaignNameOrPlaceholder = (name?: string): string => name || 'No description';
