import { CampaignDetails, Optional, ProjectDetails } from 'backend-api/models';
import { createTypedReducer, onAction } from 'core/store';
import {
  openManagingCampaigns,
  closeManagingCampaigns,
  getProject,
  getProjectCampaigns,
  resetProject,
  resetProjectError,
  resetProjectCampaignsError,
  getDigitalCampaigns,
  manageExternalCampaigns,
  resetManageAssignedCampaignsError,
  removeExternalCampaign,
} from './actions';
import { ApiError } from 'backend-api';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { Loadable, Loading } from 'common-v2/types';

export interface ReportingState {
  project: Loadable<Optional<ProjectDetails>>;
  projectError?: ApiError<ProjectDetailsErrorResponse>;
  projectCampaigns: Loadable<CampaignDetails[]>;
  managingCampaigns: {
    isOpened: boolean;
    campaigns: Loadable<CampaignDetails[]>;
    total: number;
    infiniteLoading: Loading;
    assignedCampaigns: {
      loading: Loading;
      error?: ApiError;
    };
  };
  projectCampaignsError?: ApiError;
}

export const initialState: ReportingState = {
  project: { loading: Loading.Idle, data: undefined },
  projectCampaigns: { loading: Loading.Idle, data: [] },
  managingCampaigns: {
    isOpened: false,
    campaigns: { loading: Loading.Idle, data: [] },
    total: 0,
    infiniteLoading: Loading.Idle,
    assignedCampaigns: { loading: Loading.Idle },
  },
};

export const reducer = createTypedReducer<ReportingState>(
  initialState,
  onAction(getProject.request, state => ({
    ...state,
    project: {
      ...state.project,
      loading: Loading.Started,
    },
  })),
  onAction(getProject.success, (state, action) => ({
    ...state,
    project: {
      loading: Loading.Finished,
      data: action.payload,
    },
  })),
  onAction(getProject.failure, (state, action) => ({
    ...state,
    project: {
      loading: Loading.Failed,
      data: undefined,
    },
    projectError: action.payload,
  })),
  onAction(resetProject, state => ({
    ...state,
    project: {
      loading: Loading.Idle,
      data: undefined,
    },
  })),
  onAction(resetProjectError, state => ({
    ...state,
    projectError: undefined,
  })),
  onAction(getProjectCampaigns.request, state => ({
    ...state,
    projectCampaigns: {
      ...state.projectCampaigns,
      loading: Loading.Started,
    },
  })),
  onAction(getProjectCampaigns.success, (state, action) => ({
    ...state,
    projectCampaigns: {
      loading: Loading.Finished,
      data: action.payload,
    },
  })),
  onAction(getProjectCampaigns.failure, (state, action) => ({
    ...state,
    projectCampaigns: {
      ...state.projectCampaigns,
      loading: Loading.Failed,
    },
    projectCampaignsError: action.payload,
  })),
  onAction(getDigitalCampaigns.request, (state, { payload }) => ({
    ...state,
    managingCampaigns: {
      ...state.managingCampaigns,
      infiniteLoading: payload.isInfinite ? Loading.Started : state.managingCampaigns.infiniteLoading,
      campaigns: {
        ...state.managingCampaigns.campaigns,
        loading: payload.isInfinite ? state.managingCampaigns.campaigns.loading : Loading.Started,
      },
    },
  })),
  onAction(getDigitalCampaigns.success, (state, { payload }) => ({
    ...state,
    managingCampaigns: {
      ...state.managingCampaigns,
      infiniteLoading: payload.isInfinite ? Loading.Finished : state.managingCampaigns.infiniteLoading,
      total: payload.total,
      campaigns: {
        loading: payload.isInfinite ? state.managingCampaigns.campaigns.loading : Loading.Finished,
        data: payload.isInfinite ? state.managingCampaigns.campaigns.data.concat(payload.items) : payload.items,
      },
    },
  })),
  onAction(getDigitalCampaigns.failure, state => ({
    ...state,
    managingCampaigns: {
      ...state.managingCampaigns,
      campaigns: {
        ...state.managingCampaigns.campaigns,
        loading: Loading.Failed,
      },
    },
  })),
  onAction(manageExternalCampaigns.request, state => ({
    ...state,
    managingCampaigns: {
      ...state.managingCampaigns,
      assignedCampaigns: {
        ...state.managingCampaigns.assignedCampaigns,
        loading: Loading.Started,
      },
    },
  })),
  onAction(manageExternalCampaigns.success, state => ({
    ...state,
    managingCampaigns: {
      ...state.managingCampaigns,
      assignedCampaigns: {
        ...state.managingCampaigns.assignedCampaigns,
        loading: Loading.Finished,
      },
    },
  })),
  onAction(manageExternalCampaigns.failure, (state, { payload }) => ({
    ...state,
    managingCampaigns: {
      ...state.managingCampaigns,
      assignedCampaigns: {
        error: payload,
        loading: Loading.Failed,
      },
    },
  })),
  onAction(removeExternalCampaign.request, state => ({
    ...state,
    projectCampaigns: {
      ...state.projectCampaigns,
      loading: Loading.Started,
    },
  })),
  onAction(removeExternalCampaign.success, state => ({
    ...state,
    projectCampaigns: {
      ...state.projectCampaigns,
      loading: Loading.Finished,
    },
  })),
  onAction(removeExternalCampaign.failure, state => ({
    ...state,
    projectCampaigns: {
      ...state.projectCampaigns,
      loading: Loading.Finished,
    },
  })),
  onAction(openManagingCampaigns, state => ({
    ...state,
    managingCampaigns: {
      ...state.managingCampaigns,
      isOpened: true,
      assignedCampaigns: {
        error: undefined,
        loading: Loading.Idle,
      },
    },
  })),
  onAction(closeManagingCampaigns, state => ({
    ...state,
    managingCampaigns: {
      ...state.managingCampaigns,
      isOpened: false,
    },
  })),
  onAction(resetProjectCampaignsError, state => ({
    ...state,
    projectCampaignsError: undefined,
  })),
  onAction(resetManageAssignedCampaignsError, state => ({
    ...state,
    manageCampaigns: {
      ...state.managingCampaigns,
      assignedCampaigns: {
        ...state.managingCampaigns.assignedCampaigns,
        error: undefined,
      },
    },
  }))
);
