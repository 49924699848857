import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
interface GetLabelBackgroundParams {
  isEllipsis?: boolean;
  disabled?: boolean;
}

type Props = GetLabelBackgroundParams;

const getLabelBackground = ({ isEllipsis, disabled }: GetLabelBackgroundParams) => {
  if (isEllipsis) return palette.athensGray;
  if (disabled) return palette.athensGrayTwo;

  return palette.darkBlueTwo;
};

export const SMultiSelectLabel = styled.div<Props>`
  .multiselect {
    &__value-container {
      padding: 3px 5px;
      border-radius: 2px;
      margin: 4px 0 5px 0;
      height: auto;
      min-width: auto;
      background: ${getLabelBackground};

      & .button__text {
        color: ${({ disabled }) => (disabled ? palette.athensGraySeven : 'white')};
        text-transform: initial;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.1px;
      }

      & i:before {
        color: ${({ disabled }) => (disabled ? palette.athensGraySix : 'white')};
        font-size: 12px;
        margin: 0 1px;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.1px;
      }
    }
  }
`;
