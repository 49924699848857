import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import { EXPANDED_CONTAINER_HEIGHT, EXPANDED_CONTAINER_WIDTH } from './constants';

export const BEM_CLASS = 'expandable-links';

interface Props {
  isMaxValue?: boolean;
  isEmpty?: boolean;
}

export const SExpandableLinks = styled(({ className, children }) => children(className))<Props>`
  display: flex;
  flex-direction: column;
  width: ${EXPANDED_CONTAINER_WIDTH}px;
  height: ${EXPANDED_CONTAINER_HEIGHT}px;

  &&& {
    .${BEM_CLASS} {
      &__values-container {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        gap: ${({ isEmpty }) => (isEmpty ? '0' : '5px 10px')};
        padding: 5px 20px;
      }

      &__select-container {
        height: 100%;
        width: 100%;
      }

      &__control {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: unset;
      }

      &__value-container {
        height: 100%;
        padding: 0;
      }

      &__value-counter {
        color: ${({ isMaxValue }) =>
          isMaxValue ? manhattanThemeColors.darkRed : manhattanThemeColors.secondGrayedText};
      }

      &__error-notification {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        border-bottom: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
      }

      &__select {
        flex: 1;
        overflow: hidden;
      }
    }
  }
`;
