import React, { useCallback, useMemo } from 'react';
import { ProjectDetails, TargetType } from 'backend-api/models';
import { ProjectSortField } from 'backend-api/types';
import { SAllProjects } from '../../s-components/s-all-projects';
import { getProjectsTableConfig } from './table-config';
import {
  allAssignedProjectsSelector,
  allUnassignedProjectsSelector,
  isLoadingAllProjectsSelector,
} from '../../selectors';
import { useSelector } from 'react-redux';
import { AllProjectsFiltersByStatus } from '../../types';
import { useActiveTab, useProjectsFilters } from '../../hooks';
import { UNASSIGNED_STATUS } from 'common/constants';
import { DEFAULT_SORT } from 'projects/constants';
import { usePagination } from 'hooks';
import { Sort } from 'utils/sort';
import { Table } from 'common/components/table';
import { Pagination } from 'common/components/pagination';
import { TableTheme } from 'app/theme/table';
import { dateFormatSelector } from 'common/selectors';

interface Props {
  targetType: TargetType;
  filters: AllProjectsFiltersByStatus;
  className?: string;
  totalCount: number;
  gradientEnabled?: boolean;
}

export const AllProjects = React.memo(({ targetType, filters, className, totalCount, gradientEnabled }: Props) => {
  const allProjects = useSelector(allAssignedProjectsSelector);
  const unassignedProjects = useSelector(allUnassignedProjectsSelector);
  const isAllProjectLoading = useSelector(isLoadingAllProjectsSelector);
  const dateFormat = useSelector(dateFormatSelector);

  const [activeTab] = useActiveTab();
  const isUnassignedTab = activeTab === UNASSIGNED_STATUS.name;

  const [allFilters, filtersDispatcher] = useProjectsFilters();

  const onSortChange = useCallback(
    (sort: Sort<ProjectSortField>) => {
      filtersDispatcher.setSort(sort);
    },
    [filtersDispatcher]
  );
  const onPaginationChange = useCallback(
    (pagination: Pagination) => {
      filtersDispatcher.setPagination(pagination);
    },
    [filtersDispatcher]
  );

  const sort = useMemo(() => filters.sort || DEFAULT_SORT, [filters.sort]);
  const pagination = usePagination(filters, totalCount);

  const bodyContent: ProjectDetails[] = isUnassignedTab ? unassignedProjects : allProjects;
  const headerContent = getProjectsTableConfig(targetType, dateFormat);
  const theme = gradientEnabled ? TableTheme.WHITE : TableTheme.GREY;

  return (
    <SAllProjects className={className}>
      <Table
        dataSelector="projects-table"
        headerContent={headerContent}
        bodyContent={bodyContent}
        onSortChange={onSortChange}
        sort={sort}
        onPaginationChange={onPaginationChange}
        pagination={pagination}
        isLoading={isAllProjectLoading}
        isSearching={!!allFilters.search}
        theme={theme}
      />
    </SAllProjects>
  );
});
