import { GridApi, RowNode } from 'ag-grid-community';
import { MediaPlanColumnId, SpreadsheetCellData, SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { Id, Taxonomy, Optional, Nullable } from 'backend-api/models';
import { getPlacementsOptions } from 'common/transducers';
import { BaseOption } from 'common/components/select';
import { TOAST_TYPE, ToastOptions } from 'gdb-web-shared-components';

export const processPlacementsOnPlatformSelect = (
  platformIds: Id[],
  api: GridApi<SpreadsheetRowData>,
  node: RowNode<SpreadsheetRowData>,
  openToast: (options: ToastOptions) => void,
  taxonomy?: Taxonomy
) => {
  const availablePlacements =
    getPlacementsOptions(taxonomy?.placements, platformIds || [], taxonomy?.platforms)?.[0]?.options || [];
  const selectedPlacements: SpreadsheetCellData<Optional<Nullable<BaseOption[]>>> = api.getValue(
    MediaPlanColumnId.PLACEMENTS,
    node
  );

  const validPlacements = selectedPlacements?.value?.filter(placement =>
    availablePlacements.some(available => available.id === placement.id)
  );

  if (validPlacements && selectedPlacements?.value && validPlacements.length < selectedPlacements.value.length) {
    openToast({
      id: 'media-plan-placements-warning',
      type: TOAST_TYPE.WARNING,
      message: 'Pasted placements automatically updated to match selected Platforms.',
      preventDuplicate: true,
    });
  }

  if (availablePlacements.length === 0) {
    node?.setDataValue(MediaPlanColumnId.PLACEMENTS, { ...selectedPlacements, value: null });
    api?.refreshCells({ columns: [MediaPlanColumnId.PLACEMENTS], rowNodes: [node], force: true });
  } else {
    node?.setDataValue(MediaPlanColumnId.PLACEMENTS, { ...selectedPlacements, value: validPlacements });
    api?.refreshCells({ columns: [MediaPlanColumnId.PLACEMENTS], rowNodes: [node], force: true });
  }
};
