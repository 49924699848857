const SORT_ORDER_SYMBOL = '-';

export enum SortOrder {
  Ascending = 'ascending',
  Descending = 'descending',
}

export class Sort<SortField extends string> {
  order: SortOrder;
  field: SortField;

  constructor(order: SortOrder, field: SortField) {
    this.order = order;
    this.field = field;
  }

  getString(): string {
    const firstSymbol = this.order === SortOrder.Descending ? SORT_ORDER_SYMBOL : '';
    return firstSymbol + this.field;
  }

  static create<SortField extends string>(
    sortString: string,
    isSortField: (string: string) => string is SortField = (string: string): string is SortField => string.length > 0
  ): Sort<SortField> | undefined {
    const order = sortString.startsWith(SORT_ORDER_SYMBOL) ? SortOrder.Descending : SortOrder.Ascending;
    const field = order === SortOrder.Descending ? sortString.substring(SORT_ORDER_SYMBOL.length) : sortString;

    if (!isSortField(field)) return undefined;

    return new Sort(order, field);
  }
}
