import styled from 'styled-components';
import { Modal } from '../../components/modal/modal';
import { confirmationModalOverlay } from 'app/styles/z-indexes';

export const SModal = styled(Modal)`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity ${props => props.animationDuration}ms ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .modal {
    &__overlay {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: ${confirmationModalOverlay};
      height: 100%;
      overflow: auto;
      background-color: rgba(0, 0, 0, 0.65);
    }
    &__content {
      padding: 0 20px;
    }
  }
`;
