import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';
import { motion } from 'framer-motion';
import { PAGE_WIDTH, SCROLLBAR_WIDTH } from 'common/constants';

export const BEM_CLASS = 'expanding-section';

export const SExpandingSection = styled(motion.div)`
  .${BEM_CLASS} {
    &__expanded-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;
      width: calc(100vw - ${SCROLLBAR_WIDTH}px);
      margin-left: calc((-100vw + 100% + ${SCROLLBAR_WIDTH}px) / 2);

      @media (max-width: ${PAGE_WIDTH}px) {
        width: ${PAGE_WIDTH}px;
        margin-left: calc((100% - ${PAGE_WIDTH}px) / 2);
      }
    }
    &__inner {
      width: 100%;
      z-index: 1;
      position: relative;
    }
    &__triangle {
      position: absolute;
      width: 40px;
      height: 40px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      border-radius: 5px;
      top: -29px;
      background: linear-gradient(135deg, ${colorPalette.white} 50%, transparent 50%);
    }
    &__expansion {
      min-height: 350px;
      margin-top: 15px;
      z-index: 1;
      position: relative;
    }
    &__expansion-background {
      width: 100%;
      height: 100%;
      position: absolute;
      margin-top: 15px;
      background: linear-gradient(180deg, ${colorPalette.white} 0%, ${transparentize(0.5, colorPalette.white)} 60%);
    }
    &__projects-list {
      margin-top: 20px;
    }
    &__loader-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 350px;
      margin-top: 35px;
      z-index: 1;
      position: relative;
    }
    &__error-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 350px;
      margin-top: 35px;
      z-index: 1;
      position: relative;
    }
  }
`;
