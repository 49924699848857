import React from 'react';
import { Icon } from 'common/components/icon';
import { IconColor, IconName } from 'common/components/icon/types';
import { Dropdown, OptionProps } from 'common/components/dropdown';
import { SMenuDropdownOption } from './s-menu-dropdown-option';

export interface DropdownOptionProps extends OptionProps {
  key: string;
  title: string;
  iconName: IconName;
  iconColor?: IconColor;
  onClick(): void;
  disabled?: boolean;
  className?: string;
  dataSelector?: string;
}

export const MenuDropdownOption = ({
  key,
  title,
  iconName,
  iconColor,
  onClick,
  disabled,
  className,
  dataSelector,
}: DropdownOptionProps) => (
  <Dropdown.Option key={key} onClick={onClick} disabled={disabled} className={className} dataSelector={dataSelector}>
    <SMenuDropdownOption>
      <Icon size="general" name={iconName} color={iconColor} dataSelector={dataSelector} />
      <div>{title}</div>
    </SMenuDropdownOption>
  </Dropdown.Option>
);
