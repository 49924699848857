import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import { bem } from 'utils/bem';
import { getInitials } from 'utils/string';
import { projectNotesHistorySelector } from 'project/selectors';
import { SNotesUpdates } from './s-notes-updates';
import { SmallText } from 'common/components/typography';
import { useFormattedDateTime } from 'common/hooks/use-formatted-date-time';

interface Props {
  className?: string;
}

const classes = bem('notes-updates');

export const NotesUpdates = ({ className }: Props) => {
  const notesHistory = useSelector(projectNotesHistorySelector);
  const uniqHistoryItems = useMemo(() => uniqBy(notesHistory.data, event => event.user?.id).reverse(), [notesHistory]);
  const notesHistoryLength = uniqHistoryItems?.length || 0;

  const avatars = useMemo(
    () =>
      uniqHistoryItems?.map((event, index) => (
        <div className={classes('avatar', { last: index === notesHistoryLength - 1 })} key={event.id}>
          {getInitials(event.user?.name || '')}
        </div>
      )),
    [uniqHistoryItems, notesHistoryLength]
  );

  const latestEvent = notesHistory?.data?.[0];
  const formattedDate = useFormattedDateTime({ date: latestEvent?.date, isFullDate: true });

  return (
    <SNotesUpdates className={className}>
      <div className={classes('avatar-container')}>{avatars}</div>
      <div className={classes('last-updated-container')}>
        <SmallText className={classes('last-updated-text')} data-selector="project-notes-user-updates">
          Last updated by {latestEvent?.user?.name || ''}
        </SmallText>
        <SmallText className={classes('last-updated-text')}>{formattedDate?.date || ''}</SmallText>
      </div>
    </SNotesUpdates>
  );
};
