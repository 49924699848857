import styled from 'styled-components';
import { animatedFlare } from 'common/s-components/s-animated-flare';

export const SLoaderCategory = styled.div`
  height: 15px;
  .loader-category {
    &__clip-container {
      width: 230px;
      height: 60px;
      clip-path: url(#loader-category);
      -webkit-clip-path: url(#loader-category);
      ${animatedFlare}
    }
  }
`;
