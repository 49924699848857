import React, { useCallback, useState } from 'react';
import { Icon, ICON_SIZE, Tooltip, TOOLTIP_PLACEMENT, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { SProjectId, BEM_CLASS } from './s-project-id';

interface ProjectIdProps {
  className?: string;
  dataSelector?: string;
  id: string;
}

const classes = bem(BEM_CLASS);

export const ProjectId = React.memo(({ className, dataSelector = 'project-id', id }: ProjectIdProps) => {
  const [tooltipText, setTooltipText] = useState('Copy');

  const copyGrasId = useCallback(() => {
    navigator.clipboard.writeText(id).then(() => {
      setTooltipText('Copied');
    });
  }, [id]);

  const resetTooltipText = useCallback(() => {
    setTooltipText('Copy');
  }, []);

  return (
    <SProjectId className={className} data-selector={dataSelector}>
      <Typography type={TYPOGRAPHY_TYPE.body4}>
        <span className={classes('label')}>GRAS ID</span>
        {id}
      </Typography>

      <div className={classes('copy-wrapper')}>
        <Tooltip tooltip={tooltipText} placement={TOOLTIP_PLACEMENT.Top} onClose={resetTooltipText}>
          <button
            type="button"
            className={classes('copy')}
            onClick={copyGrasId}
            data-selector={`${dataSelector}-copy-button`}
          >
            <Icon name="Copy" size={ICON_SIZE.small} />
          </button>
        </Tooltip>
      </div>
    </SProjectId>
  );
});
