import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'project-id';

export const SProjectId = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__label {
      color: ${manhattanThemeColors.grayedText};
      margin-right: 5px;
    }

    &__copy-wrapper {
      margin-left: 10px;
    }

    &__copy {
      width: 25px;
      height: 25px;
      background-color: ${transparentize(0.95, manhattanThemeColors.primaryFillNew)};
      border-radius: 20px;
    }
  }
`;
