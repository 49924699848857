export const getVisibleItemCount = (element: Element) => {
  let visibleItemCount = 0;
  const children = element.children;
  const firstChild = children[0] as HTMLElement;
  const startingOffset = firstChild.offsetLeft;
  for (let i = 0; i < children.length; i++) {
    const child = children[i] as HTMLElement;
    if (i !== 0 && child.offsetLeft === startingOffset) {
      break;
    }
    visibleItemCount++;
  }
  return visibleItemCount;
};
