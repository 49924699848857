import React from 'react';
import { useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { useIsCampaignRouteUnassigned } from 'hooks';
import { AnchorLink } from 'common/components/anchor-link';
import { SFlex } from 'common/s-components/layout/s-flex';
import { SHeader } from '../../s-components/s-header';
import { activeCampaignSelector, adSetsSelector, filtersSelector, isLoadingSelector } from '../../selectors';
import Title from './title';
import { PlatformDetails } from 'campaign/components/platform-details';
import { getCampaignNameOrPlaceholder } from 'app/components/search-modal/transducers';

interface Props {
  onViewAdSetsClick: () => void;
}

const classes = bem('campaign-header');

export const Header = React.memo(({ onViewAdSetsClick }: Props) => {
  const campaign = useSelector(activeCampaignSelector);
  const adSets = useSelector(adSetsSelector);
  const filters = useSelector(filtersSelector);
  const isLoading = useSelector(isLoadingSelector);

  const isCampaignRouteUnassigned = useIsCampaignRouteUnassigned();

  const isShowingAds = ((adSets && adSets?.total > 0) || filters.search) && !isLoading;

  return (
    <SHeader>
      <SFlex justifyContent="space-between" alignContent="center" alignItems="center" flexWrap="nowrap">
        <Title
          text={getCampaignNameOrPlaceholder(campaign?.name)}
          status={campaign?.workflowStatus?.status}
          statusId={campaign?.workflowStatus?.status}
          isCampaignUnassigned={isCampaignRouteUnassigned}
        />
        <SFlex>
          {isShowingAds && (
            <AnchorLink
              onClick={onViewAdSetsClick}
              title={'View Ad Sets'}
              className={classes('ad-sets-link')}
              dataSelector="ad-sets"
            />
          )}
        </SFlex>
      </SFlex>
      {campaign && campaign.externalName && campaign.externalId && (
        <PlatformDetails source={campaign?.source} name={campaign.externalName} id={campaign.externalId} />
      )}
    </SHeader>
  );
});
