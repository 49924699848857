import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, CLIP_PATH_ID, STerritoriesLoader } from './s-territories-loader';

interface Props {
  className?: string;
}

const classes = bem(BEM_CLASS);

export const TerritoriesLoader = React.memo(({ className }: Props) => {
  return (
    <STerritoriesLoader className={className}>
      <div className={classes('clip-container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <path d={`m 0 0 h 90 v 30 h -90 v -30`} />
          </clipPath>
        </defs>
      </svg>
    </STerritoriesLoader>
  );
});
