import styled from 'styled-components';
import { getStatusBadgeStyling, getStatusTextStyling } from './transducers';
import { CampaignStatuses } from 'backend-api/models';

interface Props {
  status: CampaignStatuses;
}

export const BEM_CLASS = 'status-badge';

export const SStatusBadge = styled.div<Props>`
  .${BEM_CLASS} {
    &__badge {
      ${({ status }) => getStatusBadgeStyling(status)}
    }

    &__text {
      ${({ status }) => getStatusTextStyling(status)}
    }
  }
`;
