import React from 'react';
import { BEM_CLASS, SPreferences } from './s-preferences';
import { bem } from 'utils/bem';
import { H4 } from 'common/components/typography';
import { DateFormatSelector } from './components';

interface Props {
  className?: string;
  dateFormat: string;
  onDateFormatChange(string): void;
}

const classes = bem(BEM_CLASS);

export const Preferences = React.memo(({ className, dateFormat, onDateFormatChange }: Props) => {
  return (
    <SPreferences className={className}>
      <H4 className={classes('title')} data-selector="preferences-title">
        Preferences
      </H4>
      <div className={classes('card')}>
        <DateFormatSelector value={dateFormat} onChange={onDateFormatChange} />
      </div>
    </SPreferences>
  );
});
