import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { theme } from 'app/theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { ROW_GAP, COLUMN_GAP, MEMBER_WIDTH, COLUMN_SIZE, MEMBER_HEIGHT } from './constants';

export const BEM_CLASS = 'team-members-renderer';

interface STeamMembersRendererProps {
  restMembersCount: number;
}

export const STeamMembersRenderer = styled.div<STeamMembersRendererProps>`
  display: flex;

  .${BEM_CLASS} {
    &__avatar {
      margin-right: 6px;
      width: 25px;
      height: 25px;
    }

    &__no-members {
      font-size: 14px;
      color: ${colorPalette.gray};
    }

    &__rest-members {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${transparentize(0.95, manhattanThemeColors.primaryFillNew)};
      color: ${transparentize(0.2, manhattanThemeColors.primaryFillNew)};
      border-radius: 20px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      font-size: 12px;

      ${({ restMembersCount }) =>
        restMembersCount >= 100 &&
        css`
          width: auto;
          padding: 0 8px;
        `}
    }
  }
`;

export const STeamMembersRendererMemberTooltip = styled.div`
  display: flex;
  align-items: baseline;
  font-size: 12px;

  .${BEM_CLASS} {
    &__role {
      color: ${colorPalette.gray};
    }
  }
`;

interface STeamMembersRendererTooltipProps {
  membersCount: number;
}

export const STeamMembersRendererTooltip = styled(({ className, children }) => children(className))<
  STeamMembersRendererTooltipProps
>`
  .${BEM_CLASS} {
    &__members {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: ${ROW_GAP}px ${COLUMN_GAP}px;
      width: ${({ membersCount }) => (MEMBER_WIDTH + COLUMN_GAP) * Math.ceil(membersCount / COLUMN_SIZE)}px;
      max-height: ${(MEMBER_HEIGHT + ROW_GAP) * COLUMN_SIZE}px;
      font-family: ${theme.fontFamily};
    }

    &__member {
      ${elipsizeText};
      width: ${MEMBER_WIDTH}px;
      height: ${MEMBER_HEIGHT}px;
      font-size: 12px;
      line-height: ${MEMBER_HEIGHT}px;
    }

    &__tooltip-content {
      max-width: none;
      max-height: none;
    }
  }
`;
