import { createSelector } from 'reselect';

export const authSelector = state => state?.auth;

export const auth0Selector = createSelector(authSelector, state => state?.auth0);

export const auth0ErrorSelector = createSelector(authSelector, state => state?.auth0Error);

export const auth0AccessTokenSelector = createSelector(auth0Selector, state => state?.accessToken);

export const auth0ExpiresAtSelector = createSelector(auth0Selector, state => state?.expiresAt);

export const auth0IsAuthorizedSelector = createSelector(
  auth0AccessTokenSelector,
  auth0ExpiresAtSelector,
  (accessToken, expiresAt) => {
    return !!accessToken && new Date().getTime() < expiresAt;
  }
);

//†selector
