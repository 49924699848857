import styled from 'styled-components';
import { getUnderline } from '../styles/underline';

export const SNoMatch = styled.div`
  display: flex;
  margin: auto;
  width: 100%;
  height: calc(100vh - 100px);

  .no-match {
    &__inner {
      margin: auto;
      transform: translateY(-43%);

      img {
        display: block;
        margin: 0 auto;
      }

      &-text {
        margin-top: 30px;
        font-weight: 500;
        text-align: center;

        a {
          font-weight: 600;

          ${getUnderline()}
        }
      }
    }

    &__inner-link {
      margin-top: 9px;
      display: inline-block;
    }
  }
`;
