import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { Artist, Playlist, ArtistType, ArtistSchema } from 'backend-api/models';
import { isPlaylists } from 'backend-api/guards';
import { ReusableModal } from 'common/components/reusable-modal';
import { activeProjectSelector, isShowAllTargetsModalShownSelector } from 'project/selectors';
import { showViewAllTargetsModal } from 'project/actions';
import { SShowAllTargetsModal } from './s-show-all-targets-modal';
import { paths } from 'app/routing/paths';
import { ArtistPlaylistCard } from 'project/components/form/components/body/components';

const classes = bem('show-all-targets-modal');

export const ShowAllTargetsModal = React.memo(() => {
  const dispatch = useDispatch();

  const isOpened = useSelector(isShowAllTargetsModalShownSelector);
  const project = useSelector(activeProjectSelector);
  const targetItems = project?.targets.items;
  const targetsSize = targetItems?.length || 0;

  const onModalClose = useCallback(() => dispatch(showViewAllTargetsModal(false)), [dispatch]);

  const primaryArtists = useMemo(
    () => (targetItems && !isPlaylists(targetItems) ? targetItems?.filter(artist => artist.type === 'Primary') : []),
    [targetItems]
  );
  const featuredArtists = useMemo(
    () => (targetItems && !isPlaylists(targetItems) ? targetItems?.filter(artist => artist.type === 'Featured') : []),
    [targetItems]
  );

  const renderCard = useCallback((target: Artist | Playlist) => {
    const path = ArtistSchema.is(target) && target.type === 'Primary' ? paths.artist(target.id) : undefined;

    return <ArtistPlaylistCard key={target.id} path={path} target={target} isEditable={false} shouldStretch />;
  }, []);

  const renderArtistTypeSection = useCallback(
    (type: ArtistType, artists: Artist[]) => (
      <div className={classes('artist-type-section')}>
        <div className={classes('artist-type-title')}>{type}</div>
        <div className={classes('targets-container')}>{artists.map(renderCard)}</div>
      </div>
    ),
    [renderCard]
  );

  const body = useMemo(() => {
    if (!targetItems) return null;

    if (isPlaylists(targetItems)) {
      return <div className={classes('targets-container')}>{targetItems.map(renderCard)}</div>;
    }

    return (
      <>
        {primaryArtists && renderArtistTypeSection('Primary', primaryArtists)}
        {featuredArtists && renderArtistTypeSection('Featured', featuredArtists)}
      </>
    );
  }, [targetItems, primaryArtists, featuredArtists, renderCard, renderArtistTypeSection]);

  const title = `${project?.targets.type || 'Artist'}s (${targetsSize})`;

  return (
    <ReusableModal
      width="1020px"
      title={title}
      isOpen={isOpened}
      onClose={onModalClose}
      dataSelector="project-targets-modal"
    >
      <SShowAllTargetsModal>{body}</SShowAllTargetsModal>
    </ReusableModal>
  );
});
