import { useCallback, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';
import { UUID } from 'io-ts-types/lib/UUID';
import { GridApi } from 'ag-grid-community';
import { UndoData, UndoHook } from '../types';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { ASYNC_TRANSACTION_WAIT, UNDO_ACTIONS_COUNT } from '../constants';
import { isMediaPlanEditing, isRedoKey, isUndoKey } from '../transducers';

export const useUndo = (api?: GridApi<SpreadsheetRowData>): UndoHook => {
  const undoStack = useRef<SpreadsheetRowData[][]>([]);
  const redoStack = useRef<SpreadsheetRowData[][]>([]);

  const pendingUndoData = useRef<SpreadsheetRowData[]>([]);

  const isUndoStackEmpty = undoStack.current.length === 0;
  const isRedoStackEmpty = redoStack.current.length === 0;

  const pushToStack = useCallback((stack: SpreadsheetRowData[][], data: SpreadsheetRowData[]) => {
    if (stack.length >= UNDO_ACTIONS_COUNT) {
      stack.shift();
    }

    stack.push(data);
  }, []);

  const debouncedPushToUndoStack = useMemo(
    () =>
      debounce(() => {
        if (pendingUndoData.current.length > 0) {
          pushToStack(undoStack.current, pendingUndoData.current);
          pendingUndoData.current = [];
        }
      }, ASYNC_TRANSACTION_WAIT),
    [pushToStack]
  );

  const clearUndoStack = useCallback(() => {
    undoStack.current = [];
  }, []);

  const clearRedoStack = useCallback(() => {
    redoStack.current = [];
  }, []);

  const updateUndoStack = useCallback(
    (undo: UndoData) => {
      const targetData = pendingUndoData.current.find(({ uuid }) => uuid.value === undo.data.uuid.value);

      if (targetData === undefined) {
        pendingUndoData.current.push(undo.data);
      } else {
        targetData[undo.field] = undo.data[undo.field];
      }

      clearRedoStack();
      debouncedPushToUndoStack();
    },
    [clearRedoStack, debouncedPushToUndoStack]
  );

  const aggregateNodesData = useCallback(
    (api: GridApi<SpreadsheetRowData>, transaction: SpreadsheetRowData[]) =>
      transaction.reduce<SpreadsheetRowData[]>((acc, { uuid }) => {
        const node = api.getRowNode(uuid.value);

        if (node && node.data) {
          acc.push(node.data);
        }

        return acc;
      }, []),
    []
  );

  const sanitizeUndoRedoStacks = useCallback((uuid: UUID) => {
    const filterStack = (stack: SpreadsheetRowData[][]) =>
      stack.reduce<SpreadsheetRowData[][]>((acc, item) => {
        const filteredItem = item.filter(data => data.uuid.value !== uuid);

        if (filteredItem.length === 0) {
          return acc;
        } else {
          return [...acc, item];
        }
      }, []);

    undoStack.current = filterStack(undoStack.current);
    redoStack.current = filterStack(redoStack.current);
  }, []);

  const applyUndo = useCallback(
    (api: GridApi<SpreadsheetRowData>) => {
      const updateTransaction = undoStack.current.pop()?.map(item => {
        const currentRowData = api.getRowNode(item.uuid.value)?.data;

        if (!currentRowData) return item;

        return {
          ...item,
          orderInPhase: currentRowData.orderInPhase,
          orderOnSort: currentRowData.orderOnSort,
          phaseOrder: currentRowData.phaseOrder,
        };
      });

      if (updateTransaction) {
        pushToStack(redoStack.current, aggregateNodesData(api, updateTransaction));
        api.applyTransactionAsync({ update: updateTransaction });
      }
    },
    [aggregateNodesData, pushToStack]
  );

  const applyRedo = useCallback(
    (api: GridApi<SpreadsheetRowData>) => {
      const updateTransaction = redoStack.current.pop();

      if (updateTransaction) {
        pushToStack(undoStack.current, aggregateNodesData(api, updateTransaction));
        api.applyTransactionAsync({ update: updateTransaction });
      }
    },
    [aggregateNodesData, pushToStack]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (api) {
        const isEditing = isMediaPlanEditing(api);

        if (isEditing) return;

        const redoShortCut = isRedoKey(event);
        const undoShortCut = isUndoKey(event);

        if (redoShortCut) {
          event.preventDefault();
          applyRedo(api);
        } else if (undoShortCut) {
          event.preventDefault();
          applyUndo(api);
        }
      }
    },
    [api, applyRedo, applyUndo]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return {
    updateUndoStack,
    sanitizeUndoRedoStacks,
    applyUndo,
    applyRedo,
    isUndoStackEmpty,
    isRedoStackEmpty,
    clearUndoStack,
    clearRedoStack,
  };
};
