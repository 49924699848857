import styled from 'styled-components';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';
import { MODAL_Z_INDEX } from 'common-v2/constants';
import { MODAL_BORDER_RADIUS } from './constants';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'modal';

export const SModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${MODAL_Z_INDEX};
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 50px;
  overflow-x: hidden;
  overflow-y: auto;

  .${BEM_CLASS} {
    &__overlay {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      opacity: 0.9;
      background-image: linear-gradient(
        360deg,
        ${transparentize(0.3, manhattanThemeColors.primaryDark)} 0%,
        rgba(8, 59, 69, 0.7) 100%
      );
    }

    &__content {
      position: relative;
      z-index: 2;
      backdrop-filter: blur(60px);
      margin: auto;
      border-radius: ${MODAL_BORDER_RADIUS}px;
    }
  }
`;
