import { apiClient, ResponseArray } from '../index';
import { PurchaseOrderDetailsSchema, PurchaseOrdersListResponseScheme, PurchaseOrdersProviderSchema } from '../models';
import { PurchaseOrderFilterParams } from '../types';

export const getPurchaseOrders = (projectId: number, params: PurchaseOrderFilterParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/purchase-orders`,
    responseSchema: PurchaseOrdersListResponseScheme,
    queryParams: params,
  });

export const getPurchaseOrder = (projectId: number, id: number) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/purchase-orders/${id}`,
    responseSchema: PurchaseOrderDetailsSchema,
  });

export const getPurchaseOrderItems = (projectId: number, id: number, params: PurchaseOrderFilterParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/purchase-orders/${id}/blanket-items`,
    responseSchema: PurchaseOrdersListResponseScheme,
    queryParams: params,
  });

export const getPurchaseOrdersProviders = (projectId: number) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/purchase-orders/providers`,
    responseSchema: ResponseArray(PurchaseOrdersProviderSchema),
  });

export const getPurchaseOrderBlanketItems = (projectId: number, id: number, params?: PurchaseOrderFilterParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/purchase-orders/${id}/blanket-items`,
    responseSchema: PurchaseOrdersListResponseScheme,
    queryParams: params,
  });
