import { createSelector } from 'reselect';
import { ProjectsV2State } from 'projects-v2/reducer';

export const projectsV2StateSelector = state => state.projectsV2 as ProjectsV2State;

export const recentlyViewedProjectsSelector = createSelector(
  projectsV2StateSelector,
  state => state.recentlyViewedProjects
);

export const newProjectsSelector = createSelector(projectsV2StateSelector, state => state.newProjects);
