import { ControlProps } from 'react-select/dist/declarations/src/components/Control';
import { BaseOption } from 'common/components/select';
import React from 'react';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { Icon } from 'common/components/icon';
import { SSettingsControl } from './s-settings-control';
import { Tooltip } from 'common/components/tooltip';

export const SettingsControl = React.memo(({ innerProps, ...props }: ControlProps<BaseOption, false>) => {
  const { handleMenu } = getBaseCustomSelectProps(props.selectProps);
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    handleMenu(event, props.menuIsOpen);
  };
  const newInnerProps: JSX.IntrinsicElements['div'] = {
    onClick,
    onMouseDown: onClick,
    ...innerProps,
  };
  return (
    <Tooltip content="Actions">
      <div>
        <SSettingsControl {...props} innerProps={newInnerProps}>
          <Icon name="ellipsis-menu" color={props.menuIsOpen ? 'white' : 'black'} />
        </SSettingsControl>
      </div>
    </Tooltip>
  );
});
