import React, { useCallback, useMemo } from 'react';
import { transparentize } from 'polished';
import { ICellRendererParams } from 'ag-grid-community';
import { manhattanThemeColors } from 'app/theme/colors';
import { bem } from 'utils/bem';
import { Tooltip } from 'gdb-web-shared-components';
import { Avatar } from 'common-v2/components';
import { ROLES } from 'common-v2/constants';
import { MAX_TEAM_MEMBERS_TO_SHOW } from './constants';
import {
  BEM_CLASS,
  STeamMembersRenderer,
  STeamMembersRendererTooltip,
  STeamMembersRendererMemberTooltip,
} from './s-team-members-renderer';
import { LabelPermissionsArtistWithMembers, LabelPermissionsTeamMembers } from 'backend-api/models';
import { takeRight } from 'lodash';

const classes = bem(BEM_CLASS);

export const TeamMembersRenderer = React.memo(
  ({ value: teamMembers }: ICellRendererParams<LabelPermissionsArtistWithMembers[], LabelPermissionsTeamMembers[]>) => {
    const restTeamMembersCount = teamMembers.length - MAX_TEAM_MEMBERS_TO_SHOW;

    const toolTipText = useCallback(
      (name: string, isAdmin: boolean) => (
        <STeamMembersRendererMemberTooltip>
          {name}
          {isAdmin ? <div className={classes('role')}>&nbsp;({ROLES.ADMIN.name})</div> : ''}
        </STeamMembersRendererMemberTooltip>
      ),
      []
    );

    const restMembers = useMemo(() => {
      const members = takeRight(teamMembers, restTeamMembersCount);

      const memberListRender = (
        <div className={classes('members')}>
          {members.map(member => (
            <div className={classes('member')} key={member.id}>
              {member.name}
            </div>
          ))}
        </div>
      );

      return (
        <STeamMembersRendererTooltip membersCount={restTeamMembersCount}>
          {className => (
            <Tooltip
              tooltip={memberListRender}
              popperClassName={className}
              contentClassName={classes('tooltip-content')}
            >
              <div className={classes('rest-members')} data-selector="rest-members">
                +{restTeamMembersCount}
              </div>
            </Tooltip>
          )}
        </STeamMembersRendererTooltip>
      );
    }, [restTeamMembersCount, teamMembers]);

    return (
      <STeamMembersRenderer restMembersCount={restTeamMembersCount} data-selector="team-members-avatars">
        {teamMembers.length === 0 ? (
          <div className={classes('no-members')}>No Assigned Team Members</div>
        ) : (
          <>
            {teamMembers.map((member, index) => {
              const isAdmin = member.role === ROLES.ADMIN.id;

              return (
                index < MAX_TEAM_MEMBERS_TO_SHOW && (
                  <Avatar
                    key={member.id}
                    size={30}
                    className={classes('avatar')}
                    dataSelector="team-member-avatar"
                    avatarColor={transparentize(isAdmin ? 0.6 : 0.85, manhattanThemeColors.primaryFillNew)}
                    name={member.name}
                    tooltipContent={{
                      visible: true,
                      colorsInversion: true,
                      title: toolTipText(member.name, isAdmin),
                    }}
                  />
                )
              );
            })}

            {restTeamMembersCount > 0 && restMembers}
          </>
        )}
      </STeamMembersRenderer>
    );
  }
);
