import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const BEM_CLASS = 'campaigns-result';

export const SCampaignsResult = styled.div`
  height: 100%;

  .${BEM_CLASS} {
    &__cell {
      display: flex;
      align-items: center;
      height: 100%;

      &--name,
      &--project,
      &--date {
        font-size: 14px;
        line-height: 20px;
        color: ${palette.darkBlue};
      }

      &--icon {
        display: flex;
        width: 40px;
        height: 40px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: ${palette.athensGrayTwo};
      }

      &--date {
        justify-content: flex-end;
      }
    }

    &__badge {
      margin-left: 10px;
    }

    &__icon {
      display: flex;
      margin: auto;
    }
  }
`;
