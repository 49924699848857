import React, { ReactNode } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { bem } from 'utils/bem';
import { ColoredCircle } from 'common/components/colored-circle';
import { ColoredMarketingMix, TooltipFunction } from '../../types';
import { getFormattedPercentage, getMarketingMix, getSubMarketingMix, getTotalBudget } from '../../transducers';
import { STooltip } from './s-tooltip';
import { getShortCurrencyFormatted } from 'utils/format';

const classes = bem('tooltip');

export const getMarketingMixTooltip: TooltipFunction = (
  marketingMix: ColoredMarketingMix[],
  point: Highcharts.Series | Highcharts.Point,
  projectCurrencyCode?: string
) => {
  const selectedMarketingMix = getMarketingMix(marketingMix, point.name);
  if (!selectedMarketingMix) return '';

  const subMarketingMix = getSubMarketingMix(selectedMarketingMix);

  const globalTotalBudget = getTotalBudget(marketingMix);
  const totalBudget = selectedMarketingMix.budget;

  const getRowElement = (budget: number, total: number): ReactNode => {
    const formattedBudget = getShortCurrencyFormatted(budget, projectCurrencyCode);
    const percentage = getFormattedPercentage(budget, total);

    return (
      <span className={classes('category-row-value')}>
        {formattedBudget} <span className={classes('category-row-value-percentage')}>({percentage})</span>
      </span>
    );
  };

  return renderToStaticMarkup(
    <STooltip>
      <div className={classes('container')}>
        <span className={classes('category-title')}>{point.name}</span>
        <table className={classes('category-table')}>
          {subMarketingMix.map(currentSubMarketingMix => {
            return (
              <tr className={classes('category-row')} key={currentSubMarketingMix.id}>
                <td className={classes('category-row-element')}>
                  {currentSubMarketingMix.color && <ColoredCircle color={currentSubMarketingMix.color} />}
                  <span className={classes('category-row-title')}>{currentSubMarketingMix.name}</span>
                </td>
                <td className={classes('category-row-element')}>
                  {getRowElement(currentSubMarketingMix.budget, selectedMarketingMix.budget)}
                </td>
              </tr>
            );
          })}
        </table>
        <div className={classes('category-separator')} />
        <div className={classes('category-total-container')}>
          <span className={classes('category-total-text')}>Total</span>
          {getRowElement(totalBudget, globalTotalBudget)}
        </div>
      </div>
    </STooltip>
  );
};
