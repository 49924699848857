import React from 'react';
import { bem } from 'utils/bem';
import { STextValueItem, BEM_CLASS } from './s-text-value-item';

interface TextValueItemProps {
  value: string;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const TextValueItem = React.memo(({ value, className }: TextValueItemProps) => {
  return (
    <STextValueItem className={className}>
      <span className={classes('text')}>{value}</span>
    </STextValueItem>
  );
});
