import { ParsedQs } from 'qs';
import { Sort } from 'utils/sort';
import { DEFAULT_SORT, PAGINATION_LIMIT } from './constants';
import { PurchaseOrdersFilters } from './types';
import { isPurchaseOrderSortField, PurchaseOrderFilterParams } from 'backend-api/types';
import { Optional, PurchaseOrderStatus } from 'backend-api/models';
import { PURCHASE_ORDERS_STATUSES } from '../../../constants';

export const getPurchaseOrdersFiltersParamsFromSearchQuery = (query: ParsedQs): PurchaseOrderFilterParams => {
  const search = typeof query.search === 'string' ? query.search : undefined;
  const provider = typeof query.provider === 'string' ? parseInt(query.provider, 10) : undefined;
  const status = typeof query.status === 'string' ? parseInt(query.status, 10) : undefined;
  const limit = typeof query.limit === 'string' ? parseInt(query.limit, 10) : undefined;
  const offset = typeof query.offset === 'string' ? parseInt(query.offset, 10) : undefined;
  const sort =
    typeof query.sort === 'string' && Sort.create(query.sort, isPurchaseOrderSortField)
      ? query.sort
      : DEFAULT_SORT.getString();

  return {
    search,
    provider,
    status,
    limit: limit || PAGINATION_LIMIT,
    offset: offset || 0,
    sort,
  };
};

export const getPurchaseOrdersFiltersFromParams = (params: PurchaseOrderFilterParams): PurchaseOrdersFilters => {
  const sort = params.sort && Sort.create(params.sort, isPurchaseOrderSortField);

  return {
    search: params.search,
    limit: params.limit,
    offset: params.offset,
    sort: sort || DEFAULT_SORT,
    status: params.status,
    provider: params.provider,
  };
};

export const getPurchaseOrdersFiltersParamsFromFilters = (
  filters: PurchaseOrdersFilters
): PurchaseOrderFilterParams => {
  return {
    search: filters.search,
    offset: filters.offset,
    limit: filters.limit,
    sort: filters.sort?.getString(),
    status: filters.status,
    provider: filters.provider,
  };
};

export const getTooltipByStatusName = (status: PurchaseOrderStatus): Optional<string> =>
  PURCHASE_ORDERS_STATUSES.find(item => item.value === status)?.info;
