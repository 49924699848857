import { LinkfireSearch } from './index';

const getDefaultsLinkfireSearch = (): LinkfireSearch => ({
  id: 0,
  linkId: 'link id',
  linkUrl: 'link url',
  deletable: false,
});

export const getLinkfireSearch = (p?: Partial<LinkfireSearch>): LinkfireSearch => ({
  ...getDefaultsLinkfireSearch(),
  ...p,
});
