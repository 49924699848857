import styled from 'styled-components';

export const BEM_CLASS = 'image';

interface SImageProps {
  isVisible: boolean;
}

export const SImage = styled.div<SImageProps>`
  .${BEM_CLASS} {
    &__image {
      display: ${({ isVisible }) => (isVisible ? 'unset' : 'none')};
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;
