import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'edit-members-renderer';

export const SEditMembersRenderer = styled.button`
  .${BEM_CLASS} {
    &__icon {
      color: ${manhattanThemeColors.primaryFillNew};
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;

      &:hover {
        background-color: ${transparentize(0.95, manhattanThemeColors.primaryFillNew)};
      }

      &:active,
      &:focus {
        border: 3px solid ${transparentize(0.7, manhattanThemeColors.primaryFillQuaternary)};
        background-color: transparent;
      }
    }
  }
`;
