import React, { useMemo, useCallback } from 'react';
import { Artist, Playlist, PlaylistSchema } from 'backend-api/models';
import { bem } from 'utils/bem';
import { Badge } from 'common/components/badge';
import { Tooltip } from 'common/components/tooltip';
import { TargetsImages } from 'common/components/targets-images';
import { SSpacer } from 'common/s-components/layout/s-spacer';
import { capitalize } from 'lodash';
import { SArtistPlaylistCard } from './s-artist-playlist-card';
import { CardMenu } from '../card-menu';
import ApolloSvg from 'assets/apollo.svg';
import { navigateTo } from 'utils/navigation';
import { buildApolloPlaylistUrl } from 'project/transducers';
import { Icon } from 'common/components/icon';

interface Props {
  target: Artist | Playlist;
  onRemove?: (target: Artist | Playlist) => void;
  onSelect?: (targetToSelect: Artist | Playlist) => void;
  isSelected?: boolean;
  className?: string;
  isEditable?: boolean;
  shouldStretch?: boolean;
  path?: string;
  isLocked?: boolean;
}

const classes = bem('artist-playlist-card');

export const ArtistPlaylistCard = React.memo(
  ({
    target,
    onRemove,
    onSelect,
    isSelected,
    className,
    isEditable = true,
    shouldStretch,
    path,
    isLocked = false,
  }: Props) => {
    const owner = useMemo(() => (PlaylistSchema.is(target) ? `By ${target.ownerName}` : undefined), [target]);
    const provider = useMemo(() => (PlaylistSchema.is(target) ? capitalize(target.provider) : undefined), [target]);

    const onClick = useCallback(() => {
      if (!!path) {
        navigateTo(path);
      }

      if (!isLocked && onSelect) {
        onSelect(target);
      }
    }, [isLocked, path, onSelect, target]);

    const onClickApollo = useCallback(() => {
      if (PlaylistSchema.is(target)) {
        const url = buildApolloPlaylistUrl(target);
        window.open(url, '_blank');
      }
    }, [target]);

    const onClickWithoutPropagation = useCallback(
      event => {
        if (!path) {
          event.stopPropagation();
        }
      },
      [path]
    );

    const rightElement = useMemo(() => {
      if (isLocked) {
        return (
          <Tooltip content="The Primary Artist listed in PRS cannot be removed from this project.">
            <span className={classes('lock-icon')}>
              <Icon name="blocked" size="general" />
            </span>
          </Tooltip>
        );
      }

      if (isEditable && !!onRemove) {
        return <CardMenu target={target} onRemove={onRemove} className={classes('actions-icon')} />;
      }

      if (PlaylistSchema.is(target)) {
        return (
          <img
            alt=""
            src={ApolloSvg}
            data-selector="target-item-apollo-icon"
            className={classes('apollo-icon')}
            onClick={onClickApollo}
          />
        );
      }

      return null;
    }, [isEditable, isLocked, onClickApollo, onRemove, target]);

    return (
      <SArtistPlaylistCard
        data-selector="artist-playlist-card-item"
        shouldCardStretch={shouldStretch}
        className={className}
      >
        <div onClick={onClick} className={classes('container', { selected: isSelected || false, link: !!path })}>
          <TargetsImages targets={target} className={classes('image')} />
          <div className={classes('text-container')}>
            <div className={classes('top-text-container')}>
              <Tooltip placement="top" content={target.name}>
                <span data-selector="target-item-name" onClick={onClickWithoutPropagation} className={classes('title')}>
                  {target.name}
                </span>
              </Tooltip>
              {!PlaylistSchema.is(target) && target.isSony && (
                <Badge
                  text="Sony"
                  onClick={onClickWithoutPropagation}
                  dataSelector="target-item-sony-badge"
                  className={classes('badge-container')}
                />
              )}
            </div>
            {PlaylistSchema.is(target) && (
              <div className={classes('bottom-text-container')}>
                {owner && (
                  <Tooltip content={owner}>
                    <span
                      data-selector="target-item-owner"
                      onClick={onClickWithoutPropagation}
                      className={classes('subtitle', 'owner')}
                    >
                      {owner}
                    </span>
                  </Tooltip>
                )}
                <div className={classes('circle')} />
                {provider && (
                  <Tooltip content={provider}>
                    <span
                      data-selector="target-item-provider"
                      onClick={onClickWithoutPropagation}
                      className={classes('subtitle')}
                    >
                      {provider}
                    </span>
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          <SSpacer />
          {rightElement}
        </div>
      </SArtistPlaylistCard>
    );
  }
);
