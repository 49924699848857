import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import { css, FlattenSimpleInterpolation } from 'styled-components';
import { CampaignStatuses } from 'backend-api/models';

export const getStatusBadgeStyling = (status: CampaignStatuses): FlattenSimpleInterpolation => {
  switch (status) {
    case CampaignStatuses.BOOKED:
      return css`
        background-color: ${transparentize(0.7, manhattanThemeColors.primaryFill)};
      `;
    case CampaignStatuses.LIVE:
      return css`
        background-color: ${manhattanThemeColors.background};
        border-color: ${transparentize(0.6, manhattanThemeColors.primaryDark)};
      `;
    case CampaignStatuses.COMPLETED:
      return css`
        background-color: ${transparentize(0.3, manhattanThemeColors.primaryDark)};
      `;
    case CampaignStatuses.DRAFT:
      return css`
        background-color: ${transparentize(0.97, manhattanThemeColors.primaryFillNew)};
      `;
    case CampaignStatuses.PENDING:
      return css`
        background-color: ${manhattanThemeColors.semanticWarningFill3};
      `;
    case CampaignStatuses.APPROVED:
      return css`
        background-color: ${manhattanThemeColors.semanticSuccessFill3};
      `;

    case CampaignStatuses.DISAPPROVED:
      return css`
        background-color: ${manhattanThemeColors.semanticErrorFill3};
      `;
    case CampaignStatuses.PAUSED:
      return css`
        background-color: ${transparentize(0.8, manhattanThemeColors.primaryDark)};
      `;
    case CampaignStatuses.REVIEW:
      return css`
        background-color: ${colorPalette.eastBay};
      `;
    default:
      return css`
        background-color: ${colorPalette.white};
      `;
  }
};

export const getStatusTextStyling = (status: CampaignStatuses): FlattenSimpleInterpolation => {
  switch (status) {
    case CampaignStatuses.COMPLETED:
      return css`
        color: ${manhattanThemeColors.background};
      `;
    default:
      return css`
        color: ${manhattanThemeColors.primaryDark};
      `;
  }
};
