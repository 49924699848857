import React from 'react';
import { STableLoader } from '../../s-components/table/s-table-loader';
import { motion } from 'framer-motion';
import { appearingAnimation } from '../../constants';
import { AllProjectsLoader } from '../loaders/all-projects-loader';
import { bem } from 'utils/bem';

interface Props {
  colSpan: number;
  maxWidth?: number;
}

const classes = bem('table-loader');

export const TableLoader = React.memo(({ colSpan, maxWidth }: Props) => {
  return (
    <STableLoader {...appearingAnimation} maxWidth={maxWidth}>
      <motion.tr>
        <td colSpan={colSpan}>
          <div className={classes('loader')}>
            <AllProjectsLoader />
          </div>
        </td>
      </motion.tr>
    </STableLoader>
  );
});
