import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const BEM_CLASS = 'campaign-search-item';

export const SCampaignSearchItem = styled.div`
  display: flex;
  width: 100%;
  color: ${palette.black};

  .${BEM_CLASS} {
    &__content {
      width: 100%;
    }

    &__info,
    &__schedule {
      display: flex;
      align-items: center;
      line-height: 20px;
      color: ${palette.darkBlue};
    }

    &__info {
      margin-bottom: 1px;
      font-size: 14px;
    }

    &__schedule {
      font-size: 12px;
    }

    &__badge {
      margin-left: 10px;
    }
  }
`;
