import { apiClient, EmptyPayload, EmptySchema, ResponseArray } from '../index';
import {
  ArtistMomentCategorySchema,
  ArtistMomentRequest,
  ArtistMomentRequestSchema,
  ArtistMomentSchema,
  ArtistMomentTypeGroupSchema,
  Id,
} from '../models';

export const getMoment = (projectId: Id, momentId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/artists-moments/${momentId}`,
    responseSchema: ArtistMomentSchema,
  });

export const createMoment = (projectId: Id, data: ArtistMomentRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/artists-moments`,
    requestSchema: ArtistMomentRequestSchema,
    responseSchema: ArtistMomentSchema,
    payload: data,
  });

export const editMoment = (projectId: Id, momentId: Id, payload: ArtistMomentRequest) =>
  apiClient.executeRequest({
    method: 'PUT',
    endpoint: `/projects/${projectId}/artists-moments/${momentId}`,
    requestSchema: ArtistMomentRequestSchema,
    responseSchema: ArtistMomentSchema,
    payload,
  });

export const deleteMoment = (projectId: Id, momentId: Id) =>
  apiClient.executeRequest({
    method: 'DELETE',
    endpoint: `/projects/${projectId}/artists-moments/${momentId}`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
  });

export const getMomentTypes = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/artists-moments/types',
    responseSchema: ResponseArray(ArtistMomentTypeGroupSchema),
  });

export const getMomentCategories = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/artists-moments/categories',
    responseSchema: ResponseArray(ArtistMomentCategorySchema),
  });

export const getMoments = (projectId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/artists-moments`,
    responseSchema: ResponseArray(ArtistMomentSchema),
  });
