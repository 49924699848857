import { Optional } from 'backend-api/models';
import { MediaPlanColumnId } from 'media-plan-v2/containers/spreadsheet/types';

export const getTextFieldPlaceholder = (columnId?: string): Optional<string> => {
  switch (columnId) {
    case MediaPlanColumnId.AD_COPY:
      return 'Write or paste ad copy here...';
    case MediaPlanColumnId.DETAILS:
      return 'Write or paste details here...';
    case MediaPlanColumnId.NOTES:
      return 'Write or paste note here...';
    case MediaPlanColumnId.CREATIVE_DESCRIPTION:
      return 'Write or paste creative description here...';
    case MediaPlanColumnId.HEADLINE:
      return 'Write or paste headline here...';
    case MediaPlanColumnId.CALL_TO_ACTION:
      return 'Write or paste call to action here...';
    case MediaPlanColumnId.NAME:
      return 'Write or paste campaign description here...';

    default:
      return '';
  }
};
