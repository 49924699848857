import React from 'react';
import { Skeleton } from 'common-v2/components';

export const Loader = React.memo(() => (
  <Skeleton width={310} height={195}>
    <rect x="0" y="0" rx="2px" ry="2px" width="100" height="15" />

    <rect x="0" y="35" rx="2px" ry="2px" width="40" height="40" />
    <rect x="60" y="45" rx="2px" ry="2px" width="250" height="20" />

    <rect x="0" y="95" rx="2px" ry="2px" width="40" height="40" />
    <rect x="60" y="105" rx="2px" ry="2px" width="190" height="20" />

    <rect x="0" y="155" rx="2px" ry="2px" width="40" height="40" />
    <rect x="60" y="165" rx="2px" ry="2px" width="250" height="20" />
  </Skeleton>
));
