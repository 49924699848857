import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import { ResponseModel, Optional, FormattedDateFromISOString, IdNameField, IdType } from '../common';
import { PERFORMANCE_METRICS_FORMATS } from './constants';

const PerformanceMetricValueSchema = Optional(
  t.union([t.number, FormattedDateFromISOString], 'PerformanceMetricValue')
);

export type PerformanceMetricValue = t.TypeOf<typeof PerformanceMetricValueSchema>;

export const PerformanceDataItemFieldsSchema = t.record(
  t.string,
  PerformanceMetricValueSchema,
  'PerformanceDataItemFields'
);

export type PerformanceDataItemFields = t.TypeOf<typeof PerformanceDataItemFieldsSchema>;

export const PerformanceCampaignMetadataSchema = ResponseModel(
  {
    projectId: Optional(IdType),
    campaignUuid: Optional(UUID),
    isPending: t.boolean,
  },
  'PerformanceCampaignMetadata'
);

export type PerformanceCampaignMetadata = t.TypeOf<typeof PerformanceCampaignMetadataSchema>;

export const PerformanceProjectMetadataSchema = ResponseModel(
  {
    hasPendingCampaigns: t.boolean,
    canApproveCampaigns: t.boolean,
  },
  'PerformanceProjectMetadata'
);

export type PerformanceProjectMetadata = t.TypeOf<typeof PerformanceProjectMetadataSchema>;

export const PerformanceDataItemSchema = ResponseModel(
  {
    entityId: t.string,
    name: t.string,
    fields: PerformanceDataItemFieldsSchema,
    metadata: Optional(t.union([PerformanceCampaignMetadataSchema, PerformanceProjectMetadataSchema])),
  },
  'PerformanceDataItem'
);

export type PerformanceDataItem = t.TypeOf<typeof PerformanceDataItemSchema>;

export const PerformanceDataSchema = ResponseModel(
  {
    items: t.array(PerformanceDataItemSchema),
    totalFields: PerformanceDataItemFieldsSchema,
  },
  'PerformanceDataWithTotal'
);

export type PerformanceData = t.TypeOf<typeof PerformanceDataSchema>;

export const PerformanceBreakdownDataSchema = ResponseModel(
  {
    items: t.array(PerformanceDataItemSchema),
  },
  'PerformanceBreakdownData'
);

export type PerformanceBreakdownData = t.TypeOf<typeof PerformanceBreakdownDataSchema>;

export const PerformanceMetricFormatNameSchema = t.union(
  [
    t.literal(PERFORMANCE_METRICS_FORMATS.ageGenders),
    t.literal(PERFORMANCE_METRICS_FORMATS.ageRanges),
    t.literal(PERFORMANCE_METRICS_FORMATS.currency),
    t.literal(PERFORMANCE_METRICS_FORMATS.date),
    t.literal(PERFORMANCE_METRICS_FORMATS.genders),
    t.literal(PERFORMANCE_METRICS_FORMATS.integer),
    t.literal(PERFORMANCE_METRICS_FORMATS.percentage),
    t.literal(PERFORMANCE_METRICS_FORMATS.rate),
  ],
  'PerformanceMetricFormatName'
);

export type PerformanceMetricFormatName = t.TypeOf<typeof PerformanceMetricFormatNameSchema>;

export const PerformanceMetricFormatSchema = ResponseModel(
  {
    id: IdType,
    name: PerformanceMetricFormatNameSchema,
  },
  'PerformanceMetricFormat'
);

export type PerformanceMetricFormat = t.TypeOf<typeof PerformanceMetricFormatSchema>;

export const PerformanceMetricTypeSchema = ResponseModel(
  {
    id: IdType,
    index: t.number,
    name: t.string,
  },
  'PerformanceMetricType'
);

export type PerformanceMetricType = t.TypeOf<typeof PerformanceMetricTypeSchema>;

export const PerformanceMetricSchema = ResponseModel(
  {
    ...IdNameField,
    index: t.number,
    format: PerformanceMetricFormatSchema,
    type: PerformanceMetricTypeSchema,
    title: t.string,
    subtitle: Optional(t.string),
    totalName: t.string,
    description: Optional(t.string),
  },
  'PerformanceMetric'
);

export type PerformanceMetric = t.TypeOf<typeof PerformanceMetricSchema>;

export const PerformanceObjectiveSchema = ResponseModel(
  {
    ...IdNameField,
    fields: t.array(PerformanceMetricSchema),
  },
  'PerformanceObjective'
);

export type PerformanceObjective = t.TypeOf<typeof PerformanceObjectiveSchema>;

export const PerformanceObjectivesAndMetricsSchema = ResponseModel(
  {
    objectives: t.array(PerformanceObjectiveSchema),
    allFields: t.array(PerformanceMetricSchema),
  },
  'PerformanceObjectivesAndMetrics'
);

export type PerformanceObjectivesAndMetrics = t.TypeOf<typeof PerformanceObjectivesAndMetricsSchema>;

export const PerformancePlatformsSchema = ResponseModel(
  {
    platforms: t.array(t.string),
  },
  'PerformancePlatforms'
);

export type PerformancePlatforms = t.TypeOf<typeof PerformancePlatformsSchema>;

export const BreakdownOptionSchema = ResponseModel(
  {
    ...IdNameField,
    group: t.string,
  },
  'BreakdownOption'
);

export type BreakdownOption = t.TypeOf<typeof BreakdownOptionSchema>;
