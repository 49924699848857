import React from 'react';
import { paths } from 'app/routing/paths';
import { bem } from 'utils/bem';
import { LinkLongTableTitle, LongTableTitle, TableContent } from 'common/components/table';
import { Project, ProjectDetails } from 'backend-api/models/project';
import {
  getJoinedNames,
  getProjectSchedule,
  getProjectBudget,
  getProjectAllocation,
  getPathToProjectPage,
} from 'common/transducers';
import { TargetItems, TargetType } from 'backend-api/models';
import { isArtists, isPlaylists } from 'backend-api/guards';
import { TargetsImages } from 'common/components/targets-images';
import { ProjectSortField } from 'backend-api/types';
import { ConfidentialProjectIcon } from 'common/components/confidential-project-icon';
import { getProjectName } from 'common-v2/transducers';

const classes = bem('all-projects');

const getProjectTableTargetsNames = (targets: TargetItems): JSX.Element => {
  if (isArtists(targets) && targets.length === 1) {
    return (
      <LinkLongTableTitle
        className={classes('artist-name')}
        linkProps={{ pathname: paths.artist(targets[0].id) }}
        text={targets[0].name}
        dataSelector="project-artists-name-table-column"
      />
    );
  }

  const names = isPlaylists(targets) ? getJoinedNames(targets) : getJoinedNames(targets);

  return <LongTableTitle className={classes('artist-name')} text={names} />;
};

export const getProjectsTableConfig = (
  targetType: TargetType,
  dateFormat: string
): TableContent<ProjectDetails, ProjectSortField>[] => [
  {
    title: targetType === 'Artist' ? 'Artists' : 'Playlists',
    dataIndex: 'targets',
    dataSelector: 'projects-artist-column-header',
    useSort: true,
    sortField: targetType === 'Artist' ? 'artistName' : 'playlistName',
    additionalClassName: classes('artists-cell'),
    render: (record: Project) => {
      const targets = isPlaylists(record.targets.items)
        ? record.targets.items
        : record.targets.items.filter(item => item.type === 'Primary');

      return (
        <div className={classes('artist-container')}>
          <TargetsImages targets={targets} className={classes('artist-images')} />
          {getProjectTableTargetsNames(targets)}{' '}
        </div>
      );
    },
  },
  {
    title: 'Project Name',
    dataSelector: 'projects-name-column-header',
    dataIndex: 'name',
    useSort: true,
    sortField: 'project',
    render: (record: ProjectDetails) => (
      <ConfidentialProjectIcon isConfidential={record.isConfidential} color="black">
        <LinkLongTableTitle
          linkProps={{ pathname: getPathToProjectPage(record) }}
          text={getProjectName(record)}
          dataSelector="project-item-name-table-column"
        />
      </ConfidentialProjectIcon>
    ),
  },
  {
    title: 'Budget',
    dataIndex: 'budget',
    dataSelector: 'projects-budget-column-header',
    useSort: true,
    sortField: 'budget',
    render: (record: Project) => <span>{getProjectBudget(record)}</span>,
    additionalClassName: classes('budget-cell'),
  },
  {
    title: 'Allocation',
    dataIndex: 'allocation',
    dataSelector: 'projects-allocation-column-header',
    useSort: true,
    sortField: 'allocation',
    render: (record: Project) => <span>{getProjectAllocation(record)}</span>,
    additionalClassName: classes('allocation-cell'),
  },
  {
    title: 'Schedule',
    dataIndex: 'earliestStartDate',
    dataSelector: 'projects-timeline-column-header',
    useSort: true,
    sortField: 'earliestStartDate',
    additionalClassName: classes('schedule-cell'),
    render: (record: Project) => <span>{getProjectSchedule(record, dateFormat)}</span>,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    dataSelector: 'projects-role-column-header',
    useSort: true,
    sortField: 'userRoles',
    additionalClassName: classes('role-cell'),
    render: (record: Project) => {
      return (
        <div className={classes('roles-container')}>
          <span>{getJoinedNames(record.userRoles)}</span>
        </div>
      );
    },
  },
];
