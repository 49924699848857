import { useEffect } from 'react';
import { paths } from 'app/routing/paths';
import { usePrevious, useQuery } from 'hooks';
import { LoadingState } from 'common/types';
import { replaceTo } from 'utils/navigation';
import { setMediaPlanNotFoundModalOpened } from 'media-plan-v2/actions';
import { useDispatch, useSelector } from 'react-redux';
import { mediaPlanErrorSelector, mediaPlansSelector } from 'media-plan-v2/selectors';
import { Id, Optional } from 'backend-api/models';

export const useRedirectToFirstMediaPlan = (mediaPlanId: Optional<Id>, projectId: Id) => {
  const dispatch = useDispatch();

  const previousProjectId = usePrevious(projectId);
  const { query } = useQuery();
  const mediaPlanError = useSelector(mediaPlanErrorSelector);
  const mediaPlans = useSelector(mediaPlansSelector);

  useEffect(() => {
    if ((!!mediaPlanId && !isNaN(mediaPlanId)) || mediaPlans.data.length === 0 || projectId !== previousProjectId)
      return;

    replaceTo(paths.mediaPlan(projectId, mediaPlans.data[0].id), { query });
  }, [mediaPlanId, mediaPlans.data, mediaPlans.loading, previousProjectId, projectId, query]);

  useEffect(() => {
    if (mediaPlans.loading === LoadingState.Finished && mediaPlanError?.statusCode === 404) {
      if (!mediaPlans.data.length) return;

      replaceTo(paths.mediaPlan(projectId, mediaPlans.data[0].id), { query });
      dispatch(setMediaPlanNotFoundModalOpened(true));
    }
  }, [dispatch, mediaPlanError?.statusCode, mediaPlanId, mediaPlans.data, mediaPlans.loading, projectId, query]);
};
