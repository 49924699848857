import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { SCommonTable } from './s-common-table';
import { GridOptions } from 'ag-grid-community';

interface CommonTableProps<T> extends GridOptions<T> {
  className?: string;
}

export const Root = <T,>(
  { className, ...agGridProps }: CommonTableProps<T>,
  forwardedRef: React.Ref<AgGridReact<T>>
) => (
  <SCommonTable className={className}>
    <div className="ag-theme-alpine" data-selector="common-table">
      <AgGridReact {...agGridProps} ref={forwardedRef} />
    </div>
  </SCommonTable>
);

export const CommonTable = React.memo(React.forwardRef(Root)) as typeof Root;
