import React from 'react';
import { bem } from 'utils/bem';
import { SLoaderBudgetWidget } from './s-loader-budget-widget';

const rectangleClasses = bem('loader-budget-widget');
const CLIP_PATH_ID = 'loader-budget-widget';

interface Props {
  className?: string;
}

export const BudgetWidgetLoader = React.memo(({ className }: Props) => {
  return (
    <SLoaderBudgetWidget className={className} clipPathId={CLIP_PATH_ID}>
      <div className={rectangleClasses('clip-container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <path
              d={`M0 0 60 0 60 10 0 10 0 0z
                  M0 20 110 20 110 35 0 35 0 20z`}
            />
          </clipPath>
        </defs>
      </svg>
    </SLoaderBudgetWidget>
  );
});
