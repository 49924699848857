export const colorPalette = {
  black: 'rgba(0,0,0,1)',
  white: 'rgba(255,255,255,1)',
  whitenedTooth: 'rgba(249,249,251,1)',
  vulcan: 'rgba(14,16,26,1)',
  eastBay: 'rgba(77,82,128,1)',
  wildBlueYonder: 'rgba(125,130,179,1)',
  coolGray: 'rgba(134,138,179,1)',
  logan: 'rgba(163,169,204,1)',
  pickledBluewood: 'rgba(56,61,102,1)',
  gray: 'rgba(127,127,127,1)',
  coolGrayHalfAlfa: 'rgba(134,138,179,0.5)',
  osloGray: 'rgba(135,136,141,1)',
  azureRadiance: 'rgba(1,156,254,1)',
  lilyWhite: 'rgba(229,245,254,1)',
  linkWater: 'rgba(211,227,240,1)',
  wildStrawberry: 'rgba(255,60,129,1)',
  ceriseRed: 'rgba(217,43,105,1)',
  lightningYellow: 'rgba(255,198,25,1)',
  buddhaGold: 'rgba(217,162,0,1)',
  kimberley: 'rgba(106,110,148,1)',
  lavenderGray: 'rgba(184,188,217,1)',
  athensGray: 'rgba(237,237,242,1)',
  bluishWhite: 'rgba(223,223,236,1)',
  solitude: 'rgba(237,237,242,1)',
  mischka: 'rgba(218,218,230,1)',
  whisper: 'rgba(248,248,251,1)',
  ghostWhite: 'rgba(242,242,247,1)',
  lavender: 'rgba(235,235,240,1)',
  alabaster: 'rgba(250,250,250,1)',
  paleGray: 'rgba(253,253,254,1)',
  snuff: 'rgba(220,222,236,1)',
  mountainMeadow: 'rgba(29,185,84,1)',
  jade: 'rgba(0,179,105,1)',
  chateauGreen: 'rgba(75,175,80)',
  cinnabar: 'rgba(230,57,57,1)',
  valencia: 'rgba(217,54,54,1)',
  pomegranate: 'rgba(245,67,54,1)',
  christine: 'rgba(242,128,12,1)',
  mediumPurple: 'rgba(166, 65, 217, 1)',
  blueHaze: 'rgba(191,194,217,1)',
  mariner: 'rgba(43,116,217,1)',
  cerulean: 'rgba(2,161,242,1)',
  amethyst: 'rgba(156,61,204,1)',
  periwinkleGray: 'rgba(207,210,230,1)',
  viking: 'rgba(98,217,217,1)',
  hematite: 'rgba(0,0,0,0.5)',
  fuchsiaPink: 'rgba(191,67,191,1)',
  doveGray: 'rgba(100,100,100,1)',
  cornflowerBlue: 'rgba(57,115,230,1)',
  tundora: 'rgba(64,64,64,1)',
  iron: 'rgba(228,229,240,1)',
  cottonCandy: 'rgba(255,177,205,1)',
  darkBlue: 'rgba(51,56,102,1)',
  alto: 'rgba(217,217,217,1)',
  alizarinCrimson: 'rgba(217,33,33,1)',
  zumthor: 'rgba(234,244,255)',
  // Manhattan colors
  woodsmoke: 'rgba(12,13,13,1)',
  pictonBlue: 'rgba(46,221,230,1)',
  deepSeaGreen: 'rgba(8,73,77,1)',
  fiordGrey: 'rgba(85,91,94,1)',
  aluminiumGrey: 'rgba(135,142,146,1)',
};

export const themeColors = {
  primaryColor: colorPalette.wildStrawberry,
  primaryDarkColor: colorPalette.ceriseRed,
  secondaryColor: colorPalette.lightningYellow,
  secondaryDarkColor: colorPalette.buddhaGold,
  success: colorPalette.jade,
  error: colorPalette.cinnabar,
  errorDark: colorPalette.valencia,
  warning: colorPalette.christine,
  grayBackground: colorPalette.ghostWhite,
  textHeaderColor: colorPalette.black,
  basicTextColor: colorPalette.vulcan,
  textLabelColor: colorPalette.eastBay,
  placeholderTextColor: colorPalette.wildBlueYonder,
  disabledTextColor: colorPalette.osloGray,
  linkTextColor: colorPalette.azureRadiance,
  shadowColor: colorPalette.coolGrayHalfAlfa,
};

export const manhattanThemeColors = {
  primaryColor: 'rgba(234,244,255)',
  primaryDark: 'rgba(12,13,13,1)',
  primaryFill: 'rgba(13,101,115,1)',
  secondaryColor: colorPalette.lightningYellow,
  secondaryDarkColor: colorPalette.buddhaGold,
  success: colorPalette.jade,
  error: colorPalette.cinnabar,
  errorDark: colorPalette.valencia,
  warning: colorPalette.christine,
  background: colorPalette.white,
  textHeaderColor: colorPalette.black,
  dividerColor: 'rgba(9,66,78,1)',
  textLabelColor: colorPalette.eastBay,
  placeholderTextColor: 'rgba(103,106,115,1)',
  hoveredBorderColor: 'rgba(8,59,69,1)',
  disabledTextColor: colorPalette.osloGray,
  linkTextColor: colorPalette.azureRadiance,
  shadowColor: 'rgba(46,221,230,1)',
  secondaryBackground: colorPalette.deepSeaGreen,
  grayedText: colorPalette.fiordGrey,
  secondGrayedText: colorPalette.aluminiumGrey,
  primaryFillNew: 'rgba(8, 59, 69,1)',
  primaryFillQuaternary: 'rgba(71,205,209,1)',
  darkRed: 'rgba(161,27,18,1)',
  semanticWarningFill3: 'rgba(251,224,182,1)',
  semanticSuccessFill3: 'rgba(198,236,198,1)',
  semanticErrorFill3: 'rgba(251,182,183,1)',
};
