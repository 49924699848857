import styled from 'styled-components';
import { MAIN_FOOTER_HEIGHT, MAIN_FOOTER_PADDING_TOP, TOP_HEADER_HEIGHT } from 'common/constants';
import { PROJECTS_HEADER_HEIGHT } from '../components/header';

export const SNoProjects = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: calc(
    100vh - ${TOP_HEADER_HEIGHT}px - ${PROJECTS_HEADER_HEIGHT}px - ${MAIN_FOOTER_HEIGHT}px -
      ${MAIN_FOOTER_PADDING_TOP}px
  );

  .no-projects {
    &__text {
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
      margin-bottom: 23px;
    }
  }
`;
