import React, { useCallback, useEffect, useImperativeHandle, useReducer, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { ExpandableLinks } from '../expandable-links';
import { Nullable, Optional } from 'backend-api/models';
import { useSelectEditorKeyboardInteractions } from 'media-plan/components/spreadsheet/hooks';
import { SpreadsheetRowData } from '../../types';

export const LinksEditor = React.memo(
  React.forwardRef(
    (
      {
        value: spreadsheetValue,
        api,
        charPress,
      }: ICellEditorParams<
        SpreadsheetRowData,
        SpreadsheetRowData['adCreativeLinks'] | SpreadsheetRowData['destinationLinks']
      >,
      ref
    ) => {
      const [value, setValue] = useState(spreadsheetValue.value || []);
      const [isVisible, setVisible] = useReducer(() => true, false);

      const handleChange = useCallback((value: string[]) => {
        setValue(value);
      }, []);

      const handleValueChange = useCallback(
        (value: Optional<Nullable<string[]>>) => {
          handleChange(value || []);
        },
        [handleChange]
      );

      const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions({
        api,
        value,
        setValue: handleValueChange,
        isMulti: true,
      });

      useImperativeHandle(ref, () => ({
        getValue() {
          return {
            ...spreadsheetValue,
            value,
          };
        },
      }));

      useEffect(() => {
        requestAnimationFrame(() => {
          setVisible();
        });
      }, []);

      return isVisible ? (
        <ExpandableLinks
          selectRef={selectRef}
          value={value}
          placeholder="Paste URL here..."
          onChangeValue={handleChange}
          onKeyDown={onKeyDown}
          initialInputValue={charPress || ''}
        />
      ) : null;
    }
  )
);
