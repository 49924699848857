import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'artist-avatar-and-name';

export const SArtistAvatarAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;

  .${BEM_CLASS} {
    &__image {
      flex-shrink: 0;
      height: 30px;
      width: 30px;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${transparentize(0.9, manhattanThemeColors.hoveredBorderColor)};
    }

    &__text {
      overflow: hidden;
    }
  }
`;
