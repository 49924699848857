import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ProtectedRouteComponent from '../components/protected-route';
import { ACTION } from '../reducer';
import { auth0IsAuthorizedSelector } from '../selectors';

const mapStateToProps = createStructuredSelector({
  // permissions: makePermissionsSelector(),
  // hasAccess: makeHasAccessSelector(),
  // error: makeErrorSelector(),
  isAuthorized: auth0IsAuthorizedSelector,
  //†prop
});
const mapDispatchToProps = {
  // getPermissions: ACTION.getPermissions,
  unauthorized: ACTION.unauthorized,
  checkSession: ACTION.checkSession,
  startPollingSession: ACTION.startPollingSession,
  stopPollingSession: ACTION.stopPollingSession,
  //†action
};

/*
 * @description
 * This is a special route component which incapsulates authorization and permission check per route
 * TODO add permission request and permission check
 * TODO add permission attribute for this route
 * TODO e.g <ProtectedRoute path="/calendar" component={Calendar} permissions=['edit', 'create'] />
 *
 * @example
 * <ProtectedRoute path="/calendar" component={Calendar} />
 * <Route path="/login" component={Login} />
 * */
export const ProtectedRoute = connect(mapStateToProps, mapDispatchToProps)(ProtectedRouteComponent);
