import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ActionRenderer, MultiValueView } from 'media-plan-v2/components/spreadsheet/components';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';

export const GendersRenderer = ({
  context,
  value: { color, value },
  column,
  node,
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['genders']>) => (
  <ActionRenderer
    color={color}
    accessMode={context.accessMode}
    placeholder="Select or type"
    isDisabled={!column?.isCellEditable(node)}
  >
    {value && value.length !== 0 ? <MultiValueView values={value.map(value => value.name)} /> : null}
  </ActionRenderer>
);
