import React from 'react';
import { bem } from 'utils/bem';
import { CampaignStatuses } from 'backend-api/models';
import { StatusBadge } from 'media-plan-v2/components/spreadsheet/components';
import { BEM_CLASS, SApprovalValue } from './s-approval-value';
import { TYPOGRAPHY_TYPE, Typography } from 'gdb-web-shared-components';

interface ApprovalValueProps {
  status: CampaignStatuses;
}

const classes = bem(BEM_CLASS);

export const ApprovalValue = React.memo(({ status }: ApprovalValueProps) => (
  <SApprovalValue>
    {status === CampaignStatuses.REVIEW ? (
      <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('value')}>
        {status}
      </Typography>
    ) : (
      <StatusBadge status={status} />
    )}
  </SApprovalValue>
));
