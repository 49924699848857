import { CellClassParams, GridApi, RowNode, ValueFormatterParams } from 'ag-grid-community';
import {
  Id,
  Nullable,
  CampaignStatuses,
  CampaignPlatform,
  Objective,
  Gender,
  Territory,
  AudienceAge,
  CampaignPlacement,
  PerformanceObjective,
  Goal,
  Taxonomy,
  CampaignStatus,
  Optional,
} from 'backend-api/models';
import { UUID } from 'io-ts-types';
import { BaseOption } from 'common/components/form/select';
import { MediaPlanMode } from 'media-plan-v2/types';

export enum MediaPlanColumnId {
  CHECKBOX = 'checkbox',
  ACTIONS = 'action',
  PHASE_ID = 'phaseId',
  STATUS = 'status',
  PLATFORMS = 'platforms',
  NAME = 'name',
  NAMING_CONVENTION = 'namingConventionManual',
  PLACEMENTS = 'placements',
  OBJECTIVE = 'objective',
  BUDGET = 'budget',
  ECPM = 'eCpm',
  EST_IMPRESSIONS = 'estImpressions',
  AD_COPY = 'adCopy',
  AD_CREATIVE = 'adCreativeLinks',
  KPI = 'kpi',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  AUDIENCE_GENDERS = 'genders',
  COUNTRIES = 'countries',
  AGE = 'age',
  NOTES = 'notes',
  DETAILS = 'audienceDetails',
  DESTINATION = 'destinationLinks',
  CREATIVE_DESCRIPTION = 'creativeDescription',
  HEADLINE = 'headline',
  CALL_TO_ACTION = 'callToAction',
  ACTUAL_SPENT = 'actualSpent',
}

export interface SpreadsheetRowFields {
  id: Id | undefined;
  uuid: UUID;
  phaseId: Id;
  phaseName: Nullable<string>;
  phaseOrder: number;
  orderInPhase: number | undefined;
  name: Nullable<string> | undefined;
  status: Nullable<CampaignStatuses> | undefined;
  platforms: Nullable<CampaignPlatform[]> | undefined;
  kpi:
    | Nullable<{
        id: number;
        value: string;
      }>
    | undefined;
  objective: Nullable<Objective> | undefined;
  adCopy: Nullable<string> | undefined;
  adCreativeLinks: Nullable<string[]> | undefined;
  notes: Nullable<string> | undefined;
  genders: Nullable<Gender[]> | undefined;
  audienceDetails: Nullable<string> | undefined;
  countries: Nullable<Territory[]> | undefined;
  startDate: Nullable<string> | undefined;
  endDate: Nullable<string> | undefined;
  age: Nullable<AudienceAge> | undefined;
  eCpm: Nullable<number> | undefined;
  budget: Nullable<number> | undefined;
  placements: Nullable<CampaignPlacement[]> | undefined;
  action: Nullable<never> | undefined;
  estImpressions: Nullable<never> | undefined;
  orderOnSort: number | undefined;
  creativeDescription: Nullable<string> | undefined;
  headline: Nullable<string> | undefined;
  callToAction: Nullable<string> | undefined;
  actualSpent: Nullable<number> | undefined;
  destinationLinks: Nullable<string[]> | undefined;
  namingConvention: Nullable<string> | undefined;
  editableInApproval: boolean | undefined;
  namingConventionManual: Nullable<string> | undefined;
}

export interface SpreadsheetCellData<T> {
  value: T;
  color: Nullable<string>;
}

export type SpreadsheetRowData = {
  [K in keyof SpreadsheetRowFields]: SpreadsheetCellData<SpreadsheetRowFields[K]>;
};

export interface InitialConfigParams {
  dateFormat: string;
  performanceObjectives: PerformanceObjective[];
  currencyCode: string;
  goals: Goal[];
  taxonomy: Taxonomy;
  genders: Gender[];
  countries: BaseOption[];
  statuses: CampaignStatus[];
  projectId: Id;
}

interface ValidateFormatFieldParams {
  value: any;
  api: GridApi;
  node?: Nullable<RowNode>;
}

export interface ValidatePasteFieldParams {
  value: string;
  data: SpreadsheetRowData;
}

export interface SpreadsheetValidationObject {
  formatToString(params: ValidateFormatFieldParams): string | null;
  normalizeValue(params: ValidatePasteFieldParams): Optional<any>;
  errorText?: string;
}

export type SpreadsheetValidationModel = Record<MediaPlanColumnId, SpreadsheetValidationObject>;

export enum MediaPlanAccessMode {
  EDIT,
  READ_ONLY,
}

export interface SpreadsheetContext {
  taxonomy?: Taxonomy;
  accessMode: MediaPlanAccessMode;
  mediaPlanMode: MediaPlanMode;
}

export type RowValueFormatterParams<T extends keyof SpreadsheetRowFields> = ValueFormatterParams<
  SpreadsheetRowData,
  SpreadsheetCellData<SpreadsheetRowFields[T]>
>;

export type CellClassRuleParams<T extends keyof SpreadsheetRowFields> = CellClassParams<
  SpreadsheetRowData,
  SpreadsheetCellData<SpreadsheetRowFields[T]>
>;
