import React from 'react';
import { GridApi, ICellEditor, ICellEditorParams, IContextMenuFactory, RowNode } from 'ag-grid-community';
import {
  AudienceAge,
  CampaignPlacement,
  CampaignPlatform,
  CampaignStatus,
  CampaignStatuses,
  Gender,
  Goal,
  Id,
  Nullable,
  Objective,
  Optional,
  PerformanceObjective,
  Taxonomy,
  Territory,
} from 'backend-api/models';
import { BaseOption } from 'common/components/form/select';
import InputMask from 'react-input-mask';
import { UUID } from 'io-ts-types/lib/UUID';
import { IClipboardService } from 'ag-grid-community/dist/lib/interfaces/iClipboardService';
import { IMenu } from 'ag-grid-community/dist/lib/interfaces/iMenu';
import { MediaPlanMode } from 'media-plan/types';

export interface SpreadsheetRowFields {
  id: Id | undefined;
  uuid: UUID;
  phaseId: Id;
  phaseName: Nullable<string>;
  phaseOrder: number;
  orderInPhase: number | undefined;
  name: Nullable<string> | undefined;
  status: Nullable<CampaignStatuses> | undefined;
  platforms: Nullable<CampaignPlatform[]> | undefined;
  kpi:
    | Nullable<{
        id: number;
        value: string;
      }>
    | undefined;
  objective: Nullable<Objective> | undefined;
  adCopy: Nullable<string> | undefined;
  adCreativeLinks: Nullable<string[]> | undefined;
  notes: Nullable<string> | undefined;
  genders: Nullable<Gender[]> | undefined;
  audienceDetails: Nullable<string> | undefined;
  countries: Nullable<Territory[]> | undefined;
  startDate: Nullable<string> | undefined;
  endDate: Nullable<string> | undefined;
  age: Nullable<AudienceAge> | undefined;
  eCpm: Nullable<number> | undefined;
  budget: Nullable<number> | undefined;
  placements: Nullable<CampaignPlacement[]> | undefined;
  action: Nullable<never> | undefined;
  estImpressions: Nullable<never> | undefined;
  orderOnSort: number | undefined;
  creativeDescription: Nullable<string> | undefined;
  headline: Nullable<string> | undefined;
  callToAction: Nullable<string> | undefined;
  actualSpent: Nullable<number> | undefined;
  destinationLinks: Nullable<string[]> | undefined;
  namingConvention: Nullable<string> | undefined;
  editableInApproval: boolean | undefined;
  namingConventionManual: Nullable<string> | undefined;
}

export type SpreadsheetRowFieldsValues = SpreadsheetRowFields[keyof SpreadsheetRowFields];

export interface SpreadsheetCellData<T> {
  value: T;
  color: Nullable<string>;
}

export type SpreadsheetRowData = {
  [K in keyof SpreadsheetRowFields]: SpreadsheetCellData<SpreadsheetRowFields[K]>;
};

export interface PhaseRowProps extends ICellEditorParams {
  projectId: Id;
  mediaPlanId: Id;
  accessMode: MediaPlanAccessMode;
}

export interface GroupRowRendererParams {
  suppressCount?: boolean;
  suppressDoubleClickExpand?: boolean;
  suppressEnterExpand?: boolean;
  innerRenderer?: React.FC<PhaseRowProps>;
}

export interface CellRendererProps {
  data: SpreadsheetRowData;
  eGridCell: Node;
}

export interface InitialConfigParams {
  dateFormat: string;
  performanceObjectives: PerformanceObjective[];
  currencyCode: string;
  goals: Goal[];
  taxonomy: Taxonomy;
  genders: Gender[];
  countries: BaseOption[];
  statuses: CampaignStatus[];
  projectId: Id;
}

export enum MediaPlanColumnId {
  CHECKBOX = 'checkbox',
  ACTIONS = 'action',
  PHASE_ID = 'phaseId',
  STATUS = 'status',
  PLATFORMS = 'platforms',
  NAME = 'name',
  NAMING_CONVENTION = 'namingConventionManual',
  PLACEMENTS = 'placements',
  OBJECTIVE = 'objective',
  BUDGET = 'budget',
  ECPM = 'eCpm',
  EST_IMPRESSIONS = 'estImpressions',
  AD_COPY = 'adCopy',
  AD_CREATIVE = 'adCreativeLinks',
  KPI = 'kpi',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  AUDIENCE_GENDERS = 'genders',
  COUNTRIES = 'countries',
  AGE = 'age',
  NOTES = 'notes',
  DETAILS = 'audienceDetails',
  DESTINATION = 'destinationLinks',
  CREATIVE_DESCRIPTION = 'creativeDescription',
  HEADLINE = 'headline',
  CALL_TO_ACTION = 'callToAction',
  ACTUAL_SPENT = 'actualSpent',
}

export interface ClipboardTransferObject {
  columnId: MediaPlanColumnId;
  value: Record<string, unknown>;
  isRowSelected?: boolean;
}

export enum MediaPlanAccessMode {
  EDIT,
  READ_ONLY,
}

export type PendingDataStore = Record<string, SpreadsheetRowData>;

export interface PendingData {
  create: Nullable<PendingDataStore>;
  update: Nullable<PendingDataStore>;
  delete: Nullable<PendingDataStore>;
}

export type NameEditorInstance = ICellEditor & {
  getId?: () => number;
  updateValue?: (value: Optional<string>) => void;
  getIsTouched?: () => boolean;
};

export type InputMaskExtended = InputMask & { focus: () => void; blur: () => void };

export interface SpreadsheetContext {
  taxonomy?: Taxonomy;
  accessMode: MediaPlanAccessMode;
  mediaPlanMode: MediaPlanMode;
}

export enum FocusDirection {
  NONE = 0,
  FORWARD = 1,
  BACKWARD = -1,
}
export interface ValidatePasteFieldParams {
  value: string;
  data: SpreadsheetRowData;
}

export interface ValidateFormatFieldParams {
  value: any;
  api: GridApi;
  node?: Nullable<RowNode>;
}

export interface SpreadsheetValidationObject {
  formatToString(params: ValidateFormatFieldParams): string | null;
  normalizeValue(params: ValidatePasteFieldParams): Optional<any>;
  errorText?: string;
}

export type SpreadsheetValidationModel = Record<MediaPlanColumnId, SpreadsheetValidationObject>;

export interface ExtendedContextMenuFactory extends IContextMenuFactory {
  activeMenu?: IMenu;
}

export interface ExtendedGridApi extends Omit<GridApi, 'clipboardService' | 'contextMenuFactory'> {
  clipboardService: IClipboardService;
  contextMenuFactory: ExtendedContextMenuFactory;
}

export interface UndoData {
  field: MediaPlanColumnId;
  data: SpreadsheetRowData;
}

export interface UndoHook {
  updateUndoStack(undo: UndoData): void;
  sanitizeUndoRedoStacks(uuid: UUID): void;
  applyUndo(api: GridApi<SpreadsheetRowData>): void;
  applyRedo(api: GridApi<SpreadsheetRowData>): void;
  isUndoStackEmpty: boolean;
  isRedoStackEmpty: boolean;
  clearUndoStack(): void;
  clearRedoStack(): void;
}

export interface CopyPastedCell {
  color: string;
  value: string;
}
