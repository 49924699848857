import { AdSetsSortField } from 'backend-api/types';
import { Sort, SortOrder } from 'utils/sort';
import { SimpleOption } from 'common/constants';
import { GratisType } from './types';
import { BaseOption } from 'common/components/form/select';

export const DEFAULT_SORT: Sort<AdSetsSortField> = new Sort(SortOrder.Descending, 'remainingDays');

export const NOT_AVAILABLE_BUDGET_TOOLTIP = 'Facebook campaign budgets are not available due to data pipelines.';

export const GRATIS_OPTIONS: SimpleOption[] = [
  {
    id: GratisType.Paid,
    name: 'Paid',
  },
  {
    id: GratisType.Gratis,
    name: 'Gratis',
  },
];

export const NOT_SELECTED_VALUE_TITLE = 'Not selected';

export const DEFAULT_PLACEMENTS_OPTION: BaseOption = { id: -1, label: 'Default', options: [] };
export const CUSTOM_PLACEMENTS_OPTION: BaseOption = { id: -2, label: 'Custom', options: [] };

export const CAMPAIGN_NAME_LENGTH_LIMIT = 50;

export const CAMPAIGN_TYPES_WITH_SPEND_FIELD = [5, 6, 7, 8];

export const CAMPAIGN_PLATFORM_SECTION_ID_MASK = 1000;
export const CAMPAIGN_PLATFORM_GROUP_ID_MASK = -1;
