export enum DateFormatPatterns {
  'MM/dd/yy, hh:mm a',
  'MM/dd/yy, HH:mm',
  'dd/MM/yy, hh:mm a',
  'dd/MM/yy, HH:mm',
}

export const DATE_FROMAT_ID_TO_PATTERN = {
  1: 'MM/dd/yy, hh:mm a',
  2: 'MM/dd/yy, HH:mm',
  3: 'dd/MM/yy, hh:mm a',
  4: 'dd/MM/yy, HH:mm',
};

export const INITIAL_DATE_FORMAT = 'MM/dd/yy';
export const INITIAL_DATE_TIME_FORMAT = 'MM/dd/yy, hh:mm a';

export const DEFAULT_DATE_FORMAT = 'MM/dd/yy';
