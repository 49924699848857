import amplitude from 'amplitude-js';
import { Contact, Label } from 'backend-api/models';
import { userInfoSelector } from 'common/selectors';
import { Action, AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

export enum AnalyticsEvents {
  NEW_SESSION = 'new session',
  SIGN_OUT = 'sign out',
  //Project Management
  PROJECT_CREATE_FINISHED = 'project create finished',
  PROJECT_EDITED = 'project edited',
  PROJECT_ARTIST_EDITED = 'project artists edited',
  PROJECT_CLAIM_STARTED = 'project claim started',
  PROJECT_CLAIMED = 'project claimed',
  CAMPAIGN_TEMPLATE_COPIED = 'campaign template copied',
  BUDGET_WIDGET_EXPANDED = 'budget widget expanded',
  TEAMMATE_ADDED = 'teammate added',
  TEAMMATE_REMOVED = 'teammate removed',
  MARKETING_MIX_FILTER_APPLIED = 'marketing mix filter applied',
  MARKETING_MIX_DIGITAL_SELECTED = 'marketing mix digital selected',
  MARKETING_MIX_LIVE_DIGITAL_SELECTED = 'marketing mix live digital selected',
  DUPLICATED_LINKFIRE_LINK_ADDED = 'duplicated linkfire link added',
  FEATURED_ARTIST_REMOVED = 'featured artist removed',
  FEATURED_ARTIST_ADDED = 'featured artist added',
  //Artist Moments Managements
  ARTIST_MOMENT_CREATED = 'moment created',
  ARTIST_MOMENT_EDITED = 'moment edited',
  ARTIST_MOMENT_LINK_CLICKED = 'moment link clicked',
  //Campaign Management
  CAMPAIGN_CREATED = 'campaign create finished',
  CAMPAIGN_EDITED = 'campaign edited',
  CAMPAIGN_APPROVED = 'campaign approved',
  CAMPAIGN_ASSIGNED = 'campaign assigned',
  //Planning Tab
  PHASE_ADDED = 'phase added',
  PHASE_EDITED = 'phase edit finished',
  //Reporting
  PHASE_PICKER_APPLIED = 'phase picker applied',
  PHASE_CLICKED = 'phase clicked',
  PHASE_PERIOD_CHANGED = 'phase period changed',
  FIRST_METRIC_CHANGED = 'metric 1 changed',
  SECOND_METRIC_CHANGED = 'metric 2 changed',
  CAMPAIGN_NODE_EXPANDED = 'campaign node expanded',
  MOMENT_NODE_EXPANDED = 'moment node expanded',
  //Performance
  GLOBAL_PERF_TABLE_VIEWED = 'global perf table viewed',
  PERF_PLATFORMS_FILTERED = 'perf platforms filtered',
  PERF_CATEGORIES_FILTERED = 'perf categories filtered',
  PERF_METRIC_SET_CHANGED = 'perf metric set changed',
  PERF_CUSTOM_COLUMNS_SAVED = 'perf custom columns saved',
  PERF_SEGMENT_CHANGED = 'perf segment changed',
  //Project Navigation
  SUMMARY_TAB_VIEWED = 'summary tab viewed',
  PLANNING_TAB_VIEWED = 'planning tab viewed',
  REPORTING_TAB_VIEWED = 'reporting tab viewed',
  HISTORY_TAB_VIEWED = 'history tab viewed',
  //Global Search
  RECENT_SEARCHES_OPENED = 'recent searches opened',
  RECENT_SEARCHES_CLICKED = 'recent searches clicked',
  SEARCH_RESULT_CLICKED = 'search result clicked',
  //Home Page
  HOME_PAGE_VIEWED = 'home page viewed',
  RECENT_PROJECT_CLICKED = 'recent project clicked',
  UPCOMING_PROJECT_CLICKED = 'upcoming project clicked',
  TEAM_ACTIVITY_PROJECT_CLICKED = 'team activity project clicked',
  ALL_PROJECTS_DROPDOWN_APPLIED = 'all projects dropdown applied',
  //Media Plan
  MEDIA_PLAN_VIEWED = 'media plan tab viewed',
  MEDIA_PLAN_CUSTOM_COLUMNS_SAVED = 'media plan custom columns saved',
  NEW_ROW_ADDED = 'new row added',
  STATUS_EDITED = 'status edited',
  MEDIA_CSV_EXPORTED = 'media csv exported',
  //Digital
  COUNTRY_FILTER_APPLIED = 'country filter applied',
  DIGITAL_METRIC_1_CHANGED = 'digital metric 1 changed',
  DIGITAL_METRIC_2_CHANGED = 'digital metric 2 changed',
  FUNNEL_METRIC_SET = 'funnel metric set',
  FUNNEL_PLATFORM_FILTERED = 'funnel platform filtered',
  FUNNEL_HOVER_TOOLTIP_SHOWN = 'funnel hover tooltip shown',
  PLATFORM_CHART_METRIC_APPLIED = 'platform chart metric applied',
  DEMOGRAPHIC_CHART_METRIC_APPLIED = 'demographic chart metric applied',
  //Artist Roster
  LABEL_SPACE_CHANGED = 'label space changed',
  LIVE_ARTIST_CARD_EXPANDED = 'live artist card expanded',
  PROJECT_SNAPSHOT_PERIOD_CHANGED = 'project snapshot period changed',
  ALL_LABEL_ARTISTS_VIEWED = 'all label artists viewed',
  ALL_LABEL_PROJECTS_VIEWED = 'all label projects viewed',
  NON_ACTIVE_ARTIST_SELECTED = 'non active artist selected',
  //Media Plan V2
  PHASE_CREATED = 'phase created',
  ROW_ADDED = 'row added',
  EXPORT_TO_EXCEL = 'media plan excel export',
  PLATFORM_SELECTED = 'platform selected',
  MEDIA_PLAN_CREATED = 'media plan created',
  MEDIA_PLAN_DELETED = 'media plan deleted',
  //Approval Flow
  APPROVAL_REQUEST_STARTED = 'approval request started',
  APPROVAL_REQUEST_CONFIRMED = 'approval request confirmed',
  APPROVAL_VIEW_OPENED_LINK = 'approver view opened from direct link',
  STATUS_CHANGED_BACK_TO_DRAFT_FROM_PENDING = 'go back to draft from pending approval',
  STATUS_CHANGED_MANUAL = 'manual status change',
  APPROVER_CONFIRM_REVIEW = 'approver confirm review',
  //Settings
  VISIT_USER_SETTINGS_PAGE = 'visit user settings page',
  CHANGE_DATE_AND_TIME_PREFERENCES = 'change date & time preferences',
}

export enum UserCounters {
  CAMPAIGNS_APPROVED = 'campaigns_approved',
  CAMPAIGNS_ASSIGNED = 'campaigns_assigned',
  CAMPAIGNS_CREATED = 'campaigns_created',
  CAMPAIGNS_EDITED = 'campaigns_edited',
  MOMENTS_CREATED = 'moments_created',
  MOMENTS_EDITED = 'moments_edited',
  PROJECTS_CLAIMED = 'projects_claimed',
  PROJECTS_EDITED = 'projects_edited',
}

const analyticsClient = amplitude.getInstance();

export const initAnalytics = () => {
  if (process.env.AMPLITUDE_KEY) {
    analyticsClient.init(process.env.AMPLITUDE_KEY, undefined, {
      batchEvents: true,
      eventUploadThreshold: 2,
      eventUploadPeriodMillis: 10000,
    });
  }
};

export const trackUserId = (id: string) => {
  return analyticsClient.setUserId(id);
};

export const trackEvent = (event: AnalyticsEvents, payload?: unknown) => {
  analyticsClient.logEvent(event, payload);
};

export const increaseUserCounter = (type: UserCounters, amount: number) => {
  const identify = new amplitude.Identify();
  identify.add(type, amount);
  amplitude.identify(identify);
};

export const trackUserInfo = (userInfo: Contact) => {
  const identify = new amplitude.Identify();
  identify
    .set('full_name', userInfo.name)
    .set('email', userInfo.email)
    .set('phone_number', userInfo.phone)
    .set('decibel_user_id', userInfo.id);
  amplitude.identify(identify);
};

export const trackUserLabels = (labels: Label[]) => {
  const identify = new amplitude.Identify();
  identify.set(
    'marketer_labels',
    labels.map(label => label.name)
  );
  amplitude.identify(identify);
};

export const trackUserMiddleware: Middleware = <A extends Action>(api: MiddlewareAPI) => (
  next: Dispatch<AnyAction>
) => (action: A) => {
  const userId = userInfoSelector(api.getState())?.data?.externalId;
  const {
    options: { userId: analyticsUserId },
  } = analyticsClient;
  if (userId && (!analyticsUserId || userId !== analyticsUserId)) {
    trackUserId(userId);
  }

  if (!action) return;
  next(action);
};
