import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editArtistsSelector } from 'label-permissions/selectors';
import {
  addAllArtists,
  addRecentArtist,
  closeEditArtistsModal,
  editUserArtists,
  getLabelArtists,
  removeArtist,
  removeRecentArtist,
  setArtistsSearch,
} from 'label-permissions/actions';
import { useManageToasts, THEME, TOAST_TYPE, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { EditModal } from 'label-permissions/components';
import { globalLabelSelector } from 'common/selectors';
import { isSubstring } from 'utils/string';
import { ShortArtist } from 'backend-api/models';
import { processArtists, processArtistsResults } from './transducers';
import { Loading } from 'common-v2/types';
import { ArtistItem } from 'label-permissions/components/edit-modal/components/list/components';
import { ErrorView } from 'common-v2/components';

import { getErrorViewConfig } from 'reporting-v2/components/manage-campaigns-modal/transducers';
import { ArtistsListHeader } from 'label-permissions/components/edit-modal/components/list-header/components';

interface EditModalProps {
  onDataUpdated(): void;
}

export const EditArtistsContainer = React.memo(({ onDataUpdated }: EditModalProps) => {
  const dispatch = useDispatch();
  const label = useSelector(globalLabelSelector);
  const {
    isOpened,
    title,
    userId,
    labelArtists,
    userArtists,
    search,
    recentlyAddedArtists,
    removedArtistsIds,
    processing,
  } = useSelector(editArtistsSelector);
  const { openToast } = useManageToasts(THEME.light);

  const loadLabelArtists = useCallback(() => {
    if (!label) {
      return;
    }
    dispatch(getLabelArtists.request(label.id));
  }, [dispatch, label]);

  useEffect(() => {
    isOpened && loadLabelArtists();
  }, [dispatch, label, isOpened, loadLabelArtists]);

  const closeEditArtists = useCallback(() => {
    dispatch(closeEditArtistsModal());
  }, [dispatch]);

  useEffect(() => {
    if (![Loading.Finished, Loading.Failed].some(loading => loading === processing)) return;

    if (processing === Loading.Finished) {
      openToast({ id: 'artists-assign-success', message: 'Artists successfully assigned', type: TOAST_TYPE.SUCCESS });
    }

    if (processing === Loading.Failed) {
      openToast({
        id: 'artists-assign-fail',
        message: 'An error occurred while updating artist team, please try again.',
        type: TOAST_TYPE.ERROR,
      });
    }

    closeEditArtists();
    onDataUpdated();
  }, [closeEditArtists, openToast, onDataUpdated, processing]);

  const { alreadyAddedArtists, artistsForAssignAll, artistsForSearch } = useMemo(
    () =>
      processArtists(
        labelArtists.data,
        recentlyAddedArtists,
        removedArtistsIds,
        userArtists.map(({ id }) => id)
      ),
    [labelArtists.data, recentlyAddedArtists, removedArtistsIds, userArtists]
  );

  const onSearchUpdated = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => dispatch(setArtistsSearch(event.target.value)),
    [dispatch]
  );

  const onCleanSearch = useCallback(() => dispatch(setArtistsSearch('')), [dispatch]);

  const searchResults = useMemo(() => {
    if (search.length === 0) {
      return [];
    }
    return artistsForSearch.filter(({ name }) => isSubstring(name, search));
  }, [artistsForSearch, search]);

  const onAddArtist = useCallback(
    (artist: ShortArtist) => {
      dispatch(addRecentArtist(artist));
      dispatch(setArtistsSearch(''));
    },
    [dispatch]
  );

  const onAddAllArtist = useCallback(() => dispatch(addAllArtists(artistsForAssignAll)), [
    dispatch,
    artistsForAssignAll,
  ]);

  const onApplyChanges = useCallback(() => {
    if (!label || !userId) {
      return;
    }

    const { addedArtistsIds, deletedArtistsIds } = processArtistsResults(
      userArtists.map(({ id }) => id),
      recentlyAddedArtists,
      removedArtistsIds
    );

    if (addedArtistsIds.length === 0 && deletedArtistsIds.length === 0) {
      closeEditArtists();
      return;
    }

    dispatch(
      editUserArtists.request({
        labelId: label.id,
        userId,
        addedArtistsIds,
        deletedArtistsIds,
      })
    );
  }, [label, userId, userArtists, recentlyAddedArtists, removedArtistsIds, dispatch, closeEditArtists]);

  const userArtistsNodes = useMemo(
    () =>
      alreadyAddedArtists.map(artist => (
        <ArtistItem
          key={artist.id}
          isDisabled={processing === Loading.Started}
          artist={artist}
          onDelete={() => dispatch(removeArtist(artist.id))}
        />
      )),
    [alreadyAddedArtists, processing, dispatch]
  );

  const recentlyAddedArtistsNodes = useMemo(() => {
    return recentlyAddedArtists.map(artist => (
      <ArtistItem
        key={artist.id}
        isDisabled={processing === Loading.Started}
        artist={artist}
        onDelete={() => dispatch(removeRecentArtist(artist.id))}
      />
    ));
  }, [dispatch, recentlyAddedArtists, processing]);

  const suggestedArtistsNodes = useMemo(
    () => searchResults.map(artist => <ArtistItem key={artist.id} artist={artist} onArtistClick={onAddArtist} />),
    [onAddArtist, searchResults]
  );

  const emptyState = useMemo(
    () => (
      <Typography type={TYPOGRAPHY_TYPE.body2}>
        There are currently no artists added.
        <br />
        To continue, type artist name on the search
        <br />
        or click “Add All” to assign all artists to this
        <br />
        person.
      </Typography>
    ),
    []
  );

  const errorState = useMemo(() => {
    const viewConfig = getErrorViewConfig(
      <Typography type={TYPOGRAPHY_TYPE.body2}>
        An error occurred while loading Artists data.
        <br />
        Please try again
      </Typography>
    );

    return <ErrorView config={viewConfig} action={loadLabelArtists} />;
  }, [loadLabelArtists]);

  return (
    <EditModal
      isOpened={isOpened}
      loading={labelArtists.loading}
      processing={processing}
      onClose={closeEditArtists}
      onApply={onApplyChanges}
      title={title}
      addedNodes={userArtistsNodes}
      recentNodes={recentlyAddedArtistsNodes}
      suggestedNodes={suggestedArtistsNodes}
      searchValue={search}
      searchPlaceholder="Search Artist to Add to the List"
      onSearchChange={onSearchUpdated}
      clearSearch={onCleanSearch}
      onAddAll={onAddAllArtist}
      emptyState={emptyState}
      errorState={errorState}
      listHeader={<ArtistsListHeader />}
      width={629}
      height={686}
    />
  );
});
