import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'release-info';

export const SReleaseInfo = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__info-item {
      display: flex;
      align-items: center;
      position: relative;
      gap: 5px;
    }

    &__release-details {
      display: flex;
      flex-direction: column;
      z-index: 1;
    }

    &__trigger {
      max-height: 20px;
      border-bottom: 1px dotted ${transparentize(0.4, manhattanThemeColors.primaryFillNew)};
    }

    &__trigger-title {
      &--isDisabled {
        color: ${manhattanThemeColors.secondGrayedText};
      }
    }

    &__label {
      color: ${manhattanThemeColors.grayedText};
    }
  }
`;
