import styled, { css } from 'styled-components';
import { IconColor, IconSize } from './types';
import { colorFromIconColor } from 'common/transducers';

const size = ({ size = 'glyph' }: { size?: IconSize }) => {
  let pxSize: string;

  switch (size) {
    case 'small':
      pxSize = '18px';
      break;
    case 'general':
      pxSize = '22px';
      break;
    case 'medium':
      pxSize = '28px';
      break;
    case 'large':
      pxSize = '32px';
      break;
    case 'extra-large':
      pxSize = '42px';
      break;
    case 'menu':
      pxSize = '14px';
      break;
    case 'projects':
      pxSize = '17px';
      break;
    case 'platforms':
      pxSize = '16px';
      break;
    case 'inherit':
      pxSize = 'inherit';
      break;
    default:
      // don't ask me why not 12px)
      pxSize = '11px';
  }

  return css`
    width: ${pxSize};
    height: ${pxSize};
    font-size: ${pxSize};
  `;
};

const customColor = ({ color }: { color?: IconColor }) => {
  // TODO get colors from theme
  const iconColor = colorFromIconColor(color);

  // attribute should override any other rules
  return css`
    color: ${iconColor};
  `;
};

const baseCss = (code: string) => css`
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  margin: 1px;
  ${size};

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    content: '${code}';
   ${size}
  }
`;

const baseIcon = mixin => {
  return styled.i`
    ${mixin};
    padding: 0;
    margin: 0;
    position: relative;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      ${customColor}
    }
  `;
};

export const SSpotifyCss = baseCss('\\e900');
export const SStarCss = baseCss('\\e901');
export const SAppleCss = baseCss('\\e902');
export const SGlobalCss = baseCss('\\e903');
export const SEllipsisCss = baseCss('\\e904');
export const SMultichartCss = baseCss('\\e905');
export const SPercentCss = baseCss('\\e906');
export const SPositionCss = baseCss('\\e907');
export const SSearchCss = baseCss('\\e908');
export const SPlaceholderCss = baseCss('\\e93a');
export const SStarOutlinedCss = baseCss('\\e90a');
export const STableCss = baseCss('\\e90b');
export const SBarchartCss = baseCss('\\e90c');
export const SChartCss = baseCss('\\e90d');
export const SCloseCss = baseCss('\\e90e');
export const SCollapseCss = baseCss('\\e90f');
export const SSuccessCss = baseCss('\\e910');
export const SDeleteCss = baseCss('\\e911');
export const SDownCss = baseCss('\\e912');
export const SExpandCss = baseCss('\\e913');
export const SExportCss = baseCss('\\e914');
export const SFilterCss = baseCss('\\e915');
export const SHeartCss = baseCss('\\e916');
export const SNextCss = baseCss('\\e917');
export const SNewWindowCss = baseCss('\\e939');
export const SForwardCss = baseCss('\\e918');
export const SPlusCss = baseCss('\\e919');
export const SPrevCss = baseCss('\\e91a');
export const SBackwardCss = baseCss('\\e91b');
export const SInProgressCss = baseCss('\\e91c');
export const SRightCss = baseCss('\\e91d');
export const SScheduledCss = baseCss('\\e91e');
export const SSortCss = baseCss('\\e91f');
export const SStarGlyphCss = baseCss('\\e920');
export const SStarStrokeGlyphCss = baseCss('\\e921');
export const SUpCss = baseCss('\\e922');
export const SWarningCss = baseCss('\\e92b');
export const SErrorCss = baseCss('\\e92c');
export const SMenuCss = baseCss('\\e92d');
export const SCrossCss = baseCss('\\e92e');
export const SSignOutCss = baseCss('\\e92f');
export const SArrowDiagBottomCss = baseCss('\\e923');
export const SArrowDiagTopCss = baseCss('\\e924');
export const SArrowDownCss = baseCss('\\e925');
export const SArrowLeftCss = baseCss('\\e926');
export const SArrowRightCss = baseCss('\\e927');
export const SArrowUpCss = baseCss('\\e928');
export const SCalendarCss = baseCss('\\e929');
export const SHamburgerCss = baseCss('\\e938');
export const SCheckCss = baseCss('\\e92a');
export const SCircleCss = baseCss('\\e971');
export const SAmazonCss = baseCss('\\e930');
export const SSettingsCss = baseCss('\\e931');
export const SRemovalCss = baseCss('\\e932');
export const SCalendarBigCss = baseCss('\\e94d');
export const SDragCss = baseCss('\\e936');
export const SEditCss = baseCss('\\e972');
export const STimeCss = baseCss('\\e96a');
export const SEditOutlineCss = baseCss('\\e96c');
export const SSharedOutlineCss = baseCss('\\e96d');
export const SUndoCss = baseCss('\\e96e');
export const SViewOutlineCss = baseCss('\\e96f');
export const SDeleteOutlineCss = baseCss('\\e970');
export const SDeleteInnerOutlineCss = baseCss('\\e9d8');
export const SSharedCss = baseCss('\\e973');
export const SViewCss = baseCss('\\e974');
export const SEllipsisMenuCss = baseCss('\\e968');
export const SExpandFieldCss = baseCss('\\e976');
export const SCollapseFieldCss = baseCss('\\e969');
export const SAutogenerateCss = baseCss('\\e975');
export const SBoardCss = baseCss('\\e977');
export const SUnassignedStatusCss = baseCss('\\e980');
export const SAppearancesCss = baseCss('\\e981');
export const SRejectCss = baseCss('\\e982');
export const SApproveCss = baseCss('\\e983');
export const SArrowRightThinCss = baseCss('\\e963');
export const SAddBoldCss = baseCss('\\e93c');
export const SEnterCss = baseCss('\\e989');
export const SApolloCss = baseCss('\\e98a');
export const SCopyCss = baseCss('\\e98b');
export const SNoResultsCss = baseCss('\\e97b');
export const SYoutubeCss = baseCss('\\e98c');
export const SDeezerCss = baseCss('\\e991');
export const SFacebookCss = baseCss('\\e992');
export const SGoogleCss = baseCss('\\e993');
export const SInstagramCss = baseCss('\\e994');
export const SLinkfireCss = baseCss('\\e995');
export const SPandoraCss = baseCss('\\e996');
export const SShazamCss = baseCss('\\e997');
export const SSnapchatCss = baseCss('\\e998');
export const SSoundcloudCss = baseCss('\\e999');
export const STidalCss = baseCss('\\e99a');
export const STiktokCss = baseCss('\\e99b');
export const STwitterCss = baseCss('\\e99c');
export const SLinkCss = baseCss('\\e99d');
export const SDownSingleCss = baseCss('\\e97e');
export const SBlockedCss = baseCss('\\e990');
export const SDollarCss = baseCss('\\e99e');
export const SConfidentialCss = baseCss('\\e9a0');
export const SUnreadCss = baseCss('\\e9a1');
export const SThinPlusCss = baseCss('\\e9a2');
export const SExpandBoldCss = baseCss('\\e9c9');
export const SCollapseBoldCss = baseCss('\\e9ca');
export const SEnvelopeCss = baseCss('\\e9cc');
export const SLabelPermissionsCss = baseCss('\\e9dc');

// campaign icons
export const SPlaylistCss = baseCss('\\e93e');
export const SPrintCss = baseCss('\\e93f');
export const SRadioCss = baseCss('\\e940');
export const SSocialCss = baseCss('\\e941');
export const SPictureCss = baseCss('\\e942');
export const STvCss = baseCss('\\e943');
export const STicketCss = baseCss('\\e944');
export const SCrmCss = baseCss('\\e945');
export const SDigitalAdvCss = baseCss('\\e946');
export const SCartCss = baseCss('\\e947');
export const SMusicVideoCss = baseCss('\\e948');
export const SNoteCss = baseCss('\\e949');
export const STreeCss = baseCss('\\e94a');
export const SCaseCss = baseCss('\\e94b');
export const SPhysicalActivationCss = baseCss('\\e94c');
export const SPartialDataCss = baseCss('\\e9a5');

// campaign icons medium
export const SPlaylistMediumCss = baseCss('\\e958');
export const SPrintMediumCss = baseCss('\\e95e');
export const SRadioMediumCss = baseCss('\\e95f');
export const SSocialMediumCss = baseCss('\\e950');
export const SPictureMediumCss = baseCss('\\e95c');
export const STvMediumCss = baseCss('\\e954');
export const STicketMediumCss = baseCss('\\e959');
export const SCrmMediumCss = baseCss('\\e957');
export const SDigitalAdvMediumCss = baseCss('\\e955');
export const SCartMediumCss = baseCss('\\e95b');
export const SMusicVideoMediumCss = baseCss('\\e95d');
export const SNoteMediumCss = baseCss('\\e952');
export const STreeMediumCss = baseCss('\\e951');
export const SCaseMediumCss = baseCss('\\e956');
export const SPhysicalActivationMediumCss = baseCss('\\e95a');
export const SRoundAddCss = baseCss('\\e94e');
export const SInfoCss = baseCss('\\e967');
export const STvAdMediumCss = baseCss('\\e953');
export const SUnknownCss = baseCss('\\e979');

// jsx icons
export const SSpotify = baseIcon(SSpotifyCss);
export const SStar = baseIcon(SStarCss);
export const SApple = baseIcon(SAppleCss);
export const SGlobal = baseIcon(SGlobalCss);
export const SEllipsis = baseIcon(SEllipsisCss);
export const SMultichart = baseIcon(SMultichartCss);
export const SPercent = baseIcon(SPercentCss);
export const SPosition = baseIcon(SPositionCss);
export const SSearch = baseIcon(SSearchCss);
export const SPlaceholder = baseIcon(SPlaceholderCss);
export const SStarOutlined = baseIcon(SStarOutlinedCss);
export const STable = baseIcon(STableCss);
export const SBarchart = baseIcon(SBarchartCss);
export const SChart = baseIcon(SChartCss);
export const SClose = baseIcon(SCloseCss);
export const SCollapse = baseIcon(SCollapseCss);
export const SSuccess = baseIcon(SSuccessCss);
export const SDelete = baseIcon(SDeleteCss);
export const SDown = baseIcon(SDownCss);
export const SExpand = baseIcon(SExpandCss);
export const SExport = baseIcon(SExportCss);
export const SFilter = baseIcon(SFilterCss);
export const SHeart = baseIcon(SHeartCss);
export const SNext = baseIcon(SNextCss);
export const SNewWindow = baseIcon(SNewWindowCss);
export const SForward = baseIcon(SForwardCss);
export const SPlus = baseIcon(SPlusCss);
export const SPrev = baseIcon(SPrevCss);
export const SBackward = baseIcon(SBackwardCss);
export const SInProgress = baseIcon(SInProgressCss);
export const SRight = baseIcon(SRightCss);
export const SScheduled = baseIcon(SScheduledCss);
export const SSort = baseIcon(SSortCss);
export const SStarGlyph = baseIcon(SStarGlyphCss);
export const SStarStrokeGlyph = baseIcon(SStarStrokeGlyphCss);
export const SUp = baseIcon(SUpCss);
export const SWarning = baseIcon(SWarningCss);
export const SError = baseIcon(SErrorCss);
export const SMenu = baseIcon(SMenuCss);
export const SCross = baseIcon(SCrossCss);
export const SSignOut = baseIcon(SSignOutCss);
export const SArrowDiagBottom = baseIcon(SArrowDiagBottomCss);
export const SArrowDiagTop = baseIcon(SArrowDiagTopCss);
export const SArrowDown = baseIcon(SArrowDownCss);
export const SArrowLeft = baseIcon(SArrowLeftCss);
export const SArrowRight = baseIcon(SArrowRightCss);
export const SArrowUp = baseIcon(SArrowUpCss);
export const SCalendar = baseIcon(SCalendarCss);
export const SHamburger = baseIcon(SHamburgerCss);
export const SCheck = baseIcon(SCheckCss);
export const SAmazon = baseIcon(SAmazonCss);
export const SSettings = baseIcon(SSettingsCss);
export const SCircle = baseIcon(SCircleCss);
export const SRemoval = baseIcon(SRemovalCss);
export const SEdit = baseIcon(SEditCss);

export const SCase = baseIcon(SCaseCss);
export const SCart = baseIcon(SCartCss);
export const SCrm = baseIcon(SCrmCss);
export const SDigitalAdv = baseIcon(SDigitalAdvCss);
export const SMusicVideo = baseIcon(SMusicVideoCss);
export const SPhysicalActivation = baseIcon(SPhysicalActivationCss);
export const SPicture = baseIcon(SPictureCss);
export const SPlaylist = baseIcon(SPlaylistCss);
export const SPrint = baseIcon(SPrintCss);
export const SRadio = baseIcon(SRadioCss);
export const SSocial = baseIcon(SSocialCss);
export const SNote = baseIcon(SNoteCss);
export const STicket = baseIcon(STicketCss);
export const STree = baseIcon(STreeCss);
export const STv = baseIcon(STvCss);
export const SCalendarBig = baseIcon(SCalendarBigCss);
export const SDrag = baseIcon(SDragCss);
export const STime = baseIcon(STimeCss);
export const SEditOutline = baseIcon(SEditOutlineCss);
export const SSharedOutline = baseIcon(SSharedOutlineCss);
export const SUndo = baseIcon(SUndoCss);
export const SViewOutline = baseIcon(SViewOutlineCss);
export const SDeleteOutline = baseIcon(SDeleteOutlineCss);
export const SDeleteInnerOutline = baseIcon(SDeleteInnerOutlineCss);
export const SShared = baseIcon(SSharedCss);
export const SView = baseIcon(SViewCss);

export const SPlaylistMedium = baseIcon(SPlaylistMediumCss);
export const SPrintMedium = baseIcon(SPrintMediumCss);
export const SRadioMedium = baseIcon(SRadioMediumCss);
export const SSocialMedium = baseIcon(SSocialMediumCss);
export const SPictureMedium = baseIcon(SPictureMediumCss);
export const STvMedium = baseIcon(STvMediumCss);
export const STicketMedium = baseIcon(STicketMediumCss);
export const SCrmMedium = baseIcon(SCrmMediumCss);
export const SDigitalAdvMedium = baseIcon(SDigitalAdvMediumCss);
export const SCartMedium = baseIcon(SCartMediumCss);
export const SMusicVideoMedium = baseIcon(SMusicVideoMediumCss);
export const SNoteMedium = baseIcon(SNoteMediumCss);
export const STreeMedium = baseIcon(STreeMediumCss);
export const SCaseMedium = baseIcon(SCaseMediumCss);
export const SPhysicalActivationMedium = baseIcon(SPhysicalActivationMediumCss);
export const SEllipsisMenu = baseIcon(SEllipsisMenuCss);
export const SRoundAdd = baseIcon(SRoundAddCss);
export const SExpandField = baseIcon(SExpandFieldCss);
export const SCollapseField = baseIcon(SCollapseFieldCss);
export const SAutogenerate = baseIcon(SAutogenerateCss);
export const SBoard = baseIcon(SBoardCss);
export const SInfo = baseIcon(SInfoCss);
export const SUnassignedStatus = baseIcon(SUnassignedStatusCss);
export const SAppearances = baseIcon(SAppearancesCss);
export const SReject = baseIcon(SRejectCss);
export const SApprove = baseIcon(SApproveCss);
export const STvAdMedium = baseIcon(STvAdMediumCss);
export const SArrowRightThin = baseIcon(SArrowRightThinCss);
export const SAddBold = baseIcon(SAddBoldCss);
export const SEnter = baseIcon(SEnterCss);
export const SApollo = baseIcon(SApolloCss);
export const SCopy = baseIcon(SCopyCss);
export const SNoResults = baseIcon(SNoResultsCss);
export const SYoutube = baseIcon(SYoutubeCss);
export const SDeezer = baseIcon(SDeezerCss);
export const SFacebook = baseIcon(SFacebookCss);
export const SGoogle = baseIcon(SGoogleCss);
export const SInstagram = baseIcon(SInstagramCss);
export const SLinkfire = baseIcon(SLinkfireCss);
export const SPandora = baseIcon(SPandoraCss);
export const SShazam = baseIcon(SShazamCss);
export const SSnapchat = baseIcon(SSnapchatCss);
export const SSoundcloud = baseIcon(SSoundcloudCss);
export const STidal = baseIcon(STidalCss);
export const STiktok = baseIcon(STiktokCss);
export const STwitter = baseIcon(STwitterCss);
export const SLink = baseIcon(SLinkCss);
export const SDownSingle = baseIcon(SDownSingleCss);
export const SBlocked = baseIcon(SBlockedCss);
export const SUnknown = baseIcon(SUnknownCss);
export const SDollar = baseIcon(SDollarCss);
export const SConfidential = baseIcon(SConfidentialCss);
export const SUnread = baseIcon(SUnreadCss);
export const SThinPlus = baseIcon(SThinPlusCss);
export const SPartialData = baseIcon(SPartialDataCss);
export const SExpandBold = baseIcon(SExpandBoldCss);
export const SCollapseBold = baseIcon(SCollapseBoldCss);
export const SEnvelope = baseIcon(SEnvelopeCss);
export const SLabelPermissions = baseIcon(SLabelPermissionsCss);
