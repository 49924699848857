import { actionsGroup } from 'core/store';
import {
  AllProjectsResponse,
  AllUnassignedProjectsResponse,
  ArtistRosterModel,
  ArtistRosterProject,
  ArtistTeam,
  Id,
  Label,
  Optional,
  RecentProject,
  TeamActivity,
  UpcomingProject,
} from 'backend-api/models';
import { ProjectFilterParams } from 'backend-api/types';
import {
  AllProjectsFilters,
  GetExpansionProjectsPayload,
  MetricsWithProjectId,
  ProjectSnapshotError,
  ProjectSnapshotParams,
} from './types';

interface ProjectsRecentResponse {
  recentProjects: RecentProject[];
  upcomingProjects: UpcomingProject[];
  teamActivity: TeamActivity[];
}

type AllProjectsRequest = ProjectFilterParams;

const { action, asyncAction } = actionsGroup('projects');

export const getRecentInfo = asyncAction<void, ProjectsRecentResponse, Error>('GET_RECENT_INFO');

export const getAllProjects = asyncAction<AllProjectsRequest, AllProjectsResponse, Error>('GET_ALL_PROJECTS');

export const getAllUnassignedProjects = asyncAction<AllProjectsRequest, AllUnassignedProjectsResponse, Error>(
  'GET_ALL_UNASSIGNED'
);

export const updateFilters = action<AllProjectsFilters>('UPDATE_FILTERS');

export const exportAllProjectsCsv = asyncAction<void, unknown, Error>('EXPORT_ALL_PROJECTS_CSV');

export const getUserProjectsCount = asyncAction<void, number, Error>('GET_USER_PROJECTS_COUNT');

export const setCurrentLabel = action<number>('SET_CURRENT_LABEL');

export const getCurrentLabel = asyncAction<void, Label, Error>('GET_CURRENT_LABEL');

export const getArtistRoster = asyncAction<Id, ArtistRosterModel, Error>('GET_ARTIST_ROSTER');

export const getLiveArtistTeams = asyncAction<string, ArtistTeam[], Error>('GET_LIVE_ARTIST_TEAMS');

export const getUpcomingArtistTeams = asyncAction<string, ArtistTeam[], Error>('GET_UPCOMING_ARTIST_TEAMS');

export const getProjectsSnapshots = asyncAction<ProjectSnapshotParams[], MetricsWithProjectId, ProjectSnapshotError>(
  'GET_PROJECTS_SNAPSHOTS'
);

export const getExpansionLiveProjects = asyncAction<GetExpansionProjectsPayload, ArtistRosterProject[], Error>(
  'GET_LIVE_EXPANSION_PROJECTS'
);

export const getExpansionUpcomingProjects = asyncAction<GetExpansionProjectsPayload, ArtistRosterProject[], Error>(
  'GET_UPCOMING_EXPANSION_PROJECTS'
);

export const setLiveExpandedId = action<Optional<string>>('SET_LIVE_EXPANDED_ID');
export const setUpcomingExpandedId = action<Optional<string>>('SET_UPCOMING_EXPANDED_ID');
export const resetExpansions = action<void>('RESET_EXPANSIONS');
