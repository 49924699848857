import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { SSectionTitle } from './s-section-title';
import { PrimaryLabel } from 'common/components/typography';
import { Tooltip } from 'common/components/tooltip';
import { MAX_TOOLTIP_WIDTH } from './constants';
import { convertToDataSelector } from 'common/utils/string';

interface Props {
  title: string;
  maxCount?: number;
  currentCount?: number;
  withCounter?: boolean;
  isSectionExpandable?: boolean;
  isSectionExpanded?: boolean;
  sectionExpandHandler?(isExpanded: boolean): void;
  warningText?: string;
}

const classes = bem('section-title');

export const SectionTitle = React.memo(
  ({
    title,
    isSectionExpandable = false,
    isSectionExpanded,
    sectionExpandHandler,
    maxCount = 0,
    currentCount = 0,
    withCounter,
    warningText,
  }: Props) => {
    const onExpandClick = useCallback(() => {
      if (sectionExpandHandler) {
        sectionExpandHandler(!isSectionExpanded);
      }
    }, [sectionExpandHandler, isSectionExpanded]);
    return (
      <SSectionTitle>
        <PrimaryLabel className={classes('title')} data-selector={`section-title-${convertToDataSelector(title)}`}>
          {title}
          {warningText && (
            <Tooltip maxWidth={MAX_TOOLTIP_WIDTH} placement="bottom" content={warningText}>
              <div className={classes('warning-icon')}>
                <Icon name="info" color="pink" size="general" />
              </div>
            </Tooltip>
          )}
          {isSectionExpandable && (
            <button className={classes('expand', { expanded: !!isSectionExpanded })} onClick={onExpandClick}>
              <Icon name="next" />
            </button>
          )}
        </PrimaryLabel>
        {withCounter && (
          <PrimaryLabel
            className={classes('counter')}
            data-selector={`section-title-${convertToDataSelector(title)}-counter`}
          >
            {currentCount}/{maxCount}
          </PrimaryLabel>
        )}
      </SSectionTitle>
    );
  }
);
