import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editMembersSelector } from 'label-permissions/selectors';
import {
  addAllMembers,
  addRecentMember,
  closeEditMembersModal,
  editArtistMembers,
  getLabelUsers,
  removeMember,
  removeRecentMember,
  setMembersSearch,
} from 'label-permissions/actions';
import { EditModal } from 'label-permissions/components';
import { globalLabelSelector } from 'common/selectors';
import { isSubstring } from 'utils/string';
import { LabelUser } from 'backend-api/models';
import { processMembers, processMembersResults } from './transducers';
import { Loading } from 'common-v2/types';
import { MemberItem } from 'label-permissions/components/edit-modal/components/list/components';
import { ErrorView } from 'common-v2/components';
import { THEME, TOAST_TYPE, Typography, TYPOGRAPHY_TYPE, useManageToasts } from 'gdb-web-shared-components';
import { getErrorViewConfig } from 'reporting-v2/components/manage-campaigns-modal/transducers';
import { MembersListHeader } from 'label-permissions/components/edit-modal/components/list-header/components';

interface EditModalProps {
  onDataUpdated(): void;
}

export const EditMembersContainer = React.memo(({ onDataUpdated }: EditModalProps) => {
  const dispatch = useDispatch();
  const label = useSelector(globalLabelSelector);
  const {
    isOpened,
    title,
    artistId,
    artistMembers,
    search,
    recentlyAddedMembers,
    removedMembersIds,
    processing,
    labelUsers,
  } = useSelector(editMembersSelector);

  const loadLabelUsers = useCallback(() => {
    if (!label) {
      return;
    }
    dispatch(getLabelUsers.request(label.id));
  }, [dispatch, label]);

  useEffect(() => {
    isOpened && loadLabelUsers();
  }, [dispatch, label, isOpened, loadLabelUsers]);

  const closeEditMembers = useCallback(() => {
    dispatch(closeEditMembersModal());
  }, [dispatch]);

  const { openToast } = useManageToasts(THEME.light);

  useEffect(() => {
    if (![Loading.Finished, Loading.Failed].some(loading => loading === processing)) return;

    if (processing === Loading.Finished) {
      openToast({ id: 'members-assign-success', message: 'Users successfully assigned', type: TOAST_TYPE.SUCCESS });
    }

    if (processing === Loading.Failed) {
      openToast({
        id: 'members-assign-fail',
        message: 'An error occurred while updating artist team, please try again.',
        type: TOAST_TYPE.ERROR,
      });
    }

    closeEditMembers();
    onDataUpdated();
  }, [closeEditMembers, onDataUpdated, openToast, processing]);

  const { alreadyAddedMembers, membersForAssignAll, membersForSearch } = useMemo(
    () =>
      processMembers(
        labelUsers.data,
        recentlyAddedMembers,
        removedMembersIds,
        artistMembers.map(({ id }) => id)
      ),
    [artistMembers, labelUsers.data, recentlyAddedMembers, removedMembersIds]
  );

  const onSearchUpdated = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => dispatch(setMembersSearch(event.target.value)),
    [dispatch]
  );

  const onCleanSearch = useCallback(() => dispatch(setMembersSearch('')), [dispatch]);

  const searchResults = useMemo(() => {
    if (search.length === 0) {
      return [];
    }
    return membersForSearch.filter(({ name }) => isSubstring(name, search));
  }, [membersForSearch, search]);

  const onAddMember = useCallback(
    (member: LabelUser) => {
      dispatch(addRecentMember(member));
      dispatch(setMembersSearch(''));
    },
    [dispatch]
  );

  const onAddAllMembers = useCallback(() => dispatch(addAllMembers(membersForAssignAll)), [
    dispatch,
    membersForAssignAll,
  ]);

  const onApplyChanges = useCallback(() => {
    if (!label || !artistId) {
      return;
    }

    const { addedMembersIds, deletedMembersIds } = processMembersResults(
      artistMembers.map(({ id }) => id),
      recentlyAddedMembers,
      removedMembersIds
    );

    if (addedMembersIds.length === 0 && deletedMembersIds.length == 0) {
      closeEditMembers();
      return;
    }

    dispatch(
      editArtistMembers.request({
        labelId: label.id,
        artistId,
        addedMembersIds,
        deletedMembersIds,
      })
    );
  }, [label, artistId, artistMembers, recentlyAddedMembers, removedMembersIds, dispatch, closeEditMembers]);

  const userArtistsNodes = useMemo(
    () =>
      alreadyAddedMembers.map(user => (
        <MemberItem
          key={user.id}
          isDisabled={processing === Loading.Started}
          user={user}
          onDelete={() => dispatch(removeMember(user.id))}
        />
      )),
    [alreadyAddedMembers, processing, dispatch]
  );

  const recentlyAddedArtistsNodes = useMemo(
    () =>
      recentlyAddedMembers.map(user => (
        <MemberItem
          key={user.id}
          isDisabled={processing === Loading.Started}
          user={user}
          onDelete={() => dispatch(removeRecentMember(user.id))}
        />
      )),
    [recentlyAddedMembers, processing, dispatch]
  );

  const suggestedMembersNodes = useMemo(
    () => searchResults.map(user => <MemberItem key={user.id} showEmail user={user} onMemberClick={onAddMember} />),
    [onAddMember, searchResults]
  );

  const emptyState = useMemo(
    () => (
      <Typography type={TYPOGRAPHY_TYPE.body2}>
        There are currently no users added.
        <br />
        To continue, search by user name
        <br />
        or click “Add All” to assign all users to this
        <br />
        artist.
      </Typography>
    ),
    []
  );

  const errorState = useMemo(() => {
    const viewConfig = getErrorViewConfig(
      <Typography type={TYPOGRAPHY_TYPE.body2}>
        An error occurred while loading Members data.
        <br />
        Please try again
      </Typography>
    );

    return <ErrorView config={viewConfig} action={loadLabelUsers} />;
  }, [loadLabelUsers]);

  return (
    <EditModal
      isOpened={isOpened}
      loading={labelUsers.loading}
      processing={processing}
      onClose={closeEditMembers}
      onApply={onApplyChanges}
      title={title}
      addedNodes={userArtistsNodes}
      recentNodes={recentlyAddedArtistsNodes}
      suggestedNodes={suggestedMembersNodes}
      searchValue={search}
      searchPlaceholder="Search Members to Add to the List"
      onSearchChange={onSearchUpdated}
      clearSearch={onCleanSearch}
      onAddAll={onAddAllMembers}
      errorState={errorState}
      emptyState={emptyState}
      listHeader={<MembersListHeader labelName={label?.name || ''} />}
      width={737}
      height={513}
    />
  );
});
