import React from 'react';
import { AdSet } from 'backend-api/models';
import { AdSetsSortField } from 'backend-api/types';
import { bem } from 'utils/bem';
import { getCurrencyFormatted } from 'utils/format';
import { getDaysDiffInclusive } from 'common/transducers';
import { isDateAfter, getFormattedDate } from 'common-v2/utils';

import { LongTableTitle, TableContent } from 'common/components/table';
import { Value } from 'common/components/tables/common';

const classes = bem('ad-sets-table');

export const getAdSetsTableConfig = (
  dateString: string,
  currencyCode?: string
): TableContent<AdSet, AdSetsSortField>[] => [
  {
    title: 'Ad Set Name',
    dataSelector: 'ad-set-name-column-header',
    dataIndex: 'name',
    useSort: true,
    sortField: 'name',
    additionalClassName: classes('long-cell', { down: true }),
    render: record => <LongTableTitle text={record.name} className={classes('ad-set-name')} />,
  },
  {
    title: 'Spend',
    dataIndex: 'budget',
    dataSelector: 'ad-set-budget-column-header',
    useSort: true,
    sortField: 'spend',
    additionalClassName: classes('small-cell', { right: true, down: true }),
    render: record => <Value>{getCurrencyFormatted(record.budgetSpend, record.currency, true)}</Value>,
  },
  {
    title: 'Days',
    subTitle: 'Remaining',
    dataIndex: 'daysLeft',
    dataSelector: 'ad-set-days-column-header',
    useSort: true,
    sortField: 'remainingDays',
    additionalClassName: classes('small-cell'),
    render: record => {
      const currentDate = new Date();
      const isEndDateAfterCurrent = isDateAfter(record.endDate, currentDate);
      const daysLeftInclusive = record.endDate ? getDaysDiffInclusive(record.endDate, currentDate) : 0;
      return <span>{isEndDateAfterCurrent ? daysLeftInclusive : 0}</span>;
    },
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    dataSelector: 'ad-set-start-date-column-header',
    useSort: true,
    sortField: 'startDate',
    additionalClassName: classes('small-cell', { down: true }),
    render: record => <span>{getFormattedDate(dateString)(record.startDate)}</span>,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    dataSelector: 'ad-set-end-date-column-header',
    useSort: true,
    sortField: 'endDate',
    additionalClassName: classes('small-cell', { down: true }),
    render: record => <span>{record.endDate ? getFormattedDate(dateString)(record.endDate) : 'Ongoing'}</span>,
  },
];
