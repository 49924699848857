import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const BEM_CLASS = 'artist-search-item';

export const SArtistSearchItem = styled.div`
  display: flex;
  flex-direction: column;
  color: ${palette.black};
`;
