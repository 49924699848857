import React from 'react';
import { GroupProps } from 'react-select';
import { bem } from 'utils/bem';
import { getBaseCustomSelectProps } from '../../transducers';
import { BaseGroup as BaseGroupType, BaseOption as BaseOptionType } from '../../types';
import { BEM_CLASS, SBaseGroup } from './s-base-group';
import { TYPOGRAPHY_TYPE, Typography } from 'gdb-web-shared-components';

type Props<T extends BaseOptionType, IsMulti extends boolean> = GroupProps<T, IsMulti, BaseGroupType<T>>;

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType, IsMulti extends boolean>({
  children,
  label,
  innerProps,
  data,
  selectProps,
}: Props<T, IsMulti>) => {
  const { isFirst = false, isLast = false } = data;
  const { classNames, dataSelector } = getBaseCustomSelectProps(selectProps);

  return (
    <SBaseGroup
      {...innerProps}
      ref={null}
      className={classNames.group?.root}
      hasSeparator={!label && !isLast}
      isFirst={isFirst}
      isLast={isLast}
      data-selector={`${dataSelector}-group`}
    >
      {label && (
        <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('label', undefined, classNames.group?.text)}>
          {label}
        </Typography>
      )}

      {children}
    </SBaseGroup>
  );
};

export const BaseGroup = React.memo(Root) as typeof Root;
