import { RefObject } from 'react';
import Select from 'react-select/base';
import { BaseOption } from 'common/components/select';
import { SingleValue, MultiValue } from 'react-select';
import { ValueEvent } from '../use-editor-keyboard-interactions';
import { SingleSelectParams } from './types';

const processInputChange = (selectRef: RefObject<Select<BaseOption, boolean>>) => {
  if (!selectRef.current) return;

  selectRef.current.onInputChange('', {
    action: 'input-change',
    prevInputValue: selectRef.current.inputRef?.value || '',
  });
};

const processChange = <T extends SingleValue<BaseOption> | MultiValue<BaseOption>>(
  selectRef: RefObject<Select<BaseOption, boolean>>,
  newValue: T,
  focusedValue: BaseOption
) => {
  if (!selectRef.current) return;

  selectRef.current.onChange(newValue, {
    action: 'select-option',
    option: focusedValue,
  });
};

const handleSingleSelectValueReset = <T>(
  selectRef: RefObject<Select<BaseOption, boolean>>,
  setValue: SingleSelectParams<T>['setValue']
) => {
  if (!selectRef.current?.inputRef?.value) {
    setValue(null);
    selectRef.current?.focus();
  }
  return true;
};

const handleSingleSelectCurrentValue = (selectRef: RefObject<Select<BaseOption, boolean>>) => {
  const focusedValue = selectRef.current?.state.focusedOption;
  if (!focusedValue) return true;

  const newValue = focusedValue;

  processInputChange(selectRef);
  processChange<BaseOption>(selectRef, newValue, focusedValue);

  return true;
};

const handleMultiSelectValueReset = () => false;

const handleMultiSelectCurrentValue = (selectRef: RefObject<Select<BaseOption, boolean>>) => {
  const focusedValue = selectRef.current?.state.focusedOption;
  if (!focusedValue) return true;

  const currentValue = selectRef.current?.state.selectValue || [];
  const newValue = currentValue?.concat(focusedValue);

  processInputChange(selectRef);
  processChange<BaseOption[]>(selectRef, newValue, focusedValue);

  return !focusedValue;
};

export const getSingleSelectValueEventHandler = <T>(
  event: ValueEvent,
  selectRef: RefObject<Select<BaseOption, boolean>>,
  setValue: SingleSelectParams<T>['setValue']
) => {
  switch (event) {
    case ValueEvent.RESET:
      return handleSingleSelectValueReset(selectRef, setValue);
    case ValueEvent.SELECT_CURRENT:
      return handleSingleSelectCurrentValue(selectRef);
  }
};

export const getMultiSelectValueEventHandler = (
  event: ValueEvent,
  selectRef: RefObject<Select<BaseOption, boolean>>
) => {
  switch (event) {
    case ValueEvent.RESET:
      return handleMultiSelectValueReset();
    case ValueEvent.SELECT_CURRENT:
      return handleMultiSelectCurrentValue(selectRef);
  }
};
