import Axios, { AxiosResponse } from 'axios';
import { getAuthUrlWithRedirectParam } from 'common/transducers';

export interface TokenResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

export class TokenService {
  private tokenRefreshing;
  private url?: string;
  private validator: RegExp;

  constructor(validator: RegExp, url?: string) {
    this.url = url;
    this.validator = validator;
  }

  public login(redirectTo?: string, onError?: () => void) {
    if (this.url && this.validator.test(this.url)) {
      const url = getAuthUrlWithRedirectParam(this.url, redirectTo);
      window.location.replace(url);
    } else if (onError) {
      onError();
    }
  }

  public logout(onError?: () => void) {
    if (this.url && this.validator.test(this.url)) {
      window.location.replace(`${this.url}/logout`);
    } else if (onError) {
      onError();
    }
  }

  public getToken() {
    return Axios.get<TokenResponse>(`${this.url}/token`, {
      withCredentials: true,
    });
  }

  public async refreshToken(): Promise<TokenResponse> {
    if (this.tokenRefreshing) {
      const token = await this.tokenRefreshing;

      return Promise.resolve(token);
    }

    this.tokenRefreshing = Axios.post<TokenResponse>(`${this.url}/token/refresh`, null, {
      withCredentials: true,
    })
      .then(({ data }: AxiosResponse<TokenResponse>) => Promise.resolve(data))
      .catch(Promise.reject)
      .finally(() => {
        this.tokenRefreshing = null;
      });

    return this.tokenRefreshing;
  }
}
