import React, { useMemo } from 'react';
import { BEM_CLASS, SBudgetMainInfo } from './s-budget-main-info';
import { Value } from 'project/components/project-details/components/budget-widget/components/value';
import {
  Popover,
  Tooltip as BudgetInfoTooltip,
} from 'project/components/project-details/components/budget-widget/components/budget-details-grid/components/budget-details-grid-item/components';
import { getTextWithCurrencyCode } from 'utils/format';
import { Alignment } from 'project/components/project-details/components/budget-widget/components/value/s-value';
import { bem } from 'utils/bem';
import { colorPalette } from 'app/theme/colors';
import { DEFAULT_CURRENCY_CODE } from 'common/constants';
import { getPercentage, getRelativeProgressBarWidth } from 'common/transducers';
import { formatBudgetInfoPercentage, formatBudgetInfoShortenCurrency } from '../../transducers';

interface Props {
  totalBudget?: number;
  allocation?: number;
  currency?: string;
  className?: string;
}
const classes = bem(BEM_CLASS);

export const BudgetMainInfo = React.memo(
  ({ totalBudget, allocation, currency = DEFAULT_CURRENCY_CODE, className }: Props) => {
    const tooltip = useMemo(
      () => (
        <BudgetInfoTooltip allocation={allocation} budget={totalBudget} accentColor={colorPalette.wildStrawberry} />
      ),
      [allocation, totalBudget]
    );

    const progressBarWidth = useMemo(() => getRelativeProgressBarWidth(getPercentage(allocation, totalBudget)), [
      allocation,
      totalBudget,
    ]);

    return (
      <SBudgetMainInfo className={className} progressBarWidth={progressBarWidth}>
        <div className={classes('allocated-and-total-container')}>
          <Value
            title={getTextWithCurrencyCode('Total', currency)}
            value={formatBudgetInfoShortenCurrency(currency, totalBudget)}
            dataSelector="budget-widget-total"
          />
          <Value
            title={getTextWithCurrencyCode('Allocated', currency)}
            className={classes('allocated')}
            value={formatBudgetInfoShortenCurrency(currency, allocation)}
            dataSelector="budget-widget-allocation"
          />
        </div>
        <div className={classes('progress-and-total-container')}>
          <Popover className={classes('progress-bar')} content={tooltip}>
            <div className={classes('filled-progress-bar')} data-selector="progress-bar" />
          </Popover>
          <Value
            title="Utilization"
            value={formatBudgetInfoPercentage(allocation, totalBudget)}
            alignment={Alignment.RIGHT}
            className={classes('utilization')}
            dataSelector="budget-widget-utilization"
          />
        </div>
      </SBudgetMainInfo>
    );
  }
);
