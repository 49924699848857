import React from 'react';
import { bem } from 'utils/bem';
import { LoaderItem } from '../loader-item';
import { LoaderCategory } from '../loader-category';
import { SArtistLoader } from './s-artist-loader';

const classes = bem('release-loader');

interface Props {
  className?: string;
}

export const ArtistLoader = ({ className }: Props) => {
  return (
    <SArtistLoader className={className}>
      <LoaderCategory />
      <div className={classes('items-container')}>
        <LoaderItem />
        <LoaderItem />
        <LoaderItem />
      </div>
    </SArtistLoader>
  );
};
