import React from 'react';
import { bem } from 'utils/bem';
import { TeamUser } from 'backend-api/models';
import { Icon } from 'common/components/icon';
import { SSelectedUserItem } from './s-selected-user-item';
import { UserAvatar } from 'common/components/user-avatar';

interface Props {
  user: TeamUser;
  onRemove(): void;
  isEditor: boolean;
}

const classes = bem('selected-artist-playlist-item');

export const SelectedUserItem = React.memo(({ user, onRemove, isEditor }: Props) => {
  return (
    <SSelectedUserItem data-selector="selected-user-item">
      <UserAvatar name={user.name} size={30} className={classes('avatar')} isEditor={isEditor} />
      <div data-selector="selected-user-item-name" className={classes('info-container')}>
        {user.name}
      </div>
      <Icon
        dataSelector="remove-selected-user-item-button"
        onClick={onRemove}
        name="removal"
        className={classes('icon')}
        size="general"
        color="pink"
      />
    </SSelectedUserItem>
  );
});
