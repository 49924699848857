import React, { useMemo } from 'react';
import { BEM_CLASS, SProjectCard } from './s-project-card';
import { ArtistRosterProject } from 'backend-api/models';
import { Title } from 'common/components/project-header/components';
import { bem } from 'utils/bem';
import { Badge, BadgeType } from 'common/components/badge';
import { useDatePeriodFormatted } from 'hooks';
import { Icon } from 'common/components/icon';
import { paths } from 'app/routing/paths';
import { BudgetMainInfo } from 'project/components/project-details/components/budget-widget/components';
import { MediaPlanSidebar } from '../media-plan-sidebar';
import { Link } from 'react-router-dom';

interface Props {
  project: ArtistRosterProject;
}

const classes = bem(BEM_CLASS);

export const ProjectCard = React.memo(({ project }: Props) => {
  const formattedSchedule = useDatePeriodFormatted([project.startDate, project.endDate]);
  const lastBadgeElement = useMemo(
    () =>
      formattedSchedule ? (
        <Badge
          type={BadgeType.Light}
          className={classes('schedule-badge')}
          text={formattedSchedule}
          dataSelector="schedule-badge"
        />
      ) : null,
    [formattedSchedule]
  );

  return (
    <SProjectCard>
      <div className={classes('project-container')}>
        <div className={classes('title-container')}>
          <Title
            text={project.name}
            project={project}
            dataSelector="project-title"
            className={classes('title')}
            lastBadgeElement={lastBadgeElement}
          />
          <Link to={paths.project(project.id)} className={classes('details-link')}>
            Details <Icon name="arrow-right" color="blue" className={classes('details-arrow-icon')} />
          </Link>
        </div>
        <BudgetMainInfo
          totalBudget={project.totalBudget}
          allocation={project.allocatedBudget}
          className={classes('budget-section')}
          currency={project.currency}
        />
      </div>
      <MediaPlanSidebar projectId={project.id} mediaPlanList={project.mediaPlans} />
    </SProjectCard>
  );
});
