import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'media-plans-dropdown';

export const SMediaPlansDropdown = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .${BEM_CLASS} {
    &__trigger {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-left: 10px;
    }
    &__trigger-outlined {
      width: 22px;
      height: 22px;
      border-radius: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      border: 1px solid ${transparentize(0.5, colorPalette.snuff)};
      background-color: white;
      &:hover {
        background: ${transparentize(0.9, colorPalette.azureRadiance)};
      }

      &--menuOpen {
        background: ${colorPalette.azureRadiance};

        &:hover {
          background: ${colorPalette.azureRadiance};
        }
      }
    }
    &__icon {
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;

      &--menuOpen {
        color: white;
      }
    }
  }
`;
