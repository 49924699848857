import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'group-heading-with-reset';

export const SGroupHeadingWithReset = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__content {
      ${elipsizeText};
    }
    &__clear-all {
      margin-left: 30px;
      flex-shrink: 0;
    }
  }
`;
