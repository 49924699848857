import { getLabelMock } from '../label/__mocks__';
import { CurrentUser, CurrentUserInfo, ReferenceUser, TeamUser, UserRole } from './index';

const getDefaultsCurrentUserInfo = (): CurrentUserInfo => ({
  id: 0,
  email: 'user@user.com',
  externalId: 'auth0',
  name: 'user1',
  phone: '12345678901',
  dateTimeFormat: 1,
  isAdmin: true,
});

export const getCurrentUserInfoMock = (p?: Partial<CurrentUserInfo>): CurrentUserInfo => ({
  ...getDefaultsCurrentUserInfo(),
  ...p,
});

const getDefaultsCurrentUser = (): CurrentUser => ({
  currentUser: getCurrentUserInfoMock(),
  projectsCount: 0,
});

export const getCurrentUserMock = (p?: Partial<CurrentUser>): CurrentUser => ({
  ...getDefaultsCurrentUser(),
  ...p,
});

const getDefaultsReferenceUser = (): ReferenceUser => ({
  email: 'dev-marketing-user-1@example.com',
  id: 'auth0|5cf0091d6c91d310fe6fcdc6',
});

export const getReferenceUserMock = (p?: Partial<ReferenceUser>): ReferenceUser => ({
  ...getDefaultsReferenceUser(),
  ...p,
});

const getDefaultsUserRole = (): UserRole => ({
  id: 0,
  name: 'name',
  categoryIds: [1, 2, 3],
});

export const getUserRoleMock = (p?: Partial<UserRole>): UserRole => ({
  ...getDefaultsUserRole(),
  ...p,
});

const getDefaultsTeamUser = (): TeamUser => ({
  email: 'email@email.com',
  labels: [getLabelMock()],
  id: 1,
  name: 'User',
  phone: '+123456789032',
  roles: [
    {
      id: 1,
      name: 'Editor',
      categoryIds: [],
    },
  ],
});

export const getTeamUserMock = (p?: Partial<TeamUser>): TeamUser => ({
  ...getDefaultsTeamUser(),
  ...p,
});
