import styled from 'styled-components';

export const BEM_CLASS = 'recent-search-item';

export const SRecentSearchItem = styled.div`
  width: 100%;

  .${BEM_CLASS} {
    &__title {
      margin-bottom: 2.5px;
    }

    &__info {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 15px;
    }
  }
`;
