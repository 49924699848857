import styled from 'styled-components';

interface SColoredCircleProps {
  readonly color: string;
  readonly opacity: number;
}

export const SColoredCircle = styled.div<SColoredCircleProps>`
  background-color: ${props => props.color};
  opacity: ${props => props.opacity};
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  align-self: center;
`;
