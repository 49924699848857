import React from 'react';
import { SInputDataTableLoading } from './s-input-data-campaign-table-loader';
import { ContentLoader } from 'common/components/loaders';

export const InputDataTableLoading = React.memo(() => {
  return (
    <SInputDataTableLoading>
      <ContentLoader />
    </SInputDataTableLoading>
  );
});
