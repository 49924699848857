import { useCallback, useRef } from 'react';
import { useEditorKeyboardInteractions, ValueEvent } from '../use-editor-keyboard-interactions';
import Select from 'react-select/base';
import { BaseOption } from 'common/components/select';
import { Nullable } from 'backend-api/models';
import { MultiSelectParams, SingleSelectParams, SelectResult } from './types';
import { getSingleSelectValueEventHandler, getMultiSelectValueEventHandler } from './transducers';

export const useSelectEditorKeyboardInteractions = <
  IsMulti extends boolean = false,
  T = Nullable<string | number> | undefined
>({
  api,
  value,
  setValue,
  isMulti,
}: MultiSelectParams<T> | SingleSelectParams<T>): SelectResult<IsMulti> => {
  const selectRef = useRef<Select<BaseOption, IsMulti>>(null);

  const onValueEvent = useCallback(
    (event: ValueEvent) => {
      return isMulti
        ? getMultiSelectValueEventHandler(event, selectRef)
        : getSingleSelectValueEventHandler<T>(event, selectRef, setValue);
    },
    [setValue, isMulti]
  );
  const { onKeyDown } = useEditorKeyboardInteractions({
    api,
    value,
    onValueEvent,
    shouldCloseOnEnter: !isMulti,
  });

  return { onKeyDown, selectRef };
};
