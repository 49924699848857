import { LinkfireSearch } from 'backend-api/models';
import { HTTPS_LINK_PREFIX } from 'common/constants';
import { LinkfireSearchOption } from '../components/linkfire-search/types';

export const mapLinkfireSearchToBaseOption = (linkfireLinksSearch: LinkfireSearch[]): LinkfireSearchOption[] =>
  linkfireLinksSearch.map(item => ({
    ...item,
    id: item.linkId,
    name: item.linkUrl,
    label: item.linkUrl,
    isFixed: item.deletable === false,
    modelId: item.id,
  }));

export const mapSearchOptionToLinkfire = (searchOptions: LinkfireSearchOption[]): LinkfireSearch[] =>
  searchOptions.map(option => ({
    id: option.modelId,
    linkId: option.linkId,
    linkUrl: option.linkUrl,
    deletable: option.isFixed,
  }));

export const isQueryLinkExists = (searchOptions: LinkfireSearchOption[], query: string) =>
  searchOptions
    .map(option => option.linkUrl.toLowerCase())
    .includes(query.replace(HTTPS_LINK_PREFIX, '').toLowerCase());
