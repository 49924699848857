import React from 'react';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { NOT_AVAILABLE } from 'common-v2/constants';

interface Props {
  mediaPlanName?: string;
}

export const MediaPlanName = React.memo(({ mediaPlanName = NOT_AVAILABLE }: Props) => (
  <Typography type={TYPOGRAPHY_TYPE.heading2} data-selector="media-plan-name">
    {mediaPlanName}
  </Typography>
));
