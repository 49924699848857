import styled from 'styled-components';
import { loader } from 'app/styles/z-indexes';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'loader';

const SIZE = 20;

interface SLoaderProps {
  readonly topOffset: number;
  readonly size?: number;
  readonly color?: string;
}

export const SLoader = styled.div<SLoaderProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: ${loader};
  width: ${props => props.size || SIZE}px;
  height: ${props => props.size || SIZE}px;
  transform: translate(-50%, -50%);
  margin-top: ${props => props.topOffset}px;
  color: ${props => props.color || colorPalette.wildStrawberry};

  & > svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
`;
