import React from 'react';
import { STooltip } from './s-tooltip';
import { TOOLTIP_Z_INDEX } from 'common-v2/constants';
import { TippyProps } from '@tippyjs/react';

export interface Props extends Omit<TippyProps, 'content'> {
  content?: React.ReactNode;
  zIndex?: number;
  invertedColors?: boolean;
}

export const Tooltip = ({
  delay = 0,
  duration = 0,
  content,
  children,
  placement = 'bottom',
  disabled,
  zIndex,
  invertedColors,
  ...rest
}: React.PropsWithChildren<Props>) => {
  const element = React.isValidElement(children) ? children : <span>{children}</span>;

  return (
    <STooltip
      invertedColors={invertedColors}
      content={content}
      placement={placement}
      delay={delay}
      duration={duration}
      arrow
      disabled={disabled}
      zIndex={zIndex ?? TOOLTIP_Z_INDEX}
      {...rest}
    >
      {element}
    </STooltip>
  );
};
