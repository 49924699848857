import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { GlobalLayout } from 'app/components/global-layout';
import { globalLabelSelector } from 'common/selectors';
import { ErrorViewStates } from 'common-v2/components';
import { Loading } from 'common-v2/types';
import { getLabelCounters } from 'label-permissions/actions';
import { artistsSelector, countersSelector, teamMembersSelector } from 'label-permissions/selectors';
import { LabelPermissionsTab } from 'label-permissions/types';
import { LabelPermissions, PermissionTabs } from '../../components';
import { MembersTableContainer } from '../members-table-container';
import { ArtistsTableContainer } from '../artists-table-container';
import { SEARCH_INPUT_PLACEHOLDER_MAP } from './constants';
import { BEM_CLASS, SLabelPermissionsContainer } from './s-label-permissions-container';
import { Head, HeadStaticTitles } from 'app/components/head';

const classes = bem(BEM_CLASS);

export const LabelPermissionsContainer = React.memo(() => {
  const [activeTab, setActiveTab] = useState<LabelPermissionsTab>(LabelPermissionsTab.MEMBERS);
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();

  const label = useSelector(globalLabelSelector);

  const { data: counters } = useSelector(countersSelector);
  const { loading: teamMembersLoading } = useSelector(teamMembersSelector);
  const { loading: artistsLoading } = useSelector(artistsSelector);

  const isSearchDisabled = teamMembersLoading === Loading.Started || artistsLoading === Loading.Started;

  const content = useMemo(() => {
    switch (activeTab) {
      case LabelPermissionsTab.MEMBERS:
        return <MembersTableContainer search={search} label={label} />;
      case LabelPermissionsTab.ARTISTS:
        return <ArtistsTableContainer search={search} label={label} />;
    }
  }, [activeTab, label, search]);

  const onTabChange = useCallback((activeTab: LabelPermissionsTab) => {
    setActiveTab(prevActiveTab => {
      if (activeTab !== prevActiveTab) {
        setSearch('');
      }

      return activeTab;
    });
  }, []);

  const onSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  const clearSearch = useCallback(() => {
    setSearch('');
  }, []);

  useEffect(() => {
    if (!label?.id) return;

    setSearch('');
    dispatch(getLabelCounters.request(label.id));
  }, [dispatch, label?.id]);

  return (
    <>
      <Head title={HeadStaticTitles.LABEL_PERMISSIONS} />
      <SLabelPermissionsContainer>
        <GlobalLayout className={classes('manhattan-background')} errorConfig={{ type: ErrorViewStates.none }}>
          <LabelPermissions
            labelName={label?.name}
            placeholder={SEARCH_INPUT_PLACEHOLDER_MAP[activeTab]}
            search={search}
            onSearchChange={onSearchChange}
            clearSearch={clearSearch}
            isSearchDisabled={isSearchDisabled}
            tabs={
              <PermissionTabs
                className={classes('tabs')}
                activeTab={activeTab}
                onChange={onTabChange}
                counters={counters}
              />
            }
          >
            {content}
          </LabelPermissions>
        </GlobalLayout>
      </SLabelPermissionsContainer>
    </>
  );
});
