import { Button, BUTTON_SIZE, BUTTON_TYPE, THEME } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { SListHeader, BEM_CLASS } from './s-list-header';

interface ListHeaderProps {
  content: JSX.Element;
  onAssignAll(): void;
  isDisabled?: boolean;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ListHeader = React.memo(({ content, onAssignAll, isDisabled, className }: ListHeaderProps) => {
  return (
    <SListHeader className={className}>
      <div className={classes('container')}>
        {content}
        <Button
          size={BUTTON_SIZE.small}
          type={BUTTON_TYPE.tertiary}
          theme={THEME.light}
          disabled={isDisabled}
          className={classes('assign-all')}
          onClick={onAssignAll}
          data-selector="edit-modal-add-all-button"
        >
          ADD ALL
        </Button>
      </div>
      <div className={classes('separator')} />
    </SListHeader>
  );
});
