import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bem } from 'utils/bem';
import { Button } from 'common/components/button';
import { Input } from 'common/components/form/input';
import { Icon } from 'common/components/icon';
import { DatePicker, DatePickerMode } from 'common/components/form/date-picker';
import { SFilters, BEM_CLASS } from './s-filters';
import { useProjectsFilters, useActiveTab } from 'artist/hooks';
import { OptionalPeriod, Period } from 'common/types';
import { exportProjects } from 'artist/actions';
import { ProjectFilterParams } from 'backend-api/types';
import { debounce } from 'lodash';
import { SEARCH_DEBOUNCE_TIME } from 'common/constants';

interface FiltersProps {
  artistId: string;
  artistName?: string;
  filterParams: ProjectFilterParams;
  isExportAllowed: boolean;
}

const classes = bem(BEM_CLASS);

export const Filters = React.memo(
  ({ artistId, artistName = 'Artist', filterParams, isExportAllowed }: FiltersProps) => {
    const dispatch = useDispatch();
    const [filters, filtersDispatcher] = useProjectsFilters(artistId);
    const [activeTab] = useActiveTab(artistId);

    const period: OptionalPeriod = useMemo(() => [filters.earliestStartDate, filters.latestEndDate], [
      filters.earliestStartDate,
      filters.latestEndDate,
    ]);

    const debouncedSearch = useMemo(
      () => debounce((searchQuery: string) => filtersDispatcher.setSearch(searchQuery), SEARCH_DEBOUNCE_TIME),
      [filtersDispatcher]
    );

    const onSearchChange = useCallback((searchQuery: string) => debouncedSearch(searchQuery), [debouncedSearch]);

    const onPeriodChange = useCallback(
      ([startDate, endDate]: Period) => {
        filtersDispatcher.setFilters({
          earliestStartDate: startDate,
          latestEndDate: endDate,
        });
      },
      [filtersDispatcher]
    );

    const onExport = useCallback(() => {
      if (isExportAllowed) {
        dispatch(exportProjects.request({ id: artistId, name: artistName, status: activeTab, params: filterParams }));
      }
    }, [isExportAllowed, dispatch, artistId, artistName, activeTab, filterParams]);

    return (
      <SFilters>
        <div className={classes('filter', 'growed')}>
          <Input
            dataSelector="artists-search-filter"
            iconLeft="search"
            clearable
            onChange={onSearchChange}
            initialValue={filters.search}
            className={classes('search')}
            placeholder="Search projects"
          />
        </div>
        <div className={classes('filter', 'date-picker')}>
          <DatePicker
            bgStyle="white"
            mode={DatePickerMode.RANGE}
            value={period}
            onChange={onPeriodChange}
            dataSelector="artists-calendar-input"
          />
        </div>
        <Button
          variant="secondary-cta"
          onClick={onExport}
          disabled={!isExportAllowed}
          dataSelector="artists-export-button"
        >
          <Icon name="export" />
          Export
        </Button>
      </SFilters>
    );
  }
);
