import React, { FormEvent, useCallback, useRef, useEffect } from 'react';
import { BEM_CLASS, SLimitedLengthTextField } from './s-limited-length-text-field';
import { bem } from 'utils/bem';
import { BgStyle } from 'common/styles/utils';
import { FieldComposition } from './types';

interface Props {
  value?: string;
  placeholder?: string;
  onClick?(event): void;
  onChange(value: string): void;
  maxLength: number;
  bgStyle?: BgStyle;
  inputClassName?: string;
  className?: string;
  autoFocus?: boolean;
  focusDelay?: number;
  composition?: FieldComposition;
}

export const classes = bem(BEM_CLASS);

export const LimitedLengthTextField = React.memo(
  ({
    bgStyle = 'white',
    className,
    inputClassName,
    placeholder,
    maxLength,
    value = '',
    autoFocus = false,
    focusDelay = 0,
    onChange,
    onClick,
    composition = FieldComposition.VERTICAL,
  }: Props) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const onInputChange = useCallback(
      (event: FormEvent<HTMLTextAreaElement>) => {
        onChange(event.currentTarget.value);
      },
      [onChange]
    );

    const onContainerClick = useCallback(
      event => {
        inputRef.current?.focus();
        onClick?.(event);
      },
      [inputRef, onClick]
    );

    useEffect(() => {
      const textareaElement = inputRef.current;

      if (!textareaElement || !autoFocus) return;

      setTimeout(() => {
        textareaElement.setSelectionRange(value.length, value.length);
        textareaElement.focus();
      }, focusDelay);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autoFocus]);

    return (
      <SLimitedLengthTextField
        composition={composition}
        bgStyle={bgStyle}
        className={className}
        onClick={onContainerClick}
      >
        <textarea
          ref={inputRef}
          className={classes('input', [], inputClassName)}
          placeholder={placeholder}
          onChange={onInputChange}
          maxLength={maxLength}
          value={value}
        />
        <div className={classes('limit')}>
          <span className={classes('max-text', { hidden: value?.length < maxLength })}>MAX</span>
          <span>
            {value?.length}/{maxLength}
          </span>
        </div>
      </SLimitedLengthTextField>
    );
  }
);
