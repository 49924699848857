import { PayloadActionCreator } from 'typesafe-actions';
import { SimpleOption } from 'common/constants';
import { TargetType } from 'backend-api/models';
import { searchArtist, searchPlaylists } from './actions';

export const MODAL_NAME = 'CONFIRM_DELETE';

interface TargetOption extends SimpleOption {
  id: number;
  name: TargetType;
}

export const TARGET_OPTIONS: TargetOption[] = [
  {
    id: 1,
    name: 'Artist',
  },
  {
    id: 2,
    name: 'Playlist',
  },
];

export enum ArtistModalType {
  Artist = 'Artist',
  Playlist = 'Playlist',
}

export interface ArtistModalTypeInfo {
  title: string;
  searchAction: PayloadActionCreator<string, string>;
  searchPlaceholder: string;
  type: ArtistModalType;
  limit: number;
}

export type ArtistModalTypeInfos = {
  [type in ArtistModalType]: ArtistModalTypeInfo;
};

export const PRIMARY_ARTISTS_LIMIT = 3;
export const FEATURED_ARTISTS_LIMIT = 20;
export const PLAYLISTS_LIMIT = 20;

export const ARTIST_MODAL_TYPES: ArtistModalTypeInfos = {
  Artist: {
    title: 'Add an Artist',
    searchPlaceholder: 'Search artist',
    searchAction: searchArtist.request,
    type: ArtistModalType.Artist,
    limit: PRIMARY_ARTISTS_LIMIT + FEATURED_ARTISTS_LIMIT,
  },
  Playlist: {
    title: 'Add a Playlist',
    searchPlaceholder: 'Search by playlist name or author',
    searchAction: searchPlaylists.request,
    type: ArtistModalType.Playlist,
    limit: PLAYLISTS_LIMIT,
  },
};

export const BUDGET_WIDGET_SECOND_ROW_LEFT_OFFSET = 30;
export const BUDGET_WIDGET_FIRST_ROW_PERCENT_WIDTH = 37;
export const BUDGET_WIDGET_SECOND_ROW_PERCENT_WIDTH = 63;
