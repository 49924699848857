import styled from 'styled-components';
import { Body } from 'common/components/typography';
import { colorPalette } from 'app/theme/colors';
import { ActionRenderer } from '../../action-renderer';

export const SCurrencyRenderer = styled.div`
  width: 100%;
  height: 100%;
  color: black;
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: end;
`;

export const SPlaceholder = styled(Body)`
  color: ${colorPalette.wildBlueYonder};
`;

export const SBudgetActionRenderer = styled(ActionRenderer)`
  justify-content: end;
`;
