import * as t from 'io-ts';
import { either } from 'fp-ts/lib/Either';
import { UUID } from 'io-ts-types/lib/UUID';
import {
  parseDateFromISOString,
  isValidDate,
  convertDateToUTC,
  convertDateToISOString,
  getFormattedDate,
} from 'common-v2/utils';

export const Optional = <C extends t.Mixed>(codec: C) => {
  return new t.Type<t.TypeOf<typeof codec> | undefined, t.TypeOf<typeof codec> | undefined, unknown>(
    'Optional',
    (u): u is t.TypeOf<typeof codec> | undefined => codec.is(u) || u === undefined,
    (u, c) => {
      if (u === null) return t.success(undefined);

      return t.union([codec, t.undefined]).validate(u, c);
    },
    t.identity
  );
};

export const Nullable = <C extends t.Mixed>(codec: C) => t.union([codec, t.null]);
export type Nullable<T> = T | null;
export const OptionalNullable = <C extends t.Mixed>(codec: C) => t.union([codec, t.undefined, t.null]);

export const IdType = t.number;
export type Id = number;

export const NonEmptyString = t.string;
export type NonEmptyString = string;

export const FormattedDateFromISOString = new t.Type(
  'DateTimeFromISOString',
  (u): u is Date => u instanceof Date,
  (u, c) =>
    either.chain(t.string.validate(u, c), s => {
      const parsedDate = parseDateFromISOString(s);
      return isValidDate(parsedDate) ? t.success(parsedDate) : t.failure(u, c);
    }),
  d => getFormattedDate('yyyy-MM-dd')(d)
);

export type FormattedDate = t.TypeOf<typeof FormattedDateFromISOString>;

export const DateTimeFromISOString = new t.Type(
  'DateTimeFromISOString',
  (u): u is Date => u instanceof Date,
  (u, c) =>
    either.chain(t.string.validate(u, c), s => {
      const parsedDate = parseDateFromISOString(s);
      const utcDate = convertDateToUTC(parsedDate);
      return isValidDate(utcDate) ? t.success(utcDate) : t.failure(u, c);
    }),
  d => convertDateToISOString(d)
);

export type DateTime = t.TypeOf<typeof DateTimeFromISOString>;

export type Optional<T> = T | undefined;

export const IdNameField = {
  id: IdType,
  name: NonEmptyString,
};

export type IdNameField = {
  id: Id;
  name: NonEmptyString;
};

export const UuidNameField = {
  uuid: UUID,
  name: NonEmptyString,
};

export type UuidNameField = {
  uuid: UUID;
  name: NonEmptyString;
};

export const ResponseModel = <P extends t.Props>(props: P, name?: string): t.TypeC<P> => {
  return t.type(props, name);
};

export const TerritorySchema = ResponseModel(
  {
    ...IdNameField,
    parent_id: Optional(t.number),
  },
  'Territory'
);

export type Territory = t.TypeOf<typeof TerritorySchema>;

export const PaginationResponseModel = <C extends t.Mixed>(codec: C, name?: string) => {
  return ResponseModel(
    {
      items: t.array(codec),
      limit: t.number,
      offset: t.number,
      total: t.number,
    },
    name
  );
};

export const CurrencySchema = ResponseModel(
  {
    code: t.string,
    id: IdType,
  },
  'Currency'
);

export type Currency = t.TypeOf<typeof CurrencySchema>;

export const CountryCodeSchema = ResponseModel(
  {
    code: t.string,
    name: t.string,
  },
  'CountryCode'
);

export type CountryCode = t.TypeOf<typeof CountryCodeSchema>;

export const ImageSchema = ResponseModel(
  {
    height: Optional(t.number),
    width: Optional(t.number),
    url: t.string,
  },
  'RecordImage'
);

export type Image = t.TypeOf<typeof ImageSchema>;

export const CampaignCategorySchema = ResponseModel({ ...IdNameField }, 'CampaignCategory');

export type CampaignCategory = t.TypeOf<typeof CampaignCategorySchema>;

export const CampaignTypeAndCategorySchema = ResponseModel(
  { ...IdNameField, category: CampaignCategorySchema },
  'CampaignTypeAndCategory'
);

export type CampaignTypeAndCategory = t.TypeOf<typeof CampaignTypeAndCategorySchema>;
