import { isPlaylists } from 'backend-api/guards';
import { Artist, Optional, Targets } from 'backend-api/models';
import { getPrimaryTargets, isArtistLocked } from 'common-v2/transducers';
import { getFormattedShortDate, isSameDay, substractDaysFromDate } from 'common-v2/utils';

export const getUpdatedDate = (nowDate: Date, dateFormat: string, editDate?: Date): string | null => {
  if (!editDate) return null;

  const { date: shortDate, time } = getFormattedShortDate(dateFormat)(editDate);
  let date = shortDate;

  const isToday = isSameDay(editDate, nowDate);
  const isYesterday = isSameDay(editDate, substractDaysFromDate(nowDate, 1));

  if (isToday) {
    date = 'Today';
  } else if (isYesterday) {
    date = 'Yesterday';
  }

  return date && time ? `${date} • ${time}` : null;
};

export const getCardArtist = (targets: Targets): Optional<Artist> => {
  const primaryTargets = getPrimaryTargets(targets.items);

  if (isPlaylists(primaryTargets)) return;

  return primaryTargets.find(isArtistLocked) || primaryTargets[0];
};
