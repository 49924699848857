import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectFilterParams } from 'backend-api/types';
import { ProjectsTab } from 'artist/types';
import { artistSelector, metadataSelector, projectsSelector, unassignedProjectsSelector } from 'artist/selectors';
import { hidePermissionsNotification } from 'artist/actions';
import { useActiveTab, useProjectsFilters } from 'artist/hooks';
import { Filters, Header, Page, Table } from 'artist/components';
import { getProjectsTableConfig } from 'artist/components/table/table-config';
import { getTotalProjectsCount } from 'artist/transducers';
import { ArtistTeamsContainer } from './artist-teams-container';
import { dateFormatSelector } from 'common/selectors';

interface HeaderProps {
  artistId: string;
  filterParams: ProjectFilterParams;
  showTeamEntry?: boolean;
  showTabs?: boolean;
}

export const ArtistHeaderContainer = React.memo(
  ({ artistId, filterParams, showTeamEntry = false, showTabs = false }: HeaderProps) => {
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useActiveTab(artistId);
    const [filters] = useProjectsFilters(artistId);
    const artist = useSelector(artistSelector);
    const metadata = useSelector(metadataSelector);
    const projects = useSelector(projectsSelector);
    const unassignedProjects = useSelector(unassignedProjectsSelector);
    const dateFormat = useSelector(dateFormatSelector);

    const tableBodyContent = useMemo(
      () => (activeTab === ProjectsTab.Unassigned ? unassignedProjects.data : projects.data),
      [activeTab, projects.data, unassignedProjects.data]
    );

    const tableLoading = useMemo(
      () => (activeTab === ProjectsTab.Unassigned ? unassignedProjects.loading : projects.loading),
      [activeTab, projects.loading, unassignedProjects.loading]
    );

    const filtersComponent = useMemo(
      () => (
        <Filters
          artistId={artistId}
          artistName={artist.data?.info.name}
          filterParams={filterParams}
          isExportAllowed={tableBodyContent.length > 0 && activeTab !== ProjectsTab.Unassigned}
        />
      ),
      [artist.data?.info.name, artistId, filterParams, tableBodyContent, activeTab]
    );

    const tableComponent = useCallback(
      (activeTab: ProjectsTab) => (
        <Table
          artistId={artistId}
          loading={tableLoading}
          query={filters.search}
          headerContent={getProjectsTableConfig(artistId, dateFormat)}
          bodyContent={tableBodyContent}
          totalCount={getTotalProjectsCount(activeTab, metadata?.counts)}
        />
      ),
      [artistId, filters.search, metadata?.counts, tableBodyContent, dateFormat, tableLoading]
    );

    const tabs = useMemo(
      () => [
        {
          title: ProjectsTab.InProgress,
          content: <Page filters={filtersComponent} content={tableComponent(ProjectsTab.InProgress)} />,
        },
        {
          title: ProjectsTab.Upcoming,
          content: <Page filters={filtersComponent} content={tableComponent(ProjectsTab.Upcoming)} />,
        },
        {
          title: ProjectsTab.Completed,
          content: <Page filters={filtersComponent} content={tableComponent(ProjectsTab.Completed)} />,
        },
        {
          title: ProjectsTab.Unassigned,
          content: <Page filters={filtersComponent} content={tableComponent(ProjectsTab.Unassigned)} />,
        },
      ],
      [filtersComponent, tableComponent]
    );

    const onTabChange = useCallback(
      (activeTab: string) => {
        dispatch(hidePermissionsNotification());
        setActiveTab(activeTab);
      },
      [dispatch, setActiveTab]
    );

    return (
      <Header
        artist={artist}
        tabs={showTabs ? tabs : undefined}
        activeTab={activeTab}
        counts={metadata?.counts}
        onTabChange={onTabChange}
        teamEntry={showTeamEntry ? <ArtistTeamsContainer artistId={artistId} filterParams={filterParams} /> : null}
      />
    );
  }
);
