import { createSelector } from 'reselect';
import { ProjectState } from './reducer';

export const projectStateSelector = state => state.projectV2 as ProjectState;

export const errorViewTypeSelector = createSelector(projectStateSelector, state => state.errorViewType);

export const projectDetailsSelector = createSelector(projectStateSelector, state => state.details);

export const shareModalSelector = createSelector(projectStateSelector, state => state.shareModal);

export const sharingInfoSelector = createSelector(shareModalSelector, state => state.content);

export const isEditingSelector = createSelector(projectStateSelector, state => state.isEditing);

export const editArtistsModalSelector = createSelector(projectStateSelector, state => state.editArtistsModal);
