import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'multiple-team-entry';

export const SMultipleTeamEntry = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__label {
      margin-right: 20px;
    }

    &__dropdown {
      position: absolute;
      z-index: 1;
      top: calc(100% + 5px);
      right: 0;
    }

    &__scroller {
      margin: 15px 0;
    }

    &__divider {
      width: calc(100% - 40px);
      height: 1px;
      margin: 12px 20px 19px;
      background-color: ${colorPalette.lavenderGray};
    }

    &__create-team-label {
      display: block;
      padding: 0 20px;
      margin-bottom: 5px;
    }
  }
`;
