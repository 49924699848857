import React, { useCallback, useMemo } from 'react';
import { Popper } from 'common/components/popper';
import { Icon, Button, THEME, BUTTON_TYPE, BUTTON_SIZE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { Popup } from 'media-plan-v2/components/media-plans-dropdown/components';
import { LoadingState } from 'common/types';
import { MediaPlanName } from '../media-plan-name';
import { Id, MediaPlanListItem } from 'backend-api/models';
import { CONFIRMATION_MODAL_CONTENT_ID } from '../confirmation-modal/constants';
import { BEM_CLASS, SMediaPlansDropdown } from './s-media-plans-dropdown';

interface Props {
  mediaPlans: MediaPlanListItem[];
  mediaPlansLoading: LoadingState;
  mediaPlanId?: Id;
  onItemClick(mediaPlanId: Id): void;
  onDelete(mediaPlanId: Id): void;
  onCreateNew(name: string): void;
  onEdit(mediaPlanId: Id, name: string): void;
  onOpen(): void;
  onClose(): void;
  loading?: boolean;
  editable?: boolean;
  isOpen: boolean;
  className?: string;
  mediaPlanName?: string;
  refetch(): void;
}

const classes = bem(BEM_CLASS);

export const MediaPlansDropdown = React.memo(
  ({
    mediaPlans,
    mediaPlansLoading,
    mediaPlanId,
    onCreateNew,
    onItemClick,
    onDelete,
    onEdit,
    onOpen,
    onClose,
    loading,
    editable,
    className,
    isOpen,
    mediaPlanName,
    refetch,
  }: Props) => {
    const onSelectItem = useCallback(
      (mediaPlanId: Id) => {
        onItemClick(mediaPlanId);
        onClose();
      },
      [onClose, onItemClick]
    );

    const onDropdownTriggerClick = useCallback(() => {
      if (isOpen) {
        onClose();
      } else {
        onOpen();
      }
    }, [isOpen, onClose, onOpen]);

    const onClickOutside = useCallback(
      (_, event: Event) => {
        const isClickedOnConfirmationModal =
          event.target instanceof HTMLElement && event.target.dataset.id === CONFIRMATION_MODAL_CONTENT_ID;

        if (isClickedOnConfirmationModal) {
          return;
        }
        onClose();
      },
      [onClose]
    );

    const mediaPlanDropdownTrigger = useMemo(() => {
      if (mediaPlans.length > 1 || !editable || mediaPlansLoading === LoadingState.Failed) {
        return (
          <button
            className={classes('trigger', { menuOpen: isOpen }, className)}
            data-selector="media-plan-dropdown-trigger"
          >
            <Icon name={isOpen ? 'up-single-m' : 'down-single-m'} className={classes('icon')} />
          </button>
        );
      }

      return (
        <Button
          className={classes('edit-trigger', undefined, className)}
          theme={THEME.light}
          data-selector="media-plan-dropdown-edit-trigger"
          size={BUTTON_SIZE.smallRound}
          type={BUTTON_TYPE.tertiary}
          icon="edit"
        />
      );
    }, [className, editable, isOpen, mediaPlans.length, mediaPlansLoading]);

    return (
      <Popper
        content={
          <Popup
            mediaPlans={mediaPlans}
            mediaPlansLoading={mediaPlansLoading}
            mediaPlanId={mediaPlanId}
            loading={loading}
            editable={editable}
            visible={isOpen}
            onItemClick={onSelectItem}
            onCreateNew={onCreateNew}
            onDelete={onDelete}
            onEdit={onEdit}
            onRefresh={refetch}
          />
        }
        onClickOutside={onClickOutside}
        visible={isOpen}
        placement="bottom-start"
        maxWidth="none"
      >
        <SMediaPlansDropdown onClick={onDropdownTriggerClick}>
          <MediaPlanName mediaPlanName={mediaPlanName} />

          {mediaPlanDropdownTrigger}
        </SMediaPlansDropdown>
      </Popper>
    );
  }
);
