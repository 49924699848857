import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';
import { theme } from 'app/theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { MEDIA_PLAN_POPUP_ADDITIONAL_WIDTH } from './constants';

export const BEM_CLASS = 'popup';

interface SPopupProps {
  width?: number;
}

export const SPopup = styled.div<SPopupProps>`
  background: white;
  min-width: 327px;
  ${({ width }) => (width !== undefined ? `width: ${width + MEDIA_PLAN_POPUP_ADDITIONAL_WIDTH}px` : 'unset')};
  overflow: hidden;
  border-radius: 10px;
  max-height: 310px;
  box-shadow: ${theme.shadows.default};
  padding: 20px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  .${BEM_CLASS} {
    &__loader {
      margin: 11px 50px 15px 93px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      overflow: auto;
    }

    &__item {
      min-height: 30px;
      display: flex;
      cursor: pointer;
      padding: 0 20px;
      align-items: center;
      flex-shrink: 0;

      &--selected {
        font-weight: 800;
      }

      &:hover {
        background: ${transparentize(0.9, colorPalette.azureRadiance)};

        .${BEM_CLASS}__controls-container {
          visibility: visible;
        }
      }

      &--editing {
        &:hover {
          background: white;
        }
      }
    }

    &__name {
      margin-right: 5px;
      width: 100%;
      text-align: left;
      ${elipsizeText};

      &--selected {
        font-weight: 700;
      }
    }

    &__controls-container {
      margin-left: auto;
      visibility: hidden;
      display: flex;
      align-items: center;

      & > :not(:first-child) {
        margin-left: 10px;
      }
      &--editing {
        visibility: visible;
      }

      &--error {
        align-self: flex-start;
        margin-top: 6px;
      }
    }

    &__filled-button {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${transparentize(0.5, colorPalette.snuff)};
      border-radius: 20px;
      background-color: white;

      .${BEM_CLASS}__action-icon {
        color: black;
      }
      &:hover {
        background: ${transparentize(0.9, colorPalette.azureRadiance)};
      }

      &--disabled {
        pointer-events: none;
        opacity: 0.6;
        color: ${colorPalette.lavenderGray};

        .${BEM_CLASS}__action-icon {
          color: ${colorPalette.lavenderGray};
        }
      }
    }

    &__outlined-button {
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 22px;
      padding: 5px;
      &:hover {
        background: ${transparentize(0.9, colorPalette.azureRadiance)};
      }

      &--disabled {
        pointer-events: none;
        background: transparent;

        .${BEM_CLASS}__action-icon {
          color: ${colorPalette.lavenderGray};
        }
      }
    }

    &__action-icon {
      font-size: 10px;
      color: black;
    }

    &__new-item {
      display: flex;
      padding: 5px 20px;
      flex-shrink: 0;
      align-items: center;

      .${BEM_CLASS}__controls-container {
        visibility: visible;
      }
    }

    &__input-container {
      flex: 1;
      margin-right: 10px;
    }

    &__input {
      & input {
        height: 30px;
        border-radius: 5px;
        padding: 0 5px;
      }
    }

    &__footer {
      height: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px 20px;
      flex-shrink: 0;
      &:hover {
        .${BEM_CLASS}__add-new, .${BEM_CLASS}__icon {
          color: ${colorPalette.ceriseRed};
        }
      }

      &--disabled {
        pointer-events: none;
        opacity: 0.6;
        color: ${colorPalette.lavenderGray};
      }
    }

    &__add-new {
      margin-left: 11px;
      color: black;
      font-weight: 700;
    }

    &__error-container {
      display: flex;
      align-items: center;
      margin-top: 5px;
    }

    &__error-icon {
      margin-right: 12px;
      color: ${colorPalette.cinnabar};
    }

    &__error-text {
      color: ${colorPalette.cinnabar};
      text-transform: none;
      white-space: nowrap;
      letter-spacing: 0;
    }
  }
`;
