import styled from 'styled-components';
import { PADDING } from '../../constants';

export const BEM_CLASS = 'empty-search';

export const SEmptySearch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px ${PADDING}px;

  .${BEM_CLASS} {
    &__image {
      max-width: 100%;
      margin-bottom: 16px;
    }
  }
`;
