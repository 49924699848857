import styled from 'styled-components';
import { SInput } from 'common/components/form/input/s-input';
import { BgStyle } from 'common/styles/utils';

interface Props {
  disabled?: boolean;
  isRequired?: boolean;
  bgStyle?: BgStyle;
  isInvalid?: boolean;
  shouldUseCurrencyFormat?: boolean;
}

export const SInputNumber = styled.div<Props>`
  position: relative;
  height: 100%;
  .number-input {
    ${SInput};
    height: 100%;
    padding-right: 19px;
    padding-left: 32px;
    padding-top: 0;
    padding-bottom: 0;
  }
`;
