import styled from 'styled-components';
import Tippy from '@tippyjs/react';

export const STooltip = styled(Tippy)`
  color: white;
  background: black;
  border-radius: 0;
  padding: 7px 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: left;

  .tippy-content > div {
    word-break: break-word;
  }

  /* Styling the arrow for different placements */
  &[data-placement^='bottom'] .tippy-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    top: -5px;
  }
  &[data-placement^='top'] .tippy-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    bottom: -5px;
  }
  &[data-placement^='right'] .tippy-arrow {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid black;
    left: -5px;
  }
  &[data-placement^='left'] .tippy-arrow {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid black;
    right: -5px;
  }

  &[data-placement^='bottom-start'] .tippy-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
    left: 50% !important;
    right: 50% !important;
  }
  &[data-placement^='top-start'] .tippy-arrow {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
    left: 50% !important;
    right: 50% !important;
  }
`;
