import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'moment-form-header';

export const SHeader = styled.div`
  margin-top: 29px;
  margin-bottom: 21px;
  .${BEM_CLASS} {
    &__title-text {
      ${elipsizeText}
    }
  }
`;
