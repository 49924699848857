import { store } from 'app';
import { AxiosRequestConfig } from 'axios';
import storage from 'redux-persist/lib/storage';
import { TokenService, TokenResponse } from 'backend-api/core/token-service';
import { clearPersistedState } from 'utils/storage';
import { IS_OLD_AUTH } from 'common/constants';
import { auth0AccessTokenSelector } from 'auth/selectors';
import { tokenSelector } from 'login/selectors';
import { isAccessTokenExpired } from 'login/transducers';
import { clearAuthToken, setAuthToken } from 'login/actions';
import { ACTION as AUTH_ACTION } from 'auth/reducer';

export const getAuthorizedConfig = (config: AxiosRequestConfig, token: string): AxiosRequestConfig => ({
  ...config,
  headers: {
    ...config.headers,
    Authorization: `Bearer ${token}`,
  },
});

const authTokenProvider = (config: AxiosRequestConfig) => {
  const accessToken = auth0AccessTokenSelector(store.getState());

  if (accessToken) {
    return getAuthorizedConfig(config, accessToken);
  }

  return config;
};

const loginTokenProvider = (tokenService: TokenService) => (config: AxiosRequestConfig) => {
  const token = tokenSelector(store.getState());

  if (!token) return config;

  if (isAccessTokenExpired(token.expires_timestamp)) {
    return tokenService
      .refreshToken()
      .then((token: TokenResponse) => {
        store.dispatch(setAuthToken({ data: token, currentTimestamp: Date.now() }));
        return Promise.resolve(getAuthorizedConfig(config, token.access_token));
      })
      .catch(error => {
        store.dispatch(clearAuthToken());
        return Promise.reject(error);
      });
  }

  return getAuthorizedConfig(config, token.access_token);
};

const authUnauthorized = error => {
  if (error) {
    store.dispatch(AUTH_ACTION.setAuth0Error(error));
  }
  store.dispatch(AUTH_ACTION.unauthorized());
};

const loginUnauthorized = () => {
  store.dispatch(clearAuthToken());
};

export const unauthorize = error => {
  clearPersistedState(storage);

  if (IS_OLD_AUTH) {
    authUnauthorized(error);
  } else {
    loginUnauthorized();
  }
};

export const tokenProvider = (tokenService: TokenService) =>
  IS_OLD_AUTH ? authTokenProvider : loginTokenProvider(tokenService);
