import { isArtists, isPlaylists } from 'backend-api/guards';
import { Artist, LinkfireSearch, Optional, Playlist, ProjectDetails, TargetItems, Targets } from 'backend-api/models';
import { EMPTY_MARKDOWN, NOT_AVAILABLE } from 'common-v2/constants';
import { LinkfireSearchOption } from 'common-v2/types';
import { getFormattedDate } from 'common-v2/utils';

export const getPrimaryTargets = (targets: TargetItems) =>
  isPlaylists(targets) ? targets : targets.filter(({ type }) => type === 'Primary');

export const isArtistLocked = (artist: Artist): boolean =>
  !!artist.isPRS && artist.type === 'Primary' && !artist.isUnknown;

export const getLockedArtist = (targets: Targets): Optional<Artist> => {
  const primaryTargets = getPrimaryTargets(targets.items);

  if (isPlaylists(primaryTargets)) return;

  return primaryTargets.find(isArtistLocked);
};

export const getProjectName = (project: Optional<ProjectDetails>): string =>
  project?.name || project?.title || project?.prsData?.title || project?.ccpData?.title || '';

export const getProjectSchedule = (project: Optional<ProjectDetails>, dateFormat: string, isClaimed: boolean) => {
  if (!project) return undefined;

  if (!project.startDate) return NOT_AVAILABLE;

  const formatDate = getFormattedDate(dateFormat);

  return `${formatDate(project.startDate)}${isClaimed && project.endDate ? `-${formatDate(project.endDate)}` : ''}`;
};

export const getProjectAvatarUrl = (targets: (Artist | Playlist)[]): Optional<string> =>
  isArtists(targets) ? targets.find(isArtistLocked)?.image?.url ?? targets[0]?.image?.url : undefined;

export const isEmptyMarkdown = markdown => !markdown || markdown === EMPTY_MARKDOWN;

export const mapLinkfireSearchToBaseOption = (linkfireLinksSearch: LinkfireSearch[]): LinkfireSearchOption[] =>
  linkfireLinksSearch.map(item => ({
    ...item,
    id: item.linkId,
    name: item.linkUrl,
    label: item.linkUrl,
    isFixed: item.deletable === false,
    modelId: item.id,
  }));
