import { Api } from 'backend-api';
import { asyncEpic, ofType } from 'core/epics';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  createMediaPlan,
  createPhase,
  deleteMediaPlan,
  deletePhase,
  editMediaPlan,
  editPhase,
  getAllMediaPlans,
  getApprovalRequests,
  getMediaPlan,
  getMediaPlanMetrics,
  getMediaPlanRelease,
  getMediaPlanReviewers,
  getProjectArtistTeam,
  modifyBulkCampaigns,
  requestApproval,
  submitApproval,
  updateMediaPlanRelease,
  changeMediaPlanMode,
} from './actions';
import { MediaPlanMode } from './types';
import { isRequestRestricted, isProjectAccessRestricted } from 'common-v2/transducers';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { openPermissionErrorModal } from 'modals/permission-error-modal';
import { TOAST_TYPE } from 'gdb-web-shared-components';

export const getAllMediaPlansEpic = asyncEpic(
  getAllMediaPlans,
  ({ payload }) => Api.getMediaPlans(payload),
  undefined,
  { showError: false }
);

export const getMediaPlanEpic = asyncEpic(
  getMediaPlan,
  ({ payload: { projectId, mediaPlanId } }) => Api.getMediaPlan(projectId, mediaPlanId),
  undefined,
  { showError: false }
);

export const createMediaPlanPhaseEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(createPhase.request),
    switchMap(({ payload: { projectId, mediaPlanId, payload, onSuccess, onError, openToast } }) => {
      return Api.createMediaPlanPhase(projectId, mediaPlanId, payload).pipe(
        mergeMap(phase => [createPhase.success(phase)]),
        tap(() => trackEvent(AnalyticsEvents.PHASE_CREATED, { projectId })),
        tap(phase => onSuccess(phase.payload)),
        catchError(err => {
          if (isRequestRestricted(err)) {
            onError();
            return of(openPermissionErrorModal(err), createPhase.failure(err));
          }

          if (!isProjectAccessRestricted(err)) {
            openToast({
              id: 'media-plan-create-phase',
              type: TOAST_TYPE.ERROR,
              message: "We're sorry, an error occurred during Creating phase. Try again.",
            });

            onError();
          }

          return of(createPhase.failure(err));
        })
      );
    })
  );
};

export const editMediaPlanPhaseEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(editPhase.request),
    switchMap(({ payload: { projectId, mediaPlanId, phaseId, payload, onSuccess, onError, openToast } }) => {
      return Api.editMediaPlanPhase(projectId, mediaPlanId, phaseId, payload).pipe(
        mergeMap(phase => [editPhase.success(phase)]),
        tap(phase => onSuccess(phase.payload)),
        catchError(err => {
          if (isRequestRestricted(err)) {
            onError();
            return of(openPermissionErrorModal(err), editPhase.failure(err));
          }

          if (!isProjectAccessRestricted(err)) {
            openToast({
              id: 'media-plan-edit-phase',
              type: TOAST_TYPE.ERROR,
              message: "We're sorry, an error occurred during Editing phase. Try again.",
            });

            onError();
          }

          return of(editPhase.failure(err));
        })
      );
    })
  );
};

export const deleteMediaPlanPhaseEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(deletePhase.request),
    switchMap(({ payload: { projectId, mediaPlanId, phaseId, onError, openToast } }) => {
      return Api.deleteMediaPlanPhase(projectId, mediaPlanId, phaseId).pipe(
        mergeMap(() => [deletePhase.success(phaseId), getMediaPlanMetrics.request({ projectId, mediaPlanId })]),
        catchError(err => {
          if (isRequestRestricted(err)) {
            onError();
            return of(openPermissionErrorModal(err), deletePhase.failure(err));
          }

          if (!isProjectAccessRestricted(err)) {
            openToast({
              id: 'media-plan-delete-phase',
              type: TOAST_TYPE.ERROR,
              message: "We're sorry, an error occurred during Deleting phase. Try again.",
            });

            onError();
          }

          return of(deletePhase.failure(err));
        })
      );
    })
  );
};

export const modifyBulkCampaignsEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(modifyBulkCampaigns.request),
    switchMap(({ payload: { mediaPlanId, projectId, payload, onSuccess, onError, openToast } }) => {
      return Api.modifyMediaPlanCampaigns(projectId, mediaPlanId, payload).pipe(
        tap(response => onSuccess(response)),
        tap(response => {
          response.created.forEach(() => {
            trackEvent(AnalyticsEvents.ROW_ADDED, { mediaPlanId });
          });
        }),
        mergeMap(response => [
          modifyBulkCampaigns.success(response),
          getMediaPlanMetrics.request({ projectId, mediaPlanId }),
        ]),
        catchError(err => {
          onError();

          if (isRequestRestricted(err)) {
            return of(openPermissionErrorModal(err), modifyBulkCampaigns.failure(err));
          }

          if (!isProjectAccessRestricted(err)) {
            openToast({
              id: 'media-plan-modify-campaigns',
              type: TOAST_TYPE.ERROR,
              message: "We're sorry, an error occurred during updating Media Plan data. Try again.",
            });
          }

          return of(modifyBulkCampaigns.failure(err));
        })
      );
    })
  );
};

export const getMediaReleaseEpic = asyncEpic(
  getMediaPlanRelease,
  ({ payload: { projectId, mediaPlanId } }) => Api.getMediaPlanRelease(projectId, mediaPlanId),
  undefined,
  {
    showError: false,
  }
);

export const updateProjectReleaseEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(updateMediaPlanRelease.request),
    switchMap(({ payload: { projectId, mediaPlanId, payload, openToast, onError } }) => {
      return Api.updateMediaPlanRelease(projectId, mediaPlanId, payload).pipe(
        mergeMap(response => [updateMediaPlanRelease.success(response)]),
        catchError(err => {
          onError();

          if (isRequestRestricted(err)) {
            return of(openPermissionErrorModal(err), updateMediaPlanRelease.failure(err));
          }

          if (!isProjectAccessRestricted(err)) {
            openToast({
              id: 'media-plan-update-release',
              type: TOAST_TYPE.ERROR,
              message: 'Something went wrong. An error occurred while we were saving release details.',
            });
          }

          return of(updateMediaPlanRelease.failure(err));
        })
      );
    })
  );
};

export const getProjectReviewersListEpic = asyncEpic(
  getMediaPlanReviewers,
  ({ payload: { projectId, mediaPlanId } }) => Api.getProjectReviewers(projectId, mediaPlanId),
  undefined,
  {
    showError: false,
  }
);

export const requestApprovalEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(requestApproval.request),
    switchMap(({ payload: { projectId, mediaPlanId, payload, openToast } }) => {
      return Api.requestApproval(projectId, mediaPlanId, payload).pipe(
        tap(() => {
          openToast({
            id: 'media-plan-request-approval-success',
            type: TOAST_TYPE.SUCCESS,
            message: 'Approval request has been successfully sent.',
          });
        }),
        mergeMap(() => [requestApproval.success(), getMediaPlan.request({ projectId, mediaPlanId })]),
        catchError(err => {
          if (isRequestRestricted(err)) {
            return of(
              changeMediaPlanMode(MediaPlanMode.DEFAULT),
              openPermissionErrorModal(err),
              requestApproval.failure(err)
            );
          }

          if (!isProjectAccessRestricted(err)) {
            openToast({
              id: 'media-plan-request-approval-error',
              type: TOAST_TYPE.ERROR,
              message: 'We are unable to send an Approval Request due to a data failure. Please try again.',
            });
          }

          return of(requestApproval.failure(err));
        })
      );
    })
  );
};

export const getApprovalRequestsEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(getApprovalRequests.request),
    switchMap(({ payload: { projectId, mediaPlanId } }) => {
      return Api.getApprovalRequests(projectId, mediaPlanId).pipe(
        mergeMap(approvalResponse => [getApprovalRequests.success(approvalResponse)]),
        catchError(err => {
          if (err.data?.code === 'not_found_campaigns_for_review') {
            return of(getApprovalRequests.success([]));
          }

          return of(getApprovalRequests.failure(err));
        })
      );
    })
  );
};

export const submitApprovalEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(submitApproval.request),
    switchMap(({ payload: { projectId, mediaPlanId, payload, onSubmitSuccess, openToast } }) => {
      return Api.submitApproval(projectId, mediaPlanId, payload).pipe(
        tap(() => {
          openToast({
            id: 'media-plan-submit-approval-success',
            type: TOAST_TYPE.SUCCESS,
            message: `${payload.campaigns.length} campaigns have been reviewed successfully`,
          });

          onSubmitSuccess();
        }),
        mergeMap(() => [
          submitApproval.success(),
          getApprovalRequests.request({ projectId, mediaPlanId }),
          getMediaPlan.request({ projectId, mediaPlanId }),
        ]),
        catchError(err => {
          openToast({
            id: 'media-plan-submit-approval-error',
            type: TOAST_TYPE.ERROR,
            message: 'An error ocurred while confirming your review, please try again.',
          });

          return of(
            submitApproval.failure(err),
            getApprovalRequests.request({ projectId, mediaPlanId }),
            getMediaPlan.request({ projectId, mediaPlanId })
          );
        })
      );
    })
  );
};

export const getMediaPlanMetricsEpic = asyncEpic(
  getMediaPlanMetrics,
  ({ payload: { projectId, mediaPlanId } }) => Api.getMediaPlanMetrics(projectId, mediaPlanId),
  undefined,
  {
    showError: false,
  }
);

export const createMediaPlanEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(createMediaPlan.request),
    switchMap(action => {
      const { projectId, payload, onMediaPlanCreated, openToast } = action.payload;
      return Api.createMediaPlan(projectId, payload).pipe(
        tap(mediaPlan => {
          onMediaPlanCreated?.(mediaPlan.id);
        }),
        mergeMap(response => [createMediaPlan.success(response), getAllMediaPlans.request(projectId)]),
        catchError(error => {
          if (isRequestRestricted(error)) {
            return of(openPermissionErrorModal(error), createMediaPlan.failure(error));
          }

          if (!isProjectAccessRestricted(error)) {
            openToast({
              id: 'media-plan-create',
              type: TOAST_TYPE.ERROR,
              message: "We're sorry, an error occurred during updating Media Plan data. Try again.",
            });
          }

          return of(createMediaPlan.failure(error));
        })
      );
    })
  );
};

export const editMediaPlanEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(editMediaPlan.request),
    switchMap(action => {
      const { projectId, mediaPlanId, payload, openToast } = action.payload;
      return Api.editMediaPlan(projectId, mediaPlanId, payload).pipe(
        mergeMap(response => [editMediaPlan.success(response), getAllMediaPlans.request(projectId)]),
        catchError(error => {
          if (isRequestRestricted(error)) {
            return of(openPermissionErrorModal(error), editMediaPlan.failure(error));
          }

          if (!isProjectAccessRestricted(error)) {
            openToast({
              id: 'media-plan-edit',
              type: TOAST_TYPE.ERROR,
              message: "We're sorry, an error occurred during updating Media Plan data. Try again.",
            });
          }

          return of(editMediaPlan.failure(error));
        })
      );
    })
  );
};

export const deleteMediaPlanEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(deleteMediaPlan.request),
    switchMap(action => {
      const { projectId, mediaPlanId, onMediaPlanDeleted, openToast } = action.payload;
      return Api.deleteMediaPlan(projectId, mediaPlanId).pipe(
        tap(() => {
          onMediaPlanDeleted?.(mediaPlanId);
        }),
        mergeMap(response => [deleteMediaPlan.success(response), getAllMediaPlans.request(projectId)]),
        catchError(error => {
          if (isRequestRestricted(error)) {
            return of(openPermissionErrorModal(error), deleteMediaPlan.failure(error));
          }

          if (!isProjectAccessRestricted(error)) {
            openToast({
              id: 'media-plan-delete',
              type: TOAST_TYPE.ERROR,
              message: "We're sorry, an error occurred during updating Media Plan data. Try again.",
            });
          }

          return of(deleteMediaPlan.failure(error));
        })
      );
    })
  );
};

export const getProjectArtistTeamEpic = asyncEpic(getProjectArtistTeam, action =>
  Api.getProjectArtistTeam(action.payload).pipe(catchError(() => of(undefined)))
);
