import { manhattanThemeColors } from 'app/theme/colors';
import styled from 'styled-components';

export const BEM_CLASS = 'date-range-field';

export const SDateRangeField = styled.div`
  .${BEM_CLASS} {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    &__error {
      color: ${manhattanThemeColors.darkRed};
    }
  }
`;
