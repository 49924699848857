import React, { useCallback } from 'react';
import { ZeroStateCard } from 'common/components/zero-state-card';
import { Icon } from 'common/components/icon';
import NoCampaignsSvg from 'assets/no-campaigns.svg';
import { useDispatch } from 'react-redux';
import { openModal as openBulkCampaignsAssignmentModal } from 'modals/bulk-campaigns-assignment/actions';

interface Props {
  className?: string;
}

export const NoCampaignsCard = React.memo(({ className }: Props) => {
  const dispatch = useDispatch();

  const onClick = useCallback(() => {
    dispatch(openBulkCampaignsAssignmentModal());
  }, [dispatch]);

  return (
    <ZeroStateCard
      title="Next Step"
      subtitle="Assign campaigns to see more metrics."
      buttonChildren={[<Icon name="arrow-right" color="white" key="icon" />, 'Assign Campaign']}
      onButtonClick={onClick}
      imageSrc={NoCampaignsSvg}
      className={className}
    />
  );
});
