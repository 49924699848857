import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { BgStyle } from 'common/styles/utils';
import { FILTERS_SELECT_MENU_ITEMS_PADDING_TOP, MENU_WIDTH } from './constants';
import { MAX_MENU_HEIGHT } from '../select/constants';
import { colorPalette } from 'app/theme/colors';

interface Props {
  readonly bgStyle?: BgStyle;
  readonly disabled?: boolean;
  readonly isRequired?: boolean;
  readonly isInvalid?: boolean;
  readonly isSearchable?: boolean;
}

export const BEM_CLASS = 'filters-select';

export const SFiltersSelect = styled.div<Props>`
  .${BEM_CLASS} {
    &__control {
      display: flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 50px;
      min-height: 35px;
      cursor: pointer;
    }
    &__value-container {
      ${elipsizeText};
      padding-right: 23px;
    }
    &__menu {
      width: ${MENU_WIDTH}px;
      padding-top: 0 !important;
      background: ${colorPalette.white};
      will-change: opacity;
      max-height: 454px;
      position: absolute;
    }
    &__menu-items {
      max-height: ${MAX_MENU_HEIGHT}px;
      min-height: ${MAX_MENU_HEIGHT}px;
      padding-top: ${FILTERS_SELECT_MENU_ITEMS_PADDING_TOP}px;
    }
    &__menu-container {
      width: 100%;
      height: 100%;
    }
  }
`;
