import React from 'react';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { Confirm } from 'common/components/confirm';
import { SConfirmModalContent } from 'common/s-components/s-confirm-modal-content';

interface Props {
  onOk(): void;
  onCancel?(): void;
}

const classes = bem('confirm-modal-content');

export const ChangeTypeConfirm = React.memo(({ onOk, onCancel = () => {} }: Props) => {
  return (
    <Confirm
      dataSelector="change-type-confirm"
      okText="Yes"
      cancelText="Cancel"
      width="400px"
      renderHeader={false}
      onOk={onOk}
      onCancel={onCancel}
      visible
    >
      <SConfirmModalContent>
        <div className={classes('header-container')}>
          <div className={classes('title')} data-selector={'change-type-modal-title'}>
            Switching the project’s focus will clear
            <br />
            your current selections.
          </div>
          <Icon onClick={onCancel} size="general" name="close" className={classes('close-icon')} />
        </div>
        <div className={classes('content-text')} data-selector={'change-type-modal-message'}>
          Are you sure you want to continue?
        </div>
      </SConfirmModalContent>
    </Confirm>
  );
});
