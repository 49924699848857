import { createTypedReducer, onAction } from 'core/store';
import { Campaign } from 'backend-api/models';
import { PartialLoadingState, PartiallyLoadableData } from 'common/types';
import {
  openModal,
  closeModal,
  clearAllSelectedCampaigns,
  resetModal,
  updateCampaignsFilters,
  setSelectedCampaigns,
  getAssignableCampaigns,
} from './actions';
import { CampaignsFilters } from './types';
import { DEFAULT_SORT } from './constants';

export interface ModalState {
  isOpened: boolean;
  campaigns: PartiallyLoadableData<Campaign[]>;
  campaignsFilters: CampaignsFilters;
  campaignsTotalCount: number;
  selectedCampaigns: Campaign[];
}

export const initialState: ModalState = {
  isOpened: false,
  campaigns: {
    loading: PartialLoadingState.Idle,
    data: [],
  },
  campaignsFilters: {
    sort: DEFAULT_SORT,
    search: '',
  },
  campaignsTotalCount: 0,
  selectedCampaigns: [],
};

export const reducer = createTypedReducer<ModalState>(
  initialState,
  onAction(openModal, state => ({
    ...state,
    isOpened: true,
  })),
  onAction(closeModal, state => ({
    ...state,
    isOpened: false,
  })),
  onAction(getAssignableCampaigns.request, (state, { payload }) => ({
    ...state,
    campaigns: {
      ...state.campaigns,
      loading: payload.isInfinite ? PartialLoadingState.StartedPartial : PartialLoadingState.Started,
    },
  })),
  onAction(getAssignableCampaigns.success, (state, { payload }) => ({
    ...state,
    campaigns: {
      loading: PartialLoadingState.Finished,
      data: payload.isInfinite ? state.campaigns.data.concat(payload.items) : payload.items,
    },
    campaignsTotalCount: payload.total,
  })),
  onAction(getAssignableCampaigns.failure, state => ({
    ...state,
    campaigns: {
      ...state.campaigns,
      loading: PartialLoadingState.Failed,
    },
  })),
  onAction(clearAllSelectedCampaigns, state => ({
    ...state,
    selectedCampaigns: [],
  })),
  onAction(updateCampaignsFilters, (state, { payload }) => ({
    ...state,
    campaignsFilters: {
      ...state.campaignsFilters,
      ...payload,
    },
  })),
  onAction(setSelectedCampaigns, (state, { payload }) => ({
    ...state,
    selectedCampaigns: payload,
  })),
  onAction(resetModal, () => initialState)
);
