import { BaseOption, MultiSelectProps, SelectProps } from 'common/components/form/select';
import { ReactElement } from 'react';
import { MenuProps } from 'react-select';

type SingleProps<T> = SelectProps<T> & {
  containerClassName?: string;
  searchPlaceholder?: string;
  entityName?: string;
  renderMenuFooter?(props: MenuProps<T>, onApplyHandler: () => void): ReactElement;
};

type MultiProps<T> = MultiSelectProps<T> & {
  containerClassName?: string;
  searchPlaceholder?: string;
  entityName?: string;
  renderMenuFooter?(props: MenuProps<T>, onApplyHandler: () => void): ReactElement;
};

export const isMultiProps = <T extends BaseOption>(props: SingleProps<T> | MultiProps<T>): props is MultiProps<T> => {
  return !!props.isMulti;
};

export type FilterSelectProps<T> = SingleProps<T> | MultiProps<T>;
