import React from 'react';
import { DropdownIndicatorProps } from 'react-select';
import { bem } from 'utils/bem';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { Icon } from 'common/components/icon';
import { BaseOption as BaseOptionType } from '../../types';
import { BEM_CLASS, SIndicatorContainer } from './s-indicator-container';

type Props<T extends BaseOptionType, IsMulti extends boolean> = DropdownIndicatorProps<T, IsMulti>;

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType, IsMulti extends boolean>({ selectProps }: Props<T, IsMulti>) => {
  const { classNames, dataSelector } = getBaseCustomSelectProps(selectProps);

  return (
    <SIndicatorContainer className={classNames.indicatorsContainer} data-selector={`${dataSelector}-indicator`}>
      <div className={classes('icon', { isUp: selectProps.menuIsOpen })}>
        <Icon name="down" color="inherit" />
      </div>
    </SIndicatorContainer>
  );
};

export const IndicatorContainer = React.memo(Root) as typeof Root;
