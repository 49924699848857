import { actionsGroup } from 'core/store';
import { SetTokenPayload } from './types';

const { action } = actionsGroup('auth');

export const setAuthToken = action<SetTokenPayload>('SET_AUTH_TOKEN');

export const setAuthTokenLoading = action<void>('SET_AUTH_TOKEN_LOADING');

export const clearAuthToken = action<void>('CLEAR_AUTH_TOKEN');
