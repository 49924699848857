import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'input-data-campaign-table-container';

export const SInputDataCampaignTableContainer = styled.div`
  .${BEM_CLASS} {
    &__table-action-header {
      border-left: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
    }

    &__table-action-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      border-left: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
      color: ${transparentize(0.2, manhattanThemeColors.hoveredBorderColor)};
      cursor: pointer;
    }
  }
`;
