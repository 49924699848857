import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'date-editor';

export const SDateEditor = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${manhattanThemeColors.background};
`;
