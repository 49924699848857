import styled from 'styled-components';

export const BEM_CLASS = 'table-infinite-loader';

export const STableInfiniteLoader = styled.tr`
  height: 80px;

  .${BEM_CLASS} {
    &__loader {
      position: relative;
      z-index: 0;
    }
  }
`;
