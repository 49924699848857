import styled from 'styled-components';
import { PROJECTS_HEADER_HEIGHT } from './constants';

export const BEM_CLASS = 'header';

export const SHeader = styled.div`
  display: flex;
  align-items: center;
  height: ${PROJECTS_HEADER_HEIGHT}px;

  .${BEM_CLASS} {
    &__title {
      margin-right: 20px;
    }
  }
`;
