import * as t from 'io-ts';
import { ArtistSearchSchema } from '../index';
import { FormattedDateFromISOString, Optional, ResponseModel } from '../common';

export * from './artist-roster-project';

export const ArtistRosterArtistStatusSchema = t.union([t.literal('live'), t.literal('planned'), t.literal('inactive')]);

export type ArtistRosterArtistStatus = t.TypeOf<typeof ArtistRosterArtistStatusSchema>;

export const ArtistRosterMetadataSchema = ResponseModel(
  {
    projectsCount: t.number,
    earliestProjectStartDate: Optional(FormattedDateFromISOString),
    latestProjectEndDate: Optional(FormattedDateFromISOString),
    status: ArtistRosterArtistStatusSchema,
    isCurrentUserArtistTeamMember: t.boolean,
  },
  'ArtistRosterMetadata'
);

export type ArtistRosterMetadata = t.TypeOf<typeof ArtistRosterMetadataSchema>;

export const ArtistRosterItemSchema = ResponseModel(
  {
    artist: ArtistSearchSchema,
    metadata: ArtistRosterMetadataSchema,
  },
  'ArtistRosterItem'
);

export type ArtistRosterItem = t.TypeOf<typeof ArtistRosterItemSchema>;

export const ArtistRosterSchema = ResponseModel(
  {
    live: t.array(ArtistRosterItemSchema),
    planned: t.array(ArtistRosterItemSchema),
    inactive: t.array(ArtistRosterItemSchema),
  },
  'ArtistRoster'
);

export type ArtistRosterModel = t.TypeOf<typeof ArtistRosterSchema>;
