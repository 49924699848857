import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'approval-value';

export const SApprovalValue = styled.div`
  .${BEM_CLASS} {
    &__value {
      color: ${manhattanThemeColors.grayedText};
    }
  }
`;
