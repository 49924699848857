import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SCalendarPicker } from './s-calendar-picker';
import DayPicker, { CaptionElementProps } from 'react-day-picker';
import { bem } from 'utils/bem';
import { MonthPickerHeader } from './components';
import {
  getPickerDisabledDays,
  HeaderType,
  RangeComponentsProps,
  SingleComponentsProps,
} from 'common/components/form/date-picker';
import { DayModifiers } from 'react-day-picker/types/Modifiers';
import { isRangeComponentsProps } from 'common/components/form/date-picker/guards';
import { Period } from 'common/types';
import {
  addMonthsToDate,
  substractMonthsFromDate,
  addYearsToDate,
  substractYearsFromDate,
  isDateAfter,
} from 'common-v2/utils';

interface InnerProps {
  availableDates?: Period[] | Period;
  maxPeriodDays?: number;
}

type Props = (RangeComponentsProps | SingleComponentsProps) & InnerProps;

const classes = bem('calendar-picker');

export const CalendarPicker = React.memo((props: Props) => {
  const { value, onChange, mode, availableDates, maxPeriodDays } = props;

  const [rightMonth, setRightMonth] = useState<Date>(new Date());
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [firstSelectedDate, setFirstSelectedDate] = useState<Date>();

  const onNextMonthClick = useCallback(() => setRightMonth(addMonthsToDate(rightMonth, 1)), [rightMonth]);
  const onPreviousMonthClick = useCallback(() => setRightMonth(substractMonthsFromDate(rightMonth, 1)), [rightMonth]);
  const onNextYearClick = useCallback(() => setRightMonth(addYearsToDate(rightMonth, 1)), [rightMonth]);
  const onPreviousYearClick = useCallback(() => setRightMonth(substractYearsFromDate(rightMonth, 1)), [rightMonth]);

  useEffect(() => {
    if (!props.value) return;
    if (isRangeComponentsProps(props)) {
      const [startDate, endDate] = props.value;

      setFromDate(startDate);
      setToDate(endDate);
      setRightMonth(endDate || new Date());
    } else {
      setToDate(props.value);
      setRightMonth(props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const renderMonthHeader = useCallback(
    (type: HeaderType) => (data: CaptionElementProps) => (
      <MonthPickerHeader
        date={data.date}
        mode={mode}
        type={type}
        onNextMonthClick={onNextMonthClick}
        onPreviousMonthClick={onPreviousMonthClick}
        onNextYearClick={onNextYearClick}
        onPreviousYearClick={onPreviousYearClick}
      />
    ),
    [onNextMonthClick, onPreviousMonthClick, onNextYearClick, onPreviousYearClick, mode]
  );

  const changeDateValue = useCallback(() => {
    if (!props.onChange || !fromDate) return;
    if (isRangeComponentsProps(props)) {
      props.onChange([fromDate, toDate || fromDate]);
    } else {
      props.onChange(fromDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, onChange]);

  const selectingProcess = useCallback(
    (date: Date, { disabled }: DayModifiers) => {
      if (disabled || !firstSelectedDate) return;
      if (isDateAfter(firstSelectedDate, date)) {
        setToDate(firstSelectedDate);
        setFromDate(date);
      } else {
        setToDate(date);
        setFromDate(firstSelectedDate);
      }
    },
    [firstSelectedDate]
  );

  const handleRangeSelection = useCallback(
    (date: Date) => {
      if (!firstSelectedDate) {
        setFromDate(date);
        setToDate(undefined);
        setFirstSelectedDate(date);
      } else {
        changeDateValue();
        setFirstSelectedDate(undefined);
      }
    },
    [firstSelectedDate, changeDateValue]
  );

  const handleSingleSelection = useCallback(
    (date: Date) => {
      if (isRangeComponentsProps(props)) return;
      setFromDate(date);
      props?.onChange?.(date);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange, props.mode]
  );

  const toggleSelection = useCallback(
    (date: Date, { disabled }: DayModifiers, event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      if (disabled) return;
      if (isRangeComponentsProps(props)) {
        handleRangeSelection(date);
      } else {
        handleSingleSelection(date);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleRangeSelection, handleSingleSelection, props.mode]
  );

  const selectedDaysModifiers = useMemo(() => {
    if (!isRangeComponentsProps(props) && toDate) return { start: toDate, end: toDate, from: toDate, to: toDate };

    if (!fromDate || !toDate) return undefined;

    return { start: fromDate, end: toDate, from: fromDate, to: toDate };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, props.mode]);

  const leftMonthDate = useMemo(() => substractMonthsFromDate(rightMonth, 1), [rightMonth]);

  const rightMonthDate = useMemo(() => rightMonth, [rightMonth]);

  const leftCaption = useMemo(() => renderMonthHeader(HeaderType.LEFT), [renderMonthHeader]);
  const rightCaption = useMemo(() => renderMonthHeader(HeaderType.RIGHT), [renderMonthHeader]);

  const disabledDays = useMemo(() => getPickerDisabledDays(firstSelectedDate, maxPeriodDays, availableDates), [
    availableDates,
    maxPeriodDays,
    firstSelectedDate,
  ]);

  return (
    <SCalendarPicker mode={mode}>
      <div className={classes('calendar-container')} data-selector="date-picker">
        {isRangeComponentsProps(props) && (
          <DayPicker
            numberOfMonths={1}
            month={leftMonthDate}
            selectedDays={selectedDaysModifiers}
            modifiers={selectedDaysModifiers}
            captionElement={leftCaption}
            disabledDays={disabledDays}
            onDayClick={toggleSelection}
            onDayMouseEnter={selectingProcess}
          />
        )}
        <DayPicker
          numberOfMonths={1}
          month={rightMonthDate}
          selectedDays={selectedDaysModifiers}
          modifiers={selectedDaysModifiers}
          captionElement={rightCaption}
          onDayClick={toggleSelection}
          disabledDays={disabledDays}
          onDayMouseEnter={selectingProcess}
        />
      </div>
    </SCalendarPicker>
  );
});
