import React, { useCallback, useMemo } from 'react';
import { ArtistRosterItem } from 'backend-api/models';
import { bem } from 'utils/bem';
import { BEM_CLASS, SLiveArtistCard } from './s-live-artist-card';
import { Badge } from 'common/components/badge';
import { pluralizeString } from 'utils/string';
import { colorPalette } from 'app/theme/colors';
import { LiveArtistCardStates } from 'common/types/artist';
import { Icon } from 'common/components/icon';
import { Tooltip } from 'common/components/tooltip';
import { Body, SmallText } from 'common/components/typography';
import { motion, MotionConfig } from 'framer-motion';

interface ArtistCardProps {
  item: ArtistRosterItem;
  cardState: LiveArtistCardStates;
  onSelectArtist(id: string): void;
}

const classes = bem(BEM_CLASS);

export const LiveArtistCard = ({ item, cardState, onSelectArtist }: ArtistCardProps) => {
  const isProjectsExpanded = cardState === LiveArtistCardStates.SELECTED;
  const cardClickHandler = useCallback(() => onSelectArtist(item.artist.id), [item.artist.id, onSelectArtist]);
  const expanderText = isProjectsExpanded ? 'Hide Projects' : 'View Projects';

  const transition = useMemo(() => ({ type: 'tween', duration: 0.2 }), []);
  const elevationAnimate = useMemo(() => ({ y: isProjectsExpanded ? -10 : 0 }), []);
  return (
    <MotionConfig transition={transition}>
      <SLiveArtistCard
        layoutId={item.artist.id}
        expanded={isProjectsExpanded}
        cardState={cardState}
        animate={elevationAnimate}
        onClick={cardClickHandler}
        backgroundImage={item.artist.image?.url}
      >
        <Tooltip content={expanderText}>
          <motion.button layoutId="projects-count" className={classes('expand-button', { active: isProjectsExpanded })}>
            <Icon color="black" className={classes('expand-icon')} name="expand" />
          </motion.button>
        </Tooltip>

        <motion.div layoutId="info-container" className={classes('info-container')}>
          <motion.div layoutId="badge-status">
            <Badge className={classes('badge')} text="Live" color={colorPalette.jade} />
          </motion.div>
          <motion.div layoutId="artist-name">
            <Body className={classes('artist-name')}>{item.artist.name}</Body>
          </motion.div>
          <motion.div layoutId="projects-count">
            <SmallText className={classes('projects-count')}>
              {item.metadata.projectsCount}{' '}
              {pluralizeString('project', item.metadata.projectsCount ? item.metadata.projectsCount : 0)}
            </SmallText>
          </motion.div>
        </motion.div>
      </SLiveArtistCard>
    </MotionConfig>
  );
};
