import styled, { css } from 'styled-components';
import palette from 'frontend-shared-theme/src/palette';
import { colorPalette } from 'app/theme/colors';
import { appHeader } from '../styles/z-indexes';
import { TOP_HEADER_HEIGHT } from 'common/constants';

interface Props {
  selectedIndex: number;
}

const activeLink = css`
  font-weight: 600;
  color: ${palette.white};
`;

const TAB_WIDTH = 120;
const TAB_MARGIN = 20;

export const STopHeaderOutdated = styled.div<Props>`
  height: ${TOP_HEADER_HEIGHT}px;
  background-color: #0c0d14;
  color: ${palette.white};
  width: 100%;

  // Fix for active link styling
  position: fixed;
  z-index: ${appHeader};

  .top-header {
    padding: 0 20px;
    height: 100%;

    &__decibel-logo {
      display: flex;
    }

    &__beta {
      font-size: 10px;
      line-height: 15px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 0 5px;
      border-radius: 2px;
      background-color: ${colorPalette.wildStrawberry};
      margin-left: 15px;
    }

    &__divider {
      width: 1px;
      height: 17px;
      margin: 0 20px;
      background-color: ${colorPalette.alto};
      opacity: 0.5;
    }

    &__item-container {
      height: 100%;
    }

    &__items {
      display: flex;
      align-items: center;
      height: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    &__right-container {
      position: relative;
      top: -1px;
      display: flex;
      align-items: center;
      flex-grow: 1;
    }

    &__links {
      text-align: right;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      position: relative;
    }

    &__link {
      color: #dfe0eb;
      text-align: center;
      line-height: 100%;

      display: flex;
      align-items: center;
      justify-content: center;
      transition: font-weight 0.1s ease 0s;
      white-space: nowrap;
      padding: 0 20px;
      width: ${TAB_WIDTH}px;

      + .top-header__link {
        margin-left: ${TAB_MARGIN}px;
      }

      &:hover {
        ${activeLink}
      }
    }

    &__logout-btn {
      margin: auto 0 auto 45px;
    }

    &__current {
      position: relative;
      ${activeLink};
      ~ .top-header__under {
        transform: translateX(${({ selectedIndex }) => selectedIndex * (TAB_WIDTH + TAB_MARGIN)}px);
      }
    }
    &__under {
      height: 0.25rem;
      width: 120px;
      left: 0;
      bottom: -7px;
      position: absolute;
      background: ${palette.pink};
      border: none;
      border-radius: 2px;
      transition: width 0.3s ease-in-out, transform 0.3s ease-in-out;
    }
    &__left-container {
      display: flex;
      flex-direction: row;
      height: 100%;
    }

    &__search {
      flex-grow: 1;
      margin-right: 60px;
    }

    &__user-dropdown {
      align-self: center;
      margin-left: 40px;
    }
  }
`;
