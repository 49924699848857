import React from 'react';
import { BEM_CLASS, SUserSettings } from './s-user-settings';
import { Preferences, ProfileInfo } from './components';
import { bem } from 'utils/bem';
import { WaveBackground } from 'common/components/wave-background';
import { CurrentUserInfo, Label } from 'backend-api/models';

const classes = bem(BEM_CLASS);

interface Props {
  userInfo?: CurrentUserInfo;
  labels?: Label[];
  onPhoneBlur(phone: string): void;
  phone: string;
  dateFormat: string;
  onDateFormatChange(string): void;
}

export const UserSettings = React.memo(
  ({ userInfo, labels, onPhoneBlur, phone, onDateFormatChange, dateFormat }: Props) => {
    return (
      <WaveBackground>
        <SUserSettings data-selector="user-settings-container">
          {userInfo && labels && (
            <ProfileInfo
              className={classes('profile-info')}
              userInfo={userInfo}
              labels={labels}
              onPhoneBlur={onPhoneBlur}
              phone={phone}
            />
          )}
          <Preferences
            className={classes('preferences')}
            dateFormat={dateFormat}
            onDateFormatChange={onDateFormatChange}
          />
        </SUserSettings>
      </WaveBackground>
    );
  }
);
