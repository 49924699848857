import { useCallback } from 'react';
import { AllProjectsFiltersByStatus } from '../types';
import { useProjectsFilters } from './use-projects-filters';

export const useSetFiltersForCurrentStatus = () => {
  const [filters, filtersDispatcher] = useProjectsFilters();

  return useCallback(
    (newFiltersForCurrentStatus: Partial<AllProjectsFiltersByStatus>) => {
      const statusId = filters.status?.id;

      if (!statusId) return;

      const { filtersByStatus } = filters;

      filtersDispatcher.setFilters({
        filtersByStatus: {
          ...filtersByStatus,
          [statusId]: {
            ...filtersByStatus[statusId],
            ...newFiltersForCurrentStatus,
          },
        },
      });
    },
    [filters, filtersDispatcher]
  );
};
