import { createSelector } from 'reselect';
import { State } from './reducer';

export const purchaseOrderStateSelector = state => state.purchaseOrder as State;

export const purchaseOrderSelector = createSelector(purchaseOrderStateSelector, state => state.purchaseOrder);

export const filtersSelector = createSelector(purchaseOrderStateSelector, state => state.filters);
export const isPOChildrenLoadingSelector = createSelector(
  purchaseOrderStateSelector,
  state => state.isPOChildrenLoading
);
export const isPOChildrenLoadingFailedSelector = createSelector(
  purchaseOrderStateSelector,
  state => state.isPOChildrenLoadingFailed
);
export const purchaseOrdersSelector = createSelector(purchaseOrderStateSelector, state => state.purchaseOrdersResponse);
export const purchaseProvidersSelector = createSelector(purchaseOrderStateSelector, state => state.providers);

export const errorSelector = createSelector(purchaseOrderStateSelector, state => state.error);
