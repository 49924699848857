import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { Label } from 'backend-api/models';
import { Icon } from 'common/components/icon';
import { SNotification, BEM_CLASS } from './s-notification';
import { getJoinedNames } from 'common/transducers';

const classes = bem(BEM_CLASS);

interface NotificationProps {
  labels: Label[];
  onClose(): void;
}

export const Notification = React.memo(({ labels, onClose }: NotificationProps) => {
  const combinedLabels = useMemo(() => getJoinedNames(labels), [labels]);

  return (
    <SNotification>
      <Icon className={classes('icon')} name="blocked" color="white" size="general" />
      <span className={classes('text')} title={combinedLabels}>
        Missing a project? Reach out to one of these labels for access: {combinedLabels}
      </span>
      <button className={classes('close')} onClick={onClose}>
        <Icon name="close" color="white" size="general" />
      </button>
    </SNotification>
  );
});
