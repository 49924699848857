import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const SSimpleNumberRenderer = styled.div`
  width: 100%;
  height: 100%;
  color: ${colorPalette.black};
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: end;
`;
