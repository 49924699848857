import styled from 'styled-components';

const GRID_VERTICAL_PADDING = 10;
const GRID_HORIZONTAL_PADDING = 20;

export const SArtistsPlaylistsSection = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .artists-playlists-section {
    &__section-title {
      margin: 0 ${GRID_HORIZONTAL_PADDING}px;
    }
    &__items-scroller {
      overflow-y: auto;
      overflow-x: visible;
    }
    &__items-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-auto-rows: min-content;
      grid-gap: 10px;
      padding: ${GRID_VERTICAL_PADDING}px ${GRID_HORIZONTAL_PADDING}px;
    }
    &__empty-view {
      flex: 1;
      margin: 0 ${GRID_HORIZONTAL_PADDING}px;
    }
  }
`;
