import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectDetails } from 'backend-api/models';
import { UUID } from 'io-ts-types/lib/UUID';
import { Button, BUTTON_SIZE, BUTTON_TYPE, THEME } from 'gdb-web-shared-components';
import { dateFormatSelector } from 'common/selectors';
import {
  getProjectCampaigns,
  openManagingCampaigns,
  removeExternalCampaign,
  undoRemovalExternalCampaign,
} from 'reporting-v2/actions';
import { bem } from 'utils/bem';
import { ExpandableContainer, InputDataTableLoading } from 'reporting-v2/components';
import { projectCampaignsSelector } from 'reporting-v2/selectors';
import { getInitialConfig } from './transducers';
import { RemoveExternalCampaignPayload } from 'reporting-v2/types';
import { usePermissionsChecker } from 'common-v2/hooks';
import { ACCESS_PERMISSIONS } from 'common-v2/constants';
import { Loading } from 'common-v2/types';
import { CommonTable } from 'common-v2/components';
import { NoRowsOverlay } from './components';
import { SInputDataCampaignTableContainer, BEM_CLASS } from './s-input-data-campaign-table-container';

interface Props {
  project: ProjectDetails;
  currencyCode: string;
  loading?: boolean;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const InputDataCampaignTableContainer = React.memo(({ project, currencyCode, className }: Props) => {
  const dispatch = useDispatch();
  const permissionChecker = usePermissionsChecker(project);

  const hasEditPermission = permissionChecker(ACCESS_PERMISSIONS.EDIT_REPORTING);

  const [isExpandableContainerOpen, setExpandableContainerOpen] = useState(false);

  const dateFormat = useSelector(dateFormatSelector);
  const { loading, data: campaigns } = useSelector(projectCampaignsSelector);

  const isLoading = loading === Loading.Started;
  const inputDataTitle = `Input Data ${campaigns.length ? `(${campaigns.length})` : ''}`;

  const onDelete = useCallback(
    (campaignUuid: UUID) => {
      const campaign = campaigns.find(campaign => campaign.uuid === campaignUuid);
      if (!campaign) return;

      const payload: RemoveExternalCampaignPayload = {
        projectId: project.id,
        campaign,
        onUndo: () => {
          dispatch(undoRemovalExternalCampaign.request({ projectId: project.id, campaignUuid }));
        },
      };
      dispatch(removeExternalCampaign.request(payload));
    },
    [campaigns, dispatch, project]
  );
  const columnDefs = useMemo(
    () =>
      getInitialConfig({
        currencyCode,
        dateFormat,
        isEmpty: campaigns.length === 0,
        hasEditPermission,
        onDelete,
        classNames: { actionHeader: classes('table-action-header'), actionCell: classes('table-action-cell') },
      }),
    [currencyCode, dateFormat, campaigns, hasEditPermission, onDelete]
  );

  const openModal = useCallback(() => {
    dispatch(openManagingCampaigns());
  }, [dispatch]);

  const actions = useMemo(
    () =>
      isExpandableContainerOpen && hasEditPermission
        ? [
            <Button
              key="manage"
              type={BUTTON_TYPE.tertiary}
              theme={THEME.light}
              size={BUTTON_SIZE.small}
              caption="Manage Campaigns"
              onClick={openModal}
            />,
          ]
        : [],
    [isExpandableContainerOpen, openModal, hasEditPermission]
  );

  useEffect(() => {
    dispatch(getProjectCampaigns.request(project.id));
  }, [dispatch, project]);

  return (
    <ExpandableContainer
      isOpen={isExpandableContainerOpen}
      title={inputDataTitle}
      className={className}
      onToggle={setExpandableContainerOpen}
      actions={actions}
    >
      {isLoading ? (
        <InputDataTableLoading />
      ) : (
        <SInputDataCampaignTableContainer>
          <CommonTable
            headerHeight={38}
            rowHeight={39}
            columnDefs={columnDefs}
            rowData={campaigns}
            domLayout="autoHeight"
            noRowsOverlayComponent={NoRowsOverlay}
            suppressContextMenu
          />
        </SInputDataCampaignTableContainer>
      )}
    </ExpandableContainer>
  );
});
