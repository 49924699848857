import { Input } from 'common/components/form';
import { H3 } from 'common/components/typography';
import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SModalHeader } from './s-modal-header';

interface Props {
  title: React.ReactChild;
  search?: string;
  onChangeSearch(value: string): void;
}

const classes = bem(BEM_CLASS);

export const ModalHeader = React.memo(({ title, search, onChangeSearch }: Props) => {
  return (
    <SModalHeader>
      <H3>{title}</H3>
      <Input
        className={classes('search')}
        dataSelector="modal-header-search-field"
        initialValue={search}
        placeholder="Search by name"
        bgStyle="grey"
        onChange={onChangeSearch}
        iconLeft="search"
        clearable
      />
    </SModalHeader>
  );
});
