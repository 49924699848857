import { globalSearchSelector } from 'common/selectors';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React, { FC, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { BEM_CLASS, SRecentSearches } from './s-recent-searches';
import { LoadingState } from 'common/types';
import { RecentSearchItemType } from 'backend-api/models';
import searchStartPlaceholder from 'assets/search-start-placeholder.png';

import { ArtistCard, CampaignCard, Loader, ProjectCard } from '../global-search-modal/components';
import { Image } from 'common-v2/components';

const classes = bem(BEM_CLASS);

interface RecentSearchesProps {
  dateFormat: string;
}

export const RecentSearches: FC<RecentSearchesProps> = React.memo(({ dateFormat }) => {
  const { recent: recentResults } = useSelector(globalSearchSelector);

  const renderCard = useCallback(
    data => {
      switch (data.type) {
        case RecentSearchItemType.Artist:
          return <ArtistCard {...data.entity} dateFormat={dateFormat} withBadges />;
        case RecentSearchItemType.Campaign:
          return <CampaignCard {...data.entity} dateFormat={dateFormat} withBadges />;
        case RecentSearchItemType.Project:
          return <ProjectCard {...data.entity} dateFormat={dateFormat} withBadges />;
      }
      return '';
    },
    [dateFormat]
  );

  const content = useMemo(() => {
    const emptyState = (
      <div className={classes('placeholder')}>
        <Typography className={classes('placeholder-text')} type={TYPOGRAPHY_TYPE.body4}>
          Start typing project, artist or campaign&apos;s name <br /> to see search results
        </Typography>

        <Image imageUrl={searchStartPlaceholder} />
      </div>
    );

    switch (recentResults.loading) {
      case LoadingState.Idle:
      case LoadingState.Started:
        return (
          <div className={classes('loader')}>
            <Loader />
          </div>
        );
      case LoadingState.Finished:
        return recentResults.data.length > 0 ? (
          <>
            <Typography className={classes('title')} type={TYPOGRAPHY_TYPE.body3} data-selector="recent-results-title">
              Recent Searches
            </Typography>

            <div className={classes('results')}>
              {recentResults.data.map(result => (
                <div className={classes('card')} data-selector="recent-search-result-card" key={result.id}>
                  {renderCard(result)}
                </div>
              ))}
            </div>
          </>
        ) : (
          emptyState
        );
      case LoadingState.Failed:
        return emptyState;
    }
  }, [recentResults.loading, recentResults.data, renderCard]);

  return <SRecentSearches>{content}</SRecentSearches>;
});
