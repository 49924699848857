import { CampaignStatuses } from 'backend-api/models';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { getInitialCellData } from 'media-plan-v2/containers/spreadsheet/transducers';

export const clearCampaign = (data: SpreadsheetRowData): SpreadsheetRowData => {
  const { id, uuid, phaseId, phaseName, phaseOrder, orderInPhase, ...fields } = data;
  const fieldsCopy = { ...fields };

  for (const field in fieldsCopy) {
    fieldsCopy[field] = getInitialCellData(null);
  }

  return {
    ...fieldsCopy,
    id,
    uuid,
    phaseId,
    phaseName,
    phaseOrder,
    orderInPhase,
    status: getInitialCellData(CampaignStatuses.DRAFT),
  };
};
