import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'approvers-control';

interface Props {
  hasError?: boolean;
  isFocused?: boolean;
}

export const STeamMembersControl = styled.div<Props>`
  display: flex;
  height: 37px;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: ${colorPalette.ghostWhite};

  ${({ isFocused, hasError }) => {
    if (isFocused) {
      return css`
        border-color: ${colorPalette.azureRadiance};
        background-color: ${colorPalette.white};
      `;
    }

    if (hasError) {
      return css`
        border-color: ${colorPalette.alizarinCrimson};
      `;
    }

    return ``;
  }}

  .${BEM_CLASS} {
    &__icon {
      color: ${transparentize(0.4, colorPalette.wildBlueYonder)};

      &--control {
        width: 24px;
        height: 24px;
        font-size: 24px;
        margin-left: 12px;
        display: flex;
      }

      &--copy {
        cursor: pointer;
        width: 18px;
        height: 18px;
        font-size: 18px;
        margin-right: 10px;
      }
    }
  }
`;
