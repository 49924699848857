import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { BaseRenderer, SingleValueView } from 'media-plan-v2/components/spreadsheet/components';
import { SpreadsheetRowData, MediaPlanAccessMode } from 'media-plan-v2/containers/spreadsheet/types';

interface NamingConventionRendererProps
  extends ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['namingConventionManual']> {
  accessMode: MediaPlanAccessMode;
}

export const NamingConventionRenderer = React.memo(
  ({ accessMode, value: { color, value: rendererValue }, data }: NamingConventionRendererProps) => {
    const value = (data?.namingConvention.value ?? '') + (rendererValue ?? '');

    return (
      <BaseRenderer color={color} accessMode={accessMode} placeholder="Write here...">
        {value && <SingleValueView value={value} />}
      </BaseRenderer>
    );
  }
);
