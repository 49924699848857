import React from 'react';
import { Skeleton } from 'common-v2/components/skeleton';

interface LoaderProps {
  className?: string;
}

export const Loader = React.memo(({ className }: LoaderProps) => (
  <Skeleton className={className} width={50} height={20}>
    <rect x="0" y="0" rx="2px" ry="2px" width="50" height="20" />
  </Skeleton>
));
