import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ActionRenderer } from '../action-renderer';
import { SingleValueView } from '../single-value-view';
import { SpreadsheetRowData } from '../../types';

export const ObjectiveRenderer = ({
  context,
  value: { color, value },
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['objective']>) => (
  <ActionRenderer color={color} accessMode={context.accessMode} placeholder="Select or type">
    {value?.name && <SingleValueView value={value.name} />}
  </ActionRenderer>
);
