import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'start-date-renderer';

export const SValidationTooltipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;

  .${BEM_CLASS} {
    &__warning-icon {
      color: ${manhattanThemeColors.darkRed};
    }

    &__error-text {
      color: ${manhattanThemeColors.darkRed};
    }
  }
`;
