import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { getCurrencyFormatted } from 'utils/format';
import { paths } from 'app/routing/paths';
import { PurchaseOrderDetails } from 'backend-api/models';
import { Link } from 'react-router-dom';
import { Body } from 'common/components/typography';
import { CampaignIcon } from 'common/components/campaign-icon';
import { SPaper } from 'common/s-components/s-paper';
import { Cell } from 'common/components/form/cell';
import { BEM_CLASS, SInfo } from './s-info';
import { useFormattedDateTime } from 'hooks';

interface InfoProps {
  projectId: number;
  purchaseOrder: PurchaseOrderDetails;
}

const classes = bem(BEM_CLASS);

export const Info = React.memo(
  ({
    projectId,
    purchaseOrder: {
      budget,
      allocation,
      budgetGroup,
      budgetCategory,
      provider,
      createdDate,
      lastModifiedDate,
      linkedCampaign,
      notes,
      budgetCurrency,
    },
  }: InfoProps) => {
    const defaultPaperProps = useMemo(() => ({ pt: '25px', pb: '15px', mb: '10px', color: 'white' }), []);

    const createdDateFormatted = useFormattedDateTime({ date: createdDate });
    const lastModifiedDateFormatted = useFormattedDateTime({ date: lastModifiedDate });

    return (
      <SInfo>
        <SPaper {...defaultPaperProps}>
          <div className={classes('grid')}>
            <Cell label="Total amount" dataSelector="total-amount">
              <Body>{getCurrencyFormatted(budget, budgetCurrency)}</Body>
            </Cell>
            <Cell label="Paid amount" dataSelector="paid-amount">
              <Body>{getCurrencyFormatted(allocation, budgetCurrency)}</Body>
            </Cell>
            <Cell label="Budget group" dataSelector="budget-group">
              <Body>{budgetGroup.name}</Body>
            </Cell>
            <Cell label="Budget category" dataSelector="budget-category">
              <Body>{budgetCategory.name}</Body>
            </Cell>
            <Cell label="Provider" dataSelector="provider">
              <Body>{provider.name}</Body>
            </Cell>
          </div>
        </SPaper>
        <SPaper {...defaultPaperProps}>
          <div className={classes('grid')} data-selector="created-date-cell">
            <Cell label="Created date" dataSelector="created-date-value">
              <Body>{createdDateFormatted?.date}</Body>
            </Cell>
            <Cell label="Last modified" dataSelector="last-modified">
              <Body>{lastModifiedDateFormatted?.date}</Body>
            </Cell>
          </div>
        </SPaper>
        {linkedCampaign && !linkedCampaign.isDeleted && (
          <SPaper {...defaultPaperProps}>
            <Cell label="Campaign link" dataSelector="campaign-link">
              <Link
                className={classes('campaign-link')}
                to={paths.campaign(projectId, linkedCampaign.uuid)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CampaignIcon className={classes('campaign-icon')} campaignCategory={linkedCampaign.type?.category} />
                <span className={classes('campaign-name')}>{linkedCampaign.name}</span>
              </Link>
            </Cell>
          </SPaper>
        )}
        <SPaper {...defaultPaperProps} mb="0px">
          <Cell label="Notes" dataSelector="notes">
            <Body>{notes}</Body>
          </Cell>
        </SPaper>
      </SInfo>
    );
  }
);
