import { ShortProject } from 'backend-api/models';
import { createTypedReducer, onAction } from 'core/store';
import { Loadable, Loading } from 'common-v2/types';
import { getNewProjects, getRecentlyViewedProjects } from './actions';

export interface ProjectsV2State {
  recentlyViewedProjects: Loadable<ShortProject[]>;
  newProjects: Loadable<ShortProject[]>;
}

export const initialState: ProjectsV2State = {
  recentlyViewedProjects: { loading: Loading.Idle, data: [] },
  newProjects: { loading: Loading.Idle, data: [] },
};

export const reducer = createTypedReducer<ProjectsV2State>(
  initialState,
  onAction(getRecentlyViewedProjects.request, state => ({
    ...state,
    recentlyViewedProjects: {
      loading: Loading.Started,
      data: [],
    },
  })),
  onAction(getRecentlyViewedProjects.success, (state, { payload }) => ({
    ...state,
    recentlyViewedProjects: {
      loading: Loading.Finished,
      data: payload,
    },
  })),
  onAction(getRecentlyViewedProjects.failure, state => ({
    ...state,
    recentlyViewedProjects: {
      loading: Loading.Failed,
      data: [],
    },
  })),
  onAction(getNewProjects.request, state => ({
    ...state,
    newProjects: {
      loading: Loading.Started,
      data: [],
    },
  })),
  onAction(getNewProjects.success, (state, { payload }) => ({
    ...state,
    newProjects: {
      loading: Loading.Finished,
      data: payload,
    },
  })),
  onAction(getNewProjects.failure, state => ({
    ...state,
    newProjects: {
      loading: Loading.Failed,
      data: [],
    },
  }))
);
