import styled, { keyframes } from 'styled-components';
import { appHeader } from 'app/styles/z-indexes';
import { GLOBAL_SEARCH_WIDTH } from 'common/constants';
import { transparentize } from 'polished';
import { palette } from 'frontend-shared-theme';
import { motion } from 'framer-motion';
import { theme } from 'app/theme';

export const BEM_CLASS = 'search-modal';

const contentAnimation = keyframes`
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
`;

export const SSearchModal = styled(motion.div)`
  display: flex;
  position: fixed;
  z-index: ${appHeader};
  justify-content: center;
  width: 100vw;
  padding: 5px;
  background-color: ${transparentize(0.6, palette.darkBlue)};

  .${BEM_CLASS} {
    &__content {
      flex-grow: 1;
      max-width: ${GLOBAL_SEARCH_WIDTH}px;
      height: 100%;
      background-color: ${palette.white};
      border-radius: 10px;
      box-shadow: ${theme.shadows.default};
      transform-origin: 50% 0;
      will-change: transform, opacity;
      animation: ${contentAnimation} 0.3s ease-out both;
    }
  }
`;
