import * as React from 'react';
import { SFlex } from 'common/s-components/layout/s-flex';
import { STimeline } from 'common/s-components/s-timeline';
import { bem } from 'utils/bem';
import { calculateDateRangePercentProgress } from './transducers';
import { useFormattedDate } from 'common-v2/hooks';

interface Props {
  showInfo?: boolean;
  startDate?: Date;
  endDate?: Date;
  className?: string;
}

const classes = bem('timeline');

export const Timeline = React.memo(({ showInfo = true, startDate, endDate: nullableEndDate, className }: Props) => {
  const formatDate = useFormattedDate();

  const endDate = nullableEndDate || startDate;

  const startDateFormatted = formatDate(startDate);
  const endDateFormatted = formatDate(endDate);

  const percentProgress = startDate && endDate ? calculateDateRangePercentProgress(startDate, endDate) : 0;
  const timelineValue = Number.isNaN(percentProgress) ? 0 : percentProgress;

  return (
    <STimeline className={className} progressWidth={timelineValue}>
      <div className={classes('progress')} />
      {showInfo && (
        <SFlex className={classes('dates')} justifyContent="space-between" pt="16px">
          {startDateFormatted && <span className={classes('date')}>{startDateFormatted}</span>}
          {endDateFormatted && <span className={classes('date')}>{endDateFormatted}</span>}
        </SFlex>
      )}
    </STimeline>
  );
});
