import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'release-info';

export const SReleaseInfo = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__info-item {
      display: flex;
      align-items: center;
    }
    &__release-details {
      position: relative;
      z-index: 1;
      & > button {
        color: ${colorPalette.azureRadiance};
        font-weight: 600;
        padding: 2px 10px;
      }
    }
  }
`;
