import React, { useCallback, useMemo } from 'react';
import { paths } from 'app/routing/paths';
import { navigateTo } from 'utils/navigation';
import { canEditOrRemoveMoment } from 'common/transducers';
import { Button } from 'common/components/button';
import { Tooltip } from 'common/components/tooltip';
import { Icon } from 'common/components/icon';
import { MomentData } from 'artist-moment/types';
import { useAppContext } from 'common-v2/hooks';

export const EditButton = React.memo(({ project, moment }: MomentData) => {
  const { user: currentUser } = useAppContext();

  const canEditMoment = canEditOrRemoveMoment(project, moment, currentUser);

  const onEditClick = useCallback(() => {
    navigateTo(paths.editMoment(project.id, moment.id));
  }, [moment.id, project.id]);

  const tooptipContent = useMemo(() => {
    return (
      <>
        <div>You have no rights to edit moment in this project.</div>
        <div>Please contact project editor.</div>
      </>
    );
  }, []);

  return (
    <Tooltip content={tooptipContent} enabled={!canEditMoment}>
      <span>
        <Button
          disabled={!canEditMoment}
          variant={canEditMoment ? 'secondary-cta' : 'secondary-white'}
          onClick={onEditClick}
          dataSelector="moment-edit-button"
        >
          <Icon name="edit" color="white" />
          Edit
        </Button>
      </span>
    </Tooltip>
  );
});
