import { colorPalette } from 'app/theme/colors';
import { rgba } from 'polished';

export enum TableTheme {
  WHITE = 'white',
  GREY = 'grey',
}

export const tableTheme = {
  [TableTheme.WHITE]: {
    tableBackgroundColor: colorPalette.white,
    rowBackgroundColor: {
      even: colorPalette.white,
      odd: colorPalette.whitenedTooth,
    },
    rowSelectedBackground: rgba(colorPalette.logan, 0.1),
    headerColumnBackground: colorPalette.whitenedTooth,
  },
  [TableTheme.GREY]: {
    tableBackgroundColor: colorPalette.ghostWhite,
    rowBackgroundColor: {
      even: colorPalette.alabaster,
      odd: colorPalette.ghostWhite,
    },
    rowSelectedBackground: rgba(colorPalette.logan, 0.1),
    headerColumnBackground: colorPalette.athensGray,
  },
};
