import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { getTextareaPosition as getContainerPosition } from './transducers';
import { SExpandableField, SExpandedContainer } from './s-expandable-field';
import { callbackWithoutPropagation } from 'utils/event';

interface ExpandableFieldProps {
  children: React.ReactChild;
  className?: string;
  hasError: boolean;
}

export const ExpandableField = React.memo(({ className, children, hasError }: ExpandableFieldProps) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [containerPosition, setContainerPosition] = useState({
    top: 0,
    left: 0,
  });

  const calculatePopupPosition = useCallback(() => {
    const rootElement = rootRef.current;
    const containerElement = containerRef.current;

    if (rootElement && containerElement) {
      const rootBound = rootElement.getBoundingClientRect();
      const containerBound = containerElement.getBoundingClientRect();
      const position = getContainerPosition(
        rootBound,
        { width: containerBound.width, height: containerBound.height },
        window
      );

      setContainerPosition(position);
    }
  }, []);

  useLayoutEffect(() => {
    calculatePopupPosition();
  }, [calculatePopupPosition]);

  useLayoutEffect(() => {
    const portalElement = containerRef.current;
    if (!portalElement) return;
    const resizeObserver = new ResizeObserver(calculatePopupPosition);
    resizeObserver.observe(portalElement);

    return () => {
      resizeObserver.unobserve(portalElement);
    };
  }, [calculatePopupPosition]);

  return (
    <SExpandableField ref={rootRef} className={className}>
      {createPortal(
        <SExpandedContainer
          ref={containerRef}
          top={containerPosition.top}
          left={containerPosition.left}
          hasError={hasError}
          onMouseDown={callbackWithoutPropagation()}
        >
          {children}
        </SExpandedContainer>,
        document.body
      )}
    </SExpandableField>
  );
});
