import {
  allLabelsSelector,
  artistMomentCategoriesSelector,
  artistMomentTypesSelector,
  campaignStatusesSelector,
  countryCodesSelector,
  currenciesSelector,
  gendersSelector,
  goalsSelector,
  performanceArtistsBreakdownsSelector,
  performanceCampaignProvidersSelector,
  performanceCampaignsBreakdownsSelector,
  performanceObjectivesSelector,
  performancePlatformsSelector,
  performanceProjectsBreakdownsSelector,
  projectReleaseTypesSelector,
  projectStatusSelector,
  rawTerritoriesSelector,
  territoriesSelector,
} from '../selectors';
import { createFetchableSelectorHook } from './create-fetchable-selector-hook';
import {
  getAllLabels,
  getArtistMomentCategories,
  getArtistMomentTypes,
  getCampaignStatuses,
  getCountryCodes,
  getCurrencies,
  getGenders,
  getGoals,
  getPerformanceArtistsBreakdowns,
  getPerformanceCampaignProviders,
  getPerformanceCampaignsBreakdowns,
  getPerformanceObjectives,
  getPerformancePlatforms,
  getPerformanceProjectsBreakdowns,
  getProjectReleaseTypes,
  getProjectStatuses,
  getTerritories,
} from '../actions';

export const useProjectStatus = createFetchableSelectorHook(projectStatusSelector, getProjectStatuses.request);
export const useCurrencies = createFetchableSelectorHook(currenciesSelector, getCurrencies.request);
export const useGoals = createFetchableSelectorHook(goalsSelector, getGoals.request);
export const useAllLabels = createFetchableSelectorHook(allLabelsSelector, getAllLabels.request);
export const useTerritories = createFetchableSelectorHook(territoriesSelector, getTerritories.request);
export const useCountries = createFetchableSelectorHook(rawTerritoriesSelector, getTerritories.request);
export const useCountryCodes = createFetchableSelectorHook(countryCodesSelector, getCountryCodes.request);
export const useGenders = createFetchableSelectorHook(gendersSelector, getGenders.request);
export const usePerformanceCampaignProviders = createFetchableSelectorHook(
  performanceCampaignProvidersSelector,
  getPerformanceCampaignProviders.request,
  { force: true }
);
export const usePerformanceObjectives = createFetchableSelectorHook(
  performanceObjectivesSelector,
  getPerformanceObjectives.request
);

export const usePerformancePlatforms = createFetchableSelectorHook(
  performancePlatformsSelector,
  getPerformancePlatforms.request,
  { force: true }
);

export const usePerformanceArtistsBreakdowns = createFetchableSelectorHook(
  performanceArtistsBreakdownsSelector,
  getPerformanceArtistsBreakdowns.request
);

export const usePerformanceProjectsBreakdowns = createFetchableSelectorHook(
  performanceProjectsBreakdownsSelector,
  getPerformanceProjectsBreakdowns.request
);

export const usePerformanceCampaignsBreakdowns = createFetchableSelectorHook(
  performanceCampaignsBreakdownsSelector,
  getPerformanceCampaignsBreakdowns.request
);

export const useArtistMomentTypes = createFetchableSelectorHook(
  artistMomentTypesSelector,
  getArtistMomentTypes.request
);

export const useArtistMomentCategories = createFetchableSelectorHook(
  artistMomentCategoriesSelector,
  getArtistMomentCategories.request
);

export const useCampaignStatuses = createFetchableSelectorHook(campaignStatusesSelector, getCampaignStatuses.request);

export const useProjectReleaseTypes = createFetchableSelectorHook(
  projectReleaseTypesSelector,
  getProjectReleaseTypes.request
);
