import { AudienceAge, Nullable, Optional } from 'backend-api/models';

const mapNumber = (value?: string): Optional<number> => {
  const numberValue = Number(value);

  if (value?.length === 0 || isNaN(numberValue)) return;

  return numberValue;
};

export const ageStringToAudienceAge = (value?: string): Optional<AudienceAge> => {
  const ages = value?.split('-');
  if (!ages || ages?.length === 0) {
    return undefined;
  }

  if (ages.length === 2) {
    const lowerAge = mapNumber(ages[0]);
    const higherAge = mapNumber(ages[1]);
    return { lowerAge, higherAge };
  }

  return undefined;
};

export const audienceAgeToString = (value?: AudienceAge | null): string => {
  if (!value) {
    return '';
  }

  return `${value.lowerAge == null ? 'YY' : value.lowerAge}-${value.higherAge == null ? 'YY' : value.higherAge}`;
};

const prepareAge = (age?: Nullable<number>): string => (age == null ? '' : ('0' + age).slice(-2));

export const prepareAgeInputValue = (value?: string): string => {
  const age = ageStringToAudienceAge(value);

  if (!age) return '';

  const { lowerAge, higherAge } = age;

  if (lowerAge == null && higherAge == null) return '';

  return `${prepareAge(lowerAge)}-${prepareAge(higherAge)};`;
};
