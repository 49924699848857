import { LabelPermissionsPaths, ProjectsPaths } from 'app/routing/types';
import { Label } from 'backend-api/models';

export const PATHS_WITH_ACTIVE_LABEL_SELECTOR = [LabelPermissionsPaths.labelPermissions, ProjectsPaths.projects];

export const PATHS_WITH_ALL_LABELS_OPTION = [ProjectsPaths.projects];

export const ALL_LABELS_ID = -1;

export const ALL_LABELS: Label = {
  id: ALL_LABELS_ID,
  name: 'All Labels',
  imageUrl: undefined,
};
