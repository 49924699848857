import {
  ApprovalRequest,
  ArtistTeam,
  Id,
  MediaPlan,
  MediaPlanListItem,
  MediaPlanPhase,
  MediaPlanRelease,
  MediaPlanReleaseUpdateResponse,
  ModifyBulkCampaignsResponse,
  Optional,
  ProjectMetrics,
  ProjectReviewer,
  SubmitApprovalCampaign,
} from 'backend-api/models';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { ApiError } from 'backend-api';
import { actionsGroup } from 'core/store';
import {
  CreateMediaPlanPayload,
  CreatePhasePayload,
  DeleteMediaPlanPayload,
  DeletePhasePayload,
  EditMediaPlanPayload,
  EditPhasePayload,
  GetMediaPlanPayload,
  MediaPlanMode,
  ModifyBulkCampaignsPayload,
  RequestApprovalPayload,
  SubmitApprovalPayload,
  UpdateMediaPlanReleasePayload,
} from './types';
import { SpreadsheetValidationModel } from 'media-plan-v2/containers/spreadsheet/types';
import { UUID } from 'io-ts-types/lib/UUID';

const { action, asyncAction } = actionsGroup('media-plan-v2');

export const getAllMediaPlans = asyncAction<Id, MediaPlanListItem[], ApiError<ProjectDetailsErrorResponse>>(
  'GET_ALL_MEDIA_PLANS'
);

export const getMediaPlan = asyncAction<GetMediaPlanPayload, MediaPlan, ApiError<ProjectDetailsErrorResponse>>(
  'GET_MEDIA_PLAN'
);

export const createPhase = asyncAction<CreatePhasePayload, MediaPlanPhase, ApiError<ProjectDetailsErrorResponse>>(
  'CREATE_PHASE'
);
export const editPhase = asyncAction<EditPhasePayload, MediaPlanPhase, ApiError<ProjectDetailsErrorResponse>>(
  'EDIT_PHASE'
);
export const deletePhase = asyncAction<DeletePhasePayload, Id, ApiError<ProjectDetailsErrorResponse>>('DELETE_PHASE');
export const focusPhaseInput = action<Id>('FOCUS_PHASE_INPUT');
export const resetPhaseInput = action<void>('RESET_PHASE_INPUT');

export const modifyBulkCampaigns = asyncAction<
  ModifyBulkCampaignsPayload,
  ModifyBulkCampaignsResponse,
  ApiError<ProjectDetailsErrorResponse>
>('MODIFY_BULK_CAMPAIGN');

export const clearMediaPlan = action<void>('CLEAR_MEDIA_PLAN');

export const openConfirmModal = action<string>('OPEN_CONFIRM_MODAL');
export const closeConfirmModal = action<void>('CLOSE_CONFIRM_MODAL');

export const setSpreadsheetValidationModel = action<SpreadsheetValidationModel>('SET_SPREADSHEET_VALIDATION_MODEL');

export const getMediaPlanRelease = asyncAction<GetMediaPlanPayload, MediaPlanRelease, Error>('GET_PROJECT_RELEASE');
export const updateMediaPlanRelease = asyncAction<UpdateMediaPlanReleasePayload, MediaPlanReleaseUpdateResponse, Error>(
  'UPDATE_PROJECT_RELEASE'
);

export const selectCampaign = action<UUID>('SELECT_CAMPAIGN');
export const deselectCampaign = action<UUID>('DESELECT_CAMPAIGN');
export const resetCampaignSelection = action<void>('RESET_CAMPAIGN');

export const changeMediaPlanMode = action<MediaPlanMode>('CHANGE_MEDIA_PLAN_MODE');

export const getMediaPlanReviewers = asyncAction<GetMediaPlanPayload, ProjectReviewer[], Error>(
  'GET_MEDIA_PLAN_REVIEWERS'
);

export const requestApproval = asyncAction<RequestApprovalPayload, void, ApiError<ProjectDetailsErrorResponse>>(
  'REQUEST_APPROVAL'
);

export const getApprovalRequests = asyncAction<GetMediaPlanPayload, ApprovalRequest[], Error>('GET_APPROVAL_REQUESTS');

export const getMediaPlanMetrics = asyncAction<GetMediaPlanPayload, ProjectMetrics, Error>('GET_PROJECT_METRICS');

export const addCampaignForApproval = action<SubmitApprovalCampaign>('ADD_CAMPAIGNS_FOR_APPROVAL');
export const resetCampaignsForApproval = action<void>('RESET_CAMPAIGNS_FOR_APPROVAL');

export const setMediaPlanMode = action<MediaPlanMode>('SET_MEDIA_PLAN_MODE');

export const setShowAllCampaignsInApproval = action<boolean>('SET_SHOW_ALL_CAMPAIGNS_IN_APPROVAL');

export const setDisableRequestApprovalButton = action<boolean>('SET_DISABLE_REQUEST_APPROVAL_BUTTON');

export const submitApproval = asyncAction<SubmitApprovalPayload, void, Error>('SUBMIT_APPROVAL');

export const setMediaPlanNotFoundModalOpened = action<boolean>('SET_MEDIA_PLAN_NOT_FOUND_MODAL_OPENED');

export const createMediaPlan = asyncAction<CreateMediaPlanPayload, MediaPlanListItem, Error>('CREATE_MEDIA_PLAN');
export const editMediaPlan = asyncAction<EditMediaPlanPayload, MediaPlanListItem, Error>('EDIT_MEDIA_PLAN');
export const deleteMediaPlan = asyncAction<DeleteMediaPlanPayload, unknown, Error>('DELETE_MEDIA_PLAN');

export const openDeleteMediaPlanConfirmationModal = action<Id>('OPEN_DELETE_MEDIA_PLAN_CONFIRMATION_MODAL');
export const closeDeleteMediaPlanConfirmationModal = action<void>('CLOSE_DELETE_MEDIA_PLAN_CONFIRMATION_MODAL');

export const openMediaPlansDropdown = action<void>('OPEN_MEDIA_PLANS_DROPDOWN');
export const closeMediaPlansDropdown = action<void>('CLOSE_MEDIA_PLANS_DROPDOWN');

export const openMediaPlanUpdatedWarningModal = action<void>('OPEN_UPDATED_MEDIA_PLAN_WARNING_MODAL');
export const closeMediaPlanUpdatedWarningModal = action<void>('CLOSE_UPDATED_MEDIA_PLAN_WARNING_MODAL');

export const getProjectArtistTeam = asyncAction<Id, Optional<ArtistTeam>, Error>('GET_PROJECT_ARTIST_TEAM');
