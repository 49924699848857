import styled from 'styled-components';
import { motion } from 'framer-motion';

interface STableLoaderProps {
  readonly maxWidth?: number;
}

export const STableLoader = styled(motion.tbody)<STableLoaderProps>`
  .table-loader {
    &__loader {
      max-width: ${({ maxWidth }) => `${maxWidth}px` || '100vw'};
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
