import styled from 'styled-components';
import { ReusableModal } from 'common/components/reusable-modal';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { colorPalette } from 'app/theme/colors';
import { MODAL_CONTENT_HEIGHT } from './constants';

export const BEM_CLASS = 'view-team-modal';

export const SViewTeamModal = styled(ReusableModal)`
  .${BEM_CLASS} {
    &__header {
      background: ${colorPalette.ghostWhite};
      height: 60px;
      display: flex;
      width: 100%;
    }

    &__title-container {
      display: flex;
      align-items: center;
      margin-right: 42px;
    }

    &__title {
      margin-left: 20px;
      flex-shrink: 0;
      ${elipsizeText};
    }

    &__label-badge {
      margin-left: 10px;
    }

    &__list {
      height: ${MODAL_CONTENT_HEIGHT}px;
      overflow: auto;
    }

    &__loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${MODAL_CONTENT_HEIGHT}px;
    }

    &__list-item {
      height: 70px;
      display: flex;
      align-items: center;

      &:nth-child(even) {
        background-color: ${colorPalette.whisper};
      }
    }

    &__avatar {
      margin-left: 20px;
    }

    &__name-container {
      flex: 3;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    &__role {
      flex: 2;
      margin-left: 20px;
      color: ${colorPalette.eastBay};
      ${elipsizeText}
    }

    &__name {
      font-weight: 600;
    }

    &__labels {
      color: ${colorPalette.eastBay};
    }

    &__footer {
      border-top: 1px solid ${colorPalette.ghostWhite};
      height: 60px;
      display: flex;
      width: 100%;
      align-items: center;
      column-gap: 20px;
    }

    &__button {
      display: flex;
      align-items: center;
      column-gap: 9px;
      cursor: pointer;
    }

    &__subtitle {
      font-weight: 600;
      color: ${colorPalette.azureRadiance};
    }
  }
`;
