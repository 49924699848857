import React from 'react';
import { bem } from 'utils/bem';
import { PrimaryLabel } from '../typography';
import { BEM_CLASS, SToggleSwitch } from './s-toggle-switch';

interface Props {
  checked?: boolean;
  disabled?: boolean;
  onCheckChange(): void;
  label?: string;
  className?: string;
  dataSelector?: string;
}

const classes = bem(BEM_CLASS);

export const ToggleSwitch = React.memo(
  ({ checked, onCheckChange, label, disabled, className, dataSelector }: Props) => {
    return (
      <SToggleSwitch className={className}>
        <span className={classes('switch-container')}>
          <input
            type="checkbox"
            className={classes('checkbox')}
            data-selector={`${dataSelector}-toggle`}
            checked={checked}
            onChange={onCheckChange}
            disabled={disabled}
          />
          <span className={classes('switch-container')}>
            <span className={classes('inner')} />
            <span className={classes('switch')} />
          </span>
        </span>
        <PrimaryLabel className={classes('toggle-label')} data-selector={`${dataSelector}-toggle-label`}>
          {label}
        </PrimaryLabel>
      </SToggleSwitch>
    );
  }
);
