import styled from 'styled-components';
import { transparentize } from 'polished';
import { palette } from 'frontend-shared-theme';
import { Dropdown } from '../dropdown';

export const SMenuDropdown = styled(Dropdown)`
  .menu-dropdown {
    &__menu-dropdown-list {
      width: 200px;
      right: 0;
    }
    &__icon-button {
      min-width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      cursor: pointer;
      transition: background 0.8s;
      border-radius: 35px;
      padding: 0;
      background-size: 0;
      &:hover {
        background-color: ${transparentize('0.5', palette.athensGrayTwo)};
      }
      &:active {
        background-color: ${palette.athensGrayTwo};
        background-size: 100%;
        transition: background 0s;
      }
      &--disabled {
        pointer-events: none;
        opacity: 0.1;
      }
    }
    &__icon {
      height: 14px;
      display: flex;
      align-content: center;
      justify-content: center;
      &--disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }
`;
