import styled from 'styled-components';
import { animatedFlare } from 'common/s-components/s-animated-flare';
import { uniqueId } from 'lodash';

export const BEM_CLASS = 'tabbed-loader';
export const CLIP_PATH_ID = uniqueId(BEM_CLASS);
export const LOADER_HEIGHT = 110;

interface STabbedLoaderProps {
  clipPathId: string;
}

export const STabbedLoader = styled.div<STabbedLoaderProps>`
  width: 440px;
  height: ${LOADER_HEIGHT}px;

  .${BEM_CLASS} {
    &__container {
      width: 100%;
      height: ${LOADER_HEIGHT}px;
      clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      -webkit-clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      ${animatedFlare}
    }
  }
`;
