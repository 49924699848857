import React, { useMemo } from 'react';
import { Button, BUTTON_SIZE, BUTTON_TYPE, THEME, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { Loading } from 'common-v2/types';
import { Modal, ModalContentProps } from 'common-v2/components';
import { SEditArtistsModal, BEM_CLASS } from './s-edit-artists-modal';
import { Footer, Recent, SearchInput } from './components';
import { MAX_FEATURED_ARTISTS_COUNT } from 'project-v2/containers/edit-artists-modal-container/constants';

interface EditArtistsModalProps {
  isOpened: boolean;
  loading: Loading;
  onClose(): void;
  onApply(): void;
  title: string;
  addedNodes: React.ReactNode[];
  recentNodes: React.ReactNode[];
  suggestedNodes: React.ReactNode[];
  onClearAll(): void;
  searchValue: string;
  onSearchChange: React.ChangeEventHandler<HTMLInputElement>;
  clearSearch(): void;
}

const classes = bem(BEM_CLASS);

export const EditArtistsModal = React.memo(
  ({
    isOpened,
    onClose,
    onApply,
    title,
    onClearAll,
    searchValue,
    onSearchChange,
    clearSearch,
    addedNodes,
    recentNodes,
    suggestedNodes,
  }: EditArtistsModalProps) => {
    const isNoItems = addedNodes.length === 0 && recentNodes.length === 0;

    const recentSection = useMemo(
      () => recentNodes.length > 0 && <Recent recentNodes={recentNodes} className={classes('recent-artists')} />,
      [recentNodes]
    );

    const emptyState = useMemo(
      () => (
        <Typography className={classes('empty-text')} type={TYPOGRAPHY_TYPE.body4}>
          There are currently no artists added.
          <br />
          To continue, type artist name on the search.
        </Typography>
      ),
      []
    );

    const listContent = useMemo(() => {
      return (
        <div className={classes('list')}>
          {isNoItems ? (
            <div className={classes('empty')}>{emptyState}</div>
          ) : (
            <>
              {recentSection}
              <div className={classes('artists-list')}>{addedNodes}</div>
            </>
          )}
        </div>
      );
    }, [addedNodes, emptyState, isNoItems, recentSection]);

    const isMaxItems = addedNodes.length + recentNodes.length >= MAX_FEATURED_ARTISTS_COUNT;

    const renderContent = (props: ModalContentProps) => (
      <SEditArtistsModal>
        <header className={classes('header')}>
          <Typography
            className={classes('title')}
            type={TYPOGRAPHY_TYPE.heading2}
            data-selector="edit-artists-modal-title"
          >
            {title}
          </Typography>
          <Button
            theme={THEME.light}
            type={BUTTON_TYPE.tertiary}
            size={BUTTON_SIZE.smallRound}
            icon="cross"
            onClick={props.closeModal}
            data-selector="edit-artists-modal-close-button"
          />
        </header>
        <div className={classes('content')}>
          <SearchInput
            value={searchValue}
            onSearchChange={onSearchChange}
            isDisabled={isMaxItems}
            suggestedNodes={suggestedNodes}
            clearSearch={clearSearch}
          />
          {listContent}
        </div>
        <Footer
          onClearAll={onClearAll}
          onCancel={props.closeModal}
          onApply={onApply}
          isClearAllDisabled={isNoItems}
          className={classes('footer')}
        />
      </SEditArtistsModal>
    );

    return <Modal isOpened={isOpened} renderContent={renderContent} onClose={onClose} />;
  }
);
