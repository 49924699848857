import React from 'react';
import { BEM_CLASS, SAssignedCampaignsList } from './s-assigned-campaigns-list';
import { CampaignDetails } from 'backend-api/models';
import { bem } from 'utils/bem';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { CampaignItem } from './components';
import { EmptyResult } from '../empty-result';

const classes = bem(BEM_CLASS);

interface Props {
  campaigns: CampaignDetails[];
  disabled?: boolean;
  sideButton?: React.ReactNode;
  onCampaignDelete?(id: number): void;
}

export const AssignedCampaignsList = React.memo(({ campaigns, disabled, sideButton, onCampaignDelete }: Props) => (
  <SAssignedCampaignsList>
    <div className={classes('header')}>
      <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('title')}>
        {campaigns.length ? `Selected (${campaigns.length})` : 'Selected'}
      </Typography>
      {sideButton}
    </div>
    <div className={classes('wrapper')}>
      {campaigns.length ? (
        campaigns.map(({ id, name }) => (
          <CampaignItem key={id} id={id} title={name} disabled={disabled} onCampaignDelete={onCampaignDelete} />
        ))
      ) : (
        <EmptyResult>
          <Typography type={TYPOGRAPHY_TYPE.body4}>There are currently no campaigns selected.</Typography>
          <Typography type={TYPOGRAPHY_TYPE.body4}>To continue, select from the left-side.</Typography>
        </EmptyResult>
      )}
    </div>
  </SAssignedCampaignsList>
));
