import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { BaseRenderer } from '../../base-renderer';
import { MultiValueView } from '../../multi-value-view';
import { SpreadsheetTooltip } from '../../spreadsheet-tooltip';
import { TooltipContent } from './components';
import { AD_CREATIVE_MULTIPLE_VALIDATION_TEXT, AD_CREATIVE_SINGLE_VALIDATION_TEXT } from '../../../constants';
import { getLinksWithLabels } from 'common/components/select/containers/link-input-multi-select/transducers';
import { SpreadsheetRowData } from 'media-plan/components/spreadsheet/types';

export const LinksRenderer = ({
  value: { color, value = [] } = { color: null, value: null },
  context,
}: ICellRendererParams<
  SpreadsheetRowData,
  SpreadsheetRowData['adCreativeLinks'] | SpreadsheetRowData['destinationLinks']
>) => {
  const mappedValues = useMemo(() => getLinksWithLabels(value || [], true), [value]);
  const isAnyInvalid = useMemo(() => mappedValues.some(link => link.isInvalid), [mappedValues]);
  const labels = useMemo(() => mappedValues.map(item => item.label || ''), [mappedValues]);

  return (
    <SpreadsheetTooltip
      content={
        <TooltipContent
          values={mappedValues}
          errorText={value?.length === 1 ? AD_CREATIVE_SINGLE_VALIDATION_TEXT : AD_CREATIVE_MULTIPLE_VALIDATION_TEXT}
        />
      }
      disabled={!isAnyInvalid}
    >
      <div style={{ height: '100%' }}>
        <BaseRenderer color={color} placeholder="Paste URL here…" accessMode={context.accessMode}>
          {labels?.length !== 0 && <MultiValueView values={labels} isTooltipDisabled={isAnyInvalid} />}
        </BaseRenderer>
      </div>
    </SpreadsheetTooltip>
  );
};
