import React from 'react';
import { FeatureFlagsContext } from 'common-v2/context';
import { IFeatureFlagsContext } from 'common-v2/types';

export const useFeatureFlagsContext = () => {
  const context = React.useContext<IFeatureFlagsContext | undefined>(FeatureFlagsContext);

  if (!context) {
    throw new Error('useFeatureFlagsContext must be used within a Provider');
  }

  return context;
};
