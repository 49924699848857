import styled from 'styled-components';
import { theme } from 'app/theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { transparentize } from 'polished';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'media-plan-dropdown-popup';

export const SPopup = styled.div`
  background: white;
  overflow: auto;
  box-sizing: content-box;
  border-radius: 10px;
  min-width: 182px;
  max-width: 260px;
  max-height: 305px;
  box-shadow: ${theme.shadows.default};
  padding: 15px 0;
  display: flex;
  flex-direction: column;

  .${BEM_CLASS} {
    &__item {
      cursor: pointer;
      display: block;
      min-height: 30px;
      padding: 5px 20px;
      width: 100%;
      ${elipsizeText};

      &:hover {
        background: ${transparentize(0.9, colorPalette.azureRadiance)};
      }
    }
  }
`;
