import React from 'react';
import { BEM_CLASS, SPopup } from './s-popup';
import { bem } from 'utils/bem';
import { Id, IdNameField } from 'backend-api/models';
import { Body } from 'common/components/typography';

interface Props {
  mediaPlanList?: IdNameField[];
  onItemClick(id: Id): void;
}

const classes = bem(BEM_CLASS);

export const Popup = React.memo(({ mediaPlanList, onItemClick }: Props) => (
  <SPopup>
    {mediaPlanList?.map(mediaPlan => (
      <Body key={mediaPlan.id} className={classes('item')} onClick={() => onItemClick(mediaPlan.id)}>
        {mediaPlan.name}
      </Body>
    ))}
  </SPopup>
));
