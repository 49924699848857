import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { theme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';
import { typography } from 'app/theme/typography';

export const SSelectedUserItem = styled.div`
  display: flex;
  border-radius: 5px;
  height: 46px;
  box-sizing: border-box;
  background: linear-gradient(0deg, ${colorPalette.whisper} 100%, ${colorPalette.white} 0%);
  box-shadow: ${theme.shadows.default};
  padding-right: 13px;
  position: relative;
  align-items: center;

  &:hover .selected-artist-playlist-item__icon {
    opacity: 1;
  }

  .selected-artist-playlist-item {
    &__avatar {
      margin-left: 10px;
      box-shadow: none;
    }
    &__info-container {
      ${elipsizeText};
      ${typography.smallText}
      flex: 1;
      margin-left: 15px;
      color: ${palette.darkBlue};
      padding-right: 10px;
      font-weight: 500;
    }
    &__icon {
      align-self: center;
      cursor: pointer;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }
  }
`;
