import React from 'react';
import { bem } from 'utils/bem';
import { Loader } from 'common/components/loader';
import { colorPalette } from 'app/theme/colors';
import { STableInfiniteLoader, BEM_CLASS } from './s-table-infinite-loader';

interface TableInfiniteLoaderProps {
  colSpan: number;
}

const classes = bem(BEM_CLASS);

export const TableInfiniteLoader = React.memo(({ colSpan }: TableInfiniteLoaderProps) => {
  return (
    <STableInfiniteLoader>
      <td className={classes('loader')} colSpan={colSpan}>
        <Loader size={22} color={colorPalette.eastBay} isActive />
      </td>
    </STableInfiniteLoader>
  );
});
