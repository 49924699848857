import React from 'react';
import { PlaceholderProps } from 'react-select';
import { BaseOption as BaseOptionType } from '../../types';
import { SBasePlaceholder } from './s-base-placeholder';

type Props<T extends BaseOptionType> = PlaceholderProps<T, false>;

const Root = <T extends BaseOptionType>({ children, innerProps }: Props<T>) => (
  <SBasePlaceholder {...innerProps} ref={null}>
    {children}
  </SBasePlaceholder>
);

export const BasePlaceholder = React.memo(Root) as typeof Root;
