import React, { useImperativeHandle, useState } from 'react';
import { SDateEditor } from './s-date-editor';
import { DateInput } from '../../date-input';
import { SpreadsheetTooltip } from '../../spreadsheet-tooltip';
import { GridApi } from 'ag-grid-community';
import { useEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan/components/spreadsheet/hooks';
import { SpreadsheetCellData } from 'media-plan/components/spreadsheet/types';

interface DateEditorProps {
  initialValue: SpreadsheetCellData<any>;
  api: GridApi;
  tooltipContent: React.ReactChild | React.ReactChild[];
  isTooltipVisible: boolean;
  rowIndex: number;
  colKey: string;
}

export const DateEditor = React.memo(
  React.forwardRef(({ initialValue, api, tooltipContent, isTooltipVisible }: DateEditorProps, ref) => {
    const [value, setValue] = useState(initialValue.value);
    const { onBlur } = useStopEditingBlur(api);
    const { onKeyDown } = useEditorKeyboardInteractions({ api, value });

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          ...initialValue,
          value,
        };
      },
    }));

    return (
      <SpreadsheetTooltip content={tooltipContent} disabled={!isTooltipVisible} visible={isTooltipVisible}>
        <SDateEditor>
          <DateInput value={value} onChange={setValue} onKeyDown={onKeyDown} onBlur={onBlur} />
        </SDateEditor>
      </SpreadsheetTooltip>
    );
  })
);
