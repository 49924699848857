import styled from 'styled-components';

export const BEM_CLASS = 'input-field';

export const SInputField = styled.div`
  .${BEM_CLASS} {
    &__input {
      width: 100%;
    }
  }
`;
