import React from 'react';
import { BEM_CLASS, SMenuFooter } from './s-menu-footer';
import { Button } from 'common/components/button';
import { bem } from 'utils/bem';

interface Props {
  onClearAll(event: React.MouseEvent): void;
  onApply(event: React.MouseEvent): void;
  isClearAllShown?: boolean;
}

const classes = bem(BEM_CLASS);

export const MenuFooter = React.memo(({ onClearAll, onApply, isClearAllShown }: Props) => {
  return (
    <SMenuFooter>
      {isClearAllShown && (
        <Button
          variant="secondary-gray"
          className={classes('button')}
          onClick={onClearAll}
          dataSelector="clear-all-button"
        >
          Clear All
        </Button>
      )}
      <Button
        variant="secondary-cta"
        className={classes('button')}
        onClick={onApply}
        dataSelector="apply-changes-button"
      >
        Apply
      </Button>
    </SMenuFooter>
  );
});
