import styled from 'styled-components';

export const BEM_CLASS = 'search-field';

export const SSearchField = styled.div`
  max-width: 420px;

  .${BEM_CLASS} {
    &__input {
      &--dark {
        .input__icon,
        .input__close {
          &::before {
            color: rgba(116, 119, 140, 1);
          }
        }

        input {
          background-color: rgba(48, 50, 63, 1);
          color: rgba(191, 194, 217, 1);

          &::placeholder { {
            color: rgba(191, 194, 217, 1);
          }
        }
      }
    }
  }
`;
