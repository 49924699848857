import styled from 'styled-components';

interface SLabelProps {
  readonly editable?: boolean;
}

export const SLabel = styled.div<SLabelProps>`
  padding-top: ${({ editable }) => (editable ? '10px' : '3px')};
  color: #4d5280;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 15px;
  text-transform: uppercase;
`;
