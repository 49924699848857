import React from 'react';
import { SAddRow } from './s-add-row';

interface Props {
  onClick(props): void;
}

export const AddRow = React.memo(
  React.forwardRef<HTMLButtonElement, Props>(({ onClick }, forwardedRef) => (
    <SAddRow ref={forwardedRef} tabIndex={-1} onClick={onClick}>
      Add Row
    </SAddRow>
  ))
);
