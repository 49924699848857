import {
  Label,
  LabelPermissionsArtist,
  LabelPermissionsArtistInfo,
  LabelPermissionsArtistWithMembers,
  LabelPermissionsMember,
  LabelPermissionsTeamMembers,
  LabelPermissionsUser,
  LabelUser,
} from './index';

const getDefaultsLabel = (): Label => ({
  id: 0,
  name: 'Artist',
  imageUrl: undefined,
});

export const getLabelMock = (p?: Partial<Label>): Label => ({
  ...getDefaultsLabel(),
  ...p,
});

const getDefaultsLabelUser = (): LabelUser => ({
  id: 0,
  name: 'Artist',
  phone: '+18015555555',
  email: 'email@gmail.com',
  isAdmin: false,
});

export const getLabelUserMock = (p?: Partial<LabelUser>): LabelUser => ({
  ...getDefaultsLabelUser(),
  ...p,
});

const getDefaultsLabelPermissionsArtistInfo = (): LabelPermissionsArtistInfo => ({
  id: 'id',
  name: 'name',
  imageUrl: 'https://images.com/image/1',
});

export const getLabelPermissionsArtistInfoMock = (
  p?: Partial<LabelPermissionsArtistInfo>
): LabelPermissionsArtistInfo => ({
  ...getDefaultsLabelPermissionsArtistInfo(),
  ...p,
});

const getDefaultsLabelPermissionsTeamMembers = (): LabelPermissionsTeamMembers => ({
  id: 1,
  name: 'name',
  email: 'name@mail.com',
  avatarUrl: 'https://images.com/image/1',
  role: 1,
});

export const getLabelPermissionsTeamMembersMock = (
  p?: Partial<LabelPermissionsTeamMembers>
): LabelPermissionsTeamMembers => ({
  ...getDefaultsLabelPermissionsTeamMembers(),
  ...p,
});

const getDefaultsLabelPermissionsMember = (): LabelPermissionsMember => ({
  user: getLabelPermissionsUserMock(),
  role: 1,
  artists: [getLabelPermissionsArtistInfoMock()],
});

export const getLabelPermissionsTeamMemberMock = (p?: Partial<LabelPermissionsMember>): LabelPermissionsMember => ({
  ...getDefaultsLabelPermissionsMember(),
  ...p,
});

const getDefaultsLabelPermissionsUser = (): LabelPermissionsUser => ({
  id: 1,
  name: 'name',
  email: 'name@mail.com',
  avatarUrl: 'https://images.com/image/1',
});

export const getLabelPermissionsUserMock = (p?: Partial<LabelPermissionsUser>): LabelPermissionsUser => ({
  ...getDefaultsLabelPermissionsUser(),
  ...p,
});

const getDefaultsLabelPermissionsArtistWithMembers = (): LabelPermissionsArtistWithMembers => ({
  artist: getLabelPermissionsArtistInfoMock(),
  teamMembers: [getLabelPermissionsTeamMembersMock({ id: 1 }), getLabelPermissionsTeamMembersMock({ id: 2 })],
});

export const getLabelPermissionsArtistWithMembersMock = (
  p?: Partial<LabelPermissionsArtistWithMembers>
): LabelPermissionsArtistWithMembers => ({
  ...getDefaultsLabelPermissionsArtistWithMembers(),
  ...p,
});

const getDefaultsLabelPermissionsArtist = (): LabelPermissionsArtist => ({
  id: 'id',
  name: 'name',
});

export const getLabelPermissionsArtistMock = (p?: Partial<LabelPermissionsArtist>): LabelPermissionsArtist => ({
  ...getDefaultsLabelPermissionsArtist(),
  ...p,
});
