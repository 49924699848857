import React from 'react';
import { merge } from 'lodash';
import { BaseOption as BaseOptionType, SelectMenuProps, SelectType, SearchableSimpleSelectProps } from '../../types';
import { SimpleSelect } from '../simple-select';
import { SearchableSelect } from '../searchable-select';

const Root = <T extends BaseOptionType>(props: SearchableSimpleSelectProps<T> & SelectMenuProps) => (
  <SimpleSelect
    {...props}
    type={SelectType.Simple}
    selectContainer={simpleSelectProps => (
      <SearchableSelect {...merge(simpleSelectProps, props)} type={SelectType.Searchable} />
    )}
  />
);

export const SearchableSimpleSelect = React.memo(Root) as typeof Root;
