import styled from 'styled-components';
import { BgStyle } from 'common/styles/utils';
import { SInput } from 'common/components/form/input/s-input';

interface Props {
  bgStyle: BgStyle;
  disabled?: boolean;
}

export const SDateInput = styled.div<Props>`
  position: relative;
  .date-input {
    &__input {
      ${SInput};

      &--error {
        &:focus {
          border: 1px solid ${({ theme }) => theme.colors.input.border.error};
        }
      }
    }
    &__icon {
      top: 12px;
      right: 12px;
      position: absolute;
    }
  }
`;
