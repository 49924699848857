import React from 'react';
import { SArtistSearchItem } from './s-artist-search-item';
import { TooltipedText } from 'common/components/tooltiped-text';
import { pluralizeString } from 'utils/string';
import { STitle } from 'app/components/search-modal/styled';
import { SmallText } from 'common/components/typography';

interface ArtistSearchItemProps {
  title: string;
  projectsCount?: number;
}

export const ArtistSearchItem = React.memo(({ title, projectsCount }: ArtistSearchItemProps) => (
  <SArtistSearchItem>
    <TooltipedText text={title}>
      <STitle>{title}</STitle>
    </TooltipedText>
    <SmallText>
      {projectsCount && projectsCount > 0
        ? `${projectsCount} ${pluralizeString('project', projectsCount)}`
        : 'No projects'}
    </SmallText>
  </SArtistSearchItem>
));
