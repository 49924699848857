import styled from 'styled-components';
import { WaveBackground } from 'common/components/wave-background';

export const BEM_CLASS = 'details';

export const SDetails = styled(WaveBackground)`
  flex-grow: 1;
  padding-bottom: 40px;
  margin-bottom: auto;

  .${BEM_CLASS} {
    &__head {
      display: flex;
      align-items: center;
      padding: 34px 0 29px;
    }

    &__title,
    &__badge {
      margin-right: 10px;
    }

    &__badge,
    &__anchor {
      flex-shrink: 0;
    }

    &__anchor {
      margin-left: auto;
    }

    &__empty {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 390px;
    }
  }
`;
