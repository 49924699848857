import React from 'react';
import { SDaysLeftBadge } from 'projects/components/small-artists-section/components/small-artist-item/days-left-badge/s-days-left-badge';
import { pluralizeString } from 'utils/string';

interface Props {
  daysLeft: number;
  className?: string;
}

export const DaysLeftBadge = React.memo(({ daysLeft, className }: Props) => (
  <SDaysLeftBadge className={className}>
    In {daysLeft} {pluralizeString('day', daysLeft)}
  </SDaysLeftBadge>
));
