import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { colorPalette } from 'app/theme/colors';

interface Props {
  disabled?: boolean;
}

export const SSelectedSectionHeader = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 14px;

  .selected-section-header {
    &__reset {
      color: ${({ disabled }) => (disabled ? colorPalette.osloGray : palette.brightBlue)};
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }
`;
