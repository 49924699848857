import Auth0Lock from 'auth0-lock';
import logoImage from 'assets/decibel-logo-sign-in.svg';
//
// export const auth0 = new Auth0Js.WebAuth({
// 	domain: process.env.AUTH0_DOMAIN,
// 	clientID: process.env.AUTH0_CLIENT_ID,
// 	redirectUri:  window.location.origin + '/auth/callback',
// 	responseType: 'token id_token',
// 	scope: 'openid',
// 	rememberLastLogin: false,
// });

// https://auth0.com/docs/libraries/lock/v11/configuration#index-of-configurable-options
let lockOptions = {
  auth: {
    // redirectUrl: process.env.AUTH0_REDIRECT_URI,
    redirectUrl: `${window.location.origin}/auth/callback`,
    // responseType: 'code',
    audience: process.env.AUTH0_API_AUDIENCE,
    responseType: 'token id_token',
    params: {
      scope: 'openid profile', // Learn about scopes: https://auth0.com/docs/scopes
    },
    sso: true,
  },
  allowSignUp: process.env.NODE_ENV === 'development',
  // DISPLAY
  languageDictionary: {
    title: 'Please login with your Sony credentials',
    error: {
      login: {
        blocked_user:
          'Your account has been disabled. Please contact DataAnalytics@sonymusic.com to activate your account.',
      },
    },
  },
  closable: false,
  rememberLastLogin: false,
  theme: {
    logo: logoImage,
  },
  configurationBaseUrl: 'https://cdn.auth0.com',
  hashCleanup: true,
};

export const auth0Lock = new Auth0Lock(process.env.AUTH0_CLIENT_ID, process.env.AUTH0_DOMAIN, lockOptions);
