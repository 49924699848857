import React from 'react';
import { bem } from 'utils/bem';
import { Button } from 'common/components/button';
import { Tooltip } from 'common/components/tooltip';
import { SModalFooter } from './s-modal-footer';

interface Props {
  cancelText?: string;
  submitText?: string;
  submitTooltipContent?: string;
  submitTooltipEnabled?: boolean;
  isSubmitDisabled?: boolean;
  onCancel?(): void;
  onSubmit?(): void;
  dataSelector?: string;
}

const classes = bem('modal-footer');

export const ModalFooter = React.memo(
  ({
    cancelText = 'Cancel',
    submitText = 'Save',
    submitTooltipContent = '',
    submitTooltipEnabled = false,
    isSubmitDisabled = false,
    onCancel,
    onSubmit,
    dataSelector,
  }: Props) => {
    return (
      <SModalFooter>
        <Button
          className={classes('button')}
          dataSelector={`${dataSelector}-cancel-button`}
          variant="secondary-gray"
          onClick={onCancel}
        >
          {cancelText}
        </Button>
        <Tooltip className={classes('button')} content={submitTooltipContent} enabled={submitTooltipEnabled}>
          <div>
            <Button
              dataSelector={`${dataSelector}-submit-button`}
              disabled={isSubmitDisabled}
              variant="primary-cta"
              onClick={onSubmit}
            >
              {submitText}
            </Button>
          </div>
        </Tooltip>
      </SModalFooter>
    );
  }
);
