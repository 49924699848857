import { Nullable } from 'backend-api/models';
import { Button } from 'common/components/button';
import { Icon } from 'common/components/icon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bem } from 'utils/bem';
import { pluralizeString } from 'utils/string';
import { BEM_CLASS, SConfirmReviewFooter } from './s-confirm-review-footer';

const classes = bem(BEM_CLASS);

interface ConfirmReviewProps {
  selectedCount: number;
  allCount: number;
  hasError?: boolean;
  onConfirm(): void;
  onGoHome(): void;
}

interface ConfirmReviewWidgetConfig {
  info: Nullable<JSX.Element>;
  button: Nullable<JSX.Element>;
}

export const ConfirmReviewFooter = React.memo(
  ({ selectedCount, allCount, onConfirm, onGoHome, hasError }: ConfirmReviewProps) => {
    const [isWarningVisible, setIsWarningVisible] = useState(false);

    const handleConfirm = useCallback(() => {
      if (selectedCount !== 0) {
        onConfirm();
        return;
      }

      setIsWarningVisible(true);
    }, [onConfirm, selectedCount]);

    useEffect(() => {
      if (selectedCount > 0) setIsWarningVisible(false);
    }, [selectedCount]);

    const widgetConfig = useMemo<ConfirmReviewWidgetConfig>(() => {
      if (hasError) {
        return {
          info: null,
          button: (
            <button onClick={onGoHome} className={classes('home-page-button')} disabled>
              Go to Home Page
            </button>
          ),
        };
      }

      if (allCount === 0) {
        return {
          info: (
            <>
              There are <b>no</b> campaigns to review
            </>
          ),
          button: (
            <button onClick={onGoHome} className={classes('home-page-button')}>
              Go to Home Page
            </button>
          ),
        };
      }

      if (isWarningVisible) {
        return {
          info: (
            <div className={classes('warning-container')}>
              <Icon className={classes('warning-icon')} size="small" name="warning" color="cinnabar" />
              <span className={classes('empty-review-text')}>Please review campaign(s) to continue.</span>
            </div>
          ),
          button: (
            <Button
              className={classes('button')}
              variant="primary-cta"
              onClick={onConfirm}
              dataSelector="media-plan-confirm-review-button"
            >
              Confirm
            </Button>
          ),
        };
      }
      return {
        info: (
          <>
            <b>{selectedCount}</b> out of <b>{allCount}</b>
            {` ${pluralizeString('campaign', allCount)} reviewed`}
          </>
        ),
        button: (
          <Button
            className={classes('button')}
            variant="primary-cta"
            onClick={handleConfirm}
            dataSelector="media-plan-confirm-review-button"
          >
            Confirm
          </Button>
        ),
      };
    }, [allCount, hasError, isWarningVisible, selectedCount, handleConfirm, onGoHome, onConfirm]);

    return (
      <SConfirmReviewFooter>
        <div className={classes('info')} data-selector="confirm-review-footer-info">
          {widgetConfig.info}
        </div>
        <div className={classes('button-container')}>{widgetConfig.button}</div>
      </SConfirmReviewFooter>
    );
  }
);
