import { animatedFlare } from 'common/s-components/s-animated-flare';
import styled from 'styled-components';

export const BEM_CLASS = 'summary-metric';
export const CLIP_PATH_ID = 'summary-metric';

export const SSummaryMetricLoader = styled.div`
  width: 40px;
  height: 12px;

  .${BEM_CLASS} {
    &__clip-container {
      width: 40px;
      height: 12px;
      clip-path: url(#${CLIP_PATH_ID});
      -webkit-clip-path: url(#${CLIP_PATH_ID});
      ${animatedFlare}
    }
  }
`;
