import styled from 'styled-components';

interface SModalBodyProps {
  readonly height: string;
}

export const SModalBody = styled.div<SModalBodyProps>`
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  word-wrap: break-word;
  height: ${({ height }) => height};
`;
