import React, { useMemo } from 'react';
import { ArtistRosterProject, Campaign, ProjectDetails } from 'backend-api/models';
import { ModalSideBarConfig, ReusableModal } from 'common/components/reusable-modal';
import { BulkCampaignsAssignmentTableContainer } from '../../containers/bulk-campaigns-assignment-table-container';
import { SelectionSidebar } from './components/sidebar';

interface Props {
  project: ProjectDetails | ArtistRosterProject;
  isOpened: boolean;
  selectedCampaigns: Campaign[];
  searchTerm?: string;
  onCloseModal(): void;
  onSearchChange(query: string): void;
  resetAllSelectedCampaigns(): void;
  onAssignCampaigns(): void;
  onModalClosed(): void;
}

export const BulkCampaignsAssignmentModal = React.memo(
  ({
    project,
    isOpened,
    selectedCampaigns,
    searchTerm,
    onCloseModal,
    onSearchChange,
    resetAllSelectedCampaigns,
    onAssignCampaigns,
    onModalClosed,
  }: Props) => {
    const sidebarConfig: ModalSideBarConfig = useMemo(
      () => ({
        component: (
          <SelectionSidebar
            selectedCampaigns={selectedCampaigns}
            onResetAllSelectedCampaigns={resetAllSelectedCampaigns}
          />
        ),
      }),
      [resetAllSelectedCampaigns, selectedCampaigns]
    );

    return (
      <ReusableModal
        title={`Assign Campaigns to ${project.name}`}
        onClose={onCloseModal}
        width="1180px"
        height="600px"
        isOpen={isOpened}
        hasSearch
        hasHeader
        hasFooter
        sideBarConfig={sidebarConfig}
        submitText="Assign to Project"
        searchPlaceholder="Search by campaign name"
        searchValue={searchTerm}
        onSearchChange={onSearchChange}
        isSubmitDisabled={selectedCampaigns.length === 0}
        onSubmit={onAssignCampaigns}
        onAfterClose={onModalClosed}
        dataSelector="campaign-search-input-modal"
      >
        <BulkCampaignsAssignmentTableContainer project={project} />
      </ReusableModal>
    );
  }
);
