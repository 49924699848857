import styled from 'styled-components';

export const BEM_CLASS = 'long-table-title';

export const SLongTableTitle = styled.div`
  overflow: hidden;
  .${BEM_CLASS} {
    &__long-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 100%;
      line-height: 20px;
      outline: none;
      vertical-align: middle;
    }
  }
`;
