import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { theme } from 'app/theme';

export const SSelectedArtistPlaylistItem = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  height: 46px;
  box-sizing: border-box;
  background: linear-gradient(0deg, rgba(247, 247, 250, 1) 100%, rgba(255, 255, 255, 1) 0%);
  box-shadow: ${theme.shadows.default};
  padding-right: 13px;
  position: relative;
  align-items: center;
  min-width: 0;

  &:hover .selected-artist-playlist-item__icon {
    opacity: 1;
  }

  .selected-artist-playlist-item {
    &__images-container {
      width: 46px;
      height: 100%;
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 5px 0 0 5px;
    }
    &__info-container {
      flex: 1;
      margin-left: 20px;
      color: ${palette.darkBlue};
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      min-width: 0;
      padding-right: 10px;
      font-weight: 500;
      ${elipsizeText};
    }
    &__icon {
      align-self: center;
      cursor: pointer;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }

    &__lock-icon {
      display: flex;
      align-self: center;
    }
  }
`;
