import styled from 'styled-components';

export const BEM_CLASS = 'approval-status-editor';

export const SApprovalStatusEditor = styled(({ className, children }) => children(className))`
  .${BEM_CLASS} {
    &__select-container {
      height: 100%;
    }

    &__control {
      width: 100%;
      overflow: hidden;
      height: 41px;
      border-radius: 0;
    }

    &__menu {
      width: 220px;
      border-radius: 10px;
    }

    &__value-container-root {
      height: 100%;
      padding: 0;
    }

    &__value-container {
      padding: 8px var(--cell-horizontal-padding);
    }

    &__indicators-container {
      height: 100%;
      overflow: hidden;
      padding-right: var(--cell-horizontal-padding);
      padding-left: var(--cell-horizontal-padding);
    }

    &__option-container {
      height: 30px;
    }
  }
`;
