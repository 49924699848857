import styled from 'styled-components';
import NoResults from 'assets/no-results.svg';
import { palette } from 'frontend-shared-theme';

export const SBody = styled.div`
  .project-edit-form {
    &__name-field {
      margin-top: 35px;
    }

    &__selection-state-artists-playlists-header {
      &--selecting {
        padding-bottom: 15px;
      }
    }

    &__toolbar {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    &__button {
      &--first {
        margin-right: 10px;
      }
    }
    &__buttons {
      display: inline-flex;
    }

    &__empty-state {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 247px;
      margin: 36px auto 38px;
      text-align: center;
      font-size: 14px;
      line-height: 25px;

      &:before {
        display: block;
        content: '';
        background-image: url(${NoResults});
        width: 180px;
        height: 95px;
        margin-bottom: 7px;
        position: relative;
        top: -5px;
      }
    }

    &__email {
      color: ${palette.darkBlue};
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__project-type-container {
      width: 46.4%;
    }

    &__text {
      display: table-cell;
      vertical-align: middle;
      height: 35px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
    &__project-focus {
      display: flex;
    }
    &__project-focus-cell {
      width: 100%;
    }
  }
`;
