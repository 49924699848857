import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import { colorPalette } from 'app/theme/colors';
import { Body } from 'common/components/typography';

export const SSpreadsheetTooltip = styled(Tippy)`
  box-shadow: 0 5px 10px 0 ${colorPalette.coolGray};
  padding: 10px;
  border: 1px solid ${colorPalette.iron};
  border-radius: 10px;
  background-color: ${colorPalette.alabaster};
  text-align: left;
  word-wrap: break-word;

  .tippy-backdrop {
    background-color: ${colorPalette.alabaster};
  }

  .tippy-content {
    background-color: ${colorPalette.alabaster};
  }

  &[data-placement] {
    .tippy-arrow {
      border-bottom-color: transparent;
      border-top-color: transparent;
    }
  }
`;

export const EllipsisBody = styled(Body)`
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 10;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
`;
