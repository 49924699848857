import { Icon } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SAssignTeamMembersRenderer } from './s-assign-team-members-renderer';

const classes = bem(BEM_CLASS);

export const AssignTeamMembersRenderer = React.memo(() => (
  <SAssignTeamMembersRenderer>
    <Icon className={classes('icon')} name="edit" />
  </SAssignTeamMembersRenderer>
));
