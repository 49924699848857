import styled from 'styled-components';

export const BEM_CLASS = 'link-input-multi-select';

export const SLinkInputMultiSelect = styled.div`
  height: 100%;
  width: 100%;
  .${BEM_CLASS} {
    &__control {
      width: 100%;
    }
  }
`;
