import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { BaseOption as BaseOptionType, SelectMenuProps, SelectType, SimpleSelectProps } from '../../types';
import { BaseSelect } from '../base-select';
import { BEM_CLASS, SSimpleSelect } from './s-simple-select';

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType>({
  classNames,
  selectContainer,
  ...props
}: SimpleSelectProps<T> & SelectMenuProps) => {
  const { isMenuOpen, isDisabled = false } = props;

  const selectClassNames = useMemo(
    () => ({
      ...classNames,

      control: classes('control', { isMenuOpen }, classNames?.control),
      valueContainer: { root: classes('value-container', undefined, classNames?.valueContainer?.root) },
      singleValue: classes('single-value', { isDisabled }, classNames?.singleValue),
      indicatorsContainer: classes('indicators-container', undefined, classNames?.indicatorsContainer),
    }),
    [classNames, isDisabled, isMenuOpen]
  );

  return (
    <SSimpleSelect>
      {selectContainer?.({ ...props, classNames: selectClassNames }) || (
        <BaseSelect {...props} type={SelectType.Base} classNames={selectClassNames} />
      )}
    </SSimpleSelect>
  );
};

export const SimpleSelect = React.memo(Root) as typeof Root;
