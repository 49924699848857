import React from 'react';

import { SFooter } from '../s-components/s-footer';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';

interface Props {
  readonly isOpened: boolean;
  readonly noResponsive: boolean;
  readonly background?: string;
}

export const Footer = (props: Props) => {
  const footer = (
    <SFooter background={props.background}>
      <div className="footer__container">
        <div data-selector="footer-copyright">Copyright © 2021 Sony Music Entertainment</div>
      </div>
    </SFooter>
  );

  return props.noResponsive ? (
    footer
  ) : (
    <ResponsiveContainer background={props.background} filtersOpened={props.isOpened}>
      {footer}
    </ResponsiveContainer>
  );
};

Footer.defaultProps = {
  isOpened: false,
  noResponsive: false,
};
