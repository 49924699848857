import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { Card } from 'common-v2/components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'artist-card';

export const SArtistCard = styled(Card)`
  .${BEM_CLASS} {
    &__content {
      display: flex;
      flex-direction: column;
      align-self: center;
      justify-content: center;
      padding: 5px 20px;
    }

    &__name {
      ${elipsizeText};
      max-width: 100%;
    }

    &__info {
      color: ${manhattanThemeColors.grayedText};
    }

    &__info {
      display: flex;
      max-width: 100%;
      align-items: baseline;
      color: ${manhattanThemeColors.grayedText};
    }

    &__badge {
      margin-right: 5px;
    }
  }
`;
