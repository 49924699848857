import { ProductFamily, Id, Playlist, ProjectDetails, Phase } from 'backend-api/models';
import { Sort } from 'utils/sort';
import { LoadableData, LoadingState } from './loading';

export interface ShowAttachReleasesModalPayload {
  phase: Phase;
  project: ProjectDetails;
  activeTab: AttachReleasesTab;
}

export enum AttachReleasesTab {
  ProductFamilies = 'Product Families',
  Playlists = 'Playlists',
}

export type ProductFamiliesSortFields = '' | 'title' | 'date' | 'tracks';

export interface ProductFamiliesState {
  selected: ProductFamily[];
  search: string;
  sort: Sort<ProductFamiliesSortFields>;
  loading: LoadingState;
  expandedProductFamiliesIds: Id[];
}

export interface PlaylistsState {
  data: Playlist[];
  selected: Playlist[];
  search: string;
  loading: LoadingState;
}

export interface AttachReleasesModalState {
  isOpened: boolean;
  activeTab: AttachReleasesTab;
  productFamiliesState: ProductFamiliesState;
  playlistsState: PlaylistsState;
  allProductFamilies: LoadableData<ProductFamily[]>;
  phase?: Phase;
  project?: ProjectDetails;
}

export interface PhaseInput {
  name?: string;
  start_date?: Date;
  end_date?: Date;
  objective_text?: string;
  product_families?: ProductFamily[];
  playlists?: Playlist[];
}

export interface EditPhasePayload extends PhaseInput {
  readonly projectId: Id;
  readonly phaseId: Id;
  readonly onPhaseEditSuccess?: () => void;
}
