import { palette } from 'frontend-shared-theme';
import { themeColors } from './colors';
import { buttonsTheme } from './buttons';
import { typography, fontFamily } from './typography';
import { selectTheme } from './select';
import { paginationTheme } from './pagination';
import { inputTheme } from './input';
import { tableTheme } from './table';

export const theme = {
  ...themeColors,
  breakpoints: ['1200px', '1366px', '1560px'],
  shadows: {
    default: `0 5px 10px 0 ${themeColors.shadowColor}`,
    notification: `0 10px 20px 0 ${themeColors.shadowColor}`,
    graphTooltip: `0 10px 20px 0 ${themeColors.shadowColor}`,
    smallCardShadow: `0 3px 7px 0 ${themeColors.shadowColor}`,
    largeCardShadow: `0 15px 30px 0 ${themeColors.shadowColor}`,
  },
  typography: typography,
  fontFamily: fontFamily,
  table: tableTheme,
  colors: {
    loader: {
      background: palette.pink,
    },
    header: {
      background: palette.athensGrayTwo,
    },
    button: buttonsTheme,
    select: selectTheme,
    pagination: paginationTheme,
    input: inputTheme,
  },
};
