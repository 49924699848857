import styled from 'styled-components';

export const SCustomColumnsModal = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;

  .custom-columns-modal {
    &__metrics {
      padding: 0 20px;
      flex: 1;
      overflow-y: auto;
    }
    &__empty-view {
      flex: 1;
    }
  }
`;
