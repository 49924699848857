import { asyncEpic } from 'core/epics';
import { Api } from 'backend-api';
import { assignCampaignsToProject, getAssignableCampaigns } from './actions';
import { map, tap } from 'rxjs/operators';
import { increaseUserCounter, UserCounters } from 'utils/analytic';

export const getAssignableCampaignsEpic = asyncEpic(getAssignableCampaigns, action => {
  const { projectId, isInfinite, filters } = action.payload;
  return Api.getAssignableCampaigns(projectId, filters).pipe(
    map(response => ({
      isInfinite,
      ...response,
    }))
  );
});

export const assignCampaignsEpic = asyncEpic(assignCampaignsToProject, action => {
  const { projectId, campaignUuids, onCampaignsAssigned } = action.payload;
  return Api.assignCampaignsToProject(campaignUuids, projectId).pipe(
    tap(() => onCampaignsAssigned && onCampaignsAssigned()),
    tap(() => increaseUserCounter(UserCounters.CAMPAIGNS_ASSIGNED, campaignUuids.length))
  );
});
