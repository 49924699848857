import React from 'react';
import { components, InputProps } from 'react-select';
import { bem } from 'utils/bem';
import { BaseOption as BaseOptionType } from '../../types';
import { SSearchInput } from './s-search-input';

type Props<T extends BaseOptionType, IsMulti extends boolean> = InputProps<T, IsMulti>;

const classes = bem('search-input');

const Root = <T extends BaseOptionType, IsMulti extends boolean>(props: Props<T, IsMulti>) => (
  <SSearchInput>
    <components.Input className={classes('input')} {...props} />
  </SSearchInput>
);

export const SearchInput = React.memo(Root) as typeof Root;
