import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const SExpandedAdSet = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .expanded-ad-set {
    &__divider,
    &__padded-divider {
      height: 1px;
      width: 100%;
      border-radius: 10px 10px 0 0;
      background-color: rgba(228, 229, 240, 1);
    }
    &__padded-divider {
      margin: 0 30px;
      width: calc(100% - 60px);
    }
    &__main-info {
      display: grid;
      padding: 20px 30px 10px;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 80px;
      row-gap: 15px;
      width: 100%;

      & > * {
        ${elipsizeText};
      }
    }
    &__text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      ${elipsizeText};
    }
    &__cell-centered-label {
      align-items: center;
    }
    &__links-container {
      display: flex;
      flex-direction: column;
      padding: 20px 30px 10px;
    }
  }
`;
