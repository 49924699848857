import React, { useCallback, useMemo } from 'react';
import { SettingsHeader } from '../components';
import { SettingsTab, SettingsTabs } from 'settings/types';
import { useSettingsFilters } from 'settings/hooks/settings-filters';
import { UserSettingsContainer } from 'settings/containers/user-settings-container';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';
import { Head, HeadStaticTitles } from 'app/components/head';

export const SettingsHeaderContainer = React.memo(() => {
  const [filters, filtersDispatcher] = useSettingsFilters();

  const tabs: SettingsTab[] = useMemo(
    () => [
      { title: SettingsTabs.UserSettings, component: <UserSettingsContainer /> },
      { title: SettingsTabs.RateCard, component: <div /> },
    ],
    []
  );

  const changeActiveTab = useCallback((tab: SettingsTabs) => filtersDispatcher.setFilters({ activeTab: tab }), [
    filtersDispatcher,
  ]);

  return (
    <>
      <Head title={HeadStaticTitles.SETTINGS} />
      <OutdatedGlobalLayout>
        <SettingsHeader tabs={tabs} activeTab={filters.activeTab} onTabChange={changeActiveTab} />
      </OutdatedGlobalLayout>
    </>
  );
});
