import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette } from 'app/theme/colors';
import { PADDING } from 'common/components/select/constants';

interface Props {
  deepness: number;
}

export const BEM_CLASS = 'single-option';

export const SSingleOption = styled.div<Props>`
  display: flex;
  padding: 0 ${PADDING}px;
  transition: color 0.3s ease-out, background-color 0.3s ease-out;

  ${({ deepness }) =>
    deepness > 0 &&
    css`
      padding-left: ${deepness * PADDING}px;
    `}

  &:hover {
    color: ${colorPalette.azureRadiance};
    background-color: ${transparentize(0.9, colorPalette.azureRadiance)};
  }

  .${BEM_CLASS} {
    &__icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      padding: 0 10px;
      margin-left: -10px;
      cursor: pointer;

      &--isExpanded {
        transform: rotate(0.5turn);
      }
    }

    &__value {
      padding: 5px 0;
      flex-grow: 1;
      cursor: pointer;

      &--isSelected {
        font-weight: 700;
      }

      &--isDisabled {
        color: ${colorPalette.gray};
      }
    }
  }
`;
