import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'tooltip-content';

export const STooltipContentWithError = styled.div`
  .${BEM_CLASS} {
    &__error-notification {
      height: 32px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
    }
    &__divider {
      height: 2px;
      padding: 0 100%;
      margin: 0 -100%;
      background: ${colorPalette.athensGray};
    }
  }
`;
