import React from 'react';
import { DatePickerMode, HeaderType } from 'common/components/form/date-picker/types';
import { SMonthPickerHeader } from './s-month-picker-header';
import { Icon } from 'common/components/icon';
import { bem } from 'utils/bem';
import { callbackWithoutPropagation } from 'utils/event';
import { getFormattedDate } from 'common-v2/utils';

interface Props {
  mode: DatePickerMode;
  type: HeaderType;
  date: Date;
  onPreviousMonthClick(): void;
  onNextMonthClick(): void;
  onPreviousYearClick(): void;
  onNextYearClick(): void;
}

const classes = bem('month-picker-header');

export const MonthPickerHeader = React.memo(
  ({ mode, type, date, onPreviousMonthClick, onNextMonthClick, onPreviousYearClick, onNextYearClick }: Props) => {
    const shouldShowLeftIcons = type === HeaderType.LEFT || mode === DatePickerMode.SINGLE;
    const shouldShowRightIcons = type === HeaderType.RIGHT || mode === DatePickerMode.SINGLE;

    return (
      <SMonthPickerHeader>
        <div className={classes('container')}>
          <Icon
            className={classes('icon', { hidden: !shouldShowLeftIcons })}
            name="backward"
            color="blue"
            onClick={callbackWithoutPropagation(onPreviousYearClick)}
          />
          <Icon
            className={classes('icon', { hidden: !shouldShowLeftIcons })}
            name="prev"
            color="blue"
            onClick={callbackWithoutPropagation(onPreviousMonthClick)}
          />
          <div className={classes('title')}>{`${getFormattedDate('MMM')(date)} ${getFormattedDate('yyyy')(date)}`}</div>
          <Icon
            className={classes('icon', { hidden: !shouldShowRightIcons })}
            name="next"
            color="blue"
            onClick={callbackWithoutPropagation(onNextMonthClick)}
          />
          <Icon
            className={classes('icon', { hidden: !shouldShowRightIcons })}
            name="forward"
            color="blue"
            onClick={callbackWithoutPropagation(onNextYearClick)}
          />
        </div>
      </SMonthPickerHeader>
    );
  }
);
