import React from 'react';
import { bem } from 'utils/bem';
import { GlobalLayout } from 'app/components/global-layout';
import { ErrorViewStates } from 'common-v2/components';
import { NewProjectsContainer, RecentProjectsContainer } from 'projects-v2/containers';
import { SProjectsContainer, BEM_CLASS } from './s-projects-container';

const classes = bem(BEM_CLASS);

export const ProjectsContainer = React.memo(() => (
  <SProjectsContainer>
    <GlobalLayout className={classes('manhattan-background')} errorConfig={{ type: ErrorViewStates.none }}>
      <div className={classes('container')}>
        <RecentProjectsContainer />
        <NewProjectsContainer />
      </div>
    </GlobalLayout>
  </SProjectsContainer>
));
