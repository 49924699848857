import { useCallback, useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { v4 as uuidv4 } from 'uuid';
import { UUID } from 'io-ts-types/lib/UUID';
import { isEditMode, isLastPhase, isMultipleRowsSelected, moveCellFocus, sendToClipboard } from '../../transducers';
import { getInitialCellData } from 'media-plan-v2/containers/spreadsheet/transducers';
import { isKeySymbol } from './transducers';
import { MediaPlanMode, WithConfirmPayload } from 'media-plan-v2/types';
import { IS_MAC_OS } from 'common/constants';
import { useMediaPlanAccessMode } from 'media-plan-v2/hooks';
import { Id } from 'backend-api/models';
import { useDeletePhase } from '../use-delete-phase';
import { useCreateCampaign } from '../use-create-campaign';
import { useDeleteCampaign } from '../use-delete-campaign/use-delete-campaign';
import { ExtendedGridApi, FocusDirection } from '../../types';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { useSelector } from 'react-redux';
import { mediaPlanModeSelector } from 'media-plan-v2/selectors';

export const useHotKeys = (
  projectId: Id,
  mediaPlanId: Id,
  withConfirm: (payload: WithConfirmPayload) => void,
  sanitizeUndoRedoStacks: (uuid: UUID) => void,
  resetCopiedData: () => void,
  agGrid?: React.MutableRefObject<AgGridReact<SpreadsheetRowData> | null | undefined>
) => {
  const deletePhase = useDeletePhase(projectId, mediaPlanId);
  const createCampaign = useCreateCampaign();
  const deleteCampaign = useDeleteCampaign(sanitizeUndoRedoStacks);
  const mediaPlanMode = useSelector(mediaPlanModeSelector);
  const accessMode = useMediaPlanAccessMode(projectId, mediaPlanMode);
  const isEnabled = useMemo(() => isEditMode(accessMode), [accessMode]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (!isEnabled || !agGrid?.current) return;

      const { api, columnApi } = agGrid.current;
      const focusedCell = api.getFocusedCell();

      if (focusedCell == null) return;

      const rowIndex = focusedCell.rowIndex;
      const lastRowIndex = api.getLastDisplayedRow();

      const focusedRow = api.getDisplayedRowAtIndex(rowIndex);
      const isMultipleRows = isMultipleRowsSelected(api);

      const extendedApi = (api as unknown) as ExtendedGridApi; // NOTE: Workaround to type missing fields

      if (focusedRow?.footer && event.key === 'Enter') {
        event.preventDefault();
        if (event.shiftKey) {
          if (rowIndex > 0) moveCellFocus(api, FocusDirection.BACKWARD);
        } else if (rowIndex < lastRowIndex) moveCellFocus(api, FocusDirection.FORWARD);
      }

      const copyShortCut = IS_MAC_OS ? event.metaKey && event.code === 'KeyC' : event.ctrlKey && event.code === 'KeyC';
      const pasteShortCut = IS_MAC_OS ? event.metaKey && event.code === 'KeyV' : event.ctrlKey && event.code === 'KeyV';

      if (copyShortCut && focusedRow?.footer) {
        const valueToCopy = focusedRow.aggData[focusedCell.column.getColId()] ?? getInitialCellData(undefined);
        sendToClipboard(new Map([[uuidv4('footer'), [valueToCopy]]]), resetCopiedData)();
      } else if (copyShortCut && !!extendedApi.contextMenuFactory.activeMenu) {
        api.hidePopupMenu();
        api.copySelectedRangeToClipboard();
      }

      if (pasteShortCut && !!extendedApi.contextMenuFactory.activeMenu) {
        extendedApi.clipboardService.pasteFromClipboard();
        api.hidePopupMenu();
        return;
      }

      if (focusedRow?.footer || (isMultipleRows && !focusedRow?.group) || mediaPlanMode === MediaPlanMode.APPROVAL)
        return;

      const createCampaignShortcut = IS_MAC_OS
        ? event.metaKey && event.altKey && event.code === 'Equal'
        : event.ctrlKey && event.altKey && event.code === 'Equal';

      const deleteShortcut = IS_MAC_OS
        ? event.metaKey && event.altKey && event.code === 'Minus'
        : event.ctrlKey && event.altKey && event.code === 'Minus';

      if (createCampaignShortcut) {
        event.preventDefault();

        createCampaign(api, columnApi, focusedRow);
      }

      if (deleteShortcut && !focusedRow?.group && !focusedRow?.footer) {
        event.preventDefault();

        withConfirm({
          text: 'Deleting campaign cannot be undone.',
          action: () => deleteCampaign(api, columnApi, api.getDisplayedRowAtIndex(rowIndex)),
        });
      }

      if (deleteShortcut && focusedRow?.group && !focusedRow?.footer && !isLastPhase(api)) {
        event.preventDefault();

        withConfirm({
          text: 'Deleting the phase will also delete all of its campaigns.',
          action: () => deletePhase(api, api.getDisplayedRowAtIndex(rowIndex)),
        });
      }

      if (isKeySymbol(event.key) && !event.metaKey && !event.ctrlKey) {
        api.startEditingCell({
          rowIndex,
          colKey: focusedCell.column.getColId(),
          charPress: event.key,
          key: event.key,
        });
      }
    },
    [isEnabled, agGrid, mediaPlanMode, resetCopiedData, createCampaign, withConfirm, deleteCampaign, deletePhase]
  );

  useEffect(() => {
    if (isEnabled) document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown, isEnabled]);

  return null;
};
