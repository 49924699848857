import React, { useCallback } from 'react';
import { ControlProps } from 'react-select';
import { BaseOption as BaseOptionType } from '../../types';
import { getBaseCustomSelectProps } from '../../transducers';
import { SBaseControl } from './s-base-control';

type Props<T extends BaseOptionType, IsMulti extends boolean> = ControlProps<T, IsMulti>;

const Root = <T extends BaseOptionType, IsMulti extends boolean>(props: Props<T, IsMulti>) => {
  const { children, innerProps, menuIsOpen, selectProps } = props;
  const { classNames, handleMenu, dataSelector } = getBaseCustomSelectProps(selectProps);

  const onClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    event => {
      handleMenu(event, menuIsOpen);
    },
    [handleMenu, menuIsOpen]
  );

  const newInnerProps: JSX.IntrinsicElements['div'] = {
    onClick,
    ...innerProps,
  };
  return (
    <SBaseControl
      {...props}
      innerProps={newInnerProps}
      className={classNames.control}
      data-selector={`${dataSelector}-control`}
    >
      {children}
    </SBaseControl>
  );
};

export const BaseControl = React.memo(Root) as typeof Root;
