import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'details-loading';

export const SDetailsLoading = styled.div`
  width: 100%;
  height: 130px;
  border: 1px solid ${transparentize(0.9, colorPalette.woodsmoke)};
  box-shadow: 0 5px 20px 0 ${transparentize(0.95, colorPalette.woodsmoke)};
  border-radius: 10px;
  overflow: hidden;
  background: radial-gradient(
    100% 100% at 41.33% 0%,
    ${transparentize(0.5, colorPalette.white)} 0%,
    ${transparentize(0.2, colorPalette.white)} 100%
  );

  .${BEM_CLASS} {
    &__container {
      display: flex;
      height: 100%;
    }

    &__rows {
      display: flex;
      flex-direction: column;
      margin: 20px 30px;
    }

    &__second-row {
      margin-top: 6px;
    }

    &__third-row {
      margin-top: 25px;
    }

    &__controls {
      display: flex;
      gap: 10px;
      margin: 20px 30px;
      margin-left: auto;
    }
  }
`;
