import React, { useEffect } from 'react';
import { navigateTo } from 'utils/navigation';

const RouteInternalComponent = props => {
  const { component, match, isAuthorized, ...routerProps } = props;
  const { location } = routerProps;

  useEffect(() => {
    const { startPollingSession, stopPollingSession } = props;

    startPollingSession();

    return () => stopPollingSession();
  }, []);

  const checkSessionHandler = (silent = false) => {
    const { checkSession } = props;
    const { search, pathname } = location;

    checkSession({
      params: search,
      backUrl: pathname,
      silent,
    });
  };

  useEffect(() => {
    checkSessionHandler();
  }, [location.pathname]);

  useEffect(() => {
    if (!isAuthorized) {
      navigateTo('/login');
    }
  }, [isAuthorized]);

  if (!match) {
    return null;
  }

  if (!isAuthorized) {
    return null;
  }

  return React.createElement(component, { ...routerProps, match });
};

export default RouteInternalComponent;
