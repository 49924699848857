import styled from 'styled-components';
import { palette, shadows } from 'frontend-shared-theme';

export const STooltip = styled.div`
  .tooltip {
    &__container {
      display: flex;
      flex-direction: column;
      box-shadow: ${shadows.tooltip};
      background-color: ${palette.white};
      padding: 20px 25px;
    }

    &__category-title {
      color: ${palette.darkBlueTwo};
      font-size: 12px;
      align-self: left;
      font-family: Montserrat;
    }

    &__category-table {
      border-spacing: 0px;
      margin: 5px 0px 15px;
    }

    &__category-row {
      display: flex;
      justify-content: space-between;
      margin: 4px 0px;
    }

    &__category-row-element {
      display: flex;
    }

    &__category-row-title {
      color: ${palette.darkBlue};
      font-size: 14px;
      font-weight: 400;
      font-family: Montserrat;
    }

    &__category-row-value {
      margin-left: 15px;
      color: ${palette.darkBlue};
      font-size: 14px;
      font-weight: 600;
      font-family: Montserrat;
    }

    &__category-row-value-percentage {
      font-weight: 400;
    }

    &__category-separator {
      height: 1px;
      background-color: ${palette.lavanderGray};
    }

    &__category-total-container {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
    }

    &__category-total-text {
      color: ${palette.darkBlue};
      font-family: Montserrat;
      font-size: 14px;
    }

    &__territory-container {
      display: flex;
      justify-content: space-between;
      padding: 20px 25px;
      background-color: ${palette.white};
    }
  }
`;
