import { ShortArtist } from 'backend-api/models';
import { Image } from 'common-v2/components';
import { Typography, TYPOGRAPHY_TYPE, Button, BUTTON_SIZE, BUTTON_TYPE, THEME } from 'gdb-web-shared-components';
import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { SArtistItem, BEM_CLASS } from './s-artist-item';

interface ArtistItemProps {
  artist: ShortArtist;
  onDelete?(id: string): void;
  isDisabled?: boolean;
  onArtistClick?(artist: ShortArtist): void;

  className?: string;
}

const classes = bem(BEM_CLASS);

export const ArtistItem = React.memo(({ artist, onDelete, isDisabled, onArtistClick, className }: ArtistItemProps) => {
  const onDeleteClick = useCallback(() => {
    onDelete?.(artist.id);
  }, [onDelete, artist.id]);

  const onClick = useCallback(() => {
    onArtistClick?.(artist);
  }, [onArtistClick, artist]);

  return (
    <SArtistItem className={className} onClick={onClick} data-selector="edit-modal-artist-item">
      <div className={classes('info-container')}>
        <Image imageUrl={artist.imageUrl} className={classes('image')} />
        <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('name')}>
          {artist.name}
        </Typography>
      </div>
      {onDelete && !isDisabled && (
        <Button
          size={BUTTON_SIZE.smallRound}
          icon="delete"
          type={BUTTON_TYPE.tertiary}
          theme={THEME.light}
          className={classes('delete')}
          onClick={onDeleteClick}
          data-selector="artist-item-delete-button"
        />
      )}
    </SArtistItem>
  );
});
