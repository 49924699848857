import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'modal-footer';

export const SModalFooter = styled.div`
  display: flex;
  flex-direction: column;
  &::before {
    content: '';
    width: 100%;
    height: 1px;
    background: ${colorPalette.whitenedTooth};
  }

  .${BEM_CLASS} {
    &__content {
      display: flex;
      justify-content: space-between;
      padding: 20px;
    }
    &__warning {
      display: flex;
      align-items: center;
      letter-spacing: 0;
      font-size: 12px;
      font-weight: 300;
      line-height: 14px;
      white-space: nowrap;
      &--dirty,
      &--dirty i:before {
        color: ${colorPalette.christine};
      }
      &--error,
      &--error i:before {
        color: ${colorPalette.valencia};
      }
    }
    &__confirmation {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
    &__save {
      margin-left: 20px;
    }
    &__warning-icon {
      margin-right: 5px;
    }
  }
`;
