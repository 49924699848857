import React from 'react';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { SAppFooter, BEM_CLASS } from './s-app-footer';
import { bem } from 'utils/bem';

const classes = bem(BEM_CLASS);

export const AppFooter = React.memo(() => (
  <SAppFooter>
    <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('copyright')}>
      {`Copyright © Sony Music Entertainment, ${new Date().getFullYear()}`}
    </Typography>
    <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('decibel-version')}>
      Decibel Version: Beta 2.0
    </Typography>
  </SAppFooter>
));
