import React, { useCallback, useMemo } from 'react';
import { AssignableProject, Id } from 'backend-api/models';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { TargetsImages } from 'common/components/targets-images';
import { getTargetsNames } from 'projects/transducers';
import { SProjectsListItem } from './s-projects-list-item';
import { TooltipedText } from 'common/components/tooltiped-text';

interface Props {
  project: AssignableProject;
  onSelectProject: (projectId: Id) => void;
  className?: string;
}

const classes = bem('project-list-item');

export const ProjectsListItem = React.memo(({ project, onSelectProject, className }: Props) => {
  const onSelectItem = useCallback(() => onSelectProject(project.id), [project.id, onSelectProject]);
  const targetNames = useMemo(() => getTargetsNames(project.targets), [project]);

  return (
    <SProjectsListItem data-selector="projects-list-item" onClick={onSelectItem} className={className}>
      <TargetsImages targets={project.targets.items} isOverlayDisabled className={classes('images-container')} />
      <div className={classes('info-container')}>
        <div data-selector="project-name" className={classes('name')} data-id={project.id}>
          {project.name}
        </div>
        <TooltipedText text={targetNames.tooltip}>
          <span className={classes('artist-names')}>{targetNames.display}</span>
        </TooltipedText>
      </div>
      <Icon
        name="add-bold"
        color="pink"
        className={classes('icon')}
        size={'general'}
        dataSelector="projects-list-item"
      />
    </SProjectsListItem>
  );
});
