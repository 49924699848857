import styled from 'styled-components';

export const SMultiValueRemove = styled.div`
  height: auto;
  align-self: stretch;

  :hover {
    background: unset;
  }
`;
