import * as styledComponents from 'styled-components';
import { theme } from './theme';

export type ThemeType = typeof theme;

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
} = (styledComponents as unknown) as styledComponents.ThemedStyledComponentsModule<ThemeType>;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
