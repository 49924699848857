import * as t from 'io-ts';
import { IdNameField, ResponseModel, IdType } from '../common';
import { HistoryEventSchema } from '../history';
import { TargetsSchema } from '../targets';

export const CategoryCounterResponseSchema = ResponseModel(
  {
    ...IdNameField,
    count: t.number,
  },
  'CategoryCounterResponse'
);

export type CategoryCounterResponse = t.TypeOf<typeof CategoryCounterResponseSchema>;

export const TeamCountersResponseSchema = ResponseModel(
  {
    usersCount: t.number,
  },
  'TeamCountersResponse'
);

export type TeamCountersResponse = t.TypeOf<typeof TeamCountersResponseSchema>;

export const TeamActivityProjectSchema = ResponseModel(
  {
    ...IdNameField,
    targets: TargetsSchema,
  },
  'TeamActivityProject'
);

export type TeamActivityProject = t.TypeOf<typeof TeamActivityProjectSchema>;

export const TeamActivitySchema = ResponseModel(
  {
    event: HistoryEventSchema,
    project: TeamActivityProjectSchema,
  },
  'TeamCampaign'
);

export type TeamActivity = t.TypeOf<typeof TeamActivitySchema>;

export const ChangeTeamUserRequestSchema = ResponseModel(
  {
    userId: IdType,
    roleId: IdType,
    categoryIds: t.array(IdType),
  },
  'ChangeTeamUserRequest'
);

export type ChangeTeamUserRequest = t.TypeOf<typeof ChangeTeamUserRequestSchema>;

export const ChangeTeamRequestSchema = ResponseModel(
  {
    added: t.array(ChangeTeamUserRequestSchema),
    deleted: t.array(ChangeTeamUserRequestSchema),
    updated: t.array(ChangeTeamUserRequestSchema),
  },
  'ChangeTeamRequest'
);

export type ChangeTeamRequest = t.TypeOf<typeof ChangeTeamRequestSchema>;
