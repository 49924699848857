import styled from 'styled-components';

export const SArtistLoader = styled.div`
  min-height: 255px;
  display: flex;
  flex-direction: column;
  .release-loader {
    &__items-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;

      & > * {
        &:not(:last-child) {
          margin-right: 80px;
        }
        flex: 1;
      }
    }
  }
`;
