import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UUID } from 'io-ts-types/lib/UUID';
import { Id, MediaPlanPhase } from 'backend-api/models';
import { v4 as uuidv4 } from 'uuid';
import { GridApi, RowNode } from 'ag-grid-community';
import { createPhase as createPhaseAction, focusPhaseInput, getMediaPlan } from 'media-plan/actions';
import { SpreadsheetRowData } from '../types';
import { dateFormatSelector } from 'common/selectors';
import { createDraftCampaign, prepareSpreadsheetData } from '../transducers';
import { getUniqueId } from 'utils/data';

export const useCreatePhase = (projectId: Id, mediaPlanId: Id) => {
  const dispatch = useDispatch();

  const dateFormat = useSelector(dateFormatSelector);

  const createPhaseCallback = useCallback(
    (order: number, campaignUuid: UUID, onSuccess: (phase: MediaPlanPhase) => void, onError: () => void) => {
      dispatch(
        createPhaseAction.request({
          projectId,
          mediaPlanId,
          payload: {
            name: 'Phase',
            order,
            campaignUuid,
          },
          onSuccess,
          onError,
        })
      );
    },
    [dispatch, mediaPlanId, projectId]
  );

  const createPhase = useCallback(
    (api: GridApi<SpreadsheetRowData>, node?: RowNode<SpreadsheetRowData> | null, isAbove = false) => {
      if (!node) return;

      const groupNodes = node.group ? node.allLeafChildren : node.parent?.allLeafChildren || [];
      const groupNode = groupNodes[0];

      if (!groupNode || !groupNode.data) return;

      const campaignUuid = uuidv4();
      const phaseOrder = isAbove ? groupNode.data.phaseOrder.value : groupNode.data.phaseOrder.value + 1;
      const updateTransaction: SpreadsheetRowData[] = [];

      api.forEachNode(({ group, data }) => {
        if (group || !data) return;

        if (data.phaseOrder.value >= phaseOrder) {
          updateTransaction.push({
            ...data,
            phaseOrder: {
              ...data.phaseOrder,
              value: isAbove ? data.phaseOrder.value + 1 : data.phaseOrder.value + 2,
            },
          });
        }
      });

      api.applyTransaction({
        add: [
          createDraftCampaign(
            {
              uuid: campaignUuid,
              phaseId: getUniqueId(),
              phaseName: 'Phase',
              phaseOrder: phaseOrder,
            },
            { orderInPhase: 0, orderOnSort: undefined }
          ),
        ],
        update: updateTransaction,
      });
      api.clearFocusedCell();
      api.clearRangeSelection();

      createPhaseCallback(
        phaseOrder,
        campaignUuid,
        phase => {
          api.applyTransaction({
            update: prepareSpreadsheetData({ phases: [phase], dateFormat }),
          });
          dispatch(focusPhaseInput(phase.id));
        },
        () => {
          dispatch(getMediaPlan.request({ projectId, mediaPlanId }));
        }
      );
    },
    [createPhaseCallback, dispatch, mediaPlanId, projectId, dateFormat]
  );

  return createPhase;
};
