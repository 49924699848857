import styled from 'styled-components';

export const SCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 20px 20px;
  overflow: hidden;
`;
