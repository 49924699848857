import styled from 'styled-components';
import { motion } from 'framer-motion';
import { rangePicker as rangePickerZIndex } from 'app/styles/z-indexes';
import { palette } from 'frontend-shared-theme';
import { DATE_POPUP_BORDER_RADIUS } from '../../constants';
import { PopupAlignment } from '../../types';
import { theme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';

interface Props {
  top: number;
  left: number;
  alignment: PopupAlignment;
}

export const SDatePickerPopup = styled(motion.div)<Props>`
  border-radius: ${DATE_POPUP_BORDER_RADIUS}px;
  background-color: white;
  box-shadow: ${theme.shadows.default};
  position: absolute;
  z-index: ${rangePickerZIndex};
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  margin-top: 5px;
  display: flex;

  .date-picker-popup {
    &__calendar-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &__message {
      font-size: 10px;
      line-height: 20px;
      margin: 0 24px;
      color: ${palette.darkBlueTwo};
      text-align: center;

      &--error {
        color: ${colorPalette.alizarinCrimson};
      }
    }
  }
`;
