import styled, { css } from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { colorPalette } from 'app/theme/colors';

interface SFlatInputProps {
  readonly hasIconLeft?: boolean;
  readonly hasIconRight?: boolean;
  readonly disabled?: boolean;
}

export const SFlatInput = css<SFlatInputProps>`
  display: block;
  min-width: 30px;
  width: 100%;
  height: 25px;
  background: none;
  border: none;
  border-bottom: 1px dashed grey;
  outline: none;
  font-size: 14px;
  font-weight: bold;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.input.text.disabled : theme.colors.input.text.regular)};
  padding: 3px ${({ hasIconRight }) => (hasIconRight ? '18px' : '0')} 3px
    ${({ hasIconLeft }) => (hasIconLeft ? '18px' : '0')};
  &:focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.select.focus.borderColor};
  }
  &:focus-within {
    border-bottom: 1px solid ${({ theme }) => theme.colors.select.focus.borderColor};
  }

  &::placeholder {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.input.placeholder.disabled : theme.colors.input.placeholder.regular};
  }

  &--focus {
    border-bottom: 1px solid ${({ theme }) => theme.colors.select.focus.borderColor};
  }
`;

export const SFlatInputWrapper = styled.div<SFlatInputProps>`
  display: flex;
  align-items: flex-start;
  font-size: 14px;

  .input {
    &__wrapper {
      position: relative;
      width: fit-content;
      overflow: hidden;
      max-width: 100%;

      &:hover {
        .input__icon--right {
          &::before {
            color: ${({ disabled, theme }) =>
              disabled ? theme.colors.input.icon.refular : theme.colors.input.icon.focused};
          }
        }
      }
    }

    &__reset-text {
      margin-top: 3px;
      margin-left: 20px;
      color: ${palette.brightBlue};
      cursor: pointer;
    }

    &__info {
      cursor: pointer;
      display: flex;
      align-self: center;
      margin-left: 10px;
    }

    &__icon {
      position: absolute;
      top: 7px;

      &--left {
        left: 2px;
      }

      &--right {
        right: 2px;
      }
    }

    &__input-sizer,
    &__input {
      ${SFlatInput};
    }

    &__input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &__input-sizer {
      visibility: hidden;
      max-width: 100%;
    }
    &__input-container {
      position: relative;
    }
    &__placeholder {
      position: absolute;
      top: 2px;
      color: ${colorPalette.blueHaze};
    }
  }
`;
