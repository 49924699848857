import { manhattanThemeColors } from 'app/theme/colors';
import styled from 'styled-components';

export const BEM_CLASS = 'user-dropdown-container';

export const SUserDropdownContainer = styled.div`
  .${BEM_CLASS} {
    &__user-dropdown {
      color: ${manhattanThemeColors.background};
    }
  }
`;
