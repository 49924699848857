import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { bem } from 'utils/bem';
import { Id, MarketingMix, Optional } from 'backend-api/models';
import { ColoredCircle } from 'common/components/colored-circle';
import { getShortCurrencyFormatted } from 'utils/format';
import {
  getFilteredDigitalMix,
  getFormattedPercentage,
  getMarketingMixChartOptions,
  getSortedColoredMarketingMix,
  getSubMarketingMix,
  getTotalBudget,
} from './transducers';
import { getMarketingMixTooltip } from './components';
import { BEM_CLASS, SMarketingMixAnalytics } from './s-marketing-mix-analytics';
import { Tooltip } from 'common/components/tooltip';
import { OptionIdType } from 'common/components/form/select';
import { DIGITAL_CATEGORY_IDS, LINKED_DIGITAL_CATEGORY_ID, TooltipByCampaignCategory } from '../../constants';
import { CURRENCY_FORMATS } from 'common/constants';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { useProjectCurrencyFormat } from 'hooks';
import { PrimaryLabel } from 'common/components/typography';
import { Select, SelectType } from 'common/components/select';

interface Props {
  projectId: Id;
  marketingMix: MarketingMix[];
  selectedMarketingMix?: MarketingMix;
  className?: string;
  currencyCode?: string;
}

const classes = bem(BEM_CLASS);

export const MarketingMixAnalytics = ({
  projectId,
  marketingMix,
  selectedMarketingMix,
  className,
  currencyCode,
}: Props) => {
  const [selectedDigitalMixId, setSelectedDigitalMixId] = useState<OptionIdType>();

  const digitalMix = useMemo(() => marketingMix.filter(({ id }) => DIGITAL_CATEGORY_IDS.includes(id)), [marketingMix]);
  const otherMix = useMemo(() => marketingMix.filter(({ id }) => !DIGITAL_CATEGORY_IDS.includes(id)), [marketingMix]);

  const filteredDigitalMix = useMemo(
    () => getFilteredDigitalMix(digitalMix, selectedDigitalMixId, selectedMarketingMix?.id),
    [digitalMix, selectedDigitalMixId, selectedMarketingMix?.id]
  );

  const combinedMix = useMemo(() => [...filteredDigitalMix, ...otherMix], [filteredDigitalMix, otherMix]);

  const getTooltipContent = (id: Id): Optional<string> => {
    if (selectedMarketingMix) return undefined;

    return TooltipByCampaignCategory[id];
  };

  const onSelectChange = useCallback(
    (id: OptionIdType) => {
      const eventName =
        id === LINKED_DIGITAL_CATEGORY_ID
          ? AnalyticsEvents.MARKETING_MIX_LIVE_DIGITAL_SELECTED
          : AnalyticsEvents.MARKETING_MIX_DIGITAL_SELECTED;
      trackEvent(eventName, { project_id: projectId });

      setSelectedDigitalMixId(id);
    },
    [projectId]
  );

  useEffect(() => {
    if (digitalMix.length > 1) {
      setSelectedDigitalMixId(LINKED_DIGITAL_CATEGORY_ID);
    }
  }, [digitalMix]);

  const coloredMarketingMix = useMemo(() => getSortedColoredMarketingMix(combinedMix), [combinedMix]);

  const subMarketingMix = useMemo(() => getSubMarketingMix(coloredMarketingMix, selectedMarketingMix?.name), [
    coloredMarketingMix,
    selectedMarketingMix?.name,
  ]);

  const formatShortCurrency = useCallback((value: number) => getShortCurrencyFormatted(value, currencyCode), [
    currencyCode,
  ]);

  const options = useMemo(
    () => getMarketingMixChartOptions(coloredMarketingMix, subMarketingMix, getMarketingMixTooltip, currencyCode),
    [coloredMarketingMix, currencyCode, subMarketingMix]
  );

  const totalBudget = useMemo(() => getTotalBudget(subMarketingMix), [subMarketingMix]);

  return (
    <SMarketingMixAnalytics data-selector="marketing-mix-categories-chart" className={className}>
      <div className={classes('chart')}>
        <HighchartsReact highcharts={Highcharts} options={options} />
        <span data-selector="marketing-mix-categories-total-sum" className={classes('total')}>
          {useProjectCurrencyFormat(totalBudget, CURRENCY_FORMATS.short)}
          <PrimaryLabel className={classes('currency-code')}>{currencyCode}</PrimaryLabel>
        </span>
      </div>
      <div className={classes('list')}>
        <table data-selector="marketing-mix-categories-table" className={classes('table')}>
          <tbody>
            {subMarketingMix.map(marketingMix => {
              const isNameSelector =
                !selectedMarketingMix &&
                !!selectedDigitalMixId &&
                DIGITAL_CATEGORY_IDS.some(id => id === marketingMix.id);

              const tooltipContent = getTooltipContent(marketingMix.id);

              return (
                <tr key={marketingMix.id} className={classes('table-row')}>
                  <td className={classes('table-row-element')}>
                    {marketingMix.color && <ColoredCircle color={marketingMix.color} />}
                    {isNameSelector ? (
                      <div className={classes('digital-selector')}>
                        <Select
                          type={SelectType.Simple}
                          valueId={marketingMix.id}
                          valueHandler={onSelectChange}
                          options={digitalMix.map(({ id, name }) => ({
                            id,
                            value: name,
                          }))}
                        />
                      </div>
                    ) : (
                      <span
                        data-selector="marketing-mix-categories-category-title"
                        className={classes('table-row-title')}
                      >
                        <Tooltip content={tooltipContent} enabled={!!tooltipContent}>
                          {marketingMix.name}
                        </Tooltip>
                      </span>
                    )}
                  </td>
                  <td className={classes('table-row-element')}>
                    <span
                      data-selector="marketing-mix-categories-category-value"
                      className={classes('table-row-budget')}
                    >
                      {formatShortCurrency(marketingMix.budget)}
                      <span className={classes('table-row-percentage')}>
                        ({getFormattedPercentage(marketingMix.budget, totalBudget)})
                      </span>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </SMarketingMixAnalytics>
  );
};
