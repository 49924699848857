import React from 'react';
import { Skeleton } from 'common-v2/components';
import { SReportingTableLoader } from './s-reporting-table-loader';

interface Props {
  className?: string;
}

export const ReportingTableLoader = React.memo(({ className }: Props) => {
  return (
    <SReportingTableLoader className={className}>
      <Skeleton className={className} width={275} height={65}>
        <rect x="0" y="0" rx="3" ry="3" width="15" height="15" />
        <rect x="0" y="25" rx="3" ry="3" width="15" height="15" />
        <rect x="0" y="50" rx="3" ry="3" width="15" height="15" />
        <rect x="25" y="0" rx="3" ry="3" width="175" height="15" />
        <rect x="25" y="25" rx="3" ry="3" width="250" height="15" />
        <rect x="25" y="50" rx="3" ry="3" width="175" height="15" />
      </Skeleton>
    </SReportingTableLoader>
  );
});
