import { Id } from 'backend-api/models';
import { UUID } from 'io-ts-types/lib/UUID';

export const basePaths = {
  projects: () => '/projects',
  unassignedCampaigns: () => '/unassigned-campaigns',
  performance: () => '/performance',
  artists: () => '/artists',
  settings: () => '/settings',
  labelPermissions: () => '/label-permissions',
};

const projectsPaths = {
  newProject: () => basePaths.projects() + '/add',
  project: (id: Id) => basePaths.projects() + `/${id}`,
  projectDetails: (id: Id) => projectsPaths.project(id) + '/details',
  editProject: (id: Id) => projectsPaths.project(id) + '/edit',
  projectHistory: (id: Id) => projectsPaths.project(id) + '/history',
  campaigns: (projectId: Id) => projectsPaths.project(projectId) + '/campaigns',
  reporting: (projectId: Id) => projectsPaths.project(projectId) + '/reporting',
  newCampaign: (projectId: Id) => projectsPaths.campaigns(projectId) + '/add',
  campaign: (projectId: Id, id: UUID) => projectsPaths.campaigns(projectId) + `/${id}`,
  editCampaign: (projectId: Id, id: UUID) => projectsPaths.campaign(projectId, id) + '/edit',
  phases: (projectId: Id) => projectsPaths.project(projectId) + '/phases',
  newPhase: (projectId: Id) => projectsPaths.phases(projectId) + '/add',
  projectReporting: (projectId: Id) => projectsPaths.phases(projectId) + '/reporting',
  phase: (projectId: Id, id: Id) => projectsPaths.phases(projectId) + `/${id}`,
  phaseDetails: (projectId: Id, id: Id) => projectsPaths.phase(projectId, id) + '/details',
  editPhase: (projectId: Id, id: Id) => projectsPaths.phase(projectId, id) + '/edit',
  projectPhaseReporting: (projectId: Id, id: Id) => projectsPaths.phase(projectId, id) + '/reporting',
  newMoment: (projectId: Id) => projectsPaths.project(projectId) + '/moments/add',
  momentDetails: (projectId: Id, id: Id) => projectsPaths.project(projectId) + `/moments/${id}/details`,
  editMoment: (projectId: Id, id: Id) => projectsPaths.project(projectId) + `/moments/${id}/edit`,
  purchaseOrders: (projectId: Id) => projectsPaths.project(projectId) + `/purchase-orders`,
  mediaPlan: (projectId: Id, mediaPlanId?: Id) =>
    mediaPlanId
      ? projectsPaths.project(projectId) + `/media-plans/${mediaPlanId}`
      : projectsPaths.project(projectId) + '/media-plans',
};

const unassignedCampaignsPaths = {
  unassignedCampaign: (id: UUID) => basePaths.unassignedCampaigns() + `/${id}`,
  unassignedCampaignDetails: (id: UUID) => unassignedCampaignsPaths.unassignedCampaign(id) + '/details',
};

const artistPaths = {
  artist: (id: string) => basePaths.artists() + `/${id}`,
};

const purchaseOrderPaths = {
  purchaseOrders: (projectId: Id) => projectsPaths.project(projectId) + '/purchase-orders',
  purchaseOrder: (projectId: Id, id: Id) => purchaseOrderPaths.purchaseOrders(projectId) + `/${id}`,
  purchaseOrderItems: (projectId: Id, id: Id) =>
    purchaseOrderPaths.purchaseOrder(projectId, id) + `/${id}/blanket-items`,
};

const errorPaths = {
  outdatedNotFound: () => '/v1/not-found',
};

export const paths = {
  ...basePaths,
  ...projectsPaths,
  ...unassignedCampaignsPaths,
  ...artistPaths,
  ...purchaseOrderPaths,
  ...errorPaths,
};
