import styled from 'styled-components';

export const BEM_CLASS = 'options-group';

export const SOptionsGroup = styled.div`
  .${BEM_CLASS} {
    &__options {
      overflow: hidden;
    }
  }
`;
