import { DATE_FORMAT_OPTIONS } from './constants';

export interface SettingsTab {
  title: string;
  component: React.ReactElement;
}

export enum SettingsTabs {
  UserSettings = 'User Settings',
  RateCard = 'Rate Card',
}

export interface SettingsFilters {
  activeTab: SettingsTabs;
}

export interface SettingsQuery {
  activeTab: SettingsTabs;
}

export type DateFormatPatterns = typeof DATE_FORMAT_OPTIONS[number]['id'];
