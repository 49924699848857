import { useEffect, useState } from 'react';
import { AudienceAge } from 'backend-api/models';
import { getIsTargetAgeInvalid } from '../transducers';

export const useAudienceAgeValidation = (audienceAge?: AudienceAge | null): [boolean] => {
  const [isValueInvalid, setIsValueInvalid] = useState(getIsTargetAgeInvalid(audienceAge));

  useEffect(() => {
    setIsValueInvalid(getIsTargetAgeInvalid(audienceAge));
  }, [audienceAge]);

  return [isValueInvalid];
};
