import React from 'react';
import { bem } from 'utils/bem';
import { SLoaderUpcomingProjects } from './s-loader-upcoming-projects';

const rectangleClasses = bem('loader-upcoming-projects');
const CLIP_PATH_ID = 'loader-upcoming';

export const UpcomingProjectsLoader = React.memo(() => {
  return (
    <SLoaderUpcomingProjects clipPathId={CLIP_PATH_ID}>
      <div className={rectangleClasses('clip-container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <path
              d={`M0 0 110 0 110 30 0 30 0 0z
                  M0 40 110 40 110 70 0 70 0 40z`}
            />
          </clipPath>
        </defs>
      </svg>
    </SLoaderUpcomingProjects>
  );
});
