import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { formatNumeral } from 'utils/format';
import { BaseRenderer } from '../../base-renderer';
import { TextValue } from '../../text-value';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';

export const SimpleNumberRenderer = ({
  context,
  value: { color, value },
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['estImpressions']>) => (
  <BaseRenderer color={color} accessMode={context.accessMode}>
    {value && <TextValue value={formatNumeral(value)} />}
  </BaseRenderer>
);
