import React, { useCallback } from 'react';
import { MenuListProps } from 'react-select';
import { bem } from 'utils/bem';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { OptionsGroup, SingleOption } from './components';
import { BaseOption as BaseOptionType } from '../../types';
import { BEM_CLASS, STreeList } from './s-tree-list';

type Props<T extends BaseOptionType> = MenuListProps<T, false>;

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType>({
  options,
  innerRef,
  innerProps,
  selectOption,
  getValue,
  selectProps,
}: Props<T>) => {
  const { classNames, dataSelector } = getBaseCustomSelectProps(selectProps);

  const renderTreeOptions = useCallback(
    (options: T[], level = 1) => {
      const selectedValueId = getValue()[0].id;

      return options.flatMap(option => {
        const { id, isDisabled = false } = option;

        if (option.options) {
          const isSelected = selectedValueId === id || option.options.map(({ id }) => id).includes(selectedValueId);

          return [
            <OptionsGroup
              key={id}
              data={option}
              onClick={selectOption}
              deepness={level}
              isSelected={isSelected}
              isDisabled={isDisabled}
            >
              {renderTreeOptions(option.options as T[], level + 1)}
            </OptionsGroup>,
          ];
        } else {
          return [
            <SingleOption
              key={id}
              data={option}
              onClick={selectOption}
              deepness={level + 1}
              isSelected={selectedValueId === id}
              isDisabled={isDisabled}
            />,
          ];
        }
      });
    },
    [selectOption, getValue]
  );

  return (
    <STreeList
      {...innerProps}
      ref={innerRef}
      className={classNames.menuList}
      data-selector={`${dataSelector}-tree-menu`}
    >
      <div className={classes('options')}>{renderTreeOptions(options as T[])}</div>
    </STreeList>
  );
};

export const TreeList = React.memo(Root) as typeof Root;
