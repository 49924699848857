import { ApiError } from 'backend-api';
import { ArtistSearch, ProjectDetails, ProjectSharing, Id, LabelUser } from 'backend-api/models';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { actionsGroup } from 'core/store';
import { ErrorViewStates } from 'common-v2/components';
import {
  EditArtistModalInfo,
  EditProjectPayload,
  ProjectDetailsPayload,
  AddProjectUsersPayload,
  UpdateProjectUserPayload,
  PreviousUserState,
  RemoveProjectUserPayload,
  SearchArtistsPayload,
} from './types';

const { action, asyncAction } = actionsGroup('project-v2');

export const getProjectDetails = asyncAction<
  ProjectDetailsPayload,
  ProjectDetails,
  ApiError<ProjectDetailsErrorResponse>
>('GET_PROJECT_DETAILS');

export const setErrorViewType = action<ErrorViewStates>('SET_ERROR_VIEW_TYPE');

export const getProjectSharingInfo = asyncAction<Id, ProjectSharing, Error>('GET_PROJECT_SHARING_INFO');

export const openShareModal = action<void>('OPEN_SHARE_MODAL');
export const closeShareModal = action<void>('CLOSE_SHARE_MODAL');

export const editProject = asyncAction<EditProjectPayload, ProjectDetails, Error>('EDIT_PROJECT');

export const openEditArtistsModal = action<EditArtistModalInfo>('OPEN_EDIT_ARTISTS_MODAL');
export const closeEditArtistsModal = action<void>('CLOSE_EDIT_ARTISTS_MODAL');

export const clearAllArtists = action<void>('CLEAR_ALL_ARTISTS');
export const addRecentArtist = action<ArtistSearch>('ADD_RECENT_ARTIST');
export const removeArtist = action<ArtistSearch>('REMOVE_ARTIST');
export const removeRecentArtist = action<ArtistSearch>('REMOVE_RECENT_ARTIST');

export const setArtistsSearch = action<string>('SET_ARTISTS_SEARCH');
export const searchArtists = asyncAction<SearchArtistsPayload, ArtistSearch[], Error>('SEARCH_ARTISTS');
export const resetSearchResults = action<void>('RESET_SEARCH_RESULTS');

export const updateProjectUser = asyncAction<UpdateProjectUserPayload, void, PreviousUserState>('UPDATE_PROJECT_USER');
export const removeProjectUser = asyncAction<RemoveProjectUserPayload, void, ApiError>('REMOVE_PROJECT_USER');
export const addProjectUsers = asyncAction<AddProjectUsersPayload, void, ApiError>('SHARE_PROJECT_WITH_USERS');

export const getLabelUsers = asyncAction<Id, LabelUser[], ApiError>('GET_LABEL_USERS');

export const getUsers = asyncAction<void, LabelUser[], ApiError>('GET_USERS');
