import { isEqual, omit, uniqBy } from 'lodash';
import numeral from 'numeral';

import { DECIMAL_NUMERAL_FORMAT, HTTPS_LINK_PREFIX, NUMERAL_FORMAT, PERFORMANCE_RATE_FORMAT } from 'common/constants';
import {
  Id,
  Optional,
  PerformanceCampaignMetadataSchema,
  PerformanceData,
  PerformanceDataItem,
  PerformanceMetric,
  PerformanceMetricValue,
  PerformanceObjective,
} from 'backend-api/models';
import { PerformanceDataParams } from 'backend-api/types';
import { PerformanceDataItemWithId } from 'common/types';
import { notNullable } from 'utils/data';
import { paths } from 'app/routing/paths';
import {
  ALL_LABELS_OPTION_ID,
  BREAKDOWN_BY_CAMPAIGN_ID,
  BREAKDOWN_BY_CHARTWEEK_ID,
  BREAKDOWN_BY_DAY_ID,
  BREAKDOWN_BY_LINKFIRE_LINK_ID,
  BREAKDOWN_BY_MONTH_ID,
  BREAKDOWN_BY_PROJECT_ID,
  BREAKDOWN_BY_WEEK_ID,
} from './constants';
import { Pagination } from 'common/components/pagination';
import { PERFORMANCE_METRICS_FORMATS } from 'backend-api/models/performance/constants';
import { OptionIdType } from 'common/components/form/select';
import { getCurrencyFormatted } from 'utils/format';
import { parseDateFromISOString, getFormattedDate, isValidDate, addDaysToDate } from 'common-v2/utils';

export const isValueDate = (value: PerformanceMetricValue, field: PerformanceMetric): value is Date => {
  return field.format.name === PERFORMANCE_METRICS_FORMATS.date && value instanceof Date;
};

export const isValueInteger = (value: PerformanceMetricValue, field: PerformanceMetric): value is number => {
  return field.format.name === PERFORMANCE_METRICS_FORMATS.integer && typeof value === 'number';
};

export const isValueCurrency = (value: PerformanceMetricValue, field: PerformanceMetric): value is number => {
  return field.format.name === PERFORMANCE_METRICS_FORMATS.currency && typeof value === 'number';
};

export const isValuePercentage = (value: PerformanceMetricValue, field: PerformanceMetric): value is number => {
  return field.format.name === PERFORMANCE_METRICS_FORMATS.percentage && typeof value === 'number';
};

export const isValueRate = (value: PerformanceMetricValue, field: PerformanceMetric): value is number => {
  return field.format.name === PERFORMANCE_METRICS_FORMATS.rate && typeof value === 'number';
};

export const getFormattedTableValueByFieldFormat = (
  value: PerformanceMetricValue,
  field: PerformanceMetric,
  dateFormat: string,
  currencyCode?: string
): string => {
  if (value === undefined) return 'N/A';

  if (isValueDate(value, field)) {
    const formatDate = getFormattedDate(dateFormat);
    return formatDate(value);
  }

  if (isValueCurrency(value, field)) {
    return getCurrencyFormatted(value, currencyCode, false, DECIMAL_NUMERAL_FORMAT);
  }

  if (isValueInteger(value, field)) {
    return numeral(value).format(NUMERAL_FORMAT);
  }

  if (isValuePercentage(value, field)) {
    // @ts-ignore typescript thinks type of value is never
    const percentage = value.toFixed(2);
    return `${percentage}%`;
  }

  if (isValueRate(value, field)) {
    return `${numeral(value).format(PERFORMANCE_RATE_FORMAT)}`;
  }

  return `${value}`;
};

export const getProjectPathForRecord = (record: PerformanceDataItem) => {
  const projectId = parseInt(record.entityId, 10);
  return projectId ? paths.projectDetails(projectId) : undefined;
};

export const getAssignedCampaignPathForRecord = (record: PerformanceDataItem) => {
  if (record.metadata && !PerformanceCampaignMetadataSchema.is(record.metadata)) return undefined;

  const projectId = record.metadata?.projectId;
  const campaignUuid = record.metadata?.campaignUuid;

  return projectId && campaignUuid ? paths.campaign(projectId, campaignUuid) : undefined;
};

export const getUnassignedCampaignPathForRecord = (record: PerformanceDataItem) => {
  if (record.metadata && !PerformanceCampaignMetadataSchema.is(record.metadata)) return undefined;

  const campaignUuid = record.metadata?.campaignUuid;

  return campaignUuid ? paths.unassignedCampaignDetails(campaignUuid) : undefined;
};

export const getUniversalCampaignPathForRecord = (record: PerformanceDataItem) => {
  const pathForAssignedCampaign = getAssignedCampaignPathForRecord(record);

  return pathForAssignedCampaign ? pathForAssignedCampaign : getUnassignedCampaignPathForRecord(record);
};

export const getPathForBreakdownRecord = (record: PerformanceDataItem, breakdownId: Id): Optional<string> => {
  switch (breakdownId) {
    case BREAKDOWN_BY_PROJECT_ID:
      return getProjectPathForRecord(record);
    case BREAKDOWN_BY_CAMPAIGN_ID:
      return getAssignedCampaignPathForRecord(record);
    case BREAKDOWN_BY_LINKFIRE_LINK_ID:
      return HTTPS_LINK_PREFIX + record.name;
    default:
      return undefined;
  }
};

export const dateRangeByWeek = (week: string, dateFormat: string): string => {
  const [startDate, endDate] = week.split('/').map(date => parseDateFromISOString(date));
  const formatDate = getFormattedDate(dateFormat);

  if (!startDate || !isValidDate(startDate)) return 'Invalid dates';

  // TODO: Remove when delphi change the API
  if (!endDate || !isValidDate(endDate)) {
    const calculatedEndDate = addDaysToDate(startDate, 6);
    return `${formatDate(startDate)} - ${formatDate(calculatedEndDate)}`;
  }

  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
};

export const getFormattedBreakdownName = ({ name }: PerformanceDataItem, breakdownId: Id, dateFormat: string) => {
  const parsedDate = parseDateFromISOString(name);
  switch (breakdownId) {
    case BREAKDOWN_BY_DAY_ID:
      return getFormattedDate(dateFormat)(parsedDate);
    case BREAKDOWN_BY_WEEK_ID:
      return dateRangeByWeek(name, dateFormat);
    case BREAKDOWN_BY_CHARTWEEK_ID:
      return dateRangeByWeek(name, dateFormat);
    case BREAKDOWN_BY_MONTH_ID:
      return getFormattedDate('MMMM yyyy')(parsedDate);
    default:
      return name;
  }
};

export const getUniquePerformanceMetrics = (objectives?: PerformanceObjective[]): PerformanceMetric[] => {
  if (!objectives) return [];

  const metrics = objectives.flatMap(objective => objective.fields);
  return uniqBy(metrics, metric => metric.id);
};

export const getPerformanceMetricsByObjectiveId = (
  objectives: PerformanceObjective[],
  objectiveId?: Id
): PerformanceMetric[] => {
  return objectives.find(objective => objective.id === objectiveId)?.fields || [];
};

export const preparePerformanceTableContent = (values: PerformanceDataItem[]): PerformanceDataItemWithId[] =>
  values.map(value => ({ ...value, id: value.entityId }));

export const getPerformanceSelectedMetrics = (
  metricsIds: OptionIdType[],
  objectives?: PerformanceObjective[]
): PerformanceMetric[] => {
  const uniqueMetrics = getUniquePerformanceMetrics(objectives);
  return metricsIds.map(metricId => uniqueMetrics.find(metric => metric.id === metricId)).filter(notNullable);
};

export const isPerformanceFieldsEqual = (previousFields: string[], currentIds: number[]) => {
  const previousIds = previousFields.map(id => parseInt(id, 10));
  return isEqual(previousIds.sort(), [...currentIds].sort());
};

export const removeAllLabelsOptionFromLabels = (labelIds?: Id[] | null) =>
  labelIds?.filter(labelId => labelId !== ALL_LABELS_OPTION_ID);

export const getPerformanceItemsPage = (
  pagination: Pagination,
  performance?: PerformanceData
): PerformanceDataItem[] => {
  const items = performance?.items;
  const { pageSize, current } = pagination;

  if (!items || items.length === 0) return [];

  const pageItems: PerformanceDataItem[] = [];

  const showFrom = pageSize * (current - 1);
  const showTo = showFrom + pageSize;

  for (let i = showFrom; i < showTo; i++) {
    const item = items[i];
    if (item) pageItems.push(item);
  }

  return pageItems;
};

export const preparePerformanceRequestParams = <T extends PerformanceDataParams>(params?: T): Omit<T, 'fields'> =>
  omit(params, 'fields');
