import React, { useCallback, useEffect, useRef, useState } from 'react';
import { bem } from 'utils/bem';
import { callbackWithoutPropagation } from 'utils/event';
import { ExpandableField } from '../expandable-field';
import { MAX_LENGTH } from './constants';
import { BEM_CLASS, SExpandableText } from './s-expanded-text';

interface ExpandableTextProps {
  value: string;
  prefix?: string;
  placeholder?: string;
  stopEditing(): void;
  onTextareaChange(value: string): void;
  onKeyDown?(event: React.KeyboardEvent<HTMLTextAreaElement>): void;
  onBlur?(): void;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ExpandableText = React.memo(
  ({
    value,
    prefix = '',
    placeholder,
    stopEditing,
    onTextareaChange,
    onKeyDown,
    className,
    onBlur,
  }: ExpandableTextProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const prefixedValue = prefix + value;

    const [isError, setIsError] = useState(false);
    const [blurPrevented, setIsBlurPrevented] = useState(false);

    const handleClear = useCallback(() => {
      setIsError(false);

      onTextareaChange('');

      textareaRef?.current?.focus();
    }, [onTextareaChange]);

    const processChange = useCallback(
      (newValue: string, selectionStart = 0) => {
        const prefixRegex = new RegExp(`^${prefix}`, 'g');

        if (newValue.length > MAX_LENGTH) {
          setIsError(true);
        } else {
          setIsError(false);
        }

        const truncatedValue = newValue.substring(0, MAX_LENGTH);

        if (prefixRegex.test(newValue)) {
          const sanitizedValue = truncatedValue.replace(prefix, '');

          onTextareaChange(sanitizedValue);
        } else {
          const sanitizedValue = truncatedValue.substring(selectionStart, truncatedValue.length);

          if (sanitizedValue.endsWith(value)) {
            onTextareaChange(value);

            const selection = selectionStart + prefix.length + value.length - truncatedValue.length;

            queueMicrotask(() => {
              textareaRef?.current?.setSelectionRange(selection, selection);
            });
          } else {
            onTextareaChange(sanitizedValue);

            queueMicrotask(() => {
              textareaRef?.current?.setSelectionRange(prefix.length, prefix.length);
            });
          }
        }
      },
      [onTextareaChange, prefix, value]
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        processChange(event.target.value, event.target.selectionStart);
      },
      [processChange]
    );

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        onKeyDown?.(e);

        if (e.ctrlKey && e.key === 'Enter') {
          e.preventDefault();
          const index = textareaRef?.current?.selectionStart ?? value.length;
          const newValue = value.substring(0, index) + '\n' + value.substring(index);
          processChange(newValue);
          queueMicrotask(() => {
            textareaRef?.current?.setSelectionRange(index + 1, index + 1);
          });
        } else if (e.key === 'Enter' || e.key === 'Tab') {
          setIsBlurPrevented(true);
        }
      },
      [onKeyDown, processChange, value]
    );

    useEffect(() => {
      requestAnimationFrame(() => {
        textareaRef?.current?.setSelectionRange(prefixedValue.length, prefixedValue.length);
        textareaRef?.current?.focus();
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnBlur = useCallback(
      (event: React.FocusEvent<HTMLTextAreaElement>) => {
        if ((event.target as HTMLElement).isEqualNode(textareaRef.current) && !blurPrevented) {
          setIsBlurPrevented(false);
          textareaRef.current?.focus();

          return;
        }

        onBlur?.();
      },
      [onBlur, blurPrevented]
    );

    const onTextAreaMouseDown = useCallback((event: React.MouseEvent<HTMLTextAreaElement>) => {
      event.stopPropagation();
    }, []);

    useEffect(() => {
      const handleClick = (event: MouseEvent) => {
        if (containerRef?.current?.contains(event.target as Node)) return;
        event.stopPropagation();

        stopEditing();
      };

      document.addEventListener('click', handleClick, { capture: true });

      return () => {
        document.removeEventListener('click', handleClick, { capture: true });
      };
    }, [stopEditing]);

    return (
      <ExpandableField hasError={isError}>
        <SExpandableText className={className} ref={containerRef}>
          <textarea
            ref={textareaRef}
            className={classes('textarea')}
            data-selector="spreadsheet-textarea"
            value={prefixedValue}
            placeholder={placeholder}
            onMouseDown={onTextAreaMouseDown}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleOnBlur}
          />
          <div className={classes('footer')}>
            <button
              className={classes('clear', { isHidden: value.length === 0 })}
              onMouseDown={callbackWithoutPropagation()}
              onClick={callbackWithoutPropagation(handleClear)}
            >
              Clear All
            </button>
            <div className={classes('counter')}>
              <span className={classes('max', { isHidden: !isError })}>Max</span>{' '}
              {prefixedValue.length > 0 && (
                <span className={classes('count')}>{`${prefixedValue.length}/${MAX_LENGTH}`}</span>
              )}
            </div>
          </div>
        </SExpandableText>
      </ExpandableField>
    );
  }
);
