import styled from 'styled-components';

export const SAssignToProjectModal = styled.div`
  flex: 1 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .assign-to-project-modal {
    &__header {
      flex-shrink: 0;
      padding: 0 20px;
      margin-bottom: 5px;
    }

    &__body {
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      padding-bottom: 10px;
    }
  }
`;
