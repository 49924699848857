import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'team-option';

export const STeamOption = styled.button`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  padding: 10px 20px;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: ${transparentize(0.9, colorPalette.vulcan)};

    .${BEM_CLASS}__icon {
      opacity: 1;
      visibility: visible;
    }
  }

  .${BEM_CLASS} {
    &__title {
      text-align: left;
      margin-bottom: 5px;
    }

    &__main {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__members {
      margin-right: 15px;
    }

    &__icon {
      color: ${colorPalette.wildStrawberry};
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
    }
  }
`;
