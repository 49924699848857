import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'labels-list';

export const SLabelsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  & > :not(:last-child) {
    margin-right: 10px;
  }
  & > * {
    margin-top: 10px;
    height: 23px;
  }
  .${BEM_CLASS} {
    &__label-item {
      height: 23px;
      max-width: 149px;
      border-radius: 3px;
      padding: 0 10px;
      background-color: ${colorPalette.eastBay};
      color: white;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 23px;
      text-transform: uppercase;

      &--admin {
        background: black;
      }
    }
  }
`;
