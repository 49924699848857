import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'expandable-container';

export const SExpandableContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px 15px;
  background-color: ${transparentize(0.5, manhattanThemeColors.background)};
  border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
  border-radius: 10px;
  box-shadow: 0px 5px 20px ${transparentize(0.95, manhattanThemeColors.primaryDark)};

  .${BEM_CLASS} {
    &__header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__title {
      text-transform: capitalize;
      cursor: pointer;
      color: ${manhattanThemeColors.primaryDark};
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 26px;
      height: 26px;
      border-radius: 20px;
      cursor: pointer;
      background-color: ${transparentize(0.95, manhattanThemeColors.secondaryBackground)};
      margin-left: 20px;

      &--rotate {
        transform: rotate(180deg);
      }
    }

    &__actions {
      display: flex;
      align-items: center;
    }

    &__animation {
      overflow: hidden;
    }

    &__body {
      padding-top: 15px;
    }
  }
`;
