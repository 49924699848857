import React from 'react';
import { bem } from 'utils/bem';
import { SApproversControl, BEM_CLASS } from './s-approvers-control';
import { Icon } from 'common/components/icon';
import { ControlProps } from 'react-select';
import { SelectOption, SelectProps } from '../../types';

const classes = bem(BEM_CLASS);

export const ApproversControl = React.memo(
  ({ innerProps, innerRef, selectProps, isFocused, children }: ControlProps<SelectOption, true>) => {
    return (
      <SApproversControl
        {...innerProps}
        ref={innerRef}
        isFocused={isFocused}
        hasError={(selectProps as SelectProps).customSelectProps.hasError}
      >
        <div className={classes('control-icon')}>
          <Icon color="inherit" name="envelope" size="inherit" />
        </div>

        {children}
      </SApproversControl>
    );
  }
);
