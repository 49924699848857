import styled from 'styled-components';
import { Card } from 'common-v2/components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'project-card';

export const SProjectCard = styled(Card)`
  .${BEM_CLASS} {
    &__content {
      justify-content: center;
      padding: 5px 20px;
    }

    &__title {
      display: flex;
      align-items: center;
      max-width: 100%;
    }

    &__name {
      ${elipsizeText};
      flex-grow: 1;
    }

    &__icon-wrapper {
      margin-left: 14px;
      flex-shrink: 0;
    }

    &__icon {
      display: flex;
      align-items: center;
    }

    &__info {
      display: flex;
      align-items: baseline;
      max-width: 100%;
      color: ${manhattanThemeColors.grayedText};
    }

    &__badge {
      margin-right: 5px;
    }

    &__targets {
      ${elipsizeText};
      flex-grow: 1;
    }

    &__date {
      flex-shrink: 0;
    }
  }
`;
