import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'top-results';

export const STopResults = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .${BEM_CLASS} {
    &__container {
      display: flex;
      align-items: flex-start;
      padding: 15px 20px;
      overflow-x: hidden;
      overflow-y: auto;
      flex-grow: 1;
    }

    &__side {
      width: 50%;

      &:nth-child(1) {
        padding-right: 25px;
      }

      &:nth-child(2) {
        padding-left: 25px;
      }
    }

    &__artists {
      margin-bottom: 30px;
    }

    &__title {
      margin: 0 0 15px;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    &__info {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 20px;
      background-color: ${colorPalette.ghostWhite};
      flex-shrink: 0;
      backface-visibility: hidden;
    }

    &__info-icon {
      flex-shrink: 0;
      margin-right: 10px;
    }

    &__center {
      margin: auto;
      overflow: auto;
    }
  }
`;
