import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { basePaths } from 'app/routing/paths';
import logoSvg from 'assets/manhattan-decibel-logo.svg';
import { bem } from 'utils/bem';
import { SAppHeader, BEM_CLASS } from './s-app-header';
import { LabelSelector } from './components/label-selector';
import { Id, Label } from 'backend-api/models';
import { Button, BUTTON_SIZE, BUTTON_TYPE, THEME } from 'gdb-web-shared-components';
import { callbackWithoutPropagation } from 'utils/event';

interface AppHeaderProps {
  labels?: Label[];
  globalLabel?: Label;
  isLabelSelectorActive: boolean;
  onLabelChange(label: Label): void;
  showGlobalSearch(): void;
  hideGlobalSearch(): void;
  isSearchActive: boolean;
  isNewUIEnabled: boolean;
  userDropdown: React.ReactElement;
  searchField: React.ReactElement;
}

const classes = bem(BEM_CLASS);

export const AppHeader = React.memo(
  ({
    labels,
    globalLabel,
    isLabelSelectorActive,
    onLabelChange,
    showGlobalSearch,
    hideGlobalSearch,
    isSearchActive,
    isNewUIEnabled,
    userDropdown,
    searchField,
  }: AppHeaderProps) => {
    const onLabelIdChange = useCallback(
      (labelId?: Id) => {
        if (!labels || labelId === undefined) {
          return;
        }
        const label = labels.find(label => label.id === labelId);

        if (!label) {
          return;
        }
        onLabelChange(label);
      },
      [labels, onLabelChange]
    );

    return (
      <>
        <SAppHeader isNewUIEnabled={isNewUIEnabled} data-selector="app-header">
          <div className={classes('wrapper')}>
            {isSearchActive && isNewUIEnabled && <div className={classes('search-container')}>{searchField}</div>}
            {(!isNewUIEnabled || (isNewUIEnabled && !isSearchActive)) && (
              <div className={classes('items')}>
                <div className={classes('left-container')}>
                  <Link to={basePaths.projects()} className={classes('logo')} data-selector="app-header-logo">
                    <img alt="Decibel" src={logoSvg} />
                  </Link>
                  <div className={classes('divider')} />
                  <LabelSelector
                    isEnabled={isLabelSelectorActive}
                    labels={labels}
                    onClick={hideGlobalSearch}
                    selectedLabelId={globalLabel?.id}
                    onChange={onLabelIdChange}
                    className={classes('label-selector')}
                    dataSelector="app-header-label-selector"
                  />
                  {!isNewUIEnabled && searchField}
                </div>
                <div className={classes('right-container')}>
                  {isNewUIEnabled && (
                    <Button
                      type={BUTTON_TYPE.tertiary}
                      size={BUTTON_SIZE.mediumRound}
                      theme={THEME.light}
                      icon="search"
                      className={classes('search-button')}
                      data-selector="app-header-search-button"
                      onClick={callbackWithoutPropagation(showGlobalSearch)}
                    />
                  )}
                  {userDropdown}
                </div>
              </div>
            )}
          </div>
        </SAppHeader>
      </>
    );
  }
);
