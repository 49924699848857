import * as t from 'io-ts';
import { ArtistRosterProject } from '../../models';
import {
  FormattedDateFromISOString,
  DateTimeFromISOString,
  IdNameField,
  IdType,
  Optional,
  OptionalNullable,
  ResponseModel,
  TerritorySchema,
} from '../common';
import { LabelSchema } from '../label';
import { ReferenceUserSchema, UserRoleSchema, SimpleProjectUserSchema } from '../user';
import { TargetsSchema } from '../targets';
import { CampaignPlatformValueSchema, NamingConventionResponseSchema, StatusIdSchema } from '../campaign';
import { LinkfireSearchSchema } from '../linkfire';

export const ProjectStatusSchema = ResponseModel(IdNameField, 'ProjectStatus');

export type ProjectStatus = t.TypeOf<typeof ProjectStatusSchema>;

export const ProjectIdsResponseSchema = ResponseModel({ projectId: t.array(t.number) }, 'ProjectIdsResponse');

export type ProjectIdsResponse = t.TypeOf<typeof ProjectIdsResponseSchema>;

export const ProjectTypeSchema = ResponseModel(
  {
    ...IdNameField,
  },
  'ProjectType'
);

export type ProjectType = t.TypeOf<typeof ProjectTypeSchema>;

export const ShareStatusSchema = t.union([t.literal('by_me'), t.literal('to_me')], 'ShareStatus');

export type ShareStatus = t.TypeOf<typeof ShareStatusSchema>;

export const GrasDataSchema = ResponseModel(
  {
    id: t.string,
    title: t.string,
  },
  'GrasData'
);

export type GrasData = t.TypeOf<typeof GrasDataSchema>;

export const PRSDataSchema = ResponseModel(
  {
    id: t.string,
    title: t.string,
    updatedAt: DateTimeFromISOString,
  },
  'PRSData'
);

export type PRSData = t.TypeOf<typeof PRSDataSchema>;

export const CCPDataSchema = ResponseModel(
  {
    id: t.string,
    title: t.string,
    updatedAt: DateTimeFromISOString,
  },
  'CCPData'
);

export type CCPData = t.TypeOf<typeof CCPDataSchema>;

export const BudgetGroupCategorySchema = ResponseModel(
  {
    id: IdType,
    title: t.string,
    budget: t.number,
    allocation: t.number,
  },
  'BudgetGroupCategory'
);

export type BudgetGroupCategory = t.TypeOf<typeof BudgetGroupCategorySchema>;

export const BudgetGroupSchema = ResponseModel(
  {
    id: IdType,
    title: t.string,
    budget: t.number,
    allocation: t.number,
    categories: t.array(BudgetGroupCategorySchema),
  },
  'BudgetGroup'
);

export type BudgetGroup = t.TypeOf<typeof BudgetGroupSchema>;

export const ProjectLinkfireMetadataSchema = ResponseModel(
  {
    projectLinksCount: t.number,
    campaignsLinksCount: t.number,
  },
  'ProjectLinkfireMetadata'
);

export type ProjectLinkfireMetadata = t.TypeOf<typeof ProjectLinkfireMetadataSchema>;

export const ProjectCampaignsMetadataSchema = ResponseModel(
  {
    internalCampaignsCount: t.number,
    externalCampaignsCount: t.number,
    pendingCampaignsCount: t.number,
  },
  'ProjectCampaignsMetadata'
);

export type ProjectCampaignsMetadata = t.TypeOf<typeof ProjectCampaignsMetadataSchema>;

export const ProjectMetadataSchema = ResponseModel(
  {
    assignedUsersCount: t.number,
    latestEndDate: Optional(FormattedDateFromISOString),
    earliestStartDate: Optional(FormattedDateFromISOString),
    productFamiliesCount: t.number,
    campaigns: ProjectCampaignsMetadataSchema,
    linkfire: ProjectLinkfireMetadataSchema,
    estimatedImpressions: t.number,
    campaignPlatforms: t.array(CampaignPlatformValueSchema),
    plannedBudget: t.number,
  },
  'ProjectMetadata'
);

export type ProjectMetadata = t.TypeOf<typeof ProjectMetadataSchema>;

export const ProjectDetailsSchema = ResponseModel(
  {
    id: t.number,
    name: Optional(t.string),
    title: Optional(t.string),
    createUser: Optional(ReferenceUserSchema),
    editUser: Optional(ReferenceUserSchema),
    editDate: Optional(DateTimeFromISOString),
    userRoles: t.array(UserRoleSchema),
    startDate: Optional(FormattedDateFromISOString),
    endDate: Optional(FormattedDateFromISOString),
    budget: t.number,
    allocation: t.number,
    prsData: Optional(PRSDataSchema),
    status: Optional(StatusIdSchema),
    territories: Optional(t.array(TerritorySchema)),
    isClaimed: t.boolean,
    targets: TargetsSchema,
    label: Optional(LabelSchema),
    notes: Optional(t.string),
    grasData: Optional(GrasDataSchema),
    linkfireLinks: Optional(t.array(LinkfireSearchSchema)),
    metadata: Optional(ProjectMetadataSchema),
    isConfidential: t.boolean,
    currency: t.string,
    ccpData: Optional(CCPDataSchema),
  },
  'ProjectDetails'
);

export type ProjectDetails = t.TypeOf<typeof ProjectDetailsSchema>;

export const RepertoireOwnerSchema = ResponseModel(
  {
    id: IdType,
    externalId: Optional(t.string),
    name: t.string,
  },
  'RepertoireOwner'
);

export type RepertoireOwner = t.TypeOf<typeof RepertoireOwnerSchema>;

export const ProjectSchema = ResponseModel(
  {
    id: t.number,
    name: Optional(t.string),
    targets: TargetsSchema,
    startDate: Optional(DateTimeFromISOString),
    endDate: Optional(DateTimeFromISOString),
    status: Optional(StatusIdSchema),
    allocation: Optional(t.number),
    budget: Optional(t.number),
    userRoles: t.array(UserRoleSchema),
    isConfidential: t.boolean,
    isClaimed: t.boolean,
    currency: t.string,
  },
  'Project'
);

export type Project = t.TypeOf<typeof ProjectSchema>;

export const RecentProjectSchema = ResponseModel(
  {
    ...ProjectSchema.props,
    campaignsCount: t.number,
    isConfidential: t.boolean,
  },
  'RecentProject'
);

export type RecentProject = t.TypeOf<typeof RecentProjectSchema>;

export const UpcomingProjectSchema = ResponseModel(
  {
    id: t.number,
    name: Optional(t.string),
    targets: TargetsSchema,
    userRoles: t.array(UserRoleSchema),
    allocation: Optional(t.number),
    budget: Optional(t.number),
    startDate: Optional(DateTimeFromISOString),
    isConfidential: t.boolean,
    currency: t.string,
  },
  'UpcomingProject'
);

export type UpcomingProject = t.TypeOf<typeof UpcomingProjectSchema>;

export const ProjectsCounterSchema = ResponseModel(
  {
    artistCount: t.number,
    playlistCount: t.number,
    totalCount: t.number,
  },
  'ProjectsCounter'
);

export type ProjectsCounter = t.TypeOf<typeof ProjectsCounterSchema>;

export const ProjectsMetadataSchema = ResponseModel(
  {
    completed: ProjectsCounterSchema,
    inProgress: ProjectsCounterSchema,
    scheduled: ProjectsCounterSchema,
    unassigned: ProjectsCounterSchema,
    total: ProjectsCounterSchema,
  },
  'ProjectsMetadata'
);

export type ProjectsMetadata = t.TypeOf<typeof ProjectsMetadataSchema>;

export const ProjectSharingSchema = ResponseModel(
  {
    projectTeam: t.array(SimpleProjectUserSchema),
    artistTeamAndAdmins: t.array(SimpleProjectUserSchema),
    approvers: t.array(SimpleProjectUserSchema),
  },
  'ProjectsSharing'
);

export type ProjectSharing = t.TypeOf<typeof ProjectSharingSchema>;

export const UpdateProjectUserRequestSchema = ResponseModel(
  {
    userId: t.number,
    roleId: t.number,
  },
  'UpdateProjectUserRequest'
);

export type UpdateProjectUserRequest = t.TypeOf<typeof UpdateProjectUserRequestSchema>;

export const AddProjectUsersRequestSchema = ResponseModel(
  {
    roleId: t.number,
    usersIds: t.array(t.number),
  },
  'AddProjectUsersRequest'
);

export type AddProjectUsersRequest = t.TypeOf<typeof AddProjectUsersRequestSchema>;

export const AssignableProjectSchema = ResponseModel(
  {
    ...IdNameField,
    targets: TargetsSchema,
  },
  'AssignableProject'
);

export type AssignableProject = t.TypeOf<typeof AssignableProjectSchema>;

export const AllProjectsResponseSchema = ResponseModel(
  {
    items: t.array(ProjectDetailsSchema),
    metadata: ProjectsMetadataSchema,
    limit: Optional(t.number),
    offset: t.number,
  },
  'AllProjectsResponse'
);

export type AllProjectsResponse = t.TypeOf<typeof AllProjectsResponseSchema>;

export const AssignableProjectsResponseSchema = ResponseModel(
  { items: t.array(AssignableProjectSchema) },
  'AssignableProjectsResponse'
);

export type AssignableProjectsResponse = t.TypeOf<typeof AssignableProjectsResponseSchema>;

export const AllUnassignedProjectsResponseSchema = ResponseModel(
  {
    items: t.array(ProjectDetailsSchema),
    metadata: ProjectsMetadataSchema,
    limit: Optional(t.number),
    offset: t.number,
  },
  'AllUnassignedProjectsResponse'
);

export type AllUnassignedProjectsResponse = t.TypeOf<typeof AllUnassignedProjectsResponseSchema>;

export type AnyProject = Project | RecentProject | UpcomingProject | ProjectDetails | ArtistRosterProject;

export const ScheduleUpdateEntitySchema = ResponseModel(
  {
    entityId: IdType,
    startDate: t.string,
    endDate: Optional(t.string),
  },
  'ScheduleUpdateEntitySchema'
);

export const ScheduleUpdatesSchema = ResponseModel(
  {
    campaigns: t.array(ScheduleUpdateEntitySchema),
    phases: t.array(ScheduleUpdateEntitySchema),
  },
  'ScheduleUpdates'
);

export type ScheduleUpdates = t.TypeOf<typeof ScheduleUpdatesSchema>;

export const ProjectRequestSchema = ResponseModel(
  {
    name: t.string,
    startDate: FormattedDateFromISOString,
    endDate: FormattedDateFromISOString,
    targets: TargetsSchema,
    notes: Optional(t.string),
    scheduleUpdates: Optional(ScheduleUpdatesSchema),
    linkfireLinks: Optional(t.array(LinkfireSearchSchema)),
  },
  'ProjectRequest'
);

export type ProjectRequest = t.TypeOf<typeof ProjectRequestSchema>;

export const ShareRequestSchema = ResponseModel(
  {
    userId: IdType,
    editable: t.boolean,
  },
  'ShareRequest'
);

export type ShareRequest = t.TypeOf<typeof ShareRequestSchema>;

export interface MarketingMix extends IdNameField {
  budget: number;
  subtypes: MarketingMix[];
}

export const MarketingMixSchema: t.Type<MarketingMix> = t.recursion('MarketingMix', () =>
  ResponseModel(
    {
      ...IdNameField,
      budget: t.number,
      subtypes: t.array(MarketingMixSchema),
    },
    'MarketingMix'
  )
);

export const ArtistProjectsMetadataSchema = ResponseModel(
  {
    counts: ProjectsMetadataSchema,
    projectsLabels: t.array(LabelSchema),
  },
  'ArtistProjectsMetadata'
);

export type ArtistProjectsMetadata = t.TypeOf<typeof ArtistProjectsMetadataSchema>;

export const ArtistProjectsResponseSchema = ResponseModel(
  {
    items: t.array(ProjectSchema),
    metadata: ArtistProjectsMetadataSchema,
    limit: Optional(t.number),
    offset: t.number,
  },
  'ArtistProjectsResponse'
);

export type ArtistProjectsResponse = t.TypeOf<typeof ArtistProjectsResponseSchema>;

export const ProjectPlatformsResponseSchema = ResponseModel(
  {
    items: t.array(t.string),
  },
  'ProjectPlatformsResponse'
);

export type ProjectPlatformsResponse = t.TypeOf<typeof ProjectPlatformsResponseSchema>;

export const ProjectDataSourcesResponseSchema = ResponseModel(
  {
    items: t.array(t.string),
  },
  'ProjectDataSourcesResponse'
);

export type ProjectDataSourcesResponse = t.TypeOf<typeof ProjectDataSourcesResponseSchema>;

export const ProjectReleaseTypeSchema = ResponseModel({ ...IdNameField }, 'ProjectReleaseType');

export type ProjectReleaseType = t.TypeOf<typeof ProjectReleaseTypeSchema>;

export const MediaPlanReleaseSchema = ResponseModel(
  {
    name: Optional(t.string),
    type: OptionalNullable(IdType),
  },
  'MediaPlanRelease'
);

export type MediaPlanRelease = t.TypeOf<typeof MediaPlanReleaseSchema>;

export const MediaPlanReleaseUpdateResponseSchema = ResponseModel(
  {
    name: Optional(t.string),
    type: OptionalNullable(IdType),
    campaignsNames: t.array(NamingConventionResponseSchema),
  },
  'MediaPlanReleaseUpdateResponse'
);

export type MediaPlanReleaseUpdateResponse = t.TypeOf<typeof MediaPlanReleaseUpdateResponseSchema>;

export const ShortProjectSchema = ResponseModel(
  {
    ...IdNameField,
    editDate: Optional(FormattedDateFromISOString),
    isConfidential: t.boolean,
    targets: TargetsSchema,
    label: LabelSchema,
  },
  'ShortProject'
);

export type ShortProject = t.TypeOf<typeof ShortProjectSchema>;
