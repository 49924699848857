import { actionsGroup } from 'core/store';
import {
  Artist,
  ArtistSearch,
  BudgetGroup,
  Campaign,
  HistoryEvent,
  Id,
  MarketingMix,
  PerformanceDataItem,
  Playlist,
  ProjectDetails,
  ProjectUser,
  TeamCountersResponse,
  TeamUser,
} from 'backend-api/models';
import { ProjectActionRequest } from 'common/actions';
import { ConfirmModal, FunnelChartFilter, FunnelChartRequestPayload, GetProjectHistoryPayload } from './types';
import { LinkfireSearchOption } from 'common/components/linkfire-search/types';
import { ShareModalResponse } from 'modals/sharing-modal/types';

export interface ProjectInput {
  artists: Artist[];
  playlists: Playlist[];
  name: string;
  startDate?: Date;
  endDate?: Date;
  budget?: number;
  allocation?: number;
  type: number;
  notes?: string;
  labelId?: number;
  linkfireLinks?: LinkfireSearchOption[];
}

export interface ContactInput {
  name: string;
  email: string;
}

export interface EditProjectPayload {
  projectId: Id;
  data: ProjectInput;
  changedFields?: string[];
}

export interface DeleteProjectPayload {
  projectId: Id;
  onUndo: () => void;
}

interface OpenArtistModalPayload {
  selectedArtists: Artist[];
  selectedPlaylists: Playlist[];
}

interface ShareProjectRequest {
  projectId: Id;
  shareInfo: ShareModalResponse;
  closeShareProjectModal(): void;
}

interface GetProjectTeamUsersResponse {
  projectUsers: ProjectUser[];
  teamUsers: TeamUser[];
}

const { action, asyncAction } = actionsGroup('project');

export const getProject = asyncAction<ProjectActionRequest, ProjectDetails, Error>('GET_PROJECT');

export const resetProject = action<void>('RESET_PROJECT');

export const claimUnassignedProject = asyncAction<EditProjectPayload, ProjectDetails, Error>(
  'CLAIM_UNASSIGNED_PROJECT'
);

export const deleteProject = asyncAction<DeleteProjectPayload, unknown, Error>('DELETE_PROJECT');

export const editProject = asyncAction<EditProjectPayload, ProjectDetails, Error>('EDIT_PROJECT');

export const showConfirmModal = action<ConfirmModal>('SHOW_CONFIRM_MODAL');

export const toggleShareWindow = action<void>('TOGGLE_SHARE_WINDOW');

export const closeShareWindow = action<void>('CLOSE_SHARE_WINDOW');

export const getProjectTeamUsersInfo = asyncAction<Id, GetProjectTeamUsersResponse, Error>(
  'GET_PROJECT_TEAM_USERS_INFO'
);

export const shareProject = asyncAction<ShareProjectRequest, TeamUser[], string>('SHARE_PROJECT');

export const undoDeleteProject = asyncAction<Id, unknown, Error>('UNDO_DELETE_PROJECT');

export const getProjectHistoryAction = asyncAction<GetProjectHistoryPayload, HistoryEvent[], Error>(
  'GET_PROJECT_HISTORY'
);

export const getTeamCountersAction = asyncAction<Id, TeamCountersResponse, Error>('GET_PROJECT_MARKETING_MIX');

export const getProjectMarketingMixAction = asyncAction<Id, MarketingMix[], Error>('GET_PROJECT_TEAM_COUNTERS');

export const getNotesHistoryAction = asyncAction<Id, HistoryEvent[], Error>('GET_PROJECT_NOTES_HISTORY');

export const openArtistModal = action<OpenArtistModalPayload>('OPEN_ARTIST_MODAL');
export const closeArtistModal = action<void>('CLOSE_ARTIST_MODAL');

export const searchArtist = asyncAction<string, ArtistSearch[], Error>('SEARCH_ARTISTS');
export const searchPlaylists = asyncAction<string, Playlist[], Error>('SEARCH_PLAYLISTS');

export const selectArtist = action<Artist>('SELECT_ARTIST_MODAL');
export const selectPlaylist = action<Playlist>('SELECT_PLAYLIST_MODAL');
export const deselectArtist = action<string>('DESELECT_ARTIST_MODAL');
export const deselectPlaylist = action<Playlist>('DESELECT_PLAYLIST_MODAL');
export const updateSelectedArtists = action<Artist[]>('UPDATE_SELECTED_ARTISTS');

export const clearSelectionArtistModal = action<void>('CLEAR_SELECTION_ARTIST_MODAL');

export const showDeleteModal = action<boolean>('SHOW_DELETE_MODAL');

export const showViewAllTargetsModal = action<boolean>('SHOW_VIEW_ALL_TARGETS_MODAL');

export const getBudgetGroupsForProjectAction = asyncAction<Id, BudgetGroup[], Error>('GET_PROJECT_BUDGET_GROUPS');

export const getUnassignedProjectCampaigns = asyncAction<Id, Campaign[], Error>('GET_UNASSIGNED_PROJECT_CAMPAIGNS');

export const getFunnelGraphData = asyncAction<FunnelChartRequestPayload, PerformanceDataItem[], Error>(
  'GET_FUNNEL_GRAPH_DATA'
);

export const setFunnelGraphFilter = action<FunnelChartFilter>('SET_FUNNEL_GRAPH_FILTER');
