import { matchPath } from 'react-router-dom';
import { ApiError } from 'backend-api';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { paths } from 'app/routing/paths';
import { ProjectPath } from 'app/routing/types';
import { ErrorViewStates } from 'common-v2/components';
import { PrimaryAndFeaturedArtists, ProjectFormValues, ProjectTab } from './types';
import { PROJECT_PATH_TO_TAB_MAP, TARGET_OPTIONS } from './constants';
import {
  ProjectRequest,
  ProjectDetails,
  Contact,
  Label,
  Id,
  Artist,
  SimpleProjectUser,
  IdNameField,
  ProjectSharing,
} from 'backend-api/models';
import { getProjectName, isEmptyMarkdown, mapLinkfireSearchToBaseOption } from 'common-v2/transducers';
import { isEqual, trim } from 'lodash';

export const getErrorViewType = (error: ApiError<ProjectDetailsErrorResponse>): ErrorViewStates => {
  if (error.statusCode === undefined) return ErrorViewStates.none;
  if (error.statusCode === 404) return ErrorViewStates.notFound;
  if (error.statusCode >= 500) return ErrorViewStates.serverError;

  return ErrorViewStates.none;
};

export const getProjectTabPath = (projectId: number, tab: ProjectTab): string => {
  switch (tab) {
    case ProjectTab.Summary:
      return paths.project(projectId);
    case ProjectTab.MediaPlan:
      return paths.mediaPlan(projectId);
    case ProjectTab.Reporting:
      return paths.reporting(projectId);
    case ProjectTab.Finance:
      return paths.purchaseOrders(projectId);
  }
};

export const getActiveProjectTab = (url: string, pathname: string): ProjectTab => {
  const path = [ProjectPath.mediaPlans, ProjectPath.mediaPlan, ProjectPath.reporting, ProjectPath.finance].find(path =>
    matchPath(pathname, {
      path: url + path,
      exact: true,
    })
  );

  return path ? PROJECT_PATH_TO_TAB_MAP[path] : ProjectTab.MediaPlan;
};

export const prepareRequest = (values: ProjectFormValues): ProjectRequest => {
  const isArtists = TARGET_OPTIONS.find(type => type.id === values.type)?.name === 'Artist';
  return {
    targets: isArtists
      ? {
          items: values.artists,
          type: 'Artist',
        }
      : {
          items: values.playlists,
          type: 'Playlist',
        },
    name: trim(values.name),
    startDate: values.dates?.[0] || new Date(),
    endDate: values.dates?.[1] || new Date(),
    notes: isEmptyMarkdown(values.notes) ? undefined : values.notes,
    scheduleUpdates: undefined,
    linkfireLinks:
      values.linkfireLinks?.map(option => ({
        id: option.modelId,
        linkId: option.linkId,
        linkUrl: option.linkUrl,
        deletable: option.isFixed,
      })) || [],
  };
};

export const prepareProjectFormValues = (project: ProjectDetails): ProjectFormValues => {
  const targetsType = TARGET_OPTIONS.find(type => type.name === project.targets.type)?.id;
  const type = targetsType || TARGET_OPTIONS[0].id;

  return {
    artists: project.targets.type === 'Artist' ? project.targets.items : [],
    playlists: project.targets.type === 'Playlist' ? project.targets.items : [],
    name: getProjectName(project),
    budget: project.budget,
    dates: [project.startDate, project.endDate],
    allocation: project.allocation,
    notes: project.notes || '<p></p>',
    type,
    labelId: project.label?.id,
    linkfireLinks: mapLinkfireSearchToBaseOption(project.linkfireLinks || []),
    grassId: project.grasData?.id,
  };
};

export const getProjectFormValues = (
  id?: Id,
  project?: ProjectDetails,
  currentUser?: Contact,
  labels?: Label[]
): ProjectFormValues => {
  const defaultInitialValues = {
    type: TARGET_OPTIONS[0].id,
    labelId: labels?.[0]?.id,
    ownerId: currentUser?.id,
    phone: `+${currentUser?.phone}`,
    email: currentUser?.email,
    artists: [],
    playlists: [],
    name: getProjectName(project),
  };

  if (!id || !project) return defaultInitialValues;

  return prepareProjectFormValues(project);
};

export const getFieldsWithDiffValues = <T>(firstObj: T, secondObj: T): string[] => {
  if (!firstObj || !secondObj) return [];

  return Object.keys(firstObj).reduce((result, key) => {
    return isEqual(firstObj[key], secondObj[key]) ? result : result.concat(key);
  }, <string[]>[]);
};

export const getPrimaryAndFeaturedArtists = (artists: Artist[]): PrimaryAndFeaturedArtists => {
  const primaryArtists: Artist[] = [];
  const featuredArtists: Artist[] = [];

  artists
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach(artist => {
      artist.type === 'Primary' ? primaryArtists.push(artist) : featuredArtists.push(artist);
    });

  return { primaryArtists, featuredArtists };
};

export const updateUserRole = (userId: Id, role: IdNameField) => (user: SimpleProjectUser) => {
  if (user.id === userId) {
    return { ...user, role: { id: role.id, name: role.name, categoryIds: user.role.categoryIds } };
  }

  return user;
};

export const isSharingInfoEmpty = (info?: ProjectSharing): boolean => {
  if (info === undefined) return true;

  return info.approvers.length === 0 && info.projectTeam.length === 0;
};
