import styled from 'styled-components';
import { ellipsizeText } from 'common-v2/styles/ellipsize-text';

export const BEM_CLASS = 'member-item';

export const SMemberItem = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__name {
      ${ellipsizeText}
      margin-left: 10px;
    }

    &__avatar {
      flex-shrink: 0;
    }

    &__role {
      flex-shrink: 0;
      margin-left: auto;
      margin-right: 20px;
    }
  }
`;
