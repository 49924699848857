import { onAction } from 'core/store';
import { toggleIdInArray } from 'utils/data';
import {
  openAttachReleasesModal,
  closeAttachReleasesModal,
  resetAttachReleasesModal,
  setAttachReleasesModalActiveTab,
  sortProductFamilies,
  setSelectedProductFamilies,
  setExpandedProductFamilies,
  toggleProductFamilyExpand,
  setProductFamiliesSearch,
  setPlaylists,
  setSelectedPlaylists,
  setPlaylistsSearch,
  setPendingProductFamilies,
  getProductFamilies,
  searchPlaylists,
} from '../actions';
import { CommonState, initialState } from './common-reducer';
import { LoadingState } from '../types';
import { AttachReleasesTab } from '../types/phases';

export const attachReleasesActionsReducer = [
  onAction(openAttachReleasesModal, (state: CommonState, { payload }) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      isOpened: true,
      phase: payload.phase,
      project: payload.project,
      pendingPlaylists: payload.phase.playlists,
      pendingProductFamilies: payload.phase.product_families,
      playlistsState: {
        ...state.attachReleasesModal.playlistsState,
        selected: payload.phase.playlists,
      },
      productFamiliesState: {
        ...state.attachReleasesModal.productFamiliesState,
        selected: payload.phase.product_families,
      },
      activeTab: payload.activeTab,
    },
  })),
  onAction(closeAttachReleasesModal, (state: CommonState) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      isOpened: false,
    },
  })),
  onAction(resetAttachReleasesModal, (state: CommonState) => ({
    ...state,
    attachReleasesModal: {
      ...initialState.attachReleasesModal,
      activeTab: AttachReleasesTab.ProductFamilies,
    },
  })),
  onAction(setAttachReleasesModalActiveTab, (state: CommonState, { payload }) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      activeTab: payload,
    },
  })),
  onAction(getProductFamilies.request, (state: CommonState) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      allProductFamilies: {
        loading: LoadingState.Started,
        data: state.attachReleasesModal.allProductFamilies.data,
      },
      productFamiliesState: {
        ...state.attachReleasesModal.productFamiliesState,
        loading: LoadingState.Started,
      },
    },
  })),
  onAction(getProductFamilies.success, (state: CommonState, action) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      allProductFamilies: { loading: LoadingState.Finished, data: action.payload },
      productFamiliesState: {
        ...state.attachReleasesModal.productFamiliesState,
        loading: LoadingState.Finished,
      },
    },
  })),
  onAction(getProductFamilies.failure, (state: CommonState) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      allProductFamilies: {
        loading: LoadingState.Failed,
        data: state.attachReleasesModal.allProductFamilies.data,
      },
      productFamiliesState: {
        ...state.attachReleasesModal.productFamiliesState,
        loading: LoadingState.Failed,
      },
    },
  })),
  onAction(sortProductFamilies, (state: CommonState, action) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      productFamiliesState: {
        ...state.attachReleasesModal.productFamiliesState,
        sort: action.payload,
      },
    },
  })),
  onAction(setSelectedProductFamilies, (state: CommonState, action) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      productFamiliesState: {
        ...state.attachReleasesModal.productFamiliesState,
        selected: action.payload,
      },
    },
  })),
  onAction(setExpandedProductFamilies, (state: CommonState, { payload }) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      productFamiliesState: {
        ...state.attachReleasesModal.productFamiliesState,
        expandedProductFamiliesIds: payload.map(family => family.id),
      },
    },
  })),
  onAction(toggleProductFamilyExpand, (state: CommonState, { payload }) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      productFamiliesState: {
        ...state.attachReleasesModal.productFamiliesState,
        expandedProductFamiliesIds: toggleIdInArray(
          state.attachReleasesModal.productFamiliesState.expandedProductFamiliesIds,
          payload.id
        ),
      },
    },
  })),
  onAction(setProductFamiliesSearch, (state: CommonState, { payload }) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      productFamiliesState: {
        ...state.attachReleasesModal.productFamiliesState,
        search: payload,
      },
    },
  })),
  onAction(searchPlaylists.request, (state: CommonState) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      playlistsState: {
        ...state.attachReleasesModal.playlistsState,
        loading: LoadingState.Started,
      },
    },
  })),
  onAction(searchPlaylists.success, (state: CommonState, { payload }) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      playlistsState: {
        ...state.attachReleasesModal.playlistsState,
        loading: LoadingState.Finished,
        data: payload,
      },
    },
  })),
  onAction(searchPlaylists.failure, (state: CommonState) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      playlistsState: {
        ...state.attachReleasesModal.playlistsState,
        loading: LoadingState.Failed,
      },
    },
  })),
  onAction(setPlaylists, (state: CommonState, { payload }) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      playlistsState: {
        ...state.attachReleasesModal.playlistsState,
        data: payload,
      },
    },
  })),
  onAction(setSelectedPlaylists, (state: CommonState, { payload }) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      playlistsState: {
        ...state.attachReleasesModal.playlistsState,
        selected: payload,
      },
    },
  })),
  onAction(setPlaylistsSearch, (state: CommonState, { payload }) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      playlistsState: {
        ...state.attachReleasesModal.playlistsState,
        search: payload,
      },
    },
  })),
  onAction(setPendingProductFamilies, (state: CommonState, action) => ({
    ...state,
    attachReleasesModal: {
      ...state.attachReleasesModal,
      pendingProductFamilies: action.payload,
    },
  })),
];
