import { from, of } from 'rxjs';
import { auth0Lock } from './auth0';

export const login = (errorMessage = {}) => {
  const params = {};

  if (errorMessage?.error) {
    params.flashMessage = {
      type: 'error',
      text: decodeURIComponent(errorMessage.error_description),
    };
  }

  auth0Lock.show(params);

  return of(null);
};

export function hideLoginForm() {
  return auth0Lock.hide();
}

export const logout = () => {
  auth0Lock.logout({
    returnTo: `${window.location.origin}/login`,
  });

  return of(null);
};

export const checkSession = () => {
  const res = new Promise((resolve, reject) =>
    auth0Lock.checkSession({ redirectUri: `${window.location.origin}/auth/callback` }, (err, authResult) => {
      if (err) {
        reject(err);
      } else {
        resolve({ authResultRenewal: authResult });
      }
    }),
  );
  return from(res);
};

//†api
