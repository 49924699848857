import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'metric-item';

export const SMetricItem = styled.div`
  display: flex;
  gap: 5px;

  .${BEM_CLASS} {
    &__label {
      color: ${manhattanThemeColors.grayedText};
    }
  }
`;
