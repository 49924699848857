import { manhattanThemeColors } from 'app/theme/colors';
import { CampaignStatuses } from 'backend-api/models';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { getOptionIndicatorStyle } from './transducers';

export const BEM_CLASS = 'approval-select-option';

interface Props {
  isDisabled: boolean;
  isFocused?: boolean;
  status?: CampaignStatuses;
}

export const SApprovalSelectOption = styled.div<Props>`
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 7px 20px;

  ${({ isDisabled }) =>
    isDisabled
      ? css``
      : css`
          cursor: pointer;

          &:hover {
            background-color: ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};

            .${BEM_CLASS} {
              &__value {
                font-weight: 700;
              }
            }
          }
        `}

  .${BEM_CLASS} {
    &__option-indicator {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-right: 14px;

      ${({ status }) => getOptionIndicatorStyle(status)}
    }
  }
`;
