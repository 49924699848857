import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { callbackWithoutPropagation } from 'utils/event';
import { ReleaseDetailsPopup } from 'media-plan-v2/components/release-details-popup';
import { bem } from 'utils/bem';
import { BEM_CLASS, SReleaseInfo } from './s-release-info';
import { Loader } from './components';
import { Id } from 'backend-api/models';

interface Props {
  mediaPlanId: Id;
  projectId: Id;
  loading?: boolean;
  disabled?: boolean;
  isInputsDisabled: boolean;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ReleaseInfo = React.memo(
  ({ mediaPlanId, projectId, loading, disabled = false, isInputsDisabled, className }: Props) => {
    const releaseDetailsControlRef = useRef(null);
    const [isReleaseDetailsOpened, setIsReleaseDetailsOpened] = useState(false);

    const openReleaseDetails = useCallback(() => {
      setIsReleaseDetailsOpened(true);
    }, []);

    const closeReleaseDetails = useCallback(() => {
      setIsReleaseDetailsOpened(false);
    }, []);

    const releaseDetailsTrigger = useMemo(() => {
      if (loading) return <Loader />;

      return (
        <button
          ref={releaseDetailsControlRef}
          disabled={disabled}
          onMouseDown={callbackWithoutPropagation()}
          onClick={openReleaseDetails}
          className={classes('trigger')}
        >
          <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('trigger-title', { isDisabled: disabled })}>
            View
          </Typography>
        </button>
      );
    }, [loading, disabled, openReleaseDetails]);

    return (
      <SReleaseInfo className={className}>
        <div className={classes('info-item')}>
          <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('label')}>
            Release Details
          </Typography>

          <div className={classes('release-details')}>{releaseDetailsTrigger}</div>

          <ReleaseDetailsPopup
            projectId={projectId}
            mediaPlanId={mediaPlanId}
            isOpened={isReleaseDetailsOpened}
            isDisabled={isInputsDisabled}
            onClose={closeReleaseDetails}
            controlRef={releaseDetailsControlRef}
          />
        </div>
      </SReleaseInfo>
    );
  }
);
