import React from 'react';
import { MenuProps } from 'react-select';
import { BaseOption as BaseOptionType } from '../../types';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { SMenuWrapper } from './s-menu-wrapper';

type Props<T extends BaseOptionType, IsMulti extends boolean> = MenuProps<T, IsMulti>;

const Root = <T extends BaseOptionType, IsMulti extends boolean>({
  children,
  innerRef,
  innerProps,
  selectProps,
}: Props<T, IsMulti>) => {
  const { classNames, dataSelector } = getBaseCustomSelectProps(selectProps);

  return (
    <SMenuWrapper {...innerProps} ref={innerRef} className={classNames.menu} data-selector={`${dataSelector}-menu`}>
      {children}
    </SMenuWrapper>
  );
};

export const MenuWrapper = React.memo(Root) as typeof Root;
