import { Icon } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SEllipsisIcon } from './s-ellipsis-icon';

const classes = bem(BEM_CLASS);

export const EllipsisIcon = React.memo(() => (
  <SEllipsisIcon>
    <Icon className={classes('icon')} name="ellipsis" />
  </SEllipsisIcon>
));
