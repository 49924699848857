import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DropResult } from 'react-beautiful-dnd';
import { Artist, ArtistType, Playlist } from 'backend-api/models';
import { insertItem, moveItem, removeItemAt } from 'utils/data';
import { DraggableSectionConfig, SectionConfig, SelectedSection } from 'common/components/selected-section';
import { clearSelectionArtistModal, deselectArtist, deselectPlaylist, updateSelectedArtists } from 'project/actions';
import { selectedArtistsSelector, selectedPlaylistsSelector } from 'project/selectors';
import {
  ArtistModalType,
  ArtistModalTypeInfo,
  FEATURED_ARTISTS_LIMIT,
  PLAYLISTS_LIMIT,
  PRIMARY_ARTISTS_LIMIT,
} from 'project/constants';
import { SelectedArtistPlaylistItem } from 'common/components/selected-artist-playlist-item';
import { isArtistLocked, getDraggableArtistItems } from 'project/transducers';

interface Props {
  type: ArtistModalTypeInfo;
  className?: string;
}

const PRIMARY_LIST_ID = 'primary';
const FEATURED_LIST_ID = 'featured';

export const ArtistsPlaylistsSelectedSection = React.memo(({ className, type }: Props) => {
  const dispatch = useDispatch();

  const selectedArtists = useSelector(selectedArtistsSelector);
  const selectedPlaylists = useSelector(selectedPlaylistsSelector);

  const onClear = useCallback(() => dispatch(clearSelectionArtistModal()), [dispatch]);

  const isArtistModal = type.type === ArtistModalType.Artist;

  const renderPlaylistItem = useCallback(
    (playlist: Playlist) => {
      const onRemove = () => {
        dispatch(deselectPlaylist(playlist));
      };
      return <SelectedArtistPlaylistItem target={playlist} onRemove={onRemove} />;
    },
    [dispatch]
  );

  const primaryArtists = useMemo(() => selectedArtists.filter(artist => artist.type === 'Primary'), [selectedArtists]);
  const featuredArtists = useMemo(() => selectedArtists.filter(artist => artist.type === 'Featured'), [
    selectedArtists,
  ]);

  const renderArtistItem = useCallback(
    (artist: Artist) => {
      const selectedArtist = selectedArtists.find(selected => artist.id === selected.id);
      const isSelectedArtistLocked = selectedArtist && isArtistLocked(selectedArtist);

      const onRemove = () => {
        if (isSelectedArtistLocked) return;
        dispatch(deselectArtist(artist.id));
      };

      return <SelectedArtistPlaylistItem target={artist} onRemove={onRemove} isLocked={isSelectedArtistLocked} />;
    },
    [dispatch, selectedArtists]
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const type: ArtistType = result.destination.droppableId === PRIMARY_LIST_ID ? 'Primary' : 'Featured';
      const targetArray = result.destination.droppableId === PRIMARY_LIST_ID ? primaryArtists : featuredArtists;
      const sourceArray = result.source.droppableId === PRIMARY_LIST_ID ? primaryArtists : featuredArtists;

      if (result.destination.droppableId === result.source.droppableId) {
        const fromIndex = result.source.index;
        const toIndex = result.destination.index;
        const secondArray = result.source.droppableId === PRIMARY_LIST_ID ? featuredArtists : primaryArtists;
        const newArtists = moveItem(targetArray, fromIndex, toIndex);
        newArtists[toIndex] = { ...newArtists[toIndex], type };
        dispatch(updateSelectedArtists(newArtists.concat(secondArray)));
      } else {
        const fromIndex = result.source.index;
        const toIndex = result.destination.index;
        const newSourceArray = removeItemAt(sourceArray, fromIndex);
        const newTargetArray = insertItem(targetArray, toIndex, sourceArray[fromIndex]);
        newTargetArray[toIndex] = { ...newTargetArray[toIndex], type };
        dispatch(updateSelectedArtists(newSourceArray.concat(newTargetArray)));
      }
    },
    [dispatch, primaryArtists, featuredArtists]
  );

  const artistsSectionsConfig: DraggableSectionConfig<Artist>[] = useMemo(
    () => [
      {
        id: PRIMARY_LIST_ID,
        title: 'Primary',
        placeholder: 'Add at least one primary artist to continue.',
        selectedItems: getDraggableArtistItems(primaryArtists),
        renderItem: renderArtistItem,
        draggable: true,
        dropOutsideEnabled: primaryArtists.length !== PRIMARY_ARTISTS_LIMIT,
        maxCount: PRIMARY_ARTISTS_LIMIT,
        withCounter: true,
      },
      {
        id: FEATURED_LIST_ID,
        title: 'Featured',
        placeholder: 'Drag and drop featured artists here.',
        selectedItems: featuredArtists,
        renderItem: renderArtistItem,
        draggable: true,
        dropOutsideEnabled: featuredArtists.length !== FEATURED_ARTISTS_LIMIT,
        maxCount: FEATURED_ARTISTS_LIMIT,
        withCounter: true,
      },
    ],
    [primaryArtists, renderArtistItem, featuredArtists]
  );

  const playlistsSectionConfig: SectionConfig<Playlist>[] = useMemo(
    () => [
      {
        id: 'playlists',
        title: '',
        selectedItems: selectedPlaylists,
        renderItem: renderPlaylistItem,
        maxCount: PLAYLISTS_LIMIT,
        withCounter: true,
      },
    ],
    [selectedPlaylists, renderPlaylistItem]
  );

  const section = useMemo(
    () =>
      isArtistModal ? (
        <SelectedSection
          dragEnabled
          onDragEnd={onDragEnd}
          title={`Selected (${selectedArtists.length})`}
          placeholder="No selected Artists"
          onResetClick={onClear}
          sectionsConfig={artistsSectionsConfig}
          className={className}
        />
      ) : (
        <SelectedSection
          title={`Selected (${selectedPlaylists.length})`}
          placeholder="No selected Playlists"
          onResetClick={onClear}
          sectionsConfig={playlistsSectionConfig}
          className={className}
        />
      ),
    [
      isArtistModal,
      artistsSectionsConfig,
      playlistsSectionConfig,
      onClear,
      onDragEnd,
      selectedPlaylists,
      selectedArtists,
      className,
    ]
  );

  return section;
});
