import { manhattanThemeColors } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'member-item';

export const SMemberItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;

  .${BEM_CLASS} {
    &__container {
      padding: 10px 0;
      display: flex;
      gap: 10px;
    }

    &__name-and-email {
      display: flex;
      flex-direction: column;
    }

    &__avatar {
      margin-left: 7.5px;
      height: 25px;
      width: 25px;
      font-size: 12px;
    }

    &__name {
      ${elipsizeText}
      font-size: 14px;
      line-height: 25px;
    }

    &__email {
      font-size: 12px;
      line-height: 14px;
      color: ${transparentize(0.4, manhattanThemeColors.primaryDark)};
    }

    &__chip {
      margin: 0 0 3px 10px;
      border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
      border-radius: 5px;
      background-color: ${transparentize(0.5, manhattanThemeColors.background)};
      pointer-events: none;
    }

    &__delete {
      display: none;
      flex-shrink: 0;
      margin-left: 5px;
      margin-right: 20px;
      background: ${transparentize(0.95, manhattanThemeColors.hoveredBorderColor)};

      &:hover {
        background: ${transparentize(0.9, manhattanThemeColors.hoveredBorderColor)};
      }
    }
  }

  &:hover {
    background: ${transparentize(0.95, manhattanThemeColors.hoveredBorderColor)};

    .${BEM_CLASS} {
      &__delete {
        display: block;
      }
    }
  }
`;
