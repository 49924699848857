import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { paginationArrow } from 'app/styles/z-indexes';

export const SArrow = styled('span')`
  &.pagination {
    &__arrow {
      position: absolute;
      width: 8px;
      height: 18px;
      display: inline-block;
      top: 7px;
      z-index: ${paginationArrow};
      &::before,
      &::after {
        content: '';
        width: 2px;
        height: 6px;
        position: absolute;
        left: 2px;
        display: inline-block;
        background-color: ${palette.brightBlue};
        border-radius: 1px;
      }
      &--disabled {
        &::before,
        &::after {
          background-color: ${palette.lavanderGray};
        }
      }
      &::before {
        top: 9px;
      }
      &::after {
        top: 5px;
      }
      &--left {
        left: 14px;
        &::before {
          transform: rotate(-45deg);
        }
        &::after {
          transform: rotate(45deg);
        }
      }
      &--right {
        margin-left: 15px;
        &::before {
          transform: rotate(45deg);
        }
        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }
`;
