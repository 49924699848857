import { CampaignStatuses } from 'backend-api/models';
import { BaseGroup, BaseOption } from 'common/components/select';
import { Body } from 'common/components/typography';
import React from 'react';
import { OptionProps } from 'react-select';
import { bem } from 'utils/bem';
import { getDataSelector } from 'utils/string';
import { BEM_CLASS, SApprovalSelectOption } from './s-approval-select-option';

const classes = bem(BEM_CLASS);

export const ApprovalSelectOption = React.memo(
  ({ innerProps, data, isFocused, isDisabled }: OptionProps<BaseOption, false, BaseGroup<BaseOption>>) => {
    return (
      <SApprovalSelectOption
        {...innerProps}
        ref={null}
        isFocused={isFocused}
        isDisabled={isDisabled}
        status={data?.value as CampaignStatuses}
        data-selector={getDataSelector('approval-option', data?.label)}
      >
        <div className={classes('option-indicator')} />
        <Body>{data?.label}</Body>
      </SApprovalSelectOption>
    );
  }
);
