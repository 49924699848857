import styled from 'styled-components';
import { SInput } from 'common/components/form/input/s-input';
import { colorPalette } from 'app/theme/colors';
import { typography } from 'app/theme/typography';

export const BEM_CLASS = 'age-editor';
interface Props {
  disabled?: boolean;
}

export const SAgeInput = styled.div<Props>`
  .${BEM_CLASS} {
    &__mask-input {
      ${SInput};

      padding: 8px 10px;
      border: 0;
      text-align: end;

      &:focus-within {
        border: 0;
      }

      &::placeholder {
        ${typography.body};
        color: ${colorPalette.wildBlueYonder};
      }
    }
  }
`;
