import styled from 'styled-components';
import { manhattanBackground } from 'common-v2/styles';

export const BEM_CLASS = 'app-loader';

export const SAppLoader = styled.div`
  ${manhattanBackground};

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  min-height: 100vh;

  .${BEM_CLASS} {
    &__loader {
      width: 50px;
      height: 50px;
    }
  }
`;
