import { Popper } from 'common/components/popper';
import React, { useCallback, useState } from 'react';
import { Icon } from 'gdb-web-shared-components';
import { BEM_CLASS, SMediaPlanDropdown } from './s-media-plan-dropdown';
import { bem } from 'utils/bem';
import { Id, IdNameField } from 'backend-api/models';
import { Popup } from './components';
import first from 'lodash/first';

interface Props {
  mediaPlanList: IdNameField[];
  onItemClick(id: Id): void;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const MediaPlansDropdown = React.memo(({ mediaPlanList, onItemClick, className }: Props) => {
  const [isOpen, setOpen] = useState(false);

  const onShown = useCallback(() => setOpen(true), []);
  const onHide = useCallback(() => setOpen(false), []);

  const isPopperDisabled = mediaPlanList.length === 1;

  const onClickOpenFirstMediaPlan = useCallback(() => {
    const firstMediaPlan = first(mediaPlanList);
    if (!isPopperDisabled || !firstMediaPlan) return;

    onItemClick(firstMediaPlan.id);
  }, [isPopperDisabled, mediaPlanList, onItemClick]);

  return (
    <Popper
      content={<Popup onItemClick={onItemClick} mediaPlanList={mediaPlanList} />}
      placement="bottom"
      onShow={onShown}
      onHide={onHide}
      disabled={isPopperDisabled}
      appendTo={document.body}
    >
      <SMediaPlanDropdown variant="primary-cta" className={className} onClick={onClickOpenFirstMediaPlan}>
        Go to media plan
        {!isPopperDisabled && <Icon name={isOpen ? 'up-single-m' : 'down-single-m'} className={classes('icon')} />}
      </SMediaPlanDropdown>
    </Popper>
  );
});
