import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const SArrowWrapper = styled('div')`
  &.pagination {
    &__wrapper {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      margin: 0 4px;
      cursor: pointer;
      &:hover {
        background: ${palette.lightBlue};
      }
      &--disabled {
        pointer-events: none;
      }
    }
  }
`;
