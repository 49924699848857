import React from 'react';
import { appearingAnimation } from 'common/constants';
import { UpcomingProjectsLoader } from 'common/components/loaders/upcoming-projects-loader';
import { SArtistPlaylistWidgetLoader } from './s-artist-playlist-widget-loader';

interface Props {
  className?: string;
}

export const ArtistPlaylistWidgetLoader = React.memo(({ className }: Props) => {
  return (
    <SArtistPlaylistWidgetLoader {...appearingAnimation} className={className}>
      <UpcomingProjectsLoader />
    </SArtistPlaylistWidgetLoader>
  );
});
