import styled from 'styled-components';

interface STreeItemProps {
  depth: number;
  isDisabled: boolean;
  isEmpty: boolean;
}

export const STreeItem = styled.div<STreeItemProps>`
  margin-left: ${({ depth }) => `${depth * 25}px`};
  margin-bottom: ${({ depth, isEmpty }) => `${isEmpty ? 25 : 20 + depth * 5}px`};
  opacity: ${({ isDisabled }) => (isDisabled ? 0.6 : 1)};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'default')};

  .tree-item {
    &__label {
      font-weight: ${({ depth, isEmpty }) => (depth || isEmpty ? '400' : '700')} !important;
    }
  }
`;
