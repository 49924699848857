import { TeamUser, UserRoleType } from 'backend-api/models/user';
import { ROLES } from 'common-v2/constants';
import { LocalRole } from 'common-v2/types';
import { ShareModalContext } from 'modals/sharing-modal/types';
import { ShareModalListId } from './constants';

export const prepareUsersForDisplay = (users: TeamUser[], role: UserRoleType): TeamUser[] => {
  return users
    .filter(user => user.roles.some(({ id }) => id === role))
    .sort((prevItem, nexItem) => prevItem.name.localeCompare(nexItem.name));
};

export const getUpdatedUserRole = (listId: ShareModalListId, context: ShareModalContext): LocalRole => {
  if (context === ShareModalContext.ARTIST_TEAM && listId === ShareModalListId.EDITORS) {
    return ROLES.EDITOR;
  }

  return listId === ShareModalListId.EDITORS ? ROLES.EDITOR : ROLES.VIEWER;
};

export const listIdToRole = (listId: ShareModalListId) => {
  switch (listId) {
    case ShareModalListId.EDITORS:
      return ROLES.EDITOR;
    case ShareModalListId.VIEWERS:
      return ROLES.VIEWER;
  }
};
