import { ofType } from 'core/epics';
import { Api } from 'backend-api';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { getUserInfo } from 'common/actions';
import { updateCurrentUser } from './actions';

export const updateUserEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(updateCurrentUser.request),
    switchMap(({ payload }) => {
      const { dateTimeFormat, phone, onError } = payload;
      return Api.updateUserInfo({ dateTimeFormat, phone }).pipe(
        mergeMap(response => [updateCurrentUser.success(response), getUserInfo.success(response)]),
        catchError(error => {
          onError();

          return of(updateCurrentUser.failure(error));
        })
      );
    })
  );
};
