import { UUID } from 'io-ts-types/lib/UUID';
import { CampaignFilterStatus } from '../../models/campaign';
import { FreeTextParam } from '../search';

export * from './guards';

export const CAMPAIGN_SORT_FIELDS = [
  'id',
  'name',
  'platforms',
  'adSetsCount',
  'spend',
  'startDate',
  'endDate',
  'linkfireLinks',
] as const;

export type CampaignSortField = typeof CAMPAIGN_SORT_FIELDS[number];

export interface CampaignFilterParams extends FreeTextParam {
  readonly platforms?: string;
  readonly offset?: number;
  readonly limit?: number;
  readonly sort?: string;
  readonly status?: CampaignFilterStatus;
}

export interface CampaignDetailsParams extends FreeTextParam {
  readonly isDigital?: boolean;
}

export interface ChangeCampaignsStatusRequest {
  campaignUuids?: UUID[];
  externalCampaignIds?: (string | number)[];
}

export interface RemoveCampaignsParams {
  campaignUuids: UUID[];
}
