import styled from 'styled-components';

export const STeamMembersValueContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px 0;
  margin-right: 12px;
  margin-left: 5px;
`;
