import {
  Campaign,
  CampaignCategory,
  CampaignCategoryAndTypes,
  CampaignDetails,
  CampaignPlacement,
  CampaignPlatformObjectType,
  CampaignPlatformsGroup,
  CampaignPlatformWithPlacements,
  CampaignSources,
  CampaignStatuses,
  Id,
  Optional,
  Taxonomy,
} from 'backend-api/models';
import { CAMPAIGN_CATEGORY_GROUPS, CampaignColor } from 'common/constants';
import { NOT_AVAILABLE } from 'common-v2/constants';
import { IconName } from 'common/components/icon/types';
import { CUSTOM_PLACEMENTS_OPTION, DEFAULT_PLACEMENTS_OPTION } from 'campaign/constants';
import { BaseGroup, BaseOption } from 'common/components/select';
import { intersection } from 'lodash';
import { colorPalette } from 'app/theme/colors';
import { notNullable } from 'utils/data';

export const isCampaignExternal = (campaign: CampaignDetails | Campaign | undefined): boolean =>
  !!campaign && campaign.source !== CampaignSources.MANUAL;

export const getCampaignCategory = (
  campaignTypeId?: Id,
  campaignCategories?: CampaignCategoryAndTypes[]
): Optional<CampaignCategoryAndTypes> => {
  return campaignCategories?.find(category => category.campaignTypes.some(value => value.id === campaignTypeId));
};

export const getCampaignCategoryColor = (categoryId?: Id): CampaignColor => {
  switch (categoryId) {
    case CAMPAIGN_CATEGORY_GROUPS.digital:
      return 'cerulean';
    case CAMPAIGN_CATEGORY_GROUPS.traditional:
      return 'amethyst';
    case CAMPAIGN_CATEGORY_GROUPS.radio:
      return 'mountainMeadow';
    default:
      return 'uncategorizedGray';
  }
};

export const getCampaignCategoryIconName = (category?: CampaignCategory): IconName => {
  switch (category?.id) {
    case CAMPAIGN_CATEGORY_GROUPS.digital:
      return 'social-medium';
    case CAMPAIGN_CATEGORY_GROUPS.traditional:
      return 'tv-ad-medium';
    case CAMPAIGN_CATEGORY_GROUPS.radio:
      return 'appearances';
    default:
      return 'unknown';
  }
};

export const getCampaignGroupNameById = (groupId: Id): Optional<string> =>
  Object.keys(CAMPAIGN_CATEGORY_GROUPS).find(key => CAMPAIGN_CATEGORY_GROUPS[key] === groupId);

export const getCampaignBudget = ({ source, budgetSpend, plannedBudget }: CampaignDetails): Optional<number> =>
  source === CampaignSources.FACEBOOK ? budgetSpend : plannedBudget;

export const prepareCampaignBudgetRequestValue = (budget?: number | string): number => {
  if (!budget || budget === NOT_AVAILABLE) return 0;

  if (typeof budget === 'number') return budget;

  const parsedBudget = parseInt(budget, 10);

  if (isNaN(parsedBudget)) return 0;

  return parsedBudget;
};

export const generateCampaignName = (
  selectedCampaignTypeId: Id,
  selectedPlatformIds: Id[],
  campaignTaxonomy?: Taxonomy
): string | undefined => {
  if (!campaignTaxonomy) return;
  const selectedCampaignType = campaignTaxonomy.categories
    .flatMap(category => category.campaignTypes)
    .find(type => type.id === selectedCampaignTypeId);

  const selectedPlatforms = campaignTaxonomy.platforms.filter(platform => selectedPlatformIds.includes(platform.id));

  const platformNames =
    selectedPlatforms.length <= 2 ? selectedPlatforms.map(platform => platform.name).join(', ') : 'Various Platforms';

  const campaignAutoName: string[] = [selectedCampaignType?.name || '', platformNames];
  return !campaignAutoName[1] ? campaignAutoName[0] : campaignAutoName.map(item => item.toString().trim()).join(' | ');
};

export const getPlacementsOptions = (
  allPlacements?: CampaignPlacement[],
  selectedPlatformIds?: Id[],
  allPlatforms?: CampaignPlatformsGroup[],
  isUppercase = true
): BaseGroup[] => {
  if (!allPlacements) {
    return [];
  }

  const flattenedPlatforms = getFlattenedPlatforms(allPlatforms || []);

  const platforms = flattenedPlatforms?.filter(platform => selectedPlatformIds?.includes(platform.id));
  const placementIds = platforms?.flatMap(platform => platform.defaultPlacements);

  const defaultPlacements = allPlacements
    .filter(placement => placementIds?.includes(placement.id))
    .map(item => ({
      id: item.id,
      value: item.name,
    }))
    .sort((placement1, placement2) => placement1.value.localeCompare(placement2.value));
  const customPlacements = allPlacements
    .filter(placement => !!placement.labelId)
    .map(item => ({
      id: item.id,
      value: item.name,
    }));

  const options: BaseGroup[] = [];

  if (defaultPlacements.length > 0) {
    const platformLabels = platforms
      .filter(platform => platform.defaultPlacements.length !== 0)
      .map(platform => platform.name)
      .join(' and ');
    options.push({
      ...DEFAULT_PLACEMENTS_OPTION,
      label: isUppercase ? `For ${platformLabels}`.toUpperCase() : `For ${platformLabels}`,
      options: defaultPlacements,
    });
  }

  if (customPlacements.length > 0) {
    options.push({ ...CUSTOM_PLACEMENTS_OPTION, options: customPlacements });
  }

  return options;
};

export const getSelectedPlacementsForSelectedPlatforms = (
  allPlatforms?: CampaignPlatformsGroup[],
  allPlacements?: CampaignPlacement[],
  selectedPlatforms?: Id[],
  selectedPlacements?: Id[]
) => {
  if (!allPlatforms || !allPlacements || !selectedPlatforms || !selectedPlacements) {
    return undefined;
  }

  const flattenedPlatforms = getFlattenedPlatforms(allPlatforms);

  const allSelectedPlatformsPlacementsIds =
    flattenedPlatforms
      .filter(platform => selectedPlatforms.includes(platform.id))
      .flatMap(platform => platform.defaultPlacements) || [];

  const allCustomPlacements =
    allPlacements.filter(placement => !!placement.labelId).map(placement => placement.id) || [];

  const allAvailablePlacements = [...allSelectedPlatformsPlacementsIds, ...allCustomPlacements];

  return intersection(allAvailablePlacements, selectedPlacements);
};

export const getCampaignStatusColor = (statusId?: string): string => {
  switch (statusId) {
    case CampaignStatuses.DRAFT:
      return colorPalette.gray;
    case CampaignStatuses.BOOKED:
      return colorPalette.eastBay;
    case CampaignStatuses.LIVE:
      return colorPalette.jade;
    case CampaignStatuses.COMPLETED:
      return colorPalette.black;
    default:
      return colorPalette.eastBay;
  }
};

export const getFlattenedPlatforms = (platformsGroups: CampaignPlatformsGroup[]): CampaignPlatformWithPlacements[] => {
  return platformsGroups.flatMap(group => group.items);
};

export const isPlatformGroup = (
  platform: CampaignPlatformWithPlacements | CampaignPlatformsGroup
): platform is CampaignPlatformsGroup => platform.hasOwnProperty('type');

export const getIsPlatformDisabled = (
  platform: CampaignPlatformWithPlacements | CampaignPlatformsGroup,
  selectedIds: Id[]
) => {
  if (selectedIds.length === 0) {
    return false;
  }

  if (isPlatformGroup(platform)) {
    if (platform.type === CampaignPlatformObjectType.CATEGORY) return true;
    const platformsIds = platform.items?.map(child => child.id) || [];
    return intersection(selectedIds, platformsIds).length === 0;
  }

  return selectedIds.includes(platform.id);
};

export const getPlatformsOptions = (groups?: CampaignPlatformsGroup[], selectedIds?: Id[]): BaseGroup[] => {
  if (!groups || !selectedIds) {
    return [];
  }

  return groups.map(group => {
    const label: BaseGroup = { label: group.name, options: [] };
    const isGroupDisabled = getIsPlatformDisabled(group, selectedIds);
    const platforms: BaseOption[] = group.items.map(platform => {
      const isDisabled = getIsPlatformDisabled(platform, selectedIds) || isGroupDisabled;
      return {
        id: platform.id,
        label: platform.name,
        isDisabled,
        value: platform.name,
      };
    });
    return { ...label, options: platforms };
  });
};

export const getDefaultExpandedPlatforms = (platforms?: BaseGroup[]): string[] => {
  if (!platforms) {
    return [];
  }
  return platforms.map(platform => platform.id?.toString()).filter(notNullable);
};
