import { OptionalPeriod } from 'common/types';
import { DEFAULT_DATE_FORMAT } from 'common-v2/constants';
import { getFormattedDate } from 'common-v2/utils';

export const getPeriodFormatted = (format = DEFAULT_DATE_FORMAT) => (period: OptionalPeriod): string => {
  const [startDate, endDate] = period;
  if (!startDate && !endDate) return '';

  if (!endDate) return getFormattedDate(format)(startDate);
  if (!startDate) return getFormattedDate(format)(endDate);

  return `${getFormattedDate(format)(startDate)} – ${getFormattedDate(format)(endDate)}`;
};
