import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'error-view';

export const SErrorView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .${BEM_CLASS} {
    &__image {
      &--top {
        margin-bottom: 25px;
      }

      &--bottom {
        margin-top: 30px;
      }
    }

    &__description {
      padding-bottom: 10px;
      text-align: center;
    }

    &__action-button {
      padding: 3px 0;
      border-bottom: 1px dashed ${transparentize(0.4, manhattanThemeColors.placeholderTextColor)};
    }
  }
`;
