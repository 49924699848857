import { actionsGroup } from 'core/store';
import {
  ArtistMomentCategory,
  ArtistMomentTypeGroup,
  ArtistTeam,
  AssignableProjectsResponse,
  BreakdownOption,
  CampaignPlacement,
  CampaignProvider,
  CampaignStatus,
  CountryCode,
  Currency,
  CurrentUser,
  Gender,
  GlobalSearch,
  Goal,
  Id,
  Label,
  LabelUser,
  LinkfireSearch,
  Optional,
  PerformanceObjectivesAndMetrics,
  PerformancePlatforms,
  ProjectDetails,
  ProjectReleaseType,
  ProjectStatus,
  RecentSearchItem,
  Taxonomy,
  Territory,
  UuidNameField,
} from 'backend-api/models';
import { AssignableProjectsParams, FromSearchParam, GlobalSearchParams, RecentSearchParams } from 'backend-api/types';
import { ToastOptions } from 'common/components/toast';
import { SearchTab } from 'app/components/search-modal/components/search-tabs/types';
import { CreateCampaignPlacementPayload, CurrentProjectOwner, SetAccessRestrictedDataPayload } from 'common/types';
import { UUID } from 'io-ts-types/lib/UUID';

export interface ProjectActionRequest {
  projectId: Id;
  isUnassigned?: boolean;
  hasErrorToast?: boolean;
  params?: FromSearchParam;
}

interface AssignCampaignToProjectPayload {
  campaignUuid: UUID;
  projectId: Id;
  onCampaignAssigned?: (projectId: Id) => void;
}

interface SetDateFormatPayload {
  dateFormat: string;
  dateTimeFormat: string;
}

const { action, asyncAction } = actionsGroup('common');

export const setFilters = action<any>('SET_FILTER');

export const getLabels = asyncAction<void, Label[], Error>('GET_LABELS');

export const getAllLabels = asyncAction<void, Label[], Error>('GET_ALL_LABELS');

export const getCampaignTaxonomy = asyncAction<Id[], Taxonomy, Error>('GET_CAMPAIGN_TYPES');

export const getTerritories = asyncAction<void, Territory[], Error>('GET_TERRITORIES');

export const getProjectStatuses = asyncAction<void, ProjectStatus[], Error>('GET_PROJECT_STATUS');

export const getCurrencies = asyncAction<void, Currency[], Error>('GET_CURRENCIES');

export const setActiveFiltersName = action<string>('SET_ACTIVE_FILTERS_NAME');

export const flush = action<void>('FLUSH');

export const getLabelUsers = asyncAction<Id, LabelUser[], Error>('GET_LABEL_USERS');

export const getUserInfo = asyncAction<void, CurrentUser, Error>('GET_USER_INFO');

export const getGoals = asyncAction<void, Goal[], Error>('GET_GOALS');

export const getGenders = asyncAction<void, Gender[], Error>('GET_GENDERS');

export const getCampaignProviders = asyncAction<UUID, CampaignProvider[], Error>('GET_CAMPAIGN_PROVIDERS');

export const getPerformanceCampaignProviders = asyncAction<void, CampaignProvider[], Error>(
  'GET_PERFORMANCE_CAMPAIGN_PROVIDERS'
);

export const getProject = asyncAction<ProjectActionRequest, ProjectDetails, Error>('GET_PROJECT');

export const clearProject = action<void>('CLEAR_PROJECT');

export const clearProjectError = action<void>('CLEAR_PROJECT_ERROR');

export const getCountryCodes = asyncAction<void, CountryCode[], Error>('GET_COUNTRY_CODES');

export const getPerformanceObjectives = asyncAction<void, PerformanceObjectivesAndMetrics, Error>(
  'GET_PERFORMANCE_OBJECTIVES'
);

export const getPerformancePlatforms = asyncAction<void, PerformancePlatforms, Error>('GET_PERFORMANCE_PLATFORMS');

export const getPerformanceArtistsBreakdowns = asyncAction<void, BreakdownOption[], Error>(
  'GET_PERFORMANCE_ARTISTS_BREAKDOWNS'
);

export const getPerformanceProjectsBreakdowns = asyncAction<void, BreakdownOption[], Error>(
  'GET_PERFORMANCE_PROJECTS_BREAKDOWNS'
);

export const getPerformanceCampaignsBreakdowns = asyncAction<void, BreakdownOption[], Error>(
  'GET_PERFORMANCE_CAMPAIGNS_BREAKDOWNS'
);

export const openAssignCampaignToProjectModal = action<UuidNameField>('OPEN_ASSIGN_CAMPAIGN_TO_PROJECT_MODAL');
export const closeAssignCampaignToProjectModal = action<void>('CLOSE_ASSIGN_CAMPAIGN_TO_PROJECT_MODAL');
export const searchProjectsForAssignModal = asyncAction<AssignableProjectsParams, AssignableProjectsResponse, Error>(
  'SEARCH_PROJECTS_FOR_ASSIGN_MODAL'
);
export const getRecommendedProjectsForAssignModal = asyncAction<UUID, AssignableProjectsResponse, Error>(
  'GET_RECOMMENDED_PROJECTS_FOR_ASSIGN_MODAL'
);
export const assignCampaignToProject = asyncAction<AssignCampaignToProjectPayload, unknown, Error>(
  'ASSIGN_CAMPAIGN_TO_PROJECT'
);

export const showToast = action<ToastOptions>('SHOW_TOAST');
export const removeToast = action<string | void>('REMOVE_TOAST');
export const removeToasts = action<void>('REMOVE_TOASTS');

export const getArtistMomentTypes = asyncAction<void, ArtistMomentTypeGroup[], Error>('GET_ARTISTS_MOMENT_TYPES');
export const getArtistMomentCategories = asyncAction<void, ArtistMomentCategory[], Error>(
  'GET_ARTISTS_MOMENT_CATEGORIES'
);

export const showGlobalSearch = action<void>('SHOW_GLOBAL_SEARCH');
export const hideGlobalSearch = action<void>('HIDE_GLOBAL_SEARCH');
export const setGlobalSearchQuery = action<string>('SET_GLOBAL_SEARCH_VALUE');
export const getRecentSearch = asyncAction<RecentSearchParams, RecentSearchItem[], Error>('GET_RECENT_SEARCH_DATA');
export const getGlobalSearch = asyncAction<GlobalSearchParams, GlobalSearch, Error>('GET_GLOBAL_SEARCH_DATA');
export const getGlobalSearchPage = asyncAction<GlobalSearchParams, GlobalSearch, Error>('GET_GLOBAL_SEARCH_PAGE');
export const setGlobalSearchActiveTab = action<SearchTab>('SET_GLOBAL_SEARCH_ACTIVE_TAB');
export const resetGlobalSearch = action<void>('RESET_GLOBAL_SEARCH');
export const resetRecentSearch = action<void>('RESET_RECENT_SEARCH');

export const searchLinkfireLinks = asyncAction<string, LinkfireSearch[], Error>('SEARCH_LINKFIRE_SEARCH');

export const setProjectOwner = action<CurrentProjectOwner | undefined>('SET_PROJECT_OWNER');

export const setAccessRestrictedData = action<SetAccessRestrictedDataPayload>('SET_ACCESS_RESTRICTED_DATA');
export const resetAccessRestrictedData = action<void>('RESET_ACCESS_RESTRICTED_DATA');

export const setDateFormat = action<SetDateFormatPayload>('SET_DATE_FORMAT');

export const getCampaignStatuses = asyncAction<void, CampaignStatus[], Error>('GET_CAMPAIGN_STATUSES');

export const createPlacement = asyncAction<CreateCampaignPlacementPayload, CampaignPlacement, Error>(
  'CREATE_CAMPAIGN_PLACEMENTS'
);

export const getProjectArtistTeam = asyncAction<Id, Optional<ArtistTeam>, Error>('GET_PROJECT_ARTIST_TEAM');

export const getProjectReleaseTypes = asyncAction<void, ProjectReleaseType[], Error>('GET_PROJECT_RELEASE_TYPES');

export const setGlobalLabel = action<Label>('SET_GLOBAL_LABEL');
