import React, { useRef } from 'react';
import { Tooltip } from 'common-v2/components/tooltip';
import { useIsTextOverflown } from 'common-v2/hooks';
import { bem } from 'utils/bem';
import { BEM_CLASS, SLongTableTitle } from './s-long-table-title';
import { TOOLTIP_SHOW_DELAY } from './constants';

const classes = bem(BEM_CLASS);

interface Props {
  text: string;
  className?: string;
  textClassName?: string;
  dataSelector?: string;
}

export const LongTableTitle = React.memo(({ text, className, dataSelector, textClassName }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const isTextOverflown = useIsTextOverflown(ref, false);
  return (
    <SLongTableTitle className={className} data-selector={dataSelector}>
      <Tooltip placement="bottom" delay={TOOLTIP_SHOW_DELAY} content={text} disabled={!isTextOverflown}>
        <span ref={ref} className={classes('long-text', {}, textClassName)} data-selector={dataSelector}>
          {text}
        </span>
      </Tooltip>
    </SLongTableTitle>
  );
});
