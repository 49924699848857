import React, { useCallback } from 'react';
import { motion } from 'framer-motion';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { SExpandableContainer, BEM_CLASS } from './s-expandable-container';

const classes = bem(BEM_CLASS);

interface Props {
  title: string;
  isOpen: boolean;
  children: React.ReactNode;
  className?: string;
  actions?: React.ReactNode[];
  onToggle(isOpen: boolean): void;
}

export const ExpandableContainer = React.memo(({ title, isOpen, children, className, actions, onToggle }: Props) => {
  const handleToggle = useCallback(() => {
    onToggle(!isOpen);
  }, [isOpen, onToggle]);

  return (
    <SExpandableContainer className={className}>
      <div className={classes('header')}>
        <div className={classes('title')} onClick={handleToggle} data-selector="expandable-container-title">
          <Typography type={TYPOGRAPHY_TYPE.heading2}>{title}</Typography>
        </div>

        <div className={classes('actions')}>
          {actions}

          <div
            className={classes('icon', { rotate: isOpen })}
            onClick={handleToggle}
            data-selector="expandable-container-toggle"
          >
            <Icon name="down-single" color="black" />
          </div>
        </div>
      </div>

      <motion.div
        className={classes('animation')}
        initial={{ height: 0 }}
        animate={{ height: isOpen ? 'auto' : 0 }}
        transition={{ type: 'linear' }}
      >
        <div className={classes('body')}>{children}</div>
      </motion.div>
    </SExpandableContainer>
  );
});
