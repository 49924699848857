import { createGlobalStyle } from 'app/styled';
import icomoon from 'frontend-shared-icons/dist/fonts/icomoon.woff';
import { palette } from 'frontend-shared-theme';
import { notifications, toasts } from 'app/styles/z-indexes';
import { PAGE_WIDTH } from 'common/constants';

// Global styles but theme- and update-able!
export const SGlobalStyles = createGlobalStyle`
	@font-face {
    font-family: "icomoon";
    src: url(${icomoon}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  html, body {
    width: 100%;
    height: 100%;
  }

  html {
    display: block;
  }

  h1,h2,h3,h4 {
    margin: 0;
  }

  a {
    color: ${palette.brightBlue};
	  text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
	}

  button {
    cursor: pointer;
  }

  button:disabled,
  button[disabled] {
    cursor: default;
  }

	button,
	input[type='checkbox'] {
		outline: none;
	}

	a,
	area,
	button,
	[role='button'],
	input:not([type='range']),
	label,
	select,
	summary,
	input,
	textarea {
    touch-action: manipulation;
    font-family: Montserrat;
  }

  span,
  button,
  input {
    &:focus {
      outline: none;
    }
  }

  input:disabled {
    color: ${palette.darkGrayishBlue};
  }

	#root {
		height: 100%;
		width: 100%;
    min-width: ${PAGE_WIDTH}px;
  }
  
  #app_toasts {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${toasts};
  }

  #app_notifications {
    position: fixed;
    top:0;
    right: 0;
    left: 0;
    z-index: ${notifications};
  }

	button {
		margin: 0;
		padding: 0;
		border: 0;
		background: none;
		font-size: 100%;
		vertical-align: baseline;
		font-family: inherit;
		font-weight: inherit;
		color: inherit;
		appearance: none;
		font-smoothing: antialiased;
	}

	body {
		height: 100%;
		width: 100%;
		padding: 0;
		min-width: 1024px;
		margin: 0 auto;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		-ms-font-smoothing: antialiased;
		font-smoothing: antialiased;
		font-weight: 400;
		font-family: Montserrat;
    font-feature-settings: unset;
    font-variant: unset;
    font-size: 14px;
    line-height: 1.5;
	}

	h1 {
		font-size: 40px;
	}

  .tippy-box[data-reference-hidden] {
    visibility: hidden;
    pointer-events: none;
  }

	.starts-on-first-day {
    padding-left: 10px;
  }

  .ends-on-last-day {
    padding-right: 10px;
  }

	// Hacks
	body {
	  .ml-20 {
	    margin-left: 20px;
	  }

    .ReactModal__Body--open {
      overflow: hidden;
      position: fixed;
      padding-right: 17px;
    }
  }
`;
