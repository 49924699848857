import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SingleToggleGroup, SingleToggle, SINGLE_TOGGLE_TYPE } from 'gdb-web-shared-components';
import { paths } from 'app/routing/paths';
import { navigateTo } from 'utils/navigation';
import { MediaPlanMode } from 'media-plan-v2/types';
import { mediaPlanModeSelector } from 'media-plan-v2/selectors';
import { resetCampaignsForApproval } from 'media-plan-v2/actions';

interface MediaPlanModeControlsProps {
  projectId: number;
  mediaPlanId?: number;
}

export const MediaPlanModeControls = React.memo(({ projectId, mediaPlanId }: MediaPlanModeControlsProps) => {
  const dispatch = useDispatch();

  const mediaPlanMode = useSelector(mediaPlanModeSelector);

  const handleChange = useCallback(() => {
    const query = mediaPlanMode === MediaPlanMode.DEFAULT ? { mode: MediaPlanMode.APPROVAL } : {};

    if (mediaPlanMode !== MediaPlanMode.APPROVAL) {
      dispatch(resetCampaignsForApproval());
    }

    navigateTo(paths.mediaPlan(projectId, mediaPlanId), { query });
  }, [mediaPlanMode, projectId, mediaPlanId, dispatch]);

  return (
    <SingleToggleGroup value={mediaPlanMode} onValueChange={handleChange}>
      <SingleToggle toggleType={SINGLE_TOGGLE_TYPE.textOnly} text="Approval view" value={MediaPlanMode.APPROVAL} />
      <SingleToggle toggleType={SINGLE_TOGGLE_TYPE.textOnly} text="Regular view" value={MediaPlanMode.DEFAULT} />
    </SingleToggleGroup>
  );
});
