import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SRow } from 'common/s-components/layout/s-row';
import { SCol } from 'common/s-components/layout/s-col';
import { SSpacer } from 'common/s-components/layout/s-spacer';
import { Field } from 'common/components/form/formik-field';
import { Cell } from 'common/components/form/cell';
import { Button } from 'common/components/button';
import { Icon } from 'common/components/icon';
import { useCurrencies, useProjectCurrencyFormat } from 'hooks';
import { SPaper } from 'common/s-components/s-paper';
import { SBody } from './s-body';
import { required } from 'utils/validation';
import { Widget } from 'common/constants';
import { ArtistPlaylistSection, ChangeTypeConfirm } from './components';
import { useDispatch, useSelector } from 'react-redux';
import { activeProjectSelector, isLoadingSelector, phasesSelector, processingSelector } from 'project/selectors';
import { openArtistModal, ProjectInput } from 'project/actions';
import { Artist, Id, Playlist } from 'backend-api/models';
import { isPlaylists } from 'backend-api/guards';
import { bem } from 'utils/bem';
import { ArtistModal } from '../../artist-modal';
import {
  ArtistModalType,
  FEATURED_ARTISTS_LIMIT,
  PLAYLISTS_LIMIT,
  PRIMARY_ARTISTS_LIMIT,
  TARGET_OPTIONS,
} from 'project/constants';
import { Tooltip } from 'common/components/tooltip';
import {
  getProjectAvailableEndDates,
  getProjectAvailableStartDates,
  getTargetsLimitMessage,
} from 'project/transducers';
import { navigateTo } from 'utils/navigation';
import { paths } from 'app/routing/paths';
import { FormFooter } from 'common/components/form-footer';
import { useFormikContext } from 'formik';

interface Props {
  projectId: Id;
}

const classes = bem('project-edit-form');

export const Body = ({ projectId }: Props) => {
  const { values, submitForm, setFieldValue } = useFormikContext<ProjectInput>();

  const dispatch = useDispatch();
  const project = useSelector(activeProjectSelector);
  const phases = useSelector(phasesSelector);
  const isBeingProcessed = useSelector(processingSelector);
  const isLoading = useSelector(isLoadingSelector);

  useCurrencies();

  const formattedBudget = useProjectCurrencyFormat(values?.budget);
  const formattedAllocation = useProjectCurrencyFormat(values?.allocation);

  const showModal = useCallback(() => {
    dispatch(openArtistModal({ selectedArtists: values?.artists, selectedPlaylists: values?.playlists }));
  }, [dispatch, values?.artists, values?.playlists]);

  const onModalSave = useCallback(
    (artists: Artist[], playlists: Playlist[]) => {
      setFieldValue('artists', artists);
      setFieldValue('playlists', playlists);
    },
    [setFieldValue]
  );

  const [isChangeTypeConfirmationVisible, setChangeTypeConfirmationVisible] = useState(false);

  const onChangeTypeConfirmed = useCallback(() => {
    const anotherType = TARGET_OPTIONS.find(option => option.id !== values?.type)?.id;

    setChangeTypeConfirmationVisible(false);
    setFieldValue('artists', []);
    setFieldValue('playlists', []);
    setFieldValue('type', anotherType);
  }, [setFieldValue, values?.type]);

  const onChangeTypeCancel = useCallback(() => {
    setChangeTypeConfirmationVisible(false);
  }, []);

  const selectedTargetOption = useMemo(() => TARGET_OPTIONS.find(option => option.id === values?.type), [values?.type]);

  const selectedTargets = useMemo(
    () => (selectedTargetOption?.name === 'Playlist' ? values?.playlists : values?.artists),
    [values?.playlists, values?.artists, selectedTargetOption]
  );

  const primaryArtists = useMemo(() => values?.artists.filter(item => item.type === 'Primary'), [values?.artists]);

  const isSaveDisabled = useMemo(() => {
    if (!values) return true;

    return primaryArtists?.length === 0 && values.playlists.length === 0;
  }, [primaryArtists, values]);

  const saveButtonTooltipContent = useMemo(() => {
    if (selectedTargetOption?.name === 'Playlist' && !values?.playlists.length) {
      return 'You need to add at least 1 playlist';
    }

    if (selectedTargetOption?.name === 'Artist' && !primaryArtists?.length) {
      return 'You need to add at least 1 primary artist';
    }

    return undefined;
  }, [selectedTargetOption, primaryArtists, values?.playlists]);

  const [selectedTargetIds, setSelectedTargetIds] = useState<string[]>([]);

  const onSelectTarget = useCallback(
    (targetToSelect: Artist | Playlist) => {
      let newSelectedIds: string[];

      const isSelected = selectedTargetIds.includes(targetToSelect.id);
      if (isSelected) {
        newSelectedIds = selectedTargetIds.filter(id => id !== targetToSelect.id);
      } else {
        newSelectedIds = [...selectedTargetIds, targetToSelect.id];
      }

      setSelectedTargetIds(newSelectedIds);
    },
    [selectedTargetIds]
  );

  const onRemoveTarget = useCallback(
    (targetToRemove: Artist | Playlist) => {
      if (isPlaylists(selectedTargets)) {
        const filteredTargets = selectedTargets.filter(target => target.id !== targetToRemove.id);
        setFieldValue('playlists', filteredTargets);
      } else {
        const filteredTargets = selectedTargets.filter(target => target.id !== targetToRemove.id);
        setFieldValue('artists', filteredTargets);
      }

      const newSelectedIds = selectedTargetIds.filter(id => id !== targetToRemove.id);
      setSelectedTargetIds(newSelectedIds);
    },
    [setFieldValue, selectedTargets, selectedTargetIds]
  );

  const onCancelSelectedTargets = useCallback(() => {
    setSelectedTargetIds([]);
  }, []);

  const onRemoveSelectedTargets = useCallback(() => {
    if (isPlaylists(selectedTargets)) {
      const filteredTargets = selectedTargets.filter(target => !selectedTargetIds.includes(target.id));
      setFieldValue('playlists', filteredTargets);
    } else {
      const filteredTargets = selectedTargets.filter(target => !selectedTargetIds.includes(target.id));
      setFieldValue('artists', filteredTargets);
    }

    setSelectedTargetIds([]);
  }, [setFieldValue, selectedTargets, selectedTargetIds]);

  const isSelectingTargets = selectedTargetIds.length > 0;

  const isAddFromApolloButtonDisabled = useMemo(() => {
    if (!selectedTargets) return false;

    if (isPlaylists(selectedTargets)) {
      return selectedTargets.length >= PLAYLISTS_LIMIT;
    }

    return selectedTargets.length >= PRIMARY_ARTISTS_LIMIT + FEATURED_ARTISTS_LIMIT;
  }, [selectedTargets]);

  const addFromApolloButton = useMemo(() => {
    const selectedType = TARGET_OPTIONS.find(value => value.id === values?.type);
    const buttonText = selectedType?.name === 'Artist' ? 'Add Artists' : 'Add Playlists';
    const button = (
      <Button
        dataSelector="add-from-apollo-button"
        onClick={showModal}
        disabled={isAddFromApolloButtonDisabled}
        variant="secondary-cta"
      >
        <Icon name="plus" color="white" />
        {buttonText}
      </Button>
    );

    return isAddFromApolloButtonDisabled ? (
      <Tooltip content={getTargetsLimitMessage(isPlaylists(selectedTargets))}>
        <div>{button}</div>
      </Tooltip>
    ) : (
      button
    );
  }, [isAddFromApolloButtonDisabled, selectedTargets, showModal, values?.type]);

  const projectAvailableStartDates = useMemo(() => {
    return getProjectAvailableStartDates(phases.data, values?.endDate);
  }, [phases.data, values?.endDate]);

  const projectAvailableEndDates = useMemo(() => {
    return getProjectAvailableEndDates(phases.data, values?.startDate);
  }, [values?.startDate, phases.data]);

  const prsData = project?.prsData;
  const ccpData = project?.ccpData;
  const grasData = project?.grasData;
  const isExternal = prsData || grasData;

  const autogeneratedName = prsData?.title || ccpData?.title;
  const name = values?.name || '';

  useEffect(() => {
    if (!name || name.trim().length < 1) {
      setFieldValue('name', autogeneratedName);
    }
  }, [setFieldValue, name, autogeneratedName]);

  const onCancelEditing = useCallback(() => {
    navigateTo(paths.project(projectId));
  }, [projectId]);

  const isDateFieldDisabled = isLoading;

  const nameTooltip = useMemo(
    () => (
      <span>
        The name of the project as it will appear in
        <br />
        Decibel. Autofills to match the {ccpData ? 'CCP' : 'PRS'} Sub-Project.
      </span>
    ),
    [ccpData]
  );

  return (
    <>
      <SBody>
        <Field
          name="name"
          type={Widget.FlatInput}
          dataSelector="name"
          iconRight="edit"
          infoTooltipContent={nameTooltip}
          defaultValue={autogeneratedName}
          className={classes('name-field')}
        />
        {isExternal && (
          <SPaper pt="18px" pb="2px" mt="13px" mb="10px" color="white">
            <SRow>
              {ccpData && (
                <SCol width={13 / 28}>
                  <Cell
                    editable
                    label="CCP Sub-Project"
                    labelTooltipContent={
                      <span>
                        CCP description for this
                        <br />
                        specific marketing sub-project.
                      </span>
                    }
                  >
                    <span className={classes('text')}>{ccpData.title}</span>
                  </Cell>
                </SCol>
              )}
              {prsData && (
                <SCol width={13 / 28}>
                  <Cell
                    editable
                    label="PRS Sub-Project"
                    labelTooltipContent={
                      <span>
                        PRS description for this
                        <br />
                        specific marketing sub-project.
                      </span>
                    }
                  >
                    <span className={classes('text')}>{prsData.title}</span>
                  </Cell>
                </SCol>
              )}
              {grasData && (
                <>
                  <SSpacer />
                  <SCol width={13 / 28}>
                    <Cell
                      editable
                      label="GRAS Project"
                      labelTooltipContent={
                        <span>
                          Name of the GRAS master recording
                          <br />
                          project this sub-project is marketing.
                        </span>
                      }
                    >
                      <span className={classes('text')}>{grasData.title}</span>
                    </Cell>
                  </SCol>
                </>
              )}
            </SRow>
          </SPaper>
        )}
        <SPaper pt="18px" pb="2px" mt="13px" mb="10px" color="white">
          <SRow>
            <SCol width={13 / 28}>
              <Field
                name="startDate"
                type={Widget.Date}
                bgStyle="grey"
                label="Start Date"
                dataSelector="project-start-date-input"
                validate={required}
                isRequired
                format={null}
                availableDates={projectAvailableStartDates}
                disabled={isDateFieldDisabled}
              />
            </SCol>
            <SSpacer />
            <SCol width={13 / 28}>
              <Field
                name="endDate"
                type={Widget.Date}
                bgStyle="grey"
                label="End Date"
                dataSelector="project-end-date-input"
                validate={required}
                isRequired
                format={null}
                availableDates={projectAvailableEndDates}
                disabled={isDateFieldDisabled}
              />
            </SCol>
          </SRow>
          <SRow>
            <SCol width={13 / 28}>
              <Cell editable label="Total Budget">
                <span className={classes('text')}>{formattedBudget}</span>
              </Cell>
            </SCol>
            <SSpacer />
            <SCol width={13 / 28}>
              <Cell editable label="Total Allocation">
                <span className={classes('text')}>{formattedAllocation}</span>
              </Cell>
            </SCol>
          </SRow>
        </SPaper>
        <SPaper pt="18px" pb="2px" mt="13px" mb="10px" color="white">
          <SRow>
            <Cell dataSelector="type" editable label="Project focus" wide className={classes('project-focus-cell')}>
              <div className={classes('project-focus')}>
                {addFromApolloButton}
                <SSpacer />
                {isSelectingTargets && (
                  <>
                    <Button onClick={onCancelSelectedTargets} variant="secondary-gray">
                      Cancel
                    </Button>
                    <SSpacer width={20} />
                    <Button onClick={onRemoveSelectedTargets} variant="secondary-cta">
                      <Icon name="delete" />
                      {`Remove (${selectedTargetIds.length})`}
                    </Button>
                  </>
                )}
              </div>
            </Cell>
          </SRow>
          {selectedTargets && selectedTargets.length > 0 && (
            <ArtistPlaylistSection
              targets={selectedTargets}
              selectedTargetIds={selectedTargetIds}
              onSelect={onSelectTarget}
              onRemove={onRemoveTarget}
            />
          )}
        </SPaper>
        <SPaper pt="18px" pb="2px" mt="13px" mb="25px" color="white">
          <SRow>
            <SCol width={1}>
              {project && (
                <Field
                  bgStyle="grey"
                  name="notes"
                  type={Widget.Notes}
                  dataSelector="project-notes"
                  label="Project Notes"
                  maxLength={5000}
                  wide
                />
              )}
            </SCol>
          </SRow>
        </SPaper>
        <FormFooter
          onSubmit={submitForm}
          onCancel={onCancelEditing}
          isSaveDisabled={isSaveDisabled}
          isBeingProcessed={isBeingProcessed}
          tooltipContent={saveButtonTooltipContent}
        />
        {selectedTargetOption && (
          <ArtistModal
            type={ArtistModalType[selectedTargetOption.name]}
            onModalSave={onModalSave}
            projectId={projectId}
          />
        )}
      </SBody>
      {isChangeTypeConfirmationVisible && (
        <ChangeTypeConfirm onOk={onChangeTypeConfirmed} onCancel={onChangeTypeCancel} />
      )}
    </>
  );
};
