import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { BadgeType } from './types';
import { colorPalette } from 'app/theme/colors';

interface SBadgeProps {
  type?: BadgeType;
  color?: string;
}

export const SBadge = styled.div<SBadgeProps>`
  display: flex;
  padding: 1px 8px;
  border-radius: 5px;
  user-select: none;
  align-items: center;
  width: fit-content;
  background-color: ${({ color }) => color || colorPalette.eastBay};

  .badge {
    &__text {
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 14px;
      text-transform: uppercase;
      color: white;
    }
  }

  ${({ type, color }) => {
    switch (type) {
      case BadgeType.Light:
        return `
          background-color: ${color || palette.lightGray};

          .badge {
            &__text {
              color: ${palette.black};
            }
          }
        `;
      case BadgeType.Outlined:
        return `
          border: 1px solid ${palette.lightGray};
          background-color: ${color || palette.white};

          .badge {
            &__text {
              color: ${palette.black};
            }
          }
        `;
      case BadgeType.Rounded:
        return `
            border: 1px solid ${colorPalette.snuff};
            background-color: ${color || colorPalette.white};
            border-radius: 999px;

            .badge {
              &__text {
                text-transform: none;
                font-size: 12px;
                font-weight: normal;
                letter-spacing: 0px;
                color: ${palette.black};
              }
            }
          `;
      default:
        return '';
    }
  }}
`;
