import React from 'react';
import { Skeleton } from 'common-v2/components';

interface Props {
  className?: string;
}

export const TitleLoader = React.memo(({ className }: Props) => {
  return (
    <Skeleton className={className} width={400} height={24}>
      <rect x="0" y="0" rx="3" ry="3" width="400" height="24" />
    </Skeleton>
  );
});
