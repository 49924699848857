import React, { useCallback, useMemo } from 'react';
import { Id } from 'backend-api/models';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { Button } from 'common/components/button';
import { Tooltip } from 'common/components/tooltip';
import { H4 } from 'common/components/typography';
import { ALL_OPTION_ID } from 'common/components/form/select/constants';
import { FunnelChartFilter } from 'project/types';
import { SHeader, BEM_CLASS } from './s-funnel-graph-header';
import { ADS_SELECT_OPTIONS } from './constants';
import { Select, SelectType } from 'common/components/select';
import { useSelector } from 'react-redux';
import { activeProjectSelector } from 'common/selectors';

interface FunnelGraphHeaderProps {
  className?: string;
  projectId: Id;
  selectedId: FunnelChartFilter;
  onSelectChange(id: FunnelChartFilter): void;
}

const classes = bem(BEM_CLASS);

export const FunnelGraphHeader = React.memo(({ className, selectedId, onSelectChange }: FunnelGraphHeaderProps) => {
  const project = useSelector(activeProjectSelector);
  const isUnassigned = !!project && project.isClaimed;

  const onButtonClick = useCallback(() => {}, []);

  const platformSelectorClassNames = useMemo(
    () => ({
      control: classes('platform-select-control'),
    }),
    []
  );

  return (
    <SHeader className={className}>
      <H4 className={classes('title')}>Project Funnel</H4>
      <Tooltip content="Shows key events and the dropoff in volume between each throughout a typical person’s interaction with marketing efforts.">
        <span>
          <Icon className={classes('icon')} color="pink" name="info" size="general" />
        </span>
      </Tooltip>
      <div className={classes('selector')}>
        <Select
          type={SelectType.Base}
          valueId={selectedId || ALL_OPTION_ID}
          valueHandler={onSelectChange}
          options={ADS_SELECT_OPTIONS}
          classNames={platformSelectorClassNames}
          dataSelector="funnel-chart-ads-selector"
        />
      </div>
      {!isUnassigned && (
        <Button variant="secondary-cta" onClick={onButtonClick} className={classes('button')}>
          View Campaigns
        </Button>
      )}
    </SHeader>
  );
});
