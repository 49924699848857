import React, { useEffect, useMemo, useRef } from 'react';
import { MenuProps } from 'react-select';
import { callbackWithoutPropagation } from 'utils/event';
import { bem } from 'utils/bem';
import { Input } from 'common/components/form';
import { BaseOption as BaseOptionType } from '../../types';
import { BEM_CLASS, SSearchableMenuWrapper } from './s-searchable-menu-wrapper';
import { noop } from 'lodash';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { getSearchableCustomSelectProps } from 'common/components/select/containers/searchable-select/transducers';

type Props<T extends BaseOptionType, IsMulti extends boolean> = MenuProps<T, IsMulti>;

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType, IsMulti extends boolean>({
  children,
  innerRef,
  innerProps,
  selectProps,
}: Props<T, IsMulti>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { isSearchableFromPopup, classNames, popupSearch, popupSearchHandler, searchPlaceholder, dataSelector } = {
    ...getBaseCustomSelectProps(selectProps),
    ...getSearchableCustomSelectProps(selectProps),
  };

  const handleClickPropagation = useMemo<React.MouseEventHandler<HTMLDivElement>>(
    () =>
      callbackWithoutPropagation(event => {
        if (event.target === inputRef?.current) {
          inputRef?.current?.focus();
        }
      }),
    []
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      inputRef?.current?.focus();
    });
  }, []);

  return (
    <SSearchableMenuWrapper
      {...innerProps}
      ref={innerRef}
      className={classNames?.menu}
      data-selector={`${dataSelector}-menu`}
    >
      {isSearchableFromPopup && (
        <div className={classes('search')} onClick={handleClickPropagation}>
          <Input
            key="search-input"
            ref={inputRef}
            value={popupSearch}
            onChange={popupSearchHandler || noop}
            bgStyle="white"
            iconLeft="search"
            clearable
            placeholder={searchPlaceholder || 'Search'}
          />
        </div>
      )}
      {children}
    </SSearchableMenuWrapper>
  );
};

export const SearchableMenuWrapper = React.memo(Root) as typeof Root;
