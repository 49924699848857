import styled from 'styled-components';

export const SFieldTooltip = styled.div`
  width: 16px;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-self: flex-end;
  margin-left: 13px;
  .field-tooltip {
    &__info {
      cursor: pointer;
      width: 16px;
      height: 16px;
      display: flex;
    }
  }
`;
