import { colorPalette } from 'app/theme/colors';

export const paginationTheme = {
  color: colorPalette.vulcan,
  link: {
    color: colorPalette.azureRadiance,
  },
  activeLink: {
    color: colorPalette.black,
  },
  dropdown: {
    hover: {
      background: colorPalette.lilyWhite,
    },
  },
  icon: {
    disabled: {
      color: colorPalette.lavenderGray,
    },
    hover: {
      background: colorPalette.lilyWhite,
      color: colorPalette.azureRadiance,
    },
  },
};
