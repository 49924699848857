import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { motion } from 'framer-motion';

interface SMarketingMixWidgetProps {
  showButton: boolean;
}

const PADDING_BOTTOM = 20;

export const SMarketingMixWidget = styled(motion.div)<SMarketingMixWidgetProps>`
  border-radius: 10px;
  background-color: white;
  padding-top: 23px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: ${PADDING_BOTTOM}px;
  margin-bottom: ${({ showButton }) => (showButton ? 40 : 20)}px;
  display: flex;
  flex-direction: column;

  .marketing-mix-widget {
    &__loader {
      margin: auto;
    }
    &__title-with-dropdown {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 33px;
    }
    &__title {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__dropdown {
      & .react-select__control {
        background-color: ${palette.athensGrayTwo};
      }
    }
  }
`;
