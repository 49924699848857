import React, { useCallback } from 'react';
import { SLinkItem } from './s-link-item';
import { MultiValueGenericProps } from 'react-select';
import { LinkOption } from '../../../../types';

type Props<T extends LinkOption> = MultiValueGenericProps<T, true>;

const Root = <T extends LinkOption>({ data, children }: Props<T>) => {
  const onMouseDown = useCallback((event: React.MouseEvent<HTMLAnchorElement>) => event.stopPropagation(), []);

  return (
    <SLinkItem
      href={!data.isInvalid && data?.value}
      target="_blank"
      onMouseDown={onMouseDown}
      rel="noopener noreferrer"
    >
      {children}
    </SLinkItem>
  );
};

export const LinkItem = React.memo(Root) as typeof Root;
