import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Artist, ArtistSchema, Playlist } from 'backend-api/models';
import { isPlaylists } from 'backend-api/guards';
import { showViewAllTargetsModal } from 'project/actions';
import { activeProjectSelector, isLoadingSelector } from 'project/selectors';
import { ShowAllTargetsModal, ArtistWidget, PlaylistWidget, ArtistPlaylistWidgetLoader } from './components';
import { paths } from 'app/routing/paths';
import { ArtistPlaylistCard } from 'project/components/form/components/body/components';
import { isArtistLocked } from 'project/transducers';

interface Props {
  className?: string;
}

export const ArtistPlaylistWidget = React.memo(({ className }: Props) => {
  const dispatch = useDispatch();
  const project = useSelector(activeProjectSelector);
  const isLoading = useSelector(isLoadingSelector);

  const targetItems = project?.targets.items;

  const renderCard = useCallback((target: Artist | Playlist) => {
    const path = ArtistSchema.is(target) && target.type === 'Primary' ? paths.artist(target.id) : undefined;

    const isLocked = ArtistSchema.is(target) && isArtistLocked(target);

    return (
      <ArtistPlaylistCard
        key={target.id}
        path={path}
        target={target}
        isEditable={false}
        shouldStretch
        isLocked={isLocked}
      />
    );
  }, []);

  const onShowViewAllTargetsModal = useCallback(() => dispatch(showViewAllTargetsModal(true)), [dispatch]);

  const body = useMemo(() => {
    if (isLoading) {
      return <ArtistPlaylistWidgetLoader />;
    }

    if (!targetItems) return null;

    if (isPlaylists(targetItems)) {
      return (
        <PlaylistWidget
          className={className}
          playlists={targetItems}
          renderCard={renderCard}
          onShowViewAllTargetsModal={onShowViewAllTargetsModal}
        />
      );
    }

    return (
      <ArtistWidget
        className={className}
        artists={targetItems}
        renderCard={renderCard}
        onShowViewAllTargetsModal={onShowViewAllTargetsModal}
      />
    );
  }, [isLoading, targetItems, className, renderCard, onShowViewAllTargetsModal]);

  return (
    <>
      {body}
      <ShowAllTargetsModal />
    </>
  );
});
