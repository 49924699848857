import { BaseOption as OldBaseOption } from 'common/components/form/select';
import { BaseGroup } from 'common/components/select';
import { GLOBAL_TERRITORY_ID } from 'common/constants';

export const prepareCountries = (options: OldBaseOption[]): OldBaseOption[] => {
  if (options.length === 0) {
    return [];
  }
  const globalOption = options[0];
  const otherCountries = globalOption.options || [];

  return [globalOption].concat(otherCountries);
};

export const prepareCountriesOptions = (options: OldBaseOption[], selectedOptions?: OldBaseOption[]): BaseGroup[] => {
  const isGlobalSelected = selectedOptions?.map(country => country.id).includes(GLOBAL_TERRITORY_ID);

  const globalOption = options[0];
  const otherOptions = options.slice(1, options.length);

  return [
    {
      options: [
        {
          id: globalOption.id || 0,
          value: globalOption.name || '',
        },
      ],
    },
    {
      options: otherOptions.map(country => ({
        id: country.id || 0,
        value: country.name || '',
        isDisabled: isGlobalSelected,
      })),
    },
  ];
};
