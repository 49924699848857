import styled, { css } from 'styled-components';

export const BEM_CLASS = 'flag-sprite';

interface Props {
  size: number;
}

const spriteContainerStyles = css<Props>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SFlagSprite = styled.div<Props>`
  ${spriteContainerStyles}

  i,
  i:before {
    ${spriteContainerStyles}
  }
`;
