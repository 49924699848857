import styled from 'styled-components';
import { animatedFlare } from 'common/s-components/s-animated-flare';

export const SLoaderItem = styled.div`
  height: 60px;
  overflow: hidden;
  .loader-item {
    &__clip-container {
      width: 100%;
      height: 60px;
      clip-path: url(#loader-item);
      -webkit-clip-path: url(#loader-item);
      ${animatedFlare}
    }
  }
`;
