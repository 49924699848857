import { BaseOption } from 'common/components/form/select/types';

export const BREAKDOWN_BY_PLATFORM_ID = 5;
export const BREAKDOWN_BY_PROJECT_ID = 12;
export const BREAKDOWN_BY_CAMPAIGN_ID = 13;
export const BREAKDOWN_BY_AD_SET_ID = 14;
export const BREAKDOWN_BY_DAY_ID = 15;
export const BREAKDOWN_BY_WEEK_ID = 16;
export const BREAKDOWN_BY_CHARTWEEK_ID = 17;
export const BREAKDOWN_BY_MONTH_ID = 18;
export const BREAKDOWN_BY_LINKFIRE_LINK_ID = 19;

export const MAX_DAY_LIMIT_BY_BREAKDOWN_ID = {
  [BREAKDOWN_BY_DAY_ID]: 30,
  [BREAKDOWN_BY_WEEK_ID]: 90,
  [BREAKDOWN_BY_CHARTWEEK_ID]: 90,
  [BREAKDOWN_BY_MONTH_ID]: 366,
};

export const BREAKDOWN_TITLE_BY_BREAKDOWN_ID = {
  [BREAKDOWN_BY_DAY_ID]: 'Day',
  [BREAKDOWN_BY_WEEK_ID]: 'Week',
  [BREAKDOWN_BY_CHARTWEEK_ID]: 'Сhartweek',
  [BREAKDOWN_BY_MONTH_ID]: 'Month',
};

export const ALL_LABELS_OPTION_ID = 0;

export const ALL_LABELS_OPTION: BaseOption = {
  id: ALL_LABELS_OPTION_ID,
  name: 'All Labels',
};
