import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DATE_PICKER_INPUT_ID, RangeComponentsProps, SingleComponentsProps } from 'common/components/form/date-picker';
import { SDateInput } from './s-date-input';
import { bem } from 'utils/bem';
import InputMask from 'react-input-mask';
import { BgStyle } from 'common/styles/utils';
import { Icon } from 'common/components/icon';
import { getJoinedDateRange, isAvailableDate } from 'common/components/form/date-picker/transducers';
import { isRangeComponentsProps } from '../../guards';
import { Period } from 'common/types';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'common/selectors';
import { DATE_RANGE_INPUT_FORMAT, DATE_SINGLE_INPUT_FORMAT } from 'common/constants';
import { getFormattedDate, isDateAfter, addDaysToDate, parseDate, isValidDate, isSameDate } from 'common-v2/utils';

interface InnerProps {
  onBlur(): void;
  onFocus(): void;
  onClick(): void;
  onErrorChange(isValueInvalid: boolean): void;
  shouldResetInputToDateValue?: boolean;
  withInputIcon?: boolean;
  bgStyle?: BgStyle;
  maxPeriodDays?: number;
  availableDates?: Period[] | Period;
  dataSelector?: string;
  className?: string;
  inputClassName?: string;
}

type Props = (RangeComponentsProps | SingleComponentsProps) & InnerProps;

const classes = bem('date-input');

export const DateInput = React.memo(
  React.forwardRef<HTMLDivElement, Props>((props: Props, ref) => {
    const {
      onFocus,
      onBlur,
      onErrorChange,
      shouldResetInputToDateValue,
      bgStyle = 'grey',
      maxPeriodDays,
      onClick,
      availableDates,
      disabled,
      dataSelector,
      inputClassName,
      className,
      withInputIcon = true,
    } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const dateFormat = useSelector(dateFormatSelector);

    const [inputValue, setInputValue] = useState<string>('');
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [lastAutoCompletedDate, setLastAutoCompletedDate] = useState<Date>();
    const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

    const mask = isRangeComponentsProps(props) ? DATE_RANGE_INPUT_FORMAT : DATE_SINGLE_INPUT_FORMAT;
    const placeholder = useMemo(
      () =>
        isRangeComponentsProps(props)
          ? `${dateFormat.toLowerCase()}-${dateFormat.toLowerCase()}`
          : dateFormat?.toLowerCase(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.mode]
    );

    useEffect(() => {
      const formatDate = getFormattedDate(dateFormat);

      if (!shouldResetInputToDateValue) return;
      setIsInvalid(false);

      let valueString: string;

      if (isRangeComponentsProps(props)) {
        valueString = getJoinedDateRange(props.value, dateFormat) ?? '';
      } else {
        valueString = props.value ? formatDate(props.value) : '';
      }

      setLastAutoCompletedDate(isRangeComponentsProps(props) ? props.value?.[0] : undefined);
      setInputValue(valueString);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value, shouldResetInputToDateValue, props.mode, dateFormat]);

    const handleAutoComplete = useCallback(
      (inputValue: string) => {
        if (!maxPeriodDays) {
          setInputValue(inputValue);
          return;
        }

        const datesString = inputValue.split('–');
        const formatDate = getFormattedDate(dateFormat);
        const firstDate = parseDate(datesString[0]);

        const isSecondDateEmpty = datesString[1] === dateFormat.toLowerCase();
        const shouldAutocomplete =
          isValidDate(firstDate) && !isSameDate(lastAutoCompletedDate, firstDate) && isSecondDateEmpty;

        if (shouldAutocomplete) {
          const secondDate = addDaysToDate(firstDate, maxPeriodDays - 1);
          setInputValue(`${formatDate(firstDate)}–${formatDate(secondDate)}`);
          setLastAutoCompletedDate(firstDate);
          return;
        } else if (!isSecondDateEmpty) {
          setLastAutoCompletedDate(firstDate);
        }
        setInputValue(inputValue);
      },
      [maxPeriodDays, lastAutoCompletedDate, dateFormat]
    );

    const onChange = useCallback(
      (event: SyntheticEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        if (value === placeholder) {
          setIsInvalid(false);
        }

        handleAutoComplete(value);
      },
      [handleAutoComplete, placeholder]
    );

    useEffect(() => {
      onErrorChange(isInvalid);
    }, [isInvalid, onErrorChange]);

    const isNotDateAvailable = useCallback(
      (value: Date, firstSelectedDate?: Date) =>
        !isAvailableDate(value, availableDates, firstSelectedDate, maxPeriodDays),
      [availableDates, maxPeriodDays]
    );

    const onEnterPressed = useCallback(() => {
      if (!props.onChange) return;

      const datesString = inputValue.split('–');
      const firstDate = parseDate(datesString[0]);

      if (!isValidDate(firstDate) || isNotDateAvailable(firstDate)) {
        setIsInvalid(true);
        return;
      }

      if (isRangeComponentsProps(props)) {
        const secondDate = parseDate(datesString[1]);

        if (
          isDateAfter(firstDate, secondDate) ||
          !isValidDate(secondDate) ||
          isNotDateAvailable(secondDate, firstDate)
        ) {
          setIsInvalid(true);
          return;
        }
        setIsInvalid(false);
        props.onChange([firstDate, secondDate]);
      } else {
        setIsInvalid(false);
        props.onChange(firstDate);
      }
      inputRef.current?.blur();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onChange, inputValue, props.mode, inputRef, dateFormat]);

    const onKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') return;
        onEnterPressed();
      },
      [onEnterPressed]
    );

    const onInputFocus = useCallback(() => {
      onFocus();
      setIsInputFocused(true);
    }, [onFocus]);

    const onInputBlur = useCallback(() => {
      onBlur();
      setIsInputFocused(false);
    }, [onBlur]);

    return (
      <SDateInput ref={ref} bgStyle={bgStyle} disabled={disabled} className={className}>
        <InputMask
          id={DATE_PICKER_INPUT_ID}
          mask={mask}
          className={classes('input', { error: isInvalid }, inputClassName)}
          maskPlaceholder={placeholder}
          alwaysShowMask={false}
          onBlur={onInputBlur}
          onFocus={onInputFocus}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onClick={onClick}
          value={inputValue}
          disabled={disabled}
        >
          <input ref={inputRef} placeholder={placeholder} data-selector={dataSelector} />
        </InputMask>
        {withInputIcon && <Icon className={classes('icon')} name={isInputFocused ? 'enter' : 'calendar'} />}
      </SDateInput>
    );
  })
);
