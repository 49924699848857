import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { SRoleRenderer } from './s-role-renderer';
import { Id } from 'backend-api/models';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { getRoleNameById } from 'common/transducers';

export const RoleRenderer = ({ value }: ICellRendererParams<any, Id>) => (
  <SRoleRenderer>
    <Typography type={TYPOGRAPHY_TYPE.body2}>{getRoleNameById(value)}</Typography>
  </SRoleRenderer>
);
