import styled from 'styled-components';

export const BEM_CLASS = 'confidential-project-icon';

interface Props {
  isConfidential: boolean;
}

export const SConfidentialProjectIcon = styled.div<Props>`
  .${BEM_CLASS} {
    &__container {
      padding-top: ${({ isConfidential }) => (isConfidential ? 5 : 0)}px;
      display: flex;
    }

    &__icon {
      margin-left: -1px;
      margin-top: -1px;
      width: 22px;
      margin-right: 8px;
    }
  }
`;
