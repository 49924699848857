import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { CARD_SIZE } from '../../constants';
import { GAP_SIZE } from './constants';
import { getColumnWidth } from './transducers';

export const BEM_CLASS = 'global-search-results';

interface SGlobalSearchResultsProps {
  columns: number;
}

export const SGlobalSearchResults = styled.div<SGlobalSearchResultsProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 20px;
  overflow: hidden;

  .${BEM_CLASS} {
    &__header {
      flex-shrink: 0;
      padding: 20px 20px 0;
    }

    &__switcher {
      margin-bottom: 15px;
    }

    &__grid {
      display: grid;
      grid-template-columns: ${({ columns }) => `repeat(${columns}, ${getColumnWidth(columns)})`};
      gap: ${GAP_SIZE}px;

      &--loader {
        margin-top: 15px;
      }
    }

    &__content {
      flex-grow: 1;
      padding: 15px 20px 20px;
    }

    &__column {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &__empty {
      position: sticky;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${CARD_SIZE}px;
      text-align: center;
      color: ${manhattanThemeColors.grayedText};
    }

    &__card-loader {
      background-color: ${transparentize(0.5, colorPalette.white)};
      border-radius: 10px;
      overflow: hidden;
    }
  }
`;
