import React from 'react';
import { Button, BUTTON_SIZE, BUTTON_TYPE, THEME, Tooltip, TOOLTIP_PLACEMENT } from 'gdb-web-shared-components';

interface FullScreenControlProps {
  disabled?: boolean;
  isFullScreen: boolean;
  onFullscreenClick(): void;
}

export const FullScreenControl = React.memo(({ isFullScreen, onFullscreenClick, disabled }: FullScreenControlProps) => (
  <Tooltip
    tooltip={isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
    placement={TOOLTIP_PLACEMENT.BottomEnd}
    isInteractive={false}
  >
    <Button
      disabled={disabled}
      type={BUTTON_TYPE.tertiary}
      theme={THEME.light}
      size={BUTTON_SIZE.smallRound}
      icon={isFullScreen ? 'collapse-b' : 'expand-b'}
      onClick={onFullscreenClick}
      data-selector="media-plan-full-screen-button"
    />
  </Tooltip>
));
