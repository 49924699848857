import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router';
import { ApiError } from 'backend-api';
import {
  Button,
  Icon,
  ICON_SIZE,
  SEARCH_FIELD_DROPDOWN_PLACEMENT,
  SearchField,
  THEME,
  TOAST_TYPE,
  Typography,
  TYPOGRAPHY_TYPE,
  useManageToasts,
} from 'gdb-web-shared-components';
import { Optional, ProjectSharing, SimpleProjectUser, Id, UserRole, ProjectDetails } from 'backend-api/models';
import { bem } from 'utils/bem';
import { replaceTo } from 'utils/navigation';
import FailedToLoadErrorImage from 'assets/error_failed_to_load.png';
import { Popper } from 'common/components/popper'; // FIX: Outdated, have to be replaced by popper from shared library
import { Loadable, Loading, LocalRole } from 'common-v2/types';
import { ROLES } from 'common-v2/constants';
import { ErrorView } from 'common-v2/components';
import { getHigestRole } from 'common-v2/transducers';
import { openPermissionErrorModal } from 'modals/permission-error-modal';
import { shareModalSelector } from 'project-v2/selectors';
import {
  getUsers,
  updateProjectUser,
  addProjectUsers,
  closeShareModal,
  getProjectDetails,
  removeProjectUser,
  getProjectSharingInfo,
} from 'project-v2/actions';
import { Loader, MemberItem, MembersBlock, MembersList } from '../../components';
import { InviteMenu, SearchOption, UserRoleEditor } from './components';
import { getSearchOptions } from './transducers';
import { SearchOption as SearchFieldOption, SearchFieldError } from './types';
import { AVAILABLE_ROLES_BY_CURRENT_ROLE } from '../../constants';
import { SEditModeContainer, BEM_CLASS } from './s-edit-mode-container';

interface EditModeContainerProps {
  projectId: Id;
  projectDetails: ProjectDetails;
  sharingInfo: Loadable<Optional<ProjectSharing>>;
  flipBoundary: React.ReactNode;
  isConfidential: boolean;
  hasLockedArtist: boolean;
  refreshSharingInfo(): void;
}

const classes = bem(BEM_CLASS);

export const EditModeContainer = React.memo(
  ({
    projectId,
    projectDetails,
    sharingInfo,
    flipBoundary,
    isConfidential,
    hasLockedArtist,
    refreshSharingInfo,
  }: EditModeContainerProps) => {
    const [isInviteMenuVisible, setIsInviteMenuVisible] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedUsers, setSelectedUsers] = useState<SearchFieldOption[]>([]);
    const [isAddingUsersProcessing, setIsAddingUsersProcessing] = useState(false);
    const [searchFieldError, setSearchFieldError] = useState(SearchFieldError.NONE);

    const dispatch = useDispatch();

    const { openToast } = useManageToasts(THEME.light);
    const sharingModal = useSelector(shareModalSelector);

    const isPageReloadBlocked = selectedUsers.length > 0;
    const isAddingUsersDisabled =
      isAddingUsersProcessing ||
      sharingModal.content.loading !== Loading.Finished ||
      sharingModal.users.loading !== Loading.Finished;

    const isInviteDisabled = isAddingUsersDisabled || selectedUsers.length === 0;

    const searchOptions = useMemo(() => {
      if (!sharingModal.users.data || !sharingModal.content.data) return [];

      return getSearchOptions(sharingModal.users.data, sharingModal.content.data.artistTeamAndAdmins);
    }, [sharingModal]);

    const toggleInviteMenu = useCallback(() => setIsInviteMenuVisible(!isInviteMenuVisible), [isInviteMenuVisible]);
    const closeInviteMenu = useCallback(() => setIsInviteMenuVisible(false), []);

    const handleSearchChange = useCallback((value: string) => {
      setSearch(value);
      setSearchFieldError(SearchFieldError.NONE);
    }, []);

    const handleSelectedUsersChange = useCallback((value: SearchFieldOption[]) => {
      setSelectedUsers(value);
      setSearchFieldError(SearchFieldError.NONE);
    }, []);

    const onEditProjectRestricted = useCallback(
      (error: ApiError) => {
        setIsAddingUsersProcessing(false);
        dispatch(closeShareModal());
        dispatch(openPermissionErrorModal(error));
        getProjectDetails.request({ projectId });
      },
      [dispatch, projectId]
    );

    const onProjectAccessRestricted = useCallback(() => {
      setSelectedUsers([]);
      replaceTo(`/projects/${projectId}/access-restricted`);
    }, [projectId]);

    const handleInviteUsers = useCallback(
      (roleId: number) => {
        setIsAddingUsersProcessing(true);

        const onSuccess = () => {
          setIsAddingUsersProcessing(false);
          setSelectedUsers([]);
        };

        const onFailure = () => {
          setIsAddingUsersProcessing(false);
          openToast({
            id: 'add-project-users-failure',
            message: 'An error occurred while updating project team, please try again.',
            type: TOAST_TYPE.ERROR,
          });
        };

        dispatch(
          addProjectUsers.request({
            projectId,
            roleId,
            usersIds: selectedUsers.map(({ id }) => Number(id)),
            currenUserRole: getHigestRole(projectDetails.userRoles)?.name,
            onSuccess,
            onFailure,
            onEditRestricted: onEditProjectRestricted,
            onProjectRestricted: onProjectAccessRestricted,
          })
        );
      },
      [
        dispatch,
        onEditProjectRestricted,
        onProjectAccessRestricted,
        openToast,
        projectId,
        selectedUsers,
        projectDetails.userRoles,
      ]
    );

    const handleInviteEditors = useCallback(() => {
      handleInviteUsers(ROLES.EDITOR.id);
    }, [handleInviteUsers]);

    const handleInviteViewers = useCallback(() => {
      handleInviteUsers(ROLES.VIEWER.id);
    }, [handleInviteUsers]);

    const onSelectRole = useCallback(
      (member: SimpleProjectUser) => (role: LocalRole) => {
        if (role.id === member.role.id) return;

        const onFailure = () => {
          openToast({
            id: 'update-project-user-failure',
            message: 'An error occurred while updating project team, please try again.',
            type: TOAST_TYPE.ERROR,
          });
        };

        dispatch(
          updateProjectUser.request({
            role: { id: role.id, name: role.name },
            previousRole: { id: member.role.id, name: member.role.name },
            userId: member.id,
            projectId,
            onFailure,
            onEditRestricted: onEditProjectRestricted,
            onProjectRestricted: onProjectAccessRestricted,
          })
        );
      },
      [dispatch, onEditProjectRestricted, onProjectAccessRestricted, openToast, projectId]
    );

    const onRemoveUser = useCallback(
      (member: SimpleProjectUser) => (role: UserRole) => {
        const onFailure = () => {
          openToast({
            id: 'remove-project-user-failure',
            message: 'An error occurred while updating project team, please try again.',
            type: TOAST_TYPE.ERROR,
          });

          dispatch(getProjectSharingInfo.request(projectId));
        };

        const onProjectUnclaim = () => {
          openToast({
            id: 'project-is-unclaimed',
            message: 'Project is back to the Unclaimed State due to removal of last team member.',
            type: TOAST_TYPE.WARNING,
          });
        };

        dispatch(
          removeProjectUser.request({
            roleId: role.id,
            userId: member.id,
            currenUserRole: getHigestRole(projectDetails.userRoles)?.name,
            projectId,
            onFailure,
            onProjectUnclaim,
            onEditRestricted: onEditProjectRestricted,
            onProjectRestricted: onProjectAccessRestricted,
          })
        );
      },
      [dispatch, onEditProjectRestricted, onProjectAccessRestricted, projectDetails.userRoles, openToast, projectId]
    );

    const renderMember = useCallback(
      (member: SimpleProjectUser) => {
        const availableRoles = AVAILABLE_ROLES_BY_CURRENT_ROLE[member.role.id];

        return (
          <MemberItem
            member={member}
            roleNode={
              availableRoles ? (
                <UserRoleEditor
                  availableRoles={availableRoles}
                  currentRole={member.role}
                  flipBoundary={flipBoundary}
                  onSelectRole={onSelectRole(member)}
                  onRemoveUser={onRemoveUser(member)}
                />
              ) : (
                <Typography type={TYPOGRAPHY_TYPE.body4}>{member.role.name}</Typography>
              )
            }
          />
        );
      },
      [flipBoundary, onSelectRole, onRemoveUser]
    );

    const renderDisabledMember = useCallback(
      (member: SimpleProjectUser) => (
        <MemberItem
          member={member}
          roleNode={
            <div className={classes('disabled-role')}>
              <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('role-name')}>
                {member.role.name}
              </Typography>
              <Icon name="down-single-m" size={ICON_SIZE.small} />
            </div>
          }
        />
      ),
      []
    );

    const renderBlocks = useCallback(
      ({ projectTeam, approvers, artistTeamAndAdmins }: ProjectSharing) => [
        <MembersBlock
          key="project-team"
          title="Project Team"
          members={projectTeam}
          renderMember={renderMember}
          emptyText="No team has been created. Begin adding collaborators to build a Project Team."
          dataSelector="project-team"
        />,

        approvers.length > 0 ? (
          <MembersBlock
            title="Project Approvers"
            members={approvers}
            renderMember={renderMember}
            dataSelector="approvers-team"
          />
        ) : null,

        artistTeamAndAdmins.length > 0 ? (
          <MembersBlock
            title={isConfidential || !hasLockedArtist ? 'Label Admins' : 'Artist Team & Label Admins'}
            members={artistTeamAndAdmins}
            renderMember={renderDisabledMember}
            dataSelector="artist-team-and-admins"
          />
        ) : null,
      ],
      [hasLockedArtist, isConfidential, renderMember, renderDisabledMember]
    );

    const inviteMenuOptions = useMemo(() => {
      const inviteMenuAction = (action: () => void) => () => {
        closeInviteMenu();

        if (isInviteDisabled) {
          setSearchFieldError(SearchFieldError.EMPTY_FIELD);
          return;
        }

        return action();
      };

      return [
        { title: 'Invite as Editor', action: inviteMenuAction(handleInviteEditors) },
        { title: 'Invite as Viewer', action: inviteMenuAction(handleInviteViewers) },
      ];
    }, [closeInviteMenu, handleInviteEditors, handleInviteViewers, isInviteDisabled]);

    const renderContent = () => {
      const header = (
        <div className={classes('header')}>
          <div className={classes('search')} data-selector="share-modal-search">
            <SearchField<SearchFieldOption>
              inputValue={search}
              value={selectedUsers}
              options={searchOptions}
              icon="search"
              popperProps={{ style: { width: '530px' }, placement: SEARCH_FIELD_DROPDOWN_PLACEMENT.BottomStart }}
              placeholder="Search by Name or Email"
              error={searchFieldError}
              optionComponent={SearchOption}
              onChange={handleSelectedUsersChange}
              onInputChange={handleSearchChange}
              disabled={isAddingUsersDisabled}
              multiple
            />
          </div>

          <Popper
            content={<InviteMenu isDisabled={isInviteDisabled} options={inviteMenuOptions} />}
            placement="bottom-end"
            visible={isInviteMenuVisible}
            offset={[0, 5]}
            onClickOutside={closeInviteMenu}
          >
            <Button
              className={classes('invite-button')}
              theme={THEME.light}
              onClick={toggleInviteMenu}
              isLoading={isAddingUsersProcessing}
              data-selector="share-modal-invite-button"
            >
              <Typography className={classes('invite-button-text')} type={TYPOGRAPHY_TYPE.subtitle3}>
                Invite As
              </Typography>

              <Icon name={isInviteMenuVisible ? 'up-single-m' : 'down-single-m'} size={ICON_SIZE.small} />
            </Button>
          </Popper>
        </div>
      );

      switch (sharingInfo.loading) {
        case Loading.Idle:
        case Loading.Started:
          return (
            <>
              {header}
              <Loader />
            </>
          );
        case Loading.Finished:
          return (
            <>
              {header}
              <MembersList data={sharingInfo.data} renderBlocks={renderBlocks} />
            </>
          );
        case Loading.Failed:
          return (
            <ErrorView
              config={{
                bottomImageSrc: FailedToLoadErrorImage,
                description: (
                  <span>
                    An error occurred while loading Project Team
                    <br />
                    data. Please try again
                  </span>
                ),
                actionTitle: 'Refresh',
              }}
              action={refreshSharingInfo}
            />
          );
      }
    };

    useEffect(() => {
      dispatch(getUsers.request());
    }, [dispatch]);

    useEffect(() => {
      const handleBeforeUnloadEvent = (event: BeforeUnloadEvent) => {
        if (isPageReloadBlocked) {
          event.preventDefault();
          event.returnValue = '';
        }
      };

      window.addEventListener('beforeunload', handleBeforeUnloadEvent);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
      };
    }, [isPageReloadBlocked]);

    return (
      <SEditModeContainer>
        {renderContent()}

        <Prompt when={isPageReloadBlocked} message="Changes that you made may not be saved." />
      </SEditModeContainer>
    );
  }
);
