import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const SAnchorLink = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  .anchor-link {
    &__anchor-title {
      color: ${palette.brightBlue};
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      text-align: right;
      margin-right: 8px;
    }
  }
`;
