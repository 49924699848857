import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Icon, ICON_SIZE, TOOLTIP_PLACEMENT, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { ShortProject } from 'backend-api/models';
import { paths } from 'app/routing/paths';
import { bem } from 'utils/bem';
import { CardContent, CardImage, CardProps, TooltipedText } from 'common-v2/components';
import { SProjectCard, BEM_CLASS } from './s-project-card';
import { getCardArtist, getUpdatedDate } from './transducers';

interface ProjectCardProps extends CardProps, ShortProject {
  dateFormat?: string;
}

const classes = bem(BEM_CLASS);

export const ProjectCard = React.memo(
  ({ id, name, label, targets, editDate, isConfidential, dateFormat, ...cardProps }: ProjectCardProps) => {
    const lockedArtist = useMemo(() => getCardArtist(targets), [targets]);
    const updatedDate = useMemo(() => {
      if (!dateFormat) {
        return null;
      }
      return getUpdatedDate(new Date(), dateFormat, editDate);
    }, [dateFormat, editDate]);

    return (
      <SProjectCard {...cardProps}>
        <CardImage url={lockedArtist?.image?.url} />

        <CardContent>
          <div className={classes('project')}>
            <Link className={classes('project-link')} to={paths.project(id)} target="_blank">
              <Typography
                className={classes('project-link-text')}
                type={TYPOGRAPHY_TYPE.body2}
                data-selector="project-name"
              >
                <TooltipedText text={name} placement={TOOLTIP_PLACEMENT.Top}>
                  <span>{name}</span>
                </TooltipedText>
              </Typography>
            </Link>

            {isConfidential && (
              <Icon
                className={classes('project-icon')}
                name="lock-outlined"
                size={ICON_SIZE.medium}
                dataAttributes={{ 'data-selector': 'confidential-project-icon' }}
              />
            )}
          </div>

          {lockedArtist && (
            <Typography className={classes('artist')} type={TYPOGRAPHY_TYPE.body4}>
              <span className={classes('artist-text')}>By</span>
              <TooltipedText text={lockedArtist.name} placement={TOOLTIP_PLACEMENT.Top}>
                <Link
                  className={classes('artist-link')}
                  to={paths.artist(lockedArtist.id)}
                  target="_blank"
                  data-selector="project-locked-artist"
                >
                  {lockedArtist.name}
                </Link>
              </TooltipedText>
            </Typography>
          )}

          {updatedDate && (
            <Typography className={classes('date')} type={TYPOGRAPHY_TYPE.body4} data-selector="project-updated-date">
              <TooltipedText text={updatedDate} placement={TOOLTIP_PLACEMENT.Top}>
                <span>{updatedDate}</span>
              </TooltipedText>
            </Typography>
          )}

          <div className={classes('label')} data-selector="project-label">
            <TooltipedText text={label.name} placement={TOOLTIP_PLACEMENT.Top}>
              <span>{label.name}</span>
            </TooltipedText>
          </div>
        </CardContent>
      </SProjectCard>
    );
  }
);
