import {
  CreateMediaPlanRequest,
  CreatePhaseRequest,
  EditPhaseRequest,
  Id,
  MediaPlanPhase,
  MediaPlanRelease,
  ModifyBulkCampaignsRequest,
  ModifyBulkCampaignsResponse,
  RequestApprovalRequest,
  SubmitApprovalRequest,
} from 'backend-api/models';
import { ToastOptions } from 'gdb-web-shared-components';

export interface WithConfirmPayload {
  text: string;
  action: () => void;
}

export interface GetMediaPlanPayload {
  projectId: Id;
  mediaPlanId: Id;
}

export interface CreatePhasePayload {
  projectId: Id;
  mediaPlanId: Id;
  payload: CreatePhaseRequest;
  onSuccess(phase: MediaPlanPhase): void;
  onError(): void;
  openToast(options: ToastOptions): void;
}

export interface EditPhasePayload {
  projectId: Id;
  mediaPlanId: Id;
  phaseId: Id;
  payload: EditPhaseRequest;
  onSuccess(phase: MediaPlanPhase): void;
  onError(): void;
  openToast(options: ToastOptions): void;
}

export interface DeletePhasePayload {
  projectId: Id;
  mediaPlanId: Id;
  phaseId: Id;
  onError(): void;
  openToast(options: ToastOptions): void;
}

export interface ModifyBulkCampaignsPayload {
  mediaPlanId: Id;
  projectId: Id;
  payload: ModifyBulkCampaignsRequest;

  onSuccess(response: ModifyBulkCampaignsResponse): void;
  onError(): void;
  openToast(options: ToastOptions): void;
}

export interface UpdateMediaPlanReleasePayload {
  mediaPlanId: Id;
  projectId: Id;
  payload: MediaPlanRelease;
  openToast(options: ToastOptions): void;
  onError(): void;
}

export enum MediaPlanMode {
  REQUEST_APPROVAL = 'request',
  DEFAULT = 'default',
  APPROVAL = 'approval',
}

export enum MediaPlanReferrerType {
  EMAIL = 'email',
}

export interface RequestApprovalPayload {
  mediaPlanId: Id;
  projectId: Id;
  payload: RequestApprovalRequest;
  onError?: () => void;
  openToast(options: ToastOptions): void;
}

export interface SubmitApprovalPayload {
  mediaPlanId: Id;
  projectId: Id;
  payload: SubmitApprovalRequest;
  onSubmitSuccess(): void;
  openToast(options: ToastOptions): void;
}

export interface CreateMediaPlanPayload {
  projectId: Id;
  payload: CreateMediaPlanRequest;
  onMediaPlanCreated?(id: Id): void;
  openToast(options: ToastOptions): void;
}

export interface EditMediaPlanPayload {
  projectId: Id;
  mediaPlanId: Id;
  payload: CreateMediaPlanRequest;
  openToast(options: ToastOptions): void;
}

export interface DeleteMediaPlanPayload {
  projectId: Id;
  mediaPlanId: Id;
  onMediaPlanDeleted?(id: Id): void;
  openToast(options: ToastOptions): void;
}

export interface DeleteMediaPlanModalInfo {
  mediaPlanId?: Id;
  isVisible: boolean;
}
