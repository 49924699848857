import React, { useCallback } from 'react';
import { Loadable } from 'common-v2/types';
import { Optional, ProjectSharing } from 'backend-api/models';
import { Modal, ModalContentProps, ModalHeader } from 'common-v2/components';
import { bem } from 'utils/bem';
import { SShareModal, BEM_CLASS } from './s-share-modal';

interface ShareModalProps {
  title: string;
  height: number;
  isOpened: boolean;
  sharingInfo: Loadable<Optional<ProjectSharing>>;

  renderBody(sharingInfo: Loadable<Optional<ProjectSharing>>): React.ReactElement;
  onClose(): void;
}

const classes = bem(BEM_CLASS);

export const ShareModal = React.memo(
  React.forwardRef<HTMLDivElement, ShareModalProps>(
    ({ title, height, isOpened, sharingInfo, renderBody, onClose }, ref) => {
      const renderContent = useCallback(
        ({ closeModal }: ModalContentProps) => (
          <SShareModal ref={ref} height={height}>
            <ModalHeader className={classes('header')} title={title} closeModal={closeModal} />

            <div className={classes('content')}>{renderBody(sharingInfo)}</div>
          </SShareModal>
        ),
        [height, ref, renderBody, sharingInfo, title]
      );

      return <Modal isOpened={isOpened} renderContent={renderContent} onClose={onClose} />;
    }
  )
);
