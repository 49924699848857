import styled from 'styled-components';
import { SBox } from './s-box';
import { WidthProps, SpaceProps } from 'styled-system';

type SpacerProps = WidthProps & SpaceProps & { maxWidthOffset?: number };

export const SSpacer = styled(SBox)<SpacerProps>`
  ${props => (props.width ? '' : 'flex: 1;')};
  align-self: center;
  max-width: calc(100% - ${({ maxWidthOffset }) => `${maxWidthOffset || 0}px`});
`;
