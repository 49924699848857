import styled from 'styled-components';
import { CELL_WIDTH_SHIFT } from 'media-plan-v2/components/spreadsheet/constants';
import { SELECT_DROPDOWN_MAX_WIDTH } from 'common-v2/components';

export const BEM_CLASS = 'objective-editor';

export const SObjectiveEditor = styled(({ className, children }) => children(className))`
  &--input {
    overflow: hidden;
  }

  .${BEM_CLASS} {
    &__control {
      width: ${({ width }) => width - CELL_WIDTH_SHIFT}px;
      height: 100%;
      min-height: 0;
      border-radius: 0;
    }

    &__select-container {
      height: 100%;
    }

    &__value-container-root {
      height: 100%;
    }

    &__value-container {
      overflow: hidden;
      padding-left: var(--cell-horizontal-padding);
    }

    &__indicators-container {
      height: 100%;
      overflow: hidden;
      padding-right: var(--cell-horizontal-padding);
      padding-left: var(--cell-horizontal-padding);
    }

    &__menu {
      min-width: ${({ width }) => Math.min(width, SELECT_DROPDOWN_MAX_WIDTH)}px;
    }
  }
`;
