import {
  AddCampaignHistoryEvent,
  AddCampaignHistoryEventSchema,
  AddFeaturedArtistsHistoryEvent,
  AddFeaturedArtistsHistoryEventSchema,
  AddPhaseHistoryEvent,
  AddPhaseHistoryEventSchema,
  AddPlaylistsHistoryEvent,
  AddPlaylistsHistoryEventSchema,
  AddPrimaryArtistsHistoryEvent,
  AddPrimaryArtistsHistoryEventSchema,
  AddProjectHistoryEvent,
  AddProjectHistoryEventSchema,
  ApproveGrasCampaignHistoryEvent,
  ApproveGrasCampaignHistoryEventSchema,
  ChangeOwnerHistoryEvent,
  ChangeOwnerHistoryEventSchema,
  ClaimProjectHistoryEvent,
  ClaimProjectHistoryEventSchema,
  HistoryEvent,
  Label,
  ProjectsMetadata,
  ProjectStatus,
  RejectGrasCampaignHistoryEvent,
  RejectGrasCampaignHistoryEventSchema,
  RemoveCampaignHistoryEvent,
  RemoveCampaignHistoryEventSchema,
  RemoveFeaturedArtistsHistoryEvent,
  RemoveFeaturedArtistsHistoryEventSchema,
  RemovePhaseHistoryEvent,
  RemovePhaseHistoryEventSchema,
  RemovePlaylistsHistoryEvent,
  RemovePlaylistsHistoryEventSchema,
  RemovePrimaryArtistsHistoryEvent,
  RemovePrimaryArtistsHistoryEventSchema,
  Targets,
  TargetTypeSchema,
  UpdateProjectBudgetHistoryEvent,
  UpdateProjectBudgetHistoryEventSchema,
  UpdateProjectTargetTypeHistoryEvent,
  UpdateProjectTargetTypeHistoryEventSchema,
} from 'backend-api/models';
import { isPlaylists } from 'backend-api/guards';
import { getMultipleItemsString } from 'common/transducers';
import { isStringArray } from 'utils/array';
import { formatCurrency } from 'utils/format';
import { DEFAULT_MY_PROJECTS_DROPDOWN_OPTION, DEFAULT_SORT } from './constants';
import {
  COMPLETED_STATUS,
  DEFAULT_PAGINATION_LIMIT,
  IN_PROGRESS_STATUS,
  PROJECT_STATUSES,
  SHORT_DATE_REQUEST_FORMAT,
  UNASSIGNED_STATUS,
  UPCOMING_STATUS,
} from 'common/constants';
import { isProjectSortField, ProjectFilterParams } from 'backend-api/types';
import { AllProjectsFilters, AllProjectsFiltersByStatus, AllProjectsTab, AllProjectsTargetOption } from './types';
import { ParsedQs } from 'qs';
import { Sort } from 'utils/sort';
import { ALL_OPTION_ID } from 'common/components/form/select';
import { BaseOption } from 'common/components/select';
import { getStartOfDay, parseDateFromISOString, getFormattedDate, isValidDate } from 'common-v2/utils';

export const getTargetsNames = (targets?: Targets): { tooltip: string; display: string } => {
  if (!targets) return { tooltip: '', display: '' };

  if (isPlaylists(targets.items)) {
    const names = targets.items.filter(({ name }) => name).map(target => target.name);
    const namesStr = names.join(', ');

    return {
      tooltip: namesStr,
      display: names.length > 3 ? 'Various Playlists' : namesStr,
    };
  }

  const names = targets.items
    .filter(({ type }) => type === 'Primary')
    .map(target => target.name)
    .join(', ');

  return {
    tooltip: names,
    display: names,
  };
};

const getUpdatedProjectBudgetText = (event: UpdateProjectBudgetHistoryEvent) => {
  const formattedOldBudget = formatCurrency(event.old_value.value);
  const formattedNewBudget = formatCurrency(event.new_value.value);

  return `Updated project planned budget from ${formattedOldBudget} to ${formattedNewBudget}`;
};

const getChangeOwnerEventText = (event: ChangeOwnerHistoryEvent) => {
  return `Changed project owner from ${event.old_value.name} to ${event.new_value.name}`;
};

const getAddPhaseEventText = (event: AddPhaseHistoryEvent) => {
  return `Created Phase ${event.new_value.order}, ${event.new_value.name}`;
};

const getRemovePhaseEventText = (event: RemovePhaseHistoryEvent) => {
  return `Removed Phase ${event.old_value.order}, ${event.old_value.name}`;
};

const getAddProjectText = ({ new_value }: AddProjectHistoryEvent) => {
  return `Created project, ${new_value.value}`;
};

const getUpdateProjectTargetTypeText = ({ old_value, new_value }: UpdateProjectTargetTypeHistoryEvent) => {
  return `Switched the project focus from ${old_value.value} to ${new_value.value}`;
};

const getAddPlaylistText = (event: AddPlaylistsHistoryEvent) => {
  const playlistTitles = getMultipleItemsString(event.new_value);
  return `Added ${playlistTitles} to playlists`;
};

const getRemovePlaylistText = (event: RemovePlaylistsHistoryEvent) => {
  const playlistTitles = getMultipleItemsString(event.old_value);
  return `Removed ${playlistTitles} from playlists`;
};

const getAddPrimaryArtistText = (event: AddPrimaryArtistsHistoryEvent) => {
  const artistsTitles = getMultipleItemsString(event.new_value);
  return `Added ${artistsTitles} to primary artists`;
};

const getRemovePrimaryArtistEvent = (event: RemovePrimaryArtistsHistoryEvent) => {
  const artistsTitles = getMultipleItemsString(event.old_value);
  return `Removed ${artistsTitles} from primary artists`;
};

const getClaimProjectText = (event: ClaimProjectHistoryEvent) => {
  return `${event.user.name} claimed ownership of project`;
};

const getAddFeaturedArtistText = (event: AddFeaturedArtistsHistoryEvent) => {
  const artistsTitles = getMultipleItemsString(event.new_value);
  return `Added ${artistsTitles} to featured artists`;
};

const getRemoveFeaturedArtistText = (event: RemoveFeaturedArtistsHistoryEvent) => {
  const artistsTitles = getMultipleItemsString(event.old_value);
  return `Removed ${artistsTitles} from featured artists`;
};

const getAddCampaignText = ({ new_value, campaign }: AddCampaignHistoryEvent) => {
  const formattedBudget = formatCurrency(new_value.value);

  if (campaign.source) {
    return `Added ${campaign.name} as a pending campaign with ${formattedBudget} budget`;
  }

  return `Created ${campaign.name} with ${formattedBudget} budget`;
};

const getApproveGrasCampaignText = ({ new_value, campaign }: ApproveGrasCampaignHistoryEvent) => {
  const formattedBudget = formatCurrency(new_value.value);

  return `Approved ${campaign.source} campaign, ${campaign.name}, with ${formattedBudget} budget`;
};

const getRejectGrasCampaignText = ({ old_value, campaign }: RejectGrasCampaignHistoryEvent) => {
  const formattedBudget = formatCurrency(old_value.value);

  return `Rejected ${campaign.source} campaign, ${campaign.name}, with ${formattedBudget} budget`;
};

const getRemoveCampaignText = ({ old_value, campaign }: RemoveCampaignHistoryEvent) => {
  const formattedBudget = formatCurrency(old_value.value);

  if (campaign.source) {
    return `Removed ${campaign.source} campaign, ${campaign.name}, with ${formattedBudget} budget`;
  }

  return `Removed ${campaign.name} with ${formattedBudget} budget`;
};

export const getEventActionText = (event: HistoryEvent): string => {
  if (UpdateProjectBudgetHistoryEventSchema.is(event)) return getUpdatedProjectBudgetText(event);
  if (ChangeOwnerHistoryEventSchema.is(event)) return getChangeOwnerEventText(event);
  if (AddPhaseHistoryEventSchema.is(event)) return getAddPhaseEventText(event);
  if (RemovePhaseHistoryEventSchema.is(event)) return getRemovePhaseEventText(event);
  if (AddProjectHistoryEventSchema.is(event)) return getAddProjectText(event);
  if (UpdateProjectTargetTypeHistoryEventSchema.is(event)) return getUpdateProjectTargetTypeText(event);
  if (AddPlaylistsHistoryEventSchema.is(event)) return getAddPlaylistText(event);
  if (RemovePlaylistsHistoryEventSchema.is(event)) return getRemovePlaylistText(event);
  if (AddPrimaryArtistsHistoryEventSchema.is(event)) return getAddPrimaryArtistText(event);
  if (RemovePrimaryArtistsHistoryEventSchema.is(event)) return getRemovePrimaryArtistEvent(event);
  if (ClaimProjectHistoryEventSchema.is(event)) return getClaimProjectText(event);
  if (AddFeaturedArtistsHistoryEventSchema.is(event)) return getAddFeaturedArtistText(event);
  if (RemoveFeaturedArtistsHistoryEventSchema.is(event)) return getRemoveFeaturedArtistText(event);
  if (AddCampaignHistoryEventSchema.is(event)) return getAddCampaignText(event);
  if (ApproveGrasCampaignHistoryEventSchema.is(event)) return getApproveGrasCampaignText(event);
  if (RejectGrasCampaignHistoryEventSchema.is(event)) return getRejectGrasCampaignText(event);
  if (RemoveCampaignHistoryEventSchema.is(event)) return getRemoveCampaignText(event);

  return `Unsupported event ${event.action.type}`;
};

export const getProjectFiltersParamsFromSearchQuery = (query: ParsedQs): ProjectFilterParams => {
  const freeText = typeof query.freeText === 'string' ? query.freeText : undefined;
  const statuses = isStringArray(query.statuses) ? query.statuses : undefined;
  const type = typeof query.type === 'string' && TargetTypeSchema.is(query.type) ? query.type : 'Artist';
  const earliestStartDate = typeof query.earliestStartDate === 'string' ? query.earliestStartDate : undefined;
  const latestEndDate = typeof query.latestEndDate === 'string' ? query.latestEndDate : undefined;
  const limit = typeof query.limit === 'string' ? parseInt(query.limit, 10) : undefined;
  const offset = typeof query.offset === 'string' ? parseInt(query.offset, 10) : undefined;
  const labelId = isStringArray(query.labels) ? parseInt(query.labels[0], 10) : undefined;
  const onlyMine = typeof query.onlyMine === 'string' ? query.onlyMine === 'true' : undefined;
  const sort =
    typeof query.sort === 'string' && Sort.create(query.sort, isProjectSortField)
      ? query.sort
      : DEFAULT_SORT.getString();
  const rosterLabelId = isStringArray(query.artistLabels) ? parseInt(query.artistLabels[0], 10) : undefined;

  return {
    freeText,
    statuses: statuses?.map(status => parseInt(status, 10)) || [PROJECT_STATUSES[0].id],
    type,
    earliestStartDate,
    latestEndDate,
    limit: limit || DEFAULT_PAGINATION_LIMIT,
    offset: offset || 0,
    sort,
    labels: labelId !== undefined ? [labelId] : undefined,
    onlyMine,
    artistLabels: rosterLabelId !== undefined ? [rosterLabelId] : undefined,
  };
};

export const getProjectFiltersFromParams = (params: ProjectFilterParams): AllProjectsFilters => {
  const status = PROJECT_STATUSES.find(status => status.id === params.statuses?.[0]);

  const getParsedStartOfDay = (dateString?: string) => {
    const startOfDay = getStartOfDay(parseDateFromISOString(dateString));
    return isValidDate(startOfDay) ? startOfDay : undefined;
  };

  const earliestStartDate = getParsedStartOfDay(params.earliestStartDate);
  const latestEndDate = getParsedStartOfDay(params.latestEndDate);

  if (!status) {
    return {
      status,
      earliestStartDate,
      latestEndDate,
      search: params.freeText,
      filtersByStatus: {},
      labelId: params.labels?.[0],
      rosterLabelId: params.artistLabels?.[0],
    };
  }

  const sort = params.sort && Sort.create(params.sort, isProjectSortField);

  return {
    status,
    earliestStartDate,
    latestEndDate,
    search: params.freeText,
    onlyMine: params.onlyMine,
    labelId: params.labels?.[0],
    rosterLabelId: params.artistLabels?.[0],
    filtersByStatus: {
      [status.id]: {
        targetType: params.type || 'Artist',
        limit: params.limit,
        offset: params.offset,
        sort: sort || DEFAULT_SORT,
      },
    },
  };
};

export const getProjectFiltersParamsFromFilters = (filters: AllProjectsFilters): ProjectFilterParams => {
  if (!filters.status) {
    return {};
  }

  const specificFilters = filters.filtersByStatus[filters.status.id];

  const formatDate = getFormattedDate(SHORT_DATE_REQUEST_FORMAT);

  const defaultParams = {
    statuses: [filters.status.id],
    earliestStartDate: formatDate(filters.earliestStartDate) || undefined,
    latestEndDate: formatDate(filters.latestEndDate) || undefined,
    freeText: filters.search,
    onlyMine: filters.onlyMine,
    labels: filters.labelId !== undefined ? [filters.labelId] : undefined,
    artistLabels: filters.rosterLabelId !== undefined ? [filters.rosterLabelId] : undefined,
  };

  if (!specificFilters) {
    return defaultParams;
  }

  return {
    ...defaultParams,
    type: specificFilters.targetType,
    offset: specificFilters.offset,
    limit: specificFilters.limit,
    sort: specificFilters.sort?.getString(),
  };
};

const getBadgeString = (badge?: number) => (typeof badge === 'number' ? ` (${badge})` : '');

export const getArtistsOption = (badge?: number): AllProjectsTargetOption => ({
  id: 1,
  name: `Artists${getBadgeString(badge)}`,
  type: 'Artist',
  count: badge || 0,
});

export const getPlaylistsOption = (badge?: number): AllProjectsTargetOption => ({
  id: 2,
  name: `Playlists${getBadgeString(badge)}`,
  type: 'Playlist',
  count: badge || 0,
});

export const getAllProjectsTabs = (
  labelId: number,
  hasProjects: boolean,
  meta?: ProjectsMetadata
): AllProjectsTab[] => {
  const getProjectCounterByStatus = (status: ProjectStatus) => {
    switch (status.id) {
      case UPCOMING_STATUS.id:
        return meta?.scheduled;
      case IN_PROGRESS_STATUS.id:
        return meta?.inProgress;
      case COMPLETED_STATUS.id:
        return meta?.completed;
      case UNASSIGNED_STATUS.id:
        return meta?.unassigned;
      default:
        return undefined;
    }
  };

  const getTabOptionsByStatus = (status: ProjectStatus) => {
    const counter = getProjectCounterByStatus(status);
    return [getArtistsOption(counter?.artistCount), getPlaylistsOption(counter?.playlistCount)];
  };

  const filteredProjectStatuses =
    labelId === ALL_OPTION_ID && hasProjects
      ? PROJECT_STATUSES.filter(({ id }) => id !== UNASSIGNED_STATUS.id)
      : PROJECT_STATUSES;

  return filteredProjectStatuses.map(status => ({
    status,
    title: status.name,
    badge: getProjectCounterByStatus(status)?.totalCount,
    options: getTabOptionsByStatus(status),
    dataSelector: status.name
      .split(' ')
      .join('-')
      .toLowerCase(),
  }));
};

export const getFiltersByStatusWithDefaultOffset = (filtersByStatus: Record<string, AllProjectsFiltersByStatus>) => {
  return Object.entries(filtersByStatus).reduce(
    (result, [id, filters]) => ({
      ...result,
      [id]: {
        ...filters,
        offset: 0,
      },
    }),
    {} as Record<string, AllProjectsFiltersByStatus>
  );
};

export const getMyProjectDropdownLabelsOptions = (labels?: Label[], labelId?: number): BaseOption[] => {
  if (!labels || !labelId) return [DEFAULT_MY_PROJECTS_DROPDOWN_OPTION];

  const label = labels.find(({ id }) => id === labelId);

  if (!label) return [DEFAULT_MY_PROJECTS_DROPDOWN_OPTION];

  return [
    DEFAULT_MY_PROJECTS_DROPDOWN_OPTION,
    {
      id: label.id,
      value: `${label.name} Projects`,
    },
  ];
};
