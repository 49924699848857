import React from 'react';
import { bem } from 'utils/bem';
import { SStatusBadge, BEM_CLASS } from './s-status-badge';
import { SingleValueView } from '../single-value-view';
import { CampaignStatuses } from 'backend-api/models';

interface StatusBadgeProps {
  className?: string;
  status: CampaignStatuses;
}

const classes = bem(BEM_CLASS);

export const StatusBadge = React.memo(({ className, status }: StatusBadgeProps) => (
  <SStatusBadge className={className} status={status}>
    <SingleValueView isBadge value={status} className={classes('badge')} textClassName={classes('text')} />
  </SStatusBadge>
));
