import { ColDef, RowNode } from 'ag-grid-community';
import { LabelPermissionsMember } from 'backend-api/models';
import { isSubstring } from 'utils/string';
import { getRoleNameById } from 'common-v2/transducers';
import { EditArtistsModalPayload } from 'label-permissions/types';
import { ArtistsRenderer, EditArtistsRenderer, RoleRenderer, UserNameRenderer } from './components';
import { TeamMembersTableClassNames } from './types';

interface TableConfigParams {
  classNames: TeamMembersTableClassNames;
  openEditArtistsModal: (payload: EditArtistsModalPayload) => void;
  isSortingDisabled: boolean;
  isUserAdmin: boolean;
}

export const buildTableConfig = ({
  classNames,
  openEditArtistsModal,
  isSortingDisabled,
  isUserAdmin,
}: TableConfigParams): ColDef[] => [
  {
    field: 'user',
    headerName: 'Name',
    width: 230,
    sortable: !isSortingDisabled,
    unSortIcon: !isSortingDisabled,
    comparator: userComparator,
    suppressMenu: true,
    cellRenderer: UserNameRenderer,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 144,
    sortable: !isSortingDisabled,
    unSortIcon: !isSortingDisabled,
    comparator: roleComparator,
    suppressMenu: true,
    cellRenderer: RoleRenderer,
  },
  {
    field: 'artists',
    headerName: 'Artist',
    cellClass: classNames.artistsCell,
    flex: 1,
    suppressMenu: true,
    cellRenderer: ArtistsRenderer,
  },
  {
    field: 'edit-artists',
    hide: !isUserAdmin,
    headerName: '',
    width: 55,
    suppressMenu: true,
    cellRenderer: EditArtistsRenderer,
    cellRendererParams: {
      openEditArtistsModal,
    },
  },
];

export const userComparator = (
  valueA: LabelPermissionsMember['user'],
  valueB: LabelPermissionsMember['user']
): number => valueB.name.localeCompare(valueA.name);

export const roleComparator = (
  valueA: LabelPermissionsMember['role'],
  valueB: LabelPermissionsMember['role'],
  rowNodeA: RowNode,
  rowNodeB: RowNode,
  isInverted: boolean
): number => {
  const roleAName = getRoleNameById(valueA);
  const roleBName = getRoleNameById(valueB);

  if (roleAName === undefined) return isInverted ? -1 : 1;
  if (roleBName === undefined) return isInverted ? 1 : -1;

  return roleBName.localeCompare(roleAName);
};

export const teamMemberPredicate = (search: string) => ({ user, artists }: LabelPermissionsMember): boolean =>
  isSubstring(user.name, search) || artists.some(artist => isSubstring(artist.name, search));
