import { manhattanThemeColors } from 'app/theme/colors';
import styled from 'styled-components';

export const BEM_CLASS = 'artists-list-header';

export const SArtistsListHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .${BEM_CLASS} {
    &__icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${manhattanThemeColors.textHeaderColor};
      height: 30px;
      width: 30px;
      border-radius: 10px;
    }

    &__icon {
      color: ${manhattanThemeColors.background};
    }

    &__title {
      font-size: 14px;
      line-height: 25px;
      font-weight: 700;
      padding-bottom: 0;
    }
  }
`;
