import React, { useCallback } from 'react';
import { BEM_CLASS, SErrorOverlay } from './s-error-overlay';
import { bem } from 'utils/bem';
import { Body } from 'common/components/typography';
import FailedToLoadGraySvg from 'assets/failed-to-load-gray.svg';

const classes = bem(BEM_CLASS);

export const ErrorOverlay = React.memo(() => {
  const onRefresh = useCallback(() => window.location.reload(), []);

  return (
    <SErrorOverlay>
      <img alt="" src={FailedToLoadGraySvg} className={classes('icon')} />
      <Body className={classes('text')}>
        An error occurred while loading your
        <br />
        Media Plan data, please try again.
      </Body>
      <button className={classes('refresh')} onClick={onRefresh}>
        Refresh
      </button>
    </SErrorOverlay>
  );
});
