import { responsiveContainerWrapperCss } from 'common/s-components/sidebar-form/s-responsive-container';
import styled from 'styled-components';

export const BEM_CLASS = 'settings-header';

export const SSettingsHeader = styled.div`
  .${BEM_CLASS} {
    &__header {
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 93px;
    }
    &__tab-component {
      position: relative;
      z-index: 1;
      .tabs__header {
        ${responsiveContainerWrapperCss};
      }
    }
  }
`;
