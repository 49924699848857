import styled from 'styled-components';
import { Button } from 'common/components/button';

export const BEM_CLASS = 'media-plan-dropdown';

export const SMediaPlanDropdown = styled(Button)`
  .${BEM_CLASS} {
    &__icon {
      margin-left: 10px;
    }
  }
`;
