import { Artist, Playlist, ArtistSearch, PlaylistSchema } from 'backend-api/models';
import { TargetsImagesDisplayMode, TargetsImagesSizeMode, TargetsImagesTargetType } from './types';

export const getTargetImageUrl = (target: Artist | Playlist | ArtistSearch) => {
  return PlaylistSchema.is(target) ? target.images[0]?.url : target.image?.url;
};

export const getTargetImageSizeMode = (
  containerHeight: number,
  displayMode?: TargetsImagesDisplayMode
): TargetsImagesSizeMode => {
  if (containerHeight >= 50 && !!displayMode) {
    return 'big';
  }

  if (containerHeight < 50 && !displayMode) {
    return 'small';
  }

  return 'medium';
};

export const getTargetImageType = (target: Artist | Playlist | ArtistSearch): TargetsImagesTargetType =>
  PlaylistSchema.is(target) ? 'playlist' : 'artist';
