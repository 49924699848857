import React, { useEffect } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { bem } from 'utils/bem';
import { END_DATE_VALIDATION_TEXT } from 'media-plan-v2/components/spreadsheet/constants';
import { useScheduleValidation } from 'media-plan-v2/components/spreadsheet/hooks';
import { MediaPlanColumnId, SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { BaseRenderer, SpreadsheetTooltip, TextValue } from 'media-plan-v2/components/spreadsheet/components';
import { BEM_CLASS, SEndDateRenderer } from './s-end-date-renderer';

const classes = bem(BEM_CLASS);

interface EndDateRendererProps extends ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['endDate']> {
  dateFormat: string;
}

export const EndDateRenderer = ({
  context,
  value: { color, value },
  data,
  api,
  node,
  formatValue,
  dateFormat,
}: EndDateRendererProps) => {
  const [isValueInvalid, tooltipContent] = useScheduleValidation({
    startDate: data?.startDate.value,
    endDate: value,
    tooltipText: END_DATE_VALIDATION_TEXT,
    dateFormat,
  });

  useEffect(() => {
    api.refreshCells({ columns: [MediaPlanColumnId.START_DATE], rowNodes: [node], force: true });
  }, [isValueInvalid, api, node]);

  return (
    <SpreadsheetTooltip content={tooltipContent} disabled={!isValueInvalid}>
      <SEndDateRenderer>
        <BaseRenderer
          color={color}
          accessMode={context.accessMode}
          className={classes('base-renderer')}
          placeholder={dateFormat}
        >
          {formatValue?.(value) && <TextValue value={formatValue(value)} />}
        </BaseRenderer>
      </SEndDateRenderer>
    </SpreadsheetTooltip>
  );
};
