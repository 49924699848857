import React from 'react';
import { BEM_CLASS, SModalFooter } from './s-modal-footer';
import { Button } from 'common/components/button';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { Tooltip } from 'common/components/tooltip';
import { Warning } from 'modals/sharing-modal/types';
interface Props {
  isSaveDisabled: boolean;
  isCancelDisabled: boolean;
  warning?: Warning;
  onCancel(): void;
  onSave(): void;
  saveButtonTooltipText?: string;
}

const classes = bem(BEM_CLASS);

export const ModalFooter = React.memo(
  ({ isSaveDisabled, isCancelDisabled, onCancel, onSave, warning, saveButtonTooltipText }: Props) => (
    <SModalFooter>
      <div className={classes('content')}>
        {warning?.value && (
          <div className={classes('warning')}>
            <Icon className={classes('warning-icon')} name="warning" size="large" />
            <span data-selector="sharing-modal-warn-label">{warning.message}</span>
          </div>
        )}
        <div className={classes('confirmation')}>
          <Button
            disabled={isCancelDisabled}
            variant="secondary-gray"
            onClick={onCancel}
            dataSelector="sharing-modal-cancel-button"
          >
            Cancel
          </Button>
          <Tooltip content={saveButtonTooltipText} enabled={!!saveButtonTooltipText}>
            <span>
              <Button
                className={classes('save')}
                disabled={isSaveDisabled}
                variant="primary-cta"
                onClick={onSave}
                dataSelector="sharing-modal-save-button"
              >
                Save
              </Button>
            </span>
          </Tooltip>
        </div>
      </div>
    </SModalFooter>
  )
);
