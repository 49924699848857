import React from 'react';
import { bem } from 'utils/bem';
import { Value } from 'common/components/tables/common';
import { bemClass, SBreakdownCell } from './s-breakdown-cell';

interface BreakdownCellProps {
  value?: string;
  valueClassName?: string;
  hasDivider: boolean;
  className?: string;
}

const classes = bem(bemClass);

export const BreakdownCell = ({ value = '', hasDivider, valueClassName, className }: BreakdownCellProps) => {
  return (
    <SBreakdownCell className={className}>
      <Value className={valueClassName}>{value}</Value>
      {hasDivider && <div className={classes('divider')} />}
    </SBreakdownCell>
  );
};
