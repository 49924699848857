import { Optional } from 'backend-api/models';
import persistReducer from 'redux-persist/lib/persistReducer';
import storage from 'redux-persist/lib/storage';
import { createTypedReducer, onAction } from 'core/store';
import { clearAuthToken, setAuthToken, setAuthTokenLoading } from './actions';
import { TokenState } from './types';

export interface LoginState {
  isLoading: boolean;
  token: Optional<TokenState>;
}

export const initialState: LoginState = {
  isLoading: false,
  token: undefined,
};

const persistConfig = {
  key: 'login',
  storage,
  whitelist: ['token'],
};

export const loginReducer = createTypedReducer<LoginState>(
  initialState,
  onAction(setAuthTokenLoading, state => ({
    ...state,
    isLoading: true,
  })),
  onAction(setAuthToken, (state, { payload }) => ({
    ...state,
    isLoading: false,
    token: {
      ...payload.data,
      expires_timestamp: payload.currentTimestamp + payload.data.expires_in * 1000,
    },
  })),
  onAction(clearAuthToken, state => ({
    ...state,
    isLoading: false,
    token: undefined,
  }))
);

export const reducer = persistReducer(persistConfig, loginReducer);
