import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { ArtistTeam } from 'backend-api/models';
import { Icon } from 'common/components/icon';
import { Team } from 'common/components/team';
import { SmallText } from 'common/components/typography';
import { BEM_CLASS, STeamOption } from './s-team-option';

interface TeamOptionProps {
  team: ArtistTeam;
  isEditable?: boolean;
  onClick?(team: ArtistTeam): void;
}

const classes = bem(BEM_CLASS);

export const TeamOption = React.memo(({ team, isEditable = false, onClick }: TeamOptionProps) => {
  const onOptionClick = useCallback(() => onClick?.(team), [onClick, team]);

  return (
    <STeamOption onClick={onOptionClick} data-selector="artist-team-button">
      <SmallText className={classes('title')} data-selector="artist-team-title">
        {team.label.name}
      </SmallText>
      <div className={classes('main')}>
        <Team className={classes('members')} users={team.users} dataSelector="artist" />
        {isEditable && <Icon className={classes('icon')} name="edit-outline" color="inherit" size="general" />}
      </div>
    </STeamOption>
  );
});
