import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

interface STimelineProps {
  progressWidth: number;
}

export const STimeline = styled.div<STimelineProps>`
  display: flex;
  flex-wrap: wrap;
  .timeline {
    &__dates {
      width: 100%;
    }
    &__date {
      font-size: 10px;
      font-weight: 400;
      color: ${palette.darkBlueTwo};
      letter-spacing: 1px;
      line-height: 15px;
    }
    &__progress {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 3px;
      background-color: ${palette.athensGrayThree};

      &::after {
        content: '';
        position: absolute;
        z-index: 2;
        display: block;
        width: ${({ progressWidth }) => progressWidth}%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${palette.pink};
      }
    }
  }
`;
