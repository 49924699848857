import React from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { END_DATE_VALIDATION_TEXT } from 'media-plan-v2/components/spreadsheet/constants';
import { useScheduleValidation } from 'media-plan-v2/components/spreadsheet/hooks';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { DateEditor } from 'media-plan-v2/components/spreadsheet/components';

interface EndDateEditorProps extends ICellEditorParams<SpreadsheetRowData, SpreadsheetRowData['endDate']> {
  dateFormat: string;
}

export const EndDateEditor = React.memo(
  React.forwardRef(({ value, data, api, column, rowIndex, dateFormat }: EndDateEditorProps, ref) => {
    const [isValueInvalid, tooltipContent] = useScheduleValidation({
      startDate: data.startDate.value,
      endDate: value.value,
      tooltipText: END_DATE_VALIDATION_TEXT,
      dateFormat,
    });

    return (
      <DateEditor
        initialValue={value}
        ref={ref}
        tooltipContent={tooltipContent}
        isTooltipVisible={isValueInvalid}
        api={api}
        colKey={column.getColId()}
        rowIndex={rowIndex}
      />
    );
  })
);
