import { Sort, SortOrder } from 'utils/sort';
import { PurchaseOrderSortField } from 'backend-api/types';
import { ALL_OPTION_ID } from 'common/components/form/select/constants';

export const DEFAULT_SORT: Sort<PurchaseOrderSortField> = new Sort(SortOrder.Descending, 'createdAt');

export const ALL_STATUSES_SELECT_OPTION = {
  id: ALL_OPTION_ID,
  value: 'All Statuses',
};

export const ALL_PROVIDERS_OPTION = {
  id: ALL_OPTION_ID,
  value: 'All Providers',
};

export const PAGINATION_LIMIT = 10;
