import { GlobalSearchTab } from './types';

export const rootAnimation = {
  initial: { opacity: 0 },
  exit: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { type: 'ease-out', duration: 0.3 },
};

export const CARD_SIZE = 65;

export const DEFAULT_TABS = [GlobalSearchTab.PROJECTS, GlobalSearchTab.ARTISTS];
export const TABS = [...DEFAULT_TABS, GlobalSearchTab.CAMPAIGNS];
