import * as t from 'io-ts';
import { AudienceAgeSchema, GenderSchema, ObjectiveSchema } from '../campaign';
import {
  ResponseModel,
  IdNameField,
  FormattedDateFromISOString,
  Optional,
  TerritorySchema,
  PaginationResponseModel,
} from '../common';

export const AdSetSchema = ResponseModel(
  {
    ...IdNameField,
    budgetSpend: Optional(t.number),
    plannedBudget: t.number,
    startDate: FormattedDateFromISOString,
    endDate: Optional(FormattedDateFromISOString),
    territories: t.array(TerritorySchema),
    objective: ObjectiveSchema,
    platform: t.string,
    audienceAge: AudienceAgeSchema,
    genders: t.array(GenderSchema),
    destinationLinks: Optional(t.array(t.string)),
    currency: t.string,
  },
  'AdSet'
);

export type AdSet = t.TypeOf<typeof AdSetSchema>;

export const AdSetsResponseSchema = PaginationResponseModel(AdSetSchema);

export type AdSetsResponse = t.TypeOf<typeof AdSetsResponseSchema>;
