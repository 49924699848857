import React from 'react';
import { BEM_CLASS, SMenuFooter } from './s-menu-footer';
import { Button } from 'common/components/button';
import { bem } from 'utils/bem';

interface Props {
  onApplyClick(): void;
  onResetClick(): void;
  canCreate?: boolean;
}

const classes = bem(BEM_CLASS);

export const MenuFooter = React.memo(({ onApplyClick, onResetClick, canCreate }: Props) => {
  return (
    <SMenuFooter>
      <Button
        variant="secondary-gray"
        dataSelector="menu-reset-button"
        onClick={onResetClick}
        className={classes('reset-button')}
      >
        Reset
      </Button>
      <Button
        variant="secondary-cta"
        dataSelector="menu-apply-button"
        onClick={onApplyClick}
        className={classes('apply-button')}
      >
        {canCreate ? 'Create' : 'Apply'}
      </Button>
    </SMenuFooter>
  );
});
