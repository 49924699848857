import React, { useMemo, useRef, useState } from 'react';
import { palette } from 'frontend-shared-theme';
import { SPaper } from 'common/s-components/s-paper';
import { SRow } from 'common/s-components/layout/s-row';
import { SCol } from 'common/s-components/layout/s-col';
import { Cell } from 'common/components/form/cell';
import { Tooltip } from 'common/components/tooltip';
import { Timeline } from 'common/components/timeline';
import { Header } from './header';
import { bem } from 'utils/bem';
import { SBody } from './s-body';
import MarkdownEditor from 'common/components/markdown-editor';
import {
  getAudienceString,
  getNamesString,
  getPurchaseOrderNameWithPONumber,
  isCampaignExternal,
  isEmptyMarkdown,
} from 'common/transducers';
import { getCurrencyFormatted } from 'utils/format';
import { CampaignDetails, CampaignSources, Id, Optional } from 'backend-api/models';
import { usePerformanceObjectives, useShouldNotesExpand } from 'hooks';
import { NOT_AVAILABLE_BUDGET_TOOLTIP, NOT_SELECTED_VALUE_TITLE } from '../../constants';
import { prepareValidLinksForDisplay } from 'campaign/transducers';
import { H4 } from 'common/components/typography';
import { LinksViewComponent, LinkViewContainer } from 'common/components/form/links/components';
import { paths } from 'app/routing/paths';
import { LinkSelectOption } from 'common/components/form/links/types';
import { HTTPS_LINK_PREFIX } from 'common/constants';
import { NOT_AVAILABLE } from 'common-v2/constants';

const classes = bem('campaign-details');

interface Props {
  campaign: CampaignDetails;
  projectId?: Id;
  onViewAdSetsClick: () => void;
  currencyCode?: string;
}

export const Body = React.memo((props: Props) => {
  const { campaign, projectId, currencyCode, onViewAdSetsClick } = props;

  const formattedPlannedBudget = useMemo(() => {
    if (campaign.source === CampaignSources.FACEBOOK) {
      return NOT_AVAILABLE;
    }

    return getCurrencyFormatted(campaign.plannedBudget, currencyCode, true);
  }, [campaign.plannedBudget, campaign.source, currencyCode]);
  const formattedSpendBudget = useMemo(() => getCurrencyFormatted(campaign.budgetSpend, currencyCode, true), [
    campaign.budgetSpend,
    currencyCode,
  ]);

  const editorRef = useRef<HTMLDivElement>(null);

  const [notesExpanded, setNotesExpanded] = useState(false);
  const shouldNotesExpand = useShouldNotesExpand(editorRef);

  const expandText = notesExpanded ? 'See less' : 'See more';

  const gendersString = useMemo(() => getNamesString(campaign.genders), [campaign.genders]);
  const territoriesString = useMemo(() => getNamesString(campaign.territories), [campaign.territories]);
  const platformsString = useMemo(() => getNamesString(campaign.platforms), [campaign.platforms]);
  const placementsString = useMemo(() => getNamesString(campaign.placements) || NOT_SELECTED_VALUE_TITLE, [
    campaign.placements,
  ]);
  const audienceAgeString = useMemo(() => getAudienceString(campaign.audienceAge), [campaign.audienceAge]);

  const metrics = usePerformanceObjectives();
  const kpiValue = useMemo(() => metrics?.[0].fields.find(metric => metric.id === campaign.kpiMetricsFieldId), [
    campaign.kpiMetricsFieldId,
    metrics,
  ]);

  const isExternalCampaign = isCampaignExternal(campaign);

  const showLinksSection =
    (campaign.destinationLinks && campaign.destinationLinks.length > 0) ||
    campaign.purchaseOrder ||
    (campaign.linkfireLinks && campaign.linkfireLinks.length > 0);

  const creativeLinks = useMemo(() => prepareValidLinksForDisplay(campaign.adCreativeLinks || []), [campaign]);

  const showAdSection = creativeLinks.length > 0 || !!campaign.adCreativeNotes;

  const purchaseOrderLink: Optional<LinkSelectOption> = useMemo(() => {
    if (!projectId || !campaign.purchaseOrder) {
      return undefined;
    }

    return {
      link: paths.purchaseOrder(projectId, campaign.purchaseOrder.id),
      id: campaign.purchaseOrder.id,
      label: getPurchaseOrderNameWithPONumber(campaign.purchaseOrder),
      icon: 'dollar',
    };
  }, [campaign.purchaseOrder, projectId]);

  const linkfireLinks = useMemo(() => campaign.linkfireLinks?.map(link => HTTPS_LINK_PREFIX + link.linkUrl), [
    campaign,
  ]);

  return (
    <SBody expanded={notesExpanded}>
      <div>
        <Header onViewAdSetsClick={onViewAdSetsClick} />
        <SPaper pt="25px" pb="10px" mb="10px" mt="17px" color={palette.white}>
          <div className={classes('campaign-main-info')}>
            <Cell label="Budget">
              <Tooltip
                content={
                  formattedPlannedBudget === NOT_AVAILABLE ? NOT_AVAILABLE_BUDGET_TOOLTIP : formattedPlannedBudget
                }
              >
                <span className={classes('text')}>{formattedPlannedBudget}</span>
              </Tooltip>
            </Cell>
            {!isExternalCampaign ? (
              <Cell label="Spend">
                <Tooltip content={formattedSpendBudget}>
                  <span className={classes('text')}>{formattedSpendBudget}</span>
                </Tooltip>
              </Cell>
            ) : (
              <div />
            )}
            <Cell label="Schedule" dataSelector="dates">
              <Timeline startDate={campaign.startDate} endDate={campaign.endDate} className={classes('timeline')} />
            </Cell>
            <Cell label="Gratis" dataSelector="gratis" className={classes('cell-top-label')}>
              <span className={classes('text')}>{campaign.gratis ? 'Gratis' : 'Paid'}</span>
            </Cell>
          </div>
        </SPaper>
        <SPaper pt="25px" pb="10px" mb="10px" color={palette.white}>
          <div className={classes('campaign-main-info')}>
            <Cell label="Campaign Type" dataSelector="campaign-type">
              <Tooltip content={campaign.type?.name || NOT_SELECTED_VALUE_TITLE}>
                <span className={classes('text')}>{campaign.type?.name || NOT_SELECTED_VALUE_TITLE}</span>
              </Tooltip>
            </Cell>
            <Cell label="Platforms" dataSelector="campaign-platforms">
              <Tooltip content={platformsString}>
                <span className={classes('text')}>{platformsString}</span>
              </Tooltip>
            </Cell>
            <Cell label="Objective" dataSelector="objective">
              <Tooltip content={campaign.objective?.name || NOT_SELECTED_VALUE_TITLE}>
                <span className={classes('text')}>{campaign.objective?.name || NOT_SELECTED_VALUE_TITLE}</span>
              </Tooltip>
            </Cell>
            <Cell label="Placements">
              <Tooltip content={placementsString}>
                <span className={classes('text')}>{placementsString}</span>
              </Tooltip>
            </Cell>
            {kpiValue?.name && (
              <Cell label="Kpi">
                <Tooltip content={kpiValue.name}>
                  <span className={classes('text')}>{kpiValue.name}</span>
                </Tooltip>
              </Cell>
            )}
            <Cell label="Provider">
              <Tooltip content={campaign.provider?.name || NOT_SELECTED_VALUE_TITLE}>
                <span className={classes('text')}>{campaign.provider?.name || NOT_SELECTED_VALUE_TITLE}</span>
              </Tooltip>
            </Cell>
          </div>
        </SPaper>
        <SPaper pt="25px" pb="10px" mb="10px" color={palette.white}>
          <div className={classes('campaign-main-info')}>
            <Cell label="Gender">
              <Tooltip content={gendersString}>
                <span className={classes('text')}>{gendersString}</span>
              </Tooltip>
            </Cell>
            <Cell label="Audience Age">
              <Tooltip content={audienceAgeString}>
                <span className={classes('text')}>{audienceAgeString}</span>
              </Tooltip>
            </Cell>
            <Cell label="Countries">
              <Tooltip content={territoriesString}>
                <span className={classes('text')}>{territoriesString}</span>
              </Tooltip>
            </Cell>
          </div>
          {campaign.audienceNotes && (
            <Cell label="Details" wide>
              <span className={classes('long-text')}>{campaign.audienceNotes}</span>
            </Cell>
          )}
        </SPaper>
        {showLinksSection && (
          <SPaper pt="25px" pb="10px" mb="10px" color={palette.white}>
            <div className={classes('campaign-links')}>
              {campaign.linkfireLinks && campaign.linkfireLinks.length > 0 && (
                <Cell className={classes('cell-centered-label')} label="Linkfire Link">
                  <LinksViewComponent links={linkfireLinks || []} />
                </Cell>
              )}
              {campaign.destinationLinks && campaign.destinationLinks.length > 0 && (
                <Cell className={classes('cell-centered-label')} label="Destinations">
                  <LinksViewComponent links={campaign.destinationLinks} />
                </Cell>
              )}
              {campaign.purchaseOrder && projectId && purchaseOrderLink && (
                <Cell className={classes('cell-centered-label')} label="Purchase Order">
                  <LinkViewContainer badge={campaign.purchaseOrder.status} link={purchaseOrderLink} />
                </Cell>
              )}
            </div>
          </SPaper>
        )}
        {showAdSection && (
          <SPaper pt="25px" pb="10px" mb="10px" color={palette.white}>
            <div className={classes('campaign-links')}>
              {creativeLinks.length > 0 && (
                <Cell className={classes('cell-centered-label')} label="Ad Creative Link">
                  <LinksViewComponent links={creativeLinks} allowInvalid />
                </Cell>
              )}
              {campaign.adCreativeNotes && (
                <Cell label="Copy" wide>
                  <span className={classes('long-text')}>{campaign.adCreativeNotes}</span>
                </Cell>
              )}
            </div>
          </SPaper>
        )}
        {!isEmptyMarkdown(campaign.notes) && (
          <SPaper pt="25px" pb="15px" mb="10px" color={palette.white}>
            <SRow>
              <SCol width={1}>
                <Cell label="Notes" wide>
                  <div key="editor" className={classes('editor_container')}>
                    <MarkdownEditor
                      ref={editorRef}
                      className={classes('editor')}
                      editable={false}
                      content={campaign.notes || ''}
                    />
                    {shouldNotesExpand && !notesExpanded && <div className={classes('dots')}>...</div>}
                    {shouldNotesExpand && (
                      <H4
                        key="show_more"
                        onClick={() => setNotesExpanded(!notesExpanded)}
                        className={classes('show-notes')}
                      >
                        {expandText}
                      </H4>
                    )}
                  </div>
                </Cell>
              </SCol>
            </SRow>
          </SPaper>
        )}
      </div>
    </SBody>
  );
});
