import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { BEM_CLASS, SBudgetWidget } from './s-budget-widget';
import { activeProjectSelector, budgetGroupsSelector, isLoadingSelector } from 'project/selectors';
import { appearingAnimation } from 'common/constants';
import { BudgetWidgetLoader } from 'common/components/loaders/budget-widget-loader';
import { BudgetDetailsGrid, BudgetMainInfo, Legend } from './components';
import { Icon } from 'common/components/icon';
import { Button } from 'common/components/button';
import { AnimatePresence, motion } from 'framer-motion';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { H4, PrimaryLabel } from 'common/components/typography';
import { getBudgetGroupsOptions, getBudgetInfoItems } from './transducers';
import { Id } from 'backend-api/models';
import { ALL_OPTION_ID } from 'common/components/form/select/constants';
import { LoadingState } from 'common/types';
import { Select, SelectType } from 'common/components/select';
import { useFormattedDateTime } from 'hooks';

interface Props {
  className?: string;
  dataSelector?: string;
  currencyCode?: string;
  isAssignCampaignsLoading: boolean;
}

const classes = bem(BEM_CLASS);

const animationProps = {
  initial: { height: 0, opacity: 0 },
  exit: { height: 0, opacity: 0, overflow: 'hidden' },
  animate: { height: 'unset', opacity: 1 },
  transition: { type: 'spring', damping: 10, mass: 0.3, stiffness: 70 },
};

export const BudgetWidget = React.memo(({ className, dataSelector, currencyCode, isAssignCampaignsLoading }: Props) => {
  const project = useSelector(activeProjectSelector);
  const isProjectLoading = useSelector(isLoadingSelector);
  const { loading, data: budgetGroups } = useSelector(budgetGroupsSelector);

  const isLoading = loading === LoadingState.Started || loading === LoadingState.Idle || isProjectLoading;

  const [expandedOverflow, setExpandedOverflow] = useState('hidden');
  const [expanded, setExpanded] = useState(false);
  const [budgetWidgetSelectorEnabled, setBudgetWidgetSelectorEnabled] = useState(false);

  const budget = project?.budget || 0;
  const allocation = project?.allocation || 0;

  const prsData = project?.prsData;
  const ccpData = project?.ccpData;

  const isCCPDataAvailable = ccpData !== undefined;

  const lastUpdatedDateFormatted = useFormattedDateTime({
    date: isCCPDataAvailable ? ccpData?.updatedAt : prsData?.updatedAt,
    isFullDate: true,
  });

  const onViewGroupsClick = useCallback(() => {
    setExpanded(!expanded);
    setExpandedOverflow('hidden');
    setBudgetWidgetSelectorEnabled(false);
    trackEvent(AnalyticsEvents.BUDGET_WIDGET_EXPANDED, { project_id: project?.id });
  }, [expanded, project]);

  const budgetGroupsOptions = useMemo(() => getBudgetGroupsOptions(budgetGroups), [budgetGroups]);

  const [selectedBudgetGroupId, setSelectedBudgetGroupId] = useState(ALL_OPTION_ID);

  const onSelectBudgetGroupId = useCallback((groupId: Id) => {
    setSelectedBudgetGroupId(groupId);
  }, []);

  const budgetInfoItems = useMemo(() => getBudgetInfoItems(budgetGroups, selectedBudgetGroupId), [
    budgetGroups,
    selectedBudgetGroupId,
  ]);

  const breakdownSelectorClassNames = useMemo(
    () => ({
      control: classes('selector'),
    }),
    []
  );

  const onComplete = useCallback(() => {
    if (expanded) {
      setExpandedOverflow('visible');
      setBudgetWidgetSelectorEnabled(true);
    }
  }, [expanded]);

  return (
    <SBudgetWidget {...appearingAnimation} data-selector={dataSelector} expanded={expanded} className={className}>
      {isLoading || isAssignCampaignsLoading ? (
        <BudgetWidgetLoader className={classes('loader')} />
      ) : (
        <div className={classes('main-container')}>
          <div className={classes('header ')}>
            <H4>Budget</H4>
            <div className={classes('group-toggle-container', { disabled: budgetGroups.length < 1 })}>
              <PrimaryLabel className={classes('group-toggle-title')}>View Groups</PrimaryLabel>
              <Button
                variant="secondary-gray"
                onClick={onViewGroupsClick}
                dataSelector="budget-widget-view-groups-button"
              >
                <Icon name="down-single" size="glyph" className={classes('icon')} />
              </Button>
            </div>
          </div>
          <BudgetMainInfo totalBudget={budget} allocation={allocation} currency={currencyCode} />
          <AnimatePresence>
            {expanded && (
              <motion.div {...animationProps} onAnimationComplete={onComplete} style={{ overflow: expandedOverflow }}>
                <div className={classes('separator')} />
                <div className={classes('selector-container')}>
                  <H4>Breakdown by</H4>
                  <Select
                    type={SelectType.Simple}
                    valueId={selectedBudgetGroupId}
                    valueHandler={onSelectBudgetGroupId}
                    options={budgetGroupsOptions}
                    classNames={breakdownSelectorClassNames}
                    isDisabled={!budgetWidgetSelectorEnabled}
                    dataSelector="budget-widget-breakdown"
                  />
                  <div className={classes('legend-divider')} />
                  <Legend currencyCode={currencyCode} />
                </div>
                <BudgetDetailsGrid
                  items={budgetInfoItems}
                  onSelect={onSelectBudgetGroupId}
                  className={classes('grid')}
                  currencyCode={currencyCode}
                />
              </motion.div>
            )}
          </AnimatePresence>
          {lastUpdatedDateFormatted && (
            <span className={classes('date')}>
              Last updated from {isCCPDataAvailable ? 'CCP' : 'PRS'} at {lastUpdatedDateFormatted.date}
            </span>
          )}
        </div>
      )}
    </SBudgetWidget>
  );
});
