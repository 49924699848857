import { CountryCode, Currency, IdNameField, Territory } from './index';

const getDefaultCountryCode = (): CountryCode => ({
  code: 'USA',
  name: 'United States',
});

export const getCountryCodeMock = (p?: CountryCode): CountryCode => p || getDefaultCountryCode();

const getIdNameDefaults = (): IdNameField => ({
  name: 'Old Artist',
  id: 0,
});

export const getIdNameMock = (p?: Partial<IdNameField>): IdNameField => ({
  ...getIdNameDefaults(),
  ...p,
});

const getTerritoryDefaults = (): Territory => ({
  name: 'USA',
  id: 1,
  parent_id: 0,
});

export const getTerritoryMock = (p?: Partial<Territory>): Territory => ({
  ...getTerritoryDefaults(),
  ...p,
});

const getCurrencyDefaults = (): Currency => ({
  id: 1,
  code: 'USD',
});

export const getCurrencyMock = (p?: Partial<Currency>): Currency => ({
  ...getCurrencyDefaults(),
  ...p,
});
