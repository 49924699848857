import { apiClient, ResponseArray } from '../index';
import {
  CountryCodeSchema,
  CurrencySchema,
  CurrentUserSchema,
  CurrentUserUpdate,
  CurrentUserUpdateSchema,
  LabelSchema,
  LabelUserSchema,
  TerritorySchema,
} from '../models';

export const getLabels = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/user-labels',
    responseSchema: ResponseArray(LabelSchema),
  });

export const getAllLabels = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/labels',
    responseSchema: ResponseArray(LabelSchema),
  });

export const getDefaultLabel = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/labels/default-label',
    responseSchema: LabelSchema,
  });

export const getTerritories = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/territories',
    responseSchema: ResponseArray(TerritorySchema),
  });

export const getCurrencies = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/currencies',
    responseSchema: ResponseArray(CurrencySchema),
  });

export const getLabelUsers = labelId =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/labels/${labelId}/users`,
    responseSchema: ResponseArray(LabelUserSchema),
  });

export const getUserInfo = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/current-user',
    responseSchema: CurrentUserSchema,
  });

export const getCountryCodes = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/country-codes',
    responseSchema: ResponseArray(CountryCodeSchema),
  });

export const updateUserInfo = (payload: CurrentUserUpdate) =>
  apiClient.executeRequest({
    method: 'PUT',
    endpoint: '/current-user',
    requestSchema: CurrentUserUpdateSchema,
    responseSchema: CurrentUserSchema,
    payload,
  });

export const getUsers = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/users',
    responseSchema: ResponseArray(LabelUserSchema),
  });
