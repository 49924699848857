import React, { useCallback, useMemo, useState } from 'react';
import { showSuccessToast } from 'common/components/toast';
import { bem } from 'utils/bem';
import { BEM_CLASS, SConfidentialNoPermission } from './s-project-no-permission';
import { NoPermission } from 'common/components/no-permission';
import { Button } from 'common/components/button';
import { Id, LabelUser } from 'backend-api/models';
import { ShortUserInfoResponse } from 'backend-api/types';
import { TeamMemberSelect } from './components/team-member-select';
import { sendEmail } from './transducers';

const classes = bem(BEM_CLASS);

interface Props {
  projectId?: Id;
  projectName?: string;
  teamMembers?: ShortUserInfoResponse[];
  currentUser?: LabelUser;
}

export const ConfidentialNoPermission = ({ projectId, teamMembers = [], projectName, currentUser }: Props) => {
  const [selected, setSelected] = useState<Id[]>([]);

  const selectedTeamMember = useMemo(() => {
    if (!selected.length) return null;

    const selectedId = selected[0];
    return teamMembers.find(teamMember => teamMember.id === selectedId);
  }, [selected, teamMembers]);

  const selectOptions = useMemo(() => teamMembers.map(teamMember => ({ ...teamMember, value: teamMember.name })), [
    teamMembers,
  ]);

  const onTeamMemberCopy = useCallback(() => {
    const email = selectedTeamMember?.email;

    if (!email) return;

    navigator.clipboard.writeText(email);
    showSuccessToast('Email successfully copied');
  }, [selectedTeamMember]);

  const handleSendEmail = useCallback(() => {
    const email = selectedTeamMember?.email;

    if (!email) return;

    sendEmail({ email, currentUser, projectId, projectName });
  }, [selectedTeamMember, currentUser, projectId, projectName]);

  return (
    <SConfidentialNoPermission>
      <NoPermission
        title="Access Restricted"
        information="To view this project, contact a team member with access to share it with you."
      >
        <TeamMemberSelect
          className={classes('team-member-select')}
          selectedOptions={selected}
          options={selectOptions}
          dropdownIdentifier={'test-identifier-team-member'}
          onOptionSelect={setSelected}
          onCopy={onTeamMemberCopy}
        />
        {selected.length > 0 ? (
          <Button className={classes('email-button')} variant="primary-cta" onClick={handleSendEmail}>
            Send email
          </Button>
        ) : (
          <div className={classes('button-plug')} />
        )}
      </NoPermission>
    </SConfidentialNoPermission>
  );
};
