import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'reporting';

export const SReporting = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;

  .${BEM_CLASS} {
    &__title {
      display: flex;
      align-items: center;
      margin: 0 40px;
    }
    &__project-name {
      font-weight: normal;
      margin-left: 5px;
    }
    &__expandable-container {
      margin: 20px 40px;
    }
    &__tabs {
      padding: 0 40px;
    }
    &__reporting-table {
      margin: 20px 40px;
    }
    &__input-data-loader {
      height: 68px;
      background: ${transparentize(0.5, manhattanThemeColors.background)};
      border-radius: 10px;
      margin: 20px 40px;
    }
  }
`;
