import { SearchField } from 'app/components/app-header/components/search-field';
import { useFeatureFlagsContext } from 'common-v2/hooks';
import { isTreatmentEnabled } from 'common-v2/transducers';
import { setGlobalSearchQuery, showGlobalSearch } from 'common/actions';
import { globalSearchSelector } from 'common/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { SGlobalSearchFieldContainer, BEM_CLASS } from './s-global-search-field-container';

interface GlobalSearchFieldContainerProps {
  className?: string;
}

const classes = bem(BEM_CLASS);

export const GlobalSearchFieldContainer = React.memo(({ className }: GlobalSearchFieldContainerProps) => {
  const dispatch = useDispatch();

  const { treatments } = useFeatureFlagsContext();

  const { isActive, query } = useSelector(globalSearchSelector);

  const isGlobalSearchV2Enabled = isTreatmentEnabled(treatments, 'GLOBAL_SEARCH_V2');
  const searchClassName = isGlobalSearchV2Enabled ? 'search' : 'old-search';
  const searchInputClassName = isGlobalSearchV2Enabled ? 'search-input' : undefined;

  const onSearchValueChange = useCallback(
    value => {
      dispatch(setGlobalSearchQuery(value));
    },
    [dispatch]
  );

  const searchFocusHandler = useCallback(() => {
    if (isActive) return;
    dispatch(showGlobalSearch());
  }, [dispatch, isActive]);

  return (
    <SGlobalSearchFieldContainer className={className}>
      <SearchField
        searchValue={query}
        data-selector="app-header-search-field"
        className={classes(searchClassName)}
        focused={isActive}
        onSearchValueChange={onSearchValueChange}
        onFocus={searchFocusHandler}
        inputClassName={classes(searchInputClassName)}
      />
    </SGlobalSearchFieldContainer>
  );
});
