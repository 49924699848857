import React from 'react';
import { BEM_CLASS, SPlatformDetails } from './s-platform-details';
import { CampaignSources } from 'backend-api/models';
import { getSourceIconName } from 'campaign/components/platform-details/transducers';
import { Icon } from 'common/components/icon';
import { bem } from 'utils/bem';

interface Props {
  source: CampaignSources;
  name?: string;
  id?: string;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const PlatformDetails = React.memo(({ source, id, name, className }: Props) => {
  const icon = getSourceIconName(source);
  return (
    <SPlatformDetails className={className}>
      <Icon name={icon} size="general" className={classes('icon')} />
      {name && (
        <>
          <div className={classes('name')}>Campaign Name: {name}</div>
          {id ? ` | ID: ${id}` : null}
        </>
      )}
    </SPlatformDetails>
  );
});
