import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import { toastsSelector } from 'common/selectors';
import { Toast } from 'common/components/toast';
import { takeRight, uniqueId } from 'lodash';

const MAX_TOASTS_TO_SHOW = 5;

const toastsContainer = document.getElementById('app_toasts');

export const Toasts = React.memo(() => {
  const toasts = useSelector(toastsSelector);

  if (toastsContainer) {
    return ReactDOM.createPortal(
      <>
        {takeRight(toasts, MAX_TOASTS_TO_SHOW).map(props => (
          <Toast key={uniqueId(props.id)} {...props} />
        ))}
      </>,
      toastsContainer
    );
  }

  return null;
});
