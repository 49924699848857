import styled, { css } from 'styled-components';
import { theme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';
import { DROPDOWN_MAX_WIDTH, DROPDOWN_MAX_HEIGHT } from '../constants';

interface SDropdownProps {
  isVisible: boolean;
}

export const BEM_CLASS = 'dropdown';

export const SDropdown = styled.div<SDropdownProps>`
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.white};
  border-radius: 10px;
  box-shadow: ${theme.shadows.default};
  max-width: ${DROPDOWN_MAX_WIDTH}px;
  max-height: ${DROPDOWN_MAX_HEIGHT}px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  .${BEM_CLASS} {
    &__scroller {
      width: 100%;
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
`;
