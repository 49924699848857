import { Id, Label, TeamUser } from 'backend-api/models';

export interface ShareModalResponse {
  added: TeamUser[];
  deleted: TeamUser[];
  updated: TeamUser[];
}

export interface SearchUser {
  id: Id;
  name: string;
  email: string;
  labels: Label[];
}

export enum Mode {
  CREATION,
  EDIT,
}

export enum ShareModalContext {
  ARTIST_TEAM,
  PROJECT_COLLABORATORS,
}

export enum WarningMessage {
  PROJECT_WITH_NO_USERS = 'Add project collaborators so they have an access to the project’s data',
  TEAM_WITHOUT_EDITORS = 'The team must have at least one editor',
  CONFIDENTIAL = 'Warning: this entire team will see this project',
}

export interface Warning {
  value: boolean;
  message: WarningMessage;
}
