import React from 'react';
import { GroupBase, Props as ReactSelectProps, SelectComponentsConfig } from 'react-select';
import { CreatableProps } from 'react-select/creatable';
import Select from 'react-select/base';
import { Nullable } from 'backend-api/models';

export interface BaseOption {
  id: number | string;
  value: string;
  label?: string;
  options?: BaseOption[];
  info?: string;
  isDisabled?: boolean;
}

export interface LinkOption extends BaseOption {
  isInvalid?: boolean;
}

export interface BaseGroup<T extends BaseOption = BaseOption> extends GroupBase<T> {
  id?: number | string;
  isFirst?: boolean;
  isLast?: boolean;
}

export enum SelectType {
  Base,
  Simple,
  Badge,
  Searchable,
  SearchableSimple,
  Tree,
}

export enum MultiSelectType {
  Base,
  AutoComplete,
}

export enum CreatableSelectType {
  Base,
  LinkInput,
}

export interface SelectMenuProps {
  isMenuOpen: boolean;
  openMenu(): void;
  closeMenu(): void;
}

export interface ClassNamesProps {
  control?: string;
  valueContainer?: { root?: string; container?: string; counter?: string; footer?: string };
  singleValue?: string;
  indicatorsContainer?: string;
  clearIndicator?: string;
  menu?: string;
  menuList?: string;
  menuPortal?: string;
  option?: { root: string; text: string; info: string };
  group?: { root: string; text: string };
  multiValueContainer?: string;
  multiValueLabel?: string;
  selectContainer?: string;
}

export enum MenuPlacement {
  TopLeft,
  TopRight,
  BottomLeft,
  BottomRight,
  Auto,
}

export interface ReactSelectPropsWithCustomProps<T, IsMulti extends boolean, P>
  extends ReactSelectBaseProps<T, IsMulti> {
  customSelectProps: P;
}

export interface BaseCustomReactSelectProps {
  handleMenu(event: React.MouseEvent<HTMLDivElement>, menuIsOpen: boolean): void;
  classNames?: ClassNamesProps;
  closeMenu(): void;
  portalPlacement: MenuPlacement;
  dataSelector?: string;
}

export interface SearchableCustomSelectProps {
  searchPlaceholder?: string;
  isSearchableFromPopup?: boolean;
  popupSearch?: string;
  popupSearchHandler?(value?: string): void;
}

export interface MultiCustomReactSelectProps extends BaseCustomReactSelectProps {
  valueContainerLabel?: string;
}

export type SelectComponents<T, IsMulti extends boolean = false> = Omit<
  SelectComponentsConfig<T, IsMulti, GroupBase<T>>,
  'MenuPortal'
>;

export type ReactSelectBaseProps<T, IsMulti extends boolean> = Omit<
  ReactSelectProps<T, IsMulti, GroupBase<T>>,
  'components'
>;

export type CreatableReactSelectBaseProps<T, IsMulti extends boolean> = Omit<
  CreatableProps<T, IsMulti, GroupBase<T>>,
  'components'
>;

export interface BaseCustomSelectProps<T extends BaseOption, IsMulti extends boolean, P> {
  classNames?: ClassNamesProps;

  isOpened?: boolean;

  popupSearch?: string;
  popupSearchHandler?(value: string): void;
  searchPlaceholder?: string;
  isSearchableFromMenu?: boolean;

  placement?: MenuPlacement;

  selectComponents?(props: SelectMenuProps): SelectComponents<T, IsMulti>;

  selectContainer?(props: BaseCustomSelectProps<T, IsMulti, P>): React.ReactChild;

  dataSelector?: string;

  customReactSelectProps?: P;

  selectRef?: React.Ref<Select<T, IsMulti>>;
}

export type DefaultSelectProps<T extends BaseOption, IsMulti extends boolean, P> = BaseCustomSelectProps<
  T,
  IsMulti,
  P
> &
  ReactSelectBaseProps<T, IsMulti>;

export type DefaultCreatableSelectProps<T extends BaseOption, IsMulti extends boolean, P> = BaseCustomSelectProps<
  T,
  IsMulti,
  P
> &
  CreatableReactSelectBaseProps<T, IsMulti>;

export interface SingleSelectProps<T extends BaseOption, P> extends DefaultSelectProps<T, false, P> {
  valueId: number | string;
  valueHandler(valueId: Nullable<number | string>): void;
}

export interface MultiSelectProps<T extends BaseOption, P> extends DefaultSelectProps<T, true, P> {
  valueIds: (number | string)[];
  valueHandler(values: (number | string)[]): void;
}

export interface CreatableSelectProps<T extends BaseOption, P> extends DefaultCreatableSelectProps<T, true, P> {
  valueHandler(values: (number | string)[]): void;
}

export interface BaseSelectProps<T extends BaseOption, P> extends SingleSelectProps<T, P> {
  type: SelectType.Base;
}

export interface SimpleSelectProps<T extends BaseOption> extends SingleSelectProps<T, never> {
  type: SelectType.Simple;
}

export interface BadgeSelectProps<T extends BaseOption> extends SingleSelectProps<T, never> {
  type: SelectType.Badge;
}

export interface SearchableSelectProps<T extends BaseOption> extends SingleSelectProps<T, SearchableCustomSelectProps> {
  type: SelectType.Searchable;
}

export interface SearchableSimpleSelectProps<T extends BaseOption> extends SingleSelectProps<T, never> {
  type: SelectType.SearchableSimple;
}

export interface TreeSelectProps<T extends BaseOption> extends SingleSelectProps<T, never> {
  type: SelectType.Tree;

  selectComponents?(props: SelectMenuProps): Omit<SelectComponents<T, false>, 'Option'>;
}

export interface BaseMultiSelectProps<T extends BaseOption, P> extends MultiSelectProps<T, P> {
  type: MultiSelectType.Base;
}

export interface AutocompleteMultiSelectProps<T extends BaseOption, P> extends MultiSelectProps<T, P> {
  type: MultiSelectType.AutoComplete;
  showMenuOnFocus?: boolean;
  minSearchLength?: number;
  maxItemsLength?: number;
  onEditFinished?(): void;
}

export interface BaseCreatableSelectProps<T extends BaseOption, P> extends CreatableSelectProps<T, P> {
  type: CreatableSelectType.Base;

  valueContainerLabel?: string;
}

export interface LinkInputMultiSelectProps<T extends LinkOption, P> extends CreatableSelectProps<T, P> {
  type: CreatableSelectType.LinkInput;

  onEditFinished?(): void;
  onInvalidLink?(): void;
  onAllValid?(): void;
  maxLength?: number;
  rawLinks: string[];
}
