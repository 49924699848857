import { ParsedQs } from 'qs';
import { AdSetsFilterParams, isAdSetsSortField } from 'backend-api/types';
import {
  CampaignCategoryAndTypes,
  CampaignDetails,
  CampaignEditableFields,
  CampaignRequest,
  CampaignSources,
  CampaignStatuses,
  GenerateCampaignName,
  Id,
  Optional,
  Phase,
  ProjectDetails,
} from 'backend-api/models';
import {
  DEFAULT_GENDERS,
  DEFAULT_PAGINATION_LIMIT,
  DEFAULT_PROVIDER,
  DEFAULT_TERRITORY,
  MAX_AGE,
  MAX_DATE_MILLIS,
  MIN_AGE,
  SHORT_DATE_REQUEST_FORMAT,
  SimpleSectionOption,
} from 'common/constants';
import { NOT_AVAILABLE } from 'common-v2/constants';
import { EMPTY_MARKDOWN } from 'common/components/markdown-editor';
import { CampaignInput } from './components/form/types';
import { DEFAULT_SORT } from './constants';
import { AdSetsFilters, GratisType } from './types';
import { Sort } from 'utils/sort';
import { Period } from 'common/types';
import { mapLinkfireSearchToBaseOption } from 'common/transducers';
import { isEmpty } from 'lodash';
import { DecibelLink } from 'utils/decibel-link';
import { getStartOfDay, getEndOfDay, getFormattedDate } from 'common-v2/utils';

const prepareCampaignBudgetRequestValue = (budget?: number | string): number => {
  if (!budget || budget === NOT_AVAILABLE) return 0;

  if (typeof budget === 'number') return budget;

  const parsedBudget = parseInt(budget, 10);

  if (isNaN(parsedBudget)) return 0;

  return parsedBudget;
};

const prepareIdArray = (array?: Id[]) => (isEmpty(array) ? undefined : array);

export const prepareRequest = (values: CampaignInput): CampaignRequest => ({
  name: values.name || '',
  territories: prepareIdArray(values.territory),
  startDate: values?.startDate || new Date(),
  endDate: values?.endDate || new Date(),
  budgetSpend: values.budgetSpend || 0,
  plannedBudget: prepareCampaignBudgetRequestValue(values.plannedBudget),
  campaignType: values?.type,
  platforms: values?.platforms || [],
  gratis: values?.gratis === GratisType.Gratis,
  objective: values.objective,
  notes: values.notes,
  provider: values.provider,
  genders: prepareIdArray(values.genders),
  audienceLowerAge: values.audienceAge?.[0] || 0,
  audienceHigherAge: values.audienceAge?.[1] || 0,
  destinationLinks: values.destinations,
  linkfireLinks:
    values.linkfireLinks?.map(option => ({
      id: option.modelId,
      linkId: option.linkId,
      linkUrl: option.linkUrl,
      deletable: option.isFixed,
    })) || [],
  adCreativeLinks: values.creativeLinks,
  adCreativeNotes: values.copy,
  kpiMetricsFieldId: values.kpi,
  workflowStatus: values.status,
  audienceNotes: values.details,
  ecpm: values.ecpm,
  placements: values.placements,
});

export const prepareAutogenerateNameParams = (values: CampaignInput): GenerateCampaignName => ({
  startDate: values.startDate ? getFormattedDate(SHORT_DATE_REQUEST_FORMAT)(values.startDate) : undefined,
  platforms: values.platforms,
  provider: values.provider,
  objective: values.objective,
  territories: prepareIdArray(values.territory),
  placements: values.placements,
  campaignType: values.type,
});

export const prepareCampaignBudgetValue = (source: CampaignSources, budget?: number): Optional<string | number> => {
  if (source === CampaignSources.FACEBOOK) {
    return NOT_AVAILABLE;
  }

  return budget;
};

export const prepareInitialValues = (values: CampaignDetails): CampaignInput => {
  const audienceAge = values.audienceAge && [
    values.audienceAge.lowerAge || MIN_AGE,
    values.audienceAge.higherAge || MAX_AGE,
  ];

  return {
    name: values.name,
    type: values.type?.id,
    platforms: values.platforms.map(platform => platform.id),
    gratis: values.gratis ? GratisType.Gratis : GratisType.Paid,
    startDate: values.startDate,
    endDate: values.endDate,
    audienceAge,
    provider: values.provider?.id,
    genders: values.genders?.map(gender => gender.id),
    territory: values?.territories?.map(({ id }) => id) || [],
    budgetSpend: values.budgetSpend,
    plannedBudget: values.plannedBudget,
    objective: values.objective?.id,
    notes: values.notes || EMPTY_MARKDOWN,
    destinations: values.destinationLinks,
    linkfireLinks: mapLinkfireSearchToBaseOption(values.linkfireLinks || []),
    kpi: values.kpiMetricsFieldId,
    status: values.workflowStatus?.status,
    copy: values.adCreativeNotes,
    creativeLinks: values.adCreativeLinks ? values.adCreativeLinks : undefined,
    details: values.audienceNotes,
    ecpm: values.ecpm,
    placements: values.placements?.map(placement => placement.id),
  };
};

export const getInitialValues = (campaign?: CampaignDetails): CampaignInput => {
  if (!campaign) {
    return {
      territory: [DEFAULT_TERRITORY],
      audienceAge: [MIN_AGE, MAX_AGE],
      provider: DEFAULT_PROVIDER,
      genders: DEFAULT_GENDERS,
      gratis: GratisType.Paid,
      status: CampaignStatuses.DRAFT,
      plannedBudget: 0,
    };
  }

  return prepareInitialValues(campaign);
};

export const getCampaignEditableFields = (campaign: CampaignDetails | undefined): CampaignEditableFields => {
  const defaultEditableFields = {
    name: true,
    platforms: true,
    provider: true,
    objective: true,
    budget: true,
    spend: true,
    startDate: true,
    endDate: true,
    genders: true,
    audienceAge: true,
    countries: true,
    destinations: true,
    notes: true,
  };

  if (!campaign) return defaultEditableFields;

  return {
    ...defaultEditableFields,
    ...campaign.metadata.fields,
  };
};

export const getCampaignAvailableStartDates = (endDate?: Date, project?: ProjectDetails, phase?: Phase): Period => {
  const fromDate = getStartOfDay(phase?.start_date) || getStartOfDay(project?.startDate) || new Date(0);

  const toDate =
    getEndOfDay(endDate) || getEndOfDay(phase?.end_date) || getEndOfDay(project?.endDate) || new Date(MAX_DATE_MILLIS);

  return [fromDate, toDate];
};

export const getCampaignAvailableEndDates = (startDate?: Date, project?: ProjectDetails, phase?: Phase): Period => {
  const fromDate =
    getStartOfDay(startDate) || getStartOfDay(phase?.start_date) || getStartOfDay(project?.startDate) || new Date(0);

  const toDate = getEndOfDay(phase?.end_date) || getEndOfDay(project?.endDate) || new Date(MAX_DATE_MILLIS);

  return [fromDate, toDate];
};

export const getAdSetsFiltersParamsFromSearchQuery = (query: ParsedQs): AdSetsFilterParams => {
  const freeText = typeof query.freeText === 'string' ? query.freeText : undefined;
  const limit = typeof query.limit === 'string' ? parseInt(query.limit, 10) : undefined;
  const offset = typeof query.offset === 'string' ? parseInt(query.offset, 10) : undefined;
  const sort =
    typeof query.sort === 'string' && Sort.create(query.sort, isAdSetsSortField)
      ? query.sort
      : DEFAULT_SORT.getString();

  return {
    freeText,
    limit: limit || DEFAULT_PAGINATION_LIMIT,
    offset: offset || 0,
    sort,
  };
};

export const getAdSetsFiltersFromParams = (params: AdSetsFilterParams): AdSetsFilters => {
  const sort = params.sort && Sort.create(params.sort, isAdSetsSortField);

  return {
    search: params.freeText,
    limit: params.limit,
    offset: params.offset,
    sort: sort || DEFAULT_SORT,
  };
};

export const getAdSetsFiltersParamsFromFilters = (filters: AdSetsFilters): AdSetsFilterParams => {
  return {
    freeText: filters.search,
    offset: filters.offset,
    limit: filters.limit,
    sort: filters.sort?.getString(),
  };
};

export const isCampaignIncomplete = (campaign: Optional<CampaignDetails>): boolean => {
  if (!campaign) return false;

  return (
    !campaign.name ||
    !campaign.plannedBudget ||
    !campaign.provider ||
    !campaign.objective ||
    !campaign.startDate ||
    !campaign.endDate ||
    !campaign.genders ||
    !campaign.territories ||
    !campaign.audienceAge
  );
};

export const isCampaignIncompleteAndExternal = (campaign: Optional<CampaignDetails>): boolean => {
  if (!campaign) return false;

  return isCampaignIncomplete(campaign) && campaign.source !== CampaignSources.MANUAL;
};

export const isCampaignEditable = (campaign?: CampaignDetails) => {
  if (!campaign) return false;

  const hasEditableFields = Object.values(campaign.metadata.fields || {}).includes(true);
  const isNotPending = !campaign.isPending;
  const isNotRejected = campaign.projectId != null;
  return isNotPending && isNotRejected && hasEditableFields;
};

export const getCampaignsTypesOptions = (campaignCategories?: CampaignCategoryAndTypes[]): SimpleSectionOption[] => {
  if (!campaignCategories) {
    return [];
  }

  return campaignCategories.map(category => ({
    id: category.id,
    label: category.name,
    options: category.campaignTypes.sort((a, b) => a.name.localeCompare(b.name)),
  }));
};

export const prepareValidLinksForDisplay = (links: string[]): string[] =>
  links.filter(item => {
    try {
      new DecibelLink(item);
      return true;
    } catch (e) {
      return false;
    }
  });
