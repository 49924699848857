import styled from 'styled-components';

export const BEM_CLASS = 'objective-editor';

export const SObjectiveEditor = styled(({ className, children }) => children(className))`
  overflow: hidden;
  .${BEM_CLASS} {
    &__select-container {
      height: 100%;
    }
    &__control {
      width: 100%;
      height: 100%;
      min-height: 0;
      border-radius: 0;
    }

    &__menu {
      border-radius: 10px;
    }

    &__value-container-root {
      height: 100%;
      padding: 0;
    }

    &__value-container {
      padding: 8px var(--cell-horizontal-padding);
    }

    &__indicators-container {
      height: 100%;
      overflow: hidden;
      padding-right: var(--cell-horizontal-padding);
      padding-left: var(--cell-horizontal-padding);
    }
  }
`;
