import styled from 'styled-components';
import { appearingAnimation } from 'common/s-components/s-appearing-animation';
import { motion } from 'framer-motion';

interface Props {
  shouldAnimate?: boolean;
  shouldExtendBottomOffset?: boolean;
}

export const SArtistPlaylistWidget = styled(motion.div)<Props>`
  border-radius: 10px;
  background-color: white;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ shouldExtendBottomOffset }) => (shouldExtendBottomOffset ? 37.5 : 20)}px;

  .artist-playlist-widget {
    &__title {
      color: black;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 30px;
      margin-left: 30px;
    }
    &__targets-container,
    &__artists-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      padding: 0 30px;

      & > :not(:last-child) {
        flex: 1;
        margin-bottom: 10px;
      }

      & > * {
        ${({ shouldAnimate }) => (shouldAnimate ? appearingAnimation() : '')};
      }
    }
    &__artists-container {
      margin-top: 29px;
    }
    &__button {
      transform: translate(0, 50%);
      align-self: center;
      margin-top: -7px;
    }
    &__tabs {
      .tabs__top-container {
        padding: 0 30px;
      }
    }
    &__empty-container {
      min-height: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(14, 16, 26, 1);
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 25px;
      text-align: center;
    }
  }
`;
