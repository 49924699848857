import { ParsedQs } from 'qs';
import { isRight } from 'fp-ts/lib/These';
import * as t from 'io-ts';

export const decodeQueryString = <T extends t.Any>(query: ParsedQs, schema: T) => {
  const decode = schema.decode(query);
  if (!isRight(decode)) {
    throw Error('parse error');
  }
  return decode.right;
};
