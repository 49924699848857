import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Label, LabelPermissionsArtistWithMembers } from 'backend-api/models';
import { ReusableTable, EmptyViewMode } from 'label-permissions/components';
import { getLabelArtistsList, openEditMembersModal } from 'label-permissions/actions';
import { artistsSelector } from 'label-permissions/selectors';
import { buildTableConfig } from './transducers';
import { useArtists } from './hooks';
import { EditMembersModalPayload } from 'label-permissions/types';
import { EditMembersContainer } from '../edit-members-container';
import { useAppContext } from 'common-v2/hooks';

interface ArtistsTableProps {
  search: string;
  label?: Label;
}

export const ArtistsTableContainer = React.memo(({ search, label }: ArtistsTableProps) => {
  const dispatch = useDispatch();
  const { user: currentUser } = useAppContext();
  const { artists, isSearchActive } = useArtists(search);

  const { loading: artistsLoading } = useSelector(artistsSelector);

  const fetchTableData = useCallback(() => {
    if (!label?.id) return;
    dispatch(getLabelArtistsList.request(label.id));
  }, [dispatch, label?.id]);

  const openEditMembers = useCallback(
    (payload: EditMembersModalPayload) => {
      dispatch(openEditMembersModal(payload));
    },
    [dispatch]
  );

  const getTableConfig = useCallback(
    (emptyViewMode: EmptyViewMode) =>
      buildTableConfig({
        isSortingDisabled: emptyViewMode !== EmptyViewMode.None,
        openEditMembersModal: openEditMembers,
        user: currentUser,
      }),
    [currentUser, openEditMembers]
  );

  return (
    <>
      <ReusableTable<LabelPermissionsArtistWithMembers>
        data={artists}
        loading={artistsLoading}
        getTableConfig={getTableConfig}
        fetchData={fetchTableData}
        isSearchActive={isSearchActive}
        errorMessage={
          <span>
            An error occurred while loading the
            <br />
            Artists data, please try again.
          </span>
        }
        emptyMessage="There are no artists in this label."
      />
      <EditMembersContainer onDataUpdated={fetchTableData} />
    </>
  );
});
