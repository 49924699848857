import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { transparentize } from 'polished';

export const BEM_CLASS = 'artist-item';

export const SArtistItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  border-radius: 10px;
  cursor: pointer;

  .${BEM_CLASS} {
    &__info-container {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &__image {
      margin-left: 7.5px;
      height: 30px;
      width: 30px;
      border-radius: 10px;
      overflow: hidden;
    }

    &__name {
      ${elipsizeText}
      font-size: 14px;
      line-height: 25px;
    }

    &__delete {
      display: none;
      flex-shrink: 0;
      margin-left: 5px;
      margin-right: 20px;
      background: ${transparentize(0.95, manhattanThemeColors.hoveredBorderColor)};

      &:hover {
        background: ${transparentize(0.9, manhattanThemeColors.hoveredBorderColor)};
      }
    }
  }

  &:hover {
    background: ${transparentize(0.95, manhattanThemeColors.hoveredBorderColor)};

    .${BEM_CLASS} {
      &__delete {
        display: block;
      }
    }
  }
`;
