import React from 'react';
import { SFieldTooltip } from './s-field-tooltip';
import { Icon } from '../../icon';
import { bem } from 'utils/bem';
import { Tooltip } from '../../tooltip';

interface Props {
  className?: string;
  dataSelector?: string;
  tooltipContent: React.ReactChild;
}

const classes = bem('field-tooltip');

export const FieldTooltip = React.memo(({ className, dataSelector, tooltipContent }: Props) => {
  return (
    <SFieldTooltip className={className}>
      <Tooltip content={tooltipContent}>
        <div data-selector={`${dataSelector}-icon`} className={classes('info')}>
          <Icon name="info" color="pink" size="projects" />
        </div>
      </Tooltip>
    </SFieldTooltip>
  );
});
