import { Artist } from 'backend-api/models';
import { ArtistAvatarAndName } from 'common-v2/components';
import { isArtistLocked } from 'common-v2/transducers';
import { Icon, ICON_SIZE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { SArtistItem, BEM_CLASS } from './s-artist-item';

interface ArtistItemProps {
  artist: Artist;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ArtistItem = React.memo(({ artist, className }: ArtistItemProps) => (
  <SArtistItem className={className} data-selector="project-form-artists-item">
    <ArtistAvatarAndName key={artist.id} name={artist.name} imageUrl={artist.image?.url} />
    {isArtistLocked(artist) && (
      <Icon
        className={classes('icon')}
        size={ICON_SIZE.medium}
        name="locked-artist"
        dataAttributes={{ 'data-selector': 'locked-artist-icon' }}
      />
    )}
  </SArtistItem>
));
