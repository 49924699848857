import { Nullable } from 'backend-api/models';
import { Button, BUTTON_TYPE, THEME, Typography, TYPOGRAPHY_TYPE, Icon, ICON_SIZE } from 'gdb-web-shared-components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { bem } from 'utils/bem';
import { pluralizeString } from 'utils/string';
import { BEM_CLASS, SConfirmReviewFooter } from './s-confirm-review-footer';
import { LoadingState } from 'common/types';

const classes = bem(BEM_CLASS);

interface ConfirmReviewProps {
  selectedCount: number;
  allCount: number;
  onConfirm(): void;
  approvalRequestLoading: LoadingState;
}

interface ConfirmReviewWidgetConfig {
  info: Nullable<JSX.Element>;
  button: Nullable<JSX.Element>;
}

export const ConfirmReviewFooter = React.memo(
  ({ selectedCount, allCount, onConfirm, approvalRequestLoading }: ConfirmReviewProps) => {
    const [isWarningVisible, setIsWarningVisible] = useState(false);

    const handleConfirm = useCallback(() => {
      if (selectedCount !== 0) {
        onConfirm();
        return;
      }

      setIsWarningVisible(true);
    }, [onConfirm, selectedCount]);

    const widgetConfig = useMemo<ConfirmReviewWidgetConfig>(() => {
      if (isWarningVisible) {
        return {
          info: (
            <div className={classes('warning-container')}>
              <Icon className={classes('warning-icon')} size={ICON_SIZE.medium} name="warning" />

              <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('empty-review-text')}>
                Please review campaign(s) to continue.
              </Typography>
            </div>
          ),
          button: (
            <Button
              type={BUTTON_TYPE.primary}
              theme={THEME.light}
              onClick={handleConfirm}
              caption="Confirm"
              data-selector="media-plan-confirm-review-button"
            />
          ),
        };
      }

      return {
        info: (
          <Typography type={TYPOGRAPHY_TYPE.body4}>
            <b>{selectedCount}</b> out of <b>{allCount}</b>
            {` ${pluralizeString('campaign', allCount)} reviewed`}
          </Typography>
        ),
        button: (
          <Button
            type={BUTTON_TYPE.primary}
            theme={THEME.light}
            onClick={handleConfirm}
            caption="Confirm"
            isLoading={approvalRequestLoading === LoadingState.Started}
            data-selector="media-plan-confirm-review-button"
          />
        ),
      };
    }, [allCount, approvalRequestLoading, handleConfirm, isWarningVisible, selectedCount]);

    useEffect(() => {
      if (selectedCount > 0) setIsWarningVisible(false);
    }, [selectedCount]);

    return (
      <SConfirmReviewFooter>
        <div className={classes('info')} data-selector="confirm-review-footer-info">
          {widgetConfig.info}
        </div>

        <div className={classes('button-container')}>{widgetConfig.button}</div>
      </SConfirmReviewFooter>
    );
  }
);
