import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';
import { MANHATTAN_HEADER_HEIGHT } from 'common/constants';

export const BEM_CLASS = 'manhattan-header';

interface SAppHeaderProps {
  isNewUIEnabled: boolean;
}

export const SAppHeader = styled.div<SAppHeaderProps>`
  .${BEM_CLASS} {
    &__wrapper {
      position: relative;
      height: ${MANHATTAN_HEADER_HEIGHT}px;
      background: ${({ isNewUIEnabled }) =>
        transparentize(isNewUIEnabled ? 0.1 : 0.5, manhattanThemeColors.background)};
      border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
      box-shadow: 0px 5px 40px ${transparentize(0.8, manhattanThemeColors.shadowColor)};
    }

    &__items {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      padding: 0 40px;
      justify-content: space-between;
    }

    &__search-container {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 0 40px;
    }

    &__left-container {
      display: flex;
      align-items: center;
    }

    &__divider {
      width: 1px;
      height: 30px;
      margin: 0 20px;
      border-left: 1px solid ${manhattanThemeColors.primaryDark};
      opacity: 0.1;
    }

    &__label-selector {
      flex-shrink: 0;
      margin-right: 20px;
    }

    &__right-container {
      display: flex;
      align-items: center;
    }

    &__search-button {
      margin-right: 10px;
      background-color: unset;

      :hover {
        background-color: unset;
      }

      :focus {
        outline: none;
      }
    }

    &__user-dropdown {
      color: ${manhattanThemeColors.background};
    }
  }
`;
