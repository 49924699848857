import { Skeleton } from 'common/components/skeleton';
import React from 'react';

interface Props {
  className?: string;
}

export const Loader = React.memo(({ className }: Props) => (
  <Skeleton width={110} height={65} className={className}>
    <rect x="0" y="0" rx="0" ry="0" width={110} height={15} />
    <rect x="0" y="25" rx="0" ry="0" width={110} height={15} />
    <rect x="0" y="50" rx="0" ry="0" width={110} height={15} />
  </Skeleton>
));
