import { H3 } from 'common/components/typography';
import React, { useMemo } from 'react';
import { BEM_CLASS, SSettingsHeader } from './s-settings-header';
import { Tab, Tabs } from 'common/components/tabs';
import { convertToLowerKebabCase } from 'utils/string';
import { SettingsTab, SettingsTabs } from 'settings/types';
import { bem } from 'utils/bem';

interface Props {
  tabs: SettingsTab[];
  onTabChange(tab: SettingsTabs): void;
  activeTab: SettingsTabs;
}

const classes = bem(BEM_CLASS);

export const SettingsHeader = React.memo(({ activeTab, onTabChange, tabs }: Props) => {
  const activeSettingsTab = useMemo(() => tabs.find(tab => tab.title === activeTab), [activeTab, tabs]);

  return (
    <SSettingsHeader data-selector="settings-header-tabs-container">
      <div className={classes('header')} data-selector="settings-header-info-container">
        <H3 data-selector="settings-title">Settings</H3>
        <div>
          <Tabs activeTab={activeTab} onChange={onTabChange} shouldHideDivider>
            {tabs.map(({ title }, index) => (
              <Tab key={index} title={title} dataSelector={`${convertToLowerKebabCase(title)}-settings-tab`}>
                <></>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
      <div className={classes('tabs')}>{activeSettingsTab?.component}</div>
    </SSettingsHeader>
  );
});
