import * as React from 'react';
import { SRow } from 'common/s-components/layout/s-row';
import { SBox } from 'common/s-components/layout/s-box';
import { SCol } from 'common/s-components/layout/s-col';
import { SSpacer } from 'common/s-components/layout/s-spacer';
import { SLabel } from 'common/components/form/cell/s-label';
import { SValue } from 'common/s-components/s-value';
import { bem } from 'utils/bem';
import { FieldTooltip } from '../field-tooltip';
import { Tooltip } from '../../tooltip';

interface Props {
  editable?: boolean;
  label?: React.ReactNode;
  children: React.ReactNode;
  shouldShowTooltip?: boolean;
  tooltipContent?: React.ReactChild;
  labelTooltipContent?: React.ReactChild;
  className?: string;
  noPaddings?: boolean;
  dataSelector?: string;
  error?: string;
  direction?: 'row' | 'column';
  wide?: boolean;
}

const classes = bem('form-field-cell');

export const Cell = React.memo(
  ({
    editable = false,
    label = false,
    error,
    children,
    direction = 'row',
    className,
    noPaddings,
    dataSelector,
    shouldShowTooltip,
    tooltipContent,
    labelTooltipContent,
  }: Props) => {
    const isRow = direction === 'row';
    const expectedBottomPadding = editable ? '15px' : '10px';
    const actualBottomPadding = noPaddings ? '0' : expectedBottomPadding;
    const labelWidth = isRow ? 170 : 1;

    return (
      <SRow className={className} pb={actualBottomPadding} flexDirection={direction}>
        {label && (
          <SCol width={labelWidth}>
            <SRow>
              <SLabel editable={editable} className={classes('label')} data-selector={`${dataSelector}-label`}>
                <Tooltip content={labelTooltipContent} enabled={!!labelTooltipContent}>
                  {label}
                </Tooltip>
              </SLabel>
              {shouldShowTooltip && tooltipContent && (
                <FieldTooltip className={classes('tooltip')} tooltipContent={tooltipContent} />
              )}
            </SRow>
          </SCol>
        )}
        <SSpacer maxWidthOffset={label ? labelWidth : 0}>
          <SValue
            data-selector={`${dataSelector}-container`}
            line-height={editable ? 1 : '20px'}
            className={classes('field')}
          >
            {children}
            {error && (
              <SBox
                data-selector={`${dataSelector}-error`}
                mx="20px"
                mt="10px"
                color="#d92121"
                font-size="12px"
                className={classes('error')}
              >
                {error}
              </SBox>
            )}
          </SValue>
        </SSpacer>
      </SRow>
    );
  }
);
