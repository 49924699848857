import React from 'react';
import { AppContext } from 'common-v2/context';
import { IAppContext } from 'common-v2/types';

export const useAppContext = () => {
  const context = React.useContext<IAppContext | undefined>(AppContext);

  if (!context) {
    throw new Error('useAppContext must be used within a Provider');
  }

  return context;
};
