import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Confirm } from 'common/components/confirm';
import { Icon } from 'common/components/icon';
import { Button } from 'common/components/button';
import { Tooltip } from 'common/components/tooltip';
import { SModalTitle } from 'common/s-components/s-modal-title';
import { canEditOrRemoveMoment } from 'common/transducers';
import { isConfirmSelector, processingSelector } from 'artist-moment/selectors';
import { deleteMoment, showConfirmModal } from 'artist-moment/actions';
import { MomentData } from 'artist-moment/types';
import { useAppContext } from 'common-v2/hooks';

const MODAL_NAME = 'CONFIRM_MOMENT_DELETE';

export const RemoveButton = React.memo(({ project, moment }: MomentData) => {
  const dispatch = useDispatch();

  const isBeingProcessed = useSelector(processingSelector);
  const isConfirmModalOpened = useSelector(isConfirmSelector);
  const { user: currentUser } = useAppContext();

  const showModal = useCallback(() => {
    dispatch(
      showConfirmModal({
        id: MODAL_NAME,
        isOpened: !isConfirmModalOpened,
      })
    );
  }, [dispatch, isConfirmModalOpened]);

  const hideModal = useCallback(() => {
    dispatch(
      showConfirmModal({
        id: MODAL_NAME,
        isOpened: false,
      })
    );
  }, [dispatch]);

  const deleteHandler = useCallback(() => {
    if (!isBeingProcessed) {
      dispatch(
        deleteMoment.request({
          projectId: project.id,
          momentId: moment.id,
        })
      );

      hideModal();
    }
  }, [isBeingProcessed, moment.id, dispatch, project.id, hideModal]);

  const canRemoveMoment = canEditOrRemoveMoment(project, moment, currentUser);

  const tooptipContent = useMemo(
    () => (
      <>
        <div>You have no rights to remove this moment.</div>
        <div>Please contact project editor.</div>
      </>
    ),
    []
  );

  return (
    <>
      <Tooltip content={tooptipContent} enabled={!canRemoveMoment}>
        <span>
          <Button
            variant="secondary-white"
            onClick={showModal}
            disabled={!canRemoveMoment || isBeingProcessed}
            dataSelector="moment-delete-button"
          >
            <Icon name="delete" />
            Remove
          </Button>
        </span>
      </Tooltip>
      <Confirm
        visible={isConfirmModalOpened}
        onOk={deleteHandler}
        okText="Remove"
        title={
          <SModalTitle>
            <br />
            Are you sure you want to remove this moment?
          </SModalTitle>
        }
        onCancel={hideModal}
      />
    </>
  );
});
