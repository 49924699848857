import styled from 'styled-components';
import Checked from 'assets/Checked.svg';
import Unchecked from 'assets/Unchecked.svg';
import Indeterminate from 'assets/Indeterminate.svg';
import { elipsizeText } from './s-elipsize-text';

interface SCheckboxProps {
  readonly isDisabled: boolean;
}

export const SCheckbox = styled.div<SCheckboxProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  .checkbox {
    &__label {
      opacity: ${props => (props.isDisabled ? 0.5 : 1)};
      line-height: 16px;
      font-weight: 400;
      ${elipsizeText}
      &--has-margin {
        margin-left: 10px;
      }
    }
  }
`;

interface SCheckboxInputProps {
  readonly isChecked: boolean;
  readonly halfChecked: boolean;
  readonly isDisabled: boolean;
}

export const SCheckboxInput = styled.input<SCheckboxInputProps>`
  border: none;
  background: url(${props => {
    if (props.halfChecked) {
      return Indeterminate;
    }
    return props.isChecked ? Checked : Unchecked;
  }});
  outline: none;
  background-repeat: no-repeat;
  background-size: contain;
  height: 16px;
  width: 16px;
  pointer-events: none;
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
`;
