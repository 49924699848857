import { BudgetGroup, BudgetGroupCategory, Id } from 'backend-api/models';
import { BaseGroup } from 'common/components/select';
import { ALL_OPTION_ID } from 'common/components/form/select/constants';
import { formatShortenPercent, getShortCurrencyFormatted } from 'utils/format';
import { isNumber } from 'lodash';

export const getBudgetGroupsOptions = (budgetGroups: BudgetGroup[]): BaseGroup[] => {
  const groupOptions = budgetGroups
    .sort((a, b) => b.budget - a.budget)
    .map(group => ({
      id: group.id,
      value: group.title,
    }));

  return [
    {
      options: [
        {
          id: ALL_OPTION_ID,
          value: 'All Groups',
        },
      ],
      isFirst: true,
    },
    { options: groupOptions, isLast: true },
  ];
};

export const getBudgetInfoItems = (
  budgetGroups: BudgetGroup[],
  selectedGroupId: Id
): BudgetGroup[] | BudgetGroupCategory[] => {
  if (selectedGroupId === ALL_OPTION_ID) {
    return budgetGroups.sort((a, b) => b.budget - a.budget);
  }

  return budgetGroups.find(group => group.id === selectedGroupId)?.categories.sort((a, b) => b.budget - a.budget) || [];
};

export const formatBudgetInfoPercentage = (first?: number, second?: number) =>
  isNumber(first) && isNumber(second) ? formatShortenPercent(first / second) : 'N/A';

export const formatBudgetInfoShortenCurrency = (currency: string, number?: number) =>
  isNumber(number) ? getShortCurrencyFormatted(number, currency) : 'N/A';
