import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { Id } from 'backend-api/models';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { ALL_OPTION_ID } from 'common/components/form/select';
import { useProjectsFilters } from 'projects/hooks';
import {
  artistRosterSelector,
  currentLabelSelector,
  expandedLiveArtistIdSelector,
  expandedUpcomingArtistIdSelector,
} from 'projects/selectors';
import {
  getMyArtistRoster,
  getMyArtistsDropdownLabelsOptions,
  isMyArtistRosterEmpty,
  sortArtistRoster,
} from './transducers';
import { BEM_CLASS, SArtistRoster } from './s-artist-roster';
import { getArtistRoster, resetExpansions, setLiveExpandedId, setUpcomingExpandedId } from 'projects/actions';
import { ArtistRoster } from 'projects/components/artist-roster';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { Select, SelectType } from 'common/components/select';
import { useAppContext } from 'common-v2/hooks';

const classes = bem(BEM_CLASS);

export const ArtistRosterContainer = React.memo(() => {
  const { labels } = useAppContext();
  const [filters, filtersDispatcher] = useProjectsFilters();
  const dispatch = useDispatch();

  const currentLabel = useSelector(currentLabelSelector);
  const expandedLiveArtistId = useSelector(expandedLiveArtistIdSelector);
  const expandedUpcomingArtistId = useSelector(expandedUpcomingArtistIdSelector);

  useEffect(() => {
    currentLabel && dispatch(getArtistRoster.request(currentLabel));
  }, [currentLabel, dispatch]);

  const onLiveArtistExpand = useCallback((id?: string) => dispatch(setLiveExpandedId(id)), [dispatch]);
  const onUpcomingArtistExpand = useCallback((id?: string) => dispatch(setUpcomingExpandedId(id)), [dispatch]);

  const handleLabelSelect = useCallback(
    (rosterLabelId: Id) => {
      const isAllOptions = rosterLabelId === ALL_OPTION_ID;
      const id = isAllOptions ? undefined : rosterLabelId;

      if (!isAllOptions) {
        trackEvent(AnalyticsEvents.ALL_LABEL_ARTISTS_VIEWED);
      }

      dispatch(resetExpansions());

      filtersDispatcher.setFilters({
        rosterLabelId: id,
      });
    },
    [filtersDispatcher, dispatch]
  );

  const rosterLabelId = filters.rosterLabelId || ALL_OPTION_ID;

  const labelArtistRoster = useSelector(artistRosterSelector);

  const myArtistRoster = useMemo(() => getMyArtistRoster(labelArtistRoster), [labelArtistRoster]);

  const artistRosterToDisplay = useMemo(() => {
    const artistRoster = filters.rosterLabelId === undefined ? myArtistRoster : labelArtistRoster;
    return { ...artistRoster, data: sortArtistRoster(artistRoster.data) };
  }, [filters.rosterLabelId, labelArtistRoster, myArtistRoster]);

  const disableMyRosterOption = useMemo(() => isMyArtistRosterEmpty(labelArtistRoster), [labelArtistRoster]);

  const rosterLabelOptions = useMemo(
    () => getMyArtistsDropdownLabelsOptions(disableMyRosterOption, labels, currentLabel),
    [disableMyRosterOption, labels, currentLabel]
  );

  const selectClassNames = useMemo(
    () => ({
      control: classes('control'),
      singleValue: classes('title'),
    }),
    []
  );

  useEffect(() => {
    disableMyRosterOption &&
      filtersDispatcher.setFilters({
        rosterLabelId: currentLabel,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableMyRosterOption]);

  return (
    <SArtistRoster heightFromChildren>
      <ResponsiveContainer>
        <Select
          type={SelectType.Simple}
          valueId={rosterLabelId}
          valueHandler={handleLabelSelect}
          options={rosterLabelOptions}
          classNames={selectClassNames}
        />
      </ResponsiveContainer>
      <ArtistRoster
        artistRosterData={artistRosterToDisplay}
        expandedLiveArtistId={expandedLiveArtistId}
        expandedUpcomingArtistId={expandedUpcomingArtistId}
        onLiveArtistExpand={onLiveArtistExpand}
        onUpcomingArtistExpand={onUpcomingArtistExpand}
      />
    </SArtistRoster>
  );
});
