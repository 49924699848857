import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Badge, BADGE_TYPE, Icon, ICON_SIZE, Tooltip, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { ProjectDetails } from 'backend-api/models';
import { paths } from 'app/routing/paths';
import { getPeriodFormatted } from 'common-v2/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { bem } from 'utils/bem';
import placeholderPng from 'assets/user.png';
import { Image, TooltipedText } from 'common-v2/components';
import { getPrimaryTargets, getProjectAvatarUrl, getProjectName } from 'common-v2/transducers';
import { SDetails, BEM_CLASS } from './s-details';
import { ProjectId } from './components';
import { NOT_AVAILABLE } from 'common-v2/constants';
import { EXPAND_ANIMATION } from './constants';

interface DetailsProps {
  className?: string;
  dateFormat: string;
  data: ProjectDetails;
  isEditing: boolean;
  controls: React.ReactNode;
}

const classes = bem(BEM_CLASS);

export const Details = React.memo(
  ({ className, dateFormat, data, isEditing, controls, children }: React.PropsWithChildren<DetailsProps>) => {
    const primaryTargets = useMemo(() => getPrimaryTargets(data.targets.items), [data.targets.items]);
    const avatarUrl = useMemo(() => getProjectAvatarUrl(primaryTargets), [primaryTargets]);
    const projectName = useMemo(() => getProjectName(data), [data]);

    return (
      <SDetails className={className}>
        <div className={classes('info')}>
          <Image
            className={classes('image')}
            imageUrl={avatarUrl}
            alt="Project image"
            placeholderUrl={placeholderPng}
            dataSelector="details-image"
          />

          <div className={classes('container')}>
            <div className={classes('content')}>
              <div className={classes('top-container')}>
                <div className={classes('title_and_artists')}>
                  <div className={classes('title')}>
                    <Typography
                      className={classes('title-text')}
                      type={TYPOGRAPHY_TYPE.heading1}
                      element="h1"
                      data-selector="details-title-text"
                    >
                      <TooltipedText text={projectName}>
                        <span>{projectName}</span>
                      </TooltipedText>
                    </Typography>

                    {data.isConfidential && (
                      <div className={classes('title-icon')}>
                        <Tooltip tooltip="Confidential Project">
                          <Icon
                            name="lock-outlined"
                            size={ICON_SIZE.medium}
                            dataAttributes={{ 'data-selector': 'confidential-project-icon' }}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>

                  <div className={classes('artists')}>
                    <Typography type={TYPOGRAPHY_TYPE.body2}>By</Typography>

                    {primaryTargets.map(({ id, name }) => (
                      <Link key={id} className={classes('artist-link')} to={paths.artist(id)}>
                        <Typography type={TYPOGRAPHY_TYPE.body1} data-selector="details-artist">
                          {name}
                        </Typography>
                      </Link>
                    ))}
                  </div>
                </div>
                <div className={classes('controls-container')}>{controls}</div>
              </div>

              <div className={classes('meta')}>
                {data.label && (
                  <div className={classes('meta-column')}>
                    <Badge type={BADGE_TYPE.quinary} content={data.label.name} data-selector="details-label" />
                  </div>
                )}

                <div className={classes('meta-column', { isHidden: isEditing })}>
                  <Typography type={TYPOGRAPHY_TYPE.body4} data-selector="details-date">
                    <span className={classes('meta-label')}>Date</span>
                    {getPeriodFormatted(dateFormat)([data.startDate, data.endDate]) || NOT_AVAILABLE}
                  </Typography>
                </div>

                {data.grasData && (
                  <div className={classes('meta-column', { isHidden: isEditing })}>
                    <ProjectId id={data.grasData.id} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <AnimatePresence exitBeforeEnter>
          {isEditing && <motion.div {...EXPAND_ANIMATION}>{children}</motion.div>}
        </AnimatePresence>
      </SDetails>
    );
  }
);
