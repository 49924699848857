import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const SNoResults = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 255px;
  .no-results {
    &__message {
      height: 50px;
      width: 350px;
      color: ${palette.darkBlue};
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
      margin-top: 10px;
    }
  }
`;
