import { createSelector } from 'reselect';
import { LabelPermissionsState } from './reducer';

export const labelPermissionsStateSelector = state => state.labelPermissions as LabelPermissionsState;

export const countersSelector = createSelector(labelPermissionsStateSelector, state => state.counters);

export const teamMembersSelector = createSelector(labelPermissionsStateSelector, state => state.teamMembers);

export const editArtistsSelector = createSelector(labelPermissionsStateSelector, state => state.editArtists);

export const artistsSelector = createSelector(labelPermissionsStateSelector, state => state.artists);

export const editMembersSelector = createSelector(labelPermissionsStateSelector, state => state.editMembers);
