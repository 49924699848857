import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { STeamMembersControl, BEM_CLASS } from './s-team-members-control';
import { Icon } from 'common/components/icon';
import { ControlProps } from 'react-select';
import { SelectOption } from '../../types';
import { isSelectProps } from '../../utils';

const classes = bem(BEM_CLASS);

export const TeamMembersControl = React.memo(
  ({ innerProps, innerRef, selectProps, isFocused, children }: ControlProps<SelectOption, true>) => {
    const customSelectProps = useMemo(() => {
      if (!isSelectProps(selectProps)) return;

      return selectProps.customSelectProps;
    }, [selectProps]);

    return (
      <STeamMembersControl {...innerProps} ref={innerRef} isFocused={isFocused} hasError={customSelectProps?.hasError}>
        <div className={classes('icon', 'control')}>
          <Icon color="inherit" name="envelope" size="inherit" />
        </div>

        {children}

        {customSelectProps?.canCopy && (
          <div className={classes('icon', 'copy')} onClick={customSelectProps?.onCopy}>
            <Icon color="inherit" name="copy" size="inherit" />
          </div>
        )}
      </STeamMembersControl>
    );
  }
);
