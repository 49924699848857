import { ICellRendererParams } from 'ag-grid-community';
import { CampaignStatuses } from 'backend-api/models';
import { MediaPlanMode } from 'media-plan/types';
import React from 'react';
import { SpreadsheetRowData } from '../../types';
import { ActionRenderer } from '../action-renderer';
import { StatusBadge } from '../status-badge';

export const StatusRenderer = React.memo(
  ({
    value: { color, value },
    context,
    data,
  }: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['status']>) => {
    const normalizedStatus =
      context.mediaPlanMode === MediaPlanMode.APPROVAL &&
      data?.editableInApproval?.value &&
      value === CampaignStatuses.PENDING
        ? CampaignStatuses.REVIEW
        : value;

    return (
      <ActionRenderer color={color} accessMode={context.accessMode}>
        {normalizedStatus && <StatusBadge status={normalizedStatus} />}
      </ActionRenderer>
    );
  }
);
