import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import { AvailablePeriodSchema } from '../analytic';
import {
  FormattedDateFromISOString,
  DateTimeFromISOString,
  IdNameField,
  IdType,
  Optional,
  ResponseModel,
  TerritorySchema,
  PaginationResponseModel,
} from '../common';
import { LabelSchema } from '../label';
import { UserRoleSchema, SimpleProjectUserSchema } from '../user';
import {
  ADD_CAMPAIGN,
  ADD_FEATURED_ARTISTS,
  ADD_PHASE,
  ADD_PLAYLISTS,
  ADD_PRIMARY_ARTISTS,
  ADD_PROJECT,
  APPROVE_GRAS_CAMPAIGN,
  CAMPAIGN_PHASE_CHANGE,
  CHANGE_OWNER,
  CHANGE_PROJECT_BUDGET,
  CHANGE_TERRITORIES,
  CLAIM_GRAS_PROJECT_OWNERSHIP,
  PLATFORM_UPDATED,
  PROJECT_TEAM_CHANGE,
  REJECT_GRAS_CAMPAIGN,
  REMOVE_CAMPAIGN,
  REMOVE_FEATURED_ARTISTS,
  REMOVE_PHASE,
  REMOVE_PLAYLISTS,
  REMOVE_PRIMARY_ARTISTS,
  UPDATE_ARTIST,
  UPDATE_CAMPAIGN_BUDGET,
  UPDATE_CAMPAIGN_TIMELINE,
  UPDATE_CAMPAIGN_TYPES,
  UPDATE_PROJECT_NOTES,
  UPDATE_PROJECT_TARGET_TYPE,
} from './constants';

export const HistoryCampaignSchema = ResponseModel(
  {
    ...IdNameField,
    uuid: UUID,
    source: Optional(t.string),
    categoryId: Optional(IdType),
    projectId: Optional(IdType),
    isDeleted: t.boolean,
  },
  'HistoryCampaign'
);

export type HistoryCampaign = t.TypeOf<typeof HistoryCampaignSchema>;

export const HistoryPhaseSchema = ResponseModel(
  {
    ...IdNameField,
    order: t.number,
  },
  'HistoryPhase'
);

export type HistoryPhase = t.TypeOf<typeof HistoryPhaseSchema>;

export const HistoryPhaseWithDatesSchema = ResponseModel(
  {
    ...IdNameField,
    start_date: FormattedDateFromISOString,
    end_date: Optional(FormattedDateFromISOString),
    order: t.number,
  },
  'HistoryPhaseWithDates'
);

export type HistoryPhaseWithDates = t.TypeOf<typeof HistoryPhaseWithDatesSchema>;

export const HistoryItemCreatorSchema = ResponseModel(
  {
    id: t.string,
    name: t.string,
    email: t.string,
    label: Optional(LabelSchema),
    role: Optional(UserRoleSchema),
  },
  'HistoryItemCreator'
);

export type HistoryItemCreator = t.TypeOf<typeof HistoryItemCreatorSchema>;

export const HistoryActionTypeSchema = t.union(
  [
    t.literal(ADD_CAMPAIGN),
    t.literal(REMOVE_CAMPAIGN),
    t.literal(UPDATE_CAMPAIGN_TIMELINE),
    t.literal(UPDATE_CAMPAIGN_BUDGET),
    t.literal(UPDATE_CAMPAIGN_TYPES),
    t.literal(CHANGE_TERRITORIES),
    t.literal(ADD_PHASE),
    t.literal(REMOVE_PHASE),
    t.literal(CHANGE_OWNER),
    t.literal(CHANGE_PROJECT_BUDGET),
    t.literal(UPDATE_ARTIST),
    t.literal(PROJECT_TEAM_CHANGE),
    t.literal(CAMPAIGN_PHASE_CHANGE),
    t.literal(PLATFORM_UPDATED),
    t.literal(ADD_PROJECT),
    t.literal(CLAIM_GRAS_PROJECT_OWNERSHIP),
    t.literal(APPROVE_GRAS_CAMPAIGN),
  ],
  'HistoryActionType'
);

export type HistoryActionType = t.TypeOf<typeof HistoryActionTypeSchema>;

export const HistoryActionSchema = ResponseModel(
  {
    id: IdType,
    type: HistoryActionTypeSchema,
  },
  'HistoryAction'
);

export type HistoryAction = t.TypeOf<typeof HistoryActionSchema>;

export const AddCampaignHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(ADD_CAMPAIGN),
  },
  'AddCampaignHistoryAction'
);

export type AddCampaignHistoryAction = t.TypeOf<typeof AddCampaignHistoryActionSchema>;

export const RemoveCampaignHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(REMOVE_CAMPAIGN),
  },
  'RemoveCampaignHistoryAction'
);

export type RemoveCampaignHistoryAction = t.TypeOf<typeof RemoveCampaignHistoryActionSchema>;

export const UpdateCampaignTimelineHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(UPDATE_CAMPAIGN_TIMELINE),
  },
  'UpdateCampaignTimelineHistoryAction'
);

export type UpdateCampaignTimelineHistoryAction = t.TypeOf<typeof UpdateCampaignTimelineHistoryActionSchema>;

export const UpdateCampaignBudgetHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(UPDATE_CAMPAIGN_BUDGET),
  },
  'UpdateCampaignBudgetHistoryAction'
);

export type UpdateCampaignBudgetHistoryAction = t.TypeOf<typeof UpdateCampaignBudgetHistoryActionSchema>;

export const UpdateCampaignTypesHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(UPDATE_CAMPAIGN_TYPES),
  },
  'UpdateCampaignTypesHistoryAction'
);

export type UpdateCampaignTypesHistoryAction = t.TypeOf<typeof UpdateCampaignTypesHistoryActionSchema>;

export const ChangeTerritoriesHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(CHANGE_TERRITORIES),
  },
  'ChangeTerritoriesHistoryAction'
);

export type ChangeTerritoriesHistoryAction = t.TypeOf<typeof ChangeTerritoriesHistoryActionSchema>;

export const AddPhaseHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(ADD_PHASE),
  },
  'AddPhaseHistoryAction'
);

export type AddPhaseHistoryAction = t.TypeOf<typeof AddPhaseHistoryActionSchema>;

export const RemovePhaseHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(REMOVE_PHASE),
  },
  'RemovePhaseHistoryAction'
);

export type RemovePhaseHistoryAction = t.TypeOf<typeof RemovePhaseHistoryActionSchema>;

export const ChangeOwnerHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(CHANGE_OWNER),
  },
  'ChangeOwnerHistoryAction'
);

export type ChangeOwnerHistoryAction = t.TypeOf<typeof ChangeOwnerHistoryActionSchema>;

export const UpdateProjectBudgetHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(CHANGE_PROJECT_BUDGET),
  },
  'UpdateProjectBudgetHistoryAction'
);

export type UpdateProjectBudgetHistoryAction = t.TypeOf<typeof UpdateProjectBudgetHistoryActionSchema>;

export const UpdateArtistHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(UPDATE_ARTIST),
  },
  'UpdateArtistHistoryAction'
);

export type UpdateArtistHistoryAction = t.TypeOf<typeof UpdateArtistHistoryActionSchema>;

export const ProjectTeamChangeHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(PROJECT_TEAM_CHANGE),
  },
  'ProjectTeamChangeHistoryAction'
);

export type ProjectTeamChangeHistoryAction = t.TypeOf<typeof ProjectTeamChangeHistoryActionSchema>;

export const CampaignPhaseChangeHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(CAMPAIGN_PHASE_CHANGE),
  },
  'CampaignPhaseChangeHistoryAction'
);

export type CampaignPhaseChangeHistoryAction = t.TypeOf<typeof CampaignPhaseChangeHistoryActionSchema>;

export const PlatformUpdatedHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(PLATFORM_UPDATED),
  },
  'PlatformUpdatedHistoryAction'
);

export type PlatformUpdatedHistoryAction = t.TypeOf<typeof PlatformUpdatedHistoryActionSchema>;

export const AddProjectHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(ADD_PROJECT),
  },
  'AddProjectHistoryAction'
);

export const UpdateNotesHistoryActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(UPDATE_PROJECT_NOTES),
  },
  'UpdateNotesHistoryAction'
);

export type AddProjectHistoryAction = t.TypeOf<typeof AddProjectHistoryActionSchema>;

export const UpdateProjectTargetTypeActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(UPDATE_PROJECT_TARGET_TYPE),
  },
  'UpdateProjectTargetTypeAction'
);

export type UpdateProjectTargetTypeAction = t.TypeOf<typeof UpdateProjectTargetTypeActionSchema>;

export const AddPrimaryArtistsActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(ADD_PRIMARY_ARTISTS),
  },
  'AddPrimaryArtistsAction'
);

export type AddPrimaryArtistsAction = t.TypeOf<typeof AddPrimaryArtistsActionSchema>;

export const ClaimProjectActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(CLAIM_GRAS_PROJECT_OWNERSHIP),
  },
  'ClaimProjectAction'
);

export type ClaimProjectAction = t.TypeOf<typeof ClaimProjectActionSchema>;

export const ApproveGrasCampaignActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(APPROVE_GRAS_CAMPAIGN),
  },
  'ApproveGrasCampaignAction'
);

export type ApproveGrasCampaignAction = t.TypeOf<typeof ApproveGrasCampaignActionSchema>;

export const RejectGrasCampaignActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(REJECT_GRAS_CAMPAIGN),
  },
  'RejectGrasCampaignAction'
);

export type RejectGrasCampaignAction = t.TypeOf<typeof RejectGrasCampaignActionSchema>;

export const RemovePrimaryArtistsActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(REMOVE_PRIMARY_ARTISTS),
  },
  'RemovePrimaryArtistsAction'
);

export type RemovePrimaryArtistsAction = t.TypeOf<typeof RemovePrimaryArtistsActionSchema>;

export const AddFeaturedArtistsActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(ADD_FEATURED_ARTISTS),
  },
  'AddFeaturedArtistsAction'
);

export type AddFeaturedArtistsAction = t.TypeOf<typeof AddFeaturedArtistsActionSchema>;

export const RemoveFeaturedArtistsActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(REMOVE_FEATURED_ARTISTS),
  },
  'RemoveFeaturedArtistsAction'
);

export type RemoveFeaturedArtistsAction = t.TypeOf<typeof RemoveFeaturedArtistsActionSchema>;

export const RemovePlaylistsActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(REMOVE_PLAYLISTS),
  },
  'RemovePlaylistsAction'
);

export type RemovePlaylistsAction = t.TypeOf<typeof RemovePlaylistsActionSchema>;

export const AddPlaylistsActionSchema = ResponseModel(
  {
    ...HistoryActionSchema.props,
    type: t.literal(ADD_PLAYLISTS),
  },
  'AddPlaylistsAction'
);

export type AddPlaylistsAction = t.TypeOf<typeof AddPlaylistsActionSchema>;

export const HistoryValueNumberSchema = ResponseModel(
  {
    value: t.number,
  },
  'HistoryValueNumber'
);

export type HistoryValueNumber = t.TypeOf<typeof HistoryValueNumberSchema>;

export const HistoryValueStringSchema = ResponseModel(
  {
    value: t.string,
  },
  'HistoryValueString'
);

export const OptionalHistoryValueStringSchema = Optional(
  ResponseModel(
    {
      value: Optional(t.string),
    },
    'OptionalHistoryValueStringSchema'
  )
);

export type HistoryValueString = t.TypeOf<typeof HistoryValueStringSchema>;

export const OptionalHistoryValueStringArraySchema = Optional(
  ResponseModel(
    {
      value: Optional(t.array(t.string)),
    },
    'OptionalHistoryValueStringArray'
  )
);

export type OptionalHistoryValueStringArray = t.TypeOf<typeof OptionalHistoryValueStringArraySchema>;

export const HistoryValueSimpleSchema = t.union(
  [HistoryValueNumberSchema, HistoryValueStringSchema],
  'HistoryValueSimple'
);

export type HistoryValueSimple = t.TypeOf<typeof HistoryValueSimpleSchema>;

export const HistoryPlaylistSchema = ResponseModel(
  {
    ...IdNameField,
    owner: t.string,
    provider: t.string,
  },
  'HistoryPlaylist'
);

export type HistoryPlaylist = t.TypeOf<typeof HistoryPlaylistSchema>;

export const HistoryArtistSchema = ResponseModel(
  {
    ...IdNameField,
  },
  'HistoryArtist'
);

export type HistoryArtist = t.TypeOf<typeof HistoryArtistSchema>;

export const HistoryValueTypeSchema = t.union(
  [
    AvailablePeriodSchema,
    HistoryValueSimpleSchema,
    t.array(TerritorySchema),
    HistoryPhaseSchema,
    t.array(HistoryPhaseSchema),
    SimpleProjectUserSchema,
    t.array(SimpleProjectUserSchema),
    ResponseModel(IdNameField),
    t.array(HistoryPlaylistSchema),
    t.array(HistoryArtistSchema),
  ],
  'HistoryValueType'
);

export type HistoryValueType = t.TypeOf<typeof HistoryValueTypeSchema>;

const HistoryBaseEventSchema = {
  id: IdType,
  user: Optional(HistoryItemCreatorSchema),
  date: DateTimeFromISOString,
  action: HistoryActionSchema,
  is_gras: t.boolean,
};

export const AddCampaignHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: AddCampaignHistoryActionSchema,
    old_value: Optional(t.null),
    new_value: HistoryValueNumberSchema,
    campaign: HistoryCampaignSchema,
  },
  'AddCampaignHistoryEvent'
);

export type AddCampaignHistoryEvent = t.TypeOf<typeof AddCampaignHistoryEventSchema>;

export const RemoveCampaignHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: RemoveCampaignHistoryActionSchema,
    old_value: HistoryValueNumberSchema,
    new_value: Optional(t.null),
    campaign: HistoryCampaignSchema,
  },
  'RemoveCampaignHistoryEvent'
);

export type RemoveCampaignHistoryEvent = t.TypeOf<typeof RemoveCampaignHistoryEventSchema>;

export const UpdateCampaignTimelineHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: UpdateCampaignTimelineHistoryActionSchema,
    old_value: AvailablePeriodSchema,
    new_value: AvailablePeriodSchema,
    campaign: HistoryCampaignSchema,
  },
  'UpdateCampaignTimelineHistoryEvent'
);

export type UpdateCampaignTimelineHistoryEvent = t.TypeOf<typeof UpdateCampaignTimelineHistoryEventSchema>;

export const UpdateCampaignBudgetHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: UpdateCampaignBudgetHistoryActionSchema,
    old_value: HistoryValueNumberSchema,
    new_value: HistoryValueNumberSchema,
    campaign: HistoryCampaignSchema,
  },
  'UpdateCampaignBudgetHistoryEvent'
);

export type UpdateCampaignBudgetHistoryEvent = t.TypeOf<typeof UpdateCampaignBudgetHistoryEventSchema>;

export const UpdateCampaignTypesHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: UpdateCampaignTypesHistoryActionSchema,
    // TODO: when event is gonna be implemented on the UI, refine the response
    old_value: t.unknown,
    new_value: t.unknown,
    campaign: HistoryCampaignSchema,
  },
  'UpdateCampaignTypesHistoryEvent'
);

export type UpdateCampaignTypesHistoryEvent = t.TypeOf<typeof UpdateCampaignTypesHistoryEventSchema>;

export const ChangeTerritoriesHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: ChangeTerritoriesHistoryActionSchema,
    old_value: Optional(t.array(TerritorySchema)),
    new_value: Optional(t.array(TerritorySchema)),
    campaign: HistoryCampaignSchema,
  },
  'ChangeTerritoriesHistoryEvent'
);

export type ChangeTerritoriesHistoryEvent = t.TypeOf<typeof ChangeTerritoriesHistoryEventSchema>;

export const AddPhaseHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: AddPhaseHistoryActionSchema,
    new_value: HistoryPhaseSchema,
    old_value: Optional(t.null),
    campaign: Optional(t.null),
  },
  'AddPhaseHistoryEvent'
);

export type AddPhaseHistoryEvent = t.TypeOf<typeof AddPhaseHistoryEventSchema>;

export const RemovePhaseHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: RemovePhaseHistoryActionSchema,
    old_value: HistoryPhaseSchema,
    campaign: Optional(t.null),
    new_value: Optional(t.null),
  },
  'RemovePhaseHistoryEvent'
);

export type RemovePhaseHistoryEvent = t.TypeOf<typeof RemovePhaseHistoryEventSchema>;

export const ChangeOwnerHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: ChangeOwnerHistoryActionSchema,
    old_value: HistoryItemCreatorSchema,
    new_value: HistoryItemCreatorSchema,
    campaign: Optional(t.null),
  },
  'ChangeOwnerHistoryEvent'
);

export type ChangeOwnerHistoryEvent = t.TypeOf<typeof ChangeOwnerHistoryEventSchema>;

export const UpdateProjectBudgetHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: UpdateProjectBudgetHistoryActionSchema,
    old_value: HistoryValueNumberSchema,
    new_value: HistoryValueNumberSchema,
    campaign: Optional(t.null),
  },
  'UpdateProjectBudgetHistoryEvent'
);

export type UpdateProjectBudgetHistoryEvent = t.TypeOf<typeof UpdateProjectBudgetHistoryEventSchema>;

export const UpdateArtistHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: UpdateArtistHistoryActionSchema,
    old_value: HistoryValueStringSchema,
    new_value: HistoryValueStringSchema,
    campaign: Optional(t.null),
  },
  'UpdateArtistHistoryEvent'
);

export type UpdateArtistHistoryEvent = t.TypeOf<typeof UpdateArtistHistoryEventSchema>;

export const ProjectTeamChangeHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: ProjectTeamChangeHistoryActionSchema,
    old_value: Optional(t.array(SimpleProjectUserSchema)),
    new_value: Optional(t.array(SimpleProjectUserSchema)),
    campaign: Optional(t.null),
  },
  'ProjectTeamChangeHistoryEvent'
);

export type ProjectTeamChangeHistoryEvent = t.TypeOf<typeof ProjectTeamChangeHistoryEventSchema>;

export const CampaignPhaseChangeHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: CampaignPhaseChangeHistoryActionSchema,
    old_value: t.array(HistoryPhaseWithDatesSchema),
    new_value: t.array(HistoryPhaseWithDatesSchema),
    campaign: HistoryCampaignSchema,
  },
  'CampaignPhaseChangeHistoryEvent'
);

export type CampaignPhaseChangeHistoryEvent = t.TypeOf<typeof CampaignPhaseChangeHistoryEventSchema>;

export const PlatformUpdateHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: PlatformUpdatedHistoryActionSchema,
    old_value: OptionalHistoryValueStringArraySchema,
    new_value: OptionalHistoryValueStringArraySchema,
    campaign: HistoryCampaignSchema,
  },
  'PlatformUpdateHistoryEvent'
);

export type PlatformUpdateHistoryEvent = t.TypeOf<typeof PlatformUpdateHistoryEventSchema>;

export const AddProjectHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: AddProjectHistoryActionSchema,
    new_value: HistoryValueStringSchema,
    old_value: Optional(t.null),
    campaign: Optional(t.null),
  },
  'AddProjectHistoryEvent'
);

export type AddProjectHistoryEvent = t.TypeOf<typeof AddProjectHistoryEventSchema>;

export const UpdateProjectNotesHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: UpdateNotesHistoryActionSchema,
    new_value: OptionalHistoryValueStringSchema,
    old_value: OptionalHistoryValueStringSchema,
    campaign: Optional(t.null),
  },
  'UpdateProjectNotesHistoryEvent'
);

export type UpdateProjectNotesHistoryEvent = t.TypeOf<typeof UpdateProjectNotesHistoryEventSchema>;

export const AddPrimaryArtistsHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: AddPrimaryArtistsActionSchema,
    new_value: t.array(HistoryArtistSchema),
    old_value: Optional(t.null),
    campaign: Optional(t.null),
  },
  'AddPrimaryArtistsHistoryEvent'
);

export type AddPrimaryArtistsHistoryEvent = t.TypeOf<typeof AddPrimaryArtistsHistoryEventSchema>;

export const RemovePrimaryArtistsHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: RemovePrimaryArtistsActionSchema,
    new_value: Optional(t.null),
    old_value: t.array(HistoryArtistSchema),
    campaign: Optional(t.null),
  },
  'RemovePrimaryArtistsHistoryEvent'
);

export type RemovePrimaryArtistsHistoryEvent = t.TypeOf<typeof RemovePrimaryArtistsHistoryEventSchema>;

export const AddFeaturedArtistsHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: AddFeaturedArtistsActionSchema,
    new_value: t.array(HistoryArtistSchema),
    old_value: Optional(t.null),
    campaign: Optional(t.null),
  },
  'AddFeaturedArtistsHistoryEvent'
);

export type AddFeaturedArtistsHistoryEvent = t.TypeOf<typeof AddFeaturedArtistsHistoryEventSchema>;

export const RemoveFeaturedArtistsHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: RemoveFeaturedArtistsActionSchema,
    new_value: Optional(t.null),
    old_value: t.array(HistoryArtistSchema),
    campaign: Optional(t.null),
  },
  'RemoveFeaturedArtistsHistoryEvent'
);

export type RemoveFeaturedArtistsHistoryEvent = t.TypeOf<typeof RemoveFeaturedArtistsHistoryEventSchema>;

export const AddPlaylistsHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: AddPlaylistsActionSchema,
    new_value: t.array(HistoryPlaylistSchema),
    old_value: Optional(t.null),
    campaign: Optional(t.null),
  },
  'AddPlaylistsHistoryEvent'
);

export type AddPlaylistsHistoryEvent = t.TypeOf<typeof AddPlaylistsHistoryEventSchema>;

export const RemovePlaylistsHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: RemovePlaylistsActionSchema,
    new_value: Optional(t.null),
    old_value: t.array(HistoryPlaylistSchema),
    campaign: Optional(t.null),
  },
  'RemovePlaylistsHistoryEvent'
);

export type RemovePlaylistsHistoryEvent = t.TypeOf<typeof RemovePlaylistsHistoryEventSchema>;

export const UpdateProjectTargetTypeHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: UpdateProjectTargetTypeActionSchema,
    new_value: HistoryValueStringSchema,
    old_value: HistoryValueStringSchema,
    campaign: Optional(t.null),
  },
  'UpdateProjectTargetTypeHistoryEvent'
);

export type UpdateProjectTargetTypeHistoryEvent = t.TypeOf<typeof UpdateProjectTargetTypeHistoryEventSchema>;

export const ClaimProjectHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    user: HistoryItemCreatorSchema,
    action: ClaimProjectActionSchema,
    new_value: Optional(t.null),
    old_value: Optional(t.null),
    campaign: Optional(t.null),
  },
  'ClaimProjectHistoryEvent'
);

export type ClaimProjectHistoryEvent = t.TypeOf<typeof ClaimProjectHistoryEventSchema>;

export const ApproveGrasCampaignHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: ApproveGrasCampaignActionSchema,
    new_value: HistoryValueNumberSchema,
    old_value: Optional(t.null),
    campaign: HistoryCampaignSchema,
  },
  'ApproveGrasCampaignHistoryEvent'
);

export type ApproveGrasCampaignHistoryEvent = t.TypeOf<typeof ApproveGrasCampaignHistoryEventSchema>;

export const RejectGrasCampaignHistoryEventSchema = ResponseModel(
  {
    ...HistoryBaseEventSchema,
    action: RejectGrasCampaignActionSchema,
    new_value: Optional(t.null),
    old_value: HistoryValueNumberSchema,
    campaign: HistoryCampaignSchema,
  },
  'RejectGrasCampaignHistoryEvent'
);

export type RejectGrasCampaignHistoryEvent = t.TypeOf<typeof RejectGrasCampaignHistoryEventSchema>;

export const HistoryEventSchema = t.union(
  [
    AddCampaignHistoryEventSchema,
    RemoveCampaignHistoryEventSchema,
    UpdateCampaignTimelineHistoryEventSchema,
    UpdateCampaignBudgetHistoryEventSchema,
    UpdateCampaignTypesHistoryEventSchema,
    ChangeTerritoriesHistoryEventSchema,
    AddPhaseHistoryEventSchema,
    RemovePhaseHistoryEventSchema,
    ChangeOwnerHistoryEventSchema,
    UpdateProjectBudgetHistoryEventSchema,
    UpdateArtistHistoryEventSchema,
    ProjectTeamChangeHistoryEventSchema,
    CampaignPhaseChangeHistoryEventSchema,
    PlatformUpdateHistoryEventSchema,
    AddProjectHistoryEventSchema,
    UpdateProjectNotesHistoryEventSchema,
    AddPrimaryArtistsHistoryEventSchema,
    RemovePrimaryArtistsHistoryEventSchema,
    AddPlaylistsHistoryEventSchema,
    RemovePlaylistsHistoryEventSchema,
    UpdateProjectTargetTypeHistoryEventSchema,
    AddFeaturedArtistsHistoryEventSchema,
    RemoveFeaturedArtistsHistoryEventSchema,
    ClaimProjectHistoryEventSchema,
    ApproveGrasCampaignHistoryEventSchema,
    RejectGrasCampaignHistoryEventSchema,
  ],
  'HistoryEvent'
);

export type HistoryEvent = t.TypeOf<typeof HistoryEventSchema>;

export const HistoryResponseSchema = PaginationResponseModel(HistoryEventSchema);

export type HistoryResponse = t.TypeOf<typeof HistoryResponseSchema>;

export const NotesHistoryResponseSchema = ResponseModel(
  {
    history: t.array(HistoryEventSchema),
  },
  'NotesHistoryResponseSchema'
);

export type NotesHistoryResponse = t.TypeOf<typeof NotesHistoryResponseSchema>;
