import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const SAddRow = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: var(--cell-horizontal-padding);
  padding-left: calc(var(--cell-horizontal-padding) + var(--action-column-width));
  background-color: ${colorPalette.ghostWhite};
  cursor: pointer;

  &:focus {
    border: 1px solid ${colorPalette.azureRadiance};
  }
`;
