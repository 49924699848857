import React from 'react';
import { SCardContent } from './s-card-content';

interface CardContentProps {
  className?: string;
}

export const CardContent = React.memo(({ className, children }: React.PropsWithChildren<CardContentProps>) => (
  <SCardContent className={className}>{children}</SCardContent>
));
