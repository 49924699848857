import { ProjectsPaths, LoginPaths, ReportingPath } from 'app/routing/types';
import { ProjectTab } from 'common-v2/types';

const MANHATTAN_DESIGN = {
  LOGIN_V2: 'LOGIN_V2',
  PROJECTS_V2: 'PROJECTS_V2',
  PROJECT_V2: 'PROJECT_V2',
  REPORTING_V2: 'REPORTING_V2',
  GLOBAL_SEARCH_V2: 'GLOBAL_SEARCH_V2',
  SETTINGS_V2: 'SETTINGS_V2',
};

export const FEATURE_FLAGS = {
  ...MANHATTAN_DESIGN,
};

export const FEATURE_FLAGS_BY_PATH = {
  [ProjectsPaths.projects]: [FEATURE_FLAGS.PROJECTS_V2],
  [LoginPaths.login]: [FEATURE_FLAGS.LOGIN_V2],
  [ReportingPath.reporting]: [FEATURE_FLAGS.REPORTING_V2],
};

export const FEATURE_FLAG_BY_TAB: Partial<Record<ProjectTab, string>> = {
  [ProjectTab.Reporting]: FEATURE_FLAGS.REPORTING_V2,
};
