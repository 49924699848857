import React, { useMemo } from 'react';
import { AssignableProject, Id } from 'backend-api/models';
import { ProjectsListItem } from './components';
import { SProjectsList } from './s-projects-list';

interface Props {
  projects: AssignableProject[];
  onSelectProject(projectId: Id): void;
  className?: string;
}

export const ProjectsList = React.memo(({ projects, onSelectProject, className }: Props) => {
  const projectsList = useMemo(
    () =>
      projects.map(project => (
        <ProjectsListItem project={project} key={project.id} onSelectProject={onSelectProject} />
      )),
    [onSelectProject, projects]
  );

  return (
    <SProjectsList data-selector="projects-list" className={className}>
      {projectsList}
    </SProjectsList>
  );
});
