import styled, { css } from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { ellipsizeText } from 'common-v2/styles';

export const BEM_CLASS = 'action-renderer';

interface Props {
  isDisabled?: boolean;
}

export const SActionRenderer = styled.div<Props>`
  width: 100%;
  height: 100%;

  .${BEM_CLASS} {
    &__placeholder,
    &__content {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    &__content {
      ${({ isDisabled }) =>
        !isDisabled &&
        css`
          &:hover {
            .${BEM_CLASS}__content-icon {
              visibility: visible;
            }
          }
        `};
    }

    &__placeholder-value,
    &__content-value {
      ${ellipsizeText};
      color: inherit;
      margin-right: var(--cell-horizontal-padding);
    }

    &__placeholder-value {
      color: ${manhattanThemeColors.grayedText};
    }

    &__placeholder-icon,
    &__content-icon {
      flex-shrink: 0;
      margin-left: auto;
    }

    &__content-icon {
      visibility: hidden;
    }

    &__arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${manhattanThemeColors.primaryDark};
    }
  }
`;
