import { apiClient, EmptyPayload, EmptySchema, ResponseArray } from '../index';
import {
  CampaignDetailsSchema,
  Id,
  MetricsReportingResponseSchema,
  PerformanceBreakdownDataSchema,
  PerformanceDataSchema,
  PhaseRequest,
  PhaseRequestSchema,
  PhaseSchema,
} from '../models';
import { PerformanceBreakdownDataParams, PerformanceDataParams, MetricsReportingParams } from '../types';
import { parseDateFromISOString, addDaysToDate, getFormattedDate } from 'common-v2/utils';

export const deletePhase = (projectId: Id, phaseId: Id) =>
  apiClient.executeRequest({
    method: 'DELETE',
    endpoint: `/projects/${projectId}/phases/${phaseId}`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
  });

export const addPhase = (projectId: Id, payload: PhaseRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/phases`,
    payload,
    requestSchema: PhaseRequestSchema,
    responseSchema: PhaseSchema,
  });

export const editPhase = (projectId: Id, phaseId: Id, payload: PhaseRequest) =>
  apiClient.executeRequest({
    method: 'PUT',
    endpoint: `/projects/${projectId}/phases/${phaseId}`,
    payload,
    requestSchema: PhaseRequestSchema,
    responseSchema: PhaseSchema,
  });

export const getPhase = (projectId: Id, phaseId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/phases/${phaseId}`,
    responseSchema: PhaseSchema,
  });

export const getProjectPerformanceBreakdown = (
  projectId: Id,
  phaseId: string,
  params: PerformanceBreakdownDataParams
) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/metrics/${phaseId}/details`,
    responseSchema: PerformanceBreakdownDataSchema,
    queryParams: params,
  });

export const getPhasePerformance = (projectId: Id, phaseId: Id, params: PerformanceDataParams) => {
  const formatDate = getFormattedDate('yyyy-MM-dd');
  const parsedEndDate = parseDateFromISOString(params.endDate);

  const updatedParams: PerformanceDataParams = {
    ...params,
    endDate: formatDate(addDaysToDate(parsedEndDate, 1)),
  };

  return apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/metrics/${phaseId}`,
    responseSchema: PerformanceDataSchema,
    queryParams: updatedParams,
  });
};

export const getPhaseMetricsReporting = (projectId: Id, phaseId: Id, params: MetricsReportingParams) => {
  const formatDate = getFormattedDate('yyyy-MM-dd');
  const parsedEndDate = parseDateFromISOString(params.endDate);

  const updatedParams: MetricsReportingParams = {
    ...params,
    endDate: formatDate(addDaysToDate(parsedEndDate, 1)),
  };

  return apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/phases/${phaseId}/reporting-v2/metrics`,
    responseSchema: MetricsReportingResponseSchema,
    queryParams: updatedParams,
  });
};

export const getPhaseCampaigns = (projectId: Id, phaseId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/phases/${phaseId}/campaigns`,
    responseSchema: ResponseArray(CampaignDetailsSchema),
  });
