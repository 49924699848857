import React from 'react';
import { paths } from 'app/routing/paths';
import { bem } from 'utils/bem';
import { LinkLongTableTitle, TableContent } from 'common/components/table';
import { Project, ProjectDetails } from 'backend-api/models/project';
import {
  getJoinedNames,
  getPathToProjectPage,
  getProjectAllocation,
  getProjectBudget,
  getProjectSchedule,
} from 'common/transducers';
import { ProjectSortField } from 'backend-api/types';
import { BEM_CLASS } from './s-table';
import { Artist, Playlist } from 'backend-api/models';
import { isArtists } from 'backend-api/guards';
import { Link } from 'react-router-dom';
import { ConfidentialProjectIcon } from 'common/components/confidential-project-icon';
import { getProjectName } from 'common-v2/transducers';

const classes = bem(BEM_CLASS);

export const getProjectArtists = (targets: (Artist | Playlist)[], id: string) => {
  const artists = isArtists(targets) ? targets.filter(item => `${id}` !== item.id && item.type === 'Primary') : [];

  if (artists.length === 0) return null;

  const [firstArtist, secondArtist] = artists;

  return (
    <span className={classes('project-cell-artists')}>
      with <Link to={paths.artist(firstArtist.id)}>{firstArtist.name}</Link>
      {secondArtist && (
        <>
          {' '}
          and <Link to={paths.artist(secondArtist.id)}>{secondArtist.name}</Link>
        </>
      )}
    </span>
  );
};

export const getProjectsTableConfig = (
  artistId: string,
  dateFormat: string
): TableContent<Project, ProjectSortField>[] => [
  {
    title: 'Project Name',
    dataSelector: 'artist-projects-name-column',
    dataIndex: 'name',
    useSort: true,
    sortField: 'project',
    render: (record: ProjectDetails) => (
      <>
        <ConfidentialProjectIcon color="black" isConfidential={record.isConfidential}>
          <LinkLongTableTitle
            className={classes('project-cell-title')}
            linkProps={{ pathname: getPathToProjectPage(record) }}
            text={getProjectName(record)}
            dataSelector="artist-project-name-item"
          />
        </ConfidentialProjectIcon>
        {getProjectArtists(record.targets.items, artistId)}
      </>
    ),
  },
  {
    title: 'Budget',
    dataIndex: 'budget',
    dataSelector: 'artist-projects-budget-column',
    useSort: true,
    sortField: 'budget',
    render: (record: Project) => <span>{getProjectBudget(record)}</span>,
    additionalClassName: classes('medium-cell'),
  },
  {
    title: 'Allocation',
    dataIndex: 'allocation',
    dataSelector: 'artist-projects-allocation-column',
    useSort: true,
    sortField: 'allocation',
    render: (record: Project) => <span>{getProjectAllocation(record)}</span>,
    additionalClassName: classes('medium-cell'),
  },
  {
    title: 'Schedule',
    dataIndex: 'earliestStartDate',
    dataSelector: 'artist-projects-timeline-column',
    useSort: true,
    sortField: 'earliestStartDate',
    additionalClassName: classes('medium-cell'),
    render: (record: Project) => <span>{getProjectSchedule(record, dateFormat)}</span>,
  },
  {
    title: 'Role',
    dataIndex: 'role',
    dataSelector: 'artist-projects-role-column',
    useSort: true,
    sortField: 'userRoles',
    additionalClassName: classes('medium-cell'),
    render: (record: Project) => {
      return (
        <div className={classes('role-cell')}>
          <span>{getJoinedNames(record.userRoles)}</span>
        </div>
      );
    },
  },
];
