import styled from 'styled-components';

export const SAllProjectsTabHeader = styled.div`
  display: flex;
  align-items: center;

  .all-projects-tab-header {
    &__right-content {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
    }

    &__search {
      width: 260px;
      margin-left: 20px;
    }

    &__date-picker {
      width: 232px;
    }
  }
`;
