import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { Loader } from '../loader';
import { NOT_AVAILABLE } from 'common-v2/constants';
import { BEM_CLASS, SMetricItem } from './s-metric-item';

interface MetricItemProps {
  label: string;
  content?: string | number;
  isLoading?: boolean;
  isFailedToLoad?: boolean;
  dataSelector?: string;
}

const classes = bem(BEM_CLASS);

export const MetricItem = React.memo(
  ({ label, content, isLoading = false, isFailedToLoad, dataSelector }: MetricItemProps) => {
    const contentWithLoader = useMemo(() => {
      if (isLoading) return <Loader />;

      if (isFailedToLoad) return <Typography type={TYPOGRAPHY_TYPE.body4}>{NOT_AVAILABLE}</Typography>;

      return (
        <Typography type={TYPOGRAPHY_TYPE.body4} data-selector={dataSelector}>
          {content}
        </Typography>
      );
    }, [isLoading, content, isFailedToLoad, dataSelector]);

    return (
      <SMetricItem>
        <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('label')}>
          {label}
        </Typography>
        {contentWithLoader}
      </SMetricItem>
    );
  }
);
