import React, { useMemo } from 'react';
import { SLinkView } from './s-links-view';
import { getLinksWithLabels } from 'common/transducers';
import { LinkViewContainer } from '../link-view-container';

interface Props {
  links: string[];
  className?: string;
  flat?: boolean;
  onLinkClick?(): void;
  allowInvalid?: boolean;
}

export const LinksViewComponent = React.memo(({ links, className, flat, onLinkClick, allowInvalid }: Props) => {
  const linksWithLabels = useMemo(() => getLinksWithLabels(links, allowInvalid), [allowInvalid, links]);
  return (
    <SLinkView className={className}>
      {linksWithLabels.map(link => (
        <LinkViewContainer key={link.link} link={link} flat={flat} onLinkClick={onLinkClick} />
      ))}
    </SLinkView>
  );
});
