import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'list-header';

export const SListHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 7.5px;

  .${BEM_CLASS} {
    &__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__assign-all {
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
      background: ${transparentize(0.95, manhattanThemeColors.hoveredBorderColor)};

      &:hover {
        background: ${transparentize(0.9, manhattanThemeColors.hoveredBorderColor)};
      }
    }

    &__separator {
      margin-top: 15px;
      background-color: ${transparentize(0.9, manhattanThemeColors.textHeaderColor)};
      height: 1px;
    }
  }
`;
