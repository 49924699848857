import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import { IdNameField, IdType, ImageSchema, Optional, ResponseModel } from '../common';
import { TargetsSchema } from '../targets';
import { UserRoleSchema } from '../user';

export const ArtistSearchSchema = ResponseModel(
  {
    id: t.string,
    name: t.string,
    isSony: t.boolean,
    isUnknown: t.boolean,
    image: Optional(ImageSchema),
    projectsCount: Optional(t.number),
  },
  'ArtistSearch'
);

export type ArtistSearch = t.TypeOf<typeof ArtistSearchSchema>;

export const ProjectSearchSchema = ResponseModel(
  {
    ...IdNameField,
    startDate: Optional(t.string),
    editDate: Optional(t.string),
    targets: TargetsSchema,
    isClaimed: t.boolean,
    isConfidential: t.boolean,
    userRoles: Optional(t.array(UserRoleSchema)),
  },
  'ProjectSearch'
);

export type ProjectSearch = t.TypeOf<typeof ProjectSearchSchema>;

export const CampaignSearchSchema = ResponseModel(
  {
    id: IdType,
    name: Optional(t.string),
    uuid: UUID,
    categoryId: Optional(t.number),
    project: Optional(ProjectSearchSchema),
    startDate: Optional(t.string),
    endDate: Optional(t.string),
    isUnassigned: t.boolean,
    isPending: t.boolean,
    platforms: Optional(t.array(t.string)),
  },
  'CampaignSearch'
);

export type CampaignSearch = t.TypeOf<typeof CampaignSearchSchema>;

export const GlobalSearchMetadataCountersSchema = ResponseModel(
  {
    projects: Optional(t.number),
    artists: Optional(t.number),
    campaigns: Optional(t.number),
  },
  'GlobalSearchMetadataCounters'
);

export type GlobalSearchMetadataCounters = t.TypeOf<typeof GlobalSearchMetadataCountersSchema>;

export const GlobalSearchMetadataSchema = ResponseModel(
  {
    counts: GlobalSearchMetadataCountersSchema,
  },
  'GlobalSearchMetadata'
);

export type GlobalSearchMetadata = t.TypeOf<typeof GlobalSearchMetadataSchema>;

export const GlobalSearchSchema = ResponseModel(
  {
    artists: Optional(t.array(ArtistSearchSchema)),
    projects: t.array(ProjectSearchSchema),
    campaigns: t.array(CampaignSearchSchema),
    limit: t.number,
    offset: t.number,
    metadata: GlobalSearchMetadataSchema,
  },
  'GlobalSearch'
);

export type GlobalSearch = t.TypeOf<typeof GlobalSearchSchema>;

export enum RecentSearchItemType {
  Artist,
  Project,
  Campaign,
  UnassignedProject,
}

const RecentSearchItemTypeSchema = t.union([
  t.literal(RecentSearchItemType.Artist),
  t.literal(RecentSearchItemType.Campaign),
  t.literal(RecentSearchItemType.Project),
  t.literal(RecentSearchItemType.UnassignedProject),
]);

export const RecentSearchItemEntitySchema = t.union([ArtistSearchSchema, ProjectSearchSchema, CampaignSearchSchema]);

export type RecentSearchItemEntity = t.TypeOf<typeof RecentSearchItemEntitySchema>;

export const RecentSearchItemSchema = ResponseModel(
  {
    id: t.number,
    type: RecentSearchItemTypeSchema,
    entity: RecentSearchItemEntitySchema,
  },
  'RecentSearchItem'
);

export type RecentSearchItem = t.TypeOf<typeof RecentSearchItemSchema>;
