import React from 'react';
import { MultiValueLabel } from './multiselect-value-label';
import { Tooltip } from '../../tooltip';

export function Ellipsis(props) {
  const { remainingItems, disabled } = props;
  const tooltip = remainingItems.map(({ name }) => name).join(', ');

  return (
    <Tooltip placement="top" content={tooltip} enabled="true">
      <div>
        <MultiValueLabel
          isEllipsis
          variant="secondary-gray"
          name={`+${remainingItems.length} ...`}
          disabled={disabled}
        />
      </div>
    </Tooltip>
  );
}
