import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SCampaignItem } from './s-campaign-item';
import { Checkbox, ICheckboxProps, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';

const classes = bem(BEM_CLASS);

interface ICampaignItemProps {
  title?: string;
  subTitle?: string;
  titleChip?: React.ReactElement;
  checkboxProps: ICheckboxProps;
}

export const CampaignItem = ({ title, subTitle, titleChip, checkboxProps }: ICampaignItemProps) => (
  <SCampaignItem>
    <div className={classes('checkbox-container')}>
      <Checkbox {...checkboxProps} />
    </div>
    <div className={classes('text-container')}>
      <div className={classes('title-container')}>
        <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('title')}>
          {title}
        </Typography>
        {titleChip && <div className={classes('chip')}>{titleChip}</div>}
      </div>
      <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('sub-title')}>
        {subTitle}
      </Typography>
    </div>
  </SCampaignItem>
);
