import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppLoader } from 'app/components/app-loader';
import { userInfoSelector, labelsSelector } from 'common/selectors';
import { getLabels, getUserInfo } from 'common/actions';
import { AppContextProvider } from 'common-v2/context';
import { Loading } from 'common-v2/types';

export const AppContentLoader: React.FC = React.memo(({ children }) => {
  const dispatch = useDispatch();

  const { loading: userLoading, data: user } = useSelector(userInfoSelector);
  const { loading: labelsLoading, data: labels } = useSelector(labelsSelector);

  useEffect(() => {
    if (userLoading !== Loading.Idle) return;

    dispatch(getUserInfo.request());
  }, [dispatch, userLoading]);

  useEffect(() => {
    if (labelsLoading !== Loading.Idle) return;

    dispatch(getLabels.request());
  }, [dispatch, labelsLoading]);

  if (userLoading === Loading.Finished && labelsLoading === Loading.Finished)
    return (
      <AppContextProvider user={user} labels={labels}>
        {children}
      </AppContextProvider>
    );

  return <AppLoader hasError={userLoading === Loading.Failed || labelsLoading === Loading.Failed} />;
});
