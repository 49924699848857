import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'badge-select';

export const SBadgeSelect = styled.div`
  .${BEM_CLASS} {
    &__control {
      max-width: 100%;
      border-radius: 3px;
      background-color: ${colorPalette.eastBay};
    }

    &__value-container {
      padding: 4px 5px 4px 10px;
    }

    &__single-value {
      font-size: 10px;
      line-height: 15px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: ${colorPalette.white};
    }

    &__indicators-container {
      padding-left: 5px;
      padding-right: 10px;
      color: ${colorPalette.white};
    }
  }
`;
