import React, { PropsWithChildren, useMemo } from 'react';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { Body } from 'common/components/typography';
import { BaseRenderer, BaseRendererProps } from '../base-renderer';
import { BEM_CLASS, SActionRenderer } from './s-action-renderer';
import { MediaPlanAccessMode } from '../../types';
import { isEditMode } from '../../transducers';

const classes = bem(BEM_CLASS);

interface ActionRendererProps extends BaseRendererProps {
  color: string | null;
  accessMode: MediaPlanAccessMode;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
  icon?: React.ReactNode;
}

export const ActionRenderer = ({
  color,
  accessMode,
  className,
  children,
  icon,
  placeholder,
  isDisabled,
}: PropsWithChildren<ActionRendererProps>) => {
  const iconElement = useMemo(
    () =>
      icon || (
        <div className={classes('arrow')}>
          <Icon name="down" color="inherit" />
        </div>
      ),
    [icon]
  );

  const placeholderElement = useMemo(
    () => (
      <div className={classes('placeholder')}>
        <Body className={classes('placeholder-value')}>{placeholder}</Body>
        <div className={classes('placeholder-icon')}>{iconElement}</div>
      </div>
    ),
    [iconElement, placeholder]
  );

  return (
    <SActionRenderer className={className} isDisabled={isDisabled}>
      <BaseRenderer color={color} accessMode={accessMode} placeholder={placeholderElement} isDisabled={isDisabled}>
        {children && (
          <div className={classes('content')}>
            <div className={classes('content-value')}>{children}</div>
            {isEditMode(accessMode) && <div className={classes('content-icon')}>{iconElement}</div>}
          </div>
        )}
      </BaseRenderer>
    </SActionRenderer>
  );
};
