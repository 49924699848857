import React, { useImperativeHandle, useMemo, useState } from 'react';
import { BEM_CLASS, SAgeEditor } from './s-age-editor';
import { ICellEditorParams } from 'ag-grid-community';
import { bem } from 'utils/bem';
import { AgeInput } from './components';
import { ageStringToAudienceAge, audienceAgeToString } from '../transducers';
import {
  useAudienceAgeValidation,
  useEditorKeyboardInteractions,
  useStopEditingBlur,
} from 'media-plan-v2/components/spreadsheet/hooks';
import { SpreadsheetTooltip, ValidationTooltipContent } from 'media-plan-v2/components/spreadsheet/components';
import { AUDIENCE_AGE_VALIDATION_TEXT } from 'media-plan-v2/components/spreadsheet/constants';
import { MAX_AGE, MIN_AGE } from 'common/constants';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';

const classes = bem(BEM_CLASS);

export const AgeEditor = React.memo(
  React.forwardRef(
    ({ api, value: cellValue }: ICellEditorParams<SpreadsheetRowData, SpreadsheetRowData['age']>, ref) => {
      const [value, setValue] = useState(audienceAgeToString(cellValue.value));
      const [isValueInvalid] = useAudienceAgeValidation(cellValue.value);
      const { onBlur } = useStopEditingBlur(api);

      const { onKeyDown } = useEditorKeyboardInteractions({ api, value });

      useImperativeHandle(ref, () => ({
        getValue() {
          return {
            ...cellValue,
            value: ageStringToAudienceAge(value),
          };
        },
      }));

      const validationMessage = useMemo(() => <ValidationTooltipContent text={AUDIENCE_AGE_VALIDATION_TEXT} />, []);

      return (
        <SpreadsheetTooltip content={validationMessage} disabled={!isValueInvalid} visible={isValueInvalid}>
          <SAgeEditor onKeyDown={onKeyDown}>
            <AgeInput
              value={value}
              onChange={setValue}
              mask="99-99"
              placeholder={`${MIN_AGE}-${MAX_AGE}`}
              onBlur={onBlur}
              className={classes('age-input')}
              maskInputClassName={classes('mask-age-input')}
            />
          </SAgeEditor>
        </SpreadsheetTooltip>
      );
    }
  )
);
