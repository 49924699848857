import { Id, ProjectReviewer } from 'backend-api/models';
import { ReviewerFieldOption } from './types';

export const isReviewersHasProjectAccess = (reviewers: ProjectReviewer[], selectedReviewers: Id[]) =>
  selectedReviewers.every(id => {
    const reviewerData = reviewers.find(reviewer => reviewer.id === id);

    if (reviewerData) {
      return reviewerData.hasProjectAccess;
    } else {
      return false;
    }
  });

export const getWarningMessage = (isConfidentialProject: boolean): string => {
  if (isConfidentialProject) {
    return 'NOTE: This is a Confidential Project being shared to a user outside its label.';
  } else {
    return "The selected user doesn't belong to this label.";
  }
};

export const convertToSearchOption = ({ id, name, email }: ProjectReviewer): ReviewerFieldOption => ({
  id,
  title: name,
  description: email,
});
