import styled from 'styled-components';
import { COLUMN_COUNT, GRID_ITEM_GAP } from 'projects/components/small-artists-section/constants';

export const BEM_CLASS = 'small-artist-section';

interface Props {
  expansionRow?: number;
}

export const SSmallArtistsSection = styled.div<Props>`
  overflow: hidden;
  .${BEM_CLASS} {
    &__title-container {
      display: flex;
      align-items: center;
      margin-bottom: 5px;
    }
    &__expand-icon {
      margin-right: 12px;
      cursor: pointer;
    }
    &__artists-container {
      display: grid;
      grid-template-columns: repeat(${COLUMN_COUNT}, minmax(280px, 1fr));
      grid-template-rows: repeat(auto-fill, min-content);
      column-gap: ${GRID_ITEM_GAP}px;
    }
    &__expanded-section {
      grid-column: 1/-1;
      grid-row: ${({ expansionRow }) => expansionRow};
    }
    &__pagination-button {
      margin-top: 23px;
      grid-column: 1/-1;
      width: 138px;
      justify-self: center;
    }
  }
`;
