import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { colorPalette } from 'app/theme/colors';

interface STitleProps {
  readonly statusColor?: string;
}

export const STitle = styled.div<STitleProps>`
  display: flex;
  align-items: center;

  .title {
    &__name {
      overflow: hidden;
    }

    &__text {
      display: block;
      ${elipsizeText}
    }

    &__status-container {
      display: flex;
      align-items: center;
      background-color: ${props => props.statusColor || palette.pink};
      padding: 3px 11px 2px 10px;
      border-radius: 2px;
      margin-left: 10px;
    }

    &__status-text {
      color: ${palette.white};
      font-weight: 700;
      letter-spacing: 1px;
      font-size: 10px;
      text-transform: uppercase;
      white-space: nowrap;
    }

    &__status-icon {
      margin-right: 6px;
    }

    &__confidential-badge {
      padding: 2px 11px 1px 10px;
      border-color: ${colorPalette.hematite};
      margin-left: 10px;
    }
  }
`;
