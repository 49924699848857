import { Props } from 'react-select';
import { noop } from 'lodash';
import { SearchableCustomSelectProps } from '../../types';
import { isSelectCustomProps } from '../../guards';

export const getSearchableCustomSelectProps = <T, IsMulti extends boolean>(
  props: Props<T, IsMulti>
): Required<SearchableCustomSelectProps> => {
  const customPropsDefaultValues: Required<SearchableCustomSelectProps> = {
    isSearchableFromPopup: false,
    popupSearch: '',
    popupSearchHandler: noop,
    searchPlaceholder: '',
  };

  const customSelectProps = isSelectCustomProps<T, IsMulti, SearchableCustomSelectProps>(props)
    ? props?.customSelectProps
    : undefined;

  const {} = customSelectProps ?? {};

  return {
    ...customPropsDefaultValues,
    ...customSelectProps,
  };
};
