import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

interface SCardImageProps {
  width: number;
}

export const BEM_CLASS = 'card-image';

export const SCardImage = styled.div<SCardImageProps>`
  flex-shrink: 0;
  background-color: ${transparentize(0.95, manhattanThemeColors.primaryFillNew)};

  ${({ width }) => css`
    width: ${width}px;
  `}

  .${BEM_CLASS} {
    &__image {
      width: 100%;
      height: 100%;
    }

    &__loader {
      width: ${({ width }) => `${width}px`};
      height: 100%;
    }
  }
`;
