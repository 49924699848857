import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { timer } from 'rxjs';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useQuery } from 'hooks';
import { auth0AccessTokenSelector } from '../selectors';
import { ACTION } from '../reducer';

const mapStateToProps = createStructuredSelector({
  token: auth0AccessTokenSelector,
  //†prop
});
const mapDispatchToProps = {
  setAuth0Error: ACTION.setAuth0Error,
  //†action
};

const ConnectedAuthCallbackComponent = ({ token, setAuth0Error }) => {
  const history = useHistory();
  const { queryHash } = useQuery();

  useEffect(() => {
    const timer$ = timer(2000).subscribe(() => {
      if (token) {
        history.replace('/projects');
      } else {
        setAuth0Error(queryHash);
        history.replace('/login');
      }
    });
    return () => timer$.unsubscribe();
  }, []);

  return null;
};

export const AuthCallbackContainer = connect(mapStateToProps, mapDispatchToProps)(ConnectedAuthCallbackComponent);
