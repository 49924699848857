import { useField } from 'formik';
import { DatePicker, DATE_PICKER_TYPE, THEME, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React, { useCallback, useMemo } from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SDateRangeField } from './s-date-range-field';
import { projectDatesValidation } from './transducers';
import { DATE_INPUT_MASK } from './constants';

interface DateRangeFieldProps {
  name: string;
  dateFormat: string;
}

const classes = bem(BEM_CLASS);

export const DateRangeField = React.memo(({ name, dateFormat }: DateRangeFieldProps) => {
  const [field, meta] = useField({
    name: name,
    validate: projectDatesValidation,
  });

  const onApply = useCallback(
    dateRange => {
      field.onChange([dateRange.from, dateRange.to]);
    },
    [field]
  );

  const initialValue = useMemo(() => {
    if (!field.value) {
      return;
    }

    return { from: field.value[0], to: field.value[1] };
  }, [field]);

  return (
    <SDateRangeField>
      <DatePicker
        onApply={onApply}
        theme={THEME.light}
        initialValue={initialValue}
        type={DATE_PICKER_TYPE.Range}
        preferences={{ format: dateFormat, mask: DATE_INPUT_MASK, placeholder: dateFormat.toUpperCase() }}
        isRequired
      />
      {meta.error && (
        <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('error')}>
          {meta.error}
        </Typography>
      )}
    </SDateRangeField>
  );
});
