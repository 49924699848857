import React from 'react';
import { NoticeProps } from 'react-select';
import { TYPOGRAPHY_TYPE, Typography } from 'gdb-web-shared-components';
import { BaseOption as BaseOptionType } from '../../types';
import { SEmptySearch } from './s-empty-search';

type Props<T extends BaseOptionType, IsMulti extends boolean> = NoticeProps<T, IsMulti>;

export const Root = <T extends BaseOptionType, IsMulti extends boolean>({ innerProps }: Props<T, IsMulti>) => (
  <SEmptySearch {...innerProps} ref={null}>
    <Typography type={TYPOGRAPHY_TYPE.body4}>No results found.</Typography>
  </SEmptySearch>
);

export const EmptySearch = React.memo(Root) as typeof Root;
