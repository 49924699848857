import { actionsGroup } from 'core/store';
import { Campaign, CampaignsResponse } from 'backend-api/models';
import { AssignCampaignsToProjectPayload, CampaignsFilters, CampaignsRequestParams } from './types';

const { action, asyncAction } = actionsGroup('bulk-campaigns-assignment-modal');

export const openModal = action<void>('OPEN_BULK_ASSIGN_CAMPAIGN_TO_PROJECT_MODAL');
export const closeModal = action<void>('CLOSE_BULK_ASSIGN_CAMPAIGN_TO_PROJECT_MODAL');

export const setSelectedCampaigns = action<Campaign[]>('SET_SELECTED_CAMPAIGNS');
export const clearAllSelectedCampaigns = action<void>('CLEAR_ALL_SELECTED_CAMPAIGNS');
export const resetModal = action<void>('RESET_MODAL');

export const getAssignableCampaigns = asyncAction<
  CampaignsRequestParams,
  { isInfinite: boolean } & CampaignsResponse,
  Error
>('GET_ASSIGNABLE_CAMPAIGNS');

export const updateCampaignsFilters = action<Partial<CampaignsFilters>>('UPDATE_CAMPAIGNS_FILTER');

export const assignCampaignsToProject = asyncAction<AssignCampaignsToProjectPayload, unknown, Error>(
  'ASSIGN_CAMPAIGNS_TO_PROJECT'
);
