import React, { useCallback } from 'react';
import { ValueContainerProps } from 'react-select';
import { BUTTON_SIZE, BUTTON_TYPE, Button, THEME, TYPOGRAPHY_TYPE, Typography } from 'gdb-web-shared-components';
import { BaseOption as BaseOptionType } from '../../types';
import { getMultiCustomSelectProps } from '../../transducers';
import { BEM_CLASS, SValueContainer } from './s-value-container';
import { bem } from 'utils/bem';
import { callbackWithoutPropagation } from 'utils/event';

type Props<T, IsMulti extends boolean> = ValueContainerProps<T, IsMulti>;

export const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType, IsMulti extends boolean>({
  children,
  selectProps,
  getValue,
  clearValue,
  isMulti,
  innerProps,
}: Props<T, IsMulti>) => {
  const { classNames, dataSelector, valueContainerLabel } = getMultiCustomSelectProps(selectProps);

  const handleClearAll = useCallback(() => {
    clearValue();
  }, [clearValue]);

  const valueCount = getValue().length;
  const shouldShowFooter = (isMulti && valueCount > 0) || valueContainerLabel;

  const onMouseDown = useCallback((event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <SValueContainer
      className={classNames.valueContainer?.root}
      data-selector={`${dataSelector}-value-container`}
      isMulti={isMulti}
    >
      <div className={classes('container')}>
        <div {...innerProps} className={classes('values-container', undefined, classNames.valueContainer?.container)}>
          {children}
        </div>

        {shouldShowFooter && (
          <div className={classes('footer', undefined, classNames.valueContainer?.footer)}>
            {selectProps.isClearable && (
              <Button
                className={classes('clear')}
                size={BUTTON_SIZE.small}
                type={BUTTON_TYPE.tertiary}
                theme={THEME.light}
                caption="Clear All"
                disabled={valueCount === 0}
                onMouseDown={onMouseDown}
                onClick={callbackWithoutPropagation(handleClearAll)}
              />
            )}

            <Typography
              type={TYPOGRAPHY_TYPE.body4}
              className={classes('counter', undefined, classNames.valueContainer?.counter)}
            >
              {valueContainerLabel || valueCount}
            </Typography>
          </div>
        )}
      </div>
    </SValueContainer>
  );
};

export const ValueContainer = React.memo(Root) as typeof Root;
