import { ArtistSearch, Label, TeamUser } from 'backend-api/models';
import { ROLES } from 'common-v2/constants';
import { getJoinedNames } from 'common/transducers';
import { ViewTeamModalMode } from 'common/types/artist';
import React, { useCallback, useMemo } from 'react';
import { bem } from 'utils/bem';
import { Badge } from '../badge';
import { Icon } from '../icon';
import { AllProjectsLoader } from '../loaders/all-projects-loader';
import { getTeamMembers } from '../team/transducers';
import { TooltipedText } from '../tooltiped-text';
import { Body, H3 } from '../typography';
import { UserAvatar } from '../user-avatar';
import { BEM_CLASS, SViewTeamModal } from './s-view-team-modal';

interface Props {
  isOpened: boolean;
  isLoading?: boolean;
  users?: TeamUser[];
  mode: ViewTeamModalMode;
  onClose(): void;
  label?: Label;
  canEditArtistTeam?: boolean;
  artist?: ArtistSearch;
  onEditTeam?(): void;
  dataSelector?: string;
}

const classes = bem(BEM_CLASS);

export const ViewTeamModal = React.memo(
  ({
    isOpened,
    isLoading = false,
    users = [],
    mode,
    canEditArtistTeam = false,
    onClose,
    label,
    artist,
    onEditTeam,
  }: Props) => {
    const onEditTeamClick = useCallback(() => {
      if (!!onEditTeam && canEditArtistTeam && !!artist) {
        onEditTeam();
      }
    }, [artist, canEditArtistTeam, onEditTeam]);

    const isArtistTeamMode = mode === ViewTeamModalMode.ARTIST_TEAM && !!label && !!artist;

    const title = isArtistTeamMode
      ? `${artist?.name || 'Artist'}'s Team (${users.length})`
      : `Project Collaborators (${users.length})`;

    const footerTitle = `To edit the artist team go to ${artist?.name || 'Artist'} page.`;
    const shouldDisplayFooter = isArtistTeamMode && canEditArtistTeam;

    const list = useMemo(
      () =>
        getTeamMembers(users).map(user => {
          const { id, name, labels, roles } = user;
          const labelNames = getJoinedNames(labels);
          const roleNames = getJoinedNames(roles);
          const isEditorOrAdmin = roles?.some(({ id }) => id === ROLES.EDITOR.id || id === ROLES.ADMIN.id);
          const dataSelector = 'view-team-list-item';

          return (
            <div className={classes('list-item')} key={id} data-selector={dataSelector}>
              <UserAvatar name={name} size={40} className={classes('avatar')} isEditor={isEditorOrAdmin} />
              <div className={classes('name-container')}>
                <Body className={classes('name')} data-selector={`${dataSelector}-name`}>
                  {name}
                </Body>
                <TooltipedText text={labelNames} appendTo={document.body}>
                  <span className={classes('labels')} data-selector={`${dataSelector}-labels`}>
                    {labelNames}
                  </span>
                </TooltipedText>
              </div>
              <Body className={classes('role')} data-selector={`${dataSelector}-roles`}>
                {roleNames}
              </Body>
            </div>
          );
        }),
      [users]
    );

    return (
      <SViewTeamModal
        width="780px"
        hasHeader={false}
        hasFooter={false}
        isOpen={isOpened}
        onClose={onClose}
        dataSelector="view-team-modal"
      >
        <>
          <div className={classes('header')}>
            <div className={classes('title-container')}>
              <H3 className={classes('title')}>{title}</H3>
              {isArtistTeamMode && (
                <Badge text={label?.name || ''} dataSelector="label-badge" className={classes('label-badge')} />
              )}
            </div>
          </div>
          {isLoading ? (
            <div className={classes('loader')}>
              <AllProjectsLoader />
            </div>
          ) : (
            <div className={classes('list')}>{list}</div>
          )}
          {shouldDisplayFooter && (
            <div className={classes('footer')}>
              <Body className={classes('title')}>{footerTitle}</Body>
              <div
                className={classes('button')}
                onClick={onEditTeamClick}
                data-selector="view-team-modal-go-to-artist-page-button"
              >
                <Body className={classes('subtitle')}>Go to Artist Page</Body>
                <Icon name="arrow-right" color="blue" />
              </div>
            </div>
          )}
        </>
      </SViewTeamModal>
    );
  }
);
