import { ProjectTab } from 'common-v2/types';

export const PROJECT_TABS: ProjectTab[] = [
  ProjectTab.Summary,
  ProjectTab.MediaPlan,
  ProjectTab.Reporting,
  ProjectTab.Finance,
];

export const UNCLAIMED_PROJECT_RESTRICTED_TABS = [ProjectTab.MediaPlan, ProjectTab.Reporting];
