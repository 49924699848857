import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { typography } from 'app/theme/typography';
import { GRID_ITEM_WIDTH } from 'projects/components/small-artists-section/constants';
import { motion } from 'framer-motion';

export const BEM_CLASS = 'small-artist-item';

export const SSmallArtistItem = styled(motion.button)`
  display: flex;
  overflow: hidden;
  width: ${GRID_ITEM_WIDTH}px;
  margin-top: 20px;
  position: relative;
  text-align: start;

  :hover {
    .${BEM_CLASS}__icon {
      opacity: 1;
    }
  }
  .${BEM_CLASS} {
    &__info-container {
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
    &__title-container {
      display: flex;
      overflow: hidden;
      align-items: center;
      padding-right: 20px;
    }
    &__artist-image {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 40px;
      margin-right: 20px;
      background: ${colorPalette.mischka};
    }
    &__title {
      ${typography.h4};
    }
    &__days-badge {
      flex-shrink: 0;
      margin: 0 10px;
    }
    &__divider {
      height: 1px;
      width: 220px;
      opacity: 0.5;
      background-color: ${colorPalette.lavenderGray};
    }
    &__icon {
      position: absolute;
      right: 10px;
      top: 15px;
      flex-shrink: 0;
      transition: opacity 0.2s ease-out;
      opacity: 0;
    }
    &__projects-count {
      margin-bottom: 9px;
    }
  }
`;
