import { paths } from 'app/routing/paths';
import { Id, RequestApprovalRequest } from 'backend-api/models';
import { WaveBackground } from 'common/components/wave-background';
import { SpreadsheetContainer } from 'media-plan/containers/spreadsheet';
import { ProjectTab } from 'common-v2/types';
import { ProjectHeader } from 'common/containers/project-header';
import { activeProjectSelector } from 'common/selectors';
import { LoadingState } from 'common/types';
import {
  changeMediaPlanMode,
  clearApprovalRequests,
  clearMediaPlan,
  closeMediaPlanUpdatedWarningModal,
  getAllMediaPlans,
  getApprovalRequests,
  getMediaPlan,
  getMediaPlanMetrics,
  getMediaPlanReviewers,
  openMediaPlanUpdatedWarningModal,
  requestApproval,
  resetCampaignSelection,
  resetCampaignsForApproval,
  resetRequestApprovalModalInfo,
  setMediaPlanMode,
  setMediaPlanNotFoundModalOpened,
  setRequestApprovalModalInfo,
  setShowAllCampaignsInApproval,
  submitApproval,
} from 'media-plan/actions';
import { ConfirmReviewFooter, LoadingOverlay, RequestApprovalFooter } from 'media-plan/components';
import { SpreadsheetRef } from 'media-plan/components/spreadsheet';
import {
  approvalRequestsCampaignsSelector,
  approvalRequestsLoadingStateSelector,
  isMediaPlanNotFoundModalOpenedSelector,
  isMediaPlanUpdatedModalOpenedSelector,
  isRequestApprovalLoadingSelector,
  mediaPlanModeSelector,
  mediaPlansSelector,
  projectReviewersSelector,
  requestApprovalModalInfoSelector,
  reviewedCampaignsSelector,
  selectedCampaignsIdsSelector,
} from 'media-plan/selectors';
import { isLoadingStateAllowUILoading, splitAppovedDissaprovedCampaigns } from 'media-plan/transducers';
import { MediaPlanMode, MediaPlanModeWidgetConfig, MediaPlanReferrerType } from 'media-plan/types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionErrorModalContainer } from 'modals/permission-error-modal';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { bem } from 'utils/bem';
import { getSearchParamsFromLocation, navigateTo, replaceTo } from 'utils/navigation';
import { BEM_CLASS, SMediaPlanContainer } from './s-media-plan-container';
import { includesRole } from 'common/transducers';
import { MediaPlanWarningModal } from 'media-plan/components/media-plan-not-exist-modal';
import { useRedirectToFirstMediaPlan } from 'media-plan/hooks/use-redirect-to-first-media-plan';
import { MediaPlanHeaderContainer } from '../media-plan-header';
import { ROLES } from 'common-v2/constants';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';
import { Head } from 'app/components/head';
import { getProjectName } from 'common-v2/transducers';

interface MatchParams {
  id: string;
  mediaPlanId: string;
}

const classes = bem(BEM_CLASS);

export const MediaPlanContainer = React.memo(({ match }: RouteComponentProps<MatchParams>) => {
  const dispatch = useDispatch();

  const projectId = Number(match.params.id);
  const mediaPlanId = match.params.mediaPlanId === undefined ? undefined : Number(match.params.mediaPlanId);

  const location = useLocation();
  const history = useHistory();
  const params = getSearchParamsFromLocation(location);

  const spreadsheetRef = useRef<SpreadsheetRef>(null);

  const project = useSelector(activeProjectSelector);
  const mediaPlans = useSelector(mediaPlansSelector);
  const mediaPlanMode = useSelector(mediaPlanModeSelector);
  const selectedCampaignIds = useSelector(selectedCampaignsIdsSelector);
  const projectReviewers = useSelector(projectReviewersSelector);
  const requestApprovalLoadingState = useSelector(isRequestApprovalLoadingSelector);
  const reviewedCampaigns = useSelector(reviewedCampaignsSelector);
  const approvalRequestsCampaigns = useSelector(approvalRequestsCampaignsSelector);
  const approvalRequestsLoading = useSelector(approvalRequestsLoadingStateSelector);
  const requestApprovalModalInfo = useSelector(requestApprovalModalInfoSelector);
  const isMediaPlanNotFoundModalOpened = useSelector(isMediaPlanNotFoundModalOpenedSelector);
  const isMediaPlanUpdatedModalOpened = useSelector(isMediaPlanUpdatedModalOpenedSelector);
  useRedirectToFirstMediaPlan(mediaPlanId, projectId);

  useEffect(() => {
    if (!!project && !project.isClaimed) {
      navigateTo(paths.outdatedNotFound());
    }
  }, [project]);

  useEffect(() => {
    return () => {
      dispatch(clearMediaPlan());
    };
  }, [dispatch]);

  useEffect(() => {
    if (mediaPlans.loading === LoadingState.Idle) {
      dispatch(getAllMediaPlans.request(projectId));
    }
  }, [dispatch, mediaPlans.loading, projectId]);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const hasNothingToApprove =
    approvalRequestsLoading === LoadingState.Finished && approvalRequestsCampaigns.length === 0;

  const isApprovalModeEmpty = mediaPlanMode === MediaPlanMode.APPROVAL && hasNothingToApprove;

  useEffect(() => {
    if (approvalRequestsLoading !== LoadingState.Finished) return;
    if (!isApprovalModeEmpty) {
      return;
    }

    if (params.referrer === MediaPlanReferrerType.EMAIL) {
      dispatch(openMediaPlanUpdatedWarningModal());
    } else {
      replaceTo(paths.mediaPlan(projectId, mediaPlanId));
    }
  }, [dispatch, isApprovalModeEmpty, projectId, mediaPlanId, params.referrer, approvalRequestsLoading]);

  const isUserHasApprovalPermission = useMemo(
    () => !!project?.userRoles && includesRole(project.userRoles, ROLES.APPROVER),
    [project?.userRoles]
  );

  const submitRequestApproval = useCallback(
    (reviewers: Id[], message?: string) => {
      if (!mediaPlanId) return;
      const payload: RequestApprovalRequest = { campaigns: selectedCampaignIds, reviewers, message };
      trackEvent(AnalyticsEvents.APPROVAL_REQUEST_CONFIRMED, { projectId, campaignCount: selectedCampaignIds.length });
      dispatch(
        requestApproval.request({
          projectId,
          mediaPlanId,
          payload,
        })
      );
    },
    [selectedCampaignIds, dispatch, projectId, mediaPlanId]
  );

  const cancelRequestApproval = useCallback(() => {
    dispatch(changeMediaPlanMode(MediaPlanMode.DEFAULT));
    dispatch(resetCampaignSelection());
    dispatch(resetRequestApprovalModalInfo());
  }, [dispatch]);

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen(!isFullScreen);
  }, [isFullScreen]);

  useEffect(() => trackEvent(AnalyticsEvents.MEDIA_PLAN_VIEWED, { projectId }), [projectId]);

  useEffect(() => {
    document.body.style.overflow = isFullScreen ? 'hidden' : 'visible';
  }, [isFullScreen]);

  const onExportClick = useCallback(() => spreadsheetRef.current?.export(), []);

  useEffect(() => {
    if (!mediaPlanId) return;
    dispatch(getMediaPlanReviewers.request({ projectId, mediaPlanId }));
  }, [dispatch, mediaPlanId, projectId]);

  useEffect(() => {
    if (mediaPlanId === undefined) return;
    const enableApprovalMode = isUserHasApprovalPermission && params.mode === MediaPlanMode.APPROVAL;

    dispatch(getMediaPlan.request({ projectId, mediaPlanId }));
    dispatch(getApprovalRequests.request({ projectId, mediaPlanId }));

    if (enableApprovalMode) {
      trackEvent(AnalyticsEvents.APPROVAL_VIEW_OPENED_LINK, { projectId, mediaPlanId });
      dispatch(setMediaPlanMode(MediaPlanMode.APPROVAL));
    } else {
      dispatch(setMediaPlanMode(MediaPlanMode.DEFAULT));
      dispatch(clearApprovalRequests());
    }
    dispatch(getMediaPlanMetrics.request({ projectId, mediaPlanId }));

    return () => {
      dispatch(resetCampaignsForApproval());
    };
  }, [dispatch, params.mode, mediaPlanId, isUserHasApprovalPermission, projectId]);

  useEffect(() => {
    if (isApprovalModeEmpty) {
      dispatch(setShowAllCampaignsInApproval(true));
    }
  }, [dispatch, isApprovalModeEmpty]);

  const onSubmitSuccess = useCallback(() => {
    dispatch(resetCampaignsForApproval());
    replaceTo(paths.mediaPlan(projectId, mediaPlanId), { query: { mode: MediaPlanMode.APPROVAL } });
  }, [dispatch, mediaPlanId, projectId]);

  const confirmCampaignsReview = useCallback(() => {
    if (!mediaPlanId) return;
    const { approved, disapproved } = splitAppovedDissaprovedCampaigns(reviewedCampaigns);
    trackEvent(AnalyticsEvents.APPROVER_CONFIRM_REVIEW, {
      projectId,
      approvedCampaignsCount: approved.length,
      disapprovedCampaignsCount: disapproved.length,
    });
    dispatch(
      submitApproval.request({
        projectId,
        mediaPlanId,
        payload: { campaigns: reviewedCampaigns },
        onSubmitSuccess,
      })
    );
  }, [reviewedCampaigns, projectId, dispatch, mediaPlanId, onSubmitSuccess]);

  const goHome = useCallback(() => {
    navigateTo(paths.projects());
  }, []);

  const onRequestApprovalModalClosed = useCallback(
    (reviewers: Id[], message?: string) => {
      dispatch(setRequestApprovalModalInfo({ reviewers, message }));
    },
    [dispatch]
  );

  const widgetConfig = useMemo<MediaPlanModeWidgetConfig>(() => {
    const defaultConfig: MediaPlanModeWidgetConfig = {
      stickyFooter: null,
    };

    if (!mediaPlanId) return defaultConfig;

    switch (mediaPlanMode) {
      case MediaPlanMode.REQUEST_APPROVAL:
        return {
          ...defaultConfig,
          stickyFooter: (
            <div className={classes('footer')}>
              <RequestApprovalFooter
                count={selectedCampaignIds.length}
                reviewers={projectReviewers.data}
                onSubmit={submitRequestApproval}
                onCancel={cancelRequestApproval}
                isConfidentialProject={project?.isConfidential}
                onModalClosed={onRequestApprovalModalClosed}
                requestApprovalModalInfo={requestApprovalModalInfo}
              />
            </div>
          ),
        };
      case MediaPlanMode.APPROVAL:
        return {
          ...defaultConfig,
          stickyFooter: !isLoadingStateAllowUILoading(approvalRequestsLoading) ? (
            <div className={classes('footer')}>
              <ConfirmReviewFooter
                selectedCount={reviewedCampaigns.length}
                allCount={approvalRequestsCampaigns.length}
                onGoHome={goHome}
                onConfirm={confirmCampaignsReview}
                hasError={approvalRequestsLoading === LoadingState.Failed}
              />
            </div>
          ) : null,
        };
      default:
        return defaultConfig;
    }
  }, [
    mediaPlanId,
    mediaPlanMode,
    project,
    selectedCampaignIds.length,
    projectReviewers.data,
    submitRequestApproval,
    cancelRequestApproval,
    onRequestApprovalModalClosed,
    requestApprovalModalInfo,
    approvalRequestsLoading,
    reviewedCampaigns.length,
    approvalRequestsCampaigns.length,
    goHome,
    confirmCampaignsReview,
  ]);

  return (
    <>
      <Head title={getProjectName(project)} />
      <OutdatedGlobalLayout>
        <SMediaPlanContainer>
          {requestApprovalLoadingState === LoadingState.Started && <LoadingOverlay />}
          <ProjectHeader projectId={projectId} activeTab={ProjectTab.MediaPlan} />
          <WaveBackground className={classes('background', undefined, 'app__wrapper')}>
            <div className={classes('spreadsheet', { isFullScreen })}>
              <MediaPlanHeaderContainer
                projectId={projectId}
                mediaPlanId={mediaPlanId}
                isFullScreen={isFullScreen}
                onFullscreenClick={toggleFullScreen}
                onExportClick={onExportClick}
              />
              <SpreadsheetContainer
                ref={spreadsheetRef}
                projectId={projectId}
                mediaPlanId={mediaPlanId}
                className={classes('table')}
              />
              {widgetConfig.stickyFooter}
            </div>
            <MediaPlanWarningModal
              message="The Media Plan you are trying to open has been deleted and no longer exists."
              isOpen={isMediaPlanNotFoundModalOpened}
              onClose={() => dispatch(setMediaPlanNotFoundModalOpened(false))}
            />
            <MediaPlanWarningModal
              message="This Media Plan has been updated since you received the approval request."
              isOpen={isMediaPlanUpdatedModalOpened}
              onClose={() => {
                dispatch(closeMediaPlanUpdatedWarningModal());
                history.replace(paths.mediaPlan(projectId, mediaPlanId));
              }}
            />
            <PermissionErrorModalContainer />
          </WaveBackground>
        </SMediaPlanContainer>
      </OutdatedGlobalLayout>
    </>
  );
});
