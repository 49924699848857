import { createSelector } from 'reselect';
import { LoginState } from './reducer';

export const loginSelector = state => state.login as LoginState;

export const tokenSelector = createSelector(loginSelector, state => state.token);

export const tokenLoadingSelector = createSelector(loginSelector, state => state.isLoading);

export const accessTokenSelector = createSelector(tokenSelector, state => state?.access_token);

export const expiresTimestampSelector = createSelector(tokenSelector, state => state?.expires_timestamp);
