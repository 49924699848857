import React from 'react';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { BaseRenderer } from 'media-plan-v2/components/spreadsheet/components';
import { ICellRendererParams } from 'ag-grid-community';

export const TotalRenderer = ({ context }: ICellRendererParams) => (
  <BaseRenderer color={null} accessMode={context.accessMode}>
    <Typography type={TYPOGRAPHY_TYPE.body2}>Total</Typography>
  </BaseRenderer>
);
