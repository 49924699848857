import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Id } from 'backend-api/models';
import { ProjectHeader as ProjectHeaderComponent } from '../components/project-header';
import { ProjectTab } from 'common-v2/types';
import { clearProject, clearProjectError, getProject } from '../actions';
import { useQuery } from '../hooks';
import { activeProjectSelector, errorSelector } from '../selectors';
import { useProjectAccessRestricted } from 'common/hooks/use-project-access-restricted';
import { useRouteMatch } from 'react-router-dom';

interface Props {
  projectId: Id;
  activeTab?: ProjectTab;
  isNoActiveTab?: boolean;
  className?: string;
}

export const ProjectHeader = React.memo(({ projectId, ...props }: Props) => {
  const dispatch = useDispatch();
  const params = useQuery();
  const match = useRouteMatch<{ id: string }>();
  const project = useSelector(activeProjectSelector);

  useEffect(() => {
    const isRightProject = parseInt(match.params.id, 10) === project?.id;
    if (!isRightProject) {
      dispatch(clearProject());
    }
  }, [dispatch, match, project]);

  useEffect(() => {
    dispatch(
      getProject.request({
        projectId,
      })
    );

    return () => {
      dispatch(clearProjectError());
    };
  }, [dispatch, params.query, projectId]);

  useProjectAccessRestricted(errorSelector);

  return <ProjectHeaderComponent {...props} />;
});
