import { CurrentUserInfo, Label } from 'backend-api/models';
import { LabelAttributes, UserInfo } from './types';

const getLabelAttributeKey = (label: Label): string => `${label.name} (${label.id})`;

export const getLabelAttributes = (userLabels?: Label[], allLabels?: Label[]): LabelAttributes => {
  const attributes = {} as LabelAttributes;

  userLabels?.forEach(label => {
    const key = getLabelAttributeKey(label);
    attributes[key] = true;
  });

  allLabels?.forEach(label => {
    const key = getLabelAttributeKey(label);
    attributes[key] = attributes[key] || false;
  });

  return attributes;
};

export const getUserInfo = (profile?: CurrentUserInfo): UserInfo => {
  if (!profile) return {};

  return {
    name: profile.name,
    email: profile.email,
  };
};
