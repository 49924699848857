import styled from 'styled-components';

export const BEM_CLASS = 'header';

export const SHeader = styled.div`
  display: grid;
  grid-template-columns: 25px auto 25px;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 20px;
  overflow: hidden;

  &::before {
    content: '';
  }

  .${BEM_CLASS} {
    &__title {
      padding: 0 20px;
      text-align: center;
    }
  }
`;
