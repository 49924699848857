import { ArtistRosterProject, Id, MetricsReporting, Optional } from 'backend-api/models';
import { isNumber, sum } from 'lodash';
import { ALL_SNAPSHOT_PERIODS, SNAPSHOT_DAYS_OPTIONS } from 'projects/constants';
import {
  Delta,
  MetricsInfo,
  ProjectSnapshotParams,
  ProjectSnapshotsByProjectId,
  SnapshotConfig,
  SnapshotPeriod,
  SnapshotPeriodByProjectId,
  SnapshotValue,
} from 'projects/types';
import { notNullable } from 'utils/data';
import { formatShortenNumeral, getShortCurrencyFormatted } from 'utils/format';
import { getDiffInDays } from 'common-v2/utils';

export const projectMaxSnapshotPeriod = (project: ArtistRosterProject, date: Date): Optional<SnapshotPeriod> => {
  const days = getDiffInDays(date, project.startDate);
  const allowedPeriods = ALL_SNAPSHOT_PERIODS.filter(period => days >= period * 2);

  return allowedPeriods.length > 0 ? Math.max(...allowedPeriods) : undefined;
};

export const getProjectsMaxSnapshotPeriods = (projects: ArtistRosterProject[], date: Date) => {
  const result = {};
  return projects.reduce((result, project) => {
    const period = projectMaxSnapshotPeriod(project, date);

    if (!period) {
      return result;
    }

    return {
      ...result,
      [project.id.toString()]: projectMaxSnapshotPeriod(project, date),
    };
  }, result);
};

export const getParamsForProjectsSnapshots = (
  periodByProjectId: SnapshotPeriodByProjectId
): ProjectSnapshotParams[] => {
  return Object.entries(periodByProjectId).map(
    ([projectId, maxPeriod]): ProjectSnapshotParams => {
      return {
        projectId: Number(projectId),
        period: maxPeriod >= SnapshotPeriod.SEVEN_DAYS ? SnapshotPeriod.SEVEN_DAYS : SnapshotPeriod.ONE_DAY,
        maxPeriod,
      };
    }
  );
};

const sumOnlyNumbers = (array: Optional<number>[]): Optional<number> => {
  const nonOptionalArray = array.filter(isNumber);
  if (nonOptionalArray.length === 0) {
    return undefined;
  }

  return sum(nonOptionalArray);
};

export const getMetricsInfo = (reportingMetrics: MetricsReporting[]): MetricsInfo => {
  const metricsInfo: MetricsInfo = {};

  const totalStreamsValues: Optional<number>[] = [];
  const leanForwardStreamsValues: Optional<number>[] = [];
  const spendValues: Optional<number>[] = [];
  const impressionsValues: Optional<number>[] = [];
  const clicksValues: Optional<number>[] = [];
  const videoViewsValues: Optional<number>[] = [];

  reportingMetrics.forEach(metric => {
    const { streams, leanForwardStreams, spend, impressions, clicks, videoViews } = metric;

    totalStreamsValues.push(streams as Optional<number>);
    leanForwardStreamsValues.push(leanForwardStreams as Optional<number>);
    spendValues.push(spend as Optional<number>);
    impressionsValues.push(impressions as Optional<number>);
    clicksValues.push(clicks as Optional<number>);
    videoViewsValues.push(videoViews as Optional<number>);
  });

  metricsInfo.totalStreams = sumOnlyNumbers(totalStreamsValues);
  metricsInfo.leanForwardStreams = sumOnlyNumbers(leanForwardStreamsValues);
  metricsInfo.digitalSpend = sumOnlyNumbers(spendValues);
  metricsInfo.impressions = sumOnlyNumbers(impressionsValues);
  metricsInfo.clicks = sumOnlyNumbers(clicksValues);
  metricsInfo.videoViews = sumOnlyNumbers(videoViewsValues);

  return metricsInfo;
};

export const formatSnapshotValueValue = (value: SnapshotValue, currencyCode?: string) => {
  if (value.value === null || value.value === undefined) {
    return 'N/A';
  }

  return value.isCurrencyValue
    ? getShortCurrencyFormatted(value.value, currencyCode, true)
    : formatShortenNumeral(value.value);
};

const getValuesDelta = (value1?: number, value2?: number): Optional<Delta> => {
  if (value1 === null || value1 === undefined || value2 === null || value2 === undefined) {
    return undefined;
  }

  if (value1 < value2) {
    return Delta.SMALLER;
  } else if (value1 === value2) {
    return Delta.SAME;
  } else {
    return Delta.BIGGER;
  }
};

const getValuesPercentage = (value1?: number, value2?: number): Optional<Delta> => {
  if (value2 === 0) return 0;
  if (!value1 || !value2) return undefined;

  return Math.abs((value1 - value2) / value2);
};

export const processMetricsForPeriod = (metrics: MetricsReporting[], period: SnapshotPeriod): SnapshotValue[] => {
  const fullPeriodMetrics = metrics.slice(metrics.length - period * 2, metrics.length);

  const previousPeriodMetrics = fullPeriodMetrics.slice(0, period);
  const currentPeriodMetrics = fullPeriodMetrics.slice(period, period * 2);

  const currentMetricsInfo = getMetricsInfo(currentPeriodMetrics);
  const previousMetricsInfo = getMetricsInfo(previousPeriodMetrics);

  return [
    {
      name: 'Total Streams',
      value: currentMetricsInfo.totalStreams,
      delta: getValuesDelta(currentMetricsInfo.totalStreams, previousMetricsInfo.totalStreams),
      isCurrencyValue: false,
      percentage: getValuesPercentage(currentMetricsInfo.totalStreams, previousMetricsInfo.totalStreams),
    },
    {
      name: 'Lean Forward Streams',
      value: currentMetricsInfo.leanForwardStreams,
      delta: getValuesDelta(currentMetricsInfo.leanForwardStreams, previousMetricsInfo.leanForwardStreams),
      isCurrencyValue: false,
      percentage: getValuesPercentage(currentMetricsInfo.leanForwardStreams, previousMetricsInfo.leanForwardStreams),
    },
    {
      name: 'Digital Spend',
      value: currentMetricsInfo.digitalSpend,
      delta: getValuesDelta(currentMetricsInfo.digitalSpend, previousMetricsInfo.digitalSpend),
      isCurrencyValue: true,
      percentage: getValuesPercentage(currentMetricsInfo.digitalSpend, previousMetricsInfo.digitalSpend),
    },
    {
      name: 'Impressions',
      value: currentMetricsInfo.impressions,
      delta: getValuesDelta(currentMetricsInfo.impressions, previousMetricsInfo.impressions),
      isCurrencyValue: false,
      percentage: getValuesPercentage(currentMetricsInfo.impressions, previousMetricsInfo.impressions),
    },
    {
      name: 'Clicks',
      value: currentMetricsInfo.clicks,
      delta: getValuesDelta(currentMetricsInfo.clicks, previousMetricsInfo.clicks),
      isCurrencyValue: false,
      percentage: getValuesPercentage(currentMetricsInfo.clicks, previousMetricsInfo.clicks),
    },
    {
      name: 'Video Views',
      value: currentMetricsInfo.videoViews,
      delta: getValuesDelta(currentMetricsInfo.videoViews, previousMetricsInfo.videoViews),
      isCurrencyValue: false,
      percentage: getValuesPercentage(currentMetricsInfo.videoViews, previousMetricsInfo.videoViews),
    },
  ];
};

const getDatesOption = (maxPeriod: SnapshotPeriod) => {
  return SNAPSHOT_DAYS_OPTIONS.map(option => ({
    ...option,
    isDisabled: option.id > maxPeriod,
  }));
};

export const getProjectsSnapshotConfigs = (
  projects: ArtistRosterProject[],
  snapshots: ProjectSnapshotsByProjectId,
  onSelectDateOption: (projectId: Id, optionId: Id) => void
): SnapshotConfig[] => {
  const configs = projects
    .map(project => {
      if (snapshots[project.id] === undefined) {
        return undefined;
      }
      const state = snapshots[project.id];

      const { loading, data } = state.metrics;

      const config: SnapshotConfig = {
        projectId: project.id,
        datesOptions: getDatesOption(state.maxPeriod),
        selectedDateOptionId: state.period,
        onSelectDateOption: onSelectDateOption,
        values: processMetricsForPeriod(data, state.period),
        loadingState: loading,
        currencyCode: project.currency,
      };

      return config;
    })
    .filter(notNullable);

  return configs;
};
