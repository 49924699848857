import React from 'react';
import { bem } from 'utils/bem';
import { Checkbox } from 'common/components/checkbox';
import { STreeItem } from './s-tree-item';

interface Props {
  isChecked: boolean;
  isHalfChecked: boolean;
  isDisabled?: boolean;
  name: string;
  description?: string;
  depth: number;
  onClick(checked: boolean): void;
  className?: string;
  isEmpty: boolean;
}

const classes = bem('tree-item');

export const TreeItem = React.memo(
  ({ isChecked, isHalfChecked, name, description, depth, onClick, className, isDisabled = false, isEmpty }: Props) => {
    return (
      <STreeItem depth={depth} className={className} isDisabled={isDisabled} isEmpty={isEmpty}>
        <Checkbox
          isChecked={isChecked}
          halfChecked={isHalfChecked}
          name={name}
          description={description}
          label={name}
          onClick={onClick}
          labelClassName={classes('label')}
        />
      </STreeItem>
    );
  }
);
