import styled, { css } from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'approvers-control';

interface Props {
  hasError?: boolean;
  isFocused?: boolean;
}

export const SApproversControl = styled.div<Props>`
  display: flex;
  height: 37px;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: ${colorPalette.ghostWhite};

  ${({ isFocused, hasError }) =>
    isFocused
      ? css`
          border-color: ${colorPalette.azureRadiance};
          background-color: ${colorPalette.white};
        `
      : hasError
      ? css`
          border-color: ${colorPalette.alizarinCrimson};
        `
      : ``};

  .${BEM_CLASS} {
    &__control-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      font-size: 24px;
      margin-left: 12px;
      color: ${colorPalette.wildBlueYonder};
    }
  }
`;
