import styled from 'styled-components';
import { motion } from 'framer-motion';

const MAX_NOTES_LINE = 5;
const NOTES_LINE_HEIGHT = 16;

interface Props {
  shouldNotesExpand?: boolean;
}

export const SNotesWidget = styled(motion.div)<Props>`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: flex-start;
  background-color: white;
  padding: 30px 30px ${({ shouldNotesExpand }) => (shouldNotesExpand ? 0 : 25)}px;
  margin-bottom: ${({ shouldNotesExpand }) => (shouldNotesExpand ? 20 : 0)}px;
  .notes-widget {
    &__loader {
      margin: auto;
    }
    &__title {
      color: black;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 25px;
    }
    &__editor {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 25px;
      width: 100%;
      line-height: ${NOTES_LINE_HEIGHT}px;
      max-height: ${NOTES_LINE_HEIGHT * MAX_NOTES_LINE}px;
    }
    &__dots {
      margin-top: 5px;
      margin-bottom: ${({ shouldNotesExpand }) => (shouldNotesExpand ? -15 : 0)}px;
    }
    &__button {
      align-self: center;
      transform: translate(0%, 50%);
    }
  }
`;
