import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'multi-value-container';

export const SMultiValueView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  .${BEM_CLASS} {
    &__placeholder {
      color: ${colorPalette.wildBlueYonder};
    }

    &__labels-container {
      overflow: hidden;
      flex-wrap: wrap;
      display: flex;
      flex: 1;
      height: 25px;
      gap: 5px;
      row-gap: 50px;
    }

    &__hidden-values {
      margin-left: 10px;
    }
  }
`;
