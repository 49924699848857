import { animatedFlare } from 'common/s-components/s-animated-flare';
import styled from 'styled-components';

interface Props {
  clipPathId: string;
}

export const SPieChartLoader = styled.div<Props>`
  height: 130px;
  width: 130px;
  .loader-pie-chart {
    &__clip-container {
      height: 130px;
      width: 130px;
      clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      -webkit-clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      ${animatedFlare}
    }
  }
`;
