import {
  ArtistMomentCategory,
  ArtistMomentTypeGroup,
  AssignableProject,
  BreakdownOption,
  CampaignProvider,
  CampaignStatus,
  CountryCode,
  Currency,
  CurrentUserInfo,
  Gender,
  GlobalSearch,
  Goal,
  Label,
  LabelUser,
  LinkfireSearch,
  Optional,
  PerformanceObjective,
  PerformancePlatforms,
  ProjectDetails,
  ProjectReleaseType,
  ProjectStatus,
  RecentSearchItem,
  Taxonomy,
  Territory,
  UuidNameField,
} from 'backend-api/models';
import {
  clearProject,
  clearProjectError,
  closeAssignCampaignToProjectModal,
  closeTeamModal,
  flush,
  getAllLabels,
  getArtistMomentCategories,
  getArtistMomentTypes,
  getCampaignProviders,
  getCampaignStatuses,
  getCampaignTaxonomy,
  getCountryCodes,
  getCurrencies,
  getGenders,
  getGlobalSearch,
  getGoals,
  getLabels,
  getLabelUsers,
  getPerformanceArtistsBreakdowns,
  getPerformanceCampaignProviders,
  getPerformanceCampaignsBreakdowns,
  getPerformanceObjectives,
  getPerformancePlatforms,
  getPerformanceProjectsBreakdowns,
  getProject,
  getProjectReleaseTypes,
  getProjectStatuses,
  getRecentSearch,
  getRecommendedProjectsForAssignModal,
  getTerritories,
  getUserInfo,
  hideGlobalSearch,
  openAssignCampaignToProjectModal,
  openTeamModal,
  removeToast,
  removeToasts,
  searchLinkfireLinks,
  searchProjectsForAssignModal,
  setActiveFiltersName,
  setDateFormat,
  setFilters,
  setGlobalLabel,
  setGlobalSearchActiveTab,
  setGlobalSearchQuery,
  setProjectOwner,
  setAccessRestrictedData,
  resetAccessRestrictedData,
  showGlobalSearch,
  showToast,
  getGlobalSearchPage,
  resetGlobalSearch,
  resetRecentSearch,
} from 'common/actions';
import { createTypedReducer, onAction } from 'core/store';
import { ToastOptions } from 'common/components/toast';
import { CurrentProjectOwner, LoadableData, LoadingState } from 'common/types';
import { SearchTab } from 'app/components/search-modal/components/search-tabs/types';
import { getAllObjectivesWithFakeAllMetricsObjective } from 'common/transducers/performance';
import { AttachReleasesModalState, AttachReleasesTab } from 'common/types/phases';
import { Sort, SortOrder } from 'utils/sort';
import { attachReleasesActionsReducer } from './attach-releases-reducer';
import { ApiError } from 'backend-api';
import { INITIAL_DATE_FORMAT, INITIAL_DATE_TIME_FORMAT } from 'common/constants';
import { ProjectDetailsErrorResponse, ShortUserInfoResponse } from 'backend-api/types';
import { DATE_FORMAT_BY_DATE_TIME_FORMAT } from 'settings/constants';
import { convertFromIdToDateFormat } from 'common-v2/transducers';
import storage from 'redux-persist/lib/storage';
import persistReducer from 'redux-persist/lib/persistReducer';
import { Loadable, Loading } from 'common-v2/types';
import { mergeWith } from 'lodash';

interface AssignCampaignToProjectModal {
  campaign?: UuidNameField;
  isOpened: boolean;
  isLoading: boolean;
  searchedProjects: AssignableProject[];
  recommendedProjects: AssignableProject[];
}

export interface GlobalSearchState {
  isActive: boolean;
  query: string;
  recent: LoadableData<RecentSearchItem[]>;
  top: {
    loading: LoadingState;
    pageLoading: LoadingState;
    data?: GlobalSearch;
  };
  activeTab: SearchTab;
}

export interface Settings {
  dateFormat: string;
  dateTimeFormat: string;
}

const persistConfig = {
  key: 'common',
  storage,
  whitelist: ['globalLabel'],
};

export interface CommonState {
  labels: Loadable<Optional<Label[]>>;
  userInfo: Loadable<Optional<CurrentUserInfo>>;
  allLabels?: Label[];
  campaignTaxonomy?: Taxonomy;
  territories?: Territory[];
  projectStatus?: ProjectStatus[];
  currencies?: Currency[];
  filters?: any;
  activeFiltersName?: string;
  labelUsers: LabelUser[];
  goals?: Goal[];
  genders?: Gender[];
  campaignProviders?: CampaignProvider[];
  performanceCampaignProviders?: CampaignProvider[];
  project: LoadableData<Optional<ProjectDetails>>;
  countryCodes?: CountryCode[];
  performanceObjectives?: PerformanceObjective[];
  performancePlatforms?: PerformancePlatforms;
  performanceArtistsBreakdowns?: BreakdownOption[];
  performanceCampaignsBreakdowns?: BreakdownOption[];
  performanceProjectsBreakdowns?: BreakdownOption[];
  performanceUnassignedCampaignsBreakdowns?: BreakdownOption[];
  assignCampaignToProjectModal: AssignCampaignToProjectModal;
  toasts: ToastOptions[];
  artistMomentTypes?: ArtistMomentTypeGroup[];
  artistMomentCategories?: ArtistMomentCategory[];
  globalSearch: GlobalSearchState;
  linkfireLinks: LinkfireSearch[];
  isLinkfireLinksLoading: boolean;
  attachReleasesModal: AttachReleasesModalState;
  isTeamModalOpened: boolean;
  currentProjectOwner?: CurrentProjectOwner;
  accessRestrictedData?: {
    currentTeamMembers?: ShortUserInfoResponse[];
    currentProjectName?: string;
    currentProjectId?: number;
  };
  campaignStatuses?: CampaignStatus[];
  error?: ApiError<ProjectDetailsErrorResponse>;
  settings: Settings;
  projectReleaseTypes?: ProjectReleaseType[];
  globalLabel?: Label;
}

export const initialState: CommonState = {
  userInfo: {
    loading: Loading.Idle,
    data: undefined,
  },
  labels: {
    loading: Loading.Idle,
    data: undefined,
  },
  project: {
    loading: LoadingState.Idle,
    data: undefined,
  },
  labelUsers: [],
  assignCampaignToProjectModal: {
    isOpened: false,
    isLoading: false,
    searchedProjects: [],
    recommendedProjects: [],
  },
  toasts: [],
  globalSearch: {
    isActive: false,
    query: '',
    recent: {
      loading: LoadingState.Idle,
      data: [],
    },
    top: {
      loading: LoadingState.Idle,
      pageLoading: LoadingState.Idle,
      data: undefined,
    },
    activeTab: SearchTab.Top,
  },
  linkfireLinks: [],
  isLinkfireLinksLoading: false,
  attachReleasesModal: {
    isOpened: false,
    activeTab: AttachReleasesTab.ProductFamilies,
    productFamiliesState: {
      selected: [],
      search: '',
      sort: new Sort(SortOrder.Descending, ''),
      loading: LoadingState.Idle,
      expandedProductFamiliesIds: [],
    },
    playlistsState: {
      data: [],
      selected: [],
      search: '',
      loading: LoadingState.Idle,
    },
    allProductFamilies: { loading: LoadingState.Idle, data: [] },
  },
  isTeamModalOpened: false,
  settings: {
    dateFormat: INITIAL_DATE_FORMAT,
    dateTimeFormat: INITIAL_DATE_TIME_FORMAT,
  },
};

export const commonReducer = createTypedReducer<CommonState>(
  initialState,
  onAction(setFilters, (state, action) => {
    const { activeFiltersName } = state;
    const pendingState = {
      ...state,
      filters: {
        ...state.filters,
      },
    };
    if (activeFiltersName) {
      pendingState.filters[activeFiltersName] =
        action.payload === null ? state.filters?.[activeFiltersName] : action.payload;
    }
    return pendingState;
  }),
  onAction(setActiveFiltersName, (state, action) => ({ ...state, activeFiltersName: action.payload })),
  onAction(flush, () => initialState),
  onAction(getLabels.request, state => ({ ...state, labels: { loading: Loading.Started, data: undefined } })),
  onAction(getLabels.success, (state, { payload }) => ({
    ...state,
    labels: { loading: Loading.Finished, data: payload },
  })),
  onAction(getLabels.failure, state => ({ ...state, labels: { loading: Loading.Failed, data: undefined } })),
  onAction(getAllLabels.success, (state, action) => ({ ...state, allLabels: action.payload })),
  onAction(getCampaignTaxonomy.success, (state, action) => ({ ...state, campaignTaxonomy: action.payload })),
  onAction(getGoals.success, (state, action) => ({ ...state, goals: action.payload })),
  onAction(getGenders.success, (state, action) => ({ ...state, genders: action.payload })),
  onAction(getCampaignProviders.success, (state, action) => ({ ...state, campaignProviders: action.payload })),
  onAction(getPerformanceCampaignProviders.success, (state, action) => ({
    ...state,
    performanceCampaignProviders: action.payload,
  })),
  onAction(getTerritories.success, (state, action) => ({ ...state, territories: action.payload })),
  onAction(getProjectStatuses.success, (state, action) => ({ ...state, projectStatus: action.payload })),
  onAction(getCurrencies.success, (state, action) => ({ ...state, currencies: action.payload })),
  onAction(getLabelUsers.success, (state, action) => ({ ...state, labelUsers: action.payload })),
  onAction(getUserInfo.request, state => ({ ...state, userInfo: { loading: Loading.Started, data: undefined } })),
  onAction(getUserInfo.success, (state, { payload: { currentUser } }) => ({
    ...state,
    userInfo: { loading: Loading.Finished, data: currentUser },
    settings: {
      ...state.settings,
      dateTimeFormat: convertFromIdToDateFormat(currentUser.dateTimeFormat),
      dateFormat: DATE_FORMAT_BY_DATE_TIME_FORMAT[convertFromIdToDateFormat(currentUser.dateTimeFormat)],
    },
  })),
  onAction(getUserInfo.failure, state => ({ ...state, userInfo: { loading: Loading.Failed, data: undefined } })),
  onAction(getProject.request, state => ({
    ...state,
    project: {
      ...state.project,
      loading: LoadingState.Started,
    },
  })),
  onAction(getProject.success, (state, action) => ({
    ...state,
    project: {
      loading: LoadingState.Finished,
      data: action.payload,
    },
  })),
  onAction(getProject.failure, (state, action) => ({
    ...state,
    project: {
      ...state.project,
      loading: LoadingState.Failed,
    },
    error: action.payload,
  })),
  onAction(clearProject, state => ({
    ...state,
    project: {
      loading: LoadingState.Idle,
      data: undefined,
    },
  })),
  onAction(clearProjectError, state => {
    return {
      ...state,
      error: undefined,
    };
  }),
  onAction(getCountryCodes.success, (state, action) => ({ ...state, countryCodes: action.payload })),
  onAction(getPerformanceObjectives.success, (state, action) => ({
    ...state,
    performanceObjectives: getAllObjectivesWithFakeAllMetricsObjective(action.payload),
  })),
  onAction(getPerformancePlatforms.success, (state, action) => ({
    ...state,
    performancePlatforms: action.payload,
  })),

  onAction(getPerformanceArtistsBreakdowns.success, (state, action) => ({
    ...state,
    performanceArtistsBreakdowns: action.payload,
  })),
  onAction(getPerformanceProjectsBreakdowns.success, (state, action) => ({
    ...state,
    performanceProjectsBreakdowns: action.payload,
  })),
  onAction(getPerformanceCampaignsBreakdowns.success, (state, action) => ({
    ...state,
    performanceCampaignsBreakdowns: action.payload,
  })),
  onAction(getCampaignStatuses.success, (state, action) => ({
    ...state,
    campaignStatuses: action.payload,
  })),
  onAction(openAssignCampaignToProjectModal, (state, action) => ({
    ...state,
    assignCampaignToProjectModal: {
      ...state.assignCampaignToProjectModal,
      campaign: action.payload,
      isOpened: true,
    },
  })),
  onAction(closeAssignCampaignToProjectModal, state => ({
    ...state,
    assignCampaignToProjectModal: {
      ...state.assignCampaignToProjectModal,
      campaign: undefined,
      isOpened: false,
      isLoading: false,
      searchedProjects: [],
      recommendedProjects: [],
    },
  })),
  onAction(searchProjectsForAssignModal.request, state => ({
    ...state,
    assignCampaignToProjectModal: {
      ...state.assignCampaignToProjectModal,
      isLoading: true,
    },
  })),
  onAction(searchProjectsForAssignModal.success, (state, action) => ({
    ...state,
    assignCampaignToProjectModal: {
      ...state.assignCampaignToProjectModal,
      isLoading: false,
      searchedProjects: action.payload.items,
    },
  })),
  onAction(searchProjectsForAssignModal.failure, state => ({
    ...state,
    assignCampaignToProjectModal: {
      ...state.assignCampaignToProjectModal,
      isLoading: false,
    },
  })),
  onAction(getRecommendedProjectsForAssignModal.request, state => ({
    ...state,
    assignCampaignToProjectModal: {
      ...state.assignCampaignToProjectModal,
      isLoading: true,
    },
  })),
  onAction(getRecommendedProjectsForAssignModal.success, (state, action) => ({
    ...state,
    assignCampaignToProjectModal: {
      ...state.assignCampaignToProjectModal,
      isLoading: false,
      recommendedProjects: action.payload.items,
    },
  })),
  onAction(getRecommendedProjectsForAssignModal.failure, state => ({
    ...state,
    assignCampaignToProjectModal: {
      ...state.assignCampaignToProjectModal,
      isLoading: false,
    },
  })),
  onAction(showToast, (state, { payload }) => {
    let filteredState = state.toasts;

    if (payload.replaceable) {
      filteredState = filteredState.filter(toast => toast.id !== payload.id);
    }

    return {
      ...state,
      toasts: [...filteredState, payload],
    };
  }),
  onAction(removeToast, (state, { payload }) => {
    const [, ...rest] = state.toasts;

    return {
      ...state,
      toasts: payload ? state.toasts.filter(({ id }) => id !== payload) : rest,
    };
  }),
  onAction(removeToasts, state => ({
    ...state,
    toasts: [],
  })),
  onAction(getArtistMomentTypes.success, (state, action) => ({ ...state, artistMomentTypes: action.payload })),
  onAction(getArtistMomentCategories.success, (state, action) => ({
    ...state,
    artistMomentCategories: action.payload,
  })),
  onAction(showGlobalSearch, state => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      isActive: true,
      activeTab: SearchTab.Top,
    },
  })),
  onAction(hideGlobalSearch, state => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      isActive: false,
      query: '',
      recent: {
        loading: LoadingState.Idle,
        data: [],
      },
    },
  })),
  onAction(setGlobalSearchQuery, (state, { payload }) => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      query: payload,
      activeTab: SearchTab.Top,
    },
  })),
  onAction(getGlobalSearch.request, state => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      top: {
        ...state.globalSearch.top,
        loading: LoadingState.Started,
      },
    },
  })),
  onAction(getGlobalSearch.success, (state, { payload }) => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      top: {
        ...state.globalSearch.top,
        loading: LoadingState.Finished,
        data: payload,
      },
    },
  })),
  onAction(getGlobalSearch.failure, state => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      top: {
        ...state.globalSearch.top,
        loading: LoadingState.Failed,
      },
    },
  })),
  onAction(getRecentSearch.request, state => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      recent: {
        ...state.globalSearch.recent,
        loading: LoadingState.Started,
      },
    },
  })),
  onAction(getRecentSearch.success, (state, { payload }) => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      recent: {
        loading: LoadingState.Finished,
        data: payload,
      },
    },
  })),
  onAction(getRecentSearch.failure, state => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      recent: {
        ...state.globalSearch.recent,
        loading: LoadingState.Failed,
      },
    },
  })),
  onAction(setGlobalSearchActiveTab, (state, { payload }) => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      activeTab: payload,
    },
  })),
  onAction(searchLinkfireLinks.request, state => ({
    ...state,
    isLinkfireLinksLoading: true,
    linkfireLinks: [],
  })),
  onAction(searchLinkfireLinks.success, (state, action) => ({
    ...state,
    isLinkfireLinksLoading: false,
    linkfireLinks: action.payload,
  })),
  onAction(openTeamModal, state => ({
    ...state,
    isTeamModalOpened: true,
  })),
  onAction(closeTeamModal, state => ({
    ...state,
    isTeamModalOpened: false,
  })),
  onAction(setProjectOwner, (state, action) => ({
    ...state,
    currentProjectOwner: action.payload,
  })),
  onAction(setAccessRestrictedData, (state, action) => ({ ...state, accessRestrictedData: action.payload })),
  onAction(resetAccessRestrictedData, state => ({ ...state, accessRestrictedData: undefined })),
  ...attachReleasesActionsReducer,
  onAction(setDateFormat, (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      dateFormat: action.payload.dateFormat,
      dateTimeFormat: action.payload.dateTimeFormat,
    },
  })),
  onAction(getProjectReleaseTypes.success, (state, { payload }) => ({
    ...state,
    projectReleaseTypes: payload,
  })),
  onAction(setGlobalLabel, (state, action) => ({
    ...state,
    globalLabel: action.payload,
  })),
  onAction(getGlobalSearchPage.request, state => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      top: {
        ...state.globalSearch.top,
        pageLoading: LoadingState.Started,
      },
    },
  })),
  onAction(getGlobalSearchPage.success, (state, { payload }) => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      top: {
        ...state.globalSearch.top,
        pageLoading: LoadingState.Finished,
        data: mergeWith({}, state.globalSearch.top.data, payload, (value, payloadValue) => {
          if (Array.isArray(value)) {
            return value.concat(payloadValue);
          }

          return payloadValue;
        }),
      },
    },
  })),
  onAction(getGlobalSearchPage.failure, state => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      top: {
        ...state.globalSearch.top,
        pageLoading: LoadingState.Failed,
      },
    },
  })),
  onAction(resetGlobalSearch, state => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      top: initialState.globalSearch.top,
    },
  })),
  onAction(resetRecentSearch, state => ({
    ...state,
    globalSearch: {
      ...state.globalSearch,
      recent: initialState.globalSearch.recent,
    },
  }))
);

export const reducer = persistReducer(persistConfig, commonReducer);
