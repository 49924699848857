import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'marketing-mix-analytics';

export const SMarketingMixAnalytics = styled.div`
  display: flex;
  flex-direction: column;

  .${BEM_CLASS} {
    &__total {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      font-weight: 700;
      color: ${palette.black};
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__chart {
      flex: 0 1 auto;
      overflow: hidden;
      position: relative;
    }

    &__list {
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 30px 0 0;
    }

    &__table {
      flex: 1;
      width: 100%;
    }

    &__table-row {
      display: flex;
      justify-content: space-between;
      line-height: 1;
      padding: 7.5px 0px;
    }

    &__table-row-element {
      display: flex;
    }

    &__table-row-title {
      font-size: 14px;
      font-weight: 400;
      height: 15px;
      color: ${palette.darkBlue};
      align-self: center;
    }

    &__table-row-percentage,
    &__table-row-budget {
      font-size: 14px;
      line-height: 15px;
      font-weight: 400;
      color: ${palette.darkBlue};
      align-self: center;
    }

    &__table-row-budget {
      font-weight: 600;
    }

    &__digital-selector {
      padding-top: 2px;
    }

    &__currency-code {
      font-weight: 400;
      color: ${colorPalette.eastBay};
    }
  }
`;
