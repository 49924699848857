import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'edit-modal';

interface SEditModalProps {
  width: number;
  height: number;
}

export const SEditModal = styled.div<SEditModalProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;

  .${BEM_CLASS} {
    &__header {
      flex-shrink: 0;
      display: grid;
      grid-template-columns: 25px auto 25px;
      width: 100%;
      align-items: center;
      padding: 23px 20px 20px;

      &::before {
        content: '';
      }
    }

    &__title {
      text-align: center;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 0 42.5px;
      overflow: hidden;
    }

    &__input-container {
      margin: 0 7.5px 30px;
    }

    &__input {
      width: 100%;
    }

    &__list {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &__loader {
      margin: 0 7.5px;
    }

    &__artists-list {
      margin-top: 15.5px;
    }

    &__recent-artists {
      margin-top: 15px;
    }

    &__footer {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 40px 40px;
      gap: 20px;
    }

    &__button {
      min-width: 180px;
    }

    &__empty {
      width: 100%;
      margin: auto;
      text-align: center;
      display: flex;
      justify-content: center;
      color: ${transparentize(0.5, manhattanThemeColors.textHeaderColor)};
    }

    &__error {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      color: ${transparentize(0.3, manhattanThemeColors.textHeaderColor)};
    }
  }
`;
