import styled from 'styled-components';
import { motion } from 'framer-motion';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'artist-team-widget';

export const SArtistTeamWidget = styled(motion.div)`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: flex-start;
  background-color: ${colorPalette.white};
  padding: 24px 30px;

  .${BEM_CLASS} {
    &__loader {
      min-height: 95px;
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__team {
      width: fit-content;
      margin-top: 17px;
      margin-bottom: 23px;
    }

    &__name {
      font-weight: 600;
      margin-top: 6px;
    }

    &__email {
      margin-top: 9px;
    }

    &__phone {
      margin-top: 5px;
    }

    &__button {
      display: flex;
      align-items: center;
      column-gap: 9px;
      cursor: pointer;
    }

    &__subtitle {
      font-weight: 600;
      color: ${colorPalette.azureRadiance};
    }
  }
`;
