import React, { useMemo } from 'react';
import { SProjectSearchItem } from './s-project-search-item';
import { TooltipedText } from 'common/components/tooltiped-text';
import { ProjectSearch } from 'backend-api/models';
import { InfoContent } from '../../../info-content';
import { SPoint, STitle } from 'app/components/search-modal/styled';
import { aggregateTargetsNames } from 'app/components/search-modal/transducers';
import { getPrimaryTargets } from 'common/transducers';
import { Badge } from 'common/components/badge';
import { ConfidentialProjectIcon } from 'common/components/confidential-project-icon';

interface ProjectSearchItemProps {
  project: ProjectSearch;
}

export const ProjectSearchItem = React.memo(
  ({ project: { name, startDate, targets, isClaimed, isConfidential } }: ProjectSearchItemProps) => {
    const primaryTargets = useMemo(() => getPrimaryTargets(targets.items), [targets.items]);
    const names = useMemo(() => {
      const aggregatedNames = aggregateTargetsNames(primaryTargets);

      return (
        <>
          {!isClaimed && (
            <>
              <Badge text="Unclaimed" />
              <SPoint />
            </>
          )}
          <TooltipedText text={aggregatedNames}>
            <span>{aggregatedNames}</span>
          </TooltipedText>
        </>
      );
    }, [primaryTargets, isClaimed]);

    return (
      <SProjectSearchItem>
        <ConfidentialProjectIcon isConfidential={isConfidential} color="black">
          <TooltipedText text={name}>
            <STitle>{name}</STitle>
          </TooltipedText>
        </ConfidentialProjectIcon>
        <InfoContent date={startDate} content={names} />
      </SProjectSearchItem>
    );
  }
);
