import React from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';
import { SInputWrapper } from 'common/components/form/input/s-input';

interface Props extends InputMaskProps {
  dataSelector?: string;
}

export const MaskedInput = React.memo(({ dataSelector, ...restProps }: Props) => {
  return (
    <SInputWrapper {...restProps}>
      <InputMask data-selector={dataSelector} {...restProps} />
    </SInputWrapper>
  );
});
