import React from 'react';
import { ProjectMetrics } from 'backend-api/models';
import { formatNumeral, formatShortenNumeral, getShortCurrencyFormatted } from 'utils/format';
import { MetricItem } from './components';
import { SSummaryMetrics } from './s-project-summary-metrics';

interface ProjectSummaryMetricsProps {
  projectMetrics?: ProjectMetrics;
  loading?: boolean;
  isFailedToLoad?: boolean;
  currencyCode?: string;
}

export const ProjectSummaryMetrics = React.memo(
  ({ projectMetrics, currencyCode, isFailedToLoad, loading }: ProjectSummaryMetricsProps) => (
    <SSummaryMetrics>
      <MetricItem
        label="Planned Budget"
        content={getShortCurrencyFormatted(projectMetrics?.plannedBudget, currencyCode)}
        isLoading={loading}
        isFailedToLoad={isFailedToLoad}
        dataSelector="summary-metrics-planned-budget"
      />
      <MetricItem
        label="Platforms"
        content={projectMetrics?.platformsCount}
        isLoading={loading}
        isFailedToLoad={isFailedToLoad}
        dataSelector="summary-metrics-platforms"
      />
      <MetricItem
        label="Est. Impressions"
        content={formatNumeral(projectMetrics?.estimatedImpressions)}
        isLoading={loading}
        isFailedToLoad={isFailedToLoad}
        dataSelector="summary-metrics-est-impressions"
      />
      <MetricItem
        label="Campaigns"
        content={formatShortenNumeral(projectMetrics?.campaignsCount)}
        isLoading={loading}
        isFailedToLoad={isFailedToLoad}
        dataSelector="summary-metrics-campaigns"
      />
    </SSummaryMetrics>
  )
);
