import React from 'react';
import { bem } from 'utils/bem';
import { SLoaderRecentProjects } from './s-loader-recent-projects';

const rectangleClasses = bem('loader-recent-projects');
const CLIP_PATH_ID = 'loader-rectangle';

interface Props {
  className?: string;
}

export const RecentProjectsLoader = React.memo(({ className }: Props) => {
  return (
    <SLoaderRecentProjects clipPathId={CLIP_PATH_ID} className={className}>
      <div className={rectangleClasses('clip-container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <path
              d={`M0 0 110 0 110 50 0 50 0 0z
                  M0 60 30 60 30 90 0 90 0 60z
                  M40 60 70 60 70 90 40 90 40 60z
                  M80 60 110 60 110 90 80 90 80 60z`}
            />
          </clipPath>
        </defs>
      </svg>
    </SLoaderRecentProjects>
  );
});
