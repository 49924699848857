import styled from 'styled-components';

export const BEM_CLASS = 'preferences';

export const SPreferences = styled.div`
  .${BEM_CLASS} {
    &__title {
      margin-bottom: 20px;
    }
    &__card {
      border-radius: 10px;
      padding: 24px 30px;
      background-color: white;
      height: 200px;
    }
  }
`;
