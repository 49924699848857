import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'badge';

export const SBadge = styled.div`
  overflow: hidden;
  border-radius: 6px;
  background-color: ${colorPalette.white};
  border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
  padding: 0 5px;
  height: 25px;

  .${BEM_CLASS} {
    &__content {
      height: 100%;
      display: flex;
      align-items: center;
    }

    &__text {
      line-height: 1;
    }
  }
`;
