import {
  ProjectDetails,
  ArtistMoment,
  ArtistMomentRequest,
  ArtistMomentTypeGroup,
  ArtistMomentEditableFields,
} from 'backend-api/models';
import { BaseOption } from 'common/components/form/select';
import { GLOBAL_TERRITORY_ID, SimpleSectionOption } from 'common/constants';
import { getProjectPrimaryArtists } from 'common/transducers';
import { ArtistMomentInput } from './actions';
import { CUSTOM_MOMENT_TYPE_OPTION, CUSTOM_MOMENT_TYPE_OPTION_ID } from './constants';

export const prepareRequest = (values: ArtistMomentInput): ArtistMomentRequest => {
  const typeId = values.typeId === CUSTOM_MOMENT_TYPE_OPTION_ID ? undefined : values.typeId;
  const categoryId = values.typeId === CUSTOM_MOMENT_TYPE_OPTION_ID ? values.categoryId : undefined;
  const link = values.links?.[0];

  return {
    name: values.name || '',
    territoriesIds: values.territoriesIds || [],
    date: values?.date || new Date(),
    notes: values?.notes,
    link,
    categoryId,
    typeId,
    artistId: values.artistId || '',
  };
};

export const getInitialValues = (moment?: ArtistMoment): ArtistMomentInput | undefined => {
  const momentAutofilledValues: ArtistMomentInput = { territoriesIds: [GLOBAL_TERRITORY_ID] };

  if (!moment) {
    return momentAutofilledValues;
  }

  const territoriesIds =
    moment.territories.length > 0
      ? moment.territories.map(territory => territory.id)
      : momentAutofilledValues.territoriesIds;

  return {
    name: moment.name,
    date: moment.date,
    artistId: moment.artist.id,
    territoriesIds,
    links: moment.link ? Array(moment.link) : [],
    notes: moment.notes,
    typeId: typeof moment.type?.id === 'number' ? moment.type.id : CUSTOM_MOMENT_TYPE_OPTION_ID,
    categoryId: moment.category?.id,
  };
};

export const getMomentEditableFields = (moment?: ArtistMoment): ArtistMomentEditableFields => {
  const defaultEditableFields = {
    name: true,
    date: true,
    artist: true,
    territories: true,
    link: true,
    notes: true,
    type: true,
    category: true,
  };

  if (!moment) return defaultEditableFields;

  return {
    ...defaultEditableFields,
    ...moment.metadata.fields,
  };
};

export interface BaseArtistOption extends BaseOption {
  artistId: string;
}

export const getArtistOptions = (project?: ProjectDetails): BaseArtistOption[] => {
  if (!project) {
    return [];
  }

  const artists = getProjectPrimaryArtists(project);

  return artists
    .filter(artist => artist.type === 'Primary')
    .map((artist, index) => ({ id: index + 1, name: artist.name, artistId: artist.id }));
};

export const getMomentTypeOptions = (types?: ArtistMomentTypeGroup[]): SimpleSectionOption[] => {
  if (!types) {
    return [CUSTOM_MOMENT_TYPE_OPTION];
  } else {
    const options: SimpleSectionOption[] = types.map(type => ({ label: type.name, options: type.types }));
    return [...options, CUSTOM_MOMENT_TYPE_OPTION];
  }
};

export const getSelectedMomentTypeOptionId = (options: SimpleSectionOption[], values?: ArtistMomentInput) => {
  if (values && values.typeId === undefined && typeof values.categoryId === 'number') {
    return CUSTOM_MOMENT_TYPE_OPTION_ID;
  }

  if (values && values.typeId === null) {
    return CUSTOM_MOMENT_TYPE_OPTION_ID;
  }

  const flatOptions = options.flatMap(option => option.options);
  const selectedId = flatOptions.find(option => values && option.id === values.typeId)?.id;
  return typeof selectedId === 'number' ? selectedId : -1;
};

//†transducer
