import styled from 'styled-components';
import { typography } from 'app/theme/typography';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'no-rows-overlay';

export const SNoRowsOverlay = styled.div`
  display: flex;
  flex-direction: column;

  .${BEM_CLASS} {
    &__text {
      ${typography.smallText}
      color: ${manhattanThemeColors.placeholderTextColor};

      &--title {
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
  }
`;
