import React from 'react';
import { PlaceholderProps } from 'react-select';
import { BaseOption as BaseOptionType } from '../../types';
import { SMultiValuePlaceholder } from './s-multi-value-placeholder';

type Props<T extends BaseOptionType> = PlaceholderProps<T, true>;

const Root = <T extends BaseOptionType>({ children, innerProps }: Props<T>) => (
  <SMultiValuePlaceholder {...innerProps} ref={null}>
    {children}
  </SMultiValuePlaceholder>
);

export const MultiValuePlaceholder = React.memo(Root) as typeof Root;
