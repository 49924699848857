import { ALL_OPTION_ID } from 'common/components/form/select';
import { BaseGroup } from 'common/components/select';
import { AdsSelectOption } from './types';

export const ADS_SELECT_OPTIONS: BaseGroup<AdsSelectOption>[] = [
  {
    options: [
      {
        id: ALL_OPTION_ID,
        value: 'All Ad Platforms',
      },
    ],
    isFirst: true,
  },
  {
    options: [
      {
        id: 'facebook',
        value: 'Facebook Ads',
        info: 'Includes Facebook, Instagram, Audience Network, and Messenger placements.',
      },
      {
        id: 'google',
        value: 'Google Ads',
        info: 'Includes Google Search & Display and YouTube placements.',
      },
    ],
    isLast: true,
  },
];
