import { DateFormatPatterns, DATE_FROMAT_ID_TO_PATTERN } from 'common-v2/constants';

export const convertFromIdToDateFormat = (dateFormatId: number): string => {
  return DATE_FROMAT_ID_TO_PATTERN[dateFormatId] ?? DateFormatPatterns[0];
};

export const convertFromDateFormatToId = (dateFormat: string): number | undefined => {
  const id = Object.keys(DATE_FROMAT_ID_TO_PATTERN).find(key => DATE_FROMAT_ID_TO_PATTERN[key] === dateFormat);

  if (!id) return;

  return parseInt(id, 10);
};
