import React, { useEffect, useCallback, useRef } from 'react';
import { bem } from 'utils/bem';
import { SDropdown, BEM_CLASS } from './s-dropdown';

interface DropdownProps {
  className?: string;
  scrollerClassName?: string;
  isVisible: boolean;
  hideDropdown(): void;
  dataSelector?: string;
}

const classes = bem(BEM_CLASS);

export const Dropdown: React.FC<DropdownProps> = React.memo(
  ({ children, className, scrollerClassName, isVisible, hideDropdown, dataSelector }) => {
    const scrollerRef = useRef<HTMLDivElement>(null);

    const onDropdownClick = useCallback((e: React.SyntheticEvent) => {
      e.stopPropagation();
    }, []);

    useEffect(() => {
      const scrollerElement = scrollerRef && scrollerRef.current;

      if (scrollerElement && isVisible) scrollerElement.scrollTo(0, 0);
    }, [isVisible]);

    useEffect(() => {
      window.addEventListener('click', hideDropdown);

      return () => {
        window.removeEventListener('click', hideDropdown);
      };
    }, [hideDropdown]);

    return (
      <SDropdown className={className} isVisible={isVisible} onClick={onDropdownClick}>
        <div
          ref={scrollerRef}
          className={classes('scroller', undefined, scrollerClassName)}
          data-selector={dataSelector}
        >
          {children}
        </div>
      </SDropdown>
    );
  }
);
