import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { THEME, useManageToasts } from 'gdb-web-shared-components';
import { getFlattenedPlatforms, getPlatformsOptions } from 'common/transducers';
import { bem } from 'utils/bem';
import {
  MultiSelect,
  MultiSelectType,
  MULTI_SELECT_MAX_COUNT_CLEARABLE,
  SelectClassNamesProps,
  SelectBaseOption,
} from 'common-v2/components';
import { useSelectEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan-v2/components/spreadsheet/hooks';
import { Id, IdNameField, Nullable } from 'backend-api/models';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { isSubstring } from 'utils/string';
import { processPlacementsOnPlatformSelect } from '../transducers';
import { ExpandableField } from 'media-plan-v2/components/spreadsheet/components';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { BEM_CLASS, SPlatformsEditor } from './s-platforms-editor';

const classes = bem(BEM_CLASS);

export const PlatformsEditor = React.memo(
  React.forwardRef((props: ICellEditorParams<SpreadsheetRowData, SpreadsheetRowData['platforms']>, ref) => {
    const [value, setValue] = useState(props.value.value);
    const { onBlur } = useStopEditingBlur(props.api);
    const { openToast } = useManageToasts(THEME.light);
    const taxonomy = useMemo(() => props.colDef?.cellEditorParams.taxonomy, [props.colDef?.cellEditorParams.taxonomy]);

    const handleChange = useCallback(
      (ids: Id[]) => {
        const platforms =
          getFlattenedPlatforms(taxonomy?.platforms || []).filter(platform => ids.includes(platform.id)) || [];

        setValue(platforms);
      },
      [taxonomy?.platforms]
    );

    const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions<true, Nullable<IdNameField[]>>({
      api: props.api,
      value,
      setValue: handleChange,
      isMulti: true,
    });

    const selectedPlatformsIds = useMemo(() => value?.map(platform => platform.id) || [], [value]);

    const platformsOptions = useMemo(() => getPlatformsOptions(taxonomy?.platforms, selectedPlatformsIds), [
      selectedPlatformsIds,
      taxonomy?.platforms,
    ]);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return {
            ...props.value,
            value,
          };
        },
        isCancelAfterEnd() {
          processPlacementsOnPlatformSelect(selectedPlatformsIds || [], props.api, props.node, openToast, taxonomy);
          return false;
        },
      };
    });

    const selectClassNames = useCallback<(className: string) => SelectClassNamesProps>(
      className => ({
        control: classes('control'),
        valueContainer: {
          root: classes('value-root'),
          container: classes('value-container'),
          footer: classes('value-footer'),
        },
        menu: classes('menu'),
        indicatorsContainer: classes('indicators-container'),
        menuPortal: className,
      }),
      []
    );

    const filterOption = useCallback(
      (option: FilterOptionOption<SelectBaseOption>, inputValue: string) =>
        isSubstring(option.data.value, inputValue) && !option.data.isDisabled,
      []
    );

    useEffect(() => {
      selectRef.current?.inputRef?.focus({ preventScroll: true });
    }, [selectRef]);

    return (
      <ExpandableField hasError={false}>
        <SPlatformsEditor width={props.column.getActualWidth()} height={props.node.rowHeight || 0}>
          {className => {
            const clearable = value ? value.length >= MULTI_SELECT_MAX_COUNT_CLEARABLE : false;

            return (
              <div className={className}>
                <MultiSelect
                  selectRef={selectRef}
                  type={MultiSelectType.AutoComplete}
                  dataSelector="platforms-editor"
                  valueIds={value?.map(value => value.id) || []}
                  valueHandler={handleChange}
                  options={platformsOptions}
                  isSearchable
                  classNames={selectClassNames(className)}
                  onKeyDown={onKeyDown}
                  filterOption={filterOption}
                  placeholder=""
                  onBlur={onBlur}
                  isClearable={clearable}
                  isOpened
                />
              </div>
            );
          }}
        </SPlatformsEditor>
      </ExpandableField>
    );
  })
);
