import * as React from 'react';
import { SModalFooter } from '../../s-components/modal/s-footer';
import { Button } from '../button';
import { bem } from 'utils/bem';

export const ModalFooter = props => {
  const { onCancel, onOk, cancelText, okText, reverseButtons } = props;
  const classes = bem('footer');

  return (
    <SModalFooter reverse={reverseButtons}>
      {onCancel && (
        <Button
          className={classes('button')}
          dataSelector="modal-cancel-button"
          onClick={onCancel}
          variant={reverseButtons ? 'secondary-cta' : 'secondary-gray'}
        >
          {cancelText || 'Cancel'}
        </Button>
      )}
      {onOk && (
        <Button
          variant={reverseButtons ? 'secondary-gray' : 'secondary-cta'}
          dataSelector="modal-submit-button"
          className={classes('button')}
          onClick={onOk}
        >
          {okText || 'Ok'}
        </Button>
      )}
    </SModalFooter>
  );
};
