import { manhattanThemeColors } from 'app/theme/colors';
import styled from 'styled-components';

export const BEM_CLASS = 'edit-mode-container';

export const SEditModeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .${BEM_CLASS} {
    &__header {
      display: flex;
      align-items: flex-start;
      gap: 20px;
      margin-bottom: 20px;
    }

    &__search {
      flex-grow: 1;
      overflow: hidden;
    }

    &__invite-button {
      flex-shrink: 0;
      gap: 10px;
      margin-right: 10px;
    }

    &__invite-button-text {
      &--isDisabled {
        color: ${manhattanThemeColors.secondGrayedText};
      }
    }

    &__disabled-role {
      display: flex;
      align-items: center;
      gap: 5px;
      color: ${manhattanThemeColors.secondGrayedText};
    }

    &__role-name {
      color: ${manhattanThemeColors.secondGrayedText};
    }
  }
`;
