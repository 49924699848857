import { RouteComponentProps } from 'react-router-dom';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ProjectHeader } from 'common/containers/project-header';
import { ProjectTab } from 'common-v2/types';
import { PurchaseOrders } from 'common/components/tables/purchase-orders';
import {
  fetchBreakdownData,
  getAllPurchaseOrders,
  getPurchaseOrdersProviders,
  resetBreakdowns,
  toggleBreakdown,
  updateFilters,
} from '../actions';
import {
  errorSelector,
  filtersSelector,
  isLoadingFailedSelector,
  isLoadingSelector,
  purchaseOrdersBreakdownsSelector,
  purchaseOrdersSelector,
  purchaseProvidersSelector,
} from '../selectors';
import { useDispatch, useSelector } from 'react-redux';
import { PurchaseOrderDetails } from 'backend-api/models';
import { PurchaseOrderFilterParams } from 'backend-api/types';
import { WaveBackground } from 'common/components/wave-background';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { useProjectAccessRestricted } from 'common/hooks/use-project-access-restricted';
import { useProjectApproverAccessRestricted, useProjectCurrencyCode } from 'hooks';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';
import { Head } from 'app/components/head';
import { getProjectName } from 'common-v2/transducers';
import { activeProjectSelector } from 'project/selectors';

interface MatchParams {
  id: string;
}

type Props = RouteComponentProps<MatchParams>;

export const PrsContainer = React.memo(({ match }: Props) => {
  const projectId = parseInt(match.params.id, 10);

  const dispatch = useDispatch();

  const purchaseOrdersResponse = useSelector(purchaseOrdersSelector);
  const providers = useSelector(purchaseProvidersSelector);
  const breakdowns = useSelector(purchaseOrdersBreakdownsSelector);
  const project = useSelector(activeProjectSelector);
  const isLoading = useSelector(isLoadingSelector);
  const isLoadingFailed = useSelector(isLoadingFailedSelector);

  useEffect(() => {
    dispatch(getPurchaseOrdersProviders.request(projectId));
  }, [dispatch, projectId]);

  const onRequestData = useCallback(
    (params: PurchaseOrderFilterParams) => {
      dispatch(getAllPurchaseOrders.request({ projectId, params }));
    },
    [dispatch, projectId]
  );

  const onExpandItem = useCallback(
    (item: PurchaseOrderDetails, isExpanded: boolean) => {
      dispatch(toggleBreakdown({ item, isExpanded }));
      if (isExpanded) {
        dispatch(
          fetchBreakdownData.request({
            projectId: projectId,
            entityId: item.id,
          })
        );
      }
    },
    [dispatch, projectId]
  );

  const onSortClick = useCallback(() => {
    dispatch(resetBreakdowns());
  }, [dispatch]);

  const updateFiltersAction = useMemo(() => updateFilters, []);

  useProjectAccessRestricted(errorSelector);
  useProjectApproverAccessRestricted();

  const currencyCode = useProjectCurrencyCode();

  return (
    <>
      <Head title={getProjectName(project)} />
      <OutdatedGlobalLayout>
        <ProjectHeader projectId={projectId} activeTab={ProjectTab.Finance} />
        <WaveBackground className="app__wrapper">
          <ResponsiveContainer>
            <PurchaseOrders
              projectId={projectId}
              filterUpdateAction={updateFiltersAction}
              filtersSelector={filtersSelector}
              onRequestData={onRequestData}
              purchaseOrdersResponse={purchaseOrdersResponse}
              providers={providers}
              breakdowns={breakdowns}
              isLoading={isLoading}
              onSortClick={onSortClick}
              isLoadingFailed={isLoadingFailed}
              onExpandItem={onExpandItem}
              currencyCode={currencyCode}
            />
          </ResponsiveContainer>
        </WaveBackground>
      </OutdatedGlobalLayout>
    </>
  );
});
