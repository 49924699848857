import React, { useCallback, useMemo } from 'react';
import { bem } from 'utils/bem';
import { isSubstring } from 'utils/string';
import { Id } from 'backend-api/models';
import { Icon } from 'common/components/icon';
import { MenuPlacement, MultiSelectType, SelectComponents, SelectMenuProps } from 'common/components/select/types';
import { MultiSelect } from 'common/components/select/multi-select';
import { BEM_CLASS, STeamMemberSelect } from './s-team-member-select';
import { CustomSelectProps, SelectOption } from './types';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { MultiValuePlaceholder } from 'common/components/select/components';
import { TeamMembersControl, TeamMembersValueContainer } from './components';

interface TeamMemberSelectProps {
  className?: string;
  selectedOptions: Id[];
  options: SelectOption[];
  dropdownIdentifier: string;
  hasError?: boolean;
  onCopy(): void;
  onOptionSelect(options: Id[]): void;
}

const classes = bem(BEM_CLASS);

export const TeamMemberSelect = React.memo(
  ({
    className,
    selectedOptions,
    options,
    dropdownIdentifier,
    hasError = false,
    onCopy,
    onOptionSelect,
  }: TeamMemberSelectProps) => {
    const customSelectProps = useMemo<CustomSelectProps>(
      () => ({
        hasError,
        onCopy,
        canCopy: selectedOptions.length > 0,
      }),
      [hasError, onCopy, selectedOptions]
    );

    const selectComponents = useCallback<(props: SelectMenuProps) => SelectComponents<SelectOption, true>>(
      () => ({
        Control: TeamMembersControl,
        ValueContainer: TeamMembersValueContainer,
        Option: ({ innerProps, innerRef, isFocused, data }) => (
          <div {...innerProps} ref={innerRef} className={classes('option', { isFocused })}>
            <span className={classes('option-name')}>{data.value}</span>
            <span className={classes('option-email')}>{data.email}</span>
          </div>
        ),
        Placeholder: props => (props.isFocused ? null : <MultiValuePlaceholder {...props} />),
        NoOptionsMessage: ({ innerProps }) => (
          <div {...innerProps} className={classes('no-options-message')}>
            No user found. Please try again.
          </div>
        ),
      }),
      []
    );

    const selectClassNames = useCallback(
      (className: string) => ({
        menuList: classes('menu-list'),
        multiValueContainer: classes('value-container'),
        menuPortal: className ? `${className} ${dropdownIdentifier}` : dropdownIdentifier,
      }),
      [dropdownIdentifier]
    );

    const filterOption = useCallback((option: FilterOptionOption<SelectOption>, inputValue: string) => {
      const isNameMatched = isSubstring(option.data.value, inputValue);
      const isEmailMatched = isSubstring(option.data.email, inputValue);

      return isNameMatched || isEmailMatched;
    }, []);

    return (
      <STeamMemberSelect>
        {styledClassName => (
          <div className={`${className} ${styledClassName}`}>
            <MultiSelect<SelectOption, CustomSelectProps>
              type={MultiSelectType.AutoComplete}
              valueIds={selectedOptions}
              valueHandler={onOptionSelect}
              options={options}
              classNames={selectClassNames(styledClassName)}
              placeholder="Click to select or search name"
              selectComponents={selectComponents}
              customReactSelectProps={customSelectProps}
              filterOption={filterOption}
              placement={MenuPlacement.BottomLeft}
              dataSelector="team-member"
              maxItemsLength={1}
              showMenuOnFocus
            />

            {hasError && (
              <div className={classes('error')}>
                <Icon className={classes('error-icon')} color="inherit" name="warning" size="general" />
                Please provide email or name of team member.
              </div>
            )}
          </div>
        )}
      </STeamMemberSelect>
    );
  }
);
