import { GridApi } from 'ag-grid-community';
import { UndoHook } from 'media-plan-v2/components/spreadsheet/types';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { getIndexesRange } from 'media-plan-v2/components/spreadsheet/transducers';
import { isMediaPlanColumnId } from 'media-plan-v2/containers/spreadsheet/transducers';

export const isMultipleColumnsSelected = (api: GridApi): boolean => {
  const cellRanges = api.getCellRanges();

  if (!cellRanges) return false;

  return cellRanges.some(range => range.columns.length > 1);
};

export const updateCellsColor = (api: GridApi<SpreadsheetRowData>, undo: UndoHook, color: string | null) => {
  const range = api.getCellRanges()?.[0];
  const focusedCell = api.getFocusedCell();

  if (!range || range.startRow?.rowIndex === undefined || range.endRow?.rowIndex === undefined) return;

  const startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
  const endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);

  const updateTransaction: SpreadsheetRowData[] = [];
  const rowIndexes = getIndexesRange(startRowIndex, endRowIndex);

  rowIndexes.forEach(rowIndex => {
    const node = api.getDisplayedRowAtIndex(rowIndex);

    if (!node || !node.data) return;

    let updatedRow = node.data;

    range.columns.forEach(column => {
      if (!node || !node.data) return;

      const colId = column.getColId();

      if (!isMediaPlanColumnId(colId)) return;

      const cellData = api.getValue(colId, node);

      if (cellData.color !== color) {
        undo.updateUndoStack({ field: colId, data: node.data });
        updatedRow = { ...updatedRow, [colId]: { ...cellData, color } };
      }
    });

    updateTransaction.push(updatedRow);
  });

  api.applyTransactionAsync(
    {
      update: updateTransaction,
    },
    () => {
      const focusRowIndex = focusedCell?.rowIndex ?? startRowIndex;
      const focusColumnIndex = (focusedCell?.column ?? range.startColumn).getColId();

      api.clearFocusedCell();
      api.setFocusedCell(focusRowIndex, focusColumnIndex);
    }
  );
};
