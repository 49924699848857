import React, { useCallback, useMemo } from 'react';
import { bem } from 'utils/bem';
import { LinkSelectOption } from '../../types';
import { Icon } from 'common/components/icon';
import { getLinkLabel } from 'common/transducers';
import { INVALID_LINK_LABEL } from 'common/constants';
import { Tooltip } from 'common/components/tooltip';
import { BEM_CLASS, SLinkContainer } from './link-view-container';

interface Props {
  data: LinkSelectOption;
  onRemove(value: LinkSelectOption): void;
  disabled?: boolean;
  uneditable?: boolean;
  onLinkClick?(): void;
  onMouseEnter(): void;
  onMouseLeave(): void;
}

const classes = bem(BEM_CLASS);

export const LinkContainer = React.memo(
  ({ data, onRemove, disabled, uneditable, onLinkClick, onMouseEnter, onMouseLeave }: Props) => {
    const isLinkInvalid = useMemo(() => getLinkLabel(data.link) === INVALID_LINK_LABEL, [data.link]);

    const handleLinkClick = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
      event => {
        if (isLinkInvalid) {
          event.preventDefault();
        }

        onLinkClick?.();
      },
      [isLinkInvalid, onLinkClick]
    );

    const onRemoveClick = useCallback(() => {
      onRemove(data);
    }, [onRemove, data]);

    return (
      <Tooltip content="Invalid link" enabled={isLinkInvalid} interactive={false}>
        <SLinkContainer isInvalid={isLinkInvalid} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          <Icon name={data.icon || 'link'} size="projects" className={classes('icon')} color="inherit" />
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={data.link}
            className={classes('link-text')}
            onClick={handleLinkClick}
          >
            {data.label}
          </a>
          {!disabled && !uneditable && (
            <Icon name="close" className={classes('close')} onClick={onRemoveClick} color="inherit" />
          )}
        </SLinkContainer>
      </Tooltip>
    );
  }
);
