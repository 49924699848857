import { Tooltip, TooltipProps } from 'gdb-web-shared-components';
import React, { useLayoutEffect, useState, useRef, useMemo } from 'react';
import { bem } from 'utils/bem';

import { STooltipedText, BEM_CLASS } from './s-tooltiped-text';
import { useWindowSize } from 'react-use';

const classes = bem(BEM_CLASS);

interface TooltipedTextProps extends Omit<TooltipProps, 'tooltip'> {
  text: string;
  children: React.ReactElement;
}

export const TooltipedText = React.memo(({ text, children, ...tooltipProps }: TooltipedTextProps) => {
  const [isTextOverflowed, setIsTextOverflowed] = useState(false);
  const { height, width } = useWindowSize();

  const containerRef = useRef<HTMLSpanElement>(null);
  const childRef = useRef<HTMLSpanElement>(null);

  const referredChild = useMemo(() => {
    const classNames = classes('child', { ellipsis: isTextOverflowed }, children.props.className);

    return React.cloneElement(children, {
      ref: childRef,
      className: classNames,
    });
  }, [children, isTextOverflowed]);

  useLayoutEffect(() => {
    const containerElement = containerRef?.current;
    const childElement = childRef?.current;

    if (!containerElement || !childElement) return;

    const containerElementWidth = containerElement.offsetWidth;
    const childElementWidth = childElement.scrollWidth;

    setIsTextOverflowed(childElementWidth > containerElementWidth);
  }, [width, height, text]);

  return (
    <Tooltip {...tooltipProps} tooltip={text} isDisabled={!isTextOverflowed}>
      <STooltipedText ref={containerRef}>{referredChild}</STooltipedText>
    </Tooltip>
  );
});
