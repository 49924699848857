import { MediaPlanListItem, ProjectDetails } from 'backend-api/models';
import { usePermissionsChecker } from 'common-v2/hooks';
import { ACCESS_PERMISSIONS } from 'common-v2/constants';

interface HeaderPermissionsHook {
  canEditMediaPlan: boolean;
  shouldShowMediaPlansDropdown: boolean;
}

export const useHeaderPermissions = (
  mediaPlans: MediaPlanListItem[],
  project?: ProjectDetails
): HeaderPermissionsHook => {
  const permissionsChecker = usePermissionsChecker(project);
  const canEditMediaPlan = permissionsChecker(ACCESS_PERMISSIONS.EDIT_MEDIA_PLAN);

  const shouldShowMediaPlansDropdown = mediaPlans.length > 1 || (canEditMediaPlan && mediaPlans.length > 0);

  return { canEditMediaPlan, shouldShowMediaPlansDropdown };
};
