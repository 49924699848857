import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { motion } from 'framer-motion';
import { theme } from 'app/theme';

interface Props {
  expanded: boolean;
}

export const BEM_CLASS = 'budget-widget';

export const SBudgetWidget = styled(motion.div)<Props>`
  min-height: 185px;
  border-radius: 10px;
  background-color: white;
  padding: 25px 30px 22px;
  display: flex;
  justify-content: space-between;
  position: relative;

  .${BEM_CLASS} {
    &__main-container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__group-toggle-container {
      display: flex;
      align-items: center;
      &--disabled {
        pointer-events: none;
        opacity: 0;
      }
    }

    &__group-toggle-title {
      text-transform: uppercase;
      margin-right: 7px;
    }

    &__icon {
      transition: transform 0.2s ease-in;
      transform: rotate(${({ expanded }) => (expanded ? 180 : 0)}deg);
    }

    &__separator {
      margin-top: 31px;
      height: 1px;
      background-color: ${palette.lightGraySix};
    }

    &__selector-container {
      ${theme.typography.h4}
      margin-top: 19px;
      display: flex;
      align-items: center;
    }

    &__selector {
      margin-left: 7px;
    }

    &__legend-divider {
      flex: 1;
    }

    &__grid {
      margin-top: 23px;
    }

    &__date {
      margin-top: 16px;
      color: ${palette.grayBlue};
      font-size: 10px;
      line-height: 13px;
    }

    &__loader {
      margin: auto;
    }
  }
`;
