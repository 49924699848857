import React, { useState, useCallback } from 'react';
import { theme } from 'app/theme';
import { BgStyle, bgStyle } from 'common/styles/utils';
import { ReusableModal } from 'common/components/reusable-modal';
import { SPaper } from '../../../s-components/s-paper';
import { Notes } from './notes';

interface Props {
  bgStyle?: BgStyle;
  onChange(value: string): void;
  value: string;
  maxLength: number;
  expandClick(): void;
  expanded: boolean;
  placeholder: string;
  className?: string;
  disabled: boolean;
}

export const ExpandableNotes = (props: Props) => {
  const revertedBgStyle = props.bgStyle === 'white' || !props.bgStyle ? 'grey' : 'white';

  const [expanded, setExpanded] = useState(false);

  const onExpand = useCallback(() => setExpanded(true), []);
  const onModalClose = useCallback(() => setExpanded(false), []);

  return (
    <div>
      {!expanded && <Notes {...props} expanded={expanded} expandClick={onExpand} />}
      <ReusableModal
        width="1172px"
        height="660px"
        isOpen={expanded}
        onClose={onModalClose}
        hasHeader={false}
        hasCloseIcon={false}
      >
        {expanded ? (
          <SPaper pt="14px" pb="30px" pl="30px" pr="30px" color={bgStyle({ theme, bgStyle: revertedBgStyle })}>
            <Notes {...props} expanded={expanded} expandClick={onModalClose} />
          </SPaper>
        ) : (
          <div />
        )}
      </ReusableModal>
    </div>
  );
};
