import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { PADDING } from '../../constants';

interface Props {
  isDisabled: boolean;
  isFocused?: boolean;
}

export const BEM_CLASS = 'base-option';

const hoverState = css`
  color: ${colorPalette.azureRadiance};
  background-color: ${transparentize(0.9, colorPalette.azureRadiance)};

  .${BEM_CLASS} {
    &__info {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const SBaseOption = styled.div<Props>`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5px ${PADDING}px;
  color: ${colorPalette.vulcan};
  background-color: ${colorPalette.white};
  transition: color 0.3s ease-out, background-color 0.3s ease-out;

  ${({ isDisabled, isFocused }) =>
    isDisabled
      ? css``
      : css`
          cursor: pointer;
          ${isFocused && hoverState};

          .${BEM_CLASS} {
            &__info {
              opacity: 0;
              visibility: hidden;
            }
          }
        `}


  .${BEM_CLASS} {
    &__text {
      ${elipsizeText};
      margin-right: auto;
      font-size: 14px;
      line-height: 20px;

      &--isSelected {
        font-weight: 700;
      }

      &--isDisabled {
        color: ${colorPalette.osloGray};
      }
    }

    &__info {
      flex-shrink: 0;
      display: flex;
      margin-left: 10px;
      transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
    }
  }
`;
