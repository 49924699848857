import React from 'react';
import { ArtistSearch, ArtistTeam } from 'backend-api/models';
import {
  BEM_CLASS,
  SExpansionHeader,
} from 'projects/components/expanding-section/components/expansion-header/s-expansion-header';
import { bem } from 'utils/bem';
import { NoBrokenIconImage } from 'common/components/no-broken-icon-image';
import { H4, PrimaryLabel } from 'common/components/typography';
import { paths } from 'app/routing/paths';
import { Team } from 'common/components/team';

interface Props {
  artist: ArtistSearch;
  artistTeam?: ArtistTeam;
}

const classes = bem(BEM_CLASS);

export const ExpansionHeader = React.memo(({ artist, artistTeam }: Props) => {
  return (
    <SExpansionHeader>
      <div className={classes('artist-image')}>
        <NoBrokenIconImage src={artist.image?.url} className={classes('artist-image')} />
      </div>
      <div className={classes('name-container')}>
        <H4 className={classes('artist-name')}> {artist.name}</H4>
        <a href={paths.artist(artist.id)} className={classes('view-artist-page')}>
          View Artist Page
        </a>
      </div>
      {artistTeam && (
        <>
          <PrimaryLabel className={classes('artist-team-label')}>Artist Team</PrimaryLabel>
          <Team users={artistTeam.users} className={classes('artist-team-container')} />
        </>
      )}
    </SExpansionHeader>
  );
});
