import React from 'react';
import { BEM_CLASS, SEmptyState } from './s-empty-state';
import { bem } from 'utils/bem';
import { Body, H4 } from 'common/components/typography';
import { Icon } from 'common/components/icon';

interface Props {
  onCreateArtistTeam(): void;
}

const classes = bem(BEM_CLASS);

export const EmptyState = React.memo(({ onCreateArtistTeam }: Props) => (
  <SEmptyState>
    <div className={classes('header')}>
      <H4>Artist Team</H4>
      <div className={classes('button')} onClick={onCreateArtistTeam}>
        <Body className={classes('subtitle')}>Go to Artist Page</Body>
        <Icon name="arrow-right" color="blue" />
      </div>
    </div>
    <Body>No users have been added to the Artist&apos;s team yet. Go to Artist page to create the team.</Body>
  </SEmptyState>
));
