import React from 'react';
import { ProjectDetails } from 'backend-api/models';
import { bem } from 'utils/bem';
import { BEM_CLASS, SReporting } from './s-reporting';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { ProjectTabs } from 'common/components/project-tabs';
import { ErrorViewConfig, ErrorView } from 'common-v2/components';
import { ReportingTableLoader, TitleLoader } from 'reporting-v2/components';
import { ProjectTab } from 'common-v2/types';
import { InputDataCampaignTableContainer } from 'reporting-v2/containers/input-data-campaign-table-container';
import FailedToLoadErrorImage from 'assets/error_failed_to_load.png';

interface Props {
  project?: ProjectDetails;
  isLoading?: boolean;
  isFailedToLoad?: boolean;
  onRefresh(): void;
}

export const classes = bem(BEM_CLASS);

const errorViewConfig: ErrorViewConfig = {
  bottomImageSrc: FailedToLoadErrorImage,
  description: (
    <>
      Reporting failed to load.
      <br />
      Please try refreshing the page.
    </>
  ),
  actionTitle: 'Refresh',
};

export const Reporting = React.memo(({ project, isLoading, isFailedToLoad, onRefresh }: Props) => {
  return (
    <SReporting>
      <div className={classes('title')}>
        {isLoading ? (
          <TitleLoader />
        ) : (
          <>
            <Typography type={TYPOGRAPHY_TYPE.heading1}>Analytics & Reporting</Typography>
            {project && (
              <Typography type={TYPOGRAPHY_TYPE.heading1} className={classes('project-name')}>
                | {project?.name}
              </Typography>
            )}
          </>
        )}
      </div>
      <ProjectTabs
        project={project}
        activeTab={ProjectTab.Reporting}
        isLoading={isLoading}
        className={classes('tabs')}
      />
      {!isFailedToLoad && (
        <>
          {project && (
            <InputDataCampaignTableContainer
              project={project}
              currencyCode={project.currency}
              className={classes('expandable-container')}
            />
          )}
          {isLoading && <ReportingTableLoader className={classes('reporting-table')} />}
        </>
      )}
      {isFailedToLoad && <ErrorView config={errorViewConfig} action={onRefresh} />}
    </SReporting>
  );
});
