import styled from 'styled-components';

export const BEM_CLASS = 'legend';

export const SLegend = styled.div`
  display: flex;
  > :not(:first-child) {
    margin-left: 20px;
  }
`;
