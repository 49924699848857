import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const BEM_CLASS = 'artists-result';

export const SArtistsResult = styled.div`
  height: 100%;

  .${BEM_CLASS} {
    &__cell {
      display: flex;
      align-items: center;
      height: 100%;

      &--name,
      &--count {
        font-size: 14px;
        line-height: 20px;
        color: ${palette.darkBlue};
      }

      &--count {
        justify-content: flex-end;
      }
    }

    &__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
`;
