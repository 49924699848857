import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'sharing-modal-title';

export const SSharingModalTitle = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__title {
      flex-shrink: 1;
      ${elipsizeText};
    }

    &__badge {
      white-space: nowrap;
      margin-left: 10px;
    }
  }
`;
