import styled from 'styled-components';
import { darken, lighten } from 'polished';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'action-button';

interface Props {
  isPhase?: boolean;
}

export const SActionButton = styled.button<Props>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px;
  position: relative;

  &:hover {
    .${BEM_CLASS}__action-button {
      background: ${({ isPhase }) =>
        isPhase ? darken(0.1, colorPalette.iron) : lighten(0.4, colorPalette.azureRadiance)};
    }

    .${BEM_CLASS}__dot {
      background-color: ${colorPalette.kimberley};
    }
  }

  .${BEM_CLASS} {
    &__action-button {
      visibility: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      background: ${({ isPhase }) => (isPhase ? colorPalette.iron : colorPalette.zumthor)};

      &:active {
        background-color: ${colorPalette.azureRadiance};

        .${BEM_CLASS}__dot {
          background-color: ${colorPalette.ghostWhite};
        }
      }
    }
    &__dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${colorPalette.lavenderGray};

      &:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
`;
