import { UUID } from 'io-ts-types/lib/UUID';
import { apiClient, ResponseArray } from '../index';
import { AddLinkfireRequest, AddLinkfireRequestSchema, Id, LinkfireSearchSchema } from '../models';

interface LinkfireSearchParams {
  query: string;
  limit: number;
}

export const getLinkfireLinks = (params?: LinkfireSearchParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/linkfire/search`,
    queryParams: params,
    responseSchema: ResponseArray(LinkfireSearchSchema),
  });

export const addLinkfireLinksToCampaign = (projectId: Id, campaignUuid: UUID, payload: AddLinkfireRequest) =>
  apiClient.executeRequest({
    method: 'PUT',
    endpoint: `/projects/${projectId}/campaigns/${campaignUuid}/linkfire`,
    requestSchema: AddLinkfireRequestSchema,
    payload: payload,
    responseSchema: AddLinkfireRequestSchema,
  });
