import { Optional } from 'backend-api/models';
import { useProjectCurrencyCode } from 'hooks';
import { useMemo } from 'react';
import { getCurrencyFormatted } from 'utils/format';

export const useProjectCurrencyFormat = (value: Optional<string | number>, format?: string): string => {
  const currencyCode = useProjectCurrencyCode();

  return useMemo(() => getCurrencyFormatted(value, currencyCode, false, format), [currencyCode, format, value]);
};
