import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SInfoContent } from './s-info-content';
import { SPoint } from '../../styled';
import { parseDateFromISOString } from 'common-v2/utils';
import { useFormattedDate } from 'common-v2/hooks';

const classes = bem(BEM_CLASS);

interface InfoContentProps {
  date?: string;
  content: React.ReactChild;
}

export const InfoContent = React.memo(({ date, content }: InfoContentProps) => {
  const parsedDate = useMemo(() => (date ? parseDateFromISOString(date) : undefined), [date]);
  const formatDate = useFormattedDate();

  return (
    <SInfoContent>
      {content}
      {date && (
        <>
          <SPoint />
          <span className={classes('date')}>{formatDate(parsedDate)}</span>
        </>
      )}
    </SInfoContent>
  );
});
