import React from 'react';
import { ValueContainerProps } from 'react-select';
import { SelectOption } from '../../types';
import { STeamMembersValueContainer } from './s-team-members-value-container';

export const TeamMembersValueContainer = React.memo(
  ({ innerProps, children }: ValueContainerProps<SelectOption, true>) => (
    <STeamMembersValueContainer {...innerProps} ref={null}>
      {children}
    </STeamMembersValueContainer>
  )
);
