import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'unclaimed-project-with-locked-artist';

export const SUnclaimedProjectWithLockedArtist = styled.div`
  .${BEM_CLASS} {
    &__link {
      display: inline-block;
      margin-top: 11px;
      border-bottom: 1px dotted ${transparentize(0.4, manhattanThemeColors.primaryDark)};
    }

    &__description {
      color: ${manhattanThemeColors.grayedText};
    }
  }
`;
