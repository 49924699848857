import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'info';

export const SInfo = styled.div`
  .${BEM_CLASS} {
    &__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 80px;
      row-gap: 15px;
    }

    &__campaign-link {
      display: flex;
      align-items: center;
      max-width: 100%;
      background-color: #f2f2f7;
      width: fit-content;
      padding: 0 10px 0 1px;
      border-radius: 2px;
    }

    &__campaign-icon {
      flex-shrink: 0;
    }

    &__campaign-name {
      flex-grow: 1;
      font-size: 11px;
      line-height: 15px;
      font-weight: 600;
      margin-left: 2px;
      ${elipsizeText};
    }
  }
`;
