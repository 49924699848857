import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { theme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'selected-campaign';

export const SSelectedCampaign = styled.div`
  display: flex;
  box-shadow: ${theme.shadows.default};
  border-radius: 5px;
  height: 45px;
  background-image: linear-gradient(to top, ${colorPalette.athensGray}, ${colorPalette.white});
  overflow: hidden;

  .${BEM_CLASS} {
    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: inherit;
      margin-right: 10px;
      background-color: ${colorPalette.athensGray};
      flex-shrink: 0;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 10px;
      overflow: hidden;
    }

    &__title {
      ${elipsizeText}
      font-weight: 400;
      line-height: 18px;
      font-size: 12px;
      margin: 0;
    }

    &__date {
      line-height: 14px;
      font-size: 10px;
    }
  }
`;
