import React, { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CampaignDetails as CampaignDetailsType, Id } from 'backend-api/models';
import { SViewProject } from '../../s-components/s-view-project';
import { adSetsSelector, filtersSelector, isLoadingSelector } from '../../selectors';
import { Body } from './body';
import { WAVE_GRADIENT_END_COLOR, WaveBackground } from 'common/components/wave-background';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { Footer } from 'app/components/footer';
import { AdSetsSection } from './ad-sets';
import { bem } from 'utils/bem';
import { SmallText } from 'common/components/typography';
import { useFormattedDateTime } from 'common/hooks/use-formatted-date-time';

interface Props {
  campaign: CampaignDetailsType;
  projectId?: Id;
}

const classes = bem('campaign-details');

export const CampaignDetails = React.memo(({ campaign, projectId }: Props) => {
  const adSets = useSelector(adSetsSelector);
  const filters = useSelector(filtersSelector);
  const isLoading = useSelector(isLoadingSelector);

  const formattedEditDate = useFormattedDateTime({ date: campaign.editDate });

  const adSetsRef = useRef<HTMLDivElement>(null);

  const userEmail = campaign.editUser?.email || campaign.createUser?.email;

  const isShowingAds = (adSets && adSets?.total > 0) || filters.search || isLoading;

  const onViewAdSetsClick = useCallback(() => {
    adSetsRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [adSetsRef]);

  return (
    <SViewProject>
      <WaveBackground heightFromChildren className={classes('container')}>
        <ResponsiveContainer>
          <Body
            onViewAdSetsClick={onViewAdSetsClick}
            projectId={projectId}
            campaign={campaign}
            currencyCode={campaign.currency}
          />
          {userEmail && (
            <SmallText className="contacts">
              Modified by {userEmail} at {formattedEditDate?.date}
            </SmallText>
          )}
        </ResponsiveContainer>
      </WaveBackground>
      <ResponsiveContainer>
        {isShowingAds && <AdSetsSection ref={adSetsRef} projectId={projectId} campaignUuid={campaign.uuid} />}
      </ResponsiveContainer>
      <Footer background={isShowingAds ? 'white' : WAVE_GRADIENT_END_COLOR} />
    </SViewProject>
  );
});
