import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const SMonthPickerHeader = styled.div`
  display: table-caption;
  width: 100%;
  padding: 18px 0 12px;

  .month-picker-header {
    &__container {
      display: flex;
      align-items: center;
      width: 100%;
    }
    &__title {
      color: ${palette.darkBlue};
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
      text-align: center;
      flex: 1;
    }
    &__icon {
      display: flex;
      justify-content: center;
      width: 30px;
      cursor: pointer;
      &::before {
        position: relative;
      }
      &--hidden {
        visibility: hidden;
      }
    }
  }
`;
