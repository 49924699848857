import React, { useCallback, useMemo } from 'react';
import { bem } from 'utils/bem';
import { take, takeRight } from 'lodash';
import { UserAvatar } from '../user-avatar';
import { MAX_TEAM_MEMBERS_TO_SHOW } from './constants';
import { getTeamMembers } from './transducers';
import { STeam, BEM_CLASS } from './s-team';
import { UserWithOptionalRoles } from './types';
import { ROLES } from 'common-v2/constants';

interface TeamProps {
  className?: string;
  users: UserWithOptionalRoles[];
  dataSelector?: string;
}

const classes = bem(BEM_CLASS);

export const Team = React.memo(({ className, users, dataSelector }: TeamProps) => {
  const members = useMemo(() => getTeamMembers(users), [users]);
  const isEditor = useCallback(roles => roles?.some(({ id }) => id === ROLES.EDITOR.id || id === ROLES.ADMIN.id), []);
  const canShowAllMembers = members.length <= MAX_TEAM_MEMBERS_TO_SHOW + 1;

  const userAvatars = useMemo(() => {
    const teamMembersToShow = canShowAllMembers ? members : take(members, MAX_TEAM_MEMBERS_TO_SHOW);
    return teamMembersToShow.map(({ id, name, roles }) => (
      <UserAvatar
        key={id}
        className={classes('member')}
        dataSelector={`${dataSelector}-team-member-avatar`}
        size={30}
        isEditor={isEditor(roles)}
        name={name}
        hasTooltip
      />
    ));
  }, [members, dataSelector, isEditor, canShowAllMembers]);

  return (
    <STeam className={className} data-selector={`${dataSelector}-team-members-avatars`}>
      {canShowAllMembers
        ? userAvatars.reverse()
        : [
            ...userAvatars,
            <UserAvatar
              key="rest-users"
              className={classes('member')}
              dataSelector={`${dataSelector}-team-member-avatar`}
              size={30}
              name={takeRight(members, members.length - MAX_TEAM_MEMBERS_TO_SHOW).map(({ name }) => name)}
              hasTooltip
            />,
          ].reverse()}
    </STeam>
  );
});
