import React from 'react';
import {
  BaseCreatableSelectProps,
  BaseOption as BaseOptionType,
  CreatableSelectType,
  LinkInputMultiSelectProps,
} from './types';
import { BaseCreatableSelect, LinkInputMultiSelect } from './containers';

const Root = <T extends BaseOptionType, P extends {} = {}>(
  props: BaseCreatableSelectProps<T, P> | LinkInputMultiSelectProps<T, P>
) => {
  const { type } = props;

  switch (type) {
    case CreatableSelectType.Base:
      return <BaseCreatableSelect {...props} />;
    case CreatableSelectType.LinkInput:
      return <LinkInputMultiSelect {...props} />;
  }
};

export const CreatableSelect = React.memo(Root) as typeof Root;
