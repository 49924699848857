import React from 'react';
import { Skeleton } from 'common-v2/components/skeleton';

interface LoaderProps {
  className?: string;
}

export const Loader = React.memo(({ className }: LoaderProps) => (
  <Skeleton className={className} width={285} height={115}>
    <rect x="0" y="0" rx="2px" ry="2px" width="20" height="20" />
    <rect x="0" y="45" rx="2px" ry="2px" width="20" height="20" />
    <rect x="0" y="90" rx="2px" ry="2px" width="20" height="20" />
    <rect x="35" y="0" rx="2px" ry="2px" width="250" height="25" />
    <rect x="35" y="45" rx="2px" ry="2px" width="150" height="25" />
    <rect x="35" y="90" rx="2px" ry="2px" width="250" height="25" />
  </Skeleton>
));
