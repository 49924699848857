import * as t from 'io-ts';
import { IdType, Optional, ResponseModel } from '../common';

export const LinkfireSearchSchema = ResponseModel(
  { id: Optional(IdType), linkId: t.string, linkUrl: t.string, deletable: Optional(t.boolean) },
  'LinkfireSearch'
);

export type LinkfireSearch = t.TypeOf<typeof LinkfireSearchSchema>;

export const AddLinkfireRequestSchema = ResponseModel(
  { linkfireLinks: t.array(LinkfireSearchSchema) },
  'AddLinkfireRequest'
);

export type AddLinkfireRequest = t.TypeOf<typeof AddLinkfireRequestSchema>;
