import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SBudgetDetailsGridHeader } from './s-budget-details-grid-header';
import { PrimaryLabel } from 'common/components/typography';
import { getTextWithCurrencyCode } from 'utils/format';

const classes = bem(BEM_CLASS);

interface Props {
  title: string;
  currencyCode?: string;
}

export const BudgetDetailsGridHeader = React.memo(({ title, currencyCode }: Props) => {
  const total = useMemo(() => getTextWithCurrencyCode('Total', currencyCode), [currencyCode]);
  const allocated = useMemo(() => getTextWithCurrencyCode('Allocated', currencyCode), [currencyCode]);

  return (
    <SBudgetDetailsGridHeader>
      <div className={classes('budget-and-allocated-container')}>
        <PrimaryLabel className={classes('text')} data-selector="budget-group-title">
          {title}
        </PrimaryLabel>
        <PrimaryLabel className={classes('text')} data-selector="budget-details-total-title">
          {total}
        </PrimaryLabel>
      </div>
      <div className={classes('utilization-and-total-container')}>
        <PrimaryLabel className={classes('text')} data-selector="budget-details-group-utilization-title">
          Group Utilization
        </PrimaryLabel>
        <PrimaryLabel className={classes('text')} data-selector="budget-details-allocated-title">
          {allocated}
        </PrimaryLabel>
      </div>
    </SBudgetDetailsGridHeader>
  );
});
