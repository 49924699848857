import { PurchaseOrdersListResponse, PurchaseOrdersProvider } from '../backend-api/models';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { BreakdownValuesByEntityId, PurchaseOrdersFilters } from 'common/components/tables/purchase-orders';
import { createTypedReducer, onAction } from '../core/store';
import {
  getAllPurchaseOrders,
  getPurchaseOrdersProviders,
  updateFilters,
  fetchBreakdownData,
  toggleBreakdown,
  resetBreakdowns,
} from './actions';
import { ApiError } from 'backend-api';

export interface PrsState {
  isLoading: boolean;
  isLoadingFailed: boolean;
  filters: PurchaseOrdersFilters;
  purchaseOrdersResponse?: PurchaseOrdersListResponse;
  providers: PurchaseOrdersProvider[];
  breakdowns: BreakdownValuesByEntityId;
  error?: ApiError<ProjectDetailsErrorResponse>;
}

export const initialState = {
  isLoading: true,
  isLoadingFailed: false,
  filters: {},
  providers: [],
  breakdowns: {},
};

export const reducer = createTypedReducer<PrsState>(
  initialState,
  onAction(updateFilters, (state, action) => ({
    ...state,
    filters: {
      ...state.filters,
      ...action.payload,
    },
  })),
  onAction(getAllPurchaseOrders.request, state => ({
    ...state,
    isLoading: !state.purchaseOrdersResponse || state.purchaseOrdersResponse?.items?.length === 0,
  })),
  onAction(getAllPurchaseOrders.failure, (state, action) => ({
    ...state,
    isLoading: false,
    isLoadingFailed: true,
    error: action.payload,
  })),
  onAction(getAllPurchaseOrders.success, (state, action) => ({
    ...state,
    purchaseOrdersResponse: action.payload,
    isLoading: false,
  })),
  onAction(getPurchaseOrdersProviders.success, (state, action) => ({
    ...state,
    providers: action.payload,
  })),
  onAction(fetchBreakdownData.request, (state, action) => {
    const breakdownFromState = state.breakdowns[action.payload.entityId];
    return {
      ...state,
      breakdowns: {
        ...state.breakdowns,
        [action.payload.entityId]: { ...breakdownFromState, isLoading: true },
      },
    };
  }),
  onAction(fetchBreakdownData.success, (state, action) => {
    const breakdownFromState = state.breakdowns[action.payload.entityId];
    return {
      ...state,
      breakdowns: {
        ...state.breakdowns,
        [action.payload.entityId]: {
          ...breakdownFromState,
          data: action.payload.data.items,
          isLoading: false,
          isExpanded: action.payload.data.items.length === 0 ? false : breakdownFromState.isExpanded,
        },
      },
    };
  }),
  onAction(fetchBreakdownData.failure, (state, action) => {
    const breakdownFromState = state.breakdowns[action.payload.entityId];
    return {
      ...state,
      breakdowns: {
        ...state.breakdowns,
        [action.payload.entityId]: { ...breakdownFromState, isExpanded: false, isLoading: false },
      },
    };
  }),
  onAction(toggleBreakdown, (state, action) => {
    const { breakdowns } = state;
    const {
      payload: {
        item: { id },
        isExpanded,
      },
    } = action;

    const breakdownFromState = breakdowns[id];
    return {
      ...state,
      breakdowns: {
        ...breakdowns,
        [id]: { ...breakdownFromState, isExpanded: isExpanded },
      },
    };
  }),
  onAction(resetBreakdowns, state => {
    return {
      ...state,
      breakdowns: {},
    };
  })
);
