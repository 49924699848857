import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { bem } from 'utils/bem';
import { globalSearchSelector } from 'common/selectors';
import { RecentSearch } from './components';
import { SearchTabs } from './components/search-tabs';
import { SSearchModal, BEM_CLASS } from './s-search-modal';

interface SearchModalProps {
  className?: string;
  isRecentViewActive: boolean;
  onOverlayClick(): void;
}

const classes = bem(BEM_CLASS);

const rootAnimation = {
  initial: { opacity: 0 },
  exit: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { type: 'ease-out', duration: 0.3 },
};

export const SearchModal = React.memo(({ className, isRecentViewActive, onOverlayClick }: SearchModalProps) => {
  const { recent, top, activeTab, isActive } = useSelector(globalSearchSelector);

  const onContentClick = useCallback((e: React.SyntheticEvent) => {
    e.stopPropagation();
  }, []);

  const renderContent = useMemo(() => {
    if (isRecentViewActive) {
      return <RecentSearch loading={recent.loading} data={recent.data} />;
    }

    return <SearchTabs loading={top.loading} data={top.data} activeTab={activeTab} />;
  }, [activeTab, isRecentViewActive, recent.data, recent.loading, top.data, top.loading]);

  return (
    <AnimatePresence exitBeforeEnter>
      {isActive && (
        <SSearchModal className={className} {...rootAnimation} onClick={onOverlayClick}>
          <div className={classes('content')} onClick={onContentClick}>
            {renderContent}
          </div>
        </SSearchModal>
      )}
    </AnimatePresence>
  );
});
