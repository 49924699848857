import React, { useState, useCallback } from 'react';
import { SNoBrokenIconImage } from '../s-components/s-no-broken-icon-image';

type Props = React.ImgHTMLAttributes<unknown>;

export const NoBrokenIconImage = React.memo(({ onError, onLoad, ...props }: Props) => {
  const [isVisible, setIsVisible] = useState(true);

  const customOnError = useCallback(
    (event: React.SyntheticEvent<unknown, Event>) => {
      onError?.(event);
      setIsVisible(false);
    },
    [onError]
  );

  const customOnLoad = useCallback(
    (event: React.SyntheticEvent<unknown, Event>) => {
      onLoad?.(event);
      setIsVisible(true);
    },
    [onLoad]
  );

  return <SNoBrokenIconImage isVisible={isVisible} onError={customOnError} onLoad={customOnLoad} {...props} />;
});
