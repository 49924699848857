import { TeamUser } from 'backend-api/models';
import { createTypedReducer, onAction } from 'core/store';
import {
  addInitialTeamMembers,
  addTeamUsers,
  clearAll,
  deleteTeamUser,
  resetModal,
  updateTeamUser,
} from 'modals/sharing-modal/actions';
import differenceBy from 'lodash/differenceBy';
import { hasSameRoleAndCategories } from './transducers';

export interface ShareModalState {
  error?: string;
  initialMembers: TeamUser[];
  added: TeamUser[];
  deleted: TeamUser[];
  updated: TeamUser[];
}

export const initialState: ShareModalState = {
  added: [],
  deleted: [],
  updated: [],
  initialMembers: [],
};

export const reducer = createTypedReducer<ShareModalState>(
  initialState,
  onAction(addInitialTeamMembers, (state, action) => {
    return {
      ...state,
      initialMembers: action.payload,
    };
  }),
  onAction(deleteTeamUser, (state, action) => {
    const newAdded = differenceBy(state.added, [action.payload], user => user.id);
    const newUpdated = differenceBy(state.updated, [action.payload], user => user.id);
    const newDeleted = differenceBy(state.deleted, [action.payload], user => user.id);

    const isInAdded = state.added.includes(action.payload);

    return {
      ...state,
      deleted: isInAdded ? newDeleted : newDeleted.concat(action.payload),
      added: newAdded,
      updated: newUpdated,
    };
  }),
  onAction(addTeamUsers, (state, action) => {
    const isPending = state.deleted.find(pending => pending.id === action.payload.id);
    const newAdded = differenceBy(state.added, [action.payload], user => user.id);
    const newUpdated = differenceBy(state.updated, [action.payload], user => user.id);
    const newDeleted = differenceBy(state.deleted, [action.payload], user => user.id);

    const isReturnedToInitialState = hasSameRoleAndCategories(state.initialMembers, action.payload);

    return {
      ...state,
      deleted: newDeleted,
      added: isPending || isReturnedToInitialState ? newAdded : newAdded.concat(action.payload),
      updated: isPending && !isReturnedToInitialState ? newUpdated.concat(action.payload) : newUpdated,
    };
  }),
  onAction(updateTeamUser, (state, action) => {
    const isPending = state.added.find(pending => pending.id === action.payload.id);
    const newAdded = differenceBy(state.added, [action.payload], user => user.id);
    const newUpdated = differenceBy(state.updated, [action.payload], user => user.id);
    const newDeleted = differenceBy(state.deleted, [action.payload], user => user.id);

    const isReturnedToInitialState = hasSameRoleAndCategories(state.initialMembers, action.payload);

    return {
      ...state,
      deleted: newDeleted,
      added: isPending && !isReturnedToInitialState ? newAdded.concat(action.payload) : newAdded,
      updated: isPending || isReturnedToInitialState ? newUpdated : newUpdated.concat(action.payload),
    };
  }),
  onAction(clearAll, (state, action) => {
    const newDeleted = state.deleted.concat(action.payload).concat(state.updated);

    return {
      ...state,
      deleted: newDeleted,
      added: [],
      updated: [],
    };
  }),
  onAction(resetModal, () => initialState)
);
