import React from 'react';
import { TippyProps } from '@tippyjs/react';
import { SPopover } from './s-popover';

export const Popover = React.memo(
  ({ content, placement = 'bottom', children, arrow = true, className, ...rest }: TippyProps) => {
    return (
      <SPopover arrow={arrow} content={content} placement={placement} interactive {...rest}>
        <span className={className}>{children}</span>
      </SPopover>
    );
  }
);
