import React, { PureComponent } from 'react';
import { SLogin } from '../s-components/s-login';

// type TProps = {};

export class Auth extends PureComponent {
  render() {
    return (
      <>
        <SLogin />
        <div />
      </>
    );
  }
}
