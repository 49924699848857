import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'tooltip-content';

export const STooltipContentWithError = styled.div`
  .${BEM_CLASS} {
    &__error-notification {
      height: 32px;
      margin-bottom: 7px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
    }
  }
`;
