import { apiClient, ResponseArray } from '../index';
import {
  PerformanceObjectivesAndMetricsSchema,
  BreakdownOptionSchema,
  PerformanceBreakdownDataSchema,
  PerformanceDataSchema,
  PerformancePlatformsSchema,
} from '../models';
import { PerformanceBreakdownDataParams, PerformanceDataParams, PerformancePlatformsParams } from '../types';

export const getArtistsPerformanceData = (params: PerformanceDataParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/performance/metrics/artists',
    responseSchema: PerformanceDataSchema,
    queryParams: params,
  });

export const getProjectsPerformanceData = (params: PerformanceDataParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/performance/metrics/projects',
    responseSchema: PerformanceDataSchema,
    queryParams: params,
  });

export const getCampaignsPerformanceData = (params: PerformanceDataParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/performance/metrics/campaigns',
    responseSchema: PerformanceDataSchema,
    queryParams: params,
  });

export const getUnassignedCampaignsPerformanceData = (params: PerformanceDataParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/performance/metrics/unassigned-campaigns',
    responseSchema: PerformanceDataSchema,
    queryParams: params,
  });

export const getPerformanceObjectives = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/performance/metrics',
    responseSchema: PerformanceObjectivesAndMetricsSchema,
  });

export const getPerformancePlatforms = (params?: PerformancePlatformsParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/performance/platforms',
    responseSchema: PerformancePlatformsSchema,
    queryParams: params,
  });

export const getArtistsPerformanceBreakdownOptions = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/performance/breakdown_options/artists',
    responseSchema: ResponseArray(BreakdownOptionSchema),
  });

export const getProjectsPerformanceBreakdownOptions = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/performance/breakdown_options/projects',
    responseSchema: ResponseArray(BreakdownOptionSchema),
  });

export const getCampaignsPerformanceBreakdownOptions = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/performance/breakdown_options/campaigns',
    responseSchema: ResponseArray(BreakdownOptionSchema),
  });

export const getUnassignedCampaignsPerformanceBreakdownOptions = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/performance/breakdown_options/unassigned_campaigns',
    responseSchema: ResponseArray(BreakdownOptionSchema),
  });

export const getArtistPerformanceBreakdown = (id: string, params: PerformanceBreakdownDataParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/performance/metrics/artists/${id}/details`,
    responseSchema: PerformanceBreakdownDataSchema,
    queryParams: params,
  });

export const getProjectsPerformanceBreakdown = (id: string, params: PerformanceBreakdownDataParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/performance/metrics/projects/${id}/details`,
    responseSchema: PerformanceBreakdownDataSchema,
    queryParams: params,
  });

export const getCampaignsPerformanceBreakdown = (id: string, params: PerformanceBreakdownDataParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/performance/metrics/campaigns/${id}/details`,
    responseSchema: PerformanceBreakdownDataSchema,
    queryParams: params,
  });

export const getUnassignedCampaignsPerformanceBreakdown = (id: string, params: PerformanceBreakdownDataParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/performance/metrics/unassigned-campaigns/${id}/details`,
    responseSchema: PerformanceBreakdownDataSchema,
    queryParams: params,
  });
