import styled from 'styled-components';

export const BEM_CLASS = 'user-role-editor';

export const SUserRoleEditor = styled.div`
  .${BEM_CLASS} {
    &__content {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;

      &--isDisabled {
        cursor: default;
      }
    }
  }
`;
