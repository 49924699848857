import { apiClient, ResponseArray } from '../index';
import { ArtistRosterProjectSchema, ArtistRosterSchema, Id } from '../models';
import { ArtistRosterProjectsParams } from '../types';

export const getArtistRoster = (id: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/artist-roster`,
    responseSchema: ArtistRosterSchema,
    queryParams: { labelId: id },
  });

export const getArtistRosterProjects = (artistId: string, params?: ArtistRosterProjectsParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/artist-roster/${artistId}/projects`,
    responseSchema: ResponseArray(ArtistRosterProjectSchema),
    queryParams: params,
  });
