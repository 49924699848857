import { UserDetailsResponse } from 'backend-api/types';
import {
  CreateMediaPlanRequest,
  CreatePhaseRequest,
  EditPhaseRequest,
  Id,
  MediaPlanPhase,
  MediaPlanRelease,
  ModifyBulkCampaignsRequest,
  ModifyBulkCampaignsResponse,
  Nullable,
  RequestApprovalRequest,
  SubmitApprovalRequest,
} from 'backend-api/models';

export interface WithConfirmPayload {
  text: string;
  action: () => void;
}

export interface GetMediaPlanPayload {
  projectId: Id;
  mediaPlanId: Id;
}

export interface CreatePhasePayload {
  projectId: Id;
  mediaPlanId: Id;
  payload: CreatePhaseRequest;
  onSuccess(phase: MediaPlanPhase): void;
  onError(): void;
}

export interface EditPhasePayload {
  projectId: Id;
  mediaPlanId: Id;
  phaseId: Id;
  payload: EditPhaseRequest;
  onSuccess(phase: MediaPlanPhase): void;
  onError(): void;
}

export interface DeletePhasePayload {
  projectId: Id;
  mediaPlanId: Id;
  phaseId: Id;
  onError(): void;
}

export interface ModifyBulkCampaignsPayload {
  mediaPlanId: Id;
  projectId: Id;
  payload: ModifyBulkCampaignsRequest;

  onSuccess(response: ModifyBulkCampaignsResponse): void;
  onError(): void;
}

export interface UpdateMediaPlanReleasePayload {
  mediaPlanId: Id;
  projectId: Id;
  payload: MediaPlanRelease;
}

export enum MediaPlanMode {
  REQUEST_APPROVAL = 'request',
  DEFAULT = 'default',
  APPROVAL = 'approval',
}

export enum MediaPlanReferrerType {
  EMAIL = 'email',
}

export interface RequestApprovalPayload {
  mediaPlanId: Id;
  projectId: Id;
  payload: RequestApprovalRequest;
  onError?: () => void;
}

export interface MediaPlanModeWidgetConfig {
  stickyFooter: Nullable<React.ReactElement>;
}

export interface SubmitApprovalPayload {
  mediaPlanId: Id;
  projectId: Id;
  payload: SubmitApprovalRequest;
  onSubmitSuccess(): void;
}

interface CustomDataResponse {
  code: string;
  detail?: UserDetailsResponse;
}

export interface CustomResponseError extends Error {
  statusCode: number;
  data?: CustomDataResponse;
}

export interface CreateMediaPlanPayload {
  projectId: Id;
  payload: CreateMediaPlanRequest;
  onMediaPlanCreated?: (id: Id) => void;
}

export interface EditMediaPlanPayload {
  projectId: Id;
  mediaPlanId: Id;
  payload: CreateMediaPlanRequest;
}

export interface DeleteMediaPlanPayload {
  projectId: Id;
  mediaPlanId: Id;
  onMediaPlanDeleted?: (id: Id) => void;
}

export interface DeleteMediaPlanModalInfo {
  mediaPlanId?: Id;
  isVisible: boolean;
}
