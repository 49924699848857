import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'po-table';

export const STable = styled.div`
  &&& {
    .${BEM_CLASS} {
      &__table {
        margin-top: 25px;
        min-height: 180px;
        overflow: visible;
      }

      &__table-container {
        overflow: hidden;
      }

      &__small-table-cell,
      &__currency-header {
        width: 125px;
        min-width: 125px;
        flex: 0;
      }

      &__menu-cell {
        min-width: 54px;
        width: 54px;
        flex: 0;
      }

      &__purchase-order-label {
        text-align: end;
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
      }

      &__sub-title-container {
        display: flex;
        align-items: center;

        &--expanded {
          margin-bottom: 10px;
        }
      }

      &__sub-title {
        color: ${colorPalette.eastBay};
      }

      &__currency-header {
        .header__sub-title {
          color: ${colorPalette.eastBay};
          height: 15px;
          font-size: 10px;
          letter-spacing: 0;
          line-height: 15px;
          text-align: right;
          margin-right: 15px;
        }
      }

      &__dot-divider {
        height: 6px;
        width: 6px;
        background: ${colorPalette.lavenderGray};
        border-radius: 6px;
        margin: 0 8px;
      }

      &__purchase-order-name-container {
        &--expanded {
          height: unset;
          min-height: unset;
          margin-top: 8px;
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &__name-divider {
        height: 1px;
        width: calc(100% + 20px);
        border-radius: 10px 10px 0 0;
        background-color: rgba(228, 229, 240, 1);
      }

      &__table-value-status-expanded-container {
        align-items: flex-start;
      }

      &__table-value,
      &__table-value-status {
        font-size: 14px;
        margin-right: 20px;

        &--textRight {
          width: 100%;
        }

        &--expanded {
          padding: 0 10px;
        }
      }

      &__table-value-status {
        width: fit-content;
        white-space: nowrap;
      }
    }
  }
`;
