import React from 'react';
import { bem } from 'utils/bem';
import { SResponsiveContainer, BEM_CLASS } from '../../s-components/sidebar-form/s-responsive-container';

interface Props {
  filtersOpened?: boolean;
  filtersMargin?: number[];
  background?: string;
  className?: string;
  children: React.ReactNode;
}

const classes = bem(BEM_CLASS);

export const ResponsiveContainer = ({
  filtersOpened,
  filtersMargin,
  background = 'transparent',
  children,
  className,
}: Props) => (
  <SResponsiveContainer
    background={background}
    filtersOpened={filtersOpened}
    className={className}
    filtersMargin={filtersMargin}
  >
    <div className={classes('wrapper')}>{children}</div>
  </SResponsiveContainer>
);
