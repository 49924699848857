export enum EmptyViewMode {
  None = 'None',
  Default = 'Default',
  Search = 'Search',
  Loading = 'Loading',
  Error = 'Error',
}

export interface EmptyViewParams {
  mode: EmptyViewMode;
  fetchData(): void;
}
