import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ActionRenderer } from '../action-renderer';
import { MultiValueView } from '../multi-value-view/multi-value-view';
import { SpreadsheetRowData } from '../../types';

export const PlatformsRenderer = ({
  context,
  value: { color, value },
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['platforms']>) => (
  <ActionRenderer color={color} accessMode={context.accessMode} placeholder="Select or type">
    {value && value.length > 0 ? <MultiValueView values={value.map(value => value.name)} /> : null}
  </ActionRenderer>
);
