import React from 'react';
import { ValueContainerProps } from 'react-select';
import { SelectOption } from '../../types';
import { SApproversValueContainer } from './s-approvers-value-container';

export const ApproversValueContainer = React.memo(
  ({ innerProps, children }: ValueContainerProps<SelectOption, true>) => {
    return (
      <SApproversValueContainer {...innerProps} ref={null}>
        {children}
      </SApproversValueContainer>
    );
  }
);
