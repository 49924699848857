import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const SConfirmModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  .confirm-modal-content {
    &__header-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 8px;
      justify-content: space-between;
    }
    &__close-icon {
      margin-right: -12px;
      cursor: pointer;
    }
    &__title {
      color: ${palette.darkBlue};
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      margin-top: 10px;
    }
    &__content-text {
      color: ${palette.darkBlue};
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
    }
  }
`;
