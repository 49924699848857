import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ActionRenderer } from '../action-renderer';
import { SingleValueView } from '../single-value-view';
import { SpreadsheetRowData } from '../../types';

export const KpiRenderer = ({
  value: { color, value },
  context,
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['kpi']>) => (
  <ActionRenderer color={color} accessMode={context.accessMode} placeholder="Select or type">
    {value?.value && <SingleValueView value={value.value} />}
  </ActionRenderer>
);
