import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';
import { EXPANDED_CONTAINER_HEIGHT, EXPANDED_CONTAINER_WIDTH } from './constants';

export const BEM_CLASS = 'expandable-text';

export const SExpandableText = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: ${EXPANDED_CONTAINER_WIDTH}px;
  height: ${EXPANDED_CONTAINER_HEIGHT}px;

  .${BEM_CLASS} {
    &__textarea {
      width: 100%;
      flex-grow: 1;
      border: 0;
      resize: none;
      padding: 10px;

      &::placeholder {
        color: ${colorPalette.wildBlueYonder};
      }
    }

    &__footer {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
    }

    &__clear {
      font-size: 10px;
      line-height: 15px;
      font-weight: 700;
      letter-spacing: 1px;
      margin-right: 10px;
      text-transform: uppercase;
      transition: color 0.3s ease-out;

      &:focus {
        color: ${colorPalette.azureRadiance};
      }

      &:hover {
        color: ${colorPalette.ceriseRed};
      }

      &--isHidden {
        visibility: hidden;
      }
    }

    &__counter {
      font-size: 10px;
      line-height: 15px;
      letter-spacing: 1px;
      color: ${colorPalette.wildBlueYonder};
      text-transform: uppercase;
    }

    &__max {
      color: ${colorPalette.ceriseRed};

      &--isHidden {
        visibility: hidden;
      }
    }
  }
`;
