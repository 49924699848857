import React from 'react';
import { SingleValueProps } from 'react-select';
import { BaseOption as BaseOptionType } from '../../types';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { SSingleValue } from './s-single-value';

type Props<T, IsMulti extends boolean> = SingleValueProps<T, IsMulti>;

const Root = <T extends BaseOptionType, IsMulti extends boolean>({
  isDisabled,
  children,
  selectProps,
}: Props<T, IsMulti>) => {
  const { classNames, dataSelector } = getBaseCustomSelectProps(selectProps);

  return (
    <SSingleValue
      className={classNames.singleValue}
      isDisabled={isDisabled}
      data-selector={`${dataSelector}-single-value`}
    >
      {children}
    </SSingleValue>
  );
};

export const SingleValue = React.memo(Root) as typeof Root;
