import { DateFormatOption } from './types';

enum DateFormatPatterns {
  'MM/DD/YY, hh:mm A',
  'MM/DD/YY, HH:mm',
  'DD/MM/YY, hh:mm A',
  'DD/MM/YY, HH:mm',
}

export const DATE_FORMAT_OPTIONS: DateFormatOption[] = [
  {
    id: 1,
    format: DateFormatPatterns[0],
    name: 'Month / Day / Year | AM / PM',
  },
  {
    id: 2,
    format: DateFormatPatterns[1],
    name: 'Month / Day / Year | 24 Hour',
  },
  {
    id: 3,
    format: DateFormatPatterns[2],
    name: 'Day / Month / Year | AM / PM',
  },
  {
    id: 4,
    format: DateFormatPatterns[3],
    name: 'Day / Month / Year | 24 Hour',
  },
];
