import { useEffect, useRef } from 'react';
import { Optional } from 'backend-api/models';

export const useInitialValue = <T>(value: T): Optional<T> => {
  const initialValue = useRef<T>();

  useEffect(() => {
    initialValue.current = value;
    // Important for initial value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return initialValue.current;
};
