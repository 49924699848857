import React, { useCallback, useMemo } from 'react';
import { BEM_CLASS, SCampaignList } from './s-campaign-list';
import { CampaignItem, Loader } from './components';
import { Badge, BadgeType, InfiniteScroll, Skeleton } from 'common-v2/components';
import { getShortCurrencyFormatted } from 'common-v2/utils';
import { CampaignDetails, Id } from 'backend-api/models';
import { bem } from 'utils/bem';
import { Loading } from 'common-v2/types';
import { EmptyResult } from '../empty-result';
import { Typography, TYPOGRAPHY_TYPE, THEME } from 'gdb-web-shared-components';

interface ICampaignListProps {
  campaigns: CampaignDetails[];
  campaignsTotalCount: number;
  selectedIds: Id[];
  loading: Loading;
  disabled: boolean;
  infiniteLoading: Loading;
  formatDate(date?: Date): void;
  onScrollEnd(): void;
  toggleCampaign(campaign: CampaignDetails): void;
}

const classes = bem(BEM_CLASS);

export const CampaignList = React.memo(
  ({
    campaigns,
    selectedIds,
    loading,
    disabled,
    infiniteLoading,
    campaignsTotalCount,
    formatDate,
    onScrollEnd,
    toggleCampaign,
  }: ICampaignListProps) => {
    const checkboxChangeHandler = useCallback(
      (campaign: CampaignDetails) => () => {
        toggleCampaign(campaign);
      },
      [toggleCampaign]
    );

    const getCheckboxProps = useCallback(
      (campaign: CampaignDetails) => ({
        disabled,
        theme: THEME.light,
        checked: selectedIds.includes(campaign.id),
        onChange: checkboxChangeHandler(campaign),
      }),
      [selectedIds, disabled, checkboxChangeHandler]
    );

    const getSubtitle = useCallback(
      (campaign: CampaignDetails) => {
        const platforms = campaign.platforms.map(platform => platform.name).join(', ');
        const currency = getShortCurrencyFormatted(campaign.budgetSpend, campaign.currency);
        const dateRange = `${formatDate(campaign.startDate)} 
        ${campaign.endDate ? `- ${formatDate(campaign.endDate)}` : ''}`;
        return [platforms, currency, dateRange].join(' | ');
      },
      [formatDate]
    );

    const campaignsList = useMemo(
      () =>
        campaigns.length ? (
          <InfiniteScroll
            onScrollEnd={onScrollEnd}
            isActive={campaignsTotalCount > campaigns.length}
            isLoading={infiniteLoading === Loading.Started}
            loader={
              <Skeleton width={285} height={25}>
                <rect x="0" y="0" rx="2px" ry="2px" width="20" height="20" />
                <rect x="35" y="0" rx="2px" ry="2px" width="250" height="25" />
              </Skeleton>
            }
          >
            {campaigns.map(campaign => (
              <div key={campaign.id} className={classes('campaign')}>
                <CampaignItem
                  title={campaign.name}
                  checkboxProps={getCheckboxProps(campaign)}
                  subTitle={getSubtitle(campaign)}
                  titleChip={campaign.isPending ? <Badge text="Pending" type={BadgeType.Outlined} /> : undefined}
                />
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <EmptyResult>
            <Typography type={TYPOGRAPHY_TYPE.body4}>No results found.</Typography>
            <Typography type={TYPOGRAPHY_TYPE.body4}>Please try editing your query.</Typography>
          </EmptyResult>
        ),
      [campaigns, getCheckboxProps, onScrollEnd, getSubtitle, campaignsTotalCount, infiniteLoading]
    );

    return <SCampaignList>{loading === Loading.Started ? <Loader /> : campaignsList}</SCampaignList>;
  }
);
