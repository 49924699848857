import psl from 'psl';
import { capitalize, last } from 'lodash';
import { Optional } from 'backend-api/models';
import { LinkParsingError, LinkPlatform } from './types';
import { PLATFORM_SLDS } from './constants';
import { HTTPS_LINK_PREFIX } from 'common/constants';

const LINK_REGEX = RegExp(
  `(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)`
);

export class DecibelLink {
  public url: URL;
  public label: string;
  public platform: LinkPlatform;

  constructor(link: string) {
    try {
      this.url = new URL(link);
    } catch (e) {
      this.url = this.getDecibelLinkFromUnfinishedLink(link);
    }

    const parsedLink = psl.parse(this.url.hostname);

    if (parsedLink.error) {
      throw new LinkParsingError('Invalid link');
    }
    const customLabel = DecibelLink.getCustomLabelFromParsedLink(parsedLink, link);
    const defaultLabel = DecibelLink.getDefaultLabelFromParsedLink(parsedLink);
    this.label = customLabel || defaultLabel;

    this.platform = DecibelLink.getPlatformFromParsedLink(parsedLink);
  }

  private static getDefaultLabelFromParsedLink = (parsedLink: psl.ParsedDomain): string => {
    if (!parsedLink.sld) {
      throw new LinkParsingError('Invalid link');
    }

    const domainName = parsedLink.sld;
    const subDomain = parsedLink.subdomain?.replace('www.', '').replace('www', '');

    const formattedDomainName = capitalize(domainName);
    const formattedSubDomain = subDomain && subDomain !== 'null' ? ` ${capitalize(subDomain)}` : '';
    return `${formattedDomainName}${formattedSubDomain} Link`;
  };

  private static getCustomLabelFromParsedLink = (parsedLink: psl.ParsedDomain, baseLink: string): Optional<string> => {
    switch (parsedLink.sld) {
      case PLATFORM_SLDS.spotify:
        return 'Spotify Link';
      case PLATFORM_SLDS.smarturl:
        return 'SmartURL Link';
      case PLATFORM_SLDS.linkfire:
        return baseLink.replace(HTTPS_LINK_PREFIX, '');
      default:
        return undefined;
    }
  };

  private extractHostname = (url: string): string => {
    let hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf('//') > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];

    return hostname;
  };

  private getDecibelLinkFromUnfinishedLink = (link: string): URL => {
    if (link.match(LINK_REGEX)) {
      const parsedLink = psl.parse(this.extractHostname(link));
      if (parsedLink.error) {
        throw new LinkParsingError('Invalid link');
      }
      const { domain, subdomain } = parsedLink;
      const subDomain = subdomain && subdomain !== 'null' ? subdomain + '.' : 'www.';
      const pathElements = link.split(domain || '');
      const restLink = pathElements?.length === 1 ? '' : last(pathElements);
      return new URL(`https:\\` + subDomain + domain + restLink);
    } else {
      throw new LinkParsingError('Invalid link');
    }
  };

  private static getPlatformFromParsedLink = (parsedLink: psl.ParsedDomain): LinkPlatform => {
    switch (parsedLink.sld) {
      case PLATFORM_SLDS.amazon:
        return LinkPlatform.Amazon;
      case PLATFORM_SLDS.apple:
        return LinkPlatform.Apple;
      case PLATFORM_SLDS.deezer:
        return LinkPlatform.Deezer;
      case PLATFORM_SLDS.facebook:
        return LinkPlatform.Facebook;
      case PLATFORM_SLDS.google:
        return LinkPlatform.Google;
      case PLATFORM_SLDS.instagram:
        return LinkPlatform.Instagram;
      case PLATFORM_SLDS.linkfire:
        return LinkPlatform.Linkfire;
      case PLATFORM_SLDS.pandora:
        return LinkPlatform.Pandora;
      case PLATFORM_SLDS.shazam:
        return LinkPlatform.Shazam;
      case PLATFORM_SLDS.smarturl:
        return LinkPlatform.SmartURL;
      case PLATFORM_SLDS.snapchat:
        return LinkPlatform.Snapchat;
      case PLATFORM_SLDS.soundcloud:
        return LinkPlatform.SoundCloud;
      case PLATFORM_SLDS.spotify:
        return LinkPlatform.Spotify;
      case PLATFORM_SLDS.tidal:
        return LinkPlatform.Tidal;
      case PLATFORM_SLDS.tiktok:
        return LinkPlatform.TikTok;
      case PLATFORM_SLDS.twitter:
        return LinkPlatform.Twitter;
      case PLATFORM_SLDS.youtube:
        return LinkPlatform.YouTube;
      default:
        return LinkPlatform.Unknown;
    }
  };
}
