import { Optional, ProjectDetails, ProjectSharing, ArtistSearch, LabelUser } from 'backend-api/models';
import { createTypedReducer, onAction } from 'core/store';
import { ErrorViewStates } from 'common-v2/components';
import { Loadable, Loading } from 'common-v2/types';
import {
  getProjectSharingInfo,
  openShareModal,
  closeShareModal,
  addRecentArtist,
  clearAllArtists,
  closeEditArtistsModal,
  editProject,
  getProjectDetails,
  openEditArtistsModal,
  removeArtist,
  removeRecentArtist,
  resetSearchResults,
  searchArtists,
  setArtistsSearch,
  setErrorViewType,
  getUsers,
  updateProjectUser,
  removeProjectUser,
} from './actions';
import { getErrorViewType, updateUserRole } from './transducers';
import { ROLES } from 'common-v2/constants';

export interface EditArtistsModalState {
  isOpen: boolean;
  searchResults: Loadable<ArtistSearch[]>;
  projectArtists: ArtistSearch[];
  recentlyAddedArtists: ArtistSearch[];
  search: string;
  processing: Loading;
}

export interface ProjectState {
  errorViewType: ErrorViewStates;
  details: Loadable<Optional<ProjectDetails>>;
  shareModal: {
    isOpened: boolean;
    content: Loadable<Optional<ProjectSharing>>;
    users: Loadable<Optional<LabelUser[]>>;
  };
  isEditing: boolean;
  editArtistsModal: EditArtistsModalState;
}

export const initialState: ProjectState = {
  errorViewType: ErrorViewStates.none,
  details: {
    loading: Loading.Idle,
    data: undefined,
  },
  shareModal: {
    isOpened: false,
    content: {
      loading: Loading.Idle,
      data: undefined,
    },
    users: {
      loading: Loading.Idle,
      data: undefined,
    },
  },
  isEditing: false,
  editArtistsModal: {
    isOpen: false,
    searchResults: {
      loading: Loading.Idle,
      data: [],
    },
    projectArtists: [],
    search: '',
    processing: Loading.Idle,
    recentlyAddedArtists: [],
  },
};

export const reducer = createTypedReducer<ProjectState>(
  initialState,
  onAction(setErrorViewType, (state, { payload }) => ({
    ...state,
    errorViewType: payload,
  })),
  onAction(openShareModal, state => ({ ...state, shareModal: { ...state.shareModal, isOpened: true } })),
  onAction(closeShareModal, state => ({ ...state, shareModal: { ...state.shareModal, isOpened: false } })),
  onAction(getProjectDetails.request, state => ({
    ...state,
    errorViewType: ErrorViewStates.none,
    details: {
      loading: Loading.Started,
      data: undefined,
    },
  })),
  onAction(getProjectDetails.success, (state, { payload }) => ({
    ...state,
    details: {
      loading: Loading.Finished,
      data: payload,
    },
  })),
  onAction(getProjectDetails.failure, (state, { payload }) => ({
    ...state,
    errorViewType: getErrorViewType(payload),
    details: {
      loading: Loading.Failed,
      data: undefined,
    },
  })),
  onAction(getProjectSharingInfo.request, state => ({
    ...state,
    shareModal: {
      ...state.shareModal,
      content: {
        loading: Loading.Started,
        data: undefined,
      },
    },
  })),
  onAction(getProjectSharingInfo.success, (state, { payload }) => ({
    ...state,
    shareModal: {
      ...state.shareModal,
      content: {
        loading: Loading.Finished,
        data: payload,
      },
    },
  })),
  onAction(getProjectSharingInfo.failure, state => ({
    ...state,
    shareModal: {
      ...state.shareModal,
      content: {
        loading: Loading.Failed,
        data: undefined,
      },
    },
  })),
  onAction(updateProjectUser.request, (state, { payload: { role, userId } }) => {
    if (state.shareModal.content.loading !== Loading.Finished) return state;

    return {
      ...state,
      shareModal: {
        ...state.shareModal,
        content: {
          ...state.shareModal.content,
          data: {
            ...state.shareModal.content.data,
            projectTeam: state.shareModal.content.data.projectTeam.map(updateUserRole(userId, role)),
          },
        },
      },
    };
  }),
  onAction(updateProjectUser.failure, (state, { payload: { role, userId } }) => {
    if (state.shareModal.content.loading !== Loading.Finished) return state;

    return {
      ...state,
      shareModal: {
        ...state.shareModal,
        content: {
          ...state.shareModal.content,
          data: {
            ...state.shareModal.content.data,
            projectTeam: state.shareModal.content.data.projectTeam.map(updateUserRole(userId, role)),
          },
        },
      },
    };
  }),
  onAction(editProject.request, state => ({
    ...state,
    isEditing: true,
  })),
  onAction(editProject.failure, state => ({
    ...state,
    isEditing: false,
  })),
  onAction(editProject.success, (state, { payload }) => ({
    ...state,
    isEditing: false,
    details: {
      loading: Loading.Finished,
      data: payload,
    },
  })),
  onAction(openEditArtistsModal, (state, { payload }) => ({
    ...state,
    editArtistsModal: {
      ...state.editArtistsModal,
      projectArtists: payload.selectedArtists,
      isOpen: true,
    },
  })),
  onAction(closeEditArtistsModal, state => ({
    ...state,
    editArtistsModal: initialState.editArtistsModal,
  })),
  onAction(clearAllArtists, state => ({
    ...state,
    editArtistsModal: {
      ...state.editArtistsModal,
      projectArtists: [],
      recentlyAddedArtists: [],
    },
  })),
  onAction(addRecentArtist, (state, { payload }) => ({
    ...state,
    editArtistsModal: {
      ...state.editArtistsModal,
      recentlyAddedArtists: [payload, ...state.editArtistsModal.recentlyAddedArtists].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    },
  })),
  onAction(removeArtist, (state, { payload }) => ({
    ...state,
    editArtistsModal: {
      ...state.editArtistsModal,
      projectArtists: state.editArtistsModal.projectArtists.filter(({ id }) => payload.id !== id),
    },
  })),
  onAction(removeRecentArtist, (state, { payload }) => ({
    ...state,
    editArtistsModal: {
      ...state.editArtistsModal,
      recentlyAddedArtists: state.editArtistsModal.recentlyAddedArtists.filter(({ id }) => payload.id !== id),
    },
  })),
  onAction(setArtistsSearch, (state, { payload }) => ({
    ...state,
    editArtistsModal: {
      ...state.editArtistsModal,
      search: payload,
    },
  })),
  onAction(searchArtists.request, state => ({
    ...state,
    editArtistsModal: {
      ...state.editArtistsModal,
      searchResults: { loading: Loading.Started, data: [] },
    },
  })),
  onAction(searchArtists.failure, state => ({
    ...state,
    editArtistsModal: {
      ...state.editArtistsModal,
      searchResults: { loading: Loading.Failed, data: [] },
    },
  })),
  onAction(searchArtists.success, (state, { payload }) => ({
    ...state,
    editArtistsModal: {
      ...state.editArtistsModal,
      searchResults: { loading: Loading.Finished, data: payload },
    },
  })),
  onAction(resetSearchResults, state => ({
    ...state,
    editArtistsModal: {
      ...state.editArtistsModal,
      searchResults: { loading: Loading.Idle, data: [] },
    },
  })),
  onAction(getUsers.request, state => ({
    ...state,
    shareModal: {
      ...state.shareModal,
      users: {
        loading: Loading.Started,
        data: undefined,
      },
    },
  })),
  onAction(getUsers.success, (state, { payload }) => ({
    ...state,
    shareModal: {
      ...state.shareModal,
      users: {
        loading: Loading.Finished,
        data: payload,
      },
    },
  })),
  onAction(getUsers.failure, state => ({
    ...state,
    shareModal: {
      ...state.shareModal,
      users: {
        loading: Loading.Failed,
        data: undefined,
      },
    },
  })),
  onAction(removeProjectUser.request, (state, { payload: { roleId, userId } }) => {
    if (state.shareModal.content.loading !== Loading.Finished) return state;

    const contentData =
      roleId === ROLES.APPROVER.id
        ? {
            ...state.shareModal.content.data,
            approvers: state.shareModal.content.data.approvers.filter(({ id }) => id !== userId),
          }
        : {
            ...state.shareModal.content.data,
            projectTeam: state.shareModal.content.data.projectTeam.filter(({ id }) => id !== userId),
          };

    return {
      ...state,
      shareModal: {
        ...state.shareModal,
        content: {
          ...state.shareModal.content,
          data: contentData,
        },
      },
    };
  })
);
