import * as React from 'react';
import { PropsWithChildren } from 'react';
import { BEM_CLASS, STooltipContentWithError } from './s-tooltip-content-with-error';
import { bem } from 'utils/bem';
import { ValidationTooltipContent } from './../validation-tooltip-content';

interface Props {
  errorText?: string;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const TooltipContentWithError = React.memo(({ errorText, className, children }: PropsWithChildren<Props>) => (
  <STooltipContentWithError className={className}>
    {errorText && (
      <>
        <ValidationTooltipContent text={errorText} className={classes('error-notification')} />
        {children && <div className={classes('divider')} />}
      </>
    )}
    {children}
  </STooltipContentWithError>
));
