import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'menu-list';

export const SMenuList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 340px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px 0;
  background-color: ${colorPalette.white};

  .${BEM_CLASS} {
    &__options {
      display: flex;
      flex-direction: column;
    }
  }
`;
