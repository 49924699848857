import {
  DatePickerMode,
  DatePickerProps,
  RangeComponentsProps,
  RangeDatePickerProps,
  SingleComponentsProps,
} from './types';

//Changing one of these guards requires checking all useEffect, useMemo, useCallback dependencies as it can break updating logic

export const isRangeProps = (props: DatePickerProps): props is RangeDatePickerProps =>
  props.mode === DatePickerMode.RANGE;

export const isRangeComponentsProps = (
  props: SingleComponentsProps | RangeComponentsProps
): props is RangeComponentsProps => props.mode === DatePickerMode.RANGE;
