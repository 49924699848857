import { MediaPlanCampaignStylesRequest, MediaPlanCampaignRequest } from 'backend-api/models';
import { RequestPayloadItem } from './types';

const emptyStyleRequestPayload: MediaPlanCampaignStylesRequest = {
  name: undefined,
  territories: undefined,
  startDate: undefined,
  endDate: undefined,
  platforms: undefined,
  campaignType: undefined,
  notes: undefined,
  audienceNotes: undefined,
  adCreativeNotes: undefined,
  kpiMetricsFieldId: undefined,
  provider: undefined,
  objective: undefined,
  budgetSpend: undefined,
  ecpm: undefined,
  plannedBudget: undefined,
  workflowStatus: undefined,
  genders: undefined,
  audienceLowerAge: undefined,
  audienceHigherAge: undefined,
  placements: undefined,
  adCreativeLinks: undefined,
  destinationLinks: undefined,
  creativeDescription: undefined,
  headline: undefined,
  callToAction: undefined,
  actualSpent: undefined,
  namingConventionManual: undefined,
  estImpressions: undefined,
};

const emptyColumnRequestPayload: MediaPlanCampaignRequest = {
  name: undefined,
  territories: undefined,
  startDate: undefined,
  endDate: undefined,
  platforms: undefined,
  campaignType: undefined,
  notes: undefined,
  audienceNotes: undefined,
  adCreativeNotes: undefined,
  kpiMetricsFieldId: undefined,
  provider: undefined,
  objective: undefined,
  budgetSpend: undefined,
  ecpm: undefined,
  plannedBudget: undefined,
  workflowStatus: undefined,
  genders: undefined,
  audienceLowerAge: undefined,
  audienceHigherAge: undefined,
  placements: undefined,
  adCreativeLinks: undefined,
  destinationLinks: undefined,
  creativeDescription: undefined,
  headline: undefined,
  callToAction: undefined,
  actualSpent: undefined,
  namingConventionManual: undefined,
};

export const emptyRequestPayloadItem: RequestPayloadItem = {
  style: emptyStyleRequestPayload,
  columns: emptyColumnRequestPayload,
};
