import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { CampaignStatuses } from 'backend-api/models';
import { bem } from 'utils/bem';
import { isEnumValue } from 'common-v2/types';
import { MediaPlanMode } from 'media-plan-v2/types';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { SingleValueView, StatusBadge } from 'media-plan-v2/components/spreadsheet/components';
import { BEM_CLASS, SStatusRenderer } from './s-status-renderer';

const classes = bem(BEM_CLASS);

export const StatusRenderer = React.memo(
  ({
    value: { color, value },
    context,
    data,
  }: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['status']>) => {
    const isStatusDuringApproval = context.mediaPlanMode === MediaPlanMode.APPROVAL && data?.editableInApproval?.value;
    const normalizedValue =
      isStatusDuringApproval && value === CampaignStatuses.PENDING ? CampaignStatuses.REVIEW : value;
    const validatedValue = isEnumValue(CampaignStatuses)(normalizedValue) ? normalizedValue : null;

    return (
      <SStatusRenderer color={color} accessMode={context.accessMode}>
        {validatedValue === CampaignStatuses.REVIEW ? (
          <SingleValueView value={validatedValue} textClassName={classes('text')} />
        ) : (
          validatedValue && <StatusBadge status={validatedValue} />
        )}
      </SStatusRenderer>
    );
  }
);
