import { ApiError } from 'backend-api';
import {
  ArtistTeam,
  BudgetGroup,
  Campaign,
  HistoryEvent,
  MarketingMix,
  Optional,
  Phase,
  ProjectDetails,
  ProjectUser,
  TeamCountersResponse,
  TeamUser,
  User,
} from 'backend-api/models';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { createTypedReducer, onAction } from 'core/store';
import {
  claimUnassignedProject,
  clearSelectionArtistModal,
  closeArtistModal,
  closeShareWindow,
  deleteProject,
  deselectArtist,
  deselectPlaylist,
  editProject,
  getBudgetGroupsForProjectAction,
  getFunnelGraphData,
  getNotesHistoryAction,
  getProject,
  getProjectHistoryAction,
  getProjectMarketingMixAction,
  getProjectTeamUsersInfo,
  getTeamCountersAction,
  getUnassignedProjectCampaigns,
  openArtistModal,
  resetProject,
  searchArtist,
  searchPlaylists,
  selectArtist,
  selectPlaylist,
  setFunnelGraphFilter,
  shareProject,
  showConfirmModal,
  showDeleteModal,
  showViewAllTargetsModal,
  toggleShareWindow,
  updateSelectedArtists,
} from './actions';
import { isArtistLocked } from './transducers';
import { ArtistModal, ConfirmModal, FunnelChartState } from './types';
import { LoadableData, LoadingState } from 'common/types';
import { ALL_OPTION_ID } from 'common/components/form/select/constants';
import { getProjectArtistTeam } from 'common/actions';
export interface ProjectState {
  activeProject?: ProjectDetails;
  confirmModal: ConfirmModal;
  artistModal: ArtistModal;
  error?: ApiError<ProjectDetailsErrorResponse>;
  isShareWindowOpened: boolean;
  unassignedUsers: User[];
  assignedUsers: User[];
  team: TeamUser[];
  projectUsers: ProjectUser[];
  isShareLoading: boolean;
  shareError?: string;
  isProcessing: boolean;
  isLoading: boolean;
  projectHistory: LoadableData<HistoryEvent[]>;
  projectMarketingMix: LoadableData<MarketingMix[]>;
  teamCounters: LoadableData<Optional<TeamCountersResponse>>;
  notesHistory: LoadableData<HistoryEvent[]>;
  isDeleteModalShown: boolean;
  isShowAllTargetsModalShown: boolean;
  phases: LoadableData<Phase[]>;
  budgetGroups: LoadableData<BudgetGroup[]>;
  funnelGraph: FunnelChartState;
  unassignedProjectCampaigns: Campaign[];
  artistTeam: LoadableData<Optional<ArtistTeam>>;
}

export const initialState: ProjectState = {
  confirmModal: {
    id: undefined,
    isOpened: false,
  },
  isShareWindowOpened: false,
  artistModal: {
    artists: [],
    playlists: [],
    selectedArtists: [],
    selectedPlaylists: [],
    isOpened: false,
    isChanged: false,
    isLoading: false,
  },
  unassignedUsers: [],
  assignedUsers: [],
  isProcessing: false,
  isShareLoading: false,
  isLoading: true,
  projectHistory: { loading: LoadingState.Idle, data: [] },
  team: [],
  projectUsers: [],
  projectMarketingMix: { loading: LoadingState.Idle, data: [] },
  isDeleteModalShown: false,
  isShowAllTargetsModalShown: false,
  phases: {
    loading: LoadingState.Idle,
    data: [],
  },
  budgetGroups: { loading: LoadingState.Idle, data: [] },
  funnelGraph: {
    loading: LoadingState.Idle,
    data: undefined,
    filter: ALL_OPTION_ID,
  },
  unassignedProjectCampaigns: [],
  artistTeam: { loading: LoadingState.Idle, data: undefined },
  teamCounters: { loading: LoadingState.Idle, data: undefined },
  notesHistory: { loading: LoadingState.Idle, data: [] },
};

export const reducer = createTypedReducer<ProjectState>(
  initialState,
  onAction(getProject.request, state => ({
    ...state,
  })),
  onAction(getProject.failure, (state, action) => ({
    ...state,
    error: action.payload,
  })),
  onAction(getProject.success, (state, action) => ({
    ...state,
    activeProject: action.payload,
    isLoading: false,
  })),
  onAction(getBudgetGroupsForProjectAction.request, state => ({
    ...state,
    budgetGroups: { loading: LoadingState.Started, data: [] },
  })),
  onAction(getBudgetGroupsForProjectAction.failure, (state, action) => ({
    ...state,
    error: action.payload,
    budgetGroups: { loading: LoadingState.Failed, data: [] },
  })),
  onAction(getBudgetGroupsForProjectAction.success, (state, action) => ({
    ...state,
    budgetGroups: { loading: LoadingState.Finished, data: action.payload },
  })),
  onAction(deleteProject.request, state => processActiveProject(state)),
  onAction(deleteProject.failure, state => finishProcessingProject(state)),
  onAction(deleteProject.success, state => finishProcessingProject(state)),
  onAction(editProject.request, state => processActiveProject(state)),
  onAction(editProject.failure, state => finishProcessingProject(state)),
  onAction(editProject.success, state => finishProcessingProject(state)),
  onAction(claimUnassignedProject.request, state => processActiveProject(state)),
  onAction(claimUnassignedProject.failure, state => finishProcessingProject(state)),
  onAction(claimUnassignedProject.success, state => finishProcessingProject(state)),
  onAction(showConfirmModal, (state, action) => ({
    ...state,
    confirmModal: action.payload,
  })),
  onAction(resetProject, () => initialState),
  onAction(getProjectTeamUsersInfo.request, state => ({
    ...state,
    isShareLoading: true,
  })),
  onAction(getProjectTeamUsersInfo.success, (state, action) => ({
    ...state,
    team: action.payload.teamUsers,
    projectUsers: action.payload.projectUsers,
    isShareLoading: false,
  })),
  onAction(getProjectTeamUsersInfo.failure, state => ({
    ...state,
    isShareLoading: false,
  })),
  onAction(toggleShareWindow, state => ({
    ...state,
    isShareWindowOpened: !state.isShareWindowOpened,
  })),
  onAction(closeShareWindow, state => ({
    ...state,
    isShareWindowOpened: false,
  })),
  onAction(shareProject.request, state => ({
    ...state,
    isShareLoading: true,
  })),
  onAction(shareProject.success, (state, action) => {
    return {
      ...state,
      isShareLoading: false,
      team: action.payload,
    };
  }),
  onAction(shareProject.failure, (state, action) => ({
    ...state,
    shareError: action.payload,
    isShareLoading: false,
  })),

  onAction(getProjectHistoryAction.request, state => ({
    ...state,
    projectHistory: { loading: LoadingState.Started, data: [] },
  })),

  onAction(getProjectHistoryAction.success, (state, action) => ({
    ...state,
    projectHistory: { loading: LoadingState.Finished, data: action.payload },
  })),

  onAction(getProjectHistoryAction.failure, state => ({
    ...state,
    projectHistory: { loading: LoadingState.Failed, data: [] },
  })),

  onAction(getTeamCountersAction.request, state => ({
    ...state,
    teamCounters: { loading: LoadingState.Started, data: undefined },
  })),

  onAction(getTeamCountersAction.success, (state, action) => ({
    ...state,
    teamCounters: { loading: LoadingState.Finished, data: action.payload },
  })),

  onAction(getTeamCountersAction.failure, state => ({
    ...state,
    teamCounters: { loading: LoadingState.Failed, data: undefined },
  })),

  onAction(getProjectMarketingMixAction.request, state => ({
    ...state,
    projectMarketingMix: { loading: LoadingState.Started, data: [] },
  })),

  onAction(getProjectMarketingMixAction.success, (state, action) => ({
    ...state,
    projectMarketingMix: { loading: LoadingState.Finished, data: action.payload },
  })),

  onAction(getProjectMarketingMixAction.failure, state => ({
    ...state,
    projectMarketingMix: { loading: LoadingState.Failed, data: [] },
  })),

  onAction(getNotesHistoryAction.request, state => ({
    ...state,
    notesHistory: { loading: LoadingState.Started, data: [] },
  })),

  onAction(getNotesHistoryAction.success, (state, action) => ({
    ...state,
    notesHistory: { loading: LoadingState.Finished, data: action.payload },
  })),

  onAction(getNotesHistoryAction.failure, state => ({
    ...state,
    notesHistory: { loading: LoadingState.Failed, data: [] },
  })),

  onAction(getProjectArtistTeam.request, state => ({
    ...state,
    artistTeam: { loading: LoadingState.Started, data: undefined },
  })),

  onAction(getProjectArtistTeam.success, (state, action) => ({
    ...state,
    artistTeam: { loading: LoadingState.Finished, data: action.payload },
  })),

  onAction(getProjectArtistTeam.failure, state => ({
    ...state,
    artistTeam: { loading: LoadingState.Failed, data: undefined },
  })),

  onAction(openArtistModal, (state, action) => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      isOpened: true,
      selectedPlaylists: action.payload.selectedPlaylists || [],
      selectedArtists: action.payload.selectedArtists || [],
    },
  })),
  onAction(closeArtistModal, state => ({
    ...state,
    artistModal: initialState.artistModal,
  })),
  onAction(searchArtist.request, state => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      artists: [],
      isLoading: true,
    },
  })),
  onAction(searchArtist.success, (state, action) => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      artists: action.payload,
      isLoading: false,
    },
  })),
  onAction(searchArtist.failure, (state, action) => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      error: action.payload.message,
      isLoading: false,
    },
  })),
  onAction(searchPlaylists.request, state => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      playlists: [],
      isLoading: true,
    },
  })),
  onAction(searchPlaylists.success, (state, action) => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      playlists: action.payload,
      isLoading: false,
    },
  })),
  onAction(searchPlaylists.failure, (state, action) => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      error: action.payload.message,
      isLoading: false,
    },
  })),
  onAction(selectArtist, (state, action) => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      selectedArtists: state.artistModal.selectedArtists.concat(action.payload),
      isChanged: true,
    },
  })),
  onAction(selectPlaylist, (state, action) => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      selectedPlaylists: state.artistModal.selectedPlaylists.concat(action.payload),
      isChanged: true,
    },
  })),
  onAction(deselectArtist, (state, action) => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      selectedArtists: state.artistModal.selectedArtists.filter(artist => artist.id !== action.payload),
      isChanged: true,
    },
  })),
  onAction(deselectPlaylist, (state, action) => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      selectedPlaylists: state.artistModal.selectedPlaylists.filter(playlist => playlist.id !== action.payload.id),
      isChanged: true,
    },
  })),
  onAction(clearSelectionArtistModal, state => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      selectedPlaylists: [],
      selectedArtists: state.artistModal.selectedArtists.filter(artist => isArtistLocked(artist)),
      isChanged: true,
    },
  })),
  onAction(showDeleteModal, (state, action) => ({
    ...state,
    isDeleteModalShown: action.payload,
  })),
  onAction(showViewAllTargetsModal, (state, action) => ({
    ...state,
    isShowAllTargetsModalShown: action.payload,
  })),
  onAction(updateSelectedArtists, (state, action) => ({
    ...state,
    artistModal: {
      ...state.artistModal,
      selectedArtists: action.payload,
      isChanged: true,
    },
  })),
  onAction(getFunnelGraphData.request, state => ({
    ...state,
    funnelGraph: {
      ...state.funnelGraph,
      loading: LoadingState.Started,
      data: undefined,
    },
  })),
  onAction(getFunnelGraphData.success, (state, { payload }) => ({
    ...state,
    funnelGraph: {
      ...state.funnelGraph,
      loading: LoadingState.Finished,
      data: payload,
    },
  })),
  onAction(getFunnelGraphData.failure, state => ({
    ...state,
    funnelGraph: {
      ...state.funnelGraph,
      loading: LoadingState.Failed,
      data: undefined,
    },
  })),
  onAction(setFunnelGraphFilter, (state, { payload }) => ({
    ...state,
    funnelGraph: {
      ...state.funnelGraph,
      filter: payload,
    },
  })),
  onAction(getUnassignedProjectCampaigns.success, (state, action) => ({
    ...state,
    unassignedProjectCampaigns: action.payload,
  }))
);

function processActiveProject(state) {
  return {
    ...state,
    isProcessing: true,
  };
}

function finishProcessingProject(state) {
  return {
    ...state,
    isProcessing: false,
  };
}

//†reducer
