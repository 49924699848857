import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { LiveArtistCardStates } from 'common/types';
import { theme } from 'app/theme';
import { motion } from 'framer-motion';
import { transparentize } from 'polished';

export const BEM_CLASS = 'artist-card';

interface SArtistCardProps {
  width?: number;
  height?: number;
  backgroundImage?: string;
  cardState: LiveArtistCardStates;
  expanded: boolean;
}

export const SLiveArtistCard = styled(motion.div)<SArtistCardProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-shadow: ${theme.shadows.default};
  border-radius: 10px;
  cursor: pointer;
  aspect-ratio: 1;
  z-index: 3;
  overflow: hidden;
  user-select: none;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ backgroundImage }) =>
      `url(${backgroundImage}), linear-gradient(180deg, ${transparentize(0.4, colorPalette.darkBlue)} 0%,  ${
        colorPalette.darkBlue
      } 100%)`};
    background-blend-mode: multiply;
    background-size: cover;
    background-repeat: no-repeat;
    object-fit: cover;
    opacity: ${({ cardState }) => (cardState === LiveArtistCardStates.INACTIVE ? 0.4 : 1)};
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -2;
    border-radius: 10px;
  }

  &:hover {
    .${BEM_CLASS}__expand-button {
      opacity: 1;
      visibility: visible;
    }
  }

  .${BEM_CLASS} {
    &__info-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0 20px 12px;
      width: 100%;
    }

    &__artist-name {
      color: ${colorPalette.white};
      font-weight: 700;
    }

    &__projects-count {
      color: ${colorPalette.white};
      font-size: 12px;
    }

    &__badge {
      margin-bottom: 7px;
      &::before {
        content: '';
        width: 5px;
        height: 5px;
        margin: 0 6px 0 2px;
        background-color: ${colorPalette.white};
        border-radius: 50%;
      }
    }

    &__expand-button {
      opacity: 0;
      visibility: hidden;
      background-color: ${colorPalette.white};
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 35px;
      border-radius: 20px;
      margin: 15px;
      transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

      &--active {
        opacity: 1;
        visibility: visible;
      }
    }

    &__expand-icon {
      transform: ${({ expanded }) => expanded && 'rotate(0.5turn)'};
      transition: transform 0.2s ease-out;
    }
  }
`;
