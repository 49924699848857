import { applyMiddleware, combineReducers, compose, createStore, Middleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import persistStore from 'redux-persist/lib/persistStore';
import LogRocket from 'logrocket';
import { rootReducer } from './reducers';
import { rootEpic } from './epics';
import { trackUserMiddleware } from 'utils/analytic';

declare const module;

export function configureStore() {
  const epicMiddleware = createEpicMiddleware();
  const middlewares: Middleware[] = [epicMiddleware];

  if (process.env.LOGROCKET_KEY) {
    middlewares.push(LogRocket.reduxMiddleware());
  }

  if (process.env.AMPLITUDE_KEY) {
    middlewares.push(trackUserMiddleware);
  }

  const devTools = (global as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const composeEnhancers = devTools || compose;

  const store = createStore(combineReducers(rootReducer), undefined, composeEnhancers(applyMiddleware(...middlewares)));
  const persistor = persistStore(store);

  epicMiddleware.run(rootEpic as any);

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      const reloadReducers = () => {
        // eslint-disable-next-line @typescript-eslint/no-require-imports
        const nextReducer = require('./reducers');
        store.replaceReducer(nextReducer);
      };

      module.hot.accept('./reducers', reloadReducers);
    }
  }

  return { store, persistor };
}
