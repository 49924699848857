import { SyntheticEvent } from 'react';

export const callbackWithoutPropagation = <T>(callback?: (event: SyntheticEvent<T>) => void) => (
  event: SyntheticEvent<T>
) => {
  event.stopPropagation();

  if (callback) {
    callback(event);
  }
};
