import React, { useCallback } from 'react';
import { Button, BUTTON_TYPE, THEME } from 'gdb-web-shared-components';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { bem } from 'utils/bem';
import { SEditModeControls, BEM_CLASS } from './s-edit-mode-controls';

interface EditModeControlsProps {
  isLoading: boolean;
  onCancel(): void;
}

const classes = bem(BEM_CLASS);

export const EditModeControls = React.memo(({ isLoading, onCancel }: EditModeControlsProps) => {
  const { errors, handleSubmit, handleReset } = useFormikContext();

  const onSaveClick = useCallback(() => {
    if (!isEmpty(errors)) return;

    handleSubmit();
  }, [errors, handleSubmit]);

  const onCancelClick = useCallback(() => {
    onCancel();
    handleReset();
  }, [handleReset, onCancel]);

  return (
    <SEditModeControls>
      <Button
        type={BUTTON_TYPE.tertiary}
        caption="Cancel"
        theme={THEME.light}
        onClick={onCancelClick}
        disabled={isLoading}
        data-selector="edit-mode-controls-cancel-button"
      />

      <Button
        theme={THEME.light}
        type={BUTTON_TYPE.primary}
        caption="Save"
        className={classes('save-button')}
        onClick={onSaveClick}
        isLoading={isLoading}
        data-selector="edit-mode-controls-save-button"
      />
    </SEditModeControls>
  );
});
