import styled from 'styled-components';

const buttonMargin = '20px';

interface SModalFooterProps {
  readonly reverse: boolean;
}

export const SModalFooter = styled.div<SModalFooterProps>`
  display: flex;
  justify-content: center;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'unset')};
  padding: 16px 20px 30px;

  .footer {
    &__button {
      &:last-child {
        margin-left: ${({ reverse }) => (reverse ? '0' : buttonMargin)};
        margin-right: ${({ reverse }) => (reverse ? buttonMargin : '0')};
      }
    }
  }
`;
