import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const STitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  padding-right: 20px;
  flex-grow: 1;
  .title {
    &__title-text {
      ${elipsizeText};
    }
    &__completion {
      height: 16px;
      width: 16px;
      min-width: 16px;
      border-radius: 8px;
      margin-left: 13px;
    }
    &__unassigned-badge {
      flex-shrink: 0;
      height: 23px;
      margin-left: 10px;
    }
  }
`;
