import styled from 'styled-components';
import { manhattanBackground } from 'common-v2/styles';

export const BEM_CLASS = 'error-view-container';

export const SErrorViewContainer = styled.div`
  .${BEM_CLASS} {
    &__manhattan-background {
      ${manhattanBackground};
    }
  }
`;
