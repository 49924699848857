import styled from 'styled-components';

export const BEM_CLASS = 'confidential-no-permission';

export const SConfidentialNoPermission = styled.div`
  .${BEM_CLASS} {
    &__team-member-select {
      width: 350px;
    }

    &__email-button {
      margin-top: 20px;
    }

    &__button-plug {
      height: 55px;
    }
  }
`;
