import React, { useCallback, useMemo } from 'react';
import { SelectedSection, SectionConfig } from 'common/components/selected-section';
import { SelectedCampaign } from './components';
import { SSelectionSidebar } from './s-sidebar';
import { Campaign } from 'backend-api/models';

interface Props {
  selectedCampaigns: Campaign[];
  onResetAllSelectedCampaigns(): void;
}

export const SelectionSidebar = React.memo(({ selectedCampaigns, onResetAllSelectedCampaigns }: Props) => {
  const renderCampaignItem = useCallback((campaign: Campaign) => <SelectedCampaign campaign={campaign} />, []);

  const sectionsConfig: [SectionConfig<Campaign>] = useMemo(
    () => [
      {
        id: 'selected-campaigns',
        selectedItems: selectedCampaigns,
        renderItem: renderCampaignItem,
        withCounter: true,
        placeholder: 'No selected campaigns.',
      },
    ],
    [selectedCampaigns, renderCampaignItem]
  );

  return (
    <SSelectionSidebar>
      <SelectedSection
        title={`Selected Campaigns (${selectedCampaigns.length})`}
        placeholder={'No selected campaigns.'}
        onResetClick={onResetAllSelectedCampaigns}
        sectionsConfig={sectionsConfig}
      />
    </SSelectionSidebar>
  );
});
