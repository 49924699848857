import {
  Id,
  LabelPermissionsCountersSchema,
  LabelPermissionsMemberSchema,
  ModifyArtistsListSchema,
  ShortArtistSchema,
  LabelPermissionsArtistWithMembersSchema,
  ModifyMembersListSchema,
} from '../models';
import { apiClient, EmptyPayload, EmptySchema, ResponseArray } from '../index';

export const getLabelPermissionsCounters = (labelId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/labels/${labelId}/permissions/counters`,
    responseSchema: LabelPermissionsCountersSchema,
  });

export const getLabelPermissionsTeamMembers = (labelId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/labels/${labelId}/permissions/team-members`,
    responseSchema: ResponseArray(LabelPermissionsMemberSchema),
  });

export const getLabelArtists = (labelId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/labels/${labelId}/artists`,
    responseSchema: ResponseArray(ShortArtistSchema),
  });

export const addUserToLabelArtists = (labelId: Id, userId: Id, artistsIds: string[]) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/labels/${labelId}/permissions/users/${userId}/artist-teams`,
    responseSchema: EmptySchema,
    requestSchema: ModifyArtistsListSchema,
    payload: { artistId: artistsIds },
  });

export const removeUserFromLabelArtists = (labelId: Id, userId: Id, artistsIds: string[]) =>
  apiClient.executeRequest({
    method: 'DELETE',
    endpoint: `/labels/${labelId}/permissions/users/${userId}/artist-teams`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
    queryParams: { artistId: artistsIds },
  });

export const getLabelPermissionsArtists = (labelId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/labels/${labelId}/permissions/artists`,
    responseSchema: ResponseArray(LabelPermissionsArtistWithMembersSchema),
  });

export const addMembersToArtist = (labelId: Id, artistId: string, userIds: Id[]) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/labels/${labelId}/permissions/artists/${artistId}/artist-teams`,
    responseSchema: EmptySchema,
    requestSchema: ModifyMembersListSchema,
    payload: { userId: userIds },
  });

export const removeMembersFromArtist = (labelId: Id, artistId: string, userIds: Id[]) =>
  apiClient.executeRequest({
    method: 'DELETE',
    endpoint: `/labels/${labelId}/permissions/artists/${artistId}/artist-teams`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
    queryParams: { userId: userIds },
  });
