import { Id, ProjectDetails } from 'backend-api/models';
import { Loading } from 'common-v2/types';

export enum MomentPaths {
  addMoment = '/projects/:id/moments/add',
  editMoment = '/projects/:id/moments/:momentId/edit',
  redirectMomentDetails = '/projects/:id/moments/:momentId',
  momentDetails = '/projects/:id/moments/:momentId/details',
}

export enum ProjectReportingPaths {
  projectReporting = '/projects/:id/phases/reporting',
  projectPhaseReporting = '/projects/:id/phases/:phaseId/reporting',
}

export enum ArtistPaths {
  projects = '/artists/:id',
}

export enum PurchaseOrdersPaths {
  purchaseOrders = '/projects/:id/purchase-orders',
  unassignedPurchaseOrders = '/unassigned-projects/:id/purchase-orders',
}

export enum SettingsPaths {
  settings = '/settings/',
}

export enum MediaPlanPaths {
  deprecatedRoute = '/projects/:id/media-plan',
  generalMediaPlan = '/projects/:id/media-plans',
  specificMediaPlan = '/projects/:id/media-plans/:mediaPlanId',
}

export enum ReportingPath {
  reporting = '/projects/:id/reporting',
}

export enum ErrorPath {
  notFound = '/not-found',
}

export enum LabelPermissionsPaths {
  labelPermissions = '/label-permissions',
}

export enum LoginPaths {
  login = '/login',
}

export enum ProjectsPaths {
  projects = '/projects',
  project = '/projects/:id',
}

export enum ProjectPath {
  mediaPlans = '/media-plans',
  mediaPlan = '/media-plans/:mediaPlanId',
  reporting = '/reporting',
  finance = '/purchase-orders',
}

export interface ProjectRoutingProps {
  projectId: Id;
  projectLoading: Loading;
  projectDetails?: ProjectDetails;
}
