import { ApiError } from 'backend-api';
import { useEffect, useState } from 'react';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { replaceTo } from 'utils/navigation';
import { PayloadActionCreator } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { Optional } from 'backend-api/models';
import { useProjectAccessRestricted } from 'common/hooks/use-project-access-restricted';
import { OutputSelector } from 'reselect';
import { ErrorPath } from 'app/routing/types';

export const useHandleGlobalError = (
  errorSelector: OutputSelector<
    any,
    Optional<ApiError<ProjectDetailsErrorResponse>>,
    (state: any) => Optional<ApiError<ProjectDetailsErrorResponse>>
  >,
  resetErrorAction: PayloadActionCreator<string, void>
) => {
  const [serverError, setServerError] = useState(false);
  const [globalError, setGlobalError] = useState(false);
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);

  const isRestrictedAccessHandled = useProjectAccessRestricted(errorSelector);

  useEffect(() => {
    setServerError(false);
    setGlobalError(false);

    if (!error) {
      return;
    }

    const { statusCode } = error;

    if (isRestrictedAccessHandled) return;

    if (statusCode === 404) {
      replaceTo(ErrorPath.notFound);
    } else if (statusCode && statusCode >= 500) {
      setServerError(true);
    } else if (!!error) {
      setGlobalError(true);
    }

    return () => {
      dispatch(resetErrorAction());
    };
  }, [dispatch, error, isRestrictedAccessHandled, resetErrorAction]);

  return { serverError, globalError };
};
