import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { PADDING } from '../../constants';

export const BEM_CLASS = 'indicator-container';

export const SIndicatorContainer = styled.div`
  flex-shrink: 0;
  padding: 0 ${PADDING}px;
  align-self: stretch;
  color: ${manhattanThemeColors.primaryDark};

  .${BEM_CLASS} {
    &__icon {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      &--isUp {
        transform: rotate(0.5turn);
      }
    }
  }
`;
