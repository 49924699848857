import styled from 'styled-components';
import { Tabs } from 'common/components/tabs';

export const BEM_CLASS = 'tabs';

export const SSearchTabs = styled(Tabs)`
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabs__top-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .${BEM_CLASS} {
    &__tab {
      flex: 1;
      overflow: hidden;
    }
  }
`;
