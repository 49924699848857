import { DATE_SINGLE_INPUT_FORMAT } from 'common/constants';
import { dateFormatSelector } from 'common/selectors';
import React, { SyntheticEvent, useCallback, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { bem } from 'utils/bem';
import { BEM_CLASS, SDateInput } from './s-date-input';
import { Nullable } from 'backend-api/models';
import { InputMaskExtended } from '../../types';
import { isValidDate, getFormattedDate, parseDateByFormat } from 'common-v2/utils';

interface DateInputProps {
  value: string;
  onChange(value: Nullable<string>): void;
  onKeyDown?(event: React.KeyboardEvent<HTMLElement>): void;
  onBlur?(): void;
}

const classes = bem(BEM_CLASS);

export const DateInput = React.memo(({ value, onChange, onBlur, onKeyDown }: DateInputProps) => {
  const dateFormat = useSelector(dateFormatSelector);
  const inputRef = useRef<InputMaskExtended>(null);
  const [inputValue, setInputValue] = useState(value);

  const handleSaveDate = useCallback(
    (value: string) => {
      if (value === dateFormat) {
        onChange(null);
        return;
      }

      const date = parseDateByFormat(value, dateFormat);
      if (isValidDate(date)) {
        onChange(value);
      }
    },
    [dateFormat, onChange]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(event);
      if (event.key !== 'Enter') return;
      inputRef.current?.blur();
    },
    [onKeyDown]
  );

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<Element>) => {
      event.preventDefault();
      try {
        const value = JSON.parse(event.clipboardData.getData('Text')).value;
        const dateFormatted = getFormattedDate(dateFormat)(value);
        setInputValue(dateFormatted);
        handleSaveDate(dateFormatted);
      } catch {
        //do nothing if clipboard was not parsed
      }
    },
    [dateFormat, handleSaveDate]
  );

  const handleChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      setInputValue(value);
      handleSaveDate(value);
    },
    [handleSaveDate]
  );

  useEffect(() => inputRef?.current?.focus(), []);

  return (
    <SDateInput>
      <InputMask
        mask={DATE_SINGLE_INPUT_FORMAT}
        className={classes('input')}
        maskPlaceholder={dateFormat}
        alwaysShowMask={false}
        onChange={handleChange}
        value={inputValue}
        disabled={false}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        onPaste={handlePaste}
        ref={inputRef}
      />
    </SDateInput>
  );
});
