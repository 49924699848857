import { getType, PayloadActionCreator, TypeConstant } from 'typesafe-actions';
import { PayloadAction } from 'typesafe-actions/dist/type-helpers';
import { EMPTY, Observable, of } from 'rxjs';
import { ofType as ofReduxType, StateObservable } from 'redux-observable';
import { catchError, flatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { showErrorToast } from 'common/components/toast';

type TC = TypeConstant;

interface AsyncAction<
  TT1 extends TC,
  PT1 extends unknown,
  TT2 extends TC,
  PT2 extends unknown,
  TT3 extends TC,
  PT3 extends unknown
> {
  readonly request: PayloadActionCreator<TT1, PT1>;
  readonly success: PayloadActionCreator<TT2, PT2>;
  readonly failure: PayloadActionCreator<TT3, PT3>;
}

type ActonExecutor<TType extends TypeConstant, T extends unknown, R extends unknown> = (
  action: PayloadAction<TType, T>,
  state?: any
) => Observable<R>;

type Epic<R> = (action$: Observable<any>, state$: StateObservable<any>) => Observable<R>;

interface EpicOptions {
  readonly showError?: boolean;
  readonly cancelable?: boolean;
}

const defaultEpicOptions: EpicOptions = {
  showError: true,
  cancelable: true,
};

export const ofType = <TT extends TC, PT>(
  actionCreator: PayloadActionCreator<TT, PT>
): ((source: Observable<any>) => Observable<PayloadAction<TT, PT>>) => {
  return ofReduxType(getType(actionCreator));
};
export const asyncEpic = <
  TT1 extends TC,
  PT1 extends unknown,
  TT2 extends TC,
  PT2 extends unknown,
  TT3 extends TC,
  PT3 extends unknown
>(
  asyncAction: AsyncAction<TT1, PT1, TT2, PT2, TT3, PT3>,
  executor: ActonExecutor<TT1, PT1, PT2>,
  onError?: (error: PT3, payload: PT1) => Observable<PayloadAction<TT3, PT3>>,
  options?: EpicOptions,
  cancelAction?: PayloadActionCreator<string, any>
): Epic<PayloadAction<TT2, PT2> | PayloadAction<TT3, PT3>> => {
  const epicOptions = { ...defaultEpicOptions, ...options };

  const mapWithHandleCancelable = epicOptions.cancelable ? switchMap : flatMap;

  return (action$, state$) => {
    return action$.pipe(
      ofType(asyncAction.request),
      mapWithHandleCancelable(action => {
        return executor(action, state$.value).pipe(
          map(result => asyncAction.success(result)),
          takeUntil(cancelAction ? action$.pipe(ofType(cancelAction)) : EMPTY),
          catchError(
            onError
              ? error => onError(error, action.payload)
              : error => {
                  if (epicOptions.showError && !error.isCancelled) showErrorToast(error);
                  return of(asyncAction.failure(error));
                }
          )
        );
      })
    );
  };
};
