import { colorPalette, themeColors } from './colors';

export const inputTheme = {
  background: {
    disabled: {
      light: colorPalette.whisper,
      grey: colorPalette.whisper,
    },
    light: colorPalette.white,
    grey: colorPalette.ghostWhite,
  },
  border: {
    error: themeColors.error,
    required: colorPalette.lavenderGray,
    focus: colorPalette.azureRadiance,
  },
  placeholder: {
    regular: colorPalette.wildBlueYonder,
    disabled: colorPalette.blueHaze,
  },
  text: {
    regular: colorPalette.vulcan,
    disabled: colorPalette.osloGray,
  },
  icon: {
    regular: colorPalette.lavenderGray,
    focused: colorPalette.azureRadiance,
  },
};
