import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { BaseRenderer, SingleValueView } from 'media-plan-v2/components/spreadsheet/components';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';

export const HeadlineRenderer = ({
  context,
  value: { color, value },
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['headline']>) => (
  <BaseRenderer color={color} accessMode={context.accessMode} placeholder="Write here...">
    {value && <SingleValueView value={value} />}
  </BaseRenderer>
);
