import styled from 'styled-components';
import { Card } from 'common-v2/components';

export const BEM_CLASS = 'project-card-loader';

export const SProjectCardLoader = styled(Card)`
  .${BEM_CLASS} {
    &__title-loader,
    &__subtitle-loader,
    &__label-loader {
      max-width: 100%;
    }

    &__title-loader {
      margin-bottom: 10px;
    }

    &__subtitle-loader {
      margin-bottom: auto;
    }
  }
`;
