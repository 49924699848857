import { Optional } from 'backend-api/models';
import { isIconName } from 'common/components/icon/guards';
import { getFormattedDate, parseDateFromISOString, getPeriodFormatted } from 'common-v2/utils';

export const getCampaignIcon = (platforms?: string[]): Optional<string> => {
  if (!platforms || platforms.length === 0) return;

  const platform = platforms[0].toLowerCase();

  if (platform === 'other' || !isIconName(platform)) return 'unknown';

  return platform;
};

export const formatDatePeriodForCampaignCard = (format: string, startDate?: string, endDate?: string) => {
  const parsedStartDate = parseDateFromISOString(startDate);

  if (endDate === undefined) {
    return `Started ${getFormattedDate(format)(parsedStartDate)}`;
  }

  const parsedEndDate = parseDateFromISOString(endDate);

  return getPeriodFormatted(format)([parsedStartDate, parsedEndDate]);
};
