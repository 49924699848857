import styled from 'styled-components';

export const BEM_CLASS = 'recent-search-result-container';

export const SRecentSearches = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .${BEM_CLASS} {
    &__loader {
      padding: 20px;
    }

    &__title {
      flex-shrink: 0;
      padding: 20px 20px 0;
    }

    &__results {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 14px 20px;
      margin-bottom: 20px;
      overflow-x: hidden;
      overflow-y: auto;
    }

    &__placeholder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      flex-grow: 1;
    }

    &__placeholder-text {
      text-align: center;
      margin-bottom: 34px;
    }
  }
`;
