import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'unclaimed-project-view';

export const SUnclaimedProjectView = styled.div`
  height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${transparentize(0.5, manhattanThemeColors.background)};
  border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
  box-shadow: 0px 5px 20px ${transparentize(0.95, manhattanThemeColors.primaryDark)};
  border-radius: 10px;

  .${BEM_CLASS} {
    &__title {
      margin-bottom: 5px;
    }

    &__description {
      text-align: center;
    }

    &__image {
      margin-top: 45px;
    }
  }
`;
