import { createSelector } from 'reselect';
import { MomentState } from './reducer';

export const momentStateSelector = state => state.moment as MomentState;

export const activeMomentSelector = createSelector(momentStateSelector, state => state.activeMoment);

export const projectSelector = createSelector(momentStateSelector, state => state.project);

export const errorSelector = createSelector(momentStateSelector, state => state.error);

export const isLoadingSelector = createSelector(momentStateSelector, state => state.isLoading);

export const isConfirmSelector = createSelector(momentStateSelector, state => state.confirmModal?.isOpened);

export const processingSelector = createSelector(momentStateSelector, state => state.isProcessing);

//†selector
