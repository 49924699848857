import { Icon, ICON_SIZE, RadioButton, RadioButtonGroup, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React, { useCallback, useEffect, useState } from 'react';
import { DATE_FORMAT_OPTIONS } from 'settings-v2/constants';
import { bem } from 'utils/bem';
import { SDatePreferences, BEM_CLASS } from './s-date-preferences';

interface DatePreferencesProps {
  dateFormat: string;
  onChangeDateFormat(format: string): void;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const DatePreferences = React.memo(({ dateFormat, onChangeDateFormat, className }: DatePreferencesProps) => {
  const [selectedFormat, setSelectedFormat] = useState(dateFormat);

  useEffect(() => {
    setSelectedFormat(dateFormat);
  }, [dateFormat]);

  const onChange = useCallback(
    (format: string) => {
      setSelectedFormat(format);
      onChangeDateFormat(format);
    },
    [onChangeDateFormat]
  );

  return (
    <SDatePreferences className={className}>
      <div className={classes('header')}>
        <Icon className={classes('icon')} name="preferences" size={ICON_SIZE.medium} />
        <Typography type={TYPOGRAPHY_TYPE.heading2}>Preferences</Typography>
      </div>
      <div className={classes('container')}>
        <Typography className={classes('title')} type={TYPOGRAPHY_TYPE.heading2}>
          Date & Time Preferences
        </Typography>
        <RadioButtonGroup value={selectedFormat} onChange={onChange} className={classes('radio-group')}>
          {DATE_FORMAT_OPTIONS.map(option => (
            <RadioButton
              key={option.id}
              value={option.format}
              label={<Typography type={TYPOGRAPHY_TYPE.body2}>{option.name}</Typography>}
              radioButtonClassName={classes('radio-item')}
            />
          ))}
        </RadioButtonGroup>
      </div>
    </SDatePreferences>
  );
});
