import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { theme } from 'app/theme';
import { ellipsizeText } from 'common-v2/styles';
import { AVATAR_SIZE, COLUMN_GAP, COLUMN_SIZE, MEMBER_HEIGHT, MEMBER_WIDTH, ROW_GAP } from './constants';

interface SRequestersProps {
  hiddenUsersCount: number;
}

export const BEM_CLASS = 'requesters';

export const SRequesters = styled(({ className, children }) => children(className))<SRequestersProps>`
  .${BEM_CLASS} {
    &__container {
      display: flex;
      align-items: center;
      gap: 5px;
    }

    &__label {
      margin-right: 10px;
      color: ${manhattanThemeColors.grayedText};
    }

    &__rest {
      width: ${AVATAR_SIZE}px;
      height: ${AVATAR_SIZE}px;
      line-height: ${AVATAR_SIZE}px;
      text-align: center;
      background-color: ${transparentize(0.95, manhattanThemeColors.primaryFillNew)};
      color: ${transparentize(0.2, manhattanThemeColors.primaryFillNew)};
      border-radius: 50%;
    }

    &__users {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: ${ROW_GAP}px ${COLUMN_GAP}px;
      width: ${({ hiddenUsersCount }) => (MEMBER_WIDTH + COLUMN_GAP) * Math.ceil(hiddenUsersCount / COLUMN_SIZE)}px;
      max-height: ${(MEMBER_HEIGHT + ROW_GAP) * COLUMN_SIZE}px;
      font-family: ${theme.fontFamily};
    }

    &__user {
      ${ellipsizeText};

      width: ${MEMBER_WIDTH}px;
      height: ${MEMBER_HEIGHT}px;
      font-size: 12px;
      line-height: ${MEMBER_HEIGHT}px;
    }

    &__tooltip-content {
      max-width: none;
      max-height: none;
    }
  }
`;
