import React, { useMemo } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import { history } from 'utils/navigation';

export interface LinkProps extends Omit<RouterLinkProps, 'to'> {
  pathname: string;
}

export const Link = React.memo(({ pathname, ...restProps }: LinkProps) => {
  const to = useMemo(
    () => ({
      pathname,
      state: {
        from: history.location.pathname,
      },
    }),
    [pathname]
  );

  return <RouterLink {...restProps} to={to} />;
});
