import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { asyncEpic, ofType } from 'core/epics';
import { Observable, of } from 'rxjs';
import { Api } from 'backend-api';
import { AnalyticsEvents, increaseUserCounter, trackEvent, UserCounters } from 'utils/analytic';
import { createMoment, deleteMoment, editMoment, getMoment, getMomentProject } from './actions';
import { prepareRequest } from './transducers';
import { navigateTo } from 'utils/navigation';
import { paths } from 'app/routing/paths';
import { showErrorToast, showSuccessToast } from 'common/components/toast';

export const getProjectEpic = asyncEpic(getMomentProject, action => Api.getProject(action.payload));

export const createMomentEpic = asyncEpic(createMoment, action => {
  const { projectId } = action.payload;
  const data = prepareRequest(action.payload);

  return Api.createMoment(projectId, data).pipe(
    tap(response => navigateTo(paths.momentDetails(projectId, response.id))),
    tap(response => showSuccessToast(`${response.name} successfully created`)),
    tap(() => increaseUserCounter(UserCounters.MOMENTS_CREATED, 1)),
    tap(response =>
      trackEvent(AnalyticsEvents.ARTIST_MOMENT_CREATED, {
        moment_category: response.category?.name,
        moment_type: response.type?.name,
        moment_source: response.createUser?.name,
      })
    )
  );
});

export const getMomentEpic = asyncEpic(getMoment, action => {
  const { projectId, momentId } = action.payload;
  return Api.getMoment(projectId, momentId);
});

export const editMomentEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(editMoment.request),
    switchMap(action => {
      const { projectId, momentId } = action.payload;
      const data = prepareRequest(action.payload);
      return Api.editMoment(projectId, momentId, data).pipe(
        tap(() => navigateTo(paths.momentDetails(projectId, momentId))),
        tap(() => showSuccessToast('Moment was successfully updated')),
        tap(() => increaseUserCounter(UserCounters.MOMENTS_EDITED, 1)),
        tap(response =>
          trackEvent(AnalyticsEvents.ARTIST_MOMENT_EDITED, {
            moment_category: response.category?.name,
            moment_type: response.type?.name,
            moment_source: response.createUser?.name,
          })
        ),
        mergeMap(() => [editMoment.success({}), getMoment.request({ projectId, momentId })]),
        catchError(error => {
          showErrorToast(error);
          return of(editMoment.failure(error));
        })
      );
    })
  );
};

export const deleteMomentEpic = asyncEpic(deleteMoment, action => {
  const { projectId, momentId } = action.payload;
  return Api.deleteMoment(projectId, momentId).pipe(
    tap(() => navigateTo(paths.projectReporting(projectId))),
    tap(() => showSuccessToast('Moment was successfully removed'))
  );
});

//†epic
