import { colorPalette } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import styled from 'styled-components';

interface SLinkContainerProps {
  isInvalid: boolean;
}

export const BEM_CLASS = 'link-container';

export const SLinkContainer = styled.div<SLinkContainerProps>`
  max-width: 100%;
  height: 20px;
  border-radius: 2px;
  background-color: ${colorPalette.white};
  box-shadow: 0 0 0 1px ${({ isInvalid }) => (isInvalid ? colorPalette.gray : colorPalette.azureRadiance)};
  margin: 5px;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;

  .${BEM_CLASS} {
    &__link-text {
      ${elipsizeText};
      flex-grow: 1;
      color: ${({ isInvalid }) => (isInvalid ? colorPalette.gray : colorPalette.azureRadiance)};
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;

      &:hover {
        color: ${({ isInvalid }) => (isInvalid ? colorPalette.gray : 'rgba(11, 125, 217, 1)')};
        text-decoration: none;
      }
    }

    &__icon {
      flex-shrink: 0;
      line-height: unset;
      margin-right: 5px;
      color: ${({ isInvalid }) => (isInvalid ? colorPalette.gray : colorPalette.azureRadiance)};
    }

    &__close {
      flex-shrink: 0;
      cursor: pointer;
      margin-left: 5px;
      color: ${({ isInvalid }) => (isInvalid ? colorPalette.gray : colorPalette.azureRadiance)};

      &:hover {
        color: ${({ isInvalid }) => (isInvalid ? colorPalette.gray : 'rgba(11, 125, 217, 1)')};
      }
    }
  }
`;
