import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import {
  MODAL_SPACING,
  MODAL_TOP_PADDING,
  TITLE_RIGHT_MARGIN_WITHOUT_SIDEBAR,
} from 'common/components/reusable-modal/constants';

interface SModalHeaderProps {
  hasSideBar: boolean;
}

export const SModalHeader = styled.div<SModalHeaderProps>`
  display: flex;
  flex-direction: column;
  padding: ${MODAL_TOP_PADDING}px ${MODAL_SPACING}px 0;

  .modal-header {
    &__title {
      margin-right: ${({ hasSideBar }) => (hasSideBar ? 0 : TITLE_RIGHT_MARGIN_WITHOUT_SIDEBAR)}px;
      flex-shrink: 0;
      ${elipsizeText};
    }
    &__search {
      margin: 12px 0 32px;
    }
  }
`;
