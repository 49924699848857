import React, { useCallback, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { bem } from 'utils/bem';
import { BaseOption as BaseOptionType } from '../../../../types';
import { SOptionsGroup, BEM_CLASS } from './s-options-group';
import { SingleOption, SingleOptionProps } from '../single-option';

interface Props<T extends BaseOptionType> extends Omit<SingleOptionProps<T>, 'onIconClick' | 'isExpanded'> {
  children: React.ReactNode;
}

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType>({ data, children, onClick, deepness, isSelected, isDisabled }: Props<T>) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const expandAnimationProps = useMemo(
    () => ({
      initial: { height: 0, opacity: 0 },
      exit: { height: 0, opacity: 0 },
      animate: { height: 'unset', opacity: 1 },
      transition: { type: 'spring', damping: 15, mass: 0.5 },
    }),
    []
  );

  const handleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const handleClick = useCallback(() => {
    onClick(data);
  }, [data, onClick]);

  return (
    <SOptionsGroup>
      <SingleOption
        data={data}
        onClick={handleClick}
        onIconClick={handleExpand}
        deepness={deepness}
        isSelected={isSelected}
        isDisabled={isDisabled}
        isExpanded={isExpanded}
      />
      <AnimatePresence exitBeforeEnter initial={false}>
        {isExpanded && (
          <motion.div {...expandAnimationProps} className={classes('options')}>
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </SOptionsGroup>
  );
};

export const OptionsGroup = React.memo(Root) as typeof Root;
