import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { BACKGROUND_COLOR, DEFAULT_HEIGHT, DEFAULT_WIDTH, FOREGROUND_COLOR, ROTATION } from './constants';
import { SSkeleton } from './s-skeleton';

interface SkeletonProps extends IContentLoaderProps {
  className?: string;
  rotation?: number;
  backgroundColor?: string;
  foregroundColor?: string;
}

export const Skeleton = React.memo(
  ({
    className,
    children,
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
    backgroundColor = BACKGROUND_COLOR,
    foregroundColor = FOREGROUND_COLOR,
    rotation = ROTATION,
    ...props
  }: SkeletonProps) => (
    <SSkeleton className={className} rotation={rotation}>
      <ContentLoader
        title=""
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        gradientRatio={0.75}
        speed={2.2}
        interval={0.3}
        {...props}
      >
        {children ?? <rect x="0" y="0" rx="0" ry="0" width={width} height={height} />}
      </ContentLoader>
    </SSkeleton>
  )
);
