import styled from 'styled-components';
import { appHeader as appHeaderZIndex } from '../../styles/z-indexes';
import { manhattanBackground } from 'common-v2/styles';

export const SAppHeaderContainer = styled.div`
  ${manhattanBackground};
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${appHeaderZIndex};
`;
