import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'artist-link';

export const SArtistLink = styled.div`
  height: 100%;

  .${BEM_CLASS} {
    &__text {
      font-weight: 700;
      color: ${colorPalette.black};

      border-bottom: 1px dotted ${transparentize(0.4, colorPalette.woodsmoke)};

      :hover {
        border-bottom: 1px solid ${transparentize(0.4, colorPalette.woodsmoke)};
      }
    }
  }
`;
