import styled from 'styled-components';
import { ellipsizeText } from 'common-v2/styles/ellipsize-text';

export const BEM_CLASS = 'cell-badge';

export const STextValue = styled.div`
  display: flex;
  overflow: hidden;

  .${BEM_CLASS} {
    &__content {
      max-width: 100%;
      padding-bottom: 0;
    }

    &__text {
      ${ellipsizeText}
      display: block;
    }
  }
`;
