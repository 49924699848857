import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';

export const BEM_CLASS = 'project-collaborators-button';

export const SProjectCollaboratorsButton = styled.div`
  .${BEM_CLASS} {
    &__container {
      display: flex;
      margin-right: 15px;
      gap: 5px;
      align-items: center;
    }

    &__button {
      border-radius: 20px;
      background-color: ${colorPalette.white};
    }

    &__icon {
      margin-right: 9px;
    }
  }
`;
