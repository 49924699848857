import React, { useCallback, useRef, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Typography, TYPOGRAPHY_TYPE, Icon, ICON_SIZE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { BEM_CLASS, SActionButton } from './s-action-button';
import { isEditMode } from '../../transducers';
import { ExtendedGridApi } from 'media-plan-v2/components/spreadsheet/types';
import { MediaPlanAccessMode } from 'media-plan-v2/containers/spreadsheet/types';
import { useClickAway } from 'react-use';

const classes = bem(BEM_CLASS);

interface Props extends ICellEditorParams {
  accessMode: MediaPlanAccessMode;
}

export const ActionButton = React.memo(({ api, node, column, value, eGridCell, context }: Props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [isHighlighted, setIsHightlighted] = useState(false);
  const editable = isEditMode(context.accessMode);
  const isFooter = node.footer;

  const openContextMenu = useCallback<React.MouseEventHandler>(
    event => {
      if (isFooter || !editable) return;
      const extendedApi = (api as unknown) as ExtendedGridApi; // NOTE: Workaround to type missing fields
      extendedApi.contextMenuFactory.showMenu?.(node, column, value, event, eGridCell);
      setIsHightlighted(true);
    },
    [api, column, eGridCell, node, value, isFooter, editable]
  );

  const onMouseDown = useCallback(() => {
    if (isFooter || !editable) {
      api.clearFocusedCell();
      api.clearRangeSelection();
      api.setFocusedCell(0, 'action');
      return;
    }
  }, [api, editable, isFooter]);

  useClickAway(buttonRef, () => {
    setIsHightlighted(false);
  });

  return (
    <SActionButton ref={buttonRef} onClick={openContextMenu} onMouseDown={onMouseDown} isHighlighted={isHighlighted}>
      {editable && !isFooter && (
        <div className={classes('action-button')} data-selector="action-button">
          <Icon name="ellipsis-vert-l" size={ICON_SIZE.medium} />
        </div>
      )}

      <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('value')}>
        {value}
      </Typography>
    </SActionButton>
  );
});
