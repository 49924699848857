import { useFilters } from 'hooks';
import { useMemo } from 'react';
import { filtersSelector } from 'settings/selectors';
import { basePaths } from 'app/routing/paths';
import { SettingsFilters } from 'settings/types';
import { updateFilters } from 'settings/actions';
import { getSettingsFiltersFromSearchQuery, getSettingsSearchQueryFromFilters } from 'settings/transducers';

export const useSettingsFilters = () => {
  const config = useMemo(
    () => ({
      action: updateFilters,
      selector: filtersSelector,
      path: basePaths.settings(),
      queryToFilters: getSettingsFiltersFromSearchQuery,
      filtersToQuery: getSettingsSearchQueryFromFilters,
    }),
    []
  );

  return useFilters<SettingsFilters, string>(config);
};
