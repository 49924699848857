import React, { useEffect, useMemo } from 'react';
import { useAuth, useAllLabels } from 'hooks';
import { useLocation } from 'react-router-dom';
import { palette } from 'frontend-shared-theme';
import { IS_OLD_AUTH } from 'common/constants';
import { getLabelAttributes, getUserInfo } from './transducers';
import { useAppContext } from 'common-v2/hooks';

export const Intercom = React.memo(() => {
  const location = useLocation();
  const allLabels = useAllLabels();
  const { labels } = useAppContext();
  const { authProfile } = useAuth();

  const labelAttributes = useMemo(() => getLabelAttributes(labels, allLabels), [labels, allLabels]);
  const userInfo = useMemo(() => getUserInfo(authProfile), [authProfile]);

  useEffect(() => {
    if (authProfile?.externalId) {
      window.Intercom('boot', {
        app_id: `${process.env.INTERCOM_ID}`,
        user_id: authProfile.externalId.replace(IS_OLD_AUTH ? 'auth0|' : 'sme-dna|', ''),
        action_color: '#0c0d14',
        background_color: palette.pink,
      });
    }

    return () => {
      window.Intercom('shutdown');
    };
  }, [authProfile?.externalId]);

  useEffect(() => {
    window.Intercom('update', {
      name: userInfo.name,
      email: userInfo.email,
      ...labelAttributes,
    });
  }, [labelAttributes, userInfo, location]);

  return <></>;
});
