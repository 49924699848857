import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { EXPANDED_CONTAINER_HEIGHT, EXPANDED_CONTAINER_WIDTH } from './constants';

export const BEM_CLASS = 'expandable-text';

export const SExpandableText = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: ${EXPANDED_CONTAINER_WIDTH}px;
  height: ${EXPANDED_CONTAINER_HEIGHT}px;

  .${BEM_CLASS} {
    &__textarea {
      width: 100%;
      flex-grow: 1;
      border: 0;
      resize: none;
      outline: none;
      padding: 7px 20px;
      font-size: 14px;
      line-height: 25px;
      font-weight: 500;
      color: ${manhattanThemeColors.primaryDark};

      &::placeholder {
        color: ${manhattanThemeColors.grayedText};
      }
    }

    &__footer {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
    }

    &__clear {
      margin-right: 10px;
    }

    &__counter {
      color: ${manhattanThemeColors.secondGrayedText};
      text-transform: uppercase;
    }

    &__max {
      color: ${manhattanThemeColors.darkRed};

      &--isHidden {
        visibility: hidden;
      }
    }
  }
`;
