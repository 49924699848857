import { PurchaseOrder } from 'backend-api/models';
import { PO_NAME_SEPARATOR } from 'common/constants';

export const getPurchaseOrderNameWithoutPONumber = (dirtyName: string): string => {
  if (!dirtyName.includes(PO_NAME_SEPARATOR)) {
    return 'Untitled';
  }

  return dirtyName.split(PO_NAME_SEPARATOR)[0];
};

export const getPurchaseOrderNameWithPONumber = (purchaseOrder: PurchaseOrder): string => {
  if (!purchaseOrder.name.includes(PO_NAME_SEPARATOR)) {
    return `Untitled | PO #${purchaseOrder.poNumber}`;
  }

  return purchaseOrder.name;
};
