import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const BEM_CLASS = 'info-content';

export const SInfoContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  color: ${palette.darkBlue};

  .${BEM_CLASS} {
    &__date {
      flex-shrink: 0;
    }
  }
`;
