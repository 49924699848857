import { useMemo } from 'react';
import { Pagination } from '../components/pagination';
import { DEFAULT_PAGINATION_LIMIT } from '../constants';

interface Filters {
  limit?: number;
  offset?: number;
}

export const usePagination = (filters: Filters, total?: number): Pagination =>
  useMemo(
    () => ({
      pageSize: filters.limit || DEFAULT_PAGINATION_LIMIT,
      total: total || 0,
      current: (filters.offset || 0) / (filters.limit || DEFAULT_PAGINATION_LIMIT) + 1 || 1,
    }),
    [filters.limit, filters.offset, total]
  );
