import React from 'react';
import { bem } from 'utils/bem';
import { SRecentSearchItem, BEM_CLASS } from './s-recent-search-item';
import { TooltipedText } from 'common/components/tooltiped-text';
import { SPoint, STitle } from 'app/components/search-modal/styled';
import { ConfidentialProjectIcon } from 'common/components/confidential-project-icon';

const classes = bem(BEM_CLASS);

interface RecentSearchItemProps {
  title: string;
  badges: React.ReactElement[];
  info?: string;
  isConfidentialProject?: boolean;
}

export const RecentSearchItem = React.memo(
  ({ title, badges, info, isConfidentialProject = false }: RecentSearchItemProps) => (
    <SRecentSearchItem>
      <ConfidentialProjectIcon isConfidential={isConfidentialProject} color="black">
        <TooltipedText text={title}>
          <STitle className={classes('title')}>{title}</STitle>
        </TooltipedText>
      </ConfidentialProjectIcon>
      <div className={classes('info')}>
        {badges.map((badge, index) => (
          <React.Fragment key={index}>
            {badge}
            {index !== badges.length - 1 && <SPoint />}
          </React.Fragment>
        ))}
        {info && (
          <>
            <SPoint />
            <TooltipedText text={info}>
              <span>{info}</span>
            </TooltipedText>
          </>
        )}
      </div>
    </SRecentSearchItem>
  )
);
