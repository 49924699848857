import { GroupBase, Props } from 'react-select';
import { isSubstring } from 'utils/string';
import { isOptionsGroup, isSelectCustomProps } from './guards';
import { BaseCustomReactSelectProps, BaseOption, MenuPlacement, MultiCustomReactSelectProps } from './types';
import { noop } from 'lodash';

export const getFilteredOptions = <T extends BaseOption>(
  options: readonly (T | GroupBase<T>)[],
  search: string
): (T | GroupBase<T>)[] =>
  options.reduce((result, option) => {
    if (isOptionsGroup(option)) {
      const { options } = option;
      const filteredOptions = options.filter(option => isSubstring(option.value, search));

      result.push({
        ...option,
        options: filteredOptions,
      });
    } else {
      if (isSubstring(option.value, search)) {
        result.push(option);
      }
    }

    return result;
  }, [] as (T | GroupBase<T>)[]);

export const getBaseCustomSelectProps = <T, IsMulti extends boolean>(
  props: Props<T, IsMulti>
): Required<BaseCustomReactSelectProps> => {
  const customPropsDefaultValues: Required<BaseCustomReactSelectProps> = {
    dataSelector: 'select',
    portalPlacement: MenuPlacement.Auto,
    classNames: {},
    handleMenu: noop,
    closeMenu: noop,
  };

  const customSelectProps = isSelectCustomProps<T, IsMulti, BaseCustomReactSelectProps>(props)
    ? props?.customSelectProps
    : undefined;

  const {} = customSelectProps ?? {};

  return {
    ...customPropsDefaultValues,
    ...customSelectProps,
    classNames: customSelectProps?.classNames || customPropsDefaultValues.classNames,
  };
};
export const getMultiCustomSelectProps = <T, IsMulti extends boolean>(
  props: Props<T, IsMulti>
): Required<MultiCustomReactSelectProps> => {
  const customPropsDefaultValues: Required<MultiCustomReactSelectProps> = {
    ...getBaseCustomSelectProps(props),
    valueContainerLabel: '',
  };

  const customSelectProps = isSelectCustomProps<T, IsMulti, MultiCustomReactSelectProps>(props)
    ? props?.customSelectProps
    : undefined;

  const {} = customSelectProps ?? {};

  return {
    ...customPropsDefaultValues,
    ...customSelectProps,
    classNames: customSelectProps?.classNames || customPropsDefaultValues.classNames,
  };
};
