import { Label } from 'backend-api/models';
import { matchPath } from 'react-router-dom';
import { PATHS_WITH_ACTIVE_LABEL_SELECTOR, PATHS_WITH_ALL_LABELS_OPTION, ALL_LABELS } from './constants';

export const getIsLabelSelectorActive = (currentPath: string) =>
  PATHS_WITH_ACTIVE_LABEL_SELECTOR.some((path: string) => !!matchPath(currentPath, { path })?.isExact);

export const getAllLabelsOptions = (currentPath: string, labels?: Label[]) => {
  if (!labels) return [];

  const isAllLabelsOptionsAvailable = PATHS_WITH_ALL_LABELS_OPTION.some(
    (path: string) => !!matchPath(currentPath, { path })?.isExact
  );
  return isAllLabelsOptionsAvailable ? [ALL_LABELS].concat(labels) : labels;
};
