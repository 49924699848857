import { Api } from 'backend-api';
import { asyncEpic } from 'core/epics';
import { getPurchaseOrder, getPurchaseOrderChildren, getPurchaseOrdersProviders } from './actions';

export const getArtistUnassignedProjectsEpic = asyncEpic(getPurchaseOrder, ({ payload }) =>
  Api.getPurchaseOrder(payload.projectId, payload.id)
);

export const getPurchaseOrderChildrenEpic = asyncEpic(getPurchaseOrderChildren, action => {
  const { projectId, id, params } = action.payload;
  return Api.getPurchaseOrderBlanketItems(projectId, id, params);
});

export const getPurchaseOrdersProvidersEpic = asyncEpic(getPurchaseOrdersProviders, action => {
  return Api.getPurchaseOrdersProviders(action.payload);
});
