export const MODAL_SPACING = 20;

export const MODAL_TOP_PADDING = 16;
export const MODAL_MIN_WIDTH = 300;

export const DEFAULT_MODAL_WIDTH = 720;
export const DEFAULT_MODAL_SIDEBAR_WIDTH = 316;

export const CLOSE_ICON_TOP_OFFSET = MODAL_TOP_PADDING + 1;
export const CLOSE_ICON_RIGHT_OFFSET = MODAL_SPACING;
export const CLOSE_ICON_SIZE = 22;

export const MODAL_SIDEBAR_TOP_PADDING = CLOSE_ICON_TOP_OFFSET + CLOSE_ICON_SIZE + MODAL_SPACING;

export const TITLE_RIGHT_MARGIN_WITHOUT_SIDEBAR = CLOSE_ICON_RIGHT_OFFSET + CLOSE_ICON_SIZE;
