import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { theme } from 'app/theme';
import { typography } from 'app/theme/typography';

export const BEM_CLASS = 'project-card';

export const SProjectCard = styled.div`
  border-radius: 10px;
  background-color: ${colorPalette.white};
  box-shadow: ${theme.shadows.smallCardShadow};
  margin-bottom: 20px;
  overflow: hidden;
  display: flex;

  .${BEM_CLASS} {
    &__project-container {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      overflow: hidden;
    }
    &__title-container {
      display: flex;
      align-items: center;
      padding: 26px 30px;
    }
    &__schedule-badge {
      flex-shrink: 0;
      padding: 3px 10px 2px;
      margin-left: 10px;
      background-color: ${colorPalette.eastBay};
      .badge {
        &__text {
          color: ${colorPalette.white};
        }
      }
    }
    &__details-link {
      ${typography.h4};
      color: ${colorPalette.azureRadiance};
      margin-left: auto;
      width: 69px;
      flex-shrink: 0;
    }
    &__details-arrow-icon {
      margin-left: 6px;
    }
    &__budget-section {
      margin-top: auto;
      background-color: ${colorPalette.whisper};
      padding: 24px 30px;
    }
    &__current-phase {
      width: 100%;
      margin-bottom: 26px;
    }
    &__main-info-container {
      display: flex;
      align-items: center;
    }
    &__platforms {
      flex-shrink: 0;
      margin-left: auto;
      margin-right: 30px;
      margin-bottom: 26px;
    }
    &__title {
      margin-right: 20px;
      flex-grow: 1;
      overflow: hidden;
    }
  }
`;
