import React, { ReactChild } from 'react';
import { bem } from 'utils/bem';
import { Playlist } from 'backend-api/models';
import { pluralizeString } from 'utils/string';
import { Button } from 'common/components/button';
import { SArtistPlaylistWidget } from '../../s-artist-playlist-widget';

interface Props {
  playlists: Playlist[];
  className?: string;
  renderCard(target: Playlist): ReactChild;
  onShowViewAllTargetsModal(): void;
}

const classes = bem('artist-playlist-widget');

export const PlaylistWidget = React.memo(({ className, playlists, renderCard, onShowViewAllTargetsModal }: Props) => {
  const firstFiveItems = playlists.slice(0, 5);
  const targetsSize = playlists?.length || 0;
  const shouldExpand = targetsSize > 5;

  return (
    <SArtistPlaylistWidget className={className} data-selector="targets-widget">
      <div data-selector="targets-widget-title" className={classes('title')}>
        {pluralizeString('Playlist' || '', targetsSize)}
      </div>
      <div className={classes('targets-container')}>{firstFiveItems.map(renderCard)}</div>
      {shouldExpand && (
        <Button
          dataSelector="targets-widget-show-all"
          className={classes('button')}
          variant={'secondary-cta'}
          onClick={onShowViewAllTargetsModal}
        >
          {`Show All (${targetsSize})`}
        </Button>
      )}
    </SArtistPlaylistWidget>
  );
});
