import React from 'react';
import { Skeleton } from 'common-v2/components';

interface LoaderProps {
  className?: string;
}

export const Loader = React.memo(({ className }: LoaderProps) => (
  <Skeleton width={300} height={116} className={className}>
    <rect x="0" y="0" rx="2px" ry="2px" width="100" height="20" />
    <rect x="0" y="24" rx="2px" ry="2px" width="300" height="25" />
    <rect x="0" y="69" rx="2px" ry="2px" width="100" height="20" />
    <rect x="0" y="93" rx="2px" ry="2px" width="300" height="25" />
  </Skeleton>
));
