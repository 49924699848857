import styled from 'styled-components';
import { manhattanBackground } from 'common-v2/styles';

export const BEM_CLASS = 'label-permissions-container';

export const SLabelPermissionsContainer = styled.div`
  .${BEM_CLASS} {
    &__manhattan-background {
      ${manhattanBackground};
    }

    &__tabs {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
`;
