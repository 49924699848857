import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { Typography, TYPOGRAPHY_TYPE, SelectOptionProps } from 'gdb-web-shared-components';
import { SLabelOption, BEM_CLASS } from './s-label-option';
import { LabelOption as Option } from '../../types';
import { Image } from 'common-v2/components';
import sonyMusicPng from 'assets/sony-music.png';

const classes = bem(BEM_CLASS);

export const LabelOption = React.memo(({ label, isDisabled, isActive, image }: Option & SelectOptionProps) => {
  const labelOptionClassName = useMemo(() => `${classes('', { isDisabled, isActive })}`, [isDisabled, isActive]);

  return (
    <SLabelOption className={labelOptionClassName}>
      <Image
        imageUrl={image}
        alt={`Option ${label}`}
        placeholderUrl={sonyMusicPng}
        className={classes('image')}
        data-selector="label-image"
      />
      <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('label')}>
        {label}
      </Typography>
    </SLabelOption>
  );
});
