import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { typography } from 'app/theme/typography';

interface SBodyProps {
  readonly expanded: boolean;
}

export const SBody = styled.div<SBodyProps>`
  .campaign-edit-form {
    &__toolbar {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    &__dropdownContainer {
      padding: 0 0px;
    }
    &__dropdownField {
      flex: 4;
      .form-field-cell__field {
      }
    }
    &__campaignTypeEditContainer {
      display: flex;
      flex-wrap: wrap;
    }

    &__divider {
      box-sizing: border-box;
      height: 1px;
      width: 100%;
      background: #f2f2f7;
      margin-bottom: 17px;
    }
    &__campaign-main-info,
    &__dynamic-taxonomy {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 80px;
    }
    &__dynamic-taxonomy {
      grid-column: span 2;
    }
    &__section-title {
      margin: 25px 0 20px;
    }
    &__notes-paper {
      .notes__input {
        min-height: 180px;
        padding: 21px 30px 6px 30px;
      }
      .notes__icon {
        top: ${({ expanded }) => (expanded ? '32' : '12')}px;
      }
    }
    &__name-field {
      margin-top: 35px;
      overflow: hidden;
    }
    &__purchase-order-link-cell {
      align-items: center;
      padding-bottom: 20px;
    }
    &__name-container {
      display: flex;
      align-items: flex-end;
    }
    &__status-selector {
      margin-left: 20px;
      margin-right: auto;
      flex-shrink: 0;
    }
    &__platform-details {
      margin-bottom: 16px;
    }
    &__divider {
      height: 1px;
      border-radius: 10px;
      grid-column: 1/-1;
      background-color: ${colorPalette.bluishWhite};
    }
    &__copy-input {
      min-height: 65px;
      ${typography.body};
    }
  }
`;
