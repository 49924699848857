import * as t from 'io-ts';
import {
  ResponseModel,
  IdNameField,
  FormattedDateFromISOString,
  Optional,
  TerritorySchema,
  NonEmptyString,
  IdType,
} from '../common';

export const ArtistMomentTypeSchema = ResponseModel(
  {
    ...IdNameField,
  },
  'ArtistMomentType'
);

export type ArtistMomentType = t.TypeOf<typeof ArtistMomentTypeSchema>;

export const ArtistMomentTypeGroupSchema = ResponseModel(
  {
    ...IdNameField,
    types: t.array(ArtistMomentTypeSchema),
  },
  'ArtistMomentTypeGroup'
);

export type ArtistMomentTypeGroup = t.TypeOf<typeof ArtistMomentTypeGroupSchema>;

export const ArtistMomentCategorySchema = ResponseModel(
  {
    ...IdNameField,
  },
  'ArtistMomentCategory'
);

export type ArtistMomentCategory = t.TypeOf<typeof ArtistMomentCategorySchema>;

export const MomentArtistSchema = ResponseModel(
  {
    id: t.string,
    name: t.string,
  },
  'MomentArtist'
);

export type MomentArtist = t.TypeOf<typeof MomentArtistSchema>;

export const ArtistMomentUserSchema = ResponseModel(
  {
    ...IdNameField,
    email: t.string,
  },
  'ArtistMomentUser'
);

export type ArtistMomentUser = t.TypeOf<typeof ArtistMomentUserSchema>;

export const ArtistMomentEditableFieldsSchema = ResponseModel(
  {
    name: t.boolean,
    date: t.boolean,
    artist: t.boolean,
    territories: t.boolean,
    link: t.boolean,
    notes: t.boolean,
    type: t.boolean,
    category: t.boolean,
  },
  'ArtistMomentEditableFields'
);

export type ArtistMomentEditableFields = t.TypeOf<typeof ArtistMomentEditableFieldsSchema>;

export const ArtistMomentMetadataSchema = ResponseModel(
  {
    fields: ArtistMomentEditableFieldsSchema,
  },
  'ArtistMomentMetadata'
);

export type ArtistMomentMetadata = t.TypeOf<typeof ArtistMomentMetadataSchema>;

export const ArtistMomentSchema = ResponseModel(
  {
    ...IdNameField,
    date: FormattedDateFromISOString,
    link: Optional(t.string),
    notes: Optional(t.string),
    type: Optional(ArtistMomentTypeSchema),
    artist: MomentArtistSchema,
    territories: t.array(TerritorySchema),
    createUser: Optional(ArtistMomentUserSchema),
    editUser: Optional(ArtistMomentUserSchema),
    editDate: FormattedDateFromISOString,
    category: Optional(ArtistMomentCategorySchema),
    metadata: ArtistMomentMetadataSchema,
  },
  'Moment'
);

export type ArtistMoment = t.TypeOf<typeof ArtistMomentSchema>;

export const ArtistMomentRequestSchema = ResponseModel(
  {
    name: NonEmptyString,
    date: FormattedDateFromISOString,
    artistId: t.string,
    territoriesIds: t.array(IdType),
    link: Optional(t.string),
    notes: Optional(t.string),
    typeId: Optional(IdType),
    categoryId: Optional(IdType),
  },
  'ArtistMomentRequest'
);

export type ArtistMomentRequest = t.TypeOf<typeof ArtistMomentRequestSchema>;
