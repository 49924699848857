import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'simple-select';

export const SSimpleSelect = styled.div`
  .${BEM_CLASS} {
    &__control {
      border-bottom: 1px dashed ${colorPalette.eastBay};
      border-radius: 0;
      background-color: transparent;

      &--isMenuOpen {
        border-color: transparent;
      }
    }

    &__value-container {
      padding-left: 0;
      padding-top: 4px;
      padding-bottom: 0;
    }

    &__single-value {
      &--isDisabled {
        color: ${colorPalette.gray};
      }
    }

    &__indicators-container {
      padding-right: 0;
    }
  }
`;
