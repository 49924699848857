import { createTypedReducer, onAction } from 'core/store';
import { openModal, closeModal } from './actions';

export interface ModalState {
  isOpened: boolean;
}

export const initialState: ModalState = {
  isOpened: false,
};

export const reducer = createTypedReducer<ModalState>(
  initialState,
  onAction(openModal, state => ({
    ...state,
    isOpened: true,
  })),
  onAction(closeModal, state => ({
    ...state,
    isOpened: false,
  }))
);
