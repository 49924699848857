import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { accessRestrictedData, activeProjectSelector, errorSelector } from 'common/selectors';
import { getProject, resetAccessRestrictedData } from 'common/actions';
import { ConfidentialNoPermission } from 'common/components/project-no-permission';
import { RouteComponentProps } from 'react-router-dom';
import { useProjectAccessRestricted } from 'hooks';
import { useAppContext } from 'common-v2/hooks';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';
import { Head, HeadStaticTitles } from 'app/components/head';
import { getProjectName } from 'common-v2/transducers';

interface MatchParams {
  id: string;
}

export const ProjectNoPermissionContainer = React.memo(
  ({
    match: {
      params: { id },
    },
  }: RouteComponentProps<MatchParams>) => {
    const projectId = parseInt(id, 10);

    const { user: currentUser } = useAppContext();
    const data = useSelector(accessRestrictedData);
    const project = useSelector(activeProjectSelector);

    const projectTitle = useMemo(
      () => getProjectName(project) || data?.currentProjectName || HeadStaticTitles.ACCESS_RESTRICTED,
      [project, data]
    );

    useProjectAccessRestricted(errorSelector);

    const dispatch = useDispatch();

    useEffect(() => {
      if (!data) {
        dispatch(getProject.request({ projectId }));
      }
    }, [data, dispatch, projectId]);

    useEffect(() => {
      return () => {
        dispatch(resetAccessRestrictedData());
      };
    }, [dispatch]);

    return (
      <>
        <Head title={projectTitle} />
        <OutdatedGlobalLayout>
          <ConfidentialNoPermission
            teamMembers={data?.currentTeamMembers}
            projectId={data?.currentProjectId}
            projectName={data?.currentProjectName}
            currentUser={currentUser}
          />
        </OutdatedGlobalLayout>
      </>
    );
  }
);
