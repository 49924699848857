import { cloneDeep } from 'lodash';
import { GridApi } from 'ag-grid-community';
import {
  Nullable,
  ModifyBulkCampaignsRequest,
  MediaPlanCampaignStylesRequest,
  MediaPlanCampaignRequest,
} from 'backend-api/models';
import { emptyRequestPayloadItem } from './constants';
import { RequestPayloadItem, CellColorForRequestInput, CellValueDataForRequestInput } from './types';
import { getFormattedDate, parseDateByFormat } from 'common-v2/utils';
import { MediaPlanMode } from 'media-plan-v2/types';
import { PendingData, PendingDataStore } from 'media-plan-v2/components/spreadsheet/types';
import { MediaPlanColumnId, SpreadsheetRowData, InitialConfigParams } from 'media-plan-v2/containers/spreadsheet/types';

export const aggregatePendingDataStore = (
  pendingData: PendingData,
  store: keyof PendingData,
  data?: SpreadsheetRowData
): Nullable<PendingDataStore> => {
  const pendingDataStore = pendingData[store];

  if (!data) return pendingDataStore;

  const id = data.uuid.value;

  if (id === undefined) return pendingDataStore;

  if (pendingDataStore === null) {
    return { [id]: data };
  } else {
    return {
      ...pendingDataStore,
      [id]: Object.assign(pendingDataStore[id] ?? {}, data),
    };
  }
};

export const sanitizePendingDataStore = (pendingData: PendingData): PendingData => {
  const pendingDataCopy = cloneDeep(pendingData);

  if (pendingDataCopy.delete !== null) {
    for (const id in pendingDataCopy.delete) {
      delete pendingDataCopy.create?.[id];
      delete pendingDataCopy.update?.[id];
    }
  }

  if (pendingDataCopy.create !== null) {
    for (const id in pendingDataCopy.create) {
      pendingDataCopy.create[id] = Object.assign(pendingDataCopy.create[id], pendingDataCopy.update?.[id]);
      delete pendingDataCopy.update?.[id];
    }
  }

  if (pendingDataCopy.update !== null) {
    for (const id in pendingDataCopy.update) {
      pendingDataCopy.update[id] = Object.assign(pendingDataCopy.update[id], {
        orderInPhase: { ...pendingDataCopy.update[id].orderInPhase, value: undefined },
      });
    }
  }

  return pendingDataCopy;
};

export const prepareCellColorForRequest = (
  input: CellColorForRequestInput
): Partial<MediaPlanCampaignStylesRequest> => {
  if (!input) return {};

  const { column, color } = input;
  const background = color ? { background: color } : null;

  switch (column) {
    case MediaPlanColumnId.PLATFORMS:
      return { platforms: background };
    case MediaPlanColumnId.AD_COPY:
      return { adCreativeNotes: background };
    case MediaPlanColumnId.DETAILS:
      return { audienceNotes: background };
    case MediaPlanColumnId.AD_CREATIVE:
      return { adCreativeLinks: background };
    case MediaPlanColumnId.DESTINATION:
      return { destinationLinks: background };
    case MediaPlanColumnId.BUDGET:
      return { plannedBudget: background };
    case MediaPlanColumnId.ECPM:
      return { ecpm: background };
    case MediaPlanColumnId.AGE:
      return {
        audienceHigherAge: background,
        audienceLowerAge: background,
      };
    case MediaPlanColumnId.KPI:
      return { kpiMetricsFieldId: background };
    case MediaPlanColumnId.OBJECTIVE:
      return { objective: background };
    case MediaPlanColumnId.COUNTRIES:
      return { territories: background };
    case MediaPlanColumnId.STATUS:
      return { workflowStatus: background };
    case MediaPlanColumnId.PLACEMENTS:
      return { placements: background };
    case MediaPlanColumnId.END_DATE:
      return { endDate: background };
    case MediaPlanColumnId.START_DATE:
      return { startDate: background };
    case MediaPlanColumnId.AUDIENCE_GENDERS:
      return { genders: background };
    case MediaPlanColumnId.NOTES:
      return { notes: background };
    case MediaPlanColumnId.NAME:
      return { name: background };
    case MediaPlanColumnId.CREATIVE_DESCRIPTION:
      return { creativeDescription: background };
    case MediaPlanColumnId.HEADLINE:
      return { headline: background };
    case MediaPlanColumnId.CALL_TO_ACTION:
      return { callToAction: background };
    case MediaPlanColumnId.ACTUAL_SPENT:
      return { actualSpent: background };
    case MediaPlanColumnId.NAMING_CONVENTION:
      return { namingConventionManual: background };
    case MediaPlanColumnId.EST_IMPRESSIONS:
      return { estImpressions: background };
    default:
      return {};
  }
};

export const prepareCellValueDataForRequest = (
  input: CellValueDataForRequestInput,
  params?: Partial<InitialConfigParams>
): Partial<MediaPlanCampaignRequest> => {
  if (!input) return {};

  const { column, value } = input;

  switch (column) {
    case MediaPlanColumnId.PLATFORMS:
      return { platforms: value?.map(platform => platform.id) || null };
    case MediaPlanColumnId.AD_COPY:
      return { adCreativeNotes: value || null };
    case MediaPlanColumnId.DETAILS:
      return { audienceNotes: value || null };
    case MediaPlanColumnId.AD_CREATIVE:
      return { adCreativeLinks: value || null };
    case MediaPlanColumnId.DESTINATION:
      return { destinationLinks: value || null };
    case MediaPlanColumnId.BUDGET:
      return { plannedBudget: value || null };
    case MediaPlanColumnId.ECPM:
      return { ecpm: value || null };
    case MediaPlanColumnId.AGE:
      return { audienceHigherAge: value?.higherAge || null, audienceLowerAge: value?.lowerAge || null };
    case MediaPlanColumnId.KPI:
      return { kpiMetricsFieldId: value !== undefined && value !== null ? value.id : null };
    case MediaPlanColumnId.OBJECTIVE:
      return { objective: value !== undefined && value !== null ? value.id : null };
    case MediaPlanColumnId.COUNTRIES:
      return { territories: value?.map(country => country.id) || null };
    case MediaPlanColumnId.STATUS:
      return { workflowStatus: value !== undefined && value !== null ? value : null };
    case MediaPlanColumnId.PLACEMENTS:
      return { placements: value?.map(placement => placement.id) || null };
    case MediaPlanColumnId.END_DATE:
      if (!value) return { endDate: null };

      return { endDate: getFormattedDate('yyyy-MM-dd')(parseDateByFormat(value, params?.dateFormat)) };
    case MediaPlanColumnId.START_DATE:
      if (!value) return { startDate: null };

      return { startDate: getFormattedDate('yyyy-MM-dd')(parseDateByFormat(value, params?.dateFormat)) };

    case MediaPlanColumnId.AUDIENCE_GENDERS:
      return { genders: value?.map(gender => gender.id) || null };
    case MediaPlanColumnId.NOTES:
      return { notes: value || null };
    case MediaPlanColumnId.NAME:
      return { name: value || null };
    case MediaPlanColumnId.CREATIVE_DESCRIPTION:
      return { creativeDescription: value || null };
    case MediaPlanColumnId.HEADLINE:
      return { headline: value || null };
    case MediaPlanColumnId.CALL_TO_ACTION:
      return { callToAction: value || null };
    case MediaPlanColumnId.ACTUAL_SPENT:
      return { actualSpent: value || null };
    case MediaPlanColumnId.NAMING_CONVENTION:
      return { namingConventionManual: value || null };
    default:
      return {};
  }
};

export const mapPendingData = (
  pendingData: PendingData,
  mediaPlanMode?: MediaPlanMode,
  params?: Partial<InitialConfigParams>
): ModifyBulkCampaignsRequest => {
  const payload: ModifyBulkCampaignsRequest = { create: [], update: [], delete: [] };

  if (pendingData.create !== null) {
    Object.values(pendingData.create).forEach(data => {
      const { style, columns } = Object.entries(data).reduce<RequestPayloadItem>((acc, [column, { color, value }]) => {
        const colorsPayload = {
          ...acc.style,
          ...prepareCellColorForRequest({ column, color } as CellColorForRequestInput),
        };

        if (column === MediaPlanColumnId.STATUS && mediaPlanMode === MediaPlanMode.APPROVAL)
          return {
            ...acc,
            style: colorsPayload,
          };

        return {
          style: colorsPayload,
          columns: {
            ...acc.columns,
            ...prepareCellValueDataForRequest({ column, value } as CellValueDataForRequestInput, params),
          },
        };
      }, emptyRequestPayloadItem);

      payload.create.push({
        orderInPhase: data.orderInPhase.value,
        phaseId: data.phaseId.value,
        columns,
        campaignUuid: data.uuid.value,
        style,
      });
    });
  }

  if (pendingData.update !== null) {
    Object.values(pendingData.update).forEach(data => {
      if (!data.id) return;

      const { style, columns } = Object.entries(data).reduce<RequestPayloadItem>((acc, [column, { color, value }]) => {
        const colorsPayload = {
          ...acc.style,
          ...prepareCellColorForRequest({ column, color } as CellColorForRequestInput),
        };

        if (column === MediaPlanColumnId.STATUS && mediaPlanMode === MediaPlanMode.APPROVAL)
          return {
            ...acc,
            style: colorsPayload,
          };

        return {
          style: colorsPayload,
          columns: {
            ...acc.columns,
            ...prepareCellValueDataForRequest({ column, value } as CellValueDataForRequestInput, params),
          },
        };
      }, emptyRequestPayloadItem);

      payload.update.push({
        orderInPhase: data.orderInPhase.value,
        campaignUuid: data.uuid.value,
        columns,
        style,
      });
    });
  }

  if (pendingData.delete !== null) {
    Object.values(pendingData.delete).forEach(data => {
      if (!data.id) return;

      payload.delete.push(data.uuid.value);
    });
  }

  return payload;
};

export const enrichPendingData = (api: GridApi<SpreadsheetRowData>, data: PendingData): PendingData => {
  const pendingDataCopy = cloneDeep(data);

  if (pendingDataCopy.update !== null) {
    for (const id in pendingDataCopy.update) {
      const updateData = pendingDataCopy.update[id];

      if (updateData.id.value === undefined) {
        const node = api.getRowNode(id);

        if (!node || !node.data) continue;

        if (updateData.id) {
          updateData.id.value = node.data.id.value;
        }
      }
    }
  }

  return pendingDataCopy;
};
