import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { SFlex } from 'common/s-components/layout/s-flex';
import { Button } from 'common/components/button';
import { Tooltip } from 'common/components/tooltip';
import { SBox } from 'common/s-components/layout/s-box';
import { SSpacer } from 'common/s-components/layout/s-spacer';
import { SFormFooter } from './s-form-footer';

interface FormFooterProps {
  isSaveDisabled?: boolean;
  isBeingProcessed?: boolean;
  tooltipContent?: string;
  isRemovable?: boolean;
  hasDateConflicts?: boolean;
  customRemoveButton?: React.ReactNode;
  onSubmit(): void;
  onCancel(): void;
  onRemove?(): void;
}

export const FormFooter = React.memo(
  ({
    isSaveDisabled,
    isBeingProcessed,
    isRemovable = false,
    tooltipContent,
    customRemoveButton,
    onSubmit,
    onCancel,
    onRemove,
  }: FormFooterProps) => {
    const [isSticky, setIsSticky] = useState(false);

    const rootRef = useRef<HTMLDivElement>(null);

    const button = useMemo(
      () => (
        <Button
          variant={isSaveDisabled || isBeingProcessed ? 'secondary-white' : 'primary-cta'}
          onClick={onSubmit}
          disabled={isSaveDisabled || isBeingProcessed}
          dataSelector="save-button"
        >
          Save
        </Button>
      ),
      [isSaveDisabled, isBeingProcessed, onSubmit]
    );

    const buttonWithTooltip = useMemo(
      () =>
        tooltipContent ? (
          <Tooltip content={tooltipContent} maxWidth={180}>
            <div>{button}</div>
          </Tooltip>
        ) : (
          button
        ),
      [button, tooltipContent]
    );

    useLayoutEffect(() => {
      const rootElement = rootRef && rootRef.current;
      const observer = new IntersectionObserver(([entry]) => setIsSticky(entry.intersectionRatio < 1), {
        threshold: 1,
      });

      if (rootElement) {
        observer.observe(rootElement);
      }

      return () => {
        if (rootElement) {
          observer.unobserve(rootElement);
        }
      };
    }, []);

    return (
      <SFormFooter ref={rootRef} isSticky={isSticky}>
        <SFlex alignItems="center" flexWrap="nowrap">
          {isRemovable &&
            (customRemoveButton || (
              <Button
                variant="secondary-white"
                dataSelector="remove-button"
                onClick={onRemove}
                disabled={isBeingProcessed}
              >
                Remove
              </Button>
            ))}
          <SSpacer />
          <SFlex>
            <Button
              variant="secondary-white"
              dataSelector="cancel-button"
              onClick={onCancel}
              disabled={isBeingProcessed}
            >
              Cancel
            </Button>
            <SBox ml="20px">{buttonWithTooltip}</SBox>
          </SFlex>
        </SFlex>
      </SFormFooter>
    );
  }
);
