import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { ResultTableConfig } from './types';
import { DEFAULT_GAP } from './constants';

export const BEM_CLASS = 'result-table';
interface SResultTableProps {
  config: ResultTableConfig[];
}

export const SResultTable = styled.div<SResultTableProps>`
  flex-shrink: 0;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .${BEM_CLASS} {
    &__grid {
      position: relative;
      z-index: 1;
      display: grid;
      grid-template-columns: ${({ config }) =>
        config.reduce((template, { fraction }) => `${template} ${fraction}`.trim(), '')};
      row-gap: ${DEFAULT_GAP}px;
      height: 100%;
    }

    &__header-cell {
      position: sticky;
      top: 0;
      min-width: 0;
      padding: 12px ${DEFAULT_GAP}px;
      font-size: 12px;
      line-height: 15px;
      font-weight: 700;
      color: ${palette.darkBlueThree};
      background-color: ${palette.athensGrayTwo};
      margin-bottom: ${DEFAULT_GAP}px;

      &:nth-child(1) {
        padding-left: ${DEFAULT_GAP * 2}px;
      }

      &:nth-child(${({ config }) => config.length}) {
        text-align: right;
        padding-right: ${DEFAULT_GAP * 2}px;
      }
    }

    &__content-cell {
      position: relative;
      z-index: -1;
      min-width: 0;
      padding: 0 ${DEFAULT_GAP}px ${DEFAULT_GAP}px;

      &::after {
        content: '';
        position: absolute;
        display: block;
        top: 100%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${palette.gray235};
      }

      ${({ config }) => `
        &:nth-child(1),
        &:nth-child(${config.length}n + 1),
        &:nth-last-child(-n + ${config.length}) {
          &::after {
            display: none;
          }
        }

        &:nth-child(1),
        &:nth-child(${config.length}n + 1) {
          padding-left: ${DEFAULT_GAP * 2}px;
        }

        &:nth-child(2),
        &:nth-child(${config.length}n + 2) {
          &::after {
            left: ${DEFAULT_GAP}px;
            width: calc(100% - ${DEFAULT_GAP}px);
          }
        }

        &:nth-child(${config.length}n) {
          padding-right: ${DEFAULT_GAP * 2}px;

          &::after {
            width: calc(100% - ${DEFAULT_GAP * 2}px);
          }
        }

        &:nth-last-child(-n + ${config.length}) {
          padding-bottom: ${DEFAULT_GAP * 2}px;
        }
      `}
    }
  }
`;
