import { useCallback } from 'react';
import { ColumnApi, GridApi, RowNode } from 'ag-grid-community';
import { UUID } from 'io-ts-types/lib/UUID';
import { SpreadsheetRowData } from '../types';
import { clearCampaign, isTableSorted } from '../transducers';

export const useDeleteCampaign = (sanitizeUndoRedoStacks: (uuid: UUID) => void) => {
  const deleteCampaign = useCallback(
    (api: GridApi<SpreadsheetRowData>, columnApi: ColumnApi, node?: RowNode<SpreadsheetRowData> | null) => {
      if (!node || node.group || !node.data) return;

      const isSorted = isTableSorted(columnApi);

      const groupNodes = node.parent?.[isSorted ? 'childrenAfterSort' : 'allLeafChildren'] || [];
      const groupNodeIndex = groupNodes.findIndex(
        ({ data }) => data?.uuid.value && data.uuid.value === node.data?.uuid.value
      );

      if (groupNodes.length > 1) {
        const updateTransaction: SpreadsheetRowData[] = [];

        groupNodes.forEach(({ data }, index) => {
          if (data?.orderInPhase.value === undefined || node.data?.orderInPhase.value === undefined) return;

          const orderInPhase =
            data.orderInPhase.value > node.data.orderInPhase.value
              ? data.orderInPhase.value - 1
              : data.orderInPhase.value;
          const orderOnSort = index > groupNodeIndex ? index - 1 : index;

          updateTransaction.push({
            ...data,
            orderInPhase: {
              ...data.orderInPhase,
              value: orderInPhase,
            },
            orderOnSort: {
              ...data.orderOnSort,
              value: orderOnSort,
            },
          });
        });

        api.applyTransaction({
          update: updateTransaction,
        });

        sanitizeUndoRedoStacks(node.data.uuid.value);
        api.applyTransactionAsync({ remove: [node.data] }, () => {
          api.clearRangeSelection();
        });
      } else {
        sanitizeUndoRedoStacks(node.data.uuid.value);
        api.applyTransactionAsync({
          update: [clearCampaign(node.data)],
        });
      }
    },
    [sanitizeUndoRedoStacks]
  );

  return deleteCampaign;
};
