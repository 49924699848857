import * as t from 'io-ts';
import { ResponseModel, IdNameField, Optional } from '../common';

export const LabelSchema = ResponseModel({ ...IdNameField, imageUrl: Optional(t.string) }, 'Label');

export type Label = t.TypeOf<typeof LabelSchema>;

export const LabelUserSchema = ResponseModel(
  { ...IdNameField, phone: t.string, email: t.string, isAdmin: t.boolean },
  'LabelUser'
);

export type LabelUser = t.TypeOf<typeof LabelUserSchema>;

export const LabelPermissionsArtistSchema = ResponseModel({ id: t.string, name: t.string }, 'LabelPermissionsArtist');

export type LabelPermissionsArtist = t.TypeOf<typeof LabelPermissionsArtistSchema>;

export const LabelPermissionsCountersSchema = ResponseModel(
  { teamMembers: t.number, artists: t.number },
  'LabelPermissionsCounters'
);

export type LabelPermissionsCounters = t.TypeOf<typeof LabelPermissionsCountersSchema>;

export const LabelPermissionsUserSchema = ResponseModel({
  ...IdNameField,
  email: t.string,
  avatarUrl: Optional(t.string),
});

export type LabelPermissionsUser = t.TypeOf<typeof LabelPermissionsUserSchema>;

export const LabelPermissionsMemberSchema = ResponseModel(
  {
    user: LabelPermissionsUserSchema,
    role: t.number,
    artists: t.array(LabelPermissionsArtistSchema),
  },
  'LabelPermissionsMember'
);

export type LabelPermissionsMember = t.TypeOf<typeof LabelPermissionsMemberSchema>;

export const LabelPermissionsArtistInfoSchema = ResponseModel(
  {
    id: t.string,
    name: t.string,
    imageUrl: Optional(t.string),
  },
  'LabelPermissionsArtistInfo'
);

export type LabelPermissionsArtistInfo = t.TypeOf<typeof LabelPermissionsArtistInfoSchema>;

export const LabelPermissionsMemberInfoSchema = ResponseModel(
  {
    id: t.number,
    name: t.string,
    email: t.string,
    avatarUrl: Optional(t.string),
    role: t.number,
  },
  'LabelPermissionsMemberInfo'
);

export type LabelPermissionsTeamMembers = t.TypeOf<typeof LabelPermissionsMemberInfoSchema>;

export const LabelPermissionsArtistWithMembersSchema = ResponseModel(
  {
    artist: LabelPermissionsArtistInfoSchema,
    teamMembers: t.array(LabelPermissionsMemberInfoSchema),
  },
  'LabelPermissionsArtistWithMembers'
);

export type LabelPermissionsArtistWithMembers = t.TypeOf<typeof LabelPermissionsArtistWithMembersSchema>;
