import { css } from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const fontFamily = 'Montserrat';

export const typography = {
  secondaryLabel: css`
    color: ${colorPalette.eastBay};
    font-size: 12px;
    letter-spacing: 1.2px;
    line-height: 15px;
    text-transform: uppercase;
  `,
  primaryLabel: css`
    color: ${({ theme }) => theme.textLabelColor};
    font-size: 10px;
    letter-spacing: 1px;
    line-height: 15px;
  `,
  smallText: css`
    color: ${({ theme }) => theme.basicTextColor};
    font-size: 12px;
    line-height: 20px;
  `,
  body: css`
    color: ${({ theme }) => theme.basicTextColor};
    font-size: 14px;
    line-height: 20px;
  `,
  h4: css`
    color: ${colorPalette.black};
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
  `,
  h3: css`
    color: ${colorPalette.black};
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
  `,
  h2: css`
    color: ${colorPalette.black};
    font-size: 24px;
    font-weight: bold;
    line-height: 35px;
  `,
  h1: css`
    color: ${colorPalette.black};
    font-size: 32px;
    font-weight: bold;
    line-height: 50px;
  `,
};
