import styled from 'styled-components';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'invite-menu';

export const SInviteMenu = styled.div`
  display: flex;
  flex-direction: column;
  width: 142px;
  padding: 15px 0;
  border-radius: 10px;
  background-color: ${colorPalette.white};
  box-shadow: 0px 5px 20px ${transparentize(0.8, manhattanThemeColors.primaryDark)};

  .${BEM_CLASS} {
    &__item {
      padding: 5px 15px;
      text-align: left;

      &:hover {
        background-color: ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
      }

      &--isDisabled {
        cursor: default;

        .${BEM_CLASS}__text {
          color: ${manhattanThemeColors.secondGrayedText};
        }

        &:hover {
          background-color: inherit;
        }
      }
    }
  }
`;
