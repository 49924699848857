import { createSelector } from 'reselect';
import { CommonState } from './reducer';
import { Label, LabelUser, Territory } from 'backend-api/models';
import { GLOBAL_TERRITORY_ID } from './constants';

export const commonSelector = state => state.common as CommonState;

export const labelsSelector = createSelector(commonSelector, state => state.labels);

export const allLabelsSelector = createSelector(commonSelector, state => state.allLabels);

export const labelsNamesSelector = createSelector(labelsSelector, state => state?.data?.map(item => item?.name));

export const labelIdsSelector = createSelector(labelsSelector, state => state?.data?.map(item => item?.id));

export const campaignTaxonomySelector = createSelector(commonSelector, state => state.campaignTaxonomy);

export const goalsSelector = createSelector(commonSelector, state => state.goals);

export const rawTerritoriesSelector = createSelector(commonSelector, state => state.territories);

export interface TerritoryWithLabel extends Territory {
  label: string;
}

export const territoriesSelector = createSelector(rawTerritoriesSelector, treeData => {
  const globalTerritory = treeData?.find(option => option.id === GLOBAL_TERRITORY_ID);
  const globalOptions = treeData?.splice(1, treeData.length) || [];

  return (
    globalTerritory && [
      {
        id: globalTerritory.id,
        name: globalTerritory.name,
        options: globalOptions,
      },
    ]
  );
});

export const projectStatusSelector = createSelector(commonSelector, state => state.projectStatus);

export const currenciesSelector = createSelector(commonSelector, state => state.currencies);

export const usaDollarIdSelector = createSelector(
  currenciesSelector,
  state => state?.find(item => item.code === 'USD')?.id
);

export const activeFiltersNameSelector = createSelector(commonSelector, state => state.activeFiltersName);

export const filtersSelector = createSelector(commonSelector, state => state.filters);

export const activeFiltersSelector = createSelector(
  filtersSelector,
  activeFiltersNameSelector,
  (filters, activeName) => {
    if (filters && activeName) return filters[activeName];
    return null;
  }
);

export const labelUsersSelector = createSelector(commonSelector, state => state.labelUsers || ([] as LabelUser[]));

export const userInfoSelector = createSelector(commonSelector, state => state.userInfo);

export const countryCodesSelector = createSelector(commonSelector, state => state.countryCodes);

export const campaignProvidersSelector = createSelector(commonSelector, state => state.campaignProviders);

export const performanceCampaignProvidersSelector = createSelector(
  commonSelector,
  state => state.performanceCampaignProviders
);

export const gendersSelector = createSelector(commonSelector, state => state.genders);

export const activeProjectSelector = createSelector(commonSelector, state => state.project.data);

export const projectLoadingSelector = createSelector(commonSelector, state => state.project.loading);

export const getLabelNamesFromCachedCommonStoreSelector = createSelector(
  (state: any) => state?.labels,
  labels => {
    let parsedLabels = new Array<Label>();
    try {
      if (labels) {
        parsedLabels = JSON.parse(labels);
      }
    } catch (er) {
      // continue regardless of error
    }

    return parsedLabels?.map(item => item?.name);
  }
);

export const performanceObjectivesSelector = createSelector(commonSelector, state => state.performanceObjectives);

export const performancePlatformsSelector = createSelector(commonSelector, state => state.performancePlatforms);

export const performanceArtistsBreakdownsSelector = createSelector(
  commonSelector,
  state => state.performanceArtistsBreakdowns
);

export const performanceProjectsBreakdownsSelector = createSelector(
  commonSelector,
  state => state.performanceProjectsBreakdowns
);

export const performanceCampaignsBreakdownsSelector = createSelector(
  commonSelector,
  state => state.performanceCampaignsBreakdowns
);

export const performanceUnassignedCampaignsBreakdownsSelector = createSelector(
  commonSelector,
  state => state.performanceUnassignedCampaignsBreakdowns
);

export const isAssignmentModalOpenedSelector = createSelector(
  commonSelector,
  state => state.assignCampaignToProjectModal.isOpened
);

export const campaignForAssignmentSelector = createSelector(
  commonSelector,
  state => state.assignCampaignToProjectModal.campaign
);

export const isProjectsLoadingForAssignmentSelector = createSelector(
  commonSelector,
  state => state.assignCampaignToProjectModal.isLoading
);

export const searchedProjectsForAssignmentSelector = createSelector(
  commonSelector,
  state => state.assignCampaignToProjectModal.searchedProjects
);

export const recommendedProjectsForAssignmentSelector = createSelector(
  commonSelector,
  state => state.assignCampaignToProjectModal.recommendedProjects
);

export const toastsSelector = createSelector(commonSelector, state => state.toasts);

export const artistMomentTypesSelector = createSelector(commonSelector, state => state.artistMomentTypes);
export const artistMomentCategoriesSelector = createSelector(commonSelector, state => state.artistMomentCategories);
export const globalSearchSelector = createSelector(commonSelector, state => state.globalSearch);

export const isLinkfireLinksLoadingSelector = createSelector(commonSelector, state => state.isLinkfireLinksLoading);

export const linkfireLinksSelector = createSelector(commonSelector, state => state.linkfireLinks);

export const attachReleasesModalStateSelector = createSelector(commonSelector, state => state.attachReleasesModal);

export const productFamiliesStateSelector = createSelector(
  attachReleasesModalStateSelector,
  state => state.productFamiliesState
);
export const allProductFamiliesSelector = createSelector(
  attachReleasesModalStateSelector,
  state => state.allProductFamilies
);
export const pendingProductFamiliesSelector = createSelector(
  attachReleasesModalStateSelector,
  state => state.phase?.product_families
);
export const pendingPlaylistsSelector = createSelector(
  attachReleasesModalStateSelector,
  state => state.phase?.playlists
);

export const selectedProductFamiliesSelector = createSelector(
  attachReleasesModalStateSelector,
  state => state.productFamiliesState.selected
);

export const expandedProductFamiliesIdsSelector = createSelector(
  productFamiliesStateSelector,
  state => state.expandedProductFamiliesIds
);

export const playlistsSelector = createSelector(attachReleasesModalStateSelector, state => state.playlistsState);

export const selectedPlaylistsSelector = createSelector(
  attachReleasesModalStateSelector,
  state => state.playlistsState.selected
);

export const attachReleasesProjectSelector = createSelector(attachReleasesModalStateSelector, state => state.project);

export const isTeamModalOpenedSelector = createSelector(commonSelector, state => state.isTeamModalOpened);

export const currentProjectOwnerSelector = createSelector(commonSelector, state => state.currentProjectOwner);

export const accessRestrictedData = createSelector(commonSelector, state => state.accessRestrictedData);

export const errorSelector = createSelector(commonSelector, state => state.error);

export const dateFormatSelector = createSelector(commonSelector, state => state.settings.dateFormat);
export const dateTimeFormatSelector = createSelector(commonSelector, state => state.settings.dateTimeFormat);

export const campaignStatusesSelector = createSelector(commonSelector, state => state.campaignStatuses);
export const projectReleaseTypesSelector = createSelector(commonSelector, state => state.projectReleaseTypes);

export const globalLabelSelector = createSelector(commonSelector, state => state.globalLabel);
