import React from 'react';
import { SMediaPlanName } from './s-media-plan-name';

interface Props {
  mediaPlanName?: string;
}

export const MediaPlanName = React.memo(({ mediaPlanName }: Props) => (
  <SMediaPlanName data-selector="media-plan-name">{mediaPlanName}</SMediaPlanName>
));
