import styled from 'styled-components';

export const BEM_CLASS = 'moment-details';

export const SMomentDetails = styled.div`
  .${BEM_CLASS} {
    &__moment-main-info {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 80px;
      row-gap: 15px;
      width: 100%;
    }

    &__moment-links {
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > :not(:first-child) {
        margin-top: 5px;
      }
    }

    &__text {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }

    &__link {
      display: flex;
      align-items: center;
      margin-top: -10px;
      grid-column-start: 1;
    }

    &__last-modified-label {
      font-size: 12px;
      margin-top: 16px;
    }
  }
`;
