import React from 'react';
import { SAdSetsSection } from '../../../s-components/ad-sets/s-ad-sets-section';
import { Header } from './header';
import { Id } from 'backend-api/models';
import { AdSetsTable } from './ad-sets-table';
import { UUID } from 'io-ts-types/lib/UUID';

interface Props {
  projectId?: Id;
  campaignUuid: UUID;
}

export const AdSetsSection = React.memo(
  React.forwardRef<HTMLDivElement, Props>(({ projectId, campaignUuid }: Props, ref) => {
    return (
      <SAdSetsSection ref={ref}>
        <Header projectId={projectId} campaignUuid={campaignUuid} />
        <AdSetsTable projectId={projectId} campaignUuid={campaignUuid} />
      </SAdSetsSection>
    );
  })
);
