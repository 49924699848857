import { createSelector } from 'reselect';
import { State } from './reducer';

export const artistStateSelector = state => state.artist as State;

export const artistSelector = createSelector(artistStateSelector, state => state.artist);

export const filtersSelector = createSelector(artistStateSelector, state => state.filters);

export const projectsSelector = createSelector(artistStateSelector, state => state.projects);

export const unassignedProjectsSelector = createSelector(artistStateSelector, state => state.unassignedProjects);

export const metadataSelector = createSelector(artistStateSelector, state => state.metadata);

export const permissionsSelector = createSelector(artistStateSelector, state => state.permissions);

export const sharingModalSelector = createSelector(artistStateSelector, state => state.sharingModal);

export const selectedArtistTeamSelector = createSelector(artistStateSelector, state => state.selectedArtistTeam);

export const errorSelector = createSelector(artistStateSelector, state => state.error);
