import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'action-button';

interface Props {
  isHighlighted: boolean;
}

export const SActionButton = styled.button<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px;

  .${BEM_CLASS} {
    &__action-button {
      visibility: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      color: ${transparentize(0.2, manhattanThemeColors.primaryFillNew)};
      background-color: ${({ isHighlighted }) =>
        isHighlighted ? transparentize(0.2, manhattanThemeColors.background) : null};
    }
  }
`;
