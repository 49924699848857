import { Image } from '../common';

const getTargetImageDefaults = (): Image => ({
  width: 100,
  height: 100,
  url: 'url',
});

export const getTargetImageMock = (p?: Partial<Image>): Image => ({
  ...getTargetImageDefaults(),
  ...p,
});
