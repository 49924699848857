import * as t from 'io-ts';
import { ResponseModel, FormattedDateFromISOString } from '../common';

export const AvailablePeriodSchema = ResponseModel(
  {
    start_date: FormattedDateFromISOString,
    end_date: FormattedDateFromISOString,
  },
  'AvailablePeriod'
);

export type AvailablePeriod = t.TypeOf<typeof AvailablePeriodSchema>;
