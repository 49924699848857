import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { BaseRenderer } from '../base-renderer';
import { SingleValueView } from '../single-value-view';
import { SpreadsheetRowData } from '../../types';

export const CallToActionRenderer = ({
  context,
  value: { color, value },
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['callToAction']>) => (
  <BaseRenderer color={color} accessMode={context.accessMode} placeholder="Write here...">
    {value && <SingleValueView value={value} />}
  </BaseRenderer>
);
