import styled from 'styled-components';

export const SHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 21px;
  flex: 1;

  .ad-sets-header {
    &__title {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__input {
      width: 420px;
      margin-right: 20px;
    }
  }
`;
