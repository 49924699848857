import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';
import { COLUMN_ITEMS_COUNT } from './constants';

export const BEM_CLASS = 'artists-grid';

interface SArtistsGridProps {
  columnsCount: number;
}

export const SArtistsGrid = styled.div<SArtistsGridProps>`
  display: flex;
  flex-direction: column;

  .${BEM_CLASS} {
    &__header {
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
    }

    &__item {
      margin-top: 15px;
    }

    &__grid {
      margin-top: 5px;
      display: grid;
      grid-template-columns: repeat(${({ columnsCount }) => columnsCount}, 1fr);
      grid-template-rows: repeat(${COLUMN_ITEMS_COUNT}, auto);
      grid-auto-flow: column;
      column-gap: 15px;
    }

    &__empty {
      padding: 41px 0;
      align-self: center;
      color: ${colorPalette.fiordGrey};
    }
  }
`;
