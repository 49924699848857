import styled from 'styled-components';
import { modalHeader } from 'app/styles/z-indexes';

export const SModalHeader = styled.div`
  padding: 16px 20px 0;
  .header {
    &__close-button {
      z-index: ${modalHeader};
      padding: 0;
      align-self: flex-start;
      color: rgba(0, 0, 0, 0.45);
      font-weight: 700;
      line-height: 1;
      text-decoration: none;
      background: transparent;
      border: 0;
      outline: 0;
      cursor: pointer;
      transition: color 0.3s;
    }

    &__title {
      font-weight: 500;
      flex-grow: 1;
      align-self: center;
    }
  }
`;
