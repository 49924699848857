import styled from 'styled-components';

export const SApproversValueContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 3px 0;
  margin-right: 12px;
  margin-left: 5px;
  overflow-x: auto;
  overflow-y: hidden;
  /* this will hide the scrollbar in mozilla based browsers */
  overflow-x: -moz-scrollbars-none;
  scrollbar-width: none;

  /* this will hide the scrollbar in webkit based browsers - safari, chrome, etc */
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
`;
