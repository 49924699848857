import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectDetails } from 'backend-api/models';
import { getLockedArtist } from 'common-v2/transducers';
import { ShareModal } from 'project-v2/components';
import { getProjectSharingInfo, closeShareModal } from 'project-v2/actions';
import { sharingInfoSelector, shareModalSelector } from 'project-v2/selectors';
import { EditModeContainer } from './containers';
import { ViewMode } from './components';
import { useLocation } from 'react-router-dom';

interface ShareModalContainerProps {
  projectId: number;
  projectDetails: ProjectDetails;
  canEditProject: boolean;
}

export const ShareModalContainer = React.memo(
  ({ projectId, projectDetails, canEditProject }: ShareModalContainerProps) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const sharingInfo = useSelector(sharingInfoSelector);
    const shareModal = useSelector(shareModalSelector);

    const modalContainerRef = useRef<HTMLDivElement>(null);

    const closeHandler = useCallback(() => dispatch(closeShareModal()), [dispatch]);

    const fetchSharingInfo = useCallback(() => {
      dispatch(getProjectSharingInfo.request(projectId));
    }, [dispatch, projectId]);

    const renderBody = () => {
      const lockedArtist = getLockedArtist(projectDetails.targets);

      return canEditProject ? (
        <EditModeContainer
          projectId={projectId}
          projectDetails={projectDetails}
          flipBoundary={modalContainerRef.current}
          sharingInfo={sharingInfo}
          isConfidential={projectDetails.isConfidential}
          hasLockedArtist={Boolean(lockedArtist)}
          refreshSharingInfo={fetchSharingInfo}
        />
      ) : (
        <ViewMode
          sharingInfo={sharingInfo}
          isConfidential={projectDetails.isConfidential}
          hasLockedArtist={Boolean(lockedArtist)}
          refreshSharingInfo={fetchSharingInfo}
        />
      );
    };

    useEffect(() => {
      if (shareModal.isOpened) {
        fetchSharingInfo();
      }
    }, [fetchSharingInfo, shareModal.isOpened]);

    useEffect(() => {
      dispatch(closeShareModal());
    }, [dispatch, location]);

    return (
      <ShareModal
        ref={modalContainerRef}
        title={canEditProject ? 'Invite to Project' : 'View Project Team'}
        height={canEditProject ? 600 : 540}
        isOpened={shareModal.isOpened}
        sharingInfo={sharingInfo}
        renderBody={renderBody}
        onClose={closeHandler}
      />
    );
  }
);
