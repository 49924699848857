import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { SRecent, BEM_CLASS } from './s-recent';

interface RecentProps {
  recentNodes: React.ReactNode[];
  className?: string;
}

const classes = bem(BEM_CLASS);

export const Recent = React.memo(({ recentNodes, className }: RecentProps) => (
  <SRecent className={className}>
    <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('title')}>
      Recently Added
    </Typography>
    <div className={classes('list')}>{recentNodes}</div>
    <div className={classes('separator')} />
  </SRecent>
));
