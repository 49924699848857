import styled from 'styled-components';
import { CELL_HORIZONTAL_PADDING } from './constants';
import { MediaPlanAccessMode } from 'media-plan-v2/containers/spreadsheet/types';
import { theme } from './s-theme';

export const BEM_CLASS = 'spreadsheet';

interface SSpreadsheetProps {
  accessMode: MediaPlanAccessMode;
  shouldShowGroupCheckbox?: boolean;
}

export const SSpreadsheet = styled.div<SSpreadsheetProps>`
  --cell-horizontal-padding: ${CELL_HORIZONTAL_PADDING}px;

  ${theme};

  .${BEM_CLASS} {
    &__loader {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 700px;
    }
  }
`;
