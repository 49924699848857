import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TeamUser } from 'backend-api/models';
import { pendingAddedSelector, pendingDeletedSelector, pendingUpdatedSelector } from '../selectors';
import { ShareModal } from '../components';
import { Mode, SearchUser, ShareModalContext, ShareModalResponse } from '../types';
import { addInitialTeamMembers, addTeamUsers, clearAll, deleteTeamUser, resetModal, updateTeamUser } from '../actions';

interface Props {
  title: React.ReactChild;
  onClose(): void;
  onShare(teamMembers: ShareModalResponse): void;
  mode?: Mode;
  teamUsers: TeamUser[];
  searchUsers: SearchUser[];
  isOpened: boolean;
  isLoading: boolean;
  context: ShareModalContext;
  isConfidential?: boolean;
}

export const ShareModalContainer = ({
  title,
  onShare,
  isOpened,
  isLoading,
  onClose,
  mode = Mode.EDIT,
  teamUsers,
  searchUsers,
  isConfidential = false,
  context,
}: Props) => {
  const dispatch = useDispatch();

  const pendingAddedUsers = useSelector(pendingAddedSelector);
  const pendingDeletedUsers = useSelector(pendingDeletedSelector);
  const pendingUpdatedUsers = useSelector(pendingUpdatedSelector);

  const onShareHandler = useCallback(
    () =>
      onShare({
        added: pendingAddedUsers,
        updated: pendingUpdatedUsers,
        deleted: pendingDeletedUsers,
      }),
    [onShare, pendingAddedUsers, pendingUpdatedUsers, pendingDeletedUsers]
  );

  const onAddNewUser = useCallback((newUsers: TeamUser) => dispatch(addTeamUsers(newUsers)), [dispatch]);
  const onUpdateUser = useCallback((user: TeamUser) => dispatch(updateTeamUser(user)), [dispatch]);
  const onDeleteUser = useCallback((user: TeamUser) => dispatch(deleteTeamUser(user)), [dispatch]);
  const onClearAll = useCallback(() => dispatch(clearAll(teamUsers)), [dispatch, teamUsers]);

  useEffect(() => {
    if (isOpened && mode === Mode.CREATION) {
      dispatch(addInitialTeamMembers(teamUsers));
    }
  }, [dispatch, isOpened, mode, teamUsers]);

  useEffect(() => {
    if (!isOpened) {
      dispatch(resetModal());
    }
  }, [dispatch, isOpened]);

  return (
    <ShareModal
      context={context}
      mode={mode}
      title={title}
      isOpened={isOpened}
      isLoading={isLoading}
      teamUsers={teamUsers}
      searchUsers={searchUsers}
      pendingAddedUsers={pendingAddedUsers}
      pendingUpdatedUsers={pendingUpdatedUsers}
      pendingDeletedUsers={pendingDeletedUsers}
      onCloseShareWindow={onClose}
      onSave={onShareHandler}
      onAddUser={onAddNewUser}
      onUpdateUser={onUpdateUser}
      onDeleteUser={onDeleteUser}
      onClearAll={onClearAll}
      isConfidential={isConfidential}
    />
  );
};
