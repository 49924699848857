import styled from 'styled-components';
import { bgStyle, BgStyle } from 'common/styles/utils';

interface SModalContainerProps {
  readonly top: string;
  readonly transform: string;
  readonly width: string;
  readonly minWidth?: string;
  readonly bgStyle: BgStyle;
}

export const SModalContainer = styled.div<SModalContainerProps>`
  position: absolute;
  top: ${({ top }) => top};
  left: 50%;
  transform: ${({ transform }) => transform};
  width: ${({ width }) => width};
  padding-bottom: 24px;
  min-width: ${({ minWidth = 300 }) => minWidth}px;

  .container {
    position: relative;
    background: ${bgStyle};
    border-radius: 12px;
    min-width: ${({ minWidth }) => minWidth}px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
`;
