import { colorPalette } from 'app/theme/colors';

export const selectTheme = {
  background: colorPalette.white,
  option: {
    color: colorPalette.vulcan,
    selected: {
      background: colorPalette.alabaster,
    },
    active: {
      color: colorPalette.azureRadiance,
      background: colorPalette.lilyWhite,
    },
    disabled: {
      color: colorPalette.gray,
      background: colorPalette.white,
    },
  },
  focus: {
    borderColor: colorPalette.azureRadiance,
  },
};
