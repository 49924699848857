import { actionsGroup } from 'core/store';
import { ArtistProjectsResponse, Label, ArtistTeam, Id, LabelUser } from 'backend-api/models';
import {
  GetArtistPayload,
  GetArtistProjectsPayload,
  ProjectsFilters,
  ExportArtistProjectsPayload,
  ArtistInfo,
  CreateArtistTeamPayload,
} from './types';

const { action, asyncAction } = actionsGroup('artist');

export const getArtistInfo = asyncAction<GetArtistPayload, ArtistInfo, Error>('GET_ARTIST');

export const getProjects = asyncAction<GetArtistProjectsPayload, ArtistProjectsResponse, Error>('GET_PROJECTS');

export const getUnassignedProjects = asyncAction<GetArtistProjectsPayload, ArtistProjectsResponse, Error>(
  'GET_UNASSIGNED_PROJECTS'
);

export const exportProjects = asyncAction<ExportArtistProjectsPayload, unknown, Error>('EXPORT_PROJECTS');

export const updateFilters = action<ProjectsFilters>('UPDATE_FILTERS');

export const setPermissionsUnavailableLabels = action<Label[]>('SET_PERMISSIONS_UNAVAILABLE_LABELS');

export const showPermissionsNotification = action<void>('SHOW_PERMISSIONS_NOTIFICATION');

export const hidePermissionsNotification = action<void>('HIDE_PERMISSIONS_NOTIFICATION');

export const disablePermissionsNotification = action<void>('DISABLE_PERMISSIONS_NOTIFICATION');

export const showSharingModal = action<void>('SHOW_SHARING_MODAL');
export const hideSharingModal = action<void>('HIDE_SHARING_MODAL');

export const getArtistTeams = asyncAction<string, ArtistTeam[], Error>('GET_ARTIST_TEAMS');

export const getLabelUsers = asyncAction<Id, LabelUser[], Error>('GET_LABEL_USERS');

export const editArtistTeam = asyncAction<CreateArtistTeamPayload, ArtistTeam, Error>('CREATE_ARTIST_TEAM');

export const selectArtistTeam = action<ArtistTeam>('SELECT_ARTIST_TEAM');
