import * as t from 'io-ts';
import { FormattedDateFromISOString, IdType, NonEmptyString, Optional, ResponseModel } from '../../common';
import { LinkfireSearchSchema } from '../../linkfire';
import { CampaignStatusesSchema } from 'backend-api/models/campaign/index';

export const LinkfireLinksRequestSchema = ResponseModel(
  {
    id: t.string,
    url: t.string,
    deletable: Optional(t.boolean),
  },
  'LinkfireLinksRequest'
);

export type LinkfireLinksRequest = t.TypeOf<typeof LinkfireLinksRequestSchema>;

export const CampaignRequestSchema = ResponseModel(
  {
    name: NonEmptyString,
    territories: Optional(t.array(IdType)),
    budgetSpend: Optional(t.number),
    ecpm: Optional(t.number),
    plannedBudget: t.number,
    campaignType: Optional(IdType),
    platforms: t.array(IdType),
    gratis: Optional(t.boolean),
    startDate: FormattedDateFromISOString,
    endDate: FormattedDateFromISOString,
    objective: Optional(IdType),
    notes: Optional(t.string),
    genders: Optional(t.array(IdType)),
    provider: Optional(IdType),
    audienceLowerAge: t.number,
    audienceHigherAge: t.number,
    destinationLinks: Optional(t.array(t.string)),
    linkfireLinks: Optional(t.array(LinkfireSearchSchema)),
    workflowStatus: Optional(CampaignStatusesSchema),
    audienceNotes: Optional(t.string),
    adCreativeLinks: Optional(t.array(t.string)),
    adCreativeNotes: Optional(t.string),
    kpiMetricsFieldId: Optional(IdType),
    placements: Optional(t.array(IdType)),
  },
  'CampaignRequest'
);

export type CampaignRequest = t.TypeOf<typeof CampaignRequestSchema>;

export const ManageExternalCampaignsRequestSchema = ResponseModel({
  assignCampaignsUuids: Optional(t.array(t.string)),
  unassignCampaignsUuids: Optional(t.array(t.string)),
});

export type ManageExternalCampaignsRequest = t.TypeOf<typeof ManageExternalCampaignsRequestSchema>;

export const CampaignsRequestSchema = ResponseModel(
  {
    campaigns: t.array(CampaignRequestSchema),
  },
  'UpdateScheduleRequest'
);

export type CampaignsRequest = t.TypeOf<typeof CampaignsRequestSchema>;

export const GenerateCampaignNameParams = ResponseModel(
  {
    territories: Optional(t.array(IdType)),
    campaignType: Optional(IdType),
    platforms: Optional(t.array(IdType)),
    startDate: Optional(t.string),
    objective: Optional(IdType),
    provider: Optional(IdType),
    placements: Optional(t.array(IdType)),
  },
  'GenerateCampaignNameParams'
);

export type GenerateCampaignName = t.TypeOf<typeof GenerateCampaignNameParams>;

export const GenerateCampaignNameResponseSchema = ResponseModel(
  {
    name: t.string,
  },
  'GenerateCampaignNameResponse'
);

export type GenerateCampaignNameResponse = t.TypeOf<typeof GenerateCampaignNameResponseSchema>;
