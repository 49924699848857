import React from 'react';
import { SSearchContainer } from './s-search-container';
import { Input } from '../../../input';
import { callbackWithoutPropagation } from 'utils/event';

interface Props {
  onSearchQueryChange(value: string): void;
  value?: string;
  searchPlaceholder?: string;
  onMouseDown(event): void;
}

export const SearchContainer = ({ onSearchQueryChange, value, searchPlaceholder, onMouseDown }: Props) => {
  return (
    <SSearchContainer onClick={callbackWithoutPropagation()}>
      <Input
        autoFocus
        dataSelector="performance-search"
        bgStyle="white"
        iconLeft="search"
        clearable
        onChange={onSearchQueryChange}
        value={value}
        placeholder={searchPlaceholder}
        onMouseDown={onMouseDown}
      />
    </SSearchContainer>
  );
};
