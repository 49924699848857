import { Skeleton } from 'common/components/skeleton';
import React from 'react';

export const ItemLoader = React.memo(() => (
  <Skeleton width={210} height={40}>
    <circle cx="20" cy="20" r="20" />
    <rect x="60" y="0" rx="0" ry="0" width={120} height={15} />
    <rect x="60" y="20" rx="0" ry="0" width={150} height={15} />
  </Skeleton>
));
