import React, { useCallback } from 'react';
import { TreeList } from '../../components';
import { BaseOption as BaseOptionType, SelectMenuProps, SelectType, TreeSelectProps } from '../../types';
import { BaseSelect } from '../base-select';
import { STreeSelect } from './s-tree-select';

const Root = <T extends BaseOptionType>({
  classNames,
  selectComponents,
  ...props
}: TreeSelectProps<T> & SelectMenuProps) => {
  const selectClassNames = useCallback(
    (className: string) => ({
      ...classNames,

      menuPortal: `${className} ${classNames?.menuPortal}`,
    }),
    [classNames]
  );

  const components = useCallback(
    () => ({
      ...selectComponents?.({
        isMenuOpen: props.isMenuOpen,
        openMenu: props.openMenu,
        closeMenu: props.closeMenu,
      }),

      MenuList: TreeList,
    }),
    [props.closeMenu, props.isMenuOpen, props.openMenu, selectComponents]
  );

  return (
    <STreeSelect>
      {className => (
        <BaseSelect
          {...props}
          type={SelectType.Base}
          classNames={selectClassNames(className)}
          selectComponents={components}
        />
      )}
    </STreeSelect>
  );
};

export const TreeSelect = React.memo(Root) as typeof Root;
