import React from 'react';
import { Skeleton } from 'common-v2/components';

interface Props {
  className?: string;
}

export const TitleLoader = React.memo(({ className }: Props) => (
  <Skeleton className={className} width={400} height={25}>
    <rect x="0" y="0" rx="2px" ry="2px" width="400" height="25" />
  </Skeleton>
));
