import React from 'react';
import { SLinkViewContainer } from './s-link-view-container';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { Badge } from 'common/components/badge';
import { LinkSelectOption } from '../../types';

interface Props {
  link: LinkSelectOption;
  flat?: boolean;
  badge?: string;
  onLinkClick?(): void;
}

const classes = bem('link-view-container');

export const LinkViewContainer = React.memo(({ link, flat, badge, onLinkClick }: Props) => {
  return (
    <SLinkViewContainer
      rel="noopener noreferrer"
      target="_blank"
      href={link.link}
      onClick={onLinkClick}
      flat={flat}
      invalid={link.isInvalid}
    >
      <Icon name={link.icon || 'link'} size="general" className={classes('icon')} color="blue" />
      <span className={classes('link-text')}>{link.label}</span>
      {badge && <Badge className={classes('link-badge')} text={badge} />}
    </SLinkViewContainer>
  );
});
