import { manhattanThemeColors } from 'app/theme/colors';
import styled from 'styled-components';

export const BEM_CLASS = 'artist-ellipsis-icon';

export const SEllipsisIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .${BEM_CLASS} {
    &__icon {
      color: ${manhattanThemeColors.primaryFillNew};
    }
  }
`;
