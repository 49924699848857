import React, { useMemo } from 'react';
import { SSpreadsheetOverlay } from './s-spreadsheet-overlay';
import { ContentLoader } from 'common/components/loaders';
import { ILoadingOverlayParams } from 'ag-grid-community';
import { ErrorOverlay } from './components';

export enum OverlayType {
  ERROR,
  LOADING,
  NONE,
}

interface Props extends ILoadingOverlayParams {
  type: OverlayType;
}

export const SpreadsheetOverlay = React.memo(({ type }: Props) => {
  const overlayContent = useMemo(() => {
    switch (type) {
      case OverlayType.LOADING:
        return <ContentLoader />;
      case OverlayType.ERROR:
        return <ErrorOverlay />;
      case OverlayType.NONE:
        return null;
    }
  }, [type]);

  return <SSpreadsheetOverlay>{overlayContent}</SSpreadsheetOverlay>;
});
