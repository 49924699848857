import styled from 'styled-components';

export const BEM_CLASS = 'single-select-item';

interface Props {
  depth: number;
}
const ITEM_DEFAULT_MARGIN = 22;

export const SSingleSelectItem = styled.div<Props>`
  display: flex;
  margin-left: ${({ depth }) => `${depth * ITEM_DEFAULT_MARGIN}px`};

  &:hover {
    .${BEM_CLASS}__icon-container {
      visibility: visible;
    }
  }
  .${BEM_CLASS} {
    &__label {
      &--offset {
        margin-right: 20px;
      }
    }

    &__icon-container {
      visibility: hidden;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-left: auto;
    }

    &__expand-trigger {
      display: block;
      margin-right: 8px;
      align-self: center;
      padding: 5px 8px 8px;

      &--hidden {
        visibility: hidden;
      }
    }
  }
`;
