import * as t from 'io-ts';
import { FormattedDateFromISOString, ResponseModel } from '../common';

export const MetricsReportingSchema = t.intersection(
  [
    t.type({
      date: FormattedDateFromISOString,
    }),
    t.UnknownRecord,
  ],
  'MetricsReporting'
);

export type MetricsReporting = t.TypeOf<typeof MetricsReportingSchema>;

export const MetricsMetadataSchema = ResponseModel(
  {
    crossPlatformBlendedRate: t.number,
    spotifyBlendedRate: t.number,
    appleBlendedRate: t.number,
    amazonBlendedRate: t.number,
  },
  'MetricsMetadata'
);

export type MetricsMetadata = t.TypeOf<typeof MetricsMetadataSchema>;

export const MetricsReportingResponseSchema = ResponseModel(
  {
    items: t.array(MetricsReportingSchema),
    metadata: MetricsMetadataSchema,
  },
  'MetricsReportingResponse'
);

export type MetricsReportingResponse = t.TypeOf<typeof MetricsReportingResponseSchema>;
