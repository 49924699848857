import styled from 'styled-components';

export const BEM_CLASS = 'infinite-scroll';

export const SInfiniteScroll = styled.div`
  overscroll-behavior: contain;
  overflow-x: hidden;
  overflow-y: auto;

  .${BEM_CLASS} {
    &__loader {
      position: relative;
      width: 100%;
      height: 80px;
    }
  }
`;
