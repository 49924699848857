import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { BoldButton, ItalicButton, UnderlineButton, UnorderedListButton } from 'draft-js-buttons';
import { ContentState, EditorState, CompositeDecorator, Entity } from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createInlineToolbarPlugin from 'draft-js-inline-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import { SMarkdown } from '../../s-components/markdown-editor/s-markown-editor';
import { bem } from 'utils/bem';
import { isEmptyMarkdown } from '../../transducers';

export const EMPTY_MARKDOWN = '<p></p>';

const classes = bem('markdown');
const convertContentToHTML = content =>
  convertToHTML({
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'LINK') {
        return <a href={entity.data.url}>{originalText}</a>;
      }
      return originalText;
    },
  })(content);

const convertContentFromHTML = content =>
  convertFromHTML({
    htmlToEntity: (nodeName, node, createEntity) => {
      if (nodeName === 'a') {
        return createEntity('LINK', 'MUTABLE', {
          url: node.href,
        });
      }
    },
  })(content);

const inlineToolbarPlugin = createInlineToolbarPlugin({
  theme: {
    toolbarStyles: {
      toolbar: classes('toolbar'),
    },
    buttonStyles: {
      button: classes('button'),
      buttonWrapper: classes('button-wrapper'),
      active: classes('button-active'),
    },
    separatorStyles: {
      separator: classes('separator'),
    },
  },
});

const linkPlugin = createLinkPlugin({
  theme: {
    input: classes('link-input'),
    inputInvalid: classes('link-input-invalid'),
    link: classes('link'),
  },
});
const { InlineToolbar } = inlineToolbarPlugin;
const { LinkButton } = linkPlugin;

function findLinkEntities(contentBlock, callback) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return entityKey !== null && Entity.get(entityKey).getType() === 'LINK';
  }, callback);
}

const Link = props => {
  const { url } = Entity.get(props.entityKey).getData();
  return (
    <a className={classes('link')} href={url}>
      {props.children}
    </a>
  );
};

const decorators = [{ strategy: findLinkEntities, component: Link }];

const MarkdownEditor = React.forwardRef(
  ({ content, className, editable, onChange, maxLength, dataSelector, placeholder }, ref) => {
    const [isPlaceholder, setIsPlaceholder] = useState(isEmptyMarkdown(content));
    const mediumEditorRef = useRef(null);
    const getInitialState = () => {
      if (!editable) {
        return EditorState.createWithContent(convertContentFromHTML(content), new CompositeDecorator(decorators));
      }
      return EditorState.createWithContent(convertContentFromHTML(content));
    };
    const [editorState, setEditorState] = useState(editable ? null : getInitialState());

    useEffect(() => {
      const stateHtml = editorState && convertContentToHTML(editorState?.getCurrentContent());
      if (stateHtml !== content) {
        const state = getInitialState();
        setEditorState(state);
      }
    }, [content, editable]);

    const handleMaxLength = () => {
      const oldPlainText = editorState.getCurrentContent().getPlainText();
      const undoEditorState = EditorState.undo(
        EditorState.push(editorState, ContentState.createFromText(oldPlainText), 'delete-character'),
      );
      setEditorState(undoEditorState);
    };

    const onChangeEditor = newEditorState => {
      const plainText = newEditorState.getCurrentContent().getPlainText();
      const textNoLineBreaks = plainText.replace(/[\r\n]+/gm, '');

      if (textNoLineBreaks.length > maxLength) {
        handleMaxLength();
      } else {
        setEditorState(newEditorState);
        if (onChange) {
          onChange(plainText, convertContentToHTML(newEditorState.getCurrentContent()));
        }
      }
    };

    const [focused, setFocused] = useState(false);

    const focus = () => {
      if (!focused) {
        setEditorState(EditorState.moveFocusToEnd(editorState));
        setFocused(true);
      }
    };

    useEffect(() => {
      if (focused) {
        setIsPlaceholder(false);
      } else {
        setIsPlaceholder(isEmptyMarkdown(content));
      }
    }, [focused, content]);

    return (
      <SMarkdown
        isPlaceholder={isPlaceholder}
        ref={ref}
        onClick={focus}
        className={className}
        tabIndex="-1"
        bgStyle="white"
        data-selector={dataSelector}
      >
        {editorState && (
          <>
            <Editor
              data-selector="test1"
              ref={mediumEditorRef}
              editorState={editorState}
              onChange={onChangeEditor}
              readOnly={!editable}
              placeholder={isPlaceholder && placeholder}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              plugins={[inlineToolbarPlugin, linkPlugin]}
            />
            <InlineToolbar>
              {externalProps => (
                <>
                  <BoldButton {...externalProps} />
                  <ItalicButton {...externalProps} />
                  <UnderlineButton {...externalProps} />
                  <UnorderedListButton {...externalProps} />
                  <LinkButton {...externalProps} />
                </>
              )}
            </InlineToolbar>
          </>
        )}
      </SMarkdown>
    );
  },
);

MarkdownEditor.propTypes = {
  content: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  editable: PropTypes.bool,
};

MarkdownEditor.defaultProps = {
  content: '',
  placeholder: '',
  className: '',
  onChange: null,
  editable: true,
};

export default MarkdownEditor;
