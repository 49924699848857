import React from 'react';
import { bem } from 'utils/bem';
import { Input } from 'common/components/form/input';
import { SModalHeader } from './s-modal-header';
import { H3 } from 'common/components/typography';

interface Props {
  title?: string;
  hasSideBar: boolean;
  hasSearch: boolean;
  searchValue?: string;
  searchPlaceholder?: string;
  onSearchChange?(value: string): void;
  dataSelector?: string;
  className?: string;
}

const classes = bem('modal-header');

export const ModalHeader = React.memo(
  ({
    title,
    hasSideBar,
    hasSearch,
    searchValue,
    searchPlaceholder,
    onSearchChange,
    dataSelector,
    className,
  }: Props) => (
    <SModalHeader hasSideBar={hasSideBar} className={className}>
      {title && (
        <H3 data-selector={`${dataSelector}-title`} className={classes('title')}>
          {title}
        </H3>
      )}
      {hasSearch && onSearchChange && (
        <Input
          bgStyle="grey"
          iconLeft="search"
          clearable
          value={searchValue}
          placeholder={searchPlaceholder}
          onChange={onSearchChange}
          dataSelector={`${dataSelector}-search`}
          className={classes('search')}
        />
      )}
    </SModalHeader>
  )
);
