import styled, { css } from 'styled-components';

interface SContextMenuColorProps {
  color: string;
}

export const SContextMenuColor = styled.div<SContextMenuColorProps>`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid rgba(184, 188, 217, 1);
  background-color: ${({ color }) => color};

  ${({ color }) =>
    color === 'transparent'
      ? css`
          &::after {
            content: '';
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: 0;
            width: 24px;
            height: 1px;
            background-color: rgba(184, 188, 217, 1);
            transform-origin: 0 0;
            transform: rotate(-45deg);
          }
        `
      : ''};

  &:hover {
    border-color: rgba(77, 82, 128, 1);
  }
`;
