import { UUID } from 'io-ts-types/lib/UUID';
import { TargetType } from '../../models';
import { FreeTextParam } from '../search';
import { ShortUserInfoResponse } from '../user';

export * from './guards';

export const PROJECT_SORT_FIELDS = [
  'id',
  'project',
  'artistName',
  'playlistName',
  'earliestStartDate',
  'budget',
  'allocation',
  'userRoles',
] as const;

export type ProjectSortField = typeof PROJECT_SORT_FIELDS[number];

export interface ProjectFilterParams extends FreeTextParam {
  readonly statuses?: number[];
  readonly type?: TargetType;
  readonly earliestStartDate?: string;
  readonly latestEndDate?: string;
  readonly labels?: number[];
  readonly onlyMine?: boolean;
  readonly offset?: number;
  readonly limit?: number;
  readonly sort?: string;
  readonly onlyGras?: boolean;
  readonly artistLabels?: number[];
  readonly isClaimed?: boolean;
}

export interface AssignableProjectsParams {
  readonly campaignUuid: UUID;
  readonly query?: string;
}

export enum ProjectHistoryActionType {
  ADDED = 1,
  CHANGED = 2,
  CREATED = 3,
  EXTENDED = 4,
  MOVED = 5,
  REMOVED = 6,
  SHORTENED = 7,
  UPDATED = 8,
  REJECTED = 9,
  APPROVED = 10,
  CLAIMED = 11,
}

export interface ProjectHistoryParams {
  readonly actionType?: ProjectHistoryActionType;
  readonly query?: string;
  readonly offset?: number;
  readonly limit?: number;
}

export interface MediaPlanExportParams {
  readonly requiredFields: number[];
  readonly phaseId?: number;
}

export interface RecentlyViewedProjectsParams {
  readonly label?: number;
}

export interface NewProjectsParams {
  readonly label?: number;
}

export interface ProjectDetailsErrorResponse {
  projectName: string;
  projectEditors: ShortUserInfoResponse[];
}
