import React from 'react';
import { SMultiSelectItem } from './s-multi-select-item';
import { Checkbox } from '../../../checkbox';
import { bem } from 'utils/bem';
import { Icon } from '../../../icon';
import { BEM_CLASS } from 'common/components/form/select';

interface Props {
  isChecked?: boolean;
  isHalfChecked?: boolean;
  hasCheckbox?: boolean;
  isDisabled?: boolean;
  isExpanded?: boolean;
  isLeaf?: boolean;
  depth?: number;
  label: string;
  onClick?(checked: boolean): void;
  onExpand?(): void;
  dataSelector?: string;
}

const classes = bem('multi-select-item');

export const MultiSelectItem = React.memo(
  ({
    isChecked = false,
    isHalfChecked = false,
    isLeaf = false,
    isDisabled,
    label,
    depth = 1,
    onClick,
    isExpanded,
    onExpand,
    dataSelector,
  }: Props) => {
    const commonDataSelector = `${dataSelector}-${label.toLowerCase().replace(/ /g, '-')}`;
    const itemDataSelector = `${commonDataSelector}${isChecked ? '-is-checked' : ''}${
      isHalfChecked ? '-is-half-checked' : ''
    }`;
    return (
      <SMultiSelectItem depth={depth} data-selector={itemDataSelector} className={`${BEM_CLASS}__multi-item`}>
        <Icon
          className={classes('expand-trigger', { hidden: isLeaf })}
          onClick={onExpand}
          name={isExpanded ? 'up' : 'down'}
        />
        <Checkbox
          className={classes('checkbox')}
          isChecked={isChecked}
          halfChecked={isHalfChecked}
          isDisabled={isDisabled}
          label={label}
          onClick={onClick}
          labelClassName={classes('label')}
          dataSelector={`${dataSelector}-checkbox`}
        />
      </SMultiSelectItem>
    );
  }
);
