import { getFormattedDate, parseDateFromISOString } from 'common-v2/utils';
import { REQUEST_DATE_FORMAT } from 'common/constants';

export const filterDatesFormatter = (value: string | Date) => {
  const formatDate = getFormattedDate(REQUEST_DATE_FORMAT);

  if (typeof value === 'string') {
    value = parseDateFromISOString(value);
  }

  return formatDate(value);
};
