import styled from 'styled-components';

export const BEM_CLASS = 'artist-roster';

export const SArtistRoster = styled.div`
  .${BEM_CLASS} {
    &__upcoming-section {
      margin-top: 35px;
    }

    &__inactive-section {
      margin-top: 30px;
    }

    &__error {
      margin: auto;
      height: 400px;
    }
  }
`;
