import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'profile-info';

export const SProfileInfo = styled.div`
  .${BEM_CLASS} {
    &__title {
      margin-bottom: 20px;
    }
    &__card {
      border-radius: 10px;
      padding: 30px;
      background-color: white;
      display: grid;
      height: 200px;
      grid-template-columns: 140px 1fr;
      column-gap: 30px;
      grid-template-rows: 1fr 1fr 2fr;
    }
    &__avatar {
      grid-row: 1 / span 3;
      background: ${colorPalette.ghostWhite};
      border-radius: 100%;
    }
    &__name {
      ${elipsizeText};
      align-self: center;
    }
    &__contact-info-container {
      display: flex;
      align-items: center;
    }
    &__dot {
      height: 6px;
      width: 6px;
      background-color: ${colorPalette.lavenderGray};
      border-radius: 6px;
      margin: 0 13px;
    }
    &__phone-input {
      .input__input {
        color: ${colorPalette.vulcan};
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
      }
    }
  }
`;
