import React, { useMemo } from 'react';
import { Label } from 'backend-api/models';
import { BEM_CLASS, SLabelsList } from './s-labels-list';
import { bem } from 'utils/bem';
import { Tooltip } from 'common/components/tooltip';
import { getJoinedNames } from 'common/transducers';
import { convertToLowerKebabCase } from 'utils/string';
import { TooltipedText } from 'common/components/tooltiped-text';

interface Props {
  labels: Label[];
  isAdmin?: boolean;
}

const classes = bem(BEM_CLASS);

const MAX_LABEL_COUNT = 6;

export const LabelsList = React.memo(({ labels, isAdmin }: Props) => {
  const labelsTooltipTitle = useMemo(() => getJoinedNames(labels.slice(MAX_LABEL_COUNT, labels.length)), [labels]);
  const shownLabels = useMemo(() => labels.slice(0, MAX_LABEL_COUNT), [labels]);
  return (
    <SLabelsList>
      {isAdmin && (
        <div className={classes('label-item', { admin: true })} key="admin" data-selector="admin-role-item">
          Admin
        </div>
      )}
      {shownLabels.map(label => (
        <TooltipedText key={label.id} text={label.name}>
          <div className={classes('label-item')} data-selector={`${convertToLowerKebabCase(label.name)}-label-item`}>
            {label.name}
          </div>
        </TooltipedText>
      ))}
      {labels.length > MAX_LABEL_COUNT && (
        <Tooltip content={labelsTooltipTitle}>
          <div className={classes('label-item')} key="other-labels" data-selector="other-labels">
            +{labels.length - MAX_LABEL_COUNT} labels
          </div>
        </Tooltip>
      )}
    </SLabelsList>
  );
});
