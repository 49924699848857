import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Nullable, Id, IdNameField } from 'backend-api/models';
import { bem } from 'utils/bem';
import {
  MultiSelect,
  MultiSelectType,
  MULTI_SELECT_MAX_COUNT_CLEARABLE,
  SelectClassNamesProps,
} from 'common-v2/components';
import { useSelectEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan-v2/components/spreadsheet/hooks';
import { ExpandableField } from 'media-plan-v2/components/spreadsheet/components';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { BEM_CLASS, SGendersEditor } from './s-genders-editor';

const classes = bem(BEM_CLASS);

export const GendersEditor = React.memo(
  React.forwardRef((props: ICellEditorParams<SpreadsheetRowData, SpreadsheetRowData['genders']>, ref) => {
    const { onBlur } = useStopEditingBlur(props.api);
    const genders = useMemo(() => props.colDef?.cellEditorParams.genders, [props.colDef?.cellEditorParams.genders]);
    const options = useMemo(() => genders.map(gender => ({ id: gender.id, value: gender.name })), [genders]);

    const [value, setValue] = useState(props.value.value);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return {
            ...props.value,
            value,
          };
        },
      };
    });

    const handleChange = useCallback(
      (ids: Id[]) => {
        const selectedGenders = genders.filter(gender => ids.includes(gender.id)) || [];
        setValue(selectedGenders);
      },
      [genders]
    );

    const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions<true, Nullable<IdNameField[]>>({
      api: props.api,
      value,
      isMulti: true,
      setValue: handleChange,
    });

    const selectClassNames = useCallback<(className: string) => SelectClassNamesProps>(
      className => ({
        control: classes('control'),
        valueContainer: {
          root: classes('value-root'),
          container: classes('value-container'),
          footer: classes('value-footer'),
        },
        menu: classes('menu'),
        indicatorsContainer: classes('indicators-container'),
        menuPortal: className,
      }),
      []
    );

    useEffect(() => {
      selectRef.current?.inputRef?.focus({ preventScroll: true });
    }, [selectRef]);

    return (
      <ExpandableField hasError={false}>
        <SGendersEditor width={props.column.getActualWidth()} height={props.node.rowHeight || 0}>
          {className => (
            <div className={className}>
              <MultiSelect
                selectRef={selectRef}
                type={MultiSelectType.AutoComplete}
                dataSelector="gender-editor"
                valueIds={value?.map(value => value.id) || []}
                valueHandler={handleChange}
                options={options}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                isSearchable
                placeholder=""
                classNames={selectClassNames(className)}
                isClearable={!!value && value.length >= MULTI_SELECT_MAX_COUNT_CLEARABLE}
                isOpened
              />
            </div>
          )}
        </SGendersEditor>
      </ExpandableField>
    );
  })
);
