import { manhattanThemeColors } from 'app/theme/colors';
import { Id, LabelUser } from 'backend-api/models';
import { Avatar } from 'common-v2/components';
import {
  Typography,
  TYPOGRAPHY_TYPE,
  Button,
  BUTTON_SIZE,
  BUTTON_TYPE,
  THEME,
  Chip,
  CHIP_TYPE,
} from 'gdb-web-shared-components';
import { transparentize } from 'polished';
import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { SMemberItem, BEM_CLASS } from './s-member-item';

interface MemberItemProps {
  user: LabelUser;
  isDisabled?: boolean;
  onDelete?(id: Id): void;
  onMemberClick?(user: LabelUser): void;
  showEmail?: boolean;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const MemberItem = React.memo(
  ({ user, isDisabled, onDelete, onMemberClick, showEmail = false, className }: MemberItemProps) => {
    const onDeleteClick = useCallback(() => {
      onDelete?.(user.id);
    }, [onDelete, user.id]);

    const onClick = useCallback(() => {
      onMemberClick?.(user);
    }, [onMemberClick, user]);

    const avatarColor = transparentize(user.isAdmin ? 0.6 : 0.9, manhattanThemeColors.hoveredBorderColor);

    return (
      <SMemberItem className={className} onClick={onClick} data-selector="edit-modal-member-item">
        <div className={classes('container')}>
          <Avatar name={user.name} className={classes('avatar')} avatarColor={avatarColor} />
          <div className={classes('name-and-email')}>
            <div>
              <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('name')}>
                {user.name}
              </Typography>
              {user.isAdmin && (
                <Chip theme={THEME.light} label="Admin" type={CHIP_TYPE.compact} className={classes('chip')} />
              )}
            </div>

            {showEmail && (
              <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('email')}>
                {user.email}
              </Typography>
            )}
          </div>
        </div>
        {onDelete && !isDisabled && (
          <Button
            size={BUTTON_SIZE.smallRound}
            icon="delete"
            type={BUTTON_TYPE.tertiary}
            theme={THEME.light}
            className={classes('delete')}
            onClick={onDeleteClick}
            data-selector="member-item-delete-button"
          />
        )}
      </SMemberItem>
    );
  }
);
