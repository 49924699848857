import { ofType } from 'redux-observable';
import { catchError, debounceTime, ignoreElements, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { from, interval, of, Subject } from 'rxjs';
import * as API from './api';
import { ACTION, TYPE } from './reducer';
import { flush, getLabels, getUserInfo } from 'common/actions';
import { navigateTo, replaceTo } from 'utils/navigation';
import { showErrorToast } from 'common/components/toast';
import { auth0Lock } from './auth0';
import { DEBOUNCE_TIME_SHORT } from 'common/constants';
import { paths } from 'app/routing/paths';
import { AnalyticsEvents, trackEvent } from 'common/utils/analytic';

export const loginEpic = action$ =>
  action$.pipe(
    ofType(TYPE.LOGIN),
    switchMap(({ payload }) => {
      return API.login(payload).pipe(ignoreElements());
    }),
  );

const lockSubject = () => {
  const onEvent$ = new Subject();
  auth0Lock.on('authenticated', authResult => {
    onEvent$.next(authResult);
  });
  return onEvent$;
};

const checkSessionSubject = () => {
  const onEvent$ = new Subject();
  auth0Lock.checkSession({}, (err, authResult) => {
    if (err) {
      return onEvent$.error(err);
    }
    onEvent$.next(authResult);
  });

  return onEvent$;
};

export const lockEpic = () =>
  lockSubject().pipe(
    tap(() => replaceTo(paths.projects())),
    mergeMap((...res) => [flush(), ACTION.setToken(...res), ACTION.loginSuccess(...res)]),
    catchError(() => {
      return of(null).pipe(ignoreElements());
    }),
  );

export const loginSuccessEpic = action$ => {
  return action$.pipe(
    ofType(TYPE.LOGIN_SUCCESS),
    switchMap(() => {
      return from(new Promise(resolve => resolve(trackEvent(AnalyticsEvents.NEW_SESSION)))).pipe(
        mergeMap(() => [getUserInfo.request(), getLabels.request()]),
      );
    }),
  );
};

export const startPollingSessionEpic = action$ =>
  action$.pipe(
    ofType(TYPE.START_POLLING_SESSION),
    switchMap(() => {
      return interval(1000 * 20).pipe(
        map(() => ACTION.checkSession()),
        takeUntil(action$.pipe(ofType(TYPE.STOP_POLLING_SESSION))),
      );
    }),
  );

export const checkSessionEpic = (action$, state$) =>
  action$.pipe(
    ofType(TYPE.CHECK_SESSION),
    switchMap(({ payload: redirectParams }) => {
      const {
        value: {
          auth: { checkingTime },
        },
      } = state$;
      if (!checkingTime) {
        return of(
          ACTION.checkSessionError({
            silent: true,
          }),
        );
      }
      return API.checkSession(checkingTime).pipe(
        map(payload =>
          ACTION.checkSessionSuccess({
            ...redirectParams,
            ...payload,
          }),
        ),
        catchError(err => {
          return of(ACTION.checkSessionError(err));
        }),
      );
    }),
  );

export const checkSessionEpicSuccess = action$ =>
  action$.pipe(
    ofType(TYPE.CHECK_SESSION_SUCCESS),
    switchMap(({ payload = {} }) => {
      const { need_authorize } = payload;
      if (need_authorize) {
        return of(ACTION.checkSessionError(payload));
      }
      return of(null).pipe(ignoreElements());
    }),
  );

export const checkSessionEpicError = action$ =>
  action$.pipe(
    ofType(TYPE.CHECK_SESSION_ERROR),
    switchMap(({ payload = {} }) => {
      const { backurl, params, silent = false } = payload;
      return checkSessionSubject().pipe(
        map(userInfo => ACTION.setToken(userInfo, { backurl, params })),
        tap(() => API.hideLoginForm()),
        catchError(() => {
          if (!silent) {
            showErrorToast('You have been logged out on another service');
          }
          return of(ACTION.unauthorized());
        }),
      );
    }),
  );

export const logoutEpic = action$ =>
  action$.pipe(
    ofType(TYPE.LOGOUT),
    switchMap(() => {
      return API.logout().pipe(
        tap(() => {
          trackEvent(AnalyticsEvents.SIGN_OUT);
        }),
      );
    }),
  );

export const unauthorizedEpic = action$ =>
  action$.pipe(
    ofType(TYPE.UNAUTHORIZED),
    debounceTime(DEBOUNCE_TIME_SHORT),
    switchMap(() => {
      return of(null).pipe(
        tap(() => navigateTo('/login')),
        mergeMap(() => [ACTION.stopPollingSession()]),
      );
    }),
  );

//†epic
