import { createAction, createAsyncAction, createReducer, PayloadAction, PayloadActionCreator } from 'typesafe-actions';
import { Reducer } from 'redux';

export const asyncAction = <RP extends unknown, SP extends unknown, FP extends unknown>(type: string) => {
  return createAsyncAction(`${type}_REQUEST`, `${type}_SUCCESS`, `${type}_FAILURE`)<RP, SP, FP>();
};

export const actionsGroup = (group: string) => {
  return {
    asyncAction: <RP extends unknown, SP extends unknown, FP extends unknown>(type: string) => {
      return asyncAction<RP, SP, FP>(`${group}/${type}`);
    },
    action: <P extends unknown>(type: string) => {
      return createAction(`${group}/${type}`)<P>();
    },
  };
};

type ActionReducer<S, A> = (state: S, action: A) => S;
type ActionHandler<S> = {
  actionType: PayloadActionCreator<string, any>;
  reducer: ActionReducer<S, PayloadAction<string, any>>;
};

export const onAction = <S, P>(
  actionType: PayloadActionCreator<string, P>,
  reducer: ActionReducer<S, PayloadAction<string, P>>
): ActionHandler<S> => {
  return {
    actionType,
    reducer,
  };
};

export const createTypedReducer = <S>(initialState: S, ...handlers: ActionHandler<S>[]): Reducer<S> => {
  let reducer = createReducer<S, any>(initialState);

  handlers.forEach(handler => {
    reducer = reducer.handleAction(handler.actionType, handler.reducer) as any;
  });

  return reducer;
};
