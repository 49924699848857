import { colorPalette } from 'app/theme/colors';
import { LiveArtistCardStates } from 'common/types';
import styled from 'styled-components';

export const BEM_CLASS = 'more-artists-card';

interface SMoreArtistsCardProps {
  cardState: LiveArtistCardStates;
}

export const SMoreArtistsCard = styled.button<SMoreArtistsCardProps>`
  position: relative;
  aspect-ratio: 1;

  .${BEM_CLASS} {
    &__targets-images {
      height: 100%;
      width: 100%;
      border-radius: 10px;
      opacity: ${({ cardState }) => (cardState === LiveArtistCardStates.INACTIVE ? 0.4 : 1)};
    }

    &__plus-artists {
      position: absolute;
      font-size: 40px;
      font-weight: bold;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: ${colorPalette.white};
    }
  }
`;
