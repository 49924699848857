import styled from 'styled-components';

export const BEM_CLASS = 'outdated-global-layout';

export const SOutdatedGlobalLayout = styled.div`
  .${BEM_CLASS} {
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: calc(100vh - 50px);
      position: relative;
    }

    &__top-holder {
      height: 50px;
    }
  }
`;
