import React, { useMemo } from 'react';
import { SSelectedSection } from './s-selected-section';
import { bem } from 'utils/bem';
import { MultipleListsDragNDrop } from 'common/components/multiple-lists-drag-n-drop';
import { SelectedSectionHeader, StaticSection } from './components';
import { getDragNDropConfigFromProps } from './transducers';
import { DraggableProps, SectionConfig, StaticProps } from './types';
import { isDraggableProps } from './guards';

export type Props<T extends SectionConfig<any>[]> = DraggableProps<T> | StaticProps<T>;

const classes = bem('selected-section');

export const SelectedSection = React.memo(<T extends SectionConfig<any>[]>(props: Props<T>) => {
  const { title, resetTitle = 'Clear All', onResetClick, disabled = false, className, placeholder, isLoading } = props;

  //Do not need to watch for all props here, just these ones
  const dragNDropProps = useMemo(
    () => (isDraggableProps(props) ? getDragNDropConfigFromProps(props.sectionsConfig, disabled) : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.dragEnabled, props.sectionsConfig, disabled]
  );

  const isAllSectionsEmpty = props.sectionsConfig.flatMap(config => config.selectedItems).length === 0;

  const isPlaceholderVisible = isAllSectionsEmpty && !isLoading;

  return (
    <SSelectedSection className={className} disabled={disabled}>
      {isPlaceholderVisible ? (
        <div className={classes('placeholder-container')}>
          <div className={classes('placeholder')}>{placeholder}</div>
        </div>
      ) : (
        <>
          <SelectedSectionHeader
            title={title}
            disabled={disabled}
            resetTitle={resetTitle}
            onResetClick={onResetClick}
          />
          <div className={classes('scrolling-container')}>
            {isDraggableProps(props) ? (
              <MultipleListsDragNDrop
                listProps={dragNDropProps}
                onDragEnd={props.onDragEnd}
                onDragStart={props.onDragStart}
                onDragUpdate={props.onDragUpdate}
                onBeforeCapture={props.onBeforeCapture}
                onBeforeDragStart={props.onBeforeDragStart}
                shouldUseClones
                isLoading={isLoading}
              />
            ) : (
              <div className={classes('static-sections')} data-selector="selected-target-items-section">
                {props.sectionsConfig.map(config => (
                  <StaticSection key={config.id} config={config} />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </SSelectedSection>
  );
});
