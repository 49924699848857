import styled from 'styled-components';

export const BEM_CLASS = 'searchable-select';

export const SSearchableSelect = styled(({ className, children }) => children(className))`
  .${BEM_CLASS} {
    &__menu {
      min-width: 300px;
    }
  }
`;
