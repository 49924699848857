import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { SBudgetActionRenderer, SCurrencyRenderer, SPlaceholder } from './s-currency-renderer';
import { getCurrencyFormatted } from 'utils/format';
import { CURRENCY_CODE_TO_SIGN, CURRENCY_FORMATS } from 'common/constants';
import { colorPalette } from 'app/theme/colors';
import { BaseRenderer } from 'media-plan/components/spreadsheet/components/base-renderer';
import { SpreadsheetRowData } from 'media-plan/components/spreadsheet/types';

interface Props
  extends ICellRendererParams<
    SpreadsheetRowData,
    SpreadsheetRowData['actualSpent'] | SpreadsheetRowData['eCpm'] | SpreadsheetRowData['budget']
  > {
  currencyCode?: string;
  currencyFormat?: string;
  isDisabled?: boolean;
}

export const CurrencyRenderer = React.forwardRef(
  ({
    context,
    isDisabled,
    value: { color, value },
    currencyCode,
    currencyFormat = CURRENCY_FORMATS.default,
  }: Props) => {
    const currencyFormatted = useMemo(
      () => (value == null ? value : getCurrencyFormatted(value, currencyCode, false, currencyFormat)),
      [currencyCode, currencyFormat, value]
    );

    const content = useMemo(
      () => (currencyFormatted ? <SCurrencyRenderer>{currencyFormatted}</SCurrencyRenderer> : null),
      [currencyFormatted]
    );

    return value == null ? (
      <SBudgetActionRenderer
        color={color}
        accessMode={context.accessMode}
        isDisabled={isDisabled}
        icon={
          <SPlaceholder color={colorPalette.wildBlueYonder}>
            {currencyCode && CURRENCY_CODE_TO_SIGN[currencyCode]}
          </SPlaceholder>
        }
      >
        {content}
      </SBudgetActionRenderer>
    ) : (
      <BaseRenderer color={color} accessMode={context.accessMode}>
        {content}
      </BaseRenderer>
    );
  }
);
