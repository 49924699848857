import React from 'react';
import { NoticeProps } from 'react-select';
import { bem } from 'utils/bem';
import NoResultSvg from 'assets/no-results-gray.svg';
import { SmallText } from 'common/components/typography';
import { BaseOption as BaseOptionType } from '../../types';
import { BEM_CLASS, SEmptySearch } from './s-empty-search';

type Props<T extends BaseOptionType, IsMulti extends boolean> = NoticeProps<T, IsMulti>;

const classes = bem(BEM_CLASS);

export const Root = <T extends BaseOptionType, IsMulti extends boolean>({ innerProps }: Props<T, IsMulti>) => (
  <SEmptySearch {...innerProps} ref={null}>
    <img alt="empty-state" src={NoResultSvg} className={classes('image')} />
    <SmallText>
      No results to show.
      <br /> Try another search.
    </SmallText>
  </SEmptySearch>
);

export const EmptySearch = React.memo(Root) as typeof Root;
