import styled from 'styled-components';

export const SNotesModal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 20px;
  max-height: 585px;
  overflow-y: scroll;

  .notes-modal {
    &__title-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex: 0 0 100%;
    }

    &__close-icon {
      cursor: pointer;
    }

    &__title {
      flex: 1;
      color: black;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 25px;
      margin-bottom: 19px;
    }

    &__editor {
      overflow-y: scroll;
      white-space: nowrap;
      margin-top: 25px;
      width: 100%;
    }
  }
`;
