import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'sidebar-placeholder';

export const SMediaPlanSidebar = styled.div`
  width: 380px;
  flex-shrink: 0;
  border-radius: 0 10px 10px 0;
  background: linear-gradient(
    180deg,
    rgba(36, 40, 62, 0.85) 0%,
    rgba(36, 40, 62, 0.85) 26.78%,
    rgba(36, 40, 62, 1) 100%,
    rgba(36, 40, 62, 1) 100%
  );

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .${BEM_CLASS} {
    &__title {
      color: ${colorPalette.white};
    }
    &__button {
      margin-top: 14px;
    }
    &__subtitle {
      color: ${colorPalette.white};
      margin-top: 9px;
      text-align: center;
    }
  }
`;
