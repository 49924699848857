import { createSelector } from 'reselect';
import { ProjectState } from './reducer';

export const projectSelector = state => state.project as ProjectState;

export const activeProjectSelector = createSelector(projectSelector, state => state.activeProject);

export const isLoadingSelector = createSelector(projectSelector, state => state.isLoading);

export const isConfirmSelector = createSelector(projectSelector, state => state.confirmModal?.isOpened);

export const errorSelector = createSelector(projectSelector, state => state.error);

export const processingSelector = createSelector(projectSelector, state => state.isProcessing);

export const isShareWindowOpenedSelector = createSelector(projectSelector, state => state.isShareWindowOpened);

export const projectUsersSelector = createSelector(projectSelector, state => state.projectUsers);

export const teamSelector = createSelector(projectSelector, state => state.team);

export const shareProgressSelector = createSelector(projectSelector, state => state.isShareLoading);

export const projectHistorySelector = createSelector(projectSelector, state => state.projectHistory);

export const projectMarketingMixSelector = createSelector(projectSelector, state => state.projectMarketingMix);

export const projectTeamCountersSelector = createSelector(projectSelector, state => state.teamCounters);

export const projectNotesHistorySelector = createSelector(projectSelector, state => state.notesHistory);

export const isArtistModalOpenedSelector = createSelector(projectSelector, state => state.artistModal.isOpened);

export const isArtistModalLoadingSelector = createSelector(projectSelector, state => state.artistModal.isLoading);

export const artistsSelector = createSelector(projectSelector, state => state.artistModal.artists);

export const playlistsSelector = createSelector(projectSelector, state => state.artistModal.playlists);

export const selectedArtistsSelector = createSelector(projectSelector, state => state.artistModal.selectedArtists);

export const selectedPlaylistsSelector = createSelector(projectSelector, state => state.artistModal.selectedPlaylists);

export const isArtistModalChangedSelector = createSelector(projectSelector, state => state.artistModal.isChanged);

export const isDeleteModalShownSelector = createSelector(projectSelector, state => state.isDeleteModalShown);

export const isShowAllTargetsModalShownSelector = createSelector(
  projectSelector,
  state => state.isShowAllTargetsModalShown
);

export const phasesSelector = createSelector(projectSelector, state => state.phases);

export const budgetGroupsSelector = createSelector(projectSelector, state => state.budgetGroups);

export const funnelGraphSelector = createSelector(projectSelector, state => state.funnelGraph);

export const unassignedProjectCampaignsSelector = createSelector(
  projectSelector,
  state => state.unassignedProjectCampaigns
);

export const artistTeamSelector = createSelector(projectSelector, state => state.artistTeam);
