import React from 'react';
import { bem } from 'utils/bem';
import NoResultsGray from 'assets/no-results-gray.svg';
import { SNoResults } from '../s-components/s-no-results';

interface Props {
  withFilters?: boolean;
  withFiltersMessage?: React.ReactChild;
  withoutFiltersMessage?: React.ReactChild;
  message?: React.ReactChild;
  withoutFiltersImage?: string;
  withFiltersImage?: string;
  className?: string;
  dataSelector?: string;
}

const classes = bem('no-results');

export const NoResults = React.memo(
  ({
    withFilters,
    withFiltersMessage = 'No results to show. Try another criteria',
    withoutFiltersMessage = 'No data available.',
    message = '',
    withoutFiltersImage = NoResultsGray,
    withFiltersImage = NoResultsGray,
    className,
    dataSelector,
  }: Props) => (
    <SNoResults className={className}>
      {withFilters ? <img alt="" src={withFiltersImage} /> : <img alt="" src={withoutFiltersImage} />}

      <div className={classes('message')}>
        <div>{message}</div>
        {withFilters ? (
          <div data-selector="no-result-after-search">{withFiltersMessage}</div>
        ) : (
          <div data-selector={dataSelector}>{withoutFiltersMessage}</div>
        )}
      </div>
    </SNoResults>
  )
);
