import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { noop } from 'lodash';
import { Icon } from 'common/components/icon';
import { BaseOption as BaseOptionType } from '../../../../types';
import { SSingleOption, BEM_CLASS } from './s-single-option';

export interface SingleOptionProps<T extends BaseOptionType> {
  data: T;
  onClick(option: T): void;
  onIconClick?(): void;
  deepness: number;

  isSelected: boolean;
  isDisabled: boolean;
  isExpanded?: boolean;
}

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType>({
  data,
  onClick,
  onIconClick,
  deepness,
  isSelected,
  isDisabled,
  isExpanded = false,
}: SingleOptionProps<T>) => {
  const handleClick = useCallback(() => {
    onClick(data);
  }, [data, onClick]);

  return (
    <SSingleOption deepness={deepness}>
      {onIconClick && (
        <div className={classes('icon', { isExpanded })} onClick={onIconClick}>
          <Icon name="down" />
        </div>
      )}
      <div className={classes('value', { isSelected, isDisabled })} onClick={isDisabled ? noop : handleClick}>
        {data.value}
      </div>
    </SSingleOption>
  );
};

export const SingleOption = React.memo(Root) as typeof Root;
