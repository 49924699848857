import styled from 'styled-components';
import { uniqueId } from 'lodash';
import { animatedFlare } from 'common/s-components/s-animated-flare';

export const BEM_CLASS = 'recent-search-loader';
export const CLIP_PATH_ID = uniqueId('recent-search-loader');

export const SRecentSearchLoader = styled.div`
  width: 210px;
  height: 210px;
  overflow: hidden;

  .${BEM_CLASS} {
    &__container {
      width: 100%;
      height: 100%;
      clip-path: url(#${CLIP_PATH_ID});
      -webkit-clip-path: url(#${CLIP_PATH_ID});
      ${animatedFlare}
    }

    &__svg {
      width: 100%;
      height: 100%;
    }
  }
`;
