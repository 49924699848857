import React from 'react';
import { bem } from 'utils/bem';
import { SBadge } from './s-badge';
import { BadgeType } from './types';

interface Props {
  text: string;
  onClick?(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
  dataSelector?: string;
  className?: string;
  type?: BadgeType;
  color?: string;
}

const classes = bem('badge');

export const Badge = React.memo(({ text, onClick, dataSelector, className, type, color }: Props) => (
  <SBadge color={color} onClick={onClick} className={className} type={type}>
    <span data-selector={dataSelector} className={classes('text')}>
      {text}
    </span>
  </SBadge>
));
