import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

interface Props {
  isInvalid?: boolean;
}

export const SLinkItem = styled.a<Props>`
  overflow: hidden;
  opacity: ${({ isInvalid }) => (isInvalid ? 0.7 : 1)};

  background: ${({ isInvalid }) => (isInvalid ? colorPalette.alabaster : 'unset')};
  color: ${({ isInvalid }) => (isInvalid ? colorPalette.osloGray : 'unset')};
`;
