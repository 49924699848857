import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { bem } from 'utils/bem';
import { ClassNamesProps, Select, SelectType } from 'common/components/select';
import { getSelectOptions } from 'common/transducers';
import { BEM_CLASS, SKpiEditor } from './s-kpi-editor';
import { flatMapDeep } from 'lodash';
import { useSelectEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan/components/spreadsheet/hooks';
import { Nullable } from 'backend-api/models';

const classes = bem(BEM_CLASS);

export const KpiEditor = React.memo(
  React.forwardRef(({ value: tableValue, api, colDef, rowIndex }: ICellEditorParams, ref) => {
    const [value, setValue] = useState(tableValue.value);
    const { onBlur } = useStopEditingBlur(api);
    const options = useMemo(() => getSelectOptions(colDef.cellRendererParams.performanceObjectives), [
      colDef.cellRendererParams.performanceObjectives,
    ]);

    const handleChange = useCallback(
      (id: Nullable<number>) => {
        const flattenedOptions = flatMapDeep(options, ({ options }) => options);
        const kpi = flattenedOptions.find(option => option.id === id);

        if (kpi) {
          setValue(kpi);
          api.setFocusedCell(rowIndex, colDef.field || '');
        } else {
          setValue(null);
        }
      },
      [api, colDef.field, options, rowIndex]
    );

    const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions({
      api,
      value,
      setValue: handleChange,
      isMulti: false,
    });

    const selectClassNames = useCallback<(className: string) => ClassNamesProps>(
      () => ({
        selectContainer: classes('select-container'),
        control: classes('control'),
        valueContainer: { root: classes('value-container-root'), container: classes('value-container') },
        menu: classes('menu'),
        indicatorsContainer: classes('indicators-container'),
      }),
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          ...tableValue,
          value,
        };
      },
    }));

    return (
      <SKpiEditor statusId={value}>
        {className => (
          <div className={className}>
            <Select
              selectRef={selectRef}
              type={SelectType.Searchable}
              dataSelector="kpi-editor"
              valueId={value?.id}
              valueHandler={handleChange}
              options={options}
              classNames={selectClassNames(className)}
              tabSelectsValue
              isOpened
              autoFocus
              placeholder=""
              isSearchable
              onKeyDown={onKeyDown}
              onBlur={onBlur}
            />
          </div>
        )}
      </SKpiEditor>
    );
  })
);
