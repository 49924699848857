import { Dimension, Position } from './types';
import { BORDER_WIDTH } from './constants';

export const getTextareaPosition = (rootRect: DOMRect, elementDimension: Dimension, window: Window): Position => {
  const borderOffset = BORDER_WIDTH * 2;
  const top =
    rootRect.top + borderOffset + elementDimension.height > window.innerHeight
      ? rootRect.top + borderOffset + rootRect.height - elementDimension.height + window.scrollY
      : rootRect.top - borderOffset + window.scrollY;
  const left =
    rootRect.left + borderOffset + elementDimension.width > window.innerWidth
      ? rootRect.left + borderOffset + rootRect.width - elementDimension.width + window.scrollX
      : rootRect.left - borderOffset + window.scrollX;

  return { top, left };
};
