import { reducer as common } from 'common/reducer';
import { reducer as auth } from 'auth/reducer';
import { reducer as login } from 'login/reducer';
import { reducer as campaign } from 'campaign/reducer';
import { reducer as project } from 'project/reducer';
import { reducer as projects } from 'projects/reducer';
import { reducer as moment } from 'artist-moment/reducer';
import { reducer as artist } from 'artist/reducer';
import { reducer as purchaseOrder } from 'purchase-order/reducer';
import { reducer as prsTab } from 'prs/reducer';
import { reducer as shareModal } from 'modals/sharing-modal/reducer';
import { reducer as bulkCampaignsAssignmentModal } from 'modals/bulk-campaigns-assignment/reducer';
import { reducer as settings } from 'settings/reducer';
import { reducer as mediaPlan } from 'media-plan/reducer';
import { reducer as mediaPlanV2 } from 'media-plan-v2/reducer';
import { reducer as reporting } from 'reporting-v2/reducer';
import { reducer as labelPermissions } from 'label-permissions/reducer';
import { reducer as permissionErrorModal } from 'modals/permission-error-modal/reducer';
import { reducer as projectsV2 } from 'projects-v2/reducer';
import { reducer as projectV2 } from 'project-v2/reducer';

export const rootReducer = {
  common,
  auth,
  login,
  campaign,
  project,
  projects,
  moment,
  artist,
  purchaseOrder,
  prsTab,
  shareModal,
  bulkCampaignsAssignmentModal,
  settings,
  mediaPlan,
  mediaPlanV2,
  reporting,
  labelPermissions,
  permissionErrorModal,
  projectsV2,
  projectV2,
};
