import styled, { css } from 'styled-components';
import { Props as ButtonProps } from 'common/components/button';
import { IconColor } from '../icon/types';

/*
 * Button variants
 * */
const secondaryGray = css<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.button.secondaryGray.background};
  color: ${({ theme }) => theme.colors.button.secondaryGray.color};
  border: 0;

  i:before {
    color: ${({ theme, iconColor }) => iconColor || theme.colors.button.secondaryGray.color};
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.button--focused:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.button.secondaryGray.hover.background};
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.colors.button.secondaryGray.disabled.background};
    color: ${({ theme }) => theme.colors.button.secondaryGray.disabled.color};

    i:before {
      color: ${({ theme }) => theme.colors.button.secondaryGray.disabled.color};
    }
  }
`;

const secondaryCta = css<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.button.secondaryCta.background};
  color: ${({ theme }) => theme.colors.button.secondaryCta.color};

  i:before {
    color: ${({ theme, iconColor }) => iconColor || theme.colors.button.secondaryCta.color};
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.button--focused:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.button.secondaryCta.hover.background};
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.colors.button.secondaryCta.disabled.background};
    color: ${({ theme }) => theme.colors.button.secondaryCta.disabled.color};

    i:before {
      color: ${({ theme }) => theme.colors.button.secondaryCta.disabled.color};
    }
  }
`;

const primaryCta = css<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.button.primaryCta.background};
  color: ${({ theme }) => theme.colors.button.primaryCta.color};

  i:before {
    color: ${({ theme, iconColor }) => iconColor || theme.colors.button.primaryCta.color};
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.button--focused:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.button.primaryCta.hover.background};
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.colors.button.primaryCta.disabled.background};
    color: ${({ theme }) => theme.colors.button.primaryCta.disabled.color};

    i:before {
      color: ${({ theme }) => theme.colors.button.primaryCta.disabled.color};
    }
  }
`;

const secondaryWhite = css<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.button.secondaryWhite.background};
  color: ${({ theme }) => theme.colors.button.secondaryWhite.color};

  i:before {
    color: ${({ theme, iconColor }) => iconColor || theme.colors.button.secondaryWhite.iconColor};
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.button--focused:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.button.secondaryWhite.hover.background};
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.colors.button.secondaryWhite.disabled.background};
    color: ${({ theme }) => theme.colors.button.secondaryWhite.disabled.color};

    i:before {
      color: ${({ theme }) => theme.colors.button.secondaryWhite.disabled.color};
    }
  }
`;

const dark = css<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.button.dark.background};
  color: ${({ theme }) => theme.colors.button.dark.color};

  i:before {
    color: ${({ theme, iconColor }) => iconColor || theme.colors.button.dark.color};
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.button--focused:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.button.dark.hover.background};
  }

  &&[disabled] {
    background-color: ${({ theme }) => theme.colors.button.dark.disabled.background};
    color: ${({ theme }) => theme.colors.button.dark.disabled.color};
  }
`;

const transparent = css<ButtonProps>`
  background-color: transparent;

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.button--focused:not([disabled]) {
    background-color: transparent;
  }

  &&[disabled] {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.button.transparent.disabled.color};
  }
`;

const black = css<ButtonProps>`
  background-color: ${({ theme }) => theme.colors.button.black.background};
  color: ${({ theme }) => theme.colors.button.black.color};

  i:before {
    color: ${({ theme, iconColor }) => iconColor || theme.colors.button.black.color};
  }

  &:hover:not([disabled]),
  &:focus:not([disabled]),
  &.button--focused:not([disabled]) {
    background-color: ${({ theme }) => theme.colors.button.black.hover.background};
  }

  &&[disabled] {
    background-color: ${({ theme }) => theme.colors.button.black.disabled.background};
    color: ${({ theme }) => theme.colors.button.black.disabled.color};

    i:before {
      color: ${({ theme }) => theme.colors.button.black.disabled.color};
    }
  }
`;

const variant = (props: ButtonProps) => {
  switch (props.variant) {
    case 'secondary-gray':
      return secondaryGray;
    case 'secondary-cta':
      return secondaryCta;
    case 'secondary-white':
      return secondaryWhite;
    case 'primary-cta':
      return primaryCta;
    case 'dark':
      return dark;
    case 'transparent':
      return transparent;
    case 'black':
      return black;
    default:
      return secondaryGray;
  }
};

interface SButtonProps extends ButtonProps {
  readonly icon?: boolean;
  readonly iconColor?: IconColor;
  readonly iconLeft?: boolean;
  readonly iconRight?: boolean;
  readonly iconBoth?: boolean;
}

export const SButton = styled.button<SButtonProps>`
  min-width: ${({ icon, size }) => (!icon ? '100px' : size === 'big' ? '50px' : '35px')};
  border-radius: 20px;
  height: ${({ size }) => (size === 'big' ? '50px' : '35px')};
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding-top: ${({ size }) => size === 'big' && '16px'}; // Height big 50px
  padding-bottom: ${({ size }) => size === 'big' && '16px'};
  padding-left: ${({ icon, iconLeft, iconBoth }) => !icon && (iconLeft || iconBoth ? '15px' : '20px')};
  padding-right: ${({ icon, iconRight, iconBoth }) => !icon && (iconRight || iconBoth ? '15px' : '20px')};

  // style  button icons mini size
  i {
    display: flex;
    pointer-events: none;
  }

  .button__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    // TODO check vertical alignment
    //top: -1px;
    pointer-events: none;

    i {
      &:first-child {
        margin-right: ${({ iconLeft, iconBoth }) => (iconLeft || iconBoth) && '9px'};
        pointer-events: none;
      }
    }
  }

  .button__text {
    white-space: nowrap;
    margin-right: ${({ icon, iconRight, iconBoth }) => !icon && (iconRight || iconBoth ? '5px' : '0')};
    pointer-events: none;
  }

  ${variant}
`;
