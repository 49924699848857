import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import * as t from 'io-ts';
import { bem } from 'utils/bem';
import { Id, Optional } from 'backend-api/models';
import { SProjectDetails } from './s-project-details';
import {
  activeProjectSelector,
  artistTeamSelector,
  isLoadingSelector,
  projectTeamCountersSelector,
} from '../../selectors';
import { ArtistPlaylistWidget, ArtistTeamWidget, BudgetWidget, Header, NoOwnerCard, NotesWidget } from './components';
import { getProjectLockedArtist, hasLockedArtist, hasUnknownArtist } from 'project/transducers';
import { LoadingState } from 'common/types';
import { getProjectArtists } from 'common/transducers';
import { ROLES } from 'common-v2/constants';
import { useAppContext } from 'common-v2/hooks';

interface Props {
  projectId: Id;
  currencyCode?: string;
  isAssignCampaignsLoading: boolean;
  openViewProjectCollaboratorsModal(): void;
  openEditProjectCollaboratorsModal(): void;
  openArtistTeamModal(): void;
  openArtistPage(artistId: Optional<string>): void;
}

const classes = bem('project-details');

export const ProjectDetails = React.memo(
  ({
    projectId,
    currencyCode,
    isAssignCampaignsLoading,
    openViewProjectCollaboratorsModal,
    openEditProjectCollaboratorsModal,
    openArtistTeamModal,
    openArtistPage,
  }: Props) => {
    const { user, labels: userLabels } = useAppContext();

    const project = useSelector(activeProjectSelector);
    const isLoading = useSelector(isLoadingSelector);

    const isUnclaimed = t.boolean.is(project?.isClaimed) && !project?.isClaimed;

    const artists = useMemo(() => getProjectArtists(project), [project]);

    const shouldShowNoOwnerCard =
      !!project &&
      !project.isClaimed &&
      (project.isConfidential || hasUnknownArtist(artists) || !hasLockedArtist(artists));

    const shouldShowNotes = isLoading || (!!project && project.isClaimed && project?.notes);

    const artistTeam = useSelector(artistTeamSelector);
    const lockedArtist = useMemo(() => getProjectLockedArtist(project), [project]);

    const navigateToArtistPage = useCallback(() => openArtistPage(lockedArtist?.id), [
      lockedArtist?.id,
      openArtistPage,
    ]);

    const isLabelmate = userLabels?.some(({ id }) => id === project?.label?.id);
    const isLabelAdmin = user?.isAdmin && isLabelmate;

    const isEditor = project?.userRoles.some(({ id }) => id === ROLES.EDITOR.id);
    const shouldShowArtistTeam = !isLoading && !project?.isConfidential;
    const canEditProjectCollaborators = isLabelAdmin || isEditor;

    const teamCounters = useSelector(projectTeamCountersSelector);
    const collaboratorsCount = teamCounters.data ? teamCounters.data.usersCount : 0;

    return (
      <SProjectDetails>
        <Header
          canEditProjectCollaborators={canEditProjectCollaborators}
          collaboratorsCount={collaboratorsCount}
          projectId={projectId}
          onViewProjectCollaborators={openViewProjectCollaboratorsModal}
          onEditProjectCollaborators={openEditProjectCollaboratorsModal}
        />
        <div className={classes('container')}>
          <div className={classes('main-column')}>
            {shouldShowNoOwnerCard && <NoOwnerCard projectId={projectId} />}
            <BudgetWidget
              dataSelector="budget-widget"
              currencyCode={currencyCode}
              isAssignCampaignsLoading={isAssignCampaignsLoading}
            />
          </div>
          <div className={classes('secondary-column')}>
            <ArtistPlaylistWidget className={classes('artist-playlist-widget')} />
            {shouldShowArtistTeam && (
              <ArtistTeamWidget
                artistTeam={artistTeam.data}
                isLoading={artistTeam.loading === LoadingState.Started}
                onViewTeam={openArtistTeamModal}
                isUnclaimed={isUnclaimed}
                onCreateArtistTeam={navigateToArtistPage}
                className={classes('artist-team-widget')}
              />
            )}
            {shouldShowNotes && <NotesWidget />}
          </div>
        </div>
      </SProjectDetails>
    );
  }
);
