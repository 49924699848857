import React, { useCallback } from 'react';
import { Select, SelectType } from 'common-v2/components';
import { EXPORT_OPTION_ID, SETTINGS_OPTIONS } from './constants';
import { SettingsControlTrigger } from './components';

interface Props {
  disabled?: boolean;
  onExportClick?(): void;
  className?: string;
}

export const SettingsControl = React.memo(({ className, disabled, onExportClick }: Props) => {
  const selectComponents = useCallback(
    () => ({
      Control: SettingsControlTrigger,
    }),
    []
  );

  const handleOptionSelect = useCallback(
    (valueId: string) => {
      if (valueId === EXPORT_OPTION_ID) {
        onExportClick?.();
      }
    },
    [onExportClick]
  );

  return (
    <div className={className}>
      <Select
        type={SelectType.Base}
        selectComponents={selectComponents}
        valueId={0}
        valueHandler={handleOptionSelect}
        isDisabled={disabled}
        options={SETTINGS_OPTIONS}
      />
    </div>
  );
});
