import React, { useRef } from 'react';
import { SLongTableTitle } from '../../s-components/table/s-long-table-title';
import { Tooltip } from '../tooltip';
import { bem } from 'utils/bem';
import { useIsTextOverflown } from 'hooks';

const classes = bem('long-table-title');

interface Props {
  text: string;
  className?: string;
  textClassName?: string;
  dataSelector?: string;
}

export const LongTableTitle = React.memo(({ text, className, dataSelector, textClassName }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const isTextOverflown = useIsTextOverflown(ref, false);
  return (
    <SLongTableTitle className={className} data-selector={dataSelector}>
      <Tooltip placement="bottom" content={text} enabled={isTextOverflown} appendTo={document.body}>
        <span ref={ref} className={classes('long-text', {}, textClassName)} data-selector={dataSelector}>
          {text}
        </span>
      </Tooltip>
    </SLongTableTitle>
  );
});
