import React from 'react';
import { SFooter } from '../../s-components/table/s-footer';
import { bem } from 'utils/bem';
import { TableContent } from './constants';
import { TableTheme } from 'app/theme/table';

interface Props<BodyType, SortField extends string> {
  headerContent: TableContent<BodyType, SortField>[];
  isExpandable?: boolean;
  isCheckable?: boolean;
  hasStickyColumns?: boolean;
  theme: TableTheme;
}

const classes = bem('footer');

export const TableFooter = <BodyType, SortField extends string>({
  headerContent,
  isExpandable,
  isCheckable,
  hasStickyColumns,
}: Props<BodyType, SortField>) => {
  const shouldShowCheck = isCheckable && !hasStickyColumns;
  const shouldShowExpand = isExpandable && !hasStickyColumns;
  const colCount = headerContent.length + (shouldShowExpand ? 1 : 0) + (shouldShowCheck ? 1 : 0);
  return (
    <SFooter>
      <tr className={classes('shadow-row')}>
        <th colSpan={colCount} className={classes('shadow')} />
      </tr>
      <tr className={classes('row')}>
        {shouldShowCheck && <th className={classes('cell')} />}
        {shouldShowExpand && <th className={classes('cell')} />}
        {headerContent.map(({ renderFooterCell, dataIndex }) => (
          <td key={dataIndex} className={classes('cell')}>
            {renderFooterCell && renderFooterCell()}
          </td>
        ))}
      </tr>
    </SFooter>
  );
};
