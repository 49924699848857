import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { popup } from 'app/styles/z-indexes';
import { transparentize } from 'polished';

interface Props {
  isVisible: boolean;
}

export const BEM_CLASS = 'confirmation-modal';

export const SConfirmationModal = styled.div<Props>`
  position: fixed;
  z-index: ${popup};
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  overflow-x: hidden;
  overflow-y: auto;

  .${BEM_CLASS} {
    &__overlay {
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      background-image: linear-gradient(
        360deg,
        ${transparentize(0.3, manhattanThemeColors.primaryDark)} 0%,
        ${transparentize(0.3, manhattanThemeColors.primaryFillNew)} 100%
      );
    }

    &__wrapper {
      position: relative;
      z-index: 2;
      width: 480px;
      margin: auto;
      background: ${transparentize(0.1, manhattanThemeColors.background)};
      box-shadow: 0px 5px 20px ${transparentize(0.8, manhattanThemeColors.primaryDark)};
      backdrop-filter: blur(10px);
      border-radius: 10px;
    }

    &__container {
      display: grid;
      grid-template-columns: 25px auto 25px;
      gap: 25px;
      padding: 20px 20px 0;

      &::before {
        content: '';
      }
    }

    &__text {
      margin-top: 4px;
      text-align: center;
    }

    &__actions {
      display: flex;
      justify-content: center;
      gap: 20px;
      padding: 30px 50px;
    }

    &__action {
      width: 180px;
    }
  }
`;
