import React from 'react';
import { MenuDropdownOption } from 'common/components/menu-dropdown';
import { SUserDropdown } from './s-user-dropdown';
import { UserAvatar } from 'common/components/user-avatar';
import { CurrentUserInfo } from 'backend-api/models';

interface Props {
  userInfo?: CurrentUserInfo;
  className?: string;
  onLogOutPressed(): void;
  onSettingsPressed(): void;
  onLabelsPermissionsClick(): void;
}

export const UserDropdown = React.memo(
  ({ className, onLogOutPressed, onSettingsPressed, userInfo, onLabelsPermissionsClick }: Props) => {
    return (
      <SUserDropdown
        trigger={<UserAvatar name={userInfo?.name || ''} size={35} />}
        className={className}
        dataSelector="user-settings-dropdown"
      >
        <MenuDropdownOption
          key="settings"
          title="Settings"
          iconName="settings"
          onClick={onSettingsPressed}
          dataSelector="user-settings-dropdown"
        />
        <MenuDropdownOption
          key="label-permissions"
          title="Label Permissions"
          iconName="label-permissions"
          onClick={onLabelsPermissionsClick}
          dataSelector="label-permissions-dropdown"
        />
        <MenuDropdownOption
          key="sign-out"
          title="Sign Out"
          iconName="sign-out"
          onClick={onLogOutPressed}
          dataSelector="sign-out-menu-dropdown"
        />
      </SUserDropdown>
    );
  }
);
