import { actionsGroup } from 'core/store';
import { CampaignDetails, Id, ProjectDetails } from 'backend-api/models';
import {
  ManageExternalCampaignsPayload,
  AssignableCampaignsParams,
  AssignableCampaignsResponse,
  RemoveExternalCampaignPayload,
  UndoRemovalExternalCampaignPayload,
} from './types';

const { action, asyncAction } = actionsGroup('reporting');

export const getProject = asyncAction<Id, ProjectDetails, Error>('GET_PROJECT');

export const resetProject = action<void>('RESET_PROJECT');

export const resetProjectError = action<void>('RESET_PROJECT_ERROR');

export const getProjectCampaigns = asyncAction<Id, CampaignDetails[], Error>('GET_PROJECT_CAMPAIGNS');

export const getDigitalCampaigns = asyncAction<AssignableCampaignsParams, AssignableCampaignsResponse, Error>(
  'GET_DIGITAL_CAMPAIGNS'
);

export const manageExternalCampaigns = asyncAction<ManageExternalCampaignsPayload, unknown, Error>(
  'MANAGE_EXTERNAL_CAMPAIGNS'
);
export const removeExternalCampaign = asyncAction<RemoveExternalCampaignPayload, void, Error>(
  'REMOVE_EXTERNAL_CAMPAIGN'
);
export const undoRemovalExternalCampaign = asyncAction<UndoRemovalExternalCampaignPayload, void, Error>(
  'UNDO_REMOVAL_EXTERNAL_CAMPAIGN'
);

export const resetManageAssignedCampaignsError = action<void>('RESET_MANAGE_CAMPAIGNS_ERROR');

export const openManagingCampaigns = action<void>('OPEN_MANAGING_CAMPAIGNS');
export const closeManagingCampaigns = action<void>('CLOSE_MANAGING_CAMPAIGNS');
export const resetProjectCampaignsError = action<void>('RESET_PROJECT_CAMPAIGNS_ERROR');
