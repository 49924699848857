import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getVisibleItemCount } from './transducers';
import { BEM_CLASS, SMultiValueView } from './s-multi-value-view';
import { bem } from 'utils/bem';
import { Body } from 'common/components/typography';
import { SpreadsheetTooltip } from '../spreadsheet-tooltip';
import { SingleValueView } from '../single-value-view';

interface Props {
  values?: string[];
  placeholder?: string;
  isTooltipDisabled?: boolean;
}

const classes = bem(BEM_CLASS);

export const MultiValueView = ({ values, placeholder, isTooltipDisabled }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [visibleElements, setVisibleElementsCount] = useState(values?.length || 0);

  const shouldShowPlaceholder = !values || values?.length === 0;

  const observer = useMemo(
    () =>
      new ResizeObserver(entries => {
        if (values?.length === 0) return;
        const visibleItemCount = getVisibleItemCount(entries[0].target);
        if (visibleItemCount !== visibleElements) {
          setVisibleElementsCount(visibleItemCount);
        }
      }),
    [values?.length, visibleElements]
  );

  useEffect(() => {
    const element = ref.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) observer.unobserve(element);
    };
  }, [observer]);

  useEffect(() => {
    if (!ref.current) return;
    const visibleItemCount = getVisibleItemCount(ref.current);
    setVisibleElementsCount(visibleItemCount);
  }, [values]);

  const hiddenValues = (values?.length || 0) - visibleElements;
  const isOverflown = (values?.length || 0) > visibleElements;

  return (
    <SpreadsheetTooltip content={values?.join(', ') || ''} disabled={!isOverflown || isTooltipDisabled}>
      <SMultiValueView>
        {shouldShowPlaceholder ? (
          <Body className={classes('placeholder')}>{placeholder}</Body>
        ) : (
          <>
            <div className={classes('labels-container')} ref={ref}>
              {values.map(item => (
                <SingleValueView
                  className={classes('content')}
                  key={item}
                  value={item}
                  tooltipDisabled={isOverflown || isTooltipDisabled}
                />
              ))}
            </div>
            {isOverflown && <Body className={classes('other-badge')}>+{hiddenValues}</Body>}
          </>
        )}
      </SMultiValueView>
    </SpreadsheetTooltip>
  );
};
