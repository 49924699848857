import { BEM_CLASS, SEmptyState } from './s-empty-state';
import React from 'react';
import NoResults from 'assets/no-results-no-bg.svg';
import { SmallText } from 'common/components/typography';
import { bem } from 'utils/bem';

const classes = bem(BEM_CLASS);

interface Props {
  dataSelector?: string;
}

export const EmptyState = React.memo(({ dataSelector }: Props) => {
  return (
    <SEmptyState data-selector={dataSelector}>
      <img src={NoResults} alt="No result" />
      <SmallText className={classes('text')}>
        No Results found.
        <br /> Create a new platform.
      </SmallText>
    </SEmptyState>
  );
});
