import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { UUID } from 'io-ts-types';
import { mediaPlanModeSelector, spreadsheetValidationModelSelector } from 'media-plan-v2/selectors';
import { useMediaPlanAccessMode } from 'media-plan-v2/hooks';
import {
  getColorsFromClipboard,
  getIndexesRange,
  getValuesFromClipboard,
  pasteDataIntoSingleCell,
  mergeClipboardData,
  parseCellValueFromClipboard,
  processDataFromClipboard,
  updatePlacementsOnPlatformsChange,
  isMediaPlanEditing,
  isReadOnlyMode,
} from '../../transducers';
import { normalizeIndexForCopyPaste } from './transducers';
import { CopyPastedCell, UndoHook } from '../../types';
import { THEME, useManageToasts, TOAST_TYPE } from 'gdb-web-shared-components';
import { MediaPlanColumnId, SpreadsheetRowData, SpreadsheetContext } from 'media-plan-v2/containers/spreadsheet/types';
import { isMediaPlanColumnId } from 'media-plan-v2/containers/spreadsheet/transducers';

export const useCopyPaste = (
  projectId: number,
  undo: UndoHook,
  context: SpreadsheetContext,
  agGrid?: AgGridReact<SpreadsheetRowData> | null
) => {
  const { current: copiedData } = useRef<Map<UUID, CopyPastedCell[]>>(new Map([]));

  const { openToast } = useManageToasts(THEME.light);
  const spreadsheetValidationModel = useSelector(spreadsheetValidationModelSelector);

  const mediaPlanMode = useSelector(mediaPlanModeSelector);
  const accessMode = useMediaPlanAccessMode(projectId, mediaPlanMode);

  const addCopiedData = useCallback(
    ([uuid, data]: [UUID, CopyPastedCell]) => {
      const currentCopiedData = copiedData.get(uuid);
      copiedData.set(uuid, currentCopiedData ? [...currentCopiedData, data] : [data]);
    },
    [copiedData]
  );

  const resetCopiedData = useCallback(() => {
    copiedData.clear();
  }, [copiedData]);

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      if (!agGrid || !spreadsheetValidationModel) return;

      const { api, columnApi } = agGrid;
      const isAccessModeReadOnly = isReadOnlyMode(accessMode);
      const isEditing = isMediaPlanEditing(api);

      if (isEditing) return;

      const clipboardData = mergeClipboardData(
        getValuesFromClipboard(event.clipboardData?.getData('text/plain')),
        getColorsFromClipboard(event.clipboardData?.getData('text/html'))
      );
      const processedData = processDataFromClipboard(clipboardData, columnApi);
      const range = api.getCellRanges()?.[0];

      if (!range || range.startRow?.rowIndex === undefined || range.endRow?.rowIndex === undefined) return;

      const startRowIndex = Math.min(range.startRow.rowIndex, range.endRow.rowIndex);
      const endRowIndex = Math.max(range.startRow.rowIndex, range.endRow.rowIndex);
      const isOneCellSelected = startRowIndex === endRowIndex && range.columns.length === 1;

      if (isAccessModeReadOnly) {
        openToast({
          id: 'media-plan-read-only',
          preventDuplicate: true,
          message: "We're sorry, you cannot update read-only data.",
          type: TOAST_TYPE.ERROR,
        });
        return;
      }

      if (isOneCellSelected) {
        pasteDataIntoSingleCell(api, columnApi, spreadsheetValidationModel, undo, context, openToast)(clipboardData);
      } else {
        const updateTransaction: SpreadsheetRowData[] = [];
        const rangeRowIndexes = getIndexesRange(startRowIndex, endRowIndex);

        rangeRowIndexes.forEach((rangeRowIndex, rowIndex) => {
          const node = api.getDisplayedRowAtIndex(rangeRowIndex);

          if (!node || !node.data) return;

          const updatedRow = { ...node.data };

          range.columns.forEach((column, columnIndex) => {
            if (!node || !node.data) return;

            const colId = column.getColId();

            if (!isMediaPlanColumnId(colId)) return;

            const normalizedRowIndex = normalizeIndexForCopyPaste(processedData, rowIndex);
            const rowData = processedData[normalizedRowIndex];

            if (!rowData || rowData.length === 0) return;

            const normalizedColumnIndex = normalizeIndexForCopyPaste(rowData, columnIndex);
            const pastedCellValue = processedData[normalizedRowIndex][normalizedColumnIndex];

            if (!pastedCellValue) return;

            const updatedCell = parseCellValueFromClipboard(
              pastedCellValue,
              colId,
              api,
              columnApi,
              spreadsheetValidationModel,
              context,
              updatedRow,
              openToast
            );

            if (updatedCell) {
              undo.updateUndoStack({ field: colId, data: node.data });
              updatedRow[colId] = updatedCell;

              if (colId === MediaPlanColumnId.PLATFORMS) {
                updatedRow[MediaPlanColumnId.PLACEMENTS] = updatePlacementsOnPlatformsChange(
                  context,
                  updatedRow,
                  range.columns.every(column => column.getColId() !== MediaPlanColumnId.PLACEMENTS),
                  openToast
                );
              }
            }
          });

          updateTransaction.push(updatedRow);
        });

        api.applyTransactionAsync({
          update: updateTransaction,
        });
      }
    },
    [accessMode, agGrid, context, openToast, spreadsheetValidationModel, undo]
  );

  useEffect(() => {
    document.addEventListener('paste', handlePaste);

    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [handlePaste]);

  return { copiedData, addCopiedData, resetCopiedData };
};
