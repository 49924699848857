import React, { useCallback, useEffect, useImperativeHandle, useReducer, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { ExpandableText } from '../expandable-text';
import { useEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan/components/spreadsheet/hooks';
import { SpreadsheetRowData } from '../../types';

export const NamingConventionEditor = React.memo(
  React.forwardRef(
    (
      {
        data,
        api,
        rowIndex,
        colDef,
        charPress,
        value: tableValue,
      }: ICellEditorParams<SpreadsheetRowData, SpreadsheetRowData['namingConventionManual']>,
      ref
    ) => {
      const initialValue = charPress ? charPress : tableValue.value;
      const [value, setValue] = useState(initialValue);
      const [isVisible, setVisible] = useReducer(() => true, false);
      const [isTouched, setTouched] = useReducer(() => true, false);
      const { onKeyDown } = useEditorKeyboardInteractions({ api, value });
      const { onBlur } = useStopEditingBlur(api);

      const handleChange = useCallback((value: string) => {
        setValue(value);
        setTouched();
      }, []);

      const handleStopEditing = useCallback(() => {
        api.stopEditing();
        api.setFocusedCell(rowIndex, colDef.field || '');
      }, [api, colDef.field, rowIndex]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return {
            ...tableValue,
            value,
          };
        },

        updateValue(value) {
          setValue(value);
        },

        getId() {
          return data.id.value;
        },

        getIsTouched() {
          return isTouched;
        },
      }));

      useEffect(() => {
        requestAnimationFrame(() => {
          setVisible();
        });
      }, []);

      return isVisible ? (
        <ExpandableText
          prefix={data.namingConvention.value ?? ''}
          value={value ?? ''}
          placeholder="Write or paste naming convention here..."
          onTextareaChange={handleChange}
          stopEditing={handleStopEditing}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
      ) : null;
    }
  )
);
