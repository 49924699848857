export const PERFORMANCE_METRICS_FORMATS = {
  ageGenders: 'age_genders',
  genders: 'genders',
  percentage: 'percentage',
  rate: 'rate',
  date: 'date',
  ageRanges: 'age_ranges',
  integer: 'integer',
  currency: 'currency',
};
