import styled from 'styled-components';

export const BEM_CLASS = 'legend-item';

interface Props {
  color: string;
}

export const SLegendItem = styled.div<Props>`
  display: flex;
  align-items: center;
  .${BEM_CLASS} {
    &__indicator {
      width: 10px;
      height: 10px;
      background: ${({ color }) => color};
      border-radius: 10px;
      margin-right: 10px;
    }

    &__title {
      font-weight: normal;
    }
  }
`;
