import { ApiError } from 'backend-api';
import { Optional } from 'backend-api/models';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { resetAccessRestrictedData, setAccessRestrictedData } from 'common/actions';
import { ACCESS_RESTRICTED_CODES } from 'common/constants';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { OutputSelector } from 'reselect';

export const useProjectAccessRestricted = (
  errorSelector: OutputSelector<
    any,
    Optional<ApiError<ProjectDetailsErrorResponse>>,
    (state: any) => Optional<ApiError<ProjectDetailsErrorResponse>>
  >
): boolean => {
  const error = useSelector(errorSelector);
  const history = useHistory();
  const match = useRouteMatch<{ id: string }>();
  const dispatch = useDispatch();

  const isErrorHandled = error?.statusCode === 403;

  useEffect(() => {
    if (error?.statusCode === 403) {
      const currentProjectId = parseInt(match.params.id, 10);
      if (ACCESS_RESTRICTED_CODES.some(val => val === error?.data?.code)) {
        const projectDetail = error.data?.detail;

        if (!projectDetail) return;

        dispatch(
          setAccessRestrictedData({
            currentTeamMembers: projectDetail.projectEditors,
            currentProjectId,
            currentProjectName: projectDetail.projectName,
          })
        );

        history.replace(`/projects/${currentProjectId}/access-restricted`);
      } else {
        dispatch(resetAccessRestrictedData());
      }
    }
  }, [dispatch, error, history, match.params.id]);

  return isErrorHandled;
};
