import React from 'react';
import { ProjectSharing } from 'backend-api/models';
import { bem } from 'utils/bem';
import { SMembersList, BEM_CLASS } from './s-members-list';

interface MembersListProps {
  className?: string;
  data: ProjectSharing;
  renderBlocks(sharingInfo: ProjectSharing): React.ReactNode | React.ReactNodeArray;
}

const classes = bem(BEM_CLASS);

export const MembersList = React.memo(({ className, data, renderBlocks }: MembersListProps) => {
  const blocks = renderBlocks(data);

  return (
    <SMembersList className={className}>
      {Array.isArray(blocks)
        ? blocks.map(
            (block, index) =>
              Boolean(block) && (
                <div key={index} className={classes('block')}>
                  {block}
                </div>
              )
          )
        : blocks}
    </SMembersList>
  );
});
