import React from 'react';
import { SimpleProjectUser } from 'backend-api/models';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { SMembersBlock, BEM_CLASS } from './s-members-block';

const classes = bem(BEM_CLASS);

interface MembersBlockProps {
  className?: string;
  title: string;
  emptyText?: string;
  members: SimpleProjectUser[];
  renderMember(member: SimpleProjectUser): React.ReactElement;
  dataSelector?: string;
}

export const MembersBlock = React.memo(
  ({ className, title, emptyText = 'No members', members, renderMember, dataSelector }: MembersBlockProps) => (
    <SMembersBlock className={className}>
      <Typography
        type={TYPOGRAPHY_TYPE.body2}
        className={classes('title')}
        data-selector={`share-modal-members-block-${dataSelector}`}
      >
        {title} ({members.length ?? 0})
      </Typography>

      {members.length > 0 ? (
        <div className={classes('list')}>{members.map(renderMember)}</div>
      ) : (
        <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('empty')}>
          {emptyText}
        </Typography>
      )}
    </SMembersBlock>
  )
);
