import { bem } from 'utils/bem';
import { ControlProps } from 'react-select/dist/declarations/src/components/Control';
import { BaseOption } from 'common/components/select';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { Button, BUTTON_TYPE, BUTTON_SIZE, THEME, Tooltip } from 'gdb-web-shared-components';
import { SSettingsControlTrigger, BEM_CLASS } from './s-settings-control-trigger';
import React, { useCallback, useState } from 'react';

const classes = bem(BEM_CLASS);

export const SettingsControlTrigger = React.memo(({ innerProps, ...props }: ControlProps<BaseOption, false>) => {
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);

  const openTooltip = useCallback(() => {
    setIsTooltipOpened(true);
  }, []);

  const closeTooltip = useCallback(() => {
    setIsTooltipOpened(false);
  }, []);

  const { handleMenu } = getBaseCustomSelectProps(props.selectProps);

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      closeTooltip();
      handleMenu(event, props.menuIsOpen);
    },
    [closeTooltip, handleMenu, props.menuIsOpen]
  );

  const newInnerProps: JSX.IntrinsicElements['div'] = {
    onClick,
    onMouseDown: onClick,
    ...innerProps,
  };

  return (
    <SSettingsControlTrigger {...props} innerProps={newInnerProps}>
      <Tooltip
        isOpened={isTooltipOpened}
        tooltip="Actions"
        isInteractive={false}
        onOpen={openTooltip}
        onClose={closeTooltip}
      >
        <Button
          className={classes('trigger', { isDisabled: props.isDisabled })}
          disabled={props.isDisabled}
          type={BUTTON_TYPE.tertiary}
          size={BUTTON_SIZE.small}
          theme={THEME.light}
          icon="ellipsis-m"
          data-selector="media-plan-settings-control-button"
        />
      </Tooltip>
    </SSettingsControlTrigger>
  );
});
