import { createSelector } from 'reselect';
import { ProjectsState } from './reducer';

export const projectsSelector = state => state.projects as ProjectsState;

export const recentProjectsSelector = createSelector(projectsSelector, state => state.recentProjects);

export const upcomingProjectsSelector = createSelector(projectsSelector, state => state.upcomingProjects);

export const teamActivitySelector = createSelector(projectsSelector, state => state.teamActivity);

export const isLoadingSelector = createSelector(projectsSelector, state => state.isLoading);

export const isLoadingAllProjectsSelector = createSelector(projectsSelector, state => state.isLoadingAllProjects);

export const allAssignedProjectsSelector = createSelector(projectsSelector, state => state.allAssignedProjects);

export const allUnassignedProjectsSelector = createSelector(projectsSelector, state => state.allUnassignedProjects);

export const allProjectsMetaSelector = createSelector(projectsSelector, state => state.allProjectsMeta);

export const filtersSelector = createSelector(projectsSelector, state => state.filters);

export const userProjectsCountSelector = createSelector(projectsSelector, state => state.userProjectsCount);

export const currentLabelSelector = createSelector(projectsSelector, state => state.currentLabel);

export const artistRosterSelector = createSelector(projectsSelector, state => state.artistRoster);

export const expansionLiveArtistTeamsSelector = createSelector(
  projectsSelector,
  state => state.expansionState.liveArtistTeams
);

export const expansionUpcomingArtistTeamsSelector = createSelector(
  projectsSelector,
  state => state.expansionState.upcomingArtistTeams
);

export const expansionLiveProjectsSelector = createSelector(
  projectsSelector,
  state => state.expansionState.liveProjects
);

export const expansionUpcomingProjectsSelector = createSelector(
  projectsSelector,
  state => state.expansionState.upcomingProjects
);

export const projectsSnapshotsSelector = createSelector(projectsSelector, state => state.projectsSnapshots);

export const expandedLiveArtistIdSelector = createSelector(
  projectsSelector,
  state => state.expansionState.expandedLiveArtistId
);

export const expandedUpcomingArtistIdSelector = createSelector(
  projectsSelector,
  state => state.expansionState.expandedUpcomingArtistId
);
