import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { bem } from 'utils/bem';
import { ModalHeader } from './header';
import { ModalBody } from './body';
import { ModalFooter } from './footer';
import { ModalContainer } from './container';

const { func, element, bool, string, number, oneOfType } = PropTypes;

export const Modal = ({
  className,
  animationDuration,
  title,
  onCancel,
  reverseButtons,
  children,
  onOk,
  okText,
  cancelText,
  width,
  renderFooter,
  renderHeader,
  top,
  transform,
  bodyHeight,
  minWidth,
  bgStyle,
  dataSelector,
  ...props
}) => {
  const classes = bem('modal');
  const contentClassName = classes('content');
  const overlayClassName = classes('overlay');

  return (
    <ReactModal
      closeTimeoutMS={animationDuration}
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      shouldCloseOnEsc
      {...props}
    >
      <ModalContainer
        dataSelector={dataSelector}
        minWidth={minWidth}
        width={width}
        top={top}
        transform={transform}
        bgStyle={bgStyle}
      >
        {renderHeader && <ModalHeader title={title} onClosePress={onCancel} />}
        <ModalBody height={bodyHeight}>{children}</ModalBody>
        {renderFooter && (
          <ModalFooter
            reverseButtons={reverseButtons}
            onOk={onOk}
            onCancel={onCancel}
            okText={okText}
            cancelText={cancelText}
          />
        )}
      </ModalContainer>
    </ReactModal>
  );
};

Modal.propTypes = {
  className: string.isRequired,
  animationDuration: number,
  onCancel: func.isRequired,
  onOk: func,
  children: oneOfType([element, string]),
  cancelText: string,
  okText: string,
  visible: bool,
  title: oneOfType([string.isRequired, element.isRequired]),
  width: oneOfType([number, string]),
  renderFooter: bool,
  renderHeader: bool,
  top: string,
  transform: string,
};

Modal.defaultProps = {
  animationDuration: 200,
  cancelText: 'Cancel',
  okText: 'Ok',
  children: null,
  visible: false,
  width: 400,
  title: '',
  renderFooter: true,
  renderHeader: true,
  onOk: null,
  top: '50%',
  transform: 'translate(-50%, -50%)',
};
