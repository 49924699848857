import styled from 'styled-components';
import { SInput } from 'common/components/form/input/s-input';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'age-editor';
interface Props {
  disabled?: boolean;
}

export const SAgeInput = styled.div<Props>`
  .${BEM_CLASS} {
    &__mask-input {
      ${SInput};

      font-size: 14px;
      font-weight: 500;

      padding: 8px 10px;
      border: 0;
      text-align: end;

      &:focus-within {
        border: 0;
      }

      &::placeholder {
        color: ${manhattanThemeColors.grayedText};
      }
    }
  }
`;
