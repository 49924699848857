import { ArtistMoment, Contact, ProjectDetails, UpcomingProject } from 'backend-api/models';
import { ACCESS_PERMISSIONS } from 'common-v2/constants';
import { LocalRole } from 'common-v2/types';
import { intersection } from 'lodash';

export const makePermissionsChecker = (project?: ProjectDetails | UpcomingProject) => {
  return (roles: LocalRole[]) => {
    if (!project || project.userRoles.length === 0 || !roles) return false;
    const rolesIds = roles.map(role => role?.id);
    const userRolesIds = project.userRoles.map(role => role.id);
    const hasPermissions = intersection(rolesIds, userRolesIds).length > 0;

    return hasPermissions;
  };
};

export const canEditOrRemoveMoment = (
  project: ProjectDetails,
  moment: ArtistMoment,
  currentUser?: Contact
): boolean => {
  if (!currentUser) {
    return false;
  }
  const canEditProject = makePermissionsChecker(project)(ACCESS_PERMISSIONS.EDIT_PROJECT);
  const canEditMoment = currentUser.id === moment.createUser?.id;

  return canEditProject || canEditMoment;
};
