import React, { useCallback, useMemo } from 'react';
import { take } from 'lodash';
import { bem } from 'utils/bem';
import { paths } from 'app/routing/paths';
import { GlobalSearch } from 'backend-api/models';
import { Icon } from 'common/components/icon';
import { RecentProjectsLoader } from 'common/components/loaders/recent-projects-loader';
import { NoResults } from 'common/components/no-results';
import { LoadingState } from 'common/types';
import { H4, SmallText } from 'common/components/typography';
import { ArtistSearchItem, CampaignSearchItem, ProjectSearchItem } from './components';
import { SearchItem } from '../search-item';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import FailedToLoadGraySvg from 'assets/failed-to-load-gray.svg';
import { TOP_ARTISTS_COUNT, TOP_CAMPAIGNS_COUNT, TOP_PROJECTS_COUNT } from './constants';
import { useTaxonomy } from 'hooks';
import { getCampaignIcon, getCampaignLink } from 'app/components/search-modal/transducers';
import { getInfoMessage } from './transducers';
import { BEM_CLASS, STopResults } from './s-top-results';
import { getPathToProjectPage } from 'common/transducers';

const classes = bem(BEM_CLASS);

interface TopResultsProps {
  loading: LoadingState;
  data?: GlobalSearch;
}

export const TopResults = React.memo(({ loading, data }: TopResultsProps) => {
  const { artists, projects = [], campaigns = [] } = data ?? {};

  const projectsToShow = useMemo(() => take(projects, TOP_PROJECTS_COUNT), [projects]);
  const campaignsToShow = useMemo(() => take(campaigns, TOP_CAMPAIGNS_COUNT), [campaigns]);
  const artistsToShow = useMemo(() => take(artists, TOP_ARTISTS_COUNT), [artists]);

  const isArtistsFailed = !artists;
  const isProjectsEmpty = projectsToShow.length === 0;
  const isCampaignsEmpty = campaigns.length === 0;
  const isArtistsEmpty = !!artists && artists.length === 0;
  const hasEmptyData = isProjectsEmpty || isCampaignsEmpty || isArtistsEmpty;

  const taxonomy = useTaxonomy();

  const infoMessage = useMemo(
    () => getInfoMessage(isArtistsFailed, hasEmptyData, isArtistsEmpty, isProjectsEmpty, isCampaignsEmpty),
    [hasEmptyData, isArtistsEmpty, isArtistsFailed, isCampaignsEmpty, isProjectsEmpty]
  );

  const trackProjectClicks = useCallback(
    () => trackEvent(AnalyticsEvents.SEARCH_RESULT_CLICKED, { item_type: 'project' }),
    []
  );
  const trackCampaignClicks = useCallback(
    () => trackEvent(AnalyticsEvents.SEARCH_RESULT_CLICKED, { item_type: 'campaign' }),
    []
  );

  const renderContent = () => {
    if (loading === LoadingState.Idle || loading === LoadingState.Started) {
      return <RecentProjectsLoader className={classes('center')} />;
    }

    if (loading === LoadingState.Failed) {
      return (
        <NoResults
          className={classes('center')}
          withoutFiltersMessage="Data failed to load. Try another search."
          withoutFiltersImage={FailedToLoadGraySvg}
        />
      );
    }

    const isResultEmpty = !data || Object.values(data).every(v => Array.isArray(v) && v.length === 0);

    if (isResultEmpty) {
      return (
        <NoResults
          className={classes('center')}
          withoutFiltersMessage="No result to show. Try another search."
          withFilters
        />
      );
    }

    return (
      <>
        {(hasEmptyData || isArtistsFailed) && (
          <SmallText className={classes('info')}>
            <Icon className={classes('info-icon')} name="no-results" size="large" />
            {infoMessage}
          </SmallText>
        )}
        <div className={classes('container')}>
          {!isProjectsEmpty && (
            <div className={classes('side')}>
              <H4 as="div" className={classes('title')}>
                Projects
              </H4>
              {projectsToShow.map((project, index, arr) => (
                <SearchItem
                  key={project.id}
                  className={classes('item')}
                  targets={project.targets.items}
                  link={getPathToProjectPage(project)}
                  hasBorder={index !== arr.length - 1}
                  onClick={trackProjectClicks}
                >
                  <ProjectSearchItem project={project} />
                </SearchItem>
              ))}
            </div>
          )}
          <div className={classes('side')}>
            {!isArtistsEmpty && !isArtistsFailed && (
              <div className={classes('artists')} data-selector="top-results-artists">
                <H4 className={classes('title')}>Artists</H4>
                {artistsToShow?.map((artist, index, arr) => (
                  <SearchItem
                    key={artist.id}
                    className={classes('item')}
                    link={paths.artist(artist.id)}
                    targets={artist}
                    hasBorder={index !== arr.length - 1}
                  >
                    <ArtistSearchItem title={artist.name} projectsCount={artist.projectsCount} />
                  </SearchItem>
                ))}
              </div>
            )}
            {!isCampaignsEmpty && (
              <div>
                <H4 as="div" className={classes('title')}>
                  Campaigns
                </H4>
                <div className={classes('campaigns')}>
                  {campaignsToShow.map((campaign, index, arr) => {
                    return (
                      <SearchItem
                        key={campaign.uuid}
                        className={classes('item')}
                        link={getCampaignLink(campaign.isUnassigned, campaign.uuid, campaign.project)}
                        hasBorder={index !== arr.length - 1}
                        onClick={trackCampaignClicks}
                        icon={getCampaignIcon(campaign, taxonomy?.categories)}
                      >
                        <CampaignSearchItem key={campaign.uuid} campaign={campaign} />
                      </SearchItem>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return <STopResults>{renderContent()}</STopResults>;
});
