import { Optional } from 'backend-api/models';
import { DecibelLink } from 'utils/decibel-link';
import { LinkOption } from '../../types';

export const getLinksWithLabels = <T extends LinkOption>(links: string[], allowInvalid = false): T[] => {
  return links.reduce<T[]>((previousValue, currentValue) => {
    let link: Optional<DecibelLink> = undefined;

    try {
      link = new DecibelLink(currentValue);
    } catch (error) {
      if (allowInvalid) {
        const invalidLink = {
          id: currentValue,
          value: currentValue,
          label: currentValue,
          isInvalid: true,
        } as T;
        return [...previousValue, invalidLink];
      } else {
        throw error;
      }
    }

    if (!link) {
      return previousValue;
    }

    const linkLabel = link.label;
    const sameTitleCount = previousValue.filter(inner => inner.label?.includes(linkLabel)).length;
    const labelWithIndex = sameTitleCount > 0 ? `${linkLabel} ${sameTitleCount}` : linkLabel;
    const hrefWithIndex = sameTitleCount > 0 ? link.url.href + sameTitleCount : link.url.href;

    const newLink = { id: hrefWithIndex, value: link.url.href, label: labelWithIndex } as T;

    return [...previousValue, newLink];
  }, []);
};
