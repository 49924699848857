import styled from 'styled-components';

export const SRowLoader = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  > * {
    margin-right: 90px;
  }
`;
