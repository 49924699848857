import React, { useMemo } from 'react';
import { getInitials } from 'utils/string';
import { Tooltip } from '../tooltip';
import { DEFAULT_AVATAR_SIZE } from './constants';
import { SUserAvatar } from './s-user-avatar';

interface UserAvatarProps {
  className?: string;
  name: string | string[];
  size?: number;
  isEditor?: boolean;
  hasTooltip?: boolean;
  dataSelector?: string;
}

export const UserAvatar = React.memo(
  ({
    className,
    name,
    size = DEFAULT_AVATAR_SIZE,
    isEditor = false,
    hasTooltip = false,
    dataSelector,
  }: UserAvatarProps) => {
    const content = useMemo(() => {
      if (Array.isArray(name)) {
        if (name.length === 0) return null;
        if (name.length === 1) return getInitials(name[0]);

        return `+${name.length}`;
      } else {
        return getInitials(name);
      }
    }, [name]);
    const tooltipContent = useMemo(() => (Array.isArray(name) ? name.join(', ') : name), [name]);

    return (
      <Tooltip enabled={hasTooltip} content={tooltipContent}>
        <SUserAvatar className={className} size={size} isDark={isEditor} data-selector={dataSelector}>
          {content}
        </SUserAvatar>
      </Tooltip>
    );
  }
);
