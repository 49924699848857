import { Body, H3 } from 'common/components/typography';
import React, { useCallback } from 'react';
import { BEM_CLASS, SMediaPlanSidebar } from './s-media-plan-sidebar';
import { bem } from 'utils/bem';
import { Id, IdNameField } from 'backend-api/models';
import { navigateTo } from 'utils/navigation';
import { paths } from 'app/routing/paths';
import { pluralizeString } from 'utils/string';
import { MediaPlansDropdown } from './components';

const classes = bem(BEM_CLASS);

interface Props {
  projectId: Id;
  mediaPlanList: IdNameField[];
}

export const MediaPlanSidebar = React.memo(({ projectId, mediaPlanList }: Props) => {
  const onViewMediaPlan = useCallback(
    (id: Id) => {
      navigateTo(paths.mediaPlan(projectId, id));
      window.scrollTo(0, 0);
    },
    [projectId]
  );

  return (
    <SMediaPlanSidebar>
      <H3 className={classes('title')}>
        {mediaPlanList.length} {pluralizeString('Plan', mediaPlanList.length)} Created
      </H3>
      <Body className={classes('subtitle')}>Review plan’s phases & details</Body>
      <MediaPlansDropdown onItemClick={onViewMediaPlan} mediaPlanList={mediaPlanList} className={classes('button')} />
    </SMediaPlanSidebar>
  );
});
