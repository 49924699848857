import React, { useCallback, useState } from 'react';
import { bem } from 'utils/bem';
import { Artist, Playlist } from 'backend-api/models';
import { Icon } from 'common/components/icon';
import { TargetsImages } from 'common/components/targets-images';
import { SSelectedArtistPlaylistItem } from './s-selected-artist-playlist-item';
import { Tooltip } from '../tooltip';

interface Props {
  target: Artist | Playlist;
  isLocked?: boolean;
  onRemove(): void;
}

const classes = bem('selected-artist-playlist-item');

export const SelectedArtistPlaylistItem = React.memo(({ target, isLocked = false, onRemove }: Props) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onStartDragging = useCallback(() => {
    if (isLocked) {
      setIsTooltipVisible(true);
    }
  }, [isLocked]);

  const onStopDragging = useCallback(() => {
    if (isLocked) {
      setIsTooltipVisible(false);
    }
  }, [isLocked]);

  return (
    <Tooltip content="The Primary Artist listed in PRS cannot be removed from this project." visible={isTooltipVisible}>
      <SSelectedArtistPlaylistItem
        data-selector="selected-target-item"
        onMouseDown={onStartDragging}
        onMouseUp={onStopDragging}
        onMouseLeave={onStopDragging}
      >
        <TargetsImages targets={target} className={classes('images-container')} />
        <div data-selector="selected-target-item-name" className={classes('info-container')}>
          {target.name}
        </div>
        {isLocked ? (
          <span className={classes('lock-icon')}>
            <Icon name="blocked" size="general" />
          </span>
        ) : (
          <Icon
            dataSelector="remove-selected-target-item-button"
            onClick={onRemove}
            name="removal"
            className={classes('icon')}
            size="general"
            color="pink"
          />
        )}
      </SSelectedArtistPlaylistItem>
    </Tooltip>
  );
});
