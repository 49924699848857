import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { MAX_WIDTH, PADDING } from '../../constants';

export const SEmptySearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 310px;
  max-width: ${MAX_WIDTH}px;
  padding: 6px ${PADDING}px;
  color: ${manhattanThemeColors.grayedText};
`;
