import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { bem } from 'utils/bem';
import {
  BaseOption,
  ClassNamesProps,
  Select as BaseSelect,
  SelectComponents,
  SelectMenuProps,
  SelectType,
} from 'common/components/select';
import { StatusBadge } from '../../../../status-badge';
import { BEM_CLASS, SCommonStatusEditor } from './s-common-status-editor';
import { useSelectEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan/components/spreadsheet/hooks';
import { isSubstring } from 'utils/string';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { CampaignStatuses } from 'backend-api/models';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';

const classes = bem(BEM_CLASS);

export const CommonStatusEditor = React.memo(
  React.forwardRef(({ data, api, rowIndex, colDef, context }: ICellEditorParams, ref) => {
    const [value, setValue] = useState(data.status.value);
    const { onBlur } = useStopEditingBlur(api);

    const options = useMemo(() => {
      const selectedOption = colDef.cellRendererParams.statuses?.find(status => value === status.status);
      const availableOptions = selectedOption
        ? [selectedOption.status].concat(selectedOption.availableTransitions ?? [])
        : [];

      return availableOptions.map(status => ({ id: status, value: status }));
    }, [colDef.cellRendererParams.statuses, value]);

    const handleChange = useCallback(
      (status: CampaignStatuses) => {
        if (value === CampaignStatuses.PENDING && status === CampaignStatuses.DRAFT) {
          trackEvent(AnalyticsEvents.STATUS_CHANGED_BACK_TO_DRAFT_FROM_PENDING, { projectId: context.projectId });
        } else {
          trackEvent(AnalyticsEvents.STATUS_CHANGED_MANUAL, { status, projectId: context.projectId });
        }

        if (status) {
          setValue(status);
          api.setFocusedCell(rowIndex, colDef.field || '');
        }
      },
      [api, colDef.field, rowIndex, value, context.projectId]
    );

    const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions({
      api,
      value,
      setValue: handleChange,
      isMulti: false,
    });

    const selectClassNames = useCallback<(className: string) => ClassNamesProps>(
      () => ({
        control: classes('control'),
        valueContainer: { root: classes('value-container-root'), container: classes('value-container') },
        menu: classes('menu'),
        indicatorsContainer: classes('indicators-container'),
      }),
      []
    );

    const selectComponents = useCallback<(props: SelectMenuProps) => SelectComponents<BaseOption>>(
      () => ({
        SingleValue: () => <StatusBadge status={value} />,
      }),
      [value]
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          ...data.status,
          value,
        };
      },
    }));

    const filterOption = useCallback(
      (option: FilterOptionOption<BaseOption>, inputValue: string): boolean => {
        const selectedOption = colDef.cellRendererParams.statuses?.find(status => value === status.status);

        return (
          selectedOption?.availableTransitions?.includes(option.value) && isSubstring(option.data.value, inputValue)
        );
      },
      [colDef.cellRendererParams.statuses, value]
    );

    return (
      <SCommonStatusEditor statusId={value}>
        {className => (
          <div className={className}>
            <BaseSelect
              selectRef={selectRef}
              type={SelectType.Base}
              valueId={value}
              valueHandler={handleChange}
              options={options}
              classNames={selectClassNames(className)}
              selectComponents={selectComponents}
              filterOption={filterOption}
              isOpened
              isSearchable
              autoFocus
              onKeyDown={onKeyDown}
              onBlur={onBlur}
            />
          </div>
        )}
      </SCommonStatusEditor>
    );
  })
);
