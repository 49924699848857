import { Icon } from 'common/components/icon';
import React, { useCallback, useMemo, useState } from 'react';
import { Id, ProjectReviewer } from 'backend-api/models';
import { bem } from 'utils/bem';
import { pluralizeString } from 'utils/string';
import { Button } from 'common/components/button';
import { RequestApprovalModal, RequestApprovalModalInfo } from '../request-approval-modal';
import { BEM_CLASS, SRequestApprovalFooter } from './s-request-approval-footer';

const classes = bem(BEM_CLASS);

interface RequestApprovalFooterProps {
  count: number;
  reviewers: ProjectReviewer[];
  requestApprovalModalInfo?: RequestApprovalModalInfo;
  isConfidentialProject?: boolean;
  onSubmit(reviewers: Id[], message?: string): void;
  onModalClosed(reviewers: Id[], message?: string): void;
  onCancel(): void;
}

export const RequestApprovalFooter = React.memo(
  ({
    count,
    reviewers,
    requestApprovalModalInfo,
    isConfidentialProject = false,
    onSubmit,
    onCancel,
    onModalClosed,
  }: RequestApprovalFooterProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isWarningVisible, setIsWarningVisible] = useState(false);

    const onFooterSubmit = useCallback(() => {
      if (count > 0) {
        setIsModalVisible(true);
        setIsWarningVisible(false);
      } else {
        setIsWarningVisible(true);
      }
    }, [count]);

    const onModalCancel = useCallback(
      (reviewers: Id[], message?: string) => {
        setIsModalVisible(false);
        onModalClosed(reviewers, message);
      },
      [onModalClosed]
    );

    const counter = useMemo(
      () => (
        <span>
          <b>{count}</b>
          {` ${pluralizeString('campaign', count)} selected`}
        </span>
      ),
      [count]
    );

    const warningMessage = useMemo(
      () => (
        <div className={classes('warning-container')}>
          <Icon className={classes('warning-icon')} size="small" name="warning" color="cinnabar" />
          <span className={classes('empty-checkboxes-text')}>Select your preferred campaign(s) to continue.</span>
        </div>
      ),
      []
    );

    const onConfirm = useCallback(
      (reviewers: Id[], message?: string) => {
        onSubmit(reviewers, message);
        setIsModalVisible(false);
      },
      [onSubmit]
    );

    return (
      <SRequestApprovalFooter>
        <div className={classes('info')}>{isWarningVisible ? warningMessage : counter}</div>
        <div className={classes('buttons')}>
          <Button onClick={onCancel} dataSelector="request-approval-cancel-button">
            cancel
          </Button>
          <Button variant="primary-cta" onClick={onFooterSubmit} dataSelector="request-approval-submit-button">
            Submit Request
          </Button>
        </div>

        {isModalVisible && (
          <RequestApprovalModal
            className={classes('request-approval-modal')}
            reviewers={reviewers}
            isConfidentialProject={isConfidentialProject}
            onConfirm={onConfirm}
            onCancel={onModalCancel}
            initialInfo={requestApprovalModalInfo}
          />
        )}
      </SRequestApprovalFooter>
    );
  }
);
