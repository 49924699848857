import React, { useMemo } from 'react';
import { SSpreadsheetOverlay } from './s-spreadsheet-overlay';
import { TableLoader, ErrorView } from 'common-v2/components';
import { ILoadingOverlayParams } from 'ag-grid-community';
import ErrorImage from 'assets/error_failed_to_load.png';

export enum OverlayType {
  ERROR,
  LOADING,
  NONE,
}

interface Props extends ILoadingOverlayParams {
  type: OverlayType;
  refresh(): void;
}

export const SpreadsheetOverlay = React.memo(({ type, refresh }: Props) => {
  const overlayContent = useMemo(() => {
    switch (type) {
      case OverlayType.LOADING:
        return <TableLoader />;
      case OverlayType.ERROR:
        return (
          <ErrorView
            action={refresh}
            config={{
              description: (
                <>
                  An error occurred while loading your
                  <br />
                  Media Plan data, please try again.
                </>
              ),
              actionTitle: 'Refresh',
              bottomImageSrc: ErrorImage,
            }}
          />
        );
      case OverlayType.NONE:
        return null;
    }
  }, [type, refresh]);

  return <SSpreadsheetOverlay>{overlayContent}</SSpreadsheetOverlay>;
});
