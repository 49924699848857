import * as t from 'io-ts';
import { ArtistSchema } from '../artist';
import { ResponseModel } from '../common';
import { PlaylistSchema } from '../playlist';

export const TargetItemsSchema = t.union([t.array(ArtistSchema), t.array(PlaylistSchema)]);

export type TargetItems = t.TypeOf<typeof TargetItemsSchema>;

export const TargetTypeSchema = t.union([t.literal('Artist'), t.literal('Playlist')]);

export type TargetType = t.TypeOf<typeof TargetTypeSchema>;

const BaseTargetsSchema = ResponseModel(
  {
    items: TargetItemsSchema,
    type: TargetTypeSchema,
  },
  'BaseTargets'
);

export const ArtistTargetsSchema = ResponseModel(
  {
    ...BaseTargetsSchema.props,
    items: t.array(ArtistSchema),
    type: t.literal('Artist'),
  },
  'ArtistTargets'
);

export type ArtistTargets = t.TypeOf<typeof ArtistTargetsSchema>;

export const PlaylistTargetsSchema = ResponseModel(
  {
    ...BaseTargetsSchema.props,
    items: t.array(PlaylistSchema),
    type: t.literal('Playlist'),
  },
  'PlaylistTargets'
);

export type PlaylistTargets = t.TypeOf<typeof PlaylistTargetsSchema>;

export const TargetsSchema = t.union([ArtistTargetsSchema, PlaylistTargetsSchema]);

export type Targets = t.TypeOf<typeof TargetsSchema>;
