import styled from 'styled-components';
import { divider } from '../../../performance/styled';

export const bemClass = 'breakdown-cell';

export const SBreakdownCell = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-height: 40px;
    height: 40px;
    padding: 0;
    align-items: flex-end;

    & > *:not(:last-child) {
      margin-bottom: 19px;
    }

    .${bemClass} {
      &__divider {
        ${divider};
      }
      &__value-container {
        padding: 0 10px;
      }
    }
  }
`;
