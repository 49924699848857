import React from 'react';
import { Typography, TYPOGRAPHY_TYPE, Button, BUTTON_TYPE, THEME } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { BEM_CLASS, SUnclaimedConfidentialProject } from './s-unclaimed-confidential-project';
import { UnclaimedProjectView } from 'project-v2/components';
import ClaimProjectSrc from 'assets/claim-project.png';

const classes = bem(BEM_CLASS);

interface UnclaimedConfidentialProjectProps {
  onClaimProject(): void;
  isClaimingInProgress: boolean;
}

export const UnclaimedConfidentialProject = React.memo(
  ({ onClaimProject, isClaimingInProgress }: UnclaimedConfidentialProjectProps) => (
    <SUnclaimedConfidentialProject>
      <UnclaimedProjectView
        title={
          <Typography type={TYPOGRAPHY_TYPE.body1} data-selector="confidential-project-claim-title">
            Claim This Project
          </Typography>
        }
        description={
          <Typography type={TYPOGRAPHY_TYPE.body2} data-selector="confidential-project-claim-description">
            In order to start working on this project, please claim the project first.
          </Typography>
        }
        actionButton={
          <Button
            type={BUTTON_TYPE.primary}
            isLoading={isClaimingInProgress}
            theme={THEME.light}
            caption="Claim"
            className={classes('button')}
            onClick={onClaimProject}
            data-selector="confidential-project-claim-button"
          />
        }
        imageSrc={ClaimProjectSrc}
      />
    </SUnclaimedConfidentialProject>
  )
);
