import styled from 'styled-components';

export const BEM_CLASS = 'empty-state';

export const SEmptyState = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .${BEM_CLASS} {
    &__text {
      text-align: center;
      margin-top: 25px;
    }
  }
`;
