import styled from 'styled-components';

interface SSkeletonProps {
  rotation: number;
}

export const SSkeleton = styled.div<SSkeletonProps>`
  display: flex;

  linearGradient {
    transform: ${({ rotation }) => `rotate(${rotation}deg)`};
  }
`;
