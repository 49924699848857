import styled from 'styled-components';

export const BEM_CLASS = 'controls-container';

export const SControlsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  .${BEM_CLASS} {
    &__fullscreen {
      margin-left: 15px;
    }
  }
`;
