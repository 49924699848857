import styled from 'styled-components';

export const SProjectsList = styled.div`
  display: grid;
  overflow-y: auto;
  overflow-x: visible;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  padding: 0 20px 0 10px;
  justify-items: stretch;
  width: 100%;
`;
