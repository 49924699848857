import * as t from 'io-ts';
import {
  CampaignTypeAndCategorySchema,
  FormattedDateFromISOString,
  Id,
  IdNameField,
  IdType,
  Optional,
  PaginationResponseModel,
  ResponseModel,
  TerritorySchema,
} from '../common';
import { PurchaseOrderSchema } from '../purchase-order';
import { ReferenceUserSchema } from '../user';
import {
  AudienceAgeSchema,
  CampaignEditableFieldsSchema,
  CampaignProviderSchema,
  CampaignSourceSchema,
  GenderSchema,
  ObjectiveSchema,
} from './common';
import { LinkfireSearchSchema } from '../linkfire';
import { UUID } from 'io-ts-types/lib/UUID';

export * from './guards';
export * from './common';
export * from './goal';
export * from './request';
export * from './update-schedule-request';

export const CampaignTypeSchema = ResponseModel({ ...IdNameField }, 'CampaignType');

export type CampaignType = t.TypeOf<typeof CampaignTypeSchema>;

export const CampaignCategoryAndTypesSchema = ResponseModel(
  { ...IdNameField, campaignTypes: t.array(CampaignTypeSchema) },
  'CampaignCategoryAndTypes'
);

export type CampaignCategoryAndTypes = t.TypeOf<typeof CampaignCategoryAndTypesSchema>;

export const CampaignPlatformSchema = ResponseModel({ ...IdNameField }, 'CampaignPlatform');

export type CampaignPlatform = t.TypeOf<typeof CampaignPlatformSchema>;

export enum CampaignPlatformObjectType {
  GROUP = 'group',
  CATEGORY = 'category',
}

export const CampaignPlatformGroupTypeSchema = t.union(
  [t.literal(CampaignPlatformObjectType.GROUP), t.literal(CampaignPlatformObjectType.CATEGORY)],
  'CampaignStatuses'
);

export interface CampaignPlatformWithPlacements extends IdNameField {
  defaultPlacements: Id[];
  children: Optional<CampaignPlatformWithPlacements[]>;
}

export const CampaignPlatformWithPlacementsSchema: t.Type<CampaignPlatformWithPlacements> = t.recursion(
  'CampaignPlatformWithPlacements',
  () =>
    ResponseModel(
      {
        ...IdNameField,
        defaultPlacements: t.array(IdType),
        children: Optional(t.array(CampaignPlatformWithPlacementsSchema)),
      },
      'CampaignPlatformWithPlacements'
    )
);

export const CampaignPlatformsGroupSchema = ResponseModel(
  {
    ...IdNameField,
    items: t.array(CampaignPlatformWithPlacementsSchema),
    type: CampaignPlatformGroupTypeSchema,
  },
  'CampaignPlatformsGroup'
);

export type CampaignPlatformsGroup = t.TypeOf<typeof CampaignPlatformsGroupSchema>;

export const CampaignPlacementSchema = ResponseModel(
  { ...IdNameField, labelId: Optional(t.string) },
  'CampaignPlacement'
);

export type CampaignPlacement = t.TypeOf<typeof CampaignPlacementSchema>;

export const NewCampaignPlacementSchema = ResponseModel({ name: t.string }, 'NewCampaignPlacement');

export type NewCampaignPlacement = t.TypeOf<typeof NewCampaignPlacementSchema>;

export const TaxonomySchema = ResponseModel(
  {
    categories: t.array(CampaignCategoryAndTypesSchema),
    platforms: t.array(CampaignPlatformsGroupSchema),
    placements: t.array(CampaignPlacementSchema),
  },
  'Taxonomy'
);

export type Taxonomy = t.TypeOf<typeof TaxonomySchema>;

export const CampaignSchema = ResponseModel(
  {
    ...IdNameField,
    uuid: UUID,
    externalId: Optional(t.string),
    type: Optional(CampaignTypeAndCategorySchema),
    platforms: t.array(CampaignPlatformSchema),
    gratis: Optional(t.boolean),
    adSetsCount: t.number,
    startDate: FormattedDateFromISOString,
    endDate: Optional(FormattedDateFromISOString),
    budgetSpend: Optional(t.number),
    plannedBudget: Optional(t.number),
    linkfireLinks: Optional(t.array(LinkfireSearchSchema)),
    isPending: t.boolean,
    source: CampaignSourceSchema,
  },
  'Campaign'
);

export type Campaign = t.TypeOf<typeof CampaignSchema>;

export const CampaignsResponseSchema = PaginationResponseModel(CampaignSchema);

export type CampaignsResponse = t.TypeOf<typeof CampaignsResponseSchema>;

export const CampaignAssignResponseSchema = ResponseModel(
  {
    id: IdType,
    type: CampaignTypeAndCategorySchema,
    platforms: t.array(CampaignPlatformSchema),
    source: CampaignSourceSchema,
  },
  'CampaignAssignResponse'
);

export type CampaignAssignResponse = t.TypeOf<typeof CampaignAssignResponseSchema>;

export const CampaignMetadataSchema = ResponseModel(
  {
    fields: Optional(CampaignEditableFieldsSchema),
  },
  'CampaignMetadata'
);

export type CampaignMetadata = t.TypeOf<typeof CampaignMetadataSchema>;

export enum CampaignStatuses {
  BOOKED = 'Booked',
  LIVE = 'Live',
  COMPLETED = 'Completed',
  DRAFT = 'Draft',
  PENDING = 'Pending Approval',
  APPROVED = 'Approved',
  DISAPPROVED = 'Disapproved',
  PAUSED = 'Paused',
  REVIEW = 'Review',
}

export const CampaignStatusesSchema = t.union(
  [
    t.literal(CampaignStatuses.BOOKED),
    t.literal(CampaignStatuses.LIVE),
    t.literal(CampaignStatuses.COMPLETED),
    t.literal(CampaignStatuses.DRAFT),
    t.literal(CampaignStatuses.PENDING),
    t.literal(CampaignStatuses.APPROVED),
    t.literal(CampaignStatuses.DISAPPROVED),
    t.literal(CampaignStatuses.PAUSED),
    t.literal(CampaignStatuses.REVIEW),
  ],
  'CampaignStatuses'
);

export const CampaignStatusSchema = ResponseModel(
  { status: CampaignStatusesSchema, availableTransitions: Optional(t.array(CampaignStatusesSchema)) },
  'CampaignStatus'
);

export type CampaignStatus = t.TypeOf<typeof CampaignStatusSchema>;

export const CampaignDetailsSchema = ResponseModel(
  {
    id: IdType,
    name: Optional(t.string),
    uuid: UUID,
    projectId: Optional(IdType),
    budgetSpend: Optional(t.number),
    plannedBudget: Optional(t.number),
    ecpm: Optional(t.number),
    startDate: Optional(FormattedDateFromISOString),
    endDate: Optional(FormattedDateFromISOString),
    editDate: FormattedDateFromISOString,
    readOnly: Optional(t.boolean),
    territories: Optional(t.array(TerritorySchema)),
    createUser: Optional(ReferenceUserSchema),
    editUser: Optional(ReferenceUserSchema),
    objective: Optional(ObjectiveSchema),
    type: Optional(CampaignTypeAndCategorySchema),
    platforms: t.array(CampaignPlatformSchema),
    gratis: Optional(t.boolean),
    notes: Optional(t.string),
    phases: Optional(t.array(IdType)),
    audienceAge: Optional(AudienceAgeSchema),
    genders: Optional(t.array(GenderSchema)),
    provider: Optional(CampaignProviderSchema),
    destinationLinks: Optional(t.array(t.string)),
    isPending: t.boolean,
    metadata: CampaignMetadataSchema,
    externalId: Optional(t.string),
    externalName: Optional(t.string),
    source: CampaignSourceSchema,
    purchaseOrder: Optional(PurchaseOrderSchema),
    linkfireLinks: Optional(t.array(LinkfireSearchSchema)),
    adCreativeLinks: Optional(t.array(t.string)),
    adCreativeNotes: Optional(t.string),
    audienceNotes: Optional(t.string),
    workflowStatus: Optional(CampaignStatusSchema),
    kpiMetricsFieldId: Optional(IdType),
    placements: Optional(t.array(CampaignPlacementSchema)),
    currency: Optional(t.string),
  },
  'CampaignDetails'
);

export type CampaignDetails = t.TypeOf<typeof CampaignDetailsSchema>;

export const CampaignDetailsResponseSchema = PaginationResponseModel(CampaignDetailsSchema);

export type CampaignDetailsResponse = t.TypeOf<typeof CampaignDetailsResponseSchema>;
