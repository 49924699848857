import { asyncEpic } from 'core/epics';
import { Api } from 'backend-api';
import { fetchBreakdownData, getAllPurchaseOrders, getPurchaseOrdersProviders } from './actions';
import { map } from 'rxjs/operators';

export const getAllPurchaseOrdersEpic = asyncEpic(getAllPurchaseOrders, action => {
  const { projectId, params } = action.payload;
  return Api.getPurchaseOrders(projectId, params);
});

export const getPurchaseOrdersBreakdownEpic = asyncEpic(fetchBreakdownData, action => {
  const { projectId, entityId } = action.payload;
  return Api.getPurchaseOrderBlanketItems(projectId, entityId).pipe(
    map(response => ({
      data: response,
      entityId,
    }))
  );
});

export const getPurchaseOrdersProvidersEpic = asyncEpic(getPurchaseOrdersProviders, action => {
  return Api.getPurchaseOrdersProviders(action.payload);
});
