import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { Icon } from '../icon';
import { SConfidentialProjectIcon, BEM_CLASS } from './s-confidential-project-icon';
import { Tooltip } from 'common/components/tooltip';
import { IconColor } from '../icon/types';

interface ConfidentialProjectIconProps {
  className?: string;
  color: IconColor;
  isConfidential: boolean;
  children: React.ReactNode;
}

const classes = bem(BEM_CLASS);

export const ConfidentialProjectIcon = React.memo(
  ({ className, children, isConfidential, color }: ConfidentialProjectIconProps) => {
    const tooltipContent = useMemo(
      () => (
        <span>
          Confidential project. Can
          <br />
          only be seen by label leaders
          <br />
          and marketers that have
          <br />
          been added to this project.
        </span>
      ),
      []
    );

    return (
      <SConfidentialProjectIcon isConfidential={isConfidential} className={className}>
        <div className={classes('container')}>
          {isConfidential && (
            <Tooltip content={tooltipContent}>
              <div className={classes('icon')}>
                <Icon
                  name="confidential"
                  size="general"
                  color={color}
                  className={classes('icon')}
                  dataSelector="confidential-project-select"
                />
              </div>
            </Tooltip>
          )}
          {children}
        </div>
      </SConfidentialProjectIcon>
    );
  }
);
