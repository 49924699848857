import numeral from 'numeral';
import { Optional } from 'backend-api/models';
import {
  DECIMAL_NUMERAL_FORMAT,
  NUMERAL_FORMAT,
  PERCENT_FORMAT,
  SHORTEN_NUMERAL_FORMAT,
  SHORTEN_PERCENT_FORMAT,
} from 'common/constants';

export const formatPercent = (value: Optional<string | number>): string =>
  numeral(value)
    .format(PERCENT_FORMAT)
    .toUpperCase();

export const formatShortenPercent = (value: Optional<string | number>): string =>
  numeral(value)
    .format(SHORTEN_PERCENT_FORMAT)
    .toUpperCase();

export const formatShortenNumeral = (value: Optional<string | number>): string =>
  numeral(value)
    .format(SHORTEN_NUMERAL_FORMAT)
    .toUpperCase();

export const formatNumeral = (value: Optional<string | number>): string => numeral(value).format(NUMERAL_FORMAT);

export const formatDecimalNumeral = (value: Optional<string | number>): string =>
  numeral(value).format(DECIMAL_NUMERAL_FORMAT);
