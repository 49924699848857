import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { DEFAULT_MODAL_SIDEBAR_WIDTH } from 'common/components/reusable-modal/constants';

interface SModalSideBarProps {
  width?: string;
}

export const SModalSideBar = styled.div<SModalSideBarProps>`
  width: ${({ width = `${DEFAULT_MODAL_SIDEBAR_WIDTH}px` }) => width};
  background: ${palette.athensGrayTwo};
`;
