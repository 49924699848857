import styled, { css } from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'action-renderer';

interface Props {
  isDisabled?: boolean;
}

export const SActionRenderer = styled.div<Props>`
  height: 100%;

  .${BEM_CLASS} {
    &__placeholder,
    &__content {
      flex: 1;
      display: flex;
      align-items: center;
      overflow: hidden;
    }

    &__content {
      ${({ isDisabled }) =>
        !isDisabled &&
        css`
          &:hover {
            .${BEM_CLASS}__content-icon {
              visibility: visible;
            }
          }
        `};
    }

    &__placeholder-value,
    &__content-value {
      ${elipsizeText};
      color: inherit;
      margin-right: 5px;
    }

    &__placeholder-icon,
    &__content-icon {
      flex-shrink: 0;
      margin-left: auto;
    }

    &__content-icon {
      visibility: hidden;
    }

    &__arrow {
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${colorPalette.lavenderGray};
    }
  }
`;
