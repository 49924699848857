import React, { ReactChild, useCallback, useMemo, useState } from 'react';
import { bem } from 'utils/bem';
import { Artist } from 'backend-api/models';
import { pluralizeString } from 'utils/string';
import { Button } from 'common/components/button';
import { Tabs } from 'common/components/tabs/tabs';
import { Tab } from 'common/components/tabs/tab';
import { SArtistPlaylistWidget } from '../../s-artist-playlist-widget';
import { useSelector } from 'react-redux';
import { activeProjectSelector } from 'common/selectors';

interface Props {
  artists: Artist[];
  className?: string;
  renderCard(target: Artist): ReactChild;
  onShowViewAllTargetsModal(): void;
}

const classes = bem('artist-playlist-widget');

enum ArtistTabs {
  PRIMARY = 'Primary',
  FEATURED = 'Featured',
}

export const ArtistWidget = React.memo(({ className, artists, renderCard, onShowViewAllTargetsModal }: Props) => {
  const [shouldAnimate, setShouldAnimate] = useState(true);
  const targetsSize = artists?.length || 0;
  const shouldExpand = targetsSize > 5;

  const project = useSelector(activeProjectSelector);
  const isUnassigned = !!project && project.isClaimed;

  const primaryArtists = useMemo(() => artists?.filter(artist => artist.type === 'Primary'), [artists]);
  const firstFiveFeaturedArtists = useMemo(() => artists?.filter(artist => artist.type === 'Featured').slice(0, 5), [
    artists,
  ]);
  const isFeaturedEmpty = firstFiveFeaturedArtists.length === 0;
  const isPrimaryEmpty = primaryArtists.length === 0;

  const tooltipPrefix = isUnassigned ? 'Claim' : 'Edit';
  const primaryTooltipText = `${tooltipPrefix} this project to add Featured Artists`;
  const featuredTooltipText = `${tooltipPrefix} this project to add Primary Artists`;

  const onTabChange = useCallback(() => setShouldAnimate(false), []);

  const nonEmptyActiveTab = isPrimaryEmpty ? ArtistTabs.FEATURED : ArtistTabs.PRIMARY;
  const activeTab = isPrimaryEmpty && isFeaturedEmpty ? undefined : nonEmptyActiveTab;

  return (
    <SArtistPlaylistWidget
      shouldAnimate={shouldAnimate}
      shouldExtendBottomOffset={shouldExpand}
      className={className}
      data-selector="targets-widget"
    >
      <Tabs
        title={pluralizeString('Artist' || '', targetsSize)}
        activeTab={activeTab}
        onChange={onTabChange}
        className={classes('tabs')}
      >
        <Tab
          title={ArtistTabs.PRIMARY}
          disabled={isPrimaryEmpty}
          tooltipContent={isPrimaryEmpty ? primaryTooltipText : undefined}
        >
          <div className={classes('artists-container')}>{primaryArtists.map(renderCard)}</div>
        </Tab>
        <Tab
          title={ArtistTabs.FEATURED}
          disabled={isFeaturedEmpty}
          tooltipContent={isFeaturedEmpty ? featuredTooltipText : undefined}
        >
          <div className={classes('artists-container')}>{firstFiveFeaturedArtists.map(renderCard)}</div>
        </Tab>
      </Tabs>
      {isPrimaryEmpty && isFeaturedEmpty && (
        <div className={classes('empty-container')}>
          No artists have been added
          <br />
          to this project.
        </div>
      )}
      {shouldExpand && (
        <Button
          dataSelector="targets-widget-show-all"
          className={classes('button')}
          variant={'secondary-cta'}
          onClick={onShowViewAllTargetsModal}
        >
          {`Show All (${targetsSize})`}
        </Button>
      )}
    </SArtistPlaylistWidget>
  );
});
