import styled from 'styled-components';
import { components } from 'react-select';
import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';

export const SSettingsControl = styled(components.Control)`
  width: 22px;
  height: 22px;
  border: unset;
  color: black;
  min-height: unset;
  border: 1px solid ${transparentize(0.5, colorPalette.mischka)};
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ selectProps }) => (selectProps.menuIsOpen ? colorPalette.azureRadiance : 'white')};

  :hover {
    background-color: ${({ selectProps }) =>
      selectProps.menuIsOpen ? colorPalette.azureRadiance : transparentize(0.9, colorPalette.azureRadiance)};
  }
` as typeof components.Control;
