import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { BEM_CLASS, SUnclaimedProjectWithLockedArtist } from './s-unclaimed-project-with-locked-artist';
import { UnclaimedProjectView } from 'project-v2/components';
import ShareAndConnectSrc from 'assets/share-and-connect.png';

const classes = bem(BEM_CLASS);

interface UnclaimedProjectWithLockedArtistProps {
  artistPagePath: string;
}

export const UnclaimedProjectWithLockedArtist = React.memo(
  ({ artistPagePath }: UnclaimedProjectWithLockedArtistProps) => (
    <SUnclaimedProjectWithLockedArtist>
      <UnclaimedProjectView
        description={
          <Typography
            type={TYPOGRAPHY_TYPE.body2}
            className={classes('description')}
            data-selector="unclaimed-project-description"
          >
            No Artist Team exists yet. To claim this project and begin working on it, navigate here to
          </Typography>
        }
        actionButton={
          <Link to={artistPagePath} target="_blank" rel="noopener noreferrer" className={classes('link')}>
            <Typography type={TYPOGRAPHY_TYPE.body2} data-selector="unclaimed-project-go-to-artist-page-link">
              Artist Page
            </Typography>
          </Link>
        }
        imageSrc={ShareAndConnectSrc}
      />
    </SUnclaimedProjectWithLockedArtist>
  )
);
