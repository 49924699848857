import { Icon } from 'common/components/icon';
import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SValidationTooltipContent } from './s-validation-tooltip-content';

const classes = bem(BEM_CLASS);

interface ValidationTooltipContentProps {
  text: string;
  className?: string;
}

export const ValidationTooltipContent = ({ text, className }: ValidationTooltipContentProps) => (
  <SValidationTooltipContent className={className}>
    <Icon className={classes('warning-icon')} size="small" name="warning" color="cinnabar" />
    <div className={classes('error-text')}>{text}</div>
  </SValidationTooltipContent>
);
