import React from 'react';
import { bem } from 'utils/bem';
import { convertToLowerKebabCase } from 'utils/string';
import { Tab, Tabs } from 'common/components/tabs';
import { BEM_CLASS, SHeader } from './s-header';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { ArtistInfo, ProjectsTab } from 'artist/types';
import { TargetsImages } from 'common/components/targets-images';
import { getTotalProjectsCount } from 'artist/transducers';
import { LoadableData, LoadingState } from 'common/types';
import { Optional, ProjectsMetadata } from 'backend-api/models';
import { Loader, TabbedLoader } from './components';

interface HeaderTab {
  title: ProjectsTab;
  content: React.ReactElement;
}
interface HeaderProps {
  artist: LoadableData<Optional<ArtistInfo>>;
  teamEntry: React.ReactNode;
  counts?: ProjectsMetadata;
  tabs?: HeaderTab[];
  activeTab?: string;
  onTabChange(activeTab: string): void;
}

const classes = bem(BEM_CLASS);

export const Header = React.memo(({ artist, tabs = [], activeTab, onTabChange, teamEntry, counts }: HeaderProps) => {
  const { loading, data } = artist;

  const getTooltipContentForTab = (tab: ProjectsTab) => {
    switch (tab) {
      case ProjectsTab.Upcoming:
        return (
          <span>
            Projects that have a start date in <br /> the future.
          </span>
        );
      case ProjectsTab.InProgress:
        return (
          <span>
            Projects that are currently live <br /> according to their start and <br /> end dates.
          </span>
        );
      case ProjectsTab.Completed:
        return (
          <span>
            Projects that have an <br /> end date in the past.
          </span>
        );
      case ProjectsTab.Unassigned:
        return <span>Projects that don&apos;t have team created.</span>;
      default:
        return undefined;
    }
  };

  return (
    <SHeader hasTabs={!!tabs}>
      <ResponsiveContainer className={classes('container')}>
        {loading !== LoadingState.Started ? (
          <div className={classes('top')} data-selector="artists-name-header">
            <TargetsImages
              className={classes('image')}
              targets={data?.info ?? []}
              dataSelector="header-artist-page-image"
            />
            <h3 className={classes('name')} data-selector="header-artist-name">
              {data?.info.name ?? ''}
            </h3>
            <div className={classes('team-entry')} data-selector="header-team-entry">
              {teamEntry}
            </div>
          </div>
        ) : (
          <div className={classes('loader')}>{tabs ? <TabbedLoader /> : <Loader />}</div>
        )}
      </ResponsiveContainer>
      {tabs && (
        <div className={classes('tabs')}>
          <Tabs activeTab={activeTab} onChange={onTabChange} shouldHideDivider>
            {tabs.map(({ title, content }, index) => (
              <Tab
                key={index}
                title={title}
                badge={getTotalProjectsCount(title, counts)}
                tooltipContent={getTooltipContentForTab(title)}
                dataSelector={`${convertToLowerKebabCase(title)}-artists-tab`}
              >
                {content}
              </Tab>
            ))}
          </Tabs>
        </div>
      )}
    </SHeader>
  );
});
