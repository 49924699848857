import React from 'react';
import { bem } from 'utils/bem';
import { STabbedLoader, BEM_CLASS, CLIP_PATH_ID } from './s-tabbed-loader';

const classes = bem(BEM_CLASS);

export const TabbedLoader = React.memo(() => {
  return (
    <STabbedLoader clipPathId={CLIP_PATH_ID}>
      <div className={classes('container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <path
              d={`M0 0 40 0 40 40 0 40 0 0z
                  M60 10 260 10 260 30 60 30 60 10z
                  M0 75 80 75 80 90 0 90 0 75z
                  M120 75 200 75 200 90 120 90 120 75z
                  M240 75 320 75 320 90 240 90 240 75z
                  M360 75 440 75 440 90 360 90 360 75z`}
            />
          </clipPath>
        </defs>
      </svg>
    </STabbedLoader>
  );
});
