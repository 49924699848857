import styled, { css } from 'styled-components';
import { ellipsizeText } from 'common-v2/styles';
import { colorPalette } from 'app/theme/colors';

interface Props {
  isDisabled: boolean;
}

export const SSingleValue = styled.div<Props>`
  ${ellipsizeText};
  line-height: initial;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          color: ${colorPalette.gray};
        `
      : css`
          color: ${colorPalette.vulcan};
        `}
`;
