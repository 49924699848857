import React, { useCallback, useMemo } from 'react';
import { Id } from 'backend-api/models';
import { Typography, TYPOGRAPHY_TYPE, Button, BUTTON_SIZE, BUTTON_TYPE, THEME } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { Tooltip } from 'common-v2/components/tooltip';
import { SCampaignItem, BEM_CLASS } from './s-campaign-item';

const classes = bem(BEM_CLASS);

interface Props {
  id: Id;
  title?: string;
  disabled?: boolean;
  onCampaignDelete?(id: number): void;
}

export const CampaignItem = React.memo(({ id, title, disabled, onCampaignDelete }: Props) => {
  const clickHandler = useCallback(() => {
    onCampaignDelete?.(id);
  }, [onCampaignDelete, id]);

  const tooltipContent = useMemo(() => <Typography type={TYPOGRAPHY_TYPE.body4}>Delete Campaign</Typography>, []);

  return (
    <SCampaignItem>
      <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('name')}>
        {title ?? ''}
      </Typography>
      <Tooltip content={tooltipContent}>
        <Button
          size={BUTTON_SIZE.smallRound}
          icon="delete"
          type={BUTTON_TYPE.tertiary}
          theme={THEME.light}
          disabled={disabled}
          className={classes('delete')}
          onClick={clickHandler}
        />
      </Tooltip>
    </SCampaignItem>
  );
});
