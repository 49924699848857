import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { theme } from 'app/theme';

export const BEM_CLASS = 'media-plan-mode-controls';

export const SMediaPlanModeControls = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__button {
      ${theme.typography.primaryLabel};
      padding: 10px 20px;
      border: 1px solid ${colorPalette.mischka};
      border-radius: 5px;
      margin: 10px;
      font-weight: 700;
      text-transform: uppercase;
      color: ${colorPalette.black};

      &--isActive {
        border-color: transparent;
        background-color: ${colorPalette.eastBay};
        color: ${colorPalette.white};
      }
    }
  }
`;
