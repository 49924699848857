import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'links-readonly-editor';

export const SLinksReadonlyEditor = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${colorPalette.alabaster};
  align-items: flex-start;

  .${BEM_CLASS} {
    &__links-container {
      display: flex;
      flex-wrap: wrap;
      row-gap: 5px;
      align-content: flex-start;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 15px;
    }
    &__link {
      border: 0.5px solid ${colorPalette.lavenderGray};
      border-radius: 5px;
      background-color: ${colorPalette.alabaster};
      margin-right: 5px;
      padding: 0 3px;
      ${elipsizeText};

      &--invalid {
        opacity: 0.7;
        color: ${colorPalette.osloGray};
        pointer-events: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    &__copy-all {
      color: black;
      text-transform: uppercase;
      font-weight: 800;
      padding: 15px;

      &:hover {
        color: ${colorPalette.ceriseRed};
      }
    }

    &__error-notification {
      height: 42px;
      display: flex;
      align-items: center;
      padding: 10px;
    }
    &__divider {
      width: 100%;
      height: 2px;
      background: ${colorPalette.athensGray};
    }
  }
`;
