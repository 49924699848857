import React from 'react';
import Tippy from '@tippyjs/react';
import { Instance, Placement } from 'tippy.js';
import { popper } from 'app/styles/z-indexes';

interface Props {
  content?: React.ReactNode;
  onTrigger?(instance: Instance, event: Event): void;
  onShow?(instance: Instance): void;
  onHide?(instance: Instance): void;
  onClickOutside?(instance: Instance, event: Event): void;
  placement?: Placement;
  disabled?: boolean;
  visible?: boolean;
  appendTo?: Element;
  flipBoundary?: React.ReactNode;
  children: React.ReactElement;
  maxWidth?: string | number;
  offset?: [number, number];
}

export const Popper = React.memo(
  React.forwardRef<Element, Props>(
    (
      {
        content,
        disabled,
        flipBoundary,
        children,
        onClickOutside,
        onShow,
        onHide,
        placement = 'bottom',
        visible,
        appendTo,
        maxWidth,
        offset,
      }: Props,
      ref
    ) => (
      <Tippy
        content={content}
        disabled={disabled}
        trigger="mousedown"
        ref={ref}
        interactive
        arrow={false}
        popperOptions={{
          modifiers: [
            {
              name: 'flip',
              options: {
                boundary: flipBoundary ?? 'clippingParents',
              },
            },
          ],
        }}
        onClickOutside={onClickOutside}
        onShow={onShow}
        onHide={onHide}
        duration={0}
        placement={placement}
        zIndex={popper}
        visible={visible}
        appendTo={appendTo}
        maxWidth={maxWidth}
        offset={offset}
      >
        {children}
      </Tippy>
    )
  )
);
