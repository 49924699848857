import { colorPalette } from 'app/theme/colors';
import { CampaignStatuses } from 'backend-api/models';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { getOptionIndicatorStyle } from './transducers';

export const BEM_CLASS = 'approval-select-option';

const hoverState = css`
  color: ${colorPalette.azureRadiance};
  background-color: ${transparentize(0.9, colorPalette.azureRadiance)};
`;

interface Props {
  isDisabled: boolean;
  isFocused?: boolean;
  status: CampaignStatuses;
}

export const SApprovalSelectOption = styled.div<Props>`
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 10px;

  ${({ isDisabled, isFocused }) =>
    isDisabled
      ? css``
      : css`
          cursor: pointer;
          ${isFocused && hoverState};
        `}

  .${BEM_CLASS} {
    &__option-indicator {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: ${colorPalette.chateauGreen};
      margin-right: 10px;
      ${({ status }) => getOptionIndicatorStyle(status)}
    }
  }
`;
