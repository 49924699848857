import qs from 'qs';
import { createBrowserHistory, Location } from 'history';
import { RouteComponentProps } from 'react-router-dom';

export interface BaseState {
  [key: string]: any;
}

interface Params {
  query?: {} | null;
  state?: BaseState | undefined;
}

export const history = createBrowserHistory<BaseState>();

const getLocation = (pathname: string, params: Params = { query: {}, state: {} }) => ({
  pathname: pathname || history.location.pathname,
  search: qs.stringify(params.query, { strictNullHandling: true }),
  state: { ...params.state, from: history.location.pathname },
});

export const navigateTo = (pathname: string, params?: Params) => {
  history.push(getLocation(pathname, params));
};

export const goBack = (emptyStackCallback?: VoidFunction) => {
  if (!!history.location.state?.from) {
    history.goBack();
  } else {
    emptyStackCallback?.();
  }
};

export const go = (n: number, emptyStackCallback?: VoidFunction) => {
  if (!!history.location.state?.from) {
    history.go(n);
  } else {
    emptyStackCallback?.();
  }
};

export const replaceTo = (pathname: string, params?: Params) => {
  history.replace(getLocation(pathname, params));
};

export const getSearchParams = (componentProps: RouteComponentProps) => {
  const location = componentProps.location;

  return getSearchParamsFromLocation(location);
};

export const getSearchParamsFromLocation = <T>(location: Location<T>) => {
  const search = location?.search || '';

  return qs.parse(search.substring(1), { arrayLimit: 1000, strictNullHandling: true });
};
