import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const STitle = styled.h6`
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: ${palette.darkBlue};
  font-weight: 400;
`;

export const SPoint = styled.span`
  &::after {
    content: '•';
    display: block;
    margin: 0 6px;
  }
`;
