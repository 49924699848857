import React from 'react';
import { bem } from 'utils/bem';
import { SAutocompleteLoader } from './s-autocomplete-loader';

interface Props {
  maxWidth?: number;
}

export const CLIP_PATH_ID = 'loader-autocomplete';

const classes = bem('autocomplete-loader');

export const AutocompleteLoader = React.memo(({ maxWidth }: Props) => {
  return (
    <SAutocompleteLoader clipPathId={CLIP_PATH_ID} maxWidth={maxWidth}>
      <div className={classes('clip-container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <rect x="25" y="10" rx="0" ry="0" width={300} height={15} />
            <rect x="25" y="40" rx="0" ry="0" width={300} height={15} />
            <rect x="25" y="70" rx="0" ry="0" width={300} height={15} />
          </clipPath>
        </defs>
      </svg>
    </SAutocompleteLoader>
  );
});
