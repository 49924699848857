import { BaseOption } from 'common/components/form/select';
import { DateValues } from 'common/types';
import { DateFormatPatterns } from 'common-v2/constants';

export const DATE_FORMAT_OPTIONS: BaseOption[] = [
  {
    id: DateFormatPatterns[0],
    name: 'Month / Day / Year | AM / PM',
  },
  {
    id: DateFormatPatterns[1],
    name: 'Month / Day / Year | 24 Hour',
  },
  {
    id: DateFormatPatterns[2],
    name: 'Day / Month / Year | AM / PM',
  },
  {
    id: DateFormatPatterns[3],
    name: 'Day / Month / Year | 24 Hour',
  },
];

export const DATE_FORMAT_BY_DATE_TIME_FORMAT: Record<string, string> = {
  [DateFormatPatterns[0]]: 'MM/dd/yy',
  [DateFormatPatterns[1]]: 'MM/dd/yy',
  [DateFormatPatterns[2]]: 'dd/MM/yy',
  [DateFormatPatterns[3]]: 'dd/MM/yy',
};

export const DATE_FORMAT_SHORT: Record<string, DateValues> = {
  [DateFormatPatterns[0]]: {
    date: 'MMM dd',
    time: 'hh:mm aaa',
  },
  [DateFormatPatterns[1]]: {
    date: 'MMM dd',
    time: 'HH:mm',
  },
  [DateFormatPatterns[2]]: {
    date: 'dd MMM',
    time: 'hh:mm aaa',
  },
  [DateFormatPatterns[3]]: {
    date: 'dd MMM',
    time: 'HH:mm',
  },
};
