import { Id } from 'backend-api/models';

interface ModelWithId {
  id: Id;
}

export function toggleElementInArray<T extends ModelWithId>(arr: T[], element: T) {
  const isElementExists = arr.find(({ id }) => id === element.id);

  return isElementExists ? arr.filter(({ id }) => id !== element.id) : [...arr, element];
}
