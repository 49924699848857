import styled from 'styled-components';

export const SAdSetsSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 29px;
  margin-bottom: 22px;

  .ad-sets-section {
  }
`;
