import styled from 'styled-components';
import { TableTheme } from 'app/theme/table';

interface Props {
  withoutRoundCorners?: boolean;
  tableTheme: TableTheme;
}

export const STableBody = styled.tbody<Props>`
  & > :nth-last-child(2),
  & > :nth-last-child(1) {
    & > :first-child {
      border-radius: ${({ withoutRoundCorners }) => (withoutRoundCorners ? 'unset' : '0 0 0 10px')};
      overflow: hidden;
    }
    & > :last-child {
      border-radius: ${({ withoutRoundCorners }) => (withoutRoundCorners ? 'unset' : '0 0 10px 0')};
    }
  }

  .table-body {
    &__bottom-spacer {
      background: ${({ tableTheme, theme }) => theme.table[tableTheme].tableBackgroundColor};
    }
  }
`;
