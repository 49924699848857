import * as t from 'io-ts';
import { ResponseModel, Optional, ImageSchema, IdType } from '../common';
import { LabelSchema } from '../label';
import { TeamUserSchema } from '../user';

export const ArtistTypeSchema = t.union([t.literal('Primary'), t.literal('Featured')]);

export type ArtistType = t.TypeOf<typeof ArtistTypeSchema>;

export const ArtistSchema = ResponseModel(
  {
    id: t.string,
    name: t.string,
    isSony: t.boolean,
    image: Optional(ImageSchema),
    projectsCount: Optional(t.number),
    type: ArtistTypeSchema,
    isPRS: Optional(t.boolean),
    isUnknown: t.boolean,
  },
  'Artist'
);

export type Artist = t.TypeOf<typeof ArtistSchema>;

export const ArtistTeamSchema = ResponseModel(
  {
    artistTeamId: IdType,
    label: LabelSchema,
    users: t.array(TeamUserSchema),
  },
  'ArtistTeam'
);

export type ArtistTeam = t.TypeOf<typeof ArtistTeamSchema>;

export const UpdateArtistTeamUserSchema = ResponseModel(
  {
    userId: IdType,
    roleId: IdType,
    categoryIds: t.array(IdType),
  },
  'UpdateArtistTeamUser'
);

export type UpdateArtistTeamUser = t.TypeOf<typeof UpdateArtistTeamUserSchema>;

export const UpdateArtistTeamSchema = ResponseModel(
  {
    labelId: IdType,
    added: t.array(UpdateArtistTeamUserSchema),
    deleted: t.array(UpdateArtistTeamUserSchema),
    updated: t.array(UpdateArtistTeamUserSchema),
  },
  'UpdateArtistTeam'
);

export type UpdateArtistTeam = t.TypeOf<typeof UpdateArtistTeamSchema>;

export const ShortArtistSchema = ResponseModel(
  {
    id: t.string,
    name: t.string,
    imageUrl: t.string,
  },
  'ShortArtist'
);

export type ShortArtist = t.TypeOf<typeof ShortArtistSchema>;

export const ModifyArtistsListSchema = ResponseModel({ artistId: t.array(t.string) }, 'ModifyArtistsList');

export type ModifyArtistsList = t.TypeOf<typeof ModifyArtistsListSchema>;

export const ModifyMembersListSchema = ResponseModel({ userId: t.array(IdType) }, 'ModifyMembersList');

export type ModifyMembersList = t.TypeOf<typeof ModifyMembersListSchema>;
