import styled from 'app/styled';
import { SInput, SInputProps } from 'common/components/form/input/s-input';
import { typography } from 'app/theme/typography';
import { colorPalette } from 'app/theme/colors';
import { FieldComposition } from './types';

export const BEM_CLASS = 'limited-length-text-input';

interface SLimitedLengthTextFieldProps extends SInputProps {
  composition: FieldComposition;
}

export const SLimitedLengthTextField = styled.div<SLimitedLengthTextFieldProps>`
  ${SInput};
  position: relative;
  display: flex;
  flex-direction: ${({ composition }) => (composition === FieldComposition.VERTICAL ? 'column' : 'row')};
  cursor: text;
  .${BEM_CLASS} {
    &__input {
      border: none;
      background: transparent;
      width: 100%;
      text-align: start;
      resize: none;
      flex-grow: 1;
    }
    &__limit {
      display: flex;
      align-self: flex-end;
      padding: ${({ composition }) => (composition === FieldComposition.VERTICAL ? '15px 0 11px;' : '0 0 6px 10px')};
      ${typography.primaryLabel};
    }
    &__max-text {
      margin-right: 5px;
      color: ${colorPalette.ceriseRed};

      &--hidden {
        visibility: hidden;
      }
    }
  }
`;
