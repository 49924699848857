import React, { useCallback, useMemo } from 'react';
import { bem } from 'utils/bem';
import { useDispatch } from 'react-redux';
import { TargetItems, ArtistSearch, ArtistSearchSchema } from 'backend-api/models';
import { TargetsImages } from 'common/components/targets-images';
import { hideGlobalSearch } from 'common/actions';
import { SSearchItem, BEM_CLASS } from './s-search-item';
import { getPrimaryTargets } from 'common/transducers';
import { Icon } from 'common/components/icon';

const classes = bem(BEM_CLASS);

interface RecentSearchItemProps {
  className?: string;
  children: React.ReactNode;
  link: string;
  targets?: TargetItems | ArtistSearch;
  hasBorder?: boolean;
  icon?: React.ReactElement;
  onClick?(): void;
}

export const SearchItem = React.memo(
  ({ className, link, targets, children, hasBorder = true, icon, onClick }: RecentSearchItemProps) => {
    const dispatch = useDispatch();

    const image = useMemo(() => {
      if (icon)
        return (
          <div className={classes('icon-container')}>
            <div className={classes('icon')}>{icon}</div>
          </div>
        );

      if (targets) {
        const primaryTargets = ArtistSearchSchema.is(targets) ? targets : getPrimaryTargets(targets);

        return (
          <TargetsImages
            targets={primaryTargets}
            className={classes('image', { rounded: ArtistSearchSchema.is(primaryTargets) })}
            gap
          />
        );
      }

      return (
        <div className={classes('icon-container')}>
          <div className={classes('icon')}>
            <Icon color="black" name="note" size="large" />
          </div>
        </div>
      );
    }, [icon, targets]);

    const onLinkClick = useCallback(() => {
      onClick && onClick();
      dispatch(hideGlobalSearch());
    }, [dispatch, onClick]);

    return (
      <SSearchItem
        className={className}
        to={`${link}?fromSearch=1`}
        $hasBorder={hasBorder}
        onClick={onLinkClick}
        $isDisabled={link === ''}
      >
        {image}
        <div className={classes('content')} data-selector="search-result-top-result-item">
          {children}
        </div>
      </SSearchItem>
    );
  }
);
