import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'request-approval-control';

export const SRequestApproval = styled.div`
  .${BEM_CLASS} {
    &__button {
      &:not(&--isDisabled) {
        border: 1px solid ${transparentize(0.6, manhattanThemeColors.primaryFillNew)};
      }
    }
  }
`;
