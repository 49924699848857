import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { Property } from 'csstype';

interface SUserAvatarProps {
  size: number;
  avatarColor: Property.Color;
}

export const SAvatar = styled.div<SUserAvatarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background: ${({ avatarColor }) => avatarColor};
  color: ${colorPalette.black};
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
`;
