import React, { useCallback, useMemo } from 'react';
import { useMeasure } from 'react-use';
import { bem } from 'utils/bem';
import { TargetItems, Artist, Playlist, ArtistSearch } from 'backend-api/models';
import { STargetImage, STargetsImages } from './s-targets-images';
import { NoBrokenIconImage } from '../no-broken-icon-image';
import { getTargetImageUrl, getTargetImageType, getTargetImageSizeMode } from './transducers';
import { TargetsImagesDisplayMode } from './types';

interface Props {
  targets: TargetItems | Artist | Playlist | ArtistSearch | ArtistSearch[];
  gap?: boolean;
  className?: string;
  isOverlayDisabled?: boolean;
  dataSelector?: string;
  hasGradient?: boolean;
}

const classes = bem('targets-images');

export const TargetsImages = React.memo(
  ({ targets, gap, className, isOverlayDisabled, dataSelector, hasGradient }: Props) => {
    const [ref, { height: containerHeight }] = useMeasure();

    const getTargetImage = useCallback(
      (target: Artist | Playlist | ArtistSearch, displayMode?: TargetsImagesDisplayMode) => {
        const targetType = getTargetImageType(target);
        const sizeMode = getTargetImageSizeMode(containerHeight, displayMode);
        const source = getTargetImageUrl(target);

        return (
          <div className={classes('image-container', displayMode)}>
            <STargetImage targetType={targetType} sizeMode={sizeMode} hasGradient={hasGradient}>
              <NoBrokenIconImage alt="" src={source} className={classes('image')} data-selector={dataSelector} />
            </STargetImage>
          </div>
        );
      },
      [containerHeight, dataSelector, hasGradient]
    );

    const images = useMemo(() => {
      if (!Array.isArray(targets)) {
        return getTargetImage(targets, 'full');
      }

      if (targets.length <= 0) return null;

      if (targets.length === 1) {
        return getTargetImage(targets[0], 'full');
      }

      if (targets.length === 2) {
        return (
          <>
            {getTargetImage(targets[0], 'half')}
            {getTargetImage(targets[1], 'half')}
          </>
        );
      }

      if (targets.length === 3) {
        return (
          <>
            {getTargetImage(targets[0], 'half')}
            {getTargetImage(targets[1])}
            {getTargetImage(targets[2])}
          </>
        );
      }

      return (
        <>
          {getTargetImage(targets[0])}
          {getTargetImage(targets[1])}
          {getTargetImage(targets[2])}
          {getTargetImage(targets[3])}
        </>
      );
    }, [targets, getTargetImage]);

    return (
      <STargetsImages ref={ref} gap={gap} className={className}>
        {images}
        {Array.isArray(targets) && targets.length > 4 && !isOverlayDisabled && (
          <div className={classes('overlay')}>
            <span className={classes('total')}>{targets.length}</span>
          </div>
        )}
      </STargetsImages>
    );
  }
);
