import React, { useMemo, useCallback, useRef, useEffect } from 'react';
import { usePrevious } from 'react-use';
import { Typography, TYPOGRAPHY_TYPE, SegmentedButtonGroup, SegmentButton } from 'gdb-web-shared-components';
import { GlobalSearch } from 'backend-api/models';
import { bem } from 'utils/bem';
import { InfiniteScroll } from 'common-v2/components';
import { ArtistCard } from '../artist-card';
import { CampaignCard } from '../campaign-card';
import { ProjectCard } from '../project-card';
import { GlobalSearchTab } from '../../types';
import { TABS } from '../../constants';
import { CardLoader, ArtistCardLoader } from './components';
import { SGlobalSearchResults, BEM_CLASS } from './s-global-search-results';
import { isPaginationActive } from './transducers';
import { LoadingState } from 'common/types';

export interface GlobalSearchResultsProps {
  className?: string;
  dateFormat: string;
  data: GlobalSearch;
  tabs: GlobalSearchTab[];
  loading: LoadingState;
  updateTabs(tabs: GlobalSearchTab[]): void;
  fetchPage(): void;
}

const classes = bem(BEM_CLASS);

export const GlobalSearchResults = React.memo(
  ({ className, dateFormat, data, tabs, loading, updateTabs, fetchPage }: GlobalSearchResultsProps) => {
    const { projects, artists = [], campaigns, metadata } = data;
    const { counts } = metadata;

    const scrollerRef = useRef<HTMLDivElement>(null);
    const previousTabs = usePrevious(tabs);

    const cardsConfigByTab = useMemo(
      () => ({
        [GlobalSearchTab.PROJECTS]: { component: ProjectCard, data: projects, count: counts.projects },
        [GlobalSearchTab.ARTISTS]: { component: ArtistCard, data: artists, count: counts.artists },
        [GlobalSearchTab.CAMPAIGNS]: { component: CampaignCard, data: campaigns, count: counts.campaigns },
      }),
      [projects, counts, artists, campaigns]
    );

    const orderedTabs = useMemo(
      () =>
        TABS.filter(tab => tabs.includes(tab)).map(tab => ({
          tab,
          component: cardsConfigByTab[tab].component,
          data: cardsConfigByTab[tab].data,
          count: cardsConfigByTab[tab].count,
        })),
      [cardsConfigByTab, tabs]
    );

    const isInfiniteScrollActive = useMemo(() => isPaginationActive(orderedTabs), [orderedTabs]);

    const emptyData = useMemo(
      () => (
        <Typography className={classes('empty')} type={TYPOGRAPHY_TYPE.body4}>
          No results found.
          <br />
          Please try editing your query.
        </Typography>
      ),
      []
    );

    const loader = useMemo(
      () => (
        <div className={classes('grid', 'loader')}>
          {orderedTabs.map(({ tab, count, data }) => {
            if (!count || data.length === count) return <div />;

            if (tab === GlobalSearchTab.ARTISTS) {
              return <ArtistCardLoader className={classes('card-loader')} />;
            }

            return <CardLoader key={tab} className={classes('card-loader')} />;
          })}
        </div>
      ),
      [orderedTabs]
    );

    const onTabSelect = useCallback(
      (tabs: GlobalSearchTab[]) => {
        if (tabs.length === 0) return;

        updateTabs(tabs);
      },
      [updateTabs]
    );

    useEffect(() => {
      const scrollerElement = scrollerRef && scrollerRef.current;
      const isTabEnabled = previousTabs && previousTabs.length < tabs.length;

      if (scrollerElement && isTabEnabled) {
        scrollerElement.scrollTo(0, 0);
      }
    }, [previousTabs, previousTabs?.length, tabs]);

    return (
      <SGlobalSearchResults className={className} columns={orderedTabs.length}>
        <header className={classes('header')}>
          <div className={classes('switcher')}>
            <SegmentedButtonGroup value={tabs} onChange={onTabSelect}>
              {TABS.map(tab => (
                <SegmentButton key={tab} value={tab} data-selector={`${tab.toLowerCase()}-tab`}>
                  <Typography type={TYPOGRAPHY_TYPE.subtitle3}>{tab}</Typography>
                </SegmentButton>
              ))}
            </SegmentedButtonGroup>
          </div>

          <div className={classes('grid')}>
            {orderedTabs.map(({ tab, count }) => (
              <div key={tab} className={classes('column')}>
                <Typography type={TYPOGRAPHY_TYPE.body3} data-selector={`${tab.toLowerCase()}-column-header`}>
                  {tab} ({count})
                </Typography>
              </div>
            ))}
          </div>
        </header>

        <InfiniteScroll
          ref={scrollerRef}
          className={classes('content')}
          onScrollEnd={fetchPage}
          loader={loader}
          isActive={isInfiniteScrollActive}
          isLoading={loading === LoadingState.Started}
        >
          <div className={classes('grid')}>
            {orderedTabs.map(({ tab, component: Component, data }) => (
              <section key={tab} className={classes('column')} data-selector={`${tab.toLowerCase()}-column`}>
                {data.length > 0
                  ? data.map(data => <Component {...data} key={data.id} dateFormat={dateFormat} isSearched />)
                  : emptyData}
              </section>
            ))}
          </div>
        </InfiniteScroll>
      </SGlobalSearchResults>
    );
  }
);
