import { isArtistTeamEditor } from 'common/transducers';
import { makePermissionsChecker } from 'common-v2/transducers';
import { MediaPlanAccessMode } from 'media-plan/components/spreadsheet/types';
import { artistTeamSelector } from 'project/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { activeProjectSelector } from 'common/selectors';
import { useEffect } from 'react';
import { getProject, getProjectArtistTeam } from 'common/actions';
import { Id } from 'backend-api/models';
import { MediaPlanMode } from 'media-plan/types';
import { ACCESS_PERMISSIONS } from 'common-v2/constants';
import { useAppContext } from 'common-v2/hooks';

export const useMediaPlanAccessMode = (projectId: Id, mediaPlanMode: MediaPlanMode) => {
  const dispatch = useDispatch();
  const { user } = useAppContext();

  const project = useSelector(activeProjectSelector);
  const artistTeam = useSelector(artistTeamSelector);
  const isNotConfidential = !project?.isConfidential;
  const isTeamEditor = user && isArtistTeamEditor(user, artistTeam.data?.users || []);
  const isAdminOrEditor = makePermissionsChecker(project)(ACCESS_PERMISSIONS.EDIT_MEDIA_PLAN);
  const isRequestApproval = mediaPlanMode === MediaPlanMode.REQUEST_APPROVAL;
  const isApproval = mediaPlanMode === MediaPlanMode.APPROVAL;

  useEffect(() => {
    if (!project) dispatch(getProject.request({ projectId }));
    if (!artistTeam) dispatch(getProjectArtistTeam.request(projectId));
  }, [artistTeam, dispatch, project, projectId]);

  if ((isAdminOrEditor && !isRequestApproval) || isApproval) return MediaPlanAccessMode.EDIT;
  if (isTeamEditor && isNotConfidential && !isRequestApproval) return MediaPlanAccessMode.EDIT;

  return MediaPlanAccessMode.READ_ONLY;
};
