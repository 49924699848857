import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';
import { TargetsImagesTargetType, TargetsImagesSizeMode } from './types';

interface STargetImageProps {
  targetType: TargetsImagesTargetType;
  sizeMode: TargetsImagesSizeMode;
  hasGradient?: boolean;
}

export const STargetImage = styled.div<STargetImageProps>`
  flex: 1;
  display: flex;
  background-color: ${colorPalette.mischka};
  ${({ hasGradient }) =>
    hasGradient &&
    `background-image: linear-gradient(180deg, ${transparentize(0.4, colorPalette.darkBlue)} 0%, ${
      colorPalette.darkBlue
    } 100%)`};

  background-blend-mode: multiply;
`;

interface STargetImagesProps {
  gap?: boolean;
}

export const STargetsImages = styled.div<STargetImagesProps>`
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, minmax(19px, 1fr));
  grid-template-rows: repeat(2, minmax(19px, 1fr));
  gap: ${props => (props.gap ? '2px' : '0')};

  .targets-images {
    &__image-container {
      display: flex;

      &--full {
        grid-row: 1/3;
        grid-column: 1/3;
      }

      &--half {
        grid-row: 1/3;
      }
    }

    &__image {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__total {
      color: white;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.31px;
      line-height: 18px;
    }
  }
`;
