import React, { useCallback, useState } from 'react';
import { SStaticSection } from './s-static-section';
import { ModelWithId, SectionConfig } from 'common/components/selected-section';
import { bem } from 'utils/bem';
import { AnimatePresence, motion } from 'framer-motion';
import { SectionTitle } from '../section-title';

interface Props<T extends ModelWithId> {
  config: SectionConfig<T>;
}

const classes = bem('static-section');

const expandAnimationProps = {
  initial: { height: 0, opacity: 0 },
  exit: { height: 0, opacity: 0 },
  animate: { height: 'unset', opacity: 1 },
  transition: { type: 'spring', damping: 15, mass: 0.5 },
};

export const StaticSection = <T extends ModelWithId>({ config }: Props<T>) => {
  const { title, selectedItems, maxCount, withCounter, isExpandable, warningText } = config;
  const [isExpanded, setIsExpanded] = useState(true);

  const renderBody = useCallback(
    ({ selectedItems, placeholder, containerItemClassName, renderItem }: SectionConfig<T>) => {
      if (selectedItems.length === 0) {
        return (
          <div className={classes('placeholder-container')}>
            <div className={classes('placeholder')}>{placeholder}</div>
          </div>
        );
      }

      return selectedItems.map(item => (
        <div key={item.id} className={classes('list-item-container', undefined, containerItemClassName)}>
          {renderItem(item)}
        </div>
      ));
    },
    []
  );

  return (
    <SStaticSection>
      {title && (
        <SectionTitle
          title={title}
          currentCount={selectedItems.length}
          maxCount={maxCount}
          withCounter={withCounter}
          isSectionExpandable={isExpandable && selectedItems.length > 0}
          isSectionExpanded={isExpanded}
          sectionExpandHandler={setIsExpanded}
          warningText={warningText}
        />
      )}
      <AnimatePresence exitBeforeEnter initial={false}>
        {isExpanded && <motion.div {...expandAnimationProps}>{renderBody(config)}</motion.div>}
      </AnimatePresence>
    </SStaticSection>
  );
};
