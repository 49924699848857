import { Optional } from 'backend-api/models';

export interface ItemLayoutInfo {
  itemWidth: number;
  x: number;
}

export const getItemLayoutInfo = (
  gridElement: HTMLDivElement | null,
  index: number,
  gridGap: number
): Optional<ItemLayoutInfo> => {
  if (!gridElement) return undefined;
  const itemElement = gridElement.children.item(index);
  if (!itemElement) return;

  const boundingClientRect = itemElement.getBoundingClientRect();
  const gridBoundingClientRect = gridElement.getBoundingClientRect();
  const itemWidth = boundingClientRect.width + gridGap;
  const itemRelativeX = boundingClientRect.x - gridBoundingClientRect.x;

  return { itemWidth, x: itemRelativeX };
};
