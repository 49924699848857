import { CAMPAIGN_CATEGORY_GROUPS } from 'common/constants';

export const LINKED_DIGITAL_CATEGORY_ID = 999;

export const DIGITAL_CATEGORY_IDS = [CAMPAIGN_CATEGORY_GROUPS.digital, LINKED_DIGITAL_CATEGORY_ID];

export const TooltipByCampaignCategory = {
  [LINKED_DIGITAL_CATEGORY_ID]: 'Only includes FB and Google Ads campaigns pulled from those platforms.',
  [CAMPAIGN_CATEGORY_GROUPS.digital]: 'Includes linked and manual digital campaigns.',
  [CAMPAIGN_CATEGORY_GROUPS.traditional]:
    'Includes all Traditional campaign created through PRS POs plus manual campaigns in this category.',
  [CAMPAIGN_CATEGORY_GROUPS.radio]:
    'Includes all Radio campaigns created through PRS POs plus manual campaigns in this category.',
  [CAMPAIGN_CATEGORY_GROUPS.other]: 'Includes campaigns with multiple platforms across categories.',
};

export const MAX_SHOWN_INDIVIDUAL_PLATFORMS = 9;
export const BASE_OTHER_PLATFORM = { id: -1, name: 'Other Platforms', budget: 0, subtypes: [] };
