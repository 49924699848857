import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { CampaignSearch } from 'backend-api/models';
import { BEM_CLASS, SCampaignSearchItem } from './s-campaign-search-item';
import { SPoint } from 'app/components/search-modal/styled';
import { TooltipedText } from 'common/components/tooltiped-text';
import {
  getCampaignBadge,
  getCampaignNameOrPlaceholder,
  getScheduleDate,
} from 'app/components/search-modal/transducers';
import { useSelector } from 'react-redux';
import { dateFormatSelector } from 'common/selectors';

const classes = bem(BEM_CLASS);

interface CampaignSearchItemProps {
  className?: string;
  campaign: CampaignSearch;
}

export const CampaignSearchItem = React.memo(({ className, campaign }: CampaignSearchItemProps) => {
  const { name, startDate, endDate, isUnassigned, isPending } = campaign;
  const dateFormat = useSelector(dateFormatSelector);

  const campaignBadge = useMemo(() => getCampaignBadge(isUnassigned, isPending), [isUnassigned, isPending]);
  const scheduleDate = useMemo(() => getScheduleDate(startDate, endDate, dateFormat), [startDate, endDate, dateFormat]);

  return (
    <SCampaignSearchItem className={className}>
      <div className={classes('content')}>
        <div className={classes('info')}>
          <TooltipedText text={getCampaignNameOrPlaceholder(name)}>
            <span>{getCampaignNameOrPlaceholder(name)}</span>
          </TooltipedText>
          <div className={classes('badge')}>{campaignBadge}</div>
        </div>
        <div className={classes('schedule')}>
          {scheduleDate}
          {campaign.project && (
            <>
              <SPoint />
              <span>{campaign.project?.name}</span>
            </>
          )}
        </div>
      </div>
    </SCampaignSearchItem>
  );
});
