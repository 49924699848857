import { PLURAL_EXCEPTIONS } from 'common/constants';
import { first, last } from 'lodash';

export const isSubstring = (str: string, substr: string) => str.toLowerCase().includes(substr.toLowerCase());

export const pluralizeString = (value: string, count: number): string => {
  let pluralValue = `${value}s`;

  if (value.endsWith('y') && !PLURAL_EXCEPTIONS.includes(value)) {
    pluralValue = value.replace(/.$/, 'ies');
  }
  return value && (count === 1 ? value : pluralValue);
};

export const capitalize = (string: string) => {
  if (!string) return '';

  return string[0].toUpperCase() + string.slice(1);
};

export const convertToLowerKebabCase = (value: string) => value.toLowerCase().replace(/ /g, '-');

export const getInitials = (str: string): string => {
  const parts = str.split(' ');
  let letters = first(parts)?.charAt(0) || '';

  if (parts.length > 1) {
    letters += last(parts)?.charAt(0) || '';
  }

  return letters;
};

export const getTruncatedText = (text: string, maxCharacters: number) => {
  if (text.length > maxCharacters) {
    return text.substring(0, maxCharacters) + '...';
  }
  return text;
};

export const getDataSelector = (selector: string, prefix?: string) =>
  prefix ? `${prefix.toLowerCase()}-${selector}` : selector;

export const convertToDataSelector = (str: string) =>
  str
    .replace(/[\s,_]/g, '-')
    .replace(/[\(\)]/g, '')
    .toLowerCase();
