import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'app-footer';

export const SAppFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  margin: 0 40px;
  color: ${manhattanThemeColors.placeholderTextColor};
  border-top: 1px solid ${transparentize(0.9, manhattanThemeColors.placeholderTextColor)};
`;
