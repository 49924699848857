import React, { useEffect, useMemo, useCallback } from 'react';
import { Body } from './body';
import { Form as BaseForm } from 'common/containers/form';
import { useDispatch, useSelector } from 'react-redux';
import { activeCampaignSelector, isLoadingSelector, processingSelector } from '../../selectors';
import { getInitialValues } from '../../transducers';
import { createCampaign, editCampaign, setProcessing } from '../../actions';
import { getFieldsWithDiffValues } from 'common/transducers';
import { Id } from 'backend-api/models';
import { UUID } from 'io-ts-types/lib/UUID';

interface Props {
  campaignUuid: UUID;
  projectId: Id;
  currencyCode?: string;
}

export const Form = React.memo((props: Props) => {
  const { campaignUuid, projectId, currencyCode } = props;
  const dispatch = useDispatch();

  const campaign = useSelector(activeCampaignSelector);
  const isProcessing = useSelector(processingSelector);
  const isLoading = useSelector(isLoadingSelector);

  const initialValues = useMemo(() => getInitialValues(campaign), [campaign]);

  const handleSubmit = useCallback(
    data => {
      if (!campaignUuid) {
        dispatch(
          createCampaign.request({
            ...data,
            projectId,
          })
        );
      } else {
        dispatch(
          editCampaign.request({
            ...data,
            projectId,
            campaignUuid,
            changedFields: getFieldsWithDiffValues(data, initialValues),
          })
        );
      }
    },
    [campaignUuid, dispatch, initialValues, projectId]
  );

  useEffect(
    () => () => {
      dispatch(setProcessing(false));
    },
    [dispatch]
  );

  return !isLoading ? (
    <BaseForm
      initialValues={initialValues}
      useNavigationPrompt={!isProcessing}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Body campaignUuid={campaignUuid} projectId={projectId} currencyCode={currencyCode} />
    </BaseForm>
  ) : (
    <div />
  );
});
