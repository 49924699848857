import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { SCheckbox, SCheckboxInput } from '../s-components/s-checkbox';
import { Tooltip } from './tooltip';
import { kebabCase } from 'lodash';

interface Props {
  isChecked?: boolean;
  halfChecked?: boolean;
  isDisabled?: boolean;
  name?: string;
  description?: string;
  label?: string;
  onClick?(checked: boolean): void;
  dataSelector?: string;
  labelClassName?: string;
  className?: string;
  checkboxClassName?: string;
}

const classes = bem('checkbox');

export const Checkbox = React.memo(
  ({
    isChecked = false,
    halfChecked = false,
    isDisabled = false,
    name = '',
    description,
    label = '',
    onClick,
    dataSelector = 'checkbox',
    labelClassName,
    className,
    checkboxClassName,
  }: Props) => {
    const onCheckboxClick = useCallback(() => {
      if (!isDisabled && onClick) {
        onClick(!isChecked);
      }
    }, [isDisabled, isChecked, onClick]);

    const itemDataSelector = `${dataSelector}${isChecked ? '-is-checked' : ''}`;

    return (
      <SCheckbox
        isDisabled={isDisabled}
        onClick={onCheckboxClick}
        className={className}
        data-selector="checkbox-options"
      >
        <SCheckboxInput
          data-selector={itemDataSelector}
          halfChecked={halfChecked}
          isChecked={isChecked}
          isDisabled={isDisabled}
          name={name}
          readOnly
          className={checkboxClassName}
        />
        <div
          className={classes('label', { 'has-margin': !!(name || label) }, labelClassName)}
          data-selector={`${kebabCase(name || label)}-modal-window-checkbox`}
        >
          <Tooltip content={description} enabled={!!description}>
            {label}
          </Tooltip>
        </div>
      </SCheckbox>
    );
  }
);
