import * as t from 'io-ts';
import { IdNameField, IdType, NonEmptyString, Optional, ResponseModel } from '../common';
import { LabelSchema } from '../label';

export const CurrentUserInfoSchema = ResponseModel(
  {
    ...IdNameField,
    externalId: t.string,
    email: t.string,
    phone: t.string,
    isAdmin: t.boolean,
    dateTimeFormat: t.number,
  },
  'CurrentUserInfo'
);

export type CurrentUserInfo = t.TypeOf<typeof CurrentUserInfoSchema>;

export const CurrentUserSchema = ResponseModel(
  {
    currentUser: CurrentUserInfoSchema,
    projectsCount: t.number,
  },
  'CurrentUser'
);

export type CurrentUser = t.TypeOf<typeof CurrentUserSchema>;

export const ReferenceUserSchema = ResponseModel(
  {
    id: t.string,
    email: t.string,
  },
  'ReferenceUser'
);

export type ReferenceUser = t.TypeOf<typeof ReferenceUserSchema>;

export enum UserRoleType {
  Admin,
  Editor,
  Labelmate,
  Viewer,
  Approver,
}

export const UserRoleTypeSchema = t.union([
  t.literal(UserRoleType.Admin),
  t.literal(UserRoleType.Editor),
  t.literal(UserRoleType.Labelmate),
  t.literal(UserRoleType.Viewer),
  t.literal(UserRoleType.Approver),
]);

export const UserRoleSchema = ResponseModel(
  {
    id: UserRoleTypeSchema,
    name: NonEmptyString,
    categoryIds: t.array(t.number),
  },
  'UserRole'
);

export type UserRole = t.TypeOf<typeof UserRoleSchema>;

export const UserSchema = ResponseModel(
  {
    id: IdType,
    email: t.string,
    labels: t.array(t.number),
  },
  'User'
);

export type User = t.TypeOf<typeof UserSchema>;

export const TeamUserSchema = ResponseModel(
  {
    ...IdNameField,
    email: t.string,
    phone: t.string,
    labels: t.array(LabelSchema),
    roles: t.array(UserRoleSchema),
  },
  'TeamUser'
);

export type TeamUser = t.TypeOf<typeof TeamUserSchema>;

export const ProjectUserSchema = ResponseModel(
  {
    ...IdNameField,
    email: t.string,
    labels: t.array(LabelSchema),
  },
  'ProjectUser'
);

export type ProjectUser = t.TypeOf<typeof ProjectUserSchema>;

export const SimpleProjectUserSchema = ResponseModel(
  {
    ...IdNameField,
    email: t.string,
    role: UserRoleSchema,
  },
  'SimpleProjectUser'
);

export type SimpleProjectUser = t.TypeOf<typeof SimpleProjectUserSchema>;

export const CurrentUserUpdateSchema = ResponseModel(
  {
    phone: Optional(t.string),
    dateTimeFormat: t.number,
  },
  'ProjectUser'
);

export type CurrentUserUpdate = t.TypeOf<typeof CurrentUserUpdateSchema>;
