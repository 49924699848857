import styled from 'styled-components';
import { transparentize } from 'polished';
import { Card } from 'common-v2/components';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'project-card';

export const SProjectCard = styled(Card)`
  .${BEM_CLASS} {
    &__project {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 5px;
    }

    &__project-link {
      padding: 2px 10px;
      margin: -2px -10px;
      overflow: hidden;
    }

    &__project-link-text {
      max-width: 100%;
      display: block;
      padding-bottom: 0;
      color: ${colorPalette.woodsmoke};
      font-weight: 700;
      border-bottom: 1px dotted ${transparentize(0.4, colorPalette.woodsmoke)};
    }

    &__project-icon {
      flex-shrink: 0;
      margin-left: 10px;
    }

    &__artist {
      display: flex;
      align-items: baseline;
      color: ${manhattanThemeColors.grayedText};
      max-width: 100%;
      margin-bottom: 5px;
    }

    &__artist-text {
      flex-shrink: 0;
      margin-right: 5px;
    }

    &__artist-link {
      color: ${colorPalette.woodsmoke};
      font-weight: 700;
    }

    &__date {
      max-width: 100%;
      color: ${manhattanThemeColors.grayedText};
    }

    &__label {
      margin-top: auto;
      max-width: 100%;
      padding: 3px 8px;
      background-color: ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
      border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
      font-size: 10px;
      font-weight: 700;
      line-height: 1;
      text-transform: uppercase;
      color: ${manhattanThemeColors.grayedText};
      border-radius: 5px;
      letter-spacing: 1px;
    }
  }
`;
