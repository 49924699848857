import React from 'react';
import { transparentize } from 'polished';
import { SimpleProjectUser } from 'backend-api/models';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { Avatar } from 'common-v2/components';
import { manhattanThemeColors } from 'app/theme/colors';
import { bem } from 'utils/bem';
import { SMemberItem, BEM_CLASS } from './s-member-item';

const classes = bem(BEM_CLASS);

interface MemberItemProps {
  member: SimpleProjectUser;
  roleNode: React.ReactNode;
}

export const MemberItem = React.memo(({ member, roleNode }: MemberItemProps) => (
  <SMemberItem data-selector="share-modal-member-item">
    <Avatar
      className={classes('avatar')}
      size={25}
      name={member.name}
      avatarColor={transparentize(0.85, manhattanThemeColors.primaryFillNew)}
      dataSelector="share-modal-member-item-avatar"
    />

    <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('name')} data-selector="share-modal-member-item-name">
      {member.name}
    </Typography>

    <div className={classes('role')}>{roleNode}</div>
  </SMemberItem>
));
