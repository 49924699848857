import { getObjectiveMock } from '../common/__mocks__';
import { Goal } from './index';

const getDefaultsGoal = (): Goal => ({
  id: 1,
  name: 'Goal',
  objectives: [getObjectiveMock()],
});

export const getGoalMock = (p?: Partial<Goal>): Goal => ({
  ...getDefaultsGoal(),
  ...p,
});
