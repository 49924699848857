import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { BEM_CLASS, SArtistsRenderer } from './s-artists-renderer';
import { bem } from 'utils/bem';
import { Chip, CHIP_TYPE, THEME, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { ArtistLink } from './components';
import { getArtists } from './transducers';
import { MIN_CELL_WIDTH, RENDERER_TOP_MARGIN } from './constants';
import { LabelPermissionsArtist } from 'backend-api/models';
import { DEFAULT_ROW_HEIGHT } from 'label-permissions/constants';

const classes = bem(BEM_CLASS);

export const ArtistsRenderer = React.memo(
  ({ value, column, node, api }: ICellRendererParams<any, LabelPermissionsArtist[]>) => {
    const columnWidth = column?.getActualWidth() || 0;
    const cellWidth = columnWidth > MIN_CELL_WIDTH ? columnWidth : MIN_CELL_WIDTH; //TODO: temporary fix while I'm investigating bug with ag-grid

    const containerRef = useRef<HTMLDivElement>(null);

    const [expanded, setExpanded] = useState(false);
    const toggle = useCallback(() => setExpanded(!expanded), [expanded]);

    useEffect(() => {
      if (!expanded) {
        node.setRowHeight(DEFAULT_ROW_HEIGHT);
      } else {
        const expectedCellHeight = (containerRef.current?.clientHeight || 0) + RENDERER_TOP_MARGIN;
        const targetCellHeight = expectedCellHeight < DEFAULT_ROW_HEIGHT ? DEFAULT_ROW_HEIGHT : expectedCellHeight;

        node.setRowHeight(targetCellHeight);
      }

      // NOTE: Avoid redraw rows on the middle of another drawing process
      queueMicrotask(() => {
        api.onRowHeightChanged();
      });
    }, [expanded, node, api]);

    const content = useMemo(() => {
      if (value.length === 0) {
        const emptyState = (
          <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('empty-state')}>
            No Assigned Artists
          </Typography>
        );
        return [emptyState];
      }

      const artists = getArtists(value, expanded, cellWidth);

      const content = artists.map(artist => (
        <ArtistLink key={artist.id} artist={artist} className={classes('artist-link')} />
      ));

      const shouldIgnoreChip = !expanded && artists.length === value.length;
      if (shouldIgnoreChip) {
        return content;
      }

      const hiddenItemCount = value.length - artists.length;
      const chipTitle = expanded ? 'Show Less' : `+${hiddenItemCount} Show`;
      content.push(
        <Chip
          theme={THEME.light}
          label={chipTitle}
          type={CHIP_TYPE.compact}
          className={classes('chip')}
          onClick={toggle}
        />
      );

      return content;
    }, [cellWidth, expanded, toggle, value]);

    return (
      <SArtistsRenderer>
        <div className={classes('container')} ref={containerRef}>
          {content}
        </div>
      </SArtistsRenderer>
    );
  }
);
