import {
  LabelPermissionsArtistWithMembers,
  Id,
  LabelPermissionsArtist,
  LabelPermissionsCounters,
  LabelPermissionsMember,
  Optional,
  ShortArtist,
  LabelPermissionsTeamMembers,
  LabelUser,
} from 'backend-api/models';
import { Loadable, Loading } from 'common-v2/types';
import { createTypedReducer, onAction } from 'core/store';
import {
  addAllArtists,
  addRecentArtist,
  clearAllRecentArtists,
  editUserArtists,
  getLabelArtists,
  removeArtist,
  removeRecentArtist,
  setArtistsSearch,
  closeEditArtistsModal,
  getLabelCounters,
  getLabelTeamMembers,
  openEditArtistsModal,
  getLabelArtistsList,
  openEditMembersModal,
  getLabelUsers,
  setMembersSearch,
  addRecentMember,
  removeRecentMember,
  removeMember,
  clearAllRecentMembers,
  addAllMembers,
  closeEditMembersModal,
  editArtistMembers,
} from './actions';

export interface LabelPermissionsState {
  counters: Loadable<Optional<LabelPermissionsCounters>>;
  teamMembers: Loadable<LabelPermissionsMember[]>;
  editArtists: {
    userId?: Id;
    search: string;
    recentlyAddedArtists: ShortArtist[];
    isOpened: boolean;
    userArtists: LabelPermissionsArtist[];
    title: string;
    labelArtists: Loadable<ShortArtist[]>;
    removedArtistsIds: string[];
    processing: Loading;
  };
  editMembers: {
    labelId?: Id;
    artistId?: string;
    search: string;
    recentlyAddedMembers: LabelUser[];
    isOpened: boolean;
    artistMembers: LabelPermissionsTeamMembers[];
    title: string;
    labelUsers: Loadable<LabelUser[]>;
    removedMembersIds: Id[];
    processing: Loading;
  };
  artists: Loadable<LabelPermissionsArtistWithMembers[]>;
}

export const initialState: LabelPermissionsState = {
  counters: {
    loading: Loading.Idle,
    data: undefined,
  },
  teamMembers: {
    loading: Loading.Idle,
    data: [],
  },
  editArtists: {
    search: '',
    recentlyAddedArtists: [],
    isOpened: false,
    userArtists: [],
    title: '',
    labelArtists: {
      loading: Loading.Idle,
      data: [],
    },
    removedArtistsIds: [],
    processing: Loading.Idle,
  },
  editMembers: {
    search: '',
    recentlyAddedMembers: [],
    isOpened: false,
    artistMembers: [],
    title: '',
    labelUsers: {
      loading: Loading.Idle,
      data: [],
    },
    removedMembersIds: [],
    processing: Loading.Idle,
  },
  artists: {
    loading: Loading.Idle,
    data: [],
  },
};

export const reducer = createTypedReducer<LabelPermissionsState>(
  initialState,
  onAction(getLabelCounters.request, state => ({
    ...state,
    counters: {
      loading: Loading.Started,
      data: undefined,
    },
  })),
  onAction(getLabelCounters.success, (state, action) => ({
    ...state,
    counters: {
      loading: Loading.Finished,
      data: action.payload,
    },
  })),
  onAction(getLabelCounters.failure, state => ({
    ...state,
    counters: {
      loading: Loading.Failed,
      data: undefined,
    },
  })),
  onAction(getLabelTeamMembers.request, state => ({
    ...state,
    teamMembers: {
      loading: Loading.Started,
      data: [],
    },
  })),
  onAction(getLabelTeamMembers.success, (state, action) => ({
    ...state,
    teamMembers: {
      loading: Loading.Finished,
      data: action.payload,
    },
  })),
  onAction(getLabelTeamMembers.failure, state => ({
    ...state,
    teamMembers: {
      loading: Loading.Failed,
      data: [],
    },
  })),
  onAction(openEditArtistsModal, (state, { payload }) => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      isOpened: true,
      userArtists: payload.data,
      title: payload.title,
      userId: payload.userId,
    },
  })),
  onAction(closeEditArtistsModal, state => ({
    ...state,
    editArtists: initialState.editArtists,
  })),
  onAction(getLabelArtists.request, state => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      labelArtists: {
        loading: Loading.Started,
        data: [],
      },
    },
  })),
  onAction(getLabelArtists.success, (state, action) => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      labelArtists: {
        loading: Loading.Finished,
        data: action.payload,
      },
    },
  })),
  onAction(getLabelArtists.failure, state => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      labelArtists: {
        loading: Loading.Failed,
        data: [],
      },
    },
  })),
  onAction(setArtistsSearch, (state, { payload }) => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      search: payload,
    },
  })),
  onAction(addRecentArtist, (state, { payload }) => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      recentlyAddedArtists: [payload, ...state.editArtists.recentlyAddedArtists],
    },
  })),
  onAction(removeRecentArtist, (state, { payload }) => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      recentlyAddedArtists: state.editArtists.recentlyAddedArtists.filter(artist => artist.id !== payload),
    },
  })),
  onAction(clearAllRecentArtists, state => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      recentlyAddedArtists: [],
    },
  })),
  onAction(removeArtist, (state, { payload }) => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      removedArtistsIds: state.editArtists.removedArtistsIds.concat(payload),
    },
  })),
  onAction(addAllArtists, (state, { payload }) => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      recentlyAddedArtists: payload,
    },
  })),
  onAction(editUserArtists.request, state => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      processing: Loading.Started,
    },
  })),
  onAction(editUserArtists.success, state => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      processing: Loading.Finished,
    },
  })),
  onAction(editUserArtists.failure, state => ({
    ...state,
    editArtists: {
      ...state.editArtists,
      processing: Loading.Failed,
    },
  })),
  onAction(getLabelArtistsList.request, state => ({
    ...state,
    artists: {
      loading: Loading.Started,
      data: [],
    },
  })),
  onAction(getLabelArtistsList.success, (state, action) => ({
    ...state,
    artists: {
      loading: Loading.Finished,
      data: action.payload,
    },
  })),
  onAction(getLabelArtistsList.failure, state => ({
    ...state,
    artists: {
      loading: Loading.Failed,
      data: [],
    },
  })),
  onAction(openEditMembersModal, (state, { payload }) => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      isOpened: true,
      artistMembers: payload.data,
      title: payload.title,
      artistId: payload.artistId,
    },
  })),
  onAction(closeEditMembersModal, state => ({
    ...state,
    editMembers: initialState.editMembers,
  })),
  onAction(getLabelUsers.request, state => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      labelUsers: {
        loading: Loading.Started,
        data: [],
      },
    },
  })),
  onAction(getLabelUsers.success, (state, { payload }) => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      labelUsers: {
        loading: Loading.Finished,
        data: payload,
      },
    },
  })),
  onAction(getLabelUsers.failure, state => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      labelUsers: {
        loading: Loading.Failed,
        data: [],
      },
    },
  })),
  onAction(setMembersSearch, (state, { payload }) => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      search: payload,
    },
  })),
  onAction(addRecentMember, (state, { payload }) => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      recentlyAddedMembers: [payload, ...state.editMembers.recentlyAddedMembers],
    },
  })),
  onAction(removeRecentMember, (state, { payload }) => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      recentlyAddedMembers: state.editMembers.recentlyAddedMembers.filter(user => user.id !== payload),
    },
  })),
  onAction(removeMember, (state, { payload }) => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      removedMembersIds: state.editMembers.removedMembersIds.concat(payload),
    },
  })),
  onAction(clearAllRecentMembers, state => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      recentlyAddedMembers: [],
    },
  })),
  onAction(addAllMembers, (state, { payload }) => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      recentlyAddedMembers: payload,
    },
  })),
  onAction(editArtistMembers.request, state => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      processing: Loading.Started,
    },
  })),
  onAction(editArtistMembers.success, state => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      processing: Loading.Finished,
    },
  })),
  onAction(editArtistMembers.failure, state => ({
    ...state,
    editMembers: {
      ...state.editMembers,
      processing: Loading.Failed,
    },
  }))
);
