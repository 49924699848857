import React from 'react';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { CardProps } from './types';
import { DEFAULT_ERROR, DEFAULT_HAS_ACTION, DEFAULT_IS_ERROR, DEFAULT_SIZE } from './constants';
import { SCard, BEM_CLASS } from './s-card';

interface Props extends CardProps {
  className?: string;
  dataSelector?: string;
  error?: string;
}

const classes = bem(BEM_CLASS);

export const Card = React.memo(
  ({
    className,
    dataSelector = 'card',
    error = DEFAULT_ERROR,
    children,
    ...baseProps
  }: React.PropsWithChildren<Props>) => {
    const { size = DEFAULT_SIZE, isError = DEFAULT_IS_ERROR, hasAction = DEFAULT_HAS_ACTION } = baseProps;

    return (
      <SCard className={className} size={size} isError={isError} hasAction={hasAction} data-selector={dataSelector}>
        {isError ? (
          <Typography className={classes('error')} type={TYPOGRAPHY_TYPE.body4}>
            {error}
          </Typography>
        ) : (
          React.Children.map(children, child => {
            if (!React.isValidElement(child)) return null;
            return React.cloneElement(child as React.ReactElement<CardProps>, { ...baseProps });
          })
        )}
      </SCard>
    );
  }
);
