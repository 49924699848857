import { paths } from 'app/routing/paths';
import {
  AnyProject,
  Artist,
  Optional,
  Project,
  ProjectDetails,
  ProjectDetailsSchema,
  ProjectSchema,
  ProjectSearch,
  RecentProjectSchema,
} from 'backend-api/models';
import { ROLES } from 'common-v2/constants';
import { getFormattedDate } from 'common-v2/utils';
import { getCurrencyFormatted } from 'utils/format';
import { includesOnlyApproverRole, includesRole } from '.';

export const getProjectSchedule = (project: Optional<AnyProject>, dateFormat: string): string | undefined => {
  if (!project) return undefined;

  const formatDate = getFormattedDate(dateFormat);

  if (ProjectSchema.is(project) && !project.isClaimed) {
    return project.startDate ? formatDate(project.startDate) : undefined;
  }

  if (
    project.startDate &&
    (ProjectSchema.is(project) || ProjectDetailsSchema.is(project) || RecentProjectSchema.is(project))
  ) {
    return `${formatDate(project.startDate)}${project.endDate ? `-${formatDate(project.endDate)}` : ''}`;
  }

  return undefined;
};

export const getProjectArtists = (project: Optional<ProjectDetails>): Artist[] => {
  if (!project || project.targets.type !== 'Artist') {
    return [];
  }

  return project.targets.items;
};

export const getProjectPrimaryArtists = (project: Optional<ProjectDetails>): Artist[] => {
  const allArtists = getProjectArtists(project);

  return allArtists.filter(artist => artist.type === 'Primary');
};

export const getProjectTotalCampaignsCount = (project?: ProjectDetails): number => {
  if (!project || !project.metadata) return 0;
  return project.metadata.campaigns.externalCampaignsCount + project.metadata.campaigns.internalCampaignsCount;
};

export const getProjectTotalLinkfireLinksCount = (project?: ProjectDetails): number => {
  if (!project || !project.metadata) return 0;
  return project.metadata.linkfire.campaignsLinksCount + project.metadata.linkfire.projectLinksCount;
};

export const getPercentage = (allocated?: number, budget?: number): number => {
  if (!allocated || !budget) {
    return 0;
  }

  return allocated / budget;
};

export const getRelativeProgressBarWidth = (percentage: number): number => Math.max(0, Math.min(100, percentage * 100));

export const getProjectBudget = (project: Project): string =>
  includesOnlyApproverRole(project.userRoles) ? 'N/A' : getCurrencyFormatted(project.budget, project.currency);

export const getProjectAllocation = (project: Project): string =>
  includesOnlyApproverRole(project.userRoles) ? 'N/A' : getCurrencyFormatted(project.allocation, project.currency);

export const getPathToProjectPage = (project: Project | ProjectSearch): string => {
  const isLabelmateApproverInConfidentialProject =
    project.isConfidential &&
    includesRole(project.userRoles, ROLES.LABELMATE) &&
    includesRole(project.userRoles, ROLES.APPROVER) &&
    project.userRoles &&
    project.userRoles.length === 2;

  return (project.userRoles && includesOnlyApproverRole(project.userRoles)) || isLabelmateApproverInConfidentialProject
    ? paths.mediaPlan(project.id)
    : paths.project(project.id);
};
