import { Sort } from 'utils/sort';
import { Id, MetricsReporting, Optional, ProjectStatus, TargetType } from 'backend-api/models';
import { ArtistRosterProjectsParams, ProjectSortField } from 'backend-api/types';
import { LoadableData, LoadingState } from 'common/types';
import { BaseOption } from 'common/components/select';
import { SimpleOption } from 'common/constants';

export interface AllProjectsFiltersByStatus {
  targetType: TargetType;
  offset?: number;
  limit?: number;
  sort?: Sort<ProjectSortField>;
}

export interface AllProjectsFilters {
  status?: ProjectStatus;
  earliestStartDate?: Date;
  latestEndDate?: Date;
  search?: string;
  labelId?: Id;
  onlyMine?: boolean;
  filtersByStatus: Record<string, AllProjectsFiltersByStatus>;
  rosterLabelId?: Id;
}

export interface SnapshotState {
  maxPeriod: SnapshotPeriod;
  period: SnapshotPeriod;
  metrics: LoadableData<MetricsReporting[]>;
}

export interface ProjectSnapshotsByProjectId {
  [key: number]: SnapshotState;
}

export interface MetricsWithProjectId {
  metrics: MetricsReporting[];
  projectId: Id;
}

export interface ProjectSnapshotError extends Error {
  projectId: Id;
}

export enum SnapshotPeriod {
  ONE_DAY = 1,
  SEVEN_DAYS = 7,
  FOURTEEN_DAYS = 14,
  THIRTY_DAYS = 30,
}

export interface SnapshotPeriodByProjectId {
  [key: string]: SnapshotPeriod;
}

export interface MetricsInfo {
  totalStreams?: number;
  leanForwardStreams?: number;
  digitalSpend?: number;
  impressions?: number;
  clicks?: number;
  videoViews?: number;
}

export enum Delta {
  SMALLER = -1,
  SAME = 0,
  BIGGER = 1,
}

export interface SnapshotValue {
  name: string;
  value?: number;
  delta?: Delta;
  isCurrencyValue: boolean;
  percentage?: number;
}

export interface SnapshotConfig {
  projectId: Id;
  datesOptions: BaseOption[];
  selectedDateOptionId: Id;
  onSelectDateOption(projectId: Id, optionId: Id): void;
  values: SnapshotValue[];
  loadingState: LoadingState;
  currencyCode?: string;
}

export interface ProjectSnapshotParams {
  projectId: Id;
  period: SnapshotPeriod;
  maxPeriod: SnapshotPeriod;
}

export interface PeriodOption extends BaseOption {
  id: Id;
}

export interface GetExpansionProjectsPayload {
  id: string;
  params: ArtistRosterProjectsParams;
}

export interface AllProjectsTargetOption extends SimpleOption {
  type: TargetType;
  count: number;
}

export interface AllProjectsTab {
  status: ProjectStatus;
  title: string;
  badge: Optional<number>;
  options: AllProjectsTargetOption[];
  dataSelector: string;
}
