import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'label-option';

export const SLabelOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 7.5px 15px;

  &:hover {
    background: ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
  }

  &.${BEM_CLASS}--isActive {
    .${BEM_CLASS}__label {
      font-weight: 700;
    }
  }

  .${BEM_CLASS} {
    &__image {
      width: 22px;
      height: 22px;
      border-radius: 5px;
      margin-right: 10px;
      overflow: hidden;
    }
  }
`;
