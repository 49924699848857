import {
  Id,
  Optional,
  ProjectsMetadata,
  TeamUser,
  UpdateArtistTeam,
  UpdateArtistTeamUser,
  UserRoleType,
} from 'backend-api/models';
import { isProjectSortField, ProjectFilterParams } from 'backend-api/types';
import { DEFAULT_PAGINATION_LIMIT, PROJECT_STATUSES, SHORT_DATE_REQUEST_FORMAT } from 'common/constants';
import { isStringArray } from 'utils/array';
import { ParsedQs } from 'qs';
import { Sort } from 'utils/sort';
import { DEFAULT_SORT } from './constants';
import { ProjectsFilters, ProjectsTab } from './types';
import { ShareModalResponse } from 'modals/sharing-modal/types';
import { notNullable } from 'utils/data';
import { getFormattedDate, parseDate, isValidDate } from 'common-v2/utils';

export const getTotalProjectsCount = (tab: ProjectsTab, metadata?: ProjectsMetadata): number => {
  let result = 0;

  if (!metadata) return result;

  switch (tab) {
    case ProjectsTab.InProgress:
      result = metadata?.inProgress.totalCount;
      break;
    case ProjectsTab.Upcoming:
      result = metadata?.scheduled.totalCount;
      break;
    case ProjectsTab.Completed:
      result = metadata?.completed.totalCount;
      break;
    case ProjectsTab.Unassigned:
      result = metadata?.unassigned.totalCount;
    default:
      break;
  }

  return result;
};

export const getProjectFiltersParamsFromSearchQuery = (query: ParsedQs): ProjectFilterParams => {
  const freeText = typeof query.freeText === 'string' ? query.freeText : undefined;
  const statuses = isStringArray(query.statuses) ? query.statuses : undefined;
  const earliestStartDate = typeof query.earliestStartDate === 'string' ? query.earliestStartDate : undefined;
  const latestEndDate = typeof query.latestEndDate === 'string' ? query.latestEndDate : undefined;
  const limit = typeof query.limit === 'string' ? parseInt(query.limit, 10) : undefined;
  const offset = typeof query.offset === 'string' ? parseInt(query.offset, 10) : undefined;
  const sort =
    typeof query.sort === 'string' && Sort.create(query.sort, isProjectSortField)
      ? query.sort
      : DEFAULT_SORT.getString();

  return {
    freeText,
    statuses: statuses?.map(status => parseInt(status, 10)) || [PROJECT_STATUSES[0].id],
    earliestStartDate,
    latestEndDate,
    limit: limit || DEFAULT_PAGINATION_LIMIT,
    offset: offset || 0,
    sort,
  };
};

export const getProjectFiltersFromParams = (params: ProjectFilterParams): ProjectsFilters => {
  const status = PROJECT_STATUSES.find(status => status.id === params.statuses?.[0]);

  let earliestStartDate: Optional<Date>, latestEndDate: Optional<Date>;

  if (params.earliestStartDate) {
    const parsedStartDate = parseDate(params.earliestStartDate);
    earliestStartDate = isValidDate(parsedStartDate) ? parsedStartDate : undefined;
  }

  if (params.latestEndDate) {
    const parsedEndDate = parseDate(params.latestEndDate);
    latestEndDate = isValidDate(parsedEndDate) ? parsedEndDate : undefined;
  }

  if (!status) {
    return {
      status,
      earliestStartDate,
      latestEndDate,
      search: params.freeText,
      filtersByStatus: {},
    };
  }

  const sort = params.sort && Sort.create(params.sort, isProjectSortField);

  return {
    status,
    earliestStartDate,
    latestEndDate,
    search: params.freeText,
    filtersByStatus: {
      [status.id]: {
        limit: params.limit,
        offset: params.offset,
        sort: sort || DEFAULT_SORT,
      },
    },
  };
};

export const getProjectFiltersParamsFromFilters = ({
  status,
  filtersByStatus,
  earliestStartDate,
  latestEndDate,
  search,
}: ProjectsFilters): ProjectFilterParams => {
  if (!status) {
    return {};
  }

  const specificFilters = filtersByStatus[status.id];

  const formatDate = getFormattedDate(SHORT_DATE_REQUEST_FORMAT);

  const defaultParams = {
    statuses: [status.id],
    earliestStartDate: earliestStartDate ? formatDate(earliestStartDate) : undefined,
    latestEndDate: latestEndDate ? formatDate(latestEndDate) : undefined,
    freeText: search,
  };

  if (!specificFilters) {
    return defaultParams;
  }

  return {
    ...defaultParams,
    offset: specificFilters.offset,
    limit: specificFilters.limit,
    sort: specificFilters.sort?.getString(),
  };
};

export const mapTeamUserToUpdateArtistTeamUser = (teamUser: TeamUser): Optional<UpdateArtistTeamUser> => {
  const filteredRoles = teamUser.roles.filter(role => role.id !== UserRoleType.Approver);
  const primaryRole = filteredRoles[0];

  if (!primaryRole || !primaryRole.id) {
    return;
  }

  return {
    userId: teamUser.id,
    categoryIds: primaryRole.categoryIds,
    roleId: primaryRole.id,
  };
};

export const mapModalResponseToUpdateArtistTeamUsers = (
  modalResponse: ShareModalResponse,
  labelId: Id
): UpdateArtistTeam => ({
  labelId,
  added: modalResponse.added.map(mapTeamUserToUpdateArtistTeamUser).filter(notNullable),
  updated: modalResponse.updated.map(mapTeamUserToUpdateArtistTeamUser).filter(notNullable),
  deleted: modalResponse.deleted.map(mapTeamUserToUpdateArtistTeamUser).filter(notNullable),
});
