import React, { useCallback, useMemo } from 'react';
import { GlobalSearch } from 'backend-api/models';
import { SSearchTabs, BEM_CLASS } from './s-search-tabs';
import { Tab } from 'common/components/tabs';
import { TopResults } from '../top-results';
import { ArtistsResult, ProjectsResult, CampaignsResult } from './components';
import { LoadingState } from 'common/types';
import { bem } from 'utils/bem';
import { useDispatch } from 'react-redux';
import { setGlobalSearchActiveTab } from 'common/actions';
import { SearchTab } from './types';
import { convertToLowerKebabCase } from 'common/utils/string';

interface SearchTabsProps {
  loading: LoadingState;
  data?: GlobalSearch;
  activeTab: SearchTab;
}

const classes = bem(BEM_CLASS);

export const SearchTabs = React.memo(({ loading, data, activeTab }: SearchTabsProps) => {
  const dispatch = useDispatch();

  const onTabChange = useCallback(
    (activeTab: SearchTab) => {
      dispatch(setGlobalSearchActiveTab(activeTab));
    },
    [dispatch]
  );

  const tabs = useMemo(() => {
    const isLoading = loading === LoadingState.Idle || loading === LoadingState.Started;

    const hasArtists = data?.artists && data.artists.length > 0;
    const hasProjects = data?.projects && data.projects.length > 0;
    const hasCampaigns = data?.campaigns && data.campaigns.length > 0;

    const elements: React.ReactElement[] = [
      <Tab
        key="top-results"
        title={SearchTab.Top}
        className={classes('tab')}
        dataSelector={`search-modal-${convertToLowerKebabCase(SearchTab.Top)}-tab`}
      >
        <TopResults loading={loading} data={data} />
      </Tab>,
    ];

    if (hasArtists) {
      elements.push(
        <Tab
          title={SearchTab.Artists}
          className={classes('tab')}
          disabled={isLoading}
          dataSelector={`search-modal-${convertToLowerKebabCase(SearchTab.Artists)}-tab`}
        >
          <ArtistsResult artists={data.artists ?? []} />
        </Tab>
      );
    }

    if (hasProjects) {
      elements.push(
        <Tab
          title={SearchTab.Projects}
          className={classes('tab')}
          disabled={isLoading}
          dataSelector={`search-modal-${convertToLowerKebabCase(SearchTab.Projects)}-tab`}
        >
          <ProjectsResult projects={data.projects} />
        </Tab>
      );
    }

    if (hasCampaigns) {
      elements.push(
        <Tab
          title={SearchTab.Campaigns}
          className={classes('tab')}
          disabled={isLoading}
          dataSelector={`search-modal-${convertToLowerKebabCase(SearchTab.Campaigns)}-tab`}
        >
          <CampaignsResult campaigns={data.campaigns} />
        </Tab>
      );
    }
    return elements;
  }, [data, loading]);

  return (
    <SSearchTabs activeTab={activeTab} onChange={onTabChange}>
      {tabs}
    </SSearchTabs>
  );
});
