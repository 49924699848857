import { LabelUser, SimpleProjectUser } from 'backend-api/models';
import { SearchOption } from './types';

export const getSearchOptions = (users: LabelUser[], artistTeamAndAdmins: SimpleProjectUser[]): SearchOption[] =>
  users.map(user => {
    const isArtistTeamMemberOrAdmin = artistTeamAndAdmins.some(({ id }) => id === user.id);
    const badge = user.isAdmin ? 'Label Admin' : 'Artist team member';

    return {
      id: user.id,
      title: user.name,
      description: user.email,
      badge: isArtistTeamMemberOrAdmin ? badge : undefined,
      isDisabled: isArtistTeamMemberOrAdmin,
    };
  });
