import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import {
  getArtistInfo,
  hidePermissionsNotification,
  setPermissionsUnavailableLabels,
  showPermissionsNotification,
} from 'artist/actions';
import { Page, Permissions } from 'artist/components';
import { artistSelector, metadataSelector, permissionsSelector, errorSelector } from 'artist/selectors';
import { getSearchParamsFromLocation, navigateTo } from 'utils/navigation';
import { getProjectFiltersParamsFromSearchQuery } from 'artist/transducers';
import { NoResults } from 'common/components/no-results';
import FailedToLoad from 'assets/failed-to-load.svg';
import { LoadingState } from 'common/types';
import { usePrevious, useQuery, useProjectAccessRestricted } from 'hooks';
import { getFromSearchParams, getUnavailableLabels, isGrasId } from 'common/transducers';
import { ArtistHeaderContainer } from './artist-header-container';
import { paths } from 'app/routing/paths';
import { useProjectsRequest } from 'artist/hooks';
import { useAppContext } from 'common-v2/hooks';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';
import { Head } from 'app/components/head';

interface MatchParams {
  id: string;
}

export const ArtistPageContainer = React.memo(({ location, match }: RouteComponentProps<MatchParams>) => {
  const artistId = match.params.id;

  const dispatch = useDispatch();
  const { labels } = useAppContext();

  const artist = useSelector(artistSelector);
  const metadata = useSelector(metadataSelector);
  const permissions = useSelector(permissionsSelector);

  const previousMetadata = usePrevious(metadata);
  const params = useQuery();

  const locationSearch = useMemo(() => getSearchParamsFromLocation(location), [location]);
  const filterParams = useMemo(() => getProjectFiltersParamsFromSearchQuery(locationSearch), [locationSearch]);

  const getProjects = useProjectsRequest(artistId, filterParams);

  const showTeamEntry = !artist.data?.info.isUnknown;

  const artistName = artist.data?.info.name;

  useEffect(() => {
    if (!isGrasId(artistId)) {
      navigateTo(paths.outdatedNotFound());
      return;
    }

    dispatch(
      getArtistInfo.request({
        id: artistId,
        ...getFromSearchParams(params.query),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, artistId]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  useEffect(() => {
    if (labels && metadata?.projectsLabels) {
      const unavailableLabels = getUnavailableLabels(labels, metadata.projectsLabels);

      dispatch(setPermissionsUnavailableLabels(unavailableLabels));

      if (!permissions.isNotificationActive) return;

      if (unavailableLabels.length > 0) {
        dispatch(showPermissionsNotification());
      } else {
        dispatch(hidePermissionsNotification());
      }
    }
  }, [dispatch, labels, metadata?.projectsLabels, previousMetadata?.projectsLabels, permissions.isNotificationActive]);

  useProjectAccessRestricted(errorSelector);

  const noPermissions =
    permissions.unavailableLabels.length > 0 &&
    metadata?.counts.total.totalCount === 0 &&
    metadata?.projectsLabels.length === permissions.unavailableLabels.length;

  return (
    <>
      <Head title={artistName} />
      {noPermissions ? (
        <OutdatedGlobalLayout>
          <ArtistHeaderContainer artistId={artistId} filterParams={filterParams} />
          <Page content={<Permissions labels={permissions.unavailableLabels} />} isCentered />
        </OutdatedGlobalLayout>
      ) : (
        <OutdatedGlobalLayout>
          {artist.loading !== LoadingState.Failed ? (
            <ArtistHeaderContainer
              artistId={artistId}
              filterParams={filterParams}
              showTeamEntry={showTeamEntry}
              showTabs
            />
          ) : (
            <Page
              content={
                <NoResults
                  withoutFiltersImage={FailedToLoad}
                  withoutFiltersMessage="Data failed to load. Try refreshing this page."
                />
              }
              isCentered
              isFullHeight
            />
          )}
        </OutdatedGlobalLayout>
      )}
    </>
  );
});
