import styled from 'styled-components';

export const BEM_CLASS = 'end-date-renderer';

export const SEndDateRenderer = styled.div`
  width: 100%;
  height: 100%;

  .${BEM_CLASS} {
    &__base-renderer {
      display: flex;
      align-items: center;
      text-transform: uppercase;
    }
  }
`;
