import styled, { css } from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { components } from 'react-select';

export const SBaseControl = styled(components.Control)`
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  overflow: hidden;
  border: unset;
  min-height: unset;

  &:hover {
    border: unset;
  }

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          background-color: ${colorPalette.ghostWhite};
          cursor: not-allowed;
        `
      : css`
          background-color: ${colorPalette.white};
          cursor: pointer;
        `}
` as typeof components.Control;
