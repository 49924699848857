import { getTargetImageMock } from '../targets/__mocks__';
import { Playlist } from './index';

const getPlaylistDefaults = (): Playlist => ({
  id: 'id',
  name: 'Mock',
  ownerName: 'Mock owner',
  provider: 'apple',
  images: [getTargetImageMock()],
});

export const getPlaylistMock = (p?: Partial<Playlist>): Playlist => ({
  ...getPlaylistDefaults(),
  ...p,
});
