import { ofType } from 'core/epics';
import { Api } from 'backend-api';
import { updateCurrentUser } from 'settings/actions';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { showErrorToast, showToast, ToastType } from 'common/components/toast';
import { getUserInfo } from 'common/actions';

export const updateUserEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(updateCurrentUser.request),
    switchMap(({ payload }) => {
      return Api.updateUserInfo(payload).pipe(
        tap(() =>
          showToast({
            id: `preferences-saved-success`,
            type: ToastType.Success,
            message: 'Preferences saved.',
            replaceable: true,
          })
        ),
        mergeMap(response => [updateCurrentUser.success(response), getUserInfo.success(response)]),
        catchError(error => {
          showErrorToast('Preferences update unsuccessful. Please try again.');
          return of(updateCurrentUser.failure(error));
        })
      );
    })
  );
};
