import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';

export const SMultiValueRemove = styled.div`
  height: auto;
  align-self: stretch;
  color: ${manhattanThemeColors.grayedText};
  background-color: transparent;
  padding: 0;
  margin-left: 4px;

  &:hover {
    color: ${manhattanThemeColors.primaryDark};
    background-color: transparent;
  }
`;
