import React from 'react';
import { SMultiSelectLabel } from '../../../s-components/s-multi-select-label';
import { Button } from '../../button';
import { Icon } from '../../icon';

export function MultiValueLabel(props) {
  const { onClick = () => {}, name, variant = 'primary-cta', icon, isEllipsis, disabled } = props;

  return (
    <SMultiSelectLabel isEllipsis={isEllipsis} disabled={disabled}>
      <Button variant={variant} iconColor="white" className="multiselect__value-container" onClick={onClick}>
        {name}
        {icon ? <Icon name={icon} /> : <></>}
      </Button>
    </SMultiSelectLabel>
  );
}
