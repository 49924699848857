import { matchPath } from 'react-router-dom';
import { ManhattanDesignPath } from './routing';
import { MomentPaths, ProjectReportingPaths, ProjectsPaths } from './types';

export const isAddMomentPath = (path: string): boolean => !!matchPath(path, { path: MomentPaths.addMoment })?.isExact;

export const isEditMomentPath = (path: string): boolean => !!matchPath(path, { path: MomentPaths.editMoment })?.isExact;

export const isMomentDetailsPath = (path: string): boolean =>
  !!matchPath(path, { path: MomentPaths.momentDetails })?.isExact;

export const isProjectReportingPath = (path: string): boolean =>
  !!matchPath(path, { path: ProjectReportingPaths.projectReporting })?.isExact;

export const isManhattanDesignPath = (path: string): boolean => {
  return ManhattanDesignPath.some((newDesignPath: string) => !!matchPath(path, { path: newDesignPath })?.isExact);
};

export const isProjectSummaryPath = (path: string): boolean =>
  Boolean(matchPath(path, { path: ProjectsPaths.project, exact: true }));
