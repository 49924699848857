import styled from 'styled-components';
import { BEM_CLASS as SELECT_BEM_CLASS } from 'common/components/form/select';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'linkfire-search';

export const SLinkfireSearch = styled.div`
  .${SELECT_BEM_CLASS} {
    &__multi-value {
      max-width: 100%;
    }
  }

  .${BEM_CLASS} {
    &__error {
      display: flex;
      align-items: center;
      padding-top: 9px;
      color: ${colorPalette.alizarinCrimson};
      font-size: 12px;
      line-height: 14px;
    }

    &__error-icon {
      margin-right: 7px;
    }
  }
`;
