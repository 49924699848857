import { AudienceAge, CampaignProvider, Gender, Objective } from './index';

const getDefaultsObjective = (): Objective => ({
  id: 1,
  name: 'Objective',
});

export const getObjectiveMock = (p?: Partial<Objective>): Objective => ({
  ...getDefaultsObjective(),
  ...p,
});

const getDefaultsGender = (): Gender => ({
  id: 1,
  name: 'Male',
});

export const getGenderMock = (p?: Partial<Gender>): Gender => ({
  ...getDefaultsGender(),
  ...p,
});

const getDefaultsAudienceAge = (): AudienceAge => ({
  higherAge: 20,
  lowerAge: 13,
});

export const getAudienceAgeMock = (p?: Partial<AudienceAge>): AudienceAge => ({
  ...getDefaultsAudienceAge(),
  ...p,
});

const getDefaultsCampaignProvider = (): CampaignProvider => ({
  id: 1,
  name: 'Sony',
});

export const getCampaignProviderMock = (p?: Partial<CampaignProvider>): CampaignProvider => ({
  ...getDefaultsCampaignProvider(),
  ...p,
});
