import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGlobalSearchQuery, showGlobalSearch } from 'common/actions';
import { Input } from 'common/components/form/input';
import { SSearchField, BEM_CLASS } from './s-search-field';
import { globalSearchSelector } from 'common/selectors';
import { bem } from 'utils/bem';

const classes = bem(BEM_CLASS);

export const SearchField = React.memo(
  React.forwardRef<HTMLDivElement>((_, ref) => {
    const dispatch = useDispatch();

    const { isActive, query } = useSelector(globalSearchSelector);

    const onSearchChange = useCallback(
      (value: string) => {
        dispatch(setGlobalSearchQuery(value));

        if (!isActive) {
          dispatch(showGlobalSearch());
        }
      },
      [dispatch, isActive]
    );

    const onSearchFocus = useCallback(() => {
      if (!isActive) {
        dispatch(showGlobalSearch());
      }
    }, [dispatch, isActive]);

    const onContainerClick = useCallback((e: React.SyntheticEvent) => {
      e.stopPropagation();
    }, []);

    return (
      <SSearchField ref={ref} onClick={onContainerClick}>
        <Input
          className={classes('input', { dark: !isActive })}
          value={query}
          placeholder="Search for projects, artists or campaigns"
          iconLeft="search"
          onChange={onSearchChange}
          onFocus={onSearchFocus}
          dataSelector="global-search-input-field"
          clearable
        />
      </SSearchField>
    );
  })
);
