import { ArtistMomentTypeGroup } from 'backend-api/models';
import { SimpleSectionOption } from 'common/constants';

export const CUSTOM_MOMENT_TYPE_OPTION_ID = 9999;
export const CUSTOM_MOMENT_TYPE_OPTION_NAME = 'Custom Moment';

export const CUSTOM_MOMENT_TYPE_OPTION: SimpleSectionOption = {
  label: 'Custom',
  options: [{ id: CUSTOM_MOMENT_TYPE_OPTION_ID, name: CUSTOM_MOMENT_TYPE_OPTION_NAME }],
};

export const CUSTOM_MOMENT_TYPE_GROUP: ArtistMomentTypeGroup = {
  id: -1,
  name: 'Custom',
  types: [{ id: CUSTOM_MOMENT_TYPE_OPTION_ID, name: CUSTOM_MOMENT_TYPE_OPTION_NAME }],
};
