import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { List } from '../list';
import { SRecent, BEM_CLASS } from './s-recent';

interface RecentProps {
  recentNodes: React.ReactNode[];
  className?: string;
}

const classes = bem(BEM_CLASS);

export const Recent = React.memo(({ recentNodes, className }: RecentProps) => (
  <SRecent className={className} data-selector="edit-modal-recent-section">
    <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('title')} data-selector="edit-modal-recent-title">
      Recently Added
    </Typography>
    <List className={classes('list')}>{recentNodes}</List>
    <div className={classes('separator')} />
  </SRecent>
));
