import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const SStaticSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 14px;
  margin: 0 -14px;
  overflow: hidden;

  .static-section {
    &__placeholder-container {
      height: 45px;
      margin: 5px 0;
      width: 100%;
      border-radius: 5px;
      background-color: rgba(235, 235, 240, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 1;
      overflow: hidden;
      transition: opacity 200ms ease-in;
    }
    &__placeholder {
      color: ${palette.darkBlueTwo};
      font-size: 10px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center;
    }
    &__list-item-container {
      padding-bottom: 10px;
    }
  }
`;
