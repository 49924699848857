import React from 'react';
import { Link } from 'react-router-dom';
import { ArtistSearch } from 'backend-api/models';
import { bem } from 'utils/bem';
import { paths } from 'app/routing/paths';
import placeholderPng from 'assets/user-mic-65.png';
import { CardContent, CardImage } from 'common-v2/components';
import { CARD_SIZE } from '../../constants';
import { SArtistCard, BEM_CLASS } from './s-artist-card';
import { Badge, BADGE_TYPE, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';

interface ArtistCardProps extends ArtistSearch {
  className?: string;
  withBadges?: boolean;
}

const classes = bem(BEM_CLASS);

export const ArtistCard = React.memo(({ className, id, name, image, withBadges = false }: ArtistCardProps) => (
  <Link to={`${paths.artist(id)}?fromSearch=1`}>
    <SArtistCard className={className} dataSelector="artist-card" size={CARD_SIZE} hasAction>
      <CardImage url={image?.url} placeholder={placeholderPng} dataSelector="artist-card-image" />

      <CardContent className={classes('content')}>
        <Typography className={classes('name')} type={TYPOGRAPHY_TYPE.body2} data-selector="artist-card-name">
          {name}
        </Typography>

        <Typography className={classes('info')} type={TYPOGRAPHY_TYPE.body4}>
          {withBadges && (
            <Badge
              content="Artist"
              type={BADGE_TYPE.primary}
              className={classes('badge')}
              data-selector="artist-card-artist-badge"
            />
          )}
        </Typography>
      </CardContent>
    </SArtistCard>
  </Link>
));
