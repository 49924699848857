import { RefObject, useLayoutEffect, useState } from 'react';

export const useShouldNotesExpand = (editorRef: RefObject<HTMLElement>, dependencies: unknown[] = []) => {
  const [shouldNotesExpand, setShouldNotesExpand] = useState(false);

  useLayoutEffect(() => {
    if (editorRef?.current) {
      const lineHeightDiff = 1;
      const isNotesOverflown = editorRef.current.offsetHeight + lineHeightDiff < editorRef.current.scrollHeight;
      setShouldNotesExpand(isNotesOverflown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorRef, ...dependencies]);

  return shouldNotesExpand;
};
