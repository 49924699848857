import styled from 'styled-components';
import { transparentize } from 'polished';
import { theme } from 'app/theme';
import { manhattanThemeColors } from 'app/theme/colors';
import { typography } from 'app/theme/typography';

export const SCommonTable = styled.div`
  .ag-theme-alpine {
    font-family: ${theme.fontFamily};
  }

  .ag-root-wrapper {
    background: transparent;
    border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
  }

  .ag-cell {
    ${typography.body}
    display: flex;
    align-items: center;
    font-weight: 500;
    color: ${manhattanThemeColors.primaryDark};
  }

  .ag-header {
    background: ${transparentize(0.5, manhattanThemeColors.background)};
    border-bottom: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
  }

  .ag-header-cell {
    ${typography.primaryLabel}
    text-transform: uppercase;
    font-weight: 700;
    color: ${manhattanThemeColors.placeholderTextColor};
  }

  .ag-header-cell.ag-header-cell-sortable {
    cursor: default;

    .ag-header-cell-label {
      cursor: pointer;
    }
  }

  .ag-row-even {
    background: ${transparentize(0.9, manhattanThemeColors.background)};
  }

  .ag-row-odd {
    background: ${transparentize(0.97, manhattanThemeColors.dividerColor)};
  }
`;
