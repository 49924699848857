import styled from 'styled-components';

export const BEM_CLASS = 'user-name-renderer';

export const SUserNameRenderer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .${BEM_CLASS} {
    &__avatar {
      flex-shrink: 0;
    }

    &__tooltip-wrapper {
      flex-grow: 1;
      margin-left: 10px;
      overflow: hidden;
    }
  }
`;
