import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  allProjectsMetaSelector,
  currentLabelSelector,
  filtersSelector,
  isLoadingAllProjectsSelector,
  isLoadingSelector,
  recentProjectsSelector,
} from '../selectors';
import { SProjects } from '../s-components/s-projects';
import { Header } from './header';
import { Footer } from 'app/components/footer';
import { NoProjects } from './no-projects';
import { WAVE_GRADIENT_END_COLOR } from 'common/components/wave-background';
import { ArtistRosterContainer } from 'projects/containers/artist-roster';
import { AllProjectsSection } from './all-projects-section';
import { getCurrentLabel, resetExpansions, setCurrentLabel } from 'projects/actions';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { useAppContext } from 'common-v2/hooks';

export const Projects = React.memo(() => {
  const dispatch = useDispatch();
  const { labels: userLabels } = useAppContext();

  const recentProjects = useSelector(recentProjectsSelector);
  const isLoading = useSelector(isLoadingSelector);
  const isAllProjectsLoading = useSelector(isLoadingAllProjectsSelector);
  const meta = useSelector(allProjectsMetaSelector);
  const filters = useSelector(filtersSelector);
  const currentLabel = useSelector(currentLabelSelector);

  const allProjectsRef = useRef<HTMLDivElement>(null);

  const isRecentProjectsEmpty = recentProjects.length === 0 && !isLoading;
  const isSearchEmpty = !filters.search;
  const isPeriodEmpty = !filters.earliestStartDate && !filters.latestEndDate;
  const isFiltersEmpty = isPeriodEmpty && isSearchEmpty;
  const isAllProjectsEmpty = isFiltersEmpty && meta?.total.totalCount === 0 && !isAllProjectsLoading;
  const isProjectsEmpty = isAllProjectsEmpty && isRecentProjectsEmpty;

  const handleLabelChange = useCallback(
    (id: number) => {
      dispatch(setCurrentLabel(id));
      dispatch(resetExpansions());
      trackEvent(AnalyticsEvents.LABEL_SPACE_CHANGED, { label_selected: id });
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentLabel || !userLabels || userLabels.length === 0) return;

    dispatch(getCurrentLabel.request());
  }, [currentLabel, dispatch, userLabels]);

  return (
    <SProjects>
      <Header labels={userLabels} label={currentLabel} onLabelChange={handleLabelChange} />
      <ArtistRosterContainer />
      {!isAllProjectsEmpty && <AllProjectsSection ref={allProjectsRef} gradientEnabled={isRecentProjectsEmpty} />}
      {isProjectsEmpty && <NoProjects />}
      <Footer background={isRecentProjectsEmpty ? WAVE_GRADIENT_END_COLOR : 'white'} />
    </SProjects>
  );
});
