import { AllProjectsLoader } from 'common/components/loaders/all-projects-loader';
import React from 'react';
import { SLoadingOverlay } from './s-loading-overlay';

interface LoadingOverlayProps {
  className?: string;
}

export const LoadingOverlay = React.memo(({ className }: LoadingOverlayProps) => {
  return (
    <SLoadingOverlay className={className}>
      <AllProjectsLoader />
    </SLoadingOverlay>
  );
});
