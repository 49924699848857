import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { dropdown as dropdownZIndex } from 'app/styles/z-indexes';
import {
  ITEM_LINE_HEIGHT,
  ITEM_VERTICAL_PADDING,
  ITEM_HORIZONTAL_PADDING,
  LIST_VERTICAL_PADDING,
  MIN_WIDTH,
} from './constants';
import { DropdownPosition } from './dropdown';
import { theme } from 'app/theme';

interface SDropdownProps {
  readonly bgStyle?: string;
}

export const SDropdown = styled.div<SDropdownProps>`
  display: inline-block;
  position: relative;

  .dropdown {
    &__button {
      background: ${({ bgStyle, theme }) =>
        bgStyle === 'white' ? 'white' : theme.colors.button.secondaryGray.background};
      i {
        margin-left: 9px;
        &::before {
          color: ${palette.lavanderGray};
        }
      }
    }
  }
`;

interface SDropdownListProps extends SDropdownProps {
  readonly closed: boolean;
  readonly position: DropdownPosition;
}

export const SDropdownList = styled.div<SDropdownListProps>`
  all: unset;

  .dropdown {
    &__list {
      background: ${({ theme }) => theme.colors.select.background};
      padding-top: ${LIST_VERTICAL_PADDING}px;
      padding-bottom: ${LIST_VERTICAL_PADDING}px;
      border-radius: 20px;
      line-height: ${ITEM_LINE_HEIGHT}px;
      box-shadow: ${theme.shadows.default};
      position: absolute;
      z-index: ${dropdownZIndex};
      top: ${({ position }) => `${position.top}px`};
      left: ${({ position }) => `${position.left}px`};
      min-width: ${MIN_WIDTH}px;
      ${({ closed }) => closed && 'display: none;'};
    }

    &__option {
      cursor: pointer;
      padding-left: ${ITEM_HORIZONTAL_PADDING}px;
      padding-right: ${ITEM_HORIZONTAL_PADDING}px;
      padding-top: ${ITEM_VERTICAL_PADDING}px;
      padding-bottom: ${ITEM_VERTICAL_PADDING}px;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.select.option.color};
      background-color: transparent;
      white-space: nowrap;
      font-weight: 400;

      &:active,
      &:hover {
        color: ${({ theme }) => theme.colors.select.option.active.color};
        background-color: ${({ theme }) => theme.colors.select.option.active.background};

        &--selected {
          color: ${({ theme }) => theme.colors.select.option.active.color};
          background-color: ${({ theme }) => theme.colors.select.option.active.background};
        }
      }

      &--disabled {
        cursor: default;
        color: ${({ theme }) => theme.colors.select.option.disabled.color};
        background-color: ${({ theme }) => theme.colors.select.option.disabled.background};
        &:hover,
        &:active {
          color: ${({ theme }) => theme.colors.select.option.disabled.color};
          background-color: ${({ theme }) => theme.colors.select.option.disabled.background};
        }
      }

      &--selected {
        color: ${({ theme }) => theme.colors.select.option.selected.color};
        background-color: ${({ theme }) => theme.colors.select.option.selected.background};
        font-weight: 700;
      }

      &--focused {
        color: ${({ theme }) => theme.colors.select.option.active.color};
        background-color: ${({ theme }) => theme.colors.select.option.active.background};
      }
    }
  }
`;

export const SDropdownOption = styled.div``;
