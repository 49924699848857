import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Id } from 'backend-api/models';
import { Confirm } from 'common/components/confirm';
import { Icon } from 'common/components/icon';
import { SBox } from 'common/s-components/layout/s-box';
import { Button } from 'common/components/button';
import { Tooltip } from 'common/components/tooltip';
import { SModalTitle } from 'common/s-components/s-modal-title';
import { isCampaignExternal } from 'common/transducers';
import { usePermissionsChecker } from 'common-v2/hooks';
import { ACCESS_PERMISSIONS } from 'common-v2/constants';
import { activeCampaignSelector, isConfirmSelector, processingSelector, projectSelector } from '../../selectors';
import { deleteCampaign, showConfirmModal, unassignCampaign, undoDeleteCampaign } from '../../actions';
import { BaseState, go, goBack, navigateTo } from 'utils/navigation';
import { paths } from 'app/routing/paths';
import { useLocation } from 'react-router';
import { UUID } from 'io-ts-types/lib/UUID';

interface Props {
  campaignUuid: UUID;
  projectId: Id;
  className?: string;
}

const MODAL_NAME = 'CONFIRM_CAMPAIGN_DELETE';

export const RemoveButton = React.memo(({ projectId, campaignUuid, className }: Props) => {
  const dispatch = useDispatch();

  const campaign = useSelector(activeCampaignSelector);
  const isBeingProcessed = useSelector(processingSelector);
  const isConfirmModalOpened = useSelector(isConfirmSelector);
  const project = useSelector(projectSelector);

  const location = useLocation();
  const previousPagePath = (location.state as BaseState)?.from;
  const isOpenedFromCampaignDetails = paths.campaign(projectId, campaignUuid) === previousPagePath;
  const isClaimed = !!project && project.isClaimed;

  const permissionsChecker = usePermissionsChecker(project);

  const showModal = useCallback(() => {
    dispatch(
      showConfirmModal({
        id: MODAL_NAME,
        isOpened: !isConfirmModalOpened,
      })
    );
  }, [dispatch, isConfirmModalOpened]);

  const hideModal = useCallback(() => {
    dispatch(
      showConfirmModal({
        id: MODAL_NAME,
        isOpened: false,
      })
    );
  }, [dispatch]);

  const handleUndoDeleteCampaign = useCallback(() => {
    dispatch(undoDeleteCampaign.request({ projectId, campaignUuid, withoutAmplitude: false }));
  }, [dispatch, projectId, campaignUuid]);

  const onCampaignRemoved = useCallback(() => {
    if (isOpenedFromCampaignDetails) {
      go(-2, () => navigateTo(paths.mediaPlan(projectId)));
    } else {
      goBack(() => navigateTo(paths.mediaPlan(projectId)));
    }
  }, [isOpenedFromCampaignDetails, projectId]);

  const deleteHandler = useCallback(() => {
    if (!isBeingProcessed && campaign) {
      const action = isCampaignExternal(campaign)
        ? unassignCampaign.request({
            projectId,
            campaignUuid: campaign.uuid,
            onUnassignCampaignAction: onCampaignRemoved,
          })
        : deleteCampaign.request({
            projectId,
            campaignUuid: campaign.uuid,
            onUndo: handleUndoDeleteCampaign,
            onDeleteCampaignAction: onCampaignRemoved,
          });

      dispatch(action);

      hideModal();
    }
  }, [isBeingProcessed, campaign, projectId, onCampaignRemoved, handleUndoDeleteCampaign, dispatch, hideModal]);

  const permittedToRemoveCampaign = permissionsChecker(ACCESS_PERMISSIONS.REMOVE_CAMPAIGN);
  const canRemoveCampaign = permittedToRemoveCampaign && !!campaign && !campaign.isPending;

  const tooptipContent = useMemo(() => {
    if (!campaign) return;

    if (!permittedToRemoveCampaign) {
      return (
        <>
          <div>You have no rights to remove this campaign.</div>
          {!isClaimed && <div>Please contact project editor.</div>}
        </>
      );
    }

    if (campaign.isPending) {
      return <div>Pending campaigns can’t be removed.</div>;
    }

    return null;
  }, [campaign, permittedToRemoveCampaign, isClaimed]);

  return (
    <>
      <Tooltip content={tooptipContent} enabled={!canRemoveCampaign}>
        <SBox className={className}>
          <span>
            <Button
              variant="secondary-white"
              onClick={showModal}
              disabled={!canRemoveCampaign || isBeingProcessed}
              dataSelector={'campaign-delete-button'}
            >
              <Icon name="delete" />
              Remove
            </Button>
          </span>
        </SBox>
      </Tooltip>
      <Confirm
        visible={isConfirmModalOpened}
        onOk={deleteHandler}
        okText="Remove"
        title={
          <SModalTitle>
            Are you sure you want
            <br />
            to remove this campaign?
          </SModalTitle>
        }
        onCancel={hideModal}
      />
    </>
  );
});
