import * as t from 'io-ts';
import { FormattedDateFromISOString, ResponseModel } from '../../common';

export const UpdateScheduleRequestSchema = ResponseModel(
  {
    startDate: FormattedDateFromISOString,
    endDate: FormattedDateFromISOString,
  },
  'UpdateScheduleRequest'
);

export type UpdateScheduleRequest = t.TypeOf<typeof UpdateScheduleRequestSchema>;
