import styled from 'styled-components';

export const BEM_CLASS = 'date-input';

export const SDateInput = styled.div`
  .${BEM_CLASS} {
    &__input {
      padding: 10px;
      height: 100%;
      width: 100%;
      border: none;
      font-size: 14px;
    }
  }
`;
