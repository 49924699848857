import { UUID } from 'io-ts-types/lib/UUID';
import { apiClient, EmptyPayload, EmptySchema, ResponseArray } from '../index';
import {
  AdSetsResponseSchema,
  AssignableProjectsResponseSchema,
  AssignCampaignsRequestSchema,
  CampaignAssignResponseSchema,
  CampaignDetailsResponseSchema,
  CampaignDetailsSchema,
  CampaignPlacementSchema,
  CampaignProviderSchema,
  CampaignRequest,
  CampaignRequestSchema,
  CampaignsRequestSchema,
  CampaignsResponseSchema,
  CampaignStatusSchema,
  GenderSchema,
  GenerateCampaignName,
  GenerateCampaignNameResponseSchema,
  GoalSchema,
  Id,
  NewCampaignPlacement,
  NewCampaignPlacementSchema,
  UpdateScheduleRequest,
  UpdateScheduleRequestSchema,
  ManageExternalCampaignsRequestSchema,
  ManageExternalCampaignsRequest,
} from '../models';
import {
  AdSetsFilterParams,
  AssignableProjectsParams,
  CampaignFilterParams,
  ChangeCampaignsStatusRequest,
  FromSearchParam,
  RemoveCampaignsParams,
} from '../types';

export const approveCampaign = (projectId: Id, campaignUuid: UUID, isUnassigned = false) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/${isUnassigned ? 'unassigned-' : ''}projects/${projectId}/campaigns-list/${campaignUuid}/approve`,
    requestSchema: EmptySchema,
    responseSchema: CampaignAssignResponseSchema,
    payload: EmptyPayload,
  });

export const rejectCampaign = (projectId: Id, campaignUuid: UUID, isUnassigned = false) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/${isUnassigned ? 'unassigned-' : ''}projects/${projectId}/campaigns-list/${campaignUuid}/reject`,
    requestSchema: EmptySchema,
    responseSchema: EmptySchema,
    payload: EmptyPayload,
  });

export const createCampaign = (id: Id, data: CampaignRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${id}/campaigns`,
    requestSchema: CampaignRequestSchema,
    responseSchema: CampaignDetailsSchema,
    payload: data,
  });

export const editCampaign = (projectId: Id, campaignUuid: UUID, payload: CampaignRequest) =>
  apiClient.executeRequest({
    method: 'PUT',
    endpoint: `/projects/${projectId}/campaigns/${campaignUuid}`,
    requestSchema: CampaignRequestSchema,
    responseSchema: CampaignDetailsSchema,
    payload,
  });

export const getCampaignDetails = (projectId: Id, campaignUuid: UUID, params?: FromSearchParam) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/campaigns/${campaignUuid}`,
    queryParams: params,
    responseSchema: CampaignDetailsSchema,
  });

export const getUnassignedCampaignDetails = (campaignUuid: UUID, params?: FromSearchParam) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/unassigned-campaigns/${campaignUuid}`,
    queryParams: params,
    responseSchema: CampaignDetailsSchema,
  });

export const deleteCampaign = (projectId: Id, campaignUuid: UUID) =>
  apiClient.executeRequest({
    method: 'DELETE',
    endpoint: `/projects/${projectId}/campaigns/${campaignUuid}`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
  });

export const undoDeleteCampaign = (projectId: Id, campaignUuid: UUID) =>
  apiClient.executeRequest({
    method: 'PUT',
    endpoint: `/projects/${projectId}/campaigns/${campaignUuid}/undo/delete`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
  });

export const unassignCampaign = (projectId: Id, campaignUuid: UUID) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/campaigns/${campaignUuid}/unassign`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
  });

export const getGenders = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/campaign-genders',
    responseSchema: ResponseArray(GenderSchema),
  });

export const getCampaignProviders = (uuid: UUID) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/campaigns/${uuid}/campaign-providers`,
    responseSchema: ResponseArray(CampaignProviderSchema),
  });

export const getPerformanceCampaignProviders = (onlyExisting: boolean) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/campaign-providers',
    queryParams: { onlyExisting: onlyExisting },
    responseSchema: ResponseArray(CampaignProviderSchema),
  });

export const getGoals = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: '/campaign/goals',
    responseSchema: ResponseArray(GoalSchema),
  });

export const getAdSets = (campaignUuid: UUID, params?: AdSetsFilterParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/campaigns/${campaignUuid}/ads`,
    queryParams: params,
    responseSchema: AdSetsResponseSchema,
  });

export const exportAdSets = (campaignUuid: UUID) =>
  apiClient.executeBinaryRequest({
    method: 'GET',
    endpoint: `/campaigns/${campaignUuid}/ads/export`,
  });

export const assignCampaignsToProject = (campaignUuids: UUID[], projectId: Id) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/assign-campaigns`,
    requestSchema: AssignCampaignsRequestSchema,
    responseSchema: EmptySchema,
    payload: { campaignUuids },
  });

export const getAssignableProjects = ({ campaignUuid, query }: AssignableProjectsParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/campaigns/${campaignUuid}/assignable-projects`,
    responseSchema: AssignableProjectsResponseSchema,
    queryParams: { query: query },
  });

export const updateCampaignSchedule = (projectId: Id, campaignUuid: UUID, payload: UpdateScheduleRequest) =>
  apiClient.executeRequest({
    method: 'PATCH',
    endpoint: `/projects/${projectId}/campaigns/${campaignUuid}/update-schedule`,
    requestSchema: UpdateScheduleRequestSchema,
    responseSchema: CampaignDetailsSchema,
    payload,
  });

export const getAssignableCampaigns = (projectId: Id, params?: CampaignFilterParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `projects/${projectId}/assignable-campaigns`,
    queryParams: params,
    responseSchema: CampaignsResponseSchema,
  });

export const createCampaigns = (projectId: Id, campaigns: CampaignRequest[]) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/campaigns-bulk`,
    requestSchema: CampaignsRequestSchema,
    responseSchema: ResponseArray(CampaignDetailsSchema),
    payload: { campaigns },
  });

export const approveCampaigns = (projectId: Id, ids?: ChangeCampaignsStatusRequest, isUnassigned = false) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/${isUnassigned ? 'unassigned-' : ''}projects/${projectId}/approve-campaigns`,
    requestSchema: EmptySchema,
    responseSchema: ResponseArray(CampaignAssignResponseSchema),
    payload: ids,
  });

export const rejectCampaigns = (projectId: Id, ids?: ChangeCampaignsStatusRequest, isUnassigned = false) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/${isUnassigned ? 'unassigned-' : ''}projects/${projectId}/reject-campaigns`,
    requestSchema: EmptySchema,
    responseSchema: EmptySchema,
    payload: ids,
  });

export const unassignCampaigns = (projectId: Id, ids?: ChangeCampaignsStatusRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/unassign-campaigns`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: ids,
  });

export const removeCampaigns = (projectId: Id, params: RemoveCampaignsParams) =>
  apiClient.executeRequest({
    method: 'DELETE',
    endpoint: `/projects/${projectId}/campaigns-bulk`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
    queryParams: params,
  });

export const getCampaignStatuses = () =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/campaign-statuses`,
    responseSchema: ResponseArray(CampaignStatusSchema),
  });

export const createPlacements = (projectId: number, placement: NewCampaignPlacement) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/placements`,
    requestSchema: NewCampaignPlacementSchema,
    payload: placement,
    responseSchema: CampaignPlacementSchema,
  });

export const generateCampaignName = (projectId: number, campaignData: GenerateCampaignName) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/generate-campaign-name`,
    queryParams: campaignData,
    responseSchema: GenerateCampaignNameResponseSchema,
  });

export const getDigitalCampaigns = (projectId: number, params?: CampaignFilterParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/assignable-campaigns`,
    responseSchema: CampaignDetailsResponseSchema,
    queryParams: params,
  });

export const manageExternalCampaigns = (projectId: Id, campaignData: ManageExternalCampaignsRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/manage-external-campaigns`,
    payload: campaignData,
    requestSchema: ManageExternalCampaignsRequestSchema,
    responseSchema: EmptySchema,
  });
