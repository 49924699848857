import * as t from 'io-ts';
import { IdType, FormattedDateFromISOString, ResponseModel, Optional } from '../common';

const TrackSchema = ResponseModel(
  {
    id: IdType,
    title: t.string,
    isrc: t.string,
    supplementary: Optional(t.string),
    releaseDate: Optional(FormattedDateFromISOString),
  },
  'Track'
);

export const ProductFamilySchema = ResponseModel(
  {
    id: IdType,
    externalId: t.string,
    title: t.string,
    releaseDate: Optional(FormattedDateFromISOString),
    tracks: t.array(TrackSchema),
  },
  'ProductFamily'
);

export type ProductFamilyTrack = t.TypeOf<typeof TrackSchema>;

export type ProductFamily = t.TypeOf<typeof ProductFamilySchema>;
