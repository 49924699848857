import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { BEM_CLASS, SObjectiveEditor } from './s-objective-edtior';
import {
  SelectClassNamesProps,
  Select,
  SelectType,
  SelectMenuProps,
  SelectBaseOption,
  SelectComponents,
} from 'common-v2/components';
import { bem } from 'utils/bem';
import { goalsToSelectOptions } from 'media-plan-v2/transducers';
import { useSelectEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan-v2/components/spreadsheet/hooks';

const classes = bem(BEM_CLASS);

export const ObjectiveEditor = React.memo(
  React.forwardRef(({ value: tableValue, api, colDef, rowIndex, column }: ICellEditorParams, ref) => {
    const [value, setValue] = useState(tableValue.value);
    const { onBlur } = useStopEditingBlur(api);

    const goals = useMemo(() => colDef?.cellEditorParams.goals, [colDef?.cellEditorParams.goals]);

    const allObjectives = useMemo(() => goals?.flatMap(goal => goal.objectives), [goals]);
    const options = useMemo(() => goalsToSelectOptions(goals), [goals]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          ...tableValue,
          value,
        };
      },
    }));

    const handleChange = useCallback(
      (id: number) => {
        const objective = allObjectives?.find(option => option.id === id);

        if (objective) {
          setValue(objective);
          api.setFocusedCell(rowIndex, colDef.field || '');
        } else {
          setValue(null);
        }
      },
      [allObjectives, api, colDef.field, rowIndex]
    );

    const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions({
      api,
      value,
      setValue: handleChange,
      isMulti: false,
    });

    const selectClassNames = useCallback<(className: string) => SelectClassNamesProps>(
      className => ({
        selectContainer: classes('select-container'),
        control: classes('control'),
        menu: classes('menu'),
        valueContainer: { root: classes('value-container-root'), container: classes('value-container') },
        indicatorsContainer: classes('indicators-container'),
        menuPortal: className,
      }),
      []
    );

    const selectComponents = useCallback<(props: SelectMenuProps) => SelectComponents<SelectBaseOption>>(
      () => ({
        DropdownIndicator: null,
      }),
      []
    );

    return (
      <SObjectiveEditor objectiveId={value?.id} width={column.getActualWidth()}>
        {className => (
          <div className={`${className} ${className}--input`} id="container">
            <Select
              selectRef={selectRef}
              type={SelectType.Searchable}
              dataSelector="objective-editor"
              valueId={value?.id}
              valueHandler={handleChange}
              options={options}
              classNames={selectClassNames(className)}
              selectComponents={selectComponents}
              onBlur={onBlur}
              isSearchable
              placeholder=""
              isOpened
              autoFocus
              onKeyDown={onKeyDown}
            />
          </div>
        )}
      </SObjectiveEditor>
    );
  })
);
