import styled, { css } from 'styled-components';
import { dropdown } from 'app/styles/z-indexes';

interface Props {
  isVisible: boolean;
  position?: {
    x: number;
    y: number;
  };
}

export const SMenuPortal = styled.div<Props>`
  position: absolute;
  z-index: ${dropdown};

  ${({ isVisible, position }) => css`
    opacity: ${isVisible && position ? 1 : 0};
    visibility: ${isVisible && position ? 'visible' : 'hidden'};
    top: ${position?.y || 0}px;
    left: ${position?.x || 0}px;
  `}
`;
