import {
  ApprovalRequest,
  Id,
  MediaPlan,
  MediaPlanListItem,
  MediaPlanPhase,
  MediaPlanRelease,
  MediaPlanReleaseUpdateResponse,
  ModifyBulkCampaignsResponse,
  ProjectMetrics,
  ProjectReviewer,
  SubmitApprovalCampaign,
} from 'backend-api/models';
import { actionsGroup } from 'core/store';
import {
  CreateMediaPlanPayload,
  CreatePhasePayload,
  CustomResponseError,
  DeleteMediaPlanPayload,
  DeletePhasePayload,
  EditMediaPlanPayload,
  EditPhasePayload,
  GetMediaPlanPayload,
  MediaPlanMode,
  ModifyBulkCampaignsPayload,
  RequestApprovalPayload,
  SubmitApprovalPayload,
  UpdateMediaPlanReleasePayload,
} from './types';
import { SpreadsheetValidationModel } from 'media-plan/components/spreadsheet/types';
import { UUID } from 'io-ts-types/lib/UUID';
import { RequestApprovalModalInfo } from './components';

const { action, asyncAction } = actionsGroup('media-plan');

export const getAllMediaPlans = asyncAction<Id, MediaPlanListItem[], Error>('OUTDATED_GET_ALL_MEDIA_PLANS');

export const getMediaPlan = asyncAction<GetMediaPlanPayload, MediaPlan, Error>('OUTDATED_GET_MEDIA_PLAN');

export const createPhase = asyncAction<CreatePhasePayload, MediaPlanPhase, CustomResponseError>(
  'OUTDATED_CREATE_PHASE'
);
export const editPhase = asyncAction<EditPhasePayload, MediaPlanPhase, CustomResponseError>('OUTDATED_EDIT_PHASE');
export const deletePhase = asyncAction<DeletePhasePayload, Id, CustomResponseError>('OUTDATED_DELETE_PHASE');
export const focusPhaseInput = action<Id>('OUTDATED_FOCUS_PHASE_INPUT');
export const resetPhaseInput = action<void>('OUTDATED_RESET_PHASE_INPUT');

export const modifyBulkCampaigns = asyncAction<
  ModifyBulkCampaignsPayload,
  ModifyBulkCampaignsResponse,
  CustomResponseError
>('OUTDATED_MODIFY_BULK_CAMPAIGN');

export const clearMediaPlan = action<void>('OUTDATED_CLEAR_MEDIA_PLAN');

export const openConfirmModal = action<string>('OUTDATED_OPEN_CONFIRM_MODAL');
export const closeConfirmModal = action<void>('OUTDATED_CLOSE_CONFIRM_MODAL');

export const setSpreadsheetValidationModel = action<SpreadsheetValidationModel>(
  'OUTDATED_SET_SPREADSHEET_VALIDATION_MODEL'
);

export const getMediaPlanRelease = asyncAction<GetMediaPlanPayload, MediaPlanRelease, Error>(
  'OUTDATED_GET_PROJECT_RELEASE'
);
export const updateMediaPlanRelease = asyncAction<UpdateMediaPlanReleasePayload, MediaPlanReleaseUpdateResponse, Error>(
  'OUTDATED_UPDATE_PROJECT_RELEASE'
);

export const selectCampaign = action<UUID>('OUTDATED_SELECT_CAMPAIGN');
export const deselectCampaign = action<UUID>('OUTDATED_DESELECT_CAMPAIGN');
export const resetCampaignSelection = action<void>('OUTDATED_RESET_CAMPAIGN');

export const changeMediaPlanMode = action<MediaPlanMode>('OUTDATED_CHANGE_MEDIA_PLAN_MODE');

export const getMediaPlanReviewers = asyncAction<GetMediaPlanPayload, ProjectReviewer[], Error>(
  'OUTDATED_GET_MEDIA_PLAN_REVIEWERS'
);

export const requestApproval = asyncAction<RequestApprovalPayload, void, CustomResponseError>(
  'OUTDATED_REQUEST_APPROVAL'
);

export const getApprovalRequests = asyncAction<GetMediaPlanPayload, ApprovalRequest[], Error>(
  'OUTDATED_GET_APPROVAL_REQUESTS'
);
export const clearApprovalRequests = action<void>('OUTDATED_CLEAR_APPROVAL_REQUESTS');

export const getMediaPlanMetrics = asyncAction<GetMediaPlanPayload, ProjectMetrics, Error>(
  'OUTDATED_GET_PROJECT_METRICS'
);

export const addCampaignForApproval = action<SubmitApprovalCampaign>('OUTDATED_ADD_CAMPAIGNS_FOR_APPROVAL');
export const resetCampaignsForApproval = action<void>('OUTDATED_RESET_CAMPAIGNS_FOR_APPROVAL');

export const setMediaPlanMode = action<MediaPlanMode>('OUTDATED_SET_MEDIA_PLAN_MODE');

export const setShowAllCampaignsInApproval = action<boolean>('OUTDATED_SET_SHOW_ALL_CAMPAIGNS_IN_APPROVAL');

export const setDisableRequestApprovalButton = action<boolean>('OUTDATED_SET_DISABLE_REQUEST_APPROVAL_BUTTON');

export const submitApproval = asyncAction<SubmitApprovalPayload, void, Error>('OUTDATED_SUBMIT_APPROVAL');

export const setRequestApprovalModalInfo = action<RequestApprovalModalInfo>('OUTDATED_SET_REQUEST_APPROVAL_MODAL_INFO');
export const resetRequestApprovalModalInfo = action<void>('OUTDATED_RESET_REQUEST_APPROVAL_MODAL_INFO');

export const setMediaPlanNotFoundModalOpened = action<boolean>('OUTDATED_SET_MEDIA_PLAN_NOT_FOUND_MODAL_OPENED');

export const createMediaPlan = asyncAction<CreateMediaPlanPayload, MediaPlanListItem, Error>(
  'OUTDATED_CREATE_MEDIA_PLAN'
);
export const editMediaPlan = asyncAction<EditMediaPlanPayload, MediaPlanListItem, Error>('OUTDATED_EDIT_MEDIA_PLAN');
export const deleteMediaPlan = asyncAction<DeleteMediaPlanPayload, unknown, Error>('OUTDATED_DELETE_MEDIA_PLAN');

export const openDeleteMediaPlanConfirmationModal = action<Id>('OUTDATED_OPEN_DELETE_MEDIA_PLAN_CONFIRMATION_MODAL');
export const closeDeleteMediaPlanConfirmationModal = action<void>(
  'OUTDATED_CLOSE_DELETE_MEDIA_PLAN_CONFIRMATION_MODAL'
);

export const openMediaPlansDropdown = action<void>('OUTDATED_OPEN_MEDIA_PLANS_DROPDOWN');
export const closeMediaPlansDropdown = action<void>('OUTDATED_CLOSE_MEDIA_PLANS_DROPDOWN');

export const openMediaPlanUpdatedWarningModal = action<void>('OUTDATED_OPEN_UPDATED_MEDIA_PLAN_WARNING_MODAL');
export const closeMediaPlanUpdatedWarningModal = action<void>('OUTDATED_CLOSE_UPDATED_MEDIA_PLAN_WARNING_MODAL');
