import { Contact, Id, ProjectDetails, TargetItems, Territory } from 'backend-api/models';
import { GLOBAL_TERRITORY_ID } from 'common/constants';
import { ProjectTab } from 'common-v2/types';
import { getJoinedNames } from 'common/transducers';
import { isPlaylists } from 'backend-api/guards';

export const getUrlForProjectTab = (tab: ProjectTab, project?: ProjectDetails): string => {
  const baseUrl = `/projects/${project?.id}/`;

  switch (tab) {
    case ProjectTab.Summary:
      return `${baseUrl}details`;
    case ProjectTab.History:
      return `${baseUrl}history`;
    case ProjectTab.Finance:
      return `${baseUrl}purchase-orders`;
    case ProjectTab.Reporting:
      return `${baseUrl}reporting`;
    case ProjectTab.MediaPlan:
      return `${baseUrl}media-plans`;
    default:
      return baseUrl;
  }
};

export const getDataSelectorForProjectTab = (tab: ProjectTab) => {
  switch (tab) {
    case ProjectTab.Summary:
      return 'project-details-tab';
    case ProjectTab.History:
      return 'project-history-tab';
    case ProjectTab.Finance:
      return 'purchase-orders-tab';
    case ProjectTab.MediaPlan:
      return 'media-plan-tab';
    case ProjectTab.Reporting:
      return 'reporting-tab';
    default:
      return '';
  }
};

export const getCampaignNameConvention = (
  targets: TargetItems,
  projectName: string,
  projectId: Id,
  userInfo: Contact
) => {
  const components = Array<string>();

  if (!isPlaylists(targets)) {
    const primaryArtists = targets.filter(target => target.type === 'Primary');
    const joinedPrimaryArtists = getJoinedNames(primaryArtists);

    if (joinedPrimaryArtists) {
      components.push(joinedPrimaryArtists);
    }
  }

  components.push(`${projectName}`, `DEC = ${projectId}`, userInfo.name);

  const joinedComponents = components.join(' | ');

  return joinedComponents + ' |';
};

export const getTerritoryTooltipText = (territories: Territory[], currentTerritory: Territory): string => {
  const isGlobal = currentTerritory.id === GLOBAL_TERRITORY_ID;
  if (isGlobal && territories.length > 1) return 'List of countries targeted by at least one campaign in this project.';
  if (isGlobal) return 'Global';
  return currentTerritory.name;
};
