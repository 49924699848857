import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Typography,
  TYPOGRAPHY_TYPE,
  BADGE_TYPE,
  Icon,
  ICON_SIZE,
  Tooltip,
  TOOLTIP_PLACEMENT,
} from 'gdb-web-shared-components';
import { Artist, Playlist, ProjectSearch } from 'backend-api/models';
import { bem } from 'utils/bem';
import { paths } from 'app/routing/paths';
import placeholderPng from 'assets/user-note-65.png';
import { useFeatureFlagsContext } from 'common-v2/hooks';
import { getPrimaryTargets, getJoinedNames, getProjectAvatarUrl, isTreatmentEnabled } from 'common-v2/transducers';
import { CardContent, CardImage } from 'common-v2/components';
import { CARD_SIZE } from '../../constants';
import { SProjectCard, BEM_CLASS } from './s-project-card';
import { getFormattedDate, parseDateFromISOString } from 'common-v2/utils';

interface ProjectCardProps extends ProjectSearch {
  className?: string;
  dateFormat: string;
  withBadges?: boolean;
}

const classes = bem(BEM_CLASS);

export const ProjectCard = React.memo(
  ({
    className,
    dateFormat,
    withBadges = false,
    id,
    name,
    targets,
    editDate,
    isClaimed,
    isConfidential,
  }: ProjectCardProps) => {
    const { treatments } = useFeatureFlagsContext();

    const primaryTargets = useMemo(() => getPrimaryTargets(targets.items), [targets]);
    const targetsNames = useMemo(() => getJoinedNames<Artist | Playlist>(primaryTargets), [primaryTargets]);
    const date = useMemo(() => editDate && getFormattedDate(dateFormat)(parseDateFromISOString(editDate)), [
      dateFormat,
      editDate,
    ]);
    const avatarUrl = useMemo(() => getProjectAvatarUrl(primaryTargets), [primaryTargets]);
    const projectLink = useMemo(
      () => (isTreatmentEnabled(treatments, 'PROJECT_V2') ? paths.mediaPlan(id) : paths.project(id)),
      [id, treatments]
    );

    return (
      <Link to={`${projectLink}?fromSearch=1`}>
        <SProjectCard className={className} dataSelector="project-card" size={CARD_SIZE} hasAction>
          <CardImage url={avatarUrl} placeholder={placeholderPng} dataSelector="project-card-image" />

          <CardContent className={classes('content')}>
            <Typography className={classes('title')} type={TYPOGRAPHY_TYPE.body2} data-selector="project-card-title">
              <span className={classes('name')}>{name}</span>

              {isConfidential && (
                <div className={classes('icon-wrapper')}>
                  <Tooltip
                    tooltip="Confidential project"
                    placement={TOOLTIP_PLACEMENT.Top}
                    data-selector="project-card-confidential-tooltip"
                  >
                    <Icon
                      name="lock-outlined"
                      size={ICON_SIZE.medium}
                      className={classes('icon')}
                      dataAttributes={{ 'data-selector': 'project-confidential-icon' }}
                    />
                  </Tooltip>
                </div>
              )}
            </Typography>

            <Typography className={classes('info')} type={TYPOGRAPHY_TYPE.body4}>
              {withBadges && (
                <>
                  <Badge
                    content="project"
                    type={BADGE_TYPE.secondary}
                    className={classes('badge')}
                    data-selector="project-card-project-badge"
                  />
                  {!isClaimed && (
                    <Badge
                      content="unclaimed"
                      type={BADGE_TYPE.tertiary}
                      className={classes('badge')}
                      data-selector="project-card-unclaimed-badge"
                    />
                  )}
                </>
              )}

              <span className={classes('targets')} data-selector="project-card-targets">
                {targetsNames}
              </span>

              {Boolean(date) && (
                <span className={classes('date')} data-selector="project-card-date">
                  &nbsp;•&nbsp;{date}
                </span>
              )}
            </Typography>
          </CardContent>
        </SProjectCard>
      </Link>
    );
  }
);
