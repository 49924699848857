import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'members-block';

export const SMembersBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;

  .${BEM_CLASS} {
    &__title {
      color: ${manhattanThemeColors.grayedText};
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }

    &__empty {
      color: ${manhattanThemeColors.grayedText};
    }
  }
`;
