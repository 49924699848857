import styled from 'styled-components';

export const BEM_CLASS = 'users-list';

export const SUsersList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 2px 0 20px;
  overflow: auto;

  .${BEM_CLASS} {
    &__users-tree {
      flex: 1;
      overflow-y: auto;
    }

    &__empty-view {
      height: 100%;
    }

    &__tree-item {
      margin-top: 9px;
      margin-bottom: 9px;
    }

    &__user-item {
      display: flex;
      flex-grow: 1;
      align-items: center;
      justify-content: space-between;
      margin-right: 18px;
    }

    &__badge {
      height: 15px;
      margin: 1px 0 0 10px;
    }

    &__approver-badge {
      height: 24px;
      margin-left: 10px;
    }

    &__loader {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
