import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { BadgeSelectProps, BaseOption as BaseOptionType, SelectMenuProps, SelectType } from '../../types';
import { BaseSelect } from '../base-select';
import { BEM_CLASS, SBadgeSelect } from './s-badge-select';

const classes = bem(BEM_CLASS);

const Root = <T extends BaseOptionType>({
  classNames,
  selectContainer,
  ...props
}: BadgeSelectProps<T> & SelectMenuProps) => {
  const selectClassNames = useMemo(
    () => ({
      ...classNames,
      control: classes('control', undefined, classNames?.control),
      valueContainer: { root: classes('value-container', undefined, classNames?.valueContainer?.root) },
      singleValue: classes('single-value', undefined, classNames?.singleValue),
      indicatorsContainer: classes('indicators-container', undefined, classNames?.indicatorsContainer),
    }),
    [classNames]
  );

  return (
    <SBadgeSelect>
      {selectContainer?.({ ...props, classNames: selectClassNames }) || (
        <BaseSelect {...props} type={SelectType.Base} classNames={selectClassNames} />
      )}
    </SBadgeSelect>
  );
};

export const BadgeSelect = React.memo(Root) as typeof Root;
