import { MenuPlacement } from '../../types';
import { MENU_OFFSET } from './constants';

export const getMenuPlacement = (
  menuPlacement: MenuPlacement,
  controlRect: DOMRect,
  menuRect: DOMRect,
  menuOffset: number = MENU_OFFSET
): { x: number; y: number } => {
  const { scrollX, scrollY } = window;
  const { x: controlX, y: controlY, width: controlWidth, height: controlHeight } = controlRect;
  const { width: menuWidth, height: menuHeight } = menuRect;

  const getMenuPosition = (placement: MenuPlacement) => {
    switch (placement) {
      case MenuPlacement.TopLeft: {
        const x = controlX + scrollX;
        const y = controlY - menuOffset - menuHeight + scrollY;

        return { x, y };
      }
      case MenuPlacement.TopRight: {
        const x = controlX + controlWidth - menuWidth + scrollX;
        const y = controlY - menuOffset - menuHeight + scrollY;

        return { x, y };
      }
      case MenuPlacement.BottomLeft: {
        const x = controlX + scrollX;
        const y = controlY + controlHeight + menuOffset + scrollY;

        return { x, y };
      }
      case MenuPlacement.BottomRight: {
        const x = controlX + controlWidth - menuWidth + scrollX;
        const y = controlY + controlHeight + menuOffset + scrollY;

        return { x, y };
      }
      case MenuPlacement.Auto: {
        const { innerWidth, innerHeight } = window;

        const shouldShowRight = controlX + controlWidth + menuWidth > innerWidth;
        const shouldShowTop = controlY + controlHeight + menuHeight > innerHeight;

        if (shouldShowRight && shouldShowTop) return getMenuPosition(MenuPlacement.TopRight);
        else if (shouldShowRight && !shouldShowTop) return getMenuPosition(MenuPlacement.BottomRight);
        else if (!shouldShowRight && shouldShowTop) return getMenuPosition(MenuPlacement.TopLeft);
        else return getMenuPosition(MenuPlacement.BottomLeft);
      }
    }
  };

  return getMenuPosition(menuPlacement);
};
