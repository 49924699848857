import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { GroupBase } from 'react-select';
import { MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu';
import { BaseOption as BaseOptionType } from '../../types';
import { getMenuPlacement } from './transducers';
import { getBaseCustomSelectProps } from '../../transducers';
import { SMenuPortal } from './s-menu-portal';
import { createPortal } from 'react-dom';

type Props<T extends BaseOptionType, IsMulti extends boolean> = MenuPortalProps<T, IsMulti, GroupBase<T>>;

const Root = <T extends BaseOptionType, IsMulti extends boolean>({
  selectProps,
  controlElement,
  children,
  appendTo,
}: Props<T, IsMulti>) => {
  const portalRef = useRef<HTMLDivElement>(null);

  const [position, setPosition] = useState<{ x: number; y: number }>();

  const { closeMenu, classNames, portalPlacement, dataSelector } = getBaseCustomSelectProps(selectProps);

  const calculateMenuPosition = useCallback(() => {
    const controlRect = controlElement?.getBoundingClientRect();
    if (controlRect && portalRef?.current) {
      const menuElementRect = portalRef.current.getBoundingClientRect();
      const position = getMenuPlacement(portalPlacement, controlRect, menuElementRect);
      setPosition(position);
    } else {
      setPosition(undefined);
    }
  }, [controlElement, portalPlacement]);

  useLayoutEffect(() => calculateMenuPosition(), [calculateMenuPosition]);

  useLayoutEffect(() => {
    const portalElement = portalRef.current;

    if (!portalElement) return;

    const resizeObserver = new ResizeObserver(calculateMenuPosition);
    resizeObserver.observe(portalElement);

    return () => {
      resizeObserver.unobserve(portalElement);
    };
  }, [calculateMenuPosition]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const selectClicked = event.composedPath().some(element => (element as HTMLElement).id === 'select');
      if (portalRef?.current?.contains(event.target as Node) || selectClicked) return;
      closeMenu();
    };

    document.addEventListener('click', handleClick, { capture: true });

    return () => {
      document.removeEventListener('click', handleClick, { capture: true });
    };
  }, [closeMenu]);

  return createPortal(
    <SMenuPortal
      ref={portalRef}
      className={classNames.menuPortal}
      isVisible={selectProps.menuIsOpen}
      position={position}
      data-selector={`${dataSelector}-menu-portal`}
    >
      {children}
    </SMenuPortal>,
    appendTo ?? document.body
  );
};

export const MenuPortal = Root;
