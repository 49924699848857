import {
  ApprovalRequest,
  ArtistTeam,
  Id,
  MediaPlan,
  MediaPlanListItem,
  MediaPlanPhase,
  MediaPlanRelease,
  NamingConventionResponse,
  Optional,
  ProjectMetrics,
  ProjectReviewer,
  SubmitApprovalCampaign,
} from 'backend-api/models';
import { LoadableData, LoadingState } from 'common/types';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { createTypedReducer, onAction } from 'core/store';
import { UUID } from 'io-ts-types/lib/UUID';
import { SpreadsheetValidationModel } from 'media-plan-v2/containers/spreadsheet/types';
import {
  addCampaignForApproval,
  changeMediaPlanMode,
  clearMediaPlan,
  closeConfirmModal,
  closeDeleteMediaPlanConfirmationModal,
  closeMediaPlansDropdown,
  closeMediaPlanUpdatedWarningModal,
  createMediaPlan,
  createPhase,
  deleteMediaPlan,
  deletePhase,
  editPhase,
  deselectCampaign,
  editMediaPlan,
  focusPhaseInput,
  getAllMediaPlans,
  getApprovalRequests,
  getMediaPlan,
  getMediaPlanMetrics,
  getMediaPlanRelease,
  getMediaPlanReviewers,
  getProjectArtistTeam,
  modifyBulkCampaigns,
  openConfirmModal,
  openDeleteMediaPlanConfirmationModal,
  openMediaPlansDropdown,
  openMediaPlanUpdatedWarningModal,
  requestApproval,
  resetCampaignSelection,
  resetCampaignsForApproval,
  resetPhaseInput,
  selectCampaign,
  setDisableRequestApprovalButton,
  setMediaPlanMode,
  setMediaPlanNotFoundModalOpened,
  setSpreadsheetValidationModel,
  submitApproval,
  updateMediaPlanRelease,
} from './actions';
import { DeleteMediaPlanModalInfo, MediaPlanMode } from './types';
import { ApiError } from 'backend-api';

export interface MediaPlanV2State {
  mediaPlans: LoadableData<MediaPlanListItem[]>;
  phases: MediaPlanPhase[];
  mediaPlan: LoadableData<MediaPlan>;
  mediaPlanError?: ApiError<ProjectDetailsErrorResponse>;
  isMediaPlanNotFoundModalOpened?: boolean;
  confirmModal: {
    text: string;
    isVisible: boolean;
  };
  isCreatePhaseSyncing: LoadingState;
  isDeletePhaseSyncing: LoadingState;
  isModifyCampaignSyncing: LoadingState;
  focusedPhaseId: Optional<Id>;
  spreadsheetValidationModel: Optional<SpreadsheetValidationModel>;
  projectRelease: LoadableData<MediaPlanRelease>;
  autogeneratedNames: LoadableData<NamingConventionResponse[]>;
  mediaPlanMode: MediaPlanMode;
  selectedCampaignsIds: UUID[];
  projectReviewers: LoadableData<ProjectReviewer[]>;
  isRequestApprovalLoading: LoadingState;
  campaignsForApproval: SubmitApprovalCampaign[];
  approvalRequests: LoadableData<ApprovalRequest[]>;
  disableRequestApprovalButton: boolean;
  projectMetrics: LoadableData<Optional<ProjectMetrics>>;
  confirmDeleteMediaPlanModal: DeleteMediaPlanModalInfo;
  isMediaPlansProcessing: boolean;
  isMediaPlanDropdownVisible: boolean;
  isMediaPlanUpdatedModalOpened: boolean;
  artistTeam: LoadableData<Optional<ArtistTeam>>;
  error?: ApiError<ProjectDetailsErrorResponse>;
}

export const initialState: MediaPlanV2State = {
  mediaPlans: {
    loading: LoadingState.Idle,
    data: [],
  },
  phases: [],
  mediaPlan: {
    loading: LoadingState.Idle,
    data: { phases: [] },
  },
  isMediaPlanNotFoundModalOpened: false,
  confirmModal: {
    text: '',
    isVisible: false,
  },
  isCreatePhaseSyncing: LoadingState.Finished,
  isDeletePhaseSyncing: LoadingState.Finished,
  isModifyCampaignSyncing: LoadingState.Finished,
  focusedPhaseId: undefined,
  spreadsheetValidationModel: undefined,
  projectRelease: {
    loading: LoadingState.Idle,
    data: { name: undefined, type: undefined },
  },
  autogeneratedNames: {
    loading: LoadingState.Idle,
    data: [],
  },
  approvalRequests: {
    loading: LoadingState.Idle,
    data: [],
  },
  mediaPlanMode: MediaPlanMode.DEFAULT,
  selectedCampaignsIds: [],
  projectReviewers: {
    loading: LoadingState.Idle,
    data: [],
  },
  projectMetrics: {
    loading: LoadingState.Idle,
    data: undefined,
  },
  isRequestApprovalLoading: LoadingState.Idle,
  campaignsForApproval: [],
  disableRequestApprovalButton: true,
  confirmDeleteMediaPlanModal: {
    isVisible: false,
  },
  isMediaPlansProcessing: false,
  isMediaPlanDropdownVisible: false,
  isMediaPlanUpdatedModalOpened: false,
  artistTeam: {
    loading: LoadingState.Idle,
    data: undefined,
  },
};

export const reducer = createTypedReducer<MediaPlanV2State>(
  initialState,
  onAction(getAllMediaPlans.request, state => ({
    ...state,
    mediaPlans: {
      ...state.mediaPlans,
      loading: LoadingState.Started,
    },
  })),
  onAction(getAllMediaPlans.success, (state, action) => ({
    ...state,
    mediaPlans: {
      loading: LoadingState.Finished,
      data: action.payload,
    },
  })),
  onAction(getAllMediaPlans.failure, (state, action) => ({
    ...state,
    mediaPlans: {
      loading: LoadingState.Failed,
      data: [],
    },
    error: action.payload,
  })),
  onAction(getMediaPlan.request, state => ({
    ...state,
    mediaPlan: {
      ...state.mediaPlan,
      loading: LoadingState.Started,
    },
    phases: [],
  })),
  onAction(getMediaPlan.success, (state, action) => ({
    ...state,
    mediaPlan: {
      loading: LoadingState.Finished,
      data: action.payload,
    },
    phases: action.payload.phases,
  })),
  onAction(getMediaPlan.failure, (state, action) => ({
    ...state,
    mediaPlan: {
      loading: LoadingState.Failed,
      data: { phases: [] },
    },
    mediaPlanError: action.payload,
    error: action.payload,
    phases: [],
  })),
  onAction(createPhase.request, state => ({
    ...state,
    isCreatePhaseSyncing: LoadingState.Started,
  })),
  onAction(createPhase.success, (state, action) => ({
    ...state,
    phases: [...state.phases, action.payload],
    isCreatePhaseSyncing: LoadingState.Finished,
  })),
  onAction(createPhase.failure, (state, action) => ({
    ...state,
    isCreatePhaseSyncing: LoadingState.Failed,
    error: action.payload,
  })),
  onAction(deletePhase.request, state => ({
    ...state,
    isDeletePhaseSyncing: LoadingState.Started,
  })),
  onAction(deletePhase.success, (state, action) => ({
    ...state,
    phases: state.phases.filter(phase => phase.id !== action.payload),
    isDeletePhaseSyncing: LoadingState.Finished,
  })),
  onAction(deletePhase.failure, (state, action) => ({
    ...state,
    isDeletePhaseSyncing: LoadingState.Failed,
    error: action.payload,
  })),
  onAction(editPhase.failure, (state, action) => ({
    ...state,
    error: action.payload,
  })),
  onAction(modifyBulkCampaigns.request, state => ({
    ...state,
    isModifyCampaignSyncing: LoadingState.Started,
  })),
  onAction(modifyBulkCampaigns.success, state => ({
    ...state,
    isModifyCampaignSyncing: LoadingState.Finished,
  })),
  onAction(modifyBulkCampaigns.failure, (state, action) => ({
    ...state,
    isModifyCampaignSyncing: LoadingState.Failed,
    error: action.payload,
  })),
  onAction(openConfirmModal, (state, { payload }) => ({
    ...state,
    confirmModal: {
      text: payload,
      isVisible: true,
    },
  })),
  onAction(closeConfirmModal, state => ({
    ...state,
    confirmModal: {
      ...state.confirmModal,
      isVisible: false,
    },
  })),
  onAction(focusPhaseInput, (state, { payload }) => ({
    ...state,
    focusedPhaseId: payload,
  })),
  onAction(resetPhaseInput, state => ({
    ...state,
    focusedPhaseId: undefined,
  })),
  onAction(setSpreadsheetValidationModel, (state, action) => ({
    ...state,
    spreadsheetValidationModel: action.payload,
  })),
  onAction(selectCampaign, (state, action) => ({
    ...state,
    selectedCampaignsIds: state.selectedCampaignsIds.concat(action.payload),
  })),
  onAction(deselectCampaign, (state, action) => ({
    ...state,
    selectedCampaignsIds: state.selectedCampaignsIds.filter(id => id !== action.payload),
  })),
  onAction(resetCampaignSelection, state => ({
    ...state,
    selectedCampaignsIds: [],
  })),
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onAction(clearMediaPlan, _ => initialState),
  onAction(changeMediaPlanMode, (state, { payload }) => ({
    ...state,
    mediaPlanMode: payload,
  })),
  onAction(getMediaPlanReviewers.success, (state, { payload }) => ({
    ...state,
    projectReviewers: {
      loading: LoadingState.Finished,
      data: payload,
    },
  })),
  onAction(requestApproval.request, state => ({
    ...state,
    isRequestApprovalLoading: LoadingState.Started,
  })),
  onAction(requestApproval.success, state => ({
    ...state,
    isRequestApprovalLoading: LoadingState.Finished,
    selectedCampaignsIds: [],
    mediaPlanMode: MediaPlanMode.DEFAULT,
  })),
  onAction(requestApproval.failure, (state, action) => ({
    ...state,
    isRequestApprovalLoading: LoadingState.Failed,
    error: action.payload,
  })),
  onAction(addCampaignForApproval, (state, action) => ({
    ...state,
    campaignsForApproval: state.campaignsForApproval
      .filter(campaign => action.payload.campaignUuid !== campaign.campaignUuid)
      .concat(action.payload),
  })),
  onAction(resetCampaignsForApproval, state => ({
    ...state,
    campaignsForApproval: [],
  })),
  onAction(setMediaPlanMode, (state, action) => ({
    ...state,
    mediaPlanMode: action.payload,
  })),
  onAction(getApprovalRequests.request, state => ({
    ...state,
    approvalRequests: {
      ...state.approvalRequests,
      loading: LoadingState.Started,
    },
  })),
  onAction(getApprovalRequests.success, (state, action) => ({
    ...state,
    approvalRequests: {
      loading: LoadingState.Finished,
      data: action.payload,
    },
  })),
  onAction(getApprovalRequests.failure, state => ({
    ...state,
    approvalRequests: {
      ...state.approvalRequests,
      loading: LoadingState.Failed,
    },
  })),
  onAction(setDisableRequestApprovalButton, (state, action) => ({
    ...state,
    disableRequestApprovalButton: action.payload,
  })),
  onAction(submitApproval.request, state => ({
    ...state,
    isRequestApprovalLoading: LoadingState.Started,
  })),
  onAction(submitApproval.success, state => ({
    ...state,
    isRequestApprovalLoading: LoadingState.Finished,
    campaignsForApproval: [],
  })),
  onAction(submitApproval.failure, state => ({
    ...state,
    isRequestApprovalLoading: LoadingState.Failed,
    campaignsForApproval: [],
  })),
  onAction(getMediaPlanRelease.request, state => ({
    ...state,
    projectRelease: {
      ...state.projectRelease,
      loading: LoadingState.Started,
    },
  })),
  onAction(getMediaPlanRelease.success, (state, { payload }) => ({
    ...state,
    projectRelease: {
      loading: LoadingState.Finished,
      data: payload,
    },
  })),
  onAction(getMediaPlanRelease.failure, state => ({
    ...state,
    projectRelease: {
      ...state.projectRelease,
      loading: LoadingState.Failed,
    },
  })),
  onAction(updateMediaPlanRelease.request, state => ({
    ...state,
    autogeneratedNames: {
      ...state.autogeneratedNames,
      loading: LoadingState.Started,
    },
  })),
  onAction(updateMediaPlanRelease.success, (state, { payload }) => ({
    ...state,
    projectRelease: {
      ...state.projectRelease,
      data: {
        name: payload.name,
        type: payload.type,
      },
    },
    autogeneratedNames: {
      loading: LoadingState.Finished,
      data: payload.campaignsNames,
    },
  })),
  onAction(updateMediaPlanRelease.failure, state => ({
    ...state,
    autogeneratedNames: {
      ...state.autogeneratedNames,
      loading: LoadingState.Failed,
    },
  })),
  onAction(getMediaPlanMetrics.request, state => ({
    ...state,
    projectMetrics: { loading: LoadingState.Started, data: undefined },
  })),
  onAction(getMediaPlanMetrics.success, (state, action) => ({
    ...state,
    projectMetrics: { loading: LoadingState.Finished, data: action.payload },
  })),
  onAction(getMediaPlanMetrics.failure, state => ({
    ...state,
    projectMetrics: { loading: LoadingState.Failed, data: state.projectMetrics.data },
  })),
  onAction(setMediaPlanNotFoundModalOpened, (state, action) => ({
    ...state,
    isMediaPlanNotFoundModalOpened: action.payload,
    mediaPlanError: action.payload ? state.mediaPlanError : undefined,
  })),
  onAction(openDeleteMediaPlanConfirmationModal, (state, { payload }) => ({
    ...state,
    confirmDeleteMediaPlanModal: { isVisible: true, mediaPlanId: payload },
  })),
  onAction(closeDeleteMediaPlanConfirmationModal, state => ({
    ...state,
    confirmDeleteMediaPlanModal: { isVisible: false, mediaPlanId: undefined },
  })),
  onAction(createMediaPlan.request, state => processMediaPlans(state)),
  onAction(createMediaPlan.failure, (state, action) => processMediaPlansError(state, action)),
  onAction(createMediaPlan.success, state => finishProcessingMediaPlans(state)),
  onAction(editMediaPlan.request, state => processMediaPlans(state)),
  onAction(editMediaPlan.failure, (state, action) => processMediaPlansError(state, action)),
  onAction(editMediaPlan.success, state => finishProcessingMediaPlans(state)),
  onAction(deleteMediaPlan.request, state => processMediaPlans(state)),
  onAction(deleteMediaPlan.failure, (state, action) => processMediaPlansError(state, action)),
  onAction(deleteMediaPlan.success, state => finishProcessingMediaPlans(state)),
  onAction(openMediaPlansDropdown, state => ({
    ...state,
    isMediaPlanDropdownVisible: true,
  })),
  onAction(closeMediaPlansDropdown, state => ({
    ...state,
    isMediaPlanDropdownVisible: false,
  })),
  onAction(openMediaPlanUpdatedWarningModal, state => ({
    ...state,
    isMediaPlanUpdatedModalOpened: true,
  })),
  onAction(closeMediaPlanUpdatedWarningModal, state => ({
    ...state,
    isMediaPlanUpdatedModalOpened: false,
  })),
  onAction(getProjectArtistTeam.request, state => ({
    ...state,
    artistTeam: { loading: LoadingState.Started, data: undefined },
  })),
  onAction(getProjectArtistTeam.success, (state, action) => ({
    ...state,
    artistTeam: { loading: LoadingState.Finished, data: action.payload },
  })),
  onAction(getProjectArtistTeam.failure, state => ({
    ...state,
    artistTeam: { loading: LoadingState.Failed, data: undefined },
  }))
);

function processMediaPlans(state) {
  return {
    ...state,
    isMediaPlansProcessing: true,
  };
}

function processMediaPlansError(state, action) {
  return {
    ...finishProcessingMediaPlans(state),
    error: action.payload,
  };
}

function finishProcessingMediaPlans(state) {
  return {
    ...state,
    isMediaPlansProcessing: false,
  };
}
