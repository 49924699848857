import styled, { css } from 'styled-components';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { PADDING } from '../../constants';
import { ellipsizeText } from 'common-v2/styles';

interface Props {
  hasSeparator: boolean;
  isFirst: boolean;
  isLast: boolean;
}

export const BEM_CLASS = 'base-group';

export const SBaseGroup = styled.div<Props>`
  position: relative;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  ${({ hasSeparator }) =>
    hasSeparator &&
    css`
      margin-bottom: 1px;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: ${PADDING}px;
        display: block;
        width: calc(100% - 40px);
        height: 1px;
        background-color: ${colorPalette.lavenderGray};
      }
    `}


  .${BEM_CLASS} {
    &__label {
      ${ellipsizeText};
      display: block;
      padding: 0 ${PADDING}px;
      color: ${manhattanThemeColors.grayedText};
    }
  }
`;
