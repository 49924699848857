import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';

export const BEM_CLASS = 'summary-metrics';

export const SSummaryMetrics = styled.div`
  display: flex;
  gap: 20px;

  .${BEM_CLASS} {
    &__metric-item {
      color: ${colorPalette.eastBay};
      font-size: 12px;
      height: 12px;
      line-height: 12px;
      letter-spacing: 1.2px;
      display: flex;
      gap: 6px;
    }

    &__label {
      text-transform: uppercase;
    }
  }
`;
