import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { Dropdown } from 'common/components/dropdown';
import { transparentize } from 'polished';

export const SCardMenu = styled(Dropdown)`
  .card-menu {
    &__menu-dropdown-list {
      width: 200px;
      right: 0px;
    }

    &__icon-button {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      cursor: pointer;
      transition: background 0.8s;
      border-radius: 35px;
      background-size: 0;
      &:hover {
        background-color: ${transparentize('0.5', palette.lightGrayTwo)};
      }
      &:active {
        background-color: ${palette.lightGrayTwo};
        background-size: 100%;
        transition: background 0s;
      }
    }

    &__icon {
      width: 14px;
      height: 14px;
      display: flex;
      align-content: center;
      justify-content: center;
    }

    &__option-container {
      display: flex;
      align-items: center;
    }

    &__option-text {
      color: ${palette.darkBlue};
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      margin-left: 16px;
    }
  }
`;
