import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SNoRowsOverlay } from './s-no-rows-overlay';

const classes = bem(BEM_CLASS);

export const NoRowsOverlay = React.memo(() => (
  <SNoRowsOverlay>
    <h4 className={classes('text', 'title')}>No campaigns found</h4>
    <span className={classes('text')}>To start, please add campaigns manually</span>
  </SNoRowsOverlay>
));
