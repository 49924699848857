import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { Campaign } from 'backend-api/models';
import { BEM_CLASS, SSelectedCampaign } from './s-selected-campaign';
import { CampaignPlatformIcon } from 'common/components/campaign-platform-icon';
import { TooltipedText } from 'common/components/tooltiped-text';
import { useDatePeriodFormatted } from 'common/hooks/use-date-period-formatted';

interface Props {
  campaign: Campaign;
}

const classes = bem(BEM_CLASS);

export const SelectedCampaign = React.memo(({ campaign }: Props) => {
  const campaignDates = useDatePeriodFormatted([campaign.startDate, campaign.endDate]);

  const platforms = useMemo(() => campaign.platforms.map(item => item.name), [campaign.platforms]);

  return (
    <SSelectedCampaign>
      <div className={classes('image')}>
        <CampaignPlatformIcon platforms={platforms} iconColor="default" />
      </div>
      <div className={classes('content')}>
        <TooltipedText text={campaign.name} placement="bottom">
          <h4 className={classes('title')}>{campaign.name}</h4>
        </TooltipedText>
        <span className={classes('date')}>{campaignDates}</span>
      </div>
    </SSelectedCampaign>
  );
});
