import {
  ColDef,
  IAggFuncParams,
  EditableCallback,
  ValueGetterParams,
  ValueFormatterParams,
  CellRendererSelectorResult,
} from 'ag-grid-community/dist/lib/entities/colDef';
import { RowNode, ICellEditorParams } from 'ag-grid-community';
import { ICellRendererParams } from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer';
import { toNumber, isEqual, sum } from 'lodash';
import {
  Nullable,
  Optional,
  IdNameField,
  AudienceAge,
  CampaignStatus,
  CampaignPlacement,
  Taxonomy,
  CampaignPlatform,
  MediaPlanPhase,
} from 'backend-api/models';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import {
  MediaPlanColumnId,
  SpreadsheetRowData,
  InitialConfigParams,
  SpreadsheetValidationModel,
  SpreadsheetContext,
  MediaPlanAccessMode,
  SpreadsheetCellData,
  SpreadsheetRowFields,
  RowValueFormatterParams,
  CellClassRuleParams,
} from './types';
import { ACTION_COLUMN_WIDTH, CHECKBOX_COLUMN_WIDTH } from 'media-plan-v2/constants';
import { MAX_LINKS_COUNT } from 'media-plan/components/spreadsheet/components/expandable-links/constants';
import { MediaPlanMode } from 'media-plan-v2/types';
import {
  TotalRenderer,
  StatusRenderer,
  ActionButton,
  StatusEditor,
  CampaignNameRenderer,
  TextEditor,
  PlatformsEditor,
  PlatformsRenderer,
  PlacementsEditor,
  PlacementsRenderer,
  ObjectiveRenderer,
  ObjectiveEditor,
  CurrencyRenderer,
  CountriesRenderer,
  CountriesEditor,
  BudgetInputEditor,
  StartDateEditor,
  StartDateRenderer,
  EndDateEditor,
  EndDateRenderer,
  AgeEditor,
  AgeRenderer,
  GendersEditor,
  GendersRenderer,
  KpiEditor,
  KpiRenderer,
  DetailsRenderer,
  CallToActionRenderer,
  HeadlineRenderer,
  NamingConventionRenderer,
  NamingConventionEditor,
  NotesRenderer,
  LinksEditor,
  LinksRenderer,
  LinksReadonlyEditor,
  AdCopyRenderer,
  CreativeDescriptionRenderer,
  SimpleNumberRenderer,
} from 'media-plan-v2/components/spreadsheet/components';
import { estimatedImpressionsGetter } from 'media-plan-v2/transducers';
import { prepareCountries } from 'media-plan-v2/components/spreadsheet/components/cells/countries-cell/transducers';
import { getFlattenedPlatforms, getSelectOptions, getPlacementsOptions } from 'common/transducers';
import { MAX_AGE, MIN_AGE, CURRENCY_FORMATS } from 'common/constants';
import { DecibelLink } from 'utils/decibel-link';
import { isValidDate, parseDateByFormat, isDateAfter, getDiffInMiliseconds, getFormattedDate } from 'common-v2/utils';
import { isEnumValue } from 'common-v2/types';

export const getEmptyColumnConfig = (): ColDef<SpreadsheetRowData>[] => {
  return [
    {
      field: MediaPlanColumnId.ACTIONS,
      editable: false,
      width: ACTION_COLUMN_WIDTH,
      minWidth: ACTION_COLUMN_WIDTH,
      sortable: false,
      resizable: false,
      headerName: '',
      headerClass: ['not-resizable-header-cell', 'action-header-cell'],
      cellClass: 'action-cell',
      suppressFillHandle: true,
      suppressNavigable: true,
      suppressPaste: true,
      suppressHeaderKeyboardEvent: () => true,
      suppressKeyboardEvent: () => true,
      suppressSizeToFit: true,
      suppressAutoSize: true,
    },
    {
      field: MediaPlanColumnId.PHASE_ID,
      rowGroup: true,
      hide: true,
      editable: true,
      pinned: false,
    },
    {
      field: MediaPlanColumnId.STATUS,
      headerName: 'Status',
      initialWidth: 185,
      sortable: false,
      valueFormatter: params => params.value?.value,
      cellRendererSelector: params =>
        params.node.footer ? { component: TotalRenderer } : { component: StatusRenderer },
    },
    {
      field: MediaPlanColumnId.NAME,
      headerName: 'Campaign Description',
      initialWidth: 160,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.PLATFORMS,
      initialWidth: 160,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.PLACEMENTS,
      initialWidth: 160,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.OBJECTIVE,
      initialWidth: 160,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.KPI,
      initialWidth: 160,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.START_DATE,
      headerName: 'Start Date',
      initialWidth: 110,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.END_DATE,
      headerName: 'End Date',
      initialWidth: 110,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.BUDGET,
      initialWidth: 87,
      sortable: false,
    },
    { field: MediaPlanColumnId.ACTUAL_SPENT, initialWidth: 87, sortable: false },
    {
      field: MediaPlanColumnId.AGE,
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.AUDIENCE_GENDERS,
      headerName: 'Gender',
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.DETAILS,
      headerName: 'Audience Details',
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.ECPM,
      headerName: 'eCPM',
      initialWidth: 87,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.EST_IMPRESSIONS,
      initialWidth: 142,
      editable: false,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.COUNTRIES,
      headerName: 'Territory',
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.AD_COPY,
      initialWidth: 141,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.AD_CREATIVE,
      initialWidth: 160,
      sortable: false,
    },
    {
      field: MediaPlanColumnId.NOTES,
      initialWidth: 141,
      sortable: false,
    },
  ];
};

const normalizeSingleSelectValue = <T extends { value?: string } | { name: string }>(
  value: Nullable<string>,
  allOptions: T[]
): Optional<Nullable<T>> => {
  if (!value) return null;

  const validOption = allOptions.find(
    option =>
      ('value' in option && option?.value?.toLowerCase() === value.toLowerCase()) ||
      ('name' in option && option.name?.toLowerCase() === value.toLowerCase())
  );

  if (validOption === null) return undefined;

  return validOption;
};

const normalizeMultiSelectValue = <T extends { value?: string } | { name?: string }>(
  value: Nullable<string>,
  allOptions: T[]
): Optional<Nullable<T[]>> => {
  if (!value) return null;
  const selectedValues = value.split(', ').map(item => item.toLowerCase());
  const validOptions = allOptions?.filter(option => {
    if ('value' in option) {
      return selectedValues.includes(option.value?.toLowerCase() || '');
    } else if ('name' in option) {
      return selectedValues.includes(option.name?.toLowerCase() || '');
    }
    return false;
  });

  if (validOptions.length === 0) return undefined;

  return validOptions;
};

const normalizeNumber = (value: string): Optional<Nullable<number>> => {
  if (!value) return null;
  const number = toNumber(value);
  if (isNaN(number)) return undefined;
  return number;
};

const normalizeAge = (value: string): Optional<Nullable<AudienceAge>> => {
  if (!value) return null;
  const values = value.split('-');
  const lowerAge = toNumber(values[0]);
  const higherAge = toNumber(values[1]);

  if (isNaN(higherAge) && isNaN(lowerAge)) return undefined;

  return { lowerAge: isNaN(lowerAge) ? undefined : lowerAge, higherAge: isNaN(higherAge) ? undefined : higherAge };
};

const normalizeLinks = (value: string): Optional<Nullable<string[]>> => {
  if (!value) return null;
  const links = value.split(', ');

  if (links.length === 0 || links.length > MAX_LINKS_COUNT) return undefined;

  return value.split(', ');
};

const normalizeDate = (value: string, dateFormat?: string): Optional<Nullable<string>> => {
  if (!value) return null;

  return isValidDate(parseDateByFormat(value, dateFormat)) ? value : undefined;
};

const normalizeStatusValue = (value: string, data: SpreadsheetRowData, statuses: CampaignStatus[]) => {
  const currentStatusId = data.status.value;

  if (currentStatusId === value) return currentStatusId;

  const availableStatuses = statuses.find(status => status.status === currentStatusId)?.availableTransitions;
  const newStatusOption = statuses.find(status => status.status === value);

  if (!newStatusOption) {
    return undefined;
  }

  const canChangeStatus = availableStatuses?.includes(newStatusOption.status);

  if (!canChangeStatus) {
    return undefined;
  }

  return newStatusOption.status;
};

export const processPlacements = (
  platforms: SpreadsheetRowData['platforms'],
  taxonomy?: Taxonomy,
  value?: Nullable<string>
): Optional<CampaignPlacement[]> => {
  if (!value) return value === '' ? [] : undefined;

  const selectedPlatforms = platforms.value?.map(item => item.id);
  const selectedPlacements = value?.split(', ') || [];
  const availablePlacements =
    getPlacementsOptions(taxonomy?.placements, selectedPlatforms, taxonomy?.platforms)?.[0]?.options || [];

  const validPlacements: CampaignPlacement[] = [];

  availablePlacements.forEach(placement => {
    if (!selectedPlacements.includes(placement.value)) return;

    validPlacements.push({ id: Number(placement.id), name: placement.value, labelId: undefined });
  });

  return validPlacements.length === 0 ? undefined : validPlacements;
};

export const getColumnsValidationConfig = ({
  dateFormat,
  performanceObjectives,
  goals,
  taxonomy,
  genders,
  countries,
  statuses,
}: InitialConfigParams): SpreadsheetValidationModel => ({
  [MediaPlanColumnId.CHECKBOX]: {
    normalizeValue: () => null,
    formatToString: () => null,
  },
  [MediaPlanColumnId.ACTIONS]: {
    normalizeValue: () => null,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.PHASE_ID]: {
    normalizeValue: () => null,
    formatToString: () => null,
  },
  [MediaPlanColumnId.STATUS]: {
    normalizeValue: ({ value, data }) => normalizeStatusValue(value, data, statuses),
    formatToString: ({ value }) => value,
    errorText: 'Invalid Status type, data can not be copied.',
  },
  [MediaPlanColumnId.NAME]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.PLATFORMS]: {
    normalizeValue: ({ value }) => normalizeMultiSelectValue(value, getFlattenedPlatforms(taxonomy?.platforms || [])),
    formatToString: ({ value }) => value?.map(item => item.name).join(', '),
  },
  [MediaPlanColumnId.PLACEMENTS]: {
    normalizeValue: ({ value, data }) => processPlacements(data.platforms, taxonomy, value),
    formatToString: ({ value }) => value?.map(item => item.name).join(', '),
    errorText: "We're sorry, Placements inconsistent with Platforms were pasted into the table.",
  },
  [MediaPlanColumnId.OBJECTIVE]: {
    normalizeValue: ({ value }) => normalizeSingleSelectValue(value, goals?.flatMap(goal => goal.objectives) || []),
    formatToString: ({ value }) => value?.name,
  },
  [MediaPlanColumnId.KPI]: {
    normalizeValue: ({ value }) =>
      normalizeSingleSelectValue(
        value,
        getSelectOptions(performanceObjectives).flatMap(item => item.options)
      ),
    formatToString: ({ value }) => value?.value,
  },
  [MediaPlanColumnId.START_DATE]: {
    normalizeValue: ({ value }) => normalizeDate(value, dateFormat),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.END_DATE]: {
    normalizeValue: ({ value }) => normalizeDate(value, dateFormat),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.BUDGET]: {
    normalizeValue: ({ value }) => normalizeNumber(value),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.ACTUAL_SPENT]: {
    normalizeValue: ({ value }) => normalizeNumber(value),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.AGE]: {
    normalizeValue: ({ value }) => normalizeAge(value),
    formatToString: ({ value }) => value && `${value.lowerAge}-${value.higherAge}`,
  },
  [MediaPlanColumnId.AUDIENCE_GENDERS]: {
    normalizeValue: ({ value }) => normalizeMultiSelectValue(value, genders),
    formatToString: ({ value }) => value?.map(item => item.name).join(', '),
  },
  [MediaPlanColumnId.DETAILS]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.ECPM]: {
    normalizeValue: ({ value }) => normalizeNumber(value),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.EST_IMPRESSIONS]: {
    normalizeValue: ({ value }) => normalizeNumber(value),
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.COUNTRIES]: {
    normalizeValue: ({ value }) => normalizeMultiSelectValue(value, prepareCountries(countries)),
    formatToString: ({ value }) => value?.map(item => item.name).join(', '),
  },
  [MediaPlanColumnId.AD_COPY]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.AD_CREATIVE]: {
    normalizeValue: ({ value }) => normalizeLinks(value),
    formatToString: ({ value }) => value?.join(', '),
  },
  [MediaPlanColumnId.NOTES]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.CREATIVE_DESCRIPTION]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.DESTINATION]: {
    normalizeValue: ({ value }) => normalizeLinks(value),
    formatToString: ({ value }) => value?.join(', '),
  },
  [MediaPlanColumnId.HEADLINE]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.CALL_TO_ACTION]: {
    normalizeValue: ({ value }) => value,
    formatToString: ({ value }) => value,
  },
  [MediaPlanColumnId.NAMING_CONVENTION]: {
    normalizeValue: () => undefined,
    formatToString: ({ value, node }) => (node?.data?.namingConvention.value ?? '') + (value ?? ''),
    errorText: "We're sorry, you cannot update read-only data.",
  },
});

const canEditPlacements = (value: CampaignPlatform[] | null | undefined, taxonomy?: Taxonomy): boolean => {
  const platforms = value ? value.map(item => item.id) : undefined;
  const availablePlacements = getPlacementsOptions(taxonomy?.placements, platforms, taxonomy?.platforms);

  return availablePlacements.length > 0;
};

const isBaseCellEditable = (context?: SpreadsheetContext, data?: SpreadsheetRowData) =>
  context?.accessMode === MediaPlanAccessMode.EDIT &&
  (context?.mediaPlanMode !== MediaPlanMode.APPROVAL || data?.editableInApproval.value || false);

const isPlacementsCellEditable = (context?: SpreadsheetContext, data?: SpreadsheetRowData) =>
  isBaseCellEditable(context, data) && canEditPlacements(data?.platforms.value, context?.taxonomy);

export const getIsCellEditableCallback = (
  id: string
): ((context?: SpreadsheetContext, data?: SpreadsheetRowData) => boolean) => {
  switch (id) {
    case MediaPlanColumnId.STATUS:
    case MediaPlanColumnId.NAME:
    case MediaPlanColumnId.PLATFORMS:
    case MediaPlanColumnId.OBJECTIVE:
    case MediaPlanColumnId.KPI:
    case MediaPlanColumnId.START_DATE:
    case MediaPlanColumnId.END_DATE:
    case MediaPlanColumnId.BUDGET:
    case MediaPlanColumnId.ACTUAL_SPENT:
    case MediaPlanColumnId.AGE:
    case MediaPlanColumnId.AUDIENCE_GENDERS:
    case MediaPlanColumnId.DETAILS:
    case MediaPlanColumnId.ECPM:
    case MediaPlanColumnId.CREATIVE_DESCRIPTION:
    case MediaPlanColumnId.COUNTRIES:
    case MediaPlanColumnId.AD_COPY:
    case MediaPlanColumnId.AD_CREATIVE:
    case MediaPlanColumnId.DESTINATION:
    case MediaPlanColumnId.HEADLINE:
    case MediaPlanColumnId.CALL_TO_ACTION:
    case MediaPlanColumnId.NOTES:
    case MediaPlanColumnId.NAMING_CONVENTION:
      return isBaseCellEditable;
    case MediaPlanColumnId.PLACEMENTS:
      return isPlacementsCellEditable;
    default:
      return () => false;
  }
};

const isCellEditable: EditableCallback<SpreadsheetRowData> = params =>
  getIsCellEditableCallback(params.column.getColId())(params.context, params.data);

const suppressFooterRendererSelector = (params: ICellRendererParams<SpreadsheetRowData>): CellRendererSelectorResult =>
  params.node.footer ? { component: null } : { component: params.colDef?.cellRenderer };

const calculateRowIndex = ({ node, data, context, api }: ValueGetterParams<SpreadsheetRowData>): Optional<number> => {
  const isGroupRow = node?.group || node?.footer;
  const isFilteredApproval = context.mediaPlanMode === MediaPlanMode.APPROVAL && api.isAnyFilterPresent();

  if (node?.footer && isFilteredApproval) {
    const rowGroup = node?.sibling;
    const prevFooterValue = api.getValue(MediaPlanColumnId.ACTIONS, rowGroup);

    return rowGroup?.allLeafChildren.length + (prevFooterValue ?? 0) + 1;
  }

  if (node?.group && isFilteredApproval) {
    let phaseIndex;
    let footerCount = 0;

    api.forEachNode((iteratedNode, index) => {
      if (iteratedNode.id === node.id) {
        phaseIndex = index;
        return;
      }

      if (iteratedNode.group && phaseIndex === undefined) {
        footerCount++;
      }
    });

    return phaseIndex + footerCount + 1;
  }

  if (isGroupRow) return (node?.rowIndex || 0) + 1;

  const groupIndex = node?.parent ? api.getValue(MediaPlanColumnId.ACTIONS, node.parent) : 0;

  return (data?.orderInPhase?.value || 0) + (groupIndex ?? 0) + 1;
};

const getIsLinksInvalid = (value?: Nullable<string[]>) => {
  if (!value) return false;
  try {
    value.forEach(item => new DecibelLink(item));
    return false;
  } catch (e) {
    return true;
  }
};

const formatSelectCell = ({
  value,
}: ValueFormatterParams<SpreadsheetRowData, SpreadsheetCellData<SpreadsheetRowFields>>): string => {
  const formattedCell = Array.isArray(value?.value)
    ? value?.value.map(valueItem => valueItem.name).join(', ')
    : value?.value?.name;

  return formattedCell ?? '';
};

const multiSelectComparator = (
  valueA: SpreadsheetCellData<IdNameField[] | null | undefined>,
  valueB: SpreadsheetCellData<IdNameField[] | null | undefined>,
  rowNodeA: RowNode<SpreadsheetRowData>,
  rowNodeB: RowNode<SpreadsheetRowData>,
  isInverted: boolean
): number => {
  if (rowNodeA.data?.orderOnSort.value !== undefined && rowNodeB.data?.orderOnSort.value !== undefined) {
    const orderOnSortA = rowNodeA.data.orderOnSort.value;
    const orderOnSortB = rowNodeB.data.orderOnSort.value;

    return isInverted ? orderOnSortB - orderOnSortA : orderOnSortA - orderOnSortB;
  }

  const stringA = valueA?.value?.map(item => item.name).join(' ') || '';
  const stringB = valueB?.value?.map(item => item.name).join(' ') || '';

  const orderInPhaseA = rowNodeA.data?.orderInPhase.value ?? 0;
  const orderInPhaseB = rowNodeB.data?.orderInPhase.value ?? 0;

  if (stringA?.length === 0 && stringB?.length === 0)
    return isInverted ? orderInPhaseB - orderInPhaseA : orderInPhaseA - orderInPhaseB;

  if (stringA?.length === 0) return isInverted ? -1 : 1;
  if (stringB?.length === 0) return isInverted ? 1 : -1;

  return stringA?.localeCompare(stringB);
};

const numberComparator = (
  { value: valueA }: SpreadsheetCellData<number | null | undefined>,
  { value: valueB }: SpreadsheetCellData<number | null | undefined>,
  rowNodeA: RowNode<SpreadsheetRowData>,
  rowNodeB: RowNode<SpreadsheetRowData>,
  isInverted: boolean
): number => {
  if (valueA === valueB || (valueA == null && valueB == null)) {
    const orderInPhaseA = rowNodeA.data?.orderInPhase.value ?? 0;
    const orderInPhaseB = rowNodeB.data?.orderInPhase.value ?? 0;

    return isInverted ? orderInPhaseB - orderInPhaseA : orderInPhaseA - orderInPhaseB;
  }

  if (rowNodeA.data?.orderOnSort.value != null && rowNodeB.data?.orderOnSort.value != null) {
    const orderOnSortA = rowNodeA.data.orderOnSort.value;
    const orderOnSortB = rowNodeB.data.orderOnSort.value;

    return isInverted ? orderOnSortB - orderOnSortA : orderOnSortA - orderOnSortB;
  }

  const orderInPhaseA = rowNodeA.data?.orderInPhase.value ?? 0;
  const orderInPhaseB = rowNodeB.data?.orderInPhase.value ?? 0;

  if (valueA == null && valueB == null)
    return isInverted ? orderInPhaseB - orderInPhaseA : orderInPhaseA - orderInPhaseB;

  if (valueA == null) return isInverted ? -1 : 1;
  if (valueB == null) return isInverted ? 1 : -1;

  return valueA - valueB;
};

export const dateComparator = (
  valueA: SpreadsheetCellData<string | null | undefined>,
  valueB: SpreadsheetCellData<string | null | undefined>,
  rowNodeA: RowNode<SpreadsheetRowData>,
  rowNodeB: RowNode<SpreadsheetRowData>,
  isInverted: boolean
): number => {
  if (rowNodeA.data?.orderOnSort.value !== undefined && rowNodeB.data?.orderOnSort.value !== undefined) {
    const orderOnSortA = rowNodeA.data.orderOnSort.value;
    const orderOnSortB = rowNodeB.data.orderOnSort.value;

    return isInverted ? orderOnSortB - orderOnSortA : orderOnSortA - orderOnSortB;
  }

  const orderInPhaseA = rowNodeA.data?.orderInPhase.value ?? 0;
  const orderInPhaseB = rowNodeB.data?.orderInPhase.value ?? 0;

  if (!valueA?.value && !valueB?.value)
    return isInverted ? orderInPhaseB - orderInPhaseA : orderInPhaseA - orderInPhaseB;

  if (!valueA?.value) return isInverted ? -1 : 1;
  if (!valueB?.value) return isInverted ? 1 : -1;

  return getDiffInMiliseconds(parseDateByFormat(valueA.value, 'MM/dd/yy'), parseDateByFormat(valueB.value, 'MM/dd/yy'));
};

export const getIsScheduleInvalid = (startDate?: string | null, endDate?: string | null, dateFormat?: string) => {
  if (!startDate || !endDate) return false;
  return isDateAfter(parseDateByFormat(startDate, dateFormat), parseDateByFormat(endDate, dateFormat));
};

export const getInitialCellData = <T extends SpreadsheetRowFields[keyof SpreadsheetRowFields]>(
  value: T
): SpreadsheetCellData<T> => ({
  value,
  color: null,
});

const aggregateNumbers = ({
  values,
}: IAggFuncParams<SpreadsheetRowData, SpreadsheetCellData<string>>): SpreadsheetCellData<number | null> & {
  toString: () => string;
} => {
  const numberValues = values.flatMap(({ value }) => {
    const numberValue = parseFloat(value);
    return isNaN(numberValue) ? [] : [numberValue];
  });

  const value = numberValues.length > 0 ? sum(numberValues) : null;

  return {
    ...getInitialCellData(value),
    toString: () => (value ? value.toString() : ''),
  };
};

export const getIsTargetAgeInvalid = (targetAge?: AudienceAge | null): boolean => {
  if (!targetAge) return false;
  if (targetAge.lowerAge == null && targetAge.higherAge == null) return false;
  if (!targetAge.lowerAge || !targetAge.higherAge) return true;
  if (targetAge.higherAge < targetAge.lowerAge) return true;
  if (targetAge.lowerAge < MIN_AGE || targetAge.higherAge > MAX_AGE) return true;
  if (targetAge.lowerAge === targetAge.higherAge) return true;

  return false;
};

export const getInitialConfig = ({
  dateFormat,
  performanceObjectives,
  currencyCode,
  goals,
  taxonomy,
  genders,
  countries,
  statuses,
  projectId,
}: InitialConfigParams): ColDef<SpreadsheetRowData>[] => {
  return [
    {
      field: MediaPlanColumnId.CHECKBOX,
      editable: isCellEditable,
      width: CHECKBOX_COLUMN_WIDTH,
      minWidth: CHECKBOX_COLUMN_WIDTH,
      sortable: false,
      resizable: false,
      headerName: '',
      headerClass: ['not-resizable-header-cell', 'action-header-cell'],
      cellClass: 'action-cell',
      suppressFillHandle: true,
      suppressNavigable: true,
      suppressPaste: true,
      suppressSizeToFit: true,
      suppressAutoSize: true,
      cellRendererSelector: suppressFooterRendererSelector,
      checkboxSelection: params => !params.node.footer,
      showDisabledCheckboxes: true,
      headerCheckboxSelection: true,
      hide: true,
      pinned: true,
      equals: () => true,
    },
    {
      field: MediaPlanColumnId.ACTIONS,
      editable: isCellEditable,
      width: ACTION_COLUMN_WIDTH,
      minWidth: ACTION_COLUMN_WIDTH,
      sortable: false,
      resizable: false,
      headerName: '',
      headerClass: ['not-resizable-header-cell', 'action-header-cell'],
      cellClass: 'action-cell',
      cellRendererParams: { dateFormat },
      cellRenderer: ActionButton,
      suppressFillHandle: true,
      suppressNavigable: true,
      suppressPaste: true,
      suppressSizeToFit: true,
      suppressAutoSize: true,
      pinned: true,
      valueGetter: calculateRowIndex,
    },
    {
      field: MediaPlanColumnId.PHASE_ID,
      editable: isCellEditable,
      rowGroup: true,
      hide: true,
      pinned: false,
      checkboxSelection: true,
      keyCreator: ({ value }: RowValueFormatterParams<'phaseId'>) => value.value.toString(),
    },
    {
      field: MediaPlanColumnId.STATUS,
      editable: isCellEditable,
      headerName: 'Status',
      cellRenderer: StatusRenderer,
      cellRendererParams: { statuses },
      cellEditor: StatusEditor,
      initialWidth: 185,
      aggFunc: () => 'Total',
      suppressPaste: ({ context }) => context.mediaPlanMode === MediaPlanMode.APPROVAL, // BUG: context: any in AgGridCommon
      cellRendererSelector: params =>
        params.node.footer ? { component: TotalRenderer } : { component: StatusRenderer },
    },
    {
      field: MediaPlanColumnId.NAME,
      editable: isCellEditable,
      headerName: 'Campaign Description',
      initialWidth: 160,
      cellRenderer: CampaignNameRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.PLATFORMS,
      editable: isCellEditable,
      initialWidth: 160,
      cellRenderer: PlatformsRenderer,
      cellEditor: PlatformsEditor,
      valueFormatter: formatSelectCell,
      cellEditorParams: { taxonomy },
      sortable: true,
      comparator: multiSelectComparator,
      cellRendererSelector: suppressFooterRendererSelector,
      equals: (
        { color: colorA, value: valueA }: SpreadsheetCellData<SpreadsheetRowFields['platforms']> = {
          color: null,
          value: null,
        },
        { color: colorB, value: valueB }: SpreadsheetCellData<SpreadsheetRowFields['platforms']> = {
          color: null,
          value: null,
        }
      ) => {
        const isPlatformsEqual = isEqual(valueA, valueB);

        if (!isPlatformsEqual && valueB && valueB.length !== 0) {
          trackEvent(AnalyticsEvents.PLATFORM_SELECTED, {
            projectId,
            platforms: valueB.map(platform => platform.name),
          });
        }

        return isPlatformsEqual && colorA === colorB;
      },
    },
    {
      field: MediaPlanColumnId.PLACEMENTS,
      editable: isCellEditable,
      initialWidth: 160,
      cellRenderer: PlacementsRenderer,
      cellEditor: PlacementsEditor,
      cellEditorParams: { taxonomy },
      cellClassRules: {
        ['cell-disabled']: (params: CellClassRuleParams<'placements'>) =>
          !params.node.footer && !canEditPlacements(params.data?.platforms.value, taxonomy),
      },
      valueFormatter: formatSelectCell,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.OBJECTIVE,
      editable: isCellEditable,
      initialWidth: 160,
      cellRenderer: ObjectiveRenderer,
      cellEditor: ObjectiveEditor,
      cellEditorParams: { goals },
      valueFormatter: formatSelectCell,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.KPI,
      editable: isCellEditable,
      initialWidth: 160,
      headerName: 'KPI',
      cellRenderer: KpiRenderer,
      cellRendererParams: { performanceObjectives },
      cellEditor: KpiEditor,
      valueFormatter: (params: RowValueFormatterParams<'kpi'>) => params.value?.value?.value ?? '',
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.START_DATE,
      editable: isCellEditable,
      headerName: 'Start Date',
      initialWidth: 110,
      cellRenderer: StartDateRenderer,
      cellRendererParams: { dateFormat },
      cellEditor: StartDateEditor,
      valueFormatter: params => params.value?.value,
      cellClassRules: {
        ['cell-error']: (params: CellClassRuleParams<'startDate'>) =>
          getIsScheduleInvalid(params.data?.startDate.value, params.data?.endDate.value, dateFormat),
      },
      sortable: true,
      comparator: dateComparator,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.END_DATE,
      editable: isCellEditable,
      headerName: 'End Date',
      initialWidth: 110,
      cellRenderer: EndDateRenderer,
      cellRendererParams: { dateFormat },
      cellEditor: EndDateEditor,
      valueFormatter: params => params.value?.value,
      cellClassRules: {
        ['cell-error']: (params: CellClassRuleParams<'endDate'>) =>
          getIsScheduleInvalid(params.data?.startDate.value, params.data?.endDate.value, dateFormat),
      },
      sortable: true,
      comparator: dateComparator,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.BUDGET,
      editable: isCellEditable,
      headerName: `Budget (${currencyCode})`,
      initialWidth: 87,
      sortable: true,
      headerClass: 'ag-header-cell-label-align-right',
      cellRenderer: CurrencyRenderer,
      cellRendererParams: { currencyCode },
      cellEditor: BudgetInputEditor,
      cellEditorParams: { dataSelector: 'phase-budget-campaign-spreadsheet', currencyCode },
      comparator: numberComparator,
      aggFunc: aggregateNumbers,
      valueFormatter: ({ value }: RowValueFormatterParams<'budget'>) => (value?.value ? value.value.toString() : ' '),
      cellRendererSelector: params => {
        return params.node.footer
          ? {
              component: CurrencyRenderer,
              params: {
                currencyCode,
                isDisabled: true,
              },
            }
          : {
              component: params.colDef?.cellRenderer,
            };
      },
      cellClass: ['currencyFormat'],
      cellClassRules: {
        ['cell-footer']: (params: CellClassRuleParams<'budget'>) => params.node.footer,
      },
    },
    {
      field: MediaPlanColumnId.ACTUAL_SPENT,
      editable: isCellEditable,
      headerName: `Actual Spent (${currencyCode})`,
      initialWidth: 87,
      sortable: false,
      headerClass: 'ag-header-cell-label-align-right',
      cellRenderer: CurrencyRenderer,
      cellRendererParams: { currencyCode },
      cellEditor: BudgetInputEditor,
      cellEditorParams: { dataSelector: 'phase-actual-spent-campaign-spreadsheet', currencyCode },
      comparator: numberComparator,
      aggFunc: aggregateNumbers,
      valueFormatter: ({ value }: RowValueFormatterParams<'actualSpent'>) =>
        value?.value ? value.value.toString() : ' ',
      cellRendererSelector: params => {
        return params.node.footer
          ? {
              component: CurrencyRenderer,
              params: {
                currencyCode,
                isDisabled: true,
              },
            }
          : {
              component: params.colDef?.cellRenderer,
            };
      },
      cellClass: ['currencyFormat'],
      cellClassRules: {
        ['cell-footer']: (params: CellClassRuleParams<'actualSpent'>) => params.node.footer,
      },
    },
    {
      field: MediaPlanColumnId.AGE,
      editable: isCellEditable,
      initialWidth: 141,
      cellRenderer: AgeRenderer,
      cellEditor: AgeEditor,
      valueFormatter: ({ value }: RowValueFormatterParams<'age'>) =>
        value?.value ? `${value.value.lowerAge || 'YY'}-${value.value.higherAge || 'YY'}` : '',
      cellClassRules: {
        ['cell-error']: ({ value }: CellClassRuleParams<'age'>) => getIsTargetAgeInvalid(value?.value),
      },
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.AUDIENCE_GENDERS,
      editable: isCellEditable,
      headerName: 'Gender',
      initialWidth: 141,
      cellRenderer: GendersRenderer,
      cellEditor: GendersEditor,
      valueFormatter: formatSelectCell,
      cellEditorParams: { genders },
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.DETAILS,
      editable: isCellEditable,
      headerName: 'Audience Details',
      initialWidth: 141,
      cellRenderer: DetailsRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.ECPM,
      editable: isCellEditable,
      headerName: `eCPM (${currencyCode})`,
      headerClass: 'ag-header-cell-label-align-right',
      initialWidth: 87,
      cellRenderer: CurrencyRenderer,
      cellRendererParams: {
        currencyCode,
        currencyFormat: CURRENCY_FORMATS.fixedDecimal,
      },
      valueFormatter: ({ value }: RowValueFormatterParams<'eCpm'>) => (value?.value ? value.value.toString() : ' '),
      cellEditor: BudgetInputEditor,
      cellEditorParams: {
        dataSelector: 'ecpm-budget-campaign-spreadsheet',
        currencyCode,
      },
      sortable: true,
      aggFunc: () => getInitialCellData(null),
      comparator: numberComparator,
      cellRendererSelector: suppressFooterRendererSelector,
      cellClass: ['currencyFormat'],
      cellClassRules: {
        ['cell-footer']: (params: CellClassRuleParams<'eCpm'>) => params.node.footer,
      },
    },
    {
      field: MediaPlanColumnId.EST_IMPRESSIONS,
      editable: isCellEditable,
      headerName: 'Est. Impressions',
      initialWidth: 142,
      valueGetter: estimatedImpressionsGetter,
      cellClass: 'cell-align-right',
      headerClass: 'ag-header-cell-label-align-right',
      sortable: true,
      aggFunc: aggregateNumbers,
      cellRenderer: SimpleNumberRenderer,
      comparator: numberComparator,
      cellClassRules: {
        ['cell-footer']: (params: CellClassRuleParams<'estImpressions'>) => params.node.footer,
      },
    },
    {
      field: MediaPlanColumnId.CREATIVE_DESCRIPTION,
      editable: isCellEditable,
      initialWidth: 151,
      cellRenderer: CreativeDescriptionRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.COUNTRIES,
      editable: isCellEditable,
      headerName: 'Territory',
      initialWidth: 141,
      cellRenderer: CountriesRenderer,
      cellEditor: CountriesEditor,
      valueFormatter: formatSelectCell,
      cellEditorParams: { countries },
      cellRendererSelector: suppressFooterRendererSelector,
      cellClassRules: {
        ['cell-footer']: (params: CellClassRuleParams<'countries'>) => params.node.footer,
      },
    },
    {
      field: MediaPlanColumnId.AD_COPY,
      editable: isCellEditable,
      initialWidth: 141,
      cellRenderer: AdCopyRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.AD_CREATIVE,
      editable: isCellEditable,
      headerName: 'Ad Creative',
      initialWidth: 160,
      cellEditor: LinksEditor,
      cellRenderer: LinksRenderer,
      valueFormatter: ({ value }: RowValueFormatterParams<'adCreativeLinks'>) => {
        const formattedValue = Array.isArray(value?.value) ? value?.value?.join(', ') : value?.value;
        return formattedValue ?? '';
      },
      cellRendererSelector: suppressFooterRendererSelector,
      cellEditorSelector: (params: ICellEditorParams<SpreadsheetRowData>) =>
        params.context.accessMode !== MediaPlanAccessMode.EDIT
          ? {
              component: LinksReadonlyEditor,
              popup: true,
            }
          : {
              component: params.colDef.cellEditor,
            },
      cellClass: ['hyperlinks'],
      cellClassRules: {
        ['cell-error']: ({ value }: CellClassRuleParams<'adCreativeLinks'>) => getIsLinksInvalid(value?.value),
      },
    },
    {
      field: MediaPlanColumnId.DESTINATION,
      editable: isCellEditable,
      headerName: 'Destination Link',
      initialWidth: 160,
      cellEditor: LinksEditor,
      cellRenderer: LinksRenderer,
      valueFormatter: ({ value }: RowValueFormatterParams<'destinationLinks'>) => {
        const formattedValue = Array.isArray(value?.value) ? value?.value?.join(', ') : value?.value;
        return formattedValue ?? '';
      },
      cellRendererSelector: suppressFooterRendererSelector,
      cellEditorSelector: (params: ICellEditorParams<SpreadsheetRowData>) =>
        params.context.accessMode !== MediaPlanAccessMode.EDIT
          ? {
              component: LinksReadonlyEditor,
              popup: true,
            }
          : {
              component: params.colDef.cellEditor,
            },
      cellClass: ['hyperlinks'],
      cellClassRules: {
        ['cell-error']: ({ value }: CellClassRuleParams<'destinationLinks'>) => getIsLinksInvalid(value?.value),
      },
    },
    {
      field: MediaPlanColumnId.HEADLINE,
      editable: isCellEditable,
      initialWidth: 141,
      cellRenderer: HeadlineRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.CALL_TO_ACTION,
      editable: isCellEditable,
      initialWidth: 141,
      cellRenderer: CallToActionRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.NOTES,
      editable: isCellEditable,
      initialWidth: 141,
      cellRenderer: NotesRenderer,
      cellEditor: TextEditor,
      cellRendererSelector: suppressFooterRendererSelector,
    },
    {
      field: MediaPlanColumnId.NAMING_CONVENTION,
      editable: isCellEditable,
      headerName: 'Naming Convention',
      initialWidth: 150,
      cellRenderer: NamingConventionRenderer,
      cellEditor: NamingConventionEditor,
      valueFormatter: ({ node, value }: RowValueFormatterParams<'namingConvention'>) =>
        (node?.data?.namingConvention.value ?? '') + (value?.value ?? ''),
      cellRendererSelector: suppressFooterRendererSelector,
    },
  ];
};

interface PrepareSpreadsheetDataParams {
  phases: MediaPlanPhase[];
  isEditableInApproval?: boolean;
  dateFormat?: string;
}

export const prepareSpreadsheetData = ({
  phases,
  isEditableInApproval,
  dateFormat,
}: PrepareSpreadsheetDataParams): SpreadsheetRowData[] => {
  const rows: SpreadsheetRowData[] = [];

  phases.forEach(phase => {
    const campaigns: SpreadsheetRowData[] = phase.campaigns.map(({ columns, style }) => {
      const fields: SpreadsheetRowData = {
        phaseId: getInitialCellData(phase.id),
        phaseName: getInitialCellData(phase.name),
        phaseOrder: getInitialCellData(phase.order),
        id: getInitialCellData(columns.id),
        uuid: getInitialCellData(columns.uuid),
        name: { value: columns.name, color: style?.name?.background ?? null },
        status: {
          value: columns.workflowStatus ? columns.workflowStatus.status : undefined,
          color: style?.workflowStatus?.background ?? null,
        },
        platforms: { value: columns.platforms, color: style?.platforms?.background ?? null },
        kpi: {
          value: columns.kpiMetricsField
            ? {
                id: columns.kpiMetricsField.id,
                value: columns.kpiMetricsField.name,
              }
            : undefined,
          color: style?.kpiMetricsFieldId?.background ?? null,
        },
        objective: { value: columns.objective, color: style?.objective?.background ?? null },
        adCopy: { value: columns.adCreativeNotes, color: style?.adCreativeNotes?.background ?? null },
        notes: { value: columns.notes, color: style?.notes?.background ?? null },
        genders: { value: columns.genders, color: style?.genders?.background ?? null },
        countries: { value: columns.territories, color: style?.territories?.background ?? null },
        startDate: {
          value: getFormattedDate(dateFormat)(columns.startDate),
          color: style?.startDate?.background ?? null,
        },
        endDate: { value: getFormattedDate(dateFormat)(columns.endDate), color: style?.endDate?.background ?? null },
        age: { value: columns.audienceAge, color: style?.audienceLowerAge?.background ?? null },
        adCreativeLinks: { value: columns.adCreativeLinks, color: style?.adCreativeLinks?.background ?? null },
        audienceDetails: { value: columns.audienceNotes, color: style?.audienceNotes?.background ?? null },
        eCpm: { value: columns.ecpm, color: style?.ecpm?.background ?? null },
        budget: { value: columns.plannedBudget, color: style?.plannedBudget?.background ?? null },
        placements: { value: columns.placements, color: style?.placements?.background ?? null },
        orderInPhase: getInitialCellData(columns.orderInPhase),
        orderOnSort: getInitialCellData(undefined),
        destinationLinks: { value: columns.destinationLinks, color: style?.destinationLinks?.background ?? null },
        creativeDescription: {
          value: columns.creativeDescription,
          color: style?.creativeDescription?.background ?? null,
        },
        headline: { value: columns.headline, color: style?.headline?.background ?? null },
        callToAction: { value: columns.callToAction, color: style?.callToAction?.background ?? null },
        actualSpent: { value: columns.actualSpent, color: style?.actualSpent?.background ?? null },
        namingConvention: getInitialCellData(columns.namingConvention),
        namingConventionManual: {
          value: columns.namingConventionManual,
          color: style?.namingConventionManual?.background ?? null,
        },
        editableInApproval: getInitialCellData(isEditableInApproval),
        action: getInitialCellData(undefined),
        estImpressions: { value: undefined, color: style?.estImpressions?.background ?? null },
      };

      return fields;
    });

    rows.push(...campaigns);
  });

  return rows;
};

export const isMediaPlanColumnId = isEnumValue(MediaPlanColumnId);
