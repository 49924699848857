import { css, FlattenSimpleInterpolation } from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { CampaignStatuses } from 'backend-api/models';

export const getOptionIndicatorStyle = (status?: CampaignStatuses): FlattenSimpleInterpolation => {
  switch (status) {
    case CampaignStatuses.APPROVED:
      return css`
        background-color: ${manhattanThemeColors.semanticSuccessFill3};
      `;

    case CampaignStatuses.DISAPPROVED:
      return css`
        background-color: ${manhattanThemeColors.semanticErrorFill3};
      `;
    default:
      return css``;
  }
};
