import styled from 'styled-components';

export const BEM_CLASS = 'campaign-item';

export const SCampaignList = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);

  .${BEM_CLASS} {
    &__campaign {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
`;
