import React from 'react';
import { bem } from 'utils/bem';
import { SLoader, BEM_CLASS, CLIP_PATH_ID } from './s-loader';

const classes = bem(BEM_CLASS);

export const Loader = React.memo(() => {
  return (
    <SLoader clipPathId={CLIP_PATH_ID}>
      <div className={classes('container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <path
              d={`M0 0 40 0 40 40 0 40 0 0z
                  M60 10 260 10 260 30 60 30 60 10z`}
            />
          </clipPath>
        </defs>
      </svg>
    </SLoader>
  );
});
