import React from 'react';
import { bem } from 'utils/bem';
import { CardContent, CardImage, CardProps, Skeleton } from 'common-v2/components';
import { SProjectCardLoader, BEM_CLASS } from './s-project-card-loader';

const classes = bem(BEM_CLASS);

export const ProjectCardLoader = React.memo(({ ...cardProps }: CardProps) => (
  <SProjectCardLoader {...cardProps}>
    <CardImage />
    <CardContent>
      <Skeleton className={classes('title-loader')} width={200} height={20}>
        <rect x="0" y="0" rx="2" ry="2" width="200" height="20" />
      </Skeleton>

      <Skeleton className={classes('subtitle-loader')} width={150} height={20}>
        <rect x="0" y="0" rx="2" ry="2" width="150" height="20" />
      </Skeleton>

      <Skeleton className={classes('label-loader')} width={50} height={20}>
        <rect x="0" y="0" rx="5" ry="5" width="50" height="20" />
      </Skeleton>
    </CardContent>
  </SProjectCardLoader>
));
