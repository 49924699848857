import React, { useCallback } from 'react';
import { navigateTo } from 'utils/navigation';
import { Redirect, Route, RouteComponentProps, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { IS_OLD_AUTH } from 'common/constants';
import { useFeatureFlagsContext } from 'common-v2/hooks';
import { ProjectDetailsContainer, ProjectFormContainer } from 'project';
import { ProjectContainer as ProjectV2Container } from 'project-v2';
import { AuthCallbackContainer, AuthContainer, ProtectedRoute as AuthProtectedRoute } from 'auth';
import { LoginContainer, ProtectedRoute as LoginProtectedRoute } from 'login';
import { CampaignDetailsContainer, CampaignFormContainer } from 'campaign';
import { ProjectsContainer } from 'projects';
import { ProjectsContainer as ProjectsV2Container } from 'projects-v2';
import { MomentContainer } from 'artist-moment';
import { ArtistPageContainer } from 'artist';
import { PurchaseOrderContainer } from 'purchase-order';
import { NoMatch } from '../components/no-match';
import { PrsContainer } from 'prs';
import { ProjectNoPermissionContainer } from 'common/containers/project-no-permission-container';
import { CampaignNoPermissionContainer } from 'common/containers/campaign-no-permission-container';
import { SettingsHeaderContainer } from 'settings';
import { MediaPlanContainer } from 'media-plan';
import { MediaPlanContainer as MediaPlanV2Container } from 'media-plan-v2';
import { ReportingContainer } from 'reporting-v2';
import { LabelPermissionsContainer } from 'label-permissions';
import { ErrorViewContainer } from 'common/containers/error-view-container';
import { ErrorViewStates } from 'common-v2/components';
import { isTreatmentEnabled } from 'common-v2/transducers';
import { LoginContainer as LoginV2Container } from 'login-v2';
import {
  ArtistPaths,
  ErrorPath,
  LabelPermissionsPaths,
  LoginPaths,
  MediaPlanPaths,
  MomentPaths,
  ProjectPath,
  ProjectRoutingProps,
  ProjectsPaths,
  PurchaseOrdersPaths,
  ReportingPath,
  SettingsPaths,
} from './types';
import { SettingsContainer } from 'settings-v2';

export const ManhattanDesignPath = [
  ReportingPath.reporting,
  ErrorPath.notFound,
  LabelPermissionsPaths.labelPermissions,
  ProjectsPaths.projects,
];

export const Routing = React.memo(() => {
  const location = useLocation();
  const { treatments } = useFeatureFlagsContext();

  const ProtectedRoute = IS_OLD_AUTH ? AuthProtectedRoute : LoginProtectedRoute;

  return (
    <Switch>
      <Redirect exact from="/" to={ProjectsPaths.projects} />
      <ProtectedRoute
        exact
        path="/unassigned-campaigns/:campaignUuid/access-restricted"
        component={CampaignNoPermissionContainer}
      />
      <ProtectedRoute
        exact
        path={ProjectsPaths.projects}
        component={isTreatmentEnabled(treatments, 'PROJECTS_V2') ? ProjectsV2Container : ProjectsContainer}
      />
      <ProtectedRoute exact path={ArtistPaths.projects} component={ArtistPageContainer} />
      <ProtectedRoute
        exact
        path="/unassigned-projects/:id/purchase-orders/:purchaseOrderId"
        component={PurchaseOrderContainer}
      />
      <ProtectedRoute
        exact
        path="/unassigned-projects/:id/campaigns/:campaignUuid"
        component={CampaignDetailsContainer}
      />
      <ProtectedRoute exact path={PurchaseOrdersPaths.unassignedPurchaseOrders} component={PrsContainer} />
      <ProtectedRoute
        exact
        path={SettingsPaths.settings}
        component={isTreatmentEnabled(treatments, 'SETTINGS_V2') ? SettingsContainer : SettingsHeaderContainer}
      />

      <Redirect
        exact
        from="/unassigned-campaigns/:campaignUuid"
        to={{ ...location, pathname: '/unassigned-campaigns/:campaignUuid/details' }}
      />
      <ProtectedRoute exact path="/unassigned-campaigns/:campaignUuid/details" component={CampaignDetailsContainer} />
      <ProtectedRoute exact path={LabelPermissionsPaths.labelPermissions} component={LabelPermissionsContainer} />
      <ProtectedRoute exact path={ErrorPath.notFound}>
        <ErrorViewContainer
          type={ErrorViewStates.notFound}
          action={() => {
            navigateTo('/');
          }}
        />
      </ProtectedRoute>

      {/* Project routes start */}
      <ProtectedRoute exact path="/projects/:id/access-restricted" component={ProjectNoPermissionContainer} />
      <ProtectedRoute exact path="/projects/:id/edit" component={ProjectFormContainer} />
      <ProtectedRoute exact path="/projects/:id/campaigns/add" component={CampaignFormContainer} />
      <ProtectedRoute exact path="/projects/:id/campaigns/:campaignUuid" component={CampaignDetailsContainer} />
      <Redirect exact from={MomentPaths.redirectMomentDetails} to={MomentPaths.momentDetails} />
      <ProtectedRoute exact path={MomentPaths.addMoment} component={MomentContainer} />
      <ProtectedRoute exact path={MomentPaths.momentDetails} component={MomentContainer} />
      <ProtectedRoute exact path={MomentPaths.editMoment} component={MomentContainer} />
      {!isTreatmentEnabled(treatments, 'PROJECT_V2') && isTreatmentEnabled(treatments, 'REPORTING_V2') && (
        <ProtectedRoute exact path={ReportingPath.reporting} component={ReportingContainer} />
      )}
      {!isTreatmentEnabled(treatments, 'PROJECT_V2') && (
        <Redirect exact from={MediaPlanPaths.deprecatedRoute} to={MediaPlanPaths.generalMediaPlan} />
      )}
      {!isTreatmentEnabled(treatments, 'PROJECT_V2') && (
        <ProtectedRoute exact path={MediaPlanPaths.generalMediaPlan} component={MediaPlanContainer} />
      )}
      {!isTreatmentEnabled(treatments, 'PROJECT_V2') && (
        <ProtectedRoute exact path={MediaPlanPaths.specificMediaPlan} component={MediaPlanContainer} />
      )}
      {!isTreatmentEnabled(treatments, 'PROJECT_V2') && (
        <ProtectedRoute exact path={PurchaseOrdersPaths.purchaseOrders} component={PrsContainer} />
      )}
      {!isTreatmentEnabled(treatments, 'PROJECT_V2') && (
        <ProtectedRoute
          exact
          path="/projects/:id/purchase-orders/:purchaseOrderId"
          component={PurchaseOrderContainer}
        />
      )}
      <Redirect exact from="/projects/:id/details" to={{ ...location, pathname: ProjectsPaths.project }} />
      <ProtectedRoute
        exact={!isTreatmentEnabled(treatments, 'PROJECT_V2')}
        path={ProjectsPaths.project}
        component={isTreatmentEnabled(treatments, 'PROJECT_V2') ? ProjectV2Container : ProjectDetailsContainer}
      />
      {/* Project routes end */}

      <Route
        path={LoginPaths.login}
        component={
          isTreatmentEnabled(treatments, 'LOGIN_V2') ? LoginV2Container : IS_OLD_AUTH ? AuthContainer : LoginContainer
        }
      />
      {IS_OLD_AUTH && <Route path="/auth/:mode?" component={AuthCallbackContainer} />}
      <Route exact path="/v1/not-found" component={NoMatch} />

      <Redirect from="*" to="/v1/not-found" />
    </Switch>
  );
});

export const ProjectRouting = React.memo(({ projectId, projectLoading, projectDetails }: ProjectRoutingProps) => {
  const { path } = useRouteMatch();

  const MediaPlan = useCallback(
    (props: RouteComponentProps<{ mediaPlanId: string }>) => (
      <MediaPlanV2Container
        {...props}
        projectId={projectId}
        projectLoading={projectLoading}
        projectDetails={projectDetails}
      />
    ),
    [projectDetails, projectId, projectLoading]
  );

  return (
    <Switch>
      <Route exact path={path} />
      <Route exact path={path + ProjectPath.mediaPlans} component={MediaPlan} />
      <Route exact path={path + ProjectPath.mediaPlan} component={MediaPlan} />

      <Redirect from="*" to="/v1/not-found" />
    </Switch>
  );
});
