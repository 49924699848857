import React, { useMemo } from 'react';
import { Tabs, THEME } from 'gdb-web-shared-components';
import { LabelPermissionsTab } from 'label-permissions/types';
import { getPermissionTabs } from './transducers';
import { LabelPermissionsCounters } from 'backend-api/models';

interface Props {
  className?: string;
  activeTab: LabelPermissionsTab;
  onChange(val: LabelPermissionsTab): void;
  counters?: LabelPermissionsCounters;
}

export const PermissionTabs = React.memo(({ className, activeTab, onChange, counters }: Props) => {
  const tabsData = useMemo(() => getPermissionTabs(counters), [counters]);

  return (
    <Tabs<LabelPermissionsTab>
      tabsData={tabsData}
      value={activeTab}
      theme={THEME.light}
      onChange={onChange}
      className={className}
    />
  );
});
