import React from 'react';
import { BEM_CLASS, SDateFormatSelector } from './s-date-format-selector';
import { bem } from 'utils/bem';
import { PrimaryLabel } from 'common/components/typography';
import { Select } from 'common/components/form/select';
import { DATE_FORMAT_OPTIONS } from 'settings/constants';

interface Props {
  className?: string;
  value: string;
  onChange(value: string): void;
}

const classes = bem(BEM_CLASS);

export const DateFormatSelector = React.memo(({ className, value, onChange }: Props) => {
  return (
    <SDateFormatSelector className={className}>
      <PrimaryLabel className={classes('title')} data-selector="date-format-selector-title">
        Date and time format
      </PrimaryLabel>
      <Select value={value} onChange={onChange} options={DATE_FORMAT_OPTIONS} bgStyle="grey" />
    </SDateFormatSelector>
  );
});
