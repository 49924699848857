import styled from 'styled-components';
import { MAIN_FOOTER_HEIGHT, MAIN_FOOTER_PADDING_TOP } from 'common/constants';
import { palette } from 'frontend-shared-theme';

interface Props {
  readonly background?: string;
}

export const SFooter = styled.div<Props>`
  padding-top: ${MAIN_FOOTER_PADDING_TOP}px;
  background: ${({ background }) => background};

  .footer {
    &__container {
      height: ${MAIN_FOOTER_HEIGHT}px;
      width: 100%;
      border-top: 1px solid #cacbd9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${palette.darkBlue};
      font-size: 10px;
    }
  }
`;
