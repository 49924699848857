import React, { useCallback, useEffect, useImperativeHandle, useMemo, useReducer, useState } from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { ExpandableText } from '../expandable-text';
import { useEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan/components/spreadsheet/hooks';
import { getTextFieldPlaceholder } from '../../transducers';
import { SpreadsheetRowData } from '../../types';

export const TextEditor = React.memo(
  React.forwardRef(
    (
      {
        api,
        rowIndex,
        colDef,
        value: tableValue,
        charPress,
      }: ICellEditorParams<
        SpreadsheetRowData,
        | SpreadsheetRowData['audienceDetails']
        | SpreadsheetRowData['creativeDescription']
        | SpreadsheetRowData['adCopy']
        | SpreadsheetRowData['headline']
        | SpreadsheetRowData['callToAction']
        | SpreadsheetRowData['notes']
      >,
      ref
    ) => {
      const initialValue = charPress ? charPress : tableValue?.value;
      const [value, setValue] = useState(initialValue);
      const [isVisible, setVisible] = useReducer(() => true, false);
      const { onKeyDown } = useEditorKeyboardInteractions({ api, value });
      const { onBlur } = useStopEditingBlur(api);

      const handleChange = useCallback((value: string) => {
        setValue(value);
      }, []);

      const handleStopEditing = useCallback(() => {
        api.stopEditing();
        api.setFocusedCell(rowIndex, colDef.field || '');
      }, [api, colDef.field, rowIndex]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return { ...tableValue, value };
        },
      }));

      useEffect(() => {
        requestAnimationFrame(() => {
          setVisible();
        });
      }, []);

      const placeholder = useMemo(() => getTextFieldPlaceholder(colDef.field), [colDef]);

      return isVisible ? (
        <ExpandableText
          value={value ?? ''}
          placeholder={placeholder}
          onTextareaChange={handleChange}
          stopEditing={handleStopEditing}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
      ) : null;
    }
  )
);
