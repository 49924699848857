import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ColumnApi } from 'ag-grid-community';
import { MediaPlanColumnId } from 'media-plan-v2/containers/spreadsheet/types';
import { mediaPlanModeSelector } from 'media-plan-v2/selectors';
import { MediaPlanMode } from 'media-plan-v2/types';

export const useColumnsVisibility = (columnApi?: ColumnApi) => {
  const mediaPlanMode = useSelector(mediaPlanModeSelector);

  const isActionsColumnVisible = mediaPlanMode === MediaPlanMode.DEFAULT;
  const isCheckboxColumnVisible = mediaPlanMode === MediaPlanMode.REQUEST_APPROVAL;

  const updateColumnsVisibility = useCallback(
    (columnApi: ColumnApi) => {
      columnApi.applyColumnState({
        state: [
          { colId: MediaPlanColumnId.ACTIONS, hide: !isActionsColumnVisible },
          { colId: MediaPlanColumnId.CHECKBOX, hide: !isCheckboxColumnVisible },
        ],
      });
    },
    [isActionsColumnVisible, isCheckboxColumnVisible]
  );

  useEffect(() => {
    if (columnApi) {
      updateColumnsVisibility(columnApi);
    }
  }, [columnApi, updateColumnsVisibility]);

  return { isActionsColumnVisible, isCheckboxColumnVisible };
};
