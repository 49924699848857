import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, CLIP_PATH_ID, SSummaryMetricLoader } from './s-summary-metric-loader';

interface Props {
  className?: string;
}

const classes = bem(BEM_CLASS);

export const SummaryMetricLoader = React.memo(({ className }: Props) => {
  return (
    <SSummaryMetricLoader className={className}>
      <div className={classes('clip-container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <path d={`m 0 0 h 40 v 12 h -40 v -12`} />
          </clipPath>
        </defs>
      </svg>
    </SSummaryMetricLoader>
  );
});
