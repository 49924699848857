import { getCurrentNewYorkDayMonthYear } from 'utils/date';
import { getDiffInDays } from 'common-v2/utils';

export const calculateDateRangePercentProgress = (startDate: Date, endDate: Date) => {
  // to have only dates without hours and minutes
  const newYorkCurrentDate = getCurrentNewYorkDayMonthYear();

  const todayStartDateDiff = getDiffInDays(startDate, newYorkCurrentDate);
  let percentProgress: number;

  // only if today is before startDate - if start date is in future
  if (todayStartDateDiff > 0) {
    percentProgress = 0;
  } else {
    const startEndDiff = getDiffInDays(endDate, startDate) + 1;
    const todayEndDiff = getDiffInDays(endDate, newYorkCurrentDate);

    const percentsPerDay = 100 / startEndDiff;
    // if end day before today it could be more than 100
    percentProgress = Math.min(100 - percentsPerDay * todayEndDiff, 100);
  }

  return percentProgress;
};
