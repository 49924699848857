import { ArtistSearch } from 'backend-api/models';

export const suggestedArtists = (
  searchResults: ArtistSearch[],
  recentlyAddedArtists: ArtistSearch[],
  projectArtists: ArtistSearch[]
) =>
  searchResults.filter(({ id }) => {
    const isNotAddedToRecent = recentlyAddedArtists.find(artist => artist.id === id) === undefined;
    const isNotAddedToProject = projectArtists.find(artist => artist.id === id) === undefined;
    return isNotAddedToRecent && isNotAddedToProject;
  });
