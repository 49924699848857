import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { colorPalette } from 'app/theme/colors';
import { DEFAULT_HEIGHT, DEFAULT_WIDTH } from './constants';
import { SSkeleton } from './s-skeleton';

interface SkeletonProps extends IContentLoaderProps {
  className?: string;
  rotation?: number;
  backgroundColor?: string;
  foregroundColor?: string;
}

export const Skeleton = React.memo(
  ({
    className,
    children = <rect x="0" y="0" rx="0" ry="0" width={DEFAULT_WIDTH} height={DEFAULT_HEIGHT} />,
    width = DEFAULT_WIDTH,
    height = DEFAULT_HEIGHT,
    backgroundColor = colorPalette.snuff,
    foregroundColor = colorPalette.alabaster,
    rotation = 0,
    ...props
  }: SkeletonProps) => (
    <SSkeleton className={className} rotation={rotation}>
      <ContentLoader
        title=""
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        backgroundColor={backgroundColor}
        foregroundColor={foregroundColor}
        gradientRatio={0.75}
        speed={2.2}
        interval={0.3}
        {...props}
      >
        {children}
      </ContentLoader>
    </SSkeleton>
  )
);
