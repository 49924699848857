import React from 'react';
import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { getCurrencyFormatted } from 'utils/format/currency';
import { LongTableTitle } from 'common-v2/components/table';
import { Icon } from 'common/components/icon';
import { IdNameField } from 'backend-api/models';
import { UUID } from 'io-ts-types/lib/UUID';
import { getFormattedDate } from 'common-v2/utils';

interface InitialConfigParams {
  dateFormat: string;
  currencyCode: string;
  isEmpty: boolean;
  hasEditPermission: boolean;
  onDelete(uuid: UUID): void;
  classNames: {
    actionHeader: string;
    actionCell: string;
  };
}

export const getInitialConfig = ({
  dateFormat,
  currencyCode,
  isEmpty,
  hasEditPermission,
  onDelete,
  classNames,
}: InitialConfigParams): ColDef[] => [
  {
    field: 'name',
    headerName: 'Name',
    initialWidth: 314,
    sortable: true,
    suppressMenu: true,
    unSortIcon: true,
    flex: 2,
    cellRenderer: ({ value }) => <LongTableTitle text={value || ''} />,
  },
  {
    field: 'platforms',
    headerName: 'Platforms',
    sortable: true,
    suppressMenu: true,
    unSortIcon: true,
    flex: 2,
    comparator: platformsComparator,
    valueFormatter: ({ value }) =>
      value
        .map(platform => platform.name)
        .sort((a, b) => (a || '').localeCompare(b || ''))
        .join(', '),
  },
  {
    field: 'budgetSpend',
    headerName: `Spend (${currencyCode})`,
    sortable: true,
    suppressMenu: true,
    unSortIcon: true,
    flex: 2,
    valueFormatter: ({ value }) => getCurrencyFormatted(value, currencyCode),
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    sortable: true,
    suppressMenu: true,
    unSortIcon: true,
    flex: 1,
    valueFormatter: ({ value }) => (value ? getFormattedDate(dateFormat)(value) : ''),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    sortable: true,
    suppressMenu: true,
    unSortIcon: true,
    flex: 1,
    valueFormatter: ({ value }) => (value ? getFormattedDate(dateFormat)(value) : ''),
  },
  {
    hide: !hasEditPermission || isEmpty,
    field: 'action',
    headerName: '',
    headerClass: classNames.actionHeader,
    sortable: false,
    suppressMenu: true,
    initialWidth: 40,
    cellClass: [classNames.actionCell],
    cellRenderer: ({ data }) => (
      <Icon
        name="delete-inner-outline"
        size="general"
        color="inherit"
        onClick={() => {
          onDelete(data.uuid);
        }}
      />
    ),
  },
];

const platformsComparator = (valueA: IdNameField[], valueB: IdNameField[]) => {
  const stringA =
    valueA
      ?.map(item => item.name)
      .sort((a, b) => (a || '').localeCompare(b || ''))
      .join(' ') || '';
  const stringB =
    valueB
      ?.map(item => item.name)
      .sort((a, b) => (a || '').localeCompare(b || ''))
      .join(' ') || '';

  return stringA?.localeCompare(stringB);
};
