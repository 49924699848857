import styled, { css } from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { DatePickerMode } from 'common/components/form/date-picker/types';
// eslint-disable-next-line import/no-unresolved
import { DATE_POPUP_RANGE_WIDTH, DATE_POPUP_SINGLE_WIDTH } from 'common/components/form/date-picker';

export const SDatePickerStyles = css`
  .DayPicker {
    font-size: inherit;
    outline: none;
    * {
      &:active,
      &:focus {
        outline: none;
      }
    }
    &-wrapper {
      height: 245px;
    }
    &-NavBar {
      display: none;
    }
    &-Month {
      width: 210px;
      margin: 0;
    }
    &-Caption {
      width: 100%;
      margin: 0;
      padding: 10px 0 4px 0;
      text-align: center;

      > div {
        display: inline-block;
      }
    }
    &-Weekday {
      width: 30px;
      text-transform: uppercase;
      color: ${palette.darkBlueTwo};
      padding: 0;
      font-size: 10px;
      line-height: 30px;
      font-weight: 400;
    }
    &-Day {
      height: 30px;
      padding: 0;
      font-size: 12px;
      font-weight: 400;
      border-radius: 0;
      &:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        border-radius: 0;
        background-color: ${palette.lightBlue};
        color: ${palette.brightBlue};
      }
      &--today {
        font-weight: 700;
      }
      &--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
        color: ${palette.pink};
      }
      &--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: ${palette.athensGray};
        color: ${palette.black};
      }
      &--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
      &--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: ${palette.black};
        color: ${palette.athensGray};
      }
    }
  }
`;

interface Props {
  mode: DatePickerMode;
}

export const SCalendarPicker = styled.div<Props>`
  width: ${({ mode }) => (mode === DatePickerMode.SINGLE ? DATE_POPUP_SINGLE_WIDTH : DATE_POPUP_RANGE_WIDTH)}px;
  height: 100%;
  padding: 10px ${({ mode }) => (mode === DatePickerMode.SINGLE ? 10 : 20)}px;
  .calendar-picker {
    &__calendar-container {
      display: flex;
      & > :not(:first-child) {
        margin-left: 20px;
      }
      ${SDatePickerStyles};
    }
  }
`;
