import React from 'react';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { SSelectedMetricItem } from './s-selected-metric-item';

interface Props {
  name: string;
  onRemove(): void;
}

const classes = bem('selected-item');

export const SelectedMetricItem = React.memo(({ name, onRemove }: Props) => {
  return (
    <SSelectedMetricItem data-selector="custom-columns-selected-item">
      <div data-selector="custom-columns-selected-metric-item-name" className={classes('info-container')}>
        <Icon name="hamburger" size="general" />
        <span className={classes('title')}>{name}</span>
      </div>
      <Icon
        dataSelector="custom-columns-remove-selected-metric-item-button"
        onClick={onRemove}
        name="removal"
        className={classes('icon')}
        size="general"
        color="pink"
      />
    </SSelectedMetricItem>
  );
});
