import * as t from 'io-ts';
import { FormattedDateFromISOString, IdNameField, IdType, Optional, ResponseModel, StatusIdSchema } from '../../models';

export const ArtistRosterProjectMetadataSchema = ResponseModel(
  {
    internalCampaignsCount: t.number,
    externalCampaignsCount: t.number,
    pendingCampaignsCount: t.number,
  },
  'ProjectMetadata'
);

export type ArtistRosterProjectMetadata = t.TypeOf<typeof ArtistRosterProjectMetadataSchema>;

export const ArtistRosterPhaseSchema = ResponseModel(
  {
    ...IdNameField,
    order: t.number,
    campaignsCount: t.number,
  },
  'ProjectMetadata'
);

export type ArtistRosterPhase = t.TypeOf<typeof ArtistRosterPhaseSchema>;

export const ArtistRosterProjectSchema = ResponseModel(
  {
    id: IdType,
    allocatedBudget: Optional(t.number),
    totalBudget: Optional(t.number),
    startDate: FormattedDateFromISOString,
    endDate: FormattedDateFromISOString,
    name: t.string,
    status: StatusIdSchema,
    currency: t.string,
    mediaPlans: t.array(ResponseModel(IdNameField)),
  },
  'ArtistRosterProject'
);

export type ArtistRosterProject = t.TypeOf<typeof ArtistRosterProjectSchema>;
