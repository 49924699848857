import React from 'react';
import { CurrentUserInfo } from 'backend-api/models';
import { SUserDropdown } from './s-user-dropdown';
import { Avatar } from 'common-v2/components/user-avatar';
import { MenuDropdownOption } from 'common/components/menu-dropdown';
import { manhattanThemeColors } from 'app/theme/colors';

interface Props {
  userInfo?: CurrentUserInfo;
  className?: string;
  onLogoutClick(): void;
  onSettingsClick(): void;
  onLabelsPermissionsClick(): void;
}

export const UserDropdown = React.memo(
  ({ userInfo, className, onLogoutClick, onSettingsClick, onLabelsPermissionsClick }: Props) => {
    return (
      <SUserDropdown
        trigger={
          <Avatar
            avatarColor={manhattanThemeColors.primaryFillNew}
            name={userInfo?.name || ''}
            size={25}
            className={className}
            dataSelector="user-avatar-dropdown"
          />
        }
      >
        <MenuDropdownOption
          key="settings"
          title="User Settings"
          iconName="settings"
          iconColor="fiordGrey"
          onClick={onSettingsClick}
          dataSelector="user-settings-dropdown"
        />
        <MenuDropdownOption
          key="label-permissions"
          title="Label Permissions"
          iconName="label-permissions"
          iconColor="fiordGrey"
          onClick={onLabelsPermissionsClick}
          dataSelector="label-permissions-dropdown"
        />
        <MenuDropdownOption
          key="sign-out"
          title="Sign Out"
          iconName="sign-out"
          iconColor="fiordGrey"
          onClick={onLogoutClick}
          dataSelector="sign-out-menu-dropdown"
        />
      </SUserDropdown>
    );
  }
);
