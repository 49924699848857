import styled from 'styled-components';

export const BEM_CLASS = 'modal-header';

export const SModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px 0;

  .${BEM_CLASS} {
    &__search {
      margin-top: 12px;
    }
  }
`;
