import React, { useMemo } from 'react';
import { STitle } from '../../s-components/s-title';
import { bem } from 'utils/bem';
import { Badge } from 'common/components/badge';
import { Tooltip } from 'common/components/tooltip';
import { H4 } from 'common/components/typography';
import { getCampaignStatusColor } from 'common/transducers';

interface Props {
  text: string;
  status?: string;
  statusId?: string;
  className?: string;
  isCampaignUnassigned?: boolean;
  tooltipContent?: React.ReactChild | React.ReactChild[];
}

const classes = bem('title');

const Title = React.memo(({ text, status, statusId, className, isCampaignUnassigned, tooltipContent }: Props) => {
  const statusBadgeColor = useMemo(() => getCampaignStatusColor(statusId), [statusId]);

  return (
    <STitle className={className}>
      <H4 className={classes('title-text')} data-selector="unassigned-campaign-title">
        <Tooltip
          content={tooltipContent}
          enabled={!!tooltipContent || (Array.isArray(tooltipContent) && tooltipContent.length !== 0)}
        >
          {text}
        </Tooltip>
      </H4>
      {isCampaignUnassigned ? (
        <Badge
          text="Unassigned"
          color={statusBadgeColor}
          dataSelector="campaign-title-unassigned-badge"
          className={classes('unassigned-badge')}
        />
      ) : (
        status && (
          <Badge
            text={status}
            color={statusBadgeColor}
            dataSelector="campaign-title-unassigned-badge"
            className={classes('unassigned-badge')}
          />
        )
      )}
    </STitle>
  );
});

export default Title;
