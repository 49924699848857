import * as t from 'io-ts';
import qs from 'qs';
import { AxiosRequestConfig } from 'axios';
import { tokenProvider, unauthorize } from 'app/auth';
import { ApiClient } from './core/api-client';
import { TokenService } from './core/token-service';
import { URL_REGEXP } from './constants';
import * as requests from './requests';

export { ServerErrorResponse, ApiError } from './core/api-client';

const defaultConfig: AxiosRequestConfig = {
  baseURL: process.env.API_URL || '/api/',
  timeout: 120000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  paramsSerializer: parameters => qs.stringify(parameters, { arrayFormat: 'repeat', encode: true }),
};

export const tokenService = new TokenService(URL_REGEXP, process.env.ATLAS_AUTH_URL);

export const apiClient = new ApiClient({
  default: defaultConfig,
  tokenProvider: tokenProvider(tokenService),
  unauthorize,
});

export const ResponseArray = t.array;
export const EmptySchema = t.unknown;
export const EmptyPayload = {};

export const Api = requests;
