import styled from 'styled-components';

export const BEM_CLASS = 'global-search-field-container';

export const SGlobalSearchFieldContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .${BEM_CLASS} {
    &__search {
      width: 100%;
    }

    &__search-input {
      font-size: 14px;
    }

    &__old-search {
      width: 295px;
    }
  }
`;
