import React from 'react';
import { bem } from 'utils/bem';
import { IconName } from 'common/components/icon/types';
import { Body } from 'common/components/typography';
import { Icon } from 'common/components/icon';
import { SOption, BEM_CLASS } from './s-option';

interface OptionProps {
  text: string;
  dataSelector?: string;
  icon?: IconName;
  onClick?(): void;
}

const classes = bem(BEM_CLASS);

export const Option = React.memo(({ text, dataSelector, icon, onClick }: OptionProps) => (
  <SOption data-selector={dataSelector} onClick={onClick}>
    <Body className={classes('text')} title={text}>
      {text}
    </Body>
    {icon && <Icon className={classes('icon')} name={icon} color="inherit" size="general" />}
  </SOption>
));
