import styled, { css } from 'styled-components';
import { theme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';
import { dropdown as dropdownZIndex } from 'app/styles/z-indexes';
import { BORDER_WIDTH } from './constants';

interface SExpandedContainerProps {
  top: number;
  left: number;
  hasError: boolean;
}

export const BEM_CLASS = 'expandable-field';

export const SExpandableField = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SExpandedContainer = styled.div<SExpandedContainerProps>`
  position: absolute;
  z-index: ${dropdownZIndex};
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.white};
  box-shadow: ${theme.shadows.default};
  border: ${BORDER_WIDTH}px solid ${colorPalette.azureRadiance};

  ${({ top, left, hasError }) => css`
    top: ${top}px;
    left: ${left}px;

    ${hasError &&
      css`
        border-color: ${colorPalette.cinnabar};
      `}
  `}

  .${BEM_CLASS} {

  }
`;
