import React from 'react';
import { ItemLoader } from './components';
import { SRowLoader } from './s-row-loader';

export const RowLoader = React.memo(() => (
  <SRowLoader>
    <ItemLoader />
    <ItemLoader />
    <ItemLoader />
  </SRowLoader>
));
