import styled from 'styled-components';
import { typography } from 'app/theme/typography';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'ellipsis-item';

export const SEllipsisItem = styled.div`
  ${typography.smallText};
  border-radius: 2px;
  background-color: ${colorPalette.athensGray};
  padding: 0 5px;
  font-weight: bold;
`;
