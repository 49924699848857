import styled from 'styled-components';

export const BEM_CLASS = 'campaign-platform-icon';

interface Props {
  backgroundColor: string;
}

export const SCampaignPlatformIcon = styled.div<Props>`
  display: flex;
  align-self: center;
  justify-content: center;

  .${BEM_CLASS} {
    &__circle {
      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: ${({ backgroundColor }) => backgroundColor || 'black'};
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: bold;
      color: white;
      text-transform: capitalize;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
