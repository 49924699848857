import React from 'react';
import { bem } from 'utils/bem';
import { SSelectedSectionHeader } from './s-selected-section-header';
import { H4 } from 'common/components/typography';

const classes = bem('selected-section-header');

interface Props {
  disabled?: boolean;
  title: string;
  onResetClick?: () => void;
  resetTitle?: string;
}

export const SelectedSectionHeader = React.memo(({ disabled, title, onResetClick, resetTitle }: Props) => {
  return (
    <SSelectedSectionHeader disabled={disabled}>
      <H4 data-selector="number-of-selected-items">{title}</H4>
      {onResetClick && (
        <button
          disabled={disabled}
          onClick={onResetClick}
          className={classes('reset')}
          data-selector="reusable-modal-reset-button"
        >
          {resetTitle}
        </button>
      )}
    </SSelectedSectionHeader>
  );
});
