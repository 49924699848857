import { css } from 'styled-components';

export const animatedFlare = css`
  background: linear-gradient(-45deg, #dcdeec 0%, #dcdeec 40%, #fafafa 50%, #dcdeec 60%, #dcdeec 100%);
  background-size: 800% 800%;
  background-position: 100% 50%;
  animation: pulse 1.6s linear infinite;
  @keyframes pulse {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
