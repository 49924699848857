import React from 'react';
import { Skeleton } from 'common-v2/components';

interface CardLoaderProps {
  className?: string;
}

export const CardLoader = React.memo(({ className, children }: React.PropsWithChildren<CardLoaderProps>) => (
  <Skeleton className={className} width={285} height={65}>
    <rect x="0" y="0" rx="0" ry="0" width="65" height="65" />

    {children ?? (
      <>
        <rect x="85" y="12.5" rx="2px" ry="2px" width="200" height="20" />
        <rect x="85" y="37.5" rx="2px" ry="2px" width="113" height="15" />
      </>
    )}
  </Skeleton>
));
