import React from 'react';
import {
  CampaignCategory,
  CampaignSearchSchema,
  ProjectSearchSchema,
  RecentSearchItemEntity,
} from 'backend-api/models';
import { Badge, BadgeType } from 'common/components/badge';
import { paths } from 'app/routing/paths';
import {
  getCampaignLink,
  getProjectInfo,
  getCampaignBadge,
  getCampaignIcon,
  getCampaignNameOrPlaceholder,
} from 'app/components/search-modal/transducers';
import { MappedRecentSearchEntity } from '../../types';
import { RecentSearchItem } from './components';
import { getPathToProjectPage } from 'common/transducers';

export const mapRecentSearchEntity = (
  entity: RecentSearchItemEntity,
  campaignCategories?: CampaignCategory[]
): MappedRecentSearchEntity => {
  if (ProjectSearchSchema.is(entity)) {
    const badges: React.ReactElement[] = [];

    if (!entity.isClaimed) badges.push(<Badge key="unclaimed" text="Unclaimed" />);
    badges.push(<Badge key="project" text="Project" type={BadgeType.Light} />);

    return {
      link: getPathToProjectPage(entity),
      targets: entity.targets.items,
      component: (
        <RecentSearchItem
          title={entity.name}
          badges={badges}
          info={getProjectInfo(entity.targets.items)}
          isConfidentialProject={entity.isConfidential}
        />
      ),
    };
  }

  if (CampaignSearchSchema.is(entity)) {
    const badges: React.ReactElement[] = [];

    const campaignLink = getCampaignLink(entity.isUnassigned, entity.uuid, entity.project);
    const campaignBadge = getCampaignBadge(entity.isUnassigned, entity.isPending);
    const campaignIcon = getCampaignIcon(entity, campaignCategories);

    if (campaignBadge) badges.push(campaignBadge);
    badges.push(<Badge key="campaign" text="Campaign" type={BadgeType.Light} />);

    return {
      link: campaignLink,
      targets: entity.project?.targets.items,
      icon: campaignIcon,
      component: (
        <RecentSearchItem
          title={getCampaignNameOrPlaceholder(entity.name)}
          badges={badges}
          info={entity.project?.name}
        />
      ),
    };
  }

  return {
    link: paths.artist(entity.id),
    targets: entity,
    component: (
      <RecentSearchItem title={entity.name} badges={[<Badge key="artist" text="Artist" type={BadgeType.Outlined} />]} />
    ),
  };
};
