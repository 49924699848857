import styled from 'styled-components';
import { motion } from 'framer-motion';
import { animatedFlare } from 'common/s-components/s-animated-flare';
import { RowLoaderAlignment } from './types';

interface Props {
  clipPathId: string;
  alignment: RowLoaderAlignment;
  width: number;
}

export const STableRowLoader = styled(motion.tbody)<Props>`
  .table-row-loader {
    &__loader {
      max-width: 100vw;
      height: 100%;
      display: flex;
      flex-direction: ${props => {
        switch (props.alignment) {
          case 'left':
            return 'row';
          case 'right':
            return 'row-reverse';
          default:
            return 'row';
        }
      }};
      padding: 0px 15px 0px 20px;
    }

    &__clip-container {
      width: ${props => props.width}px;
      height: 10px;
      clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      -webkit-clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      ${animatedFlare}
    }
  }
`;
