import React from 'react';
import { bem } from 'utils/bem';
import { transparentize } from 'polished';
import { Requester } from 'backend-api/models';
import { TOOLTIP_PLACEMENT, TYPOGRAPHY_TYPE, Tooltip, Typography } from 'gdb-web-shared-components';
import { pluralizeString } from 'utils/string';
import { Avatar } from 'common-v2/components';
import { manhattanThemeColors } from 'app/theme/colors';
import { AVATAR_SIZE, USERS_TO_SHOW_COUNT } from './constants';
import { SRequesters, BEM_CLASS } from './s-requesters';

interface RequestersProps {
  className?: string;
  users: Requester[];
}

const classes = bem(BEM_CLASS);

export const Requesters = React.memo(({ className, users }: RequestersProps) => {
  const visibleUsers = users.slice(0, USERS_TO_SHOW_COUNT);
  const hiddenUsers = users.slice(USERS_TO_SHOW_COUNT, users.length);

  const isRestSectionShown = hiddenUsers.length > 0;

  return (
    <SRequesters hiddenUsersCount={hiddenUsers.length}>
      {requestersClassName => (
        <div className={`${requestersClassName} ${className ?? ''}`}>
          <div className={classes('container')}>
            <Typography className={classes('label')} hasPadding={false}>
              {pluralizeString('Requester', users.length)}:
            </Typography>

            {visibleUsers.map(({ id, name }) => (
              <Tooltip key={id} tooltip={name} placement={TOOLTIP_PLACEMENT.BottomEnd}>
                <Avatar
                  name={name}
                  size={AVATAR_SIZE}
                  avatarColor={transparentize(0.85, manhattanThemeColors.primaryFillNew)}
                />
              </Tooltip>
            ))}

            {isRestSectionShown && (
              <Tooltip
                placement={TOOLTIP_PLACEMENT.BottomEnd}
                popperClassName={requestersClassName}
                contentClassName={classes('tooltip-content')}
                tooltip={
                  <div className={classes('users')}>
                    {hiddenUsers.map(({ id, name }) => (
                      <Typography key={id} className={classes('user')} type={TYPOGRAPHY_TYPE.body4} hasPadding={false}>
                        {name}
                      </Typography>
                    ))}
                  </div>
                }
              >
                <Typography className={classes('rest')} element="div" hasPadding={false}>
                  +{hiddenUsers.length}
                </Typography>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </SRequesters>
  );
});
