import numeral from 'numeral';
import { Optional } from 'backend-api/models';
import {
  CURRENCY_CODE_TO_SIGN,
  CURRENCY_FORMAT,
  CURRENCY_FORMATS,
  DEFAULT_CURRENCY_CODE,
  DEFAULT_CURRENCY_SIGN,
  SHORTEN_CURRENCY_FORMAT,
} from 'common/constants';

export const getCurrencyFormatted = (
  value: Optional<string | number>,
  currencyCode = DEFAULT_CURRENCY_CODE,
  displayCurrencyCode = false,
  format = CURRENCY_FORMATS.default
): string => {
  const currencySign = CURRENCY_CODE_TO_SIGN[currencyCode] || DEFAULT_CURRENCY_SIGN;

  const formattedNumber = numeral(value)
    .format(format)
    .toUpperCase();

  return displayCurrencyCode ? currencySign + formattedNumber + ' ' + currencyCode : currencySign + formattedNumber;
};

export const getShortCurrencyFormatted = (
  value: Optional<string | number>,
  currencyCode = DEFAULT_CURRENCY_CODE,
  displayCurrencyCode = false
): string => getCurrencyFormatted(value, currencyCode, displayCurrencyCode, CURRENCY_FORMATS.short);

export const formatShortenCurrency = (value: Optional<string | number>): string =>
  numeral(value)
    .format(SHORTEN_CURRENCY_FORMAT)
    .toUpperCase();

export const formatCurrency = (value: Optional<string | number>): string =>
  numeral(value)
    .format(CURRENCY_FORMAT)
    .toUpperCase();

export const getTextWithCurrencyCode = (text: string, currencyCode?: string): string => {
  if (currencyCode) {
    return `${text} (${currencyCode})`;
  }
  return text;
};
