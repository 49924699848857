import { useEffect, useState } from 'react';

export const useVerticalScroll = (element?: Element) => {
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    const onScroll = event => {
      setOffsetTop(event.target.scrollTop);
    };

    element?.addEventListener('scroll', onScroll);
    return () => {
      element?.removeEventListener('scroll', onScroll);
    };
  }, [element]);

  return offsetTop;
};
