import React, { useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useShouldNotesExpand } from 'hooks';
import { bem } from 'utils/bem';
import { appearingAnimation } from 'common/constants';
import { Button } from 'common/components/button';
import MarkdownEditor from 'common/components/markdown-editor';
import { HistoryWidgetLoader } from 'common/components/loaders/history-widget-loader';
import { activeProjectSelector, isLoadingSelector } from 'project/selectors';
import { NotesModal, NotesUpdates } from './components';
import { SNotesWidget } from './s-notes-widget';

interface Props {
  className?: string;
}

const classes = bem('notes-widget');

export const NotesWidget = ({ className }: Props) => {
  const project = useSelector(activeProjectSelector);
  const isLoading = useSelector(isLoadingSelector);
  const editorRef = useRef<HTMLDivElement>(null);
  const [modalShown, setModalShown] = useState(false);
  const shouldNotesExpand = useShouldNotesExpand(editorRef, [isLoading]);

  const onShowAllClick = useCallback(() => setModalShown(true), []);
  const onModalClose = useCallback(() => setModalShown(false), []);

  return (
    <SNotesWidget
      {...appearingAnimation}
      shouldNotesExpand={shouldNotesExpand}
      className={className}
      data-selector="project-notes-section"
    >
      {isLoading ? (
        <HistoryWidgetLoader className={classes('loader')} />
      ) : (
        <>
          <div className={classes('title')}>Notes</div>
          <NotesUpdates />
          <MarkdownEditor
            ref={editorRef}
            className={classes('editor')}
            editable={false}
            content={project?.notes || ''}
            dataSelector="project-notes"
          />
          {shouldNotesExpand && <div className={classes('dots')}>...</div>}
          {shouldNotesExpand && (
            <Button className={classes('button')} variant={'secondary-cta'} onClick={onShowAllClick}>
              Show All
            </Button>
          )}
          <NotesModal onModalClose={onModalClose} isOpened={modalShown} notes={project?.notes} />
        </>
      )}
    </SNotesWidget>
  );
};
