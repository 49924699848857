import { manhattanThemeColors } from 'app/theme/colors';
import { Avatar } from 'common-v2/components';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { SMembersListHeader, BEM_CLASS } from './s-members-list-header';

interface MembersListHeaderProps {
  labelName: string;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const MembersListHeader = React.memo(({ labelName, className }: MembersListHeaderProps) => {
  return (
    <SMembersListHeader className={className}>
      <Avatar avatarColor={manhattanThemeColors.textHeaderColor} name={labelName} className={classes('avatar')} />
      <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('title')}>
        All Users in {labelName}
      </Typography>
    </SMembersListHeader>
  );
});
