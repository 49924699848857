import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { SHeader } from '../../../s-components/ad-sets/s-header';
import { SSpacer } from 'common/s-components/layout/s-spacer';
import { Input } from 'common/components/form/input';
import { Icon } from 'common/components/icon';
import { Button } from 'common/components/button';
import { useDispatch, useSelector } from 'react-redux';
import { adSetsSelector } from '../../../selectors';
import { Id } from 'backend-api/models';
import { exportAdSets } from '../../../actions';
import { useAdSetsFilters } from 'campaign/hooks/use-ad-sets-filters';
import { SEARCH_DEBOUNCE_TIME } from 'common/constants';
import { debounce } from 'lodash';
import { UUID } from 'io-ts-types/lib/UUID';

interface Props {
  projectId?: Id;
  campaignUuid: UUID;
}

const classes = bem('ad-sets-header');

export const Header = React.memo(({ projectId, campaignUuid }: Props) => {
  const dispatch = useDispatch();

  const adSets = useSelector(adSetsSelector);
  const total = adSets?.total || 0;

  const [filters, filtersDispatcher] = useAdSetsFilters(campaignUuid, projectId);

  const debouncedSearch = useCallback(
    debounce((value: string) => filtersDispatcher.setSearch(value, true), SEARCH_DEBOUNCE_TIME),
    [filtersDispatcher]
  );

  const onChangeSearchValue = useCallback((value: string) => debouncedSearch(value), [debouncedSearch]);

  const onExport = useCallback(() => dispatch(exportAdSets.request(campaignUuid)), [dispatch, campaignUuid]);

  return (
    <SHeader>
      <div className={classes('title')}>Ad Sets ({total})</div>
      <SSpacer />
      <Input
        bgStyle={'grey'}
        iconLeft="search"
        clearable
        disabled={false}
        placeholder="Search ad sets by name, platform, or objective"
        dataSelector="search-campaigns-input"
        className={classes('input')}
        initialValue={filters.search}
        onChange={onChangeSearchValue}
      />
      <Button disabled={false} variant="secondary-cta" dataSelector="in-progress-export-button" onClick={onExport}>
        <Icon name="export" />
        Export
      </Button>
    </SHeader>
  );
});
