import { useMemo } from 'react';
import { ParsedQs } from 'qs';
import { ProjectSortField } from 'backend-api/types';
import { paths } from 'app/routing/paths';
import { useFilters } from 'hooks';
import {
  getProjectFiltersFromParams,
  getProjectFiltersParamsFromFilters,
  getProjectFiltersParamsFromSearchQuery,
} from '../transducers';
import { updateFilters } from '../actions';
import { filtersSelector } from '../selectors';
import { AllProjectsFilters } from '../types';

export const useProjectsFilters = () => {
  const config = useMemo(
    () => ({
      action: updateFilters,
      selector: filtersSelector,
      path: paths.projects(),
      queryToFilters: (query: ParsedQs) => {
        const params = getProjectFiltersParamsFromSearchQuery(query);
        return getProjectFiltersFromParams(params);
      },
      filtersToQuery: getProjectFiltersParamsFromFilters,
    }),
    []
  );

  return useFilters<AllProjectsFilters, ProjectSortField>(config);
};
