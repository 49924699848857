import { animatedFlare } from 'common/s-components/s-animated-flare';
import styled from 'styled-components';

interface Props {
  clipPathId: string;
}

export const SLoaderBudgetWidget = styled.div<Props>`
  width: 100px;
  height: 35px;
  .loader-budget-widget {
    &__clip-container {
      width: 100px;
      height: 35px;
      clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      -webkit-clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      ${animatedFlare}
    }
  }
`;
