export const toasts = 120;
export const notifications = 110;
export const authContainer = 100;
export const tooltip = 90;
export const modalContent = 80;
export const rangePicker = 71;
export const confirmationModalOverlay = 70;
export const loader = 60;
export const confirmationModal = 60;
export const userDropdown = 52;
export const stickyFooter = 45;
export const calendarModal = 40;
export const modalHeader = 30;
export const popup = 30;
export const select = 30;
export const sidebar = 30;
export const sidebarSticky = 30;
export const dropdown = 20;
export const fullScreen = 16;
export const appHeader = 15;
export const popper = 14;
export const legend = 10;
export const paginationArrow = 10;
export const campaigns = 10;
export const autocomplete = 10;
export const emptyProjects = 10;
export const formFooter = 3;
