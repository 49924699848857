import React from 'react';
import { bem } from 'utils/bem';
import { SZeroStateCard } from './s-zero-state-card';
import { Button } from 'common/components/button';
import { appearingAnimation } from 'common/constants';
import { Tooltip } from '../tooltip';

interface Props {
  title?: string;
  subtitle?: string;
  buttonChildren: React.ReactNode;
  onButtonClick(): void;
  isButtonDisabled?: boolean;
  buttonTooltipContent?: JSX.Element;
  imageSrc?: string;
  className?: string;
}

const classes = bem('zero-state-card');

export const ZeroStateCard = React.memo(
  ({
    title,
    subtitle,
    buttonChildren,
    onButtonClick,
    imageSrc,
    isButtonDisabled,
    buttonTooltipContent,
    className,
  }: Props) => {
    return (
      <SZeroStateCard {...appearingAnimation} className={className} noImage={!imageSrc}>
        <div className={classes('info')}>
          <div className={classes('text-content')}>
            {title && <span className={classes('title')}>{title}</span>}
            {subtitle && <span className={classes('subtitle')}>{subtitle}</span>}
          </div>
          {imageSrc && <img src={imageSrc} alt="" className={classes('image')} />}
          <Tooltip content={buttonTooltipContent} enabled={!!buttonTooltipContent && !!isButtonDisabled}>
            <div className={classes('button')}>
              <Button
                onClick={onButtonClick}
                variant="primary-cta"
                dataSelector="start-project-button"
                disabled={isButtonDisabled}
              >
                {buttonChildren}
              </Button>
            </div>
          </Tooltip>
        </div>
      </SZeroStateCard>
    );
  }
);
