import { Id } from 'backend-api/models';
import { SearchFieldOption } from 'gdb-web-shared-components';

export interface SearchOption extends SearchFieldOption {
  description: string;
  badge?: string;
}

export interface UsersByProjectAccess {
  usersWithProjectAccess: Id[];
  usersWithoutProjectAccess: Id[];
}

export enum SearchFieldError {
  NONE = '',
  EMPTY_FIELD = 'Field cannot be empty.',
}
