import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form as BaseForm } from 'common/containers/form';
import { getInitialValues } from 'artist-moment/transducers';
import { activeMomentSelector, processingSelector } from 'artist-moment/selectors';
import { createMoment, editMoment, MomentInfo } from 'artist-moment/actions';
import { Body } from './components';

export const Form = React.memo(({ momentId, projectId }: MomentInfo) => {
  const moment = useSelector(activeMomentSelector);
  const isProcessing = useSelector(processingSelector);

  const dispatch = useDispatch();

  const initialValues = useMemo(() => getInitialValues(moment), [moment]);

  const handleSubmit = useCallback(
    data => {
      if (!momentId) {
        dispatch(
          createMoment.request({
            ...data,
            projectId,
          })
        );
      } else {
        dispatch(
          editMoment.request({
            ...data,
            projectId,
            momentId,
          })
        );
      }
    },
    [dispatch, momentId, projectId]
  );

  return (
    <BaseForm initialValues={initialValues} useNavigationPrompt={!isProcessing} onSubmit={handleSubmit}>
      <Body momentId={momentId} projectId={projectId} />
    </BaseForm>
  );
});
