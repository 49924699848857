import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const Value = styled.div`
  color: ${colorPalette.vulcan};
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-height: 20px;
`;
