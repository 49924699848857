import styled from 'styled-components';

export const BEM_CLASS = 'budget-input-editor';

export const SBudgetInputEditor = styled.div`
  width: 100%;
  height: 100%;
  .${BEM_CLASS} {
    &__input {
      height: 100%;
      width: 100%;
      .number-input {
        height: 100%;
        width: 100%;
        border-radius: 0;
        border: unset;
        padding: 0 var(--cell-horizontal-padding);
        text-align: end;
      }
    }
  }
`;
