import styled from 'styled-components';

export const SLinkView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px 0;

  & > :not(:last-child) {
    margin-right: 10px;
  }
`;
