import styled from 'styled-components';
import { manhattanBackground } from 'common-v2/styles';

export const BEM_CLASS = 'project-container';

export const SProjectContainer = styled.div`
  .${BEM_CLASS} {
    &__background {
      ${manhattanBackground};
    }

    &__container {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      width: 100%;
    }

    &__header {
      padding: 0 40px;
      margin-bottom: 2px;
    }

    &__tabs {
      padding: 0 40px;
    }

    &__content {
      padding: 20px 40px;
    }
  }
`;
