import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { DEFAULT_ROW_HEIGHT, HEADER_HEIGHT, MIN_BODY_HEIGHT, MIN_HEIGHT } from 'label-permissions/constants';

export const BEM_CLASS = 'reusable-table';

export const SReusableTable = styled.div`
  background-color: ${transparentize(0.5, manhattanThemeColors.background)};
  box-shadow: 0px 4px 4px ${transparentize(0.75, colorPalette.black)};

  .ag-root-wrapper {
    height: calc(100vh - 305px); // NOTE: Minus space occupied by other interface parts
    min-height: ${MIN_HEIGHT}px;
    max-height: ${MIN_HEIGHT * 3}px;
  }

  .ag-body-viewport {
    min-height: ${MIN_BODY_HEIGHT}px;
  }

  .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
    padding-top: ${HEADER_HEIGHT}px;
  }

  .ag-cell {
    height: ${DEFAULT_ROW_HEIGHT}px;
  }

  .ag-cell-wrapper {
    width: 100%;
  }

  .${BEM_CLASS} {
    &__error {
      height: ${MIN_BODY_HEIGHT}px;
      pointer-events: all;
    }
  }
`;
