import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'approval-status-menu-list';

export const SApprovalStatusMenu = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 340px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px 0;
  background-color: ${colorPalette.white};

  .${BEM_CLASS} {
    &__options {
      display: flex;
      flex-direction: column;
    }
    &__divider {
      height: 1px;
      background-color: ${colorPalette.iron};
      width: 100%;
    }
    &__apply-to-all {
      margin-top: 10px;
      padding: 0 10px;
      display: flex;
      align-items: center;
    }
    &__checkbox {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
  }
`;
