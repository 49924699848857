import styled, { css } from 'styled-components';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { dropdown as dropdownZIndex } from 'app/styles/z-indexes';
import { BORDER_WIDTH } from './constants';
import { transparentize } from 'polished';

interface SExpandedContainerProps {
  top: number;
  left: number;
  hasError: boolean;
}

export const SExpandableField = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SExpandedContainer = styled.div<SExpandedContainerProps>`
  position: absolute;
  z-index: ${dropdownZIndex};
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.white};
  border: ${BORDER_WIDTH}px solid ${transparentize(0.6, manhattanThemeColors.primaryFillNew)};
  border-radius: 2px;

  ${({ top, left, hasError }) => css`
    top: ${top}px;
    left: ${left}px;

    ${hasError &&
      css`
        border-color: ${manhattanThemeColors.darkRed};
      `}
  `}
`;
