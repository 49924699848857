import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import { IdNameField, IdType, OptionalNullable, ResponseModel } from '../../common';

export const ObjectiveSchema = ResponseModel(IdNameField, 'Objective');

export type Objective = t.TypeOf<typeof ObjectiveSchema>;

export enum SpendVisibilityOption {
  NotVisible = 0,
  ExternalOnly = 1,
  Visible = 2,
}

export const SpendVisibilityOptionSchema = t.union(
  [
    t.literal(SpendVisibilityOption.NotVisible),
    t.literal(SpendVisibilityOption.ExternalOnly),
    t.literal(SpendVisibilityOption.Visible),
  ],
  'SpendVisibilityOption'
);

export const StatusIdSchema = t.union([t.literal(1), t.literal(2), t.literal(3)], 'StatusId');

export type StatusId = t.TypeOf<typeof StatusIdSchema>;

export const AudienceAgeSchema = ResponseModel(
  {
    higherAge: OptionalNullable(t.number),
    lowerAge: OptionalNullable(t.number),
  },
  'AudienceAge'
);

export type AudienceAge = t.TypeOf<typeof AudienceAgeSchema>;

export const GenderSchema = ResponseModel(IdNameField, 'Gender');

export type Gender = t.TypeOf<typeof GenderSchema>;

export const CampaignProviderSchema = ResponseModel(IdNameField, 'CampaignProvider');

export type CampaignProvider = t.TypeOf<typeof CampaignProviderSchema>;

export enum CampaignSources {
  PRS = 'PRS',
  MANUAL = 'Manual',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  TIKTOK = 'tiktok',
}

export const CampaignSourceSchema = t.union([
  t.literal(CampaignSources.MANUAL),
  t.literal(CampaignSources.FACEBOOK),
  t.literal(CampaignSources.GOOGLE),
  t.literal(CampaignSources.PRS),
  t.literal(CampaignSources.TIKTOK),
]);

export enum CampaignFilterStatus {
  All = 1,
  Pending = 2,
}

export const CampaignEditableFieldsSchema = ResponseModel(
  {
    name: t.boolean,
    platforms: t.boolean,
    provider: t.boolean,
    objective: t.boolean,
    budget: t.boolean,
    spend: t.boolean,
    startDate: t.boolean,
    endDate: t.boolean,
    genders: t.boolean,
    audienceAge: t.boolean,
    countries: t.boolean,
    destinations: t.boolean,
    notes: t.boolean,
  },
  'CampaignEditableFields'
);

export type CampaignEditableFields = t.TypeOf<typeof CampaignEditableFieldsSchema>;

export const AssignCampaignsRequestSchema = ResponseModel(
  {
    campaignUuids: t.array(UUID),
  },
  'AssignCampaignsRequest'
);

export type AssignCampaignsRequest = t.TypeOf<typeof AssignCampaignsRequestSchema>;

export const CampaignPlatformValueSchema = ResponseModel(
  {
    id: IdType,
    name: t.string,
    value: t.number,
  },
  'CampaignPlatform'
);

export type CampaignPlatformValue = t.TypeOf<typeof CampaignPlatformValueSchema>;

export const NamingConventionResponseSchema = ResponseModel(
  {
    campaignUuid: UUID,
    namingConvention: t.string,
  },
  'NamingConventionResponse'
);

export type NamingConventionResponse = t.TypeOf<typeof NamingConventionResponseSchema>;
