import styled from 'styled-components';
import { ellipsizeText } from 'common-v2/styles';

export const BEM_CLASS = 'multi-value-label';

export const SMultiValueLabel = styled.div`
  overflow: hidden;

  & > div {
    padding: 0;
    font-size: 100%;
  }

  .${BEM_CLASS} {
    &__text {
      ${ellipsizeText};
      line-height: 1;
    }
  }
`;
