import styled, { css } from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

interface Props {
  backgroundColor: string | null;
  isDisabled: boolean;
}

export const BEM_CLASS = 'base-renderer';

export const SBaseRenderer = styled.div<Props>`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px var(--cell-horizontal-padding);
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: inherit;
    height: inherit;
    background-color: ${({ backgroundColor, isDisabled }) =>
      backgroundColor ?? (isDisabled ? colorPalette.alabaster : null)};
  }

  ${({ isDisabled }) =>
    !isDisabled &&
    css`
      &:hover {
        .${BEM_CLASS} {
          &__placeholder {
            visibility: visible;
          }
        }
      }
    `}


  .${BEM_CLASS} {
    &__value {
      ${elipsizeText};
      display: flex;
      width: 100%;
    }

    &__placeholder {
      flex-grow: 1;
      color: ${colorPalette.wildBlueYonder};
      visibility: hidden;
    }
  }
`;
