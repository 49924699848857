import React from 'react';
import { BaseOption as BaseOptionType } from '../../types';
import { MultiValueRemoveProps } from 'react-select';
import { ICON_SIZE, Icon } from 'gdb-web-shared-components';
import { SMultiValueRemove } from './s-multi-value-remove';

type Props<T extends BaseOptionType> = MultiValueRemoveProps<T, true>;

const Root = <T extends BaseOptionType>({ ...props }: Props<T>) => (
  <SMultiValueRemove {...props.innerProps} ref={null}>
    <Icon name="cross" size={ICON_SIZE.small} />
  </SMultiValueRemove>
);

export const MultiValueRemove = React.memo(Root) as typeof Root;
