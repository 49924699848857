import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { isIconName } from 'common/components/icon/guards';
import { Tooltip } from 'common/components/tooltip';
import { IconColor, IconSize } from 'common/components/icon/types';
import { BEM_CLASS, SCampaignPlatformIcon } from './s-campaign-platform-icon';
import { colorFromIconColor } from 'common/transducers';

interface Props {
  className?: string;
  circleClassName?: string;
  platforms: string[];
  iconSize?: IconSize;
  iconColor?: IconColor;
  isTooltipEnabled?: boolean;
}

const classes = bem(BEM_CLASS);

export const CampaignPlatformIcon = React.memo(
  ({
    platforms,
    iconSize = 'general',
    iconColor = 'black',
    className,
    isTooltipEnabled = true,
    circleClassName,
  }: Props) => {
    const getPlatformIcon = useCallback(
      (platforms: string[]): JSX.Element => {
        const platformLength = platforms.length;

        if (platformLength === 0) {
          return <></>;
        }

        const isSinglePlatform = platformLength === 1;

        const firstPlatform = platforms[0];
        const loverCasePlatform = firstPlatform === 'Other' ? 'unknown' : firstPlatform.toLowerCase();

        const platformIcon =
          isSinglePlatform && isIconName(loverCasePlatform) ? (
            <Icon className={classes('icon')} name={loverCasePlatform} size={iconSize} color={iconColor} />
          ) : (
            <div className={classes('circle', undefined, circleClassName)}>
              {isSinglePlatform ? firstPlatform.charAt(0) : platformLength}
            </div>
          );

        const tooltipText = platforms.join(', ');

        return (
          <Tooltip content={tooltipText} enabled={isTooltipEnabled}>
            <span>{platformIcon}</span>
          </Tooltip>
        );
      },
      [circleClassName, iconColor, iconSize, isTooltipEnabled]
    );

    return (
      <SCampaignPlatformIcon backgroundColor={colorFromIconColor(iconColor)} className={className}>
        {getPlatformIcon(platforms)}
      </SCampaignPlatformIcon>
    );
  }
);
