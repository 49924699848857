import styled from 'styled-components';
import { PADDING } from '../../constants';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'value-container';

interface Props {
  isMulti?: boolean;
}

export const SValueContainer = styled.div<Props>`
  flex: 1;
  overflow: hidden;

  .${BEM_CLASS} {
    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &__values-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      max-height: 92px;
      overflow: auto;
      flex-wrap: ${({ isMulti }) => (isMulti ? 'wrap' : 'nowrap')};
      gap: 5px;
      align-content: flex-start;
      scroll-padding-bottom: 10px;
      margin-bottom: auto;
      padding: 9px ${PADDING}px;
    }

    &__footer {
      padding: 9px ${PADDING}px;
      display: flex;
      align-items: center;
    }

    &__counter {
      margin-left: auto;
      color: ${manhattanThemeColors.secondGrayedText};
    }

    &__clear {
      padding-left: 17px;
      padding-right: 17px;
    }
  }
`;
