import React from 'react';
import { bem } from 'utils/bem';
import MarkdownEditor from 'common/components/markdown-editor';
import { ReusableModal } from 'common/components/reusable-modal';
import { NotesUpdates } from '../notes-updates';
import { SNotesModal } from './s-notes-modal';

interface Props {
  notes?: string;
  isOpened: boolean;
  onModalClose(): void;
}

const classes = bem('notes-modal');

export const NotesModal = ({ notes = '', isOpened, onModalClose }: Props) => {
  return (
    <ReusableModal width="840px" title="Notes" isOpen={isOpened} onClose={onModalClose} dataSelector="notes-modal">
      {isOpened ? (
        <SNotesModal>
          <NotesUpdates />
          <MarkdownEditor className={classes('editor')} editable={false} content={notes} />
        </SNotesModal>
      ) : (
        <div />
      )}
    </ReusableModal>
  );
};
