import { apiClient, EmptyPayload, EmptySchema, ResponseArray } from '../index';
import {
  ApprovalRequestSchema,
  CreateMediaPlanRequest,
  CreateMediaPlanRequestSchema,
  CreatePhaseRequest,
  CreatePhaseRequestSchema,
  EditPhaseRequest,
  EditPhaseRequestSchema,
  Id,
  MediaPlanListItemSchema,
  MediaPlanPhaseSchema,
  MediaPlanRelease,
  MediaPlanReleaseSchema,
  MediaPlanReleaseUpdateResponseSchema,
  MediaPlanSchema,
  ModifyBulkCampaignsRequest,
  ModifyBulkCampaignsRequestSchema,
  ModifyBulkCampaignsResponseSchema,
  ProjectMetricsSchema,
  ProjectReviewerSchema,
  RequestApprovalRequest,
  RequestApprovalRequestSchema,
  SubmitApprovalRequest,
  SubmitApprovalRequestSchema,
} from '../models';

export const getMediaPlans = (projectId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/media-plans`,
    responseSchema: ResponseArray(MediaPlanListItemSchema),
  });

export const getMediaPlan = (projectId: Id, mediaPlanId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}`,
    responseSchema: MediaPlanSchema,
  });

export const createMediaPlanPhase = (projectId: Id, mediaPlanId: Id, payload: CreatePhaseRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/phases`,
    responseSchema: MediaPlanPhaseSchema,
    requestSchema: CreatePhaseRequestSchema,
    payload,
  });

export const editMediaPlanPhase = (projectId: Id, mediaPlanId: Id, phaseId: Id, payload: EditPhaseRequest) =>
  apiClient.executeRequest({
    method: 'PATCH',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/phases/${phaseId}`,
    responseSchema: MediaPlanPhaseSchema,
    requestSchema: EditPhaseRequestSchema,
    payload,
  });

export const deleteMediaPlanPhase = (projectId: Id, mediaPlanId: Id, phaseId: Id) =>
  apiClient.executeRequest({
    method: 'DELETE',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/phases/${phaseId}`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
  });

export const getMediaPlanMetrics = (projectId: Id, mediaPlanId: number) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/metrics`,
    responseSchema: ProjectMetricsSchema,
  });

export const modifyMediaPlanCampaigns = (projectId: Id, mediaPlanId: Id, payload: ModifyBulkCampaignsRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/campaigns/bulk-action`,
    responseSchema: ModifyBulkCampaignsResponseSchema,
    requestSchema: ModifyBulkCampaignsRequestSchema,
    payload,
  });

export const getProjectReviewers = (projectId: Id, mediaPlanId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/approver-users`,
    responseSchema: ResponseArray(ProjectReviewerSchema),
  });

export const requestApproval = (projectId: Id, mediaPlanId: Id, payload: RequestApprovalRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/approval-request`,
    responseSchema: EmptySchema,
    requestSchema: RequestApprovalRequestSchema,
    payload,
  });

export const getApprovalRequests = (projectId: Id, mediaPlanId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/media-plan-review`,
    responseSchema: ResponseArray(ApprovalRequestSchema),
  });

export const submitApproval = (projectId: Id, mediaPlanId: Id, payload: SubmitApprovalRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/submit-approval`,
    responseSchema: EmptySchema,
    requestSchema: SubmitApprovalRequestSchema,
    payload,
  });

export const getMediaPlanRelease = (projectId: Id, mediaPlanId: Id) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/release-details`,
    responseSchema: MediaPlanReleaseSchema,
  });

export const updateMediaPlanRelease = (projectId: Id, mediaPlanId: Id, payload: MediaPlanRelease) =>
  apiClient.executeRequest({
    method: 'PATCH',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}/release-details`,
    responseSchema: MediaPlanReleaseUpdateResponseSchema,
    requestSchema: MediaPlanReleaseSchema,
    payload,
  });

export const createMediaPlan = (projectId: Id, payload: CreateMediaPlanRequest) =>
  apiClient.executeRequest({
    method: 'POST',
    endpoint: `/projects/${projectId}/media-plans`,
    responseSchema: MediaPlanListItemSchema,
    requestSchema: CreateMediaPlanRequestSchema,
    payload,
  });

export const editMediaPlan = (projectId: Id, mediaPlanId: Id, payload: CreateMediaPlanRequest) =>
  apiClient.executeRequest({
    method: 'PUT',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}`,
    responseSchema: MediaPlanListItemSchema,
    requestSchema: CreateMediaPlanRequestSchema,
    payload,
  });

export const deleteMediaPlan = (projectId: Id, mediaPlanId: Id) =>
  apiClient.executeRequest({
    method: 'DELETE',
    endpoint: `/projects/${projectId}/media-plans/${mediaPlanId}`,
    responseSchema: EmptySchema,
    requestSchema: EmptySchema,
    payload: EmptyPayload,
  });
