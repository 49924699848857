import { Optional } from 'backend-api/models';
import { OptionalPeriod, Period, isPeriod } from 'common/types';
import { DatePickerMode, PopupAlignment, PopupPosition } from 'common/components/form/date-picker/types';
import { DATE_POPUP_HEIGHT } from 'common/components/form/date-picker/constants';
import {
  isDateAfter,
  isDateBefore,
  addDaysToDate,
  substractDaysFromDate,
  getFormattedDate,
  isSameDate,
  isDateBetween,
} from 'common-v2/utils';

export const isAvailableDate = (
  date: Date,
  availableDates?: Period[] | Period,
  firstSelectedDate?: Date,
  maxPeriodDays?: number
): boolean => {
  const maxPeriodLimitRange = firstSelectedDate
    ? getMaxPeriodDateRange(firstSelectedDate, maxPeriodDays)
    : getMaxPeriodDateRange(date, maxPeriodDays);

  const dateAvailable = maxPeriodLimitRange || availableDates;

  if (dateAvailable && dateAvailable.length > 0) {
    return isPeriod(dateAvailable)
      ? isSameDate(date, dateAvailable[0]) ||
          isSameDate(date, dateAvailable[1]) ||
          (isDateAfter(date, dateAvailable[0]) && isDateBefore(date, dateAvailable[1]))
      : dateAvailable.some(
          (period: Period) =>
            isSameDate(date, period[0]) || isSameDate(date, period[1]) || isDateBetween(date, period[0], period[1])
        );
  } else {
    return true;
  }
};

export const getMaxPeriodDateRange = (firstSelectedDate?: Date, maxPeriodDays?: number): Optional<[Date, Date][]> => {
  if (!firstSelectedDate || !maxPeriodDays) return undefined;

  const rangeAfterSelectedDate: Period = [firstSelectedDate, addDaysToDate(firstSelectedDate, maxPeriodDays - 1)];

  const rangeBeforeSelectedDate: Period = [
    substractDaysFromDate(firstSelectedDate, maxPeriodDays - 1),
    firstSelectedDate,
  ];

  return [rangeBeforeSelectedDate, rangeAfterSelectedDate];
};

export const getJoinedDateRange = (period: Optional<OptionalPeriod>, dateFormat: string): Optional<string> => {
  if (!period) return;

  const formatDate = getFormattedDate(dateFormat);

  return period
    .filter(Boolean)
    .map(item => (item ? formatDate(item) : ''))
    .join('–');
};

export const getPickerDisabledDays = (
  firstSelectedDate?: Date,
  maxPeriodDays?: number,
  availableDates?: Period | Period[]
) => {
  if (maxPeriodDays && firstSelectedDate) {
    return [
      {
        before: substractDaysFromDate(firstSelectedDate, maxPeriodDays),
        after: addDaysToDate(firstSelectedDate, maxPeriodDays),
      },
    ];
  }
  if (!availableDates) return [];
  return isPeriod(availableDates)
    ? [{ before: availableDates[0], after: availableDates[1] }]
    : availableDates.map(period => ({ before: period[0], after: period[1] }));
};

export const getPopupPosition = (
  mode: DatePickerMode,
  triggerElement: HTMLDivElement,
  popupRef: HTMLDivElement,
  alignment?: PopupAlignment
): PopupPosition => {
  const { bottom, left, right, height, width } = triggerElement?.getBoundingClientRect();
  const popupWidth = popupRef.getBoundingClientRect().width;
  const offsetLeft = left - popupWidth + width;
  const offsetRight = right - width;

  const getSide = (alignment?: PopupAlignment, canFitPopupLeft?: boolean): number => {
    switch (alignment) {
      case PopupAlignment.Left:
        return offsetLeft;
      case PopupAlignment.Right:
        return offsetRight;
      case PopupAlignment.Auto:
        return canFitPopupLeft ? offsetLeft : offsetRight;
      default:
        return canFitPopupLeft ? offsetLeft : offsetRight;
    }
  };

  const canFitPopupVertically = bottom + DATE_POPUP_HEIGHT < window.innerHeight;
  const canFitPopupOnLeft = left - popupWidth > 0;

  const alignTopOffset = canFitPopupVertically ? 0 : DATE_POPUP_HEIGHT + height;

  return {
    top: window.pageYOffset + bottom - alignTopOffset,
    left: getSide(alignment, canFitPopupOnLeft),
  };
};
