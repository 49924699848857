import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { navigateTo } from 'utils/navigation';
import { Footer } from 'app/components/footer';
import { ProjectHeader } from 'common/containers/project-header';
import { WAVE_GRADIENT_END_COLOR } from 'common/components/wave-background';
import {
  filtersSelector,
  isPOChildrenLoadingFailedSelector,
  isPOChildrenLoadingSelector,
  purchaseOrderSelector,
  purchaseOrdersSelector,
  purchaseProvidersSelector,
  errorSelector,
} from '../selectors';
import { getPurchaseOrder, getPurchaseOrderChildren, getPurchaseOrdersProviders, updateFilters } from '../actions';
import { Details } from '../components';
import { PurchaseOrderFilterParams } from 'backend-api/types';
import { PurchaseOrders } from 'common/components/tables/purchase-orders';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { useProjectAccessRestricted } from 'common/hooks/use-project-access-restricted';
import { paths } from 'app/routing/paths';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';
import { Head } from 'app/components/head';
import { getProjectName } from 'common-v2/transducers';
import { activeProjectSelector } from 'project/selectors';

interface MatchParams {
  id: string;
  purchaseOrderId: string;
}

export const PurchaseOrderContainer = React.memo(({ match }: RouteComponentProps<MatchParams>) => {
  const dispatch = useDispatch();

  const projectId = parseInt(match.params.id, 10);
  const project = useSelector(activeProjectSelector);
  const purchaseOrderId = parseInt(match.params.purchaseOrderId, 10);

  const purchaseOrder = useSelector(purchaseOrderSelector);

  const purchaseOrdersResponse = useSelector(purchaseOrdersSelector);
  const providers = useSelector(purchaseProvidersSelector);
  const isPOChildrenLoading = useSelector(isPOChildrenLoadingSelector);
  const isPOChildrenLoadingFailed = useSelector(isPOChildrenLoadingFailedSelector);

  const isBlanketPO = useMemo(() => {
    return (purchaseOrder.data && purchaseOrder.data?.isBlanket) || false;
  }, [purchaseOrder.data]);

  useEffect(() => {
    if (isNaN(projectId) || isNaN(purchaseOrderId)) {
      navigateTo(paths.outdatedNotFound());
    } else {
      dispatch(getPurchaseOrder.request({ projectId, id: purchaseOrderId }));
    }
  }, [dispatch, projectId, purchaseOrderId]);

  useEffect(() => {
    if (purchaseOrder.data && purchaseOrder.data?.isBlanket) {
      dispatch(getPurchaseOrdersProviders.request(projectId));
    }
  }, [dispatch, projectId, purchaseOrder]);

  const onRequestData = useCallback(
    (params: PurchaseOrderFilterParams) => {
      if (purchaseOrder.data && purchaseOrder.data?.isBlanket) {
        dispatch(getPurchaseOrderChildren.request({ projectId, id: purchaseOrder.data.id, params }));
      }
    },
    [dispatch, projectId, purchaseOrder.data]
  );

  const purchaseOrdersTableRef = useRef<HTMLDivElement>(null);

  const onViewPOsClick = useCallback(() => {
    purchaseOrdersTableRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [purchaseOrdersTableRef]);

  useProjectAccessRestricted(errorSelector);

  return (
    <>
      <Head title={getProjectName(project)} />
      <OutdatedGlobalLayout>
        {!isNaN(projectId) && <ProjectHeader projectId={projectId} isNoActiveTab />}
        <Details
          onViewPOsClick={onViewPOsClick}
          projectId={projectId}
          data={purchaseOrder.data}
          isLoading={purchaseOrder.loading}
        />
        {isBlanketPO && (
          <ResponsiveContainer>
            <PurchaseOrders
              ref={purchaseOrdersTableRef}
              projectId={projectId}
              purchaseOrderId={purchaseOrderId}
              filterUpdateAction={updateFilters}
              filtersSelector={filtersSelector}
              onRequestData={onRequestData}
              purchaseOrdersResponse={purchaseOrdersResponse}
              providers={providers}
              isLoading={isPOChildrenLoading}
              isLoadingFailed={isPOChildrenLoadingFailed}
            />
          </ResponsiveContainer>
        )}
        <Footer background={isBlanketPO ? undefined : WAVE_GRADIENT_END_COLOR} />
      </OutdatedGlobalLayout>
    </>
  );
});
