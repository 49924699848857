import React, { useMemo } from 'react';
import { TargetsImages } from 'common/components/targets-images';
import { BEM_CLASS, SMoreArtistsCard } from './s-more-artists-card';
import { ArtistSearch } from 'backend-api/models';
import { bem } from 'utils/bem';
import { LiveArtistCardStates } from 'common/types';

interface MoreArtistsCardProps {
  artists: ArtistSearch[];
  cardState: LiveArtistCardStates;
  onClick(): void;
}

const classes = bem(BEM_CLASS);

export const MoreArtistsCard = ({ artists, cardState, onClick }: MoreArtistsCardProps) => {
  const targetArtists = useMemo(() => artists.sort((current, next) => (!next.image || !current.image ? -1 : 1)), [
    artists,
  ]);

  return (
    <SMoreArtistsCard cardState={cardState} onClick={onClick}>
      <TargetsImages hasGradient isOverlayDisabled className={classes('targets-images')} targets={targetArtists} />
      <span className={classes('plus-artists')}>+{artists.length}</span>
    </SMoreArtistsCard>
  );
};
