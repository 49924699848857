import React, { useMemo } from 'react';
import { BEM_CLASS, STable } from './s-table';
import { Table } from 'common/components/table';
import { Pagination } from 'common/components/pagination';
import { TableTheme } from 'app/theme/table';
import { bem } from 'utils/bem';
import { Id, PurchaseOrderDetails } from 'backend-api/models';
import { PurchaseOrderSortField } from 'backend-api/types';
import { getPurchaseOrderTableConfig } from './table-config';
import { Sort } from 'utils/sort';
import { BreakdownValuesByEntityId } from '../../types';
import { dateFormatSelector } from 'common/selectors';
import { useSelector } from 'react-redux';

interface Props {
  projectId: Id;
  sort: Sort<PurchaseOrderSortField>;
  onSortChange(sort: Sort<PurchaseOrderSortField>): void;
  onPaginationChange?(pagination: Pagination): void;
  pagination: Pagination;
  isLoading?: boolean;
  isLoadingFailed?: boolean;
  isUnassigned?: boolean;
  search?: string;
  purchaseOrders: PurchaseOrderDetails[];
  breakdowns?: BreakdownValuesByEntityId;
  onExpandItem?: (item: PurchaseOrderDetails, isExpanded: boolean) => void;
  theme?: TableTheme;
  currencyCode?: string;
}

const classes = bem(BEM_CLASS);

export const PurchaseOrdersTable = React.memo(
  ({
    projectId,
    onSortChange,
    sort,
    onPaginationChange,
    pagination,
    isLoading,
    search,
    purchaseOrders,
    breakdowns = {},
    onExpandItem,
    isUnassigned,
    isLoadingFailed,
    theme = TableTheme.WHITE,
    currencyCode,
  }: Props) => {
    const dateFormat = useSelector(dateFormatSelector);
    const headerContent = useMemo(
      () => getPurchaseOrderTableConfig(projectId, breakdowns, dateFormat, isUnassigned, currencyCode),
      [projectId, breakdowns, isUnassigned, dateFormat, currencyCode]
    );

    const expandedIds = useMemo(
      () =>
        (breakdowns ? Object.keys(breakdowns).filter(item => breakdowns[item].isExpanded) : []).map(item =>
          parseInt(item, 10)
        ),
      [breakdowns]
    );

    return (
      <STable>
        <Table
          className={classes('table')}
          tableContainerClassName={classes('table-container')}
          dataSelector="purchase-orders-table"
          headerContent={headerContent}
          bodyContent={purchaseOrders}
          onSortChange={onSortChange}
          sort={sort}
          onPaginationChange={onPaginationChange}
          pagination={pagination}
          isLoading={isLoading}
          isExpandable={onExpandItem !== undefined}
          isSearching={!!search}
          expandedIds={expandedIds}
          onExpandItem={onExpandItem}
          shouldDisableExpandForRow={item => !item.isBlanket}
          theme={theme}
          isLoadingFailed={isLoadingFailed}
        />
      </STable>
    );
  }
);
