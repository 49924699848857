import React, { useCallback, useEffect, useState } from 'react';
import { GridApi } from 'ag-grid-community';
import { FocusDirection, SpreadsheetRowData } from '../types';
import { moveCellFocus } from '../transducers';

export enum ValueEvent {
  SELECT_CURRENT,
  RESET,
}

export interface Params {
  api: GridApi<SpreadsheetRowData>;
  value: any;
  onValueEvent?(event: ValueEvent): boolean;
  shouldCloseOnEnter?: boolean;
}

export interface Result {
  onKeyDown(event: React.KeyboardEvent<HTMLElement>): void;
}

export const useEditorKeyboardInteractions = ({
  api,
  value,
  onValueEvent,
  shouldCloseOnEnter = true,
}: Params): Result => {
  const [lastKeyEvent, setLastKeyEvent] = useState<React.KeyboardEvent<HTMLElement>>();

  const refocusCell = useCallback(() => moveCellFocus(api, FocusDirection.NONE), [api]);

  useEffect(() => {
    if (!lastKeyEvent) return;
    if (lastKeyEvent?.key === 'Tab') {
      api.stopEditing();
      if (lastKeyEvent.shiftKey) {
        api.tabToPreviousCell(lastKeyEvent.nativeEvent);
      } else {
        api.tabToNextCell(lastKeyEvent.nativeEvent);
      }
      refocusCell();
    }
    if ((lastKeyEvent?.key === 'Enter' && !lastKeyEvent.ctrlKey) || lastKeyEvent?.key === 'Escape') {
      api.stopEditing();
      if (lastKeyEvent.shiftKey) {
        moveCellFocus(api, FocusDirection.BACKWARD);
      } else {
        moveCellFocus(api, FocusDirection.FORWARD);
      }
    }

    setLastKeyEvent(undefined);
  }, [api, value, lastKeyEvent, shouldCloseOnEnter, refocusCell]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Backspace') {
        if (!onValueEvent || onValueEvent(ValueEvent.RESET)) {
          setLastKeyEvent(event);
        }
      }

      if (event.key === 'Tab' || event.key === 'Enter') {
        event.stopPropagation();
        event.preventDefault();

        if (!onValueEvent || onValueEvent(ValueEvent.SELECT_CURRENT)) {
          setLastKeyEvent(event);
        }
      }

      if (event.key === 'Escape') {
        setLastKeyEvent(event);
      }
    },
    [onValueEvent]
  );

  return { onKeyDown };
};
