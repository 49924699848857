import styled from 'styled-components';

export const BEM_CLASS = 'autocomplete-multi-select';

export const SAutocompleteMultiSelect = styled.div`
  .${BEM_CLASS} {
    &__control {
      width: 100%;
    }
  }
`;
