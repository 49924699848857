import React, { useMemo } from 'react';
import { SExpandedAdSet } from '../../../s-components/ad-sets/s-expanded-ad-set';
import { bem } from 'utils/bem';
import { Tooltip } from 'common/components/tooltip';
import { Cell } from 'common/components/form/cell';
import { AdSet } from 'backend-api/models';
import { getAudienceString, getNamesString } from 'common/transducers';
import { LinksViewComponent } from 'common/components/form/links/components';

interface Props {
  item: AdSet;
}

const classes = bem('expanded-ad-set');

export const ExpandedAdSet = ({ item }: Props) => {
  const gendersString = useMemo(() => getNamesString(item.genders), [item.genders]);
  const territoriesString = useMemo(() => getNamesString(item.territories), [item.territories]);
  const audienceAgeString = useMemo(() => getAudienceString(item.audienceAge), [item.audienceAge]);

  return (
    <SExpandedAdSet>
      <div className={classes('divider')} />
      <div className={classes('main-info')} data-selector="ad-set-main-info">
        <Cell label="Gender" dataSelector="gender">
          <Tooltip content={gendersString}>
            <span className={classes('text')}>{gendersString}</span>
          </Tooltip>
        </Cell>
        <Cell label="Platform" dataSelector="platform">
          <Tooltip content={item.platform}>
            <span className={classes('text')}>{item.platform}</span>
          </Tooltip>
        </Cell>
        <Cell label="Audience Age" dataSelector="audience-age">
          <Tooltip content={audienceAgeString}>
            <span className={classes('text')}>{audienceAgeString}</span>
          </Tooltip>
        </Cell>
        <Cell label="Objective" dataSelector="objective">
          <Tooltip content={item.objective.name}>
            <span className={classes('text')}>{item.objective.name}</span>
          </Tooltip>
        </Cell>
        <Cell label="Countries" dataSelector="countries">
          <Tooltip content={territoriesString}>
            <span className={classes('text')}>{territoriesString}</span>
          </Tooltip>
        </Cell>
      </div>
      <div className={classes('padded-divider')} />
      {item.destinationLinks && item.destinationLinks.length > 0 && (
        <div className={classes('links-container')}>
          <div className={classes('campaign-links')} data-selector="ad-set-campaign-links">
            <Cell className={classes('cell-centered-label')} label="Destinations">
              <LinksViewComponent links={item.destinationLinks} flat />
            </Cell>
          </div>
        </div>
      )}
    </SExpandedAdSet>
  );
};
