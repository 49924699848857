import { ICellEditorParams } from 'ag-grid-community';
import { CampaignStatuses } from 'backend-api/models';
import {
  BaseOption,
  ClassNamesProps,
  Select as BaseSelect,
  SelectComponents,
  SelectMenuProps,
  SelectType,
} from 'common/components/select';
import { useSelectEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan-v2/components/spreadsheet/hooks';
import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { bem } from 'utils/bem';
import { isSubstring } from 'utils/string';
import { isEnumValue } from 'common-v2/types';
import { ApprovalSelectOption, ApprovalStatusMenu, ApprovalValue } from './components';
import { BEM_CLASS, SApprovalStatusEditor } from './s-approval-status-editor';
import { MediaPlanColumnId } from 'media-plan-v2/containers/spreadsheet/types';

const classes = bem(BEM_CLASS);

export const ApprovalStatusEditor = React.memo(
  React.forwardRef(({ api, rowIndex, colDef, data, column }: ICellEditorParams, ref) => {
    const [value, setValue] = useState(data.status.value);
    const [applyToAllPhases, setApplyToAllPhases] = useState(false);
    const { onBlur } = useStopEditingBlur(api);

    const handleChange = useCallback(
      (status: CampaignStatuses) => {
        if (status) {
          setValue(status);
          api.setFocusedCell(rowIndex, colDef.field || '');

          if (applyToAllPhases) {
            api.forEachNodeAfterFilter(rowNode => {
              if (
                rowNode &&
                rowNode.data &&
                rowNode.data.editableInApproval.value &&
                rowNode.data.phaseId.value === data.phaseId.value
              ) {
                rowNode.setDataValue(MediaPlanColumnId.STATUS, {
                  ...rowNode.data.status,
                  value: status,
                });
              }
            });
          }
        }
      },
      [api, colDef.field, rowIndex, applyToAllPhases, data]
    );

    const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions({
      api,
      value,
      setValue: handleChange,
      isMulti: false,
    });

    const options = useMemo<BaseOption[]>(
      () => [
        { id: CampaignStatuses.APPROVED, value: CampaignStatuses.APPROVED, label: 'Approve' },
        { id: CampaignStatuses.DISAPPROVED, value: CampaignStatuses.DISAPPROVED, label: 'Disapprove' },
        { id: CampaignStatuses.PENDING, value: CampaignStatuses.REVIEW },
      ],
      []
    );

    const selectClassNames = useCallback<(className: string) => ClassNamesProps>(
      className => ({
        selectContainer: classes('select-container'),
        control: classes('control'),
        valueContainer: { root: classes('value-root'), container: classes('value-container') },
        menu: classes('menu'),
        indicatorsContainer: classes('indicators-container'),
        menuPortal: className,
      }),
      []
    );

    const selectComponents = useCallback<(props: SelectMenuProps) => SelectComponents<BaseOption>>(
      () => ({
        SingleValue: ({ data }) =>
          isEnumValue(CampaignStatuses)(data.value) ? <ApprovalValue status={data.value} /> : null,
        Option: ApprovalSelectOption,
        MenuList: props => <ApprovalStatusMenu {...props} onApplyToAll={setApplyToAllPhases} />,
        DropdownIndicator: null,
      }),
      []
    );

    const filterOption = useCallback(
      (option: FilterOptionOption<BaseOption>, inputValue: string): boolean =>
        option.data.id !== CampaignStatuses.PENDING && isSubstring(option.data.value, inputValue),
      []
    );

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          ...data.status,
          value,
        };
      },
    }));

    return (
      <SApprovalStatusEditor statusId={value} width={column.getActualWidth()}>
        {className => (
          <div className={`${className} ${className}--input`}>
            <BaseSelect
              selectRef={selectRef}
              type={SelectType.Base}
              valueId={value}
              valueHandler={handleChange}
              options={options}
              classNames={selectClassNames(className)}
              selectComponents={selectComponents}
              isOpened
              isSearchable={false}
              autoFocus
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              filterOption={filterOption}
            />
          </div>
        )}
      </SApprovalStatusEditor>
    );
  })
);
