import React from 'react';
import { EllipsisBody, SSpreadsheetTooltip } from './s-spreadsheet-tooltip';
import { TippyProps } from '@tippyjs/react';

interface Props extends TippyProps {
  contentClassName?: string;
}

export const SpreadsheetTooltip = React.memo(
  ({ children, content, placement = 'bottom', contentClassName, ...props }: Props) => {
    return (
      <SSpreadsheetTooltip
        {...props}
        followCursor="horizontal"
        content={<EllipsisBody className={contentClassName}>{content}</EllipsisBody>}
        placement={placement}
      >
        {children}
      </SSpreadsheetTooltip>
    );
  }
);
