import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette } from 'app/theme/colors';
import { MAX_WIDTH, MIN_WIDTH, PADDING } from '../../constants';

export const BEM_CLASS = 'menu-wrapper';

export const SSearchableMenuWrapper = styled.div`
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;
  border-radius: 10px;
  box-shadow: 0 5px 10px ${transparentize(0.5, colorPalette.coolGray)};
  overflow: hidden;

  .${BEM_CLASS} {
    &__search {
      padding: 15px ${PADDING}px;
      background-color: ${colorPalette.ghostWhite};
    }
  }
`;
