import { Id } from 'backend-api/models';
import { ModelWithId } from 'common/components/selected-section';

export const notNullable = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;

export const moveItem = <T>(array: T[], oldIndex: number, newIndex: number): T[] => {
  const result = Array.from<T>(array);
  const [removed] = result.splice(oldIndex, 1);
  result.splice(newIndex, 0, removed);

  return result;
};

export const insertItem = <T>(array: T[], index: number, ...elementsArray): T[] => {
  const result = Array.from<T>(array);
  let normalizedIndex = index;

  if (index > result.length) {
    normalizedIndex = result.length;
  } else if (index < 0) {
    normalizedIndex = 0;
  }

  result.splice(normalizedIndex, 0, ...elementsArray);
  return result;
};

export const removeItemAt = <T>(array: T[], index: number): T[] => {
  const result = Array.from<T>(array);
  if (index > -1) {
    result.splice(index, 1);
  }
  return result;
};

export const toggleElementInArray = <T extends ModelWithId>(arr: T[], target: T): T[] => {
  const isTargetExists = arr.some(({ id }) => id === target.id);

  if (isTargetExists) {
    return [...arr].filter(({ id }) => id !== target.id);
  } else {
    return [...arr, target];
  }
};

export const toggleIdInArray = <T extends Id | string>(arr: T[], target: T): T[] => {
  const isTargetExists = arr.some(id => id === target);

  if (isTargetExists) {
    return [...arr].filter(id => id !== target);
  } else {
    return [...arr, target];
  }
};

export const getUniqueId = (): number => Math.floor(Math.random() * Date.now());
