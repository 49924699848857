import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps, useLocation } from 'react-router-dom';
import { AppContentLoader } from 'app/containers/app-content-loader';
import { tokenSelector } from 'login/selectors';

export const ProtectedRoute = React.memo(({ component, ...restProps }: RouteProps) => {
  const token = useSelector(tokenSelector);
  const currentPage = useLocation();

  const routeComponent = useCallback(
    (props: RouteComponentProps) => {
      if (!component) return null;
      if (!token) {
        return <Redirect to={{ pathname: '/login', state: { from: currentPage } }} />;
      }

      return <AppContentLoader>{React.createElement(component, props)}</AppContentLoader>;
    },
    [component, currentPage, token]
  );

  return <Route {...restProps} render={routeComponent} />;
});
