import React from 'react';
import { ContainerProps } from 'react-select';
import { getBaseCustomSelectProps } from 'common/components/select/transducers';
import { BaseOption as BaseOptionType } from '../../types';

type Props<T extends BaseOptionType, IsMulti extends boolean> = ContainerProps<T, IsMulti>;

const Root = <T extends BaseOptionType, IsMulti extends boolean>({
  children,
  innerProps,
  selectProps,
}: Props<T, IsMulti>) => {
  const { classNames, dataSelector } = getBaseCustomSelectProps(selectProps);

  return (
    <div
      {...innerProps}
      ref={null}
      className={classNames.selectContainer}
      data-selector={`${dataSelector}-select-container`}
    >
      {children}
    </div>
  );
};

export const SelectContainer = React.memo(Root) as typeof Root;
