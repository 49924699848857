import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'view-mode-controls';

export const SViewModeControls = styled.div`
  display: flex;
  gap: 10px;

  .${BEM_CLASS} {
    &__edit-button {
      border: 1px solid ${transparentize(0.6, manhattanThemeColors.primaryFillNew)};
    }
  }
`;
