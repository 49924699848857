import { BudgetGroup, BudgetGroupSchema, BudgetGroupCategory, Id } from 'backend-api/models';
import React, { useMemo } from 'react';
import { BudgetDetailsGridHeader, BudgetDetailsGridItem } from './components';
import { SBudgetDetailsGrid } from './s-budget-details-grid';

interface Props {
  items: (BudgetGroup | BudgetGroupCategory)[];
  onSelect?: (budgetGroupId: Id) => void;
  className?: string;
  dataSelector?: string;
  currencyCode?: string;
}

export const BudgetDetailsGrid = React.memo(({ className, items, onSelect, dataSelector, currencyCode }: Props) => {
  const headerTitle = useMemo(() => (BudgetGroupSchema.is(items[0]) ? 'Budget Groups' : 'Budget Categories'), [items]);

  return (
    <SBudgetDetailsGrid className={className}>
      <BudgetDetailsGridHeader
        title={headerTitle}
        data-selector={`${dataSelector}-title`}
        currencyCode={currencyCode}
      />
      {items.map(item => (
        <BudgetDetailsGridItem
          budgetInfo={item}
          onSelect={onSelect}
          key={item.id}
          data-selector={`${dataSelector}-value`}
        />
      ))}
    </SBudgetDetailsGrid>
  );
});
