import { ApiError } from 'backend-api';
import { ProjectDetails, ArtistMoment } from 'backend-api/models';
import { ProjectDetailsErrorResponse } from 'backend-api/types';
import { createTypedReducer, onAction } from 'core/store';

import {
  createMoment,
  deleteMoment,
  editMoment,
  getMoment,
  getMomentProject,
  resetMoment,
  showConfirmModal,
} from './actions';

interface ConfirmModalState {
  id?: string;
  isOpened: boolean;
}

export interface MomentState {
  activeMoment?: ArtistMoment;
  confirmModal: ConfirmModalState;
  project?: ProjectDetails;
  isLoading?: boolean;
  isProcessing?: boolean;
  error?: ApiError<ProjectDetailsErrorResponse>;
}

export const initialState: MomentState = {
  activeMoment: undefined,
  confirmModal: {
    id: undefined,
    isOpened: false,
  },
};

const processMoment = (state: MomentState) => ({
  ...state,
  isProcessing: true,
});

const processMomentSuccess = state => ({
  ...state,
  isProcessing: false,
});

const processMomentError = state => ({
  ...state,
  isProcessing: false,
});

export const reducer = createTypedReducer<MomentState>(
  initialState,
  onAction(getMoment.request, state => ({
    ...state,
    isLoading: true,
  })),
  onAction(getMoment.success, (state, action) => ({
    ...state,
    activeMoment: action.payload,
    isLoading: false,
  })),
  onAction(showConfirmModal, (state, action) => ({
    ...state,
    confirmModal: action.payload,
  })),
  onAction(resetMoment, state => ({
    ...state,
    activeMoment: undefined,
    project: undefined,
    error: undefined,
  })),
  onAction(editMoment.request, state => processMoment(state)),
  onAction(editMoment.success, state => processMomentSuccess(state)),
  onAction(editMoment.failure, state => processMomentError(state)),
  onAction(deleteMoment.request, state => processMoment(state)),
  onAction(deleteMoment.success, state => processMomentSuccess(state)),
  onAction(deleteMoment.failure, state => processMomentError(state)),
  onAction(createMoment.request, state => processMoment(state)),
  onAction(createMoment.success, state => processMomentSuccess(state)),
  onAction(createMoment.failure, state => processMomentError(state)),
  onAction(getMomentProject.success, (state, action) => ({
    ...state,
    project: action.payload,
  })),
  onAction(getMomentProject.failure, (state, action) => ({
    ...state,
    error: action.payload,
  }))
);
//†reducer
