import { ArtistTeam, CurrentUserInfo, TeamUser, UserRole, UserRoleType } from 'backend-api/models';
import { ROLES } from 'common-v2/constants';
import { LocalRole } from 'common-v2/types';

export const isMemberOfArtistTeam = (artistTeam?: ArtistTeam, user?: CurrentUserInfo): boolean => {
  if (!artistTeam || !user) {
    return false;
  }

  return artistTeam.users.some(artistTeamUser => artistTeamUser.id === user.id);
};

export const isArtistTeamEditor = (user: CurrentUserInfo, users: TeamUser[]) =>
  users.some(({ id, roles }) => includesRole(roles, ROLES.EDITOR) && id === user.id);

export const includesRole = (roles: UserRole[] = [], role: LocalRole): boolean =>
  roles.some(({ id }) => role.id === id);

export const includesOnlyApproverRole = (roles: UserRole[]): boolean =>
  includesRole(roles, ROLES.APPROVER) && roles.length === 1;

export const hasUserRole = (user: TeamUser, role: LocalRole): boolean => includesRole(user.roles, role);

export const filterUsersByRole = (users: TeamUser[], roleType: UserRoleType): TeamUser[] =>
  users.filter(user => user.roles.some(role => role.id === roleType));
