import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { GLOBAL_SEARCH_WIDTH } from 'common/constants';

export const BEM_CLASS = 'manhattan-search-field';

export const SSearchField = styled.div`
  max-width: ${GLOBAL_SEARCH_WIDTH}px;

  .${BEM_CLASS} {
    &__wrapper {
      display: flex;
      flex-grow: 1;
      align-items: center;
    }

    &__input {
      border: none;
      width: 100%;
      font-size: 12px;
      margin-left: 8px;
      background: transparent;

      ::placeholder {
        font-weight: 600;
        color: ${manhattanThemeColors.placeholderTextColor};
        text-transform: capitalize;
      }
    }

    &__button {
      flex-shrink: 0;
    }
  }
`;
