import React from 'react';
import { bem } from 'utils/bem';
import { Skeleton } from 'common/components/skeleton';
import { BEM_CLASS, SDroppableItemLoader } from './s-droppable-item-loader';

const classes = bem(BEM_CLASS);

export const DroppableItemLoader = React.memo(() => (
  <SDroppableItemLoader>
    <Skeleton className={classes('skeleton')} width={260} height={45}>
      <rect x="0" y="0" rx="0" ry="0" width="260" height="45" radius={5} />
    </Skeleton>
  </SDroppableItemLoader>
));
