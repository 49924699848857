import React, { useLayoutEffect, useState, useRef, useMemo } from 'react';
import { bem } from 'utils/bem';
import { Tooltip, TooltipProps } from '../tooltip';
import { STooltipedText, BEM_CLASS } from './s-tooltiped-text';

const classes = bem(BEM_CLASS);

interface TooltipedTextProps extends TooltipProps {
  text: string;
  children: React.ReactElement;
}

export const TooltipedText = React.memo(({ text, children, ...tooltipProps }: TooltipedTextProps) => {
  const [isTextOverflowed, setIsTextOverflowed] = useState(false);

  const containerRef = useRef<HTMLSpanElement>(null);
  const childRef = useRef<HTMLSpanElement>(null);

  const refferedChild = useMemo(() => {
    const classNames = `${children.props.className || ''} ${classes('child', { ellipsis: isTextOverflowed })}`;

    return React.cloneElement(children, {
      ref: childRef,
      className: classNames,
    });
  }, [children, isTextOverflowed]);

  useLayoutEffect(() => {
    const containerElement = containerRef?.current;
    const childElement = childRef?.current;

    if (!containerElement || !childElement) return;

    const containerElementWidth = containerElement.offsetWidth;
    const childElementWidth = childElement.scrollWidth;

    if (childElementWidth > containerElementWidth) {
      setIsTextOverflowed(true);
    }
  }, []);

  return (
    <Tooltip {...tooltipProps} content={text} enabled={isTextOverflowed}>
      <STooltipedText ref={containerRef}>{refferedChild}</STooltipedText>
    </Tooltip>
  );
});
