import styled from 'styled-components';
import { transparentize } from 'polished';
import { ellipsizeText } from 'common-v2/styles/ellipsize-text';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'user-role-dropdown';

export const SUserRoleDropdown = styled.div`
  width: 148px;
  background-color: ${manhattanThemeColors.background};
  box-shadow: 0px 5px 20px ${transparentize(0.8, manhattanThemeColors.primaryDark)};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  .${BEM_CLASS} {
    &__item {
      ${ellipsizeText}
      padding: 4.5px 15px 5.5px;
      cursor: pointer;

      &--selected {
        font-weight: 700;
      }

      &:hover {
        background-color: ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
      }
    }

    &__divider {
      height: 1px;
      margin: 0 15px;
      background-color: ${transparentize(0.9, manhattanThemeColors.primaryDark)};
    }
  }
`;
