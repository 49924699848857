import { LabelPermissionsArtist } from 'backend-api/models';
import { CELL_RIGHT_AND_LEFT_MARGIN, CHIP_WIDTH, CH_WIDTH, ARTIST_MARGIN } from './constants';

export const getArtists = (
  artists: LabelPermissionsArtist[],
  isExpanded: boolean,
  cellWidth: number
): LabelPermissionsArtist[] => {
  if (isExpanded) {
    return artists;
  }

  const processedArtists: LabelPermissionsArtist[] = [];
  let processedArtistsWidth = 0;

  artists.every((artist: LabelPermissionsArtist, index) => {
    const newArtistWidth = artist.name.length * CH_WIDTH + ARTIST_MARGIN;
    const nextWidth = processedArtistsWidth + newArtistWidth;

    const isLastArtist = artists.length - 1 === index;
    const availableWidth = cellWidth - CELL_RIGHT_AND_LEFT_MARGIN - (isLastArtist ? 0 : CHIP_WIDTH);

    if (availableWidth < nextWidth) {
      return false;
    }

    processedArtists.push(artist);
    processedArtistsWidth = nextWidth;

    return true;
  });

  return processedArtists;
};
