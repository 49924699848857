import { css } from 'styled-components';
import { theme as appTheme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';
import { TOP_HEADER_HEIGHT } from 'common/constants';
import { CHECKBOX_COLUMN_WIDTH, PAGE_PADDING } from 'media-plan/constants';
import { ACTION_BUTTON_BEM_CLASS } from './components';
import Checked from 'assets/Checked.svg';
import Unchecked from 'assets/Unchecked.svg';
import Indeterminate from 'assets/Indeterminate.svg';
import { BORDER_WIDTH } from 'media-plan/components/spreadsheet/components/expandable-field/constants';

interface Props {
  shouldShowGroupCheckbox?: boolean;
}

export const theme = css<Props>`
  .cell-error {
    --ag-input-focus-border-color: ${colorPalette.cinnabar};
  }

  &&& {
    .ag-overlay-wrapper {
      margin-top: 38px;
      background: white;
    }
    .ag-overlay-wrapper,
    .ag-react-container {
      height: 100%;
      width: 100%;
    }

    .ag-header-cell-label-align-right {
      .ag-header-cell-label {
        justify-content: flex-end;
      }
    }

    .ag-theme-alpine {
      --ag-foreground-color: ${colorPalette.vulcan};
      --ag-border-color: ${colorPalette.iron};
      --ag-input-focus-border-color: ${colorPalette.azureRadiance};
      --ag-range-selection-border-color: ${colorPalette.azureRadiance};
      --ag-row-border-color: ${colorPalette.iron};
      --ag-checkbox-background-color: ${colorPalette.white};
      --ag-range-selection-background-color: rgba(33, 150, 243, 0.2);

      height: calc(100vh - ${TOP_HEADER_HEIGHT + PAGE_PADDING * 2}px);
      font-family: Montserrat;
      font-size: 14px;

      .ag-header-cell {
        padding-left: var(--cell-horizontal-padding);
        padding-right: var(--cell-horizontal-padding);
        color: ${colorPalette.kimberley};
        font-size: 12px;
        font-weight: 700;
      }

      .ag-header-cell-resize {
        &::after {
          top: 0;
          height: 100%;
          background-color: ${colorPalette.iron};
        }
      }

      .ag-full-width-row {
        .ag-cell-wrapper.ag-row-group {
          padding-right: 0;
          padding-left: 0;
        }
      }

      .ag-group-expanded {
        display: none;
        .ag-row-selected {
          background-color: ${colorPalette.lavender};
          ::before {
            background: transparent;
          }
        }
      }

      .ag-row-group-expanded {
        background-color: ${colorPalette.lavender};
      }

      .ag-row.ag-row-selected {
        :not(.ag-row-group-expanded) {
          background: transparent;
        }
      }

      .ag-row-hover,
      .ag-row-selected {
        .${ACTION_BUTTON_BEM_CLASS}__action-button {
          visibility: visible;
        }
        .cell-disabled {
          background-color: transparent;
        }
      }

      .ag-cell {
        padding-left: 0;
        padding-right: 1px;
        line-height: unset;
        border-right: 1px solid ${colorPalette.iron};
      }

      .ag-cell-disabled {
        background-color: ${colorPalette.alabaster};
      }

      .ag-cell-focus {
        border: 1px solid ${colorPalette.azureRadiance};
      }

      .ag-cell-inline-editing {
        border-radius: 0;
        height: 100%;

        .ag-fill-handle {
          bottom: -2px;
          z-index: 1;
        }
      }

      .ag-fill-handle {
        bottom: 0;
        z-index: 1;
      }

      .ag-cell-range-selected.ag-cell-range-right {
        border-right: 1px solid ${colorPalette.azureRadiance};
      }

      .ag-cell-range-selected.ag-cell-range-single-cell {
        border-right: 1px solid ${colorPalette.azureRadiance};
      }

      .ag-cell-range-selected.cell-disabled {
        background-color: var(--ag-range-selection-background-color);
      }

      .ag-selection-fill-left {
        border-right: 1px dashed ${colorPalette.azureRadiance};
      }

      .ag-popup {
        height: auto;
      }

      .ag-menu {
        border: 0;
        border-radius: 10px;
        background-color: ${colorPalette.white};
        box-shadow: ${appTheme.shadows.default};
      }

      .ag-menu-list {
        padding: 15px 0;
      }

      .ag-menu-option-disabled {
        opacity: 1;
      }

      .ag-menu-option-text {
        padding: 4px 5px 6px 20px;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
      }

      .ag-menu-option-shortcut {
        text-align: right;
        padding: 4px 20px 6px 5px;
        font-size: 12px;
        color: ${colorPalette.osloGray};
      }

      .menu-options-group {
        .ag-menu-option-text {
          color: ${colorPalette.eastBay};
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 1px;
          text-transform: uppercase;
          padding-top: 20px;
          cursor: default;
        }
      }

      .menu-options-group-no-offset {
        .ag-menu-option-text {
          padding-top: 4px;
        }
      }

      .ag-menu-option-icon,
      .ag-menu-option-popup-pointer {
        display: none;
      }

      .not-resizable-header-cell {
        border-right: 1px solid ${colorPalette.iron};
      }

      .action-header-cell {
        padding: 0;
      }

      .action-cell {
        padding: 0;
      }

      .cell-error:hover {
        border: 1px solid ${colorPalette.cinnabar};
      }

      .cell-error::after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0;
        right: 0;
        border-bottom: 10px solid ${colorPalette.cinnabar};
        border-left: 10px solid transparent;
      }

      .ag-checkbox-input {
        cursor: pointer;
      }

      .ag-checkbox-input-wrapper.ag-checked::after {
        content: '';
        outline: none;
        width: 100%;
        height: 100%;
        background: transparent url(${Checked}) center/contain no-repeat;
      }
      .ag-checkbox-input-wrapper.ag-indeterminate::after {
        content: '';
        outline: none;
        width: 100%;
        height: 100%;
        background: transparent url(${Indeterminate}) center/contain no-repeat;
      }
      .ag-checkbox-input-wrapper {
        background: transparent;
        :active,
        :focus-within {
          box-shadow: unset;
        }
        ::after {
          content: '';
          outline: none;
          width: 100%;
          height: 100%;
          background: transparent url(${Unchecked}) center/contain no-repeat;
        }
      }
      .ag-selection-checkbox {
        width: ${CHECKBOX_COLUMN_WIDTH - BORDER_WIDTH * 2}px;
        margin-right: 0;
      }
      .ag-selection-checkbox.ag-invisible {
        display: none;
      }
      .ag-group-checkbox {
        display: ${({ shouldShowGroupCheckbox }) => (shouldShowGroupCheckbox ? 'flex' : 'none')};
        border-right: 1px solid var(--ag-border-color);

        .ag-selection-checkbox.ag-hidden {
          display: ${({ shouldShowGroupCheckbox }) => (shouldShowGroupCheckbox ? 'flex' : 'none')} !important;
          opacity: 0.5;
        }
      }
      .ag-checkbox {
        margin: 0 25%;
      }

      .ag-row-footer {
        .ag-checkbox {
          display: none;
        }
      }

      // NOTE: This will affect all sub-menus, we do not find another solution to match sub-menu
      .ag-menu[aria-label='SubMenu'] {
        .ag-menu-list {
          display: flex;
          padding: 10px 15px;
        }

        .color-menu-option {
          --ag-row-hover-color: transparent;

          .ag-menu-option-shortcut {
            display: none;
          }

          .ag-menu-option-text {
            padding: 5px;
          }
        }
      }
    }

    .cell-footer {
      font-weight: 600;
    }
  }
`;
