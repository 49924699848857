import React from 'react';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { UnclaimedProjectView } from 'project-v2/components';
import ShareAndConnectSrc from 'assets/share-and-connect.png';

export const UnclaimedProjectContactAdmin = React.memo(() => (
  <UnclaimedProjectView
    description={
      <Typography type={TYPOGRAPHY_TYPE.body2} data-selector="unclaimed-project-description">
        To start working on this project, please contact the project admin.
      </Typography>
    }
    imageSrc={ShareAndConnectSrc}
  />
));
