import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { SResultTable, BEM_CLASS } from './s-result-table';
import { ResultTableConfig } from './types';

interface ResultTableProps {
  config: ResultTableConfig[];
  data: any[];
}

const classes = bem(BEM_CLASS);

export const ResultTable = React.memo(({ config, data }: ResultTableProps) => {
  const content = useMemo(
    () =>
      data.map((item, index) =>
        config.map(({ render }) => (
          <div key={config.length + index} className={classes('content-cell')} data-selector="search-result-cell">
            {render(item)}
          </div>
        ))
      ),
    [config, data]
  );

  return (
    <SResultTable config={config}>
      <div className={classes('grid')}>
        {config.map(({ title }, index) => (
          <div key={index} className={classes('header-cell')}>
            {title}
          </div>
        ))}
        {content}
      </div>
    </SResultTable>
  );
});
