import { UserWithOptionalRoles } from './types';
import { includesRole } from 'common/transducers';
import { ROLES } from 'common-v2/constants';

const teamMembersComparer = <T extends UserWithOptionalRoles>(current: T, next: T) => {
  if (includesRole(next.roles, ROLES.EDITOR) && !includesRole(current.roles, ROLES.EDITOR)) {
    return 1;
  } else if (includesRole(current.roles, ROLES.EDITOR) && !includesRole(next.roles, ROLES.EDITOR)) {
    return -1;
  } else {
    return current.name.localeCompare(next.name);
  }
};

export const getTeamMembers = <T extends UserWithOptionalRoles>(members: T[]): T[] => {
  return members.sort(teamMembersComparer);
};
