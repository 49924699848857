import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon, ICON_SIZE, Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { ProjectCard, ProjectCardLoader } from 'projects-v2/components';
import { getNewProjects } from 'projects-v2/actions';
import { newProjectsSelector } from 'projects-v2/selectors';
import { Loading } from 'common-v2/types';
import { globalLabelSelector } from 'common/selectors';
import { CARD_SIZE } from './constants';
import { SNewProjectsContainer, BEM_CLASS } from './s-new-projects-container';
import { ALL_LABELS_ID } from 'app/components/app-header/constants';

const classes = bem(BEM_CLASS);

export const NewProjectsContainer = React.memo(() => {
  const dispatch = useDispatch();

  const { loading, data: projects } = useSelector(newProjectsSelector);
  const globalLabel = useSelector(globalLabelSelector);

  const content = useMemo(() => {
    switch (loading) {
      case Loading.Idle:
      case Loading.Started:
        return (
          <div className={classes('grid')}>
            {[0, 1, 2, 3].map(key => (
              <ProjectCardLoader key={key} isLoading size={CARD_SIZE} />
            ))}
          </div>
        );
      case Loading.Finished:
        return projects.length > 0 ? (
          <div className={classes('grid')} data-selector="new-projects-container-projects">
            {projects.map(project => (
              <ProjectCard key={project.id} size={CARD_SIZE} {...project} />
            ))}
          </div>
        ) : (
          <div className={classes('empty')} data-selector="new-projects-container-empty">
            <Typography type={TYPOGRAPHY_TYPE.body4}>There are currently no new projects from your artists.</Typography>
          </div>
        );
      case Loading.Failed:
        return (
          <div className={classes('empty')}>
            <Typography type={TYPOGRAPHY_TYPE.body4}>
              System failed to load new projects from artists. Please reload the page.
            </Typography>
          </div>
        );
    }
  }, [loading, projects]);

  useEffect(() => {
    if (!globalLabel) return;
    dispatch(getNewProjects.request({ label: globalLabel.id === ALL_LABELS_ID ? undefined : globalLabel.id }));
  }, [dispatch, globalLabel]);

  return (
    <SNewProjectsContainer>
      <div className={classes('title')}>
        <Icon className={classes('title-icon')} name="stack-plus" size={ICON_SIZE.medium} />
        <Typography type={TYPOGRAPHY_TYPE.heading2} data-selector="new-projects-container-title">
          New Projects from My Artists
        </Typography>
      </div>
      {content}
    </SNewProjectsContainer>
  );
});
