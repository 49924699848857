import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'media-plan-warning-modal';

export const SMediaPlanWarningModal = styled.div`
  display: flex;
  flex-direction: column;

  .${BEM_CLASS} {
    &__title {
      padding: 20px 30px;
    }
    &__text {
      width: 100%;
      margin: 30px 0 50px;
      padding: 0 15px;
      line-height: 20px;
      text-align: center;
    }

    &__footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-top: 1px solid ${colorPalette.snuff};
    }
    &__button {
      width: 162px;
    }
  }
`;
