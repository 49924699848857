import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import styled from 'styled-components';

export const BEM_CLASS = 'suggestions';

interface SSuggestionsProps {
  width: number;
}

export const SSuggestions = styled.div<SSuggestionsProps>`
  width: ${({ width }) => width}px;
  max-height: 300px;
  background-color: ${manhattanThemeColors.background};
  border-radius: 10px;
  padding: 20px 26px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 5px 20px ${transparentize(0.8, colorPalette.woodsmoke)};

  .${BEM_CLASS} {
    &__empty {
      width: 100%;
      padding: 105px 0;
      text-align: center;
      display: flex;
      justify-content: center;
      color: ${transparentize(0.3, manhattanThemeColors.textHeaderColor)};
    }
  }
`;
