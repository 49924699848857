import React, { useMemo } from 'react';
import { SLegend } from './s-legend';
import { LegendItem } from './components';
import { colorPalette } from 'app/theme/colors';
import { getTextWithCurrencyCode } from 'utils/format';

interface LegendProps {
  currencyCode?: string;
}

export const Legend = React.memo(({ currencyCode }: LegendProps) => {
  const total = useMemo(() => getTextWithCurrencyCode('Total', currencyCode), [currencyCode]);
  const remaining = useMemo(() => getTextWithCurrencyCode('Remaining', currencyCode), [currencyCode]);

  return (
    <SLegend>
      <LegendItem color={colorPalette.cottonCandy} title={total} />
      <LegendItem color={colorPalette.athensGray} title={remaining} />
    </SLegend>
  );
});
