import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ProjectFilterParams } from 'backend-api/types';
import { UNASSIGNED_STATUS } from 'common/constants';
import { getProjects, getUnassignedProjects } from 'artist/actions';

export const useProjectsRequest = (artistId: string, filterParams: ProjectFilterParams): any => {
  const dispatch = useDispatch();

  const getProjectRequest = useCallback(() => {
    if (filterParams.statuses?.includes(UNASSIGNED_STATUS.id)) {
      dispatch(
        getUnassignedProjects.request({
          id: artistId,
          params: {
            ...filterParams,
            statuses: undefined,
          },
        })
      );
    } else {
      dispatch(getProjects.request({ id: artistId, params: filterParams }));
    }
  }, [artistId, dispatch, filterParams]);

  return getProjectRequest;
};
