import { Loading } from 'common-v2/types';
import { EmptyViewMode } from './types';

interface GetEmptyViewModeParams {
  loading: Loading;
  isDataExist: boolean;
  isSearchActive: boolean;
}

export const getEmptyViewMode = ({ loading, isDataExist, isSearchActive }: GetEmptyViewModeParams): EmptyViewMode => {
  switch (loading) {
    case Loading.Idle:
    case Loading.Started:
      return EmptyViewMode.Loading;
    case Loading.Finished:
      if (isDataExist) return EmptyViewMode.None;

      if (isSearchActive) return EmptyViewMode.Search;

      return EmptyViewMode.Default;
    case Loading.Failed:
      return EmptyViewMode.Error;
  }
};
