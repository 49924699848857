import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterDatesFormatter } from 'utils/format';
import { activeFiltersNameSelector, activeFiltersSelector } from '../selectors';
import { prepareDatesForRequest } from '../transducers';
import { setFilters } from '../actions';

export const useSidebarFilters = () => {
  const dispatch = useDispatch();

  const activeFiltersName = useSelector(activeFiltersNameSelector);
  const filters = useSelector(activeFiltersSelector);

  const setFilter = useCallback(
    query => {
      const dates = prepareDatesForRequest(query, filterDatesFormatter);
      dispatch(setFilters({ ...query, ...dates }));
    },
    [dispatch]
  );

  const updateFilter = useCallback(
    patch => {
      const mergedQuery = { ...filters, ...patch };
      setFilter(mergedQuery);
    },
    [filters, setFilter]
  );

  return {
    activeFiltersName,
    filters,
    setFilter,
    updateFilter,
  };
};
