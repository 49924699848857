import styled from 'styled-components';

export const BEM_CLASS = 'role-renderer';

export const SRoleRenderer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;
