import styled from 'styled-components';
import { PrimaryLabel } from 'common/components/typography';
import { colorPalette } from 'app/theme/colors';

export const SDaysLeftBadge = styled(PrimaryLabel)`
  font-weight: bold;
  color: ${colorPalette.white};
  height: 15px;
  border-radius: 2px;
  background-color: ${colorPalette.christine};
  text-transform: uppercase;
  padding: 0 5px;
`;
