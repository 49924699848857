import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { StateObservable } from 'redux-observable';
import { PayloadAction } from 'typesafe-actions';
import {
  getShareProjectSuccessMessage,
  prepareRequest,
  prepareShareRequestUser,
  prepareShareRequestDeleteUser,
} from './transducers';
import { usaDollarIdSelector } from 'common/selectors';
import { navigateTo } from 'utils/navigation';
import { showSuccessToast, showToast, ToastType } from 'common/components/toast';
import { paths } from 'app/routing/paths';
import { asyncEpic, ofType } from 'core/epics';
import { Api } from 'backend-api';
import {
  claimUnassignedProject,
  closeShareWindow,
  deleteProject,
  editProject,
  getBudgetGroupsForProjectAction,
  getFunnelGraphData,
  getNotesHistoryAction,
  getProject,
  getProjectHistoryAction,
  getProjectMarketingMixAction,
  getProjectTeamUsersInfo,
  getTeamCountersAction,
  getUnassignedProjectCampaigns,
  resetProject,
  searchArtist,
  searchPlaylists,
  shareProject,
  undoDeleteProject,
} from './actions';
import { ProjectState } from './reducer';
import { AnalyticsEvents, increaseUserCounter, trackEvent, UserCounters } from 'utils/analytic';
import { getCampaignGroupNameById, getRoleNameById } from 'common/transducers';
import { getProjectArtistTeam } from 'common/actions';
import { isRequestRestricted } from 'common-v2/transducers';
import { openPermissionErrorModal } from 'modals/permission-error-modal';
import { notNullable } from 'utils/data';

export const getProjectEpic = asyncEpic(
  getProject,
  action => {
    const { projectId, params } = action.payload;
    return Api.getProject(projectId, params);
  },
  undefined,
  undefined,
  resetProject
);

export const editProjectEpic = asyncEpic(
  editProject,
  ({ payload: { projectId, data, changedFields } }, state) => {
    const currencyId = usaDollarIdSelector(state);
    if (typeof currencyId !== 'number') {
      return throwError('Could not find such currency');
    }

    const params = prepareRequest(data);
    return Api.editProject(projectId, params).pipe(
      tap(() => {
        navigateTo(paths.project(projectId));
      }),
      tap(() => increaseUserCounter(UserCounters.PROJECTS_EDITED, 1)),
      tap(() =>
        trackEvent(AnalyticsEvents.PROJECT_EDITED, { project_id: projectId, project_fields_edited: changedFields })
      )
    );
  },
  error => {
    if (isRequestRestricted(error)) {
      return of(openPermissionErrorModal(error), editProject.failure(error));
    }

    return of(editProject.failure(error));
  }
);

export const claimUnassignedProjectEpic = asyncEpic(
  claimUnassignedProject,
  ({ payload: { data, projectId } }, state) => {
    const currencyId = usaDollarIdSelector(state);

    if (typeof currencyId !== 'number') {
      return throwError('Could not find such currency');
    }

    const params = prepareRequest(data);

    return Api.claimUnassignedProject(projectId, params).pipe(
      tap(result => navigateTo(paths.project(result.id))),
      tap(() => increaseUserCounter(UserCounters.PROJECTS_CLAIMED, 1)),
      tap(response => trackEvent(AnalyticsEvents.PROJECT_CLAIMED, { project_id: response.id }))
    );
  }
);

export const deleteProjectEpic = asyncEpic(deleteProject, action => {
  const { projectId, onUndo } = action.payload;

  return Api.deleteProject(projectId).pipe(
    tap(() => navigateTo(paths.projects())),
    tap(() =>
      showToast({
        id: `project-${projectId}-removed-toast`,
        type: ToastType.Success,
        message: 'The project was deleted successfully.',
        onUndo,
      })
    )
  );
});

export const getProjectUsersEpic = asyncEpic(getProjectTeamUsersInfo, action =>
  forkJoin([Api.getProjectUsers(action.payload), Api.getTeam(action.payload)]).pipe(
    map(([projectUsers, teamUsers]) => ({
      projectUsers,
      teamUsers,
    }))
  )
);

export const shareProjectEpic = (action$: Observable<any>, state$: StateObservable<any>) =>
  action$.pipe(
    ofType(shareProject.request),
    switchMap(
      ({
        payload: {
          shareInfo: { added, updated, deleted },
          projectId,
          closeShareProjectModal,
        },
      }) => {
        const state = state$.value.project as ProjectState;

        const addedRequest = added.map(prepareShareRequestUser).filter(notNullable);
        const deletedRequest = deleted.map(prepareShareRequestDeleteUser).filter(notNullable);
        const updatedRequest = updated.map(prepareShareRequestUser).filter(notNullable);

        return Api.changeProjectTeam(projectId, {
          added: addedRequest,
          deleted: deletedRequest,
          updated: updatedRequest,
        }).pipe(
          tap(() => showSuccessToast(getShareProjectSuccessMessage(addedRequest, deletedRequest, updatedRequest))),
          tap(() => {
            addedRequest.forEach(member =>
              trackEvent(AnalyticsEvents.TEAMMATE_ADDED, {
                project_id: projectId,
                member_id: member.userId,
                member_role: getRoleNameById(member.roleId),
                member_permissions: member.categoryIds.map(id => getCampaignGroupNameById(id)),
              })
            );
          }),
          tap(() => {
            deletedRequest.forEach(member =>
              trackEvent(AnalyticsEvents.TEAMMATE_REMOVED, {
                project_id: projectId,
                member_id: member.userId,
                member_role: getRoleNameById(member.roleId),
                member_permissions: member.categoryIds.map(id => getCampaignGroupNameById(id)),
              })
            );
          }),
          mergeMap(response => {
            const actions: PayloadAction<string, unknown>[] = [
              shareProject.success(response),
              getProject.request({ projectId }),
              closeShareWindow(),
            ];
            if (state.activeProject && !state.activeProject.isClaimed) {
              actions.push(getTeamCountersAction.request(state.activeProject.id));
            }
            return actions;
          }),
          catchError(error => {
            if (isRequestRestricted(error)) {
              closeShareProjectModal();
              return of(openPermissionErrorModal(error), shareProject.failure(error));
            }

            return of(shareProject.failure("Changes couldn't be saved. Try to save them later."));
          })
        );
      }
    )
  );

export const undoDeleteProjectEpic = asyncEpic(undoDeleteProject, action => Api.undoDeleteProject(action.payload));

export const getProjectHistoryEpic = asyncEpic(getProjectHistoryAction, ({ payload: { id, isUnassigned } }) =>
  Api.getProjectHistory(id, isUnassigned, { limit: 5 }).pipe(map(history => history.items))
);

export const getTeamCountersEpic = asyncEpic(getTeamCountersAction, action => Api.getTeamCounters(action.payload));

export const getProjectMarketingMixEpic = asyncEpic(getProjectMarketingMixAction, action =>
  Api.getProjectMarketingMix(action.payload).pipe(catchError(() => of([])))
);

export const getNotesHistoryEpic = asyncEpic(getNotesHistoryAction, action =>
  Api.getNotesHistory(action.payload).pipe(map(notesHistory => notesHistory.history))
);

export const getProjectArtistTeamEpic = asyncEpic(getProjectArtistTeam, action =>
  Api.getProjectArtistTeam(action.payload).pipe(catchError(() => of(undefined)))
);

export const searchArtistEpic = asyncEpic(searchArtist, action => Api.searchArtist(action.payload));
export const searchPlaylistsEpic = asyncEpic(searchPlaylists, action => Api.searchPlaylists(action.payload));

export const getBudgetGroupsForProjectEpic = asyncEpic(
  getBudgetGroupsForProjectAction,
  action => Api.getBudgetGroupsForProject(action.payload),
  undefined,
  undefined,
  resetProject
);

export const getUnassignedProjectCampaignsEpic = asyncEpic(getUnassignedProjectCampaigns, action =>
  Api.getCampaigns(action.payload, { limit: 2000 }, true).pipe(map(item => item.items))
);

export const getFunnelGraphDataEpic = asyncEpic(getFunnelGraphData, action => {
  const { projectId, ...params } = action.payload;

  return Api.getProjectsPerformanceBreakdown(projectId, { ...params, breakdown: 5 }).pipe(
    map(performance => performance.items)
  );
});
