import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { ACTION_BADGE_HEIGHT } from './constants';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'action-badge';

export const SActionBadge = styled.button`
  height: ${ACTION_BADGE_HEIGHT}px;
  border-radius: ${ACTION_BADGE_HEIGHT / 2}px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  overflow: hidden;
  background-color: ${palette.athensGrayTwo};

  .${BEM_CLASS} {
    &__text {
      font-size: 10px;
      line-height: 15px;
      max-width: 800px;
      ${elipsizeText}
    }
    &__icon {
      color: ${palette.lavanderGray};
      margin-left: 10px;
    }
  }

  &:hover {
    background-color: ${palette.athensGrayThree};

    .${BEM_CLASS}__icon {
      color: ${palette.black};
    }
  }
`;
