import styled, { css } from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { bgStyle, BgStyle, inputBorderColor } from 'common/styles/utils';

export interface SInputProps {
  readonly bgStyle?: BgStyle;
  readonly hasIconLeft?: boolean;
  readonly disabled?: boolean;
  readonly isRequired?: boolean;
  readonly isInvalid?: boolean;
}

export const SInput = css<SInputProps>`
  display: block;
  width: 100%;
  border: 1px solid ${inputBorderColor()};
  border-radius: 20px;
  line-height: 21px;
  outline: none;
  font-size: 14px;
  background: ${bgStyle};
  color: ${({ disabled, theme }) => (disabled ? theme.colors.input.text.disabled : theme.colors.input.text.regular)};
  padding: 6px 37px 6px ${({ hasIconLeft }) => (hasIconLeft ? '38px' : '20px')};

  &:focus {
    border: 1px solid ${inputBorderColor(true)};
    background-color: ${({ theme }) => theme.colors.input.background.light};
  }

  &:focus-within {
    border: 1px solid ${inputBorderColor(true)};
    background-color: ${({ theme }) => theme.colors.input.background.light};
  }

  &:disabled {
    background-color: ${palette.lightTwo};
  }

  &::placeholder {
    color: ${({ disabled, theme }) =>
      disabled ? theme.colors.input.placeholder.disabled : theme.colors.input.placeholder.regular};
  }

  &--focus {
    border: 1px solid ${inputBorderColor(true)};
    background-color: ${({ theme }) => theme.colors.input.background.light};
  }
`;

export const SInputWrapper = styled.div`
  font-size: 14px;

  .input {
    &__wrapper {
      position: relative;
    }

    &__close {
      position: absolute;
      top: 5px;
      right: 9px;
      cursor: pointer;
    }

    &__icon {
      position: absolute;
      left: 9px;
      top: 5px;
    }
  }

  input {
    ${SInput};
  }
`;
