import { ProjectMetrics } from 'backend-api/models';
import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { formatNumeral, formatShortenNumeral, getShortCurrencyFormatted } from 'utils/format';
import { BEM_CLASS, SSummaryMetrics } from './s-summary-metrics';
import { SummaryMetricLoader } from 'common/components/loaders/summary-metric-loader';

const classes = bem(BEM_CLASS);

interface SummaryMetricsProps {
  projectMetrics?: ProjectMetrics;
  isLoading?: boolean;
  currencyCode?: string;
}

export const SummaryMetrics = ({ projectMetrics, currencyCode, isLoading }: SummaryMetricsProps) => {
  const renderContentWithLoader = useCallback(
    (content: React.ReactElement) => (!isLoading ? content : <SummaryMetricLoader />),
    [isLoading]
  );

  return (
    <SSummaryMetrics>
      <div className={classes('metric-item')}>
        <span className={classes('label')}>planned budget:</span>
        {renderContentWithLoader(
          <b data-selector="summary-metrics-planned-budget">
            {getShortCurrencyFormatted(projectMetrics?.plannedBudget, currencyCode)}
          </b>
        )}
      </div>
      <div className={classes('metric-item')}>
        <span className={classes('label')}>platforms:</span>
        {renderContentWithLoader(<b data-selector="summary-metrics-platforms">{projectMetrics?.platformsCount}</b>)}
      </div>
      <div className={classes('metric-item')}>
        <span className={classes('label')}>est. impressions:</span>
        {renderContentWithLoader(
          <b data-selector="summary-metrics-est-impressions">{formatNumeral(projectMetrics?.estimatedImpressions)}</b>
        )}
      </div>
      <div className={classes('metric-item')}>
        <span className={classes('label')}>campaigns:</span>
        {renderContentWithLoader(
          <b data-selector="summary-metrics-campaigns">{formatShortenNumeral(projectMetrics?.campaignsCount)}</b>
        )}
      </div>
    </SSummaryMetrics>
  );
};
