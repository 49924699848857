import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Id } from 'backend-api/models';
import { GridApi, RowNode } from 'ag-grid-community';
import { deletePhase as deletePhaseAction, getMediaPlan } from 'media-plan-v2/actions';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { useManageToasts, THEME } from 'gdb-web-shared-components';

export const useDeletePhase = (projectId: Id, mediaPlanId: Id) => {
  const dispatch = useDispatch();
  const { openToast } = useManageToasts(THEME.light);

  const deletePhaseCallback = useCallback(
    (phaseId: Id, onError: () => void) => {
      dispatch(
        deletePhaseAction.request({
          mediaPlanId,
          projectId,
          phaseId,
          onError,
          openToast,
        })
      );
    },
    [dispatch, mediaPlanId, openToast, projectId]
  );

  const deletePhase = useCallback(
    (api: GridApi, node?: RowNode | null) => {
      if (!node || !node.group) return;

      const campaigns: SpreadsheetRowData[] = node.allLeafChildren.map(({ data }) => data);

      if (campaigns.length === 0) return;

      const { phaseId, phaseOrder } = campaigns[0];

      const updateTransaction: SpreadsheetRowData[] = [];

      api.forEachNode(({ group, data }) => {
        if (group || !data) return;

        if (data.phaseOrder.value > phaseOrder.value) {
          updateTransaction.push({ ...data, phaseOrder: { ...data.phaseOrder, value: data.phaseOrder.value - 1 } });
        }
      });

      api.applyTransaction({
        update: updateTransaction,
        remove: campaigns,
      });

      api.clearRangeSelection();

      deletePhaseCallback(phaseId.value, () => {
        dispatch(getMediaPlan.request({ projectId, mediaPlanId }));
      });
    },
    [deletePhaseCallback, dispatch, mediaPlanId, projectId]
  );

  return deletePhase;
};
