import { Axios } from 'axios-observable';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, flatMap, map, mergeMap, retryWhen } from 'rxjs/operators';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import { isRight } from 'fp-ts/lib/Either';
import ExtendableError from 'es6-error';

type QueryParams = Record<string, any>;
type Headers = Record<string, any>;

type Schema = t.Any;

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const DEFAULT_RETRY_COUNT = 3;

const IS_ENV_FOR_TESTING =
  !process.env.ENV_PREFIX || process.env.ENV_PREFIX === 'dev' || process.env.ENV_PREFIX === 'qa';

interface RequestParams<I extends Schema, O extends Schema> {
  readonly endpoint: string;
  readonly method: HttpMethod;
  readonly headers?: Headers;
  readonly queryParams?: QueryParams;
  readonly payload?: t.TypeOf<I>;
  readonly requestSchema?: I;
  readonly responseSchema: O;
  readonly retries?: number;
}

interface RequestParamsWithRequiredBody<M extends HttpMethod, I extends Schema, O extends Schema>
  extends RequestParams<I, O> {
  readonly method: M;
  readonly requestSchema: I;
  readonly payload: t.TypeOf<I>;
}

type RequestParamsWithoutBody<M extends HttpMethod, O extends Schema> = { readonly method: M } & Omit<
  RequestParams<any, O>,
  'payload' | 'requestSchema'
>;

type GetRequestParams<O extends Schema> = RequestParamsWithoutBody<'GET', O>;
type PostRequestParams<I extends Schema, O extends Schema> = RequestParamsWithRequiredBody<'POST', I, O>;
type PutRequestParams<I extends Schema, O extends Schema> = RequestParamsWithRequiredBody<'PUT', I, O>;
type DeleteRequestParams<I extends Schema, O extends Schema> = RequestParamsWithRequiredBody<'DELETE', I, O>;
type PatchRequestParams<I extends Schema, O extends Schema> = RequestParamsWithRequiredBody<'PATCH', I, O>;

const isRequestWithBody = <I extends Schema, O extends Schema>(
  params: RequestParams<I, O>
): params is RequestParamsWithRequiredBody<any, I, O> => {
  return params.method == 'POST' || params.method == 'PUT' || params.method == 'PATCH' || params.method == 'DELETE';
};

type Params<I extends Schema, O extends Schema> =
  | GetRequestParams<O>
  | PostRequestParams<I, O>
  | PutRequestParams<I, O>
  | DeleteRequestParams<I, O>
  | PatchRequestParams<I, O>;

type BinaryGetRequestParams = { readonly method: 'GET' } & Omit<
  RequestParams<any, any>,
  'payload' | 'requestSchema' | 'responseSchema'
>;

type HttpRequestParams = {
  readonly endpoint: string;
  readonly method: HttpMethod;
  readonly responseType?: ResponseType;
  readonly queryParams?: QueryParams;
  readonly payload?: any;
  readonly headers?: Headers;
  readonly retries: number;
};

type ApiClientConfig = {
  readonly tokenProvider: (config: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
  readonly unauthorize: (error?: any) => void;
  readonly default: AxiosRequestConfig;
};

interface RetryStrategyParams {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: number[];
}

class ResponseDecodingError extends Error {
  public schema: Schema;
  public decodingErrors: ReadonlyArray<string>;

  constructor(schema: Schema, errors: string[]) {
    let message = 'Failed to decode response';

    if (IS_ENV_FOR_TESTING) {
      message += ` for ${schema.name}\n\n${JSON.stringify(errors, undefined, 4)}`;
    }

    super(message);
    this.name = 'ResponseDecodingError';
    this.schema = schema;
    this.decodingErrors = errors;
  }
}

export interface ServerErrorResponse<T = string> {
  code: string;
  detail: T;
  extra?: Record<string, any>;
}

export class ApiError<T = string> extends ExtendableError {
  public requestConfig?: AxiosRequestConfig;
  public statusCode?: number;
  public data?: ServerErrorResponse<T>;
  public isCancelled?: boolean;

  constructor(error: AxiosError<ServerErrorResponse<T>>) {
    let message = error.message || 'Api Error';

    if (!error.isAxiosError || axios.isCancel(error)) {
      super(message);

      this.isCancelled = true;
      return;
    }

    const requestConfig = error.config;
    const statusCode = error.response?.status;
    const data = error.response?.data;

    if (requestConfig && IS_ENV_FOR_TESTING) {
      message +=
        `\n\nMethod: ${requestConfig.method?.toUpperCase()}` +
        `\n\nPath: ${requestConfig.url}` +
        `\n\nBody: ${JSON.stringify(data, undefined, 4)}`;
    }

    super(message);

    this.requestConfig = requestConfig;
    this.statusCode = statusCode;
    this.data = data;
  }
}

export class ApiClient {
  private readonly httpClient: Axios;
  public unauthorize: (error?: any) => void;

  constructor(config: ApiClientConfig) {
    this.unauthorize = config.unauthorize;
    this.httpClient = Axios.create(config.default);

    this.httpClient.interceptors.request.use(config.tokenProvider, err => Promise.reject(err));
  }

  protected executeHttpRequest<R extends unknown>(requestParams: HttpRequestParams): Observable<AxiosResponse<R>> {
    return this.httpClient
      .request<R>({
        url: requestParams.endpoint,
        method: requestParams.method,
        responseType: requestParams.responseType || 'json',
        headers: requestParams.headers,
        params: requestParams.queryParams,
        data: requestParams.payload,
      })
      .pipe(
        retryWhen(
          this.genericRetryStrategy({ maxRetryAttempts: requestParams.retries, excludedStatusCodes: [403, 404] })
        )
      );
  }

  protected encodeSchema<I extends Schema>(schema: I, input: t.TypeOf<I>): t.TypeOf<I> {
    return schema.encode(input as any);
  }

  protected encodePayload<I extends Schema, O extends Schema>(params: RequestParams<I, O>): t.TypeOf<I> | null {
    if (isRequestWithBody(params)) {
      return this.encodeSchema(params.requestSchema, params.payload);
    } else {
      return null;
    }
  }

  protected decodePayload<O extends Schema>(schema: O, response: unknown): Observable<t.TypeOf<O>> {
    const result = schema.decode(response);
    if (isRight(result)) {
      return of(result.right);
    } else {
      const responseDecodingError = new ResponseDecodingError(schema, PathReporter.report(result));
      // eslint-disable-next-line no-console
      console.error('Decoding Error', responseDecodingError);
      return throwError(responseDecodingError);
    }
  }

  protected onError<T>(error: AxiosError<ServerErrorResponse<T>>): Promise<T> {
    const apiError = new ApiError(error);
    const statusCode = apiError.statusCode || 0;

    if (statusCode === 401) {
      this.unauthorize();
    }

    return Promise.reject(apiError);
  }

  public executeRequest<I extends Schema, O extends Schema>(params: Params<I, O>): Observable<t.TypeOf<O>> {
    const requestParams = params as RequestParams<I, O>;

    return this.executeHttpRequest({
      endpoint: requestParams.endpoint,
      method: requestParams.method,
      queryParams: requestParams.queryParams,
      payload: this.encodePayload(requestParams),
      headers: requestParams.headers,
      retries: requestParams.retries !== undefined ? requestParams.retries : DEFAULT_RETRY_COUNT,
    }).pipe(
      flatMap(response => {
        return this.decodePayload(requestParams.responseSchema, response.data);
      }),
      catchError(err => this.onError(err))
    );
  }

  public executeBinaryRequest(params: BinaryGetRequestParams): Observable<Blob> {
    const requestParams = params as RequestParams<any, any>;

    return this.executeHttpRequest<Blob>({
      endpoint: requestParams.endpoint,
      method: requestParams.method,
      responseType: 'blob',
      queryParams: requestParams.queryParams,
      headers: requestParams.headers,
      retries: requestParams.retries !== undefined ? requestParams.retries : DEFAULT_RETRY_COUNT,
    }).pipe(
      map(response => response.data),
      catchError(err => this.onError<Blob>(err))
    );
  }

  genericRetryStrategy = ({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = [],
  }: RetryStrategyParams) => (attempts: Observable<any>): Observable<number> => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const apiError = new ApiError(error);
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find(e => e === apiError.statusCode)) {
          return throwError(error);
        }
        // retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration);
      })
    );
  };
}
