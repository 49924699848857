import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { ActionRenderer, SingleValueView } from 'media-plan-v2/components/spreadsheet/components';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';

export const KpiRenderer = ({
  value: { color, value },
  context,
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['kpi']>) => (
  <ActionRenderer color={color} accessMode={context.accessMode} placeholder="Select or type">
    {value?.value && <SingleValueView value={value.value} />}
  </ActionRenderer>
);
