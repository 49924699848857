import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { bem } from 'utils/bem';
import { SimpleOption, UNASSIGNED_STATUS } from 'common/constants';
import { SAllProjectsTabHeader } from '../s-components/s-all-projects-tab-header';
import { RadioGroup } from 'common/components/form/radio-group';
import { Input } from 'common/components/form/input';
import { SSpacer } from 'common/s-components/layout/s-spacer';
import { Button } from 'common/components/button';
import { Icon } from 'common/components/icon';
import { exportAllProjectsCsv } from '../actions';
import { useActiveTab } from '../hooks';
import { DatePicker, DatePickerMode } from 'common/components/form/date-picker';
import { OptionalPeriod } from 'common/types';

interface Props {
  targetOptions: SimpleOption[];
  targetValue: number;
  onChangeTargetValue(value: number): void;
  searchValue?: string;
  onChangeSearchValue(value: string): void;
  disabled?: boolean;
  exportDisabled?: boolean;
  className?: string;
  gradientEnabled?: boolean;
  periodValue?: OptionalPeriod;
  onPeriodChange?(period: OptionalPeriod): void;
}

const classes = bem('all-projects-tab-header');

export const AllProjectsTabHeader = React.memo(
  ({
    targetOptions,
    targetValue,
    onChangeTargetValue,
    searchValue,
    onChangeSearchValue,
    disabled,
    gradientEnabled,
    exportDisabled,
    periodValue,
    onPeriodChange,
    className,
  }: Props) => {
    const dispatch = useDispatch();
    const [activeTab] = useActiveTab();
    const isUnassignedTab = activeTab === UNASSIGNED_STATUS.name;

    const onExportClick = useCallback(() => {
      dispatch(exportAllProjectsCsv.request());
    }, [dispatch]);

    const bgStyle = gradientEnabled ? 'white' : 'grey';
    return (
      <SAllProjectsTabHeader className={className}>
        <RadioGroup
          options={targetOptions}
          value={targetValue}
          onChange={onChangeTargetValue}
          bgStyle={bgStyle}
          disabled={isUnassignedTab}
          dataSelector="all-projects-type-toggle"
        />
        <Input
          bgStyle={bgStyle}
          iconLeft="search"
          clearable
          disabled={disabled}
          placeholder="Search artists or projects"
          dataSelector="search-projects-input"
          className={classes('search')}
          value={searchValue}
          onChange={onChangeSearchValue}
        />
        <div className={classes('right-content')}>
          <DatePicker
            className={classes('date-picker')}
            value={periodValue}
            mode={DatePickerMode.RANGE}
            onChange={onPeriodChange}
            bgStyle={gradientEnabled ? 'white' : 'grey'}
          />
          <SSpacer width={20} />
          <Button
            disabled={disabled || exportDisabled}
            variant="secondary-cta"
            dataSelector="in-progress-export-button"
            onClick={onExportClick}
          >
            <Icon name="export" />
            Export CSV
          </Button>
        </div>
      </SAllProjectsTabHeader>
    );
  }
);
