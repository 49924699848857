import React from 'react';
import { bem } from 'utils/bem';
import { colorPalette } from 'app/theme/colors';
import { WaveBackground } from 'common/components/wave-background';
import { Loader } from 'common/components/loader';
import Logo from 'assets/decibel-logo-big.raw.svg';
import { SLogin, BEM_CLASS } from './s-login';

interface LoginProps {
  isLoading: boolean;
  onClick(): void;
}

const classes = bem(BEM_CLASS);

export const Login = React.memo(({ isLoading, onClick }: LoginProps) => {
  return (
    <SLogin>
      <WaveBackground className={classes('container')}>
        <div className={classes('logo')}>
          <Logo />
        </div>
        <button className={classes('button', { loading: isLoading })} onClick={onClick} data-selector="login-button">
          {isLoading ? <Loader size={22} color={colorPalette.white} isActive /> : 'Sign in'}
        </button>
      </WaveBackground>
    </SLogin>
  );
});
