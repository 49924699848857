export * from './permission';
export * from './project';
export * from './label';
export * from './treatments';
export * from './format';
export * from './common';

export const getJoinedNames = <T extends { name?: string }>(items: T[]): string =>
  items
    .filter(({ name }) => Boolean(name))
    .map(({ name }) => name)
    .join(', ');
