import { useCallback } from 'react';
import { ITabData } from 'gdb-web-shared-components';
import { ProjectDetails } from 'backend-api/models';
import { ProjectTab } from 'common-v2/types';
import { PROJECT_TABS, FEATURE_FLAG_BY_TAB, UNCLAIMED_PROJECT_RESTRICTED_TABS } from 'common-v2/constants';
import { useFeatureFlagsContext } from 'common-v2/hooks';

export const useGetTabs = (project?: ProjectDetails): ITabData<ProjectTab>[] => {
  const { treatments } = useFeatureFlagsContext();

  const getIsTabVisible = useCallback(
    (tab: ProjectTab) => {
      const featureFlag = FEATURE_FLAG_BY_TAB[tab];

      return !featureFlag || treatments[featureFlag]?.treatment === 'on';
    },
    [treatments]
  );

  const getIsTabDisabled = useCallback(
    (tab: ProjectTab) => !project || (!project?.isClaimed && UNCLAIMED_PROJECT_RESTRICTED_TABS.includes(tab)),
    [project]
  );

  const visibleTabs = PROJECT_TABS.filter(getIsTabVisible);

  return visibleTabs.map((tab: ProjectTab) => ({
    value: tab,
    label: tab,
    disabled: getIsTabDisabled(tab),
  }));
};
