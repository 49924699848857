import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'team-member-select';

export const STeamMemberSelect = styled(({ className, children }) => children(className))`
  .${BEM_CLASS} {
    &__option {
      display: flex;
      flex-direction: column;
      padding: 5px 20px;
      color: ${colorPalette.vulcan};
      background-color: ${colorPalette.white};
      transition: color 0.3s ease-out, background-color 0.3s ease-out;
      cursor: pointer;

      &--isFocused {
        color: ${colorPalette.azureRadiance};
        background-color: ${transparentize(0.9, colorPalette.azureRadiance)};
      }
    }

    &__option-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }

    &__option-email {
      font-size: 12px;
      line-height: 15px;
    }

    &__no-options-message {
      color: ${colorPalette.wildBlueYonder};
      font-size: 14px;
      line-height: 18px;
      padding: 0 20px;
    }

    &__value-container {
      max-width: 254px;
    }

    &__menu-list {
      width: 350px;
      max-height: 172px;
    }

    &__error {
      display: flex;
      align-items: center;
      padding-top: 10px;
      color: ${colorPalette.alizarinCrimson};
      font-size: 12px;
      line-height: 14px;
      font-weight: 500;
    }

    &__error-icon {
      margin-right: 10px;
    }
  }
`;
