import { actionsGroup } from 'core/store';
import { AdSetsResponse, CampaignDetails, Id, Optional, Phase, ProjectDetails } from 'backend-api/models';
import { CampaignInput } from './components/form/types';
import { AdSetsFilters } from './types';
import { AdSetsFilterParams, FromSearchParam } from 'backend-api/types';
import { UUID } from 'io-ts-types/lib/UUID';

const { action, asyncAction } = actionsGroup('campaign');

interface GetCampaignPayload {
  campaignUuid: UUID;
  projectId: Id;
  isUnassigned?: boolean;
  withoutAmplitude: boolean;
  params?: FromSearchParam;
}

interface GetUnassignedCampaignPayload {
  campaignUuid: UUID;
  params?: FromSearchParam;
}

export interface CreateCampaignPayload extends CampaignInput {
  projectId: Id;
  timeSpent: number;
  onSuccess?(): void;
}

export interface EditCampaignPayload extends CampaignInput {
  campaignUuid: UUID;
  projectId: Id;
  changedFields: string[];
}

export interface GenerateCampaignNamePayload extends CampaignInput {
  projectId: Id;
}

export interface DeleteCampaignPayload {
  campaignUuid: UUID;
  projectId: Id;
  onUndo: () => void;
  onDeleteCampaignAction?: () => void;
}

export interface ShowModalPayload {
  id: string;
  isOpened: boolean;
}

interface GetProjectPayload {
  campaign?: CampaignDetails;
  projectId?: Id;
  isUnassigned?: boolean;
  withoutAmplitude: boolean;
}

interface GetAdSetsPayload {
  campaignUuid: UUID;
  filters: AdSetsFilterParams;
}

export interface UnassignCampaignPayload {
  campaignUuid: UUID;
  projectId: Id;
  onUnassignCampaignAction?: () => void;
}

export const getCampaign = asyncAction<GetCampaignPayload, CampaignDetails, Error>('GET_CAMPAIGN');

export const getUnassignedCampaign = asyncAction<GetUnassignedCampaignPayload, CampaignDetails, Error>(
  'GET_UNASSIGNED_CAMPAIGN'
);

export const createCampaign = asyncAction<CreateCampaignPayload, void, Error>('CREATE_CAMPAIGN');

export const deleteCampaign = asyncAction<DeleteCampaignPayload, void, Error>('DELETE_CAMPAIGN');

export const editCampaign = asyncAction<EditCampaignPayload, void, Error>('EDIT_CAMPAIGN');

export const showConfirmModal = action<ShowModalPayload>('SHOW_CONFIRM_MODAL');

export const resetCampaign = action<unknown>('RESET_CAMPAIGN');

export const getCampaignProject = asyncAction<GetProjectPayload, ProjectDetails, Error>('GET_CAMPAIGN_PROJECT');

export const undoDeleteCampaign = asyncAction<GetCampaignPayload, void, Error>('UNDO_DELETE_CAMPAIGN');

export const getPhases = asyncAction<Id, Phase[], Error>('GET_PHASES');

export const updateFilters = action<AdSetsFilters>('UPDATE_FILTERS');

export const getAdSets = asyncAction<GetAdSetsPayload, AdSetsResponse, Error>('GET_AD_SETS');

export const exportAdSets = asyncAction<UUID, unknown, Error>('EXPORT_AD_SETS');

export const unassignCampaign = asyncAction<UnassignCampaignPayload, void, Error>('UNASSIGN_CAMPAIGN');

export const setProcessing = action<boolean>('SET_PROCESSING');

export const generateCampaignName = asyncAction<GenerateCampaignNamePayload, Optional<string>, Error>(
  'AUTOGENERATE_NAME'
);
