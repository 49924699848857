import styled from 'styled-components';

interface Props {
  hasStickyColumns?: boolean;
}

export const FOOTER_ROW_HEIGHT = 60;

export const SFooter = styled.tfoot<Props>`
  position: sticky;
  bottom: 0;
  .footer {
    &__row {
      height: ${FOOTER_ROW_HEIGHT}px;
      & > :not(:first-child) {
        padding: 0 10px;
      }

      & > :first-child {
        padding: 0 10px 0 20px;
      }
      & > :last-child {
        padding: 0 20px 0 10px;
      }
    }
    &__cell {
      position: sticky;
      bottom: 0;
      height: ${FOOTER_ROW_HEIGHT}px;
      background-color: rgba(248, 248, 251, 1);
      z-index: 11;
    }
    &__shadow-row {
      height: 0.1px;
    }
    &__shadow {
      height: 0;
      position: sticky;
      bottom: ${FOOTER_ROW_HEIGHT}px;
      background-color: rgba(248, 248, 251, 1);
      box-shadow: -5px -2px 10px 0 rgba(134, 138, 179, 0.5);
    }
  }
`;
