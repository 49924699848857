import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getSearchParamsFromLocation } from 'utils/navigation';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { Projects } from '../components/projects';
import { getAllProjects, getAllUnassignedProjects, getRecentInfo, getUserProjectsCount } from '../actions';
import { getFiltersByStatusWithDefaultOffset, getProjectFiltersParamsFromSearchQuery } from '../transducers';
import { IN_PROGRESS_STATUS, UNASSIGNED_STATUS } from 'common/constants';
import { currentLabelSelector, userProjectsCountSelector } from 'projects/selectors';
import { useProjectsFilters } from 'projects/hooks';
import { usePrevious } from 'hooks';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';

export const ProjectsContainer = React.memo(() => {
  const dispatch = useDispatch();

  const userProjectCount = useSelector(userProjectsCountSelector);
  const currentLabel = useSelector(currentLabelSelector);
  const previousCurrentLabel = usePrevious(currentLabel);
  const [filters, filtersDispatcher] = useProjectsFilters();

  useEffect(() => {
    dispatch(getRecentInfo.request());
    dispatch(getUserProjectsCount.request());
  }, [dispatch]);

  const location = useLocation();
  const search = getSearchParamsFromLocation(location);

  useEffect(() => {
    const params = getProjectFiltersParamsFromSearchQuery(search);

    if (params.statuses?.includes(UNASSIGNED_STATUS.id)) {
      dispatch(getAllUnassignedProjects.request({ ...params, statuses: undefined, isClaimed: false }));
    } else {
      dispatch(getAllProjects.request({ ...params }));
    }
  }, [dispatch, search]);

  useEffect(() => trackEvent(AnalyticsEvents.HOME_PAGE_VIEWED), []);

  useEffect(() => {
    if (currentLabel !== previousCurrentLabel) {
      const filtersByStatus = getFiltersByStatusWithDefaultOffset(filters.filtersByStatus);

      filtersDispatcher.setSearch(filters.search);
      filtersDispatcher.setFilters({
        labelId: undefined,
        rosterLabelId: undefined,
        filtersByStatus,
        onlyMine: !!userProjectCount && userProjectCount > 0,
        status: IN_PROGRESS_STATUS,
      });
    }
  }, [
    currentLabel,
    filters.filtersByStatus,
    filters.search,
    filtersDispatcher,
    previousCurrentLabel,
    userProjectCount,
  ]);

  return (
    <OutdatedGlobalLayout>
      <Projects />
    </OutdatedGlobalLayout>
  );
});
