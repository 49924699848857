import React, { useMemo } from 'react';
import {
  UnclaimedProjectWithLockedArtist,
  UnclaimedConfidentialProject,
  UnclaimedProjectContactAdmin,
  UnclaimedProjectWithLockedArtistContactAdmin,
} from './components';
import { paths } from 'app/routing/paths';
import { ProjectDetails } from 'backend-api/models';
import { getLockedArtist } from 'common-v2/transducers';

interface UnclaimedProjectContainerProps {
  isAdmin: boolean;
  projectDetails: ProjectDetails;
  onClaimProject(): void;
  isClaimingInProgress: boolean;
}

export const UnclaimedProjectContainer = React.memo(
  ({ isAdmin, projectDetails, onClaimProject, isClaimingInProgress }: UnclaimedProjectContainerProps) => {
    const lockedArtist = useMemo(() => getLockedArtist(projectDetails.targets), [projectDetails.targets]);

    const adminUnclaimedView = useMemo(
      () =>
        projectDetails.isConfidential || !lockedArtist ? (
          <UnclaimedConfidentialProject onClaimProject={onClaimProject} isClaimingInProgress={isClaimingInProgress} />
        ) : (
          <UnclaimedProjectWithLockedArtist artistPagePath={paths.artist(lockedArtist.id)} />
        ),
      [isClaimingInProgress, lockedArtist, onClaimProject, projectDetails.isConfidential]
    );

    const regularUserUnclaimedView = useMemo(
      () => (lockedArtist ? <UnclaimedProjectWithLockedArtistContactAdmin /> : <UnclaimedProjectContactAdmin />),
      [lockedArtist]
    );

    return isAdmin ? adminUnclaimedView : regularUserUnclaimedView;
  }
);
