import styled from 'styled-components';

export const BEM_CLASS = 'table';

export const STable = styled.div`
  .${BEM_CLASS} {
    &__container {
      height: 404px;
      min-height: 404px;
    }
    &__platform-cell {
      width: 100px;
    }
    &__small-table-cell {
      width: 110px;
    }
  }
`;
