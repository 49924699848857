import React from 'react';
import { BaseRenderer } from 'media-plan/components/spreadsheet/components/base-renderer';
import { H4 } from 'common/components/typography';
import { ICellRendererParams } from 'ag-grid-community';

export const TotalRenderer = ({ context }: ICellRendererParams) => (
  <BaseRenderer color={null} accessMode={context.accessMode}>
    <H4>Total</H4>
  </BaseRenderer>
);
