import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const BEM_CLASS = 'projects-result';

export const SProjectsResult = styled.div`
  height: 100%;

  .${BEM_CLASS} {
    &__cell {
      display: flex;
      align-items: center;
      height: 100%;

      &--name,
      &--target,
      &--date {
        font-size: 14px;
        line-height: 20px;
        color: ${palette.darkBlue};
      }

      &--name {
        // FIX: Safari issue with hidden content
        will-change: transform;
      }

      &--date {
        justify-content: flex-end;
      }
    }

    &__avatar {
      width: 40px;
      height: 40px;
    }

    &__badge {
      margin-left: 10px;
    }
  }
`;
