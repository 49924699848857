import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isArtistTeamEditor } from 'common/transducers';
import { Optional } from 'backend-api/models';
import { ViewTeamModal } from 'common/components/view-team-modal';
import { isTeamModalOpenedSelector } from 'common/selectors';
import { ViewTeamModalMode } from 'common/types';
import { activeProjectSelector, artistTeamSelector, shareProgressSelector, teamSelector } from 'project/selectors';
import { getProjectLockedArtist } from 'project/transducers';
import { useAppContext } from 'common-v2/hooks';

interface ViewTeamModalContainerProps {
  mode: ViewTeamModalMode;
  closeTeamModal(): void;
  openArtistPage(artistId: Optional<string>): void;
}

export const ViewTeamModalContainer = React.memo(
  ({ mode, closeTeamModal, openArtistPage }: ViewTeamModalContainerProps) => {
    const { user, labels: userLabels } = useAppContext();

    const project = useSelector(activeProjectSelector);
    const artistTeam = useSelector(artistTeamSelector);
    const isTeamModalOpened = useSelector(isTeamModalOpenedSelector);
    const lockedArtist = useMemo(() => getProjectLockedArtist(project), [project]);
    const isLabelAdmin =
      user?.isAdmin && project?.label?.id && !!userLabels?.find(label => label.id === project?.label?.id);
    const canEditArtistTeam =
      (user && artistTeam && (isArtistTeamEditor(user, artistTeam.data?.users || []) || isLabelAdmin)) || undefined;
    const team = useSelector(teamSelector);
    const onEditArtistTeam = useCallback(() => openArtistPage(lockedArtist?.id), [openArtistPage, lockedArtist?.id]);
    const isShareLoading = useSelector(shareProgressSelector);

    return (
      <ViewTeamModal
        users={mode === ViewTeamModalMode.ARTIST_TEAM ? artistTeam.data?.users : team}
        isOpened={isTeamModalOpened}
        isLoading={isShareLoading}
        mode={mode}
        onClose={closeTeamModal}
        onEditTeam={onEditArtistTeam}
        canEditArtistTeam={canEditArtistTeam}
        artist={lockedArtist}
        label={artistTeam.data?.label}
      />
    );
  }
);
