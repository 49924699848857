import React, { useCallback, useEffect, useState } from 'react';
import { bem } from 'utils/bem';
import { ExpandableField } from '../../../../expandable-field';
import { BEM_CLASS, SExpandableLinks } from './s-expandable-links';
import { CreatableSelect } from 'common-v2/components/select/creatable-select';
import { BaseOption, ClassNamesProps } from 'common/components/select';
import { CreatableSelectType } from 'common/components/select/types';
import { ValidationTooltipContent } from '../../../../validation-tooltip-content';
import {
  AD_CREATIVE_MULTIPLE_VALIDATION_TEXT,
  AD_CREATIVE_SINGLE_VALIDATION_TEXT,
  MAX_LINKS_COUNT,
} from 'media-plan-v2/components/spreadsheet/constants';
import Select from 'react-select/base';

interface ExpandableLinksProps {
  selectRef?: React.RefObject<Select<BaseOption, true>>;
  onKeyDown?(event: React.KeyboardEvent<HTMLElement>): void;
  initialInputValue?: string;
  value: string[];
  placeholder?: React.ReactNode;
  onChangeValue(value: string[]): void;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ExpandableLinks = React.memo(
  ({
    value,
    placeholder,
    onChangeValue,
    className,
    selectRef,
    onKeyDown,
    initialInputValue = '',
  }: ExpandableLinksProps) => {
    const [isError, setIsError] = useState(false);
    const [isMaxValue, setIsMaxValue] = useState(value.length === MAX_LINKS_COUNT);

    const selectClassNames = useCallback<(className: string) => ClassNamesProps>(
      () => ({
        control: classes('control'),
        valueContainer: {
          root: classes('value-container'),
          counter: classes('value-counter'),
          container: classes('values-container'),
        },
        selectContainer: classes('select-container'),
      }),
      []
    );

    const onInvalidLink = useCallback(() => {
      if (!isError) setIsError(true);
    }, [isError]);

    const onAllValid = useCallback(() => {
      if (isError) setIsError(false);
    }, [isError]);

    const handleChange = useCallback(
      (value: string[]) => {
        if (value.length === MAX_LINKS_COUNT) {
          setIsMaxValue(true);
        } else {
          setIsMaxValue(false);
        }
        onChangeValue(value);
      },
      [onChangeValue]
    );

    useEffect(() => {
      selectRef?.current?.inputRef?.focus({ preventScroll: true });
    }, [selectRef]);

    return (
      <ExpandableField hasError={isError || isMaxValue}>
        <SExpandableLinks className={className} isMaxValue={isMaxValue} isEmpty={value.length === 0}>
          {className => (
            <div className={className}>
              {isError && (
                <>
                  <ValidationTooltipContent
                    text={
                      value?.length === 1 ? AD_CREATIVE_SINGLE_VALIDATION_TEXT : AD_CREATIVE_MULTIPLE_VALIDATION_TEXT
                    }
                    className={classes('error-notification')}
                  />
                  <div className={classes('divider')} />
                </>
              )}
              <div className={classes('select')}>
                <CreatableSelect
                  selectRef={selectRef}
                  onKeyDown={onKeyDown}
                  type={CreatableSelectType.LinkInput}
                  rawLinks={value}
                  valueHandler={handleChange}
                  onInvalidLink={onInvalidLink}
                  onAllValid={onAllValid}
                  placeholder={placeholder}
                  isSearchable
                  classNames={selectClassNames(className)}
                  isClearable
                  isOpened
                  maxLength={MAX_LINKS_COUNT}
                  inputValue={initialInputValue}
                />
              </div>
            </div>
          )}
        </SExpandableLinks>
      </ExpandableField>
    );
  }
);
