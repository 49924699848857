import styled, { css } from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'platform-details';

const textStyleCss = css`
  color: black;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 20px;
  align-items: center;
`;

export const SPlatformDetails = styled.div`
  display: flex;
  ${textStyleCss};
  ${elipsizeText};

  .${BEM_CLASS} {
    &__icon {
      margin-right: 20px;
      margin-left: 20px;
    }
    &__name {
      ${textStyleCss};
      ${elipsizeText};
    }
  }
`;
