import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { CampaignDetails } from '../components/campaign-details';
import { activeCampaignSelector, errorSelector } from '../selectors';
import { ProjectHeader } from 'common/containers/project-header';
import { useIsCampaignRouteUnassigned, useQuery } from 'hooks';
import {
  getAdSets,
  getCampaign,
  getCampaignProject,
  resetCampaign,
  showConfirmModal,
  updateFilters,
  getUnassignedCampaign,
} from '../actions';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import { paths } from 'app/routing/paths';
import { getAdSetsFiltersFromParams, getAdSetsFiltersParamsFromSearchQuery } from '../transducers';
import { SFlex } from 'common/s-components/layout/s-flex';
import { AssignToProjectModal } from 'common/components/modals';
import { getSearchParamsFromLocation, navigateTo } from 'utils/navigation';
import { Id } from 'backend-api/models';
import { UUID } from 'io-ts-types/lib/UUID';
import { getFromSearchParams } from 'common/transducers';
import { activeProjectSelector } from 'common/selectors';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';
import { Head } from 'app/components/head';

interface MatchParams {
  id?: string;
  campaignUuid: UUID;
}

const MODAL_NAME = 'CONFIRM_CAMPAIGN_DELETE';

export const CampaignDetailsContainer = React.memo(({ match, history }: RouteComponentProps<MatchParams>) => {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);

  const projectId = match.params.id ? parseInt(match.params.id, 10) : undefined;
  const campaignUuid = match.params.campaignUuid;

  const project = useSelector(activeProjectSelector);
  const campaign = useSelector(activeCampaignSelector);

  const campaignName = campaign?.name;
  const isProjectUnassigned = !!project && project.isClaimed;
  const isCampaignUnassigned = useIsCampaignRouteUnassigned();
  const params = useQuery();

  const location = useLocation();
  const search = getSearchParamsFromLocation(location);

  const onCampaignAssigned = useCallback(
    (projectId: Id) => {
      navigateTo(paths.campaign(projectId, campaignUuid));
    },
    [campaignUuid]
  );

  useEffect(() => {
    if (campaignUuid) {
      if (projectId) {
        dispatch(getCampaignProject.request({ projectId, isUnassigned: isProjectUnassigned, withoutAmplitude: false }));
        dispatch(
          getCampaign.request({
            campaignUuid,
            projectId,
            isUnassigned: isProjectUnassigned,
            withoutAmplitude: false,
            ...getFromSearchParams(params.query),
          })
        );
      } else {
        dispatch(
          getUnassignedCampaign.request({
            campaignUuid,
            ...getFromSearchParams(params.query),
          })
        );
      }
    }

    return () => {
      dispatch(resetCampaign({}));
    };
  }, [dispatch, campaignUuid, projectId, isProjectUnassigned, params.query]);

  useEffect(() => {
    const showModal = (isOpened: boolean) => {
      dispatch(
        showConfirmModal({
          id: MODAL_NAME,
          isOpened,
        })
      );
    };

    const onPopState = () => showModal(false);

    window.addEventListener('popstate', onPopState);

    return () => window.removeEventListener('popstate', onPopState);
  }, [dispatch]);

  useEffect(() => {
    if (error && error.statusCode === 404) {
      history.replace(paths.outdatedNotFound());
    }
  }, [error, history]);

  useEffect(() => {
    if (campaignUuid) {
      const params = getAdSetsFiltersParamsFromSearchQuery(search);
      const filters = getAdSetsFiltersFromParams(params);

      dispatch(
        getAdSets.request({
          campaignUuid,
          filters: params,
        })
      );
      dispatch(updateFilters(filters));
    }
  }, [dispatch, campaignUuid, search]);

  return (
    <>
      <Head title={campaignName} />
      <OutdatedGlobalLayout>
        <SFlex flexDirection="column" flex={1}>
          {!!projectId && <ProjectHeader projectId={projectId} isNoActiveTab />}
          {campaign && <CampaignDetails projectId={projectId} campaign={campaign} />}
          {isCampaignUnassigned && <AssignToProjectModal onCampaignAssigned={onCampaignAssigned} />}
        </SFlex>
      </OutdatedGlobalLayout>
    </>
  );
});
