import { ProductFamily, ProductFamilyTrack } from './index';
import { parseDateByFormat } from 'common-v2/utils';

const getTrackDefaults = (): ProductFamilyTrack => ({
  id: 999,
  title: 'Track',
  isrc: 'tt555555',
  supplementary: undefined,
  releaseDate: parseDateByFormat('2020-01-01', 'yyyy-MM-dd'),
});

export const getTrackMock = (p?: Partial<ProductFamilyTrack>): ProductFamilyTrack => ({
  ...getTrackDefaults(),
  ...p,
});

const getProductFamilyDefaults = (): ProductFamily => ({
  id: 1,
  title: 'Product Family',
  releaseDate: parseDateByFormat('2020-01-01', 'yyyy-MM-dd'),
  tracks: [getTrackDefaults()],
  externalId: '123456',
});

export const getProductFamilyMock = (p?: Partial<ProductFamily>): ProductFamily => ({
  ...getProductFamilyDefaults(),
  ...p,
});
