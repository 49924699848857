import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS, SBudgetDetailsGridItem } from './s-budget-details-grid-item';
import { Popover, Tooltip as BudgetInfoTooltip } from './components';
import { BudgetGroup, BudgetGroupCategory, BudgetGroupSchema, Id } from 'backend-api/models';
import { formatShortenPercent } from 'utils/format';
import { getPercentage, getRelativeProgressBarWidth } from 'common/transducers';
import { SmallText } from 'common/components/typography';
import { Tooltip } from 'common/components/tooltip';
import { CURRENCY_FORMATS } from 'common/constants';
import { useProjectCurrencyFormat } from 'hooks';

const classes = bem(BEM_CLASS);

interface Props {
  budgetInfo: BudgetGroup | BudgetGroupCategory;
  onSelect?: (budgetGroupId: Id) => void;
}

export const BudgetDetailsGridItem = React.memo(({ budgetInfo, onSelect }: Props) => {
  const tooltip = useMemo(() => <BudgetInfoTooltip {...budgetInfo} />, [budgetInfo]);

  const percentage = getPercentage(budgetInfo.allocation, budgetInfo.budget);
  const progressBarWidth = getRelativeProgressBarWidth(percentage);
  const formattedPercentage = percentage < 0.01 && percentage > 0 ? '(<1%)' : `(${formatShortenPercent(percentage)})`;

  const title = useMemo(() => {
    return BudgetGroupSchema.is(budgetInfo) ? (
      <Tooltip
        content={
          <span>
            Click to see breakdown <br /> by {budgetInfo.title}.
          </span>
        }
      >
        <SmallText onClick={() => onSelect && onSelect(budgetInfo.id)} className={classes('clickable-title')}>
          {budgetInfo.title}
        </SmallText>
      </Tooltip>
    ) : (
      <SmallText className={classes('title')}>{budgetInfo.title}</SmallText>
    );
  }, [budgetInfo, onSelect]);

  return (
    <SBudgetDetailsGridItem percentage={progressBarWidth}>
      <div className={classes('budget-and-allocated-container')}>
        {title}
        <span className={classes('currency')} data-selector="budget-details-total-amount">
          {useProjectCurrencyFormat(budgetInfo.budget, CURRENCY_FORMATS.short)}
        </span>
      </div>
      <div className={classes('utilization-and-total-container')}>
        <Popover content={tooltip} placement="bottom-start" className={classes('progress-bar-container')}>
          <div className={classes('progress-bar')}>
            <div className={classes('filled-progress-bar')} data-selector="budget-details-group-utilization-bar" />
          </div>
        </Popover>
        <div className={classes('allocated-container')}>
          <span className={classes('currency')} data-selector="budget-details-amount-value">
            {useProjectCurrencyFormat(budgetInfo.allocation, CURRENCY_FORMATS.short)}
          </span>
          <span className={classes('percentage')} data-selector="budget-details-percentage-value">
            {formattedPercentage}
          </span>
        </div>
      </div>
    </SBudgetDetailsGridItem>
  );
});
