import { typography } from 'app/theme/typography';
import styled from 'styled-components';

export const BEM_CLASS = 'moment-form-body';

export const SBody = styled.div`
  margin-bottom: 120px;

  .${BEM_CLASS} {
    &__remove-button {
      margin-top: 25px;
    }

    &__notes {
      ${typography.body};
      min-height: 62px;
    }
  }
`;
