import { ProjectDetails } from 'backend-api/models';
import { useSelector } from 'react-redux';
import { usePermissionsChecker } from 'common-v2/hooks';
import { ACCESS_PERMISSIONS } from 'common-v2/constants';
import { mediaPlansSelector } from 'media-plan-v2/selectors';
import { LoadingState } from 'common/types';

interface HeaderPermissionsHook {
  canEditMediaPlan: boolean;
  shouldShowMediaPlansDropdown: boolean;
}

export const useHeaderPermissions = (project?: ProjectDetails): HeaderPermissionsHook => {
  const permissionsChecker = usePermissionsChecker(project);
  const canEditMediaPlan = permissionsChecker(ACCESS_PERMISSIONS.EDIT_MEDIA_PLAN);
  const mediaPlans = useSelector(mediaPlansSelector);

  const shouldShowMediaPlansDropdown =
    canEditMediaPlan || mediaPlans.data.length > 0 || mediaPlans.loading === LoadingState.Failed;

  return { canEditMediaPlan, shouldShowMediaPlansDropdown };
};
