import { LabelPermissionsCounters } from 'backend-api/models';
import { TABS, TABS_COUNTERS_MAP } from './constants';
import { Tab } from './types';

export const getPermissionTabs = (counters?: LabelPermissionsCounters): Tab[] =>
  TABS.map(tab => {
    const counter = TABS_COUNTERS_MAP[tab];
    const count = counters?.[counter];

    return { value: tab, label: `${tab}${count === undefined ? '' : ` (${count})`}` };
  });
