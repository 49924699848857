import { ColDef } from 'ag-grid-community';
import { CurrentUserInfo, LabelPermissionsArtistWithMembers } from 'backend-api/models';
import { EditMembersModalPayload } from 'label-permissions/types';
import { isSubstring } from 'utils/string';
import { TeamMembersRenderer, UserNameRenderer } from './components';
import { EditMembersRenderer } from './components/edit-members-renderer';

interface TableConfigParams {
  openEditMembersModal: (payload: EditMembersModalPayload) => void;
  isSortingDisabled: boolean;
  user?: CurrentUserInfo;
}

export const buildTableConfig = ({ isSortingDisabled, openEditMembersModal, user }: TableConfigParams): ColDef[] => [
  {
    field: 'artist',
    headerName: 'Name',
    width: 415,
    sortable: !isSortingDisabled,
    unSortIcon: !isSortingDisabled,
    comparator: artistComparator,
    suppressMenu: true,
    cellRenderer: UserNameRenderer,
  },
  {
    field: 'teamMembers',
    headerName: 'Team Members',
    flex: 1,
    sortable: false,
    unSortIcon: false,
    suppressMenu: true,
    cellRenderer: TeamMembersRenderer,
  },
  {
    field: 'id',
    headerName: '',
    width: 55,
    suppressMenu: true,
    cellRenderer: EditMembersRenderer,
    cellRendererParams: {
      openEditMembersModal,
      user,
    },
  },
];

export const artistComparator = (
  valueA: LabelPermissionsArtistWithMembers['artist'],
  valueB: LabelPermissionsArtistWithMembers['artist']
): number => valueB.name.localeCompare(valueA.name);

export const artistPredicate = (search: string) => ({ artist }: LabelPermissionsArtistWithMembers): boolean =>
  isSubstring(artist.name, search);
