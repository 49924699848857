import React, { useCallback, useMemo, useRef } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { bem } from 'utils/bem';
import { basePaths } from 'app/routing/paths';
import logoSvg from 'assets/decibel-logo.svg';
import { GlobalSearchModalContainer } from 'app/containers/global-search-modal-container';
import { STopHeaderOutdated } from '../s-components/s-top-header-outdated';
import { SearchField } from './search-field';
import { useDispatch } from 'react-redux';
import { hideGlobalSearch } from 'common/actions';
import { UserDropdown } from 'app/components/user-dropdown';
import { useAuth } from 'hooks';
import { navigateTo } from 'utils/navigation';
import { convertToDataSelector } from 'common/utils/string';
import { useAppContext } from 'common-v2/hooks';

const classes = bem('top-header');

interface LinkEntity {
  title: string;
  url: string;
}

// Need to hardcode width of each link to avoid jumping on hover
const links: LinkEntity[] = [
  {
    title: 'My Label',
    url: basePaths.projects(),
  },
];

export const TopHeaderOutdated = React.memo(() => {
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const searchRef = useRef<HTMLDivElement>(null);

  const { user: userInfo } = useAppContext();

  const location = useLocation();
  const activeLinkIndex = useMemo(() => links.findIndex(link => location.pathname === link.url), [location.pathname]);

  const hideGlobalSearchModal = useCallback(() => {
    dispatch(hideGlobalSearch());
  }, [dispatch]);

  const onSettingsClick = useCallback(() => {
    navigateTo(basePaths.settings());
  }, []);

  const onLabelsPermissionsClickHandler = useCallback(() => {
    navigateTo(basePaths.labelPermissions());
  }, []);

  return (
    <STopHeaderOutdated selectedIndex={activeLinkIndex} onClick={hideGlobalSearchModal} data-selector="header">
      <div className={classes('')}>
        <div className={classes('items')}>
          <div className={classes('right-container')}>
            <Link to={basePaths.projects()} className={classes('decibel-logo')} data-selector="top-header-decibel-logo">
              <img alt="Decibel" src={logoSvg} />
            </Link>
            <div className={classes('beta')} data-selector="top-header-beta-badge">
              Beta
            </div>
            <div className={classes('divider')} />
            <div className={classes('search')}>
              <SearchField ref={searchRef} />
            </div>
          </div>
          <div className={classes('left-container')}>
            <div className={classes('links')}>
              {links.map(({ title, url }) => (
                <NavLink
                  key={url}
                  to={url}
                  className={classes('link')}
                  activeClassName={classes('current')}
                  data-selector={`${convertToDataSelector(title)}-link`}
                  exact
                >
                  {title}
                </NavLink>
              ))}
              {activeLinkIndex >= 0 && <hr className={classes('under')} />}
            </div>
            <UserDropdown
              className={classes('user-dropdown')}
              onLogOutPressed={logout}
              onSettingsPressed={onSettingsClick}
              userInfo={userInfo}
              onLabelsPermissionsClick={onLabelsPermissionsClickHandler}
            />
          </div>
        </div>
      </div>

      <GlobalSearchModalContainer />
    </STopHeaderOutdated>
  );
});
