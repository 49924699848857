import { store } from 'app';
import { uniqueId } from 'lodash';
import { showToast as showToastAction } from 'common/actions';
import { ACCESS_RESTRICTED_CODES } from 'common/constants';
import { ApiError } from 'backend-api';

export enum ToastType {
  Success,
  Error,
  Warning,
  Info,
}

export interface ToastOptions {
  id: string;
  type: ToastType;
  dataSelector?: string;
  message: React.ReactChild;
  replaceable?: boolean;
  onUndo?(): void;
}

export const showToast = (options: ToastOptions) => {
  store.dispatch(showToastAction(options));
};

interface ErrorOptions {
  message: string;
}

export const showSuccessToast = (message: string) => {
  showToast({
    id: uniqueId('success-toast-'),
    type: ToastType.Success,
    message,
  });
};

export const showErrorToast = (params: string | ErrorOptions | ApiError) => {
  if (params instanceof ApiError) {
    const isAccessResricted =
      params.statusCode === 403 && params.data && ACCESS_RESTRICTED_CODES.includes(params.data.code);

    const isLoggedOut = params.statusCode === 401 && params.data?.code === 'unauthorized';

    if (isAccessResricted || isLoggedOut) return;
  }

  let message: string;

  if (typeof params === 'string') {
    message = params;
  } else {
    message = params.message;
  }

  showToast({
    id: uniqueId('error-toast-'),
    type: ToastType.Error,
    message,
  });
};

export const showWarningToast = (message: string) => {
  showToast({
    id: uniqueId('warning-toast-'),
    type: ToastType.Warning,
    message,
  });
};

export const showInfoToast = (message: string) => {
  showToast({
    id: uniqueId('info-toast-'),
    type: ToastType.Info,
    message,
  });
};
