import { Id } from 'backend-api/models';
import { PurchaseOrderSortField } from 'backend-api/types';
import { useFilters } from 'hooks';
import { useMemo } from 'react';
import { paths } from 'app/routing/paths';
import { ParsedQs } from 'qs';
import {
  getPurchaseOrdersFiltersFromParams,
  getPurchaseOrdersFiltersParamsFromFilters,
  getPurchaseOrdersFiltersParamsFromSearchQuery,
} from '../transducers';
import { FiltersSelectorType, PurchaseOrdersFilters, UpdateFiltersType } from '../types';
import { useSelector } from 'react-redux';
import { activeProjectSelector } from 'common/selectors';

export const usePurchaseOrdersFilters = (
  projectId: Id,
  action: UpdateFiltersType,
  selector: FiltersSelectorType,
  purchaseOrderId?: Id
) => {
  const project = useSelector(activeProjectSelector);
  const isUnassigned = !!project && project.isClaimed;

  const config = useMemo(() => {
    return {
      action: action,
      selector: selector,
      path: purchaseOrderId ? paths.purchaseOrder(projectId, purchaseOrderId) : paths.purchaseOrders(projectId),
      queryToFilters: (query: ParsedQs) => {
        const params = getPurchaseOrdersFiltersParamsFromSearchQuery(query);
        return getPurchaseOrdersFiltersFromParams(params);
      },
      filtersToQuery: getPurchaseOrdersFiltersParamsFromFilters,
    };
  }, [action, selector, purchaseOrderId, projectId, isUnassigned]);

  return useFilters<PurchaseOrdersFilters, PurchaseOrderSortField>(config);
};
