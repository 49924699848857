import React from 'react';
import { SClearAllButton } from './s-clear-all-button';

interface Props {
  onClick(): void;
  className?: string;
}

export const ClearAllButton = React.memo(({ onClick, className }: Props) => (
  <SClearAllButton onClick={onClick} className={className}>
    Clear All
  </SClearAllButton>
));
