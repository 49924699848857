import { createSelector } from 'reselect';
import { PrsState } from './reducer';

export const prsSelector = state => state.prsTab as PrsState;

export const filtersSelector = createSelector(prsSelector, state => state.filters);
export const isLoadingSelector = createSelector(prsSelector, state => state.isLoading);
export const isLoadingFailedSelector = createSelector(prsSelector, state => state.isLoadingFailed);

export const purchaseOrdersSelector = createSelector(prsSelector, state => state.purchaseOrdersResponse);
export const purchaseOrdersBreakdownsSelector = createSelector(prsSelector, state => state.breakdowns);

export const purchaseProvidersSelector = createSelector(prsSelector, state => state.providers);

export const errorSelector = createSelector(prsSelector, state => state.error);
