import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { getStatusBadgeStyling } from './transducers';
import { CampaignStatuses } from 'backend-api/models';

interface Props {
  status: CampaignStatuses;
}

export const BEM_CLASS = 'status-badge';

export const SStatusBadge = styled.div<Props>`
  ${elipsizeText};
  padding: 2px 5px;
  border-radius: 5px;
  line-height: 15px;
  border: 1px solid transparent;

  ${({ status }) => getStatusBadgeStyling(status)}
`;
