import React from 'react';
import { Skeleton } from 'common-v2/components';

interface Props {
  className?: string;
}

export const Loader = React.memo(({ className }: Props) => (
  <Skeleton height={75} width={150} className={className}>
    <rect x="0" y="0" rx="2px" ry="2px" width={150} height={25} />
    <rect x="0" y="40" rx="2px" ry="2px" width={100} height={25} />
  </Skeleton>
));
