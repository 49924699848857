import * as React from 'react';
import { components as selectComponents, GroupBase, GroupHeadingProps } from 'react-select';
import { ClearAllButton } from '../clear-all-button';
import { BEM_CLASS, SGroupHeadingWithReset } from './s-group-heading-with-reset';
import { bem } from 'utils/bem';

interface Props extends Omit<GroupHeadingProps<any, false, GroupBase<any>>, 'ref'> {
  onResetClick(): void;
}

const classes = bem(BEM_CLASS);

export const GroupHeadingWithReset = React.memo(({ onResetClick, children, ...rest }: Props) => {
  return (
    <selectComponents.GroupHeading {...rest}>
      <SGroupHeadingWithReset>
        <div className={classes('content')}>{children}</div>
        <ClearAllButton onClick={onResetClick} className={classes('clear-all')} />
      </SGroupHeadingWithReset>
    </selectComponents.GroupHeading>
  );
});
