export enum Loading {
  Idle = 'Idle',
  Started = 'Started',
  Finished = 'Finished',
  Failed = 'Failed',
}

export type Loadable<T> =
  | { loading: Loading.Idle | Loading.Started | Loading.Failed; data: T }
  | { loading: Loading.Finished; data: NonNullable<T> };
