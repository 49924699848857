import { Api } from 'backend-api';
import FileSaver from 'file-saver';
import { asyncEpic, ofType } from 'core/epics';
import {
  editArtistTeam,
  exportProjects,
  getArtistInfo,
  getLabelUsers,
  getProjects,
  getUnassignedProjects,
} from './actions';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { navigateTo } from 'utils/navigation';
import { snakeCase } from 'lodash';
import { paths } from 'app/routing/paths';

export const getArtistEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(getArtistInfo.request),
    switchMap(({ payload }) => {
      return forkJoin([Api.getArtist(payload.id, payload.params), Api.getArtistTeams(payload.id)]).pipe(
        mergeMap(([info, teams]) => [
          getArtistInfo.success({
            info,
            teams,
          }),
        ]),
        catchError(error => {
          if (error.statusCode === 404) {
            navigateTo(paths.outdatedNotFound());
          }

          return of(getArtistInfo.failure(error));
        })
      );
    })
  );
};

export const getArtistProjectsEpic = asyncEpic(getProjects, ({ payload }) =>
  Api.getArtistProjects(payload.id, payload.params)
);

export const getArtistUnassignedProjectsEpic = asyncEpic(getUnassignedProjects, ({ payload }) =>
  Api.getArtistUnassignedProjects(payload.id, { ...payload.params, isClaimed: false })
);

export const exportArtistProjectsEpic = asyncEpic(exportProjects, ({ payload }) =>
  Api.exportArtistProjectsCsv(payload.id, payload.params).pipe(
    tap(data =>
      FileSaver.saveAs(new Blob([data]), `${snakeCase(`${payload.name} ${payload.status || 'all'} projects`)}.csv`)
    )
  )
);

export const getLabelUsersEpic = asyncEpic(getLabelUsers, ({ payload }) => Api.getLabelUsers(payload));

export const editArtistTeamEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(editArtistTeam.request),
    switchMap(({ payload: { artistId, onEditSuccess, ...rest } }) => {
      return Api.createArtistTeam(artistId, rest).pipe(
        mergeMap(response => {
          return [editArtistTeam.success(response)];
        }),
        tap(() => onEditSuccess()),
        catchError(error => {
          return of(editArtistTeam.failure(error));
        })
      );
    })
  );
};
