import { uniqBy, sortBy, capitalize } from 'lodash';
import {
  PerformanceObjective,
  Id,
  Optional,
  PerformanceMetric,
  PerformanceObjectivesAndMetrics,
} from 'backend-api/models';
import { TreeItemType, TreeNode } from 'common/components/tree-list';
import { getUniquePerformanceMetrics } from 'common/components/tables/performance/transducers';
import { PERFORMANCE_METRICS_FORMATS } from 'backend-api/models/performance/constants';
import {
  FAKE_ALL_METRICS_OBJECTIVE_ID,
  FAKE_ALL_METRICS_OBJECTIVE_NAME,
  SHORT_DATE_REQUEST_FORMAT,
} from 'common/constants';
import { PerformanceFilters, PerformanceQuery } from 'common/types';
import { BaseGroup } from 'common/components/select';
import { getFormattedDate } from 'common-v2/utils';

export const getAllObjectivesWithFakeAllMetricsObjective = (
  allObjectives?: PerformanceObjectivesAndMetrics
): PerformanceObjective[] => {
  if (!allObjectives) {
    return [];
  }

  const fakeObjectiveWithAllMetrics: PerformanceObjective = {
    id: FAKE_ALL_METRICS_OBJECTIVE_ID,
    name: FAKE_ALL_METRICS_OBJECTIVE_NAME,
    fields: allObjectives.allFields,
  };

  return [fakeObjectiveWithAllMetrics, ...allObjectives.objectives];
};

export const getObjectivesWithoutFakeAllMetricsObjective = (
  objectives?: PerformanceObjective[]
): PerformanceObjective[] => {
  if (!objectives) {
    return [];
  }

  return objectives.filter(objective => objective.id !== FAKE_ALL_METRICS_OBJECTIVE_ID);
};

export const getPerformanceSearchQueryFromFilters = (filters: PerformanceFilters): PerformanceQuery => {
  return {
    period: filters.period.map(date => getFormattedDate(SHORT_DATE_REQUEST_FORMAT)(date)),
    labelsIds: filters.labelsIds?.length === 0 ? null : filters.labelsIds,
    fieldsIds: filters.fieldsIds,
    providerId: filters.providerId,
    categoriesIds: filters.categoriesIds?.length === 0 ? null : filters.categoriesIds,
    platforms: filters.platforms?.length === 0 ? null : filters.platforms,
    objectiveId: filters.objectiveId,
    search: filters.search,
    breakdownId: filters.breakdownId,
    sort: filters.sort?.getString(),
  };
};

export const getPerformanceMetric = (
  metricId: number,
  performanceObjectives?: PerformanceObjective[]
): Optional<PerformanceMetric> => {
  if (!performanceObjectives) return;

  const fields = performanceObjectives.flatMap(({ fields }) => fields);
  return fields.find(({ id }) => id === metricId);
};

export const getSearchedObjectives = (
  searchQuery: string,
  objectives?: PerformanceObjective[]
): PerformanceObjective[] => {
  if (!objectives) return [];

  const lowercasedSearch = searchQuery.toLowerCase();

  return objectives
    .map(objective => {
      const searchedFields = objective.fields.filter(field => {
        const lowercasedFieldName = field.name.toLowerCase();
        return lowercasedFieldName.includes(lowercasedSearch);
      });

      return {
        id: objective.id,
        name: objective.name,
        fields: searchedFields,
      };
    })
    .filter(objective => objective.fields.length > 0);
};

export const getTreeNodes = (objectives?: PerformanceObjective[], currentObjectiveId?: Id): TreeNode[] => {
  if (!objectives || !currentObjectiveId) return [];

  const nodes: TreeNode[] = [];

  const currentObjective = objectives.find(objective => objective.id === currentObjectiveId);

  if (currentObjective && currentObjective.fields.length > 0) {
    nodes.push({
      id: 0,
      name: currentObjective.name,
      type: TreeItemType.CHECKBOX,
      children: currentObjective.fields.map(metric => ({
        id: metric.id,
        name: metric.name,
        description: metric.description,
        children: [],
        type: TreeItemType.CHECKBOX,
      })),
    });
  }

  const uniqueMetrics = getUniquePerformanceMetrics(objectives);

  const metricTypes = uniqueMetrics.map(metric => metric.type);
  const uniqueMetricTypes = uniqBy(metricTypes, type => type.id);
  const sortedUniqueMetricTypes = sortBy(uniqueMetricTypes, type => type.index);

  sortedUniqueMetricTypes.forEach(type => {
    const metricsByType = uniqueMetrics.filter(metric => metric.type.id === type.id);
    const sortedMetricsByType = sortBy(metricsByType, metric => metric.index);

    const nodesByType = sortedMetricsByType.map(metric => ({
      id: metric.id,
      name: metric.name,
      description: metric.description,
      children: [],
      type: TreeItemType.CHECKBOX,
    }));

    nodes.push({
      id: type.id,
      name: capitalize(type.name),
      type: TreeItemType.CHECKBOX,
      children: nodesByType,
    });
  });

  return nodes;
};

export const getFormattedPerformanceMetricName = (
  metric: PerformanceMetric,
  currencyCode: Optional<string>
): string => {
  if (metric.format.name !== PERFORMANCE_METRICS_FORMATS.currency || !currencyCode) {
    return metric.name;
  }

  return `${metric.name} (${currencyCode})`;
};

export const getSelectOptions = (objectives?: PerformanceObjective[]): BaseGroup[] => {
  if (!objectives) return [];

  const options: BaseGroup[] = [];

  const uniqueMetrics = getUniquePerformanceMetrics(objectives);
  const metricTypes = uniqueMetrics.map(metric => metric.type);
  const uniqueMetricTypes = uniqBy(metricTypes, type => type.id);
  const sortedUniqueMetricTypes = sortBy(uniqueMetricTypes, type => type.index);

  sortedUniqueMetricTypes.forEach(type => {
    const metricsByType = uniqueMetrics.filter(metric => metric.type.id === type.id);
    const onlyNumeralMetrics = metricsByType.filter(metric => metric.format.name !== 'date');
    const sortedMetricsByType = sortBy(onlyNumeralMetrics, metric => metric.index);

    const optionsByType = sortedMetricsByType.map(metric => ({
      id: metric.id,
      value: metric.name,
      info: metric.description,
    }));

    options.push({
      label: capitalize(type.name),
      options: optionsByType,
    });
  });

  return options;
};
