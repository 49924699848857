import React, { useCallback } from 'react';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { UserRole } from 'backend-api/models';
import { LocalRole } from 'common-v2/types';
import { bem } from 'utils/bem';
import { SUserRoleDropdown, BEM_CLASS } from './s-user-role-dropdown';
import { convertToDataSelector } from 'utils/string';

interface UserRoleDropdownProps {
  availableRoles: LocalRole[];
  currentRole: UserRole;
  onSelectRole(role: LocalRole): void;
  onRemoveUser(role: UserRole): void;
}

const classes = bem(BEM_CLASS);

export const UserRoleDropdown = React.memo(
  ({ availableRoles, currentRole, onSelectRole, onRemoveUser }: UserRoleDropdownProps) => {
    const handleRoleUpdate = useCallback(
      (role: LocalRole) => () => {
        onSelectRole(role);
      },
      [onSelectRole]
    );

    const handleUserRemove = useCallback(() => {
      onRemoveUser(currentRole);
    }, [currentRole, onRemoveUser]);

    return (
      <SUserRoleDropdown>
        {availableRoles.map(role => (
          <Typography
            type={TYPOGRAPHY_TYPE.body4}
            key={role.id}
            className={classes('item', { selected: currentRole.name === role.name })}
            onClick={handleRoleUpdate(role)}
            data-selector={`user-role-dropdown-${convertToDataSelector(role.name)}`}
          >
            {role.name}
          </Typography>
        ))}

        <div className={classes('divider')} />

        <Typography
          type={TYPOGRAPHY_TYPE.body4}
          className={classes('item')}
          onClick={handleUserRemove}
          data-selector="user-role-dropdown-remove"
        >
          Remove
        </Typography>
      </SUserRoleDropdown>
    );
  }
);
