import React, { useCallback, useMemo } from 'react';
import ApolloSvg from 'assets/apollo.svg';
import RemoveSvg from 'assets/remove.svg';
import { Artist, Playlist, PlaylistSchema } from 'backend-api/models';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { Dropdown, OptionProps } from 'common/components/dropdown';
import { SCardMenu } from './s-card-menu';
import { buildApolloPlaylistUrl } from 'project/transducers';

interface Props {
  target: Artist | Playlist;
  onRemove(target: Artist | Playlist): void;
  className?: string;
}

const classes = bem('card-menu');

export const CardMenu = React.memo(({ target, onRemove, className }: Props) => {
  const onOpenClick = useCallback(() => {
    if (PlaylistSchema.is(target)) {
      const url = buildApolloPlaylistUrl(target);
      window.open(url, '_blank');
    }
  }, [target]);

  const onRemoveClick = useCallback(() => {
    onRemove(target);
  }, [target, onRemove]);

  const options = useMemo(() => {
    const options = Array<React.ReactElement<OptionProps>>();

    if (PlaylistSchema.is(target)) {
      options.push(
        <Dropdown.Option onClick={onOpenClick}>
          <div className={classes('option-container')}>
            <img alt="" src={ApolloSvg} />
            <span className={classes('option-text')}>Open on Apollo</span>
          </div>
        </Dropdown.Option>
      );
    }

    options.push(
      <Dropdown.Option onClick={onRemoveClick}>
        <div className={classes('option-container')}>
          <img alt="" src={RemoveSvg} />
          <span className={classes('option-text')}>Remove</span>
        </div>
      </Dropdown.Option>
    );

    return options;
  }, [target, onOpenClick, onRemoveClick]);

  return (
    <SCardMenu
      className={className}
      dropdownClassName={classes('menu-dropdown-list')}
      buttonClassName={classes('icon-button')}
      variant="secondary-gray"
      trigger={
        <Icon
          dataSelector="target-item-options"
          className={classes('icon')}
          size="menu"
          name="ellipsis-menu"
          color="pink"
        />
      }
    >
      {options}
    </SCardMenu>
  );
});
