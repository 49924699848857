import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridApi, RowNode } from 'ag-grid-community';
import { dateFormatSelector } from 'common/selectors';
import {
  approvalRequestsPhasesSelector,
  mediaPlanModeSelector,
  mediaPlanSelector,
  reviewedCampaignsSelector,
} from 'media-plan-v2/selectors';
import { MediaPlanMode } from 'media-plan-v2/types';
import { prepareSpreadsheetData } from 'media-plan-v2/containers/spreadsheet/transducers';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { joinData } from './transducers';
import { resetCampaignsForApproval } from 'media-plan-v2/actions';

export const useData = (api?: GridApi) => {
  const dispatch = useDispatch();

  const mediaPlanMode = useSelector(mediaPlanModeSelector);
  const { data: mediaPlan } = useSelector(mediaPlanSelector);
  const approvalRequestsPhases = useSelector(approvalRequestsPhasesSelector);
  const reviewedCampaigns = useSelector(reviewedCampaignsSelector);
  const dateFormat = useSelector(dateFormatSelector);

  const isDataFiltered = mediaPlanMode === MediaPlanMode.APPROVAL;

  const data = useMemo(() => {
    const mediaPlanRowData = prepareSpreadsheetData({ phases: mediaPlan.phases, dateFormat });

    const approvalRowData = prepareSpreadsheetData({
      phases: approvalRequestsPhases,
      isEditableInApproval: true,
      dateFormat,
    });

    return joinData(mediaPlanRowData, approvalRowData);
  }, [approvalRequestsPhases, dateFormat, mediaPlan.phases]);

  const dataFilter = useCallback(
    ({ data }: RowNode<SpreadsheetRowData>) => {
      if (!data || mediaPlanMode !== MediaPlanMode.APPROVAL) return true;

      return Boolean(data.editableInApproval.value);
    },
    [mediaPlanMode]
  );

  useEffect(() => {
    if (!api) return;

    api.onFilterChanged();
    api.refreshCells({ force: true });
  }, [api, isDataFiltered]);

  useEffect(() => {
    dispatch(resetCampaignsForApproval());
  }, [dispatch, mediaPlanMode]);

  return { data, reviewedData: reviewedCampaigns, dataFilter, isDataFiltered };
};
