import { ArtistRosterItem, ArtistRosterModel, Label } from 'backend-api/models';
import { BaseOption } from 'common/components/select';
import { LoadableData, LoadingState } from 'common/types';
import { DEFAULT_MY_ARTISTS_DROPDOWN_OPTION } from 'projects/constants';
import { getDiffInDays } from 'common-v2/utils';

export const getMyArtistsDropdownLabelsOptions = (
  hideDefaultOption: boolean,
  labels?: Label[],
  labelId?: number
): BaseOption[] => {
  if (!labels || !labelId) return [DEFAULT_MY_ARTISTS_DROPDOWN_OPTION];

  const label = labels.find(({ id }) => id === labelId);

  const defaultOption: BaseOption = {
    ...DEFAULT_MY_ARTISTS_DROPDOWN_OPTION,
    info: hideDefaultOption
      ? "You'll be able to select My Artist Roster once you join an artist team in this label space."
      : undefined,
    isDisabled: hideDefaultOption,
  };

  if (!label) return [defaultOption];

  return [
    defaultOption,
    {
      id: label.id,
      value: `${label.name} Artist Roster`,
    },
  ];
};

const isCurrentUserArtistTeamMember = ({ metadata }: ArtistRosterItem) => metadata.isCurrentUserArtistTeamMember;

export const getMyArtistRoster = (artistRoster: LoadableData<ArtistRosterModel>): LoadableData<ArtistRosterModel> => ({
  loading: artistRoster.loading,
  data: {
    live: artistRoster.data.live.filter(isCurrentUserArtistTeamMember),
    planned: artistRoster.data.planned.filter(isCurrentUserArtistTeamMember),
    inactive: artistRoster.data.inactive.filter(isCurrentUserArtistTeamMember),
  },
});

export const isMyArtistRosterEmpty = (artistRoster: LoadableData<ArtistRosterModel>): boolean => {
  const { loading, data } = artistRoster;

  const hasLive = data.live.some(isCurrentUserArtistTeamMember);
  const hasPlanned = data.planned.some(isCurrentUserArtistTeamMember);
  const hasInactive = data.inactive.some(isCurrentUserArtistTeamMember);

  return loading === LoadingState.Finished && !hasLive && !hasPlanned && !hasInactive;
};

const sortArtistItems = (items: ArtistRosterItem[]): ArtistRosterItem[] =>
  items.sort((current, next) => {
    if (!next.metadata.latestProjectEndDate) return -1;

    if (!current.metadata.latestProjectEndDate) return 0;

    return getDiffInDays(next.metadata.latestProjectEndDate, current.metadata.latestProjectEndDate);
  });

export const sortArtistRoster = (artistRoster: ArtistRosterModel): ArtistRosterModel => ({
  ...artistRoster,
  live: sortArtistItems(artistRoster.live),
  inactive: sortArtistItems(artistRoster.inactive),
});
