import styled from 'styled-components';
import { transparentize } from 'polished';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'details';

export const SDetails = styled.div`
  border: 1px solid ${transparentize(0.9, colorPalette.woodsmoke)};
  box-shadow: 0 5px 20px 0 ${transparentize(0.95, colorPalette.woodsmoke)};
  border-radius: 10px;
  overflow: hidden;
  background: radial-gradient(
    100% 100% at 41.33% 0%,
    ${transparentize(0.5, colorPalette.white)} 0%,
    ${transparentize(0.2, colorPalette.white)} 100%
  );

  .${BEM_CLASS} {
    &__info {
      display: flex;
      height: 130px;
    }

    &__image {
      flex-shrink: 0;
      width: 130px;
      height: 100%;
      background-color: ${transparentize(0.95, manhattanThemeColors.primaryFillNew)};
    }

    &__container {
      flex-grow: 1;
      padding: 20px 30px;
      overflow: hidden;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
    }

    &__top-container {
      display: flex;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      overflow: hidden;
    }

    &__title_and_artists {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      height: 100%;
      margin-bottom: auto;
      overflow: hidden;
      max-width: 100%;
      margin-top: 5px;
    }

    &__title {
      max-width: 100%;
      display: flex;
      align-items: center;
    }

    &__title-text {
      line-height: 24px;
      max-width: 100%;
    }

    &__title-icon {
      flex-shrink: 0;
      margin-left: 10px;
    }

    &__controls-container {
      margin-left: 30px;
    }

    &__artists {
      display: flex;
      align-items: baseline;
    }

    &__artist-link {
      margin: 0 5px;
      border-bottom: 1px dotted ${transparentize(0.4, colorPalette.woodsmoke)};
    }

    &__meta {
      display: flex;
      align-items: center;
      width: 100%;
      height: 25px;
    }

    &__meta-column {
      &:not(:last-child) {
        margin-right: 84px;
      }

      &--isHidden {
        visibility: hidden;
      }
    }

    &__meta-label {
      color: ${manhattanThemeColors.grayedText};
      margin-right: 5px;
    }
  }
`;
