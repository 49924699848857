import { createSelector } from 'reselect';
import { ReportingState } from 'reporting-v2/reducer';

export const reportingStateSelector = state => state.reporting as ReportingState;

export const projectSelector = createSelector(reportingStateSelector, state => state.project);
export const projectErrorSelector = createSelector(reportingStateSelector, state => state.projectError);

export const projectCampaignsSelector = createSelector(reportingStateSelector, state => state.projectCampaigns);

export const managingCampaignsSelector = createSelector(reportingStateSelector, state => state.managingCampaigns);
export const projectCampaignsErrorSelector = createSelector(
  reportingStateSelector,
  state => state.projectCampaignsError
);

export const managingAssignedCampaignsSelector = createSelector(
  managingCampaignsSelector,
  state => state.assignedCampaigns
);
