import React from 'react';
import PropTypes from 'prop-types';
import { SModalBody } from '../../s-components/modal/s-modal-body';

const { element, string, oneOfType } = PropTypes;

export const ModalBody = ({ children, height = 'auto' }) => {
  return <SModalBody height={height}>{children}</SModalBody>;
};

ModalBody.propTypes = {
  children: oneOfType([element, string]),
};

ModalBody.defaultProps = {
  children: null,
};
