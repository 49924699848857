import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';

export const SBasePlaceholder = styled.div`
  position: relative;
  left: 2px;
  width: 0;
  white-space: nowrap;
  color: ${colorPalette.wildBlueYonder};
  pointer-events: none;
`;
