import React from 'react';
import { bem } from 'utils/bem';
import { SLoaderTab } from './s-loader-tab';

const rectangleClasses = bem('loader-tab');
const CLIP_PATH_ID = 'loader-tab';

export const TabLoader = React.memo(() => {
  return (
    <SLoaderTab clipPathId={CLIP_PATH_ID}>
      <div className={rectangleClasses('clip-container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <rect x="0" y="0" rx="0" ry="0" width="100" height="15" />
          </clipPath>
        </defs>
      </svg>
    </SLoaderTab>
  );
});
