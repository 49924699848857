import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'multi-value';

interface SMultiValueContainerProps {
  isInvalid?: boolean;
}

export const SMultiValueContainer = styled.div<SMultiValueContainerProps>`
  height: 25px;
  border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
  border-radius: 6px;
  background-color: ${({ isInvalid }) =>
    isInvalid ? transparentize(0.97, manhattanThemeColors.primaryDark) : manhattanThemeColors.background};
  padding: 0 5px;
  overflow: hidden;
  display: flex;
  align-items: center;
`;
