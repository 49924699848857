import styled from 'styled-components';
import { TOP_HEADER_HEIGHT } from 'common/constants';

export const BEM_CLASS = 'page';

interface SPageProps {
  isFullHeight: boolean;
}

export const SPage = styled.div<SPageProps>`
  ${({ isFullHeight }) =>
    isFullHeight &&
    `
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - ${TOP_HEADER_HEIGHT}px);

    .app__wrapper {
      flex: 1;
    }
  `}

  .${BEM_CLASS} {
    &__container {
      &--centered {
        margin: auto 0;
      }
    }
    &__wrapper {
      padding: 30px 0;
    }

    &__filters {
      margin-bottom: 20px;
    }
  }
`;
