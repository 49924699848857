import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '../components/form';
import { errorSelector } from '../selectors';
import { ResponsiveContainer } from 'common/components/sidebar-form/responsive-container';
import { ProjectHeader } from 'common/containers/project-header';
import { getCampaign, getCampaignProject, getPhases, resetCampaign } from '../actions';
import { RouteComponentProps } from 'react-router-dom';
import { WaveBackground } from 'common/components/wave-background';
import { useProjectCurrencyCode } from 'hooks';
import { Footer } from 'app/components/footer';
import { useProjectAccessRestricted } from 'common/hooks/use-project-access-restricted';
import { UUID } from 'io-ts-types/lib/UUID';
import { paths } from 'app/routing/paths';
import { activeProjectSelector } from 'common/selectors';
import { OutdatedGlobalLayout } from 'app/components/outdated-global-layout';

interface MatchParams {
  id: string;
  campaignUuid: UUID;
}

export const CampaignFormContainer = React.memo(({ match, history }: RouteComponentProps<MatchParams>) => {
  const projectId = parseInt(match.params.id, 10);
  const campaignUuid = match.params.campaignUuid;

  const project = useSelector(activeProjectSelector);
  const isUnassigned = !!project && project.isClaimed;

  const dispatch = useDispatch();

  const error = useSelector(errorSelector);

  useEffect(() => {
    dispatch(getCampaignProject.request({ projectId: projectId, isUnassigned, withoutAmplitude: true }));
    if (campaignUuid && projectId) {
      dispatch(
        getCampaign.request({
          campaignUuid,
          projectId,
          isUnassigned,
          withoutAmplitude: true,
        })
      );
    }

    if (!isUnassigned) {
      dispatch(getPhases.request(projectId));
    }
    return () => {
      dispatch(resetCampaign({}));
    };
  }, [dispatch, campaignUuid, projectId, isUnassigned]);

  useEffect(() => {
    if (error?.statusCode === 404) {
      history.replace(paths.outdatedNotFound());
    }
  }, [error, history]);

  useProjectAccessRestricted(errorSelector);

  const currencyCode = useProjectCurrencyCode();

  return (
    <OutdatedGlobalLayout>
      <ProjectHeader projectId={projectId} isNoActiveTab={true} />
      <WaveBackground withTabs className="app__wrapper">
        <ResponsiveContainer>
          <Form campaignUuid={campaignUuid} projectId={projectId} currencyCode={currencyCode} />
        </ResponsiveContainer>
        <Footer />
      </WaveBackground>
    </OutdatedGlobalLayout>
  );
});
