import * as t from 'io-ts';
import { ResponseModel, IdNameField } from '../common';

export const ContactRequestSchema = ResponseModel(
  {
    name: t.string,
    email: t.string,
    phone: t.string,
    userId: t.number,
  },
  'ContactRequest'
);

export type ContactRequest = t.TypeOf<typeof ContactRequestSchema>;

export const ContactSchema = ResponseModel(
  {
    ...IdNameField,
    externalId: t.string,
    email: t.string,
    phone: t.string,
  },
  'Contact'
);

export type Contact = t.TypeOf<typeof ContactSchema>;
