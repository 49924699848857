import { Button, THEME, BUTTON_TYPE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { SFooter, BEM_CLASS } from './s-footer';

interface FooterProps {
  onClearAll(): void;
  onCancel(): void;
  onApply(): void;
  isClearAllDisabled: boolean;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const Footer = React.memo(({ onClearAll, onCancel, onApply, isClearAllDisabled, className }: FooterProps) => {
  return (
    <SFooter className={className}>
      <Button
        theme={THEME.light}
        className={classes('clear-all-button')}
        caption="Clear All"
        type={BUTTON_TYPE.secondary}
        onClick={onClearAll}
        disabled={isClearAllDisabled}
        data-selector="edit-artists-modal-footer-clear-all-button"
      />
      <Button
        theme={THEME.light}
        className={classes('button')}
        caption="Cancel"
        type={BUTTON_TYPE.secondary}
        onClick={onCancel}
        data-selector="edit-artists-modal-footer-cancel-button"
      />
      <Button
        theme={THEME.light}
        className={classes('button')}
        caption="Apply"
        type={BUTTON_TYPE.primary}
        onClick={onApply}
        data-selector="edit-artists-modal-footer-apply-button"
      />
    </SFooter>
  );
});
