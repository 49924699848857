import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Id, ProjectReviewer } from 'backend-api/models';
import { bem } from 'utils/bem';
import { pluralizeString } from 'utils/string';
import { LoadingState } from 'common/types';
import { Button, THEME, BUTTON_TYPE, Typography, TYPOGRAPHY_TYPE, ICON_SIZE, Icon } from 'gdb-web-shared-components';
import { RequestApprovalModal } from '../request-approval-modal';
import { BEM_CLASS, SRequestApprovalFooter } from './s-request-approval-footer';

const classes = bem(BEM_CLASS);

interface RequestApprovalFooterProps {
  count: number;
  reviewers: ProjectReviewer[];
  isConfidentialProject?: boolean;
  approvalRequestLoading?: LoadingState;
  onSubmit(reviewers: Id[], message?: string): void;
  onModalClosed?(reviewers: Id[], message?: string): void;
  onCancel(): void;
}

export const RequestApprovalFooter = React.memo(
  ({
    count,
    reviewers,
    isConfidentialProject = false,
    approvalRequestLoading,
    onSubmit,
    onCancel,
    onModalClosed,
  }: RequestApprovalFooterProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isWarningVisible, setIsWarningVisible] = useState(false);

    const onFooterSubmit = useCallback(() => {
      if (count > 0) {
        setIsModalVisible(true);
        setIsWarningVisible(false);
      } else {
        setIsWarningVisible(true);
      }
    }, [count]);

    const onModalCancel = useCallback(
      (reviewers: Id[], message?: string) => {
        setIsModalVisible(false);
        onModalClosed?.(reviewers, message);
      },
      [onModalClosed]
    );

    const counter = useMemo(
      () => (
        <span>
          <Typography type={TYPOGRAPHY_TYPE.body3}>{count}</Typography>
          <Typography type={TYPOGRAPHY_TYPE.body4}>{` ${pluralizeString('campaign', count)} selected`}</Typography>
        </span>
      ),
      [count]
    );

    const warningMessage = useMemo(
      () => (
        <div className={classes('warning-container')}>
          <Icon className={classes('warning-icon')} size={ICON_SIZE.medium} name="warning" />
          <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('empty-checkboxes-text')}>
            Select your preferred campaign(s) to continue
          </Typography>
        </div>
      ),
      []
    );

    const onConfirm = useCallback(
      (reviewers: Id[], message?: string) => {
        onSubmit(reviewers, message);
      },
      [onSubmit]
    );

    useEffect(() => {
      if (approvalRequestLoading === LoadingState.Finished) {
        setIsModalVisible(false);
      }
    }, [approvalRequestLoading]);

    return (
      <SRequestApprovalFooter>
        <div className={classes('info')}>{isWarningVisible ? warningMessage : counter}</div>

        <div className={classes('buttons')}>
          <Button
            theme={THEME.light}
            onClick={onCancel}
            type={BUTTON_TYPE.secondary}
            caption="Cancel"
            className={classes('cancel')}
            data-selector="request-approval-cancel-button"
          />

          <Button
            theme={THEME.light}
            onClick={onFooterSubmit}
            caption="Submit Request"
            data-selector="request-approval-submit-button"
          />
        </div>

        <RequestApprovalModal
          isOpened={isModalVisible}
          className={classes('request-approval-modal')}
          reviewers={reviewers}
          isConfidentialProject={isConfidentialProject}
          isLoading={approvalRequestLoading === LoadingState.Started}
          onConfirm={onConfirm}
          onCancel={onModalCancel}
        />
      </SRequestApprovalFooter>
    );
  }
);
