import React, { SyntheticEvent, useCallback, useRef, useState } from 'react';
import { BEM_CLASS, SAgeInput } from './s-age-input';
import { bem } from 'utils/bem';
import InputMask from 'react-input-mask';
import { prepareAgeInputValue } from '../../../transducers';

interface Props {
  value?: string;
  onChange(string): void;
  onBlur?(): void;
  mask: string;
  placeholder: string;
  className?: string;
  maskInputClassName?: string;
}

const classes = bem(BEM_CLASS);

export const AgeInput = React.memo(
  React.forwardRef<HTMLDivElement, Props>(
    ({ value, onChange, onBlur, mask, placeholder, className, maskInputClassName }: Props, ref) => {
      const inputRef = useRef<HTMLInputElement>(null);

      const [inputValue, setInputValue] = useState<string>(prepareAgeInputValue(value));

      const onChangeInputValue = useCallback(
        (event: SyntheticEvent<HTMLInputElement>) => {
          const { value } = event.currentTarget;
          setInputValue(value);
          onChange(value);
        },
        [onChange]
      );

      const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key !== 'Enter') return;
        inputRef.current?.blur();
      }, []);

      return (
        <SAgeInput ref={ref} className={className}>
          <InputMask
            autoFocus
            mask={mask}
            className={classes('mask-input', undefined, maskInputClassName)}
            maskPlaceholder={null}
            alwaysShowMask={true}
            onBlur={onBlur}
            onChange={onChangeInputValue}
            onKeyDown={onKeyDown}
            value={inputValue}
            placeholder={placeholder}
          />
        </SAgeInput>
      );
    }
  )
);
