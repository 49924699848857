import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Widget } from 'common/constants';
import { bem } from 'utils/bem';
import { required, requiredArray, requiredStringMax200Formik } from 'utils/validation';
import { Field } from 'common/components/form/formik-field';
import { SRow } from 'common/s-components/layout/s-row';
import { SCol } from 'common/s-components/layout/s-col';
import { SPaper } from 'common/s-components/s-paper';
import { Id } from 'backend-api/models';
import {
  getArtistOptions,
  getMomentEditableFields,
  getMomentTypeOptions,
  getSelectedMomentTypeOptionId,
} from 'artist-moment/transducers';
import { activeMomentSelector, projectSelector } from 'artist-moment/selectors';
import { SSpacer } from 'common/s-components/layout/s-spacer';
import { useArtistMomentCategories, useArtistMomentTypes, useTerritories } from 'hooks';
import { BEM_CLASS, SBody } from './s-body';
import { Header } from '../header';
import { RemoveButton } from '../remove-button';
import { CUSTOM_MOMENT_TYPE_OPTION_ID } from 'artist-moment/constants';
import { ArtistMomentInput, MomentInfo } from 'artist-moment/actions';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { useFormikContext } from 'formik';
import { FieldComposition } from 'common/components/form/limited-length-text-field';

type Props = MomentInfo;

const classes = bem(BEM_CLASS);

export const Body = React.memo(({ projectId, momentId }: Props) => {
  const { values, submitForm, isValid } = useFormikContext<ArtistMomentInput>();
  const moment = useSelector(activeMomentSelector);
  const project = useSelector(projectSelector);
  const territories = useTerritories();
  const editableFields = getMomentEditableFields(moment);

  const momentTypes = useArtistMomentTypes();
  const momentCategories = useArtistMomentCategories();

  const artistOptions = useMemo(() => getArtistOptions(project), [project]);

  const typeOptions = useMemo(() => getMomentTypeOptions(momentTypes), [momentTypes]);
  const selectedMomentTypeId = useMemo(() => getSelectedMomentTypeOptionId(typeOptions, values), [typeOptions, values]);

  const normalizeArtistIdFromOptionId = useCallback(
    (id: Id) => artistOptions.find(option => option.id === id)?.artistId,
    [artistOptions]
  );

  const formatOptionIdArtistId = useCallback((id: string) => artistOptions.find(option => option.artistId === id)?.id, [
    artistOptions,
  ]);

  const [isCustomType, setIsCustomType] = useState(false);

  useEffect(() => {
    setIsCustomType(selectedMomentTypeId === CUSTOM_MOMENT_TYPE_OPTION_ID);
  }, [selectedMomentTypeId]);

  const onLinkClick = useCallback(() => {
    if (!moment) return;
    trackEvent(AnalyticsEvents.ARTIST_MOMENT_LINK_CLICKED, {
      moment_category: moment.category?.name,
      moment_type: moment.type?.name,
      moment_source: moment.createUser?.name,
    });
  }, [moment]);

  return (
    <SBody data-selector="moment-edit-view">
      <Header momentId={momentId} projectId={projectId} saveEditing={submitForm} isValid={isValid} />
      <SPaper pt="18px" pb="2px" mt="21px" mb="10px" color="white">
        <SRow>
          <SCol width={1}>
            <Field
              bgStyle="grey"
              name="name"
              dataSelector="moment-name-field"
              type={Widget.Text}
              label="Moment Name"
              validate={requiredStringMax200Formik}
              isRequired={editableFields.name}
              wide
              disabled={!editableFields.name}
            />
          </SCol>
        </SRow>
      </SPaper>
      <SPaper pt="18px" pb="2px" mt="13px" mb="10px" color="white">
        <SRow>
          <SCol width={13 / 28}>
            <Field
              dataSelector="moment-artist-selector"
              name="artistId"
              label="Artist"
              type={Widget.Select}
              bgStyle="grey"
              placeholder=""
              options={artistOptions}
              validate={required}
              format={formatOptionIdArtistId}
              isSearchable
              isRequired={editableFields.artist}
              normalize={normalizeArtistIdFromOptionId}
              disabled={!editableFields.artist}
            />
          </SCol>
          <SSpacer />
          <SCol width={13 / 28}>
            <Field
              name="date"
              label="Date"
              type={Widget.Date}
              bgStyle="grey"
              validate={required}
              isRequired={editableFields.date}
              dataSelector="moment-date"
              calendarInputDataSelector="moment-date-input-field"
              isSearchable={false}
              disabled={!editableFields.date}
            />
          </SCol>
        </SRow>
        <SRow>
          <SCol width={13 / 28}>
            <Field
              dataSelector="moment-type-selector"
              name="typeId"
              label="Type"
              type={Widget.Select}
              bgStyle="grey"
              options={typeOptions}
              validate={required}
              isRequired={editableFields.type}
              isSearchable
              disabled={!editableFields.type}
            />
          </SCol>
          <SSpacer />
          <SCol width={13 / 28}>
            <Field
              dataSelector="moment-countries-field"
              name="territoriesIds"
              label="Countries"
              type={Widget.MultiSelect}
              tree
              placeholder=""
              options={territories}
              bgStyle="grey"
              defaultExpandedKeys={['0']}
              validate={requiredArray}
              isRequired={editableFields.territories}
              isSearchable
              disabled={!editableFields.territories}
            />
          </SCol>
        </SRow>
        {isCustomType && (
          <SRow>
            <SCol width={13 / 28}>
              <Field
                dataSelector="moment-category-selector"
                name="categoryId"
                label="Category"
                type={Widget.Select}
                bgStyle="grey"
                options={momentCategories || []}
                validate={required}
                isRequired={editableFields.territories}
                isSearchable
                disabled={!editableFields.category}
              />
            </SCol>
          </SRow>
        )}
        <SRow>
          <SCol width={1}>
            <Field
              dataSelector="moment-link-field"
              name="links"
              type={Widget.Links}
              label="LINK (OPTIONAL)"
              maxLength={1}
              bgStyle="grey"
              wide
              onLinkClick={onLinkClick}
              disabled={!editableFields.link}
            />
          </SCol>
        </SRow>
      </SPaper>
      <SPaper pt="18px" pb="2px" mt="21px" mb="10px" color="white">
        <SRow>
          <SCol width={1}>
            <Field
              dataSelector="moment-notes-field"
              name="notes"
              type={Widget.LimitedTextField}
              label="NOTES (OPTIONAL)"
              placeholder="Add a moment note…"
              bgStyle="grey"
              wide
              disabled={!editableFields.notes}
              maxLength={150}
              inputClassName={classes('notes')}
              composition={FieldComposition.HORIZONTAL}
            />
          </SCol>
        </SRow>
      </SPaper>
      {project && moment && (
        <div className={classes('remove-button')}>
          <RemoveButton project={project} moment={moment} />
        </div>
      )}
    </SBody>
  );
});
