import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'login';

export const SLogin = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;

  .${BEM_CLASS} {
    &__logo {
      margin-bottom: 60px;
    }

    &__action-button {
      width: 230px;
      margin-bottom: 20px;
    }

    &__contact-section {
      color: ${manhattanThemeColors.grayedText};
    }

    &__contact-link {
      margin-left: 5px;
      padding-bottom: 6px;
      border-bottom: dotted 1px ${manhattanThemeColors.grayedText};
      color: ${manhattanThemeColors.primaryDark};
    }
  }
`;
