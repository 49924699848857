import { MAX_CURRENCY_VALUE, PATTERNS, VALIDATION_MESSAGES } from '../constants';
import { trim } from 'lodash';

export const required = value => {
  const result = Array.isArray(value) ? value.map(str => trim(str)).join('') : trim(value);
  return result ? undefined : VALIDATION_MESSAGES.required;
};

export const requiredArray = value => {
  return (Array.isArray(value) && value.length === 0) || !value ? VALIDATION_MESSAGES.required : undefined;
};

export const maxLength = max => value =>
  value && value.length > max
    ? // ? `Must be ${max} characters or less.`
      VALIDATION_MESSAGES.maxLength
    : undefined;

export const currency = value =>
  value >= MAX_CURRENCY_VALUE
    ? // ? `Must be ${max} characters or less.`
      VALIDATION_MESSAGES.currency
    : undefined;

export const phone = value => {
  const sanitizedValue = value?.replace(/_/g, '');
  const valid = sanitizedValue && !PATTERNS.phone.test(sanitizedValue) ? VALIDATION_MESSAGES.phone : undefined;

  return valid;
};

export const number = value => (value && isNaN(Number(value)) ? VALIDATION_MESSAGES.number : undefined);

export const email = value => (value && !PATTERNS.email.test(value) ? VALIDATION_MESSAGES.email : undefined);

// COMBO validators
// https://github.com/erikras/redux-form/issues/3405#issuecomment-332574295
export const requiredStringMax200Formik = value => {
  return required(value) || maxLength(200)(value);
};

export const requiredEmailMax50Formik = value => {
  return required(value) || email(value) || maxLength(50)(value);
};

export const requiredPhoneFormik = value => {
  return required(value) || phone(value);
};

export const requiredNumberFormik = value => {
  return required(value) || number(value);
};

export const requiredCurrencyFormik = value => {
  return required(value) || currency(value);
};

export const requiredStringMax100Formik = value => {
  return required(value) || maxLength(100)(value);
};

//LEGACY combinators for Redux-Form
//TODO remove this when fully migrated to formik

export const requiredStringMax200 = [required, maxLength(200)];

export const requiredEmailMax50 = [required, email, maxLength(50)];

export const requiredPhone = [required, phone];

export const requiredNumber = [required, number];

export const requiredCurrency = [required, currency];

export const requiredStringMax100 = [required, maxLength(100)];
