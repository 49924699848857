import styled, { css } from 'styled-components';
import backgroundIcon from 'assets/wave.svg';
import pngIcon from 'assets/background.png';
import { DEFAULT_PROJECT_HEADER_HEIGHT, TOP_HEADER_HEIGHT } from 'common/constants';
import { WaveBackgroundMainProps } from './types';
import { WAVE_GRADIENT_END_COLOR } from './constants';

export const BEM_CLASS = 'wave-background';

const DEFAULT_WAVE_WIDTH = '58%';

const dimentions = css<WaveBackgroundMainProps>`
  width: 100%;
  min-height: ${({ withTabs, heightFromChildren }) =>
    !heightFromChildren &&
    (withTabs
      ? `calc(100vh - ${DEFAULT_PROJECT_HEADER_HEIGHT + TOP_HEADER_HEIGHT}px)`
      : `calc(100vh - ${TOP_HEADER_HEIGHT}px)`)};
`;

export const SWaveBackground = styled.div<WaveBackgroundMainProps>`
  ${dimentions};
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: none;

  & > :not(:first-child) {
    pointer-events: all;
  }

  .${BEM_CLASS} {
    &__background {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: inherit;
      height: ${({ backHeight }) => backHeight || '100%'};
      min-height: ${({ backHeight }) => backHeight || 'inherit'};
      overflow: hidden;

      &::after {
        ${dimentions};
        height: inherit;
        content: '';
        display: block;
        background-image: ${({ waveWidth, waveHeight }) =>
            waveWidth || waveHeight ? `url(${pngIcon})` : `url(${backgroundIcon})`}
          ${({ mainColor }) =>
            mainColor
              ? ``
              : `, linear-gradient(
              180deg,
              rgba(240, 240, 245, 1) 8%,
              ${WAVE_GRADIENT_END_COLOR} 100%
            )`};
        background-color: ${({ mainColor }) => mainColor};
        background-attachment: scroll;
        background-size: ${({ waveWidth }) => waveWidth || DEFAULT_WAVE_WIDTH}
            ${({ waveHeight }) => waveHeight || 'auto'},
          100%;
        background-repeat: no-repeat;
        background-position: right top, top right;
      }
    }
  }
`;
