import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTaxonomy } from 'hooks';
import { bem } from 'utils/bem';
import { CampaignSearch } from 'backend-api/models';
import { ResultTable } from '../result-table';
import { hideGlobalSearch } from 'common/actions';
import { Link } from 'react-router-dom';
import { TooltipedText } from 'common/components/tooltiped-text';
import {
  getCampaignBadge,
  getCampaignIcon,
  getCampaignLink,
  getCampaignNameOrPlaceholder,
  getScheduleDate,
} from 'app/components/search-modal/transducers';
import { BEM_CLASS, SCampaignsResult } from './s-campaigns-result';
import { dateFormatSelector } from 'common/selectors';

const classes = bem(BEM_CLASS);

interface CampaignsResultProps {
  campaigns: CampaignSearch[];
}

export const CampaignsResult = React.memo(({ campaigns }: CampaignsResultProps) => {
  const dispatch = useDispatch();
  const taxonomy = useTaxonomy();
  const dateFormat = useSelector(dateFormatSelector);

  const onLinkClick = useCallback(() => {
    dispatch(hideGlobalSearch());
  }, [dispatch]);

  const tableConfig = useMemo(
    () => [
      {
        title: '',
        fraction: 'max-content',
        render: (campaign: CampaignSearch) => {
          return <span className={classes('cell', 'icon')}>{getCampaignIcon(campaign, taxonomy?.categories)}</span>;
        },
      },
      {
        title: 'Name',
        fraction: '1fr',
        render: ({ uuid, name, project, isUnassigned, isPending }: CampaignSearch) => {
          const link = getCampaignLink(isUnassigned, uuid, project);
          const campaignBadge = getCampaignBadge(isUnassigned, isPending);

          return (
            <Link
              to={`${link}?fromSearch=1`}
              className={classes('cell', 'name')}
              onClick={onLinkClick}
              data-selector="search-result-campaign-item"
            >
              <TooltipedText text={getCampaignNameOrPlaceholder(name)}>
                <span>{getCampaignNameOrPlaceholder(name)}</span>
              </TooltipedText>
              <div className={classes('badge')}>{campaignBadge}</div>
            </Link>
          );
        },
      },
      {
        title: 'Project',
        fraction: '1fr',
        render: ({ project }: CampaignSearch) => {
          const projectName = project?.name || '';

          return (
            <span className={classes('cell', 'project')}>
              <TooltipedText text={projectName}>
                <span>{projectName}</span>
              </TooltipedText>
            </span>
          );
        },
      },
      {
        title: 'Schedule',
        fraction: 'max-content',
        render: ({ startDate, endDate }: CampaignSearch) => {
          const schedule = getScheduleDate(startDate, endDate, dateFormat);

          return <span className={classes('cell', 'date')}>{schedule}</span>;
        },
      },
    ],
    [taxonomy?.categories, onLinkClick, dateFormat]
  );

  return (
    <SCampaignsResult>
      <ResultTable config={tableConfig} data={campaigns} />
    </SCampaignsResult>
  );
});
