import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';
import { ARTIST_MARGIN, RENDERER_TOP_MARGIN } from './constants';

export const BEM_CLASS = 'artists-renderer';

export const SArtistsRenderer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__container {
      margin-top: ${RENDERER_TOP_MARGIN}px;
      display: flex;
      flex-wrap: wrap;
    }

    &__artist-link {
      margin: 0 ${ARTIST_MARGIN}px ${ARTIST_MARGIN}px 0;
    }

    &__chip {
      margin-top: 4px;
      margin-bottom: 10px;
    }

    &__empty-state {
      margin-top: 1px;
      color: ${colorPalette.aluminiumGrey};
    }
  }
`;
