import React from 'react';
import { bem } from 'utils/bem';
import { SLoaderAllProjects } from './s-all-projects-loader';

const rectangleClasses = bem('loader-all-projects');
const CLIP_PATH_ID = 'loader-all-projects';

export const AllProjectsLoader = React.memo(() => {
  return (
    <SLoaderAllProjects clipPathId={CLIP_PATH_ID}>
      <div className={rectangleClasses('clip-container')} />
      <svg width="100%" height="0">
        <defs>
          <clipPath id={CLIP_PATH_ID}>
            <path
              d={`M0 0 15 0 15 15 0 15 0 0z
                  M0 25 15 25 15 40 0 40 0 25z
                  M0 50 15 50 15 65 0 65 0 50z
                  M25 0 175 0 175 15 25 15 25 0z
                  M25 25 275 25 275 40 25 40 25 25z
                  M25 50 175 50 175 65 25 65 25 50z`}
            />
          </clipPath>
        </defs>
      </svg>
    </SLoaderAllProjects>
  );
});
