import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';

export const BEM_CLASS = 'start-date-renderer';

export const SValidationTooltipContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  .${BEM_CLASS} {
    &__error-text {
      font-size: 12px;
      color: ${colorPalette.cinnabar};
    }
  }
`;
