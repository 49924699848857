import React, { useCallback } from 'react';
import { bem } from 'utils/bem';
import { Tooltip } from 'common/components/tooltip';
import { Icon } from 'common/components/icon';
import { SActionBadge, BEM_CLASS } from './s-action-badge';
import { IconName } from 'common/components/icon/types';

interface Props {
  tooltipText: string;
  title: string;
  iconName: IconName;
  dataSelector?: string;
  onClick(text: string): void;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ActionBadge = React.memo(({ className, tooltipText, title, iconName, dataSelector, onClick }: Props) => {
  const onBadgeClick = useCallback(() => onClick(title), [onClick, title]);

  return (
    <Tooltip content={tooltipText}>
      <SActionBadge onClick={onBadgeClick} className={className}>
        <div className={classes('text')} data-selector={`action-badge-${dataSelector}`}>
          {title}
        </div>
        <Icon name={iconName} color="inherit" className={classes('icon')} />
      </SActionBadge>
    </Tooltip>
  );
});
