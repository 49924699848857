import styled from 'styled-components';
import { ellipsizeText } from 'common-v2/styles/ellipsize-text';
import { manhattanThemeColors } from 'app/theme/colors';

interface SUserSearchOptionProps {
  isDisabled: boolean;
  isSelected: boolean;
}

export const BEM_CLASS = 'user-search-option';

export const SUserSearchOption = styled.li<SUserSearchOptionProps>`
  .${BEM_CLASS} {
    &__container {
      display: flex;
      align-items: flex-start;
      max-width: 100%;
      width: 100%;
      padding: 10px 20px;
    }

    &__avatar {
      flex-shrink: 0;
      margin-top: 4px;
      color: ${({ isDisabled }) =>
        isDisabled ? manhattanThemeColors.secondGrayedText : manhattanThemeColors.primaryDark};
    }

    &__content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin: 0 10px;
      overflow: hidden;
    }

    &__name,
    &__email {
      ${ellipsizeText}
      color: ${({ isDisabled }) =>
        isDisabled ? manhattanThemeColors.secondGrayedText : manhattanThemeColors.primaryDark};
    }
  }
`;
