import React from 'react';
import { bem } from 'utils/bem';
import { colorPalette } from 'app/theme/colors';
import { BEM_CLASS, SNoPermission } from './s-no-permission';
import { WaveBackground } from 'common/components/wave-background';
import { Footer } from 'app/components/footer';
import RestrictedAccessGray from 'assets/restricted-access-gray.svg';

const classes = bem(BEM_CLASS);

interface Props {
  title: React.ReactNode;
  information: React.ReactNode;
}

export const NoPermission = React.memo(({ title, information, children }: React.PropsWithChildren<Props>) => (
  <WaveBackground mainColor={colorPalette.white}>
    <SNoPermission>
      <img className={classes('image')} src={RestrictedAccessGray} alt="No permissions" />
      <span className={classes('restriction')} data-selector="access-restricted-title">
        {title}
      </span>
      <span className={classes('information')} data-selector="access-restricted-text">
        {information}
      </span>
      {children}
    </SNoPermission>
    <Footer />
  </WaveBackground>
));
