import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';

export const BEM_CLASS = 'table';

export const STable = styled.div`
  &&& {
    .${BEM_CLASS} {
      &__container {
        height: max-content;
      }

      &__small-cell {
        width: 120px;
      }

      &__medium-cell {
        width: 160px;
      }

      &__role-cell {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__role-cell-icon {
        height: 20px;
        width: 20px;
        margin-right: 11px;
      }

      &__project-cell-title {
        font-weight: 600;
      }

      &__project-cell-artists {
        font-size: 12px;
        color: ${palette.darkBlueTwo};
      }
    }
  }
`;
