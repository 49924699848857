import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'notification';

export const SNotification = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${palette.darkBlueTwo};
  height: 100%;
  padding: 0 38px 0 10px;

  .${BEM_CLASS} {
    &__icon {
      flex-shrink: 0;
      margin-right: 10px;
    }

    &__text {
      color: ${palette.white};
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
      ${elipsizeText};
    }

    &__close {
      position: absolute;
      top: 50%;
      right: 8px;
      transform: translateY(-50%);
      display: flex;
    }
  }
`;
