import { Id } from 'backend-api/models';
import { ALL_OPTION_ID } from 'common/components/form/select';
import { TreeItemType, TreeNode } from 'common/components/tree-list';
import { SearchUser } from 'modals/sharing-modal/types';

export const getUsersTreeNodes = (
  users: SearchUser[],
  selectedUserIds: Id[],
  canSelectMoreUsers: boolean
): TreeNode[] => {
  const usersCopy = [...users];

  return [
    {
      id: ALL_OPTION_ID,
      name: 'All',
      type: TreeItemType.CHECKBOX,
      children: usersCopy.map(user => ({
        id: user.id,
        name: user.name,
        children: [],
        isDisabled: !selectedUserIds.includes(user.id) && !canSelectMoreUsers,
        type: TreeItemType.CHECKBOX,
      })),
    },
  ];
};
