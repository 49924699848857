import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';

export const SEmptyResult = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${manhattanThemeColors.secondGrayedText};
`;
