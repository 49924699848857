import React, { useCallback, useMemo, useState } from 'react';
import { bem } from 'utils/bem';
import { MenuPlacement, Select, SelectType } from 'common/components/select';
import { Id, MarketingMix } from 'backend-api/models';
import { BEM_CLASS, SMarketingMixCategoriesDropdown } from './s-marketing-mix-categories-dropdown';
import { getOptions } from './transducers';
import { ALL_CATEGORIES_OPTION } from './constants';

interface Props {
  marketingMix: MarketingMix[];
  onChange(marketingMix?: MarketingMix): void;
  className?: string;
}

const classes = bem(BEM_CLASS);

export function MarketingMixCategoriesDropdown({ className, marketingMix, onChange }: Props) {
  const [selectedId, setSelectedId] = useState(ALL_CATEGORIES_OPTION.id);

  const options = useMemo(() => getOptions(marketingMix), [marketingMix]);

  const onSelectChange = useCallback(
    (id: Id) => {
      setSelectedId(id);
      const selectedCategory = marketingMix.find(marketingMix => marketingMix.id === id);
      onChange(selectedCategory);
    },
    [marketingMix, onChange]
  );

  return (
    <SMarketingMixCategoriesDropdown data-selector="marketing-mix-categories-dropdown" className={className}>
      <Select
        type={SelectType.Base}
        valueId={selectedId}
        valueHandler={onSelectChange}
        options={options}
        classNames={{
          control: classes('select-control'),
        }}
        placement={MenuPlacement.BottomRight}
      />
    </SMarketingMixCategoriesDropdown>
  );
}
