import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';
import { ellipsizeText } from 'common-v2/styles';

export const BEM_CLASS = 'popup';

export const SPopup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 350px;
  max-height: 290px;
  padding: 15px 0;
  border-radius: 10px;
  background-color: ${manhattanThemeColors.background};
  box-shadow: 0 5px 20px 0 ${transparentize(0.8, manhattanThemeColors.primaryDark)};
  overflow: hidden;

  .${BEM_CLASS} {
    &__loader {
      padding: 6.5px 20px 48.5px;
    }

    &__list {
      overflow: auto;
    }

    &__item {
      display: flex;
      align-items: flex-start;
      width: 100%;
      padding: 7px 20px;
      flex-shrink: 0;
      cursor: pointer;

      &:hover {
        .${BEM_CLASS} {
          &__controls-container {
            display: flex;
          }
        }
      }

      &:not(&--selected) {
        &:hover {
          background-color: ${transparentize(0.95, manhattanThemeColors.primaryFillNew)};
        }
      }

      &--selected {
        background-color: ${transparentize(0.9, manhattanThemeColors.primaryFillNew)};
      }

      &--editing {
        .${BEM_CLASS} {
          &__controls-container {
            display: flex;
          }
        }
      }
    }

    &__name {
      ${ellipsizeText};

      margin-right: 5px;
      width: 100%;
      text-align: left;
    }

    &__controls-container {
      display: none;
      align-items: center;
      margin-left: auto;

      & > :not(:first-child) {
        margin-left: 15px;
      }
    }

    &__new-item {
      display: flex;
      align-items: flex-start;
      padding: 15px 20px 5px;
      flex-shrink: 0;

      .${BEM_CLASS} {
        &__controls-container {
          display: flex;
        }
      }
    }

    &__input-container {
      flex: 1;
      margin-right: 10px;
    }

    &__input {
      width: 230px;
      height: 25px;
    }

    &__footer {
      padding: 15px 20px 5px;
    }

    &__error {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 21.5px 20px 23.5px;
    }

    &__error-text {
      margin-bottom: 10px;
      color: ${manhattanThemeColors.grayedText};
      text-align: center;
    }

    &__error-button {
      margin: 0 auto;
      border-bottom: 1px dotted ${manhattanThemeColors.primaryDark};
    }
  }
`;
