import React, { useCallback, useRef } from 'react';
import { BEM_CLASS, SSingleValueView } from './s-single-value-view';
import { bem } from 'utils/bem';
import { SpreadsheetTooltip } from '../spreadsheet-tooltip';

interface Props {
  value: string;
  className?: string;
  tooltipDisabled?: boolean;
}

const classes = bem(BEM_CLASS);

export const SingleValueView = ({ value, className, tooltipDisabled }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleOnShow = useCallback(() => {
    if (!ref.current) return false;
    if (ref.current.scrollWidth <= ref.current.clientWidth) {
      return false;
    }

    return;
  }, []);

  return (
    <SpreadsheetTooltip content={value} onShow={handleOnShow} disabled={tooltipDisabled}>
      <SSingleValueView className={className}>
        <div className={classes('content')} ref={ref} data-selector="single-value-view-value">
          {value}
        </div>
      </SSingleValueView>
    </SpreadsheetTooltip>
  );
};
