import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { ArtistRosterProject, ProjectDetails } from 'backend-api/models';
import { getStatusColorForProject, getStatusIconForProject, getStatusNameForProject } from 'project/transducers';
import { Icon } from 'common/components/icon';
import { Tooltip } from 'common/components/tooltip';
import { STitle } from './s-title';
import { H3 } from 'common/components/typography';
import { Badge, BadgeType } from 'common/components/badge';

interface Props {
  text: string;
  project?: ProjectDetails | ArtistRosterProject;
  lastBadgeElement?: React.ReactNode;
  dataSelector?: string;
  className?: string;
}

const classes = bem('title');

export const Title = React.memo(({ text, project, dataSelector, className, lastBadgeElement }: Props) => {
  const statusIcon = useMemo(() => getStatusIconForProject(project), [project]);
  const statusName = useMemo(() => getStatusNameForProject(project), [project]);
  const statusColor = useMemo(() => getStatusColorForProject(project), [project]);

  const isConfidential = project && 'isConfidential' in project && project.isConfidential;

  return (
    <STitle statusColor={statusColor} className={className}>
      <H3 className={classes('name')} data-selector={dataSelector}>
        <Tooltip content={text}>
          <span data-selector={dataSelector} className={classes('text')}>
            {text}
          </span>
        </Tooltip>
      </H3>
      {isConfidential && (
        <Badge
          type={BadgeType.Outlined}
          className={classes('confidential-badge')}
          text={'Confidential'}
          dataSelector="confidential-badge-select"
        />
      )}
      {statusName && (
        <div className={classes('status-container')}>
          {statusIcon && <Icon name={statusIcon} color="white" size="glyph" className={classes('status-icon')} />}
          <span data-selector={`${dataSelector}-status`} className={classes('status-text')}>
            {statusName}
          </span>
        </div>
      )}
      {lastBadgeElement}
    </STitle>
  );
});
