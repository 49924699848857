import React from 'react';
import { Typography, TYPOGRAPHY_TYPE, Button, BUTTON_TYPE, BUTTON_SIZE, THEME } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { SHeader, BEM_CLASS } from './s-header';

interface HeaderProps {
  className?: string;
  title: string;
  closeModal(): void;
}

const classes = bem(BEM_CLASS);

export const Header = React.memo(({ className, title, closeModal }: HeaderProps) => (
  <SHeader className={className}>
    <Typography className={classes('title')} type={TYPOGRAPHY_TYPE.heading2} data-selector="modal-header-title">
      {title}
    </Typography>

    <Button
      type={BUTTON_TYPE.tertiary}
      size={BUTTON_SIZE.smallRound}
      theme={THEME.light}
      icon="cross"
      onClick={closeModal}
      data-selector="modal-header-close-button"
    />
  </SHeader>
));
