import styled from 'styled-components';
import { SInput } from 'common/components/form/input/s-input';
import { BgStyle } from 'common/styles/utils';

interface SNotesProps {
  readonly expanded: boolean;
  readonly disabled?: boolean;
  readonly bgStyle?: BgStyle;
}

export const SNotes = styled.div<SNotesProps>`
  position: relative;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

  .notes {
    &__input {
      flex: 1;
      min-height: ${({ expanded }) => (expanded ? '595' : '125')}px;
      max-height: ${({ expanded }) => (expanded ? '595' : '125')}px;
      overflow: scroll;
      ${SInput};
      border-radius: 10px;
      text-align: start;
      color: black;
      word-break: break-all;
    }
    &__icon {
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 8px;
      top: ${({ expanded }) => (expanded ? '28' : '8')}px;
      cursor: pointer;
      &:hover {
        background-color: rgba(218, 218, 230, 1);
        border-radius: 18px;
      }
    }
    &__label {
      text-transform: uppercase;
      margin-bottom: 6px;
    }
  }
`;
