import { transparentize } from 'polished';
import { Id, MarketingMix, Optional } from 'backend-api/models';
import { OptionIdType } from 'common/components/form/select';
import { ColoredMarketingMix, TooltipFunction } from './types';
import { CAMPAIGN_CATEGORY_GROUPS } from 'common/constants';
import { BASE_OTHER_PLATFORM, LINKED_DIGITAL_CATEGORY_ID, MAX_SHOWN_INDIVIDUAL_PLATFORMS } from '../../constants';
import { colorPalette } from 'app/theme/colors';

export const getFilteredDigitalMix = (
  digitalMarketingMix: MarketingMix[],
  selectedDigitalMixId?: OptionIdType,
  selectedMarketingMixId?: Id
): MarketingMix[] => {
  let selectedDigitalMix = digitalMarketingMix.find(({ id }) => id === selectedMarketingMixId);

  if (!selectedDigitalMix) {
    selectedDigitalMix = digitalMarketingMix.find(({ id }) => id === selectedDigitalMixId);
  }

  if (selectedDigitalMix) {
    return [selectedDigitalMix];
  }

  return digitalMarketingMix;
};

export const getColorForTopMarketingMix = (marketingMix: MarketingMix): Optional<string> => {
  switch (marketingMix.id) {
    case LINKED_DIGITAL_CATEGORY_ID:
    case CAMPAIGN_CATEGORY_GROUPS.digital:
      return colorPalette.cerulean;
    case CAMPAIGN_CATEGORY_GROUPS.traditional:
      return colorPalette.amethyst;
    case CAMPAIGN_CATEGORY_GROUPS.radio:
      return colorPalette.mountainMeadow;
    case CAMPAIGN_CATEGORY_GROUPS.other:
      return colorPalette.gray;
    default:
      return undefined;
  }
};

export const reduceSubtypesToMaxShownNumber = (subtypes: ColoredMarketingMix[]) => {
  if (subtypes.length <= MAX_SHOWN_INDIVIDUAL_PLATFORMS || subtypes.some(type => type.id === BASE_OTHER_PLATFORM.id))
    return subtypes;

  const topPlatformsWithOther = subtypes.slice(0, MAX_SHOWN_INDIVIDUAL_PLATFORMS);

  const otherPlatform = subtypes.slice(MAX_SHOWN_INDIVIDUAL_PLATFORMS, subtypes.length).reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      budget: previousValue.budget + currentValue.budget,
    }),
    BASE_OTHER_PLATFORM
  );

  topPlatformsWithOther.push(otherPlatform);

  return topPlatformsWithOther;
};

const reduceMarketingMixRecursively = (marketingMix: MarketingMix[]) => {
  return reduceSubtypesToMaxShownNumber(marketingMix).map(item => ({
    ...item,
    subtypes: item.subtypes && reduceMarketingMixRecursively(item.subtypes.sort((a, b) => b.budget - a.budget)),
  }));
};

export const getSortedColoredMarketingMix = (
  marketingMix: MarketingMix[],
  parentColor?: string,
  depth = 0
): ColoredMarketingMix[] => {
  const isTop = depth === 0;
  const sortedMarketingMix = marketingMix.slice();

  if (!isTop) {
    sortedMarketingMix.sort((a, b) => b.budget - a.budget);
  }

  return reduceMarketingMixRecursively(sortedMarketingMix).map((currentMarketingMix, index) => {
    const color = isTop
      ? getColorForTopMarketingMix(currentMarketingMix)
      : parentColor
      ? transparentize(index / marketingMix.length, parentColor)
      : undefined;

    const subtypes = getSortedColoredMarketingMix(currentMarketingMix.subtypes, isTop ? color : undefined, depth + 1);

    return {
      ...currentMarketingMix,
      subtypes,
      color,
    };
  });
};

export const getMarketingMix = (
  marketingMix: ColoredMarketingMix[],
  marketingMixName: string
): Optional<ColoredMarketingMix> => {
  return marketingMix.find(marketingMix => marketingMix.name === marketingMixName);
};

export const getSubMarketingMix = (
  marketingMix: ColoredMarketingMix[] | ColoredMarketingMix,
  marketingMixName?: string
): ColoredMarketingMix[] => {
  if (!Array.isArray(marketingMix)) {
    return marketingMix.subtypes;
  }

  if (!marketingMixName) {
    return marketingMix;
  }

  return getMarketingMix(marketingMix, marketingMixName)?.subtypes || [];
};

export const getTotalBudget = (marketingMix: ColoredMarketingMix[]): number =>
  marketingMix.reduce((total, marketingMix) => total + marketingMix.budget, 0);

export const getFormattedPercentage = (budget: number, total: number): string => {
  if (budget <= 0 || total <= 0) return '0%';

  const percentage = Math.round((budget / total) * 1000) / 10;
  if (percentage < 1) return '<1%';

  return `${percentage}%`;
};

export const getMarketingMixChartData = (
  marketingMix: ColoredMarketingMix[],
  sortedSubMarketingMix: ColoredMarketingMix[]
): Highcharts.SeriesMapDataOptions[] => {
  const total = getTotalBudget(marketingMix);

  return sortedSubMarketingMix.map(subMarketingMix => {
    const { name, color } = subMarketingMix;
    const y = Math.round((subMarketingMix.budget / total) * 100 * 100) / 100;

    return {
      name,
      y,
      color,
    };
  });
};

export const getMarketingMixChartOptions = (
  marketingMix: ColoredMarketingMix[],
  sortedSubMarketingMix: ColoredMarketingMix[],
  getMarketingMixTooltip: TooltipFunction,
  projectCurrencyCode?: string
): Highcharts.Options => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'pie',
    spacing: [0, 0, 0, 0],
    height: 180,
  },
  title: undefined,
  tooltip: {
    enabled: true,
    outside: true,
    padding: 0,
    borderWidth: 0,
    borderRadius: 0,
    shadow: false,
    useHTML: true,
    style: {
      // @ts-ignore: width prop actually also accepts string type
      width: 'auto',
    },
    formatter() {
      return getMarketingMixTooltip(sortedSubMarketingMix, this.point, projectCurrencyCode);
    },
  },
  plotOptions: {
    pie: {
      size: 170,
      states: {
        hover: {
          halo: null,
        },
      },
    },
  },
  series: [
    {
      type: 'pie',
      dataLabels: { formatter: () => null },
      innerSize: '85%',
      data: getMarketingMixChartData(marketingMix, sortedSubMarketingMix),
    },
  ],
});
