import { Api } from 'backend-api';
import { asyncEpic } from 'core/epics';
import { getNewProjects, getRecentlyViewedProjects } from './actions';

export const getRecentlyViewedProjectsEpic = asyncEpic(
  getRecentlyViewedProjects,
  ({ payload }) => Api.getRecentlyViewedProjects(payload),
  undefined,
  { showError: false }
);

export const getNewProjectsEpic = asyncEpic(getNewProjects, ({ payload }) => Api.getNewProjects(payload), undefined, {
  showError: false,
});
