import React, { useCallback, useEffect, useMemo } from 'react';
import { SDatePickerPopup } from './s-date-picker-popup';
import ReactDOM from 'react-dom';
import {
  DATE_PICKER_ROOT,
  DatePickerPopupProps,
  PopupAlignment,
  PopupPosition,
  SingleDatePickerProps,
} from 'common/components/form/date-picker';
import { AnimatePresence } from 'framer-motion';
import { CalendarPicker } from './components';
import { bem } from 'utils/bem';
import { callbackWithoutPropagation } from 'utils/event';
import { isRangeComponentsProps } from '../../guards';
import { Period } from 'common/types';

interface InnerProps {
  position: PopupPosition;
  isVisible: boolean;
  onClickOutside(): void;
  isValueInvalid: boolean;
  availableDates?: Period[] | Period;
}

type Props = (DatePickerPopupProps | SingleDatePickerProps) & InnerProps;

const appearingAnimation = {
  shown: { opacity: 1 },
  hidden: { opacity: 0 },
  exit: { opacity: 0 },
};

const transition = { duration: 0.2, type: 'tween' };

const classes = bem('date-picker-popup');

export const DatePickerPopup = React.memo(
  React.forwardRef<HTMLDivElement, Props>((props: Props, containerRef) => {
    const {
      position: { top, left },
      isVisible,
      onClickOutside,
      isValueInvalid,
      alignment = PopupAlignment.Auto,
      availableDates,
    } = props;

    useEffect(() => {
      window.addEventListener('click', onClickOutside);

      return () => {
        window.removeEventListener('click', onClickOutside);
      };
    }, [onClickOutside]);

    const getBody = useCallback(() => {
      if (!isRangeComponentsProps(props)) {
        return (
          <div className={classes('calendar-container')}>
            <CalendarPicker
              availableDates={availableDates}
              value={props.value}
              onChange={props.onChange}
              mode={props.mode}
            />
            <div className={classes('message', { error: isValueInvalid })}>{props.annotationMessage}</div>
          </div>
        );
      }

      return (
        <>
          <div className={classes('calendar-container')} data-selector="date-picker-popup">
            <CalendarPicker
              availableDates={availableDates}
              value={props.value}
              onChange={props.onChange}
              mode={props.mode}
              maxPeriodDays={props.maxPeriodDays}
            />
            <div className={classes('message', { error: isValueInvalid })}>{props.annotationMessage}</div>
          </div>
          {props.sidebar}
        </>
      );
    }, [availableDates, isValueInvalid, props]);

    const render = useMemo(
      () => (
        <AnimatePresence>
          {isVisible && (
            <SDatePickerPopup
              transition={transition}
              initial="hidden"
              animate="shown"
              exit="exit"
              variants={appearingAnimation}
              top={top}
              left={left}
              alignment={alignment}
              ref={containerRef}
              onClick={callbackWithoutPropagation()}
            >
              {getBody()}
            </SDatePickerPopup>
          )}
        </AnimatePresence>
      ),
      [alignment, containerRef, getBody, isVisible, left, top]
    );

    return DATE_PICKER_ROOT && ReactDOM.createPortal(render, DATE_PICKER_ROOT);
  })
);
