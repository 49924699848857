import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { BEM_CLASS, SCountriesEditor } from './s-countries-editor';
import { ICellEditorParams } from 'ag-grid-community';
import { ClassNamesProps } from 'common/components/select';
import { bem } from 'utils/bem';
import { MultiSelect } from 'common/components/select/multi-select';
import { MultiSelectType } from 'common/components/select/types';
import { prepareCountries, prepareCountriesOptions } from './transducers';
import { OptionIdType } from 'common/components/form/select';
import { GLOBAL_TERRITORY_ID } from 'common/constants';
import { useSelectEditorKeyboardInteractions, useStopEditingBlur } from 'media-plan/components/spreadsheet/hooks';
import { MAX_COUNT_CLEARABLE } from 'common/components/select/constants';
import { ExpandableField } from '../../expandable-field';

const classes = bem(BEM_CLASS);

export const CountriesEditor = React.memo(
  React.forwardRef((props: ICellEditorParams, ref) => {
    const [value, setValue] = useState(props.value.value);
    const { onBlur } = useStopEditingBlur(props.api);

    const countries = useMemo(() => prepareCountries(props.colDef?.cellEditorParams.countries), [
      props.colDef?.cellEditorParams.countries,
    ]);
    const options = useMemo(() => prepareCountriesOptions(countries, value), [countries, value]);

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return {
            ...props.value,
            value,
          };
        },
      };
    });

    const handleChange = useCallback(
      (ids: OptionIdType[]) => {
        if (ids.includes(GLOBAL_TERRITORY_ID)) {
          const globalCountry = countries.find(country => country.id === GLOBAL_TERRITORY_ID);
          setValue([globalCountry]);
        } else {
          const selectedCountries = countries.filter(country => country.id && ids.includes(country.id)) || [];
          setValue(selectedCountries);
        }
      },
      [countries]
    );

    const { onKeyDown, selectRef } = useSelectEditorKeyboardInteractions({
      api: props.api,
      value,
      setValue: handleChange,
      isMulti: true,
    });

    const selectClassNames = useCallback<(className: string) => ClassNamesProps>(
      className => ({
        control: classes('control'),
        valueContainer: { root: classes('value-container') },
        menu: classes('menu'),
        menuList: classes('menu-list'),
        indicatorsContainer: classes('indicators-container'),
        menuPortal: className,
      }),
      []
    );

    useEffect(() => {
      selectRef.current?.inputRef?.focus({ preventScroll: true });
    }, [selectRef]);

    return (
      <ExpandableField hasError={false}>
        <SCountriesEditor width={props.column.getActualWidth()} height={props.node.rowHeight || 0}>
          {className => (
            <div className={className}>
              <MultiSelect
                selectRef={selectRef}
                type={MultiSelectType.AutoComplete}
                dataSelector="country-editor"
                valueIds={value?.map(value => value.id) || []}
                valueHandler={handleChange}
                options={options}
                onBlur={onBlur}
                isSearchable
                classNames={selectClassNames(className)}
                placeholder=""
                isClearable={value?.length >= MAX_COUNT_CLEARABLE}
                isOpened
                onKeyDown={onKeyDown}
              />
            </div>
          )}
        </SCountriesEditor>
      </ExpandableField>
    );
  })
);
