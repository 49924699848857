import { apiClient, ResponseArray } from '../index';
import { AvailablePeriodSchema } from '../models/analytic';
import { GetAvailableDatesParams } from '../types/analytic';

export const getAvailableDates = (params: GetAvailableDatesParams) =>
  apiClient.executeRequest({
    method: 'GET',
    endpoint: 'analytics/projects/available-dates',
    responseSchema: ResponseArray(AvailablePeriodSchema),
    queryParams: params,
  });
