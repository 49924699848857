import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'purchase-orders-header';

export const SPurchaseOrdersHeader = styled.div`
  display: flex;
  align-items: center;
  > *:not(last-child) {
    margin-right: 20px;
  }
  margin-top: 29px;

  .${BEM_CLASS} {
    &__title {
      flex: 1;
    }

    &__search {
      flex: 1;
      max-width: 280px;
    }

    &__selector-control {
      &--isGray {
        background-color: ${colorPalette.ghostWhite};
      }
    }
  }
`;
