import styled from 'styled-components';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'members-list';

export const SMembersList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 15px;
  padding-right: 10px;
  overflow-x: hidden;
  overflow-y: auto;

  .${BEM_CLASS} {
    &__block {
      &:not(:last-child) {
        padding-bottom: 15px;
        border-bottom: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};
      }
    }
  }
`;
