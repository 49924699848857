import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { bgStyle, BgStyle } from 'common/styles/utils';

interface SRadioButtonProps {
  readonly isSelected: boolean;
}

interface SRadioGroupProps {
  readonly disabled?: boolean;
  readonly bgStyle: BgStyle;
}

export const SRadioButton = styled.label<SRadioButtonProps>`
  height: 35px;
  display: flex;
  align-items: center;
  background-color: ${({ isSelected }) => (isSelected ? palette.white : 'transparent')};
  border-radius: 20px;
  border: 1px solid ${({ isSelected }) => (isSelected ? palette.gray173 : 'transparent')};
  color: ${({ isSelected }) => (isSelected ? palette.black : palette.darkBlueThree)};
  transition: color 0.3s, background 0.3s, border-color 0.3s;
  cursor: pointer;

  .radio-button {
    &__radio {
      display: none;
    }
    &__name {
      padding: 0 20px;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1px;
      text-shadow: none;
      text-transform: uppercase;
    }
    &__icon {
      display: flex;
      width: 62px;
      padding: 6px 20px;
    }
  }
`;

export const SRadioGroup = styled.div<SRadioGroupProps>`
  display: inline-flex;
  background-color: ${bgStyle};
  border-radius: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
`;
