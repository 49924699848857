import { ICellRendererParams } from 'ag-grid-community';
import { manhattanThemeColors } from 'app/theme/colors';
import { LabelPermissionsArtistWithMembers, LabelPermissionsArtistInfo } from 'backend-api/models';
import { Avatar } from 'common-v2/components';
import { transparentize } from 'polished';
import React from 'react';
import { Link } from 'react-router-dom';
import { bem } from 'utils/bem';
import { BEM_CLASS, SUserNameRenderer } from './s-user-name-renderer';

const classes = bem(BEM_CLASS);

export const UserNameRenderer = React.memo(
  ({ value }: ICellRendererParams<LabelPermissionsArtistWithMembers[], LabelPermissionsArtistInfo>) => (
    <SUserNameRenderer>
      <Avatar
        name={value.name}
        size={30}
        avatarContent={{ url: value.imageUrl }}
        avatarColor={transparentize(0.85, manhattanThemeColors.primaryFillNew)}
        className={classes('avatar')}
      />
      <div className={classes('username')}>
        <Link target="_blank" rel="no-follow" to={`/artists/${value.id}`}>
          {value.name}
        </Link>
      </div>
    </SUserNameRenderer>
  )
);
