import { Id, LabelUser } from 'backend-api/models';
import { MembersResults, ProcessedMembers } from './types';

export const processMembers = (
  labelUsers: LabelUser[],
  recentlyAddedMembers: LabelUser[],
  removedMembersIds: Id[],
  artistsMembersIds: Id[]
): ProcessedMembers => {
  const alreadyAddedMembers: LabelUser[] = [];
  const membersForAssignAll: LabelUser[] = [];
  const membersForSearch: LabelUser[] = [];

  labelUsers.forEach(labelArtist => {
    const isUserArtist = artistsMembersIds.includes(labelArtist.id);
    const isRemovedFromUserArtists = removedMembersIds.includes(labelArtist.id);

    const isAlreadyAddedArtist = isUserArtist && !isRemovedFromUserArtists;
    if (isAlreadyAddedArtist) {
      alreadyAddedMembers.push(labelArtist);
    }

    const isRecentlyAdded = recentlyAddedMembers.some(recentlyAddedArtist => recentlyAddedArtist.id === labelArtist.id);
    const isNotAdded = !(isUserArtist || isRecentlyAdded);
    const canBeAdded = !isRecentlyAdded && isRemovedFromUserArtists;
    const isArtistAvailableForSearch = isNotAdded || canBeAdded;

    if (isArtistAvailableForSearch) {
      membersForSearch.push(labelArtist);
    }

    const isAssignableUser = !isUserArtist || isRemovedFromUserArtists;
    if (isAssignableUser) {
      membersForAssignAll.push(labelArtist);
    }
  });

  return {
    alreadyAddedMembers: alreadyAddedMembers,
    membersForAssignAll: membersForAssignAll,
    membersForSearch: membersForSearch,
  };
};

export const processMembersResults = (
  initialArtistMembersIds: Id[],
  recentlyAddedMembers: LabelUser[],
  removedMembersIds: Id[]
): MembersResults => {
  const addedArtistsIds = recentlyAddedMembers
    .filter(({ id }) => !initialArtistMembersIds.includes(id))
    .map(({ id }) => id);
  const deletedArtistsIds = removedMembersIds.filter(id => !recentlyAddedMembers.some(artist => artist.id === id));
  return { addedMembersIds: addedArtistsIds, deletedMembersIds: deletedArtistsIds };
};
