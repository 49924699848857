import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { RowNode } from 'ag-grid-community';
import { autogeneratedNamesSelector } from 'media-plan/selectors';
import { LoadingState } from 'common/types';
import { MediaPlanColumnId, SpreadsheetRowData } from '../types';

export const useAutogeneratedNames = (
  gridRef: React.MutableRefObject<AgGridReact<SpreadsheetRowData> | null | undefined>
) => {
  const dispatch = useDispatch();

  const autogeneratedNames = useSelector(autogeneratedNamesSelector);

  useEffect(() => {
    const api = gridRef?.current?.api;

    if (api && autogeneratedNames.loading === LoadingState.Finished) {
      const updatedNodes: RowNode[] = [];
      const updateTransaction: SpreadsheetRowData[] = [];

      autogeneratedNames.data.forEach(({ campaignUuid, namingConvention }) => {
        const node = api.getRowNode(campaignUuid);

        if (node && node.data) {
          updatedNodes.push(node);
          updateTransaction.push({
            ...node.data,
            namingConvention: {
              ...node.data.namingConvention,
              value: namingConvention,
            },
          });
        }
      });

      api.applyTransaction({
        update: updateTransaction,
      });

      api.refreshCells({
        columns: [MediaPlanColumnId.NAMING_CONVENTION],
        rowNodes: updatedNodes,
        force: true,
      });
    }
  }, [autogeneratedNames, dispatch, gridRef]);
};
