import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import React from 'react';
import { bem } from 'utils/bem';
import { convertToDataSelector } from 'utils/string';
import { SInviteMenu, BEM_CLASS } from './s-invite-menu';

interface InviteMenuProps {
  className?: string;
  isDisabled: boolean;
  options: { title: string; action: () => void }[];
}

const classes = bem(BEM_CLASS);

export const InviteMenu = React.memo(({ className, isDisabled, options }: InviteMenuProps) => (
  <SInviteMenu className={className}>
    {options.map(({ title, action }) => (
      <button
        key={title}
        className={classes('item', { isDisabled })}
        onClick={action}
        data-selector={`invite-menu-${convertToDataSelector(title)}-button`}
      >
        <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('text')}>
          {title}
        </Typography>
      </button>
    ))}
  </SInviteMenu>
));
