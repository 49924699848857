import 'tippy.js/animations/shift-away.css?raw';

import React, { useRef } from 'react';
import { TippyProps } from '@tippyjs/react';
import { tooltip as tooltipZIndex } from 'app/styles/z-indexes';
import { STooltip } from '../s-components/s-tooltip';

export interface TooltipProps extends Omit<TippyProps, 'content' | 'children'> {
  content?: React.ReactChild | React.ReactChild[] | null;
  children: React.ReactNode;
  enabled?: boolean;
  zIndex?: number;
}

export const Tooltip = React.memo(
  ({
    content,
    maxWidth,
    zIndex,
    arrow = true,
    placement = 'bottom',
    children,
    appendTo = 'parent',
    enabled = true,
    ...rest
  }: TooltipProps) => {
    const tooltip = useRef<Element>(null);
    const element = React.isValidElement(children) ? children : <span>{children}</span>;

    return (
      <STooltip
        ref={tooltip}
        arrow={arrow}
        placement={placement}
        content={content || ''}
        maxWidth={maxWidth || 300}
        disabled={!enabled}
        animation="shift-away"
        appendTo={appendTo}
        zIndex={zIndex || tooltipZIndex}
        interactive
        {...rest}
      >
        {element}
      </STooltip>
    );
  }
);
