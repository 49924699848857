import React from 'react';
import { bem } from 'utils/bem';

const classes = bem('project-edit-form');

export const EmptyState = () => {
  const message = (
    <>
      No results to show.
      <br />
      Try changing search parameters.
    </>
  );

  return <div className={classes('empty-state')}>{message}</div>;
};
