import { palette } from 'frontend-shared-theme';
import first from 'lodash/first';
import last from 'lodash/last';
import isEqual from 'lodash/isEqual';
import {
  Artist,
  ArtistRosterProject,
  ArtistRosterProjectSchema,
  ArtistSearch,
  ArtistType,
  ChangeTeamUserRequest,
  Contact,
  Id,
  Label,
  Optional,
  Phase,
  PhaseCampaign,
  Playlist,
  ProjectDetails,
  ProjectDetailsSchema,
  ProjectRequest,
  Provider,
  TeamUser,
  UserRoleType,
} from 'backend-api/models';
import { isPlaylists } from 'backend-api/guards';
import { TARGET_OPTIONS } from './constants';
import { ProjectInput } from './actions';
import { MAX_DATE_MILLIS, UNASSIGNED_STATUS } from 'common/constants';
import {
  getPrimaryTargets,
  getStatusIconName,
  getStatusName,
  isEmptyMarkdown,
  mapLinkfireSearchToBaseOption,
} from 'common/transducers';
import { IconName } from 'common/components/icon/types';
import { DraggableItem } from 'common/components/multiple-lists-drag-n-drop';
import { Period } from 'common/types';
import { NameWithDates, PhaseSortFields } from 'project/types';
import { Sort, SortOrder } from 'utils/sort';
import { colorPalette } from 'app/theme/colors';
import { PerformanceDsp } from 'backend-api/types';
import { SearchUser } from 'modals/sharing-modal/types';
import {
  substractDaysFromDate,
  addDaysToDate,
  getMinDate,
  getMaxDate,
  isValidDate,
  getDiffInMiliseconds,
} from 'common-v2/utils';
import { getProjectName } from 'common-v2/transducers';

export const prepareRequest = (values: ProjectInput): ProjectRequest => {
  const isArtists = TARGET_OPTIONS.find(type => type.id === values.type)?.name === 'Artist';
  return {
    targets: isArtists
      ? {
          items: values.artists,
          type: 'Artist',
        }
      : {
          items: values.playlists,
          type: 'Playlist',
        },
    name: values.name,
    startDate: values.startDate || new Date(),
    endDate: values.endDate || new Date(),
    notes: isEmptyMarkdown(values.notes) ? undefined : values.notes,
    scheduleUpdates: undefined,
    linkfireLinks:
      values.linkfireLinks?.map(option => ({
        id: option.modelId,
        linkId: option.linkId,
        linkUrl: option.linkUrl,
        deletable: option.isFixed,
      })) || [],
  };
};

export const prepareInitialValues = (project: ProjectDetails): ProjectInput => {
  const targetsType = TARGET_OPTIONS.find(type => type.name === project.targets.type)?.id;
  const type = targetsType || TARGET_OPTIONS[0].id;

  return {
    artists: project.targets.type === 'Artist' ? project.targets.items : [],
    playlists: project.targets.type === 'Playlist' ? project.targets.items : [],
    name: getProjectName(project),
    budget: project.budget,
    allocation: project.allocation,
    startDate: project.startDate,
    endDate: project.endDate,
    notes: project.notes || '<p></p>',
    type,
    labelId: project.label?.id,
    linkfireLinks: mapLinkfireSearchToBaseOption(project.linkfireLinks || []),
  };
};

export const getInitialValues = (
  id?: Id,
  project?: ProjectDetails,
  currentUser?: Contact,
  labels?: Label[]
): ProjectInput | undefined => {
  const defaultInitialValues = {
    type: TARGET_OPTIONS[0].id,
    labelId: labels?.[0]?.id,
    ownerId: currentUser?.id,
    phone: `+${currentUser?.phone}`,
    email: currentUser?.email,
    artists: [],
    playlists: [],
    name: getProjectName(project),
  };

  if (!id) return defaultInitialValues;
  if (!project) return undefined;

  return prepareInitialValues(project);
};

export const getUserInitials = (userName: string): string => {
  const names = userName.split(' ');
  let letters = first(names)?.charAt(0) || '';

  if (names.length > 1) {
    letters += last(names)?.charAt(0) || '';
  }

  return letters;
};

export const prepareShareRequestDeleteUser = (user: TeamUser): Optional<ChangeTeamUserRequest> => {
  const hasApproverAndAdminRoles = [UserRoleType.Approver, UserRoleType.Admin].every(roleId =>
    user.roles.some(role => role.id === roleId)
  );

  if (hasApproverAndAdminRoles) return;

  return prepareShareRequestUser(user);
};

export const prepareShareRequestUser = (user: TeamUser): Optional<ChangeTeamUserRequest> => {
  const filteredRoles = user.roles.filter(role => role.id !== UserRoleType.Approver);
  const primaryRole = filteredRoles[0];

  if (!primaryRole) {
    return;
  }

  return {
    userId: user.id,
    roleId: primaryRole.id,
    categoryIds: primaryRole.categoryIds,
  };
};

export const buildApolloPlaylistUrl = (playlist: Playlist) =>
  `${process.env.APPOLO_BASE_URL}${playlist.provider}/playlist/${playlist.id}`;

export const iconByProvider = (provider: Provider): IconName => {
  switch (provider) {
    case 'apple':
      return 'apple';
    case 'spotify':
      return 'spotify';
    default:
      return 'apple';
  }
};

export const getProjectAvailableStartDates = (phases: Phase[], endDate?: Date): Period => {
  const unEditableCampaigns: PhaseCampaign[] = phases.flatMap(phase =>
    phase.campaigns.filter(campaign => !campaign.isEditable)
  );
  const minPhaseDays = phases.length !== 0 ? phases.length - 1 : 0;
  const minCampaignsLength = substractDaysFromDate(endDate, minPhaseDays);
  if (unEditableCampaigns.length === 0) {
    return [new Date(0), isValidDate(minCampaignsLength) ? minCampaignsLength : new Date(MAX_DATE_MILLIS)];
  }
  const startDates = unEditableCampaigns.map(campaign => campaign.startDate);
  const earliestCampaignsStartDate = getMinDate(startDates);
  const lastAvailableDate = isValidDate(minCampaignsLength)
    ? getMinDate([minCampaignsLength, earliestCampaignsStartDate])
    : earliestCampaignsStartDate;

  return [new Date(0), lastAvailableDate];
};

export const getProjectAvailableEndDates = (phases: Phase[], startDate?: Date): Period => {
  const unEditableCampaigns: PhaseCampaign[] = phases.flatMap(phase =>
    phase.campaigns.filter(campaign => !campaign.isEditable)
  );
  const minPhaseDays = phases.length !== 0 ? phases.length - 1 : 0;
  const minCampaignsLength = addDaysToDate(startDate, minPhaseDays);
  if (unEditableCampaigns.length === 0) {
    return [isValidDate(minCampaignsLength) ? minCampaignsLength : new Date(0), new Date(MAX_DATE_MILLIS)];
  }
  const endDates = unEditableCampaigns.map(campaign => campaign.endDate);
  const latestCampaignsEndDate = getMaxDate(endDates);
  const firstAvailableDate = isValidDate(minCampaignsLength)
    ? getMaxDate([minCampaignsLength, latestCampaignsEndDate])
    : latestCampaignsEndDate;

  return [firstAvailableDate, new Date(MAX_DATE_MILLIS)];
};

export const getUnassignedProjectAvailableStartDates = (project: ProjectDetails, endDate?: Date): Period => {
  const latestEndDate = project?.metadata?.latestEndDate
    ? addDaysToDate(project.metadata.latestEndDate, 1)
    : endDate ?? new Date(MAX_DATE_MILLIS);
  return [new Date(0), latestEndDate];
};

export const getUnassignedProjectAvailableEndDates = (project: ProjectDetails, startDate?: Date): Period => {
  const earliestEndDate = project?.metadata?.earliestStartDate || startDate || new Date(0);
  return [earliestEndDate, new Date(MAX_DATE_MILLIS)];
};

export const typedArtistFromArtistSearch = (artist: ArtistSearch, type: ArtistType = 'Primary'): Artist => ({
  ...artist,
  type,
  isPRS: false,
  isUnknown: false,
});

export const getTargetsLimitMessage = (isPlaylist: boolean) =>
  `You reached ${isPlaylist ? 'playlists' : 'artists'} limit. Remove a selection to add a new one.`;

export const getStatusIconForProject = (project?: ProjectDetails | ArtistRosterProject): IconName | undefined => {
  if (!project) {
    return undefined;
  }

  if (ProjectDetailsSchema.is(project) && !project.isClaimed) {
    return 'unassigned-status';
  }

  return ProjectDetailsSchema.is(project) && project.status ? getStatusIconName(project.status) : undefined;
};

export const getStatusNameForProject = (project?: ProjectDetails | ArtistRosterProject): string | undefined => {
  if (!project) {
    return undefined;
  }

  if (ProjectDetailsSchema.is(project) && !project.isClaimed) {
    return UNASSIGNED_STATUS.name;
  }

  return (ProjectDetailsSchema.is(project) || ArtistRosterProjectSchema.is(project)) && project.status
    ? getStatusName(project.status)
    : undefined;
};

export const getStatusColorForProject = (project?: ProjectDetails | ArtistRosterProject): string | undefined => {
  if (!project) {
    return undefined;
  }

  if (ProjectDetailsSchema.is(project) && !project.isClaimed) {
    return palette.black;
  }

  return palette.pink;
};

export const isArtistLocked = (artist: Artist): boolean =>
  !!artist.isPRS && artist.type === 'Primary' && !artist.isUnknown;

export const getDraggableArtistItems = (items: Artist[]): (Artist & DraggableItem)[] =>
  items.map(item => ({ ...item, isDragDisabled: isArtistLocked(item) }));

export const hasLockedArtist = (artists: Artist[]): boolean => artists.some(artist => isArtistLocked(artist));

export const hasUnknownArtist = (artists: Artist[]): boolean =>
  artists.some(artist => isArtistLocked(artist) && artist.isUnknown);

export const getProjectLockedArtist = (project?: ProjectDetails): Optional<Artist> => {
  if (!project) {
    return undefined;
  }
  const primaryTargets = getPrimaryTargets(project.targets.items);

  if (isPlaylists(primaryTargets)) {
    return undefined;
  }

  return primaryTargets.find(artist => isArtistLocked(artist));
};

export const getFieldsWithDiffValues = <T>(firstObj: T, secondObj: T): string[] => {
  if (!firstObj || !secondObj) return [];

  return Object.keys(firstObj).reduce((result, key) => {
    return isEqual(firstObj[key], secondObj[key]) ? result : result.concat(key);
  }, <string[]>[]);
};

export const orderNameWithDates = (prev: NameWithDates, next: NameWithDates, sort: Sort<PhaseSortFields>): number => {
  const orderedPrev = sort.order === SortOrder.Ascending ? prev : next;
  const orderedNext = sort.order === SortOrder.Ascending ? next : prev;
  switch (sort.field) {
    case 'name':
      return orderedPrev.name.localeCompare(orderedNext.name);
    case 'startDate':
      return getDiffInMiliseconds(orderedPrev.startDate, orderedNext.startDate);
    case 'endDate':
      return getDiffInMiliseconds(orderedPrev.endDate, orderedNext.endDate);
  }
};

export const getColumnColor = (platform?: PerformanceDsp): string => {
  switch (platform) {
    case 'facebook':
      return colorPalette.azureRadiance;
    case 'google':
      return colorPalette.chateauGreen;
    default:
      return colorPalette.viking;
  }
};

export const getShareProjectSuccessMessage = (
  added: ChangeTeamUserRequest[],
  deleted: ChangeTeamUserRequest[],
  updated: ChangeTeamUserRequest[]
): string => {
  const isDeleteOrUpdate = deleted.length > 0 || updated.length > 0;
  if (added && !isDeleteOrUpdate) return 'Collaborators successfully added to this project.';

  return 'Project collaborators successfully edited.';
};

export const prepareSearchUsers = (users: SearchUser[]): SearchUser[] =>
  users.sort((a, b) => a.name.localeCompare(b.name));
