import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  disableRequestApprovalButtonSelector,
  mediaPlanModeSelector,
  mediaPlanSelector,
} from 'media-plan-v2/selectors';
import { changeMediaPlanMode } from 'media-plan-v2/actions';
import { MediaPlanMode } from 'media-plan-v2/types';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { LoadingState } from 'common/types';
import { bem } from 'utils/bem';
import { Id } from 'backend-api/models';
import { useShowRequestApprovalButton } from 'media-plan-v2/hooks';
import {
  RequestApprovalControl,
  SettingsControl,
  FullScreenControl,
} from 'media-plan-v2/containers/media-plan-header/components';
import { SControlsContainer, BEM_CLASS } from './s-controls-container';

interface ControlsContainerProps {
  projectId: Id;
  isFullScreen: boolean;
  onFullscreenClick(): void;
  onExportClick(): void;
}

const classes = bem(BEM_CLASS);

export const ControlsContainer = React.memo(
  ({ projectId, isFullScreen, onFullscreenClick, onExportClick }: ControlsContainerProps) => {
    const mediaPlanMode = useSelector(mediaPlanModeSelector);
    const mediaPlan = useSelector(mediaPlanSelector);
    const shouldDisableRequestApprovalButton = useSelector(disableRequestApprovalButtonSelector);
    const shouldShowRequestApprovalButton = useShowRequestApprovalButton(projectId, mediaPlanMode);

    const dispatch = useDispatch();

    const isFailedToLoadMediaPlan = mediaPlan.loading === LoadingState.Failed;

    const shouldShowSettings = mediaPlanMode !== MediaPlanMode.APPROVAL;

    const handleRequestApprovalPress = useCallback(() => {
      trackEvent(AnalyticsEvents.APPROVAL_REQUEST_STARTED, { projectId });
      return dispatch(changeMediaPlanMode(MediaPlanMode.REQUEST_APPROVAL));
    }, [dispatch, projectId]);

    return (
      <SControlsContainer>
        {shouldShowRequestApprovalButton && (
          <RequestApprovalControl
            disabled={shouldDisableRequestApprovalButton}
            isTooltipShown={shouldDisableRequestApprovalButton && !isFailedToLoadMediaPlan}
            handleRequestApprovalClick={handleRequestApprovalPress}
          />
        )}

        {shouldShowSettings && <SettingsControl disabled={isFailedToLoadMediaPlan} onExportClick={onExportClick} />}

        <div className={classes('fullscreen')}>
          <FullScreenControl
            disabled={isFailedToLoadMediaPlan}
            isFullScreen={isFullScreen}
            onFullscreenClick={onFullscreenClick}
          />
        </div>
      </SControlsContainer>
    );
  }
);
