import styled from 'styled-components';
import { theme } from 'app/theme';

export const H1 = styled.h1`
  ${theme.typography.h1};
`;

export const H2 = styled.h2`
  ${theme.typography.h2};
`;

export const H3 = styled.h3`
  ${theme.typography.h3};
`;

export const H4 = styled.h4`
  ${theme.typography.h4};
`;

export const Body = styled.span`
  ${theme.typography.body};
`;

export const PrimaryLabel = styled.span`
  ${theme.typography.primaryLabel};
`;

export const SecondaryLabel = styled.span`
  ${theme.typography.secondaryLabel};
`;

export const SmallText = styled.span`
  ${theme.typography.smallText};
`;
