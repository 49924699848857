import React, { useMemo } from 'react';
import { ArtistRosterItem } from 'backend-api/models';
import {
  BEM_CLASS,
  SSmallArtistItem,
} from 'projects/components/small-artists-section/components/small-artist-item/s-small-artist-item';
import { bem } from 'utils/bem';
import { SmallText } from 'common/components/typography';
import { pluralizeString } from 'utils/string';
import { LongTableTitle } from 'common/components/table';
import { DaysLeftBadge } from 'projects/components/small-artists-section/components/small-artist-item/days-left-badge';
import { MIN_DAYS_TO_SHOW_BADGE } from 'projects/components/small-artists-section/constants';
import { NoBrokenIconImage } from 'common/components/no-broken-icon-image';
import { Icon } from 'common/components/icon';
import { getDaysDiffInclusive } from 'common/transducers';

interface Props {
  item: ArtistRosterItem;
  onClick?(): void;
  canExpand?: boolean;
  isExpanded?: boolean;
  shouldShowDivider?: boolean;
}

export const classes = bem(BEM_CLASS);

const itemAppearingAnimation = {
  animate: { opacity: 1 },
  initial: { opacity: 0 },
  exit: { opacity: 1 },
  transition: { type: 'tween', duration: 0.3, delay: 0.1 },
};

export const SmallArtistItem = React.memo(({ item, onClick, canExpand, isExpanded, shouldShowDivider }: Props) => {
  //TODO change for real artist date after backend add
  const readyInDays = useMemo(
    () =>
      item.metadata.earliestProjectStartDate &&
      getDaysDiffInclusive(item.metadata.earliestProjectStartDate, new Date()),
    [item]
  );

  return (
    <SSmallArtistItem onClick={onClick} {...itemAppearingAnimation}>
      <div className={classes('artist-image')}>
        <NoBrokenIconImage src={item.artist.image?.url} className={classes('artist-image')} />
      </div>
      <div className={classes('info-container')}>
        <div className={classes('title-container')}>
          <LongTableTitle text={item.artist.name} textClassName={classes('title')} />
          {readyInDays && MIN_DAYS_TO_SHOW_BADGE >= readyInDays && (
            <DaysLeftBadge daysLeft={readyInDays} className={classes('days-badge')} />
          )}
        </div>
        <SmallText className={classes('projects-count')}>
          {item.metadata.projectsCount} {pluralizeString('project', item.metadata.projectsCount || 0)}
        </SmallText>
        {shouldShowDivider && <div className={classes('divider')} />}
      </div>
      <Icon
        className={classes('icon')}
        name={canExpand ? (isExpanded ? 'collapse' : 'expand') : 'arrow-right-thin'}
        color="black"
      />
    </SSmallArtistItem>
  );
});
