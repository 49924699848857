import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { bem } from 'utils/bem';
import { ProjectSearch } from 'backend-api/models';
import { ResultTable } from '../result-table';
import { BEM_CLASS, SProjectsResult } from './s-projects-result';
import { TargetsImages } from 'common/components/targets-images';
import { TooltipedText } from 'common/components/tooltiped-text';
import { hideGlobalSearch } from 'common/actions';
import { getProjectInfo } from 'app/components/search-modal/transducers';
import { Badge } from 'common/components/badge';
import { ConfidentialProjectIcon } from 'common/components/confidential-project-icon';
import { getPathToProjectPage } from 'common/transducers';
import { parseDateFromISOString } from 'common-v2/utils';
import { useFormattedDate } from 'common-v2/hooks';

const classes = bem(BEM_CLASS);

interface ProjectResultProps {
  projects: ProjectSearch[];
}

export const ProjectsResult = React.memo(({ projects }: ProjectResultProps) => {
  const dispatch = useDispatch();
  const formatDate = useFormattedDate();

  const onLinkClick = useCallback(() => {
    dispatch(hideGlobalSearch());
  }, [dispatch]);

  const tableConfig = useMemo(
    () => [
      {
        title: '',
        fraction: 'max-content',
        render: ({ targets }: ProjectSearch) => (
          <span className={classes('cell')}>
            <TargetsImages targets={targets.items} className={classes('avatar')} gap />
          </span>
        ),
      },
      {
        title: 'Name',
        fraction: '1fr',
        render: (project: ProjectSearch) => {
          const { name, isClaimed, isConfidential } = project;
          return (
            <ConfidentialProjectIcon isConfidential={isConfidential} className={classes('cell')} color="black">
              <Link
                to={`${getPathToProjectPage(project)}?fromSearch=1`}
                className={classes('cell', 'name')}
                onClick={onLinkClick}
                data-selector="search-result-project-item"
              >
                <TooltipedText text={name}>
                  <span>{name}</span>
                </TooltipedText>
                {!isClaimed && (
                  <div className={classes('badge')}>
                    <Badge text="Unclaimed" />
                  </div>
                )}
              </Link>
            </ConfidentialProjectIcon>
          );
        },
      },
      {
        title: 'Artist / Playlist',
        fraction: '1fr',
        render: ({ targets }: ProjectSearch) => (
          <span className={classes('cell', 'target')}>{getProjectInfo(targets.items)}</span>
        ),
      },
      {
        title: 'Start Date',
        fraction: 'max-content',
        render: ({ startDate }: ProjectSearch) => (
          <span className={classes('cell', 'date')}>
            {startDate ? formatDate(parseDateFromISOString(startDate)) : 'N/A'}
          </span>
        ),
      },
    ],
    [onLinkClick, formatDate]
  );

  return (
    <SProjectsResult>
      <ResultTable config={tableConfig} data={projects} />
    </SProjectsResult>
  );
});
