import React from 'react';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { STextValue, BEM_CLASS } from './s-text-value';

interface Props {
  value: React.ReactNode;
  textClassName?: string;
  className?: string;
  dataSelector?: string;
}

const classes = bem(BEM_CLASS);

export const TextValue = React.memo(
  React.forwardRef<HTMLDivElement, Props>(({ value, className, textClassName, dataSelector }, ref) => (
    <STextValue className={className}>
      <Typography
        type={TYPOGRAPHY_TYPE.body2}
        className={classes('content', undefined, textClassName)}
        data-selector={dataSelector}
      >
        {/* TODO: Remove when we add ref forwarding to Typography component */}
        <span ref={ref} className={classes('text')}>
          {value}
        </span>
      </Typography>
    </STextValue>
  ))
);
