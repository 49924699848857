import React, { useCallback, useState } from 'react';
import { MenuListProps } from 'react-select';
import { Checkbox, THEME, TYPOGRAPHY_TYPE, Typography } from 'gdb-web-shared-components';
import { SelectBaseOption } from 'common-v2/components';
import { bem } from 'utils/bem';
import { BEM_CLASS, SApprovalStatusMenu } from './s-approval-status-menu';

interface Props extends MenuListProps<SelectBaseOption, false> {
  onApplyToAll(checked: boolean): void;
}

const classes = bem(BEM_CLASS);

export const ApprovalStatusMenu = React.memo(({ onApplyToAll, innerProps, innerRef, children }: Props) => {
  const [checked, setChecked] = useState(false);

  const handleCheck = useCallback(() => {
    setChecked(!checked);
    onApplyToAll(!checked);
  }, [checked, onApplyToAll]);

  const handleCheckChanges = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
      onApplyToAll(event.target.checked);
    },
    [onApplyToAll]
  );
  return (
    <SApprovalStatusMenu {...innerProps} ref={innerRef} data-selector="approval-status-menu-list">
      <div className={classes('options')}>{children}</div>

      <div className={classes('divider')} />

      <div className={classes('apply-to-all')} data-selector="apply-to-all-phase-option">
        <div className={classes('checkbox-container')}>
          <Checkbox
            checked={checked}
            onChange={handleCheckChanges}
            data-selector="apply-to-all-phase-checkbox"
            theme={THEME.light}
          />
        </div>

        <Typography
          type={TYPOGRAPHY_TYPE.body2}
          className={classes('checkbox-label')}
          onClick={handleCheck}
          hasPadding={false}
        >
          Apply to All Phase
        </Typography>
      </div>
    </SApprovalStatusMenu>
  );
});
