import React from 'react';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { Badge } from 'common/components/badge';
import { Body, SmallText } from 'common/components/typography';
import { SPurchaseOrderLink, BEM_CLASS } from './s-purchase-order-link';
import { Id, PurchaseOrder } from 'backend-api/models';
import { paths } from 'app/routing/paths';
import { getPurchaseOrderNameWithoutPONumber } from 'common/transducers';

interface PurchaseOrderLinkProps {
  projectId: Id;
  purchaseOrder: PurchaseOrder;
}

const classes = bem(BEM_CLASS);

export const PurchaseOrderLink = React.memo(({ projectId, purchaseOrder }: PurchaseOrderLinkProps) => {
  return (
    <SPurchaseOrderLink rel="noopener noreferrer" target="_blank" to={paths.purchaseOrder(projectId, purchaseOrder.id)}>
      <div className={classes('icon')}>
        <Icon name="dollar" size="large" color="eastBay" />
      </div>
      <div className={classes('content')}>
        <div className={classes('head')}>
          <Body className={classes('title')}>{getPurchaseOrderNameWithoutPONumber(purchaseOrder.name)}</Body>
          <Badge className={classes('badge')} text={purchaseOrder.status} />
        </div>
        <SmallText className={classes('number')}>{`PO #${purchaseOrder.poNumber}`}</SmallText>
      </div>
    </SPurchaseOrderLink>
  );
});
