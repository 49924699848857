import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OutputSelector } from 'reselect';
import { PayloadActionCreator } from 'typesafe-actions';

interface FetchableSelectorHookOptions {
  readonly force?: boolean;
}

const defaultOptions: FetchableSelectorHookOptions = {
  force: false,
};

export const createFetchableSelectorHook = <S, I, O, P>(
  selector: OutputSelector<S, O, (res: I) => O>,
  getAction: PayloadActionCreator<string, P>,
  options?: FetchableSelectorHookOptions
) => {
  const hookOptions = { ...defaultOptions, ...options };

  return (params: P) => {
    const dispatch = useDispatch();
    const [requestCount, setRequestCount] = useState(0);

    const subject = useSelector(selector);

    useEffect(() => {
      const forceSingleRequest = hookOptions.force && requestCount < 1;
      if (forceSingleRequest || !subject) {
        dispatch(getAction(params));
      }
      if (forceSingleRequest) {
        setRequestCount(requestCount + 1);
      }
    }, [dispatch, subject, params, requestCount]);

    return subject;
  };
};
