import { SELECT_DROPDOWN_MAX_WIDTH } from 'common-v2/components';
import { SPREADSHEET_ROW_HEIGHT } from 'media-plan-v2/components/spreadsheet/constants';
import styled from 'styled-components';

export const BEM_CLASS = 'countries-editor';

interface Props {
  width: number;
  height: number;
}

export const SCountriesEditor = styled(({ className, children }) => children(className))<Props>`
  .${BEM_CLASS} {
    &__control {
      width: ${({ width }) => width}px;
      min-width: 160px;
      border-radius: 0;
    }

    &__value-root {
      height: 100%;
    }

    &__value-container {
      min-height: ${SPREADSHEET_ROW_HEIGHT}px;
      padding: 7px 20px 0;
    }

    &__value-footer {
      padding: 10px 20px;
    }

    &__indicators-container {
      padding-right: var(--cell-horizontal-padding);
      padding-left: var(--cell-horizontal-padding);
    }

    &__group {
      padding: 2px 0;
    }

    &__group-label {
      padding: 0 10px;
    }

    &__option {
      padding: 5px 10px;
    }

    &__menu {
      min-width: ${({ width }) => Math.min(width, SELECT_DROPDOWN_MAX_WIDTH)}px;
    }
  }
`;
