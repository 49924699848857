import React, { useMemo } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import {
  BaseRenderer,
  SingleValueView,
  SpreadsheetTooltip,
  ValidationTooltipContent,
} from 'media-plan-v2/components/spreadsheet/components';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { BEM_CLASS, SAgeRenderer } from './s-age-renderer';
import { useAudienceAgeValidation } from 'media-plan-v2/components/spreadsheet/hooks';
import { audienceAgeToString } from '../transducers';
import { AUDIENCE_AGE_VALIDATION_TEXT } from 'media-plan-v2/components/spreadsheet/constants';
import { SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';

const classes = bem(BEM_CLASS);

export const AgeRenderer = ({
  value: { color, value },
  context,
}: ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['age']>) => {
  const [isValueInvalid] = useAudienceAgeValidation(value);

  const validationMessage = useMemo(() => <ValidationTooltipContent text={AUDIENCE_AGE_VALIDATION_TEXT} />, []);

  return (
    <SpreadsheetTooltip content={validationMessage} disabled={!isValueInvalid}>
      <SAgeRenderer>
        <BaseRenderer
          className={classes('base-renderer')}
          color={color}
          placeholder={
            <Typography type={TYPOGRAPHY_TYPE.body2} className={classes('placeholder')}>
              13-99
            </Typography>
          }
          accessMode={context.accessMode}
        >
          {value && <SingleValueView className={classes('value')} value={audienceAgeToString(value)} />}
        </BaseRenderer>
      </SAgeRenderer>
    </SpreadsheetTooltip>
  );
};
