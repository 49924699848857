import React from 'react';
import { motion } from 'framer-motion';
import { bem } from 'utils/bem';
import { STableRowLoader } from './s-table-row-loader';
import { RowLoaderAlignment } from './types';

const classes = bem('table-row-loader');
const CLIP_PATH_ID = 'table-row-loader-path-id';

interface Props {
  alignment: RowLoaderAlignment;
  width: number;
}

export const TableRowLoader = React.memo(({ alignment, width }: Props) => {
  return (
    <STableRowLoader clipPathId={CLIP_PATH_ID} alignment={alignment} width={width}>
      <motion.tr>
        <div className={classes('loader')}>
          <div className={classes('clip-container')} />
          <svg width="100%" height="0">
            <defs>
              <clipPath id={CLIP_PATH_ID}>
                <rect x="0" y="0" rx="0" ry="0" width={width} height="15" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </motion.tr>
    </STableRowLoader>
  );
});
