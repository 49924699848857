import styled from 'styled-components';

interface Props {
  isInvalid?: boolean;
  onMouseDown: any;
}

export const SLinkContainer = styled.div<Props>`
  overflow: hidden;
  opacity: ${({ isInvalid }) => (isInvalid ? 0.7 : 1)};
`;
