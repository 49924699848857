import React, { useMemo, useCallback } from 'react';
import { bem } from 'utils/bem';
import { navigateTo } from 'utils/navigation';
import { Icon } from 'common/components/icon';
import { Button } from 'common/components/button';
import { Tooltip } from 'common/components/tooltip';
import { ACCESS_PERMISSIONS } from 'common-v2/constants';
import { usePermissionsChecker } from 'common-v2/hooks';
import { paths } from 'app/routing/paths';
import { Id } from 'backend-api/models';
import { SDetailsHeader, BEM_CLASS } from './s-details-header';
import { ProjectCollaboratorsButton, ProjectCollaboratorsButtonMode } from './components';
import { activeProjectSelector } from 'project/selectors';
import { useSelector } from 'react-redux';

interface Props {
  projectId: Id;
  collaboratorsCount?: number;
  canEditProjectCollaborators?: boolean;
  onViewProjectCollaborators(): void;
  onEditProjectCollaborators(): void;
}

const classes = bem(BEM_CLASS);

export const Header = React.memo(
  ({
    projectId,
    collaboratorsCount = 0,
    canEditProjectCollaborators = false,
    onViewProjectCollaborators,
    onEditProjectCollaborators,
  }: Props) => {
    const project = useSelector(activeProjectSelector);

    const handleEditClick = useCallback(() => navigateTo(paths.editProject(projectId)), [projectId]);

    const permissionsChecker = usePermissionsChecker(project);
    const canEditProject = permissionsChecker(ACCESS_PERMISSIONS.EDIT_PROJECT);

    const collaboratorsButtonMode = useMemo(() => {
      if (canEditProjectCollaborators) {
        return collaboratorsCount === 0 ? ProjectCollaboratorsButtonMode.SHARE : ProjectCollaboratorsButtonMode.MANAGE;
      }

      return ProjectCollaboratorsButtonMode.VIEW;
    }, [canEditProjectCollaborators, collaboratorsCount]);

    return (
      <SDetailsHeader>
        <h4>Details</h4>
        {!!project && project.isClaimed && (
          <div className={classes('buttons-container')}>
            <ProjectCollaboratorsButton
              mode={collaboratorsButtonMode}
              collaboratorsCount={collaboratorsCount}
              onClick={canEditProjectCollaborators ? onEditProjectCollaborators : onViewProjectCollaborators}
              className={classes('button')}
            />
            <Tooltip
              content={
                <>
                  <div>You have no rights to edit this project.</div>
                  <div>Please contact project editor.</div>
                </>
              }
              enabled={!canEditProject}
            >
              <Button
                disabled={!canEditProject}
                variant={!canEditProject ? 'secondary-white' : 'secondary-cta'}
                dataSelector="edit-project-button"
                onClick={handleEditClick}
              >
                <Icon name="edit" />
                Edit
              </Button>
            </Tooltip>
          </div>
        )}
      </SDetailsHeader>
    );
  }
);
