import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { ModalContentProps } from '../../types';
import { SContent } from './s-content';

export const Content = React.memo(({ isOpened, closeModal, children }: PropsWithChildren<ModalContentProps>) => {
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpened) {
      rootRef.current?.focus();
    }
  }, [isOpened]);

  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      if (event.target instanceof HTMLElement) {
        const isOutsideModal = !rootRef.current?.contains(event.target);

        if (isOutsideModal) {
          rootRef.current?.focus();
        }
      }
    };

    document.addEventListener('focus', handleFocus, true);

    return () => {
      document.removeEventListener('focus', handleFocus, true);
    };
  }, []);

  useEffect(() => {
    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleEscapePress, true);

    return () => {
      document.removeEventListener('keydown', handleEscapePress, true);
    };
  }, [closeModal]);

  return (
    <SContent ref={rootRef} tabIndex={0}>
      {children}
    </SContent>
  );
});
