import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'tooltip-content';

export const STooltipContent = styled.div`
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  .${BEM_CLASS} {
    &__label {
      &--isInvalid {
        color: ${colorPalette.osloGray};
      }
    }
  }
`;
