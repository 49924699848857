import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'single-value-view';

export const SSingleValueView = styled.div`
  overflow: hidden;

  .${BEM_CLASS} {
    &__content {
      ${elipsizeText};
    }
  }
`;
