import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';
import { darken, transparentize } from 'polished';
import { typography } from 'app/theme/typography';

export const BEM_CLASS = 'confirm-review-footer';

export const SConfirmReviewFooter = styled.div`
  display: grid;
  align-items: center;
  padding: 20px 30px;
  grid-template-columns: repeat(3, 1fr);
  background-color: ${colorPalette.white};
  box-shadow: 0 0 10px ${transparentize(0.5, colorPalette.coolGray)};
  border-top: 1px solid ${colorPalette.white};

  .${BEM_CLASS} {
    &__info {
      grid-column: 1 / 2;
    }

    &__button-container {
      grid-column: 2 / 3;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    &__button {
      min-width: 270px;
    }

    &__warning-container {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__empty-review-text {
      font-size: 12px;
      color: ${colorPalette.cinnabar};
    }

    &__home-page-button {
      ${typography.body};
      color: ${colorPalette.azureRadiance};
      font-weight: 600;

      :active {
        color: ${darken(0.1, colorPalette.azureRadiance)};
      }
    }
  }
`;
