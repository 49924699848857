import { palette } from 'frontend-shared-theme';

const getCSSCode = (color: string): string => `
text-decoration: none;
  box-shadow: inset 0 0 0 0 ${palette.white}, inset 0 -1px 0 0 ${color};
`;

export const getUnderline = (wrappedWithHover = true, color: string = palette.brightBlue): string =>
  wrappedWithHover
    ? `
  &:hover {
    ${getCSSCode(color)}
  }
`
    : getCSSCode(color);
