import styled from 'styled-components';

export const SArtistPlaylistSection = styled.div`
  .artist-playlist-section {
    &__paper-container,
    &__paper-container-featured {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__paper-container {
      padding: 20px 0;
    }
    &__paper-container-featured {
      padding-bottom: 20px;
    }
    &__cards-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;
    }
    &__title {
      width: 170px;
      text-transform: uppercase;
      align-self: flex-start;
      padding-top: 10px;
    }
  }
`;
