import { Sort, SortOrder } from 'utils/sort';
import { ProjectSortField } from 'backend-api/types';
import { BaseOption } from 'common/components/select';
import { ALL_OPTION_ID } from 'common/components/form/select';
import { PeriodOption, SnapshotPeriod } from './types';

export const DEFAULT_SORT: Sort<ProjectSortField> = new Sort(SortOrder.Descending, 'earliestStartDate');

export const DEFAULT_MY_PROJECTS_DROPDOWN_OPTION: BaseOption = {
  id: ALL_OPTION_ID,
  value: 'My Projects',
};

export const DEFAULT_MY_ARTISTS_DROPDOWN_OPTION: BaseOption = {
  id: ALL_OPTION_ID,
  value: 'My Artist Roster',
};

export const ALL_SNAPSHOT_PERIODS: SnapshotPeriod[] = [
  SnapshotPeriod.ONE_DAY,
  SnapshotPeriod.SEVEN_DAYS,
  SnapshotPeriod.FOURTEEN_DAYS,
  SnapshotPeriod.THIRTY_DAYS,
];

export const SNAPSHOT_DAYS_OPTIONS: PeriodOption[] = [
  {
    id: SnapshotPeriod.ONE_DAY,
    value: '1 Day',
  },
  {
    id: SnapshotPeriod.SEVEN_DAYS,
    value: '7 Days',
  },
  {
    id: SnapshotPeriod.FOURTEEN_DAYS,
    value: '14 Days',
  },
  {
    id: SnapshotPeriod.THIRTY_DAYS,
    value: '30 Days',
  },
];

export const SNAPSHOT_METRICS_COUNT = 6;
