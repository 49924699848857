export enum LoadingState {
  Idle = 'Idle',
  Started = 'Started',
  Finished = 'Finished',
  Failed = 'Failed',
}

export enum PartialLoadingState {
  Idle = 'Idle',
  Started = 'Started',
  StartedPartial = 'StartedPartial',
  Finished = 'Finished',
  Failed = 'Failed',
}

export type LoadableData<T> = { loading: LoadingState; data: T };

export type PartiallyLoadableData<T> = { loading: PartialLoadingState; data: T };
