import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { appearingAnimation } from 'common/s-components/s-appearing-animation';

export const SArtistPlaylistItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  background: ${palette.athensGrayTwo};
  border-radius: 5px;
  padding-right: 13px;
  min-width: 0;
  position: relative;
  ${() => appearingAnimation()};

  .artist-playlist-item {
    &__images-container {
      width: 60px;
      height: 60px;
      border-radius: 5px 0 0 5px;
    }
    &__info-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 19px;
      ${elipsizeText};
      min-width: 0;
    }
    &__name {
      color: ${palette.darkBlue};
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      padding-right: 10px;
      ${elipsizeText};
      min-width: 0;
    }
    &__owner-name {
      color: ${palette.darkBlue};
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      padding-right: 10px;
      ${elipsizeText}
    }
    &__checkbox {
      align-self: center;
      cursor: pointer;
    }
    &__icon {
      position: absolute;
      top: -10px;
      left: -8px;
      width: 26px;
      height: 26px;
      box-sizing: border-box;
      background: white;
      border-radius: 13px;
      padding: 0;
      border-color: white;
      border-style: solid;
    }

    &__lock-icon {
      display: flex;
      align-self: center;
    }
  }
`;
