import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'user-name-renderer';

export const SUserNameRenderer = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__username {
      padding: 10px;

      a {
        font-size: 14px;
        font-weight: 700;
        border-bottom: 1px dotted ${transparentize(0.4, colorPalette.woodsmoke)};
        color: ${colorPalette.black};

        &:hover {
          border-bottom: 1px solid ${transparentize(0.4, colorPalette.woodsmoke)};
        }
      }
    }

    &__avatar {
      border-radius: 5px;

      img {
        border-radius: inherit;
        width: 30px;
      }
    }
  }
`;
