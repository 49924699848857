import React from 'react';
import { Link, LinkProps } from 'common/components/link';
import { bem } from 'utils/bem';
import { SLinkLongTableTitle } from '../../s-components/table/s-link-long-table-title';
import { LongTableTitle } from './long-table-title';

interface Props {
  text: string;
  linkProps: LinkProps;
  dataSelector?: string;
  className?: string;
  containerClassName?: string;
}

const classes = bem('link-long-table-title');

export const LinkLongTableTitle = React.memo(
  ({ text, dataSelector, className, containerClassName, linkProps }: Props) => (
    <SLinkLongTableTitle className={containerClassName}>
      <Link {...linkProps} data-selector={dataSelector} className={classes('text', undefined, className)}>
        <LongTableTitle text={text} className={classes('text', undefined, className)} data-selector={dataSelector} />
      </Link>
    </SLinkLongTableTitle>
  )
);
