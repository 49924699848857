import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { paths } from 'app/routing/paths';
import { Auth } from '../components/auth';
import { ACTION } from '../reducer';
import { auth0ErrorSelector, auth0IsAuthorizedSelector } from '../selectors';

const mapStateToProps = createStructuredSelector({
  isAuthorized: auth0IsAuthorizedSelector,
  errorMessage: auth0ErrorSelector,
  //†prop
});
const mapDispatchToProps = {
  login: ACTION.login,
  checkSession: ACTION.checkSession,
  //†action
};

const ConnectedLoginComponent = ({ login, isAuthorized, errorMessage, checkSession, ...rest }) => {
  const history = useHistory();

  useEffect(() => {
    if (!isAuthorized) {
      checkSession({ silent: true });
      login(errorMessage);
    } else {
      history.replace(paths.projects());
    }
  }, []);

  return <Auth {...rest} />;
};

export const AuthContainer = connect(mapStateToProps, mapDispatchToProps)(ConnectedLoginComponent);
