import styled from 'styled-components';
import { manhattanThemeColors } from 'app/theme/colors';

export const BEM_CLASS = 'age-renderer';

export const SAgeRenderer = styled.div`
  height: 100%;
  width: 100%;

  .${BEM_CLASS} {
    &__value {
      display: flex;
      width: 100%;
      justify-content: end;
    }

    &__placeholder {
      display: flex;
      justify-content: end;
      color: ${manhattanThemeColors.grayedText};
    }
  }
`;
