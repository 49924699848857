import React from 'react';
import { Button, BUTTON_SIZE, BUTTON_TYPE, THEME, Tooltip } from 'gdb-web-shared-components';
import { bem } from 'utils/bem';
import { SViewModeControls, BEM_CLASS } from './s-view-mode-controls';

interface ViewModeControlsProps {
  canEditProject: boolean;

  onShareProject(): void;
  onEditProject(): void;
}

const classes = bem(BEM_CLASS);

export const ViewModeControls = React.memo(
  ({ canEditProject, onShareProject, onEditProject }: ViewModeControlsProps) => (
    <SViewModeControls>
      <Tooltip tooltip={canEditProject ? 'Share Project' : 'View collaborators'}>
        <Button
          icon="share"
          type={BUTTON_TYPE.primary}
          size={BUTTON_SIZE.mediumRound}
          theme={THEME.light}
          onClick={onShareProject}
          data-selector="share-project-button"
        />
      </Tooltip>

      {canEditProject && (
        <Button
          icon="edit-outline"
          type={BUTTON_TYPE.tertiary}
          size={BUTTON_SIZE.mediumRound}
          theme={THEME.light}
          className={classes('edit-button')}
          onClick={onEditProject}
          data-selector="edit-project-button"
        />
      )}
    </SViewModeControls>
  )
);
