import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { BEM_CLASS, SLinksReadonlyEditor } from './s-links-readonly-editor';
import { bem } from 'utils/bem';
import { ExpandableField } from '../../expandable-field';
import { ICellEditorParams } from 'ag-grid-community';
import { getLinksWithLabels } from 'common/components/select/containers/link-input-multi-select/transducers';
import { Body, PrimaryLabel } from 'common/components/typography';
import { ValidationTooltipContent } from '../../validation-tooltip-content';
import {
  AD_CREATIVE_MULTIPLE_VALIDATION_TEXT,
  AD_CREATIVE_SINGLE_VALIDATION_TEXT,
} from 'media-plan/components/spreadsheet/constants';
import { SpreadsheetRowData } from 'media-plan/components/spreadsheet/types';

type Props = ICellEditorParams<
  SpreadsheetRowData,
  SpreadsheetRowData['adCreativeLinks'] | SpreadsheetRowData['destinationLinks']
>;

export const classes = bem(BEM_CLASS);

export const LinksReadonlyEditor = React.memo(({ api, value: { value }, rowIndex, colDef }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const links = useMemo(() => getLinksWithLabels(value || [], true), [value]);
  const isAnyInvalid = useMemo(() => links.some(link => link.isInvalid), [links]);
  const onCopyAll = useCallback(() => {
    if (value) {
      navigator.clipboard.writeText(value.join(', ')).then();
    }
  }, [value]);

  const handleStopEditing = useCallback(() => {
    api.stopEditing();
    api.setFocusedCell(rowIndex, colDef.field || '');
  }, [api, colDef.field, rowIndex]);

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (containerRef?.current?.contains(event.target as Node)) return;
      event.stopPropagation();

      handleStopEditing();
    };

    document.addEventListener('click', handleClick, { capture: true });

    return () => {
      document.removeEventListener('click', handleClick, { capture: true });
    };
  }, [handleStopEditing]);

  return (
    <ExpandableField hasError={isAnyInvalid}>
      <SLinksReadonlyEditor ref={containerRef}>
        {isAnyInvalid && (
          <>
            <ValidationTooltipContent
              text={links.length === 1 ? AD_CREATIVE_SINGLE_VALIDATION_TEXT : AD_CREATIVE_MULTIPLE_VALIDATION_TEXT}
              className={classes('error-notification')}
            />
            <div className={classes('divider')} />
          </>
        )}
        <div className={classes('links-container')}>
          {links.map(item => (
            <Body as="a" className={classes('link', { invalid: !!item.isInvalid })} key={item.value} href={item.value}>
              {item.label}
            </Body>
          ))}
        </div>
        <PrimaryLabel as="button" className={classes('copy-all')} onClick={onCopyAll}>
          Copy All
        </PrimaryLabel>
      </SLinksReadonlyEditor>
    </ExpandableField>
  );
});
