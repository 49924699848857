import { asyncEpic, ofType } from 'core/epics';
import {
  getDigitalCampaigns,
  getProject,
  getProjectCampaigns,
  manageExternalCampaigns,
  removeExternalCampaign,
  undoRemovalExternalCampaign,
} from './actions';
import { Api } from 'backend-api';
import { map, switchMap, tap, mergeMap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { openPermissionErrorModal } from 'modals/permission-error-modal';
import { showErrorToast, showToast, ToastType } from 'common/components/toast';
import { isRequestRestricted } from 'common-v2/transducers';

export const getProjectEpic = asyncEpic(getProject, action => Api.getProject(action.payload), undefined, {
  showError: false,
});

export const getProjectCampaignsEpic = asyncEpic(
  getProjectCampaigns,
  action => Api.getCampaignsDetails(action.payload, { isDigital: true }),
  undefined,
  { showError: false }
);

export const getDigitalCampaignsEpic = asyncEpic(
  getDigitalCampaigns,
  ({ payload: { projectId, isInfinite, ...params } }) =>
    Api.getDigitalCampaigns(projectId, params).pipe(
      map(response => ({
        isInfinite,
        total: response.total,
        items: response.items,
      }))
    ),
  undefined,
  { showError: false }
);

export const manageExternalCampaignsEpic = asyncEpic(
  manageExternalCampaigns,
  action => Api.manageExternalCampaigns(action.payload.projectId, action.payload.data),
  (error, { closeManagingCampaignsModal }) => {
    if (isRequestRestricted(error)) {
      closeManagingCampaignsModal();
      return of(openPermissionErrorModal(error), manageExternalCampaigns.failure(error));
    }

    return of(manageExternalCampaigns.failure(error));
  }
);

export const removeExternalCampaignEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(removeExternalCampaign.request),
    switchMap(({ payload: { projectId, campaign, onUndo } }) => {
      return Api.manageExternalCampaigns(projectId, {
        unassignCampaignsUuids: [campaign.uuid],
        assignCampaignsUuids: [],
      }).pipe(
        tap(() =>
          showToast({
            id: `external-campaign-${campaign.uuid}-removed`,
            type: ToastType.Success,
            message: 'Campaign has been successfully removed',
            onUndo,
          })
        ),
        mergeMap(() => [removeExternalCampaign.success(), getProjectCampaigns.request(projectId)]),
        catchError(error => {
          if (isRequestRestricted(error)) {
            return of(openPermissionErrorModal(error), removeExternalCampaign.failure(error));
          } else {
            showErrorToast(`We're sorry, an error occurred during removing campaign from the project. Try again.`);
            return of(removeExternalCampaign.failure(error));
          }
        })
      );
    })
  );
};

export const undoRemovalExternalCampaignEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(undoRemovalExternalCampaign.request),
    switchMap(({ payload: { projectId, campaignUuid } }) => {
      return Api.manageExternalCampaigns(projectId, {
        assignCampaignsUuids: [campaignUuid],
        unassignCampaignsUuids: [],
      }).pipe(
        mergeMap(() => [undoRemovalExternalCampaign.success(), getProjectCampaigns.request(projectId)]),
        catchError(error => {
          if (isRequestRestricted(error)) {
            return of(openPermissionErrorModal(error), undoRemovalExternalCampaign.failure(error));
          } else {
            showErrorToast('Failed to Undo removal, please add the campaign manually');
            return of(undoRemovalExternalCampaign.failure(error));
          }
        })
      );
    })
  );
};
