import styled from 'styled-components';
import { theme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'request-approval-modal';

export const SRequestApprovalModal = styled.div`
  width: 390px;
  height: auto;
  background-color: ${colorPalette.white};
  border-radius: 10px;
  box-shadow: ${theme.shadows.default};

  .${BEM_CLASS} {
    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
    }

    &__footer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border-top: 1px solid ${colorPalette.snuff};
    }

    &__cancel-button {
      margin-right: 20px;
    }

    &__header {
      display: flex;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 20px;
    }

    &__title {
      margin-right: 20px;
    }

    &__close {
      display: flex;
      width: 25px;
      height: 25px;
      margin-left: auto;
      color: ${colorPalette.lavenderGray};
    }

    &__close-icon {
      margin: auto;
    }

    &__warning {
      display: flex;
      align-items: center;
      width: 100%;
      border-radius: 5px;
      border: 1px solid rgb(255, 147, 38);
      background-color: rgba(255, 147, 38, 0.3);
      padding: 10px 15px;
      margin-bottom: 20px;
      font-size: 12px;
      line-height: 15px;
    }

    &__warning-icon {
      flex-shrink: 0;
      display: flex;
      margin-right: 10px;
    }

    &__text {
      width: 100%;
      margin-bottom: 10px;
    }

    &__reviewers {
      width: 100%;
      margin-bottom: 20px;
    }

    &__textarea-label {
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    &__textarea {
      width: 100%;
      min-height: 72px;
      max-height: 272px;
      resize: none;
      border-radius: 15px;
      padding: 10px 20px;
      font-size: 14px;
      line-height: 18px;
      box-shadow: none;
      border: 1px solid transparent;
      background-color: ${colorPalette.ghostWhite};

      &::placeholder {
        color: ${colorPalette.wildBlueYonder};
      }

      &:focus {
        border: 1px solid ${colorPalette.azureRadiance};
        background-color: ${colorPalette.white};
      }
    }
  }
`;
