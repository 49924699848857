import styled from 'styled-components';

export const BEM_CLASS = 'date-selector';

export const SDateFormatSelector = styled.div`
  display: flex;
  flex-direction: column;

  .${BEM_CLASS} {
    &__title {
      margin-bottom: 14px;
      text-transform: uppercase;
    }
  }
`;
