import styled from 'styled-components';

interface Props {
  alignment: Alignment;
}

export enum Alignment {
  LEFT,
  RIGHT,
}

export const BEM_CLASS = 'value';

export const SValue = styled.div<Props>`
  .${BEM_CLASS} {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: ${props => (props.alignment == Alignment.LEFT ? 'flex-start' : 'flex-end')};
    }

    &__title {
      text-transform: uppercase;
      white-space: nowrap;
    }

    &__value {
      margin-top: 5px;
    }
  }
`;
