import React from 'react';
import { bem } from 'utils/bem';
import { SWaveBackground, BEM_CLASS } from './s-wave-background';
import { WaveBackgroundMainProps } from './types';

interface WaveBackgroundProps extends WaveBackgroundMainProps {
  className?: string;
}

const classes = bem(BEM_CLASS);

export const WaveBackground: React.FC<WaveBackgroundProps> = React.memo(({ children, ...restProps }) => {
  return (
    <SWaveBackground {...restProps}>
      <div className={classes('background')} />
      {children}
    </SWaveBackground>
  );
});
