import React from 'react';

export type TableElementId = string | number;

export interface BodyContent {
  id: TableElementId;
}

export interface TableRowState {
  isRowExpanded?: boolean;
  isRowChecked?: boolean;
}

export interface StickyTableConfig<BodyType extends BodyContent, SortField extends string> {
  maxWidth: number;
  minWidth: number;
  initialWidth: number;
  content: TableContent<BodyType, SortField>[];
}

export interface TableContent<BodyType, SortField extends string> {
  title: string;
  subTitle?: string;
  description?: string;
  dataSelector: string;
  dataIndex: string;
  useSort: boolean;
  sortField?: SortField;
  additionalClassName?: string;
  additionalExpandedClassName?: string;
  render(record: BodyType, state: TableRowState, index: number): React.ReactNode;
  renderFooterCell?: () => React.ReactNode;
  renderExpanded?: (record: BodyType) => React.ReactNode[];
}

export interface TableInfiniteScroll {
  onScrollEnd(): void;
  isActive: boolean;
  isLoading: boolean;
}

export const EMPTY_TABLE_HEIGHT = 535;
export const TABLE_HEADER_HEIGHT = 61;
export const TABLE_NOTIFICATION_HEIGHT = 40;
