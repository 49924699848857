import styled from 'styled-components';
import { TableTheme } from 'app/theme/table';

interface Props {
  isEven?: boolean;
  isSorted?: boolean;
  tableTheme: TableTheme;
}

export const STableRow = styled.tr<Props>`
  background: ${({ theme, tableTheme, isEven }) =>
    isEven ? theme.table[tableTheme].rowBackgroundColor.even : theme.table[tableTheme].rowBackgroundColor.odd};

  & > :not(:first-child) {
    padding: 0 10px;
  }

  & > :first-child {
    padding: 0 10px 0 20px;
  }

  & > :last-child {
    padding: 0 20px 0 10px;
  }

  .table-row {
    &__icon-expand {
      display: block;
      transition: transform 200ms ease-in;

      &--collapsed {
        transform: rotate(90deg);
      }
      &--expanded {
        transform: rotate(270deg);
      }
    }

    &__column {
      height: 60px;
      background: ${({ isSorted, tableTheme, theme }) =>
        isSorted ? theme.table[tableTheme].rowSelectedBackground : null};

      &--sorted {
        background: ${({ tableTheme, theme }) => theme.table[tableTheme].rowSelectedBackground};
      }

      &--checkbox {
        width: 45px;
        padding: 7px 0 10px 16px;
        .checkbox {
          position: relative;
          top: 2px;
        }
      }

      &--expand {
        cursor: pointer;
        width: 50px;
        min-width: 50px;
        background: rgba(220, 221, 231, 0.2);
        padding: 0 10px 0 20px;
      }

      &--disabled {
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
`;
