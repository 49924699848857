import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { appearingAnimation } from 'common/s-components/s-appearing-animation';
import { theme } from 'app/theme';

export const SProjectsListItem = styled.div`
  display: flex;
  flex-direction: row;
  height: 60px;
  background: ${palette.athensGrayTwo};
  border-radius: 5px;
  padding-right: 13px;
  margin-top: 10px;
  margin-left: 10px;
  min-width: 0;
  position: relative;
  cursor: pointer;
  ${appearingAnimation};

  &:hover {
    background-color: white;
    box-shadow: ${theme.shadows.default};
  }

  .project-list-item {
    &__images-container {
      width: 60px;
      height: 60px;
      border-radius: 5px 0 0 5px;
    }
    &__info-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;
      ${elipsizeText};
      min-width: 0;
    }
    &__name {
      color: black;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      padding-right: 10px;
      ${elipsizeText};
      min-width: 0;
      font-family: Montserrat;
      font-weight: 550;
    }
    &__artist-names {
      color: ${palette.darkBlueTwo};
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      padding-right: 10px;
      ${elipsizeText}
      font-family: Montserrat;
      font-weight: lighter;
    }
    &__icon {
      align-self: center;
      cursor: pointer;
    }
  }
`;
