import * as t from 'io-ts';
import { ResponseModel, Optional, ImageSchema } from '../common';

const ProviderSchema = t.union([t.literal('apple'), t.literal('spotify')]);

export type Provider = t.TypeOf<typeof ProviderSchema>;

export const PlaylistSchema = ResponseModel(
  {
    id: t.string,
    name: Optional(t.string),
    ownerName: Optional(t.string),
    provider: ProviderSchema,
    images: t.array(ImageSchema),
  },
  'Playlist'
);

export type Playlist = t.TypeOf<typeof PlaylistSchema>;
