import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';

export const BEM_CLASS = 'marketing-mix-categories-dropdown';

export const SMarketingMixCategoriesDropdown = styled.div`
  .${BEM_CLASS} {
    &__select-control {
      background-color: ${colorPalette.ghostWhite};
    }
  }
`;
