import React, { useEffect } from 'react';
import { bem } from 'utils/bem';
import { ICellRendererParams } from 'ag-grid-community';
import { useScheduleValidation } from 'media-plan/components/spreadsheet/hooks';
import { BaseRenderer } from '../../base-renderer';
import { SpreadsheetTooltip } from '../../spreadsheet-tooltip';
import { BEM_CLASS, SStartDateRenderer } from './s-start-date-renderer';
import { START_DATE_VALIDATION_TEXT } from 'media-plan/components/spreadsheet/constants';
import { MediaPlanColumnId, SpreadsheetRowData } from 'media-plan/components/spreadsheet/types';

const classes = bem(BEM_CLASS);

interface StartDateRendererProps extends ICellRendererParams<SpreadsheetRowData, SpreadsheetRowData['startDate']> {
  dateFormat: string;
}

export const StartDateRenderer = ({
  context,
  value: { color, value },
  data,
  api,
  node,
  formatValue,
  dateFormat,
}: StartDateRendererProps) => {
  const [isValueInvalid, tooltipContent] = useScheduleValidation({
    startDate: value,
    endDate: data?.endDate.value,
    tooltipText: START_DATE_VALIDATION_TEXT,
    dateFormat,
  });

  useEffect(() => {
    api.refreshCells({ columns: [MediaPlanColumnId.END_DATE], rowNodes: [node], force: true });
  }, [isValueInvalid, api, node]);

  return (
    <SpreadsheetTooltip content={tooltipContent} disabled={!isValueInvalid}>
      <SStartDateRenderer>
        <BaseRenderer
          color={color}
          accessMode={context.accessMode}
          className={classes('base-renderer')}
          placeholder={dateFormat}
        >
          {formatValue?.(value)}
        </BaseRenderer>
      </SStartDateRenderer>
    </SpreadsheetTooltip>
  );
};
