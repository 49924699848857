import { HeadStaticTitles } from 'app/components/head';
import { ErrorViewStates } from 'common-v2/components';
import { Difference } from 'common-v2/types';

export const getPageHeadTitle = (title: string, error: ErrorViewStates) => {
  if (error === ErrorViewStates.notFound) return HeadStaticTitles.NOT_FOUND;
  return title;
};

export const getValuesDiff = <T extends { id: string | number }>(firstArr: T[], secondArr: T[]): Difference<T> => {
  const result: Difference<T> = {
    removed: [],
    added: [],
  };

  secondArr.forEach(val => {
    if (!firstArr.some(item => item.id === val.id)) {
      result.added.push(val);
    }
  });

  firstArr.forEach(val => {
    if (!secondArr.some(item => item.id === val.id)) {
      result.removed.push(val);
    }
  });

  return result;
};
