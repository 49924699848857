import React, { useCallback } from 'react';
import { Typography, TYPOGRAPHY_TYPE, Icon, SelectTriggerProps } from 'gdb-web-shared-components';
import { LabelOption } from '../../types';
import { bem } from 'utils/bem';
import { BEM_CLASS, SLabelTrigger } from './s-label-trigger';
import sonyMusicPng from 'assets/sony-music.png';
import { Image } from 'common-v2/components';

const classes = bem(BEM_CLASS);

export const LabelTrigger = React.memo(
  React.forwardRef<HTMLDivElement, SelectTriggerProps<LabelOption>>(
    ({ option, className, ownerState, toggleModal, isDisabled }, ref) => {
      const clickHandler = useCallback(() => {
        toggleModal();
      }, [toggleModal]);

      return (
        <SLabelTrigger ref={ref} className={className} onClick={clickHandler} isDisabled={isDisabled}>
          <Image
            imageUrl={option?.image}
            alt={option?.label}
            placeholderUrl={sonyMusicPng}
            className={classes('image')}
            data-selector="label-trigger-image"
          />
          <Typography type={TYPOGRAPHY_TYPE.heading2}>{option?.label}</Typography>
          {!isDisabled && <Icon name={ownerState.open ? 'up-single-m' : 'down-single-m'} />}
        </SLabelTrigger>
      );
    }
  )
);
