import { colorPalette } from 'app/theme/colors';
import styled from 'styled-components';
import { elipsizeText } from 'common/s-components/s-elipsize-text';
import { typography } from 'app/theme/typography';

export const BEM_CLASS = 'expansion-header';

export const SExpansionHeader = styled.div`
  width: 100%;
  margin-top: 35px;
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__artist-image {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 40px;
      margin-right: 20px;
      background: ${colorPalette.mischka};
    }
    &__name-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 0 20px;
      overflow: hidden;
    }
    &__artist-name {
      ${elipsizeText};
    }
    &__view-artist-page {
      ${typography.smallText};
      width: fit-content;
      color: ${colorPalette.azureRadiance};
    }
    &__artist-team-label {
      flex-shrink: 0;
      text-transform: uppercase;
    }
    &__artist-team-container {
      display: flex;
      margin-left: 20px;
    }
  }
`;
