import React from 'react';
import { bem } from 'utils/bem';
import { Button } from 'common/components/button';
import { BEM_CLASS, SModalContent } from './s-modal-content';

const classes = bem(BEM_CLASS);

interface ModalContentProps {
  onConfirm(): void;
}

export const ModalContent = React.memo(({ onConfirm }: ModalContentProps) => (
  <SModalContent>
    <span className={classes('text')} data-selector="access-restricted-text">
      You don’t have permissions to edit this project.
      <br /> Request Access from Project editors.
    </span>

    <div className={classes('footer')}>
      <Button variant="primary-cta" onClick={onConfirm}>
        Ok
      </Button>
    </div>
  </SModalContent>
));
