import React from 'react';
import { Skeleton } from 'common/components/skeleton';

interface Props {
  className?: string;
}

export const Loader = React.memo(({ className }: Props) => (
  <Skeleton height={43} width={183} className={className}>
    <rect x="0" y="0" rx="0" ry="0" width={10} height={10} />
    <rect x="0" y="15" rx="0" ry="0" width={10} height={10} />
    <rect x="0" y="30" rx="0" ry="0" width={10} height={10} />
    <rect x="15" y="0" rx="0" ry="0" width={120} height={10} />
    <rect x="15" y="15" rx="0" ry="0" width={183} height={10} />
    <rect x="15" y="30" rx="0" ry="0" width={120} height={10} />
  </Skeleton>
));
