import { Api } from 'backend-api';
import { showErrorToast, showSuccessToast } from 'common/components/toast';
import { asyncEpic, ofType } from 'core/epics';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  createMediaPlan,
  createPhase,
  deleteMediaPlan,
  deletePhase,
  editMediaPlan,
  editPhase,
  getAllMediaPlans,
  getApprovalRequests,
  getMediaPlan,
  getMediaPlanMetrics,
  getMediaPlanRelease,
  getMediaPlanReviewers,
  modifyBulkCampaigns,
  requestApproval,
  submitApproval,
  updateMediaPlanRelease,
} from './actions';
import { getProject } from 'common/actions';
import { isRestrictedError } from 'common-v2/transducers';
import { AnalyticsEvents, trackEvent } from 'utils/analytic';
import { openPermissionErrorModal } from 'modals/permission-error-modal';

export const getAllMediaPlansEpic = asyncEpic(
  getAllMediaPlans,
  ({ payload }) => Api.getMediaPlans(payload),
  undefined,
  {
    showError: false,
  }
);

export const getMediaPlanEpic = asyncEpic(
  getMediaPlan,
  ({ payload: { projectId, mediaPlanId } }) => Api.getMediaPlan(projectId, mediaPlanId),
  undefined,
  {
    showError: false,
  }
);

export const createMediaPlanPhaseEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(createPhase.request),
    switchMap(({ payload: { projectId, mediaPlanId, payload, onSuccess, onError } }) => {
      return Api.createMediaPlanPhase(projectId, mediaPlanId, payload).pipe(
        mergeMap(phase => [createPhase.success(phase)]),
        tap(() => trackEvent(AnalyticsEvents.PHASE_CREATED, { projectId })),
        tap(phase => onSuccess(phase.payload)),
        catchError(err => {
          if (isRestrictedError(err)) {
            onError();
            return of(openPermissionErrorModal(err), createPhase.failure(err));
          } else {
            showErrorToast("We're sorry, an error occurred during Creating phase. Try again.");
            onError();
          }

          return of(createPhase.failure(err));
        })
      );
    })
  );
};

export const editMediaPlanPhaseEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(editPhase.request),
    switchMap(({ payload: { projectId, mediaPlanId, phaseId, payload, onSuccess, onError } }) => {
      return Api.editMediaPlanPhase(projectId, mediaPlanId, phaseId, payload).pipe(
        mergeMap(phase => [editPhase.success(phase)]),
        tap(phase => onSuccess(phase.payload)),
        catchError(err => {
          if (isRestrictedError(err)) {
            onError();
            return of(openPermissionErrorModal(err), editPhase.failure(err));
          } else {
            showErrorToast("We're sorry, an error occurred during Editing phase. Try again.");
            onError();
          }

          return of(editPhase.failure(err));
        })
      );
    })
  );
};

export const deleteMediaPlanPhaseEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(deletePhase.request),
    switchMap(({ payload: { projectId, mediaPlanId, phaseId, onError } }) => {
      return Api.deleteMediaPlanPhase(projectId, mediaPlanId, phaseId).pipe(
        mergeMap(() => [
          deletePhase.success(phaseId),
          getProject.request({ projectId, hasErrorToast: false }),
          getMediaPlanMetrics.request({ projectId, mediaPlanId }),
        ]),
        catchError(err => {
          if (isRestrictedError(err)) {
            onError();
            return of(openPermissionErrorModal(err), deletePhase.failure(err));
          } else {
            showErrorToast("We're sorry, an error occurred during Deleting phase. Try again.");
            onError();
          }

          return of(deletePhase.failure(err));
        })
      );
    })
  );
};

export const modifyBulkCampaignsEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(modifyBulkCampaigns.request),
    switchMap(({ payload: { mediaPlanId, projectId, payload, onSuccess, onError } }) => {
      return Api.modifyMediaPlanCampaigns(projectId, mediaPlanId, payload).pipe(
        tap(response => onSuccess(response)),
        tap(response => {
          response.created.forEach(() => {
            trackEvent(AnalyticsEvents.ROW_ADDED, { mediaPlanId });
          });
        }),
        mergeMap(response => [
          modifyBulkCampaigns.success(response),
          getProject.request({ projectId, hasErrorToast: false }),
          getMediaPlanMetrics.request({ projectId, mediaPlanId }),
        ]),
        catchError(err => {
          if (isRestrictedError(err)) {
            onError();
            return of(openPermissionErrorModal(err), modifyBulkCampaigns.failure(err));
          } else {
            showErrorToast("We're sorry, an error occurred during updating Media Plan data. Try again.");
            onError();
          }

          return of(modifyBulkCampaigns.failure(err));
        })
      );
    })
  );
};

export const getMediaReleaseEpic = asyncEpic(
  getMediaPlanRelease,
  ({ payload: { projectId, mediaPlanId } }) => Api.getMediaPlanRelease(projectId, mediaPlanId),
  undefined,
  {
    showError: false,
  }
);

export const updateProjectReleaseEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(updateMediaPlanRelease.request),
    switchMap(({ payload: { projectId, mediaPlanId, payload } }) => {
      return Api.updateMediaPlanRelease(projectId, mediaPlanId, payload).pipe(
        mergeMap(response => [updateMediaPlanRelease.success(response)]),
        catchError(err => {
          if (isRestrictedError(err)) {
            return of(openPermissionErrorModal(err), updateMediaPlanRelease.failure(err));
          } else {
            showErrorToast('Something went wrong. An error occurred while we were saving release details.');
            return of(updateMediaPlanRelease.failure(err));
          }
        })
      );
    })
  );
};

export const getProjectReviewersListEpic = asyncEpic(
  getMediaPlanReviewers,
  ({ payload: { projectId, mediaPlanId } }) => Api.getProjectReviewers(projectId, mediaPlanId),
  undefined,
  {
    showError: false,
  }
);

export const requestApprovalEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(requestApproval.request),
    switchMap(({ payload: { projectId, mediaPlanId, payload } }) => {
      return Api.requestApproval(projectId, mediaPlanId, payload).pipe(
        tap(() => {
          showSuccessToast('Approval request has been successfully sent.');
        }),
        mergeMap(() => [requestApproval.success(), getMediaPlan.request({ projectId, mediaPlanId })]),
        catchError(err => {
          if (isRestrictedError(err)) {
            return of(openPermissionErrorModal(err), requestApproval.failure(err));
          } else {
            showErrorToast(
              'We are unable to send an Approval Request at this time due to a data failure. Please try again.'
            );
          }

          return of(requestApproval.failure(err));
        })
      );
    })
  );
};

export const getApprovalRequestsEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(getApprovalRequests.request),
    switchMap(({ payload: { projectId, mediaPlanId } }) => {
      return Api.getApprovalRequests(projectId, mediaPlanId).pipe(
        mergeMap(approvalResponse => [getApprovalRequests.success(approvalResponse)]),
        catchError(err => {
          if (err.data?.code === 'not_found_campaigns_for_review') {
            return of(getApprovalRequests.success([]));
          }
          return of(getApprovalRequests.failure(err));
        })
      );
    })
  );
};

export const submitApprovalEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(submitApproval.request),
    switchMap(({ payload: { projectId, mediaPlanId, payload, onSubmitSuccess } }) => {
      return Api.submitApproval(projectId, mediaPlanId, payload).pipe(
        tap(() => {
          onSubmitSuccess();
          showSuccessToast(`${payload.campaigns.length} campaigns have been reviewed successfully`);
        }),
        mergeMap(() => [
          submitApproval.success(),
          getApprovalRequests.request({ projectId, mediaPlanId }),
          getMediaPlan.request({ projectId, mediaPlanId }),
        ]),
        catchError(err => {
          showErrorToast('An error ocurred while confirming your review, please try again.');

          return of(
            getApprovalRequests.request({ projectId, mediaPlanId }),
            getMediaPlan.request({ projectId, mediaPlanId }),
            requestApproval.failure(err)
          );
        })
      );
    })
  );
};

export const getMediaPlanMetricsEpic = asyncEpic(
  getMediaPlanMetrics,
  ({ payload: { projectId, mediaPlanId } }) => Api.getMediaPlanMetrics(projectId, mediaPlanId),
  undefined,
  {
    showError: false,
  }
);

export const createMediaPlanEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(createMediaPlan.request),
    switchMap(action => {
      const { projectId, payload, onMediaPlanCreated } = action.payload;
      return Api.createMediaPlan(projectId, payload).pipe(
        tap(mediaPlan => {
          onMediaPlanCreated?.(mediaPlan.id);
        }),
        mergeMap(response => [createMediaPlan.success(response), getAllMediaPlans.request(projectId)]),
        catchError(error => {
          if (isRestrictedError(error)) {
            return of(openPermissionErrorModal(error), createMediaPlan.failure(error));
          } else {
            showErrorToast("We're sorry, an error occurred during updating Media Plan data. Try again.");
            return of(createMediaPlan.failure(error));
          }
        })
      );
    })
  );
};

export const editMediaPlanEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(editMediaPlan.request),
    switchMap(action => {
      const { projectId, mediaPlanId, payload } = action.payload;
      return Api.editMediaPlan(projectId, mediaPlanId, payload).pipe(
        mergeMap(response => [editMediaPlan.success(response), getAllMediaPlans.request(projectId)]),
        catchError(error => {
          if (isRestrictedError(error)) {
            return of(openPermissionErrorModal(error), editMediaPlan.failure(error));
          } else {
            showErrorToast("We're sorry, an error occurred during updating Media Plan data. Try again.");
            return of(editMediaPlan.failure(error));
          }
        })
      );
    })
  );
};

export const deleteMediaPlanEpic = (action: Observable<any>) => {
  return action.pipe(
    ofType(deleteMediaPlan.request),
    switchMap(action => {
      const { projectId, mediaPlanId, onMediaPlanDeleted } = action.payload;
      return Api.deleteMediaPlan(projectId, mediaPlanId).pipe(
        tap(() => {
          onMediaPlanDeleted?.(mediaPlanId);
        }),
        mergeMap(response => [deleteMediaPlan.success(response), getAllMediaPlans.request(projectId)]),
        catchError(error => {
          if (isRestrictedError(error)) {
            return of(openPermissionErrorModal(error), deleteMediaPlan.failure(error));
          } else {
            showErrorToast("We're sorry, an error occurred during updating Media Plan data. Try again.");
            return of(deleteMediaPlan.failure(error));
          }
        })
      );
    })
  );
};
