import * as React from 'react';
import { BEM_CLASS, STooltipContent } from './s-tooltip-content';
import { bem } from 'utils/bem';
import { Optional } from 'backend-api/models';
import { Body } from 'common/components/typography';
import { TooltipContentWithError } from '../../../../tooltip-content-with-error';
import { LinkOption } from 'common/components/select/types';

interface Props {
  values: Optional<LinkOption[]>;
  errorText?: string;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const TooltipContent = React.memo(({ values, errorText, className }: Props) => {
  return (
    <TooltipContentWithError errorText={errorText}>
      <STooltipContent className={className}>
        {values?.map((item, index) => (
          <Body key={item.label} className={classes('label', { isInvalid: !!item.isInvalid })}>
            {item.label}
            {index + 1 !== values?.length ? ',\xa0' : ''}
          </Body>
        ))}
      </STooltipContent>
    </TooltipContentWithError>
  );
});
