import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { dateFormatSelector, dateTimeFormatSelector } from 'common/selectors';
import { getFormattedShortDate, getFormattedDate } from 'common-v2/utils';
import { DateFormat, DateValues } from 'common/types';

interface UseFormattedDateTimeParams {
  date?: Date;
  format?: DateFormat;
  isFullDate?: boolean; // available only with default format
}

export const useFormattedDateTime = ({
  date,
  format = DateFormat.DEFAULT,
  isFullDate,
}: UseFormattedDateTimeParams): DateValues => {
  const currentFormat = useSelector(isFullDate ? dateTimeFormatSelector : dateFormatSelector);

  const getDateFormatted = useMemo(() => {
    if (!date) return {};

    switch (format) {
      case DateFormat.DEFAULT:
        return { date: getFormattedDate(currentFormat)(date) };
      case DateFormat.SHORT:
        return getFormattedShortDate(currentFormat)(date);
    }
  }, [currentFormat, date, format]);

  return getDateFormatted;
};
