import styled from 'styled-components';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

export const BEM_CLASS = 'approval-status-menu-list';

export const SApprovalStatusMenu = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 340px;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px 0;
  background-color: ${colorPalette.white};

  .${BEM_CLASS} {
    &__options {
      display: flex;
      flex-direction: column;
    }

    &__divider {
      width: calc(100% - 40px);
      height: 1px;
      background-color: ${transparentize(0.9, manhattanThemeColors.primaryDark)};
      margin: 0 auto 7.5px;
    }

    &__apply-to-all {
      padding: 0 20px;
      display: flex;
      align-items: center;
    }

    &__checkbox-container {
      margin-right: 10px;
    }

    &__checkbox-label {
      cursor: pointer;
      user-select: none;
    }
  }
`;
