import React, { PropsWithChildren } from 'react';
import { bem } from 'utils/bem';
import { isEditMode } from '../../transducers';
import { MediaPlanAccessMode } from 'media-plan-v2/containers/spreadsheet/types';
import { BEM_CLASS, SBaseRenderer } from './s-base-renderer';

const classes = bem(BEM_CLASS);

export interface BaseRendererProps {
  color: string | null;
  accessMode: MediaPlanAccessMode;
  className?: string;
  placeholder?: React.ReactNode;
  isDisabled?: boolean;
}

export const BaseRenderer = ({
  color,
  accessMode,
  className,
  children,
  placeholder,
  isDisabled = false,
}: PropsWithChildren<BaseRendererProps>) => (
  <SBaseRenderer backgroundColor={color} className={className} isDisabled={isDisabled}>
    {!!children ? (
      <div
        className={classes('value', { isText: typeof children === 'string' })}
        data-selector="base-renderer-cell-value"
      >
        {children}
      </div>
    ) : (
      isEditMode(accessMode) && (
        <div
          className={classes('placeholder', { isText: typeof placeholder === 'string' })}
          data-selector="base-renderer-cell-placeholder"
        >
          {placeholder}
        </div>
      )
    )}
  </SBaseRenderer>
);
