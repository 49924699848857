import styled from 'styled-components';

export const BEM_CLASS = 'single-team-entry';

export const SSingleTeamEntry = styled.div`
  display: flex;
  align-items: center;

  .${BEM_CLASS} {
    &__label {
      margin-right: 20px;
    }

    &__members {
      margin-right: 20px;
    }
  }
`;
