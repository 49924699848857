import { GAP_SIZE } from './constants';
import { TabData } from './types';

const getRoundedPart = (total: number, parts: number) => Math.round((total / parts) * 100) / 100;

export const getColumnWidth = (columns: number) =>
  `calc(${getRoundedPart(100, columns)}% - ${`${getRoundedPart(GAP_SIZE * (columns - 1), columns)}px`})`;

export const isPaginationActive = (tabs: TabData[]): boolean => {
  const biggestCount = Math.max(...tabs.map(({ count }) => count ?? 0));
  const biggestTab = tabs.find(({ count }) => count === biggestCount);

  if (!biggestTab) return false;

  return biggestTab.data.length < biggestCount;
};
