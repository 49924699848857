export type IconSize =
  | 'small'
  | 'general'
  | 'large'
  | 'menu'
  | 'glyph'
  | 'projects'
  | 'medium'
  | 'extra-large'
  | 'inherit'
  | 'platforms';

export type IconColor =
  | 'violet'
  | 'pink'
  | 'black'
  | 'white'
  | 'wildStrawberry'
  | 'green'
  | 'blue'
  | 'darkBlue'
  | 'red'
  | 'yellow'
  | 'cerulean'
  | 'amethyst'
  | 'mountainMeadow'
  | 'uncategorizedGray'
  | 'eastBay'
  | 'christine'
  | 'snuff'
  | 'jade'
  | 'cinnabar'
  | 'default'
  | 'fiordGrey'
  | 'inherit';

export const ALL_ICON_NAMES = [
  'spotify',
  'star',
  'amazon',
  'apple',
  'global',
  'ellipsis',
  'multichart',
  'percent',
  'position',
  'search',
  'placeholder',
  'star-outlined',
  'table',
  'barchart',
  'chart',
  'close',
  'collapse',
  'success',
  'delete',
  'down',
  'expand',
  'export',
  'filter',
  'heart',
  'next',
  'new-window',
  'forward',
  'plus',
  'prev',
  'backward',
  'in-progress',
  'right',
  'scheduled',
  'sort',
  'star-glyph',
  'star-stroke-glyph',
  'spotify',
  'up',
  'warning',
  'error',
  'menu',
  'cross',
  'ellipsis-menu',
  'sign-out',
  'arrow-diag-bottom',
  'arrow-diag-top',
  'arrow-down',
  'arrow-left',
  'arrow-right',
  'arrow-up',
  'calendar',
  'hamburger',
  'check',
  'settings',
  'circle',
  'removal',
  'radio',
  'social',
  'tree',
  'case',
  'digital-adv',
  'computer',
  'cart',
  'physical-activation',
  'music-video',
  'tv',
  'ticket',
  'note',
  'print',
  'picture',
  'playlist',
  'drag',
  'calendar-big',
  'crm',
  'edit',
  'time',
  'EditOutline',
  'sharedOutline',
  'undo',
  'view-outline',
  'deleteOutline',
  'shared',
  'view',
  'playlist-medium',
  'print-medium',
  'radio-medium',
  'social-medium',
  'picture-medium',
  'tv-medium',
  'ticket-medium',
  'crm-medium',
  'digital-adv-medium',
  'cart-medium',
  'music-video-medium',
  'note-medium',
  'tree-medium',
  'case-medium',
  'physical-activation-medium',
  'round-add',
  'ellipsis-menu',
  'shared-outline',
  'expand-field',
  'collapse-field',
  'autogenerate',
  'edit-outline',
  'board',
  'info',
  'delete-outline',
  'delete-inner-outline',
  'unassigned-status',
  'appearances',
  'reject',
  'approve',
  'arrow-right-thin',
  'tv-ad-medium',
  'add-bold',
  'enter',
  'apollo',
  'copy',
  'no-results',
  'youtube',
  'deezer',
  'facebook',
  'google',
  'instagram',
  'linkfire',
  'pandora',
  'shazam',
  'snapchat',
  'soundcloud',
  'tidal',
  'tiktok',
  'twitter',
  'link',
  'blocked',
  'down-single',
  'unknown',
  'dollar',
  'confidential',
  'unread',
  'thin-plus',
  'partial-data',
  'expand-bold',
  'collapse-bold',
  'envelope',
  'label-permissions',
] as const;

export type IconName = typeof ALL_ICON_NAMES[number];
