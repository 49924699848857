import React, { useCallback } from 'react';
import { history } from 'utils/navigation';
import { Typography, TYPOGRAPHY_TYPE } from 'gdb-web-shared-components';
import { SErrorView, BEM_CLASS } from './s-error-view';
import { ErrorViewProps, isConfigType } from './types';
import { defaultErrorViewConfig } from './constants';
import { bem } from 'utils/bem';

const classes = bem(BEM_CLASS);

export const ErrorView = React.memo((props: ErrorViewProps) => {
  const { className, config, action } = props;

  const viewConfig = isConfigType(config) ? defaultErrorViewConfig[config] : config;
  const defaultAction = useCallback(() => history.go(0), []);

  return (
    <SErrorView className={className}>
      {viewConfig?.topImageSrc && (
        <img src={viewConfig.topImageSrc} alt="Error occurred" className={classes('image', 'top')} />
      )}
      {viewConfig?.description && (
        <Typography type={TYPOGRAPHY_TYPE.body4} className={classes('description')}>
          {viewConfig.description}
        </Typography>
      )}
      <button className={classes('action-button')} onClick={action || defaultAction}>
        <Typography type={TYPOGRAPHY_TYPE.body2}>{viewConfig?.actionTitle}</Typography>
      </button>
      {viewConfig?.bottomImageSrc && (
        <img src={viewConfig.bottomImageSrc} alt="Error occurred" className={classes('image', 'bottom')} />
      )}
    </SErrorView>
  );
});
