import React from 'react';
import { STabsLoader } from './s-tabs-loader';
import { Skeleton } from 'common/components/skeleton';
import { transparentize } from 'polished';
import { manhattanThemeColors } from 'app/theme/colors';

interface Props {
  className?: string;
}

export const TabsLoader = React.memo(({ className }: Props) => {
  return (
    <STabsLoader className={className}>
      <Skeleton
        width={412}
        height={15}
        backgroundColor={transparentize(0.9, manhattanThemeColors.primaryDark)}
        foregroundColor={transparentize(0.95, manhattanThemeColors.primaryDark)}
      >
        <rect x="0" y="0" rx="3" ry="3" width="106" height="15" />
        <rect x="148" y="0" rx="3" ry="3" width="106" height="15" />
        <rect x="296" y="0" rx="3" ry="3" width="106" height="15" />
      </Skeleton>
    </STabsLoader>
  );
});
