import React from 'react';

export enum ErrorViewStates {
  none = 'none',
  notFound = 'notFound',
  serverError = 'serverError',
  globalError = 'globalError',
}

export interface ErrorViewConfig {
  topImageSrc?: string;
  bottomImageSrc?: string;
  description?: React.ReactNode;
  actionTitle: string;
}

export type ConfigType = Exclude<ErrorViewStates, ErrorViewStates.none>;

export interface ErrorViewProps {
  className?: string;
  config: ConfigType | ErrorViewConfig;
  action?(): void;
}

export const isConfigType = (type: any): type is ConfigType =>
  typeof type === 'string' && ErrorViewStates[type] && ErrorViewStates[type] !== ErrorViewStates.none;
