import { GlobalLayout } from 'app/components/global-layout';
import { Head, HeadStaticTitles } from 'app/components/head';
import { ErrorViewStates } from 'common-v2/components';
import { useAppContext } from 'common-v2/hooks';
import { THEME, TOAST_TYPE, Typography, TYPOGRAPHY_TYPE, useManageToasts } from 'gdb-web-shared-components';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { updateCurrentUser } from 'settings-v2/actions';
import { DatePreferences, UserInfo } from 'settings-v2/components';
import { DATE_FORMAT_OPTIONS } from 'settings-v2/constants';
import { trackEvent, AnalyticsEvents } from 'utils/analytic';
import { bem } from 'utils/bem';
import { SSettingsContainer, BEM_CLASS } from './s-settings-container';

interface SettingsContainerProps {
  className?: string;
}

const classes = bem(BEM_CLASS);

export const SettingsContainer = React.memo(({ className }: SettingsContainerProps) => {
  const dispatch = useDispatch();
  const { openToast } = useManageToasts(THEME.light);

  const { user, labels } = useAppContext();

  const dateFormat = useMemo(() => {
    const dateFormatOption = DATE_FORMAT_OPTIONS.find(({ id }) => user.dateTimeFormat === id) ?? DATE_FORMAT_OPTIONS[0];
    return dateFormatOption.format;
  }, [user.dateTimeFormat]);

  const onError = useCallback(() => {
    openToast({
      id: 'update-current-user-fail',
      message: 'An error occurred while saving date format. Please try again.',
      type: TOAST_TYPE.ERROR,
    });
  }, [openToast]);

  const onChangeDatePreference = useCallback(
    (dateTimeFormat: string) => {
      const dateFormatOption =
        DATE_FORMAT_OPTIONS.find(({ format }) => dateTimeFormat === format) ?? DATE_FORMAT_OPTIONS[0];
      dispatch(updateCurrentUser.request({ dateTimeFormat: dateFormatOption.id, phone: user.phone, onError }));

      dateFormatOption &&
        trackEvent(AnalyticsEvents.CHANGE_DATE_AND_TIME_PREFERENCES, {
          new_date_and_time_format: dateFormatOption.name,
        });
    },
    [dispatch, onError, user.phone]
  );

  useEffect(() => trackEvent(AnalyticsEvents.VISIT_USER_SETTINGS_PAGE), []);

  return (
    <>
      <Head title={HeadStaticTitles.SETTINGS} />
      <SSettingsContainer className={className}>
        <GlobalLayout className={classes('background')} errorConfig={{ type: ErrorViewStates.none }}>
          <div className={classes('container')}>
            <Typography className={classes('title')} type={TYPOGRAPHY_TYPE.heading1}>
              User Settings
            </Typography>
            <UserInfo className={classes('user-info')} user={user} labels={labels} />
            <DatePreferences
              className={classes('date-preferences')}
              dateFormat={dateFormat}
              onChangeDateFormat={onChangeDatePreference}
            />
          </div>
        </GlobalLayout>
      </SSettingsContainer>
    </>
  );
});
