import React from 'react';
import { Skeleton } from 'common-v2/components';
import { SKELETON_STYLE } from './constants';

interface ImageLoaderProps {
  className?: string;
}

export const ImageLoader = React.memo(({ className }: ImageLoaderProps) => (
  <Skeleton className={className} preserveAspectRatio="none" style={SKELETON_STYLE}>
    <rect x="0" y="0" width="100%" height="100%" />
  </Skeleton>
));
