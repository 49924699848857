import React, { useCallback, useEffect, useRef } from 'react';
import { bem } from 'utils/bem';
import { Icon } from 'common/components/icon';
import { Button } from 'common/components/button';
import { Body, SmallText } from 'common/components/typography';
import { BEM_CLASS, SConfirmationModal } from './s-confirmation-modal';
import { CONFIRMATION_MODAL_CONTENT_ID } from './constants';

const classes = bem(BEM_CLASS);

interface ConfirmationModalProps {
  className?: string;
  text: string;
  isVisible: boolean;
  action(allow: boolean): void;
}

export const ConfirmationModal = React.memo(({ className, text, isVisible, action }: ConfirmationModalProps) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  const confirmAction = useCallback(() => {
    action(true);
  }, [action]);

  const cancelAction = useCallback(() => {
    action(false);
  }, [action]);

  useEffect(() => {
    const cancelButtonElement = cancelButtonRef && cancelButtonRef.current;

    if (cancelButtonElement && isVisible) {
      cancelButtonElement.focus();
    }
  }, [isVisible]);

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Tab') {
      const isCancelFocused = document.activeElement?.isEqualNode(cancelButtonRef.current);

      if (isCancelFocused) {
        confirmButtonRef.current?.focus();
      } else {
        cancelButtonRef.current?.focus();
      }
      event.preventDefault();
      event.stopPropagation();
    }
  }, []);

  return (
    <SConfirmationModal
      className={className}
      data-selector="confirmation-modal"
      isVisible={isVisible}
      onKeyDown={onKeyDown}
      data-id={CONFIRMATION_MODAL_CONTENT_ID}
    >
      <div className={classes('overlay')} onClick={cancelAction} data-id={CONFIRMATION_MODAL_CONTENT_ID} />
      <div className={classes('wrapper')} data-id={CONFIRMATION_MODAL_CONTENT_ID}>
        <div className={classes('container')} data-id={CONFIRMATION_MODAL_CONTENT_ID}>
          <div
            className={classes('content')}
            data-selector="confirmation-modal-text"
            data-id={CONFIRMATION_MODAL_CONTENT_ID}
          >
            <Body className={classes('text')} data-id={CONFIRMATION_MODAL_CONTENT_ID}>
              {text}
            </Body>
            <SmallText>Are you sure you want to continue?</SmallText>
          </div>
          <button className={classes('close')} onClick={cancelAction} data-id={CONFIRMATION_MODAL_CONTENT_ID}>
            <Icon name="close" size="general" data-id={CONFIRMATION_MODAL_CONTENT_ID} />
          </button>
        </div>
        <div className={classes('actions')} data-id={CONFIRMATION_MODAL_CONTENT_ID}>
          <Button
            ref={cancelButtonRef}
            className={classes('action')}
            dataSelector="confirmation-modal-cancel-button"
            onClick={cancelAction}
            dataId={CONFIRMATION_MODAL_CONTENT_ID}
          >
            Cancel
          </Button>
          <Button
            ref={confirmButtonRef}
            className={classes('action')}
            dataSelector="confirmation-modal-delete-button"
            variant="secondary-cta"
            onClick={confirmAction}
            dataId={CONFIRMATION_MODAL_CONTENT_ID}
          >
            Delete
          </Button>
        </div>
      </div>
    </SConfirmationModal>
  );
});
