import styled from 'styled-components';

export const BEM_CLASS = 'artist-item';

export const SArtistItem = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;

  .${BEM_CLASS} {
    &__icon {
      margin-left: 10px;
    }
  }
`;
