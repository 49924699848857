import React, { useMemo } from 'react';
import { bem } from 'utils/bem';
import { appearingAnimation } from 'common/constants';
import { BEM_CLASS, SArtistTeamWidget } from './s-artist-team-widget';
import { Button } from 'common/components/button';
import { H4 } from 'common/components/typography';
import { ArtistTeam } from 'backend-api/models';
import { Team } from 'common/components/team';
import { ArtistPlaylistWidgetLoader } from '../artist-playlist-widget/components';
import { EmptyState } from './components';

interface Props {
  isLoading: boolean;
  onViewTeam(): void;
  onCreateArtistTeam(): void;
  artistTeam?: ArtistTeam;
  isUnclaimed?: boolean;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ArtistTeamWidget = React.memo(
  ({ artistTeam, onViewTeam, isLoading, onCreateArtistTeam, isUnclaimed, className }: Props) => {
    const teamUsers = useMemo(() => artistTeam?.users || [], [artistTeam]);

    const body = useMemo(() => {
      if (isLoading) {
        return <ArtistPlaylistWidgetLoader className={classes('loader')} />;
      }
      if (!artistTeam || isUnclaimed) {
        return <EmptyState onCreateArtistTeam={onCreateArtistTeam} />;
      }

      return (
        <>
          <div className={classes('header')}>
            <H4>Artist Team</H4>
            <Button
              variant="secondary-cta"
              onClick={onViewTeam}
              dataSelector="artist-team-widget-view-artist-team-button"
            >
              View Team
            </Button>
          </div>
          <Team users={teamUsers} className={classes('team')} />
        </>
      );
    }, [artistTeam, isLoading, onCreateArtistTeam, onViewTeam, teamUsers, isUnclaimed]);

    return (
      <SArtistTeamWidget {...appearingAnimation} className={className} data-selector="artist-team-widget">
        {body}
      </SArtistTeamWidget>
    );
  }
);
