import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { Link } from 'react-router-dom';

export const BEM_CLASS = 'search-item';

const IMAGE_SIZE = 40;
const IMAGE_GAP = 20;

interface SSearchItemProps {
  image?: string;
  $hasBorder?: boolean;
  $isDisabled: boolean;
}

export const SSearchItem = styled(Link)<SSearchItemProps>`
  position: relative;
  display: flex;
  text-decoration: none;
  color: ${palette.black};
  padding-bottom: 10px;

  // TODO: Remove after general campaign page will be ready
  ${({ $isDisabled }) => $isDisabled && 'pointer-events: none;'}

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: calc(100% - ${IMAGE_SIZE + IMAGE_GAP}px);
    height: 1px;
    background-color: ${palette.gray235};
    display: ${({ $hasBorder }) => ($hasBorder ? 'block' : 'none')};
  }

  .${BEM_CLASS} {
    &__image,
    &__icon-container {
      width: 40px;
      height: 40px;
      margin-right: 20px;
      flex-shrink: 0;

      &--rounded {
        border-radius: 50%;
      }
    }

    &__icon-container {
      display: flex;
      border-radius: 10px;
      background-color: ${palette.athensGrayTwo};
    }

    &__icon {
      display: flex;
      margin: auto;
    }

    &__content {
      flex-grow: 1;
      display: flex;
      overflow: hidden;
    }
  }
`;
