import React from 'react';
import { bem } from 'utils/bem';
import { Typography, TYPOGRAPHY_TYPE, Input, Icon, ICON_SIZE, THEME } from 'gdb-web-shared-components';
import { BEM_CLASS, SLabelPermissions } from './s-label-permissions';

export const classes = bem(BEM_CLASS);

interface LabelPermissionsProps {
  tabs: React.ReactNode;
  search: string;
  onSearchChange(event: React.ChangeEvent<HTMLInputElement>): void;
  clearSearch(): void;
  isSearchDisabled: boolean;
  placeholder?: string;
  labelName?: string;
}

export const LabelPermissions = React.memo(
  ({
    tabs,
    search,
    onSearchChange,
    clearSearch,
    children,
    isSearchDisabled,
    placeholder,
    labelName,
  }: React.PropsWithChildren<LabelPermissionsProps>) => (
    <SLabelPermissions>
      <div className={classes('title')} data-selector="label-permissions-title">
        <Icon className={classes('icon')} name="label-permissions" size={ICON_SIZE.medium} />
        <Typography type={TYPOGRAPHY_TYPE.heading1}>{labelName || 'Label'} Permissions</Typography>
      </div>

      {tabs}

      <div className={classes('container')}>
        <div className={classes('search-field')} data-selector="label-permissions-search-field">
          <Input
            value={search}
            placeholder={placeholder || 'Search'}
            icon="search"
            onChange={onSearchChange}
            theme={THEME.light}
            onClearClick={clearSearch}
            isClearable={Boolean(search.length)}
            disabled={isSearchDisabled}
            inputClassName={classes('input')}
          />
        </div>

        {children}
      </div>
    </SLabelPermissions>
  )
);
