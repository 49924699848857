import React, { useCallback, useRef, useState } from 'react';
import { SecondaryLabel } from 'common/components/typography';
import { callbackWithoutPropagation } from 'utils/event';
import { ReleaseDetailsPopup } from 'media-plan/components/release-details-popup';
import { bem } from 'utils/bem';
import { BEM_CLASS, SReleaseInfo } from './s-release-info';
import { Id } from 'backend-api/models';

interface Props {
  mediaPlanId: Id;
  projectId: Id;
  isInputsDisabled: boolean;
  className?: string;
}

const classes = bem(BEM_CLASS);

export const ReleaseInfo = React.memo(({ mediaPlanId, projectId, isInputsDisabled, className }: Props) => {
  const releaseDetailsControlRef = useRef(null);
  const [isReleaseDetailsOpened, setIsReleaseDetailsOpened] = useState(false);

  const openReleaseDetails = useCallback(() => {
    setIsReleaseDetailsOpened(true);
  }, []);

  const closeReleaseDetails = useCallback(() => {
    setIsReleaseDetailsOpened(false);
  }, []);

  return (
    <SReleaseInfo className={className}>
      <div className={classes('info-item')}>
        <SecondaryLabel>Release details:</SecondaryLabel>
        <div className={classes('release-details')}>
          <button
            ref={releaseDetailsControlRef}
            onMouseDown={callbackWithoutPropagation()}
            onClick={openReleaseDetails}
          >
            View
          </button>
          <ReleaseDetailsPopup
            projectId={projectId}
            mediaPlanId={mediaPlanId}
            isOpened={isReleaseDetailsOpened}
            isDisabled={isInputsDisabled}
            onClose={closeReleaseDetails}
            controlRef={releaseDetailsControlRef}
          />
        </div>
      </div>
    </SReleaseInfo>
  );
});
