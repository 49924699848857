import React from 'react';
import { bem } from 'utils/bem';
import { LoaderItem } from 'common/components/loaders/loader-item';
import { SProjectsLoader } from './s-projects-loader';
import { LoaderCategory } from 'common/components/loaders/loader-category';

const classes = bem('release-loader');

interface Props {
  className?: string;
}

export const ProjectsLoader = ({ className }: Props) => {
  return (
    <SProjectsLoader className={className}>
      <LoaderCategory />
      <div className={classes('items-container')}>
        <LoaderItem className={classes('item')} />
        <LoaderItem className={classes('item')} />
      </div>
    </SProjectsLoader>
  );
};
