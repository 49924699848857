import { manhattanThemeColors } from 'app/theme/colors';
import styled from 'styled-components';
import { transparentize } from 'polished';

export const BEM_CLASS = 'request-approval-footer';

export const SRequestApprovalFooter = styled.div`
  display: grid;
  padding: 10px 40px;
  grid-template-columns: repeat(3, 1fr);
  background-color: ${manhattanThemeColors.background};
  border: 1px solid ${transparentize(0.9, manhattanThemeColors.primaryDark)};

  .${BEM_CLASS} {
    &__info {
      grid-column: 1 / 2;
      display: flex;
      align-items: center;
    }

    &__buttons {
      grid-column: 0 / 4;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    &__cancel {
      &::before {
        border-color: ${transparentize(0.6, manhattanThemeColors.primaryFillNew)};
      }
    }

    &__warning-container {
      display: flex;
      align-items: center;
      gap: 7px;
      color: ${manhattanThemeColors.darkRed};
    }

    &__empty-checkboxes-text {
      color: ${manhattanThemeColors.darkRed};
    }
  }
`;
