import { useCallback, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncTransactionsFlushed, GridApi, RowNode } from 'ag-grid-community';
import { Id, ModifyBulkCampaignsResponse } from 'backend-api/models';
import { getMediaPlan, modifyBulkCampaigns } from 'media-plan-v2/actions';
import { isSyncingSelector } from 'media-plan-v2/selectors';
import { PendingData } from '../../types';
import { MediaPlanColumnId, SpreadsheetRowData } from 'media-plan-v2/containers/spreadsheet/types';
import { dateFormatSelector } from 'common/selectors';
import { EMPTY_PENDING_DATA, SYNC_INTERVAL } from '../../constants';
import { getInitialCellData } from 'media-plan-v2/containers/spreadsheet/transducers';
import { aggregatePendingDataStore, sanitizePendingDataStore, mapPendingData, enrichPendingData } from './transducers';
import { MediaPlanMode } from 'media-plan-v2/types';
import { useManageToasts, THEME } from 'gdb-web-shared-components';

export const useSync = (
  mediaPlanId: Id,
  projectId: Id,
  gridApi?: GridApi<SpreadsheetRowData>,
  mediaPlanMode?: MediaPlanMode
) => {
  const [pendingData, setPendingData] = useState<PendingData>(EMPTY_PENDING_DATA);

  const dispatch = useDispatch();
  const { openToast } = useManageToasts(THEME.light);

  const isSyncing = useSelector(isSyncingSelector);
  const dateFormat = useSelector(dateFormatSelector);
  const isPendingDataEmpty = useMemo(() => Object.values(pendingData).every(store => store === null), [pendingData]);

  const onSyncSuccess = useCallback(
    ({ created, updated }: ModifyBulkCampaignsResponse) => {
      if (!gridApi) {
        throw 'Cannot sync the table. Please refresh the page.';
      } else {
        const updatedNodes: RowNode<SpreadsheetRowData>[] = [];
        const updateTransaction: SpreadsheetRowData[] = [...created, ...updated].flatMap(data => {
          const node = gridApi.getRowNode(data.uuid);

          if (!node || !node.data) return [];

          updatedNodes.push(node);

          return [
            {
              ...node.data,
              id: getInitialCellData(data.id),
              namingConvention: getInitialCellData(data.namingConvention),
              uuid: getInitialCellData(data.uuid),
            },
          ];
        });

        gridApi.applyTransaction({
          update: updateTransaction,
        });

        gridApi.refreshCells({
          columns: [MediaPlanColumnId.NAMING_CONVENTION],
          rowNodes: updatedNodes,
          force: true,
        });
      }
    },
    [gridApi]
  );

  const onSyncError = useCallback(() => {
    dispatch(getMediaPlan.request({ projectId, mediaPlanId }));
  }, [dispatch, mediaPlanId, projectId]);

  const syncData = useCallback(
    ({ results }: AsyncTransactionsFlushed<SpreadsheetRowData>) => {
      const pendingDataCopy = { ...pendingData };

      const aggregatedPendingData = results.reduce<PendingData>((acc, result) => {
        result.add?.forEach(({ data }) => {
          acc.create = aggregatePendingDataStore(acc, 'create', data);
        });

        result.update?.forEach(({ data }) => {
          acc.update = aggregatePendingDataStore(acc, 'update', data);
        });

        result.remove?.forEach(({ data }) => {
          acc.delete = aggregatePendingDataStore(acc, 'delete', data);
        });

        return acc;
      }, pendingDataCopy);

      const sanitizedPendingData = sanitizePendingDataStore(aggregatedPendingData);

      setPendingData(sanitizedPendingData);
    },
    [pendingData]
  );

  useDebounce(
    () => {
      if (!gridApi) return;

      if (!isSyncing && !isPendingDataEmpty) {
        setPendingData(EMPTY_PENDING_DATA);

        const enrichedPendingData = enrichPendingData(gridApi, pendingData);

        dispatch(
          modifyBulkCampaigns.request({
            mediaPlanId,
            projectId,
            payload: mapPendingData(enrichedPendingData, mediaPlanMode, { dateFormat }),

            onSuccess: onSyncSuccess,
            onError: onSyncError,
            openToast,
          })
        );
      }
    },
    SYNC_INTERVAL,
    [dispatch, gridApi, pendingData, isSyncing, isPendingDataEmpty, mediaPlanMode, mediaPlanId, projectId, openToast]
  );

  return { syncData, isDataSyncing: isSyncing || !isPendingDataEmpty };
};
