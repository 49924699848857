import { createTypedReducer, onAction } from 'core/store';
import { LoadingState, LoadableData } from 'common/types';
import {
  hidePermissionsNotification,
  showPermissionsNotification,
  getArtistInfo,
  getProjects,
  getUnassignedProjects,
  updateFilters,
  setPermissionsUnavailableLabels,
  disablePermissionsNotification,
  getLabelUsers,
  editArtistTeam,
  showSharingModal,
  hideSharingModal,
  selectArtistTeam,
} from './actions';
import { PermissionsState, ProjectsFilters, TableBodyContent, ArtistInfo, SharingModal } from './types';
import { ArtistProjectsMetadata, ArtistTeam, Optional } from 'backend-api/models';
import { isPeriodChanged } from 'common/transducers';
import { ApiError } from 'backend-api';
import { ProjectDetailsErrorResponse } from 'backend-api/types';

export interface State {
  artist: LoadableData<Optional<ArtistInfo>>;
  projects: LoadableData<TableBodyContent>;
  unassignedProjects: LoadableData<TableBodyContent>;
  metadata?: ArtistProjectsMetadata;
  filters: ProjectsFilters;
  permissions: PermissionsState;
  sharingModal: LoadableData<SharingModal>;
  selectedArtistTeam?: ArtistTeam;
  error?: ApiError<ProjectDetailsErrorResponse>;
}

export const initialState: State = {
  artist: {
    loading: LoadingState.Idle,
    data: undefined,
  },
  projects: {
    loading: LoadingState.Idle,
    data: [],
  },
  unassignedProjects: {
    loading: LoadingState.Idle,
    data: [],
  },
  filters: {
    filtersByStatus: {},
  },
  permissions: {
    isNotificationActive: true,
    isNotificationVisible: false,
    unavailableLabels: [],
  },
  sharingModal: {
    loading: LoadingState.Idle,
    data: {
      isOpened: false,
      labelUsers: [],
    },
  },
};

export const reducer = createTypedReducer<State>(
  initialState,
  onAction(getArtistInfo.request, state => ({
    ...state,
    artist: {
      loading: LoadingState.Started,
      data: undefined,
    },
  })),
  onAction(getArtistInfo.success, (state, { payload }) => ({
    ...state,
    artist: {
      ...state.artist,
      loading: LoadingState.Finished,
      data: payload,
    },
  })),
  onAction(getArtistInfo.failure, state => ({
    ...state,
    artist: {
      ...state.artist,
      loading: LoadingState.Failed,
    },
  })),
  onAction(getProjects.request, state => ({
    ...state,
    projects: {
      loading: LoadingState.Started,
      data: [],
    },
  })),
  onAction(getProjects.success, (state, { payload }) => ({
    ...state,
    projects: {
      loading: LoadingState.Finished,
      data: payload.items,
    },
    metadata: payload.metadata,
  })),
  onAction(getProjects.failure, (state, action) => ({
    ...state,
    projects: {
      ...state.projects,
      loading: LoadingState.Failed,
    },
    error: action.payload,
  })),
  onAction(getUnassignedProjects.request, state => ({
    ...state,
    unassignedProjects: {
      loading: LoadingState.Started,
      data: [],
    },
  })),
  onAction(getUnassignedProjects.success, (state, { payload }) => ({
    ...state,
    unassignedProjects: {
      loading: LoadingState.Finished,
      data: payload.items,
    },
    metadata: payload.metadata,
  })),
  onAction(getUnassignedProjects.failure, state => ({
    ...state,
    unassignedProjects: {
      ...state.unassignedProjects,
      loading: LoadingState.Failed,
    },
  })),
  onAction(updateFilters, (state, action) => {
    let filtersByStatus = {
      ...state.filters.filtersByStatus,
      ...action.payload.filtersByStatus,
    };

    const shouldResetOffsets =
      state.filters.search !== action.payload.search ||
      isPeriodChanged(
        [state.filters.earliestStartDate, state.filters.latestEndDate],
        [action.payload.earliestStartDate, action.payload.latestEndDate]
      );

    if (shouldResetOffsets) {
      filtersByStatus = Object.keys(filtersByStatus).reduce((filters, key) => {
        filters[key] = {
          ...filtersByStatus[key],
          offset: 0,
        };

        return filters;
      }, {});
    }

    return {
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
        filtersByStatus,
      },
    };
  }),
  onAction(setPermissionsUnavailableLabels, (state, { payload }) => ({
    ...state,
    permissions: {
      ...state.permissions,
      unavailableLabels: payload,
    },
  })),
  onAction(showPermissionsNotification, state => ({
    ...state,
    permissions: {
      ...state.permissions,
      isNotificationVisible: true,
    },
  })),
  onAction(hidePermissionsNotification, state => ({
    ...state,
    permissions: {
      ...state.permissions,
      isNotificationVisible: false,
    },
  })),
  onAction(disablePermissionsNotification, state => ({
    ...state,
    permissions: {
      ...state.permissions,
      isNotificationActive: false,
      isNotificationVisible: false,
    },
  })),
  onAction(getLabelUsers.request, state => ({
    ...state,
    sharingModal: {
      ...state.sharingModal,
      loading: LoadingState.Started,
    },
  })),
  onAction(getLabelUsers.success, (state, { payload }) => ({
    ...state,
    sharingModal: {
      data: {
        ...state.sharingModal.data,
        labelUsers: payload,
      },
      loading: LoadingState.Finished,
    },
  })),
  onAction(showSharingModal, state => ({
    ...state,
    sharingModal: {
      data: {
        ...state.sharingModal.data,
        isOpened: true,
      },
      loading: LoadingState.Finished,
    },
  })),
  onAction(hideSharingModal, state => ({
    ...state,
    sharingModal: {
      data: {
        ...state.sharingModal.data,
        isOpened: false,
      },
      loading: LoadingState.Finished,
    },
  })),
  onAction(getLabelUsers.failure, state => ({
    ...state,
    sharingModal: {
      ...state.sharingModal,
      loading: LoadingState.Failed,
    },
  })),
  onAction(editArtistTeam.request, state => ({
    ...state,
    sharingModal: {
      ...state.sharingModal,
      loading: LoadingState.Started,
    },
  })),
  onAction(editArtistTeam.success, state => ({
    ...state,
    sharingModal: {
      ...state.sharingModal,
      data: {
        ...state.sharingModal.data,
        isOpened: false,
      },
      loading: LoadingState.Finished,
    },
  })),
  onAction(editArtistTeam.failure, state => ({
    ...state,
    sharingModal: {
      ...state.sharingModal,
      data: {
        ...state.sharingModal.data,
        error: 'Changes couldn’t be saved. Try to save them later.',
        isOpened: false,
      },
      loading: LoadingState.Finished,
    },
  })),
  onAction(selectArtistTeam, (state, { payload }) => ({
    ...state,
    selectedArtistTeam: payload,
  }))
);
//†reducer
