import React from 'react';
import { bem } from 'utils/bem';
import { getCurrencyFormatted } from 'utils/format';
import { Campaign } from 'backend-api/models';
import { CampaignSortField } from 'backend-api/types';
import { LongTableTitle, TableContent } from 'common/components/table';
import { CampaignPlatformIcon } from 'common/components/campaign-platform-icon';
import { BEM_CLASS } from './s-table';
import { getFormattedDate } from 'common-v2/utils';

const classes = bem(BEM_CLASS);

export const getCampaignsTableConfig = (
  dateFormat: string,
  currencyCode: string
): TableContent<Campaign, CampaignSortField>[] => [
  {
    title: 'Campaign Name',
    dataSelector: 'bulk-assignment-campaigns-name-column-header',
    dataIndex: 'name',
    useSort: true,
    sortField: 'name',
    render: record => <LongTableTitle text={record.name} dataSelector="campaign-name-modal" />,
  },
  {
    title: 'Platform',
    dataIndex: 'platform',
    dataSelector: 'bulk-assignment-campaigns-platform-column-header',
    useSort: true,
    sortField: 'platforms',
    additionalClassName: classes('platform-cell'),
    render: record => <CampaignPlatformIcon platforms={record.platforms.map(item => item.name)} />,
  },
  {
    title: 'Spend',
    dataIndex: 'spend',
    dataSelector: 'bulk-assignment-campaigns-spend-column-header',
    useSort: true,
    sortField: 'spend',
    additionalClassName: classes('small-table-cell'),
    render: record => <span>{getCurrencyFormatted(record.budgetSpend || record.plannedBudget, currencyCode)}</span>,
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    dataSelector: 'bulk-assignment-campaigns-start-date-column-header',
    useSort: true,
    sortField: 'startDate',
    additionalClassName: classes('small-table-cell'),
    render: record => <span>{getFormattedDate(dateFormat)(record.startDate)}</span>,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    dataSelector: 'campaigns-end-date-column-header',
    useSort: true,
    sortField: 'endDate',
    additionalClassName: classes('small-table-cell'),
    render: record => !!record.endDate && <span>{getFormattedDate(dateFormat)(record.endDate)}</span>,
  },
];
