import { IdNameField } from 'backend-api/models';

export interface LocalRole extends IdNameField {
  icon: string;
  description?: string;
  weight: number;
}

export enum RestrictionCode {
  ProjectEditRestricted = 'project_edit_restricted',
  ProjectAccessRestricted = 'project_access_restricted',
  CampaignCreationRestricted = 'campaign_creation_restricted',
  AssignCampaignRestricted = 'assign_campaign_restricted',
  TeamEditRestricted = 'team_edit_restricted',
  TeamViewRestricted = 'team_view_restricted',
  UnclaimedProjectAccessRestricted = 'unclaimed_project_access_restricted',
}
