import { ShortArtist } from 'backend-api/models';
import { ArtistsResults, ProcessedArtists } from './types';

export const processArtists = (
  labelArtists: ShortArtist[],
  recentlyAddedArtists: ShortArtist[],
  removedArtistsIds: string[],
  userArtistsIds: string[]
): ProcessedArtists => {
  const alreadyAddedArtists: ShortArtist[] = [];
  const artistsForAssignAll: ShortArtist[] = [];
  const artistsForSearch: ShortArtist[] = [];

  labelArtists.forEach(labelArtist => {
    const isUserArtist = userArtistsIds.includes(labelArtist.id);
    const isRemovedFromUserArtists = removedArtistsIds.includes(labelArtist.id);

    const isAlreadyAddedArtist = isUserArtist && !isRemovedFromUserArtists;
    if (isAlreadyAddedArtist) {
      alreadyAddedArtists.push(labelArtist);
    }

    const isRecentlyAdded = recentlyAddedArtists.some(recentlyAddedArtist => recentlyAddedArtist.id === labelArtist.id);
    const isNotAdded = !(isUserArtist || isRecentlyAdded);
    const canBeAdded = !isRecentlyAdded && isRemovedFromUserArtists;
    const isArtistAvailableForSearch = isNotAdded || canBeAdded;

    if (isArtistAvailableForSearch) {
      artistsForSearch.push(labelArtist);
    }

    const isAssignableUser = !isUserArtist || isRemovedFromUserArtists;
    if (isAssignableUser) {
      artistsForAssignAll.push(labelArtist);
    }
  });

  return { alreadyAddedArtists, artistsForAssignAll, artistsForSearch };
};

export const processArtistsResults = (
  initialUserArtistsIds: string[],
  recentlyAddedArtists: ShortArtist[],
  removedArtistsIds: string[]
): ArtistsResults => {
  const addedArtistsIds = recentlyAddedArtists
    .filter(({ id }) => !initialUserArtistsIds.includes(id))
    .map(({ id }) => id);
  const deletedArtistsIds = removedArtistsIds.filter(id => !recentlyAddedArtists.some(artist => artist.id === id));
  return { addedArtistsIds, deletedArtistsIds };
};
