import styled, { css } from 'styled-components';
import { theme } from 'app/theme';
import { colorPalette } from 'app/theme/colors';

interface Props {
  position: number;
}

export const BEM_CLASS = 'release-details-popup';

export const SReleaseDetailsPopup = styled(({ className, children }) => children(className))<Props>`
  .${BEM_CLASS} {
    &__root {
      position: absolute;
      z-index: 1;
      background-color: ${colorPalette.white};
      border-radius: 10px;
      box-shadow: ${theme.shadows.default};
      padding: 20px 20px 30px;

      ${({ position }) => css`
        top: ${position}px;
      `}
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }

    &__title {
      margin-right: auto;
    }

    &__close {
      display: flex;
      margin-left: 20px;
      color: ${colorPalette.lavenderGray};
    }

    &__content {
      width: 425px;
    }

    &__loading {
      display: flex;
      justify-content: center;
    }

    &__error {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__error-text {
      line-height: 25px;
      text-align: center;
    }

    &__error-refresh {
      color: ${colorPalette.azureRadiance};
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      padding: 5px 20px;
    }

    &__content-item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }

    &__content-title {
      margin-right: 10px;
      white-space: nowrap;
    }

    &__content-field {
      width: 300px;
      margin-left: auto;
    }

    &__types-control {
      display: flex;
      align-items: center;
      width: 100%;
      border: 1px solid transparent;
      border-radius: 20px;
      background-color: ${colorPalette.ghostWhite};
      cursor: pointer;

      &--isFocused {
        border-color: ${colorPalette.azureRadiance};
        background-color: ${colorPalette.white};
      }
    }

    &__types-value-container-root {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    &__types-menu {
      width: 300px;
    }

    &__release-name-input {
      input:disabled {
        background-color: ${colorPalette.ghostWhite};
      }
    }
  }
`;
