import { range } from 'lodash';
import { PAGINATION_PAGES_LIMIT, PAGINATION_OFFSET } from 'common/constants';

export const getPages = (numberOfPages: number, current: number): number[] => {
  if (numberOfPages <= PAGINATION_PAGES_LIMIT + 1) {
    return range(1, numberOfPages + 1);
  }

  if (current > numberOfPages - PAGINATION_PAGES_LIMIT) {
    const lastPages = range(numberOfPages + 1 - PAGINATION_PAGES_LIMIT, numberOfPages + 1);
    return [1, -1, ...lastPages];
  }

  if (current <= PAGINATION_PAGES_LIMIT) {
    const firstPages = range(1, PAGINATION_PAGES_LIMIT + 1);
    return [...firstPages, -1, numberOfPages];
  }

  const from = current - PAGINATION_OFFSET;
  const to = current + PAGINATION_PAGES_LIMIT - PAGINATION_OFFSET;
  const middlePages = range(from, to);

  return [...middlePages, -2, numberOfPages];
};
