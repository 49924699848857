import { colorPalette } from 'app/theme/colors';
import { css, FlattenSimpleInterpolation } from 'styled-components';
import { CampaignStatuses } from 'backend-api/models';

export const getStatusBadgeStyling = (status: CampaignStatuses): FlattenSimpleInterpolation => {
  switch (status) {
    case CampaignStatuses.BOOKED:
      return css`
        background-color: rgba(230, 241, 255, 1);
      `;
    case CampaignStatuses.LIVE:
      return css`
        background-color: ${colorPalette.white};
        border-color: ${colorPalette.lavenderGray};
      `;
    case CampaignStatuses.COMPLETED:
      return css`
        color: ${colorPalette.white};
        background-color: rgba(102, 105, 122, 1);
      `;
    case CampaignStatuses.DRAFT:
      return css`
        background-color: ${colorPalette.ghostWhite};
      `;
    case CampaignStatuses.PENDING:
      return css`
        background-color: rgba(252, 248, 227, 1);
      `;
    case CampaignStatuses.APPROVED:
      return css`
        background-color: rgba(223, 247, 237, 1);
      `;

    case CampaignStatuses.DISAPPROVED:
      return css`
        background-color: rgba(255, 230, 239, 1);
      `;
    case CampaignStatuses.PAUSED:
      return css`
        background-color: ${colorPalette.mischka};
      `;
    case CampaignStatuses.REVIEW:
      return css`
        color: ${colorPalette.white};
        background-color: ${colorPalette.eastBay};
      `;
    default:
      return css`
        background-color: ${colorPalette.white};
      `;
  }
};
