import * as t from 'io-ts';
import { UUID } from 'io-ts-types/lib/UUID';
import {
  CampaignTypeAndCategorySchema,
  FormattedDateFromISOString,
  IdNameField,
  Optional,
  PaginationResponseModel,
  ResponseModel,
} from '../common';

export const PurchaseOrderCampaignSchema = ResponseModel(
  {
    ...IdNameField,
    uuid: UUID,
    isDeleted: t.boolean,
    type: Optional(CampaignTypeAndCategorySchema),
  },
  'PurchaseOrderCampaign'
);

export type PurchaseOrderCampaign = t.TypeOf<typeof PurchaseOrderCampaignSchema>;

export const PurchaseOrderStatusScheme = t.union([
  t.literal('Paid'),
  t.literal('Partially Paid'),
  t.literal('Approved'),
  t.literal('Payable'),
  t.literal('Removed'),
  t.literal('Processed'),
  t.literal('Failed'),
  t.literal('Unapproved'),
  t.literal('Rejected'),
  t.literal('Resubmitted'),
  t.literal('Returned'),
  t.literal('Pending'),
  t.literal('Unknown'),
  t.literal('To be approved'),
  t.literal('Invoiced'),
  t.literal('In Modification'),
  t.literal('Invoicing Started'),
  t.literal('Closed'),
  t.literal('Sent'),
]);

export type PurchaseOrderStatus = t.TypeOf<typeof PurchaseOrderStatusScheme>;

export const PurchaseOrderDetailsSchema = ResponseModel(
  {
    ...IdNameField,
    budget: t.number,
    allocation: t.number,
    status: PurchaseOrderStatusScheme,
    budgetGroup: ResponseModel(IdNameField),
    budgetCategory: ResponseModel(IdNameField),
    provider: ResponseModel(IdNameField),
    createdDate: FormattedDateFromISOString,
    lastModifiedDate: FormattedDateFromISOString,
    linkedCampaign: Optional(PurchaseOrderCampaignSchema),
    notes: Optional(t.string),
    poNumber: t.string,
    isBlanket: t.boolean,
    budgetCurrency: t.string,
  },
  'PurchaseOrderDetails'
);

export type PurchaseOrderDetails = t.TypeOf<typeof PurchaseOrderDetailsSchema>;

export const PurchaseOrderSchema = ResponseModel(
  {
    ...IdNameField,
    status: t.string,
    poNumber: t.string,
  },
  'PurchaseOrder'
);

export type PurchaseOrder = t.TypeOf<typeof PurchaseOrderSchema>;

export const PurchaseOrdersListResponseScheme = PaginationResponseModel(PurchaseOrderDetailsSchema);
export type PurchaseOrdersListResponse = t.TypeOf<typeof PurchaseOrdersListResponseScheme>;

export const PurchaseOrdersProviderSchema = ResponseModel(IdNameField, 'PurchaseOrdersProvider');
export type PurchaseOrdersProvider = t.TypeOf<typeof PurchaseOrdersProviderSchema>;
