import styled from 'styled-components';
import { theme } from 'app/theme';

export const BEM_CLASS = 'droppable-item-loader';

export const SDroppableItemLoader = styled.div`
  .${BEM_CLASS} {
    &__skeleton {
      overflow: hidden;
      border-radius: 5px;
      box-shadow: ${theme.shadows.smallCardShadow};
    }
  }
`;
