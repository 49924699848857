import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colorPalette } from 'app/theme/colors';
import { elipsizeText } from 'common/s-components/s-elipsize-text';

export const BEM_CLASS = 'purchase-order-link';

export const SPurchaseOrderLink = styled(Link)`
  display: flex;
  width: fit-content;
  max-width: 100%;
  background-color: ${colorPalette.ghostWhite};
  border-radius: 5px;
  overflow: hidden;

  .${BEM_CLASS} {
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      min-height: 60px;
      background-color: ${colorPalette.bluishWhite};
    }

    &__content {
      width: calc(100% - 60px);
      padding: 10px 15px 10px 20px;
    }

    &__head {
      display: flex;
      align-items: center;
      max-width: 100%;
    }

    &__title {
      ${elipsizeText};
      flex-grow: 1;
      margin-right: 10px;
      color: ${colorPalette.azureRadiance};
      font-weight: 600;
    }

    &__badge {
      flex-shrink: 0;
    }

    &__number {
      color: ${colorPalette.eastBay};
    }
  }
`;
