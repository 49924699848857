import React from 'react';
import { SNoProjects } from '../s-components/s-no-projects';
import { bem } from 'utils/bem';
import { WaveBackground } from 'common/components/wave-background';

interface Props {
  className?: string;
}

const classes = bem('no-projects');

export const NoProjects = React.memo(({ className }: Props) => (
  <WaveBackground heightFromChildren>
    <SNoProjects className={className}>
      <span className={classes('text')}>Your projects will appear here.</span>
    </SNoProjects>
  </WaveBackground>
));
