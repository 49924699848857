import styled from 'styled-components';
import { palette } from 'frontend-shared-theme';
import { responsiveContainerWrapperCss } from 'common/s-components/sidebar-form/s-responsive-container';
import { TABS_HEIGHT } from 'common/s-components/tabs/s-tabs';

export const BEM_CLASS = 'header';

interface SHeaderProps {
  hasTabs: boolean;
}

export const SHeader = styled.div<SHeaderProps>`
  padding-top: 25px;
  padding-bottom: ${({ hasTabs }) => !hasTabs && '25px'};
  background-color: ${palette.white};

  .${BEM_CLASS} {
    &__container {
      position: relative;
      z-index: 2;
    }

    &__top {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: ${({ hasTabs }) => hasTabs && '15px'};
    }

    &__loader {
      background-color: ${palette.white};
      margin-bottom: ${({ hasTabs }) => hasTabs && `-${TABS_HEIGHT}px`};
    }

    &__image {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      margin-right: 20px;
    }

    &__name {
      color: ${palette.black};
      font-size: 18px;
      line-height: 25px;
      margin: 0;
    }

    &__tabs {
      position: relative;
      z-index: 1;

      .tabs__header {
        ${responsiveContainerWrapperCss};
      }
    }

    &__team-entry {
      margin-left: auto;
    }
  }
`;
