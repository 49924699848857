import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';
import { colorPalette, manhattanThemeColors } from 'app/theme/colors';
import { transparentize } from 'polished';

interface STooltipProps {
  invertedColors?: boolean;
}

export const STooltip = styled(Tippy)<STooltipProps>`
  background: ${({ invertedColors }) => (invertedColors ? colorPalette.white : colorPalette.black)};
  color: ${({ invertedColors }) => (invertedColors ? colorPalette.black : colorPalette.white)};
  border-radius: 5px;
  padding: 7px 10px;
  ${({ invertedColors }) =>
    invertedColors
      ? css`
          box-shadow: 0px 4px 4px ${transparentize(0.75, manhattanThemeColors.primaryDark)};
        `
      : css`
          box-shadow: 0px 5px 20px ${transparentize(0.95, manhattanThemeColors.primaryDark)};
        `}

  .tippy-arrow {
    width: 0;
    height: 0;
  }

  &[data-placement*='bottom'] {
    .tippy-arrow {
      top: -5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid ${({ invertedColors }) => (invertedColors ? colorPalette.white : colorPalette.black)};
    }
  }

  &[data-placement*='top'] {
    .tippy-arrow {
      bottom: -5px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid ${({ invertedColors }) => (invertedColors ? colorPalette.white : colorPalette.black)};
    }
  }

  &[data-placement*='right'] {
    .tippy-arrow {
      left: -5px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid ${({ invertedColors }) => (invertedColors ? colorPalette.white : colorPalette.black)};
    }
  }

  &[data-placement*='left'] {
    .tippy-arrow {
      right: -5px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid ${({ invertedColors }) => (invertedColors ? colorPalette.white : colorPalette.black)};
    }
  }
`;
