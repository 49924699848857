import styled from 'styled-components';
import { colorPalette } from 'app/theme/colors';
import { ACTION_COLUMN_WIDTH, PAGE_PADDING } from 'media-plan/constants';
import { typography } from 'app/theme/typography';

export const BEM_CLASS = 'phase-row';

export const SPhaseRow = styled.div`
  display: flex;
  width: calc(100vw - ${PAGE_PADDING * 2}px - 2px); // 2px is table borders
  height: 100%;

  .${BEM_CLASS} {
    &__action {
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: ${ACTION_COLUMN_WIDTH}px;
      box-shadow: inset -1px 0 0 0 ${colorPalette.ghostWhite};
      margin-left: -1px;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    &__value-container {
      flex-grow: 1;
      padding-right: var(--cell-horizontal-padding);
      padding-left: var(--cell-horizontal-padding);
    }

    &__value {
      ${typography.h4}
    }

    &__input {
      width: 100%;
      font-weight: bold;
      padding: 0;
      font-size: 14px;
      border: none;
      background: transparent;
    }
  }
`;
