import styled from 'styled-components';

export const SHeader = styled.div`
  margin-top: 29px;
  margin-bottom: 16px;
  overflow: hidden;
  .campaign-header {
    &__calendar-link {
      display: flex;
      align-items: center;
      font-weight: 600;

      & i {
        margin-left: 5px;
      }
    }

    &__ad-sets-link {
      margin-right: 25px;
    }
    &__remove-button {
      margin-left: 20px;
    }
  }
`;
