import * as React from 'react';
import { SModal } from '../s-components/modal/s-modal';
import { SConfirmBody } from '../s-components/s-confirm-body';

interface Props {
  onCancel(): void;
  onOk(): void;
  cancelText?: string;
  okText?: string;
  visible?: boolean;
  reverseButtons?: boolean;
  title?: React.ReactNode;
  animationDuration?: number;
  top?: string;
  bodyHeight?: string;
  width?: string;
  renderHeader?: boolean;
  dataSelector?: string;
  children?: React.ReactNode;
}

export const Confirm = React.memo(
  ({
    onCancel,
    onOk,
    cancelText = 'Cancel',
    okText = 'Ok',
    visible = false,
    reverseButtons = false,
    title,
    animationDuration = 200,
    top,
    bodyHeight,
    width,
    renderHeader,
    dataSelector,
    children,
  }: Props) => (
    <SModal
      onRequestClose={onCancel}
      animationDuration={animationDuration}
      isOpen={visible}
      renderHeader={renderHeader}
      cancelText={cancelText}
      reverseButtons={reverseButtons}
      title={title}
      okText={okText}
      onOk={onOk}
      onCancel={onCancel}
      top={top}
      bodyHeight={bodyHeight}
      width={width}
      dataSelector={dataSelector}
    >
      <SConfirmBody>{children}</SConfirmBody>
    </SModal>
  )
);
