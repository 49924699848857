import React from 'react';
import PropTypes from 'prop-types';
import { SModalHeader } from '../../s-components/modal/s-header';
import { Button } from '../button';
import { Icon } from '../icon';
import { bem } from 'utils/bem';
import { SRow } from '../../s-components/layout/s-row';

export const ModalHeader = ({ title, onClosePress }) => {
  const classes = bem('header');

  return (
    <SModalHeader>
      <SRow justifyContent="space-between">
        <div className={classes('title')}>{title}</div>
        <Button
          className={classes('close-button')}
          variant="transparent"
          dataSelector="modal-close-button"
          onClick={onClosePress}
        >
          <Icon size="general" name="close" />
        </Button>
      </SRow>
    </SModalHeader>
  );
};

ModalHeader.propTypes = {
  onClosePress: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]).isRequired,
};
