import { ColDef } from 'ag-grid-community/dist/lib/entities/colDef';
import { MediaPlanColumnId } from 'media-plan-v2/containers/spreadsheet/types';

export const DEFAULT_COLUMNS_CONFIG: ColDef = {
  sortable: false,
  unSortIcon: true,
  editable: true,
  suppressMenu: true,
  resizable: true,
  suppressMovable: true,
  cellEditorPopup: false,
  suppressKeyboardEvent: params => {
    return params.editing;
  },
};

export const UNDO_REDO_LIMITS = 20;

export const SPREADSHEET_HEADER_HEIGHT = 37;
export const SPREADSHEET_ROW_HEIGHT = 40;

export const CELL_HORIZONTAL_PADDING = 10;

export const START_DATE_VALIDATION_TEXT = 'The End Date cannot be before the Start Date';
export const END_DATE_VALIDATION_TEXT = 'The End Date cannot be before the Start Date';
export const AD_CREATIVE_MULTIPLE_VALIDATION_TEXT = 'At least one link is invalid';
export const AD_CREATIVE_SINGLE_VALIDATION_TEXT = 'Invalid link';
export const AUDIENCE_AGE_VALIDATION_TEXT = 'Valid range is 13-99';

export const FIRST_VALUE_COLUMN_EXPORT_INDEX = 1;
export const EXCEL_EXPORT_COLUMNS = [
  MediaPlanColumnId.STATUS,
  MediaPlanColumnId.NAME,
  MediaPlanColumnId.PLATFORMS,
  MediaPlanColumnId.PLACEMENTS,
  MediaPlanColumnId.OBJECTIVE,
  MediaPlanColumnId.KPI,
  MediaPlanColumnId.START_DATE,
  MediaPlanColumnId.END_DATE,
  MediaPlanColumnId.BUDGET,
  MediaPlanColumnId.ACTUAL_SPENT,
  MediaPlanColumnId.AGE,
  MediaPlanColumnId.AUDIENCE_GENDERS,
  MediaPlanColumnId.DETAILS,
  MediaPlanColumnId.ECPM,
  MediaPlanColumnId.EST_IMPRESSIONS,
  MediaPlanColumnId.CREATIVE_DESCRIPTION,
  MediaPlanColumnId.COUNTRIES,
  MediaPlanColumnId.AD_COPY,
  MediaPlanColumnId.AD_CREATIVE,
  MediaPlanColumnId.DESTINATION,
  MediaPlanColumnId.HEADLINE,
  MediaPlanColumnId.CALL_TO_ACTION,
  MediaPlanColumnId.NOTES,
  MediaPlanColumnId.NAMING_CONVENTION,
];

export const COLUMNS_TO_SANITIZE = [
  MediaPlanColumnId.CREATIVE_DESCRIPTION,
  MediaPlanColumnId.DETAILS,
  MediaPlanColumnId.AD_COPY,
  MediaPlanColumnId.CALL_TO_ACTION,
  MediaPlanColumnId.HEADLINE,
  MediaPlanColumnId.NOTES,
  MediaPlanColumnId.NAME,
];

export const LINK_COLUMNS = [MediaPlanColumnId.AD_CREATIVE, MediaPlanColumnId.DESTINATION];

export const SYNC_IN_PROGRESS_WARNING_MESSAGE = 'Changes that you made may not be saved.';

export const SYNC_INTERVAL = 750;

export const EMPTY_PENDING_DATA = {
  create: null,
  update: null,
  delete: null,
};

export const ROW_CREATE_THROTTLE = 200;

export const ASYNC_TRANSACTION_WAIT = 50;

export const UNDO_ACTIONS_COUNT = 16;

export const NOT_COPYABLE_MEDIA_PLAN_COLUMNS_COUNT = 1;

export const MAX_LINKS_COUNT = 10;

export const CELL_BACKGROUND_COLORS = [
  'rgba(255,212,201,1)',
  'rgba(255,245,195,1)',
  'rgba(242,217,255,1)',
  'rgba(178,210,255,1)',
  'rgba(190,232,225,1)',
  'transparent',
];

export const COLORS_IDENTIFIER = 'data-decibel-media-plan-colors';
export const COLOR_IDENTIFIER = 'data-decibel-media-plan-color';

export const CELL_WIDTH_SHIFT = 3; // NOTE: 2 * 1px borders and 1px right padding
