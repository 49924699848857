import { useMemo } from 'react';
import { ParsedQs } from 'qs';
import { paths } from 'app/routing/paths';
import { Id } from 'backend-api/models';
import { AdSetsSortField } from 'backend-api/types';
import { useFilters } from 'hooks';
import { updateFilters } from '../actions';
import { filtersSelector } from '../selectors';
import { AdSetsFilters } from '../types';
import {
  getAdSetsFiltersFromParams,
  getAdSetsFiltersParamsFromFilters,
  getAdSetsFiltersParamsFromSearchQuery,
} from '../transducers';
import { UUID } from 'io-ts-types/lib/UUID';

export const useAdSetsFilters = (campaignUuid: UUID, projectId?: Id) => {
  const config = useMemo(
    () => ({
      action: updateFilters,
      selector: filtersSelector,
      path: projectId ? paths.campaign(projectId, campaignUuid) : paths.unassignedCampaignDetails(campaignUuid),
      queryToFilters: (query: ParsedQs) => {
        const params = getAdSetsFiltersParamsFromSearchQuery(query);
        return getAdSetsFiltersFromParams(params);
      },
      filtersToQuery: getAdSetsFiltersParamsFromFilters,
    }),
    [projectId, campaignUuid]
  );

  return useFilters<AdSetsFilters, AdSetsSortField>(config);
};
