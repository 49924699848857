import React, { useCallback } from 'react';
import { Artist, ArtistSchema, Playlist, TargetItems } from 'backend-api/models';
import { isPlaylists } from 'backend-api/guards';
import { ArtistPlaylistCard } from './components';
import { SArtistPlaylistSection } from './s-artist-playlist-section';
import { bem } from 'utils/bem';
import { isArtistLocked } from 'project/transducers';
import { PrimaryLabel } from 'common/components/typography';

interface Props {
  targets: TargetItems;
  selectedTargetIds: string[];

  onSelect(targetToSelect: Artist | Playlist): void;

  onRemove(targetToRemove: Artist | Playlist): void;

  className?: string;
}

const classes = bem('artist-playlist-section');

export const ArtistPlaylistSection = React.memo(
  ({ targets, selectedTargetIds, onSelect, onRemove, className }: Props) => {
    const renderCard = useCallback(
      (target: Artist | Playlist) => {
        const isSelected = selectedTargetIds.includes(target.id);
        const isLocked = ArtistSchema.is(target) && isArtistLocked(target);

        return (
          <ArtistPlaylistCard
            key={target.id}
            target={target}
            onSelect={onSelect}
            onRemove={onRemove}
            isSelected={isSelected}
            isLocked={isLocked}
          />
        );
      },
      [selectedTargetIds, onSelect, onRemove]
    );

    const featuredArtists = !isPlaylists(targets) && targets.filter(item => item.type === 'Featured');
    const primaryArtists = !isPlaylists(targets) && targets.filter(item => item.type === 'Primary');

    return (
      <SArtistPlaylistSection className={className}>
        <div className={classes('paper-container')}>
          <PrimaryLabel className={classes('title')}>
            {isPlaylists(targets) ? 'Playlists' : 'Primary Artists'}
          </PrimaryLabel>
          <div className={classes('cards-container')}>
            {isPlaylists(targets) && targets.map(renderCard)}
            {!isPlaylists(targets) && primaryArtists && primaryArtists.map(renderCard)}
          </div>
        </div>
        {featuredArtists && featuredArtists.length > 0 && (
          <div className={classes('paper-container-featured')}>
            <PrimaryLabel className={classes('title')}>{'Featured Artists'}</PrimaryLabel>
            <div className={classes('cards-container')}>{!isPlaylists(targets) && featuredArtists.map(renderCard)}</div>
          </div>
        )}
      </SArtistPlaylistSection>
    );
  }
);
