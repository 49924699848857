import { LinkLongTableTitle, TableContent } from 'common/components/table';
import { Id, PurchaseOrderDetails } from 'backend-api/models';
import { PurchaseOrderSortField } from 'backend-api/types';

import React from 'react';
import { bem } from 'utils/bem';
import { BEM_CLASS } from './s-table';
import { getCurrencyFormatted } from 'utils/format';
import { TableMenuDropdown } from '../table-dropdown';
import { SmallText } from 'common/components/typography';
import { BreakdownValuesByEntityId } from '../../types';
import { TableRowLoader } from 'common/components/table/table-row-loader';
import { BreakdownCell, Value } from 'common/components/tables/common';
import { Tooltip } from 'common/components/tooltip';
import { getTooltipByStatusName } from '../../transducers';
import { paths } from 'app/routing/paths';
import { getFormattedDate } from 'common-v2/utils';

const classes = bem(BEM_CLASS);

export const getPurchaseOrderTableConfig = (
  projectId: Id,
  breakdowns: BreakdownValuesByEntityId,
  dateFormat: string,
  isUnassigned?: boolean,
  currencyCode?: string
): TableContent<PurchaseOrderDetails, PurchaseOrderSortField>[] => {
  const format = getFormattedDate(dateFormat);
  return [
    {
      title: 'PO Name',
      subTitle: '',
      dataSelector: 'purchase-order-name-column-header',
      dataIndex: 'name',
      useSort: true,
      sortField: 'name',
      renderExpanded: item =>
        breakdowns[item.id].data?.map((record, index, array) => (
          <div key={record.id} className={classes('purchase-order-name-container', { expanded: true })}>
            <LinkLongTableTitle
              linkProps={{ pathname: paths.purchaseOrder(projectId, record.id) }}
              text={record.name}
              dataSelector="purchase-sub-order-name-table-item"
            />
            <div className={classes('sub-title-container', { expanded: true })}>
              <SmallText className={classes('sub-title')}>PO #{record.poNumber}</SmallText>
              <div className={classes('dot-divider')} />
              <SmallText className={classes('sub-title')}>{record.provider.name}</SmallText>
            </div>
            {index !== array.length - 1 && <div className={classes('name-divider')} />}
          </div>
        )) || [<TableRowLoader alignment="left" width={120} key="loader-left" />],
      render: record => (
        <div className={classes('purchase-order-name-container')}>
          <LinkLongTableTitle
            linkProps={{ pathname: paths.purchaseOrder(projectId, record.id) }}
            text={record.name}
            dataSelector="purchase-order-name-table-item"
          />
          <div className={classes('sub-title-container')}>
            <SmallText className={classes('sub-title')}>PO #{record.poNumber}</SmallText>
            <div className={classes('dot-divider')} />
            <SmallText className={classes('sub-title')}>{record.provider.name}</SmallText>
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      subTitle: '',
      dataSelector: 'purchase-order-status-column-header',
      dataIndex: 'status',
      useSort: true,
      sortField: 'status',
      renderExpanded: item =>
        breakdowns[item.id].data?.map((record, index, array) => (
          <BreakdownCell
            key={record.id}
            valueClassName={classes('table-value', { expanded: true })}
            className={classes('table-value-status-expanded-container')}
            value={record.status}
            hasDivider={index !== array.length - 1}
          />
        )) || [<TableRowLoader alignment="right" width={50} key="loader-right" />],
      additionalClassName: classes('small-table-cell'),
      additionalExpandedClassName: classes('small-table-cell'),
      render: record => (
        <Tooltip
          placement="bottom"
          content={getTooltipByStatusName(record.status)}
          enabled={!!getTooltipByStatusName(record.status)}
        >
          <Value className={classes('table-value-status', { textRight: true })}>{record.status}</Value>
        </Tooltip>
      ),
    },
    {
      title: 'Total Amount',
      subTitle: currencyCode,
      dataSelector: 'purchase-order-total-amount-column-header',
      dataIndex: 'total-amount',
      useSort: true,
      sortField: 'total',
      additionalClassName: classes('currency-header'),
      renderExpanded: item =>
        breakdowns[item.id].data?.map((record, index, array) => (
          <BreakdownCell
            key={record.id}
            valueClassName={classes('table-value', { expanded: true })}
            value={getCurrencyFormatted(record.budget, record.budgetCurrency)}
            hasDivider={index !== array.length - 1}
          />
        )) || [<TableRowLoader alignment="right" width={50} key="loader-right" />],
      additionalExpandedClassName: classes('small-table-cell'),
      render: record => (
        <Value className={classes('table-value')}>
          <Tooltip placement="bottom" content={getCurrencyFormatted(record.budget, record.budgetCurrency, true)}>
            {getCurrencyFormatted(record.budget, record.budgetCurrency)}
          </Tooltip>
        </Value>
      ),
    },
    {
      title: 'Paid Amount',
      subTitle: currencyCode,
      dataSelector: 'purchase-order-paid-amount-column-header',
      dataIndex: 'paid-amount',
      useSort: true,
      sortField: 'paid',
      additionalClassName: classes('currency-header'),
      renderExpanded: item =>
        breakdowns[item.id].data?.map((record, index, array) => (
          <BreakdownCell
            key={record.id}
            valueClassName={classes('table-value', { expanded: true })}
            value={getCurrencyFormatted(record.allocation, record.budgetCurrency)}
            hasDivider={index !== array.length - 1}
          />
        )) || [<TableRowLoader alignment="right" width={50} key="loader-right" />],
      additionalExpandedClassName: classes('small-table-cell'),
      render: record => (
        <Value className={classes('table-value')}>
          <Tooltip placement="bottom" content={getCurrencyFormatted(record.allocation, record.budgetCurrency, true)}>
            {getCurrencyFormatted(record.allocation, record.budgetCurrency)}
          </Tooltip>
        </Value>
      ),
    },
    {
      title: 'Created Date',
      subTitle: '',
      dataSelector: 'purchase-order-created-date-column-header',
      dataIndex: 'created-date',
      useSort: true,
      sortField: 'createdAt',
      renderExpanded: item =>
        breakdowns[item.id].data?.map((record, index, array) => (
          <BreakdownCell
            key={record.id}
            valueClassName={classes('table-value', { expanded: true })}
            value={format(record.createdDate)}
            hasDivider={index !== array.length - 1}
          />
        )) || [<TableRowLoader alignment="right" width={50} key="loader-right" />],
      additionalClassName: classes('small-table-cell'),
      additionalExpandedClassName: classes('small-table-cell'),
      render: record => <Value className={classes('table-value')}>{format(record.createdDate)}</Value>,
    },
    {
      title: 'Last Modified',
      subTitle: '',
      dataSelector: 'purchase-order-last-modified-column-header',
      dataIndex: 'last-modified',
      useSort: true,
      sortField: 'modifiedAt',
      renderExpanded: item =>
        breakdowns[item.id].data?.map((record, index, array) => (
          <BreakdownCell
            key={record.id}
            valueClassName={classes('table-value', { expanded: true })}
            value={format(record.lastModifiedDate)}
            hasDivider={index !== array.length - 1}
          />
        )) || [<TableRowLoader alignment="right" width={50} key="loader-right" />],
      additionalClassName: classes('small-table-cell'),
      additionalExpandedClassName: classes('small-table-cell'),
      render: record => <Value className={classes('table-value')}>{format(record.lastModifiedDate)}</Value>,
    },
    {
      title: '',
      dataIndex: 'menu',
      additionalClassName: classes('menu-cell'),
      additionalExpandedClassName: classes('menu-cell'),
      dataSelector: 'campaigns-menu-column',
      useSort: false,
      renderExpanded: item => [<div key={item.id} />],
      render: record => <TableMenuDropdown projectId={projectId} linkedCampaign={record.linkedCampaign} />,
    },
  ];
};
