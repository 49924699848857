import { actionsGroup, asyncAction } from 'core/store';
import { Id, PurchaseOrderDetails, PurchaseOrdersListResponse, PurchaseOrdersProvider } from 'backend-api/models';
import { GetPurchaseOrderChildrenPayload, GetPurchaseOrderPayload } from './types';
import { PurchaseOrdersFilters } from 'common/components/tables/purchase-orders';

const { action } = actionsGroup('purchase-order');

export const getPurchaseOrder = asyncAction<GetPurchaseOrderPayload, PurchaseOrderDetails, Error>('GET_PURCHASE_ORDER');

export const updateFilters = action<PurchaseOrdersFilters>('UPDATE_FILTERS');

export const getPurchaseOrderChildren = asyncAction<GetPurchaseOrderChildrenPayload, PurchaseOrdersListResponse, Error>(
  'GET_PURCHASE_ORDER_CHILDREN'
);

export const getPurchaseOrdersProviders = asyncAction<Id, PurchaseOrdersProvider[], Error>(
  'GET_PURCHASE_ORDERS_PROVIDERS'
);
