import styled from 'styled-components';

export const BEM_CLASS = 'genders-editor';

interface Props {
  width: number;
  height: number;
}

export const SGendersEditor = styled(({ className, children }) => children(className))<Props>`
  min-width: ${({ width }) => width}px;

  .${BEM_CLASS} {
    &__control {
      width: 100%;
      border-radius: 0;
      min-height: ${({ height }) => height}px;
    }

    &__menu {
      border-radius: 10px;
      min-width: 170px;
    }

    &__menu-list {
      padding: 0;
    }

    &__value-container {
      padding: 10px;
      height: 100%;
    }

    &__indicators-container {
      padding-right: var(--cell-horizontal-padding);
      padding-left: var(--cell-horizontal-padding);
    }

    &__group {
      padding: 2px 0;
    }

    &__group-label {
      padding: 0 10px;
    }

    &__option {
      padding: 5px 10px;
    }
  }
`;
