import styled from 'styled-components';
import { animatedFlare } from 'common/s-components/s-animated-flare';

interface Props {
  clipPathId: string;
}

export const SLoaderUpcomingProjects = styled.div<Props>`
  height: 70px;
  width: 110px;
  .loader-upcoming-projects {
    &__clip-container {
      width: 110px;
      height: 70px;
      clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      -webkit-clip-path: ${({ clipPathId }) => `url(#${clipPathId})`};
      ${animatedFlare}
    }
  }
`;
